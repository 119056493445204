import { action, observable } from 'mobx';
import { getOrders, getOrdersCustomerRecord } from '../api/orders';
import Request from '../mobx-models/Request';

class OrdersStore {
  @observable orders = [];
  @observable ordersRequest = new Request();
  vapStore;

  constructor(store) {
    this.vapStore = store.vapStore;
  }

  getOrders = (dealershipId, page, pageSize) => {
    return this.ordersRequest
      .onSuccess(
        action((orders) => {
          this.orders = orders.Orders;
          this.totalOrderCount = orders.TotalOrders;
        })
      )
      .onError(() => {
        this.orders = null;
      })
      .call(getOrders, dealershipId, page, pageSize);
  };

  getOrdersCustomerRecord = (dealershipId, page, pageSize, dealershipConsumerId, vehicleOfInterestId) => {
    return this.ordersRequest
      .onSuccess(
        action((orders) => {
          this.orders = orders.Orders;
          this.totalOrderCount = orders.TotalOrders;
        })
      )
      .onError(() => {
        this.orders = null;
      })
      .call(getOrdersCustomerRecord, dealershipId, page, pageSize, dealershipConsumerId, vehicleOfInterestId);
  };

  // replace with new v1/orders/{dealershipId}/{orderId} route, ideally it has actual vaps on in case we deleted one
  getOrder = (orderId) => {
    let order = this.orders.find((order) => order.Id === orderId);
    if (order.Vaps && order.Vaps.length > 0) {
      order.Vaps = order.Vaps.map((vapId) => this.vapStore.getById(vapId));
    }
  };

  @action
  resetOrders = () => {
    this.orders = {};
  };

  customerOnlineOrders = () => {
    return 0;
  };
}

export default OrdersStore;
