import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PushDealActivityContainerSection from './PushDealActivityContainerSection';
import MoneyFormat from '../Common/MoneyFormat';
import './pushDealVapsDetails.scss';

const PushDealVapsDetails = ({ vaps, vapsTotal }) => {
  const { t } = useTranslation('PushDeal');
  const lenderVapLookup = new Set(['GAP', 'GAPPlus', 'RSV', 'RSVPlus']);

  return (
    <div className="pushDealVapsDetails">
      {vaps.map((vap, index) => {
        return (
          <div className="pushDealVapsDetails__vapItem" key={index}>
            <PushDealActivityContainerSection title={vap.productTypeText}>
              {lenderVapLookup.has(vap.name) ? t('PushDealConfirm.see_quote') : <MoneyFormat value={vap.price} />}
            </PushDealActivityContainerSection>
            <div className="pushDealVapsDetails__name">{vap.name}</div>
          </div>
        );
      })}
      <div className="pushDealVapsDetails__vapsTotal">
        <PushDealActivityContainerSection title={t('PushDealVapsDetails.total')}>
          <MoneyFormat value={vapsTotal} />
        </PushDealActivityContainerSection>
      </div>
    </div>
  );
};

PushDealVapsDetails.propTypes = {
  vaps: PropTypes.array,
  vapsTotal: PropTypes.string
};
export default PushDealVapsDetails;
