import React from 'react';
import { connect } from 'react-redux';
import * as quoteActions from '../../../redux/quote/quoteActions';
import * as analyticsActions from '../../../redux/analytics/analyticsActions';
import QuoteModule from '../components/QuoteModule';
import { getSortedQuotes, getDefaultQuoteDetails, getFullQuoteDetails } from '../selectors/quotingSelectors';
import { VIEW_MONTHLY_PAYMENTS } from '../../../redux/actionTypes';
import * as modalActions from '../../../redux/modal/modalActions';
import { trackCfcEligibilityCheckActivated, formatQuoteVehicleToCfcTracking } from '../../../tracking/avoTracking';
import { observer, inject } from 'mobx-react';
import { withNavigate, withParams, withQuery } from 'hocs/router';
import { compose } from 'redux';

class QuoteModuleContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldRender: false
    };
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({
      shouldRender: true
    });
  }

  UNSAFE_componentWillMount() {
    if (this.props.isContinuingFromQuickQuote) {
      // This doesn't seem to be used - `&continue=true` param is not present in the codebase
      this.props.continueQuoting();
    } else {
      this.props.appStore.vapStore.fetchValueAddedProducts(this.props.params.dealershipId).then(() => {
        // Re-quoting with a new APR for an existing product (interceptor)
        if (this.props.newAPR) {
          this.props.startRequoting();
        } else {
          // Regular quoting journeys - customer, quick quote, application
          this.props.startQuoting();
        }
      });
    }
  }
  componentDidUpdate() {
    // quickQuoteSearchBar asks to start a new Quote via Mobx
    const shouldResetQuotingState = this.props.appStore.quotingStore.shouldResetQuotingState;

    if (shouldResetQuotingState) {
      this.props.appStore.quotingStore.setShouldResetQuotingState(false);
      this.props.startQuoting();
    }
  }

  updateQueryString = () => {
    this.props.navigate(`?section=${this.props.currentView.toLowerCase()}`);
  };

  componentWillUnmount() {
    this.props.endQuoting();
  }

  render() {
    return this.state.shouldRender ? <QuoteModule {...this.props} updateQueryString={this.updateQueryString} /> : null;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    quotes: state.quotes,
    hasAdjustedBalloon: state.quotes.hasAdjustedBalloon,
    returnedQuotes: getSortedQuotes(state),
    eligibilityCheckDetails: state.quotes.eligibilityCheckDetails,
    isLoadingCfcPreFill: state.quotes.isLoadingCfcPreFill,
    checkingEligibilityState: state.quotes.checkingEligibilityState,
    dealershipId: state.dealership.Id,
    hasCheckedEligibility: state.quotes.hasCheckedEligibility,
    currentView: state.quotes.currentView,
    previousView: state.quotes.previousView,
    canEditVehicle: ownProps.isQuickQuote,
    vehicle: state.quotes.vehicle,
    defaultQuoteDetails: getDefaultQuoteDetails(state, ownProps.quote, ownProps.isQuickQuote),
    craScorePercentage: state.quotes.craScorePercentage,
    personalScorePercentage: state.quotes.personalScorePercentage,
    isContinuingFromQuickQuote: ownProps.query.continue === 'true',
    proceedingState: ownProps.hasOwnProperty('proceedingState')
      ? ownProps.proceedingState
      : state.quotes.proceedingState,
    applyingState: state.quotes.applyingState,
    savingState: state.quotes.savingState,
    sortField: state.quotes.sortField,
    sortDirection: state.quotes.sortDirection,
    fullQuoteDetails: getFullQuoteDetails(state),
    monthlyPayments: state.quotes.monthlyPayments,
    isFetchingMonthlyPayments: state.quotes.isFetchingMonthlyPayments,
    hasFetchMonthlyPaymentsError: state.quotes.hasFetchMonthlyPaymentsError
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    startQuoting: () => {
      dispatch(
        quoteActions.startQuoting(
          ownProps.params.consumerId,
          ownProps.customerEmail,
          ownProps.vehicle,
          ownProps.initialCosts,
          ownProps.restrictToProductType,
          ownProps.restrictToFunderCode,
          ownProps.customerType,
          ownProps.restrictQuotesByProduct
        )
      );
    },
    startRequoting: () => {
      dispatch(
        quoteActions.startRequoting(
          ownProps.params.consumerId,
          ownProps.customerEmail,
          ownProps.vehicle,
          ownProps.initialCosts,
          ownProps.customerType,
          ownProps.currentProductUid,
          ownProps.newAPR,
          ownProps.preApprovalData
        )
      );
    },

    handleCostsChange: (newCosts) => {
      dispatch(quoteActions.changeCosts(newCosts));
    },

    handleChangeCostsClick: () => {
      dispatch(quoteActions.changeView('COSTS_VIEW'));
    },

    handleProceedToFinanceNavigator: (quoteIds) => {
      dispatch(quoteActions.startFinanceNavigator(quoteIds));
    },

    handleCancelFinanceNavigator: () => {
      dispatch(quoteActions.cancelFinanceNavigator());
    },

    handleQuoteSelect: (quoteId, checked) => {
      if (checked) {
        dispatch(quoteActions.addQuoteForCompare(quoteId));
      } else {
        dispatch(quoteActions.removeQuoteForCompare(quoteId));
      }
    },

    handleRemoveQuoteFromCompare: (quoteId) => {
      dispatch(quoteActions.removeQuoteForCompare(quoteId));
    },

    handleCheckEligibilityClick: () => {
      trackCfcEligibilityCheckActivated(formatQuoteVehicleToCfcTracking(ownProps.vehicle));
      dispatch(quoteActions.preFillEligibilityFormDetails());
      dispatch(quoteActions.changeView('CHECK_ELIGIBILITY_VIEW'));
    },

    handleReturnToListViewClick: () => {
      dispatch(quoteActions.changeView('LIST_VIEW'));
    },

    handleBackToPreviousView: () => {
      dispatch(quoteActions.backToPreviousView());
    },

    onPrint: () => {
      dispatch(analyticsActions.trackPageInteraction('QuoteComparePrint', ownProps.params.dealershipId));
    },

    changeComissions: (settings) => {
      dispatch(quoteActions.changeCommissions(settings));
    },

    handleEligibilityFormSectionSubmit: (sectionName, formData) => {
      dispatch(quoteActions.updateEligibilityFormData(sectionName, formData));
    },

    handleEligibilityFormSubmit: () => {
      dispatch(quoteActions.checkEligibility());
    },

    continueQuoting: () => {
      dispatch(quoteActions.changeView('LIST_VIEW'));
    },
    onEligibilityMatchClick: (matchRate, decisionMessages) => {
      dispatch(modalActions.open('eligibilityModal', { matchRate, decisionMessages }));
    },
    onEligibilitySummaryClick: (personalScore, creditScore) => {
      dispatch(modalActions.open('eligibilitySummary', { personalScore, creditScore }));
    },
    onSortQuotes: (field) => {
      dispatch(quoteActions.onSortQuotes(field));
    },
    endQuoting: () => {
      dispatch(quoteActions.endQuoting());
    },
    changeToMonthlyPaymentsView: () => {
      dispatch(quoteActions.changeView('MONTHLY_PAYMENTS_VIEW'));
    },
    changeToConsumerDutyView: () => {
      dispatch(quoteActions.changeView('CONSUMER_DUTY'));
    },
    changeToCompareView: () => {
      dispatch(quoteActions.changeView('COMPARE_VIEW'));
    },
    changeMonthlyPaymentTerm: (term) => {
      dispatch(quoteActions.changeTerm(term));
      dispatch(modalActions.close('confirmChangeMonthlyPaymentsTerm'));
      window.ga && window.ga('send', 'event', 'MonthlyPriceView', 'ButtonPress', 'ConfirmCompare');
    },
    selectTerm: (selectedTerm) => {
      dispatch(modalActions.open('confirmChangeMonthlyPaymentsTerm', { selectedTerm }));
    },
    closeChangeMonthlyPaymentsTermModal: () => {
      dispatch(modalActions.close('confirmChangeMonthlyPaymentsTerm'));
    },
    selectMonthlyPayment: (quote) => {
      dispatch(modalActions.open('selectMonthlyPayment', { quote }));
    },
    updateMonthlyPayments: () => {
      dispatch({ type: VIEW_MONTHLY_PAYMENTS });
    },
    setBreakDownView: () => {
      dispatch(quoteActions.changeView('LOANS_BREAKDOWN_VIEW'));
    }
  };
}

export default compose(
  withQuery,
  withNavigate,
  withParams,
  connect(mapStateToProps, mapDispatchToProps),
  inject('appStore'),
  observer
)(QuoteModuleContainer);
