import React from 'react';
import './quoteStepsItem.scss';

const QuoteStepsItem = ({ children, title }) => {
  return (
    <div className="quoteStepsItem__inner">
      <div className="quoteStepsItem__dot" />
      <div className="quoteStepsItem__header">{title}</div>
      <div className="quoteStepsItem__text">{children}</div>
    </div>
  );
};

export default QuoteStepsItem;
