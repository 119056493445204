import { withTranslation } from 'react-i18next';
import React from 'react';
import PageTitle from '../../Common/PageTitle';

const YourShareModal = ({ t }) => {
  return (
    <div>
      <PageTitle text={t('YourShareModal.your_share_of_monthly_mortgage_or_rental_expenditure')} />
      <p>
        {t(
          'YourShareModal.the_regular_total_monthly_payment_that_is_made_in_respect_of_your_mortgage_or_rent_in_the_event_that_you_pay_both_mortgage_and_rent_please_provide_the_combined_monthly_payment_similarly_in_the_event_that_you_own_more_than_one_property_and_have_a_mortgage_on_each_please_provide_the_combined_monthly_payment_where_a_separate_repayment_commitment_is_in_place_in_respect_of_certain_interest_only_mortgages_offset_mortgages_for_example_include_the_regular_offset_payments_within_this_category'
        )}
      </p>
    </div>
  );
};

export default withTranslation('Application')(YourShareModal);
