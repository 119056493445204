import classnames from 'classnames';
import ReactCurrencyInput from 'react-currency-input-field';

import { CurrencyCodeToSymbol } from '~/constants';

import s from './numberInput.module.scss';

const noop = () => {};

const NumberInput = ({
  type = 'number',
  isValid,
  showError,
  disabled,
  size,
  id,
  maxLength,
  intlConfig = { locale: 'en-GB', currency: 'GBP' },
  onChange = noop,
  onBlur = noop,
  decimalLimit = 2,
  allowNegativeValue = true,
  prefix = '\u200b', // Hack to "disable" the prefix by default with zero-width whitespace.
  ...rest
}) => {
  const handleBlur = (...xs) => {
    onBlur(...xs); // <MobxFieldGroup /> injects this and we need to call it manually.
  };

  const handleChange = (...xs) => {
    onChange(...xs);
  };

  const isCurrency = type === 'currency';

  const labelClasses = classnames(s.label, {
    [s['label--valid']]: showError && isValid && !disabled,
    [s['label--invalid']]: showError && !isValid && !disabled,
    [s['label--small']]: size === 'small'
  });

  const inputClassnames = classnames(s.input, {
    [s['input--valid']]: showError && isValid && !disabled,
    [s['input--invalid']]: showError && !isValid && !disabled,
    [s['input--disabled']]: disabled,
    [s['input--small']]: size === 'small',
    [s['currencyPadding']]: isCurrency,
    [s['numberPadding']]: !isCurrency
  });

  return (
    <div className={s.cont}>
      {isCurrency && (
        <label className={labelClasses} htmlFor={id}>
          {CurrencyCodeToSymbol[intlConfig.currency]}
        </label>
      )}
      <ReactCurrencyInput
        className={inputClassnames}
        id={id}
        onBlur={handleBlur}
        onValueChange={handleChange}
        allowNegativeValue={allowNegativeValue}
        intlConfig={intlConfig}
        prefix={prefix}
        maxLength={maxLength}
        {...rest}
      />
    </div>
  );
};

export default NumberInput;
