import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import MoneyFormat from '../../Common/MoneyFormat';
import PaymentSchedules from './PaymentSchedule';
import QuoteCommitmentMessage from './QuoteCommitmentMessage';
import AnnualRateAdjustedMessage from './AnnualRateAdjustedMessage';
import { formatAddress } from '../../../core/helpers';
import classnames from 'classnames';
import { FormatNumToDecimals } from './FormatAPR';
import './quoteSmallPrint.scss';

const QuoteSmallPrint = ({ quote, funders, vehicleClass, showFullQuote, isPersonalQuote, newCarOfferText }) => {
  const { t } = useTranslation('Quoting');
  const funder =
    _.find(funders, {
      Code: quote.FunderCode
    }) || {};
  let vapsTodisplay;

  if (quote.ValueAddedProducts) {
    let groupedVaps = _.groupBy(quote.ValueAddedProducts, 'ProductTypeText');

    vapsTodisplay = _.map(groupedVaps, (vapGroup) => ({
      ProductTypeText: vapGroup[0].ProductTypeText,
      Price: vapGroup.reduce((accumulator, currentVap) => accumulator + currentVap.Price, 0)
    }));
  }

  const isVatQualifying = quote.VATQualifying || quote.VatQualifying || false;
  const showVat = vehicleClass && vehicleClass.toLowerCase() === 'lcv' && isVatQualifying;
  if (quote.Warranty > 0 || quote.Insurance > 0 || quote.Other > 0) {
    return (
      <div className="quoteSmallPrint__outer" data-th="QuoteSmallPrint">
        <p className="quoteSmallPrint">
          {isPersonalQuote && t('QuoteSmallPrint.personalised_quotation') + ' '}
          {t('QuoteSmallPrint.total_price')} <MoneyFormat value={quote.CashPrice} />. {t('QuoteSmallPrint.deposit')}{' '}
          <MoneyFormat value={quote.TotalDeposit} />. {t('QuoteSmallPrint.total_amount_of_credit')}{' '}
          <MoneyFormat value={quote.BalancePayable} />.{' '}
          <PaymentSchedules type="inline" payments={quote.PaymentSchedule} />{' '}
          {t('QuoteSmallPrint.duration_of_agreement_is')} {quote.Term} {t('QuoteSmallPrint.months_fees_of')}{' '}
          <MoneyFormat value={quote.Fees} /> {t('QuoteSmallPrint.are_included_in_the_payments_shown')}{' '}
          {quote.InterestCharges
            ? t('QuoteSmallPrint.interest_charges_of_amount', {
                amount: quote.InterestCharges
              })
            : ''}{' '}
          {t('QuoteSmallPrint.annual_fixed_interest_rate_of')}
          <FormatNumToDecimals value={quote.AnnualRate} decimal={2} appendChar="%" />.{' '}
          {quote.FunderCode === 'MOT' && quote.FinanceType === 'PCP' && <AnnualRateAdjustedMessage />}{' '}
        </p>
        <p className="quoteSmallPrint quoteSmallPrint__finance">
          {t('QuoteSmallPrint.finance_is_provided_by')} {formatAddress(funder)}. <QuoteCommitmentMessage />
        </p>
      </div>
    );
  }

  const quoteSmallPrintOuterclasses = classnames(
    showFullQuote ? 'quoteSmallPrint__outer' : 'quoteSmallPrint__outer--hidden'
  );
  return (
    <div className={quoteSmallPrintOuterclasses} data-th="QuoteSmallPrint">
      <p className="quoteSmallPrint">
        {isPersonalQuote && t('QuoteSmallPrint.personalised_quotation') + ' '}
        {t('QuoteSmallPrint.total_price')} <MoneyFormat value={quote.CashPrice} />.{' '}
        {quote.VehiclePrice && (
          <span>
            {t('QuoteSmallPrint.vehicle_price')} {showVat && <span>{t('QuoteSmallPrint.inc_vat')}</span>}{' '}
            <MoneyFormat value={quote.VehiclePrice} />.
          </span>
        )}
        {vapsTodisplay &&
          vapsTodisplay.map((vap, index) => (
            <span key={index}>
              {' '}
              {vap.ProductTypeText} <MoneyFormat value={vap.Price} />.
            </span>
          ))}{' '}
        {quote.FinanceDepositAllowances &&
          quote.FinanceDepositAllowances.map((FinanceDepositAllowance) => (
            <span
              key={`quote-finance-deposit-allowance-${FinanceDepositAllowance.Name}-${FinanceDepositAllowance.Value}`}
            >
              {FinanceDepositAllowance.Name} <MoneyFormat value={FinanceDepositAllowance.Value} />.{' '}
            </span>
          ))}
        {!quote.FinanceDepositAllowances && quote.FinanceDepositAllowance > 0 && (
          <span>
            {t('QuoteSmallPrint.deposit_contribution')} {<MoneyFormat value={quote.FinanceDepositAllowance} />}.{' '}
          </span>
        )}
        {t('QuoteSmallPrint.deposit')} <MoneyFormat value={quote.TotalDeposit} />.{' '}
        {!!quote.Balloon && (
          <span>
            {t('QuoteSmallPrint.residual_value')} <MoneyFormat value={quote.Balloon} />.{' '}
          </span>
        )}
        {t('QuoteSmallPrint.total_amount_of_credit')} <MoneyFormat value={quote.BalancePayable} />.{' '}
        <PaymentSchedules type="inline" payments={quote.PaymentSchedule} />{' '}
        {t('QuoteSmallPrint.duration_of_agreement_is')} {quote.Term} {t('QuoteSmallPrint.months_fees_of')}{' '}
        <MoneyFormat value={quote.Fees} /> {t('QuoteSmallPrint.are_included_in_the_payments_shown')}{' '}
        {quote.InterestCharges ? (
          <>
            {t('QuoteSmallPrint.interest_charges_of')} <MoneyFormat value={quote.InterestCharges} />.
          </>
        ) : (
          ''
        )}{' '}
        {t('QuoteSmallPrint.annual_fixed_interest_rate_of')}
        <FormatNumToDecimals value={quote.AnnualRate} decimal={2} appendChar="%" />.{' '}
        {quote.FunderCode === 'MOT' && quote.FinanceType === 'PCP' && <AnnualRateAdjustedMessage />}
      </p>
      <p className="quoteSmallPrint quoteSmallPrint__finance">
        {t('QuoteSmallPrint.finance_is_provided_by')} {formatAddress(funder)}. <QuoteCommitmentMessage />
      </p>
      {newCarOfferText && <p className="quotesSmallPrint__newCarOfferText">{newCarOfferText}</p>}
    </div>
  );
};

QuoteSmallPrint.propTypes = {
  quote: PropTypes.object,
  funders: PropTypes.array,
  vehicleClass: PropTypes.string,
  isPersonalQuote: PropTypes.bool,
  showFullQuote: PropTypes.bool,
  newCarOfferText: PropTypes.string
};
QuoteSmallPrint.defaultProps = {
  isPersonalQuote: true,
  showFullQuote: true
};

function mapStateToProps(state) {
  return {
    funders: state.funders
  };
}

export default connect(mapStateToProps)(QuoteSmallPrint);
