import React from 'react';

import './pendingDataSpinner.scss';

const PendingDataSpinner = () => (
  <div className="pendingDataSpinner">
    <div className="pendingDataSpinner__track" />
    <div className="pendingDataSpinner__handle" />
  </div>
);

export default PendingDataSpinner;
