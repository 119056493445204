import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes as MobXPropTypes, inject } from 'mobx-react';
import PropTypes from 'prop-types';

import { BroadcastStockListItem, Modal, SubModal } from './components';

import Button from '~Common/Button/Button';
import LoadingDots from '~Common/Loading/LoadingDots';

import './stockList.scss';

const BroadcastStockList = ({
  vehicles,
  isLoadingStock,
  openModal,
  handleNewDataPending,
  appStore,
  onSelectVehicle,
  selectedVehicles
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [currentScrollPosition, setCurrentScrollPosition] = useState(null);
  const { t } = useTranslation('Stock');
  const { stockListScrollPosition, setStockListScrollPosition } = appStore.stockStore;

  let stockListScrollContainer;

  useEffect(() => {
    if (stockListScrollPosition !== null && !isLoadingStock) {
      setCurrentScrollPosition(stockListScrollPosition);
      setStockListScrollPosition(null);
    }
    return () => {
      setCurrentScrollPosition(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stockListScrollContainer == null) {
      stockListScrollContainer = document.querySelector('.globalLayout__content-inner');
    }

    if (currentScrollPosition !== null && !isLoadingStock) {
      stockListScrollContainer?.scrollTo(0, currentScrollPosition);
    } else if (currentScrollPosition === null && isLoadingStock) {
      stockListScrollContainer?.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentScrollPosition]);

  const closeDataIssueModal = () => {
    setIsModalOpen(false);
    setModalContent([]);
  };

  const renderParagraphs = (para) => {
    if (para.length) {
      return para.map((text, i) => <p key={i}>{text}</p>);
    } else return '';
  };

  if (isLoadingStock && !currentScrollPosition) {
    return (
      <div className="stockList">
        <LoadingDots />
      </div>
    );
  }

  if (isLoadingStock === false && !vehicles.length) {
    return <div className="stockList__vehiclesNotFound">{t('StockList.no_vehicles_found')}</div>;
  }

  return (
    <div className="stockList">
      <Modal
        title={t('StockList.this_current_vehicle_is_not_advertised')}
        isOpen={isModalOpen}
        onDismiss={closeDataIssueModal}
        paragraph={renderParagraphs(modalContent)}
      >
        <SubModal
          title={t('StockList.important_information')}
          paragraph={t(
            'StockList.to_change_other_vehicle_information_you_will_need_to_change_this_in_your_source_inventory_management_system'
          )}
        >
          <Button dataThook={'closeDataIssueModal'} className="stockList__modalButton" onClick={closeDataIssueModal}>
            {t('StockList.close')}
          </Button>
        </SubModal>
      </Modal>

      {vehicles.map((vehicle, index) => (
        <BroadcastStockListItem
          key={index}
          vehicle={vehicle}
          openModal={openModal}
          handleNewDataPending={handleNewDataPending}
          appStore={appStore}
          onSelectVehicle={onSelectVehicle}
          isVehicleSelected={selectedVehicles && selectedVehicles.includes(vehicle.Id)}
        />
      ))
      }
    </div>
  );
};

BroadcastStockList.propTypes = {
  vehicles: MobXPropTypes.observableArray.isRequired,
  isLoadingStock: PropTypes.bool,
  openModal: PropTypes.func,
  handleNewDataPending: PropTypes.func,
};

export default inject('appStore')(BroadcastStockList);
