import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { fetchCapData } from '../../../api/vrmLookup';
import classnames from 'classnames';
import _ from 'lodash';
import LoadingSpinner from '../Loading/LoadingSpinner';
import './selectInput.scss';

class SelectDerivativeInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      derivatives: []
    };
  }

  componentDidMount() {
    this.fetchCapData();
  }

  componentDidUpdate(nextProps) {
    if (nextProps.makeId !== this.props.makeId || nextProps.modelId !== this.props.modelId) {
      this.fetchCapData();
    }
  }

  fetchCapData = async () => {
    if (!this.props.makeId || !this.props.modelId) {
      this.setState({
        derivatives: []
      });
      return;
    }

    this.setState({
      isLoading: true
    });
    this.props.onFetchDerivatives && this.props.onFetchDerivatives();
    let derivativeKeys = {};
    let derivatives = await fetchCapData({
      class: this.props.vehicleClass,
      makeId: this.props.makeId,
      modelId: this.props.modelId,
      regDate: this.props.registrationDate
    });
    derivatives = _.sortBy(derivatives, 'Value');
    derivatives.forEach((derivative) => {
      derivativeKeys[derivative.Key] = derivative.Value;
    });
    this.props.onFetchDerivativesSuccess && this.props.onFetchDerivativesSuccess(derivativeKeys);
    setTimeout(() => {
      this.setState({
        isLoading: false,
        derivatives
      });
    }, 250);
  };
  handleChange = (event) => {
    this.props.onChange && this.props.onChange(event);
  };
  renderOptions = () => {
    return this.state.derivatives.map((derivative) => (
      <option key={derivative.Key} data-derivative={derivative.Value} value={derivative.Key}>
        {derivative.Value}
      </option>
    ));
  };

  render() {
    let { id, name, value, isValid, showError, disabled } = this.props;
    const selectStyles = classnames(
      'selectInput',
      !isValid && showError && 'selectInput--invalid',
      isValid && 'selectInput--valid',
      this.state.isLoading && 'selectInput--loading'
    );
    const dataAttrs = {};

    if (isValid === false) {
      dataAttrs['data-is-invalid'] = true;
    }

    if (this.state.isLoading) {
      return (
        <div className={selectStyles}>
          <LoadingSpinner size="small" />
        </div>
      );
    }

    return (
      <select
        className={selectStyles}
        id={id}
        name={name}
        value={value}
        onChange={this.handleChange}
        disabled={disabled}
        onBlur={this.props.onBlur}
        {...dataAttrs}
      >
        <option value="">{this.props.t('SelectDerivativeInput.please_select')}</option>
        {this.renderOptions()}
      </select>
    );
  }
}

SelectDerivativeInput.propTypes = {
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  registrationDate: PropTypes.string,
  onFetchDerivatives: PropTypes.func,
  onFetchDerivativesSuccess: PropTypes.func,
  name: PropTypes.string,
  showError: PropTypes.bool,
  makeId: PropTypes.string,
  modelId: PropTypes.string,
  vehicleClass: PropTypes.string
};
export default withTranslation('Common')(SelectDerivativeInput);
