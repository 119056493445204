import React from 'react';
import PropTypes from 'prop-types';
import { titleCase } from '../../core/helpers';

const ConsumerName = ({ name }) => {
  return <span data-th="ConsumerName">{titleCase(name)}</span>;
};

ConsumerName.defaultProps = {
  name: ''
};

ConsumerName.propTypes = {
  name: PropTypes.string
};

export default ConsumerName;
