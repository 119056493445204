import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../Common/Icon/Icon';
import './dealershipDistance.scss';
import { inject } from 'mobx-react';
import Distance from '../Distance';

const DealershipDistance = ({ distance, handleDealershipDistanceModal, appStore }) => {
  const { t } = useTranslation('Customer');

  return (
    <div className="dealershipDistance">
      <div className="dealershipDistance__inner">
        <div className="dealershipDistance__icon">
          <Icon name="distance" />
        </div>
        <div className="dealershipDistance__bottom">{t('DealershipDistance.distance_from_dealership')}</div>
        <div className="dealershipDistance__miles">
          <Distance miles={distance} countryCode={appStore.uiState.countryCode} />
        </div>
        <div className="dealershipDistance__text">
          {handleDealershipDistanceModal && (
            <span onClick={handleDealershipDistanceModal} className="dealershipDistance__map">
              {t('DealershipDistance.view_map')}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

DealershipDistance.propTypes = {
  distance: PropTypes.string,
  handleDealershipDistanceModal: PropTypes.func
};
export default inject(['appStore'])(DealershipDistance);
