import PropTypes from 'prop-types';
import EmploymentHistoryPanelItem from './EmploymentHistoryPanelItem';
import './addressHistoryPanel.scss';

const EmploymentHistoryPanel = ({ employment }) => (
  <div className="addressHistoryPanel">
    {employment.map((item, index) => {
      return (
        <div className="addressHistoryPanel__inner" key={'emp' + index}>
          <EmploymentHistoryPanelItem employment={item} index={index} key={index} />
        </div>
      );
    })}
  </div>
);

EmploymentHistoryPanel.propTypes = {
  employment: PropTypes.array
};

EmploymentHistoryPanel.defaultProps = {
  employment: []
};

export default EmploymentHistoryPanel;
