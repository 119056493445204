import { observable } from 'mobx';

class FinanceDepositAllowance {
  @observable Name;
  @observable Value;

  updateFromJSON(json = {}) {
    this.Name = json.Name || json.name;
    this.Value = json.Value || json.value;
  }
}

export default FinanceDepositAllowance;
