import React, { useState, useEffect, useRef, ReactNode } from 'react';
import Icon from './Icon/Icon';
import classnames from 'classnames';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import './splitButton.scss';

// Define TypeScript interface for the component props
type SplitButtonProps = {
  children: ReactNode | ReactNode[];
  disabled?: boolean;
  state?: 'default' | 'loading' | 'done' | 'error';
  [prop: string]: any; // For additional props not explicitly defined
};

const SplitButton: React.FC<SplitButtonProps> = ({ children, disabled = false, state = 'default', ...props }) => {
  const [buttonState, setButtonState] = useState<'default' | 'loading' | 'done' | 'error'>(state);

  const [optionsVisible, setOptionsVisible] = useState<boolean>(false);
  const optionsListRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    switch (state) {
      case 'loading':
        setButtonState('loading');
        break;
      case 'done':
        setButtonState('done');
        setTimeout(() => {
          setButtonState('default');
        }, 2000);
        break;
      case 'error':
        setButtonState('error');
        setTimeout(() => {
          setButtonState('default');
        }, 2000);
        break;
      default:
        setButtonState('default');
    }
  }, [state]);

  const handleOpenChange = (open: boolean) => {
    setOptionsVisible(open);
  };

  let optionsClasses = classnames(
    'splitButton__optionsWrapper',
    (!optionsVisible || buttonState !== 'default') && 'splitButton__hideOptionsWrapper'
  );

  let arrowSectionClasses = classnames(
    'splitButton__arrow',
    buttonState !== 'default' && 'splitButton__hideArrow',
    disabled && 'splitButton__arrow--disabled'
  );

  const [firstChild, ...otherChildren] = React.Children.toArray(children);

  return (
    <div className="splitButton__wrapper" data-th="SplitButton">
      {React.cloneElement(firstChild as React.ReactElement<any>, {
        buttonState,
        ...props
      })}

      <DropdownMenu.Root open={optionsVisible} onOpenChange={handleOpenChange}>
        <DropdownMenu.Trigger asChild>
          <button className={arrowSectionClasses} disabled={disabled} data-th="SplitButtonArrow">
            <div className="splitButton__arrowInner">
              <span className="sr-only">Open Options</span>
              <Icon name="down" />
            </div>
          </button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content side="bottom" align="end">
            <ul key="ul" className={optionsClasses} ref={optionsListRef} tabIndex={0}>
              {otherChildren}
            </ul>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
};

export default SplitButton;
