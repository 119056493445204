import { connect } from 'react-redux';
import ExportClosedLeadsPage from './ExportClosedLeadsPage';
import moment from 'moment';
import { compose } from 'redux';
import { withOutletContextProps } from 'hocs/router';

function mapStateToProps(state) {
  return {
    session: state.session,
    dealership: state.dealership
  };
}

function mapDispatchToProps(dispatch, state) {
  return {
    exportClosedLeads: (form) => {
      let from = moment(form.formData.startDate).format('DD-MM-YYYY');
      let to = moment(form.formData.endDate).format('DD-MM-YYYY');
      let exportAsGroup = form.shouldExportAsGroup ? '&group=true' : '';
      window.open(
        `/platform/v2/report/${form.formData.leadType}.csv?from=${from}&to=${to}&dealershipId=${state.dealership.Id}${exportAsGroup}`
      );
    }
  };
}

export default compose(withOutletContextProps, connect(mapStateToProps, mapDispatchToProps))(ExportClosedLeadsPage);
