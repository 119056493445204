import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import ApplicationPage from './ApplicationPage';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import BankDetailsForm from '../forms/BankDetailsForm';
import Page from '../../Common/Page';
import ConsumerVehicleInfoPanel from '../../Common/ConsumerVehicleInfoPanel';
import { withParams } from 'hocs/router';

const EditApplicationBankDetails = (props) => {
  const { t } = useTranslation('Application');
  return (
    <Page>
      <Breadcrumbs
        items={[
          {
            name: t('EditApplicationBankDetails.home'),
            path: `/d/${props.params.dealershipId}`
          },
          {
            name: t('EditApplicationBankDetails.customer_list'),
            path: `/d/${props.params.dealershipId}/consumers`
          },
          {
            name: t('EditApplicationBankDetails.consumer'),
            path: `/d/${props.params.dealershipId}/consumers/${props.params.consumerId}`
          },
          {
            name: t('EditApplicationBankDetails.application_status'),
            path: `/d/${props.params.dealershipId}/consumers/${props.params.consumerId}/application/${props.params.applicantId}/status`
          },
          {
            name: t('EditApplicationBankDetails.edit_application'),
            path: `/d/${props.params.dealershipId}/consumers/${props.params.consumerId}/application/${props.params.applicantId}/edit`
          },
          {
            name: t('EditApplicationBankDetails.edit_bank_details')
          }
        ]}
        consumer={props.consumer}
      />
      <ConsumerVehicleInfoPanel vehicle={props.application.Vehicle} />
      <Panel>
        <PanelHeader>{t('EditApplicationBankDetails.edit_application')}</PanelHeader>
        <ApplicationPage title={t('EditApplicationBankDetails.bank_details')}>
          <BankDetailsForm
            defaultValues={props.application.BankDetails}
            onSubmit={props.handleSubmit}
            onCancel={props.handleBack}
            validationRules={props.validationRules.BankDetails}
            submittingState={props.application.sectionSubmittingState}
          />
        </ApplicationPage>
      </Panel>
    </Page>
  );
};

EditApplicationBankDetails.propTypes = {
  application: PropTypes.object,
  consumer: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  validationRules: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  handleBack: PropTypes.func
};
export default withParams(EditApplicationBankDetails);
