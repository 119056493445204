import _ from 'lodash';
import { pluralise } from '../../core/helpers';

function getPaymentScheduleLabel(payment, index, total) {
  if (index === 0 && payment.Number === 1) {
    return 'First Payment';
  } else if (index === total - 1) {
    return 'Final Payment';
  } else {
    return 'Followed By';
  }
}

function getPaymentPrefix(payment) {
  return `${payment.Number} ${pluralise(payment.Number, 'Payment', 'Payments')} of`;
}

function splitPaymentsIfNeeded(payments) {
  let paymentSchedules = [];
  let firstPayment;
  let lastPayment;

  _.each(payments, (payment, index) => {
    //eslint-disable-next-line:variable-name
    let number = payment.Number;

    if (index === 0 && payment.Number > 1) {
      firstPayment = {
        Number: 1,
        Amount: payment.Amount
      };
      number--;
    }

    if (index === payments.length - 1 && payment.Number > 1) {
      lastPayment = {
        Number: 1,
        Amount: payment.Amount
      };
      number--;
    }

    paymentSchedules.push({
      Number: number,
      Amount: payment.Amount
    });
  });

  if (firstPayment) {
    paymentSchedules.unshift(firstPayment);
  }

  if (lastPayment) {
    paymentSchedules.push(lastPayment);
  }

  return paymentSchedules;
}

function getPaymentScheduleFromQuote(quote) {
  return [
    {
      Number: 1,
      Amount: quote.FirstPayment
    },
    {
      Number: quote.FollowedBy,
      Amount: quote.FollowingPayments
    },
    {
      Number: 1,
      Amount: quote.FinalPayment
    }
  ];
}

export function parsePaymentSchedules(quote) {
  let paymentSchedules =
    quote.PaymentSchedule && quote.PaymentSchedule.length > 0
      ? splitPaymentsIfNeeded(quote.PaymentSchedule)
      : getPaymentScheduleFromQuote(quote);

  return _.map(paymentSchedules, (payment, index) => {
    return {
      Label: getPaymentScheduleLabel(payment, index, paymentSchedules.length),
      Prefix: getPaymentPrefix(payment),
      ...payment
    };
  });
}
