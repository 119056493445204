import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Common/Modal/Modal';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import TextInputWrappingInput from '../../Common/Form/TextInputWrappingInput';
import './settlementModal.scss';
import Validator from '../../../validators/Validator'; // import ToggleSwitch from '../../Common/ToggleSwitch';

import MobxForm from '../../MobxForm/MobxForm';
import Button from '../../Common/Button/Button';
import SettlementCompleteModalContent from './SettlementCompleteModalContent';
import { inject, observer } from 'mobx-react';
import IconListItem from '../../Common/IconListItem';
import _ from 'lodash';

class SettlementModal extends React.Component {
  constructor() {
    super();
    this.state = {
      surname: '',
      agreementId: '',
      vrm: '',
      shouldRequestPdf: false,
      hasSubmitted: false,
      hasConfirmedConsent: false
    };
    const validationRules = {
      surname: 'required',
      agreementId: 'required, number',
      vrm: 'required, maxlength:9, alphaNumSpace'
    };
    this.validator = new Validator();
    this.validator.setRules(validationRules);
  }

  UNSAFE_componentWillMount() {
    this.validator.validate(this.state);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (!_.isEqual(this.state, nextState)) {
      this.validator.validate(nextState);
    }

    if (!this.props.isOpen && nextProps.isOpen) {
      this.setState({
        surname: '',
        agreementId: '',
        vrm: '',
        shouldRequestPdf: false,
        hasSubmitted: false,
        hasConfirmedConsent: false
      });
    }
  }

  setSurname = (surname) =>
    this.setState({
      surname
    });
  setAgreementId = (agreementId) =>
    this.setState({
      agreementId
    });
  setVrm = (vrm) =>
    this.setState({
      vrm
    });
  setShouldRequestPdf = (checked) =>
    this.setState({
      shouldRequestPdf: checked
    });
  onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!this.validator.errorCount) {
      this.setState({
        hasSubmitted: true
      });
    } else {
      this.validator.validate(this.state);
    }
  };
  confirmConsent = () => {
    this.setState({
      hasConfirmedConsent: true
    });
    this.props.appStore.quotingStore
      .getSettlementFigure(this.state, this.props.dealershipId, this.props.customerId)
      .then(() => {
        if (this.props.appStore.quotingStore.settlementRequest.isSuccessful) {
          this.props.setSettlementFigure(this.props.appStore.quotingStore.settlementFigure, this.state.agreementId);
        }
      });
  };
  resetForm = () => {
    this.setState({
      hasSubmitted: false,
      hasConfirmedConsent: false
    });
    this.props.appStore.quotingStore.settlementRequest.reset();
  };

  render() {
    let errors = this.validator.errors ? this.validator.errors : {};
    let quotingStore = this.props.appStore.quotingStore;
    let request = quotingStore.settlementRequest;
    return (
      <Modal
        title={this.props.t('SettlementModal.find_your_customers_settlement_figure_from_moto_novo_finance')}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
      >
        {this.state.hasSubmitted ? (
          <div>
            {!this.state.hasConfirmedConsent ? (
              <div>
                <div className="settlementModal__heading">
                  {this.props.t('SettlementModal.before_finding_the_settlement_figure_i_the_dealer_confirm_that')}
                </div>
                <div className="settlementModal__information">
                  <IconListItem icon="validation-tick" iconStyle="border">
                    {this.props.t('SettlementModal.i_have_my_customers_consent_to_retrieve_their_settlement_data')}
                  </IconListItem>

                  <IconListItem icon="validation-tick" iconStyle="border">
                    {this.props.t(
                      'SettlementModal.i_have_completed_suitable_know_your_customer_checks_to_ensure_i_am_retrieving_the_correct_persons_settlement_figure_and_for_the_right_vehicle'
                    )}
                  </IconListItem>
                </div>
                <div className="settlementModal__footer">
                  <div className="settlementModal__button">
                    <Button
                      onClick={this.props.onClose}
                      buttonStyle="cancel"
                      type="button"
                      id="declineSettlementConsent"
                    >
                      {this.props.t('SettlementModal.decline_and_close')}
                    </Button>
                  </div>
                  <div className="settlementModal__button">
                    <Button type="button" onClick={this.confirmConsent} id="agreeSettlementConsent">
                      {this.props.t('SettlementModal.agree')}
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <SettlementCompleteModalContent
                isLoading={request.isLoading}
                isSuccessful={request.isSuccessful}
                onClose={this.props.onClose}
                settlementFigure={quotingStore.settlementFigure}
                statusCode={quotingStore.statusCode}
                timeout={quotingStore.timeout}
                expiry={quotingStore.expiry}
                resubmit={this.resetForm}
                hasError={quotingStore.hasError}
              />
            )}
          </div>
        ) : (
          <div>
            <div className="settlementModal__heading">
              {this.props.t(
                'SettlementModal.in_order_to_find_the_correct_settlement_figure_we_need_to_take_a_few_details'
              )}
            </div>

            <MobxForm onSubmit={this.onSubmit}>
              <div className="settlementModal">
                <MobxFieldGroup error={errors.surname} htmlFor="Customer Surname">
                  <MobxFormLabel>{this.props.t('SettlementModal.customer_surname')}</MobxFormLabel>
                  <TextInputWrappingInput value={this.state.surname} onChange={this.setSurname} id="surname" />
                </MobxFieldGroup>

                <MobxFieldGroup error={errors.agreementId} htmlFor="AgreementId">
                  <MobxFormLabel>{this.props.t('SettlementModal.moto_novo_agreement_id')}</MobxFormLabel>
                  <TextInputWrappingInput
                    value={this.state.agreementId}
                    onChange={this.setAgreementId}
                    id="agreementId"
                  />
                  <div className="settlementModal__explanation">
                    {this.props.t('SettlementModal.the_id_of_the_finance_agreement_that_is_outstanding')}
                  </div>
                </MobxFieldGroup>

                <MobxFieldGroup error={errors.vrm} htmlFor="VRM">
                  <MobxFormLabel>{this.props.t('SettlementModal.vrm')}</MobxFormLabel>
                  <TextInputWrappingInput value={this.state.vrm} onChange={this.setVrm} id="vrm" />
                  <div className="settlementModal__explanation">
                    {this.props.t('SettlementModal.registration_mark_of_the_vehicle_with_outstanding_finance')}
                  </div>
                </MobxFieldGroup>

                {/*<MobxFieldGroup htmlFor="Do you want a PDF copy of the settlement agreement?">
                 <MobxFormLabel>Do you want a PDF copy of the settlement letter?</MobxFormLabel>
                 <div className="settlementModal__toggleSwitch">
                     <ToggleSwitch isActive={this.state.shouldRequestPdf} handleUpdate={this.setShouldRequestPdf} id="PdfToggle"/>
                 </div>
              </MobxFieldGroup>*/}
              </div>

              <div className="settlementModal__footer">
                <div className="settlementModal__button">
                  <Button onClick={this.props.onClose} buttonStyle="cancel" type="button" id="cancelSettlementLookup">
                    {this.props.t('SettlementModal.cancel')}
                  </Button>
                </div>
                <div className="settlementModal__button">
                  <Button type="submit" id="submitSettlementLookup">
                    {this.props.t('SettlementModal.lookup_settlement_figure')}
                  </Button>
                </div>
              </div>
            </MobxForm>
          </div>
        )}
      </Modal>
    );
  }
}

SettlementModal.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  setSettlementFigure: PropTypes.func,
  appStore: PropTypes.object,
  dealershipId: PropTypes.string,
  customerId: PropTypes.string
};
export default withTranslation('Quoting')(inject(['appStore'])(observer(SettlementModal)));
