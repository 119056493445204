import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from './vehicleNumber.module.scss';

const VehicleNumber = ({ value = '-' }) => {
  const { t } = useTranslation('Stock');

  return (
    <div className={classes.wrapper} data-th="VehicleNumber">
      <span className={classes.label}>{t('Common.vehicle_number')}:</span>
      <span className={classes.value}>{value}</span>
    </div>
  );
};

VehicleNumber.propTypes = {
  value: PropTypes.string
};

export default VehicleNumber;
