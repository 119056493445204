import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import PanelToolBar from '../../Common/PanelToolBar';
import AssignLeadContainer from '../../AssignLead/containers/AssignLeadContainer';
import Layout from './ConsumerRecordItems/Layout';
import CorporateCustomer from './ConsumerRecordItems/CorporateCustomer';
import Address from './ConsumerRecordItems/Address';
import DealershipDistance from './ConsumerRecordItems/DealershipDistance';
import AlignRight from '../../Common/AlignRight';
import { compose } from 'redux';
import { withNavigate, withParams } from 'hocs/router';

class CorporateRecordPanel extends React.Component {
  static propTypes = {
    consumer: PropTypes.object,
    params: PropTypes.object,
    handleDealershipDistanceModal: PropTypes.func
  };
  handleEditCustomer = () => {
    const { dealershipId, consumerId } = this.props.params;
    this.props.navigate(`/d/${dealershipId}/consumers/${consumerId}/edit`);
  };

  render() {
    const consumer = this.props.consumer;
    return (
      <Panel>
        <PanelHeader>{this.props.t('CorporateRecordPanel.customer_information')}</PanelHeader>
        <PanelToolBar>
          <AlignRight>
            <AssignLeadContainer
              dealershipId={this.props.params.dealershipId}
              customerId={consumer.Id}
              customerName={consumer.TradingName}
              customerType="corporate"
              initialAssignees={consumer.assignedTo}
            />
          </AlignRight>
        </PanelToolBar>
        <PanelContent>
          <Layout>
            <CorporateCustomer consumer={consumer} handleEditCustomer={this.handleEditCustomer} />
            <Address address={consumer.Address} />
            {consumer.DistanceToDealership && (
              <DealershipDistance
                distance={consumer.DistanceToDealership}
                handleDealershipDistanceModal={this.props.handleDealershipDistanceModal}
              />
            )}
          </Layout>
        </PanelContent>
      </Panel>
    );
  }
}

export default compose(withParams, withNavigate, withTranslation('Customer'))(CorporateRecordPanel);
