import React from 'react';
import PropTypes from 'prop-types';
import Page from '../../Common/Page';
import LenderUpdates from './LenderUpdates';
import Breadcrumbs from '../../Common/Breadcrumbs';
import DashboardLeadsFilter from '../../Dashboard/components/DashboardLeadsFilter';

const LenderUpdatesPage = (props) => (
  <Page>
    <Breadcrumbs
      items={[
        {
          name: 'Home',
          path: `/d/${props.dealershipId}`
        },
        {
          name: 'Lender Updates'
        }
      ]}
    />
    <div key="cont">
      <DashboardLeadsFilter onClick={props.filterLenderUpdatesLeads} filter={props.activeFilter} />
      <LenderUpdates
        items={props.items}
        facets={props.facets}
        options={props.options}
        onFilter={props.filterByStatus}
        filter={props.status}
        dealershipId={props.dealershipId}
        currentPage={props.page}
        totalItems={props.total}
        pageSize={props.pageSize}
        changePage={props.changePage}
      />
    </div>
  </Page>
);

LenderUpdatesPage.propTypes = {
  lenderUpdates: PropTypes.object,
  session: PropTypes.object.isRequired,
  dashboard: PropTypes.object,
  dispatch: PropTypes.func,
  options: PropTypes.object,
  fetchLenderUpdates: PropTypes.func,
  filterLenderUpdates: PropTypes.func,
  filterByStatus: PropTypes.func,
  filterLenderUpdatesLeads: PropTypes.func,
  changePage: PropTypes.func,
  items: PropTypes.array,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dealershipId: PropTypes.string,
  activeFilter: PropTypes.string,
  facets: PropTypes.array,
  status: PropTypes.string,
  total: PropTypes.number,
  pageSize: PropTypes.number
};

export default LenderUpdatesPage;
