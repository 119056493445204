import { withTranslation } from 'react-i18next';
import React from 'react';
import MoneyFormat from '../Common/MoneyFormat';
import './pushDealViewOffer.scss';

const PushDealViewOffer = (props) => {
  const { fullPrice, offerPrice, saving } = props;
  return (
    <div className="pushDealViewOffer__container">
      <div className="pushDealViewOffer__section">
        <p>{props.t('PushDealViewOffer.vehicle_price_was')}</p>
        <p className="pushDealViewOffer__strikeThroughValue" data-th="fullPriceText">
          {<MoneyFormat value={fullPrice} />}
        </p>
      </div>
      <div className="pushDealViewOffer__sectionDivide" />
      <div className="pushDealViewOffer__section">
        <p>{props.t('PushDealViewOffer.your_price')}</p>
        <p className="pushDealViewOffer__highlightedValue" data-th="offerPriceText">
          {<MoneyFormat value={offerPrice} />}
        </p>
      </div>
      <div className="pushDealViewOffer__sectionDivide" />
      <div className="pushDealViewOffer__section">
        <p>{props.t('PushDealViewOffer.you_save')}</p>
        <p className="pushDealViewOffer__highlightedValue" data-th="savingText">
          {<MoneyFormat value={saving} />}
        </p>
      </div>
    </div>
  );
};

export default withTranslation('PushDeal')(PushDealViewOffer);
