import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MoneyFormat from '../../Common/MoneyFormat';
import FunderLogo from '../../Common/FunderLogo';
import FormatAPR from '~/components/Quoting/components/FormatAPR';
import './popularProducts.scss';

const PopularProduct = ({ data }) => {
  const { t } = useTranslation('DigitalReport');
  return (
    <div className="popularProduct__outer">
      <table className="digitalReport__table">
        <tbody>
          <tr className="popularProduct__tr">
            <th className="digitalReport__th">{t('PopularProduct.your_most_popular_lender')}</th>
            <td className="digitalReport__td">
              {data && data.Lender ? <FunderLogo funder={data.Lender} /> : t('PopularProduct.na')}
            </td>
          </tr>
          <tr className="popularProduct__tr">
            <th className="digitalReport__th">{t('PopularProduct.your_most_popular_product')}</th>
            <td className="digitalReport__td">{data && data.Product ? data.Product : t('PopularProduct.na')}</td>
          </tr>
          <tr className="popularProduct__tr">
            <th className="digitalReport__th">{t('PopularProduct.your_most_popular_term')}</th>
            <td className="digitalReport__td">
              {data && data.Term ? data.Term + ' ' + t('PopularProduct.months') : t('PopularProduct.na')}
            </td>
          </tr>
          <tr className="popularProduct__tr">
            <th className="digitalReport__th">{t('PopularProduct.your_most_popular_mileage')}</th>
            <td className="digitalReport__td">
              {data && data.Mileage ? data.Mileage + ' ' + t('PopularProduct.miles') : t('PopularProduct.na')}
            </td>
          </tr>
          <tr className="popularProduct__tr">
            <th className="digitalReport__th">{t('PopularProduct.your_most_popular_deposit')}</th>
            <td className="digitalReport__td">
              {data && data.Deposit ? <MoneyFormat value={data.Deposit} /> : t('PopularProduct.na')}
            </td>
          </tr>
          <tr className="popularProduct__tr">
            <th className="digitalReport__th">{t('PopularProduct.your_most_popular_apr')}</th>
            <td className="digitalReport__td">
              {data && data.Apr ? <FormatAPR value={data.Apr} /> : t('PopularProduct.na')}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

PopularProduct.propTypes = {
  data: PropTypes.object
};
export default PopularProduct;
