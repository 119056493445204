import { useTranslation } from 'react-i18next';
import React from 'react';

const QuoteCommitmentMessage = () => {
  const { t } = useTranslation('Quoting');
  return (
    <span>
      {t(
        'QuoteCommitmentMessage.this_is_not_a_commitment_to_lend_and_applications_are_subject_to_approval_this_indicative_quotation_is_subject_to_change_and_final_figures_will_be_provided_on_approval_and_prior_to_signing_the_agreement'
      )}
    </span>
  );
};

export default QuoteCommitmentMessage;
