import React from 'react';
import PropTypes from 'prop-types';
import ManagedInput from '../Common/Form/ManagedInput';
import Icon from '../Common/Icon/Icon';
import './searchBar.scss';

const SearchBar = ({ id, value, onChange, placeholder, dataThook }) => {
  return (
    <div className="searchBar__container" data-th="SearchBar">
      <Icon name="search" />
      <ManagedInput
        dataThook={dataThook}
        id={id}
        className="searchBar__input"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

SearchBar.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};

export default SearchBar;
