import { takeEveryByComponentName } from '../helpers';
import { put, call } from 'redux-saga/effects';
import * as api from '../../api/customer';
import { componentMount, componentError } from '../component/componentActions';
import * as actionTypes from '../actionTypes';
import { logError } from '../../core/helpers';

export function* watchCustomerContainer() {
  yield* takeEveryByComponentName(actionTypes.COMPONENT_LOAD, 'CustomerContainer', handleLoadCustomerContainer);
}

export function* fetchCustomer(customerId, dealershipId) {
  let response;

  yield put({ type: actionTypes.FETCH_CONSUMER });

  try {
    response = yield call(api.fetchCustomer, customerId, dealershipId);
  } catch (e) {
    logError(e);
    yield put(componentError('CustomerContainer', 'Customer not found.', e));
    return;
  }

  yield put({ type: actionTypes.FETCH_CONSUMER_SUCCESS, response });

  return response;
}

export function* handleLoadCustomerContainer(action) {
  const response = yield call(fetchCustomer, action.params.consumerId, action.params.dealershipId);

  if (!response) {
    return;
  }

  const customerId = response.result;
  const customer = response.entities.customers[customerId];

  yield put({ type: actionTypes.INIT_CUSTOMER_PAGE, query: action.query, customer });

  yield put(componentMount('CustomerContainer'));
}
