import React from 'react';
import s from './metricCardSkeleton.module.scss';
import cn from 'classnames';

//not currently in use, leaving in case anything changes
const MetricCardSkeleton = () => {
  return (
    <div className={cn(s['card'])}>
      <div className={s['metric']}>
        <span className={s['statTitle']} />
        <div className={cn(s['icon'], s['metricIcon'])}></div>
      </div>
      <div className={s['bigValue']} />
      <div className={s['percentageChange']} />
    </div>
  );
};

export default MetricCardSkeleton;
