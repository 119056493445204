import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import { useNavigate } from 'hooks/useNavigate';
import useQueryParams from '../hooks/useQueryParams';

export const withLocation = (Component) => {
  return (props) => <Component {...props} location={useLocation()} />;
};

export const withNavigate = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

export const withParams = (Component) => {
  return (props) => <Component {...props} params={useParams()} />;
};

export const withQuery = (Component) => {
  return (props) => <Component {...props} query={useQueryParams()[0]} />;
};

export const withOutletContext = (Component) => {
  return (props) => <Component {...props} outletContext={useOutletContext()} />;
};

export const withOutletContextProps = (Component) => {
  return (props) => <Component {...useOutletContext()} {...props} />;
};

export const withRouter = (Component) => {
  return (props) => {
    const outletContext = useOutletContext();
    return (
      <Component
        params={useParams()}
        location={useLocation()}
        navigate={useNavigate()}
        query={useQueryParams()[0]}
        outletContext={outletContext}
        {...outletContext}
        {...props}
      />
    );
  };
};
