import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../Common/Icon/Icon';
import './input.scss';

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
  }

  onMouseEnter = () => {
    this.setState({ hover: true });
  };

  onMouseLeave = () => {
    this.setState({ hover: false });
  };

  render() {
    let {
      id,
      value,
      disabled,
      required,
      defaultValue,
      isInvalid,
      yearsMonthsInput,
      type,
      size,
      prefixIcon,
      suffix,
      suffixIcon,
      onChange,
      onBlur,
      onFocus,
      placeholder,
      hasError,
      onKeyDown,
      pattern,
      blueIcon,
      inputStyle,
      handleModal,
      autoComplete = 'off'
    } = this.props;

    const inputStyles = classNames(
      'input',
      `input--${size}`,
      isInvalid && !disabled && 'is-invalid',
      hasError && 'input--hasError',
      prefixIcon && 'input--hasPrefix',
      suffixIcon && 'input--hasSuffix',
      disabled && 'is-disabled',
      inputStyle && `input--${inputStyle}`
    );

    const prefixStyles = classNames(
      'input__prefixIcon',
      `input__prefixIcon--${size}`,
      blueIcon && 'input__iconBlue',
      this.state.hover && 'input__iconBlue'
    );

    const suffixStyles = classNames(
      'input__suffix',
      isInvalid && !disabled && !yearsMonthsInput && 'input__suffixInvalid'
    );

    const suffixIconStyles = classNames(
      'input__suffixIcon',
      `input__suffixIcon--${size}`,
      blueIcon && 'input__iconBlue',
      this.state.hover && 'input__iconBlue'
    );

    const dataAttrs = {};

    if (isInvalid) {
      dataAttrs['data-is-invalid'] = true;
    }

    return (
      <div className="input__outer" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        {prefixIcon && (
          <div className={prefixStyles}>
            <Icon name={prefixIcon} />
          </div>
        )}
        <input
          data-th={`${id}Input`}
          data-testid={`${id}Input`}
          ref={this.props.inputRef}
          className={inputStyles}
          type={type === 'currency' ? 'tel' : type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onWheel={(e) => e.target.blur()}
          onFocus={onFocus}
          id={id}
          autoComplete={autoComplete}
          disabled={disabled}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          pattern={pattern}
          maxLength={this.props.maxLength}
          {...dataAttrs}
        />
        {suffix && <div className={suffixStyles}>{suffix}</div>}
        {suffixIcon && (
          <div className={suffixIconStyles} onClick={handleModal}>
            <Icon name={suffixIcon} />
          </div>
        )}
      </div>
    );
  }
}

Input.defaultProps = {
  maxLength: 100
};

Input.propTypes = {
  prefixIcon: PropTypes.string,
  suffixIcon: PropTypes.string,
  suffix: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  placeholder: PropTypes.string,
  hasError: PropTypes.string,
  onKeyDown: PropTypes.func,
  pattern: PropTypes.string,
  blueIcon: PropTypes.bool,
  inputStyle: PropTypes.string,
  inputRef: PropTypes.any,
  maxLength: PropTypes.number,
  handleModal: PropTypes.func,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.string
};

export default Input;
