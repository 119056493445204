import React from 'react';
import PropTypes from 'prop-types';
import './applicationSubHeader.scss';

const ApplicationSubHeader = ({ text }) => <div className="applicationSubHeader__outer">{text}</div>;

ApplicationSubHeader.propTypes = {
  text: PropTypes.string
};

export default ApplicationSubHeader;
