import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import ApplicationSummaryPanelHeader from '../../Application/components/ApplicationSummaryPanelHeader';
import MiniPanel from '../../Common/MiniPanel';
import Table from '../../Reporting/PartialSummarySections/SummaryTable/Table';
import TableHead from '../../Reporting/PartialSummarySections/SummaryTable/TableHead';
import TableRow from '../../Reporting/PartialSummarySections/SummaryTable/TableRow';
import TableCell from '../../Reporting/PartialSummarySections/SummaryTable/TableCell';
import OptionsValue from '../../Common/OptionsValue';
import WordBreak from '../../Common/WordBreak';
import './cfcPersonalDetailsSummaryTable.scss';

const CFCPersonalDetailsSummaryTable = ({ section, personal, showValidationTicks, errorCount }) => {
  const { t } = useTranslation('Quoting');
  return (
    <MiniPanel>
      <ApplicationSummaryPanelHeader
        title={t('CFCPersonalDetailsSummaryTable.personal_details')}
        section={section}
        showValidationTicks={showValidationTicks}
        errorCount={errorCount}
      />

      <div data-th="cfcPersonalDetailsSummaryTable" className="cfcPersonalDetailsSummaryTable__panel">
        <Table>
          <TableRow>
            <TableHead width="50%">{t('CFCPersonalDetailsSummaryTable.name')}</TableHead>
            <TableCell>
              <WordBreak>
                {personal.Title} {personal.FirstName} {personal.LastName}
              </WordBreak>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableHead>{t('CFCPersonalDetailsSummaryTable.telephone')}</TableHead>
            <TableCell>{personal.Telephone ? personal.Telephone : t('CFCPersonalDetailsSummaryTable.n_a')}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead>{t('CFCPersonalDetailsSummaryTable.date_of_birth')}</TableHead>
            <TableCell>{personal.DateOfBirth}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead>{t('CFCPersonalDetailsSummaryTable.marital_status')}</TableHead>
            <TableCell>
              <OptionsValue type="MaritalStatus" value={personal.MaritalStatus} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableHead>{t('CFCPersonalDetailsSummaryTable.no_of_dependants')}</TableHead>
            <TableCell>{personal.NoOfDependants}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead>{t('CFCPersonalDetailsSummaryTable.driving_licence')}</TableHead>
            <TableCell>
              <OptionsValue type="DrivingLicenseType" value={personal.DrivingLicence} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableHead>{t('CFCPersonalDetailsSummaryTable.email')}</TableHead>
            <TableCell>{personal.Email}</TableCell>
          </TableRow>
        </Table>
      </div>
    </MiniPanel>
  );
};

CFCPersonalDetailsSummaryTable.defaultProps = {
  personal: {}
};
CFCPersonalDetailsSummaryTable.propTypes = {
  personal: PropTypes.object,
  section: PropTypes.object,
  showValidationTicks: PropTypes.bool
};
export default CFCPersonalDetailsSummaryTable;
