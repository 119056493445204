import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './businessDetailsPanel.scss';
import Table from './SummaryTable/Table';
import TableRow from './SummaryTable/TableRow';
import OuterLeft from './SummaryTable/OuterLeft';
import OuterRight from './SummaryTable/OuterRight';
import TableHead from './SummaryTable/TableHead';
import TableCell from './SummaryTable/TableCell';
import WordBreak from '../../Common/WordBreak';
import { splitCamelCaseToString } from '../../../core/helpers';

const BusinessDetailsPanel = ({ details }) => {
  const { t } = useTranslation('Reporting');
  return (
    <div className="businessDetailsPanel">
      <OuterLeft>
        <Table>
          <TableRow>
            <TableHead width="50%">{t('BusinessDetailsPanel.trading_name')}</TableHead>
            <TableCell>{details.TradingName}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('BusinessDetailsPanel.registered_name')}</TableHead>
            <TableCell>{details.RegisteredName}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('BusinessDetailsPanel.company_type')}</TableHead>
            <TableCell>{splitCamelCaseToString(details.CompanyType)}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('BusinessDetailsPanel.company_registration_number')}</TableHead>
            <TableCell>{details.CompanyRegistrationNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('BusinessDetailsPanel.company_sector')}</TableHead>
            <TableCell>{splitCamelCaseToString(details.CompanySector)}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('BusinessDetailsPanel.date_established')}</TableHead>
            <TableCell>{details.DateEstablished}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('BusinessDetailsPanel.vat_number')}</TableHead>
            <TableCell>{details.VatNumber}</TableCell>
          </TableRow>
        </Table>
      </OuterLeft>
      <OuterRight>
        <Table>
          <TableRow>
            <TableHead width="50%">{t('BusinessDetailsPanel.number_of_directors_partners')}</TableHead>
            <TableCell>{details.NumberOfDirectorsOrPartners}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('BusinessDetailsPanel.number_of_employees')}</TableHead>
            <TableCell>{details.NumberOfEmployees}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('BusinessDetailsPanel.email')}</TableHead>
            <TableCell>
              <WordBreak>{details.EmailAddress}</WordBreak>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('BusinessDetailsPanel.business_telephone')}</TableHead>
            <TableCell>{details.Landline}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('BusinessDetailsPanel.mobile_telephone')}</TableHead>
            <TableCell>{details.Mobile}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('BusinessDetailsPanel.fax_number')}</TableHead>
            <TableCell>{details.FaxNumber}</TableCell>
          </TableRow>
        </Table>
      </OuterRight>
    </div>
  );
};

BusinessDetailsPanel.propTypes = {
  details: PropTypes.object
};
export default BusinessDetailsPanel;
