import { action, computed, observable } from 'mobx';
import { round, valueToFloat } from './helpers';
import { getCountryVatPercentage } from './vatPercentage';
import { canSeeCashback } from '~/features';
export class QuoteRequest {
  @observable CountryCode = undefined;
  @observable isBnpp = undefined;
  @observable Advance = undefined;
  @observable ActualCashDeposit = undefined;
  @observable VehiclePrice = undefined;
  @observable BasicPrice = undefined;
  @observable Cashback = undefined;
  @observable CashDeposit = undefined;
  @observable ResidualValue = undefined;
  @observable PartExchange = undefined;
  @observable OutstandingSettlement = undefined;
  @observable SettlementSource = undefined;
  @observable Term = undefined;
  @observable AnnualDistance = undefined;
  @observable VatQualifying = undefined;
  @observable VatAmount = undefined;
  @observable VatPercent = undefined;
  @observable VatAddedToDeposit = undefined;
  @observable Insurance = undefined;
  @observable Warranty = undefined;
  @observable Other = undefined;
  @observable NonVatableItems = undefined;
  @observable ValueAddedProducts = [];
  @observable LenderVaps = [];
  @observable VehicleClass = undefined;
  @observable CustomerCreditScore = undefined;
  @observable AvailableCashback = 0;
  @observable DiscountAmount = 0;

  constructor(options = {}) {
    const valueAddedProducts = (options.ValueAddedProducts || []).map((vap) => ({
      ...vap
    }));

    this.CountryCode = options.CountryCode || 'en';
    this.isBnpp = options.isBnpp;
    this.Advance = options.Advance;
    this.ActualCashDeposit = options.ActualCashDeposit;
    this.VehiclePrice = options.VehiclePrice;
    this.BasicPrice = options.BasicPrice;
    this.CashDeposit = options.CashDeposit;
    this.Cashback = options.Cashback || 0;
    this.ResidualValue = options.ResidualValue ?? 0;
    this.PartExchange = options.PartExchange;
    this.OutstandingSettlement = options.OutstandingSettlement || 0;
    this.SettlementSource = options.SettlementSource;
    this.Term = options.Term;
    this.AnnualDistance = options.AnnualDistance;
    this.VatQualifying = options.VatQualifying;
    this.VatAmount = options.VatAmount;
    this.VatPercent = options.VatQualifying ? getCountryVatPercentage(this.CountryCode) : 0;
    this.VatAddedToDeposit = options.VatAddedToDeposit;
    this.Insurance = options.Insurance;
    this.Warranty = options.Warranty;
    this.Other = options.Other;
    this.NonVatableItems = options.NonVatableItems;
    this.ValueAddedProducts = valueAddedProducts;
    this.LenderVaps = options.LenderVaps || [];
    this.VehicleClass = options.VehicleClass;
    this.CustomerCreditScore = options.CustomerCreditScore;
    this.DiscountAmount = options.DiscountAmount || 0;

    this.updateAccessories();
    this.updateBalanceToChange();
    this.updateNetDeposit();
    this.updateTotalPrice();
  }

  @computed
  get valueAddedProductsTotal() {
    const value = this.ValueAddedProducts.map((vap) => vap.Price).reduce(
      (accumulator, current) => accumulator + parseFloat(current),
      0
    );

    return typeof value === 'number' && !isNaN(value) ? value.toFixed(2) : null;
  }

  @action
  set(fieldName, value) {
    this[fieldName] = value;

    const VatAmount = valueToFloat(this.VatAmount);
    const CashDeposit = valueToFloat(this.CashDeposit);

    switch (fieldName) {
      case 'VehiclePrice':
        this.updateBasicPrice();
        this.updateDepositIfVatAdded();
        this.updateBalanceToChange();
        break;

      case 'BasicPrice':
        this.updateVatAmount();
        this.updateDepositIfVatAdded();
        this.updateVehiclePrice();
        this.updateBalanceToChange();
        break;

      case 'NonVatableItems':
        this.updateTotalPrice();
        this.updateBalanceToChange();
        break;

      case 'VatPercent':
        this.updateBasicPrice();
        this.updateVatAmount();
        this.updateDepositIfVatAdded();
        this.updateCashback();
        break;

      case 'VatAmount':
        this.updateBasicPriceFromVatAmount();
        this.updateVatPercent();
        this.updateDepositIfVatAdded();
        this.updateCashback();
        break;

      case 'CashDeposit':
        if (CashDeposit < VatAmount && this.VehicleClass !== 'lcv') {
          this.VatAddedToDeposit = false;
        }
        this.updateBalanceToChange();
        this.updateNetDeposit();
        break;

      case 'VatAddedToDeposit':
        if (this.VatAddedToDeposit) {
          this.updateDepositIfVatAdded();
        } else if (this.VehicleClass !== 'lcv') {
          this.CashDeposit = null;
        }
        this.updateBalanceToChange();
        this.updateNetDeposit();
        this.updateCashback();
        break;

      case 'Cashback':
        this.updateBalanceToChange();
        this.updateNetDeposit();
        this.updateCashback();
        break;

      case 'PartExchange':
      case 'OutstandingSettlement':
        this.updateBalanceToChange();
        this.updateNetDeposit();
        this.updateCashback();
        break;

      case 'Insurance':
      case 'Warranty':
      case 'Other':
        this.updateAccessories();
        this.updateBalanceToChange();
        break;

      case 'VatQualifying':
        this.VatPercent = value ? 20 : 0;
        this.updateVatAmount();
        this.updateBasicPrice();
        break;

      case 'ResidualValue':
        this.updateResidualValue();
        break;

      default:
        break;
    }
    this.updateBalanceToChange();
    this.updateTotalPrice();
  }

  get(value) {
    if (this[value]) {
      return this[value];
    }
  }

  toObject() {
    return {
      isBnpp: this.isBnpp,
      CountryCode: this.CountryCode,
      VehiclePrice: this.VehiclePrice,
      ActualCashDeposit: this.ActualCashDeposit,
      Advance: this.Advance,
      BasicPrice: this.BasicPrice, // isLcv || VatQualifying
      TotalPrice: this.TotalPrice,
      ResidualValue: this.ResidualValue,
      Cashback: this.Cashback,
      CashDeposit: this.CashDeposit,
      PartExchange: this.PartExchange,
      OutstandingSettlement: this.OutstandingSettlement,
      SettlementSource: this.SettlementSource,
      Term: this.Term,
      AnnualDistance: this.AnnualDistance,
      VatAmount: this.VatAmount,
      VatQualifying: this.VatQualifying,
      VatPercent: this.VatPercent,
      VatAddedToDeposit: this.VatAddedToDeposit,
      Accessories: this.Accessories,
      Insurance: this.Insurance,
      Warranty: this.Warranty,
      Other: this.Other,
      NonVatableItems: this.NonVatableItems,
      BalanceToChange: this.BalanceToChange,
      NetDeposit: this.NetDeposit,
      ValueAddedProducts: this.ValueAddedProducts.map((vap) => ({ ...vap })),
      LenderVaps: this.LenderVaps?.map((vap) => ({ ...vap })),
      CustomerCreditScore: this.CustomerCreditScore
    };
  }

  @action
  addVap = (vap) => {
    this.ValueAddedProducts.push({
      ...vap
    });

    this.updateBalanceToChange();
    this.updateTotalPrice();
  };

  @action
  removeVap = (indexToRemove) => {
    this.ValueAddedProducts = this.ValueAddedProducts.filter((v, index) => index !== indexToRemove);

    this.updateBalanceToChange();
    this.updateTotalPrice();
  };

  @action
  changeVapPrice = (index, newPrice) => {
    this.ValueAddedProducts[index].Price = newPrice;
    this.updateBalanceToChange();
    this.updateTotalPrice();
  };

  @action
  changeVap = (index, newVap) => {
    this.ValueAddedProducts[index] = {
      ...newVap
    };

    this.updateBalanceToChange();
    this.updateTotalPrice();
  };

  @action
  updateVehiclePrice() {
    const BasicPrice = valueToFloat(this.BasicPrice);
    const VatAmount = valueToFloat(this.VatAmount);
    this.VehiclePrice = round(BasicPrice + VatAmount);
    this.updateTotalPrice();
  }

  @action
  updateBasicPrice() {
    // VehiclePrice minus calculated VAT
    const VehiclePrice = valueToFloat(this.VehiclePrice);
    const VatPercent = valueToFloat(this.VatPercent);
    const VatDivisor = 1 + VatPercent / 100;

    if (this.VatPercent) {
      this.BasicPrice = round(VehiclePrice / VatDivisor);
      this.VatAmount = round(VehiclePrice - this.BasicPrice);
    } else {
      this.BasicPrice = VehiclePrice;
    }
  }

  @action
  updateResidualValue() {
    if (this.ResidualValue !== undefined) {
      this.ResidualValue = valueToFloat(this.ResidualValue);
    }
  }

  @action
  updateCashback() {
    const PartExchange = valueToFloat(this.PartExchange);
    const OutstandingSettlement = valueToFloat(this.OutstandingSettlement);
    let Equity = valueToFloat(PartExchange - OutstandingSettlement);
    const VatAmount = valueToFloat(this.VatAmount);

    // Reset AvailableCashback to 0 if canSeeCashback feature flag is false,
    // hasNegativeEquity is true, or there is no PartExchange
    if (!canSeeCashback() || this.hasNegativeEquity || !PartExchange || Equity <= 0 || this.isBnpp) {
      this.AvailableCashback = 0;
    } else {
      if (this.VehicleClass === 'lcv' && this.VatAddedToDeposit) {
        // If Equity is less than or equal to VatAmount, set AvailableCashback and Cashback to 0
        if (Equity <= VatAmount) {
          this.AvailableCashback = 0;
          this.Cashback = 0;
        } else {
          // Deduct VatAmount from Equity and set AvailableCashback to the new Equity value
          // to ensure VatAmount is catered for before Cashback is deducted
          Equity -= VatAmount;
          this.AvailableCashback = Equity;
        }
      } else {
        // Make AvailableCashback match Equity if !VatAddedToDeposit is false or vehicle is not LCV
        this.AvailableCashback = Equity;
      }
    }

    // Set Cashback to the smaller of Cashback and AvailableCashback
    this.Cashback = Math.min(this.Cashback, this.AvailableCashback);
    // Subtract Cashback from AvailableCashback
    this.AvailableCashback -= this.Cashback;
  }

  @action
  updateBasicPriceFromVatAmount() {
    const VehiclePrice = valueToFloat(this.VehiclePrice);
    const VatAmount = valueToFloat(this.VatAmount);
    this.BasicPrice = VehiclePrice - VatAmount;
  }

  @action
  updateVatPercent() {
    const BasicPrice = valueToFloat(this.BasicPrice);
    const VatAmount = valueToFloat(this.VatAmount);
    this.VatPercent = round((VatAmount / BasicPrice) * 100);
  }

  @action
  updateVatAmount() {
    const BasicPrice = valueToFloat(this.BasicPrice);
    const VatPercent = valueToFloat(this.VatPercent);

    if (VatPercent && this.VatQualifying) {
      const newVatAmount = BasicPrice * (VatPercent / 100);
      this.VatAmount = round(newVatAmount);
    } else {
      this.VatAmount = 0;
    }
  }

  @action
  updateBalanceToChange() {
    /* Similar to TotalPrice (VehiclePrice + Vaps) but BalanceToChange includes 
    customer's contributions (CashDeposit, SettlementFigure etc) */
    const VehiclePrice = valueToFloat(this.VehiclePrice);
    const NonVatableItems = valueToFloat(this.NonVatableItems);
    // We don't think accessories are used anymore, they seem to be a thing before VAPs were introduced
    const Accessories = valueToFloat(this.Accessories);
    const valueAddedProducts = valueToFloat(this.valueAddedProductsTotal);
    const DiscountAmount = valueToFloat(this.DiscountAmount);

    this.BalanceToChange = round(
      VehiclePrice + (Accessories || valueAddedProducts) + NonVatableItems - this.getNetDeposit() - DiscountAmount
    );
  }

  @action
  updateNetDeposit() {
    const netDeposit = this.getNetDeposit();
    this.NetDeposit = netDeposit < 0 ? 0 : netDeposit;
  }

  getNetDeposit = () => {
    const CashDeposit = valueToFloat(this.CashDeposit);
    const PartExchange = valueToFloat(this.PartExchange);
    const OutstandingSettlement = valueToFloat(this.OutstandingSettlement);
    const Equity = valueToFloat(PartExchange - OutstandingSettlement);
    const Cashback = this.isBnpp || Equity <= 0 ? 0 : valueToFloat(this.Cashback);
    const VatAmount = valueToFloat(this.VatAmount);

    const contribution = CashDeposit + Equity;
    if (this.VehicleClass === 'lcv' && this.VatAddedToDeposit) {
      if (canSeeCashback()) {
        /*
         * Once comms have been released, this new logic will become the default way of dealing with LCVs
         * with VatAddedToDeposit. The change means VatAmount will now be deducted
         * from NetDeposit rather than added to it. Customers are then given the opportunity to make up
         * the VatAmount owed via Equity or Cash Deposit.
         * Until comms have taken place, we have to keep the old logic in place (below)
         */
        return round(contribution - VatAmount - Cashback);
      } else {
        /*
         * This is the old logic which will be removed post-comms.
         */
        return round(contribution + VatAmount);
      }
    }
    /*
     * The below calculation is for scenarios where VatAmount no longer needs to be considered
     * 1. when vehicle of any class does not have VatAddedToDeposit
     *
     * 2. when non-LCVs have VatAddedToDeposit as updateDepositIfVatAdded() logic sets
     * CashDeposit to VatAmount value and we do not want to deduct the value twice
     */
    return round(contribution - Cashback);
  };

  @computed
  get hasNegativeEquity() {
    const PartExchange = valueToFloat(this.PartExchange);
    const OutstandingSettlement = valueToFloat(this.OutstandingSettlement);
    const Equity = PartExchange - OutstandingSettlement;

    return Equity < 0;
  }

  @action
  updateDepositIfVatAdded() {
    /*
     * Sets CashDeposit to value of VatAmount for non-LCVs with VatAddedToDeposit.
     * This ensures VatAmount is covered in order to receive quotes
     * because VAT Assist products do not exist for non-LCVs.
     */
    if (this.VatAddedToDeposit && this.VehicleClass !== 'lcv') {
      this.CashDeposit = this.VatAmount;
    }
  }

  @action
  updateAccessories() {
    const Insurance = valueToFloat(this.Insurance);
    const Warranty = valueToFloat(this.Warranty);
    const Other = valueToFloat(this.Other);
    this.Accessories = round(Insurance + Warranty + Other);
    this.updateTotalPrice();
  }

  @action
  updateTotalPrice() {
    /* Similar to BalanceToChange (VehiclePrice + Vaps) but TotalPrice does not include 
  customer's contributions (CashDeposit, SettlementFigure etc) */
    const VehiclePrice = valueToFloat(this.VehiclePrice);
    const NonVatableItems = valueToFloat(this.NonVatableItems);
    const Vaps = valueToFloat(this.valueAddedProductsTotal);
    const Accessories = valueToFloat(this.Accessories);
    const DiscountAmount = valueToFloat(this.DiscountAmount);

    this.TotalPrice = round(VehiclePrice + NonVatableItems + (Vaps || Accessories) - DiscountAmount);
  }
}
