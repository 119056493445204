import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './eligibilityMatch.scss';
import { observer, inject } from 'mobx-react';

const EligibilityMatch = ({ matchrate, onClick, personalScorePercentage, forceShowViewDetails, appStore, t }) => {
  let matchText;
  let matchSelector;
  if (matchrate === -2) {
    matchText = t('EligibilityMatch.eligibility_score_expired');
    matchSelector = 'expired';
  } else if (matchrate < 0) {
    matchText = t('EligibilityMatch.unknown_eligibility');
    matchSelector = 'unknown';
  } else if (matchrate > 50) {
    matchText = t('EligibilityMatch.high_eligibility');
    matchSelector = 'high';
  } else if (matchrate === 50) {
    matchText = t('EligibilityMatch.medium_eligibility');
    matchSelector = 'medium';
  } else if (matchrate < 50) {
    matchText = t('EligibilityMatch.low_eligibility');
    matchSelector = 'low';
  }
  const personalScore = personalScorePercentage;
  return (
    <>
      {matchrate !== -1 && (forceShowViewDetails || (appStore.uiState.canUseCfcIvendiv2 && personalScore !== null)) ? (
        <div
          onClick={onClick}
          className={`eligibilityMatch eligibilityMatch__showroomPadding eligibilityMatch__${matchSelector}`}
          key="cont2"
        >
          {matchText}
        </div>
      ) : (
        <div
          className={`eligibilityMatch eligibilityMatch__showroomPadding eligibilityMatch__${matchSelector} eligibilityMatch__readonly`}
          key="cont1"
        >
          {matchText}
        </div>
      )}
    </>
  );
};
EligibilityMatch.propTypes = {
  matchrate: PropTypes.number,
  onClick: PropTypes.func,
  personalScorePercentage: PropTypes.number,
  craScorePercentage: PropTypes.number,
  appStore: PropTypes.object,
  forceShowViewDetails: PropTypes.bool
};

export default withTranslation('Common')(inject(['appStore'])(observer(EligibilityMatch)));
