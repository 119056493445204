import React from 'react';
import PropTypes from 'prop-types';
import * as debug from '../../debug';
import UnexpectedError from '../Common/UnexpectedError';
class ErrorBoundary extends React.Component {
  state = { hasError: null, error: null, errorInfo: null };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, error, errorInfo }, () => this.handleError(error, errorInfo));
  }

  handleError = (error, errorInfo) => {
    /* eslint no-console:0 */
    if (console && console.error) {
      console.error(error);
      errorInfo && errorInfo.componentStack && console.error(errorInfo.componentStack);
    }
    /* eslint:no-console:1 */

    debug.log({
      error: error.message,
      stack: error.stack,
      reason: error.reason,
      level: 'Error',
      source: 'ErrorBoundary',
      componentStack: errorInfo && errorInfo.componentStack ? errorInfo.componentStack : 'unknown',
      location: window.location.href
    });
  };

  handleReturnToHomePage = () => {
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null
    });
  };

  render() {
    const { children } = this.props;
    return this.state.hasError ? (
      <UnexpectedError handleReturnToHomePage={this.handleReturnToHomePage}>
        {process.env.NODE_ENV !== 'production' && (
          <details style={{ whiteSpace: 'pre-wrap' }}>
            Process Env !== 'production': true
            <br />
            Process Env: {process.env.NODE_ENV}
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </details>
        )}
      </UnexpectedError>
    ) : (
      children
    );
  }
}
ErrorBoundary.defaultProps = {};
ErrorBoundary.propTypes = { children: PropTypes.any };
export default ErrorBoundary;
