import appStore from '~/mobx-stores/appStore';
import { useTranslation } from 'react-i18next';
import { ConsumerVehicleQuoteType, ConsumerVehicleType } from '~/components/Common/ConsumerVehicleInfoPanel';
import { getVehicleStatsString } from '~/modules/Stock/routes/stock/routes/list/components/StockList/components/StockListItemV2/helpers/getVehicleStatsString';
import './consumerVehicleInfo.scss';
import { useNavigate } from '~/hooks/useNavigate';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import VRMPlate from '~Common/VrmPlate';
import * as quickQuoteActions from '~/redux/quickQuote/quickQuoteActions.js';
import { connect } from 'react-redux';
import { trackDetailsVehicleCardEdit } from '~/tracking/avoTracking';

type AppStoreType = typeof appStore;
type ConsumerVehicleInfoProps = {
  vehicle: ConsumerVehicleType;
  quote?: ConsumerVehicleQuoteType;
  appStore?: AppStoreType;
  dealershipId?: string;
  canEditVehicle?: boolean;
  setQuickQuoteEditingVehicle: () => void;
  userId: string;
} & typeof mapDispatchToProps;

const ConsumerVehicleInfo = ({
  vehicle,
  quote,
  canEditVehicle = false,
  dealershipId = '',
  setQuickQuoteEditingVehicle = () => {},
  userId
}: ConsumerVehicleInfoProps) => {
  const { t, i18n } = useTranslation('Stock');
  const lng = i18n.language;
  const navigate = useNavigate();

  const isFindAVehiclePage = useFeatureIsOn('rtl-findavehicle-page');

  // VRM will be the dealer reference when in DE
  const vrm = vehicle.Vrm || quote?.VehicleVrm || '';
  const rawVehicle = vehicle.RawVehicle || vehicle;
  const vin = vehicle.Vin || null;

  const onEditVehicleDetails = () => {
    // Set the current vehicle in a separate state
    setQuickQuoteEditingVehicle();
    trackDetailsVehicleCardEdit({ rawVehicle, dealershipId, userId });

    if (vehicle.VehicleId) {
      navigate({
        pathname: `/d/${dealershipId}/stock/${vehicle.VehicleId}/fix-issues`
      });
    } else {
      const derivative = vehicle.Derivative ? vehicle.Derivative : '';
      const queryString = `?vrm=${vrm}&search=${encodeURIComponent(
        `${vehicle.Make} ${vehicle.Model} ${derivative}`
      )}&vehicleType=${vehicle.Condition}&class=${vehicle.Class}`;

      navigate({
        pathname: `/d/${dealershipId}/quickquote`,
        search: queryString
      });
    }
  };

  if (isFindAVehiclePage) {
    return (
      <div className="consumerVehicleInfo">
        <div className="consumerVehicleInfo__detailsContainer">
          <div className="consumerVehicleInfo__vrm">{vrm}</div>
          <div className="consumerVehicleInfo__makeModel">
            {vehicle?.Make} {vehicle?.Model} {vehicle?.Derivative}
          </div>
          <div className="consumerVehicleInfo__details">
            {getVehicleStatsString({
              vehicle: rawVehicle,
              t,
              lng,
              enableFallbackValue: false,
              showMileage: false,
              showBadgeEngineSize: false
            })}
          </div>
        </div>
        {canEditVehicle && (
          <div className="consumerVehicleInfo__editVehicleCTA" onClick={() => onEditVehicleDetails()}>
            {t('ConsumerVehicleInfo.edit_vehicle_details')}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={'consumerVehicleInfoOld'} data-th="vehicleInfo">
        {vrm && (
          <div className="consumerVehicleInfoOld__vehicleIdentifierOld">
            <VRMPlate vrm={vrm} />
          </div>
        )}
        {vin && (
          <div className="consumerVehicleInfoOld__vehicleIdentifierOld">
            <div className="consumerVehicleInfoOld__vinOld">{vin}</div>
          </div>
        )}
        <div className={'consumerVehicleInfoOld__makeModelDerivativeOld'}>
          <span className="consumerVehicleInfoOld__makeModelOld">
            {vehicle?.Make} {vehicle?.Model}
          </span>
          <span className="consumerVehicleInfoOld__derivativeOld">{vehicle?.Derivative}</span>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state: any) => ({
  userId: state.session.UserId
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    setQuickQuoteEditingVehicle: () => dispatch(quickQuoteActions.setQuickQuoteEditingVehicle())
  };
};

export { ConsumerVehicleInfo as ConsumerVehicleInfoUnwrapped };
export default connect(mapStateToProps, mapDispatchToProps)(ConsumerVehicleInfo);
