import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import ApplicationPage from '../../Application/components/ApplicationPage';
import CFCPersonalDetailsForm from '../forms/CFCPersonalDetailsForm';

const CFCPersonalDetails = ({ allowEmailToBeChanged, defaultValues, validationRules, onSubmit }) => {
  const { t } = useTranslation('Quoting');
  return (
    <ApplicationPage title={t('CFCPersonalDetails.personal_details')}>
      <CFCPersonalDetailsForm
        allowEmailToBeChanged={allowEmailToBeChanged}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        validationRules={validationRules}
      />
    </ApplicationPage>
  );
};

CFCPersonalDetails.propTypes = {
  allowEmailToBeChanged: PropTypes.bool,
  defaultValues: PropTypes.object,
  validationRules: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};
export default CFCPersonalDetails;
