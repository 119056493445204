import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './editProductCommissionsForm.scss';
import Button from '../../Common/Button/Button';
import Validator from '../../../validators/Validator';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import SelectInput from '../../Common/Form/Select';
import CurrencyInput from '../../Common/Form/CurrencyInput';
import PercentageInput from '../../Common/Form/PercentageInput';
import { reaction } from 'mobx';

class EditProductCommissionsForm extends React.Component {
  constructor() {
    super();
    this.state = {
      formData: {
        CommissionType: '',
        Rate: ''
      }
    };
    this.setupValidation({
      CommissionType: 'required',
      Rate: 'required, number'
    });
  }

  setupValidation(validationRules) {
    this.validator = new Validator();
    this.validator.setRules(validationRules);
    this.validationReactionDisposer = reaction(() => ({ ...this.state.formData }), this.validator.validate, {
      fireImmediately: true
    });
  }

  UNSAFE_componentWillMount() {
    if (this.props.aprOnly) {
      let state = this.state;
      state.formData.CommissionType = 'APR';
      this.setState({
        state
      });
      this.validator.validate(state.formData);
    }
  }

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  onSubmit = () => {
    if (!this.validator.errorCount) {
      this.props.onSubmit(this.state.formData);
    } else {
      this.validator.validate(this.state.formData);
    }
  };
  handleCancel = (e) => {
    e.preventDefault();
    this.props.closeModal();
  };
  setCommission = (e) => {
    let formData = this.state.formData;
    formData.CommissionType = e.currentTarget.value;
    this.setState({
      formData
    });
    this.validator.validate(formData);
  };
  setRate = (Rate) => {
    let formData = this.state.formData;
    formData.Rate = Rate;
    this.setState({
      formData
    });
    this.validator.validate(formData);
  };
  setRatePercentage = (id, Rate) => {
    this.setRate(Rate);
  };

  render() {
    let showCurrency = (this.state.formData && this.state.formData.CommissionType) === 'CommissionFixed';
    let errors = this.validator.getErrors();
    return (
      <MobxForm onSubmit={this.onSubmit} FocusOnFirstElement>
        <h2 className="editProductCommissionsForm__modalHeader">
          {this.props.t('EditProductCommissionsForm.edit_selected_commissions')}
        </h2>
        <p>
          {this.props.t(
            'EditProductCommissionsForm.the_closest_available_rate_will_be_applied_to_the_selected_products_in_the_event_of_the_requested_value_being_outside_of_the_lender_product_bandings'
          )}
        </p>
        <div className="editProductCommissionsForm__divider" />

        <MobxFieldGroup isInline htmlFor="CommissionType" error={errors.CommissionType}>
          <MobxFormLabel>{this.props.t('EditProductCommissionsForm.commission_type')}</MobxFormLabel>
          <SelectInput
            options={this.props.options.CommissionType}
            value={this.state.formData.CommissionType}
            onChange={this.setCommission}
            disabled={this.props.aprOnly}
          />
        </MobxFieldGroup>

        {showCurrency ? (
          <MobxFieldGroup isInline htmlFor="Rate" error={errors.Rate}>
            <MobxFormLabel>{this.props.t('EditProductCommissionsForm.rate')}</MobxFormLabel>
            <CurrencyInput value={this.state.formData.Rate} onChange={this.setRate} />
          </MobxFieldGroup>
        ) : (
          <MobxFieldGroup isInline htmlFor="Rate" error={errors.Rate}>
            <MobxFormLabel>{this.props.t('EditProductCommissionsForm.rate')}</MobxFormLabel>
            <PercentageInput value={this.state.formData.Rate} onChange={this.setRatePercentage} />
          </MobxFieldGroup>
        )}

        <div className="editProductCommissionsForm__divider" />
        <div className="editProductCommissionsForm__formFooter">
          <div className="editProductCommissionsForm__applyButton" key="applyButton">
            <Button
              type="submit"
              onClick={this.submit}
              isLoading={this.props.submittingState === 'loading'}
              hasError={this.props.submittingState === 'error'}
              buttonStyle="secondary"
            >
              {this.props.t('EditProductCommissionsForm.apply')}
            </Button>
          </div>
          <div className="editProductCommissionsForm__cancelButton">
            <Button type="button" buttonStyle="cancel" onClick={this.handleCancel}>
              {this.props.t('EditProductCommissionsForm.cancel')}
            </Button>
          </div>
        </div>
      </MobxForm>
    );
  }
}

EditProductCommissionsForm.propTypes = {
  options: PropTypes.object,
  closeModal: PropTypes.func,
  aprOnly: PropTypes.bool
};
export default withTranslation('Quoting')(EditProductCommissionsForm);
