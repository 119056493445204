import consumerADM from './lenders/consumer/ADM';
import consumerBAR from './lenders/consumer/BAR';
import consumerBLA from './lenders/consumer/BLA';
import consumerBLO from './lenders/consumer/BLO';
import consumerBLU from './lenders/consumer/BLU';
import consumerGMA from './lenders/consumer/GMA';
import consumerMAZ from './lenders/consumer/MAZ';
import consumerMIF from './lenders/consumer/MIF';
import consumerMIV from './lenders/consumer/MIV';
import consumerMKF from './lenders/consumer/MKF';
import consumerMOE from './lenders/consumer/MOE';
import consumerMON from './lenders/consumer/MON';
import consumerMOT from './lenders/consumer/MOT';
import consumerNOR from './lenders/consumer/NOR';
import consumerSTL from './lenders/consumer/STL';
import consumerALP from './lenders/consumer/ALP';
import consumerAUF from './lenders/consumer/AUF';
import consumerDSG from './lenders/consumer/DSG';
import consumerCCF from './lenders/consumer/CCF';
import consumerFAM from './lenders/consumer/FAM';
import consumerFIR from './lenders/consumer/FIR';
import consumerHIT from './lenders/consumer/HIT';
import consumerHYN from './lenders/consumer/HYN';
import consumerEVF from './lenders/consumer/EVF';
import consumerVAU from './lenders/consumer/VAU';
import consumerCRE from './lenders/consumer/CRE';
import consumerHAR from './lenders/consumer/HAR';
import consumer1ST from './lenders/consumer/1ST';
import consumerODL from './lenders/consumer/ODL';
import consumerSAN from './lenders/consumer/SAN';
import consumerV12 from './lenders/consumer/V12';
import consumerCLO from './lenders/consumer/CLO';
import consumerSUZ from './lenders/consumer/SUZ';
import consumerVOL from './lenders/consumer/VOL';
import consumerKIA from './lenders/consumer/KIA';
import consumerMGC from './lenders/consumer/MGC';
import consumerYAM from './lenders/consumer/YAM';
import consumerSTE from './lenders/consumer/STE';
import consumerLAN from './lenders/consumer/LAN';
import consumerJAG from './lenders/consumer/JAG';
import consumerDUC from './lenders/consumer/DUC';
import consumerMIT from './lenders/consumer/MIT';
import consumerMFL from './lenders/consumer/MFL';
import consumerFIA from './lenders/consumer/FIA';

import consumerDefault from './lenders/consumer/default';

import corporateMOT from './lenders/corporate/MOT';
import corporateDSG from './lenders/corporate/DSG';
import corporateFIR from './lenders/corporate/FIR';
import corporateEVF from './lenders/corporate/EVF';
import corporateNOR from './lenders/corporate/NOR';
import corporateMFL from './lenders/corporate/MFL';

import corporateDefault from './lenders/corporate/default';

import cfcDefault from './lenders/cfc/default';

export const lenders = {
  consumer: {
    def: consumerDefault,
    '1ST': consumer1ST,
    ADM: consumerADM,
    ALP: consumerALP,
    AUF: consumerAUF,
    BAR: consumerBAR,
    BLA: consumerBLA,
    BLO: consumerBLO,
    BLU: consumerBLU,
    CCF: consumerCCF,
    CLO: consumerCLO,
    CRE: consumerCRE,
    DSG: consumerDSG,
    EVF: consumerEVF,
    FAM: consumerFAM,
    FIA: consumerFIA,
    FIR: consumerFIR,
    GMA: consumerGMA,
    HAR: consumerHAR,
    HIT: consumerHIT,
    HYN: consumerHYN,
    KIA: consumerKIA,
    MAZ: consumerMAZ,
    MGC: consumerMGC,
    MIF: consumerMIF,
    MIV: consumerMIV,
    MKF: consumerMKF,
    MOE: consumerMOE,
    MON: consumerMON,
    MOT: consumerMOT,
    NOR: consumerNOR,
    ODL: consumerODL,
    SAN: consumerSAN,
    STE: consumerSTE,
    STL: consumerSTL,
    SUZ: consumerSUZ,
    V12: consumerV12,
    VAU: consumerVAU,
    VOL: consumerVOL,
    YAM: consumerYAM,
    LAN: consumerLAN,
    JAG: consumerJAG,
    DUC: consumerDUC,
    MIT: consumerMIT,
    MFL: consumerMFL
  },
  corporate: {
    def: corporateDefault,
    DSG: corporateDSG,
    EVF: corporateEVF,
    FIR: corporateFIR,
    MOT: corporateMOT,
    NOR: corporateNOR,
    MFL: corporateMFL
  },
  cfc: {
    def: cfcDefault
  }
};
