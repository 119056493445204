import platformApiUtils from '@ivendi/platform-api-utils';
import * as actionTypes from '../actionTypes';
import { schemaMap } from '../../core/schema';
import dashboardLenderUpdatesSchema from '../../core/schemas/dashboardLenderUpdatesSchema';

export function fetchLenderUpdates({ status, activeFilter, page }) {
  return (dispatch, getState) => {
    let state = getState();
    const dealershipId = state.dealership.Id;
    dispatch({
      types: [
        actionTypes.FETCH_LENDER_UPDATES,
        actionTypes.FETCH_LENDER_UPDATES_SUCCESS,
        actionTypes.FETCH_LENDER_UPDATES_ERROR
      ],
      callAPI: () =>
        platformApiUtils.get(`v1/dashboard/lenderupdates/${dealershipId}`, {
          PageSize: 25,
          Page: page,
          AssignedTo: activeFilter === 'mine' ? [state.session.UserId] : undefined,
          IsAssigned: activeFilter === 'unassigned' ? false : undefined,
          Status: status
        }),
      parseResponse: (data) => schemaMap(data, dashboardLenderUpdatesSchema)
    });
  };
}
