import React from 'react';
import PropTypes from 'prop-types';
import './altSelectWithLabel.scss';
import Select from './Select';

const AltSelectWithLabel = (props) => (
  <div className="altSelectWithLabel">
    {props.label}
    <Select
      onChange={props.handleSort}
      value={props.value}
      options={props.options}
      theme="alt"
      emptyValue={false}
      size="small"
    />
  </div>
);

AltSelectWithLabel.propTypes = {
  label: PropTypes.string,
  handleSort: PropTypes.func,
  value: PropTypes.string,
  options: PropTypes.string
};

export default AltSelectWithLabel;
