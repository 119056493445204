import Icon from '~Common/Icon/Icon';
import React from 'react';
import cn from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import s from './RHF-ValidationIcons.module.scss';

const RHFValidationIcons = ({ name }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState }) => {
        const { invalid, isTouched, error } = fieldState;
        const isInValid = error && invalid;
        const isValid = isTouched && !error && !invalid;

        return (
          <div className={cn(s.icon, isValid && s.tick, isInValid && s.cross)}>
            {isValid ? <Icon name="validation-tick" /> : null}
            {isInValid ? <Icon name="validation-cross" /> : null}
          </div>
        );
      }}
    />
  );
};

export default RHFValidationIcons;
