import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../../Common/Modal/Modal';
import SendDealModalContents from '../components/SendDealModalContents';
import * as modalActions from '../../../redux/modal/modalActions';
import * as sendDealActions from '../../../redux/sendDeal/sendDealActions';
import { getCustomer } from '../../../selectors/customerSelectors';
import { push } from '../../../routerHistory';
import { objectToSearchParams } from 'utils/objectToSearchParams';

class SendDealModalContainer extends React.Component {
  closeModal = () => {
    this.props.closeModal();
    this.props.resetSendDeal();
  };

  render() {
    let props = this.props;
    let tryAgainHandler;
    if (props.isExistingCustomer) {
      tryAgainHandler = props.sendDealToExistingCustomerFromQuickQuote;
    } else {
      tryAgainHandler = props.isQuickQuote ? props.sendDealFromQuickQuote : props.sendDeal;
    }

    return (
      <Modal hideClose={props.currentView === 'LOADING_VIEW'} onClose={this.closeModal} isOpen={this.props.isOpen}>
        <SendDealModalContents
          quotes={props.quotes}
          onCancel={this.closeModal}
          sendDeal={props.sendDeal}
          sendDealFromQuickQuote={props.sendDealFromQuickQuote}
          sendDealToExistingCustomerFromQuickQuote={props.sendDealToExistingCustomerFromQuickQuote}
          currentView={props.currentView}
          navigateToCustomerRecord={() =>
            props.navigateToCustomerRecord(props.dealershipId, props.returnedCustomerId, props.returnedVehicleId)
          }
          isQuickQuote={props.isQuickQuote}
          onQuickQuoteProceed={props.onQuickQuoteProceed}
          existingCustomer={props.existingCustomer}
          isExistingCustomer={props.isExistingCustomer}
          onTryAgain={tryAgainHandler}
          customer={props.customer}
          customerType={props.customerType}
          dealershipId={props.dealershipId}
        />
      </Modal>
    );
  }
}

SendDealModalContainer.propTypes = {
  quotes: PropTypes.array,
  modals: PropTypes.object,
  closeModal: PropTypes.func,
  sendDeal: PropTypes.func,
  currentView: PropTypes.string,
  navigateToCustomerRecord: PropTypes.func,
  resetSendDeal: PropTypes.func,
  isQuickQuote: PropTypes.bool,
  onQuickQuoteProceed: PropTypes.func,
  sendDealFromQuickQuote: PropTypes.func,
  sendDealToExistingCustomerFromQuickQuote: PropTypes.func,
  isExistingCustomer: PropTypes.bool,
  existingCustomer: PropTypes.object,
  customer: PropTypes.object,
  dealershipId: PropTypes.string,
  returnedCustomerId: PropTypes.string,
  returnedVehicleId: PropTypes.string,
  isOpen: PropTypes.bool
};

function mapStateToProps(state) {
  const customerQuoteCustomerId = _.get(state.modals, 'sendDeal.consumerId');
  return {
    modals: state.modals,
    isQuickQuote: _.get(state.modals, 'sendDeal.isQuickQuote'),
    currentView: state.sendDeal.currentView,
    dealershipId: state.dealership.Id,
    customer: getCustomer(state, customerQuoteCustomerId),
    vehicleId: _.get(state.modals, 'sendDeal.vehicleId'),
    quotes: _.get(state.modals, 'sendDeal.quotes'),
    returnedCustomerId: state.sendDeal.returnedCustomerId,
    returnedVehicleId: state.sendDeal.returnedVehicleId,
    isOpen: state.modals.sendDeal ? state.modals.sendDeal.isOpen : false,
    customerType: _.get(state.modals, 'sendDeal.customerType')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(modalActions.close('sendDeal'));
    },
    sendDeal: () => {
      window.ga && window.ga('send', 'event', 'Quote_SendDeal', 'ButtonPress', 'DealSent_ExistingCustomer');
      dispatch(sendDealActions.sendDealToCustomer());
    },
    sendDealFromQuickQuote: (formData) => {
      window.ga && window.ga('send', 'event', 'QuickQuote_SendDeal', 'ButtonPress', 'DealSent_NewCustomer');
      dispatch(sendDealActions.sendDealFromQuickQuote(formData));
    },
    sendDealToExistingCustomerFromQuickQuote: (customerId) => {
      window.ga && window.ga('send', 'event', 'QuickQuote_SendDeal', 'ButtonPress', 'DealSent_ExistingCustomer');
      dispatch(sendDealActions.sendDealToExistingCustomerFromQuickQuote(customerId));
    },
    navigateToCustomerRecord: (dealershipId, consumerId, vehicleId) => {
      dispatch(modalActions.close('sendDeal'));
      dispatch(sendDealActions.resetSendDeal());
      push({
        pathname: `/d/${dealershipId}/consumers/${consumerId}`,
        search: `?${objectToSearchParams({
          vehicleId,
          selectedFinanceTab: 'SentDeals'
        })}`
      });
    },
    resetSendDeal: () => {
      dispatch(sendDealActions.resetSendDeal());
    },
    onQuickQuoteProceed: () => {
      window.ga && window.ga('send', 'pageview', 'send_deal CUSTOMER_DETAILS_VIEW');
      dispatch(sendDealActions.setPage('CUSTOMER_DETAILS_VIEW'));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SendDealModalContainer);
