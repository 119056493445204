import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ApplicationSummaryPanelHeader from '../../Application/components/ApplicationSummaryPanelHeader';
import MiniPanel from '../../Common/MiniPanel';
import OptionsValue from '../../Common/OptionsValue';
import Table from '../../Reporting/PartialSummarySections/SummaryTable/Table';
import TableHead from '../../Reporting/PartialSummarySections/SummaryTable/TableHead';
import TableRow from '../../Reporting/PartialSummarySections/SummaryTable/TableRow';
import TableCell from '../../Reporting/PartialSummarySections/SummaryTable/TableCell';
import './cfcEmploymentHistorySummaryTable.scss';

const CFCEmploymentHistorySummaryTable = ({ employments, section, showValidationTicks, errorCount }) => {
  const { t } = useTranslation('Quoting');
  return (
    <MiniPanel>
      <ApplicationSummaryPanelHeader
        title={t('CFCEmploymentHistorySummaryTable.employment_history')}
        section={section}
        showValidationTicks={showValidationTicks}
        errorCount={errorCount}
      />
      <div data-th="cfcEmploymentHistorySummaryTable" className="cfcEmploymentHistorySummaryTable__panel">
        {_.map(employments, (employment, index) => {
          return (
            <Table key={`employmentHistory${index}`}>
              <TableRow>
                <TableHead width="25%">
                  <strong>
                    {t('CFCEmploymentHistorySummaryTable.employment')} {index + 1}
                  </strong>
                </TableHead>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableHead>{t('CFCEmploymentHistorySummaryTable.basis')}</TableHead>
                <TableCell>
                  <OptionsValue type="EmploymentBasis" value={employment.OccupationBasis} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableHead>{t('CFCEmploymentHistorySummaryTable.occupation_type')}</TableHead>
                <TableCell>
                  <OptionsValue type="OccupationType" value={employment.OccupationType} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableHead>{t('CFCEmploymentHistorySummaryTable.time_in_employment')}</TableHead>
                <TableCell>
                  <span className="cfcEmploymentHistorySummaryTable__tableValue">
                    {t('CFCEmploymentHistorySummaryTable.year', {
                      count: employment.TimeAtEmployment.Years || 0
                    })}{' '}
                    {t('CFCEmploymentHistorySummaryTable.month', {
                      count: employment.TimeAtEmployment.Months || 0
                    })}
                  </span>
                </TableCell>
              </TableRow>
            </Table>
          );
        })}
      </div>
    </MiniPanel>
  );
};

CFCEmploymentHistorySummaryTable.propTypes = {
  personal: PropTypes.object,
  section: PropTypes.object,
  showValidationTicks: PropTypes.bool,
  employments: PropTypes.array
};
export default CFCEmploymentHistorySummaryTable;
