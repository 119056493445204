import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './cfcProspectsWidget.scss';
import Panel from '../../Common/Panel';
import Pagination from '../../Common/Pagination';
import PanelToolBar from '../../Common/PanelToolBar';
import PanelHeader from '../../Common/PanelHeader';
import CFCProspectsItem from './CFCProspectsItem';
import DashboardListHeader from './DashboardListHeader';
import DashboardFilter from './DashboardFilter';

const CFCProspectsWidget = (props) => {
  const { t } = useTranslation('Dashboard');
  return (
    <div className="cfcProspectsWidget">
      <Panel>
        <PanelHeader>{t('CFCProspectsWidget.finance_eligibility_checks')}</PanelHeader>
        <PanelToolBar hasPadding={false}>
          <div className="cfcProspectsWidget__filterOuter">
            <DashboardFilter filter={props.filter} onClick={props.onFilter} />
          </div>
        </PanelToolBar>

        {props.items && props.items.length > 0 ? (
          <div className="cfcProspectsWidget__applicantContainer">
            <DashboardListHeader />
            <div>
              {props.items.map((info, index) => (
                <CFCProspectsItem applications={info} key={index} handleClick={props.handleClick} />
              ))}
            </div>
          </div>
        ) : (
          <div className="cfcProspectsWidget__noApplications">
            <div className="cfcProspectsWidget__noApplications">
              {t('CFCProspectsWidget.you_have_no_current_cfc_prospects')}
            </div>
          </div>
        )}
        <div className="cfcProspectsWidget__paginationContainer">
          <Pagination
            currentPage={props.currentPage}
            totalItems={props.totalItems}
            totalPages={Math.ceil(props.totalItems / props.pageSize)}
            pageSize={props.pageSize}
            onChange={props.changePage}
          />
        </div>
      </Panel>
    </div>
  );
};

CFCProspectsWidget.propTypes = {
  items: PropTypes.array,
  onFilter: PropTypes.func,
  filter: PropTypes.string,
  showFooter: PropTypes.bool,
  handleClick: PropTypes.func,
  viewMoreLink: PropTypes.string,
  changePage: PropTypes.func.isRequired,
  totalItems: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalPages: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default CFCProspectsWidget;
