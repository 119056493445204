import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as Link } from 'react-router-dom';
import { inject } from 'mobx-react';
import './sideNavigation.scss';
import { useParams } from 'react-router-dom';
import cn from 'classnames';

const SideNavigation = () => {
  const params = useParams();
  const { t } = useTranslation('Stock');

  return (
    <ul className="sideNavigation__list test">
      <li key="details">
        <Link
          className={({ isActive }) => cn('sideNavigation__listItem', isActive ? 'sideNavigation__listItem--active' : '')}
          to={`/d/${params.dealershipId}/stock/edit/${params.vehicleId}/details`}
        >
          {t('SideNavigation.vehicle_summary')}
        </Link>
      </li>

      <li key="media">
        <Link
          className={({ isActive }) => cn('sideNavigation__listItem', isActive ? 'sideNavigation__listItem--active' : '')}
          to={`/d/${params.dealershipId}/stock/edit/${params.vehicleId}/media`}
        >
          {t('SideNavigation.media')}
        </Link>
      </li>
    </ul>
  );
};

export default inject('appStore')(SideNavigation);
