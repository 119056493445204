import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';

const DigitalDealsContainer = (props) => {
  return (
    <>
      <Outlet context={{ ...props }} />
    </>
  );
};

DigitalDealsContainer.propTypes = {
  appStore: PropTypes.object
};

function mapStateToProps(state) {
  return {
    preferredDeposit: state.globalDefaultSettings.DepositValue,
    preferredTerm: state.globalDefaultSettings.PreferredTerm,
    preferredAnnualMileage: state.globalDefaultSettings.DefaultAnnualMileage
  };
}

export default inject(['appStore'])(observer(connect(mapStateToProps)(DigitalDealsContainer)));
