import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './collapsibleCardInformation.scss';
import Icon from './Icon/Icon';

class CollapsibleCardInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: true
    };
  }

  handleClick = () => {
    this.setState({
      isCollapsed: !this.state.isCollapsed
    });
  };

  render() {
    const { formatter: Formatter } = this.props;

    return (
      <div className="collapsibleCardInformation__outer">
        <div className="collapsibleCardInformation__header" onClick={this.handleClick}>
          <div className="collapsibleCardInformation__icon">
            <Icon name={this.state.isCollapsed ? 'plus' : 'minus'} />
          </div>
          <div className="collapsibleCardInformation__title">{this.props.title}</div>

          <div className="collapsibleCardInformation__headerValue">
            {this.props.warning && <div className="collapsibleCardInformation__warning">{this.props.warning}</div>}
            {Formatter ? <Formatter value={this.props.headerInfo} /> : this.props.headerInfo}
          </div>
        </div>
        <div
          className={
            this.state.isCollapsed
              ? 'collapsibleCardInformation__content--closed'
              : 'collapsibleCardInformation__content--open'
          }
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

CollapsibleCardInformation.propTypes = {
  title: PropTypes.string,
  headerInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  formatter: PropTypes.func,
  warning: PropTypes.string
};

export default CollapsibleCardInformation;
