import ApplicationNavigationItem from '~/components/Application/components/ApplicationNavigationItem';
import ApplicationNavigationWrapper from '~/components/Common/layouts/ApplicationNavigationWrapper';
import { FinanceNavigatorFormProgress, FinanceNavigatorFormSection } from '../types/financeNavigatorForm';
import { useTranslation } from 'react-i18next';
import './financeNavigatorNavigation.scss';

export const FinanceNavigatorNavigation = ({
  progress,
  section,
  setSection
}: {
  progress: FinanceNavigatorFormProgress;
  section: FinanceNavigatorFormSection;
  setSection: (section: FinanceNavigatorFormSection) => void;
}) => {
  const { t } = useTranslation('Application');
  const navigationItems = [
    {
      id: 'PersonalDetails',
      text: t('ApplicationNavigation.personal_details'),
      imageName: 'consumer',
      progress: progress.PersonalDetails
    },
    {
      id: 'AddressHistory',
      text: t('ApplicationNavigation.address'),
      imageName: 'location',
      progress: progress.AddressHistory
    },
    {
      id: 'EmploymentHistory',
      text: t('ApplicationNavigation.employment'),
      imageName: 'employment',
      progress: progress.EmploymentHistory
    },
    {
      id: 'AffordabilityDetails',
      text: t('ApplicationNavigation.affordability'),
      imageName: 'affordability',
      progress: progress.AffordabilityDetails
    },
    {
      id: 'Summary',
      text: t('ApplicationNavigation.summary'),
      imageName: 'summary',
      progress: progress.Total
    }
  ];
  return (
    <div className="financeNavigator__navigation">
      <ApplicationNavigationWrapper>
        <ul className="applicationNavigation">
          {navigationItems.map((item, index) => (
            <ApplicationNavigationItem
              key={index}
              isActive={section === item.id}
              {...item}
              numItems={navigationItems.length}
              isLast={index === navigationItems.length - 1}
              onClick={() => setSection(item.id as FinanceNavigatorFormSection)}
            />
          ))}
        </ul>
      </ApplicationNavigationWrapper>
    </div>
  );
};
