import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Table from './SummaryTable/Table';
import TableRow from './SummaryTable/TableRow';
import OuterLeft from './SummaryTable/OuterLeft';
import OuterRight from './SummaryTable/OuterRight';
import { splitCamelCaseToString, getTimeAtAddressString } from '../../../core/helpers';
import TableHead from './SummaryTable/TableHead';
import TableCell from './SummaryTable/TableCell';
import AddressLine from '../../Common/AddressLine';
import MoneyFormat from '../../Common/MoneyFormat';
import WordBreak from '../../Common/WordBreak';
import './addressHistoryPanel.scss';

const EmploymentHistoryPanelItem = ({ employment, index }) => {
  const { t } = useTranslation('Reporting');
  const {
    Occupation,
    OccupationBasis,
    OccupationType,
    EmploymentSector,
    TimeAtEmployment,
    TelephoneNumber,
    Employer,
    GrossAnnual
  } = employment;
  return (
    <div className="addressHistoryPanel__item">
      <div className="addressHistoryPanel__itemHeader">
        {t('EmploymentHistoryPanelItem.employment')} {index + 1}
      </div>
      <OuterLeft>
        <Table>
          <TableRow>
            <TableHead width="50%">{t('EmploymentHistoryPanelItem.occupation')}</TableHead>
            <TableCell>
              <WordBreak>{Occupation} </WordBreak>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('EmploymentHistoryPanelItem.basis')}</TableHead>
            <TableCell>{splitCamelCaseToString(OccupationBasis)}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('EmploymentHistoryPanelItem.occupation_type')}</TableHead>
            <TableCell>{splitCamelCaseToString(OccupationType)}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('EmploymentHistoryPanelItem.time_in_employment')}</TableHead>
            <TableCell>{getTimeAtAddressString(TimeAtEmployment)}</TableCell>
          </TableRow>
        </Table>
      </OuterLeft>
      <OuterRight>
        <Table>
          <TableRow>
            <TableHead width="50%">{t('EmploymentHistoryPanelItem.work_telephone')}</TableHead>
            <TableCell>{TelephoneNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('EmploymentHistoryPanelItem.employer')}</TableHead>
            <TableCell>
              <WordBreak>{Employer}</WordBreak>
            </TableCell>
          </TableRow>
          {EmploymentSector ? (
            <TableRow>
              <TableHead width="50%">{t('EmploymentHistoryPanelItem.employment_sector')}</TableHead>
              <TableCell>
                <WordBreak>{splitCamelCaseToString(EmploymentSector)}</WordBreak>
              </TableCell>
            </TableRow>
          ) : null}
          <TableRow>
            <TableHead width="50%">{t('EmploymentHistoryPanelItem.employer_address')}</TableHead>
            <TableCell>
              <WordBreak>
                <AddressLine address={employment} />
              </WordBreak>
            </TableCell>
          </TableRow>
          {GrossAnnual ? (
            <TableRow>
              <TableHead width="50%">{t('EmploymentHistoryPanelItem.gross_annual')}</TableHead>
              <TableCell>
                <MoneyFormat value={GrossAnnual} />
              </TableCell>
            </TableRow>
          ) : null}
        </Table>
      </OuterRight>
    </div>
  );
};

EmploymentHistoryPanelItem.propTypes = {
  employment: PropTypes.object,
  index: PropTypes.number
};
export default EmploymentHistoryPanelItem;
