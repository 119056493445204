import Request from '../mobx-models/Request';
import { observable, action } from 'mobx';
import { confirmGdprAwareness } from '../api/session';

class SessionStore {
  @observable hasGdprAwarenessUpdate = true;
  confirmGdprAwarenessRequest = new Request();

  @action
  confirmGdprAwarenessClick = (version) => {
    return this.confirmGdprAwarenessRequest
      .onSuccess(
        action(() => {
          this.hasGdprAwarenessUpdate = false;
        })
      )
      .call(confirmGdprAwareness, version);
  };
}

export default SessionStore;
