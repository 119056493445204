import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from '../Image';
import ivendiImage from '../../../styles/icons/ivendi_ml.svg';
import { setTheme } from '../../../theme';
import { Outlet } from 'react-router-dom';
import LanguageSelectTrigger from '../../LanguageSelect/LanguageSelectTrigger';
import './signedOutLayout.scss';
import LanguageFlag from '../../LanguageSelect/LanguageFlag';
import { Tooltip } from '../Tooltip/Tooltip';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import cn from 'classnames';

const SignedOutLayout = () => {
  useEffect(() => {
    setTheme('theme-default');
  }, []);

  const isMobile = useMediaQuery('(max-width: 900px)');

  return (
    <div className="signedOutLayout" key="container" id="LoginPage">
      <div className="signedOutLayout__inner" key="innerContainer">
        <div className="signedOutLayout__innerInner">
          <div className="signedOutLayout__leftOuter">
            <div className="signedOutLayout__left" key="left">
              <div className="signedOutLayout__ivendiContainer" key="ivendiContainer">
                <Image imageName={ivendiImage} />
              </div>
            </div>
          </div>
          <div className="signedOutLayout__rightOuter">
            <div className="signedOutLayout__loginForm" key="loginForm">
              <div className="signedOutLayout__right" key="right">
                <div className="signedOutLayout__languageToggle">
                  <LanguageSelectTrigger>
                    {({ currentLanguage, handleModalOpen }) => (
                      <Tooltip content={'Choose your language'}>
                        <button
                          className={cn(' signedOutLayout__languageToggleBtn', {
                            'signedOutLayout__languageToggleBtn--mobile': isMobile
                          })}
                          onClick={handleModalOpen}
                        >
                          <LanguageFlag imgSrc={currentLanguage.imageSrc} />
                          {isMobile && currentLanguage.label}
                        </button>
                      </Tooltip>
                    )}
                  </LanguageSelectTrigger>
                </div>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SignedOutLayout.propTypes = {
  location: PropTypes.object,
  children: PropTypes.any
};

export default SignedOutLayout;
