import React from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import { default as MarqueeQuotes } from './MarqueeQuotes.tsx';

const Index = ({ ...props }) => {
  const isFeatureOn = useFeatureIsOn('data-388-marquee-dashboard-quotes');

  return isFeatureOn ? <MarqueeQuotes {...props} /> : null;
};

export default Index;
