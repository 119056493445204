import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Common/Icon/Icon';
import './dailyAverageStats.scss';
import _ from 'lodash';

const DailyAverageStats = ({ averageStock, impressionsPerVehicle, quotesPerVehicle }) => {
  const { t } = useTranslation('DigitalReport');
  return (
    <div className="dailyAverageStats__outer">
      <div className="dailyAverageStats__row">
        <div className="dailyAverageStats__iconOuter">
          <Icon name="stock" />
        </div>
        <div className="dailyAverageStats__data">
          <div className="dailyAverageStats__dataTop">
            <div className="dailyAverageStats__figure">
              {_.isFinite(averageStock) ? averageStock : t('DailyAverageStats.na')}
            </div>
          </div>
          <div className="dailyAverageStats__dataBottom">{t('DailyAverageStats.unique_vehicles_get')}</div>
        </div>
      </div>
      <div className="dailyAverageStats__row">
        <div className="dailyAverageStats__iconOuter">
          <Icon name="quote" />
        </div>
        <div className="dailyAverageStats__data">
          <div className="dailyAverageStats__dataTop">
            <div className="dailyAverageStats__figure">
              {_.isFinite(quotesPerVehicle) ? quotesPerVehicle : t('DailyAverageStats.na')}
            </div>
          </div>
          <div className="dailyAverageStats__dataBottom">{t('DailyAverageStats.average_quotes_per_day_on')}</div>
        </div>
      </div>
      <div className="dailyAverageStats__row">
        <div className="dailyAverageStats__iconOuter">
          <Icon name="full-page-ad" />
        </div>
        <div className="dailyAverageStats__data">
          <div className="dailyAverageStats__dataTop">
            <div className="dailyAverageStats__figure">
              {_.isFinite(impressionsPerVehicle) ? impressionsPerVehicle : t('DailyAverageStats.na')}
            </div>
          </div>
          <div className="dailyAverageStats__dataBottom">
            {t('DailyAverageStats.full_page_advert_views_per_vehicle')}
          </div>
        </div>
      </div>
    </div>
  );
};

DailyAverageStats.propTypes = {
  averageStock: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  impressionsPerVehicle: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  quotesPerVehicle: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
export default DailyAverageStats;
