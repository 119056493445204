import algoliasearchHelper from 'algoliasearch-helper';
import algoliasearch from 'algoliasearch';
import platformApiUtils from '@ivendi/platform-api-utils';
import AlgoliaVehicle from '~/mobx-models/AlgoliaVehicle';
import { z } from 'zod';
import { VehicleClassJs } from '~/types/vehicle';

const AlgoliaApiKeysSchema = z.object({
  QS: z.string(),
  Stock: z.string()
});
export type AlgoliaApiKeys = z.infer<typeof AlgoliaApiKeysSchema>;

const VEHICLE_CLASS_WHITELIST: string[] = [
  VehicleClassJs.car,
  VehicleClassJs.lcv,
  VehicleClassJs.bike,
  VehicleClassJs.hgv,
  VehicleClassJs.motorhome,
  VehicleClassJs.van,
  VehicleClassJs.touringCaravan
];

export const findAlgoliaVehicle = async (vrm: string, dealershipId: string) => {
  try {
    const apiKeys = await platformApiUtils
      .get(`v1/stock/search?dealershipid=${dealershipId}`)
      .then(AlgoliaApiKeysSchema.parse);

    const stockKey = apiKeys.Stock;
    const algolia = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID!, stockKey);
    const algoliaHelper = algoliasearchHelper(algolia, process.env.REACT_APP_ALGOLIA_INDEX!);

    const results = await algoliaHelper.searchOnce({ query: vrm });

    if (results.content.nbHits !== 1) {
      throw new Error('nbHits did not equal 1.');
    }

    const hit = results.content.hits[0];
    const taxonomyClass = hit?.taxonomies?.[0]?.class?.toLowerCase();
    let vehicleClass = hit?.class?.toLowerCase();
    let sClass = hit?.sClass?.toLowerCase();

    const isClassWhitelisted = VEHICLE_CLASS_WHITELIST.includes(vehicleClass ?? '');
    const isSClassWhitelisted = VEHICLE_CLASS_WHITELIST.includes(sClass ?? '');
    const isTaxonomyClassWhitelisted = VEHICLE_CLASS_WHITELIST.includes(taxonomyClass ?? '');

    if (!isClassWhitelisted && !isSClassWhitelisted && !isTaxonomyClassWhitelisted) {
      throw new Error(`Vehicle class not in whitelist.`);
    }

    //Need to remove the class if it's not in the whitelist to stop it being passed to the API
    if (!isSClassWhitelisted) {
      sClass = '';
    }

    if (!isClassWhitelisted) {
      vehicleClass = '';
    }

    if (!isTaxonomyClassWhitelisted) {
      hit.taxonomies[0].class = '';
    }

    const algoliaVehicle = new AlgoliaVehicle();
    algoliaVehicle.updateFromJSON({ ...hit, sClass, class: vehicleClass });

    if (algoliaVehicle.Class === 'van') {
      algoliaVehicle.Class = 'lcv';
    }

    if (algoliaVehicle.SClass === 'van') {
      algoliaVehicle.SClass = 'lcv';
    }

    // const normalizedAlgoliaVehicle = structuredClone(algoliaVehicle); // We can use this once we've upgraded to Node 17
    const normalizedAlgoliaVehicle = JSON.parse(JSON.stringify(algoliaVehicle));

    return normalizedAlgoliaVehicle;
  } catch (e) {
    throw new Error((e as Error).message || 'No vehicle found in Algolia');
  }
};
