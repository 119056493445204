import * as rudderanalytics from 'rudder-sdk-js';

type PageView = {
  pageName: string;
  eventProperties: rudderanalytics.apiObject;
};

type LogEvent = {
  eventName: string;
  eventProperties: Record<string, any>;
};

const unsentPageViews: PageView[] = [];
const unsentEvents: LogEvent[] = [];
let isReady: boolean = false;

export const destinationInterface = {
  make: function(_: unknown, apiKey: string) {
    const dataPlaneUrl = '/dataplane';

    rudderanalytics.load(apiKey, dataPlaneUrl, {
      configUrl: dataPlaneUrl,
      secureCookie: true,
      sessions: {
        autoTrack: true
      }
    });

    rudderanalytics.ready(() => {
      isReady = true;
      console.log('Rudderstack initialised');
      // Now RS is ready, flush through any unsent page views
      for (const x of unsentPageViews) {
        this.logPage(x.pageName, x.eventProperties);
      }

      for (const unsentEvent of unsentEvents) {
        this.logEvent(unsentEvent.eventName, unsentEvent.eventProperties);
      }
    });
  },

  logPage: function(pageName: PageView['pageName'], eventProperties: PageView['eventProperties']) {
    if (isReady) {
      rudderanalytics.page(pageName, eventProperties);
    } else {
      // If RS is not ready, shove these into an array til it is
      unsentPageViews.push({ pageName: pageName, eventProperties: eventProperties });
    }
  },

  logEvent: function(eventName: LogEvent['eventName'], eventProperties: LogEvent['eventProperties']) {
    if (isReady) {
      rudderanalytics.track(eventName, eventProperties);
      console.debug(`${eventName} sent`);
    } else {
      // If RS is not ready, shove these into an array til it is
      unsentEvents.push({ eventName: eventName, eventProperties: eventProperties });
      console.debug(`${eventName} enqueued for send`);
    }
  },

  setUserProperties: function(userId: any, userProperties: any) {
    // TODO
  },

  identify: function(userId: any) {
    // TODO
  },

  unidentify: function() {
    // TODO
  },

  revenue: function(amount: any, eventProperties: any) {
    // TODO
  },

  // The following methods are used for group analytics and are not required
  setGroupProperties(groupType: any, groupId: any, groupProperties: any) {
    // TODO
  },

  addCurrentUserToGroup: function(groupType: any, groupId: any, groupProperties: any) {
    // TODO
  },

  logEventWithGroups: function(eventName: any, eventProperties: any, groupTypesToGroupIds: any) {
    // TODO
  }
};
