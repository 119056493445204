import { useTranslation } from 'react-i18next';
import MoneyFormat from '../../../Common/MoneyFormat';
import './vehicleDetailsInformation.scss';
import CloseDealBanner from '../../../CloseDeal/CloseDealBanner';
import { CustomerVehicleOfInterest } from '~/types/vehicle';

type VehicleDetailsInformationProps = {
  vehicle: CustomerVehicleOfInterest;
  latestPrice: string;
};
const VehicleDetailsInformation = ({ vehicle, latestPrice }: VehicleDetailsInformationProps) => {
  const { t } = useTranslation('Customer');
  const latestVehiclePrice = latestPrice;

  // The Supplied values come from an Engage FPA API
  // we need to prioritise the supplied values when they are available for a VoI,
  // specially on the customer page
  const make = vehicle.SuppliedMake || vehicle.Make;
  const model = vehicle.SuppliedModel || vehicle.Model;
  const derivative = vehicle.SuppliedDerivative || vehicle.Derivative;

  return (
    <div
      key="info"
      className={
        latestVehiclePrice
          ? 'vehicleDetailsInformation__container'
          : 'vehicleDetailsInformation__container vehicleDetailsInformation__noPrice'
      }
      data-th="VehicleDetailsInformation"
    >
      <div key="makemodel" className="vehicleDetailsInformation__makeModel">
        {make} {model}
      </div>
      <div key="derivative" className="vehicleDetailsInformation__derivative">
        {derivative}
      </div>
      {vehicle.Status && vehicle.Status.Status && (
        <div className="status">
          <CloseDealBanner closed={vehicle.Closed} status={vehicle.Status.Status} />
        </div>
      )}
      <div key="price" className="vehicleDetailsInformation__price">
        {latestVehiclePrice ? <MoneyFormat value={latestVehiclePrice} /> : null}
        {latestVehiclePrice ? (
          <span className="vehicleDetailsInformation__latestText">
            {vehicle.isSold
              ? ' ' + t('VehicleDetailsInformation.sold_price')
              : ' ' + t('VehicleDetailsInformation.latest_price')}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default VehicleDetailsInformation;
