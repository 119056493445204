import React from 'react';
import PropTypes from 'prop-types';
import WarningTooltip from './WarningTooltip';
import './disabledButtonWithMessage.scss';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

class DisabledButtonWithMessage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isTooltipOpen: false
    };

    this.refNum = `disabledButtonWithMessage-${Math.random()}`;
  }

  handleClick = () => {
    this.setState(
      {
        isTooltipOpen: !this.state.isTooltipOpen
      },
      () =>
        window.setTimeout(() => {
          this.setState({
            isTooltipOpen: false
          });
        }, 5000)
    );
  };

  render() {
    return (
      <div className="disabledButtonWithMessage" id={`${this.refNum}`} data-th="DisabledButtonWithMessage">
        <div onClick={this.handleClick} className="button button--normal button--primaryDisabled">
          {this.props.children}
        </div>
        <TransitionGroup>
          {this.state.isTooltipOpen ? (
            <CSSTransition
              key={this.refNum}
              classNames="disabledButtonWithMessage-animation"
              timeout={{ enter: 400, exit: 400 }}
            >
              <WarningTooltip refNum={this.refNum}>{this.props.message}</WarningTooltip>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </div>
    );
  }
}

DisabledButtonWithMessage.propTypes = {
  children: PropTypes.string,
  message: PropTypes.string
};

export default DisabledButtonWithMessage;
