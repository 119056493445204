import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './ordersItem.scss';
import Icon from '../../Common/Icon/Icon';
import moment from 'moment';
import VehicleImageWithVrm from '../../Common/VehicleImageWithVrm';
import MoneyFormat from '../../Common/MoneyFormat';
import i18n from '../../../i18n';
import { shouldShowType } from '../utils';

export const getPaymentMethod = (method) => {
  switch (method?.toLowerCase()) {
    case 'cash':
      return i18n.t('payment_method.cash', {
        ns: 'DigitalDeals'
      });
    case 'finance':
      return i18n.t('payment_method.finance', {
        ns: 'DigitalDeals'
      });
    default:
      return '';
  }
};

function OrdersItem({ order, dealershipId, isCustomerRecord }) {
  const { t } = useTranslation('DigitalDeals');
  const orderDate = moment(order.DateTimeSubmittedInNewVehicle).format('D MMM YY HH:mm');
  const paymentMethod = getPaymentMethod(order.PaymentMethod);
  const showType = shouldShowType();

  return (
    <Link
      to={`/d/${dealershipId}/orders-and-deals/order/${order.Id}${isCustomerRecord ? '?ref=customer' : ''}`}
      className="ordersItem"
    >
      <div className="ordersItem__header">
        <div className="ordersItem__orderIcon">
          <Icon name="online-order" />
        </div>
        <div className="ordersItem__orderRef">
          {t('OrdersItem.order')} {order.ReferenceCode}
        </div>
        <div className="ordersItem__orderTime">{orderDate}</div>
        <div className="ordersItem__orderTime">
          {t('OrdersItem.source')} {t('OrdersItem.online')}
        </div>
        {showType && (
          <div className="ordersItem__orderTime">
            {t('OrdersItem.type')} {order.Type}
          </div>
        )}
      </div>

      {!isCustomerRecord ? (
        <div className="ordersItem__content">
          <div className="ordersItem__vehicle">
            <VehicleImageWithVrm
              image={order.VehicleDetails.CdnVehicleImageUrl}
              vrm={order.VehicleDetails.VehicleVrm}
              vehicleClass={order.VehicleDetails.VehicleClass}
            />
          </div>
          <div className="ordersItem__info">
            <div className="ordersItem__infoItem ordersItem__customerName">
              {order.CustomerFirstName} {order.CustomerSurname}
            </div>
            <div className="ordersItem__infoItem">
              <span>{order.VehicleDetails.VehicleMake}</span> <span>{order.VehicleDetails.VehicleModel} </span>
              <div>{order.VehicleDetails.VehicleDerivative}</div>
            </div>
            <div className="ordersItem__infoItem">
              <MoneyFormat value={order.VehicleDetails.VehicleCashPrice} />
            </div>
          </div>
          {!!paymentMethod && (
            <div className="ordersItem__payment">
              <div className="ordersItem__subHeader">{t('OrdersItem.payment_method')}</div>
              <div>{paymentMethod}</div>
            </div>
          )}
        </div>
      ) : (
        <div className="ordersItem__content--customerRecord">
          <div className="ordersItem__info--customerRecord">
            <div className="ordersItem__infoItem--customerRecord">
              <div className="ordersItem__subHeader">{t('OrdersItem.total_price')}</div>
              <MoneyFormat value={order.VehicleDetails.VehicleCashPrice} />
            </div>
            {!!paymentMethod && (
              <div className="ordersItem__infoItem--customerRecord">
                <div className="ordersItem__subHeader">{t('OrdersItem.payment_method')}</div>
                <div>{paymentMethod}</div>
              </div>
            )}
            <div className="ordersItem__infoItem--customerRecord">
              <div className="ordersItem__subHeader">{t('OrdersItem.received')}</div>
              <div>{orderDate} </div>
            </div>
            {/* <div className="ordersItem__infoItem--customerRecord">
             <div className="ordersItem__subHeader">Status</div>
             <div>CHANGE THIS </div>
          </div> */}
          </div>
        </div>
      )}
    </Link>
  );
}

OrdersItem.propTypes = {
  order: PropTypes.object,
  dealershipId: PropTypes.string
};
export default OrdersItem;
