import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import LenderStatusLarge from '../LenderStatusLarge';
import DashboardBusinessTag from '../../Dashboard/components/DashboardBusinessTag';
import ConsumerName from '../../Common/ConsumerName';
import InlineWarning from '../../Common/InlineWarning';
import CheckboxWithLabel from '../../Common/Form/CheckboxWithLabel';
import FunderLogo from '../../Common/FunderLogo';
import { getClientApp } from '../../../core/helpers';
import StatusIcon from '../StatusIcon';
import { observer, inject } from 'mobx-react';
import DateFormat from '../../Common/DateFormat';
import './consumerStatus.scss';

const ConsumerStatus = ({ t, ...props }) => {
  const canUseDistanceSale = props.appStore.uiState.canUseDistanceSale(
    props.application.CustomerType,
    props.application.Quote.FunderCode
  );

  const canShowSource = props.application.CustomerType !== 'corporate';

  return (
    <div className="consumerStatus">
      <div className="consumerStatus__top">
        <div className="consumerStatus__logo">
          <FunderLogo bare className="consumerStatus__logo-inner" funder={props.application.Quote.FunderCode} />
        </div>
        <div className="consumerStatus__personalDetails">
          {props.application.CustomerType.toLowerCase() === 'corporate' ? (
            <div className="consumerStatus__info" key="consumerInfo">
              <div>
                <a
                  href={`/dealer/platform/d/${props.dealershipId}/consumers/${props.consumer.Id}`}
                  className="consumerStatus__businessName"
                  key="name2"
                >
                  <DashboardBusinessTag smallTag={true} />
                  {props.application.BusinessDetails?.TradingName}
                </a>
              </div>
              <p>
                <span className="consumerStatus__businessContact">
                  {props.application.OrganisationContacts?.Items[0]?.Title}{' '}
                  <ConsumerName
                    name={
                      props.application.OrganisationContacts?.Items[0]?.FirstName +
                      ' ' +
                      props.application.OrganisationContacts?.Items[0]?.LastName
                    }
                  />
                </span>
              </p>
              <p>
                <span className="consumerStatus__businessNumber">
                  {props.application.OrganisationContacts?.Items[0]?.MobileTelephone ||
                    props.application.OrganisationContacts?.Items[0]?.WorkTelephone ||
                    props.application.OrganisationContacts?.Items[0]?.HomeTelephone}
                </span>
              </p>
            </div>
          ) : (
            <div className="consumerStatus__info" key="businessInfo">
              <a
                href={`/dealer/platform/d/${props.dealershipId}/consumers/${props.consumer.Id}`}
                className="consumerStatus__consumerName"
                key="name2"
              >
                {props.application.PersonalDetails?.Title}{' '}
                <ConsumerName
                  name={
                    props.application.PersonalDetails?.FirstName + ' ' + props.application.PersonalDetails?.LastName
                  }
                />
              </a>
              <span className="consumerStatus__phoneNumber">
                {props.application.PersonalDetails?.MobileTelephone || props.application.PersonalDetails?.HomeTelephone}
              </span>
            </div>
          )}
        </div>
      </div>
      {props.application.Vehicle.Closed && (
        <div>
          <div className="consumerStatus__closeLeadWarning">
            <InlineWarning>{t('ConsumerStatus.this_is_a_previously_closed_deal')}</InlineWarning>
          </div>
          <div className="consumerStatus__removeFromHomeWarning">
            <CheckboxWithLabel
              onChange={props.checkboxOnChange}
              id="removeFromHomeCheckbox"
              value={props.application.Vehicle.HiddenOnDashboard}
            >
              {t('ConsumerStatus.hide_this_deal_from_the_homepage')}
            </CheckboxWithLabel>
          </div>
        </div>
      )}
      <div className="consumerStatus__applicationInformation">
        {props.combined ? (
          <>
            <StatusIcon status={props.application.LenderStatus || props.application.Status} />
            <p className="consumerStatus__productName">
              <strong>{props.application.Quote.ProductName}</strong>
            </p>
          </>
        ) : (
          <table className="consumerStatus__applicationDetails">
            <tbody>
              <tr>
                <td>Created:</td>
                <td>
                  <DateFormat value={props.application.CreatedDate} format="DD MMM YYYY" />
                </td>
              </tr>
              {canShowSource ? (
                <tr>
                  <td>Source:</td>
                  <td>{getClientApp(props.application, 'Showroom')}</td>
                </tr>
              ) : null}
              <tr>
                <td>Product:</td>
                <td>{props.application.Quote.ProductName}</td>
              </tr>

              {canUseDistanceSale && (
                <tr>
                  <td>Distance Sale:</td>
                  <td>{props.application.Arranged.DistanceSale ? 'Yes' : 'No'}</td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        <div className="consumerStatusAlt__lenderStatus">
          <LenderStatusLarge closed={true} status={props.application.Status} />
        </div>
      </div>
    </div>
  );
};

ConsumerStatus.propTypes = {
  application: PropTypes.object,
  consumer: PropTypes.object,
  checkboxOnChange: PropTypes.func,
  combined: PropTypes.bool,
  dealershipId: PropTypes.string,
  appStore: PropTypes.object
};

export default withTranslation('ApplicationStatus')(inject(['appStore'])(observer(ConsumerStatus)));
export { ConsumerStatus };
