import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import QuoteModuleContainer from '../../Quoting/containers/QuoteModuleContainer';
import { withRouter } from '../../../hocs/router';

class ApplicationRepropose extends React.Component {
  handleProceed = (quote) => {
    const { dealershipId } = this.props.params;

    this.props.cloneApplication(
      dealershipId,
      this.props.consumer,
      this.props.application.Vehicle,
      quote,
      this.props.application.Id,
      this.props.application.CustomerType
    );
  };
  handleCancel = () => {
    const { dealershipId, consumerId, applicantId } = this.props.params;
    this.props.navigate({
      pathname: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/status`,
      query: {
        vehicleId: this.props.application.Vehicle.VehicleId
      }
    });
  };
  handleEditExistingApplication = () => {
    const { dealershipId, consumerId, applicantId } = this.props.params;
    this.props.navigate(`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit`);
  };

  render() {
    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: this.props.t('ApplicationRepropose.home'),
              path: `/d/${this.props.params.dealershipId}`
            },
            {
              name: this.props.t('ApplicationRepropose.customer_list'),
              path: `/d/${this.props.params.dealershipId}/consumers`
            },
            {
              name: this.props.t('ApplicationRepropose.consumer'),
              path: `/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}`
            },
            {
              name: this.props.t('ApplicationRepropose.application_status'),
              path: `/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}/application/${this.props.params.applicantId}/status`
            },
            {
              name: this.props.t('ApplicationRepropose.re_propose_application')
            }
          ]}
          consumer={this.props.consumer}
        />
        <QuoteModuleContainer
          vehicle={this.props.application.Vehicle}
          initialCosts={this.props.application.QuoteRequest}
          onCancel={this.handleCancel}
          onProceed={this.handleProceed}
          proceedingState={this.props.application.applicationCloneState}
          warnIfLenderIs={this.props.application.Quote.FunderCode}
          onEditExistingApplication={this.handleEditExistingApplication}
          isEditableOnReproposeJourney={this.props.application.isEditable}
          customerType={this.props.application.CustomerType}
          customerId={this.props.params.consumerId}
        />
      </Page>
    );
  }
}

ApplicationRepropose.propTypes = {
  params: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  consumer: PropTypes.object.isRequired,
  application: PropTypes.object,
  cloneApplication: PropTypes.func,
  vehicle: PropTypes.object
};

export default compose(withRouter, withTranslation('Application'))(ApplicationRepropose);
