import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { observer, inject } from 'mobx-react';
import find from 'lodash/find';
import classnames from 'classnames';
import QuoteCard from '~/components/QuoteCard/QuoteCard';
import FormFooter from '../Common/Form/FormFooter';
import LoadingSpinner from '../Common/Loading/LoadingSpinner';
import EditQuoteCommissionsForm from '../Quoting/forms/EditQuoteCommissionsForm';
import Modal from '../Common/Modal/Modal';
import ToggleSwitch from '../Common/ToggleSwitch';
import Button from '../Common/Button/Button';
import QuotesHeader from '../QuotesList/components/QuotesHeader';
import AltSelectWithLabel from '../Common/Form/AltSelectWithLabel';
import AlertCard from 'components/Common/AlertCard/AlertCard';
import { QuoteCardVariantTypeJs } from '../QuoteCard/types';
import './pushDealSelectQuotes.scss';

class PushDealSelectQuotes extends Component {
  state = {
    commissionsModalIsOpen: false,
    productForEditCommissions: {},
    showSchemes: false,
    showFullQuote: true,
    showAllQuotes: false,
    isFooterSticky: document.body.offsetHeight > window.innerHeight + window.scrollY - 40 ? false : true
  };

  componentDidMount() {
    window.addEventListener('scroll', this.footerScroll, false);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.footerScroll, false);
  };
  footerScroll = () => {
    const hasScrolledToBottom = document.body.offsetHeight > window.innerHeight + window.scrollY - 40;
    this.setState({
      isFooterSticky: hasScrolledToBottom
    });
  };
  onSubmit = () => {
    if (this.props.appStore.pushDealStore.selectedQuotes.length !== 0) {
      if (this.props.appStore.pushDealStore.customer?.Id !== undefined) {
        this.props.changeTitle('confirmAndSend');
      } else {
        this.props.changeTitle('selectCustomer');
      }
    }
  };
  returnToSelectCustomer = () => {
    this.props.changeTitle('selectCustomer');
  };
  toggleCommissionsModal = () => {
    this.setState({
      commissionsModalIsOpen: !this.state.commissionsModalIsOpen
    });
  };
  updateProductSettings = (formData) => {
    if (formData.applyTo === 'all') {
      this.props.appStore.pushDealStore.updateProductSettings(formData, true);
    } else {
      this.props.appStore.pushDealStore.updateProductSettings(formData, false);
    }
  };
  onProductNameClick = (productId) => {
    this.setState(
      {
        productForEditCommissions: find(this.props.appStore.pushDealStore.ProductSettings, {
          ProductUid: productId
        })
      },
      this.toggleCommissionsModal()
    );
  };
  handleToggleSchemesClick = () => {
    this.setState(
      (prevState) => ({
        showSchemes: !prevState.showSchemes
      }),
      () => {
        window.ga &&
          window.ga(
            'send',
            'event',
            'QuotesListView',
            `ShowQuoteSchemes${this.state.showSchemes ? 'TurnedOn' : 'TurnedOff'}`
          );
      }
    );
  };
  handleToggleFullQuoteClick = () => {
    this.setState(
      (prevState) => ({
        showFullQuote: !prevState.showFullQuote
      }),
      () => {
        window.ga &&
          window.ga(
            'send',
            'event',
            this.props.appStore.repExampleStore.repExampleSelected
              ? 'RepExSelectRepresentativeQuote'
              : 'QuotesListView',
            this.props.appStore.repExampleStore.repExampleSelected
              ? 'ShowFullQuote'
              : `ShowFullQuotes${this.state.showFullQuote ? 'TurnedOn' : 'TurnedOff'}`
          );
      }
    );
  };
  handleViewMoreOptionsClick = () => {
    this.setState(
      (prevState) => ({
        showAllQuotes: !prevState.showAllQuotes
      }),
      () => {
        this.props.appStore.quotingStore.setHasViewedOptions(this.state.showAllQuotes);
      }
    );
  };
  handleSortQuotes = (e) => {
    this.props.appStore.pushDealStore.sortQuotes(e.currentTarget.value);
  };
  showCombinedDealDisclaimer = (quote) => {
    if (quote.FunderCode === 'CRE') {
      if (quote.NegativeEquity > 0) {
        return true;
      } else if (quote.ValueAddedProducts && quote.ValueAddedProducts.length > 0) {
        return true;
      }
    }

    return false;
  };

  render() {
    const {
      handleQuoteCheckboxChange,
      quotesLoadingState,
      selectedQuotes,
      fetchQuoteError,
      hasAdjustedBalloon
    } = this.props.appStore.pushDealStore;
    let quotes = this.state.showAllQuotes
      ? this.props.appStore.pushDealStore.quotes
      : _.filter(this.props.appStore.pushDealStore.quotes, (product) => {
          let shouldShowRegardless = false;

          if (product.Errors) {
            product.Errors.forEach((error) => {
              if (error.Number === 544 && this.props.vehicleClass === 'lcv') {
                shouldShowRegardless = true;
                error.Message = error.Message.includes('£')
                  ? error.Message
                  : error.Message.replace(
                      this.props.t('PushDealSelectQuotes.adding_the'),
                      this.props.t('PushDealSelectQuotes.adding_the_£')
                    );
              } else if (error.Number === 0) {
                shouldShowRegardless = true;
              }
            });
          }

          // TECH DEBT: This is a temporary fix to show BDK products in the quote results
          return (product.isVisible || product.FunderCode === 'BDK') && (!product.Errors || shouldShowRegardless);
        });
    const noQuotes = quotes.length <= 0;
    const footerStyles = classnames(
      'pushDealSelectQuotes__footer',
      this.state.isFooterSticky && 'pushDealSelectQuotes__footer--sticky'
    );

    return (
      <div data-th="PushDealSelectQuotes">
        <div className="pushDealSelectQuotes__container">
          <div className="pushDealSelectQuotes__toolbar">
            <div className="quoteResults__showSchemesInner">
              <span className="quoteResults__showSchemesText">{this.props.t('PushDealSelectQuotes.show_schemes')}</span>
              <ToggleSwitch
                id="compareToggle"
                isActive={this.state.showSchemes}
                handleUpdate={this.handleToggleSchemesClick}
              />
            </div>
            <div className="quoteResults__showSchemesInner">
              <span className="quoteResults__showSchemesText">
                {this.props.t('PushDealSelectQuotes.show_full_quotes')}
              </span>
              <ToggleSwitch
                id="compareToggle"
                isActive={this.state.showFullQuote}
                handleUpdate={this.handleToggleFullQuoteClick}
              />
            </div>
          </div>
          <div className="pushDealSelectQuotes__header">
            {hasAdjustedBalloon && this.props.appStore.uiState.isBdk && (
              <div className="pushDealSelectQuotes__header-alert">
                <AlertCard
                  styles="alert"
                  iconName="alert"
                  paragraph={this.props.t('PushDealSelectQuotes.balloon_adjusted')}
                />
              </div>
            )}
            <QuotesHeader>
              <AltSelectWithLabel
                label={this.props.t('PushDealSelectQuotes.sort_by')}
                value={this.props.appStore.pushDealStore.sortField}
                options={'QuotesListSortOptions'}
                handleSort={this.handleSortQuotes}
              />
            </QuotesHeader>
          </div>

          {quotesLoadingState && !fetchQuoteError && (
            <div className="pushDealSelectQuotes__loadingSpinner">
              <LoadingSpinner size="large" />
            </div>
          )}
          {!quotesLoadingState && (
            <div>
              {quotes.map((quote) => {
                const newCarOffer =
                  this.props.appStore.quotingStore.newCarOffers &&
                  Array.isArray(this.props.appStore.quotingStore.newCarOffers) &&
                  this.props.appStore.quotingStore.newCarOffers.find((offer) => offer.ProductUid === quote.ProductId);

                return (
                  <QuoteCard
                    variant={QuoteCardVariantTypeJs.SendDeal}
                    quote={quote}
                    showFullQuote={this.state.showFullQuote}
                    schemesVisible={this.state.showSchemes}
                    actions={{
                      onProductNameClick: this.onProductNameClick,
                      onQuoteSelect: handleQuoteCheckboxChange,
                      onCombinedQuoteClick: this.showCombinedDealDisclaimer(quote)
                    }}
                    newCarOffer={newCarOffer}
                    key={`SendDeal-${quote.QuoteId}`}
                  />
                );
              })}

              <Modal isOpen={this.state.commisionsModalIsOpen} onClose={this.toggleCommisionsModal}>
                <EditQuoteCommissionsForm
                  options={{}}
                  onSubmit={this.updateProductSettings}
                  defaultValues={this.state.productForEditCommissions}
                  onCancel={this.toggleCommissionsModal}
                />
              </Modal>
              <div className="quoteResults__buttonContainer">
                <div className="pushDealSelectQuotes__showMoreButton">
                  <Button
                    buttonStyle="secondary"
                    type="button"
                    onClick={this.handleViewMoreOptionsClick}
                    id={
                      this.state.showAllQuotes
                        ? this.props.t('PushDealSelectQuotes.show_less_quotes')
                        : this.props.t('PushDealSelectQuotes.show_more_quotes')
                    }
                  >
                    {this.state.showAllQuotes
                      ? this.props.t('PushDealSelectQuotes.show_less_quotes')
                      : this.props.t('PushDealSelectQuotes.show_more_quotes')}
                  </Button>
                </div>
              </div>
            </div>
          )}
          {fetchQuoteError && (
            <div>
              <h3>{this.props.t('PushDealSelectQuotes.there_was_a_error_fetching_your_quotes')}</h3>
            </div>
          )}
          {!quotesLoadingState && noQuotes && (
            <div className="pushDealSelectQuotes__noQuotes">
              <h3>{this.props.t('PushDealSelectQuotes.no_quotes_currently_available')}</h3>
            </div>
          )}
          <div className={footerStyles}>
            <FormFooter
              onSubmit={this.onSubmit}
              submitDisabled={selectedQuotes.length === 0}
              submitLabel={this.props.t('PushDealSelectQuotes.next')}
              onCancel={this.returnToSelectCustomer}
              cancelLabel={this.props.t('PushDealSelectQuotes.back')}
              trackingPage="PushDealSelectQuotes"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('PushDeal')(inject('appStore')(observer(PushDealSelectQuotes)));
