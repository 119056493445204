import React from 'react';
import PropTypes from 'prop-types';
import './scrollableTable.scss';
import { smoothScrollHorizontal } from '../../../core/helpers';

import HorizontalScrollIndicator from '../../Common/HorizontalScrollIndicator/HorizontalScrollIndicator';

class ScrollableTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollFraction: 0,
      showScrollIndicator: false
    };
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.showHideScrollIndicator, false);
  }

  componentDidMount() {
    this.showHideScrollIndicator();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.showHideScrollIndicator, false);
  }

  showHideScrollIndicator = () => {
    const doesOverflow = this.doesTableOverflow();
    if (doesOverflow !== this.state.showScrollIndicator) {
      this.setState({
        showScrollIndicator: doesOverflow
      });
    }
  };

  handleScroll = (e) => {
    const { scrollWidth, clientWidth, scrollLeft } = e.target;
    const scrollFraction = scrollLeft / (scrollWidth - clientWidth);

    this.setState({
      scrollFraction
    });
  };

  doesTableOverflow = () => {
    if (this.tableInner) {
      return this.tableInner.scrollWidth > this.tableInner.clientWidth;
    }

    return false;
  };

  smoothScrollTable = (direction = -1) => {
    const currentScrollPercentage = this.state.scrollFraction * 100;
    const scrollAmountInPercent =
      (this.props.scrollAmountInPx / (this.tableInner.scrollWidth - this.tableInner.clientWidth)) * 100;
    let nextScrollPercentage;

    if (direction === 1) {
      nextScrollPercentage =
        Math.floor((currentScrollPercentage + scrollAmountInPercent) / scrollAmountInPercent) * scrollAmountInPercent;
    } else {
      nextScrollPercentage =
        Math.ceil((currentScrollPercentage - scrollAmountInPercent) / scrollAmountInPercent) * scrollAmountInPercent;
    }

    smoothScrollHorizontal(this.tableInner, nextScrollPercentage);
  };

  smoothScrollLeft = () => {
    this.smoothScrollTable(-1);
  };

  smoothScrollRight = () => {
    this.smoothScrollTable(1);
  };

  render() {
    return (
      <div className="scrollableTable">
        <div className="scrollableTable__scrollIndicator">
          {this.state.showScrollIndicator && (
            <HorizontalScrollIndicator
              onClickLeft={this.smoothScrollLeft}
              onClickRight={this.smoothScrollRight}
              scrollFraction={this.state.scrollFraction}
            />
          )}
        </div>
        <div
          className="scrollableTable__inner"
          onScroll={this.handleScroll}
          ref={(element) => (this.tableInner = element)}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

ScrollableTable.propTypes = {
  children: PropTypes.node,
  scrollAmountInPx: PropTypes.number
};

export default ScrollableTable;
