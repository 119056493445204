import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as saveQuickQuoteModalActions from '../../../redux/quickQuote/saveQuickQuoteModalActions';
import * as quickQuoteActions from '../../../redux/quickQuote/quickQuoteActions';
import SaveQuickQuoteModal from '../modals/SaveQuickQuoteModal';

const SaveQuickQuoteModalContainer = ({
  onSubmitModal,
  onSubmitExistingCustomer,
  closeModal,
  submittingState,
  submittingExistingCustomerState,
  dealershipId,
  quote
}) => {
  return (
    <SaveQuickQuoteModal
      submit={onSubmitModal}
      submitExistingCustomer={onSubmitExistingCustomer}
      cancel={closeModal}
      submittingState={submittingState}
      submittingExistingCustomerState={submittingExistingCustomerState}
      dealershipId={dealershipId}
      quote={quote}
    />
  );
};

SaveQuickQuoteModalContainer.propTypes = {
  onSubmitModal: PropTypes.func,
  onSubmitExistingCustomer: PropTypes.func,
  closeModal: PropTypes.func,
  submittingState: PropTypes.string,
  submittingExistingCustomerState: PropTypes.string,
  dealershipId: PropTypes.string
};

function mapStateToProps(state) {
  return {
    submittingState: state.quickQuote.submittingState,
    submittingExistingCustomerState: state.quickQuote.submittingExistingCustomerState,
    dealershipId: state.dealership.Id,
    quote: state.quickQuote.quote
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(saveQuickQuoteModalActions.closeSaveQuickQuoteModal());
    },

    onSubmitModal: (formData) => {
      dispatch(quickQuoteActions.saveQuickQuoteApplication(formData, 1));
    },
    onSubmitExistingCustomer: (dealershipConsumerId) => {
      dispatch(quickQuoteActions.saveQuickQuoteToExistingCustomer(dealershipConsumerId));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveQuickQuoteModalContainer);
