import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const DateFormat = (props) => {
  let date;
  let dateDisplay;

  if (typeof props.value === 'undefined' || props.value === null) {
    dateDisplay = '';
  } else {
    if (props.parse === 'unix') {
      date = moment.unix(props.value);
    } else if (props.parse === 'utc') {
      date = moment(props.value);
    } else {
      date = moment(props.value, props.parse);
    }

    if (props.format === 'timeAgo') {
      dateDisplay = date.fromNow();
    } else if (props.format === 'calendar') {
      dateDisplay = date.calendar(null, {
        sameDay: `[${props.t('DateFormat.today')}] HH:mm`,
        lastDay: `[${props.t('DateFormat.yesterday')}] HH:mm`,
        lastWeek: 'DD MMM YYYY',
        sameWeek: 'DD MMM YYYY',
        sameElse: 'DD MMM YYYY'
      });
    } else {
      dateDisplay = date.format(props.format);
    }

    if (props.value === '') {
      dateDisplay = moment().format(props.format);
    }

    if (props.value < 0) {
      dateDisplay = props.t('DateFormat.no_date');
    }
  }

  return <span data-th={props.dataTh}>{dateDisplay}</span>;
};

DateFormat.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  parse: PropTypes.string,
  format: PropTypes.string.isRequired
};

DateFormat.defaultProps = {
  format: 'DD/MM/YYYY'
};

export default withTranslation('Common')(DateFormat);
