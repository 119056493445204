import closeDealPreFillDataParser from '../core/apiDataParsers/closeDealPrefillDataParser';
import platformApiUtils from '@ivendi/platform-api-utils';
import pubNubPost from '../utils/pubNubPost';
import moment from 'moment';

export function fetchCloseDealPreFillData(consumerId, vehicleId) {
  return platformApiUtils
    .get(`v1/customer/${consumerId}/close-prefill?vehicleId=${vehicleId}`)
    .then(closeDealPreFillDataParser);
}

export function fetchVapProducts(dealershipId) {
  return platformApiUtils.get(`v1/dealershipSettings/${dealershipId}/vap/product-types`);
}

export function fetchVapProductType(dealershipId, productTypeCode) {
  return platformApiUtils.get(`v1/dealershipSettings/${dealershipId}/vaps/${productTypeCode}`);
}

export function markLeadAsNotAvailable(vehicleId, formData = {}, customerId, customerType) {
  return pubNubPost(
    `v1/customer/${customerId}/close`,
    {
      CustomerType: customerType,
      ConsumerId: customerId,
      Closed: true,
      VehicleId: vehicleId,
      NewStatus: 'NotAvailable',
      Notes: formData.Notes,
      RemoveFromDashboard: formData.RemoveFromDashboard
    },
    customerType && customerType.toLowerCase() === 'corporate'
      ? 'CorporateProspectV2ReadModelPublisher/CorporateProspectV2LeadClosed'
      : 'DealershipConsumerReadModelPublisher/DealershipConsumerLeadClosed'
  );
}

export function markLeadAsDead(vehicleId, formData = {}, customerId, customerType) {
  return pubNubPost(
    `v1/customer/${customerId}/close`,
    {
      CustomerType: customerType,
      ConsumerId: customerId,
      Closed: true,
      VehicleId: vehicleId,
      NewStatus: 'NotOfInterest',
      Notes: formData.Notes,
      RemoveFromDashboard: formData.RemoveFromDashboard
    },
    customerType && customerType.toLowerCase() === 'corporate'
      ? 'CorporateProspectV2ReadModelPublisher/CorporateProspectV2LeadClosed'
      : 'DealershipConsumerReadModelPublisher/DealershipConsumerLeadClosed'
  );
}

export function markLeadAsSoldFinance(vehicleId, formData = {}, customerId, customerType) {
  return pubNubPost(
    `v1/customer/${customerId}/close`,
    {
      CustomerType: customerType,
      ConsumerId: customerId,
      Closed: true,
      VehicleId: vehicleId,
      NewStatus: 'SoldFinance',
      TotalPrice: formData.TotalPrice,
      VehiclePrice: formData.VehiclePrice,
      BasicPrice: formData.BasicPrice,
      VAT: formData.VAT,
      VATAmount: formData.VATAmount,
      VATQualifying: formData.VATQualifying,
      NonVatableItems: formData.NonVatableItems,
      AccessoriesInsurance: formData.AccessoriesInsurance,
      AccessoriesWarranty: formData.AccessoriesWarranty,
      AccessoriesOther: formData.AccessoriesOther,
      PartExchange: formData.PartExchange,
      Settlement: formData.Settlement,
      DateOfPurchase: formData.DateOfPurchase ? moment(formData.DateOfPurchase, 'DD/MM/YYYY').toISOString() : undefined,
      DateOfHandover: formData.DateOfHandover ? moment(formData.DateOfHandover, 'DD/MM/YYYY').toISOString() : undefined,
      CashDeposit: formData.CashDeposit,
      FunderCode: formData.FunderCode === 'Other' ? '' : formData.FunderCode,
      OtherFunder: formData.OtherFunder,
      FinanceType: formData.FinanceType,
      Advance: formData.Advance,
      AnnualDistanceQuoted: formData.AnnualDistanceQuoted,
      ChargePerOverDistanceUnit: formData.ChargePerOverDistanceUnit,
      AcceptanceFeeLast: formData.AcceptanceFeeLast,
      OptionToPurchaseFee: formData.OptionToPurchaseFee,
      FlatRate: formData.FlatRate,
      RepresentativeApr: formData.RepresentativeApr,
      Term: formData.Term,
      FirstPayment: formData.FirstPayment,
      FollowedBy: formData.FollowedBy,
      FollowingPayments: formData.FollowingPayments,
      FinalPayment: formData.FinalPayment,
      TotalAmountPayable: formData.TotalAmountPayable,
      InterestCharges: formData.InterestCharges,
      Commission: formData.Commission,
      AdditionalCommission: formData.AdditionalCommission,
      ValueAddedProducts: formData.ValueAddedProducts.map((item) => {
        return {
          Description: item.Description,
          Id: item.Id,
          Name: item.Name,
          Price: item.Price,
          ProductTypeCode: item.ProductTypeCode,
          ProductTypeText: item.ProductTypeText
        };
      }),
      Notes: formData.Notes,
      RemoveFromDashboard: formData.RemoveFromDashboard
    },
    customerType && customerType.toLowerCase() === 'corporate'
      ? 'CorporateProspectV2ReadModelPublisher/CorporateProspectV2LeadClosed'
      : 'DealershipConsumerReadModelPublisher/DealershipConsumerLeadClosed'
  );
}

export function markLeadAsSoldCash(vehicleId, formData = {}, customerId, customerType) {
  return pubNubPost(
    `v1/customer/${customerId}/close`,
    {
      CustomerType: customerType,
      CustomerId: customerId,
      Closed: true,
      VehicleId: vehicleId,
      NewStatus: 'SoldCash',
      TotalPrice: formData.TotalPrice,
      VehiclePrice: formData.VehiclePrice,
      BasicPrice: formData.BasicPrice,
      VAT: formData.VAT,
      VATAmount: formData.VATAmount,
      VATQualifying: formData.VATQualifying,
      NonVatableItems: formData.NonVatableItems,
      AccessoriesInsurance: formData.AccessoriesInsurance,
      AccessoriesWarranty: formData.AccessoriesWarranty,
      AccessoriesOther: formData.AccessoriesOther,
      PartExchange: formData.PartExchange,
      Settlement: formData.Settlement,
      DateOfPurchase: formData.DateOfPurchase ? moment(formData.DateOfPurchase, 'DD/MM/YYYY').toISOString() : undefined,
      DateOfHandover: formData.DateOfHandover ? moment(formData.DateOfHandover, 'DD/MM/YYYY').toISOString() : undefined,
      ValueAddedProducts: formData.ValueAddedProducts,
      Notes: formData.Notes,
      RemoveFromDashboard: formData.RemoveFromDashboard
    },
    customerType && customerType.toLowerCase() === 'corporate'
      ? 'CorporateProspectV2ReadModelPublisher/CorporateProspectV2LeadClosed'
      : 'DealershipConsumerReadModelPublisher/DealershipConsumerLeadClosed'
  );
}

export function markLeadAsPaidOut(vehicleId, formData = {}, customerId, customerType) {
  return pubNubPost(
    `v1/customer/${customerId}/close`,
    {
      CustomerType: customerType,
      CustomerId: customerId,
      Closed: true,
      VehicleId: vehicleId,
      NewStatus: 'SoldPaidOut',
      StandInValue: formData.StandInValue,
      DateOfPurchase: formData.DateOfPurchase ? moment(formData.DateOfPurchase, 'DD/MM/YYYY').toISOString() : undefined,
      DateOfHandover: formData.DateOfHandover ? moment(formData.DateOfHandover, 'DD/MM/YYYY').toISOString() : undefined,
      TotalPrice: formData.TotalPrice,
      VehiclePrice: formData.VehiclePrice,
      BasicPrice: formData.BasicPrice,
      VAT: formData.VAT,
      VATAmount: formData.VATAmount,
      ValueAddedProducts: formData.ValueAddedProducts,
      NonVatableItems: formData.NonVatableItems,
      AccessoriesInsurance: formData.AccessoriesInsurance,
      AccessoriesWarranty: formData.AccessoriesWarranty,
      AccessoriesOther: formData.AccessoriesOther,
      CashDeposit: formData.CashDeposit,
      PartExchange: formData.PartExchange,
      Settlement: formData.Settlement,
      Advance: formData.Advance,
      AmountOfAdvance: formData.AmountOfAdvance,
      Term: formData.Term,
      FirstPayment: formData.FirstPayment,
      FollowedBy: formData.FollowedBy,
      FollowingPayments: formData.FollowingPayments,
      FinalPayment: formData.FinalPayment,
      TotalAmountPayable: formData.TotalAmountPayable,
      AnnualDistanceQuoted: formData.AnnualDistanceQuoted,
      ChargePerOverDistanceUnit: formData.ChargePerOverDistanceUnit,
      RepresentativeApr: formData.RepresentativeApr,
      FlatRate: formData.FlatRate,
      AcceptanceFeeLast: formData.AcceptanceFeeLast,
      OptionToPurchaseFee: formData.OptionToPurchaseFee,
      InterestCharges: formData.InterestCharges,
      Commission: formData.Commission,
      AdditionalCommission: formData.AdditionalCommission,
      Notes: formData.Notes,
      FunderCode: formData.FunderCode === 'Other' ? '' : formData.FunderCode,
      OtherFunder: formData.OtherFunder,
      FinanceType: formData.FinanceType,
      RemoveFromDashboard: formData.RemoveFromDashboard
    },
    customerType && customerType.toLowerCase() === 'corporate'
      ? 'CorporateProspectV2ReadModelPublisher/CorporateProspectV2LeadClosed'
      : 'DealershipConsumerReadModelPublisher/DealershipConsumerLeadClosed'
  );
}

export function reopenLead(vehicleId, customerId, customerType) {
  return pubNubPost(
    `v1/customer/${customerId}/close`,
    {
      CustomerType: customerType,
      ConsumerId: customerId,
      Closed: false,
      VehicleId: vehicleId,
      NewStatus: null,
      RemoveFromDashboard: false
    },
    'DealershipConsumerReadModelPublisher/DealershipConsumerLeadClosed'
  );
}
