import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';

import MoneyFormat from '../../Common/MoneyFormat';
import DateFormat from '../../Common/DateFormat';
import appStore from '../../../mobx-stores/appStore';

import './customerVehicleTab.scss';

const CustomerVehicleTab = (props) => {
  const { t } = useTranslation('Customer');
  const { vehicle, latestPrice } = props;
  const { dateFormat } = props.appStore.uiState;

  return (
    <div className="customerVehicleTab">
      <div className="customerVehicleTab__top">
        <div className="customerVehicleTab__time">
          <DateFormat value={vehicle.CreatedTimestamp} format={`${dateFormat} HH:mm`} />
        </div>
        {appStore.uiState.showVRM && (
          <div data-th="VehicleRegistration" className="customerVehicleTab__reg">
            <div className="customerVehicleTab__regBlue" />
            {vehicle.SanitizedVrm || vehicle.Vrm || 'NO REG'}
          </div>
        )}
      </div>
      <div className="customerVehicleTab__makeModel">{vehicle.Model}</div>
      <div className="customerVehicleTab__price">
        {latestPrice ? (
          <span>
            <MoneyFormat value={latestPrice} />{' '}
            {vehicle.isSold ? (
              <span>{t('CustomerVehicleTab.sold_price')}</span>
            ) : (
              <span>{t('CustomerVehicleTab.latest_price')}</span>
            )}
          </span>
        ) : (
          <span>{t('CustomerVehicleTab.no_quotes_available')}</span>
        )}
      </div>
    </div>
  );
};

CustomerVehicleTab.propTypes = {
  vehicle: PropTypes.object
};

export default inject(['appStore'])(CustomerVehicleTab);
