import React from 'react';
import Icon from './Icon/Icon';
import './attentionPanel.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
const AttentionPanel = ({ header, main, linkText, linkTo }) => (
  <div className="attentionPanel" key="attention">
    <div className="attentionPanel__header">
      <div className="attentionPanel__headerIcon">
        <Icon name="alert" />
      </div>
      {header}
    </div>
    <div className="attentionPanel__content">
      {main}
      {linkTo && linkText && (
        <Link to={linkTo} className="attentionPanel__content__link">
          {linkText}
        </Link>
      )}
    </div>
  </div>
);
AttentionPanel.propTypes = {
  header: PropTypes.node.isRequired,
  main: PropTypes.node.isRequired,
  handleLinkClick: PropTypes.func,
  linkText: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};
export default AttentionPanel;
