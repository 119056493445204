import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';
import * as features from '~/features';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  // The following three fields will only be enabled via a feature switch for now (in the form)
  draft.applicationFields.AffordabilityDetails!.MortgageOrRental = false;
  draft.applicationFields.AffordabilityDetails!.Other = false;
  draft.applicationFields.AffordabilityDetails!.NetMonthly = false;
  delete draft.applicationStatuses!.NotProceeding;
  delete draft.applicationStatuses!.Other;
  delete draft.applicationStatuses!.ProblemPayout;
  delete draft.applicationStatuses!.Prospect;
  delete draft.applicationStatuses!.SentForPayout;
  delete draft.applicationStatuses!.Unknown;
  delete draft.applicationStatuses!.WaitingForFurtherInformation;
  draft.applicationStatuses.Pending!.isEditable = false;
  draft.applicationStatuses.Pending!.isPending = true;
  draft.applicationStatuses.Submitted!.isEditable = false;
  draft.applicationStatuses.Submitted!.isSubmitting = true;
  draft.applicationStatuses.ProposalAdjustedAccepted = {
    isEditable: false,
    isReproposable: true,
    isComplete: true,
    isError: false,
    isSubmitting: false,
    isPaidOut: false,
    isPending: false,
    isAdjusted: true
  };
  draft.editSections!.employment = false;

  delete draft.validationRules.AffordabilityDetails!.Income!.NetMonthly;
  draft.validationRules.AffordabilityDetails!.ReplacementLoan!.DetailsOfReplacementLoan =
    'required:iftrue: IsReplacementLoan';
});
