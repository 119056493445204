import React from 'react';
import PropTypes from 'prop-types';
import VideoTop from '../../Common/VideoTop';
import FCAVideos from '../../Common/FCAVideos';
import './compareQuotesVideos.scss';

const CompareQuotesVideos = ({ quotes, email }) => {
  const type = quotes ? quotes.FinanceType : '';
  let wistiaSource;
  if (type === 'HP') {
    wistiaSource = 'hirepurchase';
  } else if (type === 'PCP') {
    wistiaSource = 'pcpurchase';
  } else if (type === 'CS') {
    wistiaSource = 'hirepurchase';
  } else if (type === 'LP') {
    wistiaSource = 'lpplan';
  }

  return (
    <div className="compareQuotesVideos__wrapper">
      {type ? (
        <div className="compareQuotesVideos__video">
          <VideoTop />
          <FCAVideos source={wistiaSource} email={email} />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

CompareQuotesVideos.propTypes = {
  quotes: PropTypes.object,
  email: PropTypes.string
};

export default CompareQuotesVideos;
