import React from 'react';
import PropTypes from 'prop-types';
import './leagueTableHeaderItem.scss';

const LeagueTableHeaderItem = (props) => {
  return (
    <th className="leagueTableHeaderItem" onClick={() => props.onClick(props.fieldName)}>
      {props.children}
      {props.fieldName === props.sortField && props.fieldName !== undefined && (
        <div className="leagueTableHeaderItem__arrow">
          {props.arrowDirection === 'asc' ? <span>↑</span> : ''}
          {props.arrowDirection === 'desc' ? <span>↓</span> : ''}
        </div>
      )}
    </th>
  );
};

LeagueTableHeaderItem.propTypes = {
  onClick: PropTypes.func,
  arrowDirection: PropTypes.string,
  fieldName: PropTypes.string,
  sortField: PropTypes.string,
  children: PropTypes.node
};

export default LeagueTableHeaderItem;
