import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import CheckBox from '../Common/Form/Checkbox';
import './marketingPreferences.scss';

class MarketingPreferences extends React.Component {
  constructor() {
    super();
    this.state = {
      hasLoaded: false
    };
  }

  UNSAFE_componentWillMount() {
    this.props.appStore.legalDocumentStore
      .fetchLenderMarketing(this.props.funderCode)
      .then(() => {
        this.setState({
          hasLoaded: true
        });
      })
      .catch(() => {
        this.setState({
          hasLoaded: false
        });
      });
  }

  createMarkup = () => {
    let html = this.props.appStore.legalDocumentStore.getLenderMarketing(this.props.funderCode).html;
    html = html.replace(/<a href="(.*?)">/g, '<a href="$1" target="blank" >');
    return {
      __html: html
    };
  };
  toggleMarketing = () => {
    this.props.toggleMarketing();
  };

  render() {
    return (
      <div>
        {this.state.hasLoaded && (
          <div>
            <div className="marketingPreferences__title">
              {this.props.t('MarketingPreferences.marketing_preferences')}
            </div>
            <div dangerouslySetInnerHTML={this.createMarkup()} className="marketingPreferences" />
            <CheckBox value={this.props.hasAcceptedMarketing} onChange={this.toggleMarketing} />
            <div className="marketingPreferences__checkbox">
              {this.props.t('MarketingPreferences.hear_from_you_by_email')}
            </div>
            <div className="benefitsAndRisks__divider2" />
          </div>
        )}
      </div>
    );
  }
}

MarketingPreferences.propTypes = {
  appStore: PropTypes.object,
  funderCode: PropTypes.string,
  toggleMarketing: PropTypes.func,
  hasAcceptedMarketing: PropTypes.bool
};
export default withTranslation('Common')(inject('appStore')(observer(MarketingPreferences)));
