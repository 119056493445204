import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './carFinanceChecksTabsItem.scss';
import MoneyFormat from '../../Common/MoneyFormat';
import DateFormat from '../../Common/DateFormat';
import ConsumerName from '../../Common/ConsumerName';

const CarFinanceChecksTabsItem = ({ check, t }) => {
  let matchRate;

  if (check.BestMatchRate === -2) {
    matchRate = 'expired';
  } else if (check.BestMatchRate < 0) {
    matchRate = 'unknown';
  } else if (check.BestMatchRate > 50) {
    matchRate = 'high';
  } else if (check.BestMatchRate === 50) {
    matchRate = 'medium';
  } else if (check.BestMatchRate < 50) {
    matchRate = 'low';
  }

  return (
    <div className="carFinanceChecksTabsItem">
      <div className="carFinanceChecksTabsItem__consumer">
        <ConsumerName name={check.Firstname + ' ' + check.Surname} />
      </div>
      <div className="carFinanceChecksTabsItem__table">
        <div className="carFinanceChecksTabsItem__row" key="row1">
          <div className="carFinanceChecksTabsItem__tableLeft" key="tl1">
            {t('CarFinanceChecksTabsItem.deposit')}
          </div>
          <div className="carFinanceChecksTabsItem__tableRight" key="tr1">
            <MoneyFormat value={check.Deposit} />
          </div>
        </div>
        <div className="carFinanceChecksTabsItem__row carFinanceChecksTabsItem__rowBottom" key="row2">
          <div className="carFinanceChecksTabsItem__tableLeft" key="tl2">
            {t('CarFinanceChecksTabsItem.term')}
          </div>
          <div className="carFinanceChecksTabsItem__tableRight" key="tr2">
            {check.Term}
          </div>
        </div>
      </div>
      <div className="carFinanceChecksTabsItem__bottomLine">
        <div className="carFinanceChecksTabsItem__date">
          <DateFormat parse="utc" value={check.Created} format="DD MMM YYYY HH:mm" />
        </div>
        <div className="carFinanceChecksTabsItem__status">
          <div className={`carFinanceChecksTabsItem__circle carFinanceChecksTabsItem__${matchRate}`} key="circle" />
          <div className="carFinanceChecksTabsItem__source">{check.CreatedSource}</div>
        </div>
      </div>
    </div>
  );
};

CarFinanceChecksTabsItem.propTypes = {
  check: PropTypes.object,
  width: PropTypes.number
};
export default withTranslation('Customer')(CarFinanceChecksTabsItem);
