import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';

import CheckBox from '~Common/Form/Checkbox';
import StarRating from '~Common/Form/StarRating';
import Button from '~Common/Button/Button';
import Icon from '~Common/Icon/Icon';

import styles from './broadcastStockListFilter.module.scss';
import { isDesktop } from 'react-device-detect';
import { compose } from 'redux';
import { withLocation, withNavigate } from 'hocs/router';

@inject('appStore')
@observer
class BroadcastStockListFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggleFilterMenu = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));
  };
  resetFacets = () => {
    this.props.stockStore.resetFacets();
    this.props.stockStore.fetchStock();
    this.props.navigate({
      pathname: this.props.location.pathname,
      query: undefined
    });
  };

  render() {
    const { isOpen } = this.state;
    const { toggleFilter, stockStore, openDataAccuracyModal } = this.props;
    const { facets } = stockStore;
    const dropDownButtonClass = classnames(styles.button, isOpen && styles.buttonActive);
    const filterListClass = classnames(styles.main, isOpen && styles.mainOpen);
    const resetButtonHasLongText = this.props.i18n.language === 'de' || this.props.i18n.language === 'fr';
    const resetButtonClass = classnames(styles.reset, resetButtonHasLongText && styles.resetSmall);

    return (
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.header} onClick={() => this.toggleFilterMenu()}>
            <p className={styles.topText}>{this.props.t('StockListFilter.filters')}</p>
            <Button className={resetButtonClass} type="button" onClick={this.resetFacets}>
              {this.props.t('StockListFilter.reset')}
            </Button>
            {!isDesktop && <div className={dropDownButtonClass} key="button" />}
          </div>
        </div>
        <div className={filterListClass}>
          {this.props.appStore.uiState.canViewBroadcastDataAccuracy && (
            <div className={styles.section}>
              <div className={styles.bottomText} onClick={openDataAccuracyModal}>
                <p className={styles.sectionTitle}>{this.props.t('StockListFilter.data_accuracy')}</p>
                <div className={styles.dataAccuracyInformation}>
                  <Icon name="question" />
                </div>
              </div>

              <div className={styles.optionContainer} id="1" onClick={() => toggleFilter('accuracyScore', 1)}>
                <CheckBox value={facets['accuracyScore'].indexOf(1) !== -1} />

                <span className={styles.stars}>
                  <StarRating id="1" stars={3} active={3} />
                </span>

                <div className={styles.numberOfVehicles}>{stockStore.counts.accuracyScore[1] || 0}</div>
              </div>
              <div className={styles.optionContainer} id="2" onClick={() => toggleFilter('accuracyScore', 2)}>
                <CheckBox value={facets['accuracyScore'].indexOf(2) !== -1} />

                <span className={styles.stars}>
                  <StarRating stars={3} active={2} />
                </span>

                <div className={styles.numberOfVehicles}>{stockStore.counts.accuracyScore[2] || 0}</div>
              </div>
              <div className={styles.optionContainer} id="3" onClick={() => toggleFilter('accuracyScore', 3)}>
                <CheckBox value={facets['accuracyScore'].indexOf(3) !== -1} />

                <span className={styles.stars}>
                  <StarRating stars={3} active={1} />
                </span>

                <div className={styles.numberOfVehicles}>{stockStore.counts.accuracyScore[3] || 0}</div>
              </div>
              <div className={styles.optionContainer} id="4" onClick={() => toggleFilter('accuracyScore', 4)}>
                <CheckBox value={facets['accuracyScore'].indexOf(4) !== -1} />

                <span className={styles.stars}>
                  <StarRating stars={3} active={0} />
                </span>

                <div className={styles.numberOfVehicles}>{stockStore.counts.accuracyScore[4] || 0}</div>
              </div>
            </div>
          )}

          {this.props.appStore.uiState.canAddVehicles && (
            <div className={styles.section}>
              <div className={styles.bottomText}>
                <p className={styles.sectionTitle}>{this.props.t('StockListFilter.upload_type')}</p>
              </div>
              <div className={styles.optionContainer} onClick={() => toggleFilter('dealerPlatformUpload', true)}>
                <span className={styles.textOptionContainer}>
                  <CheckBox value={facets['dealerPlatformUpload'].indexOf(true) !== -1} />
                  <p className={styles.textOptionTitle}>{this.props.t('StockListFilter.manual_upload')}</p>
                </span>

                <div className={styles.numberOfVehicles}>{stockStore.counts.dealerPlatformUpload[true] || '0'}</div>
              </div>
              <div className={styles.optionContainer} onClick={() => toggleFilter('dealerPlatformUpload', false)}>
                <span className={styles.textOptionContainer}>
                  <CheckBox value={facets['dealerPlatformUpload'].indexOf(false) !== -1} />
                  <p className={styles.textOptionTitle}>{this.props.t('StockListFilter.feed_vehicle')}</p>
                </span>

                <div className={styles.numberOfVehicles}>{stockStore.counts.dealerPlatformUpload[false] || '0'}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

BroadcastStockListFilter.propTypes = {
  stockStore: PropTypes.object,
  openDataAccuracyModal: PropTypes.func,
  toggleFilter: PropTypes.func
};
export default compose(withTranslation('Stock'), withLocation, withNavigate)(BroadcastStockListFilter);
