import { useVisLookupContext, SET_FACET_FILTER } from '../../context/VisLookupContext';

const useFacetFilters = () => {
  const { state, dispatch } = useVisLookupContext();
  const refine = (facets) => {
    dispatch({
      type: SET_FACET_FILTER,
      payload: facets
    });
  };

  return {
    refine,
    facetFilters: state.facetFilters
  };
};

export default useFacetFilters;
