import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import store from '../configureStore';
import { Provider } from 'react-redux';
import '../styles/fonts.scss';

function styleAndPrintPage(iframe) {
  const doc = iframe.contentWindow.document;
  doc.body.style.fontFamily = 'Open Sans';
  doc.documentElement.style.fontSize = '10px';

  window.setTimeout(() => {
    // Chrome sometimes tries to print before rendering is finished (there's probably a nicer way of doing this)
    try {
      if (!iframe.contentWindow.document.execCommand('print', false, null)) {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
      }
    } catch (e) {
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
    }
  }, 300);
}

export const printReactElement = (elementToPrint) => {
  // render specified element to an iFrame and print from there
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';

  if (iframe.attachEvent) {
    iframe.attachEvent('onload', () => {
      const node = document.createElement('div');
      node.setAttribute('id', 'render-target');
      iframe.contentWindow.document.body.appendChild(node);

      ReactDOM.render(
        <Suspense fallback={<></>}>
          <Provider store={store}>{elementToPrint}</Provider>
        </Suspense>,
        iframe.contentWindow.document.getElementById('render-target')
      );

      styleAndPrintPage(iframe);
    });
  } else {
    iframe.onload = () => {
      const node = document.createElement('div');
      node.setAttribute('id', 'render-target');
      iframe.contentWindow.document.body.appendChild(node);

      ReactDOM.render(
        <Suspense fallback={<></>}>
          <Provider store={store}>{elementToPrint}</Provider>
        </Suspense>,
        iframe.contentWindow.document.getElementById('render-target')
      );

      styleAndPrintPage(iframe);
    };
  }

  iframe.src = 'about:blank';
  document.body.appendChild(iframe);
};

export const printDOMNode = (nodeToPrint) => {
  const iframe = document.createElement('iframe');

  const domCopy = nodeToPrint.cloneNode(true);

  if (iframe.attachEvent) {
    iframe.attachEvent('onload', () => {
      iframe.contentWindow.document.body.appendChild(domCopy);
      styleAndPrintPage(iframe);
    });
  } else {
    iframe.onload = () => {
      iframe.contentWindow.document.body.appendChild(domCopy);
      styleAndPrintPage(iframe);
    };
  }

  iframe.src = 'about:blank';
  iframe.style.visibility = 'hidden';
  iframe.style.position = 'absolute';
  iframe.style.zIndex = '-1';
  document.body.appendChild(iframe);
};

export const printPageByRef = (printAreaRef) => {
  const printSection = document.createElement('div');
  printSection.id = 'printSection';
  document.body.appendChild(printSection);
  const clone = printAreaRef.current.cloneNode(true);
  printSection.appendChild(clone);

  // Print for Safari browser as window.print() does not work
  const printSuccessful = document.execCommand('print', false, null);

  if (!printSuccessful) {
    window.print();
  }

  /*
   * Removes appended printSection from DOM or it will persist and the
   * content will be printed every time the print button is clicked
   */
  document.body.removeChild(printSection);
};
