import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../../Common/Icon/Icon';
import ToggleButtonWithIcon from '../../Application/components/ToggleButtonWithIcon';
import './applicationSummaryPanelHeader.scss';

const ApplicationSummaryPanelHeader = ({
  errorCount,
  title,
  errorMessage,
  showHideDetails,
  hideDetails,
  t,
  showValidationTicks,
  children
}) => {
  const titleStyles = classnames(
    'applicationSummaryPanelHeader__title',
    errorCount && 'applicationSummaryPanelHeader__title--error'
  );
  const iconStyles = classnames(
    'applicationSummaryPanelHeader__tickCross',
    'applicationSummaryPanelHeader__tickCross--isValid',
    errorCount > 0 && 'applicationSummaryPanelHeader__tickCross--isInvalid'
  );
  return (
    <div className="applicationSummaryPanelHeader">
      <div>
        <span className={titleStyles} key="title">
          {title}
        </span>
        <span className="applicationSummaryPanelHeader__subTitle" key="subTitle">
          {errorCount ? errorMessage : '\u00a0'}
        </span>
      </div>
      <div className="applicationSummaryPanelHeader__child">
        {showHideDetails && (
          <div className="applicationSummaryPanelHeader__bankDetails">
            <ToggleButtonWithIcon
              onClick={showHideDetails}
              hideDetails={hideDetails}
              icon="eye"
              text={t('ApplicationSummaryPanelHeader.bank_details')}
            />
          </div>
        )}

        <div className={iconStyles}>
          {showValidationTicks && <Icon name={errorCount > 0 ? 'validation-cross' : 'validation-tick'} />}
        </div>
        {children}
      </div>
    </div>
  );
};

ApplicationSummaryPanelHeader.propTypes = {
  title: PropTypes.string,
  errorCount: PropTypes.number,
  errorMessage: PropTypes.string,
  showValidationTicks: PropTypes.bool,
  children: PropTypes.any,
  showHideDetails: PropTypes.func,
  hideDetails: PropTypes.bool
};
export default withTranslation('Application')(ApplicationSummaryPanelHeader);
