import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './assignedToText.scss';

const AssignedToText = (props) => {
  const { t } = useTranslation('AssignLead');
  return props.assignees && props.assignees.length ? (
    <h4 className="assignedToText">
      {t('AssignedToText.assigned_to')} {`${props.assignees[0].firstName} ${props.assignees[0].surname}`}{' '}
      {props.assignees.length - 1 ? `+${props.assignees.length - 1}` : null}
    </h4>
  ) : null;
};

AssignedToText.propTypes = {
  assignees: PropTypes.array
};
export default AssignedToText;
