import PropTypes from 'prop-types';
import QuoteNotification from './QuoteNotification';
import './erroredQuote.scss';

const ErroredQuote = (props) => (
  <div className="erroredQuote">
    <QuoteNotification errorNumber={props.errorNumber} errorMessage={props.errorMessage} />
    {!props.noBorder && <div className="erroredQuote__border" key="borderRight" />}
  </div>
);

ErroredQuote.propTypes = {
  errorNumber: PropTypes.number,
  errorMessage: PropTypes.string,
  noBorder: PropTypes.bool
};

export default ErroredQuote;
