import React from 'react';
import PropTypes from 'prop-types';
import './dashboardBusinessTag.scss';
import businessTagDark from '../../../styles/icons/business_tag_dark.svg';
import briefcaseSmall from '../../../styles/icons/business_tag_small_dark.svg';

const DashboardBusinessTag = ({ smallTag }) => (
  <div className="dashboardBusinessTag">
    <div className="dashboardBusinessTag__cont">
      {smallTag ? (
        <img className="dashboardBusinessTag__briefcase" src={briefcaseSmall} />
      ) : (
        <img className="dashboardBusinessTag__image" src={businessTagDark} />
      )}
    </div>
  </div>
);

DashboardBusinessTag.defaultProps = {
  smallTag: false
};

DashboardBusinessTag.propTypes = {
  smallTag: PropTypes.bool
};

export default DashboardBusinessTag;
