import platformApiUtils from '@ivendi/platform-api-utils';
import { z } from 'zod';
import * as debug from '../../debug';

export const LookupByEmailResponseSchema = z.object({
  Exists: z.boolean(),
  DealershipConsumer: z
    .object({
      Id: z.string()
    })
    .nullable()
});

export type LookupByEmailResponse = z.infer<typeof LookupByEmailResponseSchema>;

export function lookupByEmail({
  email,
  dealershipId,
  sector = 'residential'
}: {
  email: string;
  dealershipId: string;
  sector: 'corporate' | 'residential';
}) {
  let endpoint = sector === 'corporate' ? 'v1/CorporateCustomer/email' : 'v1/dealershipconsumer/email';

  return platformApiUtils
    .get<LookupByEmailResponse>(`${endpoint}/${encodeURIComponent(email)}`, { dealershipId })
    .then((response) => {
      const parsedResponse = LookupByEmailResponseSchema.parse(response);

      if (parsedResponse?.Exists) {
        return response.DealershipConsumer;
      } else {
        return null;
      }
    })
    .catch((e: any) => {
      debug.error(e);
      return null;
    });
}

export function lookupCustomerByEmail(email: string, dealershipId: string) {
  return new Promise((resolve, reject) => {
    platformApiUtils
      .get(`v1/dealershipconsumer/email/${encodeURIComponent(email)}`, { dealershipId })
      .then((response: any) => {
        if (response?.Exists) {
          resolve(response.DealershipConsumer);
        } else {
          debug.error(`lookupCustomerByEmail`);
          reject();
        }
      })
      .catch((e) => {
        debug.error(e);
        reject();
      });
  });
}

export function lookupCorporateCustomerByEmail(email: string, dealershipId: string) {
  return new Promise((resolve, reject) => {
    platformApiUtils
      .get(`v1/CorporateCustomer/email/${encodeURIComponent(email)}`, { dealershipId })
      .then((response: any) => {
        if (response?.Exists) {
          resolve(response.DealershipConsumer);
        } else {
          reject();
        }
      })
      .catch((e) => {
        debug.error(e);
        reject();
      });
  });
}

export function lookupCorporateCustomerByTradingName(tradingName: string, dealershipId: string) {
  return new Promise((resolve, reject) => {
    platformApiUtils
      .get(`v1/CorporateCustomer/tradingName/${encodeURIComponent(tradingName)}`, { dealershipId })
      .then((response: any) => {
        if (response?.Exists) {
          resolve(response.DealershipConsumer);
        } else {
          reject();
        }
      });
  });
}

export function lookupProductName(name: string, dealershipId: string) {
  return new Promise((resolve, reject) => {
    platformApiUtils.get(`v1/DealershipSettings/${dealershipId}/vap/name-exists`, { name }).then((response: any) => {
      if (response?.Exists) {
        resolve(true);
      } else {
        reject();
      }
    });
  });
}
