import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import QuoteSmallPrint from './QuoteSmallPrint';
import FunderLogo from '../../Common/FunderLogo';
import ProductName from '../../QuotesList/components/ProductName';
import MoneyFormat from '../../Common/MoneyFormat';
import QuoteInfoLabel from './QuoteInfoLabel';
import QuoteInfoValue from './QuoteInfoValue';
import FormatAPR from './FormatAPR';
import './quoteInfoCard.scss';

const QuoteInfo = ({ quote }) => {
  const { t } = useTranslation('Quoting');
  return (
    <div className="quoteInfoCard">
      <div className="quoteInfoCard__row">
        <div className="quoteInfoCard__funderLogo">
          <FunderLogo funder={quote.FunderCode} />
        </div>
      </div>

      <div className="quoteInfoCard__row">
        <div className="quoteInfoCard__cell">
          <QuoteInfoLabel>{t('QuoteInfo.product')}</QuoteInfoLabel>
          <QuoteInfoValue>
            <ProductName productName={quote.ProductName} />
          </QuoteInfoValue>
        </div>
      </div>

      <div className="quoteInfoCard__row">
        <div className="quoteInfoCard__cell">
          <QuoteInfoLabel>{t('QuoteInfo.apr')}</QuoteInfoLabel>
          <QuoteInfoValue>
            <FormatAPR value={quote.RepresentativeApr} />
          </QuoteInfoValue>
        </div>
        <div className="quoteInfoCard__cell">
          <QuoteInfoLabel>{t('QuoteInfo.total_amount_payable')}</QuoteInfoLabel>
          <QuoteInfoValue>
            <MoneyFormat value={quote.TotalAmountPayable} />
          </QuoteInfoValue>
        </div>
      </div>

      <div className="quoteInfoCard__row">
        <div className="quoteInfoCard__cell">
          <QuoteInfoLabel>{t('QuoteInfo.agreement_term')}</QuoteInfoLabel>
          <QuoteInfoValue>{quote.Term + ' ' + t('QuoteInfo.months')}</QuoteInfoValue>
        </div>
        <div className="quoteInfoCard__cell">
          <QuoteInfoLabel>{t('QuoteInfo.monthly_payments')}</QuoteInfoLabel>
          <QuoteInfoValue>
            <MoneyFormat value={quote.FollowingPayments} />
          </QuoteInfoValue>
        </div>
      </div>

      <div className="quoteInfoCard__bottom">
        <QuoteSmallPrint quote={quote} />
      </div>
    </div>
  );
};

QuoteInfo.propTypes = {
  quote: PropTypes.object
};
export default QuoteInfo;
