import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import VehicleImage from './VehicleImage';
import './vehicleImageWithVrm.scss';
import appStore from '../../mobx-stores/appStore';

const VehicleImageWithVrm = ({ image, vrm, vehicleClass }) => {
  const { t } = useTranslation('Common');

  const vehiclesClass = vehicleClass || t('VehicleImageWithVrm.car');

  return (
    <div className="vehicleImageWithVrm" data-th="VehicleImageWithVrm">
      <VehicleImage
        imageSource={image}
        imageWidth="130px"
        imageHeight="90px"
        vehiclesClass={vehiclesClass}
        iconFontSize="45px"
      />
      {appStore.uiState.showVRM && (
        <div className="vehicleImageWithVrm__vrm">
          <div className="vehicleImageWithVrm__blueBit" />
          <div className="vehicleImageWithVrm__yellowBit">{vrm}</div>
        </div>
      )}
    </div>
  );
};

VehicleImageWithVrm.propTypes = {
  image: PropTypes.string,
  vrm: PropTypes.string,
  vehicleClass: PropTypes.string
};

export default VehicleImageWithVrm;
