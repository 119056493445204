import _ from 'lodash';
import * as actionTypes from '../actionTypes';

const defaultState = {};

export default function modalReducer(state = defaultState, action) {
  let newState;

  switch (action.type) {
    case actionTypes.OPEN_MODAL:
      return Object.assign({}, state, {
        [action.name]: {
          isOpen: true,
          hasLoaded: false,
          ...action.params
        }
      });

    case actionTypes.CLOSE_MODAL:
      newState = _.cloneDeep(state);
      newState[action.name].isOpen = false;
      return newState;

    case actionTypes.SETUP_PRODUCT_INFO_MODAL:
      return Object.assign({}, state, {
        quoteFinanceType: action.quoteFinanceType,
        isPersonalLoan: action.isPersonalLoan,
        funderCode: action.funderCode
      });

    case actionTypes.CLEANUP_PRODUCT_INFO_MODAL:
      return Object.assign({}, state, {
        quoteFinanceType: null
      });

    //be nice to make this state an immutable map
    case actionTypes.UPDATE_MODAL_STATE:
      newState = _.cloneDeep(state);
      newState[action.name] = Object.assign({}, newState[action.name], action.update, { hasLoaded: true });
      return Object.assign({}, state, newState);

    default:
      return state;
  }
}
