import React from 'react';
import PropTypes from 'prop-types';
import ProceedWithCorporateQuickQuoteForm from '../forms/ProceedFromCorporateQuickQuoteForm';
import './proceedFromCorporateQuickQuoteModal.scss';
import Modal from '../../Common/Modal/Modal';

const ProceedFromCorporateQuickQuoteModal = ({ closeModal, quote, vehicle, dealershipId, isOpen }) => (
  <Modal isOpen={isOpen} onClose={closeModal}>
    <div>
      <h1 className="proceedFromCorporateQuickQuoteModal__header">Proceed to Application</h1>
      <ProceedWithCorporateQuickQuoteForm
        quote={quote}
        vehicle={vehicle}
        dealershipId={dealershipId}
        closeModal={closeModal}
      />
    </div>
  </Modal>
);

ProceedFromCorporateQuickQuoteModal.propTypes = {
  closeModal: PropTypes.func,
  quote: PropTypes.object,
  vehicle: PropTypes.object,
  dealershipId: PropTypes.string,
  isOpen: PropTypes.bool
};

export default ProceedFromCorporateQuickQuoteModal;
