import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { inject } from 'mobx-react';
import { useParams } from 'react-router-dom';

import Modal from '~Common/Modal/Modal';
import { deleteVehicle } from '~/api/stockModule';
import * as debug from '~/debug';
import { AppStore } from '../../../../../../../../types/Types';

import classes from './deleteModal.module.scss';

interface Props {
  vehicleId: string;
  appStore: AppStore;
  closeDeleteModal: () => void;
  isDeleteModalOpen: boolean;
}
const DeleteModal = ({ vehicleId, appStore, closeDeleteModal, isDeleteModalOpen }: Props) => {
  const params = useParams();
  const { t } = useTranslation('Stock');
  const [formState, setFormState] = useState<'idle' | 'loading' | 'error'>('idle');
  const { dealershipId } = params;
  const { stockStore } = appStore;
  const { pushDangerNotification, pushSuccessNotification } = appStore.notificationStore;
  const isLoading = formState === 'loading';
  const hasError = formState === 'error';

  const handleDelete = async () => {
    setFormState('loading');
    try {
      await deleteVehicle(dealershipId, vehicleId);
      setFormState('idle');
      closeDeleteModal();

      const deletedVehiclesIds = JSON.parse(sessionStorage?.getItem('deletedVehiclesIds') as string) || [];
      deletedVehiclesIds.push(vehicleId);
      sessionStorage.setItem('deletedVehiclesIds', JSON.stringify(deletedVehiclesIds));

      stockStore.resetFacets();
      stockStore.fetchStock();
      window.scrollTo(0, 0);
      pushSuccessNotification(t('DeleteButton.vehicle_deleted'), t('DeleteButton.success'));
    } catch (e) {
      debug.error(e);
      setFormState('error');
      pushDangerNotification(t('DeleteButton.problem_getting_vehicle'), t('DeleteButton.danger'));
    }
  };

  const loadingState = isLoading ? 'loading' : hasError ? 'error' : 'default';
  return (
    <Modal
      title={t('DeleteButton.are_you_sure')}
      onClose={closeDeleteModal}
      isOpen={!!isDeleteModalOpen}
      onConfirm={handleDelete}
      buttonText={t('DeleteButton.delete_vehicle')}
      isLoading={loadingState === 'loading'}
      hasError={loadingState === 'error'}
    >
      <div className={classes.confirmationModal}>
        {t('DeleteButton.if_you_proceed_this_vehicle_will_be_permanently_deleted_this_action_is_irreversible')}
        <br />
        <br />
        {t('DeleteButton.vehicles_may_take_several_minutes_to_be_removed_from_the_stock_list')}
      </div>
    </Modal>
  );
};

export default inject('appStore')(DeleteModal);

