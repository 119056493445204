import React from 'react';
import { useTranslation } from 'react-i18next';

import VisVehicleCard from '~Common/VisVehicleCard';
import LoadingDots from '~Common/Loading/LoadingDots';

import { useSearchState, useMetaData, useSelectedVehicle } from '../../hooks';

import './hits.scss';

const Hits = () => {
  const { t } = useTranslation('Stock');
  const searchState = useSearchState();
  const { selectedVehicleId, refine } = useSelectedVehicle();
  const { vehicles, hasResults } = useMetaData();

  const filteredVehicles =
    selectedVehicleId !== null ? vehicles.filter((vehicle) => vehicle.objectID === selectedVehicleId) : vehicles;
  const displayLoading = searchState === 'loading';
  const displayErrorMessage = searchState === 'error';

  const handleCardClick = (objectId) => {
    const payload = objectId === selectedVehicleId ? null : objectId;
    refine(payload);
  };

  return (
    <>
      {hasResults &&
        filteredVehicles.map((vehicle, i) => {
          return (
            <VisVehicleCard
              capIdForComparison={vehicle.CapId}
              vehicle={vehicle}
              key={i}
              isSelected={vehicle.objectID === selectedVehicleId}
              onClick={() => {
                handleCardClick(vehicle.objectID);
              }}
            />
          );
        })}
      {displayLoading && (
        <div className="hits__loadingIndicator">
          <LoadingDots />
        </div>
      )}
      {displayErrorMessage && (
        <div className="hits__Error">
          <p className="hits__ErrorMessage">{t('Hits.an_error_has_occurred_please_try_again_later')}</p>
        </div>
      )}
    </>
  );
};

export default Hits;
