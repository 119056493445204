import { getSessionId } from '../sessionId';
import * as debug from '../debug';

export const getBroadcastSubscriptions = async (dealerId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_STOCK_API_PATH}/DealerBroadcast/${dealerId}/subscriptions`, {
      headers: {
        'content-type': 'application/json'
      },
      method: 'GET'
    });
    if (!response.ok) {
      const errorText = await createErrorResponse(response, 'getBroadcastSubscriptions');
      throw new Error(errorText);
    }

    return await response.json();
  } catch (err) {
    debug.error(err);
    return Promise.reject();
  }
};

export const getBroadcastSubscription = async (dealerId, subscriptionId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_STOCK_API_PATH}/DealerBroadcast/${dealerId}/subscription/${subscriptionId}`,
      {
        headers: {
          'content-type': 'application/json'
        },
        method: 'GET'
      }
    );
    if (!response.ok) {
      const errorText = await createErrorResponse(response, 'getBroadcastSubscription');
      throw new Error(errorText);
    }

    return await response.json();
  } catch (err) {
    debug.error(err);
    return Promise.reject();
  }
};

export const createNewBroadcast = async (dealerId, data) => {
  try {
    const correlationId = getSessionId();
    const response = await fetch(`${process.env.REACT_APP_STOCK_API_PATH}/DealerBroadcast/${dealerId}/subscribe`, {
      headers: {
        'content-type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        correlationId,
        ...data
      })
    });
    if (!response.ok) {
      const errorText = await createErrorResponse(response, 'createNewBroadcast');
      throw new Error(errorText);
    }

    return await response.json();
  } catch (err) {
    debug.error(err);
    return Promise.reject();
  }
};

export const deleteBroadcast = async (dealerId, id) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_STOCK_API_PATH}/DealerBroadcast/${dealerId}/subscription/${id}`,
      {
        headers: {
          'content-type': 'application/json'
        },
        method: 'DELETE'
      }
    );
    if (!response.ok) {
      const errorText = await createErrorResponse(response, 'deleteBroadcast');
      throw new Error(errorText);
    }

    return await response.json();
  } catch (err) {
    debug.error(err);
    throw new Error(err);
  }
};
export const updateBroadcastSubscription = async (dealershipId, subscriptionId, data) => {
  try {
    const correlationId = await getSessionId();
    const response = await fetch(
      `${process.env.REACT_APP_STOCK_API_PATH}/DealerBroadcast/${dealershipId}/subscription/${subscriptionId}`,
      {
        headers: {
          'content-type': 'application/json'
        },
        method: 'PUT',
        body: JSON.stringify({
          correlationId,
          ...data
        })
      }
    );
    if (!response.ok) {
      const errorText = await createErrorResponse(response, 'updateBroadcastFilters');
      throw new Error(errorText);
    }

    return await response.json();
  } catch (err) {
    debug.error(err);
    return Promise.reject();
  }
};
export const updateBroadcastFilters = async (dealershipId, subscriptionId, data) => {
  try {
    const correlationId = await getSessionId();
    const response = await fetch(
      `${process.env.REACT_APP_STOCK_API_PATH}/DealerBroadcast/${dealershipId}/subscription/${subscriptionId}/filters`,
      {
        headers: {
          'content-type': 'application/json'
        },
        method: 'PUT',
        body: JSON.stringify({
          correlationId,
          ...data
        })
      }
    );
    if (!response.ok) {
      const errorText = await createErrorResponse(response, 'updateBroadcastFilters');
      throw new Error(errorText);
    }

    return await response.json();
  } catch (err) {
    debug.error(err);
    return Promise.reject();
  }
};

export const updateBroadcastStatus = async (dealerId, subscriptionId, status) => {
  try {
    const correlationId = getSessionId();
    const response = await fetch(
      `${process.env.REACT_APP_STOCK_API_PATH}/DealerBroadcast/${dealerId}/subscription/${subscriptionId}/${
        status === 'disabled' ? 'disable' : 'enable'
      }`,
      {
        headers: {
          'content-type': 'application/json'
        },
        method: 'PUT',
        body: JSON.stringify({
          correlationId
        })
      }
    );
    if (!response.ok) {
      const errorText = await createErrorResponse(response, 'updateBroadcastStatus');
      throw new Error(errorText);
    }

    return await response.json();
  } catch (err) {
    debug.error(err);
    return Promise.reject();
  }
};

// response type basic
const createErrorResponse = async (response, fnName) => {
  return `${fnName} status: ${response.status} ${await response?.text()}`;
};
