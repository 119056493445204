import React from 'react';
import cn from 'classnames';

import Icon from '~Common/Icon/Icon';

import './iconButton.scss';

const IconButton = ({ name, handleClick, style, danger, className }) => {
  return (
    <button
      style={style}
      className={cn('IconButton', danger && 'IconButton--danger', className)}
      onClick={handleClick}
      type="button"
    >
      <Icon name={name} />
    </button>
  );
};

export default IconButton;
