import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Common/Modal/Modal';
import Button from '../../Common/Button/Button';
import { observer, inject } from 'mobx-react';
import './crainDisclaimerModal.scss';

class CrainDisclaimerModal extends React.Component {
  constructor() {
    super();
    this.state = {
      hasLoaded: false,
      hasError: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen && !this.props.isOpen) {
      this.props.appStore.legalDocumentStore
        .fetchCrainDisclaimer()
        .then(() => {
          this.setState({
            hasLoaded: true
          });
        })
        .catch(() => {
          this.setState({
            hasError: true
          });
        });
    }

    if (!nextProps.isOpen && this.props.isOpen) {
      this.setState({
        hasLoaded: false,
        hasError: false
      });
    }
  }

  createMarkup = () => {
    let html = this.props.appStore.legalDocumentStore.CrainDisclaimer.html;
    html = html.replace('code', 'p');
    html = html.replace(/<pre>/g, '<div >');
    return {
      __html: html
    };
  };

  render() {
    let { isOpen, onClose, onContinue } = this.props;

    return (
      <Modal isOpen={isOpen} onClose={onClose} title={this.props.t('CrainDisclaimerModal.before_you_continue')}>
        {this.state.hasLoaded && (
          <div>
            <div className="crainDisclaimerModal">
              <div dangerouslySetInnerHTML={this.createMarkup()} />
            </div>
            <div className="crainDisclaimerModal__footer">
              <div className="crainDisclaimerModal__button">
                <Button buttonStyle="cancel" onClick={onClose} type="button" id="cancelFpn">
                  {this.props.t('CrainDisclaimerModal.cancel')}
                </Button>
              </div>
              <div className="crainDisclaimerModal__button">
                <Button onClick={onContinue} id="continueFpn">
                  {this.props.t('CrainDisclaimerModal.view_results')}
                </Button>
              </div>
            </div>
          </div>
        )}

        {this.state.hasError && (
          <div>
            <div className="crainDisclaimerModal">
              {this.props.t('CrainDisclaimerModal.an_error_occurred_please_try_again_later')}
            </div>
            <div className="crainDisclaimerModal__footer">
              <div className="crainDisclaimerModal__button">
                <Button buttonStyle="cancel" onClick={onClose} type="button" id="cancelFpn">
                  {this.props.t('CrainDisclaimerModal.cancel')}
                </Button>
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

CrainDisclaimerModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  funderCode: PropTypes.string,
  onContinue: PropTypes.func,
  appStore: PropTypes.object
};
export default withTranslation('Quoting')(inject('appStore')(observer(CrainDisclaimerModal)));
