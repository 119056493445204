import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SplitButton from '../../Common/SplitButton';
import SplitButtonOption from '../../Common/SplitButtonOption';
import SplitButtonOptionDefault from '../../Common/SplitButtonOptionDefault';
import QuoteButtonWrapper from './QuoteButtonWrapper';

const QuoteSplitButton = ({ isSelected, isQuickQuote, state, onSave, onProceed }) => {
  const { t } = useTranslation('Quoting');

  return (
    <QuoteButtonWrapper isSelected={isSelected}>
      {isQuickQuote ? (
        <SplitButton state={state}>
          <SplitButtonOptionDefault onClick={onSave}>{t('QuoteSplitButton.save_quote')}</SplitButtonOptionDefault>
          <SplitButtonOption onClick={onProceed} iconName="application">
            {t('QuoteSplitButton.proceed_to_application')}
          </SplitButtonOption>
        </SplitButton>
      ) : (
        <SplitButton state={state}>
          <SplitButtonOptionDefault onClick={onProceed}>{t('QuoteSplitButton.proceed')}</SplitButtonOptionDefault>
          <SplitButtonOption onClick={onSave} iconName="application">
            {t('QuoteSplitButton.save_quote')}
          </SplitButtonOption>
        </SplitButton>
      )}
    </QuoteButtonWrapper>
  );
};

QuoteSplitButton.propTypes = {
  onSave: PropTypes.func,
  onProceed: PropTypes.func,
  isQuickQuote: PropTypes.bool,
  isSelected: PropTypes.bool,
  state: PropTypes.string
};

export default QuoteSplitButton;
