import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './settlementCompleteModalContents.scss';
import Icon from '../../Common/Icon/Icon';
import CurrencyInput from '../../Common/Form/CurrencyInput';
import Button from '../../Common/Button/Button';
import LoadingSpinnerTailSpin from '../../Common/Loading/LoadingSpinnerTailSpin';
import moment from 'moment';

const SettlementCompleteModalContent = ({
  isLoading,
  isSuccessful,
  settlementFigure,
  statusCode,
  timeout,
  expiry,
  onClose,
  resubmit,
  hasError
}) => {
  const { t } = useTranslation('Quoting');

  if (isLoading) {
    return (
      <div>
        <div className="settlementCompleteModalContents__loadingSpinner">
          <LoadingSpinnerTailSpin />
        </div>
        <div className="settlementCompleteModalContents__primaryText">
          {t('SettlementCompleteModalContent.finding_settlement_figure')}
        </div>
        <div className="settlementCompleteModalContents__secondaryText">
          {t('SettlementCompleteModalContent.this_shouldnt_take_long')}
        </div>
      </div>
    );
  }

  if (isSuccessful && settlementFigure) {
    return (
      <div>
        <div className="settlementCompleteModalContents__icon">
          <Icon name="validation-tick" />
        </div>
        <div className="settlementCompleteModalContents__primaryText">
          {t('SettlementCompleteModalContent.the_settlement_figure_has_been_added_to_the_deal')}
        </div>
        <div className="settlementCompleteModalContents__input">
          <CurrencyInput value={settlementFigure} disabled id="settlementFigureResult" />
        </div>
        <div className="settlementCompleteModalContents__expiry">
          {t('SettlementCompleteModalContent.this_settlement_figure_is_valid_until')}{' '}
          {moment(expiry).format('DD/MM/YYYY')}.
        </div>

        <div className="settlementCompleteModalContents__footer--singleButton">
          <div className="settlementCompleteModalContents__button">
            <Button onClick={onClose} type="button" id="returnToDealButton">
              {t('SettlementCompleteModalContent.return_to_deal')}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (hasError) {
    return (
      <div>
        <div className="settlementCompleteModalContents__icon">
          <Icon name="validation-cross" />
        </div>
        <div className="settlementCompleteModalContents__primaryText">
          {t('SettlementCompleteModalContent.there_was_a_problem_finding_your_requested_settlement_figure')}
        </div>
        <div className="settlementCompleteModalContents__secondaryText">
          {t('SettlementCompleteModalContent.please_enter_the_settlement_amount_manually_or_try_again')}
        </div>

        <div className="settlementCompleteModalContents__footer">
          <div className="settlementCompleteModalContents__button">
            <Button onClick={onClose} buttonStyle="cancel" type="button" id="cancelSettlement">
              {t('SettlementCompleteModalContent.cancel')}
            </Button>
          </div>
          <div className="settlementCompleteModalContents__button">
            <Button onClick={resubmit} type="button" id="tryAgainSettlement">
              {t('SettlementCompleteModalContent.try_again')}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  let errorMessage = null;

  switch (statusCode) {
    case 400:
    case 401:
    case 500:
    case 503:
      errorMessage = t('SettlementCompleteModalContent.please_enter_the_settlement_amount_manually_or_try_again');
      break;

    case 403:
      errorMessage = t(
        'SettlementCompleteModalContent.the_agreement_doesnt_belong_to_this_customer_please_check_the_customer_surname_and_vrm_and_try_again'
      );
      break;

    case 404:
      errorMessage = t(
        'SettlementCompleteModalContent.there_was_no_agreement_found_for_the_id_you_entered_please_enter_the_settlement_amount_manually'
      );
      break;

    default:
      errorMessage = null;
  }

  if (timeout) {
    errorMessage = t(
      'SettlementCompleteModalContent.the_moto_novo_settlement_service_took_too_long_to_respond_please_enter_the_settlement_amount_manually'
    );
  }

  return (
    <div>
      <div className="settlementCompleteModalContents__icon">
        <Icon name="validation-cross" />
      </div>
      <div className="settlementCompleteModalContents__primaryText">
        {t('SettlementCompleteModalContent.there_was_a_problem_finding_your_requested_settlement_figure')}
      </div>
      <div className="settlementCompleteModalContents__secondaryText">{errorMessage}</div>

      <div className="settlementCompleteModalContents__footer">
        <div className="settlementCompleteModalContents__button">
          <Button onClick={onClose} buttonStyle="cancel" type="button" id="cancelSettlement">
            {t('SettlementCompleteModalContent.cancel')}
          </Button>
        </div>
        <div className="settlementCompleteModalContents__button">
          <Button onClick={resubmit} type="button" id="tryAgainSettlement">
            {t('SettlementCompleteModalContent.try_again')}
          </Button>
        </div>
      </div>
    </div>
  );
};

SettlementCompleteModalContent.propTypes = {
  isLoading: PropTypes.bool,
  isSuccessful: PropTypes.bool,
  settlementFigure: PropTypes.number,
  statusCode: PropTypes.number,
  timeout: PropTypes.number,
  expiry: PropTypes.string,
  onClose: PropTypes.func,
  resubmit: PropTypes.func,
  hasError: PropTypes.bool
};
export default SettlementCompleteModalContent;
