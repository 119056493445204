import { VapProductTypes } from '../../constants';
import _ from 'lodash';
import { parsePaymentSchedules } from './quoteParser';
import { getVehicleLoanProductId } from '../../components/PushDeal/utils/quoteUtils';

export function parseQuotes(quotes) {
  return _.map(quotes, (quote) => {
    // creation combined quotes
    if (quote.AggregateQuote && quote.VehicleLoan) {
      let VehicleLoan = quote.VehicleLoan;
      quote.AggregateQuote.VehicleLoan = {
        ...VehicleLoan,
        PaymentSchedule: parsePaymentSchedules(VehicleLoan)
      };

      if (quote.PersonalLoan) {
        let PersonalLoan = quote.PersonalLoan;
        quote.AggregateQuote.PersonalLoan = {
          ...PersonalLoan,
          PaymentSchedule: parsePaymentSchedules(PersonalLoan)
        };
        quote.AggregateQuote.ProductName = `Creation ${quote.VehicleLoan.FinanceType} with Personal Loan`;
      }

      if (quote.NegativeEquityLoan) {
        let NegativeEquityLoan = quote.NegativeEquityLoan;
        quote.AggregateQuote.NegativeEquityLoan = {
          ...NegativeEquityLoan,
          PaymentSchedule: parsePaymentSchedules(NegativeEquityLoan)
        };
        quote.AggregateQuote.ProductName = `Creation ${quote.VehicleLoan.FinanceType} with Personal Loan`;
      }

      quote = quote.AggregateQuote;
    }

    if (quote.Errors && quote.Errors.hasPublicErrors) {
      return {
        ...quote,
        Errors: quote.Errors.PublicErrors
      };
    }

    return {
      ...quote,
      PaymentSchedule: parsePaymentSchedules(quote)
    };
  });
}

export function parseVisibleQuotes(quotes, productSettings) {
  return _.map(quotes, (quote) => {
    // creation combined quotes
    if (quote.AggregateQuote && quote.VehicleLoan) {
      let VehicleLoan = quote.VehicleLoan;
      quote.AggregateQuote.VehicleLoan = {
        ...VehicleLoan,
        PaymentSchedule: parsePaymentSchedules(VehicleLoan)
      };

      if (quote.PersonalLoan) {
        let PersonalLoan = quote.PersonalLoan;
        quote.AggregateQuote.PersonalLoan = {
          ...PersonalLoan,
          PaymentSchedule: parsePaymentSchedules(PersonalLoan)
        };
        quote.AggregateQuote.ProductName = `Creation ${quote.VehicleLoan.FinanceType} with Personal Loan`;
      }

      if (quote.NegativeEquityLoan) {
        let NegativeEquityLoan = quote.NegativeEquityLoan;
        quote.AggregateQuote.NegativeEquityLoan = {
          ...NegativeEquityLoan,
          PaymentSchedule: parsePaymentSchedules(NegativeEquityLoan)
        };
        quote.AggregateQuote.ProductName = `Creation ${quote.VehicleLoan.FinanceType} with Personal Loan`;
      }

      quote = quote.AggregateQuote;
    }

    let productSet = productSettings;

    productSet = _.find(productSettings, { ProductUid: getVehicleLoanProductId(quote) });
    if (quote.Errors && quote.Errors.hasPublicErrors) {
      return {
        ...quote,
        Errors: quote.Errors.PublicErrors
      };
    }

    return {
      ...quote,
      PaymentSchedule: parsePaymentSchedules(quote),
      isVisible: productSet && productSet.Visible
    };
  });
}

export function addQuoteErrors(quotes, customerType) {
  if (quotes) {
    quotes = quotes.map((quote) => {
      if (quote.FunderCode === 'CRE' && quote.ValueAddedProducts && quote.ValueAddedProducts.length > 0) {
        let errorText = '';

        quote.ValueAddedProducts.map((vap) => {
          switch (vap.ProductTypeText) {
            case VapProductTypes.DeliveryCharges:
              errorText += vap.ProductTypeText + ', ';
              break;

            case VapProductTypes.AdministrationFee:
              errorText += vap.ProductTypeText + ', ';
              break;

            case VapProductTypes.VehicleAccessories:
              errorText += vap.ProductTypeText + ', ';
              break;

            case VapProductTypes.Fuel:
              errorText += vap.ProductTypeText + ', ';
              break;

            case VapProductTypes.MotorcycleExtras:
              errorText += vap.ProductTypeText + ', ';
              break;

            case VapProductTypes.OtherStandardVatExtras:
              errorText += vap.ProductTypeText + ', ';
              break;

            case VapProductTypes.RoadFundLicence:
              errorText += vap.ProductTypeText + ', ';
              break;

            case VapProductTypes.MotorcycleHelmet:
              errorText += vap.ProductTypeText + ', ';
              break;

            case VapProductTypes.OtherVatExemptExtras:
              errorText += vap.ProductTypeText + ', ';
              break;

            default:
              break;
          }
        });

        if (errorText !== '') {
          let errorMessage =
            'Creation will not fund: ' +
            errorText +
            'as part of this deal. Removing this item may make this quote available.';

          quote.Errors = [
            {
              Message: errorMessage,
              Number: 0
            }
          ];
        }
      }
      return quote;
    });

    quotes = _.orderBy(quotes, ['Errors'], ['desc']);
  }

  return quotes;
}

export function parseViewMonthlyPayments(response, quotesForCompare, combinedQuote) {
  return _.map(response, (product, productId) => {
    const quote = quotesForCompare.find((quote) => productId === getVehicleLoanProductId(quote));
    const { ProductName, FunderCode } = quote;

    return {
      ProductName,
      FunderCode,
      Payments: product,
      combinedQuote
    };
  });
}
