import React from 'react';
import PropTypes from 'prop-types';
import ApplicationNavigationItem from '../../Application/components/ApplicationNavigationItem';
import './cfcNavigation.scss';
const CFCNavigation = ({ items, onChange }) => {
  const handleChange = (item) => {
    onChange(item.id);
  };
  return (
    <div>
      <ul className="cfcNavigation__ul">
        {items.map((item, index) => (
          <ApplicationNavigationItem
            key={index}
            id={item.id}
            onClick={handleChange}
            isActive={item.isActive}
            imageName={item.image}
            text={item.label}
            progress={item.progress}
            numItems={items.length}
            isLast={index === items.length - 1}
          />
        ))}
      </ul>
    </div>
  );
};

CFCNavigation.propTypes = {
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CFCNavigation;
