import PropTypes from 'prop-types';

import Icon from './Icon/Icon';

import './imageButton.scss';

const ImageButton = (props) => {
  const buttonStyles = {
    width: props.width,
    height: props.height,
    fontSize: props.width
  };

  return (
    <button onClick={props.onClick} style={buttonStyles} className="imageButton" data-th={props.dataTestId || null}>
      <Icon name={props.image} />
    </button>
  );
};

ImageButton.propTypes = {
  image: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ImageButton;
