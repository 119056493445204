import React from 'react';
import PropTypes from 'prop-types';
import './applicationPage.scss';

const ApplicationPage = ({ title, subTitle, children }) => {
  return (
    <div className="applicationPage" data-th="ApplicationPage">
      {title && (
        <div className="applicationPage__inner">
          {title ? <h1 className="applicationPage__title">{title}</h1> : ''}
          {subTitle ? <h2 className="applicationPage__subTitle">{subTitle}</h2> : ''}
        </div>
      )}
      {children}
    </div>
  );
};

ApplicationPage.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.any
};

export default ApplicationPage;
