import React from 'react';
import PropTypes from 'prop-types';
import './svgWithFallback.scss';

import mailSuccess from '../../styles/icons/mail_success.svg';
import mailSuccessFallback from '../../styles/icons/mail_success_fallback.jpg';
import mailError from '../../styles/icons/mail_error.svg';
import mailErrorFailback from '../../styles/icons/mail_error_fallback.jpg';

const SvgWithFallback = (props) => {
  const nameImageMap = {
    mailSuccess: {
      svg: mailSuccess,
      fallback: mailSuccessFallback
    },
    mailError: {
      svg: mailError,
      fallback: mailErrorFailback
    }
  };

  return (
    <object className="svgWithFallback" data={nameImageMap[props.name].svg} type="image/svg+xml">
      <img className="svgWithFallback" src={nameImageMap[props.name].fallback} />
    </object>
  );
};

SvgWithFallback.propTypes = {
  name: PropTypes.string
};

export default SvgWithFallback;
