import React from 'react';
import PropTypes from 'prop-types';
import './boldHeader.scss';

const BoldHeader = ({ text }) => <div className="boldHeader">{text}</div>;

BoldHeader.propTypes = {
  text: PropTypes.string
};

export default BoldHeader;
