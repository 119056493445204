import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './reproposeToSameLenderModal.scss';
import Button from '../../Common/Button/Button';

const ReproposeToSameLenderModal = ({ t, onAgree, onEdit, canEdit, close }) => {
  return (
    <div>
      <div className="reproposeToSameLenderModal__title">
        {t('ReproposeToSameLenderModal.you_are_trying_to_propose_to_the_same_lender')}
      </div>

      <div className="reproposeToSameLenderModal__text">
        {t('ReproposeToSameLenderModal.already_submitted_to_this_lender')}
        {canEdit && t('ReproposeToSameLenderModal.to_avoid_edit_existing_application')}
      </div>

      <div className="reproposeToSameLenderModal__buttons">
        <div className="reproposeToSameLenderModal__proceed" key="wp">
          <Button onClick={onAgree} buttonStyle="primary">
            {t('ReproposeToSameLenderModal.proceed_with_reproposal')}
          </Button>
        </div>

        {canEdit && (
          <div className="reproposeToSameLenderModal__edit" key="wme">
            <Button onClick={onEdit} buttonStyle="secondary">
              {t('ReproposeToSameLenderModal.edit_existing_application')}
            </Button>
          </div>
        )}
        <div className="reproposeToSameLenderModal__cancel" key="wmc">
          <Button onClick={close} buttonStyle="cancel">
            {t('ReproposeToSameLenderModal.go_back')}
          </Button>
        </div>
      </div>
    </div>
  );
};

ReproposeToSameLenderModal.propTypes = {
  onAgree: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  canEdit: PropTypes.bool,
  close: PropTypes.func
};
export default withTranslation('Application')(ReproposeToSameLenderModal);
