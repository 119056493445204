import { Control, Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { FieldGroupRHF } from '~/components/Common/Form/FieldGroupRHF';
import FormFooter from '~/components/Common/Form/FormFooter';
import Input from '~/components/Common/Form/Input';
import { affordabilityDetailsValidator, useRhfValidationResolver } from './validation';
import { FinanceNavigatorFormAffordabilityDetails } from '../types/financeNavigatorForm';
import { useTranslation } from 'react-i18next';
import CurrencyInput from '~/components/Common/Form/CurrencyInput';
import YesNoInput from '~/components/Common/Form/YesNoInput';
import Modal from '~/components/Common/Modal/Modal';
import { useState } from 'react';
import ModalGross from '~/components/Application/modals/ModalGross';
import ModalReplacement from '~/components/Application/modals/ModalReplacement';
import ModalOtherHouseholdIncome from '~/components/Application/modals/ModalOtherHouseholdIncome';
import ModalExpectedChanges from '~/components/Application/modals/ModalExpectedChanges';
import ModalYourShare from '~/components/Application/modals/ModalYourShare';
import { FinanceNavigatorTitle } from '../components/FinanceNavigatorTitle';

export interface FinanceNavigatorAffordabilityDetailsProps {
  affordabilityDetails: FinanceNavigatorFormAffordabilityDetails;
  onSubmit: (affordabilityDetails: FinanceNavigatorFormAffordabilityDetails) => void;
  onCancel: () => void;
}

const ConditionalQuestions = ({
  control,
  conditionalField,
  conditionalValue,
  children
}: {
  control: Control<any, any>;
  conditionalField: string;
  conditionalValue: any;
  children: React.ReactNode;
}) => {
  const value = useWatch({ control, name: conditionalField });
  if (value !== conditionalValue) return null;
  return <>{children}</>;
};

export const FinanceNavigatorAffordabilityDetails = ({
  affordabilityDetails,
  onSubmit,
  onCancel
}: FinanceNavigatorAffordabilityDetailsProps) => {
  const { t } = useTranslation('FinanceNavigator');
  const resolver = useRhfValidationResolver(affordabilityDetailsValidator);
  const methods = useForm<FinanceNavigatorFormAffordabilityDetails>({
    defaultValues: affordabilityDetails,
    mode: 'all',
    resolver
  });
  const [openModal, setOpenModal] = useState('');
  const handleModalClosed = () => setOpenModal('');

  return (
    <div>
      <FinanceNavigatorTitle text={t('AffordabilityDetailsForm.affordability_details')} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} data-th="affordabilityDetailsForm">
          <Controller
            name="Income.GrossAnnual"
            control={methods.control}
            render={({ field }) => (
              <FieldGroupRHF
                label={t('AffordabilityDetailsForm.gross_annual_income')}
                name={field.name}
                onTooltipClicked={() => setOpenModal(field.name)}
              >
                <CurrencyInput
                  id={field.name}
                  onChange={field.onChange}
                  value={field.value}
                  onBlur={field.onBlur}
                  dataThook={field.name}
                />
              </FieldGroupRHF>
            )}
          />
          <Controller
            name="Income.HasOtherHouseholdIncome"
            control={methods.control}
            render={({ field }) => (
              <FieldGroupRHF
                label={t('AffordabilityDetailsForm.other_household_income')}
                name={field.name}
                onTooltipClicked={() => setOpenModal(field.name)}
              >
                <YesNoInput
                  id={field.name}
                  onChange={(_id, value) => field.onChange(value === 'true')}
                  value={field.value}
                  dataThook={field.name}
                />
              </FieldGroupRHF>
            )}
          />
          <ConditionalQuestions
            control={methods.control}
            conditionalField="Income.HasOtherHouseholdIncome"
            conditionalValue={true}
          >
            <Controller
              name="Income.OtherHouseholdIncome"
              control={methods.control}
              render={({ field }) => (
                <FieldGroupRHF
                  label={t('AffordabilityDetailsForm.what_is_your_other_household_income')}
                  name={field.name}
                >
                  <CurrencyInput
                    id={field.name}
                    onChange={field.onChange}
                    value={field.value}
                    onBlur={field.onBlur}
                    dataThook={field.name}
                  />
                </FieldGroupRHF>
              )}
            />
          </ConditionalQuestions>
          <Controller
            name="Income.NetMonthly"
            control={methods.control}
            render={({ field }) => (
              <FieldGroupRHF label={t('AffordabilityDetailsForm.typical_net_monthly_take_home_pay')} name={field.name}>
                <CurrencyInput
                  id={field.name}
                  onChange={field.onChange}
                  value={field.value}
                  onBlur={field.onBlur}
                  dataThook={field.name}
                />
              </FieldGroupRHF>
            )}
          />
          <Controller
            name="ReplacementLoan.IsReplacementLoan"
            control={methods.control}
            render={({ field }) => (
              <FieldGroupRHF
                label={t('AffordabilityDetailsForm.is_this_a_replacement_loan')}
                name={field.name}
                onTooltipClicked={() => setOpenModal(field.name)}
              >
                <YesNoInput
                  id={field.name}
                  onChange={(_id, value) => field.onChange(value === 'true')}
                  value={field.value}
                  dataThook={field.name}
                />
              </FieldGroupRHF>
            )}
          />
          <ConditionalQuestions
            control={methods.control}
            conditionalField="ReplacementLoan.IsReplacementLoan"
            conditionalValue={true}
          >
            <Controller
              name="ReplacementLoan.DetailsOfReplacementLoan"
              control={methods.control}
              render={({ field }) => (
                <FieldGroupRHF
                  label={t('AffordabilityDetailsForm.if_yes_what_is_your_current_monthly_payment_on_this_loan')}
                  name={field.name}
                >
                  <CurrencyInput
                    id={field.name}
                    onChange={field.onChange}
                    value={field.value}
                    onBlur={field.onBlur}
                    dataThook={field.name}
                  />
                </FieldGroupRHF>
              )}
            />
          </ConditionalQuestions>
          <Controller
            name="MonthlyExpenditure.MortgageOrRental"
            control={methods.control}
            render={({ field }) => (
              <FieldGroupRHF
                label={t('AffordabilityDetailsForm.your_share_of_monthly_mortgage_or_rental_expenditure')}
                name={field.name}
                onTooltipClicked={() => setOpenModal(field.name)}
              >
                <CurrencyInput
                  id={field.name}
                  onChange={field.onChange}
                  value={field.value}
                  onBlur={field.onBlur}
                  dataThook={field.name}
                />
              </FieldGroupRHF>
            )}
          />
          <Controller
            name="PersonalCircumstances.ForeseeFutureDownturnInFinances"
            control={methods.control}
            render={({ field }) => (
              <FieldGroupRHF
                label={t('AffordabilityDetailsForm.foresee_future_downturn_in_finances')}
                name={field.name}
                onTooltipClicked={() => setOpenModal(field.name)}
              >
                <YesNoInput
                  id={field.name}
                  onChange={(_id, value) => field.onChange(value === 'true')}
                  value={field.value}
                  dataThook={field.name}
                />
              </FieldGroupRHF>
            )}
          />
          <ConditionalQuestions
            control={methods.control}
            conditionalField="PersonalCircumstances.ForeseeFutureDownturnInFinances"
            conditionalValue={true}
          >
            <Controller
              name="PersonalCircumstances.DownturnReason"
              control={methods.control}
              render={({ field, fieldState }) => (
                <FieldGroupRHF
                  label={t('AffordabilityDetailsForm.if_yes_what_is_the_reason_for_the_downturn')}
                  name={field.name}
                >
                  <Input
                    id={field.name}
                    onChange={field.onChange}
                    value={field.value}
                    onBlur={field.onBlur}
                    isInvalid={fieldState.invalid}
                  />
                </FieldGroupRHF>
              )}
            />
            <Controller
              name="PersonalCircumstances.ForeseenYearlyIncome"
              control={methods.control}
              render={({ field }) => (
                <FieldGroupRHF
                  label={t('AffordabilityDetailsForm.if_yes_expect_your_reduced_annual_income')}
                  name={field.name}
                >
                  <CurrencyInput
                    id={field.name}
                    onChange={field.onChange}
                    value={field.value}
                    onBlur={field.onBlur}
                    dataThook={field.name}
                  />
                </FieldGroupRHF>
              )}
            />
          </ConditionalQuestions>
          <FormFooter
            submitLabel={t('FinanceNavigator.next')}
            submitDisabled={!methods.formState.isValid}
            hideSubmit={false}
            submittingState=""
            cancelLabel={t('FinanceNavigator.back')}
            onCancel={onCancel}
          />
          <Modal isOpen={openModal === 'Income.GrossAnnual'} onClose={handleModalClosed}>
            <ModalGross />
          </Modal>
          <Modal isOpen={openModal === 'ReplacementLoan.IsReplacementLoan'} onClose={handleModalClosed}>
            <ModalReplacement />
          </Modal>
          <Modal isOpen={openModal === 'Income.HasOtherHouseholdIncome'} onClose={handleModalClosed}>
            <ModalOtherHouseholdIncome />
          </Modal>

          <Modal
            isOpen={openModal === 'PersonalCircumstances.ForeseeFutureDownturnInFinances'}
            onClose={handleModalClosed}
          >
            <ModalExpectedChanges />
          </Modal>

          <Modal isOpen={openModal === 'MonthlyExpenditure.MortgageOrRental'} onClose={handleModalClosed}>
            <ModalYourShare />
          </Modal>
        </form>
      </FormProvider>
    </div>
  );
};
