import { ApplicationStatusRequoteNewRateSelectedProperties, RequoteNewRateSelectedOriginValueType } from '~/lib/Avo';

export interface PartialApplication {
  Id: string;
  DealershipId: string;
  QuoteId: string | null | undefined;
  Quote: {
    RepresentativeApr: number;
  };
  Agreements?: Array<{
    PreApprovalData?: {
      NewAPR: number;
    };
  }>;
}

export const getRequoteData = (
  application: PartialApplication,
  origin: RequoteNewRateSelectedOriginValueType
): ApplicationStatusRequoteNewRateSelectedProperties => {
  return {
    financeApplicationId: application?.Id || '',
    dealershipId: application?.DealershipId || '',
    financeQuoteId: application?.QuoteId || '',
    advertisedRate: application?.Quote?.RepresentativeApr || 0,
    preApprovedRate: application?.Agreements?.[0]?.PreApprovalData?.NewAPR || 0,
    requoteNewRateSelectedOrigin: [origin]
  };
};
