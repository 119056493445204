import { useVisLookupContext } from '../../context/VisLookupContext';

const useMetaData = () => {
  const { state } = useVisLookupContext();
  const vehicles = state.searchResults && state.searchResults.errorCode === null ? state.searchResults.response : [];
  const selectedVehicle =
    (state.selectedVehicleId &&
      vehicles.length > 0 &&
      vehicles.filter((vehicle) => vehicle?.objectID === state.selectedVehicleId)) ||
    [];
  const hasResults = vehicles.length > 0 && state.searchState === 'idle';
  const noResults = state.searchResults && state.searchResults.numberOfRecords === 0;

  return {
    hasResults,
    noResults,
    vehicles,
    selectedVehicle,
    touched: state.touched
  };
};

export default useMetaData;
