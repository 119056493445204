import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Divider from '../../Common/Divider';
import BulletList from '../../Common/BulletList/BulletList';
import BulletListItem from '../../Common/BulletList/BulletListItem';
import FormFooter from '../../Common/Form/FormFooter';
import LoadingSpinnerTailSpin from '../../Common/Loading/LoadingSpinnerTailSpin';
import SvgWithFallback from '../../Common/SvgWithFallback';
import Button from '../../Common/Button/Button';
import InlineExclamationWarning from '../../Common/InlineExclamationWarning';
import BulletListTick from '../../Common/BulletList/BulletListTick';
import QuickQuoteConsumerDetailsForm from '../../QuickQuote/forms/QuickQuoteConsumerDetailsForm';
import QuickQuoteCorporateDetailsForm from '../../QuickQuote/forms/QuickQuoteCorporateDetailsForm';
import ConsumerName from '../../Common/ConsumerName';
import Checkbox from '../../Common/Form/Checkbox';
import './sendDealModalContents.scss';
import { inject } from 'mobx-react';

class SendDealModalContents extends React.Component {
  constructor() {
    super();
    this.state = {
      hasGivenConsent: false
    };
  }

  setConsent = (checked) => {
    this.setState({
      hasGivenConsent: checked
    });
  };

  render() {
    const props = this.props;
    const hasSendableDeal = _.filter(props.quotes, (quote) => quote.isSendable).length > 0;

    switch (props.currentView) {
      case 'SUMMARY_VIEW':
        return (
          <div>
            <h1>{this.props.t('SendDealModalContents.send_deal_to_customer_for_self_service')}</h1>
            <Divider />
            <p>{this.props.t('SendDealModalContents.below_are_products_included_in_deal')}</p>
            <BulletList>
              {props.quotes &&
                props.quotes.length &&
                props.quotes.map((quote, index) =>
                  quote.isSendable ? (
                    <BulletListItem valid key={`product${index}`}>
                      {quote.ProductName}
                      <BulletListTick />
                    </BulletListItem>
                  ) : (
                    <BulletListItem key={`product${index}`}>
                      {quote.ProductName}
                      <InlineExclamationWarning
                        message={this.props.t(
                          'SendDealModalContents.this_product_not_available_for_self_service_will_not_be_sent'
                        )}
                      />
                    </BulletListItem>
                  )
                )}
            </BulletList>

            {props.isQuickQuote ? (
              <p>{this.props.t('SendDealModalContents.hit_next_to_find_or_add_customer_to_send_deal')}</p>
            ) : (
              <div>
                <p>{this.props.t('SendDealModalContents.deal_will_be_sent_to_customer')}</p>
                <p className="sendDealModalContents__customerEmail">
                  <ConsumerName name={`${props.customer.Firstname} ${props.customer.Surname}`} /> -{' '}
                  {props.customer.Email}
                </p>
              </div>
            )}

            {!hasSendableDeal && (
              <span className="sendDealModalContents__noProductsWarning">
                <InlineExclamationWarning
                  message={this.props.t(
                    'SendDealModalContents.no_selected_products_available_for_self_service_deal_cannot_be_sent'
                  )}
                />
              </span>
            )}

            <Divider />
            <div className="sendDealModalContents__consent">
              <Checkbox value={this.state.hasGivenConsent} onChange={this.setConsent} id="sendDealConfirm" />
              <label className="sendDealModalContents__consentText" htmlFor="sendDealConfirm">
                {this.props.t('SendDealModalContents.confirm_customer_given_consent_to_send__deal_via_email')}
              </label>
            </div>

            <FormFooter
              submitLabel={
                props.isQuickQuote
                  ? this.props.t('SendDealModalContents.next')
                  : this.props.t('SendDealModalContents.send_deal')
              }
              onCancel={props.onCancel}
              onSubmit={props.isQuickQuote ? props.onQuickQuoteProceed : props.sendDeal}
              submitDisabled={!hasSendableDeal || !this.state.hasGivenConsent}
            />
          </div>
        );

      case 'LOADING_VIEW':
        return (
          <div>
            <div className="sendDealModalContents__spinner">
              <LoadingSpinnerTailSpin />
            </div>
            <h2 className="sendDealModalContents__sendingHeader">
              {this.props.t('SendDealModalContents.sending_deal')}
            </h2>
            <p className="sendDealModalContents__sendingText">
              {this.props.t('SendDealModalContents.this_shouldnt_take_long')}
            </p>
          </div>
        );

      case 'CUSTOMER_DETAILS_VIEW':
        return (
          <div>
            <h1 className="sendDealModalContents__header">
              {this.props.t('SendDealModalContents.send_deal_to_customer_for_self_service')}
            </h1>
            <Divider />
            {props.customerType === 'corporate' ? (
              <QuickQuoteCorporateDetailsForm
                onSave={props.sendDealFromQuickQuote}
                onCancel={props.onCancel}
                submitExistingCustomer={props.sendDealToExistingCustomerFromQuickQuote}
              />
            ) : (
              <QuickQuoteConsumerDetailsForm
                submitLabel={this.props.t('SendDealModalContents.send_deal')}
                existingCustomerActionLabel={this.props.t('SendDealModalContents.send_deal_to_this_customer')}
                onSave={props.sendDealFromQuickQuote}
                onCancel={props.onCancel}
                submitExistingCustomer={props.sendDealToExistingCustomerFromQuickQuote}
                dealershipId={props.dealershipId}
                quotes={props.quotes}
              />
            )}
          </div>
        );

      case 'SUCCESS_VIEW':
        return (
          <div data-th="SendDealSuccess">
            <div className="sendDealModalContents__resultIcon">
              <SvgWithFallback name="mailSuccess" />
            </div>
            <h2 className="sendDealModalContents__sendingHeader">
              {this.props.t('SendDealModalContents.the_deal_has_been_sent_successfully')}
            </h2>
            <p className="sendDealModalContents__sendingText">
              {this.props.t(
                'SendDealModalContents.track_customers_progress_within_self_service_and_finance_application_area'
              )}
            </p>
            <Divider margin="30px 0" />
            <div className="sendDealModalContents__footer">
              <div className="sendDealModalContents__customerRecordButton">
                <Button onClick={props.navigateToCustomerRecord} buttonStyle="primary">
                  {this.props.t('SendDealModalContents.take_me_to_the_customer_record')}
                </Button>
              </div>
            </div>
          </div>
        );

      case 'ERROR_VIEW':
        return (
          <div>
            <div className="sendDealModalContents__resultIcon">
              <SvgWithFallback name="mailError" />
            </div>
            <h2 className="sendDealModalContents__sendingHeader">
              {this.props.t('SendDealModalContents.were_sorry_but_something_went_wrong')}
            </h2>
            <p className="sendDealModalContents__sendingText">
              {this.props.t('SendDealModalContents.dont_worry_its_not_your_fault')}
            </p>
            <p className="sendDealModalContents__sendingText">
              {this.props.t('SendDealModalContents.please_try_again_later')}
            </p>
            <Divider margin="30px 0" />
            <div className="sendDealModalContents__footer">
              <div className="sendDealModalContents__customerRecordButton">
                <Button onClick={props.onTryAgain} buttonStyle="primary">
                  {this.props.t('SendDealModalContents.try_again')}
                </Button>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  }
}

SendDealModalContents.propTypes = {
  onCancel: PropTypes.func,
  sendDeal: PropTypes.func,
  navigateToCustomerRecord: PropTypes.func,
  currentView: PropTypes.string,
  quotes: PropTypes.array,
  isQuickQuote: PropTypes.bool,
  sendDealFromQuickQuote: PropTypes.func,
  onQuickQuoteProceed: PropTypes.func,
  existingCustomer: PropTypes.object,
  sendDealToExistingCustomerFromQuickQuote: PropTypes.func,
  isExistingCustomerFromQuickQuote: PropTypes.bool,
  existingCustomerId: PropTypes.string,
  onTryAgain: PropTypes.func,
  customer: PropTypes.object,
  customerType: PropTypes.string,
  dealershipId: PropTypes.string
};
export default withTranslation('SendDeal')(inject('appStore')(SendDealModalContents));
