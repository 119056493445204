import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  draft.applicationStatuses.Accepted!.isEditable = false;
  draft.applicationStatuses.ConditionalAccept!.isEditable = false;
  draft.applicationStatuses.NoDecisionYet!.isEditable = false;
  draft.applicationStatuses.NotProceeding!.isEditable = false;
  draft.applicationStatuses.NotTakenUp!.isEditable = false;
  draft.applicationStatuses.Other!.isEditable = false;
  draft.applicationStatuses.Pending!.isEditable = false;
  draft.applicationStatuses.ProblemPayout!.isEditable = false;
  draft.applicationStatuses.Prospect!.isEditable = false;
  draft.applicationStatuses.Referred!.isEditable = false;
  draft.applicationStatuses.Rejected!.isEditable = false;
  draft.applicationStatuses.Submitted!.isEditable = false;
  draft.applicationStatuses.SentForPayout!.isEditable = false;
  draft.applicationStatuses.Unknown!.isEditable = false;
  draft.applicationStatuses.WaitingForFurtherInformation!.isEditable = false;
  draft.applicationFields.AffordabilityDetails!.ForeseeFutureDownturnInFinances = false;
  draft.applicationFields.AffordabilityDetails!.DownturnReason = false;
  draft.applicationFields.AffordabilityDetails!.IsReplacementLoan = false;
  draft.applicationFields.AffordabilityDetails!.DetailsOfReplacementLoan = false;
  draft.applicationFields.AffordabilityDetails!.MortgageOrRental = true;
  draft.applicationFields.AffordabilityDetails!.Other = true;
  delete draft.validationRules.AffordabilityDetails!.PersonalCircumstances;
  delete draft.validationRules.AffordabilityDetails!.ReplacementLoan;
  draft.validationRules.AffordabilityDetails!.MonthlyExpenditure!.MortgageOrRental = 'required, currency';
  draft.validationRules.AffordabilityDetails!.MonthlyExpenditure!.Other = 'required, currency';
});
