import React from 'react';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

import Icon from '~Common/Icon/Icon';
import Checkbox from '~Common/Form/Checkbox';
import MoneyFormat from '~Common/MoneyFormat';
import VRMPlate from '~Common/VrmPlate';
import AlgoliaVehicle from '~/mobx-models/AlgoliaVehicle';

import { PendingDataSpinner } from '../../../../shared/components';
import { StockListItemDataAccuracy } from './../';
import { VehicleNumber } from './../../../../../../shared/components';

import './broadcastStockListItem.scss';
import BroadcastStockListVehicleImage from '../StockListVehicleImage/BroadcastStockListVehicleImage';

@inject('appStore')
@observer
class BroadcastStockListItem extends React.Component {
  handleDataPending = () => {
    this.props.handleNewDataPending(this.props.vehicle);
  };

  renderImages() {
    let { Images, ImagesMetadata, DealerPlatformUpload } = this.props.vehicle;
    const images = DealerPlatformUpload ? ImagesMetadata : Images;

    if (!images || !images.length) {
      return;
    }

    return (
      <div className="broadcastStockListItem__numImages">
        <Icon name="camera" />
        {images.length}
      </div>
    );
  }

  renderUpdatingTag = () => {
    return (
      <div className="broadcastStockListItem__update_tag_container">
        <div onClick={() => this.handleDataPending()} className="broadcastStockListItem__updateTag">
          <div className="broadcastStockListItem__updateTagSpinner">
            <PendingDataSpinner />
          </div>
          <p className="broadcastStockListItem__updateTag__text">{this.props.t('StockListItem.new_data_pending')}</p>
        </div>
      </div>
    );
  };

  // convert Accuracy score to star rating
  convertAccuracyScore = (stars, score) => {
    if (score === 0 || score === null || score === 4) {
      return 0;
    } else {
      return stars - score;
    }
  };

  handleSelection = (id) => (state) => {
    this.props.onSelectVehicle(state, id);
  };

  render() {
    let { uiState } = this.props.appStore;
    const { i18n, vehicle, t } = this.props;
    const lng = i18n.language;
    const {
      Id,
      AccuracyScore,
      Class,
      DealerPlatformUpload,
      Derivative,
      Fuel,
      Make,
      Mileage,
      Model,
      Price,
      RegistrationDate,
      SDerivative,
      SFuel,
      SMake,
      SModel,
      STransmission,
      Taxonomies,
      Transmission,
      Vrm,
      BodyStyle,
      Bhp,
      BadgeEngineSize,
      Doors,
      Colour,
      PwrKW,
      CC,
      Description,
      Vin,
      DealerReference,
      ImgHr,
    } = vehicle;
    let shouldRenderUpdating = false;
    const ids = JSON.parse(localStorage.getItem('updatedVehicleIds'));

    if (Taxonomies && Taxonomies.length > 0) {
      const dealerObj = Taxonomies.find((item) => item.origin === 'dealer');
      const stockObj = Taxonomies.find((item) => item.origin === 'stock');

      if (dealerObj) {
        shouldRenderUpdating = stockObj && stockObj.propagating === true && dealerObj.propagating;
      }
    }

    // eslint-disable-next-line array-callback-return
    ids.map((id) => {
      const idKey = id.vehicleId;

      if (idKey && idKey.indexOf(this.props.vehicle.Id) !== -1) {
        shouldRenderUpdating = true;
      }
    });

    const vehicleInfo = [RegistrationDate.split('/')[2] || t('StockListItem.date_of_reg_missing')];

    if (BodyStyle) vehicleInfo.push(BodyStyle);
    if (Mileage) vehicleInfo.push(t('StockListItem.miles', { value: Mileage }));
    if (BadgeEngineSize) vehicleInfo.push(t('StockListItem.engineSize', { value: BadgeEngineSize.toFixed(1) }));
    if (CC) vehicleInfo.push(t('StockListItem.cc', { value: CC }));
    if (Bhp || PwrKW) {
      if (lng === 'en' && Bhp) vehicleInfo.push(t('StockListItem.bhp', { value: Bhp }));
      else if (PwrKW) vehicleInfo.push(t('StockListItem.kw', { value: PwrKW }));
    }
    if (DealerPlatformUpload && Transmission) vehicleInfo.push(Transmission);
    if (!DealerPlatformUpload && STransmission) vehicleInfo.push(STransmission);
    if (DealerPlatformUpload && Fuel) vehicleInfo.push(Fuel);
    if (!DealerPlatformUpload && SFuel) vehicleInfo.push(SFuel);
    if (Doors && Doors !== '0') vehicleInfo.push(t('StockListItem.doors', { value: Doors }));
    if (Colour) vehicleInfo.push(Colour);

    return (
      <>
        <div className="broadcastStockListItem">
          <div className="broadcastStockListItem__content">
            <MediaQuery maxWidth={991}>
              {!uiState.canViewBroadcastVrmPlate && (
                <div className="broadcastStockListItem__vehicleCheckBoxNoVrm">
                  <Checkbox value={this.props.isVehicleSelected} onChange={this.handleSelection(Id)} />
                </div>
              )}
            </MediaQuery>
            <div className={'broadcastStockListItem__leftSide'}>
              <div className="broadcastStockListItem__stars_and_image">
                <MediaQuery maxWidth={991}>
                  {uiState.canViewBroadcastDataAccuracy && (
                    <StockListItemDataAccuracy
                      className={'broadcastStockListItem__dataAccuracy'}
                      activeStars={this.convertAccuracyScore(4, AccuracyScore)}
                      onClick={this.props.openModal}
                    />
                  )}
                </MediaQuery>
                {shouldRenderUpdating && this.renderUpdatingTag()}
                <div className="broadcastStockListItem__image">
                  <div className="broadcastStockListItem__imageHighlight">{this.renderImages()}</div>
                  <BroadcastStockListVehicleImage
                    vehicleClass={Class}
                    vrm={Vrm}
                    vin={Vin}
                    dealerReference={DealerReference}
                    dealerPlatformUpload={DealerPlatformUpload}
                    imgHr={ImgHr}
                  />
                </div>
              </div>
              {uiState.canViewBroadcastVrmPlate && (
                <div className="broadcastStockListItem__vrm">
                  <VRMPlate vrm={Vrm} fuel={Fuel || SFuel} className="broadcastStockListItem__vrmPlate" />
                  <div className="broadcastStockListItem__vehicleCheckBox">
                    <Checkbox value={this.props.isVehicleSelected} onChange={this.handleSelection(Id)} />
                  </div>
                </div>
              )}

              {!uiState.canViewBroadcastVrmPlate && (
                <MediaQuery minWidth={992}>
                  <div className="broadcastStockListItem__vehicleCheckBoxNoVrm">
                    <Checkbox value={this.props.isVehicleSelected} onChange={this.handleSelection(Id)} />
                  </div>
                </MediaQuery>
              )}
            </div>
            <div className="broadcastStockListItem__vehicleInfo">
              <div className="broadcastStockListItem__vehicleName">
                {DealerPlatformUpload ? `${Make || '-'} ${Model || '-'}` : `${SMake || '-'} ${SModel || '-'}`}{' '}
                <MediaQuery maxWidth={991}>
                  <>&nbsp;| {DealerPlatformUpload ? Derivative : SDerivative}</>
                </MediaQuery>
              </div>
              {uiState.showVehicleNumber ? <VehicleNumber value={DealerReference || Vin || '-'} /> : null}
              <MediaQuery maxWidth={991}>
                <div
                  className={
                    uiState.canViewBroadcastVrmPlate
                      ? 'broadcastStockListItem__price'
                      : 'broadcastStockListItem__priceNoVrm'
                  }
                >
                  <MoneyFormat value={Price} />
                </div>
              </MediaQuery>
              <MediaQuery minWidth={992}>
                <div className="broadcastStockListItem__derivative">
                  {DealerPlatformUpload ? Derivative : SDerivative}
                </div>
              </MediaQuery>
              <div className="broadcastStockListItem__quickInfo">
                {/* Warning before the pipe is a nbsp make sure not to remove*/}
                {vehicleInfo.join(' | ')}
              </div>

              <div className="broadcastStockListItem__description">{Description}</div>
            </div>
            <div className="broadcastStockListItem__extraInfo">
              <MediaQuery minWidth={992} className="broadcastStockListItem__extraInfoContainer">
                <div
                  className={
                    uiState.canViewBroadcastVrmPlate
                      ? 'broadcastStockListItem__price'
                      : 'broadcastStockListItem__priceNoVrm'
                  }
                >
                  <MoneyFormat value={Price} />
                </div>
                {uiState.canViewBroadcastDataAccuracy && (
                  <StockListItemDataAccuracy
                    className={'broadcastStockListItem__dataAccuracy'}
                    activeStars={this.convertAccuracyScore(4, AccuracyScore)}
                    onClick={this.props.openModal}
                  />
                )}
              </MediaQuery>
            </div>
          </div>
        </div>
      </>
    );
  }
}

BroadcastStockListItem.propTypes = {
  vehicle: PropTypes.instanceOf(AlgoliaVehicle),
  params: PropTypes.object,
  openModal: PropTypes.func,
  handleNewDataPending: PropTypes.func,
  appStore: PropTypes.any,
  onSelectVehicle: PropTypes.func,
  isVehicleSelected: PropTypes.bool
};

export default withTranslation('Stock')(BroadcastStockListItem);
