import { action, observable, computed } from 'mobx';
import objectValidation from '../core/objectValidation';
import { countItemsInObject } from '../core/helpers';

const ruleSets = {
  motorhome: {
    used: {
      Make: 'required',
      Model: 'required',
      Derivative: 'required',
      RegistrationDate: 'required, date',
      Vrm: 'required, maxlength:9, alphaNumSpace',
      Mileage: 'required, number:int, min:1',
      Condition: 'required',
      Class: 'required'
    },
    new: {
      Make: 'required',
      Model: 'required',
      Derivative: 'required',
      Condition: 'required',
      Class: 'required'
    }
  },
  touringcaravan: {
    used: {
      Make: 'required',
      Model: 'required',
      Derivative: 'required',
      RegistrationDate: 'required, date',
      Condition: 'required',
      Class: 'required',
      Vin: 'required, minlength:17, maxlength:17'
    },
    new: {
      Make: 'required',
      Model: 'required',
      Derivative: 'required',
      Condition: 'required',
      Class: 'required',
      Vin: 'required, minlength:17, maxlength:17'
    }
  },
  others: {
    used: {
      MakeId: 'required',
      ModelId: 'required',
      DerivativeId: 'required',
      RegistrationDate: 'required, date',
      Vrm: 'required, maxlength:9, alphaNumSpace',
      Mileage: 'required, number:int, min:1',
      Condition: 'required',
      Class: 'required'
    },
    new: {
      MakeId: 'required',
      ModelId: 'required',
      DerivativeId: 'required',
      Condition: 'required',
      Class: 'required'
    }
  }
};

class VehicleFormValidator {
  @observable errors = {};

  @action
  validate = (data) => {
    const vehicleClass = data.Class?.toLowerCase();
    const vehicleCondition = data.Condition?.toLowerCase();
    const ruleSet = ruleSets[vehicleClass] ?? ruleSets.others;
    const rules = ruleSet[vehicleCondition] ?? ruleSet.new;
    this.errors = objectValidation(data, rules);
  };

  @computed
  get errorCount() {
    return countItemsInObject(this.errors);
  }

  getErrors() {
    return this.errors || {};
  }
}

export default VehicleFormValidator;
