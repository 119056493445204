import React from 'react';
import PropTypes from 'prop-types';
import './latestDealsCustomerInfoMedia.scss';
import VehicleImage from '../../Common/VehicleImage';
import ConsumerName from '../../Common/ConsumerName';
import BusinessTag from '../../Common/BusinessTag';
import SmallVrm from '../../Common/SmallVrm';

const LatestDealsCustomerInfoMedia = ({ deal, handleConsumer }) => {
  return (
    <div className="latestDealsCustomerInfoMedia">
      <div className="latestDealsCustomerInfoMedia__vehicleImage">
        <VehicleImage
          imageSource={deal.Vehicle.ImageUrl}
          imageWidth="70px"
          imageHeight="50px"
          iconFontSize="45px"
          iconMargin="3px 0 0 0"
          vehicleClass={deal.Vehicle.Class}
        />
      </div>
      <div>
        <div className="latestDealsCustomerInfoMedia__name" onClick={() => handleConsumer(deal.CustomerId)}>
          {deal.CustomerType === 'Consumer' ? (
            <ConsumerName name={deal.Firstname + ' ' + deal.Surname} />
          ) : (
            <div>
              {deal.TradingName}
              <BusinessTag userType={deal.CustomerType} smallTag={true} />
            </div>
          )}
        </div>
        <div className="latestDealsCustomerInfoMedia__vrm">
          <SmallVrm vrm={deal.Vehicle.Vrm} />
        </div>
      </div>
      <div className="latestDealsCustomerInfoMedia__vehicleName">
        {deal.Vehicle.Make} {deal.Vehicle.Model} {deal.Vehicle.Derivative}
      </div>
    </div>
  );
};

LatestDealsCustomerInfoMedia.propTypes = {
  deal: PropTypes.object,
  handleConsumer: PropTypes.func
};

export default LatestDealsCustomerInfoMedia;
