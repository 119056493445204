import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ManagedInput from '~Common/Form/ManagedInput';
import Icon from '~Common/Icon/Icon';
import { CrossIcon } from '../../../../shared/icons';

import s from './searchBar.module.scss';

const SearchBar = ({ id, value, onChange, placeholder, dataThook }) => {
  return (
    <div className={s['container']} data-th="SearchBar">
      <div className={s['searchInputAndIconsContainer']}>
        <div className={s['searchIcon']}>
          <Icon name="search" />
        </div>
        <ManagedInput
          dataThook={dataThook}
          id={id}
          className={s['input']}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
        <div
          data-th="clearSearchIcon"
          onClick={() => onChange(null, '')}
          className={cn(s['crossIcon'], !!value && s['crossIcon--active'])}
        >
          <CrossIcon />
        </div>
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};

export default SearchBar;
