import React from 'react';

import IconSetOne from '~SM/components/IconSetOne';

import './vehicleFact.scss';

const VehicleFact = ({ type, content, factsPerRow }) => {
  const Icons = IconSetOne;
  const iconList = {
    year: Icons.year,
    mileage: Icons.mileage,
    bhp: Icons.bhp,
    doors: Icons.doors,
    fuel: Icons.fuel,
    bodyStyle: Icons.bodystyle,
    color: Icons.colour,
    transmission: Icons.transmission,
    location: Icons.town,
    cc: Icons.cc,
    tax: Icons.tax,
    createdTs: Icons.recentlyadded,
    vrm: Icons.vrm
  };
  const Icon = iconList[type];
  const value = content;
  const width = 100 / factsPerRow;

  return (
    <div
      style={{ width: `calc(${width}% - 5px` }}
      className="vehicleFact vehicleFact__vehicleFactList--small"
      data-cy="VehicleFact"
      data-th="VehicleFact"
    >
      <span className="vehicleFact__svg vehicleFact__container--small">{Icon ? <Icon /> : '●'}</span>
      <div data-type={type}>{value}</div>
    </div>
  );
};

export default VehicleFact;
