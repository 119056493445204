import { useTranslation } from 'react-i18next';
import './resetPasswordSuccess.scss';
import Button from '../Common/Button/Button';

const ResetPasswordSuccess = () => {
  const { t } = useTranslation('ForgotPassword');
  return (
    <div>
      <h1 className="resetPasswordSuccess__header">{t('ResetPasswordSuccess.good_news')}</h1>
      <p className="resetPasswordSuccess__text">
        {t('ResetPasswordSuccess.your_password_has_been_successfully_changed')}
      </p>

      <div className="resetPasswordSuccess__success">
        <Button buttonStyle="primary" to="/auth/signin">
          {t('ResetPasswordSuccess.back_to_sign_in')}
        </Button>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;
