export function loadDocument(name) {
  return fetch('/legal-docs/' + name, {
    headers: {
      'content-type': 'application/json'
    },
    method: 'GET'
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response.text();
  });
}
