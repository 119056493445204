import PropTypes from 'prop-types';
import QuoteButtonWrapper from './QuoteButtonWrapper';
import Button from '../../../components/Common/Button/Button';
import './quoteButton.scss';

const QuoteButton = (props) => (
  <QuoteButtonWrapper isSelected={props.isSelected}>
    <div className="quoteButton__button">
      {props.to ? (
        <Button to={props.to} type="button">
          {props.label}
        </Button>
      ) : (
        <Button
          isLoading={props.loadingState === 'loading'}
          hasError={props.loadingState === 'error'}
          onClick={props.onClick}
        >
          {props.label}
        </Button>
      )}
    </div>
  </QuoteButtonWrapper>
);

QuoteButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
  loadingState: PropTypes.string,
  isSelected: PropTypes.bool
};

export default QuoteButton;
