import { useTranslation } from 'react-i18next';
import Button from './Button/Button';
import './dealerAccessDeniedPage.scss';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

export const getHomeHref = () => {
  return '/dealer/platform';
};

const DealerAccessDeniedPage = () => {
  const isPermissionDealerErrorOn = useFeatureIsOn('rtl-no-permission-dealer-error');

  const { t } = useTranslation('Common');

  const homeHref = getHomeHref();

  if (isPermissionDealerErrorOn) {
    return (
      <div className="dealerAccessDeniedPage">
        <div className="dealerAccessDeniedPage__message">
          <h1 className="dealerAccessDeniedPage__heading">{t('DealerAccessDeniedPage.access_denied')}</h1>
          <p className="dealerAccessDeniedPage__text">{t('DealerAccessDeniedPage.dont_have_access')}</p>

          <div className="dealerAccessDeniedPage__returnToHomePageButton">
            <Button buttonStyle="primary" href={homeHref}>
              {t('DealerAccessDeniedPage.return_to_home_page')}
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};

export default DealerAccessDeniedPage;
