import platformApiUtils from '@ivendi/platform-api-utils';
import { z } from 'zod';

// Individual Option Types
export const BusinessAccountTypeSchema = z.record(z.string(), z.string());
export const BusinessPremisesTypeSchema = z.record(z.string(), z.string());
export const CompanySectorSchema = z.record(z.string(), z.string());
export const CompanySectorExtendedSchema = z.record(z.string(), z.string());
export const CompanyTypesSchema = z.record(z.string(), z.string());
export const CustomerTitlesSchema = z.record(z.string(), z.string());
export const DrivingLicenseTypeSchema = z.record(z.string(), z.string());
export const EmploymentBasisSchema = z.record(z.string(), z.string());
export const FinanceTitlesSchema = z.record(z.string(), z.string());
export const GenderSchema = z.record(z.string(), z.string());
export const LenderStatusSchema = z.record(z.string(), z.string());
export const MaritalStatusSchema = z.record(z.string(), z.string());
export const NationalitySchema = z.record(z.string(), z.string());
export const OccupationTypeSchema = z.record(z.string(), z.string());
export const PositionSchema = z.record(z.string(), z.string());
export const ResidencySchema = z.record(z.string(), z.string());
export const TitleSchema = z.record(z.string(), z.string());
export const LenderVapProductTypeSchema = z.object({
  Defaultable: z.boolean().optional(),
  ProductTypeCode: z.number().optional(),
  ProductTypeText: z.string().optional(),
  TaxRate: z.number().optional(),
  TaxTypeCode: z.number().optional(),
  TaxTypeText: z.string().optional()
});
const LenderCodeSchema = z.string(); // 'MOT', 'CRE', etc.

// Response Schemas
export const AllOptionsSchema = z.record(
  LenderCodeSchema,
  z.object({
    BusinessAccountType: BusinessAccountTypeSchema,
    BusinessPremisesType: BusinessPremisesTypeSchema,
    CompanySectorType: CompanySectorSchema,
    CompanySectorExtended: CompanySectorExtendedSchema,
    CompanyTypes: CompanyTypesSchema,
    CustomerTitles: CustomerTitlesSchema,
    DrivingLicenseType: DrivingLicenseTypeSchema,
    EmploymentBasis: EmploymentBasisSchema,
    FinanceTitles: FinanceTitlesSchema,
    Gender: GenderSchema,
    LenderStatus: LenderStatusSchema,
    MaritalStatus: MaritalStatusSchema,
    Nationality: NationalitySchema,
    OccupationType: OccupationTypeSchema,
    PositionType: PositionSchema,
    ResidencyType: ResidencySchema,
    Title: TitleSchema,
    VapProductTypes: z.array(LenderVapProductTypeSchema)
  })
);
export const OverrideOptionsSchema = z.record(
  LenderCodeSchema,
  z.object({
    BusinessAccountType: BusinessAccountTypeSchema.optional(),
    BusinessPremisesType: BusinessPremisesTypeSchema.optional(),
    CompanySectorType: CompanySectorSchema.optional(),
    CompanySectorExtended: CompanySectorExtendedSchema.optional(),
    CompanyTypes: CompanyTypesSchema.optional(),
    CustomerTitles: CustomerTitlesSchema.optional(),
    DrivingLicenseType: DrivingLicenseTypeSchema.optional(),
    EmploymentBasis: EmploymentBasisSchema.optional(),
    FinanceTitles: FinanceTitlesSchema.optional(),
    Gender: GenderSchema.optional(),
    LenderStatus: LenderStatusSchema.optional(),
    MaritalStatus: MaritalStatusSchema.optional(),
    Nationality: NationalitySchema.optional(),
    OccupationType: OccupationTypeSchema.optional(),
    PositionType: PositionSchema.optional(),
    ResidencyType: ResidencySchema.optional(),
    Title: TitleSchema.optional(),
    VapProductTypes: z.array(LenderVapProductTypeSchema).optional()
  })
);

export type AllOptionsResponse = z.infer<typeof AllOptionsSchema>;
export type OverrideOptionsResponse = z.infer<typeof OverrideOptionsSchema>;

// Used in the MobX Store Request,
// hence no awaiting or handling of the response here
export const fetchAllOptionsRequest = (): Promise<AllOptionsResponse> => platformApiUtils.get('v1/options/all');
export const fetchOverrideOptionsRequest = (): Promise<OverrideOptionsResponse> =>
  platformApiUtils.get('v1/options/all/overrides');
