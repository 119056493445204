import platformApiUtils from '@ivendi/platform-api-utils';
import { schemaMap } from '../../core/schema';
import cfcCheckDetailsSchema from '../../core/schemas/cfcCheckDetailsSchema';
import { cfcCheckDetailsDataParser } from '../../core/apiDataParsers/cfcCheckDetailsDataParser';

export const fetchCfcCheckDetails = ({ cfcProspectId, quoteId }: { cfcProspectId: string; quoteId: string }) => {
  return new Promise(function(resolve, reject) {
    platformApiUtils
      .get('v1/cfcsummary/' + cfcProspectId + '/quote/' + quoteId)
      .then((response) => resolve(cfcCheckDetailsDataParser(schemaMap(response, cfcCheckDetailsSchema))))
      .catch((error) => reject(error));
  });
};
