import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from './Icon/Icon';
import './collapsiblePanel.scss';

class CollapsiblePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContent: props.showContent,
      contentScrollHeight: null,
      currentVehicle: props.currentVehicle
    };
  }

  componentDidMount() {
    this.setInitialHeight();
    this.setState({ showContent: this.props.showContent });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showContent !== this.props.showContent) {
      this.setState({ showContent: this.props.showContent });
      this.getContentHeight();
    }

    if (prevProps.currentVehicle && prevProps.currentVehicle.VehicleId !== this.props.currentVehicle.VehicleId) {
      this.setState({ showContent: this.props.showContent });
      this.getContentHeight();
    }

    if (prevProps.childrenHeight !== this.props.childrenHeight) {
      this.getContentHeight();
    }
  }

  setInitialHeight = () => {
    this.getContentHeight();
  };

  getContentHeight = () => {
    const { id, childrenHeight } = this.props;
    const content = document.getElementById(`collapsiblePanel__content--${id}`);
    const calculatedHeight = content.scrollHeight + childrenHeight;
    this.setState((prevState) => ({
      contentScrollHeight: prevState.showContent ? calculatedHeight : 0
    }));
  };

  handleClick = () => {
    this.setState(
      (prevState) => ({
        showContent: !prevState.showContent
      }),
      () => {
        this.getContentHeight();
        if (this.props.onToggle) {
          this.props.onToggle(this.state.showContent);
        }
      }
    );
  };

  render() {
    const { id, title, disabled, children } = this.props;
    const { showContent, contentScrollHeight } = this.state;
    const outerClassname = classnames('collapsiblePanel__outer', disabled && 'collapsiblePanel__isDisabled');
    const arrowClassname = classnames(
      'collapsiblePanel__expandButton',
      showContent && 'collapsiblePanel__expandButton--up'
    );
    const contentClassname = classnames(
      'collapsiblePanel__content',
      !showContent && 'collapsiblePanel__content--close'
    );

    const contentStyle = {
      maxHeight: contentScrollHeight + 'px'
    };
    return (
      <div className={outerClassname}>
        <div className="collapsiblePanel__heading" onClick={this.handleClick}>
          <div className="collapsiblePanel__title">{title}</div>
          <div className={arrowClassname}>
            <Icon name="down" />
          </div>
        </div>
        <div id={`collapsiblePanel__content--${id}`} className={contentClassname} style={contentStyle}>
          {children}
        </div>
      </div>
    );
  }
}

CollapsiblePanel.defaultProps = {
  childrenHeight: 0,
  showContent: false
};

CollapsiblePanel.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  noMargin: PropTypes.bool,
  childrenHeight: PropTypes.number,
  showContent: PropTypes.bool
};

export default CollapsiblePanel;
