import React from 'react';
import PropTypes from 'prop-types';

import StarRating from '~Common/Form/StarRating';
import Icon from '~Common/Icon/Icon';
import cn from 'classnames';

import classes from './stockListItemDataAccuracy.module.scss';

const StockListItemDataAccuracy = ({ activeStars, onClick }) => {
  return (
    <div className={cn(classes.container)} onClick={onClick} data-th="StockListItemDataAccuracy">
      <div className={classes.icon}>
        <Icon name="question" />
      </div>
      <span className={classes.rating}>
        <StarRating
          className={cn(classes.starRating, 'stockListItemDataAccuracy_star')}
          stars={3}
          active={activeStars}
        />
      </span>
    </div>
  );
};

StockListItemDataAccuracy.propTypes = {
  activeStars: PropTypes.number,
  onClick: PropTypes.func
};
export default StockListItemDataAccuracy;
