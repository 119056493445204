import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Common/Button/Button';
import './deleteIddModal.scss';
import TrackEvent from '../../Common/Tracking/TrackEvent';

function DeleteIddModal({ handleDelete, handleClose }) {
  const { t } = useTranslation('Settings');
  return (
    <div className="deleteIddModal">
      <div className="deleteIddModal__text">{t('DeleteIddModal.disclosure_deletion_warning')}</div>
      <div className="deleteIddModal__buttonOuter">
        <div className="deleteIddModal__button">
          <TrackEvent featureName="IDD" interactionName="IDD_Deleted">
            <Button onClick={handleDelete}>{t('DeleteIddModal.delete')}</Button>
          </TrackEvent>
        </div>
        <div className="deleteIddModal__button">
          <Button buttonStyle="cancel" onClick={handleClose}>
            {t('DeleteIddModal.cancel')}
          </Button>
        </div>
      </div>
    </div>
  );
}

DeleteIddModal.propTypes = {
  handleDelete: PropTypes.func,
  handleClose: PropTypes.func
};
export default DeleteIddModal;
