import {
  ApplicationStatusMetric,
  ApplicationStatusMetricType,
  MetricType,
  QuoteSource,
  QuoteSourceType
} from '../types';

export type BaseDropdownConfigType = {
  enabled: boolean;
};

export type DefaultDropdownConfigType = BaseDropdownConfigType & {
  default: string | number | QuoteSourceType[] | MetricType;
};

export type DatePeriodDropdownConfigType = DefaultDropdownConfigType & {
  default: number;
  includeDay?: boolean;
  includeLast30Days?: boolean;
};

export type LenderDropdownConfigType = DefaultDropdownConfigType & {
  default: string;
};

export type QuoteSourceDropdownConfigType = DefaultDropdownConfigType & {
  default: QuoteSourceType[];
};

export type ApplicationStatusDropdownConfigType = DefaultDropdownConfigType & {
  default: ApplicationStatusMetricType;
};

export type DropdownFilterOptionsType = {
  includeDay?: boolean;
  includeLast30Days?: boolean;
};

export type DropdownConfigType = DropdownFilterOptionsType & {
  enabled: boolean;
  default: string | ApplicationStatusMetricType | number | Array<string | number>;
};

export const OptionName = {
  DatePeriod: 'datePeriod',
  QuoteSource: 'quoteSource',
  Lender: 'lender',
  ApplicationStatus: 'applicationStatus'
} as const;

export type OptionsListType = {
  [OptionName.DatePeriod]: OptionType[];
  [OptionName.QuoteSource]: OptionType[];
  [OptionName.Lender]: OptionType[];
  [OptionName.ApplicationStatus]: OptionType[];
};

export type OptionNameType = keyof OptionsListType;

export type LenderType = {
  key: string;
  value: string;
};

export const ChartVariant = {
  Bar: 'bar',
  Line: 'line',
  Pie: 'pie',
  Table: 'table'
} as const;

export type ChartVariantType = typeof ChartVariant[keyof typeof ChartVariant];

export type FiltersStateType = {
  datePeriod?: number;
  quoteSource?: QuoteSourceType[];
  lender?: string;
  applicationStatus?: ApplicationStatusMetricType;
  chartType?: ChartVariantType;
};

export type PriorityChartFilterType = keyof Omit<FiltersStateType, 'chartType'>;

export type OptionType<T = string | number> = {
  id: T; // Identifier used for toggle/tracking/etc.
  value: T; // Value used by react-select
  label: string; // Label displayed in the dropdown
  isDisabled?: boolean; // Whether the option is disabled
  isActive?: boolean; // Toggle state (for ToggleSelect)
  filterKey?: keyof DropdownConfigType; // Key used for filtering based on config
};

// Date dropdown
export const datePeriodOptionsData: OptionType[] = [
  { id: 1, value: 1, label: 'ChartFilters.last_day', filterKey: 'includeDay' },
  { id: 7, value: 7, label: 'ChartFilters.last_week' },
  { id: 30, value: 30, label: 'ChartFilters.last_30_days', filterKey: 'includeLast30Days' },
  { id: 90, value: 90, label: 'ChartFilters.last_3_months' }
];

// Quote QuoteSource dropdown
export const sourceOptionsData: OptionType[] = [
  { id: QuoteSource.Count, value: QuoteSource.Count, label: 'ChartFilters.all_sources' },
  { id: QuoteSource.Online, value: QuoteSource.Online, label: 'ChartFilters.online' },
  { id: QuoteSource.Showroom, value: QuoteSource.Showroom, label: 'ChartFilters.showroom' }
];

// Lender dropdown
export const lenderOptionsData: OptionType[] = [{ id: 'all', value: 'all', label: 'All' }];

// Application Status dropdown
export const getApplicationStatusMetricOptions = (): OptionType[] => {
  return Object.entries(ApplicationStatusMetric).map(([key, id]) => ({
    id,
    value: id,
    label: key
  }));
};

export const applicationStatusOptionsData: OptionType[] = [
  { id: 'all', value: 'all', label: 'All' },
  ...getApplicationStatusMetricOptions().map((option) => ({
    ...option,
    value: option.id
  }))
];

export const dropdownOptions: OptionsListType = {
  [OptionName.DatePeriod]: datePeriodOptionsData,
  [OptionName.QuoteSource]: sourceOptionsData,
  [OptionName.Lender]: lenderOptionsData,
  [OptionName.ApplicationStatus]: applicationStatusOptionsData
};
