import platformApiUtils from '@ivendi/platform-api-utils';

export type MonthlyFinanceConversionData = {
  Customers: {
    Count: number;
  };
  CustomersWhoSubmittedAnApplication: {
    Count: number;
  };
  PaidOutApplications: {
    Count: number;
    Amount: number;
  };
  PaidOutApplicationsByLender: {
    [funder: string]: {
      Count: number;
      Amount: number;
    };
  };
};

export const fetchMonthlyFinanceConversion = (dealershipId: string, numberOfMonths: number = 1) => {
  return new Promise<MonthlyFinanceConversionData>(function(resolve, reject) {
    platformApiUtils
      .get(`v1/reporting/${dealershipId}/monthly-finance-conversion`, { numberOfMonths })
      .then((response: any) => resolve(response))
      .catch((error: any) => reject(error));
  });
};
