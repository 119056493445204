import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import WistiaVideo from '../../Common/WistiaVideo';
import YoutubeVideo from '../../Common/YoutubeVideo';

const HelpVideo = ({ source, email, appStore }) => {
  return (
    <div>
      {source.isYoutubeVideo ? (
        <YoutubeVideo source={source.videoPath} startTime={source.youTubeStartTime} />
      ) : (
        <WistiaVideo source={source.videoPath} email={email} />
      )}
    </div>
  );
};

HelpVideo.propTypes = {
  source: PropTypes.object,
  email: PropTypes.string
};

export default inject('appStore')(observer(HelpVideo));
