import PropTypes from 'prop-types';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import CurrencyInput from '../../Common/Form/CurrencyInput';

const ValueAddedProduct = ({
  onChange,
  onEdit,
  onRemove,
  index,
  error,
  label,
  value,
  isInline,
  size,
  trackingPage,
  disabled,
  dataThook
}) => {
  const handleChange = (price) => onChange(index, price);
  const handleEdit = () => onEdit(index);
  const handleRemove = () => onRemove(index);

  return (
    <MobxFieldGroup isInline={isInline} error={error && error.Price} size={size}>
      <MobxFormLabel htmlFor={`vap${index}`}>{label}</MobxFormLabel>
      <CurrencyInput
        trackingPage={trackingPage}
        value={value}
        onChange={handleChange}
        onEdit={handleEdit}
        onRemove={handleRemove}
        disabled={disabled}
        id={`vap${index}`}
        dataThook={dataThook}
      />
    </MobxFieldGroup>
  );
};

ValueAddedProduct.defaultProps = {
  isInline: true,
  disabled: false,
  value: 0.0
};

ValueAddedProduct.propTypes = {
  index: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  error: PropTypes.object,
  size: PropTypes.string,
  isInline: PropTypes.bool,
  trackingPage: PropTypes.string,
  disabled: PropTypes.bool
};

export default ValueAddedProduct;
