import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { observer, inject } from 'mobx-react';
import Checkbox from '../../Common/Form/Checkbox';
import MoneyFormat from '../../Common/MoneyFormat';
import { formatToNumDecimalPlaces } from '~/components/Quoting/components/utils';
import './settingsProductTableItem.scss';

class SettingsProductTableItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: props.product.Settings && props.product.Settings.Visible,
      isSelected: props.product.isSelected
    };
  }

  handleCheckboxUpdate = (checked) => {
    this.setState({
      isSelected: checked
    });

    this.props.handleChangeSelected(this.props.product.ProductUid, checked);
  };

  handleVisibilityCheckbox = (checked) => {
    this.setState({
      isVisible: checked
    });

    this.props.handleChangeVisibility(this.props.product.ProductUid, checked);
  };

  render() {
    const product = this.props.product;

    const lenderName = _.result(_.find(this.props.funders, { Code: product.FunderCode }), 'Name');
    const commissionType = product.Settings && product.Settings.CommissionType;
    const commissionRate = product.Settings && product.Settings.Rate;
    const commissionTypeValue = _.result(_.find(this.props.options.CommissionType, { key: commissionType }), 'value');
    let displayedCommission;

    switch (commissionType) {
      case 'CommissionFixed':
        displayedCommission = <MoneyFormat value={commissionRate} />;
        break;
      case 'APR':
        displayedCommission = `${formatToNumDecimalPlaces(
          commissionRate,
          this.props.appStore.uiState.countryCode,
          '%'
        )}  ${commissionTypeValue}`;

        break;
      case 'FlatRate':
        displayedCommission = commissionRate + '% ' + commissionTypeValue;
        break;
      case 'CommissionAdvance':
        displayedCommission = commissionRate + commissionTypeValue;
        break;
    }

    return (
      <tr className="settingsProductTableItem__row">
        <td className="settingsProductTableItem__all">{product.FinanceType}</td>
        <td className="settingsProductTableItem__all">{product.Name}</td>
        <td className="settingsProductTableItem__all">{lenderName}</td>
        <td className="settingsProductTableItem__all">
          {displayedCommission}
          {commissionType === 'CommissionFixed' ? ' Commission' : ''}
        </td>
        <td className="settingsProductTableItem__checkbox ">
          <Checkbox
            id={'SettingsProductTableItemCheckbox' + this.props.index}
            onChange={this.handleVisibilityCheckbox}
            value={this.state.isVisible}
            disabled={!this.props.appStore.uiState.canEditProducts}
          />
        </td>
        {this.props.appStore.uiState.canEditProducts && (
          <td className="settingsProductTableItem__checkbox ">
            <Checkbox
              id={'SettingsProductTableItemCheckbox' + this.props.index}
              onChange={this.handleCheckboxUpdate}
              value={this.state.isSelected}
            />
          </td>
        )}
      </tr>
    );
  }
}
SettingsProductTableItem.propTypes = {
  product: PropTypes.object,
  index: PropTypes.number,
  handleChangeSelected: PropTypes.func,
  handleChangeVisibility: PropTypes.func,
  options: PropTypes.object,
  funders: PropTypes.array,
  appStore: PropTypes.object
};

export default inject(['appStore'])(observer(SettingsProductTableItem));
