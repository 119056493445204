interface GetImageUrlProps {
  imgHr: string;
  imgPrfx: string;
  imgUrls: string[];
  imageNumber?: number;
  params?: string;
}

export const getImageUrl = ({ imgPrfx, imageNumber = 0, imgUrls = [], imgHr, params = 'height=150&mode=max',
}: GetImageUrlProps) => {
  if (imageNumber === 0 && imgHr) {
    return `${imgHr}?${params}`
  }
  if (imgUrls?.length && imageNumber > imgUrls?.length) {
    console.log(`attempting to get image url that doesn't exist! imageNumber: ${imageNumber} available images: ${imgUrls.length}`);
    return '';
  }

  if (imgPrfx && imgUrls?.length) {
    return `${imgPrfx}${imgUrls[imageNumber]}?${params}`
  }
}
