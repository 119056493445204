import _ from 'lodash';
import validators from './validators';
import i18n from '../i18n';

function validateRule(rule, value, data) {
  const ruleParts = rule.split(':');
  const ruleName = ruleParts[0];

  if (validators[ruleName]) {
    let passedValue = value === undefined || value === null ? '' : value;
    passedValue = typeof passedValue === 'number' ? passedValue.toString() : passedValue;
    return !validators[ruleName].call({}, passedValue, ruleParts[1], ruleParts[2], ruleParts[3], data);
  } else {
    // eslint-disable-next-line no-console
    console.error('No validator for ' + rule);
    return null;
  }
}

function validateField(value, ruleSet, data) {
  let rules = ruleSet.replace(/\s/g, '').split(/,(?![^[]*])/);

  let error;
  let ruleExtras = [];

  _.forEach(rules, function(rule) {
    let ruleParts = rule.split(':');
    let ruleName = ruleParts[0];

    let rVal = validateRule(rule, value, data);

    if (rVal) {
      error = ruleName;
      ruleExtras = ruleParts.slice(1);
      return false;
    }
  });

  return error
    ? {
        error: error,
        message: i18n.t(`ErrorMessages:${[error]}`, { ruleExtras })
      }
    : null;
}

function traverseRuleSet(ruleSet, data, path = '') {
  if (_.isArray(ruleSet)) {
    let rVal = [];

    const subData = path === '' ? data : _.get(data, path);
    _.forEach(subData, function(item, index) {
      let errors = traverseRuleSet(ruleSet[0], data, path + '.' + index);
      if (errors) {
        rVal[index] = errors;
      }
    });

    return rVal.length ? rVal : null;
  } else if (_.isObject(ruleSet)) {
    let rVal = {};

    _.forEach(ruleSet, function(subRuleSet, key) {
      let newPath = path ? path + '.' + key : key;
      let validationError = traverseRuleSet(subRuleSet, data, newPath);

      if (validationError) {
        rVal[key] = validationError;
      }
    });

    return _.keys(rVal).length ? rVal : null;
  } else {
    let value = _.get(data, path);
    let formData = data;

    if (path.indexOf('.') !== -1) {
      let containingPath = path.replace(/\.[a-zA-Z]+$/, '');
      formData = _.get(data, containingPath);
    }

    return validateField(value, ruleSet, formData);
  }
}

export default function validate(data, rules) {
  return traverseRuleSet(rules, data);
}
