import { useCallback } from 'react';
import { findAlgoliaVehicle } from '~/api/algolia';
import { fetchVehicleVrmLookupV2 } from '~/api/vrmLookup';

type UseVehicleLookupProps = {
  checkAlgolia: boolean;
  checkVrmLookup: boolean;
  dealershipId: string;
};

export const useVehicleLookup = ({ checkAlgolia, checkVrmLookup, dealershipId }: UseVehicleLookupProps) => {
  // Call the lookups in order until one returns a result
  // and if there are no lookups, throw an error
  const lookupVehicle = useCallback(
    async (vrm: string) => {
      const lookups: (typeof findAlgoliaVehicle | typeof fetchVehicleVrmLookupV2)[] = [];

      if (checkAlgolia) {
        lookups.push(findAlgoliaVehicle);
      }

      if (checkVrmLookup) {
        lookups.push(fetchVehicleVrmLookupV2);
      }

      for (const lookup of lookups) {
        const result = await lookup(vrm, dealershipId).catch(() => null);

        if (result) {
          return result;
        }
      }
      throw new Error('No vehicle found in any lookup');
    },
    [dealershipId, checkAlgolia, checkVrmLookup]
  );

  return {
    lookupVehicle
  };
};
