import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../Common/Form/Select';
import Button from '../../Common/Button/Button';
import MiniPanel from '../../Common/MiniPanel';
import MiniPanelHeader from '../../Common/MiniPanelHeader';
import MiniPanelToolbar from '../../Common/MiniPanelToolbar';
import './editNewDecision.scss';

class EditNewDecision extends React.Component {
  handleChange = (event) => {
    const { value } = event.target;
    this.props.handleChange(value);
  };

  render() {
    return (
      <MiniPanel>
        <MiniPanelHeader title={this.props.t('EditNewDecision.edit_new_decision')}>
          <small className="editNewDecision__small">{this.props.t('EditNewDecision.developer_only')}</small>
        </MiniPanelHeader>
        <MiniPanelToolbar>
          <span className="editNewDecision__toolbar">
            {this.props.t('EditNewDecision.choose_a_status_then_submit')}
          </span>
        </MiniPanelToolbar>
        <div className="editNewDecision__options">
          <Select
            className="editNewDecision__optionsSelect"
            options="LenderStatusDev"
            onChange={this.handleChange}
            value={this.props.optionValue}
            id="NewDecision"
          />
          <Button
            className="editNewDecision__optionsButton"
            disabled={!this.props.optionValue}
            onClick={this.props.handleSubmit}
            isLoading={this.props.isLoading}
            hasError={this.props.hasError}
          >
            {this.props.t('EditNewDecision.submit')}
          </Button>
        </div>
      </MiniPanel>
    );
  }
}

EditNewDecision.defaultProps = {
  isLoading: false,
  hasError: false
};
EditNewDecision.propTypes = {
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  optionValue: PropTypes.string
};
export default withTranslation('Application')(EditNewDecision);
