import React from 'react';
import PropTypes from 'prop-types';
import MoneyFormat from './MoneyFormat';

const CommissionValue = ({ moneyFormat, commissions }) => {
  if (moneyFormat) {
    return (
      <span>
        {commissions.map((commission, index) => (
          <MoneyFormat key={'commission' + index} value={commission.Amount} />
        ))}
      </span>
    );
  } else {
    return (
      <span>
        {commissions.map((commission, index) => (
          <span key={'commission2' + index}>{commission.Amount.toFixed(2)}</span>
        ))}
      </span>
    );
  }
};

CommissionValue.propTypes = {
  commissions: PropTypes.array,
  moneyFormat: PropTypes.bool
};

CommissionValue.defaultProps = {
  commissions: [],
  moneyFormat: true
};

export default CommissionValue;
