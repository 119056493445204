import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import AlertCard from '~/components/Common/AlertCard/AlertCard';

interface PreApprovalDeclinedAlertCardProps {
  canRepropose: boolean;
}

const PreApprovalDeclinedAlertCard = ({ canRepropose }: PreApprovalDeclinedAlertCardProps) => {
  const { t } = useTranslation('Application');
  const params = useParams();

  return (
    <div className="applicationStatus__alert">
      <AlertCard
        iconName="warning"
        title={t('ApplicationStatus.pre_approval_declined')}
        paragraph={t('ApplicationStatus.pre_approval_declined_try_an_alternative_lender')}
      >
        {canRepropose && (
          <Link
            to={`/d/${params.dealershipId}/consumers/${params.consumerId}/application/${params.applicantId}/repropose`}
            className="link"
          >
            {t('ApplicationStatus.try_alternative_lenders')}
          </Link>
        )}
      </AlertCard>
    </div>
  );
};

export default PreApprovalDeclinedAlertCard;
