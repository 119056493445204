import { action, observable, computed } from 'mobx';
import objectValidation from '../core/objectValidation';
import { countItemsInObject } from '../core/helpers';

const ruleSet = {
  used: {
    Make: 'required',
    Model: 'required',
    Derivative: 'required',
    RegistrationDate: 'required, date',
    Condition: 'required',
    Class: 'required',
    Mileage: 'required, number:int, min:1'
  },
  new: {
    Make: 'required',
    Model: 'required',
    Derivative: 'required',
    Condition: 'required',
    Class: 'required'
  }
};

class DeVehicleFormValidator {
  @observable errors = {};

  @action
  validate = (data) => {
    const rules = data.Condition?.toLowerCase() === 'used' ? ruleSet.used : ruleSet.new;
    this.errors = objectValidation(data, rules);
  };

  @computed
  get errorCount() {
    return countItemsInObject(this.errors);
  }

  getErrors() {
    return this.errors || {};
  }
}

export default DeVehicleFormValidator;
