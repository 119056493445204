import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import MobxForm from '../../MobxForm/MobxForm';
import './editPriceForm.scss';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import Validator from '../../../validators/Validator';
import { reaction } from 'mobx';
import { QuoteRequest } from '../../../core/quoteRequest';
import CurrencyInput from '../../Common/Form/CurrencyInput';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import PercentageInput from '../../Common/Form/PercentageInput';
import SelectInput from '../../Common/Form/SelectInput';
import RadioButton from '../../Common/Form/RadioButton';
import FieldToggle from '../../Common/Form/FieldToggle';
import CheckboxLabel from '../../Common/Form/CheckboxLabel';
import Checkbox from '../../Common/Form/Checkbox';
import FormFooter from '../../Common/Form/FormFooter';
import * as debug from '../../../debug';
import { trackSelfDescribingEvent } from '@ivendi/snowplow-js';
import { PropTypes, inject, observer } from 'mobx-react';
import Divider from '../../Common/Divider';
import { generateDefaultQuoteRequestValues } from '../components/utils';
import { hasExtendedTermLength } from '~/utils/quoteUtils';

class EditPriceForm extends Component {
  quoteRequest = null;

  constructor(props) {
    super(props);
    this.state = {
      showVatFields: true,
      customerType: props.defaultValues.CustomerType || this.props.customerType
    };
    this.validationRules = {
      VehiclePrice: 'required, currency',
      BasicPrice: 'required, currency',
      VatPercent: 'currency',
      VatAmount: 'currency',
      Term: 'required',
      CashDeposit: 'required, currency',
      AnnualDistance: 'required'
    };
    this.initQuoteRequest();
    this.setUpValidation();
  }

  initQuoteRequest = () => {
    const { vehicle, defaultValues, vehicleClass, vehicleCondition } = this.props;
    const defaultQuoteRequestValues = generateDefaultQuoteRequestValues({
      defaultValues,
      countryCode: this.props.appStore.uiState.countryCode,
      vehicleClass,
      vehicleCondition,
      vehicle,
      isBnpp: this.props.appStore.uiState.isBnpp
    });
    this.quoteRequest = new QuoteRequest(defaultQuoteRequestValues);
  };

  setUpValidation() {
    this.validator = new Validator();
    this.validator.setRules(this.validationRules);
    this.validationReactionDisposer = reaction(() => ({ ...this.quoteRequest.toObject() }), this.validator.validate, {
      fireImmediately: true
    });
  }

  componentDidMount() {
    let ActualCashDeposit =
      this.quoteRequest.ActualCashDeposit && parseFloat(this.quoteRequest.ActualCashDeposit)
        ? parseFloat(this.quoteRequest.ActualCashDeposit)
        : 0;
    this.quoteRequest.set('CashDeposit', ActualCashDeposit);
    this.setState({
      ActualCashDeposit
    });
  }

  toggleField(field) {
    this.setState({
      [field]: !this.state[field]
    });
  }

  setVatAddedToDeposit = (VatAddedToDeposit) => this.quoteRequest.set('VatAddedToDeposit', VatAddedToDeposit);
  setBasicPrice = (BasicPrice) => this.quoteRequest.set('BasicPrice', BasicPrice);
  setVatPercent = (fieldName, VatPercent) => this.quoteRequest.set('VatPercent', VatPercent);
  setVatAmount = (VatAmount) => this.quoteRequest.set('VatAmount', VatAmount);
  setNonVatableItems = (NonVatableItems) => this.quoteRequest.set('NonVatableItems', NonVatableItems);
  setCustomerType = (fieldName, CustomerType) =>
    this.setState({
      customerType: CustomerType
    });
  setVehiclePrice = (VehiclePrice) => this.quoteRequest.set('VehiclePrice', VehiclePrice);
  setTerm = (fieldName, Term) => this.quoteRequest.set('Term', Term);
  setAnnualDistance = (fieldName, AnnualDistance) => this.quoteRequest.set('AnnualDistance', AnnualDistance);
  setVatQualifying = (e) => {
    const value = e.target.value;
    const isVatQualifying = value === 'VatQualifying';
    this.quoteRequest.set('VatQualifying', isVatQualifying);
  };
  setCashDeposit = (CashDeposit) => {
    this.quoteRequest.set('CashDeposit', CashDeposit);
    this.setState({
      ActualCashDeposit: CashDeposit
    });
  };
  handleSubmit = () => {
    if (!this.validator.errorCount) {
      this.quoteRequest.ValueAddedProducts.map((vap) => {
        if (vap.TaxTypeCode < 1 || vap.TaxTypeCode === undefined) {
          debug.error(
            'taxTypeZero ' +
              JSON.stringify({
                productTypes: this.props.appStore.vapStore.visibleProducts,
                vap: vap
              })
          );
        }
      });
      this.props.onSubmit(
        Object.assign({}, this.quoteRequest.toObject(), {
          CustomerType: this.state.customerType,
          LoanType: 'vehicleLoan',
          FinanceType: this.props.quote.FinanceType
        })
      );
      trackSelfDescribingEvent(
        'QuoteCostsFormSubmit',
        'ClickGenerateQuotesButton',
        JSON.stringify({
          vehicleClass: this.props.vehicleClass.toLowerCase(),
          VatAddedToDeposit: this.quoteRequest.VatAddedToDeposit ? 'true' : 'false'
        })
      );
    } else {
      this.validator.validate(this.quoteRequest.toObject());
    }
  };

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  render() {
    const errors = this.validator.getErrors();
    const isLcv = this.props.vehicleClass.toLowerCase() === 'lcv';
    const isNew = this.props.vehicleCondition.toLowerCase() === 'new';
    const loadingState = this.props.isLoading ? 'loading' : 'done';
    const showNonVatableItems = this.quoteRequest.NonVatableItems > 0;
    let depositRules;

    if (isNaN(parseFloat(this.quoteRequest.CashDeposit))) {
      depositRules = errors.CashDeposit;
    } else {
      depositRules =
        parseFloat(this.quoteRequest.CashDeposit) <= this.quoteRequest.VehiclePrice
          ? errors.CashDeposit
          : {
              error: 'max',
              message: this.props.t('EditPriceForm.cash_deposit_cannot_be_larger_than_the_vehicle_price')
            };
    }

    let newLoanAmount = this.quoteRequest.VehiclePrice - this.quoteRequest.CashDeposit;
    newLoanAmount = newLoanAmount < 0 ? 0 : newLoanAmount;
    return (
      <div>
        <MobxForm onSubmit={this.handleSubmit} className="editPriceForm">
          <div className="editPriceForm__section">
            {this.props.isQuickQuote ? (
              <MobxFieldGroup isInline error={errors.CustomerType}>
                <MobxFormLabel htmlFor="CustomerType">{this.props.t('EditPriceForm.customer_type')}</MobxFormLabel>
                <SelectInput
                  trackingPage="EditPriceForm"
                  emptyValue={false}
                  value={this.state.customerType}
                  onChange={this.setCustomerType}
                  options="QuickQuoteCustomerType"
                  id="CustomerType"
                />
              </MobxFieldGroup>
            ) : (
              ''
            )}

            <MobxFieldGroup isInline error={errors.TotalPrice}>
              <MobxFormLabel htmlFor="TotalPrice">{this.props.t('EditPriceForm.current_loan_amount')}</MobxFormLabel>
              <CurrencyInput trackingPage="EditPriceForm" value={this.quoteRequest.Advance} disabled id="TotalPrice" />
            </MobxFieldGroup>

            {isLcv && this.quoteRequest.VatQualifying ? (
              <MobxFieldGroup isInline error={errors.BasicPrice}>
                <MobxFormLabel htmlFor="BasicPrice">{this.props.t('EditPriceForm.basic_price')}</MobxFormLabel>
                <CurrencyInput
                  trackingPage="EditPriceForm"
                  value={this.quoteRequest.BasicPrice}
                  onChange={this.setBasicPrice}
                  id="BasicPrice"
                />
              </MobxFieldGroup>
            ) : (
              <MobxFieldGroup isInline error={errors.VehiclePrice}>
                <MobxFormLabel htmlFor="VehiclePrice">{this.props.t('EditPriceForm.vehicle_price')}</MobxFormLabel>
                <CurrencyInput
                  trackingPage="EditPriceForm"
                  value={this.quoteRequest.VehiclePrice}
                  onChange={this.setVehiclePrice}
                  id="VehiclePrice"
                />
              </MobxFieldGroup>
            )}

            <MobxFieldGroup isInline error={null}>
              <div className="editPriceForm__vatQualifyingOptions">
                <div className="editPriceForm__vatMarginScheme">
                  <RadioButton
                    trackingPage="EditPriceForm"
                    name="vatQualifying"
                    onChange={this.setVatQualifying}
                    value="VatMarginScheme"
                    disabled={isNew}
                    checked={!this.quoteRequest.VatQualifying}
                    id="VATMargin"
                  >
                    {this.props.t('EditPriceForm.vat_margin_scheme')}
                  </RadioButton>
                </div>
                <div className="editPriceForm__vatQualifying">
                  <RadioButton
                    trackingPage="EditPriceForm"
                    name="vatQualifying"
                    onChange={this.setVatQualifying}
                    value="VatQualifying"
                    disabled={isNew}
                    checked={this.quoteRequest.VatQualifying}
                    id="VATQualifying"
                  >
                    {this.props.t('EditPriceForm.vat_qualifying')}
                  </RadioButton>
                </div>
              </div>
            </MobxFieldGroup>

            {this.quoteRequest.VatQualifying && (
              <div className="editPriceForm__buttonWrap">
                <FieldToggle
                  text={this.props.t('EditPriceForm.vat_options')}
                  onClick={() => this.toggleField('showVatFields')}
                  showHide={this.state.showVatFields}
                />
              </div>
            )}

            {this.quoteRequest.VatQualifying && this.state.showVatFields && (
              <div>
                {isLcv ? (
                  <MobxFieldGroup isInline error={errors.VehiclePrice}>
                    <MobxFormLabel htmlFor="VehiclePrice">{this.props.t('EditPriceForm.vehicle_price')}</MobxFormLabel>
                    <CurrencyInput
                      trackingPage="EditPriceForm"
                      value={this.quoteRequest.VehiclePrice}
                      onChange={this.setVehiclePrice}
                      id="VehiclePrice"
                    />
                  </MobxFieldGroup>
                ) : (
                  <MobxFieldGroup isInline error={errors.BasicPrice}>
                    <MobxFormLabel htmlFor="BasicPrice">{this.props.t('EditPriceForm.basic_price')}</MobxFormLabel>
                    <CurrencyInput
                      trackingPage="EditPriceForm"
                      value={this.quoteRequest.BasicPrice}
                      onChange={this.setBasicPrice}
                      id="BasicPrice"
                    />
                  </MobxFieldGroup>
                )}

                <MobxFieldGroup isInline error={errors.VatPercent}>
                  <MobxFormLabel htmlFor="VatPercent">{this.props.t('EditPriceForm.vat')}</MobxFormLabel>
                  <PercentageInput value={this.quoteRequest.VatPercent} onChange={this.setVatPercent} id="VatPercent" />
                </MobxFieldGroup>

                <MobxFieldGroup isInline error={errors.VatAmount}>
                  <MobxFormLabel htmlFor="VatAmount">{this.props.t('EditPriceForm.vat_amount')}</MobxFormLabel>
                  <CurrencyInput
                    trackingPage="EditPriceForm"
                    value={this.quoteRequest.VatAmount}
                    onChange={this.setVatAmount}
                    id="VatAmount"
                  />
                </MobxFieldGroup>

                <MobxFieldGroup isInline>
                  <div />
                  <div className="editPriceForm__vatCheckbox">
                    <CheckboxLabel>
                      <Checkbox
                        value={this.quoteRequest.VatAddedToDeposit}
                        onChange={this.setVatAddedToDeposit}
                        trackingPage="EditPriceForm"
                        id="AddVatToDeposit"
                      />
                      {this.props.t('EditPriceForm.include_vat_with_deposit')}
                    </CheckboxLabel>
                  </div>
                </MobxFieldGroup>

                {showNonVatableItems && (
                  <MobxFieldGroup isInline error={errors.NonVatableItems}>
                    <MobxFormLabel htmlFor="NonVatableItems">
                      {this.props.t('EditPriceForm.non_vatable_items')}
                    </MobxFormLabel>
                    <CurrencyInput
                      trackingPage="EditPriceForm"
                      value={this.quoteRequest.NonVatableItems}
                      onChange={this.setNonVatableItems}
                      id="NonVatableItems"
                    />
                  </MobxFieldGroup>
                )}
              </div>
            )}
            <Divider />

            <MobxFieldGroup isInline error={depositRules}>
              <MobxFormLabel htmlFor="Term">{this.props.t('EditPriceForm.cash_deposit')}</MobxFormLabel>
              <CurrencyInput
                trackingPage="EditVapsForm"
                value={this.state.ActualCashDeposit}
                onChange={this.setCashDeposit}
                id="CashDeposit"
              />
            </MobxFieldGroup>

            <MobxFieldGroup isInline error={errors.AnnualDistance}>
              <MobxFormLabel htmlFor="AnnualDistance">{this.props.t('EditPriceForm.annual_mileage')}</MobxFormLabel>
              <SelectInput
                trackingPage="EditPriceForm"
                value={this.quoteRequest.AnnualDistance}
                onChange={this.setAnnualDistance}
                options="MileageOptions"
                id="AnnualMileage"
              />
            </MobxFieldGroup>

            <MobxFieldGroup isInline error={errors.Term}>
              <MobxFormLabel htmlFor="Term">{this.props.t('EditPriceForm.term_months')}</MobxFormLabel>
              <SelectInput
                trackingPage="EditPriceForm"
                value={this.quoteRequest.Term}
                onChange={this.setTerm}
                options={hasExtendedTermLength(this.props.vehicleClass) ? 'TermsExtended' : 'Terms'}
                id="Term"
              />
            </MobxFieldGroup>
            <Divider />
            <MobxFieldGroup isInline error={null}>
              <MobxFormLabel>{this.props.t('EditPriceForm.new_loan_amount')}</MobxFormLabel>
              <CurrencyInput trackingPage="EditPriceForm" value={newLoanAmount} disabled={true} id="NewLoanAmount" />
            </MobxFieldGroup>
          </div>
          <FormFooter
            submitLabel={this.props.t('EditPriceForm.generate_quotes')}
            onCancel={this.props.onCancel}
            submittingState={loadingState}
            trackingPage="EditPriceForm"
          />
        </MobxForm>
      </div>
    );
  }
}

EditPriceForm.propTypes = {
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  defaultValues: PropTypes.object,
  vehicleClass: PropTypes.string,
  vehicle: PropTypes.object
};
export default withTranslation('Quoting')(inject(['appStore'])(observer(EditPriceForm)));
