import React from 'react';
import Icon from '../Icon/Icon';
import './bulletListTick.scss';

const BulletListTick = () => {
  return (
    <span className="bulletListTick">
      <Icon name="validation-tick" />
    </span>
  );
};

export default BulletListTick;
