import React from 'react';
import PropTypes from 'prop-types';
import './customerlistToolbarLayout.scss';

const CustomerListToolbarLayout = ({ children }) => (
  <div className="customerListToolbarLayout__outer">
    {React.Children.toArray(children).map((child, i) => {
      return (
        <div key={`toolbar${i}`} className={`customerListToolbarLayout__toolbar${i}`}>
          {React.cloneElement(child)}
        </div>
      );
    })}
  </div>
);

CustomerListToolbarLayout.propTypes = {
  children: PropTypes.node
};

export default CustomerListToolbarLayout;
