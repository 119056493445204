import React from 'react';
import PropTypes from 'prop-types';
import './eligibilityModalGaugeSection.scss';
import Gauge from '../../Common/Gauge';

const EligibilityModalGaugeSection = ({
  matchrate,
  gaugeBkg,
  header,
  paragraphOne,
  paragraphTwo,
  paragraphThree,
  paragraphFour
}) => (
  <div>
    <div className="eligibilityModalGaugeSection__topSection">
      <div className="eligibilityModalGaugeSection__topLeft">
        <div className="eligibilityModalGaugeSection__gaugeContainer" key="gauge">
          <Gauge matchrate={matchrate} background={gaugeBkg} />
        </div>
      </div>
      <div className="eligibilityModalGaugeSection__topRight">
        <div className="eligibilityModalGaugeSection__bold" key="bold">
          {header}
        </div>
        <div className="eligibilityModalGaugeSection__p" key="p1">
          {paragraphOne}
        </div>
        <div className="eligibilityModalGaugeSection__p" key="p2">
          {paragraphTwo}
        </div>
      </div>
    </div>
    <div className="eligibilityModalGaugeSection__p" key="p3">
      {paragraphThree}
    </div>
    <div className="eligibilityModalGaugeSection__p" key="p4">
      {paragraphFour}
    </div>
  </div>
);

EligibilityModalGaugeSection.propTypes = {
  matchrate: PropTypes.number,
  gaugeBkg: PropTypes.string,
  header: PropTypes.string,
  paragraphOne: PropTypes.string,
  paragraphTwo: PropTypes.string,
  paragraphThree: PropTypes.string,
  paragraphFour: PropTypes.string
};

export default EligibilityModalGaugeSection;
