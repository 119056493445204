import React from 'react';
import { connect } from 'react-redux';
import RepExampleEdit from '../components/RepExampleEdit';

const RepExampleSettingsEditContainer = (props) => {
  return <RepExampleEdit {...props} />;
};

function mapStateToProps(state, _) {
  return {
    dealership: state.dealership,
    preferredTerm: state.globalDefaultSettings.PreferredTerm,
    preferredDeposit: state.globalDefaultSettings.DepositValue,
    preferredAnnualMileage: state.globalDefaultSettings.DefaultAnnualMileage,
    productSettings: state.products.items
  };
}

export default connect(mapStateToProps)(RepExampleSettingsEditContainer);
