import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFormFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import TextInputWrappingInput from '../../Common/Form/TextInputWrappingInput';
import { observable, action, reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import Validator from '../../../validators/Validator';
import SelectInput from '../../Common/Form/SelectInput';
import CheckboxWithLabel from '../../Common/Form/CheckboxWithLabel';
import PostcodeLookup from '../../Common/PostcodeLookup/PostcodeLookup';
import FormFooter from '../../Common/Form/FormFooter';
import EmailLookup from '../../Common/EmailLookup';
import './addConsumerForm.scss';
import { trimDeepObjectStrings } from '../../../core/helpers';
import { withNavigate } from 'hocs/router';
import { PhoneNumberInput } from '~/components/Common/Form/PhoneNumberInput';

class AddConsumerForm extends React.Component {
  @observable
  formData = {
    customerType: 'consumer',
    email: '',
    title: '',
    firstName: '',
    middleName: '',
    surname: '',
    landline: '',
    mobile: '',
    assignToSelf: false,
    buildingName: '',
    buildingNumber: '',
    subBuilding: '',
    street: '',
    district: '',
    postTown: '',
    postCode: ''
  };
  @observable
  isEmailTaken = false;

  constructor() {
    super();
    const validationRules = {
      title: 'required',
      firstName: 'required, maxlength:50, doesNotStartOrEndWithSpace',
      middleName: 'maxlength:50, doesNotStartOrEndWithSpace',
      surname: 'required, maxlength:50, doesNotStartOrEndWithSpace',
      email: 'required, email',
      landline: 'required:unless:mobile, landline',
      mobile: 'required:unless:landline, mobile',
      buildingName: 'maxlength:40, doesNotStartOrEndWithSpace',
      buildingNumber: 'maxlength:10',
      subBuilding: 'maxlength:15, doesNotStartOrEndWithSpace',
      street: 'maxlength:75, lenderAlphanumeric',
      district: 'maxlength:55, lenderAlphanumeric',
      postTown: 'maxlength:35, doesNotStartOrEndWithSpace',
      postCode: 'alphaNumSpace, doesNotStartOrEndWithSpace'
    };
    this.setUpValidation(validationRules);
  }

  setUpValidation(validationRules) {
    this.validator = new Validator();
    this.validator.setRules(validationRules);
    this.validationReactionDisposer = reaction(() => ({ ...this.formData }), this.validator.validate, {
      fireImmediately: true
    });
  }

  @action
  cleanupFormData = () => {
    this.formData = trimDeepObjectStrings(this.formData);
    this.validator.validate(this.formData);
  };
  handleSubmit = () => {
    const {
      appStore: { customerListStore }
    } = this.props;
    this.cleanupFormData();

    if (!this.validator.errorCount && !this.isEmailTaken) {
      customerListStore
        .addCustomer(this.formData, this.props.dealershipId, this.formData.assignToSelf)
        .then((customerId) => {
          this.props.navigate(`/d/${this.props.dealershipId}/consumers/${customerId}`);
        });
    }
  };
  handleCancel = () => {
    this.props.navigate(-1);
  };
  handleEmailLookup = (value) => {
    this.isEmailTaken = !!value;
  };

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  @action
  handlePostCodeLookup = (address) => {
    this.formData.buildingName = address.BuildingName;
    this.formData.buildingNumber = address.BuildingNumber;
    this.formData.subBuilding = address.SubBuilding;
    this.formData.street = address.Street;
    this.formData.district = address.District;
    this.formData.postTown = address.PostTown;
    this.formData.postCode = address.Postcode;
    this.cleanupFormData();
  };
  @action
  setCustomerType = (fieldName, customerType) => {
    this.formData.customerType = customerType;
    this.props.onCustomerTypeChange(customerType);
  };
  @action
  setEmail = (email) => (this.formData.email = email);
  @action
  setTitle = (fieldName, title) => (this.formData.title = title);
  @action
  setFirstName = (firstName) => (this.formData.firstName = firstName);
  @action
  setMiddleName = (middleName) => (this.formData.middleName = middleName);
  @action
  setSurname = (surname) => (this.formData.surname = surname);
  @action
  setLandline = (landline) => (this.formData.landline = landline);
  @action
  setMobile = (mobile) => (this.formData.mobile = mobile);
  @action
  setAssignToSelf = (value) => (this.formData.assignToSelf = value);
  @action
  setBuildingName = (buildingName) => (this.formData.buildingName = buildingName);
  @action
  setBuildingNumber = (buildingNumber) => (this.formData.buildingNumber = buildingNumber);
  @action
  setSubBuilding = (subBuilding) => (this.formData.subBuilding = subBuilding);
  @action
  setStreet = (street) => (this.formData.street = street);
  @action
  setDistrict = (district) => (this.formData.district = district);
  @action
  setPostTown = (postTown) => (this.formData.postTown = postTown);
  @action
  setPostCode = (postCode) => (this.formData.postCode = postCode);

  render() {
    const errors = this.validator.getErrors();
    const {
      appStore: { customerListStore, uiState }
    } = this.props;

    const funderCode = uiState.getPlatformCode;

    return (
      <MobxForm onSubmit={this.handleSubmit} focusOnFirstElement className="addConsumerForm">
        <section className="addConsumerForm__personalDetails">
          <h2 className="addConsumerForm__header addConsumerForm__personalDetailsHeader">
            {this.props.t('AddConsumerForm.personal_details')}
          </h2>

          <div className="addConsumerForm__fields">
            <MobxFormFieldGroup error={errors.customerType}>
              <MobxFormLabel htmlFor="customerType">{this.props.t('AddConsumerForm.customer_type')}</MobxFormLabel>
              <SelectInput
                id="customerType"
                value={this.formData.customerType}
                onChange={this.setCustomerType}
                options="ContactType"
                emptyValue={false}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.email}>
              <MobxFormLabel htmlFor="email">{this.props.t('AddConsumerForm.email')}</MobxFormLabel>
              <EmailLookup
                onChange={this.setEmail}
                dealershipId={this.props.dealershipId}
                value={this.formData.email}
                sector="residential"
                onLookup={this.handleEmailLookup}
                autoComplete="none"
                placeholder={this.props.t('AddConsumerForm.enter_customer_email_address')}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.title}>
              <MobxFormLabel htmlFor="title">{this.props.t('AddConsumerForm.title')}</MobxFormLabel>
              <SelectInput
                id="title"
                value={this.formData.title}
                onChange={this.setTitle}
                options="CustomerTitles"
                funderCode={funderCode}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.firstName}>
              <MobxFormLabel htmlFor="firstName">{this.props.t('AddConsumerForm.first_name')}</MobxFormLabel>
              <TextInputWrappingInput id="firstName" value={this.formData.firstName} onChange={this.setFirstName} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.middleName}>
              <MobxFormLabel htmlFor="middleName">{this.props.t('AddConsumerForm.middle_name')}</MobxFormLabel>
              <TextInputWrappingInput id="middleNames" value={this.formData.middleName} onChange={this.setMiddleName} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.surname}>
              <MobxFormLabel htmlFor="surname">{this.props.t('AddConsumerForm.last_name')}</MobxFormLabel>
              <TextInputWrappingInput id="surname" value={this.formData.surname} onChange={this.setSurname} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup
              error={errors.landline}
              errorMessage={
                errors.landline?.error === 'required' &&
                this.props.t('AddConsumerForm.either_home_telephone_or_mobile_is_required')
              }
            >
              <MobxFormLabel htmlFor="landline">{this.props.t('AddConsumerForm.home_telephone')}</MobxFormLabel>
              <PhoneNumberInput id="landline" value={this.formData.landline} onChange={this.setLandline} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup
              error={errors.mobile}
              errorMessage={
                errors.mobile?.error === 'required' &&
                this.props.t('AddConsumerForm.either_home_telephone_or_mobile_is_required')
              }
            >
              <MobxFormLabel htmlFor="mobile">{this.props.t('AddConsumerForm.or_mobile_telephone')}</MobxFormLabel>
              <PhoneNumberInput id="mobile" value={this.formData.mobile} onChange={this.setMobile} />
            </MobxFormFieldGroup>
            {this.props.appStore.uiState.canAssign && (
              <CheckboxWithLabel id="assignToSelf" value={this.formData.assignToSelf} onChange={this.setAssignToSelf}>
                {this.props.t('AddConsumerForm.assign_this_lead_to_me')}
              </CheckboxWithLabel>
            )}
          </div>
        </section>

        <section className="addConsumerForm__addressDetails">
          <h2 className="addConsumerForm__header addConsumerForm__addressDetailsHeader">
            {this.props.t('AddConsumerForm.address_details')}
          </h2>

          <div className="addConsumerForm__fields">
            <PostcodeLookup onSubmit={this.handlePostCodeLookup} />

            <MobxFormFieldGroup error={errors.buildingName}>
              <MobxFormLabel htmlFor="buildingName">{this.props.t('AddConsumerForm.building_name')}</MobxFormLabel>
              <TextInputWrappingInput
                id="buildingName"
                value={this.formData.buildingName}
                onChange={this.setBuildingName}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.buildingNumber}>
              <MobxFormLabel htmlFor="buildingNumber">{this.props.t('AddConsumerForm.building_number')}</MobxFormLabel>
              <TextInputWrappingInput
                id="buildingNumber"
                value={this.formData.buildingNumber}
                onChange={this.setBuildingNumber}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.subBuilding}>
              <MobxFormLabel htmlFor="subBuilding">{this.props.t('AddConsumerForm.room_floor')}</MobxFormLabel>
              <TextInputWrappingInput
                id="subBuilding"
                value={this.formData.subBuilding}
                onChange={this.setSubBuilding}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.street}>
              <MobxFormLabel htmlFor="street">{this.props.t('AddConsumerForm.street')}</MobxFormLabel>
              <TextInputWrappingInput id="street" value={this.formData.street} onChange={this.setStreet} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.district}>
              <MobxFormLabel htmlFor="district">{this.props.t('AddConsumerForm.district')}</MobxFormLabel>
              <TextInputWrappingInput id="district" value={this.formData.district} onChange={this.setDistrict} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.postTown}>
              <MobxFormLabel htmlFor="postTown">{this.props.t('AddConsumerForm.city_town')}</MobxFormLabel>
              <TextInputWrappingInput id="postTown" value={this.formData.postTown} onChange={this.setPostTown} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.postCode}>
              <MobxFormLabel htmlFor="postCode">{this.props.t('AddConsumerForm.postcode')}</MobxFormLabel>
              <TextInputWrappingInput id="postCode" value={this.formData.postCode} onChange={this.setPostCode} />
            </MobxFormFieldGroup>
          </div>
        </section>

        <div className="addConsumerForm__footer">
          <FormFooter
            submitLabel={this.props.t('AddConsumerForm.add_customer_contact')}
            onCancel={this.handleCancel}
            errorMessage={
              customerListStore.addCustomerRequest.hasError
                ? this.props.t('AddConsumerForm.sorry_an_error_occurred_while_trying_to_add_this_customer')
                : ''
            }
            isSubmitting={customerListStore.addCustomerRequest.isLoading}
            hasSubmittingError={customerListStore.addCustomerRequest.hasError}
          />
        </div>
      </MobxForm>
    );
  }
}

AddConsumerForm.propTypes = {
  dealershipId: PropTypes.string,
  appStore: PropTypes.object,
  onCustomerTypeChange: PropTypes.func
};
export default withNavigate(withTranslation('AddCustomer')(inject(['appStore'])(observer(AddConsumerForm))));
