import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Button from '../Button/Button';
import './gdprAwarenessModal.scss';

class GdprAwarenessModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.goodPracticeTimeUpdate || this.props.hasNewGoodPracticeVersion
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      isOpen: nextProps.goodPracticeTimeUpdate || nextProps.hasNewGoodPracticeVersion
    });
  }

  handleGdprModalClick = () => {
    this.props.onClick();
    this.setState({
      isOpen: false
    });
  };

  render() {
    const { content } = this.props;
    return (
      <TransitionGroup>
        {this.state.isOpen ? (
          <CSSTransition
            classNames="gdprAwarenessModalOuter"
            timeout={{
              enter: 400,
              exit: 400
            }}
            key="gdprAwarenessModal"
          >
            <div className="gdprAwarenessModal__outer">
              <div className="gdprAwarenessModal__inner">
                <div className="gdprAwarenessModal__title">
                  {this.props.t('GdprAwarenessModal.a_reminder_of_your_gdpr_responsibilities')}
                </div>
                <div className="gdprAwarenessModal__content" dangerouslySetInnerHTML={content} />
                <div className="gdprAwarenessModal__button">
                  <Button onClick={this.handleGdprModalClick}>{this.props.t('GdprAwarenessModal.continue')}</Button>
                </div>
              </div>
            </div>
          </CSSTransition>
        ) : null}
      </TransitionGroup>
    );
  }
}

GdprAwarenessModal.propTypes = {
  onClick: PropTypes.func,
  content: PropTypes.any,
  hasNewGoodPracticeVersion: PropTypes.bool,
  goodPracticeTimeUpdate: PropTypes.bool
};
export default withTranslation('Common')(GdprAwarenessModal);
