import cn from 'classnames';
import RadioButton, { RadioButtonPropTypes } from './RadioButton';

import './radioButtonGroup.scss';

const RadioButtonGroup = ({
  options,
  name,
  onChange,
  checkedValue,
  isGroupDisabled,
  variant = 'stacked',
  dataThook
}: RadioButtonGroupProps) => {
  const formattedOptions = options.map((option) => {
    if ('key' in option) {
      return {
        label: option.value,
        value: option.key
      };
    }
    return option;
  });

  return (
    <div
      className={cn('radioGroup', {
        'radioGroup--inline': variant === 'inline',
        'radioGroup--stacked': variant === 'stacked'
      })}
      data-th={`${dataThook || name}-RadioButtonGroup`}
    >
      {formattedOptions.map((option) => (
        <RadioButton
          id={option.id || `${name}-${option.value}`}
          name={name}
          value={option.value}
          onChange={onChange}
          checked={checkedValue === option.value}
          disabled={option.disabled || isGroupDisabled}
          variant={variant}
          key={`${name}-${option.value}`}
        >
          {option.label}
        </RadioButton>
      ))}
    </div>
  );
};

interface RadioButtonGroupProps {
  options: (
    | {
        id?: string;
        label: string;
        value: string;
        disabled?: boolean;
      }
    | {
        key: string;
        value: string;
      }
  )[];
  name: RadioButtonPropTypes['name'];
  onChange: RadioButtonPropTypes['onChange'];
  checkedValue: unknown;
  isGroupDisabled: boolean;
  variant?: RadioButtonPropTypes['variant'];
  dataThook?: string;
}

export default RadioButtonGroup;

