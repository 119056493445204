import { canSeeHomePage } from '~/features';

export const isDealershipMultiLender = (dealership: any) => {
  // uk
  const lenderModules = dealership.Modules.filter((module: any) => /^lender-[a-z]{3}-enabled$/.test(module));
  // de
  const lenders = Object.values(dealership.Lenders || {}).filter((lender: any) => lender.Enabled);
  return lenderModules.length > 1 || lenders.length > 1;
};

export const canDealershipUseHomePage = (dealership: any) => {
  return canSeeHomePage() && (dealership.PlatformBrand === 'multilender' || dealership.Modules.includes('multilender'));
};
