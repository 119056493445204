import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './dashboardLeadsFilter.scss';
import classnames from 'classnames';

const DashboardLeadsFilter = (props) => {
  const { t } = useTranslation('Dashboard');
  const allFilterClasses = classnames(
    'dashboardLeadsFilter__button',
    (props.filter === 'all' || !props.filter) && 'dashboardLeadsFilter__filterActive'
  );
  const mineFilterClasses = classnames(
    'dashboardLeadsFilter__button',
    props.filter === 'mine' && 'dashboardLeadsFilter__filterActive'
  );
  const unassignedFilterClasses = classnames(
    'dashboardLeadsFilter__button',
    props.filter === 'unassigned' && 'dashboardLeadsFilter__filterActive'
  );
  return (
    <div className="dashboardLeadsFilter__wrapper">
      <div className="dashboardLeadsFilter__outer" key="outer">
        <div className="dashboardLeadsFilter__viewText" key="viewText">
          {t('DashboardLeadsFilter.view')}
        </div>
        <div className="dashboardLeadsFilter__buttonContainer" key="buttonContainer">
          <button className={allFilterClasses} key="btn1" onClick={() => props.onClick('all')}>
            {t('DashboardLeadsFilter.all_leads')}
          </button>
          <button className={mineFilterClasses} key="btn2" onClick={() => props.onClick('mine')}>
            {t('DashboardLeadsFilter.my_leads')}
          </button>
          <button className={unassignedFilterClasses} key="btn3" onClick={() => props.onClick('unassigned')}>
            {t('DashboardLeadsFilter.unassigned_leads')}
          </button>
        </div>
      </div>
    </div>
  );
};

DashboardLeadsFilter.propTypes = {
  filter: PropTypes.string,
  onClick: PropTypes.func
};
export default DashboardLeadsFilter;
