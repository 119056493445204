import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './applicationNavigationItem.scss';
import Icon from '../../Common/Icon/Icon';
import ApplicationProgressBar from './ApplicationProgressBar';
import { NavLink } from 'react-router-dom';

class ApplicationNavigationItem extends React.Component {
  UNSAFE_componentWillMount() {
    this.setState({ windowWidth: window.innerWidth });
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleClick = () => {
    this.props.onClick?.(this.props);
  };

  render() {
    const itemWidth = 100 / this.props.numItems + '%';

    return (
      <li>
        <NavLink
          to={this.props.link || '#'}
          className={({ isActive }) =>
            classnames('applicationNavigationItem', {
              applicationNavigationItem__active: this.props.isActive !== undefined ? this.props.isActive : isActive
            })
          }
        >
          <div
            style={this.state.windowWidth < 1001 ? { width: itemWidth } : { width: '100%' }}
            key="li"
            id={`applicationNavigationItem-${this.props.id}`}
          >
            <div className="applicationNavigationItem__link" onClick={this.handleClick}>
              <div className="applicationNavigationItem__imageOuter">
                <div className="applicationNavigationItem__image" key="image">
                  <Icon name={this.props.imageName} />
                </div>
              </div>
              <div className="applicationNavigationItem__inner">
                <div className="applicationNavigationItem__text" key="text">
                  {this.props.text}
                </div>
                <ApplicationProgressBar progress={this.props.progress} />
              </div>
            </div>
          </div>
        </NavLink>
      </li>
    );
  }
}

ApplicationNavigationItem.propTypes = {
  link: PropTypes.string,
  isActive: PropTypes.bool,
  numItems: PropTypes.number,
  imageName: PropTypes.any,
  text: PropTypes.string,
  progress: PropTypes.number,
  onClick: PropTypes.func
};

export default ApplicationNavigationItem;
