import { types } from '../schema';

export default {
  DepositType: types.String,
  DepositValue: types.Number,
  DisabledEditing: types.Boolean,
  FourSquareEnabled: types.Boolean,
  Insurance: types.Number,
  Other: types.Number,
  PreferredTerm: types.Number,
  Scheme: types.String,
  SchemeValue: types.Number,
  UsageType: types.String,
  VehicleClass: types.String,
  VehicleType: types.String,
  Warranty: types.Number,
  DefaultAnnualMileage: types.Number
};
