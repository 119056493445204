import React from 'react';
import PropTypes from 'prop-types';
import './cfcMatchRateTile.scss';
import { useTranslation } from 'react-i18next';

const CfcMatchRateTile = ({ data, matchRate }) => {
  const { t } = useTranslation('DigitalReport');

  return (
    <div className={`cfcMatchRateTile cfcMatchRateTile--${matchRate}`}>
      <div className={`cfcMatchRateTile__name cfcMatchRateTile__name--${matchRate}`}>{matchRate}</div>
      <div className="cfcMatchRateTile__value">
        <span className="cfcMatchRateTile__current">{data ? data.Current : t('CfcMatchRateTile.unavailable')}</span>
      </div>
    </div>
  );
};

CfcMatchRateTile.propTypes = {
  data: PropTypes.object,
  matchRate: PropTypes.string
};

export default CfcMatchRateTile;
