import React from 'react';
import PropTypes from 'prop-types';
import './checkboxLabel.scss';

const CheckboxLabel = ({ children }) => (
  <label className="checkboxLabel">
    <div className="checkboxLabel__checkbox">{children[0]}</div>
    <div className="checkboxLabel__label">{children[1]}</div>
  </label>
);

CheckboxLabel.propTypes = {
  children: PropTypes.node
};

export default CheckboxLabel;
