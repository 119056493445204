import _ from 'lodash';

const blacklistRegexes = [/^.*Breeze/, /^.*LP/];

const corporatebBlacklistRegexes = [/^.*Breeze/, /^.*LP/];

export const isAllowed = (quote, customerType) => {
  const blacklist = customerType.toLowerCase() === 'corporate' ? corporatebBlacklistRegexes : blacklistRegexes;

  return (
    !_.chain(blacklist)
      .map((regex) => regex.test(quote.ProductName))
      .reduce((a, b) => a || b)
      .value() && quote.FunderCode === 'MOT'
  );
};
