import React from 'react';
import PropTypes from 'prop-types';

import './vehicleInfoList.scss';

const VehicleInfoList = ({ list }) => {
  return (
    <ul className="vehicleInfoList" data-th="VehicleInfoList">
      {list.map(({ label, value }) => {
        return (
          <li key={label}>
            <span className="vehicleInfoList__label">{label}</span>
            <span className="vehicleInfoList__value">{value}</span>
          </li>
        );
      })}
    </ul>
  );
};

VehicleInfoList.propTypes = {
  list: PropTypes.array.isRequired
};

export default VehicleInfoList;
