import React from 'react';
import PropTypes from 'prop-types';
import './table.scss';

const Table = ({ children }) => <table className="table__table">{children}</table>;

Table.propTypes = {
  children: PropTypes.node
};

export default Table;
