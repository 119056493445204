import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CustomerListItem from './CustomerListItem';
import Table from '../../Common/Table/Table';
import TableHeader from '../../Common/Table/TableHeader';
import TableHeaderItem from '../../QuotesList/components/TableHeaderItem';
import TableBody from '../../Common/Table/TableBody';
import './customerList.scss';
import classnames from 'classnames';
import NoPanelData from '../../Dashboard/components/NoPanelData';
import LoadingSpinner from '../../Common/Loading/LoadingSpinner';

const CustomerList = ({ isLoading, sortDirection, sortField, handleSort, t, customers, dealershipId }) => {
  let classes = classnames('customerList', isLoading && 'customerList--loading');
  return (
    <div className={classes} key="cont">
      <Table>
        <TableHeader
          arrowDirection={sortDirection}
          sortField={sortField}
          onClick={(field) => handleSort(field)}
          altStyle
        >
          <TableHeaderItem text={t('CustomerList.customer_details')} fieldName="customerName" altStyle />
          <TableHeaderItem text={t('CustomerList.latest_vehicle')} fieldName="currentVehicle.make" altStyle />
          <TableHeaderItem text={t('CustomerList.date_created')} fieldName="createdDate" altStyle />
          <TableHeaderItem text={t('CustomerList.last_updated')} fieldName="lastModified" altStyle />
          <TableHeaderItem text={t('CustomerList.assigned_to')} altStyle />
        </TableHeader>
        <TableBody>
          {!isLoading &&
            customers.length > 0 &&
            customers.map((customer) => (
              <CustomerListItem customer={customer} dealershipId={dealershipId} key={customer.id} />
            ))}
        </TableBody>
      </Table>

      {isLoading && customers.length <= 0 && (
        <div className="customerList__loadingSpinner">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && customers.length <= 0 && (
        <div className="customerList__noPanelDataContainer">
          <NoPanelData text={t('CustomerList.no_customers_found')} />
        </div>
      )}
    </div>
  );
};

CustomerList.propTypes = {
  customers: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  handleSort: PropTypes.func,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  dealershipId: PropTypes.string
};
export default withTranslation('CustomerList')(CustomerList);
