import React from 'react';
import { connect } from 'react-redux';
import * as modalActions from '../../../redux/modal/modalActions';
import SaveCorporateQuickQuoteModal from '../modals/SaveCorporateQuickQuoteModal';

const SaveCorporateQuickQuoteContainer = (props) => {
  return <SaveCorporateQuickQuoteModal {...props} />;
};

function mapStateToProps(state) {
  return {
    vehicle: state.quickQuote.vehicle,
    quote: state.quickQuote.quote,
    dealershipId: state.dealership.Id
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(modalActions.close('saveCorporateQuickQuote'));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveCorporateQuickQuoteContainer);
