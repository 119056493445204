import React from 'react';
import PropTypes from 'prop-types';
import './eligibilityModalRuleListItem.scss';

const EligibilityModalRuleListItem = ({ decisionMessage }) => {
  return (
    <li className="eligibilityModalRuleListItem" key="li">
      {decisionMessage.message}
    </li>
  );
};

EligibilityModalRuleListItem.propTypes = {
  decisionMessage: PropTypes.object
};

export default EligibilityModalRuleListItem;
