import _ from 'lodash';
import { getSessionId } from '../sessionId';
import showdown from 'showdown';
import { toJS } from 'mobx';
import { snakeCase } from 'snake-case';
import i18n from 'i18n';
import moment from 'moment';

/**
 * @description Converts either true/false or 'true'/'false' to 'Yes'/'No'
 * @param {*} value
 * @returns
 * - if `typeof value === string || typeof value === 'boolean'`
 * - return `value.toString() === 'true' ? 'Yes' : 'No'`;
 * - else `null`
 */
export function trueFalseToYesNo(value) {
  if (typeof value === 'string' || typeof value === 'boolean') {
    return value.toString() === 'true' ? 'Yes' : 'No';
  } else {
    return null;
  }
}

export const getOptionKey = (option) => {
  return {
    text: i18n.t(`Options.${snakeCase(option)}`, {
      ns: 'Common'
    })
  };
};

export const isQuoteAvailable = (timestamp) => {
  if (!timestamp) {
    return false;
  }
  const currentDate = new Date();
  const expiryDate = new Date(timestamp);

  return currentDate < expiryDate;
};

export function calculateTotalMonths(yearsMonthsObject) {
  if (!yearsMonthsObject) {
    return 0;
  }

  let years = parseInt(yearsMonthsObject.Years, 10) || 0;
  let months = parseInt(yearsMonthsObject.Months, 10) || 0;
  return years * 12 + months;
}

export function calculateTotalMonthsFromCollection(items, timeFieldName) {
  items = toJS(items);

  if (!_.isArray(items)) {
    return 0;
  }
  let item = _.chain(items)
    .map((item) => calculateTotalMonths(item[timeFieldName]))
    .reduce((previous, current) => previous + current)
    .value();

  return item;
}

export function countItemsInObject(obj, count = 0) {
  if (_.isObject(obj)) {
    return _.chain(obj)
      .map((item) => countItemsInObject(item, count))
      .reduce((previousValue, currentValue) => previousValue + currentValue)
      .value();
  } else if (obj) {
    return count + 1;
  } else {
    return 0;
  }
}

/**
 * @param {string} s
 * @returns `_.startCase(s)` | `typeof s !=='string` `s`
 */
export function splitCamelCaseToString(s) {
  if (typeof s !== 'string') {
    return s;
  }
  return _.startCase(s);
}

export function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.substr(1, s.length - 1);
}

export function titleCase(s) {
  if (typeof s !== 'string') {
    return '';
  }

  const split = s.split(' ');
  const newName = _.map(split, (name) => {
    if (name === 'null') {
      name = '';
    }
    return (
      name
        // word -> Word
        .replace(/^(.)(.*)/, function(a, b, c) {
          return b.toUpperCase() + c.toLowerCase();
        })
        // Word-word -> Word-Word
        .replace(/(-.)/g, function(a) {
          return a.toUpperCase();
        })
        // cspell:ignore Mcword
        // Mcword -> McWord
        .replace(/^([mM])([cC])(.)/g, function(a, b, c, d) {
          return 'Mc' + d.toUpperCase();
        })
        // O'word -> O'Word
        .replace(/('.)/g, function(a) {
          return a.toUpperCase();
        })
    );
  });
  return newName.join(' ');
}

export function filterQuotes(quotes, restrictToProductType, restrictToFunderCode, restrictQuotesByProduct) {
  return _.chain(quotes)
    .filter((quote) => {
      if (restrictToProductType && restrictToProductType === 'HP') {
        if (restrictQuotesByProduct && quote.FinanceType === 'HP') {
          return quote.ProductCode === restrictQuotesByProduct;
        } else if (
          quote.FinanceType === 'HP' &&
          (quote.ProductCode === 'Rtl - VAT Assist Brand' || quote.ProductCode === 'Rtl - Motor Select Brand')
        ) {
          return false;
        } else {
          return quote.FinanceType === 'HP';
        }
      } else {
        return restrictToProductType ? quote.FinanceType === restrictToProductType : true;
      }
    })
    .filter((quote) => (restrictToFunderCode ? quote.FunderCode === restrictToFunderCode : true))
    .filter((quote) => {
      const errorCodeWhiteList = [61, 64];

      if (quote.Errors && quote.Errors.hasPublicErrors) {
        return _.find(quote.Errors.PublicErrors, (error) => errorCodeWhiteList.indexOf(error.Number) > -1)
          ? true
          : false;
      }

      if (quote.Errors && quote.Errors.hasPrivateErrors) {
        return _.find(quote.Errors.PrivateErrors, (error) => errorCodeWhiteList.indexOf(error.Number) > -1)
          ? true
          : false;
      }

      return true;
    })
    .value();
}

export const addFunderToQuote = (quote, funders) => {
  let funder = _.find(funders, { Code: quote.FunderCode }) || {};
  return {
    ...quote,
    Funder: funder
  };
};

export function mapFundersToQuotes(quotes, funders) {
  return _.map(quotes, (quote) => addFunderToQuote(quote, funders));
}

/**
 * @param {number} count
 * @param {string} singular - singular form of response e.g. 'Application'
 * @param {string} plural - pluralised form of response e.g. 'Applications'
 * @returns {string} `singular` if `count===1`, `plural` if `count===0 || count>=2`
 * @example
 * pluralise(1,'I','They')
 * // => 'I'
 * pluralise(0,'I','They')
 * // => 'They'
 * pluralise(2,'I','They')
 * // => 'They'
 */
export function pluralise(count, singular, plural) {
  return Math.abs(count) === 1 ? singular : plural;
}

export function logError(e) {
  if (typeof window !== 'undefined') {
    const log = { error: e.message, level: 'Error', location: window.location.href, sessionId: getSessionId() };
    window.R7Insight && window.R7Insight.log(log);

    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV !== 'test' && window.console && console.error && typeof console.error === 'function') {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
}

export function formatNumber(value, numDecimalPlaces = 2, countryCode = 'gb') {
  // Parse the value to a float to ensure it is a number
  const numericValue = parseFloat(value);
  if (isNaN(numericValue)) {
    return ''; // or some error handling
  }

  // Use Intl.NumberFormat for international formatting
  return new Intl.NumberFormat(countryCode, {
    minimumFractionDigits: numDecimalPlaces,
    maximumFractionDigits: numDecimalPlaces
    // Additional options can be set here if needed
  }).format(numericValue);
}

export function isDefined(val) {
  return typeof val !== 'undefined' && !isNaN(val) && val !== null;
}

export function formatMoney(value, countryCode = 'gb') {
  if (!isDefined(value)) {
    return '';
  }

  if (value > 0 && value < 1) {
    return `${roundNumber(formatNumber(value) * 100, 0)}${countryCode === 'gb' ? 'p' : '¢'}`;
  }

  return new Intl.NumberFormat(countryCode, {
    style: 'currency',
    currency: countryCode === 'gb' ? 'GBP' : 'EUR'
  }).format(value);
}

export const formatExcess = (value, countryCode = 'gb') => {
  if (!isDefined(value)) {
    return '';
  }
  if (value > 0 && value < 1) {
    const appendSymbol = countryCode === 'gb' ? 'p' : '¢';
    const formatted = value < 1 ? (value * 100).toFixed(1) : value.toString();
    return `${formatted}${appendSymbol}`;
  } else {
    return formatMoney(value, countryCode);
  }
};

export function replaceNumberInStringWithMoneyValue(str) {
  return typeof str === 'string' ? str.replace(/\d+\.*\d*/g, (match) => formatMoney(match)) : '';
}

export function getTimeAtAddressString(TimeAtAddress) {
  let yearsString = '';
  let monthsString = '';

  if (TimeAtAddress) {
    const years = parseInt(TimeAtAddress.Years, 10);
    const months = parseInt(TimeAtAddress.Months, 10);

    if (!isNaN(years)) {
      yearsString = `${years} ${pluralise(years, 'year', 'years')}`;
    }

    if (!isNaN(months)) {
      monthsString = `${months} ${pluralise(months, 'month', 'months')}`;
    }
  }

  return `${yearsString ? `${yearsString} ` : ''}${monthsString}`;
}

export function getPercentageChange(value) {
  if (value > 0) {
    return `+${value}%`;
  }

  return `${value}%`;
}

export function commafy(val) {
  if (typeof val === 'undefined' || val === null) {
    val = '';
  } else {
    val = val.toString();
  }

  const parts = val.split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export function smoothScrollHorizontal(element, targetScrollPercentage, duration = 200) {
  let startTime;
  let elapsedTime;
  let percentComplete;
  let startX = element.scrollLeft;
  let targetX = (targetScrollPercentage / 100) * (element.scrollWidth - element.clientWidth);
  let deltaX = targetX - startX;

  function loop(currentTime) {
    elapsedTime = currentTime - startTime;
    percentComplete = (elapsedTime * 100) / duration;
    element.scrollLeft = startX + (percentComplete * deltaX) / 100;

    if (elapsedTime < duration) {
      requestAnimationFrame(loop);
    } else {
      element.scrollLeft = targetX;
    }
  }

  requestAnimationFrame(function(currentTime) {
    startTime = currentTime;
    loop(currentTime);
  });
}

export function isAlphanumeric(str) {
  // Will return true if str is falsy?
  return /^[a-zA-Z0-9]*$/.test(str);
}

export function roundNumber(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

export function convertMarkdownToHtml(markdown) {
  let converter = new showdown.Converter();
  converter.setOption('metadata', true);
  let html = converter.makeHtml(markdown);
  let metadata = converter.getMetadata();
  return {
    metadata,
    markdown,
    html
  };
}

export function formatAddress(funder) {
  const addressParts = [];
  const streetParts = [];

  if (funder.Name) {
    addressParts.push(funder.Name);
  }

  if (funder.BuildingNumber) {
    streetParts.push(funder.BuildingNumber);
  }

  if (funder.BuildingName) {
    streetParts.push(`${funder.BuildingName},`);
  }

  if (funder.Street) {
    streetParts.push(funder.Street);
  }

  addressParts.push(streetParts.join(' '));

  if (funder.District) {
    addressParts.push(funder.District);
  }

  if (funder.PostTown) {
    addressParts.push(funder.PostTown);
  }

  if (funder.Postcode) {
    addressParts.push(funder.Postcode);
  }

  if (funder.Country) {
    addressParts.push(funder.Country);
  }

  let address = addressParts.join(', ');

  if (funder.Name === 'Motonovo Finance') {
    address = address.replace(
      'Motonovo Finance,',
      'MotoNovo Finance limited.  Registered in Wales under Company No 11556144.  Registered Office: '
    );
    address = address + '. Authorised and regulated by the Financial Conduct Authority';
  }

  return address;
}

export function setLocalStorageValue(key, value, func = function() {}) {
  if (typeof window.localStorage === 'object') {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      func();
    }
  }
}

export function getLocalStorageValue(key, func = function() {}) {
  if (typeof window.localStorage === 'object') {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      return func();
    }
  }
}

export function removeLocalStorageValue(key) {
  if (typeof window.localStorage === 'object') {
    try {
      localStorage.removeItem(key);
    } catch (e) {
    console.error(e)
    }
  }
}

export function calculateCurrentApplicationNumber(multiQuoteId, applicationList, applicationCount) {
  let thisAppNumber = 1;
  applicationList.map((application) => {
    if (
      application.Tags.multiQuoteRefId === multiQuoteId &&
      application.LenderStatus !== 'Not Submitted' &&
      application.LenderStatus !== 'Error' &&
      application.LenderStatus !== 'Pending' &&
      application.LenderStatus !== 'Submitting' &&
      application.LenderStatus !== ''
    ) {
      thisAppNumber++;
    } else if (
      thisAppNumber > 1 &&
      application.Tags.multiQuoteRefId === multiQuoteId &&
      application.LenderStatus !== 'Not Submitted'
    ) {
      thisAppNumber++;
    }
  });
  return thisAppNumber > applicationCount ? applicationCount : thisAppNumber;
}

export function trimDeepObjectStrings(object) {
  const newObject = _.cloneDeep(object);
  for (const key in newObject) {
    if (object.hasOwnProperty(key)) {
      if (typeof newObject[key] === 'string') {
        newObject[key] = newObject[key].trim();
      } else if (typeof newObject[key] === 'object') {
        newObject[key] = trimDeepObjectStrings(newObject[key]);
      }
    }
  }
  return newObject;
}

export function valueToFloat(value) {
  return value && parseFloat(value) ? parseFloat(value) : 0;
}

export function round(value) {
  return +value.toFixed(2);
}

export function addPoundSymbolToString(string, position) {
  return string ? string.slice(0, position) + '£' + string.slice(position) : '';
}

export function setUrlAsCloudfront(imgSrc) {
  return imgSrc.replace(
    'https://s3-eu-west-1.amazonaws.com/stockimagesivendicom/',
    'https://d2bkdfyoj2xgsx.cloudfront.net/'
  );
}

export function getClientApp(application, defaultClientApp = 'Consumer Hub') {
  const applicationSource = application.ClientApp === 'submission-api' ? application.Referrer : application.ClientApp;
  let clientApp;

  switch (applicationSource) {
    case 'new-vehicle-account':
      clientApp = 'Consumer Hub';
      break;
    case 'motonovo-fass-consumer':
      clientApp = 'MotoNovo Self Serve';
      break;
    case 'silver-bullet':
      clientApp = 'SilverBullet';
      break;
    case 'ivendi-dealer-platform':
      clientApp = 'Showroom';
      break;
    case 'ivendi-connect':
      clientApp = 'iVendi Connect';
      break;
    default:
      clientApp = defaultClientApp;
      break;
  }

  return clientApp;
}

export function isItBike(vehicle) {
  return vehicle.toLowerCase() === 'motorbike' || vehicle.toLowerCase() === 'bike';
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const milesToKilometres = (distance) => {
  return Math.round(distance * 1.609344);
};

export const booleanOrStringToBoolean = (value) => {
  if (typeof value === 'boolean') {
    return value;
  } else if (value === 'true') {
    return true;
  } else if (value === 'false') {
    return false;
  } else {
    return false;
  }
};

export const isDateMoreThanXMonthsAgo = (dateString, months, dateFormat = 'DD/MM/YYYY') => {
  const date = moment(dateString, dateFormat, true);
  if (date.isValid()) {
    const otherDate = moment().subtract(months, 'months');
    return date.isBefore(otherDate);
  } else {
    return true;
  }
};

export const getYearFromDate = (date) => {
  const formats = ['YYYY-MM-DDTHH:mm:ssZ', 'YYYY-MM-DDTHH:mm:ss', 'DD/MM/YYYY', 'D/M/YYYY'];
  const momentDate = moment(date, formats, true);

  if (momentDate.isValid()) {
    return momentDate.year().toString();
  }

  return undefined;
};
