export function trackPageInteraction(eventName, dealershipId) {
  return () => {
    if (process.env.NODE_ENV !== 'production' && console) {
      /* eslint no-console:0 */
      console.log('%cga', 'background: #f60; color: #fff; padding: 1px 5px', 'ButtonClicks', eventName, dealershipId);
    }

    if (window.ga) {
      window.ga('send', 'event', 'ButtonClicks', eventName, dealershipId);
    }
  };
}
