import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import Button from './Button/Button';
import './pageNotFound.scss';
import { setTheme } from '../../theme';
import { useLocation, matchPath } from 'react-router-dom';

export const getDealershipIdUrlFragment = (pathname) => {
  const dealershipId = matchPath('/d/:dealershipId/*', pathname || '')?.params?.dealershipId;
  return dealershipId ? `d/${dealershipId}` : '';
};

export const getHomeHref = (dealershipIdUrlFragment) => {
  return process.env.PUBLIC_URL
    ? process.env.PUBLIC_URL + `/${dealershipIdUrlFragment}`
    : `/dealer/platform/${dealershipIdUrlFragment}`;
};

const PageNotFound = () => {
  const location = useLocation();
  const { t } = useTranslation('Common');

  const dealershipIdUrlFragment = getDealershipIdUrlFragment(location?.pathname);
  const homeHref = getHomeHref(dealershipIdUrlFragment);

  return (
    <div className="pageNotFound">
      <div className="pageNotFound__message">
        <h1 className="pageNotFound__heading">{t('PageNotFound.this_page_doesnt_exist')}</h1>
        <p className="pageNotFound__text">{t('PageNotFound.check_the_url')}</p>

        <div className="pageNotFound__returnToHomePageButton">
          <Button buttonStyle="primary" href={homeHref}>
            {t('PageNotFound.return_to_home_page')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
