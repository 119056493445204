import { useTranslation } from 'react-i18next';
import React from 'react';

const AnnualRateAdjustedMessage = () => {
  const { t } = useTranslation('Quoting');
  return (
    <span>{t('AnnualRateAdjustedMessage.actual_rate_will_be_adjusted_to_include_the_deferred_final_payment')}</span>
  );
};

export default AnnualRateAdjustedMessage;
