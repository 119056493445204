import React from 'react';
import PropTypes from 'prop-types';
import './distanceModalConsumerLayout.scss';

const DistanceModalConsumerLayout = ({ children }) => (
  <div className="distanceModalConsumerLayout">
    {React.Children.toArray(children).map((child, i) => {
      return (
        <div className={`distanceModalConsumerLayout__col${i}`} key={'col' + i}>
          {child}
        </div>
      );
    })}
  </div>
);

DistanceModalConsumerLayout.propTypes = {
  children: PropTypes.node
};

export default DistanceModalConsumerLayout;
