import React from 'react';
import PropTypes from 'prop-types';
import './panelToolBar.scss';
import classnames from 'classnames';

const PanelToolBar = ({ isWhite, hasUnderline, hasPaddingY, children }) => {
  let toolBarClasses = classnames('panelToolBar__toolbar', isWhite === true && 'panelToolBar__white');

  let wrapperClasses = classnames(
    'panelToolBar__toolbarWrapper',
    hasUnderline === false ? 'panelToolBar__notUnderlined' : 'panelToolBar__underlined',
    hasPaddingY === false ? 'panelToolBar__notPadded' : 'panelToolBar__paddedY',
    isWhite === true && 'panelToolBar__white'
  );

  return (
    <div className={wrapperClasses}>
      <div className={toolBarClasses}>{children}</div>
    </div>
  );
};

PanelToolBar.defaultProps = {
  isWhite: false,
  hasPaddingX: true,
  hasPaddingY: true,
  hasUnderline: true
};

PanelToolBar.propTypes = {
  children: PropTypes.node,
  hasPaddingX: PropTypes.bool,
  hasPaddingY: PropTypes.bool,
  isWhite: PropTypes.bool,
  hasUnderline: PropTypes.bool
};

export default PanelToolBar;
