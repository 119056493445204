import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

import Icon from '~Common/Icon/Icon';
import { getLocalStorageValue } from '~/core/helpers';

import { PendingDataSpinner } from './components';

import './pushSidebar.scss';

@inject('appStore')
@observer
class PushSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      make: '',
      model: '',
      derivative: ''
    };
    this.reactToDataPending = new reaction(
      () => ({ ...this.props.appStore.stockStore.dataPendingInfo }),
      (data) => {
        data && this.fetchData();
      }
    );
  }

  fetchData = () => {
    const dataPendingInfo = this.props.appStore.stockStore.dataPendingInfo;
    const localData = getLocalStorageValue('updatedVehicleIds');
    const findLocalDataByVehicle = find(localData, {
      vehicleId: dataPendingInfo.Id
    });
    const taxonomyData = dataPendingInfo && dataPendingInfo.Taxonomies && dataPendingInfo.Taxonomies[0];

    if (isEmpty(localData)) {
      this.fetchVisVehicleBasedOnTaxonomyId(
        taxonomyData && taxonomyData.primaryId,
        taxonomyData && taxonomyData.secondaryId
      );
    } else {
      if (findLocalDataByVehicle) {
        this.fetchVisVehicleBasedOnTaxonomyId(findLocalDataByVehicle.primaryId, findLocalDataByVehicle.secondaryId);
      } else {
        this.fetchVisVehicleBasedOnTaxonomyId(
          taxonomyData && taxonomyData.primaryId,
          taxonomyData && taxonomyData.secondaryId
        );
      }
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    this.reactToDataPending();
  }

  fetchVisVehicleBasedOnTaxonomyId = (primaryId, secondaryId) => {
    this.props.appStore.stockStore.fetchVisVehicle(primaryId, secondaryId).then((visData) => {
      const data = visData.response[0];
      this.setState({
        make: data && data.Make,
        model: data && data.Model,
        derivative: data && data.Derivative
      });
    });
  };

  render() {
    const stockStore = this.props.appStore.stockStore;
    const dataPendingInfo = stockStore.dataPendingInfo;
    return (
      <div className="pushSidebar">
        <button className="pushSidebar__close" onClick={stockStore.handleDataPendingClose}>
          <Icon name="down" />
        </button>
        <div className="pushSidebar__header">{this.props.t('PushSidebar.quote_information_is_pending')}</div>
        <div className="pushSidebar__dataSection">
          <div className="pushSidebar__dataOuter">
            <div className="pushSidebar__dataTitle">{this.props.t('PushSidebar.original_data')}</div>
            <div className="pushSidebar__dataMakeModel">
              {dataPendingInfo.SMake} {dataPendingInfo.SModel}
            </div>
            <div className="pushSidebar__dataDerivative">{dataPendingInfo.SDerivative}</div>
          </div>
          <div className="pushSidebar__dataOuter">
            <div className="pushSidebar__dataTitle">
              <span>{this.props.t('PushSidebar.pending_data')}</span>
              <div className="pushSidebar__pending">
                <PendingDataSpinner />
              </div>
            </div>
            {stockStore.isPendingDataLoading ? (
              <div />
            ) : (
              <div>
                <div className="pushSidebar__dataMakeModel">
                  {this.state.make} {this.state.model}
                </div>
                <div className="pushSidebar__dataDerivative">{this.state.derivative}</div>
              </div>
            )}
          </div>
        </div>
        <div className="pushSidebar__infoSection">
          <div className="pushSidebar__infoHeader">
            <span className="pushSidebar__infoHeaderQuestion">
              <Icon name="question" />
            </span>{' '}
            {this.props.t('PushSidebar.why_does_the_vehicle_information_still_display_incorrectly_in_my_stock_feed')}
          </div>
          <div className="pushSidebar__infoTextOuter">
            <p className="pushSidebar__infoText">
              {this.props.t(
                'PushSidebar.the_vehicle_make_model_and_derivative_displayed_in_the_stock_list_is_taken_from_your_stock_feed'
              )}
            </p>
            <p className="pushSidebar__infoText">
              {this.props.t(
                'PushSidebar.when_you_fix_a_stock_item_we_only_change_the_information_we_use_to_create_a_finance_quote'
              )}
            </p>
            <p className="pushSidebar__infoText">
              {this.props.t(
                'PushSidebar.to_change_the_vehicle_information_theat_is_displayed_change_it_in_your_stock_system'
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

PushSidebar.propTypes = {
  appStore: PropTypes.object
};
export default withTranslation('Stock')(PushSidebar);
