import PropTypes from 'prop-types';
import Button from '../Common/Button/Button';
import { useTranslation } from 'react-i18next';
import './cfcCheckDetailsButtons.scss';

const CfcCheckDetailsButtons = ({
  quote,
  loadingState,
  handleApply,
  handlePrint,
  handleBack,
  disableSubmit,
  hideSubmit
}) => {
  const { t } = useTranslation('Common');
  return (
    <div className="cfcCheckDetailsButtons__outer">
      {handleApply && !hideSubmit && (
        <div className="cfcCheckDetailsButtons__apply cfcCheckDetailsButtons__button" key="apply">
          <Button
            buttonStyle="primary"
            isLoading={loadingState === 'loading'}
            hasError={loadingState === 'error'}
            onClick={handleApply}
            disabled={disableSubmit}
          >
            {t(`FormFooter.${quote?.SupportsPreApproval ? 'get_pre_approval' : 'apply'}`)}
          </Button>
        </div>
      )}
      <div className="cfcCheckDetailsButtons__print cfcCheckDetailsButtons__button" key="print">
        <Button buttonStyle="secondary" onClick={handlePrint}>
          {t(`FormFooter.print`)}
        </Button>
      </div>
      <div className="cfcCheckDetailsButtons__cancel cfcCheckDetailsButtons__button" key="cancel">
        <Button buttonStyle="cancel" onClick={handleBack}>
          {t(`FormFooter.cancel`)}
        </Button>
      </div>
    </div>
  );
};

CfcCheckDetailsButtons.defaultProps = {
  hideSubmit: false,
  disableSubmit: false
};

CfcCheckDetailsButtons.propTypes = {
  quote: PropTypes.object,
  handleBack: PropTypes.func,
  handleApply: PropTypes.func,
  handlePrint: PropTypes.func,
  loadingState: PropTypes.string,
  disableSubmit: PropTypes.bool
};

export default CfcCheckDetailsButtons;
