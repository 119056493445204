import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import QuoteCostsForm from '../forms/QuoteCostsForm';
import ExclamationWarning from '../../Common/ExclamationWarning';
import EditVapsForm from '../forms/EditVapsForm';
import EditNegativeEquityLoan from '../forms/EditNegativeEquityLoan';
import EditPriceForm from '../forms/EditPriceForm';

class QuoteCosts extends React.Component {
  renderCostForms = () => {
    const funderCode = this.props.quote ? this.props.quote.FunderCode : null;
    const funderProductCode = this.props.quote ? this.props.quote.FunderProductCode : null;
    const tag = this.props.tag ? this.props.tag : null;

    if (funderCode === 'CRE' && funderProductCode === 'BL VAP') {
      return <EditVapsForm {...this.props} />;
    } else if (funderCode === 'CRE' && funderProductCode === 'BL NEG EQ') {
      return <EditNegativeEquityLoan {...this.props} />;
    } else if (tag && funderProductCode !== 'BL VAP' && funderProductCode !== 'BL NEG EQ') {
      return <EditPriceForm {...this.props} />;
    }

    return (
      <QuoteCostsForm
        key={`${this.props.vehicle.CapId}_${this.props.vehicle.Vrm}_${this.props.vehicle.VehicleId}_${this.props.vehicle.Derivative}`}
        {...this.props}
      />
    );
  };

  render() {
    return (
      <Panel>
        <PanelHeader>{this.props.t('QuoteCosts.quote_details')}</PanelHeader>
        <PanelContent>
          {this.props.hasCheckedEligibility && (
            <ExclamationWarning
              message={this.props.t(
                'QuoteCosts.changing_the_vehicle_costs_may_affect_the_match_rates_returned_for_one_or_more_of_the_quotes'
              )}
            />
          )}
          {this.renderCostForms()}
        </PanelContent>
      </Panel>
    );
  }
}

QuoteCosts.propTypes = {
  hasCheckedEligibility: PropTypes.bool
};
export default withTranslation('Quoting')(QuoteCosts);
