import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './removeRecordModal.scss';
import Button from '../../Common/Button/Button';

const RemoveRecordModal = ({ onClose, handleRemove, isRemoving, hasRemovingError }) => {
  const { t } = useTranslation('ManageUsers');
  return (
    <div>
      <h2 className="removeRecordModal__header">{t('RemoveRecordModal.user_details')}</h2>
      <div className="removeRecordModal__divider" />
      <p className="removeRecordModal__text">{t('RemoveRecordModal.are_you_sure_you_want_to_remove_this_record')}</p>
      <div className="removeRecordModal__divider" />
      <div className="removeRecordModal__buttonOuter">
        <div className="removeRecordModal__noButton">
          <Button buttonStyle="cancel" onClick={onClose}>
            {t('RemoveRecordModal.no')}
          </Button>
        </div>
        <div className="removeRecordModal__yesButton">
          <Button
            type="button"
            isLoading={isRemoving}
            hasError={hasRemovingError}
            buttonStyle="secondary"
            onClick={handleRemove}
          >
            {t('RemoveRecordModal.yes')}
          </Button>
        </div>
      </div>
    </div>
  );
};

RemoveRecordModal.propTypes = {
  handleRemove: PropTypes.func,
  isRemoving: PropTypes.bool,
  hasRemovingError: PropTypes.bool,
  onClose: PropTypes.func
};
export default RemoveRecordModal;
