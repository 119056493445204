import { inject } from 'mobx-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '~/components/Common/Breadcrumbs';
import Page from '~/components/Common/Page';
import Panel from '~/components/Common/Panel';
import PanelHeader from '~/components/Common/PanelHeader';
import RepExampleCustom from './RepExampleCustom';
import { useQueryClient } from '@tanstack/react-query';
import { Dealership } from '~/types/dealership';

interface RepExampleEditFormProps {
  preferredTerm: number;
  preferredDeposit: number;
  preferredAnnualMileage: number;
  appStore: any;
  productSettings: any;
  dealership: Dealership;
}

const RepExampleEdit = (props: RepExampleEditFormProps) => {
  const { t } = useTranslation('RepExampleSettings');
  const { dealershipId } = useParams();
  const navitgate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    const initialValues = {
      Term: props.preferredTerm,
      CashDeposit: props.preferredDeposit,
      AnnualDistance: props.preferredAnnualMileage
    };
    props.appStore.repExampleStore.setProductSettings(props.productSettings);
    props.appStore.repExampleStore.setQuoteeUid(props.dealership.WebQuotewareId);
    props.appStore.repExampleStore.resetRepExampleForms();
    props.appStore.repExampleStore.setInitialPricingValues(initialValues);
    props.appStore.repExampleStore.setCustomPage('vehicleForm');
    props.appStore.repExampleStore.setRepExampleType('custom');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setNewCurrentRepExample = () => {
    queryClient.invalidateQueries(['rep-example', dealershipId]);
    navitgate(`/d/${dealershipId}/settings/repexample`, { state: { showUpdateNotification: true } });
    window.ga && window.ga('send', 'event', 'RepExSelectRepresentativeQuote', 'Proceed', 'RepExampleSelectQuote');
  };

  const onCancel = () => {
    navitgate(`/d/${dealershipId}/settings/repexample`);
  };

  return (
    <Page>
      <div style={{ margin: '0 0 20px 0' }}>
        <Breadcrumbs
          items={[
            {
              name: t('Breadcrumbs.home'),
              path: `/d/${dealershipId}`
            },
            {
              name: t('Breadcrumbs.settings'),
              path: `/d/${dealershipId}/settings`
            },
            {
              name: t('Breadcrumbs.rep_example'),
              path: `/d/${dealershipId}/settings/repexample`
            },
            {
              name: t('Breadcrumbs.change_rep_example'),
              path: `/d/${dealershipId}/settings/repexample/edit`
            }
          ]}
        />
      </div>
      <Panel>
        <PanelHeader>{t('RepExampleEdit.representative_example_settings')}</PanelHeader>
        <div>
          <RepExampleCustom
            dealershipId={dealershipId}
            setNewCurrentRepExample={setNewCurrentRepExample}
            onCancel={onCancel}
          />
        </div>
      </Panel>
    </Page>
  );
};

export default inject('appStore')(RepExampleEdit);
