import React, { useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';

import Modal from '~Common/Modal/Modal';
import Icon from '~Common/Icon/Icon';

import './iconTooltip.scss';

const IconTooltip = ({ style, children, title = '', customIcon }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const HoverText = () => {
    return (
      <div className="iconTooltip__hoverModal">
        <div className="iconTooltip__hoverModalText">{children}</div>
      </div>
    );
  };

  return (
    <div>
      {isMobile || isTablet ? (
        <span>
          <button
            style={style}
            className={'iconTooltip__button iconTooltip__position'}
            onClick={showModal}
            type="button"
          >
            <Icon name={customIcon ? customIcon : 'information'} />
          </button>
          <Modal title={`${title}`} onClose={closeModal} isOpen={isModalOpen}>
            {children}
          </Modal>
        </span>
      ) : (
        <span className="iconTooltip__hoverSectionContainer">
          <button
            style={style}
            className={'iconTooltip__button iconTooltip__position'}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            type="button"
          >
            <Icon name={customIcon ? customIcon : 'information'} />
          </button>
          {isHovering && <HoverText />}
        </span>
      )}
    </div>
  );
};

export default IconTooltip;
