import { withTranslation } from 'react-i18next';
import { clone, set } from 'lodash';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import Validator from '../../../validators/Validator';
import EmailLookup from '../../Common/EmailLookup';
import CheckboxWithLabel from '../../Common/Form/CheckboxWithLabel';
import FormFooter from '../../Common/Form/FormFooter';
import SelectInput from '../../Common/Form/SelectInput';
import TextInputWrappingInput from '../../Common/Form/TextInputWrappingInput';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import SaveQuickQuoteExistingCustomer from '../components/SaveQuickQuoteExistingCustomer';

@inject('appStore')
@observer
class QuickQuoteConsumerDetailsForm extends React.Component {
  state = {
    existingCustomer: false,
    formData: {
      Email: '',
      Title: '',
      FirstName: '',
      MiddleNames: '',
      LastName: '',
      Landline: '',
      Mobile: '',
      CustomerType: 'consumer',
      AssignToSelf: false
    }
  };

  UNSAFE_componentWillMount() {
    const validationRules = {
      Email: 'required, email',
      Title: 'required',
      FirstName: 'required',
      LastName: 'required',
      Landline: 'landline',
      Mobile: 'mobile'
    };
    this.setUpValidation(validationRules);
  }

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  setUpValidation(validationRules) {
    this.validator = new Validator();
    this.validator.setRules(validationRules);
    this.validationReactionDisposer = reaction(() => ({ ...this.state }), this.validator.validate, {
      fireImmediately: true
    });
  }

  submitForm = () => {
    this.validator.validate(this.state.formData);

    if (!this.validator.errorCount) {
      this.onValidationSuccess();
    }
  };

  onValidationSuccess() {
    this.props.onSave(this.state.formData);
  }

  handleLookup = (value) => {
    this.setState({
      existingCustomer: value
    });
    this.validator.validate(this.state.formData);
  }; // Setters

  setEmail = (email) => this.handleChangeField('Email', email);
  setFirstName = (firstName) => this.handleChangeField('FirstName', firstName);
  setMiddleNames = (middleNames) => this.handleChangeField('MiddleNames', middleNames);
  setSurname = (surname) => this.handleChangeField('LastName', surname);
  setLandlinePhone = (phone) => this.handleChangeField('Landline', phone);
  setMobilePhone = (phone) => this.handleChangeField('Mobile', phone);
  setAssigned = (bool) => this.handleChangeField('AssignToSelf', bool);
  handleChangeField = (fieldName, value) => {
    const cloneState = clone(this.state);
    set(cloneState.formData, fieldName, value);
    this.validator.validate(cloneState.formData);
    this.setState({
      formData: cloneState.formData
    });
  };

  render() {
    const isDisabled = this.state.existingCustomer ? true : false;
    const errors = this.validator.getErrors();
    return (
      <MobxForm onSubmit={this.submitForm}>
        <MobxFieldGroup error={errors.Email}>
          <MobxFormLabel htmlFor="Email">{this.props.t('QuickQuoteConsumerDetailsForm.customer_email')}</MobxFormLabel>
          <EmailLookup
            sector="residential"
            id="Email"
            dealershipId={this.props.dealershipId}
            onChange={this.setEmail}
            value={this.state.formData.Email}
            onLookup={this.handleLookup}
            showPrompt={false}
            autoComplete="none"
            placeholder={this.props.t('QuickQuoteConsumerDetailsForm.enter_customer_email_address')}
          />
        </MobxFieldGroup>
        {this.state.existingCustomer && (
          <SaveQuickQuoteExistingCustomer
            existingCustomerActionLabel={this.props.existingCustomerActionLabel}
            submittingExistingCustomerState={this.props.submittingExistingCustomerState}
            customer={this.state.existingCustomer}
            handleSubmit={this.props.submitExistingCustomer}
          />
        )}
        <MobxFieldGroup error={errors.Title}>
          <MobxFormLabel htmlFor="Title">{this.props.t('QuickQuoteConsumerDetailsForm.title')}</MobxFormLabel>
          <SelectInput
            onChange={this.handleChangeField}
            funderCode={this.props.appStore.uiState.getPlatformCode || this.props.quote?.FunderCode}
            id="Title"
            options="CustomerTitles"
            disabled={isDisabled}
          />
        </MobxFieldGroup>
        <MobxFieldGroup error={errors.FirstName}>
          <MobxFormLabel htmlFor="FirstName">{this.props.t('QuickQuoteConsumerDetailsForm.first_name')}</MobxFormLabel>
          <TextInputWrappingInput
            disabled={isDisabled}
            onChange={this.setFirstName}
            value={this.state.formData.FirstName}
            id="FirstName"
          />
        </MobxFieldGroup>
        <MobxFieldGroup error={errors.MiddleNames}>
          <MobxFormLabel htmlFor="MiddleNames">
            {this.props.t('QuickQuoteConsumerDetailsForm.middle_names_optional')}
          </MobxFormLabel>
          <TextInputWrappingInput
            disabled={isDisabled}
            onChange={this.setMiddleNames}
            value={this.state.formData.MiddleNames}
            id="MiddleNames"
          />
        </MobxFieldGroup>
        <MobxFieldGroup error={errors.LastName}>
          <MobxFormLabel htmlFor="LastName">{this.props.t('QuickQuoteConsumerDetailsForm.last_name')}</MobxFormLabel>
          <TextInputWrappingInput
            disabled={isDisabled}
            onChange={this.setSurname}
            value={this.state.formData.LastName}
            id="LastName"
          />
        </MobxFieldGroup>
        <MobxFieldGroup error={errors.Landline}>
          <MobxFormLabel htmlFor="Landline">
            {this.props.t('QuickQuoteConsumerDetailsForm.home_telephone_optional')}
          </MobxFormLabel>
          <TextInputWrappingInput
            disabled={isDisabled}
            onChange={this.setLandlinePhone}
            value={this.state.formData.Landline}
            type="tel"
            id="Landline"
          />
        </MobxFieldGroup>
        <MobxFieldGroup error={errors.Mobile}>
          <MobxFormLabel htmlFor="Mobile">
            {this.props.t('QuickQuoteConsumerDetailsForm.mobile_telephone_optional')}
          </MobxFormLabel>
          <TextInputWrappingInput
            disabled={isDisabled}
            onChange={this.setMobilePhone}
            value={this.state.formData.Mobile}
            type="tel"
            id="Mobile"
          />
        </MobxFieldGroup>
        {this.props.appStore.uiState.canAssign && (
          <CheckboxWithLabel
            id="AssignToSelf"
            disabled={isDisabled}
            value={this.state.formData.AssignToSelf}
            onChange={this.setAssigned}
          >
            {this.props.t('QuickQuoteConsumerDetailsForm.assign_this_lead_to_me')}
          </CheckboxWithLabel>
        )}
        <FormFooter
          submitLabel={this.props.submitLabel}
          submittingState={this.props.submittingState}
          onCancel={this.props.onCancel}
          submitDisabled={isDisabled}
        />
      </MobxForm>
    );
  }
}

QuickQuoteConsumerDetailsForm.propTypes = {
  onSave: PropTypes.func,
  savingState: PropTypes.string,
  submittingExistingCustomerState: PropTypes.string,
  onCancel: PropTypes.func,
  submitExistingCustomer: PropTypes.func,
  submitLabel: PropTypes.string,
  existingCustomerActionLabel: PropTypes.string,
  appStore: PropTypes.object,
  quote: PropTypes.object,
  quotes: PropTypes.array
};
export default withTranslation('QuickQuote')(QuickQuoteConsumerDetailsForm);
