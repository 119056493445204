import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import TileNumber from './TileNumber';
import Icon from '../../Common/Icon/Icon';
import clock from '../../../styles/icons/clock-regular.svg';
import './dashBoardTileOld.scss';
import { Link } from 'react-router-dom';
import getTileStyles from '../../../core/getTileStylesOld';
import Button from '../../Common/Button/Button';
import LoadingSpinner from '../../Common/Loading/LoadingSpinner';

function getTime({ time, t }) {
  const labels = {
    hour: t('DashboardTile.hour'),
    day: t('DashboardTile.day'),
    '2day': t('DashboardTile.twoDays'),
    week: t('DashboardTile.week'),
    month: t('DashboardTile.month')
  };
  return labels[time] ? labels[time] : null;
}

const DashboardTile = (props) => {
  const { t } = props;
  const tileTime = getTime(props);
  const tileStyle = getTileStyles(props.type, props.count);

  if (props.hasError) {
    return (
      <div className="dashBoardTileOld" key="outer">
        <Link to={props.to}>
          <div
            style={{
              backgroundColor: tileStyle.color
            }}
            className="dashBoardTileOld__imageOuter"
          >
            <Icon name={tileStyle.icon} />
          </div>
          <div className="dashBoardTileOld__bodyWrapper">
            <div className="dashBoardTileOld__bodyOuter">
              <div className="dashBoardTileOld__textOuter">
                <div
                  style={{
                    color: tileStyle.color
                  }}
                  className="dashBoardTileOld__text"
                >
                  <div className="dashBoardTileOld__textInner">{t('DashboardTile.error_loading_reservations')}</div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }

  if (props.appStore.reserveNowStore.getReservationSettingsRequest.isLoading && props.type === 'reservations') {
    return (
      <div className="dashBoardTileOld" key="outer">
        <Link to={props.to}>
          <div
            style={{
              backgroundColor: tileStyle.color
            }}
            className="dashBoardTileOld__imageOuter"
          >
            <Icon name={tileStyle.icon} />
          </div>
          <div className="dashBoardTileOld__bodyWrapper">
            <div className="dashBoardTileOld__bodyOuter">
              <div className="dashBoardTileOld__textOuter">
                <div
                  style={{
                    color: tileStyle.color
                  }}
                  className="dashBoardTileOld__loading"
                >
                  <LoadingSpinner />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }

  if (
    !props.appStore.reserveNowStore.isReserveSettingsSet &&
    props.type === 'reservations' &&
    props.appStore.uiState.isDealershipAdmin
  ) {
    return (
      <div className="dashBoardTileOld" key="outer">
        <Link to={props.to}>
          <div
            style={{
              backgroundColor: tileStyle.color
            }}
            className="dashBoardTileOld__imageOuter"
          >
            <Icon name={tileStyle.icon} />
          </div>
          <div className="dashBoardTileOld__bodyWrapper">
            <div className="dashBoardTileOld__bodyOuter">
              <div className="dashBoardTileOld__textOuter">
                <div
                  style={{
                    color: tileStyle.color
                  }}
                  className="dashBoardTileOld__text"
                >
                  <div className="dashBoardTileOld__textInner">
                    {t('DashboardTile.total_reservations')}
                    <div className="dashBoardTileOld__setup">
                      <Button buttonStyle="secondary" size="small">
                        {t('DashboardTile.setup_now')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }

  return (
    <div className="dashBoardTileOld" key="outer">
      <Link to={props.to}>
        <div
          style={{
            backgroundColor: tileStyle.color
          }}
          className="dashBoardTileOld__imageOuter"
        >
          <Icon name={tileStyle.icon} />
        </div>
        <div className="dashBoardTileOld__bodyWrapper">
          <div className="dashBoardTileOld__bodyOuter">
            <div className="dashBoardTileOld__number">
              <TileNumber number={props.count} color={tileStyle.color} />
            </div>
            <div className="dashBoardTileOld__textOuter">
              <div
                style={{
                  color: tileStyle.color
                }}
                className="dashBoardTileOld__text"
              >
                <div className="dashBoardTileOld__textInner">{tileStyle.text}</div>
              </div>
            </div>
            {tileTime && (
              <div className="dashBoardTileOld__timeOuter">
                <img src={clock} className="dashBoardTileOld__clock" alt="" />
                {props.time === 'thisMonth' ? (
                  <span className="dashBoardTileOld__time">{tileTime}</span>
                ) : (
                  <span className="dashBoardTileOld__time">
                    {t('DashboardTile.in_the_last')} <strong>{tileTime}</strong>
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

DashboardTile.propTypes = {
  count: PropTypes.number,
  time: PropTypes.string,
  type: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hasError: PropTypes.bool,
  appStore: PropTypes.object
};
export default withTranslation(['Dashboard'])(inject(['appStore'])(observer(DashboardTile)));
