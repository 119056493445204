import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CommentsList from './CommentsList';
import Button from '../../Common/Button/Button';
import Modal from '../../Common/Modal/Modal';
import { connect } from 'react-redux';
import * as modalActions from '../../../redux/modal/modalActions';
import './vehicleNotesModal.scss';

class VehicleNotes extends Component {
  submitComment = (e) => {
    e.preventDefault();

    if (this.refs.commentTextarea.value) {
      this.props.onSubmit(this.refs.commentTextarea.value);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.loadingState === 'loading' && this.props.loadingState === 'done') {
      if (this.refs.commentTextarea) {
        this.refs.commentTextarea.value = '';
      }
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} onClose={this.props.closeModal}>
        <div className="vehicleNotesModal__container">
          <div className="vehicleNotesModal__header">
            {this.props.t('VehicleNotes.notes')} ({this.props.comments.length})
          </div>
          <div className="vehicleNotesModal__commentsSection" key="commentsSection">
            {this.props.comments.length < 1 ? (
              <div className="vehicleNotesModal__noComments">{this.props.t('VehicleNotes.no_notes')}</div>
            ) : (
              <CommentsList comments={this.props.comments} />
            )}
          </div>
          <form onSubmit={this.submit} className="vehicleNotesModal__form" key="form">
            <div className="vehicleNotesModal__addCommentSection">
              <div className="vehicleNotesModal__initials">{this.props.userInitials}</div>
              <div className="vehicleNotesModal__textareaContainer">
                <textarea
                  className="vehicleNotesModal__textarea"
                  ref="commentTextarea"
                  placeholder={this.props.t('VehicleNotes.write_a_note')}
                />
              </div>
            </div>
            <div className="vehicleNotesModal__formFooter">
              <div className="vehicleNotesModal__commentButton" key="commentButton">
                <Button
                  buttonStyle="primary"
                  onClick={this.submitComment}
                  isLoading={this.props.loadingState === 'loading'}
                  hasError={this.props.loadingState === 'error'}
                >
                  {this.props.t('VehicleNotes.add_note')}
                </Button>
              </div>
              <div className="vehicleNotesModal__cancel" key="cancel">
                <Button type="button" buttonStyle="cancel" onClick={this.props.closeModal}>
                  {this.props.t('VehicleNotes.close')}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

VehicleNotes.propTypes = {
  comments: PropTypes.array,
  userInitials: PropTypes.string,
  onSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  loadingState: PropTypes.string,
  isOpen: PropTypes.bool
};
VehicleNotes.defaultProps = {
  comments: []
};

function mapStateToProps(state) {
  return {
    isOpen: state.modals.vehicleNotes ? state.modals.vehicleNotes.isOpen : false
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(modalActions.close('vehicleNotes'));
    }
  };
}

export default withTranslation('VehicleNotes')(connect(mapStateToProps, mapDispatchToProps)(VehicleNotes));
