import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from '../../Common/Button/Button';
import AddressLine from '../../Common/AddressLine';
import { inject } from 'mobx-react';
import i18n from '../../../i18n';
import observerForHooks from '~/hocs/observerForHooks';
import { getEligibilityStatusTranslation, shouldShowType } from '../utils';
import { hasCompanionQuotes } from '~/features';
import { findCompanionQuoteByType } from '~/components/QuoteCard/utils/getQuoteDisplaySchema';
import { LoanTypeJs, QuoteSummaryVariantTypeJs } from '~/components/QuoteCard/types';
import VehiclePanel from '../../Reporting/PartialSummarySections/VehiclePanel';
import MiniPanel from '../../Common/MiniPanel';
import QuotePanelV2 from '../../Reporting/PartialSummarySections/QuotePanelV2';
import MiniPanelHeader from '../../Common/MiniPanelHeader';
import PartExchangePanel from '../../Reporting/PartialSummarySections/PartExchangePanel';
import Table from '../../Reporting/PartialSummarySections/SummaryTable/Table';
import TableRow from '../../Reporting/PartialSummarySections/SummaryTable/TableRow';
import TableHead from '../../Reporting/PartialSummarySections/SummaryTable/TableHead';
import TableCell from '../../Reporting/PartialSummarySections/SummaryTable/TableCell';
import './orderSummaryTable.scss';

export const getVehicleCondition = (condition) => {
  switch (condition?.toLowerCase()) {
    case 'new':
      return i18n.t('VehicleCondition.new', {
        ns: 'DigitalDeals'
      });
    case 'used':
      return i18n.t('VehicleCondition.used', {
        ns: 'DigitalDeals'
      });
    default:
      return '';
  }
};

export const getVehicleClass = (vehicle) => {
  switch (vehicle?.toLowerCase()) {
    case 'car':
      return i18n.t('VehicleClass.car', {
        ns: 'DigitalDeals'
      });
    case 'lcv':
      return i18n.t('VehicleClass.lcv', {
        ns: 'DigitalDeals'
      });
    case 'bike':
    // Fall through
    case 'motorbike':
      return i18n.t('VehicleClass.motorbike', {
        ns: 'DigitalDeals'
      });
    default:
      return '';
  }
};

function OrderSummaryTable(props) {
  const { t } = useTranslation('DigitalDeals');
  let { vehicle, order, quote, goToCustomerApplication, customerAddress } = props;
  const orderPlacedDate = order && moment(order.DateTimeSubmittedInNewVehicle).format('DD MMM YYYY, HH:mm');
  const noAddress = !customerAddress;
  const showType = shouldShowType();

  let vehicleLoan;
  let vapsLoan;
  let negativeEquityLoan;
  if (quote && hasCompanionQuotes(quote)) {
    vehicleLoan = findCompanionQuoteByType(quote.CompanionQuotes, LoanTypeJs.vehicleLoan);
    vapsLoan = findCompanionQuoteByType(quote.CompanionQuotes, LoanTypeJs.vapsLoan);
    negativeEquityLoan = findCompanionQuoteByType(quote.CompanionQuotes, LoanTypeJs.negativeEquityLoan);
  }

  return (
    <>
      <MiniPanel style={{ marginTop: 20 }}>
        <MiniPanelHeader title={t('OrderSummaryTable.order')} />
        <div style={{ padding: 20 }}>
          <Table>
            <TableRow>
              <TableHead width="30%">{t('OrderSummaryTable.order_number')}</TableHead>
              <TableCell>{order.ReferenceCode}</TableCell>
            </TableRow>
            <TableRow>
              <TableHead width="30%">{t('OrdersItem.source')}</TableHead>
              <TableCell>{t('OrdersItem.online')}</TableCell>
            </TableRow>
            {showType && (
              <TableRow>
                <TableHead width="30%">{t('OrdersItem.type')}</TableHead>
                <TableCell>{order.Type}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableHead width="30%">{t('OrderSummaryTable.received')}</TableHead>
              <TableCell>{orderPlacedDate}</TableCell>
            </TableRow>
            {order.EligibilityStatus && (
              <TableRow>
                <TableHead width="30%">{t('OrderSummaryTable.eligibility')}</TableHead>
                <TableCell>{getEligibilityStatusTranslation(order.EligibilityStatus)}</TableCell>
              </TableRow>
            )}
          </Table>
        </div>
      </MiniPanel>

      <MiniPanel className="OrderSummaryTable__Customer">
        <MiniPanelHeader title={t('OrderSummaryTable.customer')} />
        <div style={{ padding: 20 }}>
          <Table>
            <TableRow>
              <TableHead width="30%">{t('OrderSummaryTable.name')}</TableHead>
              <TableCell>{`${order.CustomerFirstName} ${order.CustomerSurname}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableHead width="30%">{t('OrderSummaryTable.email')}</TableHead>
              <TableCell>{order.CustomerEmail}</TableCell>
            </TableRow>
            <TableRow>
              <TableHead width="30%">{t('OrderSummaryTable.address')}</TableHead>
              <TableCell>
                <AddressLine address={customerAddress} noAddress={noAddress} />
              </TableCell>
            </TableRow>
          </Table>
        </div>
      </MiniPanel>

      {vehicle && (
        <MiniPanel>
          <MiniPanelHeader title={t('OrderSummaryTable.vehicle')} />
          <VehiclePanel vehicle={vehicle} quote={quote} />
        </MiniPanel>
      )}

      {order.PartExchanges && (
        <MiniPanel>
          <MiniPanelHeader title={t('OrderSummaryTable.part_exchange')} />
          <PartExchangePanel partExchanges={order.PartExchanges} />
        </MiniPanel>
      )}

      {order && order.PaymentMethod === 'Cash' && (
        <MiniPanel>
          <MiniPanelHeader title={t('OrderSummaryTable.personal_details')} />
          <div style={{ padding: 20 }}>
            <Table>
              <TableRow>
                <TableHead width="30%">{t('OrderSummaryTable.title')}</TableHead>
                <TableCell>{order.CustomerTitle}</TableCell>
              </TableRow>
              <TableRow>
                <TableHead width="30%">{t('OrderSummaryTable.first_name')}</TableHead>
                <TableCell>{order.CustomerFirstName}</TableCell>
              </TableRow>
              <TableRow>
                <TableHead width="30%">{t('OrderSummaryTable.last_name')}</TableHead>
                <TableCell>{order.CustomerSurname}</TableCell>
              </TableRow>
              {(order.CustomerHomeTelephone || order.CustomerMobileTelephone) && (
                <TableRow>
                  <TableHead width="30%">{t('OrderSummaryTable.contact_number')}</TableHead>
                  <TableCell>{order.CustomerHomeTelephone || order.CustomerMobileTelephone}</TableCell>
                </TableRow>
              )}
              {order.CustomerEmail && (
                <TableRow>
                  <TableHead width="30%">{t('OrderSummaryTable.email_address')}</TableHead>
                  <TableCell>{order.CustomerEmail}</TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableHead width="30%">{t('OrderSummaryTable.billing_address')}</TableHead>
                <TableCell>
                  <AddressLine address={customerAddress} noAddress={noAddress} />
                </TableCell>
              </TableRow>
            </Table>
          </div>
        </MiniPanel>
      )}

      {order.PaymentMethod === 'Finance' && quote && (
        <>
          {/* Standard or Aggregate Quote START */}
          <MiniPanel>
            <MiniPanelHeader title={t('OrderSummaryTable.quote')}>
              {goToCustomerApplication && (
                <Button buttonStyle="secondary" onClick={() => goToCustomerApplication()}>
                  {t('OrderSummaryTable.view_application_summary')}
                </Button>
              )}
            </MiniPanelHeader>
            <QuotePanelV2
              quote={quote}
              loanType={LoanTypeJs.standardLoan}
              variant={QuoteSummaryVariantTypeJs.FinanceApplicationSummary}
            />
          </MiniPanel>
          {/* Standard or Aggregate Quote END */}

          {/* Companion Quotes START */}
          {vehicleLoan && (vapsLoan || negativeEquityLoan) && (
            <>
              <div className="OrderSummaryTable__MultiFinance">
                {t('OrderSummaryTable.this_quote_is_made_up_of_multiple_finance_components')}
              </div>
              <MiniPanel>
                <MiniPanelHeader title={t('OrderSummaryTable.vehicle_loan')} />
                <QuotePanelV2
                  quote={vehicleLoan}
                  loanType={LoanTypeJs.vehicleLoan}
                  variant={QuoteSummaryVariantTypeJs.FinanceApplicationSummary}
                  hasNegativeEquityLoan={negativeEquityLoan !== undefined}
                />
              </MiniPanel>
            </>
          )}
          {negativeEquityLoan && (
            <MiniPanel>
              <MiniPanelHeader title={t('OrderSummaryTable.negative_equity_loan')} />
              <QuotePanelV2
                quote={negativeEquityLoan}
                loanType={LoanTypeJs.negativeEquityLoan}
                variant={QuoteSummaryVariantTypeJs.FinanceApplicationSummary}
              />
            </MiniPanel>
          )}
          {vapsLoan && (
            <MiniPanel>
              <MiniPanelHeader title={t('OrderSummaryTable.value_added_products_loan')} />
              <QuotePanelV2
                quote={vapsLoan}
                loanType={LoanTypeJs.vapsLoan}
                variant={QuoteSummaryVariantTypeJs.FinanceApplicationSummary}
              />
            </MiniPanel>
          )}
          {/* Companion Quotes END */}
        </>
      )}
    </>
  );
}

OrderSummaryTable.propTypes = {
  params: PropTypes.object
};

export default inject('appStore')(observerForHooks(OrderSummaryTable));
