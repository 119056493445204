import React, { useEffect, useState } from 'react';
import { inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Panel from '~Common/Panel';
import PanelContent from '~Common/PanelContent';
import PanelHeader from '~Common/PanelHeader';
import TextArea from '~Common/Form/TextArea';
import Button from '~Common/Button/Button';
import MobxForm from '~MobxForm/MobxForm';
import { updateVideos } from '~/api/stockModule';

import './stockVideoUpload.scss';

export const generateTextareaValue = (videosMetadata, type) =>
  videosMetadata
    .filter(({ source }) => source === type)
    .map(({ url }) => url)
    .join('\r\n');

const StockVideoUpload = (props) => {
  const { dealershipId, vehicleId } = useParams();
  const [dealerVideosTextArea, setDealerVideosTextArea] = useState('');
  const [stockVideosTextArea, setStockVideosTextArea] = useState('');
  const [formState, setFormState] = useState('idle'); // idle|submitting|error
  const { dealerPlatformUpload, videosMetadata } = props.appStore.stockEditStore.vehicleData;
  const { t } = useTranslation('Stock');
  const placeholder = `${t('StockVideoUpload.enter_youtube_urls_here')}
      https://www.youtube.com/watch?v=dQw4w9WgXcQ
      https://www.youtube.com/watch?v=QM1iUe6IofM
      `;

  useEffect(() => {
    setDealerVideosTextArea(generateTextareaValue(videosMetadata, 'dealer'));
    setStockVideosTextArea(generateTextareaValue(videosMetadata, 'stock'));
  }, [videosMetadata]);

  const handleTextAreaChange = (value) => {
    setDealerVideosTextArea(value);
  };

  const handleSubmit = async () => {
    const videoUrls = dealerVideosTextArea.split(/\r?\n|\s/).filter(Boolean);
    try {
      setFormState('submitting');
      await updateVideos(dealershipId, vehicleId, videoUrls);
      setFormState('idle');
    } catch (e) {
      setFormState('error');
    }
  };

  return (
    <Panel>
      <PanelHeader>{t('StockVideoUpload.upload_youtube_video_urls')}</PanelHeader>
      <PanelContent>
        <MobxForm onSubmit={handleSubmit}>
          {!dealerPlatformUpload && (
            <>
              <label htmlFor="stock-videos" className="stockVideoUpload__label">
                {t('StockVideoUpload.feed_upload_youTube_videos_urls')}
              </label>
              <TextArea id="stock-videos" className="stockVideoUpload__textarea" value={stockVideosTextArea} disabled />
            </>
          )}

          {dealerPlatformUpload && (
            <>
              <label htmlFor="dealer-videos" className="stockVideoUpload__label">
                {t('StockVideoUpload.manually_uploaded_youtube_video_urls')}
              </label>
              <TextArea
                id="dealer-videos"
                className="stockVideoUpload__textarea"
                placeholder={placeholder}
                value={dealerVideosTextArea}
                onChange={handleTextAreaChange}
              />
              <Button
                size="normal"
                isLoading={formState === 'submitting'}
                hasError={formState === 'error'}
                className="stockVideoUpload__uploadButton"
              >
                {t('StockVideoUpload.upload_video_urls')}
              </Button>
            </>
          )}
        </MobxForm>
      </PanelContent>
    </Panel>
  );
};

export default inject('appStore')(StockVideoUpload);
