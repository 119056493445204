import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './addressHistoryPanel.scss';
import Table from './SummaryTable/Table';
import TableRow from './SummaryTable/TableRow';
import { getTimeAtAddressString, splitCamelCaseToString } from '../../../core/helpers';
import TableHead from './SummaryTable/TableHead';
import TableCell from './SummaryTable/TableCell';
import AddressLine from '../../Common/AddressLine';

const BusinessAddressHistoryPanelItem = ({ address, index }) => {
  const { t } = useTranslation('Reporting');
  return (
    <div className="addressHistoryPanel__item">
      <div className="addressHistoryPanel__itemHeader">
        {t('BusinessAddressHistoryPanelItem.address')} {index + 1}
      </div>
      <Table>
        <TableRow>
          <TableHead width="25%">{t('BusinessAddressHistoryPanelItem.address')}</TableHead>
          <TableCell>
            <AddressLine address={address} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableHead width="25%">{t('BusinessAddressHistoryPanelItem.premises_type')}</TableHead>
          <TableCell>{splitCamelCaseToString(address.PremisesType)}</TableCell>
        </TableRow>
        <TableRow>
          <TableHead width="25%">{t('BusinessAddressHistoryPanelItem.time_at_address')}</TableHead>
          <TableCell>{getTimeAtAddressString(address.TimeAtAddress)}</TableCell>
        </TableRow>
      </Table>
    </div>
  );
};

BusinessAddressHistoryPanelItem.propTypes = {
  address: PropTypes.object,
  index: PropTypes.number
};
export default BusinessAddressHistoryPanelItem;
