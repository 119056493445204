import Uppy from '@uppy/core';
import German from '@uppy/locales/lib/de_DE';
import Spanish from '@uppy/locales/lib/es_ES';
import French from '@uppy/locales/lib/fr_FR';
import Italian from '@uppy/locales/lib/it_IT';
import DropTarget from '@uppy/drop-target';
import Webcam from '@uppy/webcam';
import ImageEditor from '@uppy/image-editor';
import XHRUpload from '@uppy/xhr-upload';

import i18n from '~/i18n';
import { useState } from 'react';

const noop = () => {};

const useVehicleCreateUppy = ({ endpoint, onUploadSuccess = noop, onComplete = noop, onBeforeFileAdded = noop }) => {
  const locales = {
    en: null,
    de: German,
    es: Spanish,
    fr: French,
    it: Italian
  };

  const [uppy] = useState(() =>
    new Uppy({
      meta: { type: 'avatar' },
      restrictions: { maxNumberOfFiles: 56, allowedFileTypes: ['image/*'], maxFileSize: 21000000 }, // allowing 20mb max
      autoProceed: false,
      onBeforeFileAdded,
      locale: locales[i18n.language]
    })
      .use(DropTarget, { target: document.body })
      .use(Webcam)
      .use(XHRUpload, {
        endpoint: endpoint,
        fieldName: 'FormFile',
        allowedMetaFields: ['fileName', 'tag']
      })
      .use(ImageEditor, {
        id: 'ImageEditor',
        quality: 0.8,
        cropperOptions: {
          viewMode: 1,
          background: false,
          autoCropArea: 1,
          responsive: true,
          croppedCanvasOptions: {},
          initialAspectRatio: 4 / 3
        },
        actions: {
          revert: true,
          rotate: true,
          granularRotate: true,
          flip: true,
          zoomIn: true,
          zoomOut: true,
          cropSquare: false,
          cropWidescreen: false,
          cropWidescreenVertical: false
        }
      })
      .on('upload-success', onUploadSuccess)
      .on('upload-error', () => {
        //removes the default error informer and only show subsequent custom errors generated in onComplete()
        uppy.hideInfo();
      })
      .on('complete', (res) => {
        onComplete(res);
      })
  );

  return uppy;
};

export default useVehicleCreateUppy;
