import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon/Icon';
import { compose } from 'redux';
import { withNavigate, withQuery } from 'hocs/router';
import cn from 'classnames';
import './squareActionButton.scss';

class SquareActionButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMouseOver: false
    };
  }

  iconClassName = this.props.iconClassName || '';

  handleMouseOver = () => {
    this.setState({
      hasMouseOver: true
    });
  };

  handleMouseOut = () => {
    this.setState({
      hasMouseOver: false
    });
  };

  onLinkClick = (event) => {
    event.preventDefault();
    if (this.props.to && !this.props.disabled) {
      this.props.navigate({
        pathname: this.props.to,
        query: this.props.query
      });
    }
    if (this.props.onClick && !this.props.disabled) {
      this.props.onClick();
    }
  };

  render() {
    const { disabled, isBold, href, text, iconName, ctaText, dataThook } = this.props;
    const iconOuterClasses = `squareActionButton__iconOuter${this.iconClassName}`;
    const linkClasses = cn('squareActionButton', {
      'squareActionButton--disabled': disabled
    });
    const actionTextClasses = cn('squareActionButton__actionText', {
      'squareActionButton__actionText--bold': isBold
    });

    return (
      <a
        href={href || '#'}
        onClick={!href && this.onLinkClick}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        className={linkClasses}
        target="_blank"
        rel="noopener noreferrer"
        data-th={dataThook}
      >
        <span className={actionTextClasses}>{text}</span>
        <div className={iconOuterClasses}>
          <Icon name={iconName} />
        </div>
        <div />
        <div className="squareActionButton__button">{ctaText}</div>
      </a>
    );
  }
}

SquareActionButton.propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,
  ctaText: PropTypes.string,
  type: PropTypes.string,
  iconName: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  params: PropTypes.object,
  query: PropTypes.object,
  href: PropTypes.string,
  isBold: PropTypes.bool,
  dataThook: PropTypes.string
};

SquareActionButton.defaultProps = {
  disabled: false,
  isBold: false // Default value for the new prop
};

export default compose(withQuery, withNavigate)(SquareActionButton);
