import React from 'react';
import './lenderDocumentLink.scss';

const LenderDocumentLink = ({ displayTitle, children }) => {
  return (
    <div className="lenderDocumentLink__modal--links">
      <div className="lenderDocumentLink__modal--subtitle">{displayTitle}</div>
      <div className="lenderDocumentLink__modal--link">{children}</div>
    </div>
  );
};

export default LenderDocumentLink;
