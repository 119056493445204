import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Common/Icon/Icon';
import Map from './Map';
import './heatMap.scss';

const HeatMap = ({ dealership, userDistribution, averageDistance }) => {
  const { t } = useTranslation('DigitalReport');
  return (
    <div className="heatMap__outer">
      <div className="heatMap__mapOuter">
        <Map
          lat={dealership.Latitude}
          lon={dealership.Longitude}
          data={userDistribution}
          name={dealership.DisplayName}
        />
      </div>
      <div className="heatMap__infoOuter">
        <div className="heatMap__infoOuterLeft">
          <Icon name="distance" />
        </div>
        <div className="heatMap__infoOuterRight">
          <div className="heatMap__mileage">
            {Math.round(averageDistance)} {t('HeatMap.miles')}
          </div>
          {t('HeatMap.average_distance_of_rooftop_to_buyer')}
        </div>
      </div>
    </div>
  );
};

HeatMap.defaultProps = {
  dealership: {}
};
HeatMap.propTypes = {
  dealership: PropTypes.object,
  userDistribution: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  averageDistance: PropTypes.number
};
export default HeatMap;
