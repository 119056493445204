import React from 'react';
import PropTypes from 'prop-types';
import './dashboardGroupLead.scss';
const GroupLead = ({ leadName }) => (
  <div className="dashboardGroupLead__leads">
    <div className="dashboardGroupLead__dealerName">{leadName}</div>
  </div>
);

GroupLead.propTypes = {
  leadName: PropTypes.string
};

export default GroupLead;
