import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changeDealership, unsubscribeFromDealership } from '../../../redux/dealership/dealershipActions';
import { fetchDealershipSettings } from '../../../redux/settings/settingsActions';
import LoadingDots from '../../Common/Loading/LoadingDots';
import { withOutletContextProps, withParams } from 'hocs/router';
import { Outlet } from 'react-router-dom';
import GlobalLayout from 'components/Common/layouts/GlobalLayout';
import { signOut } from 'redux/session/sessionActions';
import { fetchOptions } from 'redux/options/optionsActions';
import { fetchFundersInfo } from 'redux/funder/funderActions';
import { getThemeName } from 'theme';
import { compose } from 'redux';

class DealershipContainer extends React.Component {
  componentDidMount() {
    this.props.changeDealership(this.props.params.dealershipId);
    this.props.fetchDealershipSettings(this.props.params.dealershipId);
  }

  componentWillUnmount() {
    this.props.unsubscribeFromDealership(this.props.params.dealershipId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.params.dealershipId && nextProps.params.dealershipId !== this.props.params.dealershipId) {
      this.props.changeDealership(nextProps.params.dealershipId);
      this.props.fetchDealershipSettings(nextProps.params.dealershipId);
    }
  }

  render() {
    if (!this.props.isSignedIn || this.props.isLoading) {
      return <LoadingDots />;
    }

    return (
      <div data-th="DealershipContainer">
        <GlobalLayout {...this.props}>
          <Outlet context={this.props} />
        </GlobalLayout>
      </div>
    );
  }
}

DealershipContainer.propTypes = {
  params: PropTypes.object,
  isLoading: PropTypes.bool,
  children: PropTypes.any,
  changeDealership: PropTypes.func.isRequired,
  unsubscribeFromDealership: PropTypes.func.isRequired,
  isSignedIn: PropTypes.bool,
  fetchSession: PropTypes.func,
  fetchOptions: PropTypes.func,
  fetchFundersInfo: PropTypes.func,
  themeName: PropTypes.string,
  signOut: PropTypes.func,
  session: PropTypes.object,
  dispatch: PropTypes.func,
  hasPlatformUpdates: PropTypes.bool,
  showLoadingSpinner: PropTypes.bool,
  appStore: PropTypes.object
};

function mapStateToProps(state) {
  return {
    isLoading: state.dealership.isLoading || !state.dealership.Id || state.products.isLoading || !state.funders.length,
    session: state.session,
    pubNubAuthId: state.session.PubNub && state.session.PubNub.AuthId,
    isSignedIn: state.session.isSignedIn,
    showLoadingSpinner: !state.components.globalHasLoaded,
    themeName: getThemeName(),
    hasPlatformUpdates: state.session.hasUnreadPlatformUpdates,
    products: state.products,
    dealership: state.dealership
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeDealership: (dealershipId) => dispatch(changeDealership(dealershipId)),
    unsubscribeFromDealership: (dealershipId) => dispatch(unsubscribeFromDealership(dealershipId)),
    fetchDealershipSettings: (dealershipId) => dispatch(fetchDealershipSettings(dealershipId)),
    fetchOptions: () => dispatch(fetchOptions()),
    fetchFundersInfo: () => dispatch(fetchFundersInfo()),
    signOut: () => dispatch(signOut())
  };
}

export default compose(
  withOutletContextProps,
  withParams,
  connect(mapStateToProps, mapDispatchToProps)
)(DealershipContainer);
