import React from 'react';
import PropTypes from 'prop-types';
import './timelineItem.scss';
import classnames from 'classnames';

const TimelineItem = ({ index, first, last }) => {
  const circleClassNames = classnames('timelineItem__circle', index === 0 && 'timelineItem__circle--large');

  return (
    <div className="timelineItem">
      <div className={circleClassNames} />
      {!first && !last && <div className="timelineItem__line" key="line" />}
    </div>
  );
};

TimelineItem.propTypes = {
  index: PropTypes.number,
  first: PropTypes.bool,
  last: PropTypes.bool
};

export default TimelineItem;
