import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './benefitsAndRisks.scss';

const BenefitsAndRisksFooter = ({ hideVideo }) => {
  const { t } = useTranslation('Application');

  return (
    <div className={hideVideo ? 'benefitsAndRisks__printFooter' : 'benefitsAndRisks__footerText'} key="ft1">
      {t('BenefitsAndRisks.the_customer_must_confirm_the_following')}
      <ul>
        <li>{t('BenefitsAndRisks.the_customer_has_read_viewed_the_details_of_the_finance_product_selected')}</li>
        <li>
          {t(
            'BenefitsAndRisks.the_product_meets_their_needs_as_a_customer_who_wishes_to_buy_a_vehicle_using_a_finance_agreement_rather_than_their_own_funds_based_on_their_expected_usage_and_vehicle_change_cycle'
          )}
        </li>
        <li>
          {t('BenefitsAndRisks.the_customer_understands_the_apr_may_change_once_the_application_has_been_submitted')}
        </li>
        <li>
          {t(
            'BenefitsAndRisks.the_customer_is_aware_that_they_can_early_settle_their_agreement_and_pay_it_off_at_any_time_in_full_or_in_part'
          )}
        </li>
        <li>
          {t(
            'BenefitsAndRisks.where_a_pcp_or_hp_balloon_product_has_been_selected_the_customer_is_aware_of_the_options_at_the_end_of_the_agreement'
          )}
        </li>
        <li>
          {t(
            'BenefitsAndRisks.the_customer_has_been_made_aware_of_the_existence_and_nature_of_any_commission_to_be_paid_by_the_lender'
          )}
        </li>
      </ul>
    </div>
  );
};

BenefitsAndRisksFooter.propTypes = {
  quote: PropTypes.object.isRequired,
  hideVideo: PropTypes.bool,
  email: PropTypes.string.isRequired,
  applicationLoadingState: PropTypes.string,
  children: PropTypes.any
};

export default BenefitsAndRisksFooter;
