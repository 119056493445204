import React from 'react';
import { useTranslation } from 'react-i18next';

import StarRating from '~Common/Form/StarRating';

import classes from './broadcastStockListDataAccuracyModal.module.scss';

const BroadcastStockListDataAccuracyModal = () => {
  const { t } = useTranslation('Stock');
  return (
    <div className={classes.outer}>
      <p className={classes.title}>{t('StockListDataAccuracyModal.data_accuracy')}</p>
      <div className={classes.section}>
        <span className={classes.starRatingContainer}>
          <StarRating stars={3} active={3} className={classes.starRating} />
        </span>
        <p className={classes.text}>
          <strong>{t('StockListDataAccuracyModal.good_data_quality')}</strong>
          {t(
            'StockListDataAccuracyModal.we_have_a_high_degree_of_confidence_that_your_supplied_vehicle_description_matches_the_data_we_use_for_quoting'
          )}
        </p>
      </div>
      <div className={classes.section}>
        <span className={classes.starRatingContainer}>
          <StarRating stars={3} active={2} className={classes.starRating} />
        </span>
        <p className={classes.text}>
          <strong>{t('StockListDataAccuracyModal.potential_data_issue')}</strong>
          {t(
            'StockListDataAccuracyModal.we_suspect_there_could_be_a_potential_difference_between_your_supplied_vehicle_data_and_the_data_we_use_for_quoting_the_most_common_mismatch_is_missing_data_or_the_vehicles_bodystyle_differing'
          )}
        </p>
      </div>
      <div className={classes.section}>
        <span className={classes.starRatingContainer}>
          <StarRating stars={3} active={1} className={classes.starRating} />
        </span>
        <p className={classes.text}>
          <strong>{t('StockListDataAccuracyModal.severe_data_issue')}</strong>
          {t(
            'StockListDataAccuracyModal.it_looks_like_your_supplied_vehicle_does_not_match_the_data_we_use_for_quoting'
          )}
        </p>
      </div>
      <div className={classes.section}>
        <span className={classes.starRatingContainer}>
          <StarRating stars={3} active={0} />
        </span>

        <p className={classes.text}>
          <strong>{t('StockListDataAccuracyModal.no_taxonomy')}</strong>
          {t(
            'StockListDataAccuracyModal.from_your_supplied_data_we_are_unable_to_find_a_matching_vehicle_to_provide_a_quote'
          )}
        </p>
      </div>
    </div>
  );
};

export default BroadcastStockListDataAccuracyModal;
