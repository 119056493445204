import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './dashboardLinkedUsers.scss';
import Icon from '../../Common/Icon/Icon';

const DashboardLinkedUsers = ({ aliasCount }) => {
  const { t } = useTranslation('Dashboard');
  return (
    <div className={aliasCount > 0 ? 'dashboardLinkedUsers' : 'dashboardLinkedUsers__hidden'}>
      <div className="dashboardLinkedUsers__image">
        <Icon name="linked-consumer" />
      </div>
      <div className="dashboardLinkedUsers__text">{t('DashboardLinkedUsers.linked_customers')}</div>
    </div>
  );
};

DashboardLinkedUsers.propTypes = {
  aliasCount: PropTypes.number
};
export default DashboardLinkedUsers;
