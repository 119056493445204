import React from 'react';
import { useTranslation } from 'react-i18next';
import { snakeCase } from 'lodash';
import { PaymentDetails, QuoteRowType } from '../../types';
import { DetailsTableItemProps } from '../DetailsTable/DetailsTableItem';

type QuoteRowLabelProps = DetailsTableItemProps & { translationModule?: string };

// Translating and rendering quote row label
// This component should not hold any styling, all styling should be done in the parent component that renders it
export const QuoteRowLabel: React.FC<QuoteRowLabelProps> = ({ label, value, translationModule = 'QuoteCardTable' }) => {
  const { t } = useTranslation('QuoteCard');

  if (value.type === QuoteRowType.paymentSchedule) {
    const paymentDetails = value.value as PaymentDetails;
    return (
      <>
        {t('QuoteCardTable.followed_by_payment_prefix', {
          paymentPrefix: `${paymentDetails.duration} ${
            paymentDetails.duration === 1 ? t('QuoteCardTable.payment') : t('QuoteCardTable.payments')
          }`
        })}
      </>
    );
  }

  if (label.startsWith('VAP__')) {
    return <>{label.substring(5)}</>;
  }

  return <>{t(`${translationModule}.${snakeCase(label)}`)}</>;
};
