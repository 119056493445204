import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import classnames from 'classnames';
import './buttonWithIcon.scss';

const ButtonWithIcon = ({ children, iconName, buttonStyle, onClick }) => {
  let iconClasses = classnames('buttonWithIcon', buttonStyle && `buttonWithIcon--${buttonStyle}`);

  const btnTextClasses = classnames({
    'visually-hidden': buttonStyle === 'square' || buttonStyle === 'square active'
  });

  return (
    <span className={iconClasses} onClick={onClick} data-th="ButtonWithIcon">
      <div className="buttonWithIcon__icon">
        <Icon name={iconName} />
      </div>
      <span className={btnTextClasses}>{children}</span>
    </span>
  );
};
ButtonWithIcon.defaultProps = {
  buttonStyle: 'primary'
};
ButtonWithIcon.propTypes = {
  children: PropTypes.node,
  iconName: PropTypes.string,
  buttonStyle: PropTypes.string,
  onClick: PropTypes.func
};

export default ButtonWithIcon;
