import React from 'react';
import './videoTop.scss';

const VideoTop = () => (
  <div className="videoTop">
    <div className="videoTop__circle videoTop__red" />
    <div className="videoTop__circle videoTop__yellow" />
    <div className="videoTop__circle videoTop__green" />
  </div>
);

export default VideoTop;
