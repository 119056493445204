import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { getLenderConfig } from '../../../core/lenders';
import AssignLeadContainer from '../../AssignLead/containers/AssignLeadContainer';
import AlignRight from '../../Common/AlignRight';
import BoldHeader from '../../Common/BoldHeader';
import Breadcrumbs from '../../Common/Breadcrumbs';
import ConsumerVehicleInfoPanel from '../../Common/ConsumerVehicleInfoPanel';
import Divider from '../../Common/Divider';
import ExclamationWarning from '../../Common/ExclamationWarning';
import FormFooter from '../../Common/Form/FormFooter';
import Page from '../../Common/Page';
import Panel from '../../Common/Panel';
import PanelContent from '../../Common/PanelContent';
import PanelHeader from '../../Common/PanelHeader';
import PanelToolBar from '../../Common/PanelToolBar';
import ApplicationSummaryTables from './ApplicationSummaryTables';
import BenefitsAndRisks from './BenefitsAndRisks';
import BenefitsAndRisksFooter from './BenefitsAndRisksFooter';
import { inject, observer } from 'mobx-react';
import { isOnlineSource } from '../../../utils/application';
import { compose } from 'redux';
import { withOutletContextProps, withParams } from 'hocs/router';
import { canSeeConsumerDutyContent } from '../../../features';
import { printPageByRef } from '~/core/print';
import './viewApplicationSummary.scss';

/*
 * - Served when user clicks the View Application CTA from the Application Status page
 */
@inject('appStore')
@observer
class ViewApplicationSummary extends React.Component {
  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0);
    this.setState({
      applicationFields: getLenderConfig(this.props.application.Quote.FunderCode, 'Consumer', 'applicationFields')
    });
  }

  printAreaRef = React.createRef();

  handlePrint = () => {
    printPageByRef(this.printAreaRef);
  };

  handleCancel = () => {
    window.history.back();
  };

  render() {
    const { dealershipId, consumerId, applicantId } = this.props.params;
    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: this.props.t('ViewApplicationSummary.home'),
              path: `/d/${dealershipId}`
            },
            {
              name: this.props.t('ViewApplicationSummary.customer_list'),
              path: `/d/${dealershipId}/consumers`
            },
            {
              name: this.props.t('ViewApplicationSummary.consumer'),
              path: `/d/${dealershipId}/consumers/${consumerId}`
            },
            {
              name: this.props.t('ViewApplicationSummary.application_status'),
              path: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/status`
            },
            {
              name: this.props.t('ViewApplicationSummary.application_summary')
            }
          ]}
          consumer={this.props.consumer}
        />

        <ConsumerVehicleInfoPanel vehicle={this.props.vehicle} quote={this.props.application.Quote} />
        <Panel>
          <PanelHeader>{this.props.t('ViewApplicationSummary.application_summary')}</PanelHeader>
          {this.props.appStore.uiState.canViewConsumerApplications ? (
            <>
              <PanelToolBar>
                <AlignRight>
                  <AssignLeadContainer
                    dealershipId={dealershipId}
                    customerId={this.props.consumer.Id}
                    customerName={
                      this.props.consumer.CustomerType.toLowerCase() === 'consumer'
                        ? `${this.props.consumer.Firstname} ${this.props.consumer.Surname}`
                        : this.props.consumer.TradingName
                    }
                    customerType={this.props.consumer.CustomerType.toLowerCase()}
                    initialAssignees={this.props.consumer.assignedTo}
                  />
                </AlignRight>
              </PanelToolBar>
              <PanelContent>
                <div className="viewApplicationSummary">
                  <div className="printArea" ref={this.printAreaRef}>
                    <ApplicationSummaryTables
                      options={this.props.options}
                      application={this.props.application}
                      showValidationTicks={true}
                      applicationFields={this.state.applicationFields}
                      showConsumerHubInfoPanel={isOnlineSource(this.props.application)}
                    />
                    <Divider />
                    {!canSeeConsumerDutyContent() && (
                      <>
                        <BoldHeader text={this.props.t('ViewApplicationSummary.benefits_and_risks')} />
                        <BenefitsAndRisks quote={this.props.application.Quote} email={this.props.application.Email} />
                      </>
                    )}
                    <BenefitsAndRisksFooter />
                  </div>
                </div>
                <FormFooter hideSubmit={true} onPrint={this.handlePrint} onCancel={this.handleCancel} />
              </PanelContent>
            </>
          ) : (
            <PanelContent>
              <ExclamationWarning
                message={this.props.t('ViewApplicationSummary.you_do_not_have_the_required_permission_for_this_action')}
              />
              <FormFooter hideSubmit={true} onCancel={this.handleCancel} />
            </PanelContent>
          )}
        </Panel>
      </Page>
    );
  }
}

ViewApplicationSummary.propTypes = {
  params: PropTypes.object,
  appStore: PropTypes.any,
  options: PropTypes.object,
  application: PropTypes.object,
  dealership: PropTypes.object,
  applicationFields: PropTypes.object,
  consumer: PropTypes.object,
  vehicle: PropTypes.object,
  assignees: PropTypes.array
};

export default compose(withTranslation('Application'), withOutletContextProps, withParams)(ViewApplicationSummary);
