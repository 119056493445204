import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './youtubeVideo.scss';

class YoutubeVideo extends React.Component {
  constructor() {
    super();
    this.resize = _.throttle(this.resize, 100);
  }

  resize = () => {
    const ratio = 367 / 206;
    const container = this.refs.container;
    const iframe = this.refs.iframe;

    const width = container.clientWidth;
    const height = width / ratio;

    if (iframe) {
      iframe.width = width;
      iframe.height = Math.floor(height);
    }
  };

  componentDidMount() {
    setTimeout(this.resize, 100);
    window.addEventListener('resize', this.resize, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize, false);
  }

  setStartTime() {
    const startParam = '?start=';
    return typeof this.props.startTime !== 'undefined' ? startParam + this.props.startTime : startParam + '0';
  }

  render() {
    const src = `https://www.youtube.com/embed/${this.props.source}`;
    const startStringParameter = this.setStartTime();
    return (
      <div className="youtubeVideo" ref="container">
        <div>
          <iframe
            title="video"
            ref="iframe"
            src={src + startStringParameter}
            width="367"
            height="206"
            allowTransparency="true"
            frameBorder="0"
            scrolling="no"
            className="youtube_embed"
            name="youtube_embed"
            fullscreenbutton="true"
            allowFullScreen={true}
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            oallowfullscreen="true"
            msallowfullscreen="true"
          />
        </div>
      </div>
    );
  }
}

YoutubeVideo.propTypes = {
  source: PropTypes.string,
  startTime: PropTypes.number
};

export default YoutubeVideo;
