import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './tabContent.scss';

const TabContent = ({ children, border, currentTab }) => {
  let containerStyle = classnames('tabContent', border && 'tabContent__border');

  const child = React.Children.toArray(children)[currentTab];

  return (
    <div className={containerStyle}>
      <div className="tabContent__contentWrapper">
        <div className="tabContent__panel">{child}</div>
      </div>
    </div>
  );
};

TabContent.propTypes = {
  children: PropTypes.node,
  border: PropTypes.bool,
  currentTab: PropTypes.number
};

TabContent.defaultProps = {
  border: true
};

export default TabContent;
