import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import './tooltip.scss';

export function Tooltip({ children, content, open, defaultOpen, onOpenChange, variant, ...props }) {
  return (
    <TooltipPrimitive.Root open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange} delayDuration={0}>
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal>
        <TooltipPrimitive.Content className={`tooltip__content ${variant}`} side="bottom" align="center" {...props}>
          {content}
          <TooltipPrimitive.Arrow className="tooltip__arrow" width={11} height={5} />
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  );
}
