import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import TimelineItem from './TimelineItem';
import FunderLogo from '../../Common/FunderLogo';
import DateFormat from '../../Common/DateFormat';
import MoneyFormat from '../../Common/MoneyFormat';
import CardCell from './CardCell';
import LenderStatus from '../../Common/LenderStatus/LenderStatus';
import CardLayout from './CardLayout';
import CardCellLabel from './CardCellLabel';
import './applicationsListItem.scss';
import classnames from 'classnames';

const ApplicationsListItem = (props) => {
  const { t } = useTranslation('LatestDeals');
  const classes = classnames('applicationsListItem__outer', props.index !== 0 && 'applicationsListItem__opacity');
  return (
    <div className={classes} onClick={props.handleApplicationClick} key="outer">
      <div className="applicationsListItem__dot" key="dot">
        <TimelineItem index={props.index} first={props.first} last={props.last} />
      </div>
      <CardLayout>
        <CardCell>
          <DateFormat value={props.application.LastModifiedTimestamp} parse="utc" format="HH:mm - DD MMM YYYY" />
        </CardCell>
        <CardCell>
          <div className="applicationsListItem__logo">
            <FunderLogo funder={props.application.LenderCode} />
          </div>
        </CardCell>
        <CardCell>
          <CardCellLabel label={t('ApplicationsListItem.product_type')}>{props.application.FinanceType}</CardCellLabel>
        </CardCell>
        <CardCell>
          <CardCellLabel label={t('ApplicationsListItem.term')}>
            {t('ApplicationsListItem.months', {
              months: props.application.Term
            })}
          </CardCellLabel>
        </CardCell>
        <CardCell>
          <CardCellLabel label={t('ApplicationsListItem.advance')}>
            <MoneyFormat value={props.application.Advance} />
          </CardCellLabel>
        </CardCell>
        <CardCell>
          <div className="applicationsListItem__status">
            <LenderStatus status={props.application.Status} />
          </div>
        </CardCell>
      </CardLayout>
    </div>
  );
};

ApplicationsListItem.propTypes = {
  application: PropTypes.object,
  index: PropTypes.number,
  handleApplicationClick: PropTypes.func,
  first: PropTypes.bool,
  last: PropTypes.bool
};
export default ApplicationsListItem;
