import * as lenderList from './lenderList';

function getLenderRules(lenderCode, applicationType, lenders) {
  //Default to the motonovo application form for lenders that we don't have rules for
  if (!lenders[applicationType.toLowerCase()].hasOwnProperty(lenderCode)) {
    return lenders[applicationType.toLowerCase()].def;
  }

  return lenders[applicationType.toLowerCase()][lenderCode];
}

export function getApplicationStatusRules(lenderCode, applicationType, status, lenders = lenderList.lenders) {
  const rules = getLenderRules(lenderCode, applicationType, lenders);

  return rules.applicationStatuses && rules.applicationStatuses[status] ? rules.applicationStatuses[status] : {};
}

export function getLenderConfig(lenderCode, applicationType, config, lenders = lenderList.lenders) {
  return getLenderRules(lenderCode, applicationType, lenders)[config];
}
