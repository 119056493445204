import React from 'react';
import PropTypes from 'prop-types';

const ActivityTimeline = ({ children }) => (
  <div>
    {React.Children.toArray(children).map((child, i) => {
      // const setShowContent = i === 0 ? true : false;
      return React.cloneElement(child, {
        showContent: true
      });
    })}
  </div>
);

ActivityTimeline.propTypes = {
  children: PropTypes.any
};

export default ActivityTimeline;
