export const generateImageUrlFromIds = ({ dealershipId, vehicleId, fileName, params = 'height=150&mode=max' }) => {
  const region = process.env.REACT_APP_REGION.toLowerCase();

  switch (region) {
    case 'de': {
      return `${process.env.REACT_APP_STOCK_IMAGE_CLOUDFRONT}/${region}/${process.env.REACT_APP_STOCK_IMAGE_DIRECTORY}/${dealershipId}/${vehicleId}/${fileName}?${params}`;
    }
    default: {
      return `${process.env.REACT_APP_STOCK_IMAGE_CLOUDFRONT}/${process.env.REACT_APP_STOCK_IMAGE_DIRECTORY}/${dealershipId}/${vehicleId}/${fileName}?${params}`;
    }
  }
};

export default generateImageUrlFromIds;
