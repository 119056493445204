/*eslint vars-on-top:0*/

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './wistiaVideo.scss';

let scriptIncluded = false;

class WistiaVideo extends React.Component {
  constructor() {
    super();
    this.resize = _.throttle(this.resize, 100);
  }

  resize = () => {
    const ratio = 367 / 206;
    const container = this.refs.container;
    const iframe = this.refs.iframe;

    const width = container.clientWidth;
    const height = width / ratio;

    if (iframe) {
      iframe.width = width;
      iframe.height = Math.floor(height);
    }
  };

  componentDidMount() {
    setTimeout(this.resize, 100);
    window.addEventListener('resize', this.resize, false);
    if (typeof this.props.startTracking === 'function') {
      this.props.startTracking(this.props.source);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize, false);
  }

  render() {
    // include the script a single time.
    scriptIncluded = true;

    let src = `//fast.wistia.net/embed/iframe/${this.props.source}?videoFoam=true${
      this.props.email ? `&email=${this.props.email}` : ''
    }`;

    return (
      <div className="wistiaVideo" ref="container">
        <div>
          <iframe
            title="video"
            ref="iframe"
            src={src}
            width="367"
            height="206"
            allowTransparency="true"
            frameBorder="0"
            scrolling="no"
            className="wistia_embed"
            name="wistia_embed"
            fullscreenbutton="true"
            allowFullScreen={true}
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            oallowfullscreen="true"
            msallowfullscreen="true"
          />
          {scriptIncluded ? null : <script src="//fast.wistia.net/assets/external/E-v1.js" async />}
        </div>
      </div>
    );
  }
}

WistiaVideo.propTypes = {
  email: PropTypes.string,
  source: PropTypes.string,
  startTracking: PropTypes.func
};

export default WistiaVideo;
