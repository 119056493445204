import { Trans, withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import QuotesListCardItem from '../../Quoting/components/QuotesListCardItem';
import QuotesListTableItem from '../../Quoting/components/QuotesListTableItem';
import SelectedLabel from '../../Common/SelectedLabel';
import Button from '../../Common/Button/Button';
import './repExampleSelectQuoteForm.scss';
import InformationWarning from '../../Common/InformationWarning';

class RepExampleSelectQuoteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canViewAllQuotes: false
    };
  }

  handleSelect = (quote) => {
    this.props.appStore.repExampleStore.saveQuoteId(quote);
  };
  handleClear = () => {
    this.props.appStore.repExampleStore.clearQuote();
  };
  handleSubmit = () => {
    this.props.appStore.repExampleStore.submitExampleQuote(this.props.dealershipId);
    this.props.handleSubmit();
  };
  toggleQuotesView = () => {
    this.setState((prevState) => ({
      canViewAllQuotes: !prevState.canViewAllQuotes
    }));
  };

  render() {
    const filteredQuotes = this.props.appStore.repExampleStore.quotes.filter((quote) => {
      if (quote.isVisible === true) {
        return quote;
      }
    });
    const quotes = this.state.canViewAllQuotes === true ? this.props.appStore.repExampleStore.quotes : filteredQuotes;
    const isLoading = this.props.appStore.repExampleStore.setRepExample.isLoading;
    const hasError =
      this.props.appStore.repExampleStore.quoteRequest.hasError ||
      this.props.appStore.repExampleStore.setRepExample.hasError ||
      this.props.appStore.repExampleStore.getExistingRepExample.hasError;
    return (
      <div>
        <div className="repExampleVehiclePriceForm__menu">
          <div className="repExampleVehiclePriceForm__quoteToggle">
            <Button type="button" buttonStyle="primary" hasError={hasError} onClick={this.toggleQuotesView}>
              {this.state.canViewAllQuotes
                ? this.props.t('RepExampleSelectQuoteForm.hide_more_options')
                : this.props.t('RepExampleSelectQuoteForm.view_more_options')}
            </Button>
          </div>
        </div>

        {this.props.appStore.repExampleStore.newQuote && this.props.appStore.repExampleStore.newQuote.QuoteId ? (
          <div>
            <MediaQuery minWidth="1167px">
              <QuotesListTableItem
                quote={this.props.appStore.repExampleStore.newQuote}
                showFullQuote={true}
                hideCheckbox
                disableProductNameClick
                trackingPage="RepExampleSelectQuoteForm__QuoteSelect"
                quoteButtonChild={
                  <button
                    type="button"
                    className="repExampleSelectQuoteForm__buttonSelected"
                    onClick={this.handleClear}
                  >
                    <SelectedLabel showRemove={true} />
                  </button>
                }
                isPersonalQuote={false}
              />
            </MediaQuery>
            <MediaQuery maxWidth="1166px">
              <QuotesListCardItem
                quote={this.props.appStore.repExampleStore.newQuote}
                showFullQuote={false}
                trackingPage="RepExampleSelectQuoteForm__QuoteSelect"
                disableProductNameClick
                quoteButtonChild={
                  <button
                    type="button"
                    className="repExampleSelectQuoteForm__buttonSelected repExampleSelectQuoteForm__buttonSelected--mobile"
                    onClick={this.handleClear}
                  >
                    <SelectedLabel showRemove={true} />
                  </button>
                }
                isPersonalQuote={false}
              />
            </MediaQuery>
          </div>
        ) : quotes.length > 0 ? (
          quotes.map((quote, i) => (
            <div key={'repquote' + i}>
              <MediaQuery minWidth="1167px">
                <QuotesListTableItem
                  quote={quote}
                  showFullQuote={false}
                  hideCheckbox
                  disableProductNameClick
                  proceedLabel={this.props.t('RepExampleSelectQuoteForm.select_quote')}
                  onProceed={this.handleSelect}
                  isPersonalQuote={false}
                  trackingPage="RepExampleSelectQuoteForm__QuoteSelect"
                />
              </MediaQuery>
              <MediaQuery maxWidth="1166px">
                <QuotesListCardItem
                  quote={quote}
                  showFullQuote={false}
                  disableProductNameClick
                  proceedLabel={this.props.t('RepExampleSelectQuoteForm.select_quote')}
                  onProceed={this.handleSelect}
                  isPersonalQuote={false}
                  trackingPage="RepExampleSelectQuoteForm__QuoteSelect"
                />
              </MediaQuery>
            </div>
          ))
        ) : (
          <div className="repExampleSelectQuoteForm__noResults">
            <InformationWarning>
              <Trans
                ns="RepExampleSettings"
                i18nKey="RepExampleSelectQuoteForm.sorry_we_are_unable_to_return_any_quotes_for_this_vehicle_based_on_the_parameters_entered"
              />
            </InformationWarning>
          </div>
        )}

        <div className="repExampleVehiclePriceForm__repButton">
          <div className="repExampleVehiclePriceForm__repButtonLeft">
            <Button type="button" buttonStyle="cancel" onClick={this.props.handleCancel}>
              {this.props.t('RepExampleSelectQuoteForm.go_back')}
            </Button>
          </div>
          {this.props.appStore.repExampleStore.newQuote && this.props.appStore.repExampleStore.newQuote.QuoteId && (
            <div className="repExampleVehiclePriceForm__repButtonRight">
              <Button
                type="submit"
                buttonStyle="primary"
                isLoading={isLoading}
                hasError={hasError}
                onClick={this.handleSubmit}
              >
                {this.props.t('RepExampleSelectQuoteForm.set_rep_example')}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

RepExampleSelectQuoteForm.propTypes = {
  appStore: PropTypes.object.isRequired,
  dealershipId: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};
export default withTranslation('RepExampleSettings')(inject(['appStore'])(observer(RepExampleSelectQuoteForm)));
