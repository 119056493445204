import { useMutation } from '@tanstack/react-query';
import {
  getCloseEsign,
  updateStellantisSubmissionInfo,
  getStellantisEsign,
  getTandemEsign
} from '../../../api/applicationStatus';
import classnames from 'classnames';
import Alert from '../../Common/Alert';
import AlertCard from '../../Common/AlertCard/AlertCard';
import Button from '../../Common/Button/Button';
import DateInput from '../../Common/Form/DateInput';
import Icon from '../../Common/Icon/Icon';
import InformationWarning from '../../Common/InformationWarning';
import LoadingSpinner from '../../Common/Loading/LoadingSpinner';
import useValidator from '../../../hooks/useValidator';
import { ReactNode, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import LenderDocumentLink from './LenderDocumentLink';
import moment from 'moment';
import { VehicleCondition } from '~/types/vehicle';

// TODO: Refactor the below types and use the ones defined
// in the ~/types/application.ts file
// and in the ~/src/components/QuoteCard/types.ts file
type DocumentLink = {
  DocumentTitle: string;
  DocumentLink: string;
};

type Agreement = {
  iVendiAgreementNumber: string;
  LenderAgreementNumber: string;
};

type ValueAddedProduct = {
  Description: string;
  Id: string;
  Name: string;
  Price: number;
  ProductTypeCode: number;
  ProductTypeText: string;
  TaxTypeCode: number;
};

type ESignDetails = {
  eSignDetails: {
    IvendiAgreementNumber: string;
    LenderAgreementNumber: string;
    DealershipId: string;
    IsDistanceSale: boolean;
  };
};

export type Application = {
  Status: string;
  Agreements: Agreement[];
  BusinessUse: boolean;
  Arranged?: { DistanceSale: boolean };

  Quote: {
    FunderCode: string;
    FunderName: string;
    VehiclePrice: number;
    VehicleClass: string;
    VehicleCapId: string;
    VehicleCapCode: string;
    VehicleVrm: string;
    VehicleMileage: string;
    VatQualifying: boolean;
    VatIncluded: boolean;
    ValueAddedProducts: ValueAddedProduct[];
    CashPrice: string;
    Condition: VehicleCondition;
    PlatformMeta: {
      VehiclePrice: number;
      CashPrice: number;
      BasicPrice: number;
    };
  };
  DeliveryDate: string;
  DealershipId: string;
  // ApplicantId: string;
  DocumentLinkReadModels: DocumentLink[];
};

type ModalContentsProps = {
  application: Application;
};
export const ProceedAndRequestModalContents = ({ application }: ModalContentsProps) => {
  const hasBlackhorseDocs =
    (application.Status === 'Accepted' || application.Status === 'ConditionalAccept') &&
    application.Quote.FunderCode === 'BLA' &&
    application.DocumentLinkReadModels &&
    application.DocumentLinkReadModels.length > 0;

  const hasCloseDocs =
    (application.Status === 'Accepted' || application.Status === 'ConditionalAccept') &&
    application.Quote.FunderCode === 'CLO' &&
    application.DocumentLinkReadModels &&
    application.DocumentLinkReadModels.length > 0;

  const hasStellantisDocs =
    (application.Status === 'Accepted' || application.Status === 'ConditionalAccept') &&
    application.Quote.FunderCode === 'STE' &&
    application.Agreements &&
    application.Agreements.length > 0;

  if (hasBlackhorseDocs) {
    return <ProceedAndRequestPaperworkBlackhorseContent documentLinks={application.DocumentLinkReadModels} />;
  }

  if (hasCloseDocs) {
    return <ProceedAndRequestPaperworkCLOContent documentLinks={application.DocumentLinkReadModels} />;
  }

  if (hasStellantisDocs) {
    return <ProceedAndRequestPaperworkStellantisContent application={application} />;
  }

  return (
    <ProceedAndRequestPaperworkContent
      funderName={application.Quote.FunderName}
      funderCode={application.Quote.FunderCode}
    />
  );
};

export const SubmitEsignRequest = ({
  eSignDetails,
  funderCode
}: {
  eSignDetails: ESignDetails;
  funderCode: string;
}) => {
  const { t } = useTranslation('Application');

  const [ESignUrl, setESignUrl] = useState('');
  const [errorStatus, setErrorStatus] = useState(0);

  const paragraph = (
    <Trans
      ns="Application"
      i18nKey={
        funderCode === 'STE'
          ? 'ApplicationStatus.an_electronic_signature_is_required'
          : 'ApplicationStatus.the_lender_has_accepted_the_application'
      }
      components={{
        br: <br />
      }}
    />
  );

  const errorTitle = t('ApplicationStatus.please_resubmit_the_request', {
    errorStatus: errorStatus
  });
  const successText =
    funderCode === 'STE'
      ? t('ApplicationStatus.if_you_need_to_resend')
      : t('ApplicationStatus.the_lender_will_now_begin');
  const errorParagraph = t('ApplicationStatus.something_went_wrong_please_try_again');

  const eSignLender = funderCode === 'STE' ? getStellantisEsign : getTandemEsign;

  const { isLoading, isError, isSuccess, mutate } = useMutation(eSignLender, {
    onSuccess: (result) => {
      setESignUrl(result.ESignUrl);
    },
    onError: (error) => {
      setErrorStatus((error as { status: number }).status);
    }
  });

  return (
    <div className="applicationStatus__modal">
      <div className="applicationStatus__modal--title">{t('ApplicationStatus.e_sign')}</div>
      <div className="applicationStatus__modal--text" />
      <div className="applicationStatus__infoMessage">
        {isError ? (
          <AlertCard iconName="alert" title={errorTitle} paragraph={errorParagraph} />
        ) : (
          <AlertCard
            iconName="information"
            title={t('ApplicationStatus.important_information')}
            paragraph={paragraph}
          />
        )}
      </div>
      {isSuccess && funderCode === 'STE' ? (
        <a href={ESignUrl} target="_blank" rel="noreferrer">
          {successText}
        </a>
      ) : (
        isSuccess && <div>{successText}</div>
      )}
      <Button
        className="applicationStatus__form--submit-btn"
        buttonStyle="primary"
        onClick={() => {
          mutate(eSignDetails);
        }}
        disabled={isLoading}
      >
        {isLoading ? <LoadingSpinner size="small" /> : t('ApplicationStatus.proceed')}
      </Button>
    </div>
  );
};

export const getDocument = (documentLinks: DocumentLink[], documentTitle: string): DocumentLink | undefined =>
  documentLinks.find((doc) => doc.DocumentTitle === documentTitle);

type WrapperWithTitleProps = {
  children: ReactNode;
  subTitle?: ReactNode;
};
export const WrapperWithTitle = ({ children, subTitle }: WrapperWithTitleProps) => {
  const { t } = useTranslation('Application');
  return (
    <div className="applicationStatus__modal">
      <div className="applicationStatus__modal--title">{t('ApplicationStatus.agreement_documents')}</div>
      <div className="applicationStatus__modal--text">{subTitle}</div>
      {children}
    </div>
  );
};
type PaperworkContentProps = {
  funderName: string;
  funderCode: string;
};
export const ProceedAndRequestPaperworkContent = ({ funderName, funderCode }: PaperworkContentProps) => {
  const { t } = useTranslation('Application');
  const shouldShowCreationFinanceCopy = funderCode === 'CRE';
  return (
    <div className="applicationStatus__modal">
      {shouldShowCreationFinanceCopy ? (
        <div>
          {t(
            'ApplicationStatus.please_check_your_email_creation_finance_will_have_sent_you_a_system_link_that_will_allow_you_to_access_this_proposal_and_progress_with_contract_document_signing'
          )}
        </div>
      ) : (
        <div>
          {t(
            'ApplicationStatus.please_check_your_email_will_have_automatically_dispatched_the_customer_and_dealer_acceptance_packs_to_your_dealership_email_address',
            {
              FunderName: funderName
            }
          )}
        </div>
      )}
    </div>
  );
};

type CLOContentProps = {
  documentLinks: DocumentLink[];
};

export const ProceedAndRequestPaperworkCLOContent = ({ documentLinks }: CLOContentProps) => {
  const { t } = useTranslation('Application');
  const dealerEsignUrl = getDocument(documentLinks, 'DealerEsignAgreement')?.DocumentLink;
  const customerEsignUrl = getDocument(documentLinks, 'ApplicantEsignAgreement')?.DocumentLink;
  const proposalReplyReadModel = getDocument(documentLinks, 'PROPOSALREPLY')?.DocumentLink;
  const agreementReadModel = getDocument(documentLinks, 'AGREEMENT')?.DocumentLink;

  const {
    error: closeDocError,
    data: { Url: blockedPopupUrl, ErrorMessage: closeDocErrorMessage } = {},
    isLoading,
    mutate
  } = useMutation(
    (url: string) =>
      getCloseEsign(url)
        .then((response) => response.Model)
        .catch(() => {
          return t('ApplicationStatus.an_error_occurred');
        }),
    {
      onSuccess(data) {
        if (data.Url) {
          window.open(data.Url, '_blank');
        }
      }
    }
  );

  const errorMessage = closeDocErrorMessage || closeDocError;

  return (
    <WrapperWithTitle
      subTitle={t(
        'ApplicationStatus.please_view_and_complete_the_dealer_document_at_1_ahead_of_using_any_other_document_presented_on_this_page_the_e_sign_solution_is_meant_for_customer_use_to_be_completed_in_the_showroom_please_allow_access_to_your_keyboard_mouse_and_screen'
      )}
    >
      {errorMessage && (
        <div className="applicationStatus__modal--error">
          <Alert>{errorMessage}</Alert>
        </div>
      )}

      {blockedPopupUrl && (
        <div className="applicationStatus__modal--popups">
          <InformationWarning>
            <Trans
              ns="Application"
              i18nKey="ApplicationStatus.if_the_esign_window_did_not_open_enable_pop_ups_for_this_site_or_find_it_here_valid_for_5_mins"
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a href={blockedPopupUrl} target="_blank" rel="noopener noreferrer" />
                )
              }}
            />
          </InformationWarning>
        </div>
      )}

      <LenderDocumentLink displayTitle={t('ApplicationStatus.1_agreement_document')}>
        <>
          <Trans
            ns="Application"
            i18nKey="ApplicationStatus.view_complete_dealer_declaration_document"
            components={{
              button: (
                <button
                  className={classnames(
                    'applicationStatus__esignButton',
                    !dealerEsignUrl && 'applicationStatus__esignButton--completed'
                  )}
                  onClick={() => {
                    if (dealerEsignUrl) mutate(dealerEsignUrl);
                  }}
                  disabled={!dealerEsignUrl}
                />
              )
            }}
          />
          {!customerEsignUrl && (
            <div className="applicationStatus__esignCompletedTick">
              <Icon name="tick" testId="dealer-esign-document-done" />
            </div>
          )}
          {isLoading && (
            <div className="applicationStatus__esignLoadingSpinner">
              <LoadingSpinner size="small" />
            </div>
          )}
        </>
      </LenderDocumentLink>
      <LenderDocumentLink displayTitle={t('ApplicationStatus.2_agreement_document')}>
        <>
          <Trans
            ns="Application"
            i18nKey="ApplicationStatus.e_sign_link_for_use_within_the_showroom_customer_agreement"
            components={{
              button: (
                <button
                  className={classnames(
                    'applicationStatus__esignButton',
                    !customerEsignUrl && 'applicationStatus__esignButton--completed'
                  )}
                  onClick={() => {
                    if (customerEsignUrl) mutate(customerEsignUrl);
                  }}
                  disabled={!customerEsignUrl}
                />
              )
            }}
          />

          {!customerEsignUrl && (
            <div className="applicationStatus__esignCompletedTick">
              <Icon name="tick" testId="customer-esign-document-done" />
            </div>
          )}
          {isLoading && (
            <div className="applicationStatus__esignLoadingSpinner">
              <LoadingSpinner size="small" />
            </div>
          )}
        </>
      </LenderDocumentLink>

      <LenderDocumentLink displayTitle={t('ApplicationStatus.3_agreement_document')}>
        {proposalReplyReadModel ? (
          <Trans
            ns="Application"
            i18nKey="ApplicationStatus.proposal_reply_doc_summary_of_deal_summary_document"
            components={{
              a: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a href={proposalReplyReadModel ?? '#'} target="_blank" rel="noopener noreferrer" />
              )
            }}
          />
        ) : (
          <>{t('ApplicationStatus.awaiting_document')}</>
        )}
      </LenderDocumentLink>

      <LenderDocumentLink displayTitle={t('ApplicationStatus.4_alternative_agreement_document')}>
        {agreementReadModel ? (
          <Trans
            ns="Application"
            i18nKey="ApplicationStatus.to_be_used_as_required_wet_sign_pdf_agreement"
            components={{
              a: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a href={agreementReadModel ?? '#'} target="_blank" rel="noopener noreferrer" />
              )
            }}
          />
        ) : (
          <>{t('ApplicationStatus.awaiting_document')}</>
        )}
      </LenderDocumentLink>

      <div className="applicationStatus__documentExpiryWarning">
        <InformationWarning>
          {t(
            'ApplicationStatus.if_any_of_the_above_links_expire_you_can_use_the_update_decision_action_to_request_new_ones'
          )}
        </InformationWarning>
      </div>
    </WrapperWithTitle>
  );
};

type BlackhorseContentProps = {
  documentLinks: DocumentLink[];
};
export const ProceedAndRequestPaperworkBlackhorseContent = ({ documentLinks }: BlackhorseContentProps) => {
  const { t } = useTranslation('Application');
  const pceDoc = getDocument(documentLinks, 'PCE');
  const pcciDoc = getDocument(documentLinks, 'PCCI');
  return (
    <WrapperWithTitle>
      <div className="applicationStatus__modal--links">
        {pceDoc && (
          <div className="applicationStatus__modal--link">
            {t('ApplicationStatus.view')}{' '}
            <a href={pceDoc.DocumentLink} target="_blank" rel="noopener noreferrer">
              {pceDoc.DocumentTitle}
            </a>
          </div>
        )}
        <div className="applicationStatus__modal--text">
          <div className="applicationStatus__modal--redText">{t('ApplicationStatus.mandatory_document')}</div>{' '}
          {t(
            'ApplicationStatus.print_and_hand_this_document_to_the_customer_as_an_alternative_this_document_can_be_sent_by_e_mail'
          )}
        </div>
      </div>
      <div className="applicationStatus__modal--links">
        {pcciDoc && (
          <div className="applicationStatus__modal--link">
            {t('ApplicationStatus.view')}{' '}
            <a href={pcciDoc.DocumentLink} target="_blank" rel="noopener noreferrer">
              {pcciDoc.DocumentTitle}
            </a>
          </div>
        )}
        <div className="applicationStatus__modal--text">
          {t('ApplicationStatus.this_document_should_not_be_sent_by_e_mail_print_and_hand_a_copy_over_to_the_customer')}
        </div>
      </div>
      <div className="applicationStatus__modal--links">
        <div className="applicationStatus__modal--subtitle">
          {t('ApplicationStatus.four_document_set_required_for_delivery_handover')}
        </div>
        {documentLinks.map((doc) => {
          if (doc.DocumentTitle !== 'PCE' && doc.DocumentTitle !== 'PCCI') {
            return (
              <div className="applicationStatus__modal--link" key={doc.DocumentLink}>
                {t('ApplicationStatus.view')}{' '}
                <a href={doc.DocumentLink} target="_blank" rel="noopener noreferrer">
                  {doc.DocumentTitle}
                </a>
              </div>
            );
          }
          return null;
        })}
      </div>
    </WrapperWithTitle>
  );
};

type StellantisContentProps = {
  application: Application;
};

export const ProceedAndRequestPaperworkStellantisContent = ({ application }: StellantisContentProps) => {
  // TODO: Make sure the error message makes sense or if it needs changing
  // TODO: See if Chris / Faiza want to add another bullet point to the info message
  // TODO: Make sure there are no console logs left

  const { DeliveryDate: lastSubmittedDate } = application;

  const { t } = useTranslation('Application');
  const [date, setDate] = useState(lastSubmittedDate ? moment(lastSubmittedDate).format('DD/MM/YYYY') : '');
  const [success, setSuccess] = useState(false);
  const { isLoading, isError, error, mutate } = useMutation(
    (date: string) => updateStellantisSubmissionInfo(application, date),
    {
      onSuccess() {
        setSuccess(true);
      }
    }
  );

  const { validate, errors } = useValidator({
    date: 'required, date, dateNotPast'
  });

  if (isLoading) {
    return (
      <WrapperWithTitle>
        <div className="applicationStatus__esignLoadingSpinner">
          <LoadingSpinner size="normal" />
        </div>
      </WrapperWithTitle>
    );
  }
  if (isError) {
    console.log(isError, error, 'isError');
    return (
      <WrapperWithTitle>
        <AlertCard iconName="alert" paragraph={t('ApplicationStatus.an_error_occurred')} />
      </WrapperWithTitle>
    );
  }
  if (success) {
    return (
      <WrapperWithTitle>
        <div className="applicationStatus__successMessage">
          <AlertCard
            iconName="tick"
            paragraph={
              <Trans
                ns="Application"
                i18nKey="ApplicationStatus.the_documents_have_been_requested_documents_usually_appear_in_your_proposal_history_please_come_back_in_5_to_10_minutes"
                components={{
                  strong: <strong />
                }}
              />
            }
          />
        </div>
      </WrapperWithTitle>
    );
  }
  return (
    <WrapperWithTitle>
      <div className="applicationStatus__infoMessage">
        <AlertCard iconName="information" title={t('ApplicationStatus.important_information')}>
          <ul>
            <li>
              {t(
                'ApplicationStatus.when_submitted_customer_delivery_date_the_agreement_document_link_will_appear_in_proposal_history'
              )}
            </li>
            <li>
              {t(
                'ApplicationStatus.if_the_link_has_expired_or_the_document_needs_refreshing_please_click_update_decision'
              )}
            </li>
            <li>{t('ApplicationStatus.if_the_delivery_date_has_changed_please_update_the_date_below_and_resubmit')}</li>
          </ul>
        </AlertCard>
      </div>
      <div className="applicationStatus__form">
        <form
          onSubmit={(e) => {
            e.preventDefault();

            const hasErrors = validate({ date });

            if (!hasErrors) {
              mutate(date);
            }
          }}
        >
          <label>
            {t('ApplicationStatus.customer_delivery_date')}
            <DateInput
              id="customer-delivery-date"
              value={date}
              onChange={(_id: string, value: string) => {
                validate({ date: value });
                setDate(value);
              }}
            />
            {errors?.date && <div className="applicationStatus__form--error">{errors.date.message}</div>}
          </label>
          <Button className="applicationStatus__form--submit-btn" buttonStyle="primary">
            {t('ApplicationStatus.submit')}
          </Button>
        </form>
      </div>
    </WrapperWithTitle>
  );
};

// submit application
// lender gives decision: Accepted - agreement number generated
// as application is accepted && agreement array is not empty:  display Request Paperwork tile
// use agreement number in request

//format of date sent?
