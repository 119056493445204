import React from 'react';
import PropTypes from 'prop-types';
import './generalCreditInformationCard.scss';

const GeneralCreditInformationCard = (props) => (
  <div className="generalCreditInformationCard">
    <div className="generalCreditInformationCard__title">{props.title}</div>
    <div>{props.children}</div>
    <div className="generalCreditInformationCard__subText">{props.subText}</div>
  </div>
);

GeneralCreditInformationCard.propTypes = {
  title: PropTypes.string,
  subText: PropTypes.string,
  children: PropTypes.node
};

export default GeneralCreditInformationCard;
