import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { inject } from 'mobx-react';

import PanelSection from '~Common/PanelSection';
import SelectInput from '~Common/Form/SelectInput';
import Button from '~Common/Button/Button';
import TextInputWrappingInput from '~Common/Form/TextInputWrappingInput';
import DateInput from '~Common/Form/DateInput';
import TextArea from '~Common/Form/TextArea';
import RadioButton from '~Common/Form/RadioButton';
import { getAuctions } from '~/api/auction';
import { isVan } from '../../../shared/helpers/transformTaxonomyData';
import { IconTooltip, NumberInput } from './components';

import styles from './stockVehicleFormFields.module.scss';
import Label from '~Common/Form/Label';
import { Controller, useFormContext } from 'react-hook-form';

import { RHFValidationWrapper, RHFValidationIcons } from './components/ReactHookForm';

const StockVehicleFormFields = ({ fieldEnabled, isFeedVehicle, appStore, onClick }) => {
  const { t } = useTranslation(['Stock', 'Common']);
  const { control, watch, register, formState, setValue } = useFormContext();
  const [auctionOptions, setAuctionsOptions] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);

  useEffect(() => {
    const searchOptions = async () => {
      setOptionsLoading(true);
      const auctions = await getAuctions();
      const options = auctions.map((option) => ({
        label: option.auctionName,
        value: option.id
      }));
      options.unshift({
        label: t('Options.none', { ns: 'Common' }),
        value: ''
      });
      setAuctionsOptions(options);
      setOptionsLoading(false);
    };
    searchOptions();
    // We want this to run once and only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   vrm and vin validation depends on each other and has to trigger together.
  register('vrm', { deps: 'vin' });
  register('vin', { deps: 'vrm' });
  const {
    countryCode,
    canAmendConsumption,
    canAmendEfficiency,
    canAmendEmissions,
    canEditVATRate,
    canFixIssues
  } = appStore.uiState;
  const showConsumption = canAmendConsumption === true;
  const showEfficiency = canAmendEfficiency === true;
  const showEmissions = canAmendEmissions === true;

  const displayKeyInfo = [
    'class',
    'make',
    'model',
    'derivative',
    'bodyStyle',
    'transmission',
    'fuelType',
    'doors',
    'seats',
    'drivingPosition'
  ].some((fieldName) => fieldEnabled(fieldName));

  const displayDisabledField = (fieldName) => {
    if (fieldName === 'vatStatus') {
      return fieldEnabled('vatStatus') || (isFeedVehicle && isVan(watch('class')));
    }
    return isFeedVehicle || fieldEnabled(fieldName);
  };

  const panelHeader = () => {
    if (isFeedVehicle && canFixIssues) {
      return (
        <div className={styles.feedVehiclePanelHeader}>
          {t('StockVehicleFormFields.key_information')}
          <div className={styles.feedVehiclePanelHeaderButton}>
            <Button onClick={onClick} type="button" buttonStyle="secondary" size="small">
              {t('StockVehicleFormFields.override_taxonomy')}
            </Button>
          </div>
        </div>
      );
    } else {
      return t('StockVehicleFormFields.key_information');
    }
  };

  const shouldDisplayValidValidation = ({ formState, fieldState }) => {
    return (fieldState.isTouched && !fieldState.invalid) || (formState.submitCount > 0 && !fieldState.invalid);
  };

  const shouldDisplayNumberInputValidation = ({ fieldState, formState }) => {
    return fieldState.isTouched || formState.submitCount > 0;
  };

  return (
    <>
      {(displayKeyInfo || isFeedVehicle) && (
        <PanelSection title={panelHeader()}>
          {displayDisabledField('class') && (
            <RHFValidationWrapper
              name="class"
              labelComponent={<Label htmlFor="class">{t('StockVehicleFormFields.class')}</Label>}
            >
              <Controller
                name="class"
                control={control}
                defaultValue={false}
                render={({ field, fieldState }) => (
                  <SelectInput
                    options={isFeedVehicle ? 'StockVehicleClassIncUnknown' : 'StockVehicleClass'}
                    id="class"
                    value={field.value}
                    onChange={(_name, value) => field.onChange(value)}
                    onBlur={field.onBlur}
                    emptyValue={false}
                    disabled={isFeedVehicle}
                    isValid={shouldDisplayValidValidation({ formState, fieldState })}
                  />
                )}
              />
            </RHFValidationWrapper>
          )}

          {displayDisabledField('make') && (
            <RHFValidationWrapper
              name="make"
              labelComponent={<Label htmlFor="make">{t('StockVehicleFormFields.make')}</Label>}
            >
              <Controller
                name="make"
                control={control}
                render={({ field, fieldState }) => (
                  <TextInputWrappingInput
                    id="make"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    disabled={isFeedVehicle}
                    isValid={shouldDisplayValidValidation({ formState, fieldState })}
                    isInvalid={fieldState.invalid}
                  />
                )}
              />
            </RHFValidationWrapper>
          )}

          {displayDisabledField('model') && (
            <RHFValidationWrapper
              name="model"
              labelComponent={<Label htmlFor="model">{t('StockVehicleFormFields.model')}</Label>}
            >
              <Controller
                name="model"
                control={control}
                render={({ field, fieldState }) => (
                  <TextInputWrappingInput
                    id="model"
                    onChange={field.onChange}
                    value={field.value}
                    disabled={isFeedVehicle}
                    onBlur={field.onBlur}
                    isValid={shouldDisplayValidValidation({ formState, fieldState })}
                    isInvalid={fieldState.invalid}
                  />
                )}
              />
            </RHFValidationWrapper>
          )}
          {displayDisabledField('derivative') && (
            <RHFValidationWrapper
              name="derivative"
              labelComponent={<Label htmlFor="derivative">{t('StockVehicleFormFields.derivative')}</Label>}
            >
              <Controller
                name="derivative"
                control={control}
                render={({ field, fieldState }) => (
                  <TextInputWrappingInput
                    id="derivative"
                    onChange={field.onChange}
                    value={field.value}
                    disabled={isFeedVehicle}
                    onBlur={field.onBlur}
                    isValid={shouldDisplayValidValidation({ formState, fieldState })}
                    isInvalid={fieldState.invalid}
                  />
                )}
              />
            </RHFValidationWrapper>
          )}
          {displayDisabledField('bodyStyle') && (
            <RHFValidationWrapper
              name="bodyStyle"
              labelComponent={<Label htmlFor="bodyStyle">{t('StockVehicleFormFields.body_style')}</Label>}
            >
              <Controller
                name="bodyStyle"
                control={control}
                render={({ field, fieldState }) => (
                  <TextInputWrappingInput
                    id="bodyStyle"
                    onChange={field.onChange}
                    value={field.value}
                    disabled={isFeedVehicle}
                    onBlur={field.onBlur}
                    isValid={shouldDisplayValidValidation({ formState, fieldState })}
                    isInvalid={fieldState.invalid}
                  />
                )}
              />
            </RHFValidationWrapper>
          )}
          {displayDisabledField('transmission') && (
            <RHFValidationWrapper
              name="transmission"
              labelComponent={<Label htmlFor="transmission">{t('StockVehicleFormFields.transmission')}</Label>}
            >
              <Controller
                name="transmission"
                control={control}
                render={({ field, fieldState }) => (
                  <TextInputWrappingInput
                    id="transmission"
                    onChange={field.onChange}
                    value={field.value}
                    disabled={isFeedVehicle}
                    onBlur={field.onBlur}
                    isValid={shouldDisplayValidValidation({ formState, fieldState })}
                    isInvalid={fieldState.invalid}
                  />
                )}
              />
            </RHFValidationWrapper>
          )}
          {displayDisabledField('fuelType') && (
            <RHFValidationWrapper
              name="fuelType"
              labelComponent={<Label htmlFor="fuelType">{t('StockVehicleFormFields.fuel_type')}</Label>}
            >
              <Controller
                name="fuelType"
                control={control}
                render={({ field, fieldState }) => (
                  <TextInputWrappingInput
                    id="fuelType"
                    onChange={field.onChange}
                    value={field.value}
                    disabled={isFeedVehicle}
                    onBlur={field.onBlur}
                    isValid={shouldDisplayValidValidation({ formState, fieldState })}
                    isInvalid={fieldState.invalid}
                  />
                )}
              />
            </RHFValidationWrapper>
          )}
          {displayDisabledField('doors') && (
            <RHFValidationWrapper
              name="doors"
              labelComponent={<Label htmlFor="doors">{t('StockVehicleFormFields.doors')}</Label>}
            >
              <Controller
                name="doors"
                control={control}
                render={({ field, fieldState }) => (
                  <NumberInput
                    id="doors"
                    onChange={field.onChange}
                    defaultValue={field.value}
                    disabled={isFeedVehicle}
                    intlConfig={{
                      locale: countryCode.toLowerCase()
                    }}
                    onBlur={field.onBlur}
                    isValid={!fieldState.invalid}
                    showError={shouldDisplayNumberInputValidation({ formState, fieldState })}
                  />
                )}
              />
            </RHFValidationWrapper>
          )}
          {displayDisabledField('seats') && (
            <RHFValidationWrapper
              name="seats"
              labelComponent={<Label htmlFor="seats">{t('StockVehicleFormFields.seats')}</Label>}
            >
              <Controller
                name="seats"
                control={control}
                render={({ field, fieldState }) => (
                  <NumberInput
                    id="seats"
                    onChange={field.onChange}
                    defaultValue={field.value?.toString()}
                    disabled={isFeedVehicle}
                    intlConfig={{
                      locale: countryCode.toLowerCase()
                    }}
                    onBlur={field.onBlur}
                    isValid={!fieldState.invalid}
                    showError={shouldDisplayNumberInputValidation({ formState, fieldState })}
                  />
                )}
              />
            </RHFValidationWrapper>
          )}
          {displayDisabledField('drivingPosition') && (
            <Controller
              name="drivingPosition"
              control={control}
              render={({ field }) => (
                <>
                  <h4>{t('StockVehicleFormFields.driving_position')}</h4>
                  <div className={styles.radioSwitch}>
                    <div className={styles.radioOption}>
                      <RadioButton
                        id="drivingPositiongL"
                        name="DrivingPositionL"
                        onChange={field.onChange}
                        value="LHD"
                        checked={field.value === 'LHD'}
                      >
                        {t('StockVehicleFormFields.left_hand_drive')}
                      </RadioButton>
                    </div>
                    <div className={styles.radioOption}>
                      <RadioButton
                        id="drivingPositiongR"
                        name="DrivingPositionR"
                        onChange={field.onChange}
                        value="RHD"
                        checked={!field.value || field.value === 'RHD'}
                      >
                        {t('StockVehicleFormFields.right_hand_drive')}
                      </RadioButton>
                    </div>
                  </div>
                </>
              )}
            />
          )}
        </PanelSection>
      )}

      <PanelSection title={t('StockVehicleFormFields.registration_history')}>
        {displayDisabledField('condition') && (
          <RHFValidationWrapper
            name="condition"
            labelComponent={<Label htmlFor="condition">{t('StockVehicleFormFields.condition')}</Label>}
          >
            <Controller
              name="condition"
              control={control}
              render={({ field, fieldState }) => (
                <SelectInput
                  emptyValue={false}
                  options="VehicleType"
                  id="condition"
                  onChange={(_name, value) => field.onChange(value)}
                  value={field.value}
                  disabled={isFeedVehicle}
                  onBlur={field.onBlur}
                  isValid={shouldDisplayValidValidation({ formState, fieldState })}
                />
              )}
            />
          </RHFValidationWrapper>
        )}
        {displayDisabledField('vrm') && (
          <RHFValidationWrapper
            name="vrm"
            labelComponent={<Label htmlFor="vrm">{t('StockVehicleFormFields.vrm')}</Label>}
          >
            <Controller
              name="vrm"
              control={control}
              render={({ field, fieldState }) => (
                <TextInputWrappingInput
                  id="vrm"
                  onChange={field.onChange}
                  value={field.value}
                  disabled={isFeedVehicle}
                  onBlur={field.onBlur}
                  isValid={shouldDisplayValidValidation({ formState, fieldState })}
                  isInvalid={fieldState.invalid}
                />
              )}
            />
          </RHFValidationWrapper>
        )}
        {displayDisabledField('vin') && (
          <RHFValidationWrapper
            name="vin"
            labelComponent={<Label htmlFor="vin">{t('StockVehicleFormFields.vin')}</Label>}
          >
            <Controller
              name="vin"
              control={control}
              render={({ field, fieldState }) => (
                <TextInputWrappingInput
                  id="vin"
                  onChange={field.onChange}
                  value={field.value}
                  disabled={isFeedVehicle}
                  onBlur={field.onBlur}
                  isValid={shouldDisplayValidValidation({ formState, fieldState })}
                  isInvalid={fieldState.invalid}
                />
              )}
            />
          </RHFValidationWrapper>
        )}
        {displayDisabledField('regDate') && (
          <RHFValidationWrapper
            name="regDate"
            labelComponent={<Label htmlFor="regDate">{t('StockVehicleFormFields.reg_date')}</Label>}
          >
            <Controller
              name="regDate"
              control={control}
              render={({ field, fieldState }) => (
                <DateInput
                  value={field.value}
                  id="regDate"
                  onChange={(_, value) => field.onChange(value)}
                  disabled={isFeedVehicle}
                  onBlur={field.onBlur}
                  isValid={shouldDisplayValidValidation({ formState, fieldState })}
                  isInvalid={fieldState.invalid}
                />
              )}
            />
          </RHFValidationWrapper>
        )}
        {displayDisabledField('keepers') && (
          <RHFValidationWrapper
            name="keepers"
            labelComponent={<Label htmlFor="keepers">{t('StockVehicleFormFields.number_of_keepers')}</Label>}
          >
            <Controller
              name="keepers"
              control={control}
              render={({ field, fieldState }) => (
                <NumberInput
                  id="keepers"
                  onChange={field.onChange}
                  defaultValue={field.value}
                  disabled={isFeedVehicle}
                  intlConfig={{
                    locale: countryCode.toLowerCase()
                  }}
                  onBlur={field.onBlur}
                  isValid={!fieldState.invalid}
                  showError={shouldDisplayNumberInputValidation({ formState, fieldState })}
                  maxLength={3}
                />
              )}
            />
          </RHFValidationWrapper>
        )}
      </PanelSection>

      <PanelSection title={t('StockVehicleFormFields.advert_content')}>
        {displayDisabledField('mileage') && (
          <RHFValidationWrapper
            name="mileage"
            labelComponent={<Label htmlFor="mileage">{t('StockVehicleFormFields.mileage')}</Label>}
          >
            <Controller
              name="mileage"
              control={control}
              render={({ field, fieldState }) => (
                <NumberInput
                  id="mileage"
                  onChange={field.onChange}
                  defaultValue={field.value}
                  disabled={isFeedVehicle}
                  intlConfig={{
                    locale: countryCode.toLowerCase()
                  }}
                  onBlur={field.onBlur}
                  isValid={!fieldState.invalid}
                  showError={shouldDisplayNumberInputValidation({ formState, fieldState })}
                />
              )}
            />
          </RHFValidationWrapper>
        )}
        {displayDisabledField('mileageUnit') && (
          <RHFValidationWrapper
            name="mileageUnit"
            labelComponent={
              <Label className={styles.label} htmlFor="mileageUnit">
                {t('StockVehicleFormFields.mileage_unit')}{' '}
                <IconTooltip>{t('StockVehicleFormFields.only_applies_to_broadcasting')}</IconTooltip>
              </Label>
            }
          >
            <Controller
              name="mileageUnit"
              control={control}
              render={({ field, fieldState }) => (
                <SelectInput
                  options="MileageUnits"
                  id="mileageUnit"
                  value={field.value}
                  onChange={(_name, value) => field.onChange(value)}
                  emptyValue={false}
                  onBlur={field.onBlur}
                  isValid={shouldDisplayValidValidation({ formState, fieldState })}
                  isInvalid={fieldState.invalid}
                />
              )}
            />
          </RHFValidationWrapper>
        )}
        {displayDisabledField('colour') && (
          <RHFValidationWrapper
            name="colour"
            labelComponent={<Label htmlFor="colour">{t('StockVehicleFormFields.colour')}</Label>}
          >
            <Controller
              name="colour"
              control={control}
              render={({ field, fieldState }) => (
                <TextInputWrappingInput
                  id="colour"
                  onChange={field.onChange}
                  value={field.value}
                  disabled={isFeedVehicle}
                  onBlur={field.onBlur}
                  isValid={shouldDisplayValidValidation({ formState, fieldState })}
                  isInvalid={fieldState.invalid}
                />
              )}
            />
          </RHFValidationWrapper>
        )}
        {displayDisabledField('attentionGrabber') && (
          <RHFValidationWrapper
            name="attentionGrabber"
            labelComponent={<Label htmlFor="attentionGrabber">{t('StockVehicleFormFields.attention_grabber')}</Label>}
          >
            <Controller
              name="attentionGrabber"
              control={control}
              render={({ field, fieldState }) => (
                <TextInputWrappingInput
                  id="attentionGrabber"
                  onChange={field.onChange}
                  value={field.value}
                  onBlur={field.onBlur}
                  isValid={shouldDisplayValidValidation({ formState, fieldState })}
                  isInvalid={fieldState.invalid}
                />
              )}
            />
          </RHFValidationWrapper>
        )}
        {displayDisabledField('description') && (
          <RHFValidationWrapper
            name="description"
            labelComponent={<Label htmlFor="description">{t('StockVehicleFormFields.description')}</Label>}
          >
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <TextArea
                  id="description"
                  onChange={field.onChange}
                  value={field.value}
                  onBlur={field.onBlur}
                  isValid={shouldDisplayValidValidation({ formState, fieldState })}
                  isInvalid={fieldState.invalid}
                />
              )}
            />
          </RHFValidationWrapper>
        )}
        {displayDisabledField('options') && (
          <RHFValidationWrapper
            name="options"
            labelComponent={<Label htmlFor="options">{t('StockVehicleFormFields.options')}</Label>}
          >
            <Controller
              name="options"
              control={control}
              render={({ field, fieldState }) => (
                <TextArea
                  id="options"
                  onChange={field.onChange}
                  value={field.value}
                  onBlur={field.onBlur}
                  isValid={shouldDisplayValidValidation({ formState, fieldState })}
                  isInvalid={fieldState.invalid}
                />
              )}
            />
          </RHFValidationWrapper>
        )}
        {displayDisabledField('price') && (
          <RHFValidationWrapper
            name="price"
            labelComponent={<Label htmlFor="price">{t('StockVehicleFormFields.price')}</Label>}
          >
            <Controller
              name="price"
              control={control}
              render={({ field, fieldState }) => (
                <NumberInput
                  id="price"
                  type="currency"
                  onChange={(_value, _name, { float }) => field.onChange(float)}
                  defaultValue={field.value}
                  maxLength={9}
                  disabled={isFeedVehicle}
                  allowNegativeValue={false}
                  intlConfig={{
                    locale: countryCode.toLowerCase(),
                    currency: watch('currency')
                  }}
                  onBlur={field.onBlur}
                  isValid={!fieldState.invalid}
                  showError={shouldDisplayNumberInputValidation({ formState, fieldState })}
                />
              )}
            />
          </RHFValidationWrapper>
        )}

        {displayDisabledField('includesVat') && canEditVATRate && (
          <div className={styles.includesVat}>
            <Controller
              name="includesVat"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <Label htmlFor="includesVat">{t('StockVehicleFormFields.price_includes_vat')}</Label>
                  <div className={styles.radioSwitch}>
                    <div className={styles.radioOption}>
                      <RadioButton
                        id="includesVatYes"
                        name="includesVatYes"
                        value="true"
                        onChange={field.onChange}
                        checked={field.value === 'true'}
                        disabled={isFeedVehicle}
                      >
                        {t('StockListFilter.yes')}
                      </RadioButton>
                    </div>
                    <div className={styles.radioOption}>
                      <RadioButton
                        id="includesVatNo"
                        name="includesVatNo"
                        value="false"
                        onChange={(value) => {
                          field.onChange(value);
                          /* remove the vat rate from the submitForm when includesVat is not set */
                          setValue('vatRate', null);
                        }}
                        checked={field.value === 'false'}
                        disabled={isFeedVehicle}
                      >
                        {t('StockListFilter.no')}
                      </RadioButton>
                    </div>

                    {field.value === 'true' && (
                      <>
                        {/* set default vatRate when input is rendered  */}
                        {setValue('vatRate', '19')}
                        <Controller
                          name="vatRate"
                          control={control}
                          render={({ field, fieldState }) => (
                            <div className={styles.VATRate}>
                              <NumberInput
                                id="vatRate"
                                name="vatRate"
                                onChange={field.onChange}
                                value={field.value}
                                disabled={isFeedVehicle}
                                decimalsLimit={2}
                                maxLength={5}
                                allowNegativeValue={false}
                                isValid={!fieldState.invalid}
                                showError={shouldDisplayNumberInputValidation({ formState, fieldState })}
                                intlConfig={{
                                  locale: countryCode.toLowerCase()
                                }}
                              />
                              <div className={styles.percentageLabel}>%</div>
                            </div>
                          )}
                        />
                      </>
                    )}
                    <div className={styles.vatRateValidationIcon}>
                      <RHFValidationIcons name="includesVat" />
                    </div>
                  </div>
                  {fieldState.error && <span className={styles.errorMessage}>{fieldState.error.message}</span>}
                </>
              )}
            />
          </div>
        )}

        {displayDisabledField('currency') && (
          <RHFValidationWrapper
            name="currency"
            labelComponent={
              <Label className={styles.label} htmlFor="currency">
                {t('StockVehicleFormFields.currency')}
                <IconTooltip>{t('StockVehicleFormFields.only_applies_to_broadcasting')}</IconTooltip>
              </Label>
            }
          >
            <Controller
              name="currency"
              control={control}
              render={({ field, fieldState }) => (
                <SelectInput
                  options={'Currency'}
                  id="currency"
                  value={field.value}
                  onChange={(_name, value) => field.onChange(value)}
                  emptyValue={false}
                  onBlur={field.onBlur}
                  isValid={shouldDisplayValidValidation({ formState, fieldState })}
                  isInvalid={fieldState.invalid}
                />
              )}
            />
          </RHFValidationWrapper>
        )}

        {displayDisabledField('vatStatus') && countryCode.toLowerCase() !== 'de' && (
          <Controller
            name="vatStatus"
            control={control}
            render={({ field }) => (
              <div className={styles.radioSwitch}>
                <div className={styles.radioOption}>
                  <RadioButton
                    id="vatStatusInc"
                    name="vatStatus"
                    onChange={field.onChange}
                    value="VAT inc"
                    checked={field.value === 'VAT inc'}
                    disabled={isFeedVehicle}
                  >
                    {t('StockVehicleFormFields.vat_inc')}
                  </RadioButton>
                </div>
                <div className={styles.radioOption}>
                  <RadioButton
                    id="vatStatusEx"
                    name="VatQualifying"
                    onChange={field.onChange}
                    value="VAT Not inc"
                    checked={field.value === 'VAT Not inc'}
                    disabled={isFeedVehicle}
                  >
                    {t('StockVehicleFormFields.vat_not_inc')}
                  </RadioButton>
                </div>
              </div>
            )}
          />
        )}

        {displayDisabledField('consumptionInner') && showConsumption && (
          <RHFValidationWrapper
            name="consumptionInner"
            labelComponent={
              <Label htmlFor="consumptionInner" className={styles.label}>
                {t('StockVehicleFormFields.inner_consumption')}
                <IconTooltip> {t('StockVehicleFormFields.per_100km')}</IconTooltip>
              </Label>
            }
          >
            <Controller
              name="consumptionInner"
              control={control}
              render={({ field, fieldState }) => (
                <NumberInput
                  id="consumptionInner"
                  onChange={(_value, _name, { float }) => field.onChange(float)}
                  defaultValue={field.value}
                  disabled={isFeedVehicle}
                  decimalsLimit={1}
                  intlConfig={{
                    locale: countryCode.toLowerCase()
                  }}
                  onBlur={field.onBlur}
                  isValid={!fieldState.invalid}
                  showError={shouldDisplayNumberInputValidation({ formState, fieldState })}
                />
              )}
            />
          </RHFValidationWrapper>
        )}
        {displayDisabledField('consumptionOuter') && showConsumption && (
          <RHFValidationWrapper
            name="consumptionOuter"
            labelComponent={
              <Label htmlFor="consumptionOuter" className={styles.label}>
                {t('StockVehicleFormFields.outer_consumption')}
                <IconTooltip>{t('StockVehicleFormFields.per_100km')}</IconTooltip>
              </Label>
            }
          >
            <Controller
              name="consumptionOuter"
              control={control}
              render={({ field, fieldState }) => (
                <NumberInput
                  id="consumptionOuter"
                  onChange={(_value, _name, { float }) => field.onChange(float)}
                  defaultValue={field.value}
                  disabled={isFeedVehicle}
                  decimalsLimit={1}
                  intlConfig={{
                    locale: countryCode.toLowerCase()
                  }}
                  onBlur={field.onBlur}
                  isValid={!fieldState.invalid}
                  showError={shouldDisplayNumberInputValidation({ formState, fieldState })}
                />
              )}
            />
          </RHFValidationWrapper>
        )}
        {displayDisabledField('consumptionCombined') && showConsumption && (
          <RHFValidationWrapper
            name="consumptionCombined"
            labelComponent={
              <Label htmlFor="consumptionCombined" className={styles.label}>
                {t('StockVehicleFormFields.combined_consumption')}
                <IconTooltip>{t('StockVehicleFormFields.per_100km')}</IconTooltip>
              </Label>
            }
          >
            <Controller
              name="consumptionCombined"
              control={control}
              render={({ field, fieldState }) => (
                <NumberInput
                  id="consumptionCombined"
                  onChange={(_value, _name, { float }) => field.onChange(float)}
                  defaultValue={field.value}
                  disabled={isFeedVehicle}
                  decimalsLimit={1}
                  intlConfig={{
                    locale: countryCode.toLowerCase()
                  }}
                  onBlur={field.onBlur}
                  isValid={!fieldState.invalid}
                  showError={shouldDisplayNumberInputValidation({ formState, fieldState })}
                />
              )}
            />
          </RHFValidationWrapper>
        )}
        {displayDisabledField('emission') && showEmissions && (
          <RHFValidationWrapper
            name="emission"
            labelComponent={
              <Label htmlFor="emission" className={styles.label}>
                CO2-Emissionen (komb)
                <IconTooltip>g/km</IconTooltip>
              </Label>
            }
          >
            <Controller
              name="emission"
              control={control}
              render={({ field, fieldState }) => (
                <NumberInput
                  id="emission"
                  onChange={(_value, _name, { float }) => field.onChange(float)}
                  defaultValue={field.value}
                  disabled={isFeedVehicle}
                  intlConfig={{
                    locale: countryCode.toLowerCase()
                  }}
                  onBlur={field.onBlur}
                  isValid={!fieldState.invalid}
                  showError={shouldDisplayNumberInputValidation({ formState, fieldState })}
                />
              )}
            />
          </RHFValidationWrapper>
        )}
        {displayDisabledField('energyEfficiencyClass') && showEfficiency && (
          <RHFValidationWrapper
            name="energyEfficiencyClass"
            labelComponent={<Label htmlFor="EnergyEfficiencyClass">CO2-Effizienz</Label>}
          >
            <Controller
              name="energyEfficiencyClass"
              control={control}
              render={({ field, fieldState }) => (
                <SelectInput
                  emptyValue={true}
                  options="EnergyEfficiencyClass"
                  id="energyEfficiencyClass"
                  onChange={(_name, value) => field.onChange(value)}
                  value={field.value}
                  disabled={isFeedVehicle}
                  onBlur={field.onBlur}
                  isValid={shouldDisplayValidValidation({ formState, fieldState })}
                  isInvalid={fieldState.invalid}
                  showError={fieldState.invalid}
                />
              )}
            />
          </RHFValidationWrapper>
        )}
      </PanelSection>

      <PanelSection title={t('StockVehicleFormFields.misc')}>
        {displayDisabledField('dealerReference') && (
          <RHFValidationWrapper
            name="dealerReference"
            labelComponent={<Label htmlFor="dealerReference">{t('StockVehicleFormFields.dealer_ref')}</Label>}
          >
            <Controller
              name="dealerReference"
              control={control}
              render={({ field, fieldState }) => (
                <TextInputWrappingInput
                  id="dealerReference"
                  onChange={field.onChange}
                  value={field.value}
                  onBlur={field.onBlur}
                  isValid={shouldDisplayValidValidation({ formState, fieldState })}
                  isInvalid={fieldState.invalid}
                />
              )}
            />
          </RHFValidationWrapper>
        )}

        {displayDisabledField('auctionId') && (
          <RHFValidationWrapper
            name="auctionId"
            labelComponent={<Label htmlFor="auctionId">{t('StockVehicleFormFields.auction_id')}</Label>}
          >
            <Controller
              name="auctionId"
              control={control}
              render={({ field, fieldState }) => (
                <Select
                  className={styles.selectInput}
                  id="auctionId"
                  name="auctionId"
                  options={auctionOptions}
                  onBlur={field.onBlur}
                  value={auctionOptions.find((option) => option.value === field.value) || ''}
                  isLoading={optionsLoading}
                  onChange={(value) => field.onChange(value ? value.value : null)}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: fieldState.invalid
                        ? '#e66c6c'
                        : shouldDisplayValidValidation({ formState, fieldState }) && !state.isFocused
                        ? '#6bc348'
                        : baseStyles.borderColor
                    })
                  }}
                  isSearchable
                  form
                />
              )}
            />
          </RHFValidationWrapper>
        )}
      </PanelSection>
    </>
  );
};

export default inject('appStore')(StockVehicleFormFields);
