import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import cn from 'classnames';
import './starRating.scss';

const StarRating = ({ stars, active, className }) => {
  return (
    <div className={cn('starRating__container', className)}>
      {Array(stars)
        .fill()
        .map((e, i) => {
          return (
            <div className={i <= active - 1 ? 'starRating__star--fill' : 'starRating__star'} key={i}>
              <Icon name="star" />
            </div>
          );
        })}
    </div>
  );
};

StarRating.propTypes = {
  stars: PropTypes.number,
  active: PropTypes.number
};

export default StarRating;
