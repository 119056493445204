import platformApiUtils from '@ivendi/platform-api-utils';
import map from 'lodash/map';
import { action, computed, observable, toJS } from 'mobx';
import { parseVisibleQuotes } from '../core/apiDataParsers/quotesParser';
import { QuoteRequest } from '../core/quoteRequest';
import Request from '../mobx-models/Request';
import { fetchQuotes, isBalloonAdjusted } from '../utils/quoteUtils';
import { addCustomer, getCustomerDeals } from './../../src/api/customer';
import { valueToFloat, round } from '../core/helpers';
import VehicleOfInterest from '../mobx-models/VehicleOfInterest';
import _ from 'lodash';
import * as debug from '../debug';
import { compareVehicle } from 'utils/compareVehicle';
import { getVehicleLoanProductId } from '~/components/PushDeal/utils/quoteUtils';
import {
  trackFinanceQuotesDisplayed,
  trackFinanceQuotesFailed,
  trackFinanceQuotesSorted
} from '~/tracking/avoTracking';

class PushDealStore {
  @observable
  vehicle = {
    CapId: '',
    Condition: 'used',
    Class: 'car',
    Vrm: '',
    RegistrationDate: '',
    MakeId: '',
    Make: '',
    ModelId: '',
    Model: '',
    DerivativeId: '',
    Derivative: '',
    Mileage: '',
    Vin: ''
  };

  @observable
  customer = {
    Email: '',
    Id: '',
    Firstname: '',
    Surname: '',
    VehiclesOfInterest: []
  };

  @observable fetchQuoteError = false;

  @observable sendDealError = false;
  @observable getDealError = false;
  @observable getDealLoading = true;
  @observable createCustomerError = false;
  @observable vehicleOfInterestError = false;
  @observable isExistingCustomer = false;

  @observable createCustomerIsLoading = false;
  @observable quotesLoadingState = false;
  @observable confirmDealLoadingState = false;
  @observable confirmDealSent = false;
  @observable OfferStatus = false;
  @observable ProductSettings = [];
  @observable submitQuoteRequest = new Request();
  @observable suggestedCustomer = {};
  @observable suggestedCustomers = [];
  @observable quotes = [];
  @observable quoteRequest = new QuoteRequest({});
  @observable vehicleImage = '';
  @observable sortField = '';
  @observable hasAdjustedBalloon = false;

  QuoteeUid;

  @observable dealershipId;

  @observable customerPushDeals = [];

  constructor(store) {
    this.customerStore = store.customerStore;
    this.quotingStore = store.quotingStore;
    this.uiStateStore = store.uiState;
  }

  @computed
  get quoteFigures() {
    return {
      CashDeposit: this.quoteRequest.CashDeposit === '' ? 0 : this.quoteRequest.CashDeposit,
      VehiclePrice: this.OfferStatus ? this.discountedPrice : this.quoteRequest.VehiclePrice,
      BasicPrice: this.quoteRequest.BasicPrice,
      VatPercent: this.quoteRequest.VatPercent,
      PartExchange: this.quoteRequest.PartExchange,
      ResidualValue: this.quoteRequest.ResidualValue,
      VatAmount: this.quoteRequest.VatAmount,
      VatAddedToDeposit: this.quoteRequest.VatAddedToDeposit,
      VatQualifying: this.quoteRequest.VatQualifying,
      Term: this.quoteRequest.Term,
      OutstandingSettlement: this.quoteRequest.OutstandingSettlement,
      AnnualDistance: this.quoteRequest.AnnualDistance,
      ValueAddedProducts: this.quoteRequest.ValueAddedProducts,
      LenderVaps: toJS(this.quoteRequest.LenderVaps),
      CustomerType: 'consumer', // needs removing if we want to allow corporate customers push deal, customerStore.customer.customerType
      CapId: this.vehicle.CapId,
      CustomerCreditScore: this.quoteRequest.CustomerCreditScore
    };
  }

  @computed
  get discountedPrice() {
    return this.quoteRequest.VehiclePrice - this.quoteRequest.DiscountAmount;
  }

  @action
  sortQuotes = (sortField) => {
    const sortedQuotes = _.sortBy(this.quotes, sortField);
    this.quotes = sortedQuotes;
    this.sortField = sortField;
    this.fireFinanceQuotesSortedEvent();
  };

  @action
  setCustomerSuggestion = (customer) => {
    this.suggestedCustomer = customer;
  };

  @action
  checkIfCustomerExists = () => {
    platformApiUtils
      .get(`v1/dealershipconsumer/email/${this.customer.Email}?dealershipId=${this.dealershipId}`)
      .then((res) => {
        if (res.Exists) {
          this.setCustomerSuggestion(res.DealershipConsumer);
        } else {
          debug.error(`send deal checkIfCustomerExists`);
        }
      })
      .catch((e) => {
        debug.error('send deal checkIfCustomerExists');
      });
  };

  @action
  checkIfCustomerExistsAndSave = () => {
    return platformApiUtils
      .get(`v1/Customers/${this.dealershipId}?q=${this.customer.Email}`)
      .then(
        action((res) => {
          if (res.Results.length > 0) {
            let customer = res.Results.find((customer) => {
              return this.customer.Email === customer.Consumer.Email;
            });
            if (customer) {
              this.customer = {
                VehiclesOfInterest: customer.VehiclesOfInterest,
                ...customer.Consumer
              } || {
                Email: '',
                Id: '',
                Firstname: '',
                Surname: ''
              };
              this.customer.Id = customer.Id || '';
              this.isExistingCustomer = true;
            }
          }
        })
      )
      .catch((e) => {
        debug.error(`send deal checkIfCustomerExistsAndSave ${e}`);
      });
  };

  @action
  resetCustomer = () => {
    this.customer = {
      Email: '',
      Id: ''
    };
    this.suggestedCustomer = {};
  };

  @action
  resetPricingForm = (deposit, residualValue, term, annualMileage) => {
    this.quoteRequest.VehiclePrice = 0;
    this.OfferStatus = false;
    this.quoteRequest.DiscountAmount = 0;
    this.quoteRequest.VatQualifying = '';
    this.quoteRequest.VatAmount = '';
    this.quoteRequest.VatPercent = '';
    this.quoteRequest.BasicPrice = '';
    this.quoteRequest.VatAddedToDeposit = false;
    this.quoteRequest.ValueAddedProducts = [];
    this.quoteRequest.LenderVaps = [];
    this.quoteRequest.CashDeposit = deposit;
    this.quoteRequest.ResidualValue = 0;
    this.quoteRequest.PartExchange = '';
    this.quoteRequest.OutstandingSettlement = '';
    this.quoteRequest.Term = term;
    this.quoteRequest.TotalPrice = 0;
    this.quoteRequest.BalanceToChange = 0;
    this.quoteRequest.AnnualDistance = annualMileage;
    this.quoteRequest.CustomerCreditScore = '';
  };

  getNetDeposit = () => {
    const CashDeposit = valueToFloat(this.quoteRequest.CashDeposit);
    const PartExchange = valueToFloat(this.quoteRequest.PartExchange);
    const OutstandingSettlement = valueToFloat(this.quoteRequest.OutstandingSettlement);

    if (this.VehicleClass === 'lcv' && this.VatAddedToDeposit) {
      return round(CashDeposit + PartExchange - OutstandingSettlement + valueToFloat(this.quoteRequest.VatAmount));
    }
    return round(CashDeposit + PartExchange - OutstandingSettlement);
  };

  @action
  getSuggestedCustomers = () => {
    if (this.customer.Email.length > 0) {
      platformApiUtils.get(`v1/Customers/${this.dealershipId}?q=${this.customer.Email}`).then((res) => {
        this.updateSuggestedCustomers(res.Results);
      });
    } else {
      this.updateSuggestedCustomers([]);
    }
  };

  @action
  updateSuggestedCustomers = (suggestedCustomers) => {
    this.suggestedCustomers = suggestedCustomers;
  };

  @action
  saveSuggestedCustomer = (customer) => {
    this.customer = customer.Consumer;
    this.customer.Id = customer.Id;
    this.customer.VehiclesOfInterest = customer.VehiclesOfInterest;
    this.suggestedCustomers = [];
  };

  @action
  setCustomerFromRecord = (customer) => {
    this.customer.Firstname = customer.Firstname;
    this.customer.Surname = customer.Surname;
    this.customer.Email = customer.Email;
    this.customer.Id = customer.Id;
    this.suggestedCustomers = [];
    this.customer.VehiclesOfInterest = customer.Vehicles;
  };

  @action
  setQuoteDefaultSettings = (Deposit, Term, AnnualDistance, ProductSettings, QuoteeUid, dealershipId, defaultVaps) => {
    this.quoteRequest.CashDeposit = Deposit;
    this.quoteRequest.Term = Term;
    this.quoteRequest.AnnualDistance = AnnualDistance;
    this.setDefaultSettings(ProductSettings, QuoteeUid, dealershipId, defaultVaps);
  };

  @action
  setDefaultSettings = (ProductSettings, QuoteeUid, dealershipId, defaultVaps) => {
    this.ProductSettings = ProductSettings;
    this.QuoteeUid = QuoteeUid;
    this.quoteRequest.ValueAddedProducts = defaultVaps;
    this.dealershipId = dealershipId;
    this.ProductSettings = map(ProductSettings, function(product) {
      return {
        Name: product.Name,
        AprOnly: product.AprOnly,
        ProductUid: product.ProductUid,
        CommissionType: product.Settings.CommissionType,
        Rate: product.Settings.Rate,
        Visible: product.Settings.Visible,
        FunderCode: product.FunderCode,
        FunderProductCode: product.FunderProductCode
      };
    });
  };

  @action
  setVehicleFromStock = (vehicle, price) => {
    this.setVehicle(vehicle, price, null, null, null, null, null, true);
  };

  @action
  setVehicle = (vehicle, price, deposit, mileage, term, dealershipId, defaultVaps, stockVehicle) => {
    this.quoteRequest = new QuoteRequest({
      CountryCode: this.uiStateStore.countryCode,
      VatQualifying: vehicle.Class.toLowerCase() === 'lcv' || vehicle.Condition.toLowerCase() === 'new',
      VehicleClass: vehicle.Class.toLowerCase(),
      BasicPrice: '',
      VehiclePrice: '',
      CashDeposit: deposit,
      AnnualDistance: mileage,
      Term: term,
      ValueAddedProducts: defaultVaps || [],
      CustomerType: 'Consumer',
      OutstandingSettlement: null,
      SettlementSource: '',
      CustomerCreditScore: '',
      Vin: vehicle.Vin ?? '',
      Vrm: vehicle.Vrm ?? ''
    });

    this.quoteRequest.set('CashDeposit', deposit);
    this.dealershipId = dealershipId;
    this.vehicle = vehicle;

    if (vehicle.CdnVehicleImageUrl && vehicle.CdnVehicleImageUrl !== '') {
      this.vehicleImage = vehicle.CdnVehicleImageUrl;
    } else if (vehicle.imageSource) {
      this.vehicleImage = vehicle.imageSource;
    } else if (vehicle.Condition.toLowerCase() !== 'new') {
      this.vehicleImage = `https://d2bkdfyoj2xgsx.cloudfront.net/${vehicle.Vrm}/0.jpg?width=130&height=100&bgcolor=#000000`;
    }

    if (price && vehicle.Class.toLowerCase() === 'lcv' && stockVehicle) {
      this.quoteRequest.set('BasicPrice', price);
    } else if (price) {
      this.quoteRequest.set('VehiclePrice', price);
    }
  };

  updatePartExSettlement = (partEx, settlement) => {
    this.quoteRequest.set('OutstandingSettlement', settlement);
    this.quoteRequest.set('PartExchange', partEx);
  };

  @action
  handleQuoteCheckboxChange = (id, value) => {
    this.quotes = this.quotes.map((quote) => {
      if (quote.QuoteId === id) {
        quote.isChecked = value;
        return quote;
      } else {
        return quote;
      }
    });
  };

  @computed
  get selectedQuotes() {
    return this.quotes.reduce((acc, quote) => {
      if (quote.isChecked === true) {
        acc.push(quote);
      }
      return acc;
    }, []);
  }

  @computed
  get quoteIdsWithSettings() {
    return this.quotes.reduce((acc, quote) => {
      if (quote.isChecked === true) {
        let quoteObj = {};
        quoteObj.quoteId = quote.QuoteId;
        quoteObj.ProductSettings = this.ProductSettings.find(
          (productSetting) => productSetting.ProductUid === getVehicleLoanProductId(quote)
        );
        acc.push(quoteObj);
      }
      return acc;
    }, []);
  }

  @action
  handlePricingFormChange = (field, value) => {
    this.quoteRequest.set(field, value);
  };

  @action
  toggleOfferStatus = (status) => {
    this.OfferStatus = status;
    if (!status) {
      this.quoteRequest.set('DiscountAmount', 0);
    }
  };

  @action
  updateProductSettings = (newSettings, forAllQuotes) => {
    if (!forAllQuotes) {
      this.ProductSettings = map(this.ProductSettings, function(product) {
        if (product.ProductUid === newSettings.ProductUid) {
          return newSettings;
        } else {
          return product;
        }
      });
    }
    if (forAllQuotes) {
      this.ProductSettings = map(this.ProductSettings, function(product) {
        product.Rate = newSettings.Rate;
        product.CommissionType = newSettings.CommissionType;
        return product;
      });
    }
    this.fetchQuotes();
  };

  @action
  handleEmailInputChange = (id, value) => {
    this.customer.Email = value;
    this.isExistingCustomer = false;
  };

  @action
  fetchQuotes = () => {
    this.fetchQuoteError = false;
    this.quotesLoadingState = true;
    this.submitQuoteRequest
      .onSuccess(this.onFetchQuotesSuccess)
      .onError((e) => {
        this.setFetchQuoteErrors(e);
      })
      .call(fetchQuotes, this.QuoteeUid, this.vehicle, this.quoteFigures, this.ProductSettings, undefined, true);
  };

  @action
  setFetchQuoteErrors = (e) => {
    this.fetchQuoteError = true;
    this.quotesLoadingState = false;
    debug.error(`setFetchQuoteErrors ${e}`);
    this.fireFinanceQuotesFailedEvent(e);
  };

  @action
  onFetchQuotesSuccess = (quotes) => {
    this.hasAdjustedBalloon = isBalloonAdjusted(this.quoteRequest, quotes);
    const sortedQuotes = _.orderBy(quotes, 'Errors', 'desc');
    this.quotes = parseVisibleQuotes(sortedQuotes, this.ProductSettings);
    this.quotesLoadingState = false;
    this.fireFinanceQuotesDisplayedEvent();
  };

  @action
  changeConfirmationState = (value) => {
    this.confirmDealLoadingState = value;
    if (!value) {
      this.changeConfirmDealState(true);
    }
  };

  @action
  changeConfirmDealState = (value) => {
    this.confirmDealSent = value;
  };

  @action
  resetPushDealStore = (deposit, term, annualMileage) => {
    this.suggestedCustomer = {};
    this.quotes = [];
    this.vehicle = {
      CapId: '',
      Condition: 'used',
      Class: 'Car',
      Vrm: '',
      RegistrationDate: '',
      MakeId: '',
      Make: '',
      ModelId: '',
      Model: '',
      DerivativeId: '',
      Derivative: '',
      Mileage: '',
      Vin: ''
    };
    this.customer = {
      Firstname: '',
      Surname: '',
      Email: '',
      Id: ''
    };
    this.resetPricingForm(deposit, term, annualMileage);
    this.resetCustomer();
    this.resetConfirm();
    this.resetVehicleImage();
  };

  @action
  addNewCustomer = (email, firstName, surname, assignToSelf) => {
    let customer = {
      customerType: 'consumer',
      email,
      firstName,
      surname
    };

    return new Promise((resolve, reject) => {
      this.createCustomerIsLoading = true;
      this.createCustomerError = false;
      addCustomer(customer, this.dealershipId, assignToSelf)
        .then(
          action((customerId) => {
            this.customer = {
              Email: email,
              Firstname: firstName,
              Surname: surname,
              Id: customerId
            };
            this.createCustomerIsLoading = false;
            resolve();
          })
        )
        .catch(
          action((error) => {
            this.createCustomerError = true;
            this.createCustomerIsLoading = false;
            debug.error(`send deal addNewCustomer ${error}`);
          })
        );
    });
  };

  @action
  resetConfirm = () => {
    this.sendDealError = false;
    this.confirmDealLoadingState = false;
  };

  @action
  sendDeal = () => {
    this.changeConfirmationState(true);

    let customersVehiclesOfInterest = this.customer.VehiclesOfInterest;
    let currentVehicle;

    //  checking to see if selected vehicle already exists on the customers vehicles of interest
    currentVehicle = customersVehiclesOfInterest?.find((vehicle) =>
      compareVehicle(vehicle, this.vehicle, this.uiStateStore.countryCode)
    );

    const vehicleOfInterest = new VehicleOfInterest();

    // if customer does not already have that VOI then a new VOI is created on the customer
    if (!currentVehicle) {
      vehicleOfInterest.updateVehicleOfInterest(this.vehicle, this.customer.Id);

      // BDK does not have a CapID and the ID is not used in DE
      if (this.uiStateStore.isBdk) {
        vehicleOfInterest.CapId = -1;
      }

      // if customer does not already have that VOI then a new VOI is created on the customer
      if (!this.customer.VehiclesOfInterest) {
        this.customer.VehiclesOfInterest = [currentVehicle ? currentVehicle : vehicleOfInterest];
      } else {
        this.customer.VehiclesOfInterest.push(currentVehicle ? currentVehicle : vehicleOfInterest);
      }

      platformApiUtils
        .post(`v2/customer/${this.customer.Id}/vehicleofinterest`, vehicleOfInterest)
        .then(() => {
          this.postDeal(currentVehicle, vehicleOfInterest);
        })
        .catch(
          action((error) => {
            this.sendDealError = true;
            debug.error('send deal add vehicle of interest' + error);
          })
        );
    } else {
      this.postDeal(currentVehicle, vehicleOfInterest);
    }
  };

  postDeal = (currentVehicle, vehicleOfInterest) => {
    // if the VOI already exists on customer that vehicleId is used
    // (it's Id if "send another deal", VehicleId if from a new deal and searching existing customer)

    let vehicleId;
    if (currentVehicle?.Id && currentVehicle?.VehicleId) {
      vehicleId = currentVehicle.VehicleId;
    } else if (currentVehicle?.Id) {
      vehicleId = currentVehicle.Id;
    } else if (currentVehicle?.VehicleId) {
      vehicleId = currentVehicle.VehicleId;
    } else {
      vehicleId = vehicleOfInterest.VehicleId;
    }

    const body = {
      vehicleDiscount: +this.quoteRequest.DiscountAmount,
      quoteIdsWithSettings: this.quoteIdsWithSettings,
      DealerPlatformVehicleOfInterestId: vehicleId,
      DealVehicle: {
        VehicleClass: currentVehicle?.Class || vehicleOfInterest?.Class,
        Make: currentVehicle?.Make || vehicleOfInterest?.Make,
        Model: currentVehicle?.Model || vehicleOfInterest?.Model,
        Derivative: currentVehicle?.Derivative || vehicleOfInterest?.Derivative,
        Mileage: currentVehicle?.Mileage || vehicleOfInterest?.Mileage
      }
    };

    if (this.vehicleImage) {
      body.vehicleImageUrls = [this.vehicleImage.split('?')[0]];
    }

    platformApiUtils
      .post(`vnext/Dealership/${this.dealershipId}/customer/${this.customer.Id}/deal`, body)
      .then((res) => {
        setTimeout(() => {
          this.changeConfirmationState(false);
        }, 1000);
      })
      .catch(
        action((error) => {
          this.sendDealError = true;
          debug.error('send deal' + error);
        })
      );
  };

  @action
  getCustomerDeals = (consumerId, dealershipId) => {
    this.getDealError = false;
    this.getDealLoading = true;
    getCustomerDeals(consumerId, dealershipId)
      .then(
        action((res) => {
          this.getDealLoading = false;
          this.onGetCustomerDealsSuccess(res);
        })
      )
      .catch(
        action((error) => {
          this.getDealError = true;
          this.getDealLoading = false;
          debug.error(`send deal getCustomerDeals ${error}`);
        })
      );
  };

  @action
  onGetCustomerDealsSuccess = (deals) => {
    this.customerPushDeals = deals;
  };

  @action
  resetVehicleImage = () => {
    this.vehicleImage = null;
  };

  fireFinanceQuotesDisplayedEvent = () => {
    let funderList = [];
    let productTypesList = [];
    let monthlyPaymentList = [];
    let productIdsList = [];
    let productNameList = [];
    let repAprsList = [];
    let termsList = [];
    let quoteIdsList = [];

    this.quotes.forEach((a) => {
      funderList.push(a.FunderCode);
      productTypesList.push(a.FinanceType);
      monthlyPaymentList.push(a.FollowingPayments);
      productIdsList.push(a.ProductId);
      termsList.push(a.Term);
      quoteIdsList.push(a.QuoteId);
      repAprsList.push(a.RepresentativeApr);
      productNameList.push(a.ProductName);
    });

    trackFinanceQuotesDisplayed({
      financeFunderCodesList: funderList,
      financeMonthlyPaymentsList: monthlyPaymentList,
      financeProductTypesList: productTypesList,
      financeProductNamesList: productNameList,
      financeProductIdsList: productIdsList,
      financeRepresentativeAprsList: repAprsList,
      financeQuoteIdsList: quoteIdsList,
      financeTermsList: termsList,
      financeQuotesCount: this.quotes.length,
      financeQuoteCashDeposit: this.quoteRequest.CashDeposit,
      financeQuoteMileage: this.quoteRequest.AnnualDistance,
      financeQuoteTerm: this.quoteRequest.Term,
      dealershipId: this.dealershipId,
      consumerEntityType: this.quoteRequest.CustomerType,
      vehicleClass: this.vehicle.Class,
      vehicleCondition: this.vehicle.Condition,
      vehicleDerivative: this.vehicle.Derivative,
      vehicleMake: this.vehicle.Make,
      vehicleModel: this.vehicle.Model,
      vehicleVin: this.quoteRequest.VehicleVin ?? '',
      vehicleMileage: this.vehicle.Mileage,
      vehiclePrice: this.quoteRequest.VehiclePrice,
      vehicleVrm: this.vehicle.Vrm
    });
  };

  fireFinanceQuotesFailedEvent = (error) => {
    trackFinanceQuotesFailed({
      financeQuoteCashDeposit: this.quoteRequest.CashDeposit,
      financeQuoteMileage: this.quoteRequest.AnnualDistance,
      financeQuoteTerm: this.quoteRequest.Term,
      dealershipId: this.dealershipId,
      consumerEntityType: this.quoteRequest.CustomerType,
      vehicleClass: this.vehicle.Class,
      vehicleCondition: this.vehicle.Condition,
      vehicleDerivative: this.vehicle.Derivative,
      vehicleMake: this.vehicle.Make,
      vehicleModel: this.vehicle.Model,
      vehicleVin: this.quoteRequest.VehicleVin ?? '',
      vehicleMileage: this.vehicle.Mileage,
      vehiclePrice: this.quoteRequest.VehiclePrice,
      vehicleVrm: this.vehicle.Vrm,
      financeQuoteErrorMessage: error?.message ?? ''
    });
  };

  fireFinanceQuotesSortedEvent = () => {
    trackFinanceQuotesSorted({
      dealershipId: this.dealershipId,
      consumerEntityType: this.quoteRequest.CustomerType,
      vehicleClass: this.vehicle.Class,
      vehicleCondition: this.vehicle.Condition,
      vehicleDerivative: this.vehicle.Derivative,
      vehicleMake: this.vehicle.Make,
      vehicleModel: this.vehicle.Model,
      vehicleVin: this.quoteRequest.VehicleVin ?? '',
      vehicleMileage: this.vehicle.Mileage,
      vehiclePrice: this.quoteRequest.VehiclePrice,
      vehicleVrm: this.vehicle.Vrm,
      financeQuotesSortDirection: 'ASC',
      financeQuotesSortField: this.sortField
    });
  };
}

export default PushDealStore;
