import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { removeSpacesFromString, isLicensePlateRegisteredInUK } from '../../utils/application';

import iconGB from '../../styles/icons/reg-gb.svg';
import iconEU from '../../styles/icons/reg-eu.svg';

import './vrmPlate.scss';

const VRMPlate = ({ vrm, fuel = 'unknown', className }) => {
  const { t } = useTranslation('Customer');

  const sanitizedVRM = removeSpacesFromString(vrm);

  const newCar = sanitizedVRM === 'NEW';
  const plateIcon = ((isLicensePlateRegisteredInUK(sanitizedVRM) || newCar || !vrm) && iconGB) || iconEU;

  return (
    <div className={cn('vrmPlate__outer', className)}>
      <div
        className={cn('vrmPlate__blue', {
          vrmPlate__green_en: fuel.toLowerCase() === 'electric'
        })}
      >
        <img src={plateIcon} alt="vrmIcon" />
      </div>
      <div className="vrmPlate__yellow">{vrm ?? t('VRMPlate.no_reg')}</div>
    </div>
  );
};

VRMPlate.propTypes = {
  vrm: PropTypes.node,
  fuel: PropTypes.string,
  className: PropTypes.string
};
export default VRMPlate;
