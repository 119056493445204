import React from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CustomerListPage from '../components/CustomerListPage';
import ErrorApology from '../../Common/ErrorApology';
import { observer, inject } from 'mobx-react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNavigate, withQuery, withParams } from '../../../hocs/router';

class CustomerListContainer extends React.Component {
  UNSAFE_componentWillMount() {
    const defaultSearchParams = {
      sortField: 'lastModified',
      sortDirection: 'desc',
      page: '1',
      pageSize: '25',
      q: '',
      from: '',
      to: '',
      timePeriod: '',
      filter: ''
    };
    const newQuery = Object.assign({}, defaultSearchParams, this.props.query);
    this.props.navigate({
      pathname: `/d/${this.props.params.dealershipId}/consumers`,
      query: newQuery,
      replace: true
    });
    this.props.appStore.customerListStore.fetchCustomerList(
      this.props.params.dealershipId,
      newQuery,
      this.props.currentUserId
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.query, nextProps.query)) {
      this.props.appStore.customerListStore.fetchCustomerList(
        this.props.params.dealershipId,
        nextProps.query,
        this.props.currentUserId
      );
    }
  }

  componentWillUnmount() {
    this.props.appStore.customerListStore.reset();
  }

  updateQuery = (newParams) => {
    this.props.navigate({
      pathname: `/d/${this.props.params.dealershipId}/consumers`,
      query: { ...this.props.query, ...newParams }
    });
  };
  handleSearch = (query) => {
    this.updateQuery({
      q: query,
      page: '1'
    });
  };
  handleFilter = (filter) => {
    this.updateQuery({
      filter,
      page: '1'
    });
  };
  handleSort = (sortField) => {
    let sortDirection;
    let page = this.props.query.page;

    if (this.props.query.sortField === sortField) {
      sortDirection = this.props.query.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      sortDirection = 'asc';
      page = '1';
    }

    this.updateQuery({
      sortField,
      sortDirection,
      page
    });
  };
  handlePageChange = (page) => {
    window.scrollTo(0, 0);
    this.updateQuery({
      page
    });
  };
  handleSearchDateChange = (from, to, timePeriod) => {
    this.updateQuery({
      from: from ? from.toString() : '',
      to: to ? to.toString() : '',
      timePeriod: timePeriod ? timePeriod.toString() : '',
      page: '1'
    });
  };

  render() {
    const {
      appStore: { customerListStore }
    } = this.props;

    if (customerListStore.fetchCustomerListRequest.hasError) {
      return <ErrorApology>{this.props.t('CustomerListContainer.error_fetching_customer_list')}</ErrorApology>;
    }

    return (
      <CustomerListPage
        dealershipId={this.props.params.dealershipId}
        customers={customerListStore.customerListItems}
        isLoading={customerListStore.fetchCustomerListRequest.isLoading}
        total={customerListStore.total}
        searchQuery={this.props.query.q}
        sortField={this.props.query.sortField}
        sortDirection={this.props.query.sortDirection}
        page={this.props.query.page}
        pageSize={this.props.query.pageSize}
        filter={this.props.query.filter}
        timePeriod={this.props.query.timePeriod}
        handleSearch={this.handleSearch}
        handleFilter={this.handleFilter}
        handleSort={this.handleSort}
        handlePageChange={this.handlePageChange}
        handleSearchDateChange={this.handleSearchDateChange}
      />
    );
  }
}

CustomerListContainer.propTypes = {
  children: PropTypes.node,
  appStore: PropTypes.object,
  params: PropTypes.object,
  currentUserId: PropTypes.string,
  query: PropTypes.object
};

function mapStateToProps(state) {
  return {
    currentUserId: state.session.UserId
  };
}

export default compose(
  withParams,
  withQuery,
  withNavigate,
  withTranslation('CustomerList'),
  inject(['appStore']),
  connect(mapStateToProps),
  observer
)(CustomerListContainer);
