import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import './pushDealNavItem.scss';
import Icon from '../Common/Icon/Icon';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';

class PushDealNavItem extends Component {
  onClick = () => {
    this.props.onClick(this.props.title);
  };

  render() {
    const { width, active } = this.props;
    const iconNames = {
      selectVehicle: 'car',
      pricing: 'price-position',
      selectQuotes: 'quote',
      selectCustomer: 'add-customer',
      confirmAndSend: 'summary'
    };
    let navItemTitle = classnames('pushDealNavItem__title', active && 'pushDealNavItem__title--active');

    return (
      <div
        className="pushDealNavItem__container"
        style={{
          width: `${width}%`
        }}
      >
        <div className={navItemTitle}>
          <MediaQuery minWidth="910px">
            <div>{this.props.t(`PushDealNavItem.${this.props.title}`)}</div>
          </MediaQuery>
          <MediaQuery maxWidth="910px">
            <div className="pushDealNavItem__mobileIcons">
              <Icon name={`${iconNames[this.props.title]}`} />
            </div>
          </MediaQuery>
        </div>
      </div>
    );
  }
}

export default withTranslation('PushDeal')(PushDealNavItem);
