import { useVisLookupContext, SET_CAP_IDS } from '../../context/VisLookupContext';

const useSearchCapIds = () => {
  const { state, dispatch } = useVisLookupContext();
  const refine = (capIds, vehicleClass) => {
    dispatch({
      type: SET_CAP_IDS,
      payload: { capIds, class: vehicleClass }
    });
  };

  return {
    refine,
    capIds: state.capIds,
    class: state.class
  };
};

export default useSearchCapIds;
