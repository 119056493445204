import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Common/Icon/Icon';
import { commafy, getPercentageChange } from '../../../core/helpers';
import './financeCalculatorStatistics.scss';
import _ from 'lodash';

const FinanceCalculatorStatistics = ({ data }) => {
  const { t } = useTranslation('DigitalReport');
  const currentTotalQuotes = commafy(_.get(data, 'TotalQuotes.Current')) || 0;
  const totalQuotesPercentageDifference = _.get(data, 'TotalQuotes.PercentageDifference');
  const currentReach = commafy(_.get(data, 'Reach.Current')) || 0;
  const reachPercentageDifference = _.get(data, 'Reach.PercentageDifference');
  const currentTotalImpressions = commafy(_.get(data, 'TotalImpressions.Current')) || 0;
  const totalImpressionsPercentageDifference = _.get(data, 'TotalImpressions.PercentageDifference');
  const currentFinancePageViews = commafy(_.get(data, 'PageViews.finance_page.Current')) || 'N/A';
  const financePagePercentageDifference = _.get(data, 'PageViews.finance_page.PercentageDifference');

  return (
    <div>
      <div className="financeCalculatorStatistics__row">
        <div className="financeCalculatorStatistics__iconOuter">
          <Icon name="quote" />
        </div>
        <div className="financeCalculatorStatistics__data">
          <div className="financeCalculatorStatistics__dataTop">
            <div className="financeCalculatorStatistics__figure">{currentTotalQuotes}</div>
            {totalQuotesPercentageDifference ? (
              <div className="financeCalculatorStatistics__comparison">
                ({getPercentageChange(totalQuotesPercentageDifference)})
              </div>
            ) : null}
          </div>
          <div className="financeCalculatorStatistics__dataBottom">
            {t('FinanceCalculatorStatistics.total_quotes_delivered_to')}
          </div>
        </div>
      </div>

      <div className="financeCalculatorStatistics__row">
        <div className="financeCalculatorStatistics__iconOuter">
          <Icon name="unique-buyers" />
        </div>
        <div className="financeCalculatorStatistics__data">
          <div className="financeCalculatorStatistics__dataTop">
            <div className="financeCalculatorStatistics__figure">{currentReach}</div>
            {reachPercentageDifference ? (
              <div className="financeCalculatorStatistics__comparison">
                ({getPercentageChange(reachPercentageDifference)})
              </div>
            ) : null}
          </div>
          <div className="financeCalculatorStatistics__dataBottom">
            {t('FinanceCalculatorStatistics.unique_buyers_across')}
          </div>
        </div>
      </div>
      <div className="financeCalculatorStatistics__row">
        <div className="financeCalculatorStatistics__iconOuter">
          <Icon name="full-page-ad" />
        </div>
        <div className="financeCalculatorStatistics__data">
          <div className="financeCalculatorStatistics__dataTop">
            <div className="financeCalculatorStatistics__figure">{currentTotalImpressions}</div>
            {totalImpressionsPercentageDifference ? (
              <div className="financeCalculatorStatistics__comparison">
                ({getPercentageChange(totalImpressionsPercentageDifference)})
              </div>
            ) : null}
          </div>
          <div className="financeCalculatorStatistics__dataBottom">
            {t('FinanceCalculatorStatistics.full_page_advert_views')}
          </div>
        </div>
      </div>
      <div className="financeCalculatorStatistics__row">
        <div className="financeCalculatorStatistics__iconOuter">
          <Icon name="finance-leads" />
        </div>
        <div className="financeCalculatorStatistics__data">
          <div className="financeCalculatorStatistics__dataTop">
            <div className="financeCalculatorStatistics__figure">{currentFinancePageViews}</div>
            {financePagePercentageDifference ? (
              <div className="financeCalculatorStatistics__comparison">
                ({getPercentageChange(financePagePercentageDifference)})
              </div>
            ) : null}
          </div>
          <div className="financeCalculatorStatistics__dataBottom">
            {t('FinanceCalculatorStatistics.finance_page_views')}
          </div>
        </div>
      </div>
    </div>
  );
};

FinanceCalculatorStatistics.propTypes = {
  data: PropTypes.object
};
export default FinanceCalculatorStatistics;
