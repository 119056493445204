import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import VehicleForm from '../../Common/forms/VehicleForm';
import Page from '../../Common/Page';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import Breadcrumbs from '../../Common/Breadcrumbs';
import ConsumerVehicleInfoPanel from '../../Common/ConsumerVehicleInfoPanel';
import { withNavigate, withOutletContextProps, withParams } from '../../../hocs/router';
import VisQuotePage from '../../VisQuoting/VisQuotePage';
import { inject } from 'mobx-react';

@inject('appStore')
class EditApplicationVehicle extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    application: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    consumer: PropTypes.object.isRequired,
    vehicle: PropTypes.object.isRequired,
    onChangeVehicle: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      vehicleClass: ''
    };
  }

  updateVehicleClass = (vehicleClass) => {
    this.setState({
      vehicleClass
    });
  };
  handleCancel = () => {
    const { dealershipId, consumerId, applicantId } = this.props.params;
    this.props.navigate(`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit`);
  };
  renderForm = () => {
    let initialData = this.props.vehicle;
    initialData.VehicleClass = initialData.Class;
    initialData.Vrm = this.props.application.Quote.VehicleVrm;

    if (this.state.vehicleClass !== '') {
      initialData.VehicleClass = this.state.vehicleClass;
    }

    let options = this.props.options || {};

    if (this.props.application.Tags && this.props.application.Tags.applicationCount > 1) {
      if (this.props.vehicle.Condition.toLowerCase() === 'new') {
        options.disabledFields = {
          Condition: true,
          Make: true,
          Model: true,
          Derivative: true,
          Class: true
        };
      } else {
        options.disabledFields = {
          Condition: true,
          Make: true,
          Class: true,
          RegNumber: true,
          RegDate: true,
          Vin: true
        };
      }

      options.showCombinedDealWarning = true;
    } else {
      options.disabledFields = {};
      options.showCombinedDealWarning = false;
    }

    if (this.props.appStore.uiState.shouldUseVisLookup(this.state.vehicleClass || initialData.VehicleClass)) {
      return (
        <VisQuotePage
          options={options}
          onCancel={this.handleCancel}
          onSubmit={this.props.onChangeVehicle}
          onUpdateVehicleClass={this.updateVehicleClass}
          initialData={initialData}
          dealershipId={this.props.params.dealershipId}
        />
      );
    }

    return (
      <VehicleForm
        options={options}
        dispatch={this.props.dispatch}
        onCancel={this.handleCancel}
        onSubmit={this.props.onChangeVehicle}
        initialData={initialData}
        submitLabel={this.props.t('EditApplicationVehicle.update_vehicle')}
        onUpdateVehicleClass={this.updateVehicleClass}
        dealershipId={this.props.params.dealershipId}
      />
    );
  };

  render() {
    const { dealershipId, consumerId, applicantId } = this.props.params;
    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: this.props.t('EditApplicationVehicle.home'),
              path: `/d/${dealershipId}`
            },
            {
              name: this.props.t('EditApplicationVehicle.customer_list'),
              path: `/d/${dealershipId}/consumers`
            },
            {
              name: this.props.t('EditApplicationVehicle.consumer'),
              path: `/d/${dealershipId}/consumers/${consumerId}`
            },
            {
              name: this.props.t('EditApplicationVehicle.application_status'),
              path: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/status`
            },
            {
              name: this.props.t('EditApplicationVehicle.edit_application'),
              path: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit`
            },
            {
              name: this.props.t('EditApplicationVehicle.edit_vehicle')
            }
          ]}
          consumer={this.props.consumer}
        />
        <ConsumerVehicleInfoPanel vehicle={this.props.vehicle} />
        <Panel>
          <PanelHeader>{this.props.t('EditApplicationVehicle.edit_vehicle')}</PanelHeader>
          <PanelContent>{this.renderForm()}</PanelContent>
        </Panel>
      </Page>
    );
  }
}

export default compose(
  withParams,
  withNavigate,
  withOutletContextProps,
  withTranslation('Application')
)(EditApplicationVehicle);
