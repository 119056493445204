import { createSelector } from 'reselect';
import _ from 'lodash';
import { addFunderToQuote } from '../../../core/helpers';
import { getCustomerEntities } from '../../../selectors/customerSelectors';

const getParams = (state, params) => params;
const getFunders = (state) => state.funders;

export const savedQuoteSelector = createSelector(
  [getParams, getCustomerEntities, getFunders],
  (params, customers, funders) => {
    const consumerId = params.consumerId && params.consumerId.toLowerCase();
    const quote = _.find(customers[consumerId].SavedQuotes, (quote) => quote.Quote.QuoteId === params.quoteId);
    if (quote) {
      quote.Quote = addFunderToQuote(quote.Quote, funders);
    }
    return quote;
  }
);

export const vehicleOfInterestSelector = createSelector(
  [savedQuoteSelector, getCustomerEntities, getParams],
  (quote, customers, params) =>
    _.find(customers[params && params.consumerId.toLowerCase()].VehiclesOfInterest, (vehicle) => {
      return vehicle.VehicleId === (quote && quote.VehicleId);
    })
);
