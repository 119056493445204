import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { inject } from 'mobx-react';
import * as modalActions from '../../../redux/modal/modalActions';
import DealershipDistanceModal from '../components/DealershipDistanceModal';
import { getCustomer } from '../../../selectors/customerSelectors';

const DealershipDistanceContainer = (props) => (
  <DealershipDistanceModal
    distance={props.distance}
    consumer={props.consumer}
    dealerPostcode={props.dealerPostcode}
    customerPostcode={props.customerPostcode}
    onClose={props.closeModal}
    isOpen={props.isOpen}
    countryCode={props.countryCode}
  />
);

DealershipDistanceContainer.propTypes = {
  distance: PropTypes.string,
  consumer: PropTypes.object,
  dealerPostcode: PropTypes.string,
  customerPostcode: PropTypes.string,
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  countryCode: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  let params = state.modals.dealershipDistance || {};
  const { countryCode } = ownProps.appStore.uiState;
  return {
    consumer: getCustomer(state, params.customerId),
    distance: params.distance,
    dealerPostcode: params.dealerPostcode,
    customerPostcode: params.customerPostcode,
    isOpen: state.modals.dealershipDistance ? state.modals.dealershipDistance.isOpen : false,
    countryCode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(modalActions.close('dealershipDistance'));
    }
  };
}

export default inject(['appStore'])((connect(mapStateToProps, mapDispatchToProps))(DealershipDistanceContainer));
