import { createBrowserHistory, createMemoryHistory, parsePath } from 'history';

export const basename = process.env.PUBLIC_URL || '/dealer/platform/';

export const history = (process.env.NODE_ENV === 'test' ? createMemoryHistory : createBrowserHistory)();

const join = (basename, to) => (to.startsWith(basename) ? to : [basename, to].join('/').replace(/\/\//g, '/'));

const createPath = (to) => {
  const parsed = typeof to === 'string' ? parsePath(to) : to;

  parsed.pathname = join(basename, parsed.pathname);

  return parsed;
};

export const push = (to, state) => {
  history.push(createPath(to), state);
};

export const replace = (to, state) => {
  history.replace(createPath(to), state);
};
