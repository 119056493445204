import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { observer, inject } from 'mobx-react';
import { compose } from 'redux';
import ApplicationPage from './ApplicationPage';
import FormFooter from '../../Common/Form/FormFooter';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import LargeMenuButton from './LargeMenuButton';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Page from '../../Common/Page';
import ConsumerVehicleInfoPanel from '../../Common/ConsumerVehicleInfoPanel';
import EditApplicationAttentionPanel from './EditApplicationAttentionPanel';
import LargeButtonLayout from '../../ApplicationStatus/Layout/LargeButtonLayout';
import { getCustomer } from '../../../selectors/customerSelectors';
import { getLenderConfig } from '../../../core/lenders';
import { canEditPersonalDetailsPostSubmission } from '~/features';
import PanelContent from '../../Common/PanelContent';

import { withNavigate, withOutletContextProps, withParams } from '../../../hocs/router';
class EditApplication extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.resetEditVehicle();
  }

  handleBack = () => {
    const { dealershipId, consumerId, applicantId } = this.props.params;
    this.props.navigate(`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/status`);
  };
  handleProceed = () => {
    const { dealershipId, consumerId, applicantId } = this.props.params;
    this.props.navigate(`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit/summary`);
  };

  render() {
    let editSections = this.props.editSections;
    let associatedApplications = [];

    if (
      this.props.application.Tags &&
      this.props.application.Tags.multiQuoteRefId &&
      this.props.appStore.customerStore.customer
    ) {
      this.props.appStore.customerStore.customer.FinanceApplications.map((app) => {
        if (
          app.Tags &&
          this.props.application.Tags.multiQuoteRefId === app.Tags.multiQuoteRefId &&
          app.ApplicantId !== this.props.application.Id
        ) {
          associatedApplications.push(app);
        }
      });
    }

    // BNP Combined Applications specific
    let isCombinedApplication = associatedApplications.length > 0;
    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: this.props.t('EditApplication.home'),
              path: `/d/${this.props.params.dealershipId}`
            },
            {
              name: this.props.t('EditApplication.customer_list'),
              path: `/d/${this.props.params.dealershipId}/consumers`
            },
            {
              name: this.props.t('EditApplication.consumer'),
              path: `/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}`
            },
            {
              name: this.props.t('EditApplication.application_status'),
              path: `/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}/application/${this.props.params.applicantId}/status`
            },
            {
              name: this.props.t('EditApplication.edit_application')
            }
          ]}
          consumer={this.props.customer}
        />
        <ConsumerVehicleInfoPanel vehicle={this.props.vehicle} />
        <Panel>
          <PanelContent>
            <PanelHeader>{this.props.t('EditApplication.edit_application')}</PanelHeader>
            <ApplicationPage title={this.props.t('EditApplication.edit_application')}>
              <EditApplicationAttentionPanel funderName={this.props.application.Quote.FunderName} />
              <LargeButtonLayout>
                {editSections.vehicle && (
                  <LargeMenuButton
                    icon="car"
                    link={`/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}/application/${this.props.params.applicantId}/edit/quote/vehicle`}
                    text={this.props.t('EditApplication.vehicle_details')}
                    dataThook="vehicle_details"
                  />
                )}
                {editSections.quote && this.props.appStore.uiState.canGenerateQuotes && (
                  <LargeMenuButton
                    icon="quote"
                    link={`/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}/application/${this.props.params.applicantId}/edit/quote`}
                    text={this.props.t('EditApplication.quote')}
                    dataThook="quote"
                  />
                )}
                {editSections.personalDetails && canEditPersonalDetailsPostSubmission() && (
                  <LargeMenuButton
                    icon="car"
                    link={`/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}/application/${this.props.params.applicantId}/edit/personaldetails`}
                    text={this.props.t('ApplicationNavigation.personal_details')}
                    dataThook="personal_details"
                  />
                )}
                {editSections.employment && !isCombinedApplication && (
                  <LargeMenuButton
                    icon="employment"
                    link={`/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}/application/${this.props.params.applicantId}/edit/employment`}
                    text={this.props.t('EditApplication.employment_history')}
                    dataThook="employment_history"
                  />
                )}
                {editSections.affordability && !isCombinedApplication && (
                  <LargeMenuButton
                    icon="affordability"
                    link={`/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}/application/${this.props.params.applicantId}/edit/affordability`}
                    params={this.props.params}
                    text={this.props.t('EditApplication.income_expenditure')}
                    dataThook="affordability"
                  />
                )}
                {editSections.bankDetails && !isCombinedApplication && (
                  <LargeMenuButton
                    icon="bank"
                    link={`/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}/application/${this.props.params.applicantId}/edit/bankdetails`}
                    text={this.props.t('EditApplication.bank_details')}
                    dataThook="bank_details"
                  />
                )}
              </LargeButtonLayout>
              <FormFooter
                submitLabel={this.props.t('EditApplication.proceed_to_summary')}
                onSubmit={this.handleProceed}
                onCancel={this.handleBack}
                cancelLabel={this.props.t('EditApplication.go_back')}
              />
            </ApplicationPage>
          </PanelContent>
        </Panel>
      </Page>
    );
  }
}

EditApplication.propTypes = {
  params: PropTypes.object,
  application: PropTypes.object,
  customer: PropTypes.object,
  editSections: PropTypes.func.isRequired,
  vehicle: PropTypes.object,
  resetEditVehicle: PropTypes.func
};

function mapStateToProps(state, ownProps) {
  return {
    customer: getCustomer(state, ownProps.params.consumerId),
    application: state.application,
    editSections: getLenderConfig(
      state.application.Quote && state.application.Quote.FunderCode,
      state.application.CustomerType,
      'editSections'
    )
  };
}

export default compose(
  withOutletContextProps,
  withParams,
  withNavigate,
  withTranslation('Application'),
  inject(['appStore']),
  connect(mapStateToProps),
  observer
)(EditApplication);
