import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Table from './SummaryTable/Table';
import TableRow from './SummaryTable/TableRow';
import { trueFalseToYesNo, booleanOrStringToBoolean } from '../../../core/helpers';
import TableHead from './SummaryTable/TableHead';
import TableCell from './SummaryTable/TableCell';
import MoneyFormat from '../../Common/MoneyFormat';
import WordBreak from '../../Common/WordBreak';
import './affordabilityPanel.scss';

const AffordabilityPanel = ({ details, applicationFields }) => {
  const { t, ready } = useTranslation('Reporting');
  if (!ready) {
    return null;
  }
  return (
    <div className="affordabilityPanel">
      <Table>
        {applicationFields && applicationFields.GrossAnnual && (
          <TableRow>
            <TableHead width="75%">{t('AffordabilityPanel.gross_annual_income')}</TableHead>
            <TableCell>
              <MoneyFormat value={details && details.Income && details.Income.GrossAnnual} />
            </TableCell>
          </TableRow>
        )}
        {applicationFields && applicationFields?.HasOtherHouseholdIncome && (
          <TableRow>
            <TableHead width="75%">{t('AffordabilityPanel.other_household_income')}</TableHead>
            <TableCell>
              {trueFalseToYesNo(details && details?.Income && details?.Income?.HasOtherHouseholdIncome)}
            </TableCell>
          </TableRow>
        )}
        {applicationFields && applicationFields?.OtherHouseholdIncome && (
          <TableRow>
            <TableHead width="75%">{t('AffordabilityPanel.what_is_your_other_household_income')}</TableHead>
            <TableCell>
              <MoneyFormat value={details && details?.Income && details?.Income?.OtherHouseholdIncome} />
            </TableCell>
          </TableRow>
        )}
        {applicationFields && applicationFields.NetMonthly && (
          <TableRow>
            <TableHead width="75%">{t('AffordabilityPanel.typical_net_monthly_take_home_pay')}</TableHead>
            <TableCell>
              <MoneyFormat value={details && details.Income && details.Income.NetMonthly} />
            </TableCell>
          </TableRow>
        )}
        {applicationFields && applicationFields.IsReplacementLoan && (
          <TableRow>
            <TableHead width="75%">{t('AffordabilityPanel.is_this_replacing_a_current_loan')}</TableHead>
            <TableCell>
              {trueFalseToYesNo(details && details.ReplacementLoan && details.ReplacementLoan.IsReplacementLoan)}
            </TableCell>
          </TableRow>
        )}
        {applicationFields && applicationFields.DetailsOfReplacementLoan && (
          <TableRow>
            <TableHead width="75%">
              {t('AffordabilityPanel.if_yes_what_is_your_current_monthly_payment_on_this_loan')}
            </TableHead>
            <TableCell>
              <MoneyFormat
                value={details && details.ReplacementLoan && details.ReplacementLoan.DetailsOfReplacementLoan}
              />
            </TableCell>
          </TableRow>
        )}
        {applicationFields && applicationFields.Other && (
          <TableRow>
            <TableHead width="75%">
              {t('AffordabilityPanel.other_committed_monthly_outgoings_which_are_not_debt_related')}
            </TableHead>
            <TableCell>
              <MoneyFormat value={details && details.MonthlyExpenditure && details.MonthlyExpenditure.Other} />
            </TableCell>
          </TableRow>
        )}
        {applicationFields && applicationFields.ForeseeFutureDownturnInFinances && (
          <TableRow>
            <TableHead width="75%">{t('AffordabilityPanel.foresee_downturn')}</TableHead>
            <TableCell>
              <WordBreak>
                {trueFalseToYesNo(
                  details &&
                    details.PersonalCircumstances &&
                    details.PersonalCircumstances.ForeseeFutureDownturnInFinances
                )}
              </WordBreak>
            </TableCell>
          </TableRow>
        )}
        {applicationFields && applicationFields.DownturnReason && (
          <TableRow>
            <TableHead width="75%">{t('AffordabilityPanel.if_yes_what_is_the_reason_for_the_downturn')}</TableHead>
            <TableCell>
              {details && details.PersonalCircumstances && details.PersonalCircumstances.DownturnReason}
            </TableCell>
          </TableRow>
        )}
        {applicationFields && applicationFields?.ForeseenYearlyIncome && (
          <TableRow>
            <TableHead width="75%">{t('AffordabilityPanel.if_yes_expect_your_reduced_annual_income')}</TableHead>
            <TableCell>
              <MoneyFormat
                value={details && details.PersonalCircumstances && details.PersonalCircumstances?.ForeseenYearlyIncome}
              />
            </TableCell>
          </TableRow>
        )}
        {applicationFields && applicationFields.ChangesExpected && (
          <TableRow>
            <TableHead width="75%">{t('AffordabilityPanel.expected_changes_in_personal_circumstances')}</TableHead>
            <TableCell>{trueFalseToYesNo(details?.PersonalCircumstances?.ChangesExpected)}</TableCell>
          </TableRow>
        )}
        {applicationFields && applicationFields.DetailsOfExpectedChanges && (
          <TableRow>
            <TableHead width="75%">{t('AffordabilityPanel.if_yes_what_is_the_reason_for_the_downturn')}</TableHead>
            <TableCell>
              {booleanOrStringToBoolean(details?.PersonalCircumstances?.ChangesExpected) &&
                details.PersonalCircumstances.DetailsOfExpectedChanges}
            </TableCell>
          </TableRow>
        )}
        {applicationFields && applicationFields.MortgageOrRental && (
          <TableRow>
            <TableHead width="75%">
              {t('AffordabilityPanel.your_share_of_monthly_mortgage_or_rental_expenditure')}
            </TableHead>
            <TableCell>
              <MoneyFormat
                value={details && details.MonthlyExpenditure && details.MonthlyExpenditure.MortgageOrRental}
              />
            </TableCell>
          </TableRow>
        )}
      </Table>
    </div>
  );
};

AffordabilityPanel.propTypes = {
  details: PropTypes.object,
  applicationFields: PropTypes.object
};
AffordabilityPanel.defaultProps = {
  details: {},
  applicationFields: {}
};
export default AffordabilityPanel;
