import React from 'react';
import PropTypes from 'prop-types';
import Page from '../Common/Page';
import Panel from '../Common/Panel';
import PanelHeader from '../Common/PanelHeader';
import Breadcrumbs from '../Common/Breadcrumbs';
import InformationWarning from '../Common/InformationWarning';
import ExportClosedLeadsForm from './ExportClosedLeadsForm';
import './exportClosedLeadsPage.scss';
import { useParams } from 'react-router-dom';

const ExportClosedLeadsPage = ({ exportClosedLeads }) => {
  const params = useParams();
  const handleCancel = () => window.history.back();

  return (
    <Page>
      <Breadcrumbs
        items={[
          {
            name: 'Home',
            path: `/d/${params.dealershipId}`
          },
          {
            name: 'Reporting',
            path: `/d/${params.dealershipId}/reporting`
          },
          {
            name: 'Export Leads'
          }
        ]}
      />
      <Panel>
        <PanelHeader>Export Leads</PanelHeader>
        <div>
          <div className="exportClosedLeadsPage__info">
            <InformationWarning text="Customer data older than 12 months, where an application has not been submitted to a lender, will not be available, as per the iVendi data retention policy." />
          </div>
          <ExportClosedLeadsForm onSubmit={exportClosedLeads} handleCancel={handleCancel} />
        </div>
      </Panel>
    </Page>
  );
};

ExportClosedLeadsPage.propTypes = {
  exportClosedLeads: PropTypes.func
};

export default ExportClosedLeadsPage;
