import React from 'react';
import PropTypes from 'prop-types';
import './consumerRecordLayout.scss';

const Layout = ({ children }) => (
  <div className="consumerRecordLayout">
    {React.Children.toArray(children).map((child, i) => {
      return (
        <div className={`consumerRecordLayout__col${i}`} key={'col' + i}>
          {child}
        </div>
      );
    })}
  </div>
);

Layout.propTypes = {
  children: PropTypes.node
};

export default Layout;
