import classnames from 'classnames';
import PropTypes from 'prop-types';
import ConsumerName from '../../Common/ConsumerName';
import DateFormat from '../../Common/DateFormat';
import FunderLogo from '../../Common/FunderLogo';
import LenderUpdateStatus from '../../Common/LenderStatus/LenderStatus';
import SelfServicedApplicationTag from '../../Common/SelfServicedApplicationTag';
import DashboardBusinessTag from '../components/DashboardBusinessTag';
import { trackDashboardLenderUpdateSelected } from '~/tracking/avoTracking';
import { Link } from 'react-router-dom';
import './lenderUpdatesPanelItem.scss';

const LenderUpdatesPanelItem = ({ item, dealershipId, index, length }) => {
  const {
    Application: { Id: applicantId, Status, Lender, LastUpdatedUtc },
    Vehicle: vehicle = {}
  } = item;

  const consumerId = item?.DealershipConsumer?.Id || item?.CorporateCustomer?.Id || '';
  const isCorpCustomer = !!item?.CorporateCustomer?.Id;
  const consumerName = isCorpCustomer
    ? item?.CorporateCustomer?.TradingName || ''
    : `${item?.DealershipConsumer?.FirstName || ''} ${item?.DealershipConsumer?.LastName || ''}`;

  const innerStyles = classnames(
    'lenderUpdatesPanelItem__inner',
    index !== length && 'lenderUpdatesPanelItem__borderBottom'
  );

  const handleClick = () => {
    trackDashboardLenderUpdateSelected({
      financeApplicationId: applicantId,
      financeApplicationStatus: Status,
      dealershipId: dealershipId
    });
  };
  return (
    <div className="lenderUpdatesPanelItem" data-name={consumerName}>
      <Link
        to={`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/status`}
        onClick={handleClick}
        data-th="LenderUpdatesPanelItemLink"
      >
        <div className={innerStyles}>
          <div className="lenderUpdatesPanelItem__topSection">
            <div className="lenderUpdatesPanelItem__funderLogo">
              <FunderLogo funder={Lender} />
            </div>
            <div className="lenderUpdatesPanelItem__status">
              <div className="lenderUpdatesPanelItem__lenderStatus">
                <LenderUpdateStatus status={Status} />
              </div>
              <div className="lenderUpdatesPanelItem__date" key="date">
                <DateFormat value={LastUpdatedUtc} parse="utc" format={'DD MMM YYYY HH:mm'} />
              </div>
            </div>
          </div>

          <div className="lenderUpdatesPanelItem__mainSection">
            <div className="lenderUpdatesPanelItem__mainSectionInner">
              <div className="lenderUpdatesPanelItem__name">
                {isCorpCustomer && (
                  <div className="lenderUpdatesPanelItem__business" key="business">
                    <DashboardBusinessTag smallTag={true} />
                  </div>
                )}
                <ConsumerName name={consumerName} />
              </div>
              <div className="lenderUpdatesPanelItem__details">
                <div className="lenderUpdatesPanelItem__vehicle lenderUpdatesPanelItem__ellipsis">
                  {vehicle.Make} {vehicle.Model}
                </div>
                <div className="lenderUpdatesPanelItem__derivative">{vehicle.Derivative}</div>
              </div>
            </div>

            {item.isSelfService && (
              <div className="lenderUpdatesPanelItem__selfServiceTag">
                <SelfServicedApplicationTag />
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

LenderUpdatesPanelItem.propTypes = {
  item: PropTypes.object,
  dealershipId: PropTypes.string,
  index: PropTypes.number,
  length: PropTypes.number
};

export default LenderUpdatesPanelItem;
