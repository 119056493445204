import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import QuoteFunderLogo from '../QuotesList/components/QuoteFunderLogo';
import ProductName from '../QuotesList/components/ProductName';
import MoneyFormat from '../Common/MoneyFormat';
import MediaQuery from 'react-responsive';
import FormatAPR from '~/components/Quoting/components/FormatAPR';
import './quoteItem.scss';

const QuoteItem = (props) => {
  let { quote } = props;
  return (
    <div className="quoteItem">
      <div className="quoteItem__funderLogo">
        <QuoteFunderLogo funder={quote.FunderCode} />
      </div>
      <MediaQuery minWidth="1023px">
        <div className="quoteItem__quoteInfo--spacer" />
      </MediaQuery>
      <div className="quoteItem__quoteInfo--subItem quoteItem__quoteInfo--product">
        <div className="quoteItem__quoteInfo--header">
          <div>{props.t('QuoteItem.product')}</div>
        </div>
        <div className="quoteItem__quoteInfo--figure quoteItem__quoteInfo--productName">
          <ProductName productName={quote.ProductName} />
        </div>
      </div>
      <div className="quoteItem__quoteInfo--spacer" />

      <div className="quoteItem__quoteInfo--subItem">
        <div className="quoteItem__quoteInfo--header">{props.t('QuoteItem.apr')}</div>
        <div className="quoteItem__quoteInfo--figure">
          <FormatAPR value={quote.RepresentativeApr} />
        </div>
      </div>
      <div className="quoteItem__quoteInfo--spacer" />
      <div className="quoteItem__quoteInfo--subItem">
        <div className="quoteItem__quoteInfo--header">{props.t('QuoteItem.total_amount_payable')}</div>
        <div className="quoteItem__quoteInfo--figure">
          <MoneyFormat value={quote.TotalAmountPayable} />
        </div>
      </div>
      <div className="quoteItem__quoteInfo--spacer" />
      <div className="quoteItem__quoteInfo--subItem">
        <div className="quoteItem__quoteInfo--header">{props.t('QuoteItem.agreement_term')}</div>
        <div className="quoteItem__quoteInfo--figure">{props.t('QuoteItem.months', { term: quote.Term })}</div>
      </div>
      <div className="quoteItem__quoteInfo--spacer" />
      <div className="quoteItem__quoteInfo--subItem">
        <div className="quoteItem__quoteInfo--header">{props.t('QuoteItem.regular_monthly_payment')}</div>
        <div className="quoteItem__quoteInfo--figure">
          <MoneyFormat value={quote.FollowingPayments} />
        </div>
      </div>
    </div>
  );
};

QuoteItem.propTypes = {
  quote: PropTypes.object,
  hasLoans: PropTypes.bool
};
export default withTranslation('Common')(QuoteItem);
