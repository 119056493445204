import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as modalActions from '../../../redux/modal/modalActions';
import GeneralCreditInformationModal from '../modals/GeneralCreditInformationModal';

const EligibilitySummaryModalContainer = ({ closeModal, personalScore, creditScore, isOpen }) => {
  return (
    <GeneralCreditInformationModal
      close={closeModal}
      personalScore={personalScore}
      creditScore={creditScore}
      isOpen={isOpen}
    />
  );
};

EligibilitySummaryModalContainer.propTypes = {
  closeModal: PropTypes.func,
  personalScore: PropTypes.number,
  creditScore: PropTypes.number,
  isOpen: PropTypes.bool
};

function mapStateToProps(state) {
  let params = state.modals.eligibilitySummary || {};

  return {
    personalScore: params.personalScore,
    creditScore: params.creditScore,
    isOpen: state.modals.eligibilitySummary ? state.modals.eligibilitySummary.isOpen : false
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(modalActions.close('eligibilitySummary'));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EligibilitySummaryModalContainer);
