import Avo from '../lib/Avo';

const trackOutboundLinks = (e: MouseEvent) => {
  const clickTargetNode = (e.target as HTMLElement).closest('a');

  if (clickTargetNode && !clickTargetNode.href.startsWith(window.location.origin)) {
    Avo.outboundLink({
      targetUrl: clickTargetNode.href,
      targetTags: [clickTargetNode.textContent || ''],
      dealershipId: undefined,
      vehicleVrm: undefined,
      vehicleVin: undefined,
      vehicleRmid: undefined,
      financeFunderCode: undefined,
      financeQuoteId: undefined,
      consumerId: undefined
    });
  }
};

export default trackOutboundLinks;
