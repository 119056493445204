import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import CheckboxWithLabel from '../Common/Form/CheckboxWithLabel';
import InformationWarning from '../Common/InformationWarning';
import FormConsumerVehicleDetails from '../Common/FormConsumerVehicleDetails';
import { observer, inject } from 'mobx-react';
import { observable, action, reaction, toJS } from 'mobx';
import Validator from '../../validators/Validator';
import MobxForm from '../MobxForm/MobxForm';
import MobxFieldGroup from '../MobxForm/MobxFieldGroup';
import MobxLabel from '../MobxForm/MobxFormLabel';
import DateField from '../Common/Form/DateField';
import TextInputWrappingInput from '../Common/Form/TextInputWrappingInput';
import CurrencyInput from '../Common/Form/CurrencyInput';
import PercentageInput from '../Common/Form/PercentageInput';
import SelectInput from '../Common/Form/SelectInput';
import Button from '../Common/Button/Button';
import AddVapModal from '../Quoting/forms/AddVapModal';
import EditVapModal from '../Quoting/forms/EditVapModal';
import ValueAddedProduct from '../Quoting/forms/ValueAddedProduct';
import _ from 'lodash';
import { compose } from 'redux';
import { withParams, withNavigate } from 'hocs/router';
import './soldFinance.scss';

export const getOptionKeys = (isBdk) => {
  const optionKeys = {
    Lender: `Lenders${isBdk ? 'BDK' : ''}`,
    Product: `ProductType${isBdk ? 'BDK' : ''}`
  };

  return optionKeys;
};

@observer
class SoldFinance extends React.Component {
  constructor() {
    super();
    this.validationRules = {
      DateOfPurchase: 'required, date',
      DateOfHandover: 'date',
      TotalPrice: 'currency',
      VehiclePrice: 'currency',
      BasicPrice: 'currency',
      VAT: 'number',
      VATAmount: 'currency',
      NonVatableItems: 'currency',
      AccessoriesInsurance: 'currency',
      AccessoriesWarranty: 'currency',
      AccessoriesOther: 'currency',
      CashDeposit: 'currency',
      PartExchange: 'currency',
      Settlement: 'currency',
      Advance: 'currency',
      AnnualDistanceQuoted: 'number',
      ChargePerOverDistanceUnit: 'currency',
      AcceptanceFeeLast: 'currency',
      OptionToPurchaseFee: 'currency',
      FlatRate: { de: 'decimalPlaces:2', default: null },
      RepresentativeApr: { de: 'decimalPlaces:2', default: null },
      Term: 'number',
      FirstPayment: 'currency',
      FollowedBy: 'number',
      FollowingPayments: 'currency',
      FinalPayment: 'currency',
      TotalAmountPayable: 'currency',
      InterestCharges: 'currency',
      Commission: 'currency',
      AdditionalCommission: 'currency'
    };
    this.state = {
      isAddVapModalOpen: false,
      isEditVapModalOpen: false,
      vapToEdit: null
    };
  }

  @observable
  formData = {
    DateOfPurchase: '',
    DateOfHandover: '',
    TotalPrice: '',
    VehiclePrice: '',
    BasicPrice: '',
    VAT: '',
    VATAmount: '',
    NonVatableItems: '',
    AccessoriesInsurance: '',
    AccessoriesWarranty: '',
    AccessoriesOther: '',
    CashDeposit: '',
    PartExchange: '',
    Settlement: '',
    Advance: '',
    AnnualDistanceQuoted: '',
    ChargePerOverDistanceUnit: '',
    AcceptanceFeeLast: '',
    OptionToPurchaseFee: '',
    FlatRate: '',
    RepresentativeApr: '',
    Term: '',
    FirstPayment: '',
    FollowedBy: '',
    FollowingPayments: '',
    FinalPayment: '',
    TotalAmountPayable: '',
    InterestCharges: '',
    Commission: '',
    AdditionalCommission: '',
    FunderCode: '',
    OtherFunder: '',
    FinanceType: '',
    RemoveFromDashboard: false,
    ValueAddedProducts: []
  };
  @action
  setDateOfPurchase = (DateOfPurchase) => (this.formData.DateOfPurchase = DateOfPurchase.currentTarget.value);
  @action
  setDateOfHandover = (DateOfHandover) => (this.formData.DateOfHandover = DateOfHandover.currentTarget.value);
  @action
  setTotalPrice = (TotalPrice) => (this.formData.TotalPrice = TotalPrice);
  @action
  setVehiclePrice = (VehiclePrice) => (this.formData.VehiclePrice = VehiclePrice);
  @action
  setBasicPrice = (BasicPrice) => (this.formData.BasicPrice = BasicPrice);
  @action
  setVAT = (id, VAT) => (this.formData.VAT = VAT);
  @action
  setVATAmount = (VATAmount) => (this.formData.VATAmount = VATAmount);
  @action
  setNonVatableItems = (NonVatableItems) => (this.formData.NonVatableItems = NonVatableItems);
  @action
  setAccessoriesInsurance = (AccessoriesInsurance) => (this.formData.AccessoriesInsurance = AccessoriesInsurance);
  @action
  setAccessoriesWarranty = (AccessoriesWarranty) => (this.formData.AccessoriesWarranty = AccessoriesWarranty);
  @action
  setAccessoriesOther = (AccessoriesOther) => (this.formData.AccessoriesOther = AccessoriesOther);
  @action
  setVapPrice = (index, price) => (this.formData.ValueAddedProducts[index].Price = price);
  @action
  setCashDeposit = (CashDeposit) => (this.formData.CashDeposit = CashDeposit);
  @action
  setPartExchange = (PartExchange) => (this.formData.PartExchange = PartExchange);
  @action
  setSettlement = (Settlement) => (this.formData.Settlement = Settlement);
  @action
  setAdvance = (Advance) => (this.formData.Advance = Advance);
  @action
  setAnnualDistanceQuoted = (id, AnnualDistanceQuoted) => (this.formData.AnnualDistanceQuoted = AnnualDistanceQuoted);
  @action
  setChargePerOverDistanceUnit = (ChargePerOverDistanceUnit) =>
    (this.formData.ChargePerOverDistanceUnit = ChargePerOverDistanceUnit);
  @action
  setAcceptanceFeeLast = (AcceptanceFeeLast) => (this.formData.AcceptanceFeeLast = AcceptanceFeeLast);
  @action
  setOptionToPurchaseFee = (OptionToPurchaseFee) => (this.formData.OptionToPurchaseFee = OptionToPurchaseFee);
  @action
  setFlatRate = (id, FlatRate) => (this.formData.FlatRate = FlatRate);
  @action
  setRepresentativeApr = (id, RepresentativeApr) => (this.formData.RepresentativeApr = RepresentativeApr);
  @action
  setTerm = (id, Term) => (this.formData.Term = Term);
  @action
  setFirstPayment = (FirstPayment) => (this.formData.FirstPayment = FirstPayment);
  @action
  setFollowedBy = (FollowedBy) => (this.formData.FollowedBy = FollowedBy);
  @action
  setFollowingPayments = (FollowingPayments) => (this.formData.FollowingPayments = FollowingPayments);
  @action
  setFinalPayment = (FinalPayment) => (this.formData.FinalPayment = FinalPayment);
  @action
  setTotalAmountPayable = (TotalAmountPayable) => (this.formData.TotalAmountPayable = TotalAmountPayable);
  @action
  setInterestCharges = (InterestCharges) => (this.formData.InterestCharges = InterestCharges);
  @action
  setCommission = (Commission) => (this.formData.Commission = Commission);
  @action
  setAdditionalCommission = (AdditionalCommission) => (this.formData.AdditionalCommission = AdditionalCommission);
  @action
  setFunderCode = (id, FunderCode) => (this.formData.FunderCode = FunderCode);
  @action
  setOtherFunder = (OtherFunder) => (this.formData.OtherFunder = OtherFunder);
  @action
  setNotes = (Notes) => (this.formData.Notes = Notes);
  @action
  setFinanceType = (id, FinanceType) => (this.formData.FinanceType = FinanceType);
  @action
  toggleRemoveFromDashboard = () => (this.formData.RemoveFromDashboard = !this.formData.RemoveFromDashboard);

  UNSAFE_componentWillMount() {
    const vehicle = this.props.appStore.customerStore.customer.getVehicle(this.props.params.vehicleId);
    this.setInitialValues(vehicle);
    this.setupValidation();
  }

  @action
  setInitialValues = (vehicle) => {
    Object.assign(
      this.formData,
      _.get(vehicle, 'Status.Status') ? vehicle.Status : this.props.appStore.closeDealStore.closeDealData
    );
  };

  componentWillUnmount() {
    this.validationReaction();
  }

  setupValidation = () => {
    this.validator = new Validator(this.props.appStore.uiState.countryCode);
    this.validator.setRules(this.validationRules);
    this.validationReaction = reaction(() => ({ ...this.formData }), this.validator.validate, {
      fireImmediately: true
    });
  };
  submit = () => {
    const closeDealStore = this.props.appStore.closeDealStore;
    const customerStore = this.props.appStore.customerStore;

    if (!this.validator.errorCount) {
      closeDealStore
        .markLeadAsSoldFinance(
          toJS(this.formData),
          customerStore.customer.CustomerType,
          this.props.params.vehicleId,
          this.props.params.consumerId
        )
        .then(() => {
          this.props.navigate(`/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}`);
        });
    }
  };
  @action
  removeVap = (index) => {
    let vapState = this.formData.ValueAddedProducts;
    vapState = vapState.filter((v, i) => i !== index);
    this.formData.ValueAddedProducts = vapState;
  };
  editVap = (index) => {
    this.setState(
      {
        vapToEdit: index
      },
      () => {
        this.setState({
          isEditVapModalOpen: true
        });
      }
    );
  };
  openAddVapModal = () => {
    this.setState({
      isAddVapModalOpen: true
    });
  };
  closeAddVapModal = () => {
    this.setState({
      isAddVapModalOpen: false,
      isEditVapModalOpen: false,
      vapToEdit: null
    });
  };
  goBack = () => {
    this.props.navigate(-1);
  };
  @action
  handleSubmitAddVapModal = (productId, price, productTypeCode, shouldRemoveExistingDuplicate) => {
    this.props.appStore.closeDealStore.fetchVapProductType(this.props.params.dealershipId, productTypeCode).then(
      action(() => {
        const chosenProduct = _.find(this.props.appStore.closeDealStore.vapProductType, {
          Id: productId
        });

        chosenProduct.Price = price;

        if (shouldRemoveExistingDuplicate) {
          const toRemove = _.find(this.formData.ValueAddedProducts, (vap) => vap.ProductTypeCode === productTypeCode);

          this.removeVap(_.indexOf(this.formData.ValueAddedProducts, toRemove));
        }

        this.formData.ValueAddedProducts.push(chosenProduct);
        this.closeAddVapModal();
      })
    );
  };
  @action
  handleSubmitEditVapModal = (productId, price, productTypeCode, shouldRemoveExistingDuplicate) => {
    this.props.appStore.closeDealStore.fetchVapProductType(this.props.params.dealershipId, productTypeCode).then(
      action(() => {
        const chosenProduct = this.props.appStore.closeDealStore.updateVapPrice(productId, price);

        if (shouldRemoveExistingDuplicate) {
          const toRemove = _.find(this.formData.ValueAddedProducts, (vap) => vap.ProductTypeCode === productTypeCode);

          this.formData.ValueAddedProducts[this.state.vapToEdit] = chosenProduct;
          this.removeVap(_.indexOf(this.formData.ValueAddedProducts, toRemove));
        } else {
          this.formData.ValueAddedProducts[this.state.vapToEdit] = chosenProduct;
        }

        this.closeAddVapModal();
      })
    );
  };

  render() {
    if (this.props.appStore.customerStore.isLoadingCustomer) {
      return null;
    }
    const {
      uiState,
      closeDealStore,
      customerStore: { customer }
    } = this.props.appStore;
    let errors = this.validator.getErrors();
    const vehicle = customer.getVehicle(this.props.params.vehicleId);
    const optionKeys = getOptionKeys(uiState.isBdk);

    return (
      <MobxForm onSubmit={this.submit} className="soldFinance" focusOnFirstElement>
        <div className="soldFinance__formLeft">
          <div className="soldFinance__left" key="leftInner">
            <div className="soldFinance__infoWarning" key="info1">
              <InformationWarning
                text={this.props.t(
                  'SoldFinance.the_following_details_will_be_stored_against_the_customer_record_and_will_be_useful_for_reporting_as_it_will_form_a_historic_record_of_actions_related_to_this_vehicle'
                )}
              />
            </div>
            <div className="soldFinance__vehicleDetails">
              <FormConsumerVehicleDetails consumer={customer} vehicle={vehicle} />
            </div>
            <div className="soldFinance__dateFields" key="dateFields">
              <div className="soldFinance__dateField">
                <MobxFieldGroup error={errors.DateOfPurchase}>
                  <MobxLabel htmlFor="DateOfPurchase">{this.props.t('SoldFinance.customer_purchase_date')}</MobxLabel>
                  <DateField
                    value={this.formData.DateOfPurchase}
                    onChange={this.setDateOfPurchase}
                    tabIndex="2"
                    id="DateOfPurchase"
                  />
                </MobxFieldGroup>
              </div>
              <div className="soldFinance__dateField">
                <MobxFieldGroup error={errors.DateOfHandover}>
                  <MobxLabel htmlFor="DateOfPurchase">{this.props.t('SoldFinance.customer_handover_date')}</MobxLabel>
                  <DateField
                    value={this.formData.DateOfHandover}
                    onChange={this.setDateOfHandover}
                    tabIndex="3"
                    id="DateOfHandover"
                  />
                </MobxFieldGroup>
              </div>
            </div>
            <div className="soldFinance__divider" key="divider1" />
            <div className="soldFinance__qualifying">
              {uiState.isDe
                ? this.props.t('SoldFinance.price')
                : !uiState.isDe && this.formData.VATQualifying
                ? this.props.t('SoldFinance.vat_qualifying')
                : this.props.t('SoldFinance.vat_margin')}
            </div>

            <MobxFieldGroup size="small" error={errors.TotalPrice}>
              <MobxLabel htmlFor="TotalPrice">{this.props.t('SoldFinance.total_price')}</MobxLabel>
              <CurrencyInput
                value={this.formData.TotalPrice}
                onChange={this.setTotalPrice}
                id="TotalPrice"
                tabIndex="4"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.VehiclePrice}>
              <MobxLabel htmlFor="VehiclePrice">{this.props.t('SoldFinance.vehicle_price')}</MobxLabel>
              <CurrencyInput
                value={this.formData.VehiclePrice}
                onChange={this.setVehiclePrice}
                id="VehiclePrice"
                tabIndex="5"
              />
            </MobxFieldGroup>

            {this.formData.VATQualifying && (
              <div>
                <MobxFieldGroup size="small" error={errors.BasicPrice}>
                  <MobxLabel htmlFor="BasicPrice">{this.props.t('SoldFinance.basic_price')}</MobxLabel>
                  <CurrencyInput
                    value={this.formData.BasicPrice}
                    onChange={this.setBasicPrice}
                    id="BasicPrice"
                    tabIndex="6"
                  />
                </MobxFieldGroup>

                <MobxFieldGroup size="small" error={errors.VAT}>
                  <MobxLabel htmlFor="VAT">{this.props.t('SoldFinance.vat_percent')}</MobxLabel>
                  <PercentageInput value={this.formData.VAT} onChange={this.setVAT} id="VATPercent" tabIndex="7" />
                </MobxFieldGroup>

                <MobxFieldGroup size="small" error={errors.VATAmount}>
                  <MobxLabel htmlFor="VATAmount">{this.props.t('SoldFinance.vat_amount')}</MobxLabel>
                  <CurrencyInput
                    value={this.formData.VATAmount}
                    onChange={this.setVATAmount}
                    id="VATAmount"
                    tabIndex="8"
                  />
                </MobxFieldGroup>
              </div>
            )}

            {this.formData.NonVatableItems ||
            this.formData.AccessoriesInsurance ||
            this.formData.AccessoriesWarranty ||
            this.formData.AccessoriesOther ? (
              <div>
                <MobxFieldGroup size="small" error={errors.NonVatableItems}>
                  <MobxLabel htmlFor="NonVatableItems">{this.props.t('SoldFinance.non_va_table_items')}</MobxLabel>
                  <CurrencyInput
                    value={this.formData.NonVatableItems}
                    onChange={this.setNonVatableItems}
                    id="NonVatableItems"
                    tabIndex="9"
                  />
                </MobxFieldGroup>

                <MobxFieldGroup size="small" error={errors.AccessoriesInsurance}>
                  <MobxLabel htmlFor="AccessoriesInsurance">
                    {this.props.t('SoldFinance.accessories_insurance')}
                  </MobxLabel>
                  <CurrencyInput
                    value={this.formData.AccessoriesInsurance}
                    onChange={this.setAccessoriesInsurance}
                    id="AccessoriesInsurance"
                    tabIndex="10"
                  />
                </MobxFieldGroup>

                <MobxFieldGroup size="small" error={errors.AccessoriesWarranty}>
                  <MobxLabel htmlFor="AccessoriesWarranty">
                    {this.props.t('SoldFinance.accessories_warranty')}
                  </MobxLabel>
                  <CurrencyInput
                    value={this.formData.AccessoriesWarranty}
                    onChange={this.setAccessoriesWarranty}
                    id="AccessoriesWarranty"
                    tabIndex="11"
                  />
                </MobxFieldGroup>

                <MobxFieldGroup size="small" error={errors.AccessoriesOther}>
                  <MobxLabel htmlFor="AccessoriesOther">{this.props.t('SoldFinance.accessories_other')}</MobxLabel>
                  <CurrencyInput
                    value={this.formData.AccessoriesOther}
                    onChange={this.setAccessoriesOther}
                    id="AccessoriesOther"
                    tabIndex="12"
                  />
                </MobxFieldGroup>
              </div>
            ) : (
              <div className="soldFinance__vapsButtonContainer">
                {this.formData.ValueAddedProducts &&
                  this.formData.ValueAddedProducts.map((vap, index) => (
                    <ValueAddedProduct
                      key={`valueAddedProduct-${index}`}
                      index={index}
                      label={vap.ProductTypeText}
                      value={vap.Price}
                      onChange={this.setVapPrice}
                      onRemove={() => this.removeVap(index)}
                      onEdit={() => this.editVap(index)}
                      error={errors.ValueAddedProducts && errors.ValueAddedProducts[index]}
                      size="small"
                      isInline={false}
                    />
                  ))}
                <div className="soldFinance__vapsButton">
                  <Button type="button" buttonStyle="secondary" onClick={this.openAddVapModal}>
                    {this.props.t('SoldFinance.add_vap')}
                  </Button>
                </div>
              </div>
            )}

            <MobxFieldGroup size="small" error={errors.CashDeposit}>
              <MobxLabel htmlFor="CashDeposit">{this.props.t('SoldFinance.cash_deposit')}</MobxLabel>
              <CurrencyInput
                value={this.formData.CashDeposit}
                onChange={this.setCashDeposit}
                id="CashDeposit"
                tabIndex="13"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.PartExchange}>
              <MobxLabel htmlFor="PartExchange">{this.props.t('SoldFinance.part_exchange_value')}</MobxLabel>
              <CurrencyInput
                value={this.formData.PartExchange}
                onChange={this.setPartExchange}
                id="PartExchangeValue"
                tabIndex="14"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.Settlement}>
              <MobxLabel htmlFor="Settlement">{this.props.t('SoldFinance.settlement_value')}</MobxLabel>
              <CurrencyInput
                value={this.formData.Settlement}
                onChange={this.setSettlement}
                id="SettlementValue"
                tabIndex="15"
              />
            </MobxFieldGroup>

            <div className="soldFinance__asterisk">
              {this.props.t('SoldFinance.these_items_are_included_in_the_total_price')}
            </div>
          </div>
        </div>
        <div className="soldFinance__formRight">
          <div className="soldFinance__right" key="rightInner">
            <div className="soldFinance__mediaDivider" key="divider41" />
            <MobxFieldGroup size="small" error={errors.FunderCode}>
              <MobxLabel htmlFor="FunderCode">{this.props.t('SoldFinance.lender')}</MobxLabel>
              <SelectInput
                value={this.formData.FunderCode}
                onChange={this.setFunderCode}
                tabIndex="16"
                options={optionKeys.Lender}
                id="Lenders"
              />
            </MobxFieldGroup>

            {this.formData.FunderCode === 'Other' && (
              <MobxFieldGroup size="small" error={errors.OtherFunder}>
                <MobxLabel htmlFor="OtherFunder">{this.props.t('SoldFinance.please_specify')}</MobxLabel>
                <TextInputWrappingInput
                  value={this.formData.OtherFunder}
                  onChange={this.setOtherFunder}
                  id="LenderOther"
                  tabIndex="17"
                  type="text"
                />
              </MobxFieldGroup>
            )}

            <MobxFieldGroup size="small" error={errors.FinanceType}>
              <MobxLabel htmlFor="Product">{this.props.t('SoldFinance.product')}</MobxLabel>
              <SelectInput
                value={this.formData.FinanceType}
                onChange={this.setFinanceType}
                tabIndex="18"
                options={optionKeys.Product}
                id="ProductType"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.Advance}>
              <MobxLabel htmlFor="Advance">{this.props.t('SoldFinance.amount_of_advance')}</MobxLabel>
              <CurrencyInput
                value={this.formData.Advance}
                onChange={this.setAdvance}
                id="AmountOfAdvance"
                tabIndex="19"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.Term}>
              <MobxLabel htmlFor="Term">{this.props.t('SoldFinance.agreement_term')}</MobxLabel>
              <SelectInput
                value={this.formData.Term}
                onChange={this.setTerm}
                tabIndex="20"
                type="select"
                options="CloseDealTerms"
                id="Advance"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.FirstPayment}>
              <MobxLabel htmlFor="FirstPayment">{this.props.t('SoldFinance.first_payment')}</MobxLabel>
              <CurrencyInput
                value={this.formData.FirstPayment}
                onChange={this.setFirstPayment}
                id="FirstPayment"
                tabIndex="21"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.FollowedBy}>
              <MobxLabel htmlFor="FollowedBy">{this.props.t('SoldFinance.followed_by_number_of_payments')}</MobxLabel>
              <TextInputWrappingInput
                value={this.formData.FollowedBy}
                onChange={this.setFollowedBy}
                id="FollowedBy"
                tabIndex="22"
                type="tel"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.FollowingPayments}>
              <MobxLabel htmlFor="FollowingPayments">{this.props.t('SoldFinance.payments_of')}</MobxLabel>
              <CurrencyInput
                value={this.formData.FollowingPayments}
                onChange={this.setFollowingPayments}
                id="FollowingPayments"
                tabIndex="23"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.FinalPayment}>
              <MobxLabel htmlFor="FinalPayment">{this.props.t('SoldFinance.final_payment')}</MobxLabel>
              <CurrencyInput
                value={this.formData.FinalPayment}
                onChange={this.setFinalPayment}
                id="FinalPayment"
                tabIndex="24"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.TotalAmountPayable}>
              <MobxLabel htmlFor="TotalAmountPayable">{this.props.t('SoldFinance.total_amount_payable')}</MobxLabel>
              <CurrencyInput
                value={this.formData.TotalAmountPayable}
                onChange={this.setTotalAmountPayable}
                id="TotalAmountPayable"
                tabIndex="25"
              />
            </MobxFieldGroup>

            <div className="soldFinance__divider" key="divider1" />

            <MobxFieldGroup size="small" error={errors.AnnualDistanceQuoted}>
              <MobxLabel htmlFor="AnnualDistanceQuoted">{this.props.t('SoldFinance.mileage_p_a')}</MobxLabel>
              <SelectInput
                value={this.formData.AnnualDistanceQuoted}
                onChange={this.setAnnualDistanceQuoted}
                id="Mileage"
                tabIndex="26"
                type="select"
                options="MileageOptions"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.ChargePerOverDistanceUnit}>
              <MobxLabel htmlFor="ChargePerOverDistanceUnit">{this.props.t('SoldFinance.excess_per_mile')}</MobxLabel>
              <CurrencyInput
                value={this.formData.ChargePerOverDistanceUnit}
                onChange={this.setChargePerOverDistanceUnit}
                id="ChargePerOverDistanceUnit"
                tabIndex="27"
              />
            </MobxFieldGroup>

            <div className="soldFinance__divider" key="divider2" />

            <MobxFieldGroup size="small" error={errors.RepresentativeApr}>
              <MobxLabel htmlFor="RepresentativeApr">{this.props.t('SoldFinance.apr')}</MobxLabel>
              <PercentageInput
                size="small"
                value={this.formData.RepresentativeApr}
                onChange={this.setRepresentativeApr}
                id="RepresentativeApr"
                tabIndex="28"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.FlatRate}>
              <MobxLabel htmlFor="FlatRate">{this.props.t('SoldFinance.annual_fixed_interest_rate')}</MobxLabel>
              <PercentageInput
                size="small"
                value={this.formData.FlatRate}
                onChange={this.setFlatRate}
                id="FlatRate"
                tabIndex="29"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.InterestCharges}>
              <MobxLabel htmlFor="InterestCharges">{this.props.t('SoldFinance.interest_charges')}</MobxLabel>
              <CurrencyInput
                value={this.formData.InterestCharges}
                onChange={this.setInterestCharges}
                id="InterestCharges"
                tabIndex="30"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.AcceptanceFeeLast}>
              <MobxLabel htmlFor="AcceptanceFeeLast">{this.props.t('SoldFinance.administration_fee_s')}</MobxLabel>
              <CurrencyInput
                value={this.formData.AcceptanceFeeLast}
                onChange={this.setAcceptanceFeeLast}
                id="AdministrationFee"
                tabIndex="31"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.OptionToPurchaseFee}>
              <MobxLabel htmlFor="OptionToPurchaseFee">{this.props.t('SoldFinance.option_to_purchase_fee')}</MobxLabel>
              <CurrencyInput
                value={this.formData.OptionToPurchaseFee}
                onChange={this.setOptionToPurchaseFee}
                id="OptionToPurchaseFee"
                tabIndex="32"
              />
            </MobxFieldGroup>

            <div className="soldFinance__divider" key="divider3" />

            <MobxFieldGroup size="small" error={errors.Commission}>
              <MobxLabel htmlFor="Commission">{this.props.t('SoldFinance.commission')}</MobxLabel>
              <CurrencyInput
                value={this.formData.Commission}
                onChange={this.setCommission}
                id="Commission"
                tabIndex="33"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.AdditionalCommission}>
              <MobxLabel htmlFor="AdditionalCommission">{this.props.t('SoldFinance.additional_commission')}</MobxLabel>
              <CurrencyInput
                value={this.formData.AdditionalCommission}
                onChange={this.setAdditionalCommission}
                id="AdditionalCommission"
                tabIndex="34"
              />
            </MobxFieldGroup>
          </div>
        </div>
        <div className="soldFinance__bottom">
          <div className="soldFinance__divider" key="divider5" />

          <MobxFieldGroup error={errors.Notes}>
            <MobxLabel htmlFor="Notes">{this.props.t('SoldFinance.close_out_comment')}</MobxLabel>
            <TextInputWrappingInput
              value={this.formData.Notes}
              onChange={this.setNotes}
              tabIndex="35"
              id="CloseOutComment"
              type="text"
            />
          </MobxFieldGroup>

          {uiState.canViewDashboard && (
            <>
              <div className="soldFinance__infoWarning" key="info2">
                <InformationWarning
                  text={this.props.t(
                    'SoldFinance.selecting_to_hide_the_deal_will_remove_visibility_from_the_homepage_dashboard_the_details_will_still_be_accessible_from_the_contacts_area_of_the_platform_removing_dormant_deals_from_your_dashboard_is_a_good_house_keeping_practice'
                  )}
                />
              </div>
              <div className="soldFinance__checkbox">
                <CheckboxWithLabel
                  onChange={this.toggleRemoveFromDashboard}
                  value={this.formData.RemoveFromDashboard}
                  id="removeFromDashboard"
                >
                  {this.props.t('SoldFinance.hide_this_lead_from_the_homepage_after_closing')}
                </CheckboxWithLabel>
              </div>
            </>
          )}
        </div>
        <div className="soldFinance__buttons">
          <div className="soldFinance__proceedButton">
            <Button
              type="submit"
              isLoading={closeDealStore.sendCloseDealRequest.isLoading}
              hasError={closeDealStore.sendCloseDealRequest.hasError}
            >
              {this.props.t('SoldFinance.proceed')}
            </Button>
          </div>
          <div className="soldFinance__cancelButton">
            <Button buttonStyle="cancel" type="button" onClick={this.goBack}>
              {this.props.t('SoldFinance.cancel')}
            </Button>
          </div>
        </div>

        <AddVapModal
          isOpen={this.state.isAddVapModalOpen}
          onClose={this.closeAddVapModal}
          onSubmit={this.handleSubmitAddVapModal}
          currentVaps={this.formData.ValueAddedProducts}
          vehicleClass={vehicle.Class}
        />

        <EditVapModal
          isOpen={this.state.isEditVapModalOpen}
          onClose={this.closeAddVapModal}
          onSubmit={this.handleSubmitEditVapModal}
          vapToEdit={this.formData.ValueAddedProducts[this.state.vapToEdit]}
          currentVaps={this.formData.ValueAddedProducts}
          vehicleClass={vehicle.Class}
        />
      </MobxForm>
    );
  }
}

SoldFinance.propTypes = {
  appStore: PropTypes.object,
  params: PropTypes.object
};

export default compose(
  withParams,
  withNavigate,
  withTranslation('CloseDeal'),
  inject(['appStore']),
  observer
)(SoldFinance);
