import { useCallback } from 'react';
import { useVisLookupContext, SET_QUERY } from '../../context/VisLookupContext';

const useSearchQuery = () => {
  const { state, dispatch } = useVisLookupContext();

  const refine = useCallback(
    (query) =>
      dispatch({
        type: SET_QUERY,
        payload: query
      }),
    [dispatch]
  );

  return {
    refine,
    query: state.query
  };
};

export default useSearchQuery;
