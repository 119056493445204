import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import DateFormat from '../../Common/DateFormat';
import BusinessTag from '../../Common/BusinessTag';
import DashboardLinkedUsers from '../../Dashboard/components/DashboardLinkedUsers';
import ConsumerName from '../../Common/ConsumerName';
import { titleCase } from '../../../core/helpers';
import AssignLeadContainer from '../../AssignLead/containers/AssignLeadContainer';
import classnames from 'classnames';
import './customerListItem.scss';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

const CustomerListItem = (props) => {
  const customer = props.customer;
  const nameLinkClassNames = classnames(
    'customerListItem__link',
    customer.customerType === 'corporate' && 'customerListItem__link--business',
    customer.customerType === 'consumer' && customer.aliasCount > 0 && 'customerListItem__link--hasAliases'
  );
  const linkedUsersStyles = classnames(
    customer.customerType === 'consumer' && customer.aliasCount > 0 && 'customerListItem__linkedUsersWarning'
  );
  const customerName =
    customer.customerType === 'corporate'
      ? customer.tradingName
      : `${customer.firstName} ${`${customer.middleName} `}${customer.surname}`;
  return (
    <tr className="customerListItem" data-th="CustomerListItem">
      <td className="customerListItem__td" key="td1">
        <Link
          className={nameLinkClassNames}
          to={`/d/${props.customer.dealershipId}/consumers/${props.customer.id}`}
          data-id={customer.id}
        >
          {customer.customerType === 'corporate' ? (
            <>
              <span className="customerListItem__name">{customerName}</span>
              <span className="customerListItem__phoneNumber">{customer.landline || customer.mobile || ''}</span>
            </>
          ) : (
            <>
              <span className="customerListItem__name">
                <ConsumerName name={customerName} />
              </span>
              <span className="customerListItem__phoneNumber">{customer.mobile || customer.landline || ''}</span>
            </>
          )}
          <BusinessTag userType={customer.customerType} />
          <div className={linkedUsersStyles}>
            <DashboardLinkedUsers aliasCount={customer.aliasCount} />
          </div>
        </Link>
      </td>
      <td className="customerListItem__td" key="td2">
        <Link
          className="customerListItem__link"
          to={`/d/${props.customer.dealershipId}/consumers/${props.customer.id}`}
          data-id={customer.id}
        >
          <span className="customerListItem__vehicleMakeModel">
            {customer.latestVehicle ? titleCase(`${customer.latestVehicle.make} ${customer.latestVehicle.model}`) : ''}
          </span>
          <span className="customerListItem__vehicleDerivative">
            {customer.latestVehicle && customer.latestVehicle.derivative
              ? titleCase(customer.latestVehicle.derivative)
              : ''}
          </span>
        </Link>
      </td>
      <td className="customerListItem__td" key="td3">
        <Link
          className="customerListItem__link"
          to={`/d/${props.customer.dealershipId}/consumers/${props.customer.id}`}
          data-id={customer.id}
        >
          <DateFormat value={customer.createdDateTimestamp} format="calendar" parse="utc" />
        </Link>
      </td>
      <td className="customerListItem__td" key="td4">
        <Link
          className="customerListItem__link"
          to={`/d/${props.customer.dealershipId}/consumers/${props.customer.id}`}
          data-id={customer.id}
        >
          <DateFormat value={customer.lastModifiedTimestamp} format="calendar" parse="utc" />
        </Link>
      </td>
      <td className="customerListItem__td customerListItem__assignLead" key="td5">
        <Link
          className="customerListItem__link__assignLead"
          to={`/d/${props.customer.dealershipId}/consumers/${props.customer.id}`}
          data-id={customer.id}
        >
          <AssignLeadContainer
            dealershipId={props.dealershipId}
            customerId={customer.id}
            customerName={customerName}
            customerType={customer.customerType}
            initialAssignees={customer.assignedTo}
          />
        </Link>
      </td>
    </tr>
  );
};

CustomerListItem.propTypes = {
  customer: PropTypes.object.isRequired,
  dealershipId: PropTypes.string
};

export default withTranslation('CustomerList')(observer(CustomerListItem));
