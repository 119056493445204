import produce from 'immer';
import defaultRules, { LenderCorporateConfig } from './default';

export default produce(defaultRules, (draft: LenderCorporateConfig) => {
  delete draft.applicationStatuses.Other;
  delete draft.applicationStatuses.Prospect;
  delete draft.applicationStatuses.Unknown;
  draft.applicationStatuses.WaitingForFurtherInformation!.isReproposable = true;
  draft.applicationStatuses.WaitingForFurtherInformation!.isComplete = true;
  draft.applicationStatuses.Error!.isReproposable = false;
  draft.applicationStatuses.NotProceeding!.isEditable = false;
  draft.applicationStatuses.NotProceeding!.isReproposable = true;
  draft.applicationStatuses.NotTakenUp!.isEditable = false;
  draft.applicationStatuses.Pending!.isPending = true;
  draft.applicationStatuses.ProblemPayout!.isReproposable = true;
  draft.applicationStatuses.Referred!.isEditable = false;
  draft.applicationStatuses.Referred!.isReproposable = false;
  draft.applicationStatuses.Rejected!.isEditable = false;
  draft.applicationStatuses.SentForPayout!.isEditable = false;
  draft.applicationStatuses.Submitted!.isSubmitting = true;
  draft.editSections.affordability = true;
});
