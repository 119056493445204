import { parsePaymentSchedules } from './quoteParser';
import moment from 'moment';

export const cfcCheckDetailsDataParser = (data) => {
  if (data && data.Quote) {
    data.Quote.PaymentSchedule = parsePaymentSchedules(data.Quote);
  }

  if (data && data.PersonalDetails && data.PersonalDetails.DateOfBirth) {
    data.PersonalDetails.DateOfBirth = moment(data.PersonalDetails.DateOfBirth).format('DD/MM/YYYY');
  }

  return data;
};
