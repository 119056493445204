import { action, computed, observable } from 'mobx';
import Validator from '../validators/Validator';
import { calculateTotalMonthsFromCollection, isDateMoreThanXMonthsAgo } from '../core/helpers';
import { canValidateContactAgainstDob } from '~/features';
import { getYearsAndMonthsFromTotalMonths } from '~/utils/application';
class customerHistoryStore {
  @observable
  defaultEmploymentFormData = {
    BuildingName: '',
    BuildingNumber: '',
    Country: '',
    County: '',
    District: '',
    Employer: '',
    EmployerSector: '',
    GrossAnnual: '',
    Occupation: '',
    OccupationBasis: '',
    OccupationType: '',
    Organisation: '',
    PostTown: '',
    Postcode: '',
    Street: '',
    SubBuilding: '',
    TelephoneNumber: '',
    TimeAtEmployment: { Months: 0, TotalMonths: 0, Years: 0 },
    isEditing: true
  };

  @observable
  defaultAddressFormData = {
    BuildingName: '',
    BuildingNumber: '',
    Country: '',
    County: '',
    District: '',
    Organisation: '',
    PostTown: '',
    Postcode: '',
    Residency: '',
    Street: '',
    SubBuilding: '',
    TimeAtAddress: {
      Months: 0,
      TotalMonths: 0,
      Years: 0
    },
    isEditing: true
  };

  @observable
  formData = {};

  @observable
  application = {};

  @observable
  isFormSubmitted = false;

  @observable
  formErrors = 0;

  @computed
  get errorCount() {
    const value = this.formData.Items.reduce((acc, item, i) => acc + this.countErrors(i), 0);
    return value;
  }

  @action
  setInitialValues = (formName, data) => {
    this.formData =
      data && data.Items.length > 0
        ? data
        : {
            Items: [formName === 'EmploymentHistory' ? this.defaultEmploymentFormData : this.defaultAddressFormData]
          };
    this.showTimeAtAddressWarning = false;
    this.showTimeAtAddressDOBWarning = false;
    this.isFormSubmitted = false;
    this.validator.validate(this.formData.Items[this.getEditingIndex()]);
  };

  @action
  setApplication = (application) => (this.application = application);

  @action
  setupValidation(fieldValidationRules) {
    this.validator = new Validator();
    this.validator.setRules(fieldValidationRules);
  }

  @action
  setEditingFalse = () => {
    const currentState = this.formData;
    const newState = currentState.Items.map((item) => ({ ...item, isEditing: false }));
    this.formData = {
      ...currentState,
      Items: newState
    };
  };

  @action
  handleEditAddress = (index) => {
    this.setEditingFalse();
    this.showTimeAtAddressWarning = false;
    this.showTimeAtAddressDOBWarning = false;
    this.isFormSubmitted = false;
    const currentState = this.formData;
    const newState = currentState.Items.map((item, i) => (i === index ? { ...item, isEditing: true } : item));
    this.validator.validate(newState[index]);
    this.formData = {
      ...currentState,
      Items: newState
    };
  };

  @action
  setErrorsToState = () => {
    if (this.formData.Items.length) {
      this.formData.Items.forEach((item, index) => {
        this.validator.validate(item);
        const errors = this.validator.getErrors();
        const currentState = this.formData;
        const newStateArray = currentState.Items.map((item, i) =>
          i === index ? { ...item, validationErrors: errors } : item
        );

        this.formData = {
          ...currentState,
          Items: newStateArray
        };
      });
    }
    this.validator.validate(this.formData.Items[this.getEditingIndex()]);
  };

  @action
  countErrors = (index, count = 0) => {
    const validationErrors = this.formData.Items[index].validationErrors;
    if (typeof validationErrors === 'object') {
      return (count = Object.keys(validationErrors).length);
    } else {
      return 0;
    }
  };

  @action
  handlePostCodeLookup = (address, index) => {
    const {
      BuildingName,
      BuildingNumber,
      CountryName,
      ProvinceName,
      District,
      Company,
      PostTown,
      Postcode,
      Street,
      SubBuilding
    } = address;

    const addressObj = this.formData.Items[index];
    Object.assign(addressObj, {
      BuildingName: BuildingName,
      BuildingNumber: BuildingNumber,
      Company: Company,
      CountryName: CountryName,
      ProvinceName: ProvinceName,
      Street: Street,
      District: District,
      PostTown: PostTown,
      Postcode: Postcode,
      SubBuilding: SubBuilding
    });
    this.validator.validate(addressObj);
    this.formData.Items[index] = { ...addressObj };
  };

  @action
  handleFieldChange = (id, value, index) => {
    const currentState = this.formData;
    const newState = currentState.Items.map((item, i) => (i === index ? { ...item, [id]: value } : item));
    this.formData = {
      ...currentState,
      Items: newState
    };

    this.validator.validate(newState[index]);
  };

  @action
  handleFieldChangeYM = (value, index, key) => {
    const { years, months } = getYearsAndMonthsFromTotalMonths(value);

    const newState = this.formData.Items[index];
    Object.assign(newState, {
      ...newState,
      [key]: {
        TotalMonths: value,
        Years: years,
        Months: months
      }
    });
    this.validator.validate(newState);
    this.formData.Items[index] = { ...newState };

    this.calculateTotalMonths(key);
  };

  @action
  calculateTotalMonths = (key) => {
    this.formData = {
      ...this.formData,
      totalMonths: calculateTotalMonthsFromCollection(this.formData.Items.slice(), key)
    };
  };

  @action
  getEditingIndex = () => {
    let currentlyEditingItem;
    this.formData.Items.forEach((item, i) => {
      if (item.isEditing === true) {
        currentlyEditingItem = i;
      }
    });
    return currentlyEditingItem;
  };

  @action
  addItem = (formName) => {
    let defaultFormData = {};
    if (formName === 'Address') {
      defaultFormData = this.defaultAddressFormData;
    }
    if (formName === 'Employment') {
      defaultFormData = this.defaultEmploymentFormData;
    }
    this.setEditingFalse();
    this.formData = {
      ...this.formData,
      Items: [...this.formData.Items, defaultFormData]
    };
    this.showTimeAtAddressWarning = false;
    this.showTimeAtAddressDOBWarning = false;
    this.isFormSubmitted = false;
    this.setErrorsToState();
  };

  @action
  checkWarnings = () => {
    this.showTimeAtAddressWarning = this.formData.totalMonths < 36;
    this.showTimeAtAddressDOBWarning = this.shouldShowDOBWarning();
    this.isFormSubmitted = true;
    this.setErrorsToState();
  };

  shouldShowDOBWarning = () => {
    if (canValidateContactAgainstDob()) {
      const dob = this.application.PersonalDetails.DateOfBirth;
      const totalMonths = this.formData.totalMonths;
      return !isDateMoreThanXMonthsAgo(dob, totalMonths);
    }
    return false;
  };

  @action
  handleRemoveAddress = (index, key) => {
    let newFormData = this.formData.Items.filter((item, i) => i !== index);
    this.formData = {
      ...this.formData,
      Items: newFormData,
      totalMonths: calculateTotalMonthsFromCollection(newFormData, key)
    };
    this.validator.validate(this.formData.Items[this.getEditingIndex()]);
  };

  @action
  openModal = (name) => {
    this.formData = { ...this.formData, modalOpen: name };
  };

  @action
  closeModal = () => {
    this.formData = { ...this.formData, modalOpen: '' };
  };
}

export default customerHistoryStore;
