import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ToggleSwitch from '../Common/ToggleSwitch';
import Icon from '../Common/Icon/Icon';
import TrackEvent from '../Common/Tracking/TrackEvent';
import startCase from 'lodash/startCase';
import useDidUpdateEffect from '~/hooks/useDidUpdateEffect';
import './permissionsGroup.scss';

const PermissionsGroup = ({
  groupId,
  label,
  children,
  openSettingGroups,
  handleToggleOpen,
  handleToggleItem,
  disabled,
  trackingPage,
  toggleAdmin
}) => {
  const childArray = React.Children.toArray(children).filter(Boolean);
  const isOpen = openSettingGroups.has(groupId);
  const hasChildren = childArray.length > 0;
  const hasMultipleChildren = childArray.length > 1;
  const containerClasses = classNames('permissionsGroup', disabled && 'permissionsGroup--disabled');
  const headerClasses = classNames('permissionsGroup__header', !hasChildren && 'permissionsGroup__header--readonly');
  const headerRightClasses = classNames(
    'permissionsGroup__header__right',
    !isOpen && 'permissionsGroup__header--readonly',
    disabled && 'permissionsGroup__header--readonly'
  );
  const buttonClasses = classNames(
    'permissionsGroup__header__toggle__chevron',
    isOpen && 'permissionsGroup__header__toggle__chevron--open'
  );
  const groupLabel = startCase(groupId)
    .split(' ')
    .join('');

  const isActiveAll = childArray.every((child) => child.props.isActive);

  useDidUpdateEffect(() => {
    if (toggleAdmin !== 'undefined') {
      handleToggleBool(toggleAdmin);
    }
  }, [toggleAdmin]);

  const handleToggleBool = (bool) => {
    childArray.forEach((child) => {
      handleToggleItem(bool, child.props.id);
    });
  };

  const handleToggleAll = () => {
    childArray.forEach((child) => {
      handleToggleItem(!isActiveAll, child.props.id);
    });
  };

  const handleToggleGroup = () => {
    handleToggleOpen && handleToggleOpen(groupId);
  };

  const toggleItem = (bool, id) => {
    handleToggleItem(bool, id);
  };
  return (
    <div className={containerClasses}>
      <div className={headerClasses}>
        <div className="permissionsGroup__header__left" onClick={handleToggleGroup}>
          {label}
        </div>
        <div className={headerRightClasses}>
          {isOpen && hasMultipleChildren && !disabled && (
            <div className="permissionsGroup__header__toggle">
              <div className="permissionsGroup__header__toggle__label">All</div>
              <TrackEvent
                featureName={`toggleAllPerms${groupLabel}Group`}
                interactionName={`toggleAllPerms${groupLabel}Group${isActiveAll ? 'Disabled' : 'Enabled'}`}
              >
                <ToggleSwitch
                  dataTag={`${groupId}SettingGroupToggleAll`}
                  isActive={isActiveAll}
                  handleUpdate={handleToggleAll}
                />
              </TrackEvent>
            </div>
          )}
        </div>
        <button className={buttonClasses} type="button" onClick={handleToggleGroup}>
          <Icon name="down" />
        </button>
      </div>
      <div className="permissionsGroup__itemGroup">
        {isOpen &&
          childArray.map((child, index) =>
            React.cloneElement(child, {
              key: index,
              handleToggle: toggleItem,
              disabled: child?.props.disabled || disabled,
              trackingPage
            })
          )}
      </div>
    </div>
  );
};

PermissionsGroup.defaultProps = {
  openSettingGroups: new Set(),
  disabled: false
};

PermissionsGroup.propTypes = {
  groupId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  handleToggleItem: PropTypes.func,
  isActiveAll: PropTypes.bool
};

export default PermissionsGroup;
