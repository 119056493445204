import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import ImageWithFallback from './components/ImageWithFallback';
import { CameraIcon, VideoCameraIcon } from '../../../../../../../../shared/icons';

import car from '~/styles/icons/car-placeholder-v2.svg';
import bike from '~/styles/icons/bike-placeholder-v2.svg';
import lcv from '~/styles/icons/lcv-placeholder-v2.svg';
import motorhome from '~/styles/icons/motorhome-placeholder-v2.svg';

import s from './vehicleImage.module.scss';
import { VehicleClass } from '~/types/vehicle';
import { getImageUrl } from '~/modules/Stock/routes/stock/shared/helpers/vehicleImages/getImageUrl';

interface Props {
  count?: number;
  imagesMetadata?: {
    fileName: string;
    fullPath: string;
  }[];
  imgUrls: string[];
  imgPrfx: string;
  vehicleClass?: VehicleClass;
  videosMetadata?: {
    url: string;
    source: string;
  }[];
  imgHr: string;
}
const VehicleImage = ({ videosMetadata = [], vehicleClass, imgHr, imgUrls, imgPrfx, count = 0 }: Props) => {
  const { t } = useTranslation('Stock');

  const placeHolder = (vehicleClass?: string) => {
    const vehicleClassLowerCase = vehicleClass?.toLowerCase();
    switch (vehicleClassLowerCase) {
      case VehicleClass.car:
        return car;
      case VehicleClass.bike:
      case VehicleClass.motorbike:
        return bike;
      case VehicleClass.lcv:
        return lcv;
      case VehicleClass.motorhome:
      case VehicleClass.touringCaravan:
        return motorhome;
      default:
        return car;
    }
  };

  const videoIcon = (
    <>
      <VideoCameraIcon /> {t('StockListItem.video')}
    </>
  );

  const displayVideoIdentifier = videosMetadata?.length ? videoIcon : null;

  //  When we pass undefined to <ImageWithFallback /> it won't attempt to fetch reducing 404 errors in console
  //  We'll still see 404 as some images don't exist in test even tho we have a url for them
  const imgUrl0 = getImageUrl({ imgHr, imgPrfx, imgUrls, imageNumber: 0, params: 'height=350&mode=max' })
  if (count >= 4) {
    const imgUrl1 = getImageUrl({ imgHr, imgPrfx, imgUrls, imageNumber: 1, params: 'height=250&mode=max' });
    const imgUrl2 = getImageUrl({ imgHr, imgPrfx, imgUrls, imageNumber: 2, params: 'height=250&mode=max' });
    const imgUrl3 = getImageUrl({ imgHr, imgPrfx, imgUrls, imageNumber: 3, params: 'height=250&mode=max' });
    return (
      <>
        <div data-th="imageVideoIdentifier" className={cn(s['imageCount'])}>
          <CameraIcon />
          <span data-th="imageCount">{count}</span>
          {displayVideoIdentifier}
        </div>
        <div className={cn(s['mainImage'], s.aspectRatio)}>
          <ImageWithFallback placeholder={placeHolder(vehicleClass)} imgUrl={imgUrl0} />
        </div>
        <div className={cn(s['imageReel'])}>
          <div className={cn(s['miniImage'])}>
            <ImageWithFallback placeholder={placeHolder(vehicleClass)} imgUrl={imgUrl1} />
          </div>
          <div className={cn(s['miniImage'])}>
            <ImageWithFallback placeholder={placeHolder(vehicleClass)} imgUrl={imgUrl2} />
          </div>
          <div className={cn(s['miniImage'])}>
            <ImageWithFallback placeholder={placeHolder(vehicleClass)} imgUrl={imgUrl3} />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div data-th="imageVideoIdentifier" className={cn(s['imageCount'])}>
        <CameraIcon />
        <span data-th="imageCount">{count}</span>
        {displayVideoIdentifier}
      </div>
      <div className={cn(s['SingleMainImage'], s.aspectRatio)}>
        <ImageWithFallback placeholder={placeHolder(vehicleClass)} imgUrl={imgUrl0 ? imgUrl0 : undefined} />
      </div>
    </>
  );
};

export default VehicleImage;
