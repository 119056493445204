import React from 'react';
import PropTypes from 'prop-types';
import './collapsibleCardInformationItem.scss';
import classnames from 'classnames';

const CollapsibleCardInformationItem = ({ value, formatter: Formatter, label, subcategory }) => {
  let classes = classnames(
    'collapsibleCardInformationItem',
    subcategory && 'collapsibleCardInformationItem__subcategory'
  );

  return (
    <div className={classes}>
      {label}
      <div className="collapsibleCardInformationItem__value">{Formatter ? <Formatter value={value} /> : value}</div>
    </div>
  );
};

CollapsibleCardInformationItem.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  formatter: PropTypes.any,
  value: PropTypes.any,
  subcategory: PropTypes.bool
};

export default CollapsibleCardInformationItem;
