import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import VehicleForm from '../../Common/forms/VehicleForm';
import { compose } from 'redux';
import { withNavigate, withOutletContextProps, withParams, withQuery } from '../../../../src/hocs/router';
import VisQuotePage from '../../VisQuoting/VisQuotePage';
import { inject } from 'mobx-react';

@inject('appStore')
class QuickQuoteVehicleDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      defaults: {},
      vehicleClass: ''
    };
  }
  componentDidMount() {
    this.getDefaultSettings();
  }

  getDefaultSettings = () => {
    const settings = _.chain(this.props.dealerships)
      .find({ Id: this.props.params.dealershipId })
      .get('Settings')
      .value();

    let condition;
    let vehicleClass;
    if (this.props.query.vehicleType) {
      condition = this.props.query.vehicleType;
    } else {
      condition = settings.VehicleType.toLowerCase();
    }

    if (this.props.query.class) {
      vehicleClass = this.props.query.class;
    } else {
      vehicleClass = settings.VehicleClass.toLowerCase();
    }

    let data = {
      ...this.state.defaults,
      Condition: condition,
      Class: vehicleClass
    };

    if (this.props.query.vrm) {
      data.Vrm = this.props.query.vrm.toUpperCase();
    }

    if (this.props.query.mileage) {
      data.Mileage = this.props.query.mileage.toString();
    }

    if (this.props.query.price) {
      data.Price = this.props.query.price;
    }

    this.setState({ vehicleClass, defaults: data });
  };

  handleCancel = () => {
    this.props.navigate(-1);
  };

  updateVehicleClass = (vehicleClass) => {
    this.setState((prevState) => {
      return { vehicleClass: vehicleClass, defaults: { ...prevState.defaults, Class: vehicleClass } };
    });
  };

  render() {
    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: 'Home',
              path: `/d/${this.props.params.dealershipId}`
            },
            {
              name: `${this.props.t('QuickQuoteVehicleDetails.quick_quote')}`
            }
          ]}
        />
        <Panel>
          <PanelHeader>{this.props.t('QuickQuoteVehicleDetails.vehicle_details')}</PanelHeader>
          <PanelContent>
            {this.props.appStore.uiState.shouldUseVisLookup(this.state.vehicleClass) ? (
              <VisQuotePage
                onCancel={this.handleCancel}
                onSubmit={this.props.onVehicleChange}
                onUpdateVehicleClass={this.updateVehicleClass}
                initialData={this.state.defaults}
                searchTerms={this.props.query.searchTerms}
                dealershipId={this.props.params.dealershipId}
              />
            ) : (
              <VehicleForm
                initialData={this.state.defaults}
                onCancel={this.handleCancel}
                onSubmit={this.props.onVehicleChange}
                autoLookup={true}
                submitLabel={this.props.t('QuickQuoteVehicleDetails.next')}
                onUpdateVehicleClass={this.updateVehicleClass}
                dealershipId={this.props.params.dealershipId}
              />
            )}
          </PanelContent>
        </Panel>
      </Page>
    );
  }
}

QuickQuoteVehicleDetails.propTypes = {
  query: PropTypes.object,
  params: PropTypes.object,
  dealerships: PropTypes.array,
  onVehicleChange: PropTypes.func
};

function mapStateToProps(state) {
  return {
    dealerships: state.session.Dealerships
  };
}

export default compose(
  withNavigate,
  withParams,
  withQuery,
  withOutletContextProps,
  withTranslation('Quoting'),
  connect(mapStateToProps)
)(QuickQuoteVehicleDetails);
