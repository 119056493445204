import React from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import cn from 'classnames';

import styles from './Tab.module.scss';

export const Tabs = ({ children, className, ...rest }) => (
  <TabsPrimitive.Root className={cn(styles.tabs, className)} {...rest}>
    {children}
  </TabsPrimitive.Root>
);

export const TabList = ({ children, className, ...rest }) => (
  <TabsPrimitive.List className={cn(styles.tabList, className)} {...rest}>
    {children}
  </TabsPrimitive.List>
);

export const Tab = ({ children, className, ...rest }) => (
  <TabsPrimitive.Trigger className={cn(styles.tab, className)} {...rest}>
    {children}
  </TabsPrimitive.Trigger>
);

export const TabPanel = ({ children, className, ...rest }) => (
  <TabsPrimitive.Content className={cn(styles.tabPanel, className)} {...rest}>
    {children}
  </TabsPrimitive.Content>
);
