import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getLenderUpdatesColor } from '../../../core/lenderUpdatesColor';
import './lenderStatus.scss';

const LenderStatus = ({ status, t }) => {
  const formattedStatus = status.replace(/\s+/g, '');

  return (
    <div className={`lenderStatus lenderStatus__${getLenderUpdatesColor(formattedStatus)}`}>
      {t(`LenderStatus.${formattedStatus}`)}
    </div>
  );
};

LenderStatus.propTypes = {
  status: PropTypes.string
};

export default withTranslation('ApplicationStatus')(LenderStatus);
