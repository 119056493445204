import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import callApiMiddleware from './middleware/callApiMiddleware';
import rootReducer from './redux/rootReducer';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './redux/rootSaga';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';

import createLogger from 'redux-logger';
import { Iterable } from 'immutable';

const stateTransformer = (state) => {
  let newState = {};

  for (let i of Object.keys(state)) {
    newState[i] = Iterable.isIterable(state[i]) ? state[i].toJS() : state[i];
  }

  return newState;
};

const sagaMiddleware = createSagaMiddleware();

let middleware = [thunkMiddleware, callApiMiddleware, sagaMiddleware];

// Use loggers when needed. Removing by default as it makes the console noisy
// if (process.env.NODE_ENV !== 'production') {
//   let loggerMiddleware = createLogger({ stateTransformer });
//   middleware = [...middleware, loggerMiddleware];
// }

const store = {
  ...createStore(rootReducer, composeWithDevToolsDevelopmentOnly(compose(applyMiddleware(...middleware)))),
  runSaga: sagaMiddleware.run
};

store.runSaga(rootSaga);

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('./redux/rootReducer', () => {
    const nextRootReducer = require('./redux/rootReducer');
    store.replaceReducer(nextRootReducer);
  });
}

export default store;
