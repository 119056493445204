import React from 'react';
import PropTypes from 'prop-types';
import VehicleImage from '../../../Common/VehicleImage';
import './consumerVehicleImage.scss';

const ConsumerVehicleImage = ({ vehicleImage, vehicleClass }) => {
  return (
    <div className="consumerVehicleImage">
      <VehicleImage
        imageSource={vehicleImage}
        iconFontSize="70px"
        paddingTop="5px"
        imageHeight="110px"
        imageWidth="140px"
        vehicleClass={vehicleClass}
      />
    </div>
  );
};

ConsumerVehicleImage.propTypes = {
  vehicleImage: PropTypes.string,
  vehicleClass: PropTypes.string
};

export default ConsumerVehicleImage;
