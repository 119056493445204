import { createQueryKeys } from '@lukemorales/query-key-factory';
import { fetchCfcCheckDetails } from '.';

export const SERVICE_PREFIX = 'cfcCheckDetails';

export const cfcCheckDetailsQueries = createQueryKeys(SERVICE_PREFIX, {
  details: ({ cfcProspectId, quoteId }: Parameters<typeof fetchCfcCheckDetails>['0']) => ({
    queryKey: [SERVICE_PREFIX, { cfcProspectId, quoteId }],
    queryFn: () => fetchCfcCheckDetails({ cfcProspectId, quoteId })
  })
});
