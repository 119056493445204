import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
} from 'chart.js';

import { transformToChartJsFormat } from '../../services/utils/transformToChartJsFormat';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { getDimensions } from '../../services/utils/getDimensions';

import {
  ApiResponseType,
  ChartDimensions,
  ChartJsDataType,
  FiltersStateType,
  QuoteSource,
  ReportConfigType
} from '~/components/Insights/types';
import { legendPadding } from '~/components/Insights/services/helpers/legendPadding';
import useChartData from '~/components/Insights/services/hooks/useChartData';
import useNow from '~/components/Insights/services/hooks/useNow';
import usePastDays from '~/components/Insights/services/hooks/usePastDays';
import { getDataWithRequiredDatasets } from '~/components/Insights/services/utils/getDataWithRequiredDatasets';
import { getDefaultLineChartData } from '~/components/Insights/services/utils/getDefaultLineChartData';
import { getChartOptions } from '~/components/Insights/services/utils/getChartOptions';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  legendPadding
);

interface LineChartProps {
  filters: FiltersStateType;
  reportConfig: ReportConfigType;
}

const LineChart = ({ filters = {}, reportConfig }: LineChartProps) => {
  const { t } = useTranslation('Stock');
  const { dealershipId } = useParams<{ dealershipId: string }>();
  const {
    metric,
    chartFilterConfig: { showLegend, quoteSourceConfig }
  } = reportConfig;
  const { quoteSource } = filters;

  const { data } = useChartData({
    metric,
    dimensions: quoteSource ? getDimensions(quoteSource) : [ChartDimensions.Date],
    dealershipId,
    fromDate: usePastDays(filters?.datePeriod || 7),
    toDate: useNow(),
    datePeriod: filters?.datePeriod === 1 ? 'Hour' : 'Day' || 'Day',
    funderCode: filters?.lender
  });

  const isDisplayingAllQuoteSource: boolean = (quoteSource && quoteSource.includes(QuoteSource.Count)) || false;
  const defaultLineChartData = getDefaultLineChartData();
  const getFormattedData = (data: ApiResponseType = defaultLineChartData): ChartJsDataType => {
    const formattedData =
      quoteSourceConfig.enabled && quoteSource ? getDataWithRequiredDatasets(data, quoteSource) : data;

    return transformToChartJsFormat(formattedData, reportConfig, isDisplayingAllQuoteSource, t);
  };

  const chartOptions = getChartOptions(showLegend, data);
  return <Line data={getFormattedData(data)} options={chartOptions} />;
};

export default LineChart;
