import React from 'react';
import PropTypes from 'prop-types';
import './customerEmail.scss';
import { truncate } from 'lodash';
const CustomerEmail = ({ email, readOnly }) => {
  const emailLink = `mailto:${email}`;
  const emailText = truncate(email, { length: 40 });
  return (
    <div>
      {readOnly ? (
        <span title={email.length >= 40 && email}>{emailText}</span>
      ) : (
        <a className="customerEmail__link" href={emailLink} target="_blank" rel="noopener noreferrer">
          {emailText}
        </a>
      )}
    </div>
  );
};
CustomerEmail.defaultProps = {
  readOnly: false
};
CustomerEmail.propTypes = {
  email: PropTypes.string
};
export default CustomerEmail;
