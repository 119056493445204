import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import Button from '../../Common/Button/Button';
import FileUploadInput from '../../Common/FileUploadInput';
import TrackEvent from '../../Common/Tracking/TrackEvent';
import TrackPageView from '../../Common/Tracking/TrackPageView';
import './initialDiscloseDocumentSettings.scss';
import { useNavigate } from 'hooks/useNavigate';
import { useParams } from 'react-router-dom';
import { withOutletContextProps } from 'hocs/router';

function InitialDiscloseDocumentSettings({ session }) {
  const { t } = useTranslation('Settings');
  const [files, setFiles] = useState([]);
  const [prevFile, setPrevFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [meta, setMeta] = useState(null);
  const [isEdited, setIsEdited] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const maxFileSizeInKb = 10000;

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`/platform/v1/public/dealership/${params.dealershipId}/initial-disclosure-document`, {
      method: 'GET'
    })
      .then((response) => {
        response.blob();

        if (response.status === 200) {
          setPrevFile(true);
        }
      })
      .then(() => {
        fetch(`/platform/v1/dealershipsettings/${params.dealershipId}/initial-disclosure-document/metadata`, {
          method: 'GET'
        })
          .then((res) => res.json())
          .then((data) => {
            setMeta(data);
            setIsPageLoading(false);
          })
          .catch((err) => {
            throw err;
          });
      });
  }, []);

  const onSubmit = () => {
    setIsLoading(true);
    setHasError(false);
    let formData = new FormData();
    formData.append(files[0].name, files[0]);
    formData.append('Uploader', session.UserId);
    formData.append('OriginalFilename', files[0].name);
    fetch(`/platform/v1/dealershipsettings/${params.dealershipId}/initial-disclosure-document`, {
      body: formData,
      method: 'POST'
    }).then((response) => {
      setIsLoading(false);
      if (response.status == 200 || response.status == 202) {
        setPrevFile(files[0]);
        setTimeout(() => {
          navigate(`/d/${params.dealershipId}/settings`);
        }, 200);
      } else if (response.status == 413) {
        setHasError(true);
        setErrorMessage(
          t('InitialDisclouseDocumentSettings.max_file_size_exceeded', { fileSize: maxFileSizeInKb / 1000 })
        );
      } else if (response.status == 400) {
        setHasError(true);
        setErrorMessage(t('InitialDisclouseDocumentSettings.file_not_valid'));
      } else {
        setHasError(true);
      }
    });
  };

  const handleDelete = () => {
    fetch(`/platform/v1/dealershipsettings/${params.dealershipId}/initial-disclosure-document`, {
      method: 'DELETE'
    }).then(() => {
      setPrevFile(false);
    });
  };

  const handleEdit = () => {
    setIsEdited(true);
  };

  const eventInteractionName = isEdited ? 'IDD_Changed' : 'IDD_Uploaded';
  return (
    <Page>
      <TrackPageView dealershipId={params.dealershipId} />
      <Breadcrumbs
        items={[
          {
            name: t('InitialDiscloseDocumentSettings.home'),
            path: `/d/${params.dealershipId}`
          },
          {
            name: t('InitialDiscloseDocumentSettings.settings'),
            path: `/d/${params.dealershipId}/settings`
          },
          {
            name: t('InitialDiscloseDocumentSettings.initial_disclosure_document'),
            path: `/d/${params.dealershipId}/settings/idd`
          }
        ]}
      />
      <Panel>
        <PanelHeader>{t('InitialDiscloseDocumentSettings.initial_disclosure_document')}</PanelHeader>
        <PanelContent>
          <FileUploadInput
            files={files}
            onReceiveFile={setFiles}
            hasPrevFile={prevFile}
            dealershipId={params.dealershipId}
            maxFiles={1}
            removeFile={() => {
              setFiles([]);
              setErrorMessage('');
            }}
            handleDelete={handleDelete}
            originalFilename={meta && meta.OriginalFilename}
            isPageLoading={isPageLoading}
            handleEdit={handleEdit}
            maxFileSizeInKb={maxFileSizeInKb}
            errorMessage={errorMessage}
          />
          <div className="initialDiscloseDocumentSettings__actions">
            <Button
              className="initialDiscloseDocumentSettings__button"
              buttonStyle="cancel"
              to={`/d/${params.dealershipId}/settings`}
            >
              {t('InitialDiscloseDocumentSettings.back')}
            </Button>
            <TrackEvent featureName="IDD" interactionName={eventInteractionName}>
              <Button
                className="initialDiscloseDocumentSettings__button"
                onClick={onSubmit}
                disabled={files && files.length !== 1}
                isLoading={isLoading}
                hasError={hasError}
              >
                {t('InitialDiscloseDocumentSettings.save')}
              </Button>
            </TrackEvent>
          </div>
        </PanelContent>
      </Panel>
    </Page>
  );
}

export default withOutletContextProps(InitialDiscloseDocumentSettings);
