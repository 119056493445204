import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import PanelHeader from '../../../components/Common/PanelHeader';
import PanelFooter from '../../../components/Common/PanelFooter';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import AlertCard from '../../../components/Common/AlertCard/AlertCard';
import { useFunderConsumerDutyContent } from '../../../api/contentService/hooks';
import { ConsumerDutyDetails } from '../../../api/contentService/utils';
import { Question } from './Question';
import { LenderVulnerableCustomers } from './LenderVulnerableCustomers';
import { trackFinanceProductSuitabilityQuestionAndAnswer } from '../../../tracking/avoTracking';
import { useTrackPageViewed } from '../../../tracking/hooks';
import styles from './lenderQuestionnaire.module.scss';

export type LenderQuestionnaireProps = {
  consumerDutyDetails: ConsumerDutyDetails;
  onContinue: () => void;
  onCancel: () => void;
};

export type QuestionState = Record<string, string>;

export const hasSelectedNo = (selectedQuestions: QuestionState): boolean =>
  Object.values(selectedQuestions).includes('false');

export const hasAnsweredAll = (selectedQuestions: QuestionState, allQuestions: string[]): boolean =>
  Object.values(selectedQuestions).length > 0 && Object.values(selectedQuestions).length === allQuestions.length;

const LenderQuestionnaire = ({ consumerDutyDetails, onContinue, onCancel }: LenderQuestionnaireProps) => {
  const { t } = useTranslation('Quoting');
  const { dealershipId, quoteId } = consumerDutyDetails;
  const { isLoading, isError, data: consumerDuty } = useFunderConsumerDutyContent(consumerDutyDetails);
  const [selectedQuestions, setSelectedQuestions] = useState<QuestionState>({});

  useTrackPageViewed('Lender Product Understanding Questions', dealershipId);

  if (isLoading) {
    return <LoadingSpinner center size="large" />;
  }

  if (isError) {
    // Redirect to new error page - depends on RTL-723
    return <div>Something went wrong</div>;
  }

  const questions = consumerDuty?.questions || [];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (consumerDuty) {
      trackFinanceProductSuitabilityQuestionAndAnswer({
        dealershipId,
        consumerDutyContent: consumerDuty,
        productSuitabilityQuestion: event.target.name,
        productSuitabilityAnswer: event.target.value === 'false' ? 'No' : 'Yes',
        quoteId
      });
    }
    setSelectedQuestions((prevSelectedQuestions) => ({
      ...prevSelectedQuestions,
      [event.target.name]: event.target.value
    }));
  };

  return (
    <>
      <PanelHeader>{t('LenderQuestionnaire.lender_questions')}</PanelHeader>
      <main>
        <div className={styles.alertCardWrapper}>
          <AlertCard
            iconName="information"
            title={t('LenderQuestionnaire.important_information')}
            paragraph={t('LenderQuestionnaire.under_consumer_duty_you_need_to')}
          />
        </div>
        <div className={styles.questionsContainer}>
          {questions.map((question, index) => (
            <Question questionText={question} key={question} index={index} onChange={handleChange} />
          ))}
        </div>
        {hasSelectedNo(selectedQuestions) && (
          <>
            <h2 className={styles.furtherAdviceTitle}>
              {t('LenderQuestionnaire.for_further_advice you_can_give_the_following_options_to_the_customer')}
            </h2>
            <AlertCard
              iconName="alert"
              title={t('LenderQuestionnaire.further_advice_for_customers')}
              paragraph={t(
                'LenderQuestionnaire.before_proceeding_to_an_application_it_is_important_that_the_customer_fully_understands_the_product'
              )}
            />
          </>
        )}
        <LenderVulnerableCustomers consumerDuty={consumerDuty} dealershipId={dealershipId} quoteId={quoteId} />
      </main>
      <PanelFooter
        cancel={
          <Button buttonStyle="cancel" onClick={onCancel} id="cancelFpn">
            {t('ConsumerDutyContainer.cancel')}
          </Button>
        }
        save={
          <Button
            disabled={hasSelectedNo(selectedQuestions) || !hasAnsweredAll(selectedQuestions, questions)}
            onClick={() => {
              onContinue();
            }}
            id="continueFpn"
          >
            {t('ConsumerDutyContainer.continue')}
          </Button>
        }
      />
    </>
  );
};

export default LenderQuestionnaire;
