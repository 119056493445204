import React from 'react';
import PropTypes from 'prop-types';
import SelectInput from '../../Common/Form/SelectInput';
import FormFooter from '../../Common/Form/FormFooter';
import TradingNameLookup from '../../Common/TradingNameLookup';
import EmailLookup from '../../Common/EmailLookup';
import './proceedFromCorporateQuickQuoteForm.scss';

import MobxForm from '../../MobxForm/MobxForm';
import MobxFormFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import TextInputWrappingInput from '../../Common/Form/TextInputWrappingInput';

import { observer, inject } from 'mobx-react';
import { push } from '../../../routerHistory';

class ProceedFromCorporateQuickQuoteForm extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.appStore.quickQuoteStore.startNewCorporateQuickQuote(
      this.props.dealershipId,
      this.props.vehicle,
      this.props.quote
    );
  }

  componentWillUnmount() {
    this.props.appStore.quickQuoteStore.tearDown();
  }

  handleSubmit = () => {
    const {
      appStore: { quickQuoteStore }
    } = this.props;

    if (quickQuoteStore.corporateQuickQuoteValidator.isValid) {
      quickQuoteStore
        .proceedWithCorporateQuickQuote()
        .then((response) => {
          this.props.closeModal();
          push(
            `/d/${this.props.dealershipId}/consumers/${response.CustomerId}/application/${response.ApplicationId}`
          );
        })
        .catch(() => {
          // stops the page transition if api call fails
        });
    }
  };

  render() {
    const {
      appStore: { quickQuoteStore }
    } = this.props;
    const errors = quickQuoteStore.corporateQuickQuoteValidator.getErrors();
    const quickQuote = quickQuoteStore.corporateQuickQuote;

    return (
      <MobxForm onSubmit={this.handleSubmit} focusOnFirstElement className="proceedFromCorporateQuickQuoteForm">
        <MobxFormFieldGroup error={errors.tradingName}>
          <MobxFormLabel htmlFor="tradingName">Trading Name</MobxFormLabel>
          <TradingNameLookup
            dealershipId={this.props.dealershipId}
            onChange={quickQuote.setTradingName}
            value={quickQuote.tradingName}
          />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.title}>
          <MobxFormLabel htmlFor="title">Title</MobxFormLabel>
          <SelectInput
            id="title"
            value={quickQuote.title}
            onChange={(id, title) => quickQuote.setTitle(title)}
            options="Title"
          />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.firstName}>
          <MobxFormLabel htmlFor="firstName">First Name</MobxFormLabel>
          <TextInputWrappingInput id="firstName" value={quickQuote.firstName} onChange={quickQuote.setFirstName} />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.middleName}>
          <MobxFormLabel htmlFor="middleName">Middle Name</MobxFormLabel>
          <TextInputWrappingInput id="middleName" value={quickQuote.middleName} onChange={quickQuote.setMiddleName} />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.lastName}>
          <MobxFormLabel htmlFor="lastName">Last Name</MobxFormLabel>
          <TextInputWrappingInput id="lastName" value={quickQuote.lastName} onChange={quickQuote.setLastName} />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.position}>
          <MobxFormLabel htmlFor="position">Position</MobxFormLabel>
          <SelectInput
            id="position"
            value={quickQuote.position}
            onChange={(id, position) => quickQuote.setPosition(position)}
            options="Position"
          />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.email}>
          <MobxFormLabel htmlFor="email">Customer email</MobxFormLabel>
          <EmailLookup
            sector="corporate"
            dealershipId={this.props.dealershipId}
            onChange={quickQuote.setEmail}
            value={quickQuote.email}
            autoComplete="none"
            placeholder="Enter customer email address"
          />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.businessTelephone}>
          <MobxFormLabel htmlFor="businessTelephone">Business Telephone (or)</MobxFormLabel>
          <TextInputWrappingInput
            id="businessTelephone"
            value={quickQuote.businessTelephone}
            onChange={quickQuote.setBusinessTelephone}
          />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.mobileTelephone}>
          <MobxFormLabel htmlFor="mobileTelephone">Mobile Telephone</MobxFormLabel>
          <TextInputWrappingInput
            id="mobileTelephone"
            value={quickQuote.mobileTelephone}
            onChange={quickQuote.setMobileTelephone}
          />
        </MobxFormFieldGroup>

        <FormFooter
          submitLabel="Proceed to Application"
          onCancel={this.props.closeModal}
          isSubmitting={quickQuoteStore.proceedWithCorporateQuickQuoteRequest.isLoading}
          hasSubmittingError={quickQuoteStore.proceedWithCorporateQuickQuoteRequest.hasError}
          errorMessage={
            quickQuoteStore.proceedWithCorporateQuickQuoteRequest.hasError &&
            'An error occurred, please try again later.'
          }
        />
      </MobxForm>
    );
  }
}

ProceedFromCorporateQuickQuoteForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  appStore: PropTypes.object,
  quote: PropTypes.object,
  vehicle: PropTypes.object,
  dealershipId: PropTypes.string
};

export default inject(['appStore'])(observer(ProceedFromCorporateQuickQuoteForm));
