const formFields = [
  {
    name: 'class',
    validationRule: 'required'
  },
  {
    name: 'make',
    validationRule: 'required'
  },
  {
    name: 'model',
    validationRule: 'required'
  },
  {
    name: 'derivative'
  },
  {
    name: 'bodyStyle',
    validationRule: 'required'
  },
  {
    name: 'transmission',
    validationRule: 'required'
  },
  {
    name: 'fuelType',
    validationRule: 'required'
  },
  {
    name: 'doors',
    validationRule: 'required, number:int'
  },
  {
    name: 'seats',
    validationRule: 'required, number:int'
  },

  {
    name: 'condition',
    validationRule: 'required'
  },
  {
    name: 'vrm',
    validationRule: 'required:unless:vin, maxlength:9, alphaNumSpace'
  },
  {
    name: 'vin',
    validationRule: 'required:unless:vrm, minlength:11, maxlength:17, alphaNum'
  },
  {
    name: 'regDate',
    validationRule: 'required,date'
  },
  {
    name: 'keepers',
    validationRule: 'number:int'
  },
  {
    name: 'mileage',
    validationRule: 'number:int'
  },
  {
    name: 'colour'
  },
  {
    name: 'attentionGrabber'
  },
  {
    name: 'description'
  },
  {
    name: 'options'
  },
  {
    name: 'price',
    validationRule: 'required'
  },
  {
    name: 'vatStatus',
    validationRule: 'required'
  },
  {
    name: 'dealerReference'
  },
  {
    name: 'drivingPosition'
  },
  {
    name: 'auctionId'
  },
  {
    name: 'mileageUnit'
  },
  {
    name: 'currency'
  },
  {
    name: 'includesVat',
    validationRule: 'required:de'
  },
  {
    name: 'vatRate',
    validationRule: 'required:iftrue:includesVat'
  },
  {
    name: 'consumptionInner',
    validationRule: 'number'
  },
  {
    name: 'consumptionOuter',
    validationRule: 'number'
  },
  {
    name: 'consumptionCombined',
    validationRule: 'number'
  },
  {
    name: 'emission',
    validationRule: `required:de, number`
  },
  {
    name: 'energyEfficiencyClass',
    validationRule: 'required:de'
  }
];

export const getEditableFields = (_vehicleClass, hasTaxonomyData, isDealerPlatformUpload) => {
  const vehicleClass = _vehicleClass?.toLowerCase() || 'unknown';

  const dealerPlatformUpload = hasTaxonomyData && isDealerPlatformUpload;
  const mediaFeedUpload = hasTaxonomyData && !isDealerPlatformUpload;
  const manualEntryUpload = !hasTaxonomyData;

  switch (vehicleClass) {
    case 'car': {
      if (dealerPlatformUpload) {
        return [
          'condition',
          'vrm',
          'vin',
          'regDate',
          'keepers',
          'mileage',
          'colour',
          'attentionGrabber',
          'description',
          'options',
          'price',
          'dealerReference',
          'drivingPosition',
          'currency',
          'auctionId',
          'mileageUnit',
          'includesVat',
          'vatRate',
          'consumptionInner',
          'consumptionOuter',
          'consumptionCombined',
          'emission',
          'energyEfficiencyClass'
        ];
      }
      if (mediaFeedUpload) {
        return [
          'attentionGrabber',
          'description',
          'options',
          'dealerReference',
          'drivingPosition',
          'currency',
          'auctionId',
          'mileageUnit'
        ];
      }
      if (manualEntryUpload) {
        return [
          'class',
          'make',
          'model',
          'derivative',
          'bodyStyle',
          'transmission',
          'fuelType',
          'doors',
          'seats',
          'condition',
          'vrm',
          'vin',
          'regDate',
          'keepers',
          'mileage',
          'colour',
          'attentionGrabber',
          'description',
          'options',
          'price',
          'dealerReference',
          'drivingPosition',
          'currency',
          'auctionId',
          'mileageUnit',
          'includesVat',
          'vatRate',
          'consumptionInner',
          'consumptionOuter',
          'consumptionCombined',
          'emission',
          'energyEfficiencyClass'
        ];
      }
      break;
    }
    case 'motorbike':
    case 'bike': {
      if (dealerPlatformUpload) {
        return [
          'condition',
          'vrm',
          'vin',
          'regDate',
          'keepers',
          'mileage',
          'colour',
          'attentionGrabber',
          'description',
          'options',
          'price',
          'dealerReference',
          'currency',
          'auctionId',
          'mileageUnit',
          'includesVat',
          'vatRate',
          'consumptionInner',
          'consumptionOuter',
          'consumptionCombined',
          'emission',
          'energyEfficiencyClass'
        ];
      }
      if (mediaFeedUpload) {
        return [
          'attentionGrabber',
          'description',
          'options',
          'dealerReference',
          'currency',
          'auctionId',
          'mileageUnit'
        ];
      }
      if (manualEntryUpload) {
        return [
          'class',
          'make',
          'model',
          'derivative',
          'transmission',
          'fuelType',
          'condition',
          'vrm',
          'vin',
          'regDate',
          'keepers',
          'mileage',
          'colour',
          'attentionGrabber',
          'description',
          'options',
          'price',
          'dealerReference',
          'currency',
          'auctionId',
          'mileageUnit',
          'includesVat',
          'vatRate',
          'consumptionInner',
          'consumptionOuter',
          'consumptionCombined',
          'emission',
          'energyEfficiencyClass'
        ];
      }
      break;
    }
    case 'van':
    case 'lcv': {
      if (dealerPlatformUpload) {
        return [
          'bodyStyle',
          'doors',
          'seats',
          'condition',
          'vrm',
          'vin',
          'regDate',
          'keepers',
          'mileage',
          'colour',
          'attentionGrabber',
          'description',
          'options',
          'price',
          'vatStatus',
          'dealerReference',
          'drivingPosition',
          'currency',
          'auctionId',
          'mileageUnit',
          'includesVat',
          'vatRate',
          'consumptionInner',
          'consumptionOuter',
          'consumptionCombined',
          'emission',
          'energyEfficiencyClass'
        ];
      }
      if (mediaFeedUpload) {
        return [
          'attentionGrabber',
          'description',
          'options',
          'dealerReference',
          'drivingPosition',
          'currency',
          'auctionId',
          'mileageUnit'
        ];
      }
      if (manualEntryUpload) {
        return [
          'class',
          'make',
          'model',
          'derivative',
          'bodyStyle',
          'transmission',
          'fuelType',
          'doors',
          'seats',
          'condition',
          'vrm',
          'vin',
          'regDate',
          'keepers',
          'mileage',
          'colour',
          'attentionGrabber',
          'description',
          'options',
          'price',
          'vatStatus',
          'dealerReference',
          'drivingPosition',
          'currency',
          'auctionId',
          'mileageUnit',
          'includesVat',
          'vatRate',
          'consumptionInner',
          'consumptionOuter',
          'consumptionCombined',
          'emission',
          'energyEfficiencyClass'
        ];
      }
      break;
    }

    case 'unknown': {
      return [
        'attentionGrabber',
        'description',
        'options',
        'dealerReference',
        'drivingPosition',
        'currency',
        'auctionId',
        'mileageUnit',
        'includesVat',
        'vatRate',
        'consumptionInner',
        'consumptionOuter',
        'consumptionCombined',
        'emission',
        'energyEfficiencyClass'
      ];
    }

    default: {
      throw new TypeError(`vehicleClass ${vehicleClass} not recognized.`);
    }
  }
};

export const getValidationRules = ({ vehicleClass, hasTaxonomyData, isDealerPlatformUpload }) => {
  const enabledFields = getEditableFields(vehicleClass, hasTaxonomyData, isDealerPlatformUpload);
  return enabledFields.reduce((acc, fieldName) => {
    const { validationRule } = formFields.find(({ name }) => fieldName === name);

    if (validationRule) {
      acc[fieldName] = validationRule;
    }

    return acc;
  }, {});
};

export const isFieldEnabled = (fieldName, vehicleClass, hasTaxonomyData, isDealerPlatformUpload) => {
  const enabledFields = getEditableFields(vehicleClass, hasTaxonomyData, isDealerPlatformUpload);

  return enabledFields.includes(fieldName);
};
