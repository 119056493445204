import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class ManagedInput extends React.Component {
  handleChange = (event) => {
    this.props.onChange && this.props.onChange(this.props.id, event.currentTarget.value);
  };

  handleBlur = (event) => {
    this.props.onBlur && this.props.onBlur(this.props.id, event.currentTarget.value);
  };

  handleFocus = (event) => {
    this.props.onFocus && this.props.onFocus(this.props.id, event.currentTarget.value);
  };

  handleMouseEnter = (event) => {
    this.props.onMouseEnter && this.props.onMouseEnter(this.props.id, event.currentTarget.value);
  };

  handleMouseLeave = (event) => {
    this.props.onMouseLeave && this.props.onMouseLeave(this.props.id, event.currentTarget.value);
  };

  getInputType() {
    if (this.props.type === 'number') {
      const keywords = ['iPhone', 'iPad', 'Android'];
      const numberInput = _.some(keywords, function(word) {
        return navigator.userAgent.indexOf(word) >= 0;
      });
      return numberInput ? 'number' : 'text';
    } else {
      return this.props.type;
    }
  }

  render() {
    const dataAttrs = {};

    if (this.props.isValid === false) {
      dataAttrs['data-is-invalid'] = true;
    }

    return (
      <input
        ref={this.props.inputRef}
        className={this.props.className}
        data-th={`${this.props.dataThook}Input`}
        style={this.props.style}
        id={this.props.id}
        placeholder={this.props.placeholder}
        value={this.props.value ? this.props.value : ''}
        type={this.getInputType()}
        step={this.getInputType() === 'number' ? '0.01' : {}}
        onChange={this.handleChange}
        onClick={this.props.onClick}
        onBlur={this.handleBlur}
        onWheel={(e) => e.target.blur()}
        onFocus={this.handleFocus}
        onKeyDown={this.props.onKeyDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        disabled={this.props.disabled}
        maxLength={this.props.maxLength}
        autoComplete={this.props.autoComplete}
        {...dataAttrs}
      />
    );
  }
}

ManagedInput.propTypes = {
  type: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  suffix: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  inputRef: PropTypes.func,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  dataThook: PropTypes.string,
  autoComplete: PropTypes.string
};

ManagedInput.defaultProps = {
  type: 'text',
  valid: false,
  invalid: false,
  maxLength: 100
};

export default ManagedInput;
