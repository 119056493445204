/* eslint-disable import/no-anonymous-default-export */
import { types } from '../schema';
import { ApplicationQuoteSchema } from './quoteSchema';

export const AgreementsApplicationSchema = [
  {
    LenderAgreementNumber: types.String,
    iVendiAgreementNumber: types.String,
    TimeOfFirstAppearance: types.Number,
    AgreementTypeCode: types.String,
    LenderCustomerNumber: types.String,
    PrivateLenderAgreementId: types.String,
    LenderQuoteId: types.String,
    PreApprovalData: {
      LenderReferenceNumber: types.String,
      Success: types.Boolean,
      ExpiryDate: types.String,
      NewAPR: types.Number
    }
  }
];

export default {
  Agreements: AgreementsApplicationSchema,
  AcceptedInitialDisclosureDocument: types.NullableBoolean,
  AddressHistory: [
    {
      BuildingName: types.String,
      BuildingNumber: types.String,
      Country: types.String,
      County: types.String,
      District: types.String,
      Organisation: types.String,
      PostTown: types.String,
      Postcode: types.String,
      Residency: types.String,
      Street: types.String,
      SubBuilding: types.String,
      TimeAtAddress: {
        Months: types.Number,
        TotalMonths: types.Number,
        Years: types.Number
      }
    }
  ],
  AffordabilityDetails: {
    Income: {
      GrossAnnual: types.String,
      NetMonthly: types.String,
      HasOtherHouseholdIncome: types.NullableBoolean,
      OtherHouseholdIncome: types.String
    },
    MonthlyExpenditure: {
      MortgageOrRental: types.String,
      Other: types.String
    },
    PersonalCircumstances: {
      ChangesExpected: types.NullableBoolean,
      DetailsOfExpectedChanges: types.String,
      ForeseeFutureDownturnInFinances: types.NullableBoolean,
      ForeseenYearlyIncome: types.String,
      DownturnReason: types.String
    },
    ReplacementLoan: {
      DetailsOfReplacementLoan: types.String,
      IsReplacementLoan: types.NullableBoolean
    }
  },
  Arranged: {
    Showroom: types.Boolean,
    Phone: types.Boolean,
    Online: types.Boolean,
    DistanceSale: types.Boolean
  },
  BankDetails: {
    AccountName: types.String,
    AccountNumber: types.String,
    Bank: types.String,
    BankBIC: types.String,
    Branch: types.String,
    BranchBIC: types.String,
    CHAPSSupported: types.Boolean,
    ContactAddressLine1: types.String,
    ContactAddressLine2: types.String,
    ContactFax: types.String,
    ContactPhone: types.String,
    ContactPostTown: types.String,
    ContactPostcode: types.String,
    CorrectedAccountNumber: types.String,
    CorrectedSortCode: types.String,
    FasterPaymentsSupported: types.Boolean,
    IBAN: types.String,
    IsCorrect: types.Boolean,
    IsDirectDebitCapable: types.Boolean,
    SortCode: types.String,
    Sortcode: types.String,
    StatusInformation: types.String,
    TimeAtBank: {
      Months: types.Number,
      TotalMonths: types.Number,
      Years: types.Number
    }
  },
  BusinessContacts: [
    {
      AddressHistory: [
        {
          BuildingName: types.String,
          BuildingNumber: types.String,
          Country: types.String,
          County: types.String,
          District: types.String,
          Organisation: types.String,
          Postcode: types.String,
          PostTown: types.String,
          Residency: types.String,
          Street: types.String,
          SubBuilding: types.String,
          TimeAtAddress: {
            Months: types.Number,
            TotalMonths: types.Number,
            Years: types.Number
          }
        }
      ],
      DateOfBirth: types.String,
      CountryOfBirth: types.String,
      Nationality: types.String,
      Email: types.String,
      FirstName: types.String,
      Gender: types.String,
      HomeTelephone: types.String,
      LastName: types.String,
      MaritalStatus: types.String,
      MiddleNames: types.String,
      MobileTelephone: types.String,
      NoOfDependants: types.String,
      Position: types.String,
      Title: types.String,
      WorkTelephone: types.String
    }
  ],
  BusinessDetails: {
    CompanyRegistrationNumber: types.String,
    CompanySector: types.String,
    CompanyType: types.String,
    DateEstablished: types.String,
    EmailAddress: types.String,
    FaxNumber: types.String,
    Landline: types.String,
    Mobile: types.String,
    NumberOfDirectorsOrPartners: types.Number,
    NumberOfEmployees: types.Number,
    RegisteredAddress: types.Boolean,
    RegisteredName: types.String,
    TradingName: types.String,
    VatNumber: types.String
  },
  ClientApp: types.String,
  CreatedDate: types.String,
  CreatedDateTimestamp: types.Number,
  CustomerId: types.String,
  CustomerType: types.String,
  DealershipId: types.String,
  DeliveryDate: types.String,
  DocumentLinks: types.Array,
  DocumentLinkReadModels: types.Array,
  EmploymentHistory: [
    {
      BuildingName: types.String,
      BuildingNumber: types.String,
      Country: types.String,
      County: types.String,
      District: types.String,
      Employer: types.String,
      Occupation: types.String,
      EmploymentSector: types.String,
      OccupationBasis: types.String,
      OccupationType: types.String,
      Organisation: types.String,
      PostTown: types.String,
      Postcode: types.String,
      Street: types.String,
      SubBuilding: types.String,
      TelephoneNumber: types.String,
      TimeAtEmployment: {
        Months: types.Number,
        TotalMonths: types.Number,
        Years: types.Number
      },
      GrossAnnual: types.String
    }
  ],
  Id: types.String,
  LastModified: types.String,
  LastModifiedTimestamp: types.Number,
  LenderNotes: [
    {
      Timestamp: types.Number,
      Name: types.String,
      Text: types.String,
      ExtraInfo: types.String
    }
  ],
  PartExchanges: [
    {
      CapId: types.Number,
      Class: types.String,
      Condition: types.String,
      Derivative: types.String,
      EstimatedValue: types.Number,
      Id: types.String,
      Make: types.String,
      Mileage: types.Number,
      Model: types.String,
      NumberOfPreviousOwners: types.Number,
      OutstandingFinance: types.Number,
      RegistrationDate: types.String,
      ServiceHistory: types.String,
      SetsOfKeys: types.Number,
      Vrm: types.String
    }
  ],
  PersonalDetails: {
    DateOfBirth: types.String,
    DrivingLicence: types.String,
    DrivingLicenceNumber: types.String,
    Email: types.String,
    FirstName: types.String,
    Gender: types.String,
    HomeTelephone: types.String,
    LastName: types.String,
    MaritalStatus: types.String,
    MiddleNames: types.String,
    MobileTelephone: types.String,
    Nationality: types.String,
    DualNationality: types.String,
    NoOfDependants: types.String,
    Title: types.String,
    CountryOfBirth: types.String,
    DirectorOrBoardMemberCountryOfOperation: types.String,
    DirectorOrBoardMemberCompanySector: types.String,
    VatNumber: types.String
  },
  PrivacyPreferences: {
    LenderMarketingByEmailConsent: types.NullableBoolean,
    LenderMarketingDisclaimerVersionId: types.String
  },
  Quote: ApplicationQuoteSchema,
  QuoteId: types.String,
  Referrer: types.String,
  Status: types.String,
  Vehicle: {
    CapId: types.Number,
    CapMakeId: types.Number,
    CapModelId: types.Number,
    CdnVehicleImageUrl: types.String,
    Class: types.String,
    Closed: types.Boolean,
    Condition: types.String,
    CreatedTimestamp: types.Number,
    Derivative: types.String,
    GlassId: types.String,
    HiddenOnDashboard: types.Boolean,
    Make: types.String,
    MakeId: types.Number,
    Mileage: types.Number,
    Model: types.String,
    ModelId: types.String,
    RegistrationDate: types.String,
    SanitizedVrm: types.String,
    Status: {
      AcceptanceFeeLast: types.Number,
      AccessoriesInsurance: types.Number,
      AccessoriesOther: types.Number,
      AccessoriesWarranty: types.Number,
      Advance: types.Number,
      AnnualDistanceQuoted: types.Number,
      BasicPrice: types.Number,
      CashPrice: types.Number,
      Cashback: types.Number,
      ChargePerOverDistanceUnit: types.Number,
      Commission: types.Number,
      DateOfHandover: types.String,
      DateOfPurchase: types.String,
      Deposit: types.Number,
      FacilitatorName: types.String,
      FinalPayment: types.Number,
      FinanceType: types.String,
      FirstPayment: types.Number,
      FlatRate: types.Number,
      FollowedBy: types.Number,
      FollowingPayments: types.Number,
      NonVatableItems: types.Number,
      OptionToPurchaseFee: types.Number,
      PartExchange: types.Number,
      RepresentativeApr: types.Number,
      RequestedDeposit: types.Number,
      Settlement: types.Number,
      StandInValue: types.Number,
      Status: types.String,
      Term: types.Number,
      TotalAmountPayable: types.Number,
      VAT: types.Number,
      VATAmount: types.Number
    },
    VehicleId: types.String,
    Vin: types.String,
    Vrm: types.String
  },
  VehicleId: types.String,
  Version: types.Number,
  Tags: types.String,
  FinalDecisionsReceived: types.Boolean
};
