import { useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

const ApplicationRedirector = () => {
  const outletContext = useOutletContext();
  const { dealershipId, consumerId, applicantId } = useParams();
  const navigate = useNavigate();
  const isCorporate = outletContext.application.CustomerType === 'corporate';
  const isRequote = outletContext.quotes.isRequote;

  useEffect(() => {
    if (isCorporate) {
      navigate(`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/businessdetails`, {
        replace: true
      });
    } else if (isRequote) {
      navigate(`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/applicationsummary`, {
        replace: true
      });
    } else {
      navigate(`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/personaldetails`, {
        replace: true
      });
    }
  }, [navigate, dealershipId, consumerId, applicantId, isCorporate, isRequote]);
  return null;
};

export default ApplicationRedirector;
