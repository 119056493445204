import { ChartDimensionsType, QuoteSourceType, ChartDimensions, QuoteSource } from '~/components/Insights/types';

export const getDimensions = (source: QuoteSourceType[]): ChartDimensionsType[] => {
  const dimensions: ChartDimensionsType[] = [ChartDimensions.Date];

  if (!source.includes(QuoteSource.Count)) {
    dimensions.push(ChartDimensions.Origin);
  }

  return dimensions;
};
