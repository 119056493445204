import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelfServiceQuote from '../components/SelfServiceQuote';
import * as applicationActions from '../../../redux/application/applicationActions';
import {
  findSelfServiceQuote,
  findSelfServiceVehicle
} from '../../SelfServiceQuote/selectors/selfServiceQuoteSelectors';
import { getCustomer } from '../../../selectors/customerSelectors';
import { compose } from 'redux';
import { withParams } from 'hocs/router';

const SelfServiceQuoteContainer = ({
  consumer,
  dealership,
  quote,
  vehicle,
  cancel,
  proceedingState,
  applyWithSelfServiceQuote
}) => {
  return (
    <SelfServiceQuote
      consumer={consumer}
      dealership={dealership}
      quote={quote}
      vehicle={vehicle}
      cancel={cancel}
      proceedingState={proceedingState}
      applyWithSelfServiceQuote={applyWithSelfServiceQuote}
    />
  );
};

SelfServiceQuoteContainer.propTypes = {
  consumer: PropTypes.object,
  dealership: PropTypes.object,
  quote: PropTypes.object,
  vehicle: PropTypes.object,
  cancel: PropTypes.func,
  proceedingState: PropTypes.string,
  applyWithSelfServiceQuote: PropTypes.func
};

function mapStateToProps(state, ownProps) {
  return {
    consumer: getCustomer(state, ownProps.params.consumerId),
    dealership: state.dealership,
    proceedingState: state.quotes.proceedingState,
    quote: findSelfServiceQuote(state, ownProps.params.consumerId, ownProps.params.quoteId),
    vehicle: findSelfServiceVehicle(state, ownProps.params.consumerId, ownProps.params.vehicleId)
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    cancel: () => {
      window.history.back();
    },
    applyWithSelfServiceQuote: (vehicle, quote, customerType) =>
      dispatch(applicationActions.createApplication(vehicle, quote, ownProps.params.consumerId, customerType))
  };
}

export default compose(withParams, connect(mapStateToProps, mapDispatchToProps))(SelfServiceQuoteContainer);
