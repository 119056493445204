import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';
import { toJS } from 'mobx';

import observerForHooks from '../../shared/hocs/observerForHooks';

import './toastNotification.scss';

const ToastNotification = ({ notifications, autoDelete = true, dismissTime = 5000, removeNotification = true }) => {
  const notificationList = toJS(notifications);
  const { t } = useTranslation('Stock');
  const deleteToast = useCallback(
    (id) => {
      removeNotification(id);
    },
    [removeNotification]
  );

  // Auto dismiss functionality
  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && notificationList.length) {
        deleteToast(notificationList[0].id);
      }
    }, dismissTime);

    return () => {
      clearInterval(interval);
    };
  }, [autoDelete, dismissTime, notificationList, deleteToast]);

  return (
    <>
      <div className={`toastNotification__container toastNotification__position`}>
        {notificationList.map((toast, i) => {
          const { id, title, message } = toast;
          return (
            <div
              key={i}
              className={`toastNotification__notification toastNotification__position toastNotification__notification--${toast.type}`}
            >
              <button
                data-th="toastNotificationButton"
                className="toastNotification__button"
                onClick={() => deleteToast(id)}
              >
                {t('ToastNotification.x')}
              </button>
              <div>
                <p className="toastNotification__notificationTitle">{title}</p>
                <p className="toastNotification__notificationMessage">{message}</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default inject('appStore')(
  observerForHooks({
    notifications: (props) => props.appStore.notificationStore.notifications,
    removeNotification: (props) => props.appStore.notificationStore.removeNotification
  })(ToastNotification)
);
