import React from 'react';
import PropTypes from 'prop-types';
import './cardItem.scss';
import classNames from 'classnames';
const CardItem = ({ label, children, centered }) => (
  <div className="cardItem">
    <div className="cardItem__label">{label}</div>
    <div className={classNames('cardItem__content', centered && 'cardItem__content--centered')}>{children}</div>
  </div>
);

CardItem.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  centered: PropTypes.bool
};

export default CardItem;
