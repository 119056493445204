import { useVisLookupContext, SET_SELECTED_VEHICLE_ID } from '../../context/VisLookupContext';

const useSelectedVehicle = () => {
  const { state, dispatch } = useVisLookupContext();
  const refine = (vehicleId) => {
    dispatch({
      type: SET_SELECTED_VEHICLE_ID,
      payload: vehicleId
    });
  };

  return {
    refine,
    selectedVehicleId: state.selectedVehicleId
  };
};

export default useSelectedVehicle;
