import * as debug from '../debug';

export const getAuctions = async (dealerId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_STOCK_API_PATH}/Auction`, {
      headers: {
        'content-type': 'application/json'
      },
      method: 'GET'
    });

    return response.json();
  } catch (err) {
    debug.error(err);
  }
};
