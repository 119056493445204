import cn from 'classnames';
import { inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import SubmittedApplications from './components/SubmittedApplications';
import EligibilityChecks from './components/EligibilityChecks';
import OnlineQuotes from './components/OnlineQuotes';
import SearchImpressions from './components/SearchImpressions';
import AverageDaysInStock from './components/AverageDaysInStock';
import UiState from 'mobx-stores/UiState';
import TrackEvent from '~Common/Tracking/TrackEvent';

import s from './metricCards.module.scss';
import { useLocation, useParams } from 'react-router-dom';
import OnClick from '~/tracking/OnClick';
import { trackDealershipMetricTileSelected } from '~/tracking/avoTracking';
interface Props {
  appStore?: {
    uiState: UiState;
  };
}

const MetricCards = ({ ...props }: Props) => {
  const { pathname } = useLocation();
  const { dealershipId } = useParams<{ dealershipId: string }>();

  const { t } = useTranslation('Stock');

  const {
    canUseCfcIvendiv2,
    canViewSearchImpressionsCard,
    canViewConsumerApplications,
    hasStockEnabled,
    isDe
  } = props.appStore!.uiState;

  return (
    <div className={cn(pathname === `/d/${dealershipId}` && s['wrapper'])}>
      {pathname !== `/d/${dealershipId}` && (
        <div className={s['pageHeader']}>
          <h1 className={s['title']}>{t('StockMetrics.dealership_metrics')}</h1>
          <p className={s['paragraph']}>{t('StockMetrics.welcome_to_your_dedicated_hub_for_dealer_metrics')}</p>
        </div>
      )}
      <div className={cn(s['floatingCards'], pathname === `/d/${dealershipId}` && s['floatingCardsDashboard'])}>
        {hasStockEnabled && (
          <OnClick callback={() => trackDealershipMetricTileSelected('dealer_vehicle_snapshot:days_in_stock')}>
            <TrackEvent
              featureName="dealership-metrics:average-days-in-stock"
              interactionName="click"
              context={{
                dealershipId
              }}
              className={s['flex']}
            >
              <AverageDaysInStock />
            </TrackEvent>
          </OnClick>
        )}
        <OnClick callback={() => trackDealershipMetricTileSelected('finance_quotes_displayed:count')}>
          <TrackEvent
            featureName="dealership-metrics:online-quotes"
            interactionName="click"
            context={{
              dealershipId
            }}
            className={s['flex']}
          >
            <OnlineQuotes />
          </TrackEvent>
        </OnClick>

        {canUseCfcIvendiv2 && (
          <OnClick callback={() => trackDealershipMetricTileSelected('finance_eligibility_check_completed:count')}>
            <TrackEvent
              featureName="dealership-metrics:eligibility-checks"
              interactionName="click"
              context={{
                dealershipId
              }}
              className={s['flex']}
            >
              <EligibilityChecks />
            </TrackEvent>
          </OnClick>
        )}

        {canViewConsumerApplications && !isDe && (
          <OnClick callback={() => trackDealershipMetricTileSelected('finance_application_initial_submission:count')}>
            <TrackEvent
              featureName="dealership-metrics:submitted-applications"
              interactionName="click"
              context={{
                dealershipId
              }}
              className={s['flex']}
            >
              <SubmittedApplications />
            </TrackEvent>
          </OnClick>
        )}

        {canViewSearchImpressionsCard && (
          <OnClick callback={() => trackDealershipMetricTileSelected('dealer_search_impression:count')}>
            <TrackEvent
              featureName="dealership-metrics:search-impressions"
              interactionName="click"
              context={{
                dealershipId
              }}
              className={s['flex']}
            >
              <SearchImpressions />
            </TrackEvent>
          </OnClick>
        )}
      </div>
    </div>
  );
};

export default inject('appStore')(MetricCards);
