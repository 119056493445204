const getMismatchingKeys = (baseObj, comparisonObj) => {
  const results = [];
  const baseObjEntries = Object.entries(baseObj);

  for (const [key, value] of baseObjEntries) {
    if (!comparisonObj[key]) {
      results.push(key);
      continue;
    }

    if (String(comparisonObj[key]).toLowerCase() !== String(value).toLowerCase()) {
      results.push(key);
    }
  }

  return results;
};

export default getMismatchingKeys;
