import { withTranslation } from 'react-i18next';
import React from 'react';
import './editVapsForm.scss';
import { QuoteRequest } from '../../../core/quoteRequest';
import Validator from '../../../validators/Validator';
import { reaction } from 'mobx';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import CurrencyInput from '../../Common/Form/CurrencyInput';
import ValueAddedProduct from './ValueAddedProduct';
import Button from '../../Common/Button/Button';
import { inject, observer } from 'mobx-react';
import MobxForm from '../../MobxForm/MobxForm';
import FormFooter from '../../Common/Form/FormFooter';
import AddVapModal from './AddVapModal';
import EditVapModal from './EditVapModal';
import _ from 'lodash';
import SelectInput from '../../Common/Form/SelectInput';
import * as debug from '../../../debug';
import { trackSelfDescribingEvent } from '@ivendi/snowplow-js';
import Divider from '../../Common/Divider';
import PropTypes from 'prop-types';
import { generateDefaultQuoteRequestValues } from '../components/utils';
import { hasExtendedTermLength } from '~/utils/quoteUtils';

class EditVapsForm extends React.Component {
  quoteRequest = null;

  constructor(props) {
    super(props);
    this.state = {
      customerType: props.defaultValues.CustomerType || this.props.customerType,
      showAccessoriesFields: false,
      indexOfVapToEdit: null,
      addVapModalOpen: false,
      isEditVapModalOpen: false
    };
    this.validationRules = {
      CashDeposit: 'required, currency',
      Term: 'required',
      ValueAddedProducts: [
        {
          ProductTypeCode: 'required',
          Id: 'required',
          Price: 'required, currency'
        }
      ]
    };
    this.initQuoteRequest();
    this.setUpValidation();
  }

  initQuoteRequest = () => {
    const { vehicle, defaultValues, vehicleClass, vehicleCondition } = this.props;
    const defaultQuoteRequestValues = generateDefaultQuoteRequestValues({
      defaultValues,
      countryCode: this.props.appStore.uiState.countryCode,
      vehicleClass,
      vehicleCondition,
      vehicle,
      isBnpp: this.props.appStore.uiState.isBnpp
    });
    this.quoteRequest = new QuoteRequest(defaultQuoteRequestValues);
  };

  setUpValidation() {
    this.validator = new Validator();
    this.validator.setRules(this.validationRules);
    this.validationReactionDisposer = reaction(() => ({ ...this.quoteRequest.toObject() }), this.validator.validate, {
      fireImmediately: true
    });
  }

  componentDidMount() {
    let ActualCashDeposit =
      this.quoteRequest.ActualCashDeposit && parseFloat(this.quoteRequest.ActualCashDeposit)
        ? parseFloat(this.quoteRequest.ActualCashDeposit)
        : 0;
    this.quoteRequest.set('CashDeposit', ActualCashDeposit);
    this.setState({
      ActualCashDeposit
    });
  }

  setCashDeposit = (CashDeposit) => {
    this.quoteRequest.set('CashDeposit', CashDeposit);
    this.setState({
      ActualCashDeposit: CashDeposit
    });
  };
  editVap = (indexOfVapToEdit) => {
    this.setState({
      isEditVapModalOpen: true,
      indexOfVapToEdit: indexOfVapToEdit
    });
  };
  addVap = () => {
    this.setState({
      addVapModalOpen: true
    });
  };
  setInsurance = (Insurance) => this.quoteRequest.set('Insurance', Insurance);
  closeAddVapModal = () => {
    this.setState({
      addVapModalOpen: false,
      isEditVapModalOpen: false,
      indexOfVapToEdit: null
    });
  };
  handleSubmitAddVapModal = (productId, price, productTypeCode, shouldRemoveExistingDuplicate) => {
    const product = this.props.appStore.vapStore.visibleProducts.find((v) => v.Id.toString() === productId);

    if (shouldRemoveExistingDuplicate) {
      let toRemove = _.find(this.quoteRequest.ValueAddedProducts, (vap) => vap.ProductTypeCode === productTypeCode);

      this.quoteRequest.removeVap(_.indexOf(this.quoteRequest.ValueAddedProducts, toRemove));
    }

    this.quoteRequest.addVap({ ...product, Price: price });
    this.closeAddVapModal();
  };
  handleSubmitEditVapModal = (productId, price, productTypeCode, shouldRemoveExistingDuplicate) => {
    const product = this.props.appStore.vapStore.visibleProducts.find((v) => v.Id.toString() === productId);

    if (shouldRemoveExistingDuplicate) {
      let toRemove = _.find(this.quoteRequest.ValueAddedProducts, (vap) => vap.ProductTypeCode === productTypeCode);

      this.quoteRequest.removeVap(_.indexOf(this.quoteRequest.ValueAddedProducts, toRemove));
    }

    this.quoteRequest.changeVap(this.state.indexOfVapToEdit, { ...product, Price: price });
    this.closeAddVapModal();
  };
  setTerm = (fieldName, Term) => this.quoteRequest.set('Term', Term);
  handleSubmit = () => {
    if (!this.validator.errorCount) {
      this.quoteRequest.ValueAddedProducts.map((vap) => {
        if (vap.TaxTypeCode < 1 || vap.TaxTypeCode === undefined) {
          debug.error(
            'taxTypeZero ' +
              JSON.stringify({
                productTypes: this.props.appStore.vapStore.visibleProducts,
                vap: vap
              })
          );
        }
      });
      this.props.onSubmit(
        Object.assign({}, this.quoteRequest.toObject(), {
          CustomerType: this.state.customerType,
          LoanType: 'vapLoan'
        })
      );
      trackSelfDescribingEvent(
        'QuoteCostsFormSubmit',
        'ClickGenerateQuotesButton',
        JSON.stringify({
          vehicleClass: this.props.vehicleClass.toLowerCase(),
          VatAddedToDeposit: this.quoteRequest.VatAddedToDeposit ? 'true' : 'false'
        })
      );
    } else {
      this.validator.validate(this.quoteRequest.toObject());
    }
  };

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  render() {
    const canUseVaps = !this.quoteRequest.Accessories > 0;
    const loadingState = this.props.isLoading ? 'loading' : 'done';
    const errors = this.validator.getErrors();
    let depositRules;

    if (isNaN(parseFloat(this.quoteRequest.CashDeposit))) {
      depositRules = errors.CashDeposit;
    } else {
      depositRules =
        parseFloat(this.quoteRequest.CashDeposit) <= parseFloat(this.quoteRequest.valueAddedProductsTotal)
          ? errors.CashDeposit
          : {
              error: 'max',
              message: this.props.t(
                'EditVapsForm.cash_deposit_cannot_be_larger_than_the_total_value_added_products_cost'
              )
            };
    }

    let newLoanAmount = this.quoteRequest.valueAddedProductsTotal - this.quoteRequest.CashDeposit;
    newLoanAmount = newLoanAmount < 0 ? 0 : newLoanAmount;
    return (
      <div>
        <MobxForm onSubmit={this.handleSubmit}>
          <div className="editVapsForm__section">
            {canUseVaps && (
              <div className="editVapsForm__valueAddedProducts">
                <MobxFieldGroup isInline error={null}>
                  <MobxFormLabel>{this.props.t('EditVapsForm.current_loan_amount')}</MobxFormLabel>
                  <CurrencyInput
                    trackingPage="EditVapsForm"
                    value={this.quoteRequest.Advance}
                    disabled={true}
                    id="Previous Loan Amount"
                  />
                </MobxFieldGroup>

                <Divider />
                <MobxFieldGroup isInline error={null}>
                  <MobxFormLabel>{this.props.t('EditVapsForm.value_added_products')}</MobxFormLabel>
                  <CurrencyInput
                    trackingPage="EditVapsForm"
                    value={this.quoteRequest.valueAddedProductsTotal}
                    disabled={true}
                    id="ValueAddedProducts"
                  />
                </MobxFieldGroup>

                {!this.props.appStore.vapStore.visibleProducts.length > 0 &&
                  !this.props.appStore.vapStore.fetchVapsRequest.hasError && (
                    <div className="editVapsForm__noVaps">
                      {this.props.t(
                        'EditVapsForm.you_currently_dont_have_any_value_added_products_va_ps_set_up_for_your_dealership'
                      )}
                      <br />
                      {this.props.t(
                        'EditVapsForm.products_can_be_configured_in_the_settings_area_by_your_dealership_administrator'
                      )}
                    </div>
                  )}
                {this.props.appStore.vapStore.fetchVapsRequest.hasError && (
                  <div className="editVapsForm__noVaps">
                    {this.props.t('EditVapsForm.sorry_there_was_a_problem_loading_your_va_ps')}
                  </div>
                )}

                {this.quoteRequest.ValueAddedProducts.map((vap, index) => (
                  <div key={index} data-name={vap.Name}>
                    <ValueAddedProduct
                      key={`valueAddedProduct-${index}`}
                      index={index}
                      label={vap.ProductTypeText}
                      value={vap.Price}
                      onChange={this.quoteRequest.changeVapPrice}
                      onRemove={this.quoteRequest.removeVap}
                      onEdit={this.editVap}
                      error={errors.ValueAddedProducts && errors.ValueAddedProducts[index]}
                      trackingPage="EditVapsForm"
                    />
                  </div>
                ))}

                {this.props.appStore.vapStore.visibleProducts.length > 0 && (
                  <div className="editVapsForm__addVapButton">
                    <Button type="button" buttonStyle="secondary" onClick={this.addVap} trackingPage="EditVapsForm">
                      {this.quoteRequest.ValueAddedProducts.length > 0
                        ? this.props.t('EditVapsForm.add_another_vap')
                        : this.props.t('EditVapsForm.add_a_vap')}
                    </Button>
                  </div>
                )}
              </div>
            )}
            <Divider />
            <MobxFieldGroup isInline error={depositRules}>
              <MobxFormLabel htmlFor="Term">{this.props.t('EditVapsForm.cash_deposit')}</MobxFormLabel>
              <CurrencyInput
                trackingPage="EditVapsForm"
                value={this.state.ActualCashDeposit}
                onChange={this.setCashDeposit}
                id="CashDeposit"
              />
            </MobxFieldGroup>

            <MobxFieldGroup isInline error={errors.Term}>
              <MobxFormLabel htmlFor="Term">{this.props.t('EditVapsForm.term_months')}</MobxFormLabel>
              <SelectInput
                trackingPage="EditVapsForm"
                value={this.quoteRequest.Term}
                onChange={this.setTerm}
                options={hasExtendedTermLength(this.props.vehicleClass) ? 'TermsExtended' : 'Terms'}
                id="Term"
                hasEditLimit={this.props.defaultValues.Term}
              />
            </MobxFieldGroup>
            <div className="editVapsForm__termText">
              {this.props.t('EditVapsForm.personal_loan_term_cannot_exceed_the_duration_of_the_vehicle_loan')}
            </div>
            <Divider />
            <MobxFieldGroup isInline error={null}>
              <MobxFormLabel>{this.props.t('EditVapsForm.new_loan_amount')}</MobxFormLabel>
              <CurrencyInput trackingPage="EditVapsForm" value={newLoanAmount} disabled={true} id="NewLoanAmount" />
            </MobxFieldGroup>
          </div>
          <FormFooter
            submitLabel={this.props.t('EditVapsForm.generate_quotes')}
            onCancel={this.props.onCancel}
            submittingState={loadingState}
            trackingPage="EditVapsForm"
          />

          <AddVapModal
            isOpen={this.state.addVapModalOpen}
            onClose={this.closeAddVapModal}
            onSubmit={this.handleSubmitAddVapModal}
            currentVaps={this.quoteRequest.ValueAddedProducts}
            vehicleClass={this.props.vehicleClass}
          />

          <EditVapModal
            isOpen={this.state.isEditVapModalOpen}
            onClose={this.closeAddVapModal}
            onSubmit={this.handleSubmitEditVapModal}
            vapToEdit={this.quoteRequest.ValueAddedProducts[this.state.indexOfVapToEdit]}
            currentVaps={this.quoteRequest.ValueAddedProducts}
            vehicleClass={this.props.vehicleClass}
          />
        </MobxForm>
      </div>
    );
  }
}

export default withTranslation('Quoting')(inject(['appStore'])(observer(EditVapsForm)));
EditVapsForm.propTypes = {
  customerId: PropTypes.string.isRequired,
  customerType: PropTypes.string.isRequired,
  dealershipId: PropTypes.string.isRequired
};
