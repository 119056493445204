import _ from 'lodash';
import * as actionTypes from '../actionTypes';
import moment from 'moment';

function parseDealershipSettings(settings) {
  settings = settings || {};
  settings.UsageType = settings.UsageType ? settings.UsageType.toLowerCase() : 'consumer';
  settings.VehicleType = settings.VehicleType ? settings.VehicleType.toLowerCase() : 'used';
  settings.VehicleClass = settings.VehicleClass ? settings.VehicleClass.toLowerCase() : 'car';
  settings.PreferredTerm = settings.PreferredTerm ? settings.PreferredTerm : 36;
  settings.DepositValue = settings.DepositValue ? settings.DepositValue : 0;
  settings.DefaultAnnualMileage = settings.DefaultAnnualMileage ? settings.DefaultAnnualMileage : 10000;
  return settings;
}

const defaultState = {
  initialised: false,
  loadingState: '',
  errorMessage: '',
  Claims: []
};

export default function sessionReducer(state = defaultState, action) {
  let newState;
  let newDealerships;

  switch (action.type) {
    case actionTypes.SIGN_IN:
    case actionTypes.WHO_AM_I:
      return Object.assign({}, state, {
        initialised: true,
        loadingState: 'loading',
        hasIncorrectLogin: false,
        hasSsoLoginError: false,
        isSignedIn: false
      });

    case actionTypes.SIGN_IN_SUCCESS:
    case actionTypes.WHO_AM_I_SUCCESS:
      const { Dealerships, ...response } = action.response;

      let dealerships = _.each(Dealerships, (dealership) => {
        return {
          ...dealership,
          Settings: parseDealershipSettings(dealership.Settings)
        };
      });

      let firstName = response.Firstname || '';
      let lastName = response.LastName || '';
      let initials = firstName.substring(0, 1).toUpperCase() + lastName.substring(0, 1).toUpperCase();
      let hasUnreadPlatformUpdates = !response.WhatsNewLastViewed
        ? true
        : moment(response.LastWhatsNewPosted).valueOf() > moment(response.WhatsNewLastViewed).valueOf();

      return Object.assign({}, state, {
        loadingState: 'done',
        isSignedIn: true,
        ...response,
        Dealerships: dealerships,
        Initials: initials,
        hasUnreadPlatformUpdates
      });

    case actionTypes.SIGN_IN_ERROR:
      return Object.assign({}, state, {
        loadingState: 'error',
        hasIncorrectLogin: action.hasIncorrectLogin,
        hasSsoLoginError: action.hasSsoLoginError
      });

    case actionTypes.WHO_AM_I_ERROR:
      return {
        loadingState: 'error'
      };

    case actionTypes.UPDATE_WHO_AM_I_SUCCESS:
      newState = _.cloneDeep(state);

      newDealerships = _.map(action.response.Dealerships, (dealership) => {
        return Object.assign(dealership, { Settings: parseDealershipSettings(dealership.Settings) });
      });

      newState = Object.assign(newState, action.response, {
        isSignedIn: true,
        Dealerships: newDealerships
      });

      return newState;

    case actionTypes.SIGN_OUT:
    case actionTypes.END_SESSION:
      if (action.message) {
        return Object.assign(_.cloneDeep(defaultState), {
          initialised: true,
          errorMessage: action.message
        });
      }
      return Object.assign(_.cloneDeep(defaultState), {
        initialised: true
      });

    case actionTypes.GET_PLATFORM_UPDATES_SUCCESS:
      return Object.assign({}, state, {
        hasUnreadPlatformUpdates: false
      });

    default:
      return state;
  }
}
