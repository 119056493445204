import React from 'react';
import PropTypes from 'prop-types';
import './addressHistoryPanel.scss';
import BusinessAddressHistoryPanelItem from './BusinessAddressHistoryPanelItem';

const BusinessAddressHistoryPanel = ({ addresses }) => (
  <div className="addressHistoryPanel">
    {addresses.map((address, index) => (
      <div className="addressHistoryPanel__inner" key={'add' + index}>
        <BusinessAddressHistoryPanelItem address={address} index={index} />
      </div>
    ))}
  </div>
);

BusinessAddressHistoryPanel.propTypes = {
  addresses: PropTypes.array
};

export default BusinessAddressHistoryPanel;
