import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './vehicleReservationItem.scss';
import MoneyFormat from '../../Common/MoneyFormat';
import DateFormat from '../../Common/DateFormat';
import { inject, observer } from 'mobx-react';
import Icon from '../../Common/Icon/Icon';
import Button from '../../Common/Button/Button';
import SplitButton from '../../Common/SplitButton';
import SplitButtonOptionDefault from '../../Common/SplitButtonOptionDefault';
import SplitButtonOption from '../../Common/SplitButtonOption';
import * as lookups from '../../../api/lookups';
import ReservePaymentTag from './ReservePaymentTag';
import LineClamp from '../../Dashboard/components/panel/LineClamp';
import classnames from 'classnames';
import LoadingSpinner from '../../Common/Loading/LoadingSpinner';
import TrackEvent from '../../Common/Tracking/TrackEvent';
import IncompleteReservationTooltip from './IncompleteReservationTooltip';
import VehicleImageWithVrm from '../../Common/VehicleImageWithVrm';
import { withNavigate } from 'hocs/router';

@inject('appStore')
@observer
class VehicleReservationItem extends React.Component {
  state = {
    isEmailTaken: false,
    isRefundLoading: false,
    isRefundHidden: false,
    isRefundError: false,
    isCreateLoading: false,
    isEmailLoading: false,
    isTagHovered: false
  };

  componentDidMount() {
    this.lookupCustomerByEmail(this.props.data.email);
    this.setIsRefunded();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data.refunded !== prevProps.data.refunded) {
      this.setIsRefunded();
    }
  }

  lookupCustomerByEmail = (email) => {
    this.setState({
      isEmailLoading: true
    });
    lookups
      .lookupCustomerByEmail(email, this.props.dealershipId)
      .then(this.onExistingCustomerSuccess)
      .catch(this.onExistingCustomerError);
  };
  setIsRefunded = () => {
    this.setState({
      isRefundHidden: this.props.data.refunded
    });
  };
  onExistingCustomerSuccess = (response) => {
    this.setState({
      existingCustomer: response,
      isEmailTaken: true,
      isEmailLoading: false
    });
  };
  onExistingCustomerError = () => {
    this.setState({
      existingCustomer: null,
      isEmailTaken: false,
      isEmailLoading: false
    });
  };
  handleAddCustomer = () => {
    this.setState({
      isCreateLoading: true
    });
    const data = this.props.data;
    const customerData = {
      firstName: data.firstName,
      surname: data.lastName,
      customerType: 'consumer',
      title: data.title,
      email: data.email,
      mobile: data.phoneNumber
    };
    window && window.ga && window.ga('send', 'event', 'VehicleReservationItem', 'CreateCustomerTypeConsumerReserve');
    this.props.appStore.customerListStore
      .addCustomer(customerData, this.props.dealershipId, false)
      .then((customerId) => {
        this.props.navigate(`/d/${this.props.dealershipId}/consumers/${customerId}`);
        this.setState({
          isCreateLoading: false
        });
      });
  };
  handleShowRefundConfirmMessage = () => {
    this.props.appStore.reserveNowStore.setRefundCustomer(this.props.dealershipId, this.props.data);
    this.props.appStore.reserveNowStore.toggleRefundConfirmationMessage();
  };
  handlePaymentAsDeposit = () => {
    this.props.appStore.reserveNowStore.setRefundCustomer(this.props.dealershipId, this.props.data);
    this.props.appStore.reserveNowStore.togglePaymentAsDepositMessage();
  };
  handleTagHover = () => {
    if (this.props.data.paid === false) {
      this.setState((prevState) => ({
        isTagHovered: !prevState.isTagHovered
      }));
    }
  };
  canUsePaymentAsDeposit = () => {
    return (
      this.props.data.paymentUsedAsDeposit === false &&
      this.props.data.paid === true &&
      this.state.isRefundHidden === false
    );
  };

  render() {
    const data = this.props.data;
    const buttonOuterStyles = classnames(
      'vehicleReservationItem__buttonsOuter',
      this.state.isRefundHidden && 'vehicleReservationItem__buttonsOuter--single'
    );
    const isCurrentRefundItem =
      this.props.appStore.reserveNowStore.refundCustomer.dealershipId === this.props.dealershipId &&
      this.props.appStore.reserveNowStore.refundCustomer.data.stripePaymentId === this.props.data.stripePaymentId;
    const isRefundButtonLoading =
      this.props.appStore.reserveNowStore.setReservationRefundReserveItemRequest.isLoading && isCurrentRefundItem;
    const refundButtonHasError =
      this.props.appStore.reserveNowStore.setReservationRefundReserveItemRequest.error && isCurrentRefundItem;
    const isSplitButtonLoading =
      isRefundButtonLoading || this.props.appStore.reserveNowStore.isPaymentAsDepositLoading ? 'loading' : '';
    const shouldShowRefundButton = !this.state.isRefundHidden && data.paid === true;
    const shouldShowPaymentAsDepositDate = data.paymentUsedAsDepositDate;
    return (
      <div onMouseEnter={this.handleTagHover} onMouseLeave={this.handleTagHover} className="vehicleReservationItem">
        <div className="vehicleReservationItem__top">
          <div className="vehicleReservationItem__vehicleDetails">
            <div className="vehicleReservationItem__imageOuter">
              <VehicleImageWithVrm vrm={data.vrm} image={data.vehicleImageUrl} />
            </div>
            <div className="vehicleReservationItem__vehicle">
              <div className="vehicleReservationItem__makeModel">
                {data.make} {data.model}
              </div>
              <div className="vehicleReservationItem__derivative">{data.derivative}</div>
              <div className="vehicleReservationItem__price">
                <MoneyFormat value={data.vehiclePrice} />
              </div>
            </div>
          </div>
          <div className="vehicleReservationItem__paymentDetails">
            <div className="vehicleReservationItem__paymentTitle">
              <span className="vehicleReservationItem__paymentIcon">
                <Icon name="payment_icon" />
              </span>{' '}
              {this.props.t('VehicleReservationItem.reservation_payment')}
            </div>
            <div className="vehicleReservationItem__paymentInner">
              <div className="vehicleReservationItem__paymentPrice">
                <MoneyFormat value={data.reservePrice / 100} />
              </div>
              <div className="vehicleReservationItem__paymentTagOuter">
                <ReservePaymentTag data={data} isRefundHidden={this.state.isRefundHidden} />
                {this.state.isTagHovered && <IncompleteReservationTooltip />}
              </div>
            </div>
          </div>
          <div className={buttonOuterStyles}>
            {shouldShowRefundButton && (
              <div className="vehicleReservationItem__button">
                <TrackEvent featureName="vehicleReservation" interactionName="vehicleReservationItem_RefundCustomer">
                  {this.canUsePaymentAsDeposit() ? (
                    <SplitButton state={isSplitButtonLoading}>
                      <SplitButtonOptionDefault onClick={this.handleShowRefundConfirmMessage}>
                        {this.props.t('VehicleReservationItem.refund_customer')}
                      </SplitButtonOptionDefault>
                      <SplitButtonOption onClick={this.handlePaymentAsDeposit}>
                        {this.props.t('VehicleReservationItem.use_payment_as_deposit')}
                      </SplitButtonOption>
                    </SplitButton>
                  ) : (
                    <Button
                      onClick={this.handleShowRefundConfirmMessage}
                      buttonStyle="primary"
                      isLoading={isRefundButtonLoading}
                      hasError={refundButtonHasError}
                      disabled={this.state.isRefundHidden}
                      id="refundCustomer"
                    >
                      {this.props.t('VehicleReservationItem.refund_customer')}
                    </Button>
                  )}
                </TrackEvent>
              </div>
            )}
            <div className="vehicleReservationItem__button">
              {!this.state.isEmailLoading ? (
                <>
                  {this.state.isEmailTaken ? (
                    <TrackEvent
                      featureName="vehicleReservation"
                      interactionName="vehicleReservationItem_ToCustomerRecord"
                      linkTo={`/d/${this.props.dealershipId}/consumers/${this.state.existingCustomer.Id}`}
                    >
                      <Button
                        buttonStyle="secondary"
                        to={`/d/${this.props.dealershipId}/consumers/${this.state.existingCustomer.Id}`}
                      >
                        {this.props.t('VehicleReservationItem.to_customer_record')}
                      </Button>
                    </TrackEvent>
                  ) : (
                    <TrackEvent
                      featureName="vehicleReservation"
                      interactionName="vehicleReservationItem_CreateCustomerRecord"
                    >
                      <Button
                        onClick={this.handleAddCustomer}
                        isLoading={this.state.isCreateLoading}
                        buttonStyle="secondary"
                      >
                        {this.props.t('VehicleReservationItem.create_customer')}
                      </Button>
                    </TrackEvent>
                  )}
                </>
              ) : (
                <Button buttonStyle="secondary">
                  <LoadingSpinner size="small" />
                </Button>
              )}
            </div>

            {this.props.appStore.reserveNowStore.setReservationRefundReserveItemRequest.error &&
              isCurrentRefundItem && (
                <div className="vehicleReservationItem__refundError">
                  {this.props.t('VehicleReservationItem.there_was_a_problem_refunding_this_customer')}
                </div>
              )}
          </div>
        </div>
        <div className="vehicleReservationItem__bottom">
          <div className="vehicleReservationItem__bottomItem">
            <div className="vehicleReservationItem__bottomTitle">{this.props.t('VehicleReservationItem.customer')}</div>
            <div className="vehicleReservationItem__customerName" data-th="customerName">
              <LineClamp lineClamp={3}>
                {data.firstName} {data.lastName}
              </LineClamp>
            </div>
          </div>
          <div className="vehicleReservationItem__bottomItem">
            <div className="vehicleReservationItem__bottomTitle">
              {this.props.t('VehicleReservationItem.contact_details')}
            </div>
            <div className="vehicleReservationItem__bottomInfo">
              <a
                className="vehicleReservationItem__bottomLink"
                href={`mailto:${data.email}`}
                target="_blank"
                rel="noopener noreferrer"
                data-th="customerEmail"
              >
                <LineClamp lineClamp={2}>{data.email}</LineClamp>
              </a>
              <a
                className="vehicleReservationItem__bottomLink"
                href={`tel:${data.phoneNumber}`}
                data-th="customerPhone"
              >
                {data.phoneNumber}
              </a>
            </div>
          </div>
          {data.paid === false ? (
            <div className="vehicleReservationItem__bottomItem">
              <div className="vehicleReservationItem__bottomTitle">
                {this.props.t('VehicleReservationItem.reservation_attempted')}
              </div>
              <div className="vehicleReservationItem__bottomInfo" data-th="reservationStartDate">
                <DateFormat value={data.reservationStartDateTime} format="DD MMM YYYY, HH:mm" />
              </div>
            </div>
          ) : (
            <div className="vehicleReservationItem__bottomItem">
              <div className="vehicleReservationItem__bottomTitle">
                {this.props.t('VehicleReservationItem.payment_date')}
              </div>
              <div className="vehicleReservationItem__bottomInfo" data-th="customerPaymentDate">
                {data.paid === true ? (
                  <DateFormat value={data.paymentDate} format="DD MMM YYYY, HH:mm" />
                ) : (
                  <div>{this.props.t('VehicleReservationItem.n_a')}</div>
                )}
              </div>
            </div>
          )}

          <div className="vehicleReservationItem__bottomItem">
            <div className="vehicleReservationItem__bottomTitle">
              {this.props.t('VehicleReservationItem.reserved_until')}
            </div>
            <div className="vehicleReservationItem__bottomInfo" data-th="reservedUntildate">
              {data.paid === true ? (
                <DateFormat value={data.scheduledRefundDate} format="DD MMM YYYY, HH:mm" />
              ) : (
                <div>{this.props.t('VehicleReservationItem.n_a')}</div>
              )}
            </div>
          </div>
          {shouldShowPaymentAsDepositDate ? (
            <div className="vehicleReservationItem__bottomItem">
              <div className="vehicleReservationItem__bottomTitle">
                {this.props.t('VehicleReservationItem.used_as_deposit_date')}
              </div>
              <div className="vehicleReservationItem__bottomInfo" data-th="paymentUsedAsDepositDate">
                <DateFormat value={data.paymentUsedAsDepositDate} format="DD MMM YYYY, HH:mm" />
              </div>
            </div>
          ) : (
            <div className="vehicleReservationItem__bottomItem">
              <div className="vehicleReservationItem__bottomTitle">
                {this.props.t('VehicleReservationItem.refunded_date')}
              </div>
              <div className="vehicleReservationItem__bottomInfo" data-th="customerRefundDate">
                {data.refundDate ? <DateFormat value={data.refundDate} format="DD MMM YYYY, HH:mm" /> : 'N/A'}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

VehicleReservationItem.propTypes = {
  appStore: PropTypes.object,
  data: PropTypes.object,
  dealershipId: PropTypes.string
};
export default withNavigate(withTranslation('ReserveNow')(VehicleReservationItem));
