import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import TextInputWrappingInput from '../Common/Form/TextInputWrappingInput';
import InputTooltip from './InputTooltip';
import './productNameLookup.scss';

class ProductNameLookup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProductNameTooltip: false
    };
  }

  handleOnFocus = () => {
    this.setState({
      showProductNameTooltip: true
    });
  };
  handleOnBlur = () => {
    this.setState(
      {
        showProductNameTooltip: false
      },
      () => {
        this.props.onBlur();
      }
    );
  };
  handleChange = (productName) => {
    this.props.onChange && this.props.onChange(productName);
  };

  render() {
    const { showProductNameTooltip } = this.state;
    const { appStore } = this.props;
    const { hasOrders } = appStore.uiState;
    const showToolTip = hasOrders && showProductNameTooltip;
    return (
      <div className="productNameLookup">
        <TextInputWrappingInput
          id="productName"
          value={this.props.value}
          onChange={this.handleChange}
          onFocus={this.handleOnFocus}
          onBlur={this.handleOnBlur}
          isValid={this.props.isValid}
          isInvalid={this.props.isInvalid}
        />
        <div className="productNameLookup__tooltip">
          <InputTooltip show={showToolTip}>
            <div>
              <p className="productNameLookup__message">{this.props.t('ProductNameLookup.this_is_the_product')}</p>
            </div>
          </InputTooltip>
        </div>
      </div>
    );
  }
}

ProductNameLookup.propTypes = {
  appStore: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool
};
export default withTranslation('Common')(inject('appStore')(observer(ProductNameLookup)));
