import React from 'react';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';

import Page from '~Common/Page';
import Panel from '~Common/Panel';
import PanelHeader from '~Common/PanelHeader';

import VisLookUp from '~Common/VisLookup';
import { StockEditRefinementSearch } from './components';
import { StockBreadcrumbs } from '../../../../../../shared/components';
import { useParams } from 'react-router-dom';

const StockEditRefinementContainer = (props) => {
  const params = useParams();
  const { t } = useTranslation('Stock');
  return (
    <Page>
      <StockBreadcrumbs
        items={[
          {
            name: 'Stock List',
            path: `/d/${params.dealershipId}/stock/list`
          },
          {
            name: 'Refine Vehicle'
          }
        ]}
      />

      <Panel>
        <PanelHeader>{t('StockRefinementContainer.refine_vehicle_record')}</PanelHeader>
        <VisLookUp
          defaultValues={{
            touched: true
          }}
          dealershipId={params.dealershipId}
        >
          <StockEditRefinementSearch />
        </VisLookUp>
      </Panel>
    </Page>
  );
};

export default inject('appStore')(StockEditRefinementContainer);
