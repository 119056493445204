import customerSelfServiceVideo from '../../styles/icons/helpVideos/customerSelfServiceVideo.gif';
import dashboardVideo from '../../styles/icons/helpVideos/dashboardVideo.png';
import lenderUpdatesVideo from '../../styles/icons/helpVideos/lenderUpdatesVideo.png';
import managingQuotesVideo from '../../styles/icons/helpVideos/managingQuotesVideo.png';
import productSettingsVideo from '../../styles/icons/helpVideos/productSettingsVideo.gif';
import quickQuoteVideo from '../../styles/icons/helpVideos/quickQuoteVideo.png';
import vapsVideo from '../../styles/icons/helpVideos/valueAddedProducts.png';
import sendDealVideo from '../../styles/icons/helpVideos/sendDealVideo.png';
import userPermissionsVideo from '../../styles/icons/helpVideos/userPermissionsVideo.png';
import stockEngineVideo from '../../styles/icons/helpVideos/stockEngineVideo.png';
import managingLeadsVideo from '../../styles/icons/helpVideos/managingLeadsVideo.png';
import managingCustomerRecordsVideo from '../../styles/icons/helpVideos/managingCustomerRecordsVideo.png';
import reservationsVideo from '../../styles/icons/helpVideos/reservationsVideo.png';

export const pageVideoMap = {
  Landing: 1, // /d/:dealershipId
  Consumer: 15, // /d/:dealershipId/consumers/:consumerId
  Consumers: 15, // /d/:dealershipId/consumers
  // AddCustomer: 4, // /d/:dealershipId/customer/add
  Settings: 2, // /d/:dealershipId/settings
  SettingsProduct: 2, // /d/:dealershipId/settings/product
  AddVehicle: 15, // /d/:dealershipId/consumers/:consumerId/add
  QuickQuoteVehicleDetails: 6, // /d/:dealershipId/quickquote
  FinanceApplications: 1, // /d/:dealershipId/financeapplications
  CfcProspects: 1, // /d/:dealershipId/cfcprospects
  LenderUpdatesPage: 1, // /d/:dealershipId/lenderupdatespage
  // PersonalDetails: 12, // /d/:dealershipId/consumers/:consumerId/application/:applicantId/personaldetails
  // AddressHistory: 12, // /d/:dealershipId/consumers/:consumerId/application/:applicantId/addresshistory
  // EmploymentHistory: 12, // /d/:dealershipId/consumers/:consumerId/application/:applicantId/employmenthistory
  // Affordability: 12, // /d/:dealershipId/consumers/:consumerId/application/:applicantId/affordability
  // BusinessDetails: 12, // /d/:dealershipId/consumers/:consumerId/application/:applicantId/businessdetails
  // BusinessAddress: 12, // /d/:dealershipId/consumers/:consumerId/application/:applicantId/businessaddress
  // OrganisationContacts: 12, // /d/:dealershipId/consumers/:consumerId/application/:applicantId/organisationcontacts
  // BankDetails: 12, // /d/:dealershipId/consumers/:consumerId/application/:applicantId/bankdetails
  // ApplicationSummary: 12, // /d/:dealershipId/consumers/:consumerId/application/:applicantID/applicationsummary
  ApplicationStatus: 5,
  ApplicationEdit: 5,
  // ApplicationRepropose: 17,
  QuickQuoteApply: 6, // /d/:dealershipId/quickquote/apply
  QuickQuoteResults: 6,
  LeadCloseOut: 18,
  // 'Reporting': 19,
  LeadAssignment: 1,
  ValueAddedProducts: 10,
  SendDeal: 11, // /d/:dealershipId/send-deal
  ReserveNow: 14 // /d/:dealershipId/reservenow
};

export const groups = [
  {
    title: 'General Navigation',
    videos: [1, 6, 15, 16]
  },
  {
    title: 'Digital Deals',
    videos: [11, 14]
  },
  {
    title: 'Managing Quotes',
    videos: [3, 5, 6, 9, 10]
  },
  {
    title: 'Managing Dealership and Product Settings',
    videos: [2, 10, 12]
  },
  {
    title: 'Managing Customers and Vehicles',
    videos: [13, 14, 16]
  }
];

export const videos = [
  {
    id: 1,
    videoPath: 'x1x0mHLAF68',
    title: 'Dashboard',
    thumbnail: dashboardVideo,
    relatedVideos: [6, 16],
    isYoutubeVideo: true
  },
  {
    id: 2,
    videoPath: '1ffxr4rybo',
    title: 'Product Settings',
    thumbnail: productSettingsVideo,
    relatedVideos: []
  },
  {
    id: 3,
    videoPath: 'zcX3bzeTm1M',
    title: 'Managing Quotes',
    thumbnail: managingQuotesVideo,
    relatedVideos: [9, 10],
    isYoutubeVideo: true
  },
  {
    id: 5,
    videoPath: 'cC9UpuvL6Is',
    title: 'Managing Lender Updates',
    thumbnail: lenderUpdatesVideo,
    relatedVideos: [],
    isYoutubeVideo: true
  },
  {
    id: 6,
    videoPath: 'V69F5gTrqro',
    title: 'Quick Quote',
    thumbnail: quickQuoteVideo,
    relatedVideos: [3],
    isYoutubeVideo: true
  },
  {
    id: 9,
    videoPath: '0yrg9752lz',
    title: 'Customer Self Service Applications',
    thumbnail: customerSelfServiceVideo,
    relatedVideos: [],
    isSelfService: true
  },
  {
    id: 10,
    videoPath: 'qj9k1mZP_Bc',
    title: 'Value Added Products',
    thumbnail: vapsVideo,
    isYoutubeVideo: true,
    youTubeStartTime: 0,
    relatedVideos: []
  },
  {
    id: 11,
    videoPath: 't8ssxKANQnw',
    isYoutubeVideo: true,
    title: 'Send Deal',
    thumbnail: sendDealVideo,
    relatedVideos: [6, 10],
    isSendDeal: true
  },
  {
    id: 12,
    videoPath: '0oye4IuV9lE',
    title: 'User Permissions',
    thumbnail: userPermissionsVideo,
    relatedVideos: [],
    isYoutubeVideo: true
  },
  {
    id: 13,
    videoPath: 'L8c9vrzNs1g',
    title: 'Stock Engine',
    thumbnail: stockEngineVideo,
    relatedVideos: [],
    isYoutubeVideo: true,
    isStockModuleUser: true
  },
  {
    id: 14,
    videoPath: 'Dxf6cS_6xKA',
    title: 'Reservation Tool',
    thumbnail: reservationsVideo,
    relatedVideos: [],
    isYoutubeVideo: true,
    canViewReservations: true
  },
  {
    id: 15,
    videoPath: 'BWJcldiEbZM',
    title: 'Managing Customer Records',
    thumbnail: managingCustomerRecordsVideo,
    relatedVideos: [],
    isYoutubeVideo: true
  },
  {
    id: 16,
    videoPath: '0ihlpJdaU6o',
    title: 'Managing Leads',
    thumbnail: managingLeadsVideo,
    relatedVideos: [1],
    isYoutubeVideo: true
  }
];
