import { StylesConfig } from 'react-select';

export const legendPadding = {
  id: 'increase-legend-spacing',
  beforeInit(chart: any) {
    const originalFit = chart.legend.fit;
    chart.legend.fit = function fit() {
      originalFit.bind(chart.legend)();
      this.height += 20;
    };
  }
};

export const defaultSelectStyles: StylesConfig<any, false> = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #ccc',
    boxShadow: state.isFocused ? '0 2px 5px rgba(0, 0, 0, 0.1)' : 'none',
    '&:hover': {
      borderColor: '#ccc'
    },
    width: 175
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: isSelected ? '#3182CE' : isFocused ? '#f7f7f7' : 'none',
    color: isSelected ? '#fff' : '#4a4a4a',
    padding: '10px',
    overflow: 'hidden'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    transform: 'rotate(0deg)',
    transition: 'transform 0.3s ease',
    overflow: 'hidden'
  }),
  menu: (provided) => ({
    ...provided,
    border: '1px solid #ccc',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
  })
};

export const wideSelectStyles: StylesConfig<any, false> = {
  control: (css) => ({
    ...css,
    width: 300
  }),
  option: (css) => ({ ...css, width: 300 })
};
