import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';

import { formatExcess, formatMoney, formatNumber } from '~/core/helpers';
import { formatToNumDecimalPlaces } from '~/components/Quoting/components/utils';
import { getAprDecimalPointsPerCountry } from '~/components/Quoting/components/FormatAPR';
import { DetailsTableItemProps } from '../DetailsTable/DetailsTableItem';
import { PaymentDetails, QuoteRowType } from '../../types';

type QuoteRowValueProps = DetailsTableItemProps & { appStore?: any };

// Translating, formatting and rendering quote row value
// This component should not hold any styling, all styling should be done in the parent component that renders it
export const QuoteRowValue: React.FC<QuoteRowValueProps> = inject('appStore')(
  ({ label, value: { value, type }, appStore }) => {
    const { t } = useTranslation('QuoteCard');
    const countryCode = appStore.uiState.countryCode || 'gb';
    const aprDecimalPlaces = useMemo(() => getAprDecimalPointsPerCountry(countryCode), [countryCode]);

    switch (type) {
      case QuoteRowType.number:
        return <>{formatNumber(value ?? 0, 0, countryCode)}</>;
      case QuoteRowType.money:
        return <>{formatMoney(value ?? 0, countryCode)}</>;
      case QuoteRowType.decimals:
        return <>{formatToNumDecimalPlaces(value, label === 'Apr' ? aprDecimalPlaces : 2, '%')}</>;
      case QuoteRowType.excess:
        return <>{formatExcess(value, countryCode)}</>;
      case QuoteRowType.months:
        return <>{`${value} ${value === 1 ? t('QuoteCardFigures.month') : t('QuoteCardFigures.months')}`}</>;
      case QuoteRowType.paymentSchedule:
        const paymentDetails = value as PaymentDetails;
        return <>{formatMoney(paymentDetails?.amount, countryCode)}</>;
      default:
        return <>{value}</>;
    }
  }
);
