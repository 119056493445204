import Request from '../mobx-models/Request';
import { observable, action } from 'mobx';
import * as quotingApi from '../api/quoting';

class QuotingStore {
  @observable settlementRequest = new Request();
  settlementFigure = 0;
  expiry = '';
  @observable settlementDocumentPdfLink = false;
  statusCode = 0;
  @observable agreementId = 0;
  @observable hasError = false;
  @observable hasViewedOptions = false;
  @observable newCarOffers = null;
  @observable shouldResetQuotingState = false;

  @action
  getSettlementFigure = (formData, dealershipId, customerId) => {
    return this.settlementRequest
      .onSuccess((response) => {
        this.settlementFigure = response.Amount;
        this.expiry = response.ValidUntil;
        this.statusCode = response.StatusCode;
        this.timeout = response.Timeout;
        this.agreementId = formData.agreementId;
        this.settlementDocumentPdfLink = formData.shouldRequestPdf;
        this.hasError = false;
      })
      .onError(() => {
        this.resetSettlement();
        this.hasError = true;
      })
      .call(quotingApi.requestSettlementFigure, formData, dealershipId, customerId);
  };

  @action setHasViewedOptions = (status) => {
    this.hasViewedOptions = status;
  };
  @action
  setShouldResetQuotingState = (bool) => {
    this.shouldResetQuotingState = bool;
  };

  @action
  resetSettlement = () => {
    this.settlementFigure = 0;
    this.expiry = '';
    this.statusCode = 0;
    this.settlementDocumentPdfLink = false;
  };

  getNewCarOffers = async (quoteeUid) => {
    quotingApi
      .fetchNewCarOfferTerms(quoteeUid)
      .then(
        action((offers) => {
          this.newCarOffers = offers;
        })
      )
      .catch(() => {});
  };
}

export default QuotingStore;
