import React from 'react';
import PropTypes from 'prop-types';
import Page from '../../Common/Page';
import CFCProspectsWidget from './CFCProspectsWidget';
import Breadcrumbs from '../../Common/Breadcrumbs';
import DashboardLeadsFilter from './DashboardLeadsFilter';

const CfcProspects = (props) => (
  <Page>
    <Breadcrumbs
      items={[
        {
          name: 'Home',
          path: `/d/${props.dealershipId}`
        },
        {
          name: 'CFC Prospects'
        }
      ]}
    />
    <div key="cont">
      <DashboardLeadsFilter onClick={props.filterCfcProspects} filter={props.activeFilter} />
      <CFCProspectsWidget
        items={props.items}
        onFilter={props.filterByMatchRate}
        filter={props.matchRate}
        handleClick={props.viewConsumer}
        currentPage={props.page}
        totalItems={props.total}
        pageSize={props.pageSize}
        changePage={props.changePage}
      />
    </div>
  </Page>
);

CfcProspects.propTypes = {
  session: PropTypes.object.isRequired,
  dashboard: PropTypes.object,
  options: PropTypes.object,
  dealershipId: PropTypes.string,
  params: PropTypes.object,
  activeFilter: PropTypes.string,
  filterCfcProspects: PropTypes.func,
  filterByMatchRate: PropTypes.func,
  viewConsumer: PropTypes.func,
  changePage: PropTypes.func,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.array,
  matchRate: PropTypes.string,
  total: PropTypes.number,
  pageSize: PropTypes.number
};

export default CfcProspects;
