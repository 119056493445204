import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import MediaQuery from 'react-responsive';
import EligibilityMatch from '../../Common/EligibilityMatch';
import FunderLogo from '../../Common/FunderLogo';
import ThreeColumnLayout from '../../Common/layouts/ThreeColumnLayout';
import TwoColumnLayout from '../../Common/layouts/TwoColumnLayout';
import MoneyFormat from '../../Common/MoneyFormat';
import ErroredQuote from '../../QuotesList/components/ErroredQuote';
import ProductName from '../../QuotesList/components/ProductName';
import QuoteButton from '../../QuotesList/components/QuoteButton';
import QuoteSplitButton from '../../QuotesList/components/QuoteSplitButton';
import Schemes from '../../QuotesList/components/Schemes';
import CardItem from '../../QuotesList/mediaComponents/CardItem';
import CardOuter from '../../QuotesList/mediaComponents/CardOuter';
import ProductInfoCardLayout from '../../QuotesList/mediaComponents/ProductInfoCardLayout';
import { inject, observer } from 'mobx-react';
import FormatAPR from './FormatAPR';
import './quotesListCardItem.scss';
import { hasExtendedTermLength } from '~/utils/quoteUtils';

// Only used in RepExampleSelectQuoteForm. Should be replaced with updated QuoteCard.
@inject('appStore')
@observer
class QuotesListCardItem extends React.Component {
  state = {
    showItemFullQuote: this.props.showFullQuote
  };
  handleQuoteToggle = () => {
    this.setState((prevState) => ({
      showItemFullQuote: !prevState.showItemFullQuote
    }));
  };

  componentDidUpdate(prevProps) {
    if (this.props.showFullQuote !== prevProps.showFullQuote) {
      this.setState({
        showItemFullQuote: this.props.showFullQuote
      });
    }
  }

  handleProductNameClick = () => {
    this.props.onProductNameClick(this.props.quote.ProductId);
  };
  handleProceed = () => {
    if (this.props.quote.PersonalLoan || this.props.quote.NegativeEquityLoan) {
      this.props.proceedToBreakDown(this.props.quote);
    } else {
      this.props.onProceed(this.props.quote);
    }
  };
  handleSaveQuote = () => {
    this.props.onSave(this.props.quote);
  };
  renderQuoteButton = () => {
    let isCombinedQuote = this.props.quote.PersonalLoan || this.props.quote.NegativeEquityLoan;
    const isQuickQuoteAndExtendedTermVehicle =
      this.props.isQuickQuote && hasExtendedTermLength(this.props.vehicleClass);

    if (this.props.quoteButtonChild) {
      return this.props.quoteButtonChild;
    } else if (
      ((!this.props.isQuickQuote || isQuickQuoteAndExtendedTermVehicle) && !this.props.isCustomerQuote) ||
      isCombinedQuote
    ) {
      return (
        <>
          {this.props.appStore.uiState.canCreateApplications && (
            <QuoteButton
              label={this.props.proceedLabel}
              loadingState={this.props.chosenQuoteId === this.props.quote.QuoteId ? this.props.proceedingState : ''}
              onClick={this.handleProceed}
              isSelected={this.props.quote.isChecked}
            />
          )}
        </>
      );
    } else if (this.props.appStore.uiState.canCreateApplications) {
      return (
        <QuoteSplitButton
          onSave={this.handleSaveQuote}
          onProceed={this.handleProceed}
          state={this.props.chosenQuoteId === this.props.quote.QuoteId ? this.props.proceedingState : ''}
          isQuickQuote={this.props.isQuickQuote}
          isSelected={this.props.quote.isChecked}
        />
      );
    } else {
      return (
        <QuoteButton
          label={this.props.t('QuotesListCardItem.save')}
          loadingState={this.props.chosenQuoteId === this.props.quote.QuoteId ? this.props.proceedingState : ''}
          onClick={this.handleSaveQuote}
          isSelected={this.props.quote.isChecked}
        />
      );
    }
  };

  render() {
    const rbpLabel = this.props.appStore.uiState.dealershipModules.includes('multilender');
    const isRbp =
      this.props.quote.CreditTier &&
      this.props.quote.CreditTier !== 'None' &&
      rbpLabel &&
      this.props.appStore.uiState.canUseRbp;
    return (
      <>
        {this.props.quote.Errors ? (
          <CardOuter
            funder={this.props.quote.FunderCode}
            quote={this.props.quote}
            trackingPage={this.props.trackingPage}
            isPersonalQuote={this.props.isPersonalQuote}
          >
            <CardItem>
              <ProductName
                disabled={
                  !this.props.appStore.uiState.canEditQuotingCommissions ||
                  this.props.disableProductNameClick ||
                  this.props.appStore.uiState.isBdk
                }
                productName={this.props.quote.ProductName}
                onClick={this.handleProductNameClick}
              />
            </CardItem>
            {this.props.quote.Errors.map((error, index) => (
              <ErroredQuote errorNumber={error.Number} errorMessage={error.Message} key={index} />
            ))}
          </CardOuter>
        ) : (
          <CardOuter
            funder={this.props.quote.FunderCode}
            quote={this.props.quote}
            onQuoteSelect={this.props.onQuoteSelect}
            toggleItemFullQuote={this.handleQuoteToggle}
            vehicleClass={this.props.vehicleClass}
            showFullQuote={this.state.showItemFullQuote}
            isPersonalQuote={this.props.isPersonalQuote}
            showCombinedQuoteClick={this.props.showCombinedQuoteClick}
            showFunderLogoDesktop={false}
            trackingPage={this.props.trackingPage}
            isRbp={isRbp}
            showCombinedDealDisclaimer={this.props.showCombinedDealDisclaimer}
            newCarOffer={this.props.newCarOffer}
          >
            {this.props.hasCheckedEligibility && (
              <CardItem>
                <ProductInfoCardLayout>
                  <EligibilityMatch
                    matchrate={this.props.quote.MatchRate}
                    onClick={() =>
                      this.props.onEligibilityMatchClick(this.props.quote.MatchRate, this.props.quote.DecisionMessages)
                    }
                    personalScorePercentage={this.props.quote.personalScorePercentage}
                    craScorePercentage={this.props.quote.craScorePercentage}
                    forceShowViewDetails={true}
                  />
                </ProductInfoCardLayout>
              </CardItem>
            )}

            <MediaQuery maxWidth="767px">
              <CardItem>
                <ProductInfoCardLayout>
                  <ProductName
                    disabled={
                      !this.props.appStore.uiState.canEditQuotingCommissions ||
                      this.props.disableProductNameClick ||
                      this.props.appStore.uiState.isBdk
                    }
                    productName={this.props.quote.ProductName}
                    onClick={this.handleProductNameClick}
                  />
                </ProductInfoCardLayout>
              </CardItem>
            </MediaQuery>
            <CardItem>
              <Schemes
                label={this.props.t('QuotesListCardItem.schemes')}
                showSchemes={this.props.schemesVisible}
                commissions={this.props.quote.Commissions}
              />
            </CardItem>
            <MediaQuery minWidth="768px">
              <ThreeColumnLayout>
                <CardItem centered label={this.props.t('QuotesListCardItem.lender')}>
                  <FunderLogo maxWidth="100px" funder={this.props.quote.FunderCode} />
                </CardItem>
                <CardItem centered label={this.props.t('QuotesListCardItem.product')}>
                  <ProductName
                    disabled={
                      !this.props.appStore.uiState.canEditQuotingCommissions ||
                      this.props.disableProductNameClick ||
                      this.props.appStore.uiState.isBdk
                    }
                    productName={this.props.quote.ProductName}
                    onClick={this.handleProductNameClick}
                  />
                </CardItem>
                <CardItem centered label={this.props.t('QuotesListCardItem.apr')}>
                  <FormatAPR value={this.props.quote.RepresentativeApr} />
                </CardItem>
              </ThreeColumnLayout>
              <ThreeColumnLayout>
                <CardItem centered label={this.props.t('QuotesListCardItem.total_amount_payable')}>
                  <MoneyFormat value={this.props.quote.TotalAmountPayable} />
                </CardItem>
                <CardItem centered label={this.props.t('QuotesListCardItem.agreement_term')}>
                  {this.props.quote.Term + ' ' + this.props.t('QuotesListCardItem.months')}
                </CardItem>
                <CardItem centered label={this.props.t('QuotesListCardItem.monthly_payments')}>
                  <MoneyFormat value={this.props.quote.FollowingPayments} />
                </CardItem>
              </ThreeColumnLayout>
            </MediaQuery>
            <MediaQuery maxWidth="767px">
              <TwoColumnLayout>
                <CardItem label={this.props.t('QuotesListCardItem.apr')}>
                  <FormatAPR value={this.props.quote.RepresentativeApr} />
                </CardItem>
                <CardItem label={this.props.t('QuotesListCardItem.total_amount_payable')}>
                  <MoneyFormat value={this.props.quote.TotalAmountPayable} />
                </CardItem>
              </TwoColumnLayout>
              <TwoColumnLayout>
                <CardItem label={this.props.t('QuotesListCardItem.agreement_term')}>
                  {this.props.quote.Term + ' ' + this.props.t('QuotesListCardItem.months')}
                </CardItem>
                <CardItem label={this.props.t('QuotesListCardItem.monthly_payments')}>
                  <MoneyFormat value={this.props.quote.FollowingPayments} />
                </CardItem>
              </TwoColumnLayout>
            </MediaQuery>
            {this.props.hideButton !== true && this.renderQuoteButton()}
          </CardOuter>
        )}
      </>
    );
  }
}

QuotesListCardItem.propTypes = {
  quote: PropTypes.object,
  hasCheckedEligibility: PropTypes.bool,
  onQuoteSelect: PropTypes.func,
  onProductNameClick: PropTypes.func,
  schemesVisible: PropTypes.bool,
  onProceed: PropTypes.func,
  onSave: PropTypes.func,
  isQuickQuote: PropTypes.bool,
  isCustomerQuote: PropTypes.bool,
  sortDirection: PropTypes.string,
  sortField: PropTypes.string,
  onSortQuotes: PropTypes.func,
  onEligibilityMatchClick: PropTypes.func,
  chosenQuoteId: PropTypes.string,
  proceedingState: PropTypes.string,
  vehicleClass: PropTypes.string,
  showCombinedQuoteClick: PropTypes.func,
  proceedToBreakDown: PropTypes.func,
  showFullQuote: PropTypes.bool,
  quoteButtonChild: PropTypes.node,
  proceedLabel: PropTypes.string,
  isPersonalQuote: PropTypes.bool,
  trackingPage: PropTypes.string,
  hideButton: PropTypes.bool
};
QuotesListCardItem.defaultProps = {
  proceedLabel: 'Proceed',
  isPersonalQuote: true,
  trackingPage: 'QuoteListView'
};
export default withTranslation('Quoting')(QuotesListCardItem);
