import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';

import PanelContent from '~Common/PanelContent';
import Button from '~Common/Button/Button';

import { useSelectedVehicle, useMetaData, useSearchState } from '~Common/VisLookup/hooks';
import { Hits, SearchBox, Pagination } from '~Common/VisLookup/components';

// most/all the styling is shared.
import './stockEditRefinementSearch.scss';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const StockEditRefinementSearch = (props) => {
  const { t } = useTranslation('Stock');
  const navigate = useNavigate();
  const { dealershipId, vehicleId } = useParams();
  const searchBarRef = useRef(null);
  const { selectedVehicleId } = useSelectedVehicle();
  const { hasResults, noResults, vehicles } = useMetaData();
  const searchState = useSearchState();
  const displaySearch = !selectedVehicleId;
  const displayPagination = !selectedVehicleId && vehicles.length > 0 && searchState === 'idle';
  const isVehicleSelected = selectedVehicleId !== null;

  const handlePaginationChange = () => {
    scrollToRef(searchBarRef);
  };

  const handleNextClick = () => {
    const { stockEditStore } = props.appStore;
    const selectedVehicleData =
      selectedVehicleId !== null && vehicles.filter((v) => v.objectID === selectedVehicleId)[0];
    stockEditStore.setVehicleTaxonomyData(selectedVehicleData);
    navigate(`/d/${dealershipId}/stock/edit/${vehicleId}/details`);
  };

  const handleCancel = () => {
    navigate(`/d/${dealershipId}/stock/edit/${vehicleId}/details`);
  };

  return (
    <>
      <PanelContent noPadding>
        <div className="stockEditRefinementSearch__comparison--outer">
          {displaySearch && <SearchBox ref={searchBarRef} />}

          <div className="stockEditRefinementSearch__visResults">
            {hasResults && (
              <p className="stockEditRefinementSearch__confirmVehicleText">
                {t('StockEditRefinementSearch.confirm_your_vehicle_derivative_from_the_search_results_below')}
              </p>
            )}
            <Hits />
            {displayPagination && <Pagination onChange={handlePaginationChange} />}

            <div className="stockEditRefinementSearch__buttonContainer">
              <div className="stockEditRefinementSearch__button">
                <Button onClick={handleCancel} buttonStyle="cancel">
                  {t('StockEditRefinementSearch.cancel')}
                </Button>
              </div>
              <div className="stockEditRefinementSearch__button">
                <Button
                  disabled={!(searchState === 'idle' && (noResults || isVehicleSelected))}
                  state={''}
                  onClick={handleNextClick}
                >
                  {t('StockEditRefinementSearch.next')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </PanelContent>
    </>
  );
};

export default inject('appStore')(StockEditRefinementSearch);
