import { withTranslation } from 'react-i18next';
import React from 'react';
import VehicleForm from '../Common/forms/VehicleForm';
import { observer, inject } from 'mobx-react';
import VisQuotePage from 'components/VisQuoting/VisQuotePage';

class PushDealSelectVehicle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      vehicleClass: props.appStore.pushDealStore.vehicle?.Class
    };
  }

  handleSubmit = (vehicle) => {
    this.props.appStore.pushDealStore.setVehicle(
      vehicle,
      vehicle.price,
      this.props.preferredDeposit,
      this.props.preferredAnnualMileage,
      this.props.preferredTerm,
      this.props.dealershipId,
      this.props.appStore.vapStore.getVisibleDefaultProductsClassFiltered(vehicle.Class)
    );
    this.props.continue();
  };
  handleCancel = () => {
    window.history.back();
  };

  updateVehicleClass = (vehicleClass) => {
    this.setState({ vehicleClass });
  };

  render() {
    if (this.props.appStore.uiState.shouldUseVisLookup(this.state.vehicleClass)) {
      return (
        <VisQuotePage
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
          onUpdateVehicleClass={this.updateVehicleClass}
          initialData={this.props.appStore.pushDealStore.vehicle}
          dealershipId={this.props.dealershipId}
        />
      );
    }

    return (
      <VehicleForm
        initialData={this.props.appStore.pushDealStore.vehicle}
        dealershipId={this.props.dealershipId}
        onCancel={this.handleCancel}
        onSubmit={this.handleSubmit}
        trackingPage="PushDealSelectVehicle"
        submitLabel={this.props.t('PushDealSelectVehicle.next')}
        onUpdateVehicleClass={this.updateVehicleClass}
      />
    );
  }
}

export default withTranslation('PushDeal')(inject('appStore')(observer(PushDealSelectVehicle)));
