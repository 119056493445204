import React from 'react';
import PropTypes from 'prop-types';
import Header from '../../Header/Header.js';
import Sidebar from '../../Sidebar/Sidebar';
import LoadingDots from '../Loading/LoadingDots';
import ConnectivityMessage from '../../Misc/components/ConnectivityMessage';
import SaveQuickQuoteModalContainer from '../../QuickQuote/containers/SaveQuickQuoteModalContainer';
import SaveCorporateQuickQuoteContainer from '../../QuickQuote/containers/SaveCorporateQuickQuoteContainer';
import VehicleNotesModalContainer from '../../VehicleNotes/containers/VehicleNotesContainer';
import EligibilityResultsModalContainer from '../../Customer/containers/EligibilityResultsModalContainer';
import EligibilitySummaryModalContainer from '../../Customer/containers/EligibilitySummaryModalContainer';
import SendDealModalContainer from '../../SendDeal/containers/SendDealModalContainer';
import DealershipDistanceContainer from '../../DealershipDistance/containers/DealershipDistanceContainer';
import ProceedFromCorporateQuickQuoteContainer from '../../QuickQuote/containers/ProceedFromCorporateQuickQuoteContainer';
import './globalLayout.scss';
import GdprAwarenessModal from './GdprAwarenessModal';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { withRouter } from 'hocs/router.js';

class GlobalLayout extends React.Component {
  constructor(props) {
    super(props);
    this.sidebarStateChange = this.sidebarStateChange.bind(this);
    this.state = {
      sidebarIsOpen: false,
      isGdprModalClicked: false,
      sidebarIsHovered: false
    };
  }

  sidebarStateChange() {
    this.setState({
      sidebarIsOpen: !this.state.sidebarIsOpen
    });
  }

  handleSignOut = () => {
    this.props.signOut();
  };

  UNSAFE_componentWillMount() {
    this.props.appStore.legalDocumentStore.fetchGoodPracticeDocs();

    this.setState({
      currentPath: this.props.location.pathname
    });
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.state.currentPath !== nextProps.location.pathname) {
      this.setState({
        currentPath: nextProps.location.pathname,
        sidebarIsOpen: false
      });
    }
  }

  handleGdprModalClick = () => {
    this.props.appStore.sessionStore.confirmGdprAwarenessClick(
      this.props.appStore.legalDocumentStore.goodPracticeDocsVersion
    );

    this.setState({
      isGdprModalClicked: true
    });
  };

  handleSidebarHoverOpen = (e) => {
    if (this.sidebar?.contains(e.target)) {
      this.setState({
        sidebarIsHovered: true
      });
    }
  };

  handleSidebarHoverClose = () => {
    this.setState({
      sidebarIsHovered: false
    });
  };

  closeSidebar = () => {
    this.setState({
      sidebarIsHovered: false
    });
  };

  createMarkup = () => {
    let html = this.props.appStore.legalDocumentStore.goodPracticeDocs.html;
    return { __html: html };
  };

  render() {
    const hasNewGoodPracticeVersion =
      this.props.appStore.legalDocumentStore.goodPracticeDocsVersion >
      this.props.session.PrivacyNoticeLastAcceptedVersion;

    const goodPracticeTimeUpdate =
      moment()
        .subtract(3, 'months')
        .format('x') > moment(this.props.session.PrivacyNoticeLastAcceptedTimestamp).format('x');

    return (
      <div className="globalLayout" id="signedIn" key="wrapper">
        <Sidebar
          innerRef={(el) => {
            this.sidebar = el;
          }}
          onSignOut={this.handleSignOut}
          handleStateChange={this.sidebarStateChange}
          isOpen={this.state.sidebarIsOpen}
          params={this.props.params}
          currentPage={this.props.location.pathname}
          currentQuery={this.props.query}
          hasPlatformUpdates={this.props.hasPlatformUpdates}
          closeSidebar={this.closeSidebar}
          sidebarIsHovered={this.state.sidebarIsHovered}
          handleSidebarHoverOpen={this.handleSidebarHoverOpen}
          handleSidebarHoverClose={this.handleSidebarHoverClose}
        />

        <Header
          firstName={this.props.session.Firstname}
          lastName={this.props.session.LastName}
          dealerships={this.props.session.Dealerships}
          currentDealershipId={this.props.params.dealershipId}
          onChangeDealership={this.handleChangeDealership}
          params={this.props.params}
          desktopsidebarIsOpen={this.state.sidebarIsHovered}
        />

        <ConnectivityMessage />

        {!this.props.session.IsSysAdmin && !this.state.isGdprModalClicked && (
          <GdprAwarenessModal
            onClick={this.handleGdprModalClick}
            content={this.createMarkup()}
            goodPracticeTimeUpdate={goodPracticeTimeUpdate}
            hasNewGoodPracticeVersion={hasNewGoodPracticeVersion}
          />
        )}

        {this.props.showLoadingSpinner ? <LoadingDots /> : null}

        <SaveQuickQuoteModalContainer />
        <SaveCorporateQuickQuoteContainer />
        <ProceedFromCorporateQuickQuoteContainer />
        <VehicleNotesModalContainer />
        <EligibilityResultsModalContainer />
        <EligibilitySummaryModalContainer />
        <SendDealModalContainer />
        <DealershipDistanceContainer />

        <div
          key="overlay"
          onClick={this.sidebarStateChange}
          className={this.state.sidebarIsOpen ? 'globalLayout__overlay' : 'globalLayout__overlay--hidden'}
        />
        <div ref="content" id="content" className="globalLayout__content clearfix" key="content">
          <div className="globalLayout__content-inner">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

GlobalLayout.propTypes = {
  location: PropTypes.object,
  params: PropTypes.object.isRequired,
  children: PropTypes.any,
  themeName: PropTypes.string,
  signOut: PropTypes.func,
  session: PropTypes.object,
  dispatch: PropTypes.func,
  hasPlatformUpdates: PropTypes.bool,
  showLoadingSpinner: PropTypes.bool,
  appStore: PropTypes.object
};

export default withRouter(inject('appStore')(observer(GlobalLayout)));
