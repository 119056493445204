import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import ApplicationPage from './ApplicationPage';
import BusinessAddressForm from '../forms/BusinessAddressForm';
import { compose } from 'redux';
import { withRouter } from 'hocs/router';

class BusinessAddress extends React.Component {
  static propTypes = {
    params: PropTypes.object,
    application: PropTypes.object,
    applicationFields: PropTypes.object,
    validationRules: PropTypes.object,
    submitApplicationSection: PropTypes.func,
    saveApplicationSection: PropTypes.func
  };
  handleSubmit = (formData) => {
    const { dealershipId, consumerId, applicantId } = this.props.params;
    this.props.submitApplicationSection(
      'BusinessAddress',
      this.props.params.applicantId,
      formData,
      `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/organisationcontacts`
    );
  };
  handleSave = (formData) => {
    this.props.saveApplicationSection('BusinessAddress', this.props.params.applicantId, formData);
  };

  render() {
    return (
      <ApplicationPage title={this.props.t('BusinessAddress.business_address')}>
        <BusinessAddressForm
          defaultValues={this.props.application.AddressHistory}
          onSubmit={this.handleSubmit}
          onSave={this.handleSave}
          applicationFields={this.props.applicationFields.AddressHistory}
          validationRules={this.props.validationRules.AddressHistory}
          savingState={this.props.application.sectionSavingState}
          submittingState={this.props.application.sectionSubmittingState}
          dealershipId={this.props.params.dealershipId}
          quoteId={this.props.application.QuoteId}
        />
      </ApplicationPage>
    );
  }
}

export default compose(withTranslation('Application'), withRouter)(BusinessAddress);
