import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const getBackendLoadPath = () => {
  if (process.env.NODE_ENV === 'test') {
    return __dirname + '/../public/static/locales/{{lng}}/{{ns}}.json';
  } else if (process.env.REACT_APP_ENVIRONMENT === 'beta') {
    return '/dealer/beta/static/locales/{{lng}}/{{ns}}.json';
  } else {
    return '/dealer/platform/static/locales/{{lng}}/{{ns}}.json';
  }
};

const i18nInit = i18n
  .use(process.env.NODE_ENV === 'test' ? require('i18next-fs-backend') : HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['Common', 'ErrorMessages', 'Quoting'],
    debug: process.env.REACT_APP_ENVIRONMENT !== 'production' && process.env.NODE_ENV !== 'test',
    fallbackLng: 'en',
    supportedLngs: ['en', 'de', 'fr', 'es', 'it'],
    initImmediate: process.env.NODE_ENV !== 'test',
    returnEmptyString: false,
    backend: {
      loadPath: getBackendLoadPath()
    },
    defaultNS: 'Common',
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true
    }
  });

export { i18nInit };
export default i18n;
