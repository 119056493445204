import React from 'react';
import PropTypes from 'prop-types';
import './latestDealCard.scss';
import ApplicationsListItem from './ApplicationsListItem';
import MediaQuery from 'react-responsive';
import LatestDealsCustomerInfo from './LatestDealsCustomerInfo';
import LatestDealsCustomerInfoMedia from './LatestDealsCustomerInfoMedia';

const LatestDealCard = ({ deal, handleConsumer, handleApplicationClick }) => (
  <div className="latestDealCard">
    <div className="latestDealCard__consumer">
      <MediaQuery minWidth={601}>
        <LatestDealsCustomerInfo deal={deal} handleConsumer={handleConsumer} />
      </MediaQuery>
      <MediaQuery maxWidth={600}>
        <LatestDealsCustomerInfoMedia deal={deal} handleConsumer={handleConsumer} />
      </MediaQuery>
    </div>
    {deal.Applications.map((application, index) => (
      <ApplicationsListItem
        application={application}
        index={index}
        first={deal.Applications.length === 1}
        last={index === deal.Applications.length - 1}
        handleApplicationClick={() => handleApplicationClick(deal.CustomerId, application.Id)}
        key={'card' + index}
      />
    ))}
  </div>
);

LatestDealCard.propTypes = {
  deal: PropTypes.object,
  handleConsumer: PropTypes.func,
  handleApplicationClick: PropTypes.func
};

export default LatestDealCard;
