import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './settingsProductTabsItem.scss';

const SettingsProductTabsItem = ({ index, isActive, text, onClick }) => {
  const handleClick = () => {
    onClick(index);
  };
  const containerClasses = classnames(
    'settingsProductTabsItem__container--base',
    isActive ? 'settingsProductTabsItem__container--active' : 'settingsProductTabsItem__container--inactive'
  );

  const overlayClasses = classnames(
    'settingsProductTabsItem__overlay',
    isActive && 'settingsProductTabsItem__overlay--hidden'
  );

  return (
    <div className={containerClasses} onClick={handleClick}>
      <div className={overlayClasses} />
      <div className="settingsProductTabsItem__text">{text}</div>
    </div>
  );
};
SettingsProductTabsItem.propTypes = {
  isActive: PropTypes.bool,
  index: PropTypes.number,
  onClick: PropTypes.func,
  text: PropTypes.string
};
export default SettingsProductTabsItem;
