import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelToolBar from '../../Common/PanelToolBar';
import PanelContent from '../../Common/PanelContent';
import AssignLeadContainer from '../../AssignLead/containers/AssignLeadContainer';
import Customer from './ConsumerRecordItems/Customer';
import Address from './ConsumerRecordItems/Address';
import DealershipDistance from './ConsumerRecordItems/DealershipDistance';
import AlignRight from '../../Common/AlignRight';
import Layout from './ConsumerRecordItems/Layout';
import PanelToolBarLabel from '../../Common/PanelToolBarLabel';
import DateFormat from '../../Common/DateFormat';
import MoneyFormat from '../../Common/MoneyFormat';
import './consumerRecordDetails.scss';
import { compose } from 'redux';
import { withNavigate, withParams } from 'hocs/router';

class ConsumerRecordDetails extends React.Component {
  static propTypes = {
    consumer: PropTypes.object.isRequired,
    params: PropTypes.object,
    handleModalOpen: PropTypes.func,
    handleDealershipDistanceModal: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      linkedUsers: false
    };
  }

  componentDidMount() {
    window.setTimeout(() => {
      this.setState({
        linkedUsers: this.props.consumer.AliasCount > 0 ? true : false
      });
    }, 0);
  }

  handleEditCustomer = () => {
    const { dealershipId, consumerId } = this.props.params;
    this.props.navigate(`/d/${dealershipId}/consumers/${consumerId}/edit`);
  };

  render() {
    const consumer = this.props.consumer;
    return (
      <Panel>
        <PanelHeader>{this.props.t('ConsumerRecordDetails.customer_information')}</PanelHeader>
        <PanelToolBar>
          <PanelToolBarLabel
            customerOrigin={consumer.CreatedSource}
            createdDate={consumer.CreatedDateTimestamp}
            originalDealership={consumer.OriginalDealership.Name}
          />
          <AlignRight>
            <AssignLeadContainer
              dealershipId={this.props.params.dealershipId}
              customerId={consumer.Id}
              customerName={`${consumer.Firstname} ${consumer.Surname}`}
              customerType="consumer"
              initialAssignees={consumer.assignedTo}
            />
          </AlignRight>
        </PanelToolBar>
        <PanelContent>
          <Layout>
            <Customer
              consumer={consumer}
              handleModalOpen={this.props.handleModalOpen}
              handleEditCustomer={this.handleEditCustomer}
              linkedUsers={this.state.linkedUsers}
            />
            <Address address={consumer.Address} />
            {consumer.DistanceToDealership && (
              <DealershipDistance
                distance={consumer.DistanceToDealership}
                handleDealershipDistanceModal={this.props.handleDealershipDistanceModal}
              />
            )}
          </Layout>
        </PanelContent>
        {consumer.Settlement && consumer.Settlement.Value && consumer.Settlement.ExpiryDateTimeUtc && (
          <div>
            {consumer.Settlement.Expired ? (
              <div className="consumerRecordDetails__settlement">
                <div className="consumerRecordDetails__settlementSection">
                  {this.props.t('ConsumerRecordDetails.this_customers_settlement_quote_is_no_longer_valid')}
                </div>
              </div>
            ) : (
              <div>
                <div className="consumerRecordDetails__settlement">
                  <div className="consumerRecordDetails__settlementSection consumerRecordDetails__settlementTitle">
                    <strong>{this.props.t('ConsumerRecordDetails.customer_settlement_information')}</strong>
                  </div>
                  <div className="consumerRecordDetails__settlementSection">
                    {this.props.t('ConsumerRecordDetails.amount')}{' '}
                    <strong>
                      <MoneyFormat value={consumer.Settlement.Value} />
                    </strong>
                  </div>
                  <div className="consumerRecordDetails__settlementSection">
                    {this.props.t('ConsumerRecordDetails.expires')}{' '}
                    <strong>
                      <DateFormat value={consumer.Settlement.ExpiryDateTimeUtc} />
                    </strong>
                  </div>
                  {consumer.Settlement.HaveLetter && (
                    <div className="consumerRecordDetails__settlementLinkOuter">
                      <a
                        type="application/pdf"
                        href={`/platform/v1/settlement/document?DealershipId=${this.props.params.dealershipId}&AgreementId=${consumer.Settlement.AgreementId}`}
                        download={`${consumer.Settlement.AgreementId}.pdf`}
                        className="consumerRecordDetails__settlementLink"
                      >
                        {this.props.t('ConsumerRecordDetails.view_settlement_letter')}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </Panel>
    );
  }
}

export default compose(withParams, withNavigate, withTranslation('Customer'))(ConsumerRecordDetails);
