import React from 'react';
import PropTypes from 'prop-types';
import './outerLeft.scss';

const OuterLeft = ({ children }) => {
  return (
    <div className="outerLeft" key="OuterLeft">
      {children}
    </div>
  );
};

OuterLeft.propTypes = {
  children: PropTypes.node
};

export default OuterLeft;
