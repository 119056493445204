import React from 'react';
import PropTypes from 'prop-types';
import './dailyStatistics.scss';

const DailyStatistics = ({ busiestWindow }) => (
  <div className="dailyStatistics__outer">
    <div className="dailyStatistics__timeOuter">
      <div className="dailyStatistics__time">{busiestWindow.Window ?? '-'}</div>
    </div>
  </div>
);

DailyStatistics.propTypes = {
  busiestWindow: PropTypes.object
};

export default DailyStatistics;
