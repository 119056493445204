import React from 'react';
import PropTypes from 'prop-types';
import SelectInput from '../../Common/Form/SelectInput';
import FormFooter from '../../Common/Form/FormFooter';
import SendDealToExistingCorporateCustomer from '../components/SendDealToExistingCorporateCustomer';
import EmailLookup from '../../Common/EmailLookup';

import MobxForm from '../../MobxForm/MobxForm';
import MobxFormFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import TextInputWrappingInput from '../../Common/Form/TextInputWrappingInput';
import { observer, inject } from 'mobx-react';

import objectValidation from '../../../core/objectValidation';

import i18n from '../../../i18n';

const getValidationMessage = (key) =>
  i18n.t(key, {
    ns: 'ErrorMessages'
  });

class QuickQuoteCorporateDetailsForm extends React.Component {
  state = {
    TradingName: '',
    Title: '',
    FirstName: '',
    LastName: '',
    Position: '',
    Email: '',
    Landline: '',
    Mobile: '',
    CustomerType: 'corporate'
  };

  getValidationRules() {
    return {
      TradingName: 'required',
      Title: 'required',
      firstName: 'required',
      LastName: 'required',
      Position: 'required',
      Email: 'required, email',
      Landline: 'required:unless:Mobile, landline',
      Mobile: 'required:unless:Landline, mobile'
    };
  }

  getValidationMessages() {
    const required = getValidationMessage('required');

    return {
      TradingName: {
        required
      },
      Title: required,
      FirstName: {
        required,
        maxlength: getValidationMessage('maxCharacters')
      },
      lastName: {
        required,
        maxlength: getValidationMessage('maxCharacters')
      },
      Position: {
        required
      },
      Email: {
        required
      },
      Landline: {
        required: getValidationMessage('phone'),
        landline: getValidationMessage('landline')
      },
      Mobile: {
        phone: required,
        mobile: getValidationMessage('mobile')
      }
    };
  }

  handleSubmit = () => {
    this.props.onSave(this.state);
  };

  validate = (formData) => {
    return objectValidation(formData, this.getValidationRules());
  };

  setTradingName = (value) => {
    this.setState({
      TradingName: value
    });
  };

  setTitle = (id, value) => {
    this.setState({
      Title: value
    });
  };

  setFirstName = (value) => {
    this.setState({
      FirstName: value
    });
  };

  setMiddleName = (value) => {
    this.setState({
      MiddleName: value
    });
  };

  setLastName = (value) => {
    this.setState({
      LastName: value
    });
  };

  setPosition = (id, value) => {
    this.setState({
      Position: value
    });
  };

  setEmail = (value) => {
    this.setState({
      Email: value
    });
  };

  setLandline = (value) => {
    this.setState({
      Landline: value
    });
  };

  setMobile = (value) => {
    this.setState({
      Mobile: value
    });
  };

  render() {
    const errors = this.validate(this.state) || {};

    return (
      <MobxForm onSubmit={this.handleSubmit} focusOnFirstElement className="proceedFromCorporateQuickQuoteForm">
        <MobxFormFieldGroup error={errors.TradingName}>
          <MobxFormLabel htmlFor="TradingName">Trading Name</MobxFormLabel>
          <SendDealToExistingCorporateCustomer
            dealershipId={this.props.dealershipId}
            onChange={this.setTradingName}
            value={this.state.TradingName}
            submit={this.props.submitExistingCustomer}
          />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.Title}>
          <MobxFormLabel htmlFor="Title">Title</MobxFormLabel>
          <SelectInput
            id="Title"
            value={this.state.Title}
            funderCode={this.props.funderCode}
            onChange={this.setTitle}
            options="CustomerTitles"
          />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.FirstName}>
          <MobxFormLabel htmlFor="FirstName">First Name</MobxFormLabel>
          <TextInputWrappingInput id="FirstName" value={this.state.FirstName} onChange={this.setFirstName} />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.MiddleName}>
          <MobxFormLabel htmlFor="MiddleName">Middle Name</MobxFormLabel>
          <TextInputWrappingInput id="MiddleName" value={this.state.MiddleName} onChange={this.setMiddleName} />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.LastName}>
          <MobxFormLabel htmlFor="LastName">Last Name</MobxFormLabel>
          <TextInputWrappingInput id="LastName" value={this.state.LastName} onChange={this.setLastName} />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.Position}>
          <MobxFormLabel htmlFor="Position">Position</MobxFormLabel>
          <SelectInput id="Position" value={this.state.position} onChange={this.setPosition} options="Position" />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.Email}>
          <MobxFormLabel htmlFor="Email">Customer email</MobxFormLabel>
          <EmailLookup
            sector="corporate"
            dealershipId={this.props.dealershipId}
            onChange={this.setEmail}
            value={this.state.Email}
            autoComplete="none"
            placeholder="Enter customer email address"
          />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.Landline}>
          <MobxFormLabel htmlFor="Landline">Business Telephone (or)</MobxFormLabel>
          <TextInputWrappingInput id="Landline" value={this.state.Landline} onChange={this.setLandline} />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.Mobile}>
          <MobxFormLabel htmlFor="Mobile">Mobile Telephone</MobxFormLabel>
          <TextInputWrappingInput id="Mobile" value={this.state.Mobile} onChange={this.setMobile} />
        </MobxFormFieldGroup>

        <FormFooter submitLabel="Send Deal" onCancel={this.props.onCancel} />
      </MobxForm>
    );
  }
}

QuickQuoteCorporateDetailsForm.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  submitExistingCustomer: PropTypes.func,
  funderCode: PropTypes.string
};

export default inject(['appStore'])(observer(QuickQuoteCorporateDetailsForm));
