import React from 'react';
import RepExampleSettings from '../components/RepExampleSettings';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import RepExampleView from '../components/RepExampleView';
import { useRepExampleFeatureIsOn } from '~/features';
import { inject } from 'mobx-react';

const RepExampleSettingsContainer = ({
  preferredTerm,
  preferredDeposit,
  preferredAnnualMileage,
  dealership,
  productSettings,
  appStore
}) => {
  const params = useParams();
  const canUseRepExample = useRepExampleFeatureIsOn();
  const { hasSingleLender } = appStore.uiState || {};

  if (canUseRepExample && !hasSingleLender) return <RepExampleView />;

  return (
    <RepExampleSettings
      preferredTerm={preferredTerm}
      preferredDeposit={preferredDeposit}
      preferredAnnualMileage={preferredAnnualMileage}
      params={params}
      dealership={dealership}
      productSettings={productSettings}
    />
  );
};

function mapStateToProps(state, ownProps) {
  return {
    dealership: state.dealership,
    preferredTerm: state.globalDefaultSettings.PreferredTerm,
    preferredDeposit: state.globalDefaultSettings.DepositValue,
    preferredAnnualMileage: state.globalDefaultSettings.DefaultAnnualMileage,
    productSettings: state.products.items
  };
}

export default inject('appStore')(connect(mapStateToProps)(RepExampleSettingsContainer));
