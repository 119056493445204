import React from 'react';
import PropTypes from 'prop-types';
import './dealTableItem.scss';

const DealTableItem = (props) => (
  <div>
    <div className="dealTableItem__label">{props.label}</div>
    <div className="dealTableItem__info">{props.children}</div>
  </div>
);

DealTableItem.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node
};

export default DealTableItem;
