import PropTypes from 'prop-types';
import VehicleDetails from './ConsumerVehicleDetailsComponents/VehicleDetails';
import VehicleDetailsMileage from './ConsumerVehicleDetailsComponents/VehicleDetailsMileage';
import VehicleRegYear from './ConsumerVehicleDetailsComponents/VehicleRegYear';
import VehicleDetailsButtonContainer from './ConsumerVehicleDetailsComponents/VehicleDetailsButtonContainer';
import './consumerVehicleDetails.scss';

const ConsumerVehicleDetails = ({ consumer, vehicle, onNotesClick, params, latestPrice, deal }) => {
  if (!vehicle) {
    return null;
  }

  return (
    <div className="consumerVehicleDetails">
      <div className="consumerVehicleDetails__leftContainer" key="leftContainer">
        <VehicleDetails vehicle={vehicle} latestPrice={latestPrice} deal={deal} />
      </div>
      <div className="consumerVehicleDetails__middleContainer">
        <VehicleDetailsMileage mileage={vehicle.Mileage} />
        <div className="consumerVehicleDetails__iconDivider"></div>
        <VehicleRegYear vehicle={vehicle} />
      </div>
      <div className="consumerVehicleDetails__buttonContainer" key="buttonContainer">
        <VehicleDetailsButtonContainer
          params={params}
          consumer={consumer}
          vehicle={vehicle}
          vehicleId={vehicle.VehicleId}
          status={vehicle.Status.Status}
          isClosed={vehicle.Closed}
          onNotesClick={onNotesClick}
          notesCount={vehicle.DealerNotes.length}
        />
      </div>
    </div>
  );
};

ConsumerVehicleDetails.propTypes = {
  vehicle: PropTypes.object.isRequired,
  consumer: PropTypes.object,
  params: PropTypes.object,
  onNotesClick: PropTypes.func
};

export default ConsumerVehicleDetails;
