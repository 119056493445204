import React from 'react';
import PushDealNavItem from './PushDealNavItem';
import './pushDealProgress.scss';

const PushDealProgress = (props) => {
  const { titles, activeTitle, position } = props;
  const sectionWidth = 100 / titles.length;
  const progress = sectionWidth * position;

  return (
    <div className="pushDealProgress__container" data-th="PushDealProgress">
      {props.titles.map((title, i) => {
        return (
          <PushDealNavItem
            active={title === activeTitle}
            key={i}
            title={title}
            width={sectionWidth}
            data-th="PushDealProgress"
          />
        );
      })}
      <span className="pushDealProgress__progressStatus" style={{ width: `${progress - sectionWidth / 2}%` }} />
    </div>
  );
};

export default PushDealProgress;
