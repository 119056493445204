import { reaction } from 'mobx';
import CorporateQuickQuote from '../mobx-models/CorporateQuickQuote';
import * as api from '../api/quickQuote';
import CorporateQuickQuoteValidator from '../validators/CorporateQuickQuoteValidator';
import Request from '../mobx-models/Request';

class QuickQuoteStore {
  saveCorporateQuickQuoteRequest;
  proceedWithCorporateQuickQuoteRequest;
  corporateQuickQuote;
  corporateQuickQuoteValidator;
  validationReactionDisposer;

  constructor() {
    this.saveCorporateQuickQuoteRequest = new Request();
    this.proceedWithCorporateQuickQuoteRequest = new Request();
  }

  startNewCorporateQuickQuote = (dealershipId, vehicle, quote) => {
    this.corporateQuickQuote = new CorporateQuickQuote(this);
    this.corporateQuickQuote.setDealershipId(dealershipId);
    this.corporateQuickQuote.setVehicle(vehicle);
    this.corporateQuickQuote.setQuote(quote);

    this.setUpValidation();
  };

  setUpValidation() {
    this.corporateQuickQuoteValidator = new CorporateQuickQuoteValidator();

    this.validationReactionDisposer = reaction(
      () => ({ ...this.corporateQuickQuote.toJS() }),
      this.corporateQuickQuoteValidator.validate,
      { fireImmediately: true }
    );
  }

  tearDown = () => {
    this.validationReactionDisposer();
  };

  saveCorporateQuickQuote = () => {
    return this.saveCorporateQuickQuoteRequest.call(api.corporateQuickQuote, this.corporateQuickQuote, 1);
  };

  proceedWithCorporateQuickQuote = () => {
    return new Promise((resolve, reject) => {
      this.proceedWithCorporateQuickQuoteRequest
        .onSuccess(resolve)
        .onError(reject)
        .call(api.corporateQuickQuote, this.corporateQuickQuote, 0);
    });
  };
}

export default QuickQuoteStore;
