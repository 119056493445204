import ButtonWithIcon from '~/components/Common/Button/ButtonWithIcon';
import classnames from 'classnames';

import styles from './chartTypeFilter.module.scss';
import { ChartVariantType } from '~/components/Insights/types';

export type ChartTypeFilterProps = {
  chartType: ChartVariantType;
  onChange: (newType: ChartVariantType) => void;
};

const ChartTypeFilter = ({ chartType, onChange }: ChartTypeFilterProps) => {
  const handleChartTypeChange = (newType: ChartVariantType) => {
    onChange(newType);
  };

  const getButtonStyle = (type: ChartVariantType) =>
    classnames('square', {
      active: chartType === type
    });

  const chartTypes: ChartVariantType[] = ['line', 'table'];

  return (
    <section className={styles['chartTypeFilter']}>
      {chartTypes.map((type) => {
        return (
          <ButtonWithIcon
            key={type}
            iconName={`${type}-chart`}
            buttonStyle={getButtonStyle(type)}
            onClick={() => handleChartTypeChange(type)}
          >
            {type}
          </ButtonWithIcon>
        );
      })}
    </section>
  );
};

export default ChartTypeFilter;

