import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './search.scss';
import Icon from './Icon/Icon';

class Search extends React.Component {
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.value !== nextProps.value) {
      if (nextProps.value) {
        this.refs.query.value = nextProps.value;
      } else {
        this.refs.query.value = '';
      }
    }
  };

  handleUserSearch = () => {
    const query = this.refs.query.value;
    this.props.onSubmit(query);
  };

  onSubmit = (e) => {
    const query = this.refs.query.value;
    e.preventDefault();
    this.props.onSubmit(query);
  };

  render() {
    return (
      <form
        onSubmit={this.onSubmit}
        className={this.props.noMarginBottom ? 'search__outer--noMargin' : 'search__outer'}
        key="search"
        data-th="Search"
      >
        <input
          tabIndex={this.props.tabIndex ? this.props.tabIndex : 0}
          type="text"
          className="search__input"
          placeholder={this.props.placeHolder || this.props.t('Search.search')}
          ref="query"
          onChange={this.props.searchOnChange ? _.debounce(this.handleUserSearch, this.props.debounceTime) : null}
          onClick={this.props.searchOnClick ? this.handleUserSearch : null}
          onKeyDown={this.props.onKeyDown ? this.props.onKeyDown : null}
          maxLength={this.props.maxLength}
        />
        <button tabIndex="0" type="submit" className="search__button">
          <Icon name="search" />
        </button>
      </form>
    );
  }
}

Search.defaultProps = {
  maxLength: 100
};

Search.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  placeHolder: PropTypes.string,
  searchOnChange: PropTypes.bool,
  searchOnClick: PropTypes.bool,
  noMarginBottom: PropTypes.bool,
  value: PropTypes.string,
  tabIndex: PropTypes.number,
  debounceTime: PropTypes.number,
  onKeyDown: PropTypes.func,
  maxLength: PropTypes.number
};

export default withTranslation('Common')(Search);
