import { getNameForPath } from '../routeNames';
import { pageVideoMap, groups, videos } from './helpVideos.config';

export function getVideoForCurrentPage(path, query) {
  if (path.charAt(0) === '/') {
    path = path.slice(1, -1);
  }
  const pageName = getNameForPath(path);
  const videoId = pageVideoMap[pageName];
  return videoId ? videoId : null;
}

export function getGroups(
  canRepropose,
  hasCfc,
  canSelfServeQuotes,
  hasOrders,
  mock_hasStockEnabled,
  canViewReservations
) {
  const filteredVideos = getVideos(
    canRepropose,
    hasCfc,
    canSelfServeQuotes,
    hasOrders,
    mock_hasStockEnabled,
    canViewReservations
  );

  return groups
    .map((group) => {
      return {
        title: group.title,
        videos: group.videos.filter((videoId) => filteredVideos.find((filteredVideo) => filteredVideo.id === videoId))
      };
    })
    .filter((group) => group.videos.length > 0);
}

export function getVideos(
  canRepropose,
  hasCfc,
  canSelfServeQuotes,
  hasOrders,
  mock_hasStockEnabled,
  canViewReservations
) {
  const setViewableVideos = (item) => {
    if (item.cfc) {
      return hasCfc;
    } else if (item.isSelfService) {
      return canSelfServeQuotes;
    } else if (item.isRepropose) {
      return canRepropose;
    } else if (item.isSendDeal) {
      return hasOrders;
    } else if (item.isStockModuleUser) {
      return mock_hasStockEnabled;
    } else if (item.canViewReservations) {
      return canViewReservations;
    } else {
      return true;
    }
  };

  return videos.filter(setViewableVideos);
}
