import React from 'react';
import PropTypes from 'prop-types';
import './helpVideoMenu.scss';
import HelpVideoImageLink from './HelpVideoImageLink';
import HelpVideoMenuSection from './HelpVideoMenuSection';
import _ from 'lodash';

const HelpVideoMenu = ({ groups, videos, onClick }) => (
  <div className="helpVideoMenu">
    <div>
      {_.map(groups, (section, i) => {
        return (
          <HelpVideoMenuSection title={section.title} key={`helpVideo${i}`}>
            {_.map(section.videos, (videoId, j) => {
              const eachVideo = _.find(videos, { id: videoId });
              return <HelpVideoImageLink video={eachVideo} onClick={onClick} key={`helpVideoLink${i}${j}`} />;
            })}
          </HelpVideoMenuSection>
        );
      })}
    </div>
  </div>
);
HelpVideoMenu.propTypes = {
  videos: PropTypes.array,
  groups: PropTypes.array,
  onClick: PropTypes.func
};

export default HelpVideoMenu;
