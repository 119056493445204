import Request from '../mobx-models/Request';
import * as authApi from '../api/auth';

class AuthStore {
  checkPasswordResetGuidRequest = new Request();
  resetPasswordRequest = new Request();
  forgotPasswordRequest = new Request();
  changePasswordRequest = new Request();

  /**
   * @param {string} email
   * @memberof AuthStore
   */
  requestResetPassword = (email) => {
    return new Promise((resolve, reject) => {
      this.forgotPasswordRequest
        .onSuccess(resolve)
        .onError(reject)
        .call(authApi.requestResetPassword, email);
    });
  };

  /**
   * @param {string} guid Generic User ID to check
   * @memberof AuthStore
   */
  checkPasswordResetGuid = (guid) => {
    this.checkPasswordResetGuidRequest.call(authApi.checkPasswordResetGuid, guid);
  };

  /**
   * @param {string} guid Generic User ID
   * @param {string} newPassword new password to be set
   * @memberof AuthStore
   */
  resetPassword = (guid, newPassword) => {
    return new Promise((resolve, reject) => {
      this.resetPasswordRequest
        .onSuccess(resolve)
        .onError(reject)
        .call(authApi.resetPassword, guid, newPassword);
    });
  };

  /**
   * @param {string} newPassword
   * @param {string} oldPassword
   * @memberof AuthStore
   */
  changePassword = (newPassword, oldPassword) => {
    this.changePasswordRequest.call(authApi.changePassword, newPassword, oldPassword);
  };
}

export default AuthStore;
