import React from 'react';
import PropTypes from 'prop-types';
import './bulletList.scss';

const BulletList = ({ children }) => {
  return <ul className="bulletList">{children}</ul>;
};

BulletList.propTypes = {
  children: PropTypes.node
};

export default BulletList;
