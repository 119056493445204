import React from 'react';
import PropTypes from 'prop-types';
import './latestDealsCustomerInfo.scss';
import VehicleImage from '../../Common/VehicleImage';
import ConsumerName from '../../Common/ConsumerName';
import BusinessTag from '../../Common/BusinessTag';
import SmallVrm from '../../Common/SmallVrm';

const LatestDealsCustomerInfo = ({ deal, handleConsumer }) => {
  return (
    <div className="latestDealsCustomerInfo">
      <div className="latestDealsCustomerInfo__vehicleImage">
        <VehicleImage
          imageSource={deal.Vehicle.ImageUrl}
          imageWidth="70px"
          imageHeight="50px"
          iconFontSize="45px"
          iconMargin="3px 0 0 0"
          vehicleClass={deal.Vehicle.Class}
        />
      </div>
      <div className="latestDealsCustomerInfo__left">
        <div className="latestDealsCustomerInfo__name" onClick={() => handleConsumer(deal.CustomerId)}>
          {deal.CustomerType === 'Consumer' ? (
            <ConsumerName name={deal.Firstname + ' ' + deal.Surname} />
          ) : (
            <div>
              <span className="latestDealsCustomerInfo__tradingName">{deal.TradingName}</span>
              <BusinessTag userType={deal.CustomerType} smallTag={true} />
            </div>
          )}
        </div>
        <div className="latestDealsCustomerInfo__vehicle">
          <div className="latestDealsCustomerInfo__vrm">
            <SmallVrm vrm={deal.Vehicle.Vrm} />
          </div>
          <div className="latestDealsCustomerInfo__vehicleName">
            {deal.Vehicle.Make} {deal.Vehicle.Model} {deal.Vehicle.Derivative}
          </div>
        </div>
      </div>
    </div>
  );
};

LatestDealsCustomerInfo.propTypes = {
  deal: PropTypes.object,
  handleConsumer: PropTypes.func
};

export default LatestDealsCustomerInfo;
