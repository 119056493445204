import React from 'react';
import PropTypes from 'prop-types';
import './miniPanelToolbar.scss';
import classnames from 'classnames';

const MiniPanelToolbar = ({ hasPadding, children }) => {
  let classes = classnames(
    'miniPanelToolbar__toolbar',
    hasPadding === false ? 'miniPanelToolbar__notPadded' : 'miniPanelToolbar__padded'
  );

  return <div className={classes}>{children}</div>;
};

MiniPanelToolbar.propTypes = {
  children: PropTypes.node,
  hasPadding: PropTypes.bool
};

export default MiniPanelToolbar;
