import { ReactElement } from 'react';
import cn from 'classnames';

import  './onClick.css';

interface TrackEventClickProps {
  children: ReactElement;
  callback: () => void;
  className?: string,
}

const OnClick = ({ children, callback, className = '' }: TrackEventClickProps) => {
  return (
    <div className={cn('onClick__unsetAll', className)}  onClick={callback}>
      {children}
    </div>
  );
};

export default OnClick;
