import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import ApplicationPage from './ApplicationPage';
import AffordabilityForm from '../forms/AffordabilityForm';
import ConsumerVehicleInfoPanel from '../../Common/ConsumerVehicleInfoPanel';
import Page from '../../Common/Page';
import { withParams } from 'hocs/router';
import { compose } from 'redux';

const EditApplicationAffordability = (props) => {
  const { t } = useTranslation('Application');
  return (
    <Page>
      <Breadcrumbs
        items={[
          {
            name: t('EditApplicationAffordability.home'),
            path: `/d/${props.params.dealershipId}`
          },
          {
            name: t('EditApplicationAffordability.customer_list'),
            path: `/d/${props.params.dealershipId}/consumers`
          },
          {
            name: t('EditApplicationAffordability.consumer'),
            path: `/d/${props.params.dealershipId}/consumers/${props.params.consumerId}`
          },
          {
            name: t('EditApplicationAffordability.application_status'),
            path: `/d/${props.params.dealershipId}/consumers/${props.params.consumerId}/application/${props.params.applicantId}/status`
          },
          {
            name: t('EditApplicationAffordability.edit_application'),
            path: `/d/${props.params.dealershipId}/consumers/${props.params.consumerId}/application/${props.params.applicantId}/edit`
          },
          {
            name: t('EditApplicationAffordability.edit_affordability')
          }
        ]}
        consumer={props.consumer}
      />
      <ConsumerVehicleInfoPanel vehicle={props.application.Vehicle} />
      <Panel>
        <PanelHeader>{t('EditApplicationAffordability.edit_application')}</PanelHeader>
        <ApplicationPage title={t('EditApplicationAffordability.affordability')}>
          <AffordabilityForm
            defaultValues={props.application.AffordabilityDetails}
            onSubmit={props.submitApplicationSection}
            onCancel={props.handleBack}
            validationRules={props.validationRules.AffordabilityDetails}
            applicationFields={props.applicationFields.AffordabilityDetails}
            submittingState={props.application.sectionSubmittingState}
            funderCode={props.application.Quote.FunderCode}
          />
        </ApplicationPage>
      </Panel>
    </Page>
  );
};

EditApplicationAffordability.propTypes = {
  params: PropTypes.object.isRequired,
  application: PropTypes.object.isRequired,
  validationRules: PropTypes.object.isRequired,
  applicationFields: PropTypes.object.isRequired,
  consumer: PropTypes.object,
  vehicle: PropTypes.object,
  handleBack: PropTypes.func,
  submitApplicationSection: PropTypes.func
};

export default compose(withParams)(EditApplicationAffordability);
