import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import Page from '../../Common/Page';
import QuoteModule from '../../Quoting/containers/QuoteModuleContainer';
import Breadcrumbs from '../../Common/Breadcrumbs';
import { withRouter } from '../../../hocs/router';

class EditApplicationQuote extends React.Component {
  handleCancel = () => {
    const { dealershipId, consumerId, applicantId } = this.props.params;
    this.props.navigate(`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit`);
  };
  handleProceed = (quote, quoteRequest) => {
    let quoteRequestWithActual = { ...quoteRequest, ActualCashDeposit: parseFloat(quoteRequest.CashDeposit) };
    quoteRequest.Advance = quote.Advance;
    this.props.onProceedWithChangeQuote(
      this.props.application.Id,
      this.props.application.Vehicle,
      quote,
      this.props.application.CustomerType,
      this.props.params.dealershipId,
      this.props.params.consumerId,
      quoteRequestWithActual
    );
  };

  render() {
    const { dealershipId, consumerId, applicantId } = this.props.params;
    const quote = this.props.application.Quote;
    let tag;

    if (quote.FunderCode === 'CRE') {
      tag = this.props.application.Tags.multiQuoteRefId;
    }

    let restrictQuotesByProduct;

    if (
      quote.ProductCode === 'Rtl - VAT Assist Brand' ||
      quote.ProductCode === 'Rtl - Motor Select Brand' ||
      quote.ProductCode === 'Rtl - National Subsidy Brand'
    ) {
      restrictQuotesByProduct = quote.ProductCode;
    }

    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: this.props.t('EditApplicationQuote.home'),
              path: `/d/${dealershipId}`
            },
            {
              name: this.props.t('EditApplicationQuote.customer_list'),
              path: `/d/${dealershipId}/consumers`
            },
            {
              name: this.props.t('EditApplicationQuote.consumer'),
              path: `/d/${dealershipId}/consumers/${consumerId}`
            },
            {
              name: this.props.t('EditApplicationQuote.application_status'),
              path: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/status`
            },
            {
              name: this.props.t('EditApplicationQuote.edit_application'),
              path: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit`
            },
            {
              name: this.props.t('EditApplicationQuote.edit_quote')
            }
          ]}
          consumer={this.props.consumer}
        />
        <QuoteModule
          vehicle={this.props.vehicle}
          initialCosts={this.props.application.QuoteRequest}
          onCancel={this.handleCancel}
          onProceed={this.handleProceed}
          proceedingState={this.props.application.submittingState}
          restrictToProductType={
            this.props.application.Quote.FunderCode === 'MOT' ? this.props.application.Quote.FinanceType : null
          }
          restrictQuotesByProduct={restrictQuotesByProduct}
          restrictToFunderCode={this.props.application.Quote.FunderCode}
          params={this.props.params}
          assignees={this.props.assignees}
          customerType={this.props.application.CustomerType}
          customerId={consumerId}
          quote={quote}
          tag={tag}
        />
      </Page>
    );
  }
}

EditApplicationQuote.propTypes = {
  params: PropTypes.object,
  vehicle: PropTypes.object,
  application: PropTypes.object,
  onProceedWithChangeQuote: PropTypes.func,
  consumer: PropTypes.object,
  assignees: PropTypes.array
};

export default compose(withRouter, withTranslation('Application'))(EditApplicationQuote);
