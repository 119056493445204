import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './dashboardFilter.scss';
import DashboardFilterButton from './DashboardFilterButton';

const DashboardFilter = ({ filter, onClick }) => {
  const { t } = useTranslation('Dashboard');
  return (
    <div className="dashboardFilter">
      <DashboardFilterButton isActive={!filter} filterType={''} currentFilter={filter} handleClick={onClick}>
        {t('DashboardFilter.all')}
      </DashboardFilterButton>
      <DashboardFilterButton
        filterType="high"
        currentFilter={filter}
        isActive={filter === 'high'}
        handleClick={onClick}
      >
        {t('DashboardFilter.high')}
      </DashboardFilterButton>
      <DashboardFilterButton
        filterType="medium"
        currentFilter={filter}
        isActive={filter === 'medium'}
        handleClick={onClick}
      >
        {t('DashboardFilter.medium')}
      </DashboardFilterButton>
      <DashboardFilterButton handleClick={onClick} filterType="low" isActive={filter === 'low'} currentFilter={filter}>
        {t('DashboardFilter.low')}
      </DashboardFilterButton>
      <DashboardFilterButton
        isActive={filter === 'none'}
        currentFilter={filter}
        filterType="none"
        handleClick={onClick}
      >
        {t('DashboardFilter.unknown')}
      </DashboardFilterButton>
    </div>
  );
};

DashboardFilter.propTypes = {
  filter: PropTypes.string,
  onClick: PropTypes.func
};
export default DashboardFilter;
