import React from 'react';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import AssignLead from '../components/AssignLead';
import PropTypes from 'prop-types';
import { PropTypes as MobxTypes } from 'mobx-react';

class AssignLeadContainer extends React.Component {
  @observable isModalOpen = false;

  @action
  openModal = () => {
    this.isModalOpen = true;
  };

  @action
  closeModal = (e) => {
    e && e.preventDefault();
    this.isModalOpen = false;
  };

  render() {
    let initials = '';
    let avatarColor = 0;

    if (this.props.initialAssignees.length) {
      const firstAssignee = this.props.initialAssignees[0];
      const firstInitial = (firstAssignee.firstName && firstAssignee.firstName[0]) || '';
      const lastInitial = (firstAssignee.surname && firstAssignee.surname[0]) || '';
      initials = `${firstInitial} ${lastInitial}`.toUpperCase();
      avatarColor = firstAssignee.avatarColor;
    }

    return (
      <AssignLead
        dealershipId={this.props.dealershipId}
        isModalOpen={this.isModalOpen}
        initials={initials}
        avatarColor={avatarColor}
        initialAssignees={this.props.initialAssignees}
        customerId={this.props.customerId}
        customerName={this.props.customerName}
        customerType={this.props.customerType}
        openModal={this.openModal}
        closeModal={this.closeModal}
      />
    );
  }
}

AssignLeadContainer.propTypes = {
  initialAssignees: PropTypes.oneOfType([
    MobxTypes.arrayOrObservableArrayOf(MobxTypes.observableObject),
    PropTypes.arrayOf(PropTypes.object)
  ]),
  dealershipId: PropTypes.string,
  customerId: PropTypes.string,
  customerName: PropTypes.string,
  customerType: PropTypes.string
};

export default inject('appStore')(observer(AssignLeadContainer));
