import { action, extendObservable } from 'mobx';

class User {
  @action
  updateFromJSON = (json) => {
    extendObservable(this, {
      id: json.Id || json.UserId,
      claims: json.Claims && json.Claims.length >= 0 ? json.Claims : [],
      email: json.Email,
      firstName: json.Firstname,
      isDealerAdmin: json.IsDealerAdmin,
      lastActive: json.LastActive,
      mobile: json.Mobile,
      surname: json.Surname,
      avatarColor: json.Avatar && typeof json.Avatar.Colour === 'number' ? json.Avatar.Colour : null
    });
    return this;
  };
}

export default User;
