import Request from '../mobx-models/Request';
import { deleteCustomer } from '../api/customer';

class DeleteCustomerStore {
  deleteCustomerRequest = new Request();

  deleteCustomer = (customerId, customerType) => {
    return this.deleteCustomerRequest.call(deleteCustomer, customerId, customerType);
  };

  removeDeletedCustomerSuccess = () => {
    this.deleteCustomerRequest.reset();
  };
}

export default DeleteCustomerStore;
