import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SearchBar from '../../Common/Search';
import SearchDate from '../../Common/SearchDate';
import CustomerList from './CustomerList';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Page from '../../Common/Page';
import Button from '../../Common/Button/Button';
import Pagination from '../../Common/Pagination';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import PanelToolBar from '../../Common/PanelToolBar';
import CustomerListToolbarLayout from './CustomerListToolbarLayout';
import Select from '../../Common/Form/Select';
import PaginationSummary from '../../Common/PaginationSummary';
import './customerListPage.scss';
import { observer, inject } from 'mobx-react';
import DeletedCustomerSuccessBanner from '../../DeleteCustomer/DeletedCustomerSuccessBanner';

const CustomerListPage = ({ t, ...props }) => {
  const selectOptions = [
    {
      key: '',
      value: t('CustomerListPage.all_leads')
    },
    {
      key: 'unassigned',
      value: t('CustomerListPage.unassigned_leads')
    },
    {
      key: 'myAssigned',
      value: t('CustomerListPage.my_assigned_leads')
    },
    {
      key: 'assigned',
      value: t('CustomerListPage.assigned_leads')
    }
  ];

  const onChange = (e) => {
    e.preventDefault();
    let filterValue = e.target.value;
    props.handleFilter(filterValue);
  };

  const onDismissCustomerDeletedNotification = () => {
    props.appStore.deleteCustomerStore.removeDeletedCustomerSuccess();
  };

  return (
    <Page>
      <Breadcrumbs
        items={[
          {
            name: t('CustomerListPage.home'),
            path: `/d/${props.dealershipId}`
          },
          {
            name: t('CustomerListPage.customer_list'),
            path: `/d/${props.dealershipId}/consumers`
          }
        ]}
      />
      <DeletedCustomerSuccessBanner
        isDeleted={props.appStore.deleteCustomerStore.deleteCustomerRequest.isSuccessful}
        onClick={onDismissCustomerDeletedNotification}
      />
      <Panel>
        <PanelHeader>{t('CustomerListPage.customer_contacts')}</PanelHeader>
        <PanelToolBar noBorder>
          <CustomerListToolbarLayout>
            <PaginationSummary total={props.total} />
            <SearchBar value={props.searchQuery} onSubmit={props.handleSearch} />
            <div>
              <span>{t('CustomerListPage.show')}</span>
              <Select theme="alt" emptyValue={false} value={props.filter} options={selectOptions} onChange={onChange} />
            </div>
            <div>
              <span>{t('CustomerListPage.date')}</span>
              <SearchDate value={props.timePeriod} onChange={props.handleSearchDateChange} />
            </div>
            <div className="customerListPage__addButton">
              <Button to={`/d/${props.dealershipId}/customer/add`} buttonStyle="primary">
                {t('CustomerListPage.add_contact')}
              </Button>
            </div>
          </CustomerListToolbarLayout>
        </PanelToolBar>
        <PanelContent noPadding>
          <div className="customerListPage__customerList">
            <CustomerList
              dealershipId={props.dealershipId}
              customers={props.customers}
              isLoading={props.isLoading}
              handleSort={props.handleSort}
              sortDirection={props.sortDirection}
              sortField={props.sortField}
            />
          </div>
          <Pagination
            currentPage={props.page}
            totalItems={props.total}
            pageSize={props.pageSize}
            onChange={props.handlePageChange}
          />
        </PanelContent>
      </Panel>
    </Page>
  );
};

CustomerListPage.propTypes = {
  dealershipId: PropTypes.string.isRequired,
  customers: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  total: PropTypes.number,
  handleSearch: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
  handleSearchDateChange: PropTypes.func.isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  pageSize: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  timePeriod: PropTypes.string,
  searchQuery: PropTypes.string
};
export default withTranslation('CustomerList')(inject(['appStore'])(observer(CustomerListPage)));
