import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CfcProspects from '../components/CfcProspects';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import useQueryParams from '~/hooks/useQueryParams';
import { useNavigate } from '~/hooks/useNavigate';
import { useQuery } from '@tanstack/react-query';
import { dashboardQueries } from '~/api/dashboard/hooks';
import ms from 'ms';

const CfcProspectsContainer = ({ session }) => {
  const params = useParams();
  const [qs] = useQueryParams();
  const navigate = useNavigate();
  const { data } = useQuery({
    ...dashboardQueries.cfcProspects({
      userId: session.UserId,
      dealershipId: params.dealershipId,
      matchRate: qs.matchRate,
      activeFilter: qs.activeFilter,
      page: qs.page
    }),
    keepPreviousData: true,
    cacheTime: ms('1m')
  });

  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    navigate({
      pathname: `/d/${params.dealershipId}/cfcprospects`,
      query: { ...qs, page: page }
    });
  };

  const handleMatchRateFilter = (matchRate) => {
    navigate({
      pathname: `/d/${params.dealershipId}/cfcprospects`,
      query: { ...qs, matchRate: matchRate, page: 1 }
    });
  };

  const handleFilter = (activeFilter) => {
    navigate({
      pathname: `/d/${params.dealershipId}/cfcprospects`,
      query: { ...qs, activeFilter: activeFilter, page: 1 }
    });
  };

  return (
    <CfcProspects
      items={data?.Data}
      dealershipId={params.dealershipId}
      changePage={handlePageChange}
      filterCfcProspects={handleFilter}
      filterByMatchRate={handleMatchRateFilter}
      page={qs.page}
      activeFilter={qs.activeFilter}
      matchRate={qs.matchRate}
      total={data?.Total}
      pageSize={25}
      viewConsumer={(consumerId) => {
        navigate(`/d/${params.dealershipId}/consumers/${consumerId}`);
      }}
    />
  );
};

CfcProspectsContainer.propTypes = {
  children: PropTypes.any,
  session: PropTypes.object
};

function mapStateToProps(state) {
  return {
    session: state.session
  };
}

export default compose(connect(mapStateToProps))(CfcProspectsContainer);
