import PropTypes from 'prop-types';
import './miniPanelHeader.scss';

const MiniPanelHeader = ({ title, children }) => {
  return (
    <div className="miniPanelHeader__title">
      <div>{title}</div>
      <div className="miniPanelHeader__childWrapper miniPanelHeader__oneChildWrapper">{children}</div>
    </div>
  );
};

MiniPanelHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default MiniPanelHeader;
