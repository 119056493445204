import { ValueOf } from './index';

export type ApplicationStatusMetricType = ValueOf<typeof ApplicationStatusMetric> | 'all';

export const ApplicationStatusMetric = {
  Accepted: 'finance_application_accepted:count',
  Completed: 'finance_application_completed:count',
  'Paid Out': 'finance_application_paid_out:count',
  Pending: 'finance_application_pending:count',
  Rejected: 'finance_application_rejected:count',
  Started: 'vehicle_finance_application_started:count',
  Submitted: 'finance_application_initial_submission:count'
} as const;

export type MetricType = ValueOf<typeof Metric> | 'all';

export const Metric = {
  // Application Status Metrics
  Accepted: 'finance_application_accepted:count',
  Completed: 'finance_application_completed:count',
  'Paid Out': 'finance_application_paid_out:count',
  Pending: 'finance_application_pending:count',
  Rejected: 'finance_application_rejected:count',
  Started: 'vehicle_finance_application_started:count',
  Submitted: 'finance_application_initial_submission:count',

  // Non-Application Metrics
  DealerSearchImpression: 'dealer_search_impression:count',
  EligibilityCheckActivated: 'finance_eligibility_check_activated:count',
  EligibilityCheckCompleted: 'finance_eligibility_check_completed:count',
  EligibilityCheckFailed: 'finance_eligibility_check_failed:count',
  EligibilityCheckSubmitted: 'finance_eligibility_check_submitted:count',
  SuitabilityContentViewed: 'finance_product_suitability_content_viewed:count',
  SuitabilityQuestionAnswer: 'finance_product_suitability_question_and_answer:count',
  SuitabilityQuestionAnswerNegative: 'finance_product_suitability_question_and_answer:negative_count',
  SuitabilityQuestionAnswerPositive: 'finance_product_suitability_question_and_answer:positive_count',
  SuitabilityVideoViewed: 'finance_product_suitability_video_viewed:count',
  SuitabilityVideoViewedSeconds: 'finance_product_suitability_video_viewed:seconds_viewed',
  VulnerabilityContentViewed: 'finance_product_suitability_vulnerability_content_viewed:count',
  VulnerabilityOutboundLink: 'finance_product_suitability_vulnerability_outbound_link:count',
  QuotesDisplayed: 'finance_quotes_displayed:count',
  DaysInStock: 'dealer_vehicle_snapshot:days_in_stock'
} as const;
