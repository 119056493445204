import platformApiUtils from '@ivendi/platform-api-utils';
import { schemaMap } from '../../core/schema';
import optionsSchema from '../../core/schemas/optionsSchema';
import * as actionTypes from '../actionTypes';
import { formatOverrides } from './formatOverrides'; //will format API when hooked up

export function fetchOptions() {
  return (dispatch, getState) => {
    let options = getState().options;

    if (!options.isLoading && !options.hasFetched) {
      dispatch({
        types: [actionTypes.FETCH_OPTIONS, actionTypes.FETCH_OPTIONS_SUCCESS, actionTypes.FETCH_OPTIONS_ERROR],
        callAPI: () =>
          Promise.all([platformApiUtils.get('v1/options/all'), platformApiUtils.get('v1/options/all/overrides')]),
        parseResponse: ([all, overrides]) => ({
          all: schemaMap(all, optionsSchema),
          overrides: formatOverrides(overrides)
        })
      });
    }
  };
}

export function recreateOptions() {
  return { type: actionTypes.RECREATE_OPTIONS };
}

export function recreateClaimBasedOptions() {
  return { type: actionTypes.RECREATE_CLAIM_BASED_OPTIONS };
}
