import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import React from 'react';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Page from '../../Common/Page';
import Panel from '../../Common/Panel';
import PanelContent from '../../Common/PanelContent';
import PanelHeader from '../../Common/PanelHeader';
import SettingsAccountEditDetails from './SettingsAccountEditDetails';
import { compose } from 'redux';
import { withNavigate, withOutletContextProps } from 'hocs/router';
import './addUser.scss';

class AddUser extends React.Component {
  handleSubmit = (_, data) => {
    this.props.addUser(this.props.dealershipId, data).then(() => {
      this.props.navigate(`/d/${this.props.dealershipId}/settings/users`);
    });
  };

  render() {
    const userDefault = {
      firstName: '',
      surname: '',
      mobile: '',
      email: '',
      claims: []
    };
    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: this.props.t('AddUser.home'),
              path: `/d/${this.props.dealershipId}`
            },
            {
              name: this.props.t('AddUser.settings'),
              path: `/d/${this.props.dealershipId}/settings`
            },
            {
              name: this.props.t('AddUser.manage_users'),
              path: `/d/${this.props.dealershipId}/settings/users`
            },
            {
              name: this.props.t('AddUser.add_user'),
              path: `/d/${this.props.dealershipId}/settings/users/adduser`
            }
          ]}
        />
        <Panel>
          <PanelHeader>{this.props.t('AddUser.add_user')}</PanelHeader>
          <PanelContent noPadding={false}>
            <SettingsAccountEditDetails
              user={userDefault}
              onSubmit={this.handleSubmit}
              goBackLink={`/d/${this.props.dealershipId}/settings/users`}
              isNewUser={true}
              trackingPage="AddNewUser"
              hasSubmittingError={this.props.hasAddUserError}
              hasDuplicateEmailError={this.props.hasDuplicateEmailError}
            />
          </PanelContent>
        </Panel>
      </Page>
    );
  }
}

export default compose(withNavigate, withOutletContextProps, withTranslation('ManageUsers'), observer)(AddUser);
