import sumDataSet from '~/components/Insights/services/helpers/sumDataSet';
import useDates from './useDates';
import { ChartDimensions, DataSetType, DataSet } from '~/components/Insights/types';
import useChartData from '~/components/Insights/services/hooks/useChartData';

type UseChartDataProps = Required<Parameters<typeof useChartData>[0]>;

type Config = {
  dealershipId: string | undefined;
  metric: UseChartDataProps['metric'];
  period: 'day' | 'week' | 'month';
  dataSet?: DataSetType;
  datePeriod?: UseChartDataProps['datePeriod'];
};

type Data = {
  total: number;
  percentageChange?: number;
  previousTotal?: number;
};
type Loading = boolean;

type UseMetricsCount = (config: Config) => { data: Data | undefined; isLoading: Loading };
const useMetricsCount: UseMetricsCount = ({
  dealershipId,
  metric,
  period = 'week',
  dataSet = DataSet.Sum,
  datePeriod
}) => {
  const { fromDate, toDate, previousFromDate, previousToDate } = useDates(period);

  const { data, isLoading } = useChartData({
    metric,
    dimensions: [ChartDimensions.Date],
    fromDate,
    toDate,
    dealershipId,
    datePeriod
  });

  const { data: previousData, isLoading: isPreviousLoading } = useChartData({
    metric,
    dimensions: [ChartDimensions.Date],
    fromDate: previousFromDate,
    toDate: previousToDate,
    dealershipId,
    datePeriod
  });

  if (data) {
    try {
      const previous: Pick<Data, 'percentageChange' | 'previousTotal'> = {};
      const total = sumDataSet(data, dataSet);

      if (previousData) {
        const previousTotal = sumDataSet(previousData, dataSet);
        const percentageChange = previousTotal === 0 ? 0 : Math.round(((total - previousTotal) / previousTotal) * 100);
        previous.percentageChange = percentageChange;
        previous.previousTotal = previousTotal;
      }

      return {
        data: {
          total,
          ...previous
        },
        isLoading
      };
    } catch (e) {console.error(e)}
  }

  return { data: undefined, isLoading: isLoading || isPreviousLoading };
};

export default useMetricsCount;
