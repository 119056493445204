import React from 'react';
import PropTypes from 'prop-types';
import './quoteInfoValue.scss';

const QuoteInfoValue = ({ children }) => {
  return <span className="quoteInfoValue">{children}</span>;
};

QuoteInfoValue.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default QuoteInfoValue;
