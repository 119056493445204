import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import ApplicationPage from './ApplicationPage';
import AddressHistoryForm from '../../Common/forms/AddressHistoryForm';
import { withOutletContextProps, withParams } from 'hocs/router';
import { compose } from 'redux';

class AddressHistory extends React.Component {
  static propTypes = {
    params: PropTypes.object,
    application: PropTypes.object,
    validationRules: PropTypes.object,
    submitApplicationSection: PropTypes.func,
    saveApplicationSection: PropTypes.func
  };
  handleSubmit = (formData) => {
    const { dealershipId, consumerId, applicantId } = this.props.params;
    this.props.submitApplicationSection(
      'AddressHistory',
      this.props.params.applicantId,
      formData.Items,
      `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/employmenthistory`
    );
  };
  handleSave = (formData) => {
    this.props.saveApplicationSection('AddressHistory', this.props.params.applicantId, formData.Items);
  };

  render() {
    return (
      <ApplicationPage
        title={this.props.t('AddressHistory.address_history')}
        subTitle={this.props.t('AddressHistory.please_enter_at_least_3_years_of_address_history')}
      >
        <AddressHistoryForm
          defaultValues={this.props.application.AddressHistory}
          onSubmit={this.handleSubmit}
          onSave={this.handleSave}
          validationRules={this.props.validationRules.AddressHistory}
          savingState={this.props.application.sectionSavingState}
          submittingState={this.props.application.sectionSubmittingState}
          dealershipId={this.props.params.dealershipId}
          quoteId={this.props.application.QuoteId}
          progress={this.props.progress}
          application={this.props.application}
        />
      </ApplicationPage>
    );
  }
}

export default compose(withParams, withOutletContextProps, withTranslation('Application'))(AddressHistory);
