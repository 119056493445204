/* eslint-disable import/no-anonymous-default-export */
import sharedDefaultRules from '../default';

export default {
  applicationStatuses: sharedDefaultRules.applicationStatuses,

  applicationFields: {
    PersonalDetails: {
      Title: true,
      FirstName: true,
      LastName: true,
      Email: true,
      Telephone: true,
      DateOfBirth: true,
      MaritalStatus: true,
      NoOfDependants: true,
      DrivingLicence: true
    },
    CFCAddressHistory: {
      BuildingName: true,
      BuildingNumber: true,
      Postcode: true,
      PostTown: true,
      Street: true,
      Residency: true,
      TimeAtAddress: true
    },
    CFCEmploymentHistory: {
      OccupationBasis: true,
      OccupationType: true,
      TimeAtEmployment: true
    }
  },
  validationRules: {
    PersonalDetails: {
      Title: 'required',
      FirstName: 'required',
      LastName: 'required',
      Email: 'required, email',
      Telephone: 'required, phone',
      DateOfBirth: 'required, date, youngerThan99, olderThan18',
      MaritalStatus: 'required',
      NoOfDependants: 'required',
      DrivingLicence: 'required'
    },
    AddressHistory: {
      Items: [
        {
          BuildingName: 'required:unless:BuildingNumber',
          BuildingNumber: 'required:unless:BuildingName',
          Postcode: 'required, alphaNumSpace',
          PostTown: 'required',
          Street: 'required',
          Residency: 'required',
          TimeAtAddress: {
            Years: 'required, number:int, maxlength:3',
            Months: 'required, number:int, max:12'
          }
        }
      ],
      totalMonths: 'min:36'
    },
    EmploymentHistory: {
      Items: [
        {
          OccupationBasis: 'required',
          OccupationType: 'required',
          TimeAtEmployment: {
            Years: 'required, number:int, maxlength:3',
            Months: 'required, number:int, max:12'
          }
        }
      ],
      totalMonths: 'min:36'
    }
  }
};
