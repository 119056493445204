import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './stockContainerLayout.scss';

const StockContainerLayout = ({ children, showSidebar }) => {
  const mainStyles = classnames(
    'stockContainerLayout__main',
    showSidebar === true && 'stockContainerLayout__main--shift'
  );

  const sideStyles = classnames(
    'stockContainerLayout__side',
    showSidebar === true && 'stockContainerLayout__side--shift'
  );

  return (
    <div className="stockContainerLayout__outer">
      <div className="stockContainerLayout">
        <div className={mainStyles}>{children[0]}</div>
        <div className={sideStyles}>{children[1]}</div>
      </div>
    </div>
  );
};

StockContainerLayout.propTypes = {
  children: PropTypes.any,
  isLoading: PropTypes.bool,
  showSidebar: PropTypes.bool
};

export default StockContainerLayout;
