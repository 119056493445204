import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Image from '../Common/Image';
import closeButton from '../../styles/icons/close-red-square.svg';
import DealershipDropdownListItem from './DealershipDropdownListItem';
import _ from 'lodash';
import Icon from '../Common/Icon/Icon';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

import './dealershipDropdown.scss';
import { canDealershipUseHomePage } from '~/utils/dealershipUtils';

const DealershipDropdown = ({ onStateChange, isOpen, dealerships, current }) => {
  const { t } = useTranslation('Header');
  const navigate = useNavigate();

  const toggleMenu = () => {
    onStateChange(!isOpen);
  };

  const handleChangeDealership = (dealershipId) => {
    onStateChange(false);
    const dealership = dealerships.find((x) => x.Id === dealershipId);
    if (canDealershipUseHomePage(dealership)) {
      navigate({ pathname: `/d/${dealershipId}/home` });
    } else {
      navigate({ pathname: `/d/${dealershipId}` });
    }
  };

  const sortedDealerships = _.orderBy(dealerships, 'Name');

  return (
    <div className="dealershipDropdown" data-th="DealershipDropdown">
      <button
        className={classnames('dealershipDropdown__button', isOpen && 'dealershipDropdown__button--active')}
        onClick={toggleMenu}
        key="button"
      >
        <div className="dealershipDropdown__buttonIconContainer">
          <Icon name="dealership" />
        </div>
        <div className="dealershipDropdown__buttonText">{t('DealershipDropdown.dealership')}</div>
      </button>

      <div className={classnames('dealershipDropdown__dealerList', isOpen && 'dealershipDropdown__dealerList--open')}>
        <div className="dealershipDropdown__liHead">
          <div className="dealershipDropdown__liHeadText">{t('DealershipDropdown.switch_rooftop')}</div>
          <button className="dealershipDropdown__exitButton" onClick={toggleMenu}>
            <Image imageName={closeButton} />
          </button>
        </div>

        <ul className="dealershipDropdown__list">
          {sortedDealerships.map((dealer, index) => {
            return (
              <span key={`ddli${index}`}>
                <DealershipDropdownListItem
                  dealer={dealer}
                  current={current}
                  handleClick={handleChangeDealership}
                  index={index}
                  isLast={index === sortedDealerships.length - 1}
                  key={'ddli' + index}
                />
              </span>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

DealershipDropdown.propTypes = {
  dealerships: PropTypes.array,
  current: PropTypes.string,
  onStateChange: PropTypes.func,
  isOpen: PropTypes.bool
};

export default DealershipDropdown;
