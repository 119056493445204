import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import PushDealItem from './PushDealItem';
import NoPanelData from '../../../Dashboard/components/NoPanelData';
import DateFormat from '../../../Common/DateFormat';
import './pushDeals.scss';
import Feature, { Content, NotEnabled, NotImplemented } from '../../../Common/Feature';
import NotImplementedMessage from '../../../Common/NotImplemented';

const PushDeals = ({ customerPushDeals, isCustomerRecord, consumerId }) => {
  const [showMore, setShowMore] = React.useState(false);
  const { t } = useTranslation('Customer');

  const handleClick = () => {
    setShowMore((show) => !show);
  };

  if (!customerPushDeals || customerPushDeals.length === 0) {
    return (
      <Feature name="pushdeal">
        <NotImplemented>
          <div className="pushDeals__message">
            <NotImplementedMessage />
          </div>
        </NotImplemented>
        <NotEnabled>
          <div className="pushDeals__message">
            <Trans
              ns="Customer"
              i18nKey="PushDeals.contact_to_enable_digital_deal_builder"
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
                a: <a />
              }}
            />
          </div>
        </NotEnabled>
        <Content>
          <div className="pushDeals__noDeals">
            <NoPanelData text={t('PushDeals.there_are_currently_no_sent_deals_for_this_vehicle')} />
          </div>
        </Content>
      </Feature>
    );
  }

  return (
    <div className="pushDeals__container">
      {!isCustomerRecord && (
        <div className="pushDeals__timeline">
          {customerPushDeals.map((deal, i) => {
            if (customerPushDeals.length > 1) {
              return (
                <span key={i} className="pushDeals__timelineValues">
                  <div>
                    <DateFormat value={deal.addedAt} format="calendar" />
                  </div>
                  <div className="pushDeals__dateLine" />
                </span>
              );
            } else {
              return (
                <span key={i} className="pushDeals__timelineValues">
                  <div>
                    <DateFormat value={deal.addedAt} format="calendar" />
                  </div>
                </span>
              );
            }
          })}
        </div>
      )}
      {!showMore ? (
        <div className="pushDeals__deals">
          <PushDealItem deal={customerPushDeals[0]} consumerId={consumerId} isCustomerRecord={isCustomerRecord} />
        </div>
      ) : (
        <div className="pushDeals__deals">
          {customerPushDeals.length && (
            <>
              {customerPushDeals.map((deal, i) => {
                return <PushDealItem deal={deal} key={i} consumerId={consumerId} isCustomerRecord={isCustomerRecord} />;
              })}
            </>
          )}
        </div>
      )}
      {customerPushDeals.length > 1 && (
        <div className="pushDeals__showMoreButton" onClick={handleClick}>
          {showMore ? t('PushDeals.show_less') : t('PushDeals.show_more')}
        </div>
      )}
    </div>
  );
};

PushDeals.propTypes = {
  customerPushDeals: PropTypes.array,
  consumerId: PropTypes.string
};

export default PushDeals;
