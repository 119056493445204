import PropTypes from 'prop-types';
import DateInput from './DateInput';

const DateField = (props) => {
  const onChange = (_, value) => props.onChange && props.onChange({ currentTarget: { value } });
  return <DateInput {...props} onChange={onChange} />;
};

DateField.propTypes = {
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

export default DateField;
