import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

const FIA = produce(defaultRules, (draft: LenderConsumerConfig) => {
  draft.applicationFields.AffordabilityDetails!.ForeseeFutureDownturnInFinances = false;
  draft.applicationFields.AffordabilityDetails!.DownturnReason = false;
  draft.applicationFields.AffordabilityDetails!.NetMonthly = false;

  draft.applicationStatuses.Pending!.isEditable = false;

  delete draft.validationRules.AffordabilityDetails!.PersonalCircumstances;
  delete draft.validationRules.AffordabilityDetails!.Income!.NetMonthly;
});
export default FIA;
