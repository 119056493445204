import _ from 'lodash';
import platformApiUtils from '@ivendi/platform-api-utils';
import { schemaMap } from '../../core/schema';
import dealershipSchema from '../../core/schemas/dealershipSchema';
import * as actionTypes from '../actionTypes';
import * as productActions from '../product/productActions';
import * as sessionActions from '../session/sessionActions';
import dealershipEventEmitter from '../../utils/dealershipEventEmitter';
import appStore from '../../mobx-stores/appStore';
import { recreateClaimBasedOptions } from '../options/optionsActions';
import { trackDealershipUserSwitchedDealership } from '~/tracking/avoTracking';

export function changeDealership(dealershipId) {
  return (dispatch, getState) => {
    const state = getState();
    const currentDealershipId = state.dealership.Id;

    const currentDealership = _.chain(state)
      .get('session.Dealerships')
      .find({ Id: dealershipId })
      .value();

    if (!currentDealership) {
      dispatch({
        type: actionTypes.SIGN_OUT,
        message: 'You do not have the correct permissions to access this dealership.'
      });
    } else {
      const claims = _.get(state.session, 'Claims');
      const isSysAdmin = _.get(state.session, 'IsSysAdmin');
      const lenders = _.get(currentDealership, 'Lenders');
      const dealershipModules = _.get(currentDealership, 'Modules');
      const userClaims = _.get(currentDealership, 'UserClaims');
      const dealershipId = _.get(currentDealership, 'Id');
      const countryCode = currentDealership?.CountryCode;
      const platformBrand = currentDealership?.PlatformBrand;

      if (currentDealershipId) {
        dispatch(unsubscribeFromDealership(currentDealershipId));
        trackDealershipUserSwitchedDealership({
          dealershipIdFrom: currentDealershipId,
          dealershipIdTo: dealershipId,
          dealershipUserId: state.session.UserId
        });
      }

      appStore.uiState.setFeatures({
        claims,
        isSysAdmin,
        dealershipModules,
        userClaims,
        dealershipId,
        countryCode,
        platformBrand,
        lenders
      });

      appStore.vapStore.fetchValueAddedProducts(dealershipId);

      dispatch(fetchDealershipInfo(dealershipId));
      dispatch(productActions.fetchAll(dealershipId));
      dispatch(subscribeToDealership(dealershipId, state.session.PubNub.AuthId));
      dispatch(recreateClaimBasedOptions());
    }
  };
}

export function fetchDealershipInfo(dealershipId) {
  return {
    types: [
      actionTypes.FETCH_DEALERSHIP_INFO,
      actionTypes.FETCH_DEALERSHIP_INFO_SUCCESS,
      actionTypes.FETCH_DEALERSHIP_INFO_ERROR
    ],
    payload: { dealershipId },
    callAPI: () => platformApiUtils.get('v1/dealerships/' + dealershipId),
    parseResponse: (data) => schemaMap(data, dealershipSchema)
  };
}

export function subscribeToDealership(dealershipId, authKey) {
  return (dispatch, getState) => {
    const state = getState();
    dealershipEventEmitter.subscribe(authKey, dealershipId, state.session.UserId);

    dealershipEventEmitter.on('error', (error) => {
      window.R7Insight &&
        window.R7Insight.log({
          type: 'DealershipEventEmitterError',
          error: error,
          level: 'Error'
        });
    });

    dealershipEventEmitter.on('PubNubError', (error) => {
      if (error === 'PNAccessDeniedCategory') {
        dispatch(sessionActions.signOut());
      }
    });
  };
}

export function unsubscribeFromDealership(dealershipId) {
  return () => {
    dealershipEventEmitter.unsubscribe([dealershipId, 'd.analytics.' + dealershipId]);
  };
}
