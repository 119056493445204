import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  draft.applicationStatuses.NoDecisionYet!.isEditable = false;
  draft.applicationStatuses.Rejected!.isEditable = false;
  draft.applicationStatuses.Submitted!.isEditable = false;
  draft.applicationFields.PersonalDetails!.BornUK = true;
  draft.applicationFields.PersonalDetails!.BirthPlace = true;
  draft.applicationFields.AffordabilityDetails!.ForeseeFutureDownturnInFinances = false;
  draft.applicationFields.AffordabilityDetails!.DownturnReason = false;
  draft.applicationFields.AffordabilityDetails!.IsReplacementLoan = false;
  draft.applicationFields.AffordabilityDetails!.DetailsOfReplacementLoan = false;
  draft.applicationFields.AffordabilityDetails!.MortgageOrRental = true;
  draft.applicationFields.AffordabilityDetails!.Other = true;
  draft.applicationFields.AffordabilityDetails!.ChangesExpected = true;
  draft.applicationFields.AffordabilityDetails!.DetailsOfExpectedChanges = true;

  draft.validationRules.PersonalDetails!.Title = 'required';
  draft.validationRules.PersonalDetails!.FirstName = 'required';
  draft.validationRules.PersonalDetails!.LastName = 'required';
  draft.validationRules.PersonalDetails!.Gender = 'required';
  draft.validationRules.PersonalDetails!.MobileTelephone = 'required, mobile';
  draft.validationRules.PersonalDetails!.HomeTelephone = 'optional, landline';
  draft.validationRules.PersonalDetails!.CountryOfBirth!.BornUK = 'required';
  draft.validationRules.PersonalDetails!.CountryOfBirth!.BirthPlace = 'required:iffalse:BornUK';
  delete draft.validationRules.AffordabilityDetails!.ReplacementLoan;
  delete draft.validationRules.AffordabilityDetails!.PersonalCircumstances!.ForeseeFutureDownturnInFinances;
  delete draft.validationRules.AffordabilityDetails!.PersonalCircumstances!.DownturnReason;
  draft.validationRules.AffordabilityDetails!.PersonalCircumstances!.ChangesExpected = 'required';
  draft.validationRules.AffordabilityDetails!.MonthlyExpenditure!.MortgageOrRental = 'required, currency';
  draft.validationRules.AffordabilityDetails!.MonthlyExpenditure!.Other = 'required, currency';
  draft.validationRules.PersonalDetails!.Nationality = 'required';
});
