import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const HorizontalScrollIndicatorCircle = ({ index, isActive }) => {
  const classNames = classnames(
    'horizontalScrollIndicator__indicator',
    `horizontalScrollIndicator__indicator${index}`,
    isActive && 'horizontalScrollIndicator__indicator--active'
  );

  return <div className={classNames} />;
};

HorizontalScrollIndicatorCircle.propTypes = {
  index: PropTypes.number,
  isActive: PropTypes.bool
};

export default HorizontalScrollIndicatorCircle;
