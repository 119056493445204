import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './generalCreditInformationModal.scss';
import Modal from '../../Common/Modal/Modal';
import GeneralCreditInformationCard from '../components/GeneralCreditInformationCard';
import Chart from '../../Common/PercentageChart';
import Button from '../../Common/Button/Button';
import creditCard from '../../../webfont-icons/credit-score.svg';
import consumerIcon from '../../../webfont-icons/consumer.svg';

const GeneralCreditInformationModal = (props) => {
  const { t } = useTranslation('Customer');
  const personalScore = props.personalScore / 100;
  const creditScore = props.creditScore / 100;
  return (
    <Modal isOpen={props.isOpen} onClose={props.close}>
      <div className="generalCreditInformationModal">
        <div className="generalCreditInformationModal__title">
          {t('GeneralCreditInformationModal.eligibility_summary')}
        </div>
        <div className="generalCreditInformationModal__subTitle">
          {t('GeneralCreditInformationModal.general_credit_info_subtitle')}
        </div>
        <div className="generalCreditInformationModal__chartContainer" key="chartcont">
          <div className="generalCreditInformationModal__card generalCreditInformationModal__left" key="card1">
            <GeneralCreditInformationCard
              title={t('GeneralCreditInformationModal.personal_and_vehicle_score')}
              subText={t('GeneralCreditInformationModal.general_credit_info_personal_vehicle_score_subtext')}
            >
              <div className="generalCreditInformationModal__chart" key="chart1">
                <Chart color="#53A4DD" percent={personalScore} icon={consumerIcon} />
              </div>
            </GeneralCreditInformationCard>
          </div>
          <div className="generalCreditInformationModal__card generalCreditInformationModal__right" key="card2">
            <GeneralCreditInformationCard
              title="Credit Score"
              subText={t('GeneralCreditInformationModal.general_credit_info_credit_score_subtext')}
            >
              <div className="generalCreditInformationModal__chart" key="chart2">
                <Chart color="#427CB2" percent={creditScore} icon={creditCard} />
              </div>
            </GeneralCreditInformationCard>
          </div>
        </div>
        <div className="generalCreditInformationModal__bottom">
          <div className="generalCreditInformationModal__button" key="button">
            <Button onClick={props.close} buttonStyle="cancel">
              {t('GeneralCreditInformationModal.close')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

GeneralCreditInformationModal.propTypes = {
  personalScore: PropTypes.number,
  creditScore: PropTypes.number,
  close: PropTypes.func,
  isOpen: PropTypes.bool
};
export default GeneralCreditInformationModal;
