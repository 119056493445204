import React from 'react';
import PropTypes from 'prop-types';
import './image.scss';

const ImageAtom = ({ imageName }) => {
  return <img className="image" src={imageName} />;
};

ImageAtom.propTypes = {
  imageName: PropTypes.string
};

export default ImageAtom;
