import i18n from '../i18n';

/**
 * @param {string} type
 * @param {number} count
 * @returns {{color:string,icon:string,text:string}}
 */

function getTileStyles(type, count) {
  let color;
  let icon;
  let text;
  let backgroundColor;

  switch (type) {
    case 'rejected':
      color = '#EA8D7C';
      backgroundColor = '#FCE7E8';
      icon = 'alert';
      text = i18n.t('Dashboard:GetTileStyles.rejected_application', { count });
      break;

    case 'accepted':
      color = '#6DB951';
      backgroundColor = '#EBF6E8';
      icon = 'notification';
      text = i18n.t('Dashboard:GetTileStyles.accepted_application', { count });
      break;

    case 'myassigned':
      color = '#84BD00';
      backgroundColor = '#EBF6E8';
      icon = 'consumer';
      text = i18n.t('Dashboard:GetTileStyles.lead_assigned_to_me', { count });
      break;

    case 'assigned':
      color = '#84BD00';
      backgroundColor = '#EBF6E8';
      icon = 'consumer';
      text = i18n.t('Dashboard:GetTileStyles.assigned_lead', { count });
      break;

    case 'unassigned':
      color = '#006ED8';
      backgroundColor = '#DFEAF9';
      icon = 'consumer';
      text = i18n.t('Dashboard:GetTileStyles.unassigned_lead', { count });
      break;

    case 'customers':
      color = '#006ED8';
      backgroundColor = '#DFEAF9';
      icon = 'add-customer';
      text = i18n.t('Dashboard:GetTileStyles.total_customers_created');
      break;

    case 'applications':
      color = '#006ED8';
      backgroundColor = '#DFEAF9';
      icon = 'application';
      text = i18n.t('Dashboard:GetTileStyles.total_applications_sent');
      break;

    case 'reservations':
      color = '#006ED8'; //'#6D4CD7';
      backgroundColor = '#E9DFFA';
      icon = 'payment_icon';
      text = i18n.t('Dashboard:GetTileStyles.active_reservation', { count });
      break;

    case 'overdue':
      color = '#EA8D7C';
      backgroundColor = '#FCE7E8';
      icon = 'payment_icon';
      text = i18n.t('Dashboard:GetTileStyles.expired_reservation', { count });
      break;

    case 'deals':
      color = '#006ED8';
      backgroundColor = '#DFEAF9';
      icon = 'deals_icon';
      text = i18n.t('Dashboard:GetTileStyles.total_deals_sent');
      break;

    default:
      color = '#006ED8';
      backgroundColor = '#DFEAF9';
      icon = 'consumer';
      text = '';
      break;
  }
  return {
    color,
    icon,
    text,
    backgroundColor
  };
}

export default getTileStyles;
