/**
 * A hook to provide form validation
 * @param {Rules} rules - validation rules
 * @returns {object} - {validate, errors, hasErrors, isValid}
 */
import objectValidation from '../core/objectValidation';
import { useState, useCallback } from 'react';
import { useDeepCompareMemoize } from 'use-deep-compare-effect';
type ValidationError = {
  error: string;
  message: string;
};
type Rules = { [K: string]: Rule | Rule[] };
type Rule = string | Rules;
type Data = { [K: string]: DataItem | DataItem[] };
type DataItem = string | number | boolean | undefined | null | File | Data;
type Errors<T> = {
  [K in keyof T]?: T[K] extends Rules | Rules[] ? Errors<T[K]> : ValidationError;
};
const checkHasErrors = <R extends Rules>(errors: Errors<R> | null) => (errors ? Object.keys(errors).length > 0 : 0);
const useValidator = <R extends Rules>(rules: R) => {
  const [errors, setErrors] = useState<Errors<R>>({});
  const memoizedRules = useDeepCompareMemoize(rules);
  const validate = useCallback(
    (data: Data | unknown) => {
      const errors = objectValidation(data, memoizedRules) as Errors<R> | null;
      setErrors(errors || {});
      return checkHasErrors(errors);
    },
    [memoizedRules]
  );
  const hasErrors = checkHasErrors(errors);
  return {
    validate,
    errors,
    hasErrors,
    isValid: !hasErrors
  };
};
export default useValidator;
