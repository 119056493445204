import PropTypes from 'prop-types';
import './pushDealActivityContainerSection.scss';

const PushDealActivityContainerSection = ({ title, children }) => {
  return (
    <div className="pushDealActivityContainerSection">
      <div className="pushDealActivityContainerSection__title">{title}</div>
      <div className="pushDealActivityContainerSection__value">{children}</div>
    </div>
  );
};

PushDealActivityContainerSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any
};

export default PushDealActivityContainerSection;
