import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './editApplicationAttentionPanel.scss';
import Icon from '../../Common/Icon/Icon';

const EditApplicationAttentionPanel = ({ funderName }) => {
  const { t } = useTranslation('Application');
  return (
    <div className="editApplicationAttentionPanel" key="attention">
      <div className="editApplicationAttentionPanel__header">
        <div className="editApplicationAttentionPanel__headerIcon">
          <Icon name="alert" />
        </div>
        <div>
          {t('EditApplicationAttentionPanel.you_have_chosen_to_edit_an_application_previously_sent_to_funder', {
            funderName
          })}
        </div>
      </div>
      <ul className="editApplicationAttentionPanel__ul">
        <li className="editApplicationAttentionPanel__li">
          {t('EditApplicationAttentionPanel.updates_are_allowed_in_the_sections_highlighted_below')}
        </li>
        <li className="editApplicationAttentionPanel__li">
          {t(
            'EditApplicationAttentionPanel.if_you_wish_to_make_changes_elsewhere_in_the_application_this_will_result_in_the_need_to_recreate_the_application'
          )}
        </li>
      </ul>
    </div>
  );
};

EditApplicationAttentionPanel.propTypes = {
  funderName: PropTypes.string
};
export default EditApplicationAttentionPanel;
