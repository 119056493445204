import { withTranslation } from 'react-i18next';
import React from 'react';
import Select from '../../Common/Form/Select';
import CurrencyInput from '../../Common/Form/CurrencyInput';
import PropTypes from 'prop-types';
import './addVapModal.scss';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import MobxForm from '../../MobxForm/MobxForm';
import Modal from '../../Common/Modal/Modal';
import { inject, observer } from 'mobx-react';
import Button from '../../Common/Button/Button';
import Validator from '../../../validators/Validator';
import InformationWarning from '../../Common/InformationWarning';
import _ from 'lodash';
import { isAllowedDuplicates } from '../../../core/vaps';

class AddVapModal extends React.Component {
  validator;

  constructor() {
    super();
    this.state = {
      productTypeCode: '',
      productId: '',
      price: ''
    };
    this.validator = new Validator();
    this.validator.setRules({
      productTypeCode: 'required',
      productId: 'required',
      price: 'required, currency'
    });
    this.validator.validate(this.state);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      this.setState({
        productTypeCode: '',
        productId: '',
        price: ''
      });
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (!_.isEqual(this.state, nextState)) {
      this.validator.validate(nextState);
    }
  }

  handleTypeChange = (e) => {
    this.setState({
      productTypeCode: e.target.value,
      productId: '',
      price: ''
    });
  };
  handleProductChange = (e) =>
    this.setState({
      productId: e.target.value,
      price: parseFloat(this.props.appStore.vapStore.getById(e.target.value).Price).toFixed(2)
    });
  handlePriceChange = (price) =>
    this.setState({
      price: parseFloat(price).toFixed(2)
    });
  handleSubmit = (e) => {
    e.stopPropagation();

    if (!this.validator.errorCount) {
      let shouldRemoveExistingDuplicate = !isAllowedDuplicates(this.state.productTypeCode) && this.hasDuplicateVap();
      this.props.onSubmit(
        this.state.productId,
        this.state.price,
        parseInt(this.state.productTypeCode, 10),
        shouldRemoveExistingDuplicate
      );
    }
  };
  hasDuplicateVap = () => {
    return _.find(this.props.currentVaps, (vap) => vap.ProductTypeCode === parseInt(this.state.productTypeCode, 10))
      ? true
      : false;
  };

  render() {
    const {
      appStore: { vapStore }
    } = this.props;
    const errors = this.validator.getErrors();
    let showDuplicateMessage =
      !isAllowedDuplicates(this.state.productTypeCode) &&
      this.state.productId &&
      this.props.isOpen &&
      this.hasDuplicateVap();

    let selectedProductType =
      showDuplicateMessage &&
      _.find(vapStore.visibleProductTypeOptions, (vap) => vap.key === parseInt(this.state.productTypeCode, 10)).value;

    return (
      <Modal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title={this.props.t('AddVapModal.add_value_added_product_vap')}
      >
        <MobxForm onSubmit={this.handleSubmit} className="addVapModal">
          <MobxFieldGroup isInline className="addVapModal__vapType" error={errors && errors.productTypeCode}>
            <MobxFormLabel>{this.props.t('AddVapModal.type')}</MobxFormLabel>
            <Select
              emptyValue={true}
              value={this.state.productTypeCode}
              onChange={this.handleTypeChange}
              options={vapStore.getVisibleProductTypeOptionsClassFiltered(this.props.vehicleClass)}
              id="AddVapType"
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline className="addVapModal__vapName" error={errors && errors.productId}>
            <MobxFormLabel>{this.props.t('AddVapModal.name')}</MobxFormLabel>
            <Select
              emptyValue={true}
              value={this.state.productId}
              onChange={this.handleProductChange}
              options={vapStore.getVisibleProductOptionsForType(this.state.productTypeCode)}
              disabled={!this.state.productTypeCode}
              id="AddVapName"
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline className="addVapModal__vapPrice" error={errors && errors.price}>
            <MobxFormLabel>{this.props.t('AddVapModal.price')}</MobxFormLabel>
            <CurrencyInput
              disabled={!this.state.productId}
              value={this.state.price}
              onChange={this.handlePriceChange}
              id="AddVapPrice"
              data-th="addVapPrice"
            />
          </MobxFieldGroup>
          {showDuplicateMessage && (
            <div className="addVapModal__duplicateWarning">
              <InformationWarning
                text={this.props.t(`AddVapModal.already_added_product_type`, {
                  selectedProductType
                })}
              />
            </div>
          )}
          <div className="addVapModal__footer">
            <div className="addVapModal__footerLeft">
              <Button type="button" buttonStyle="cancel" onClick={this.props.onClose}>
                {this.props.t('AddVapModal.cancel')}
              </Button>
            </div>
            <div className="addVapModal__footerRight">
              <Button type="submit">{this.props.t('AddVapModal.add_vap')}</Button>
            </div>
          </div>
        </MobxForm>
      </Modal>
    );
  }
}

AddVapModal.propTypes = {
  vap: PropTypes.object,
  onTypeChange: PropTypes.func,
  onProductChange: PropTypes.func,
  onPriceChange: PropTypes.func,
  typeOptions: PropTypes.array,
  productOptions: PropTypes.array,
  isOpen: PropTypes.bool,
  currentVaps: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  appStore: PropTypes.object,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  vehicleClass: PropTypes.string
};
export default withTranslation('Quoting')(inject('appStore')(observer(AddVapModal)));
