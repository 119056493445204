import React from 'react';
import PropTypes from 'prop-types';
import './helpVideoModal.scss';
import HelpVideo from './HelpVideo';
import _ from 'lodash';
import HelpVideoImageLink from './HelpVideoImageLink';
import Button from '../../Common/Button/Button';
import HelpVideoMenu from './HelpVideoMenu';
import { getVideoForCurrentPage, getVideos, getGroups } from '../../../core/helpVideos/helpVideos';
import { observer, inject } from 'mobx-react';

class HelpVideoModal extends React.Component {
  UNSAFE_componentWillMount() {
    this.setState({
      currentVideo: getVideoForCurrentPage(this.props.currentPage, this.props.currentQuery)
    });
  }

  handleVideoChange = (id) => {
    this.setState({
      currentVideo: id
    });
  };

  onButtonClick = () => {
    this.setState({
      currentVideo: null
    });
  };

  render() {
    const {
      canUseShowroomCfc,
      canSelfServeQuotes,
      canRepropose,
      hasOrders,
      hasStockEnabled,
      canViewReservations
    } = this.props.appStore.uiState;
    const videos = getVideos(
      canRepropose,
      canUseShowroomCfc,
      canSelfServeQuotes,
      hasOrders,
      hasStockEnabled,
      canViewReservations
    );
    const groups = getGroups(
      canRepropose,
      canUseShowroomCfc,
      canSelfServeQuotes,
      hasOrders,
      hasStockEnabled,
      canViewReservations
    );
    const pageVideo = _.find(videos, { id: this.state.currentVideo });

    return (
      <div data-th="HelpVideoModal">
        {this.state.currentVideo !== null ? (
          <div>
            <div className="helpVideoModal__topVideo">
              <div className="helpVideoModal__header">{pageVideo.title}</div>
              <HelpVideo source={pageVideo} />
            </div>
            <div className="helpVideoModal__bottomSection">
              <div className="helpVideoModal__headerSection">
                {pageVideo.relatedVideos.length > 0 ? (
                  <div className="helpVideoModal__subHeader">These are the relevant videos to this area</div>
                ) : (
                  ''
                )}
              </div>
              <div key="smallVideosSection">
                {pageVideo.relatedVideos.length > 0 ? (
                  <div className="helpVideoModal__videoList">
                    {_.map(pageVideo.relatedVideos, (video, index) => {
                      const eachVideo = _.find(videos, { id: video });
                      return (
                        <HelpVideoImageLink
                          index={index}
                          key={'HelpVideoImageLink' + index}
                          video={eachVideo}
                          onClick={this.handleVideoChange}
                        />
                      );
                    })}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="helpVideoModal__buttonContainer">
              <div className="helpVideoModal__buttonOuter">
                <Button buttonStyle="primary" onClick={this.onButtonClick}>
                  See Complete List
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <HelpVideoMenu onClick={this.handleVideoChange} videos={videos} groups={groups} />
          </div>
        )}
      </div>
    );
  }
}

HelpVideoModal.propTypes = {
  currentPage: PropTypes.string,
  currentQuery: PropTypes.object,
  themeName: PropTypes.string,
  appStore: PropTypes.object
};

export default inject('appStore')(observer(HelpVideoModal));
