import { observable } from 'mobx';
import Vehicle from './Vehicle';
import _ from 'lodash';

class Customer {
  @observable Firstname;
  @observable Surname;
  @observable Title;
  @observable Id;
  @observable CustomerType;
  @observable Vehicles = [];
  @observable Email;
  @observable TradingName;
  @observable FinanceApplications = [];
  @observable CorporateApplications = [];
  @observable FinanceChecks = [];
  @observable SavedQuotes = [];
  @observable SelfServiceCodes = [];
  @observable Aliases = [];
  @observable AssignedTo = [];

  updateFromJSON(json = {}) {
    this.CustomerType = json.CustomerType;
    this.Firstname = json.Firstname;
    this.Surname = json.Surname;
    this.Title = json.Title;
    this.Id = json.Id;
    this.CustomerType = json.CustomerType;
    this.Email = json.Email;
    this.CorporateApplications = json.CorporateApplicants;
    this.FinanceChecks = json.CfcProspectResultSets;
    this.SavedQuotes = json.SavedQuotes;
    this.SelfServiceCodes = json.SelfServiceCodes;
    this.TradingName = json.TradingName;
    this.Aliases = json.Aliases;
    this.AssignedTo = json.AssignedTo;
    this.Vehicles = json.VehiclesOfInterest
      ? json.VehiclesOfInterest.map((item) => {
          const vehicle = new Vehicle(item);
          vehicle.updateFromJSON(item);
          return vehicle;
        })
      : [];
    this.FinanceApplications =
      json.ConsumerApplicants &&
      json.ConsumerApplicants.map((app) => {
        let { Tags, ...restOfApp } = app;
        return {
          Tags: Tags && JSON.parse(Tags),
          ...restOfApp
        };
      });
  }

  getVehicle(vehicleId) {
    return _.find(this.Vehicles, { Id: vehicleId });
  }
}

export default Customer;
