import PropTypes from 'prop-types';
import './dealerInfo.scss';

const DealerInfo = (props) => {
  return (
    <div className="dealerInfo">
      <div className="dealerInfo__name">{props.dealership.TradingName || props.dealership.CompanyName}</div>
      <div className="dealerInfo__address">
        {[props.dealership.Address1, props.dealership.Address2, props.dealership.PostalTown, props.dealership.Postcode]
          .filter(Boolean)
          .join(', ')}
      </div>
    </div>
  );
};

DealerInfo.propTypes = {
  dealership: PropTypes.object
};

export default DealerInfo;
