import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ConsumerVehicleInfo from '../Customer/components/ConsumerVehicleInfo';
import ConsumerName from './ConsumerName';
import './formConsumerVehicleDetails.scss';

const FormConsumerVehicleDetails = ({ consumer, vehicle }) => {
  const { t } = useTranslation('Common');

  const { Title, Firstname, Surname } = consumer;
  const title = Title === 'Mr' || Title === 'Mrs' ? t(`Options.${Title.toLowerCase()}`) : '';

  return (
    <div className="formConsumerVehicleDetails">
      <div className="formConsumerVehicleDetails__consumerName">
        <ConsumerName name={[title, Firstname, Surname].join(' ')} />
      </div>
      <ConsumerVehicleInfo vehicle={vehicle} />
    </div>
  );
};

FormConsumerVehicleDetails.propTypes = {
  consumer: PropTypes.object,
  vehicle: PropTypes.object
};

export default FormConsumerVehicleDetails;
