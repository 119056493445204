import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './iconListItem.scss';
import Icon from './Icon/Icon';

const IconListItem = ({ icon, iconStyle, children }) => {
  const classes = classNames('iconListItem__icon', iconStyle && `iconListItem--${iconStyle}`);

  if (!icon) {
    return <div />;
  }

  return (
    <div className="iconListItem">
      <div className={classes}>
        <Icon name={icon} />
      </div>
      <div>{children}</div>
    </div>
  );
};

IconListItem.propTypes = {
  icon: PropTypes.string,
  iconStyle: PropTypes.string,
  children: PropTypes.node
};

export default IconListItem;
