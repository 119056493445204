import { withTranslation } from 'react-i18next';
import React, { Component, lazy } from 'react';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import ivendi from '../../../styles/icons/ivendi_logo.svg';
import Select from '../../Common/Form/Select';
import help from '../../../styles/icons/help-icon.svg';
import LoadingDots from '../../Common/Loading/LoadingDots';
import './digitalReport.scss';
import MarketingPage from '../components/MarketingPage';
import FinanceCalculatorStatistics from '../components/FinanceCalculatorStatistics';
import DailyAverageStats from '../components/DailyAverageStats';
import DealerDetails from '../components/DealerDetails';
import PlatformUsage from '../components/PlatformUsage';
import DailyStatistics from '../components/DailyStatistics';
import HeatMap from '../components/HeatMap';
import PopularProducts from '../components/PopularProducts';
import Modal from '../../Common/Modal/Modal';
import ApplicationsAndChecks from '../components/ApplicationsAndChecks';
import TrackPageView from '../../Common/Tracking/TrackPageView';
import { compose } from 'redux';
import { withLocation, withOutletContextProps, withParams, withQuery } from 'hocs/router';
const MonthlyTrafficView = lazy(() => import('../components/MonthlyTrafficView'));

class OnlineReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      modalText: [],
      modalTitle: ''
    };
  }

  UNSAFE_componentWillMount() {
    this.props.appStore.reportsStore.initReports(
      this.props.params.dealershipId,
      this.props.query.filterMonth,
      'DealershipMonthlyReports',
      'monthly/dealership/'
    );
  }

  onSelectMonth = (event) => {
    event.preventDefault();
    this.props.appStore.reportsStore.selectMonth(
      this.props.params.dealershipId,
      event.target.value,
      'monthly/dealership/'
    );
  };
  handleFaqModalOpen = (modalTitle, modalText) => {
    this.setState({
      isModalOpen: true,
      modalText: modalText,
      modalTitle: modalTitle
    });
  };
  closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };

  render() {
    const data = this.props.appStore.reportsStore.report;
    const dealership = this.props.dealership;
    const filterMonth = this.props.appStore.reportsStore.filterMonth;
    const allMonths = toJS(this.props.appStore.reportsStore.options);
    const isLoading = this.props.appStore.reportsStore.isLoadingReports;

    if (isLoading) {
      return <LoadingDots />;
    }

    if (this.props.appStore.reportsStore.reportError) {
      return <MarketingPage dealership={this.props.dealership} />;
    }

    return (
      <Page>
        <TrackPageView dealershipId={this.props.params.dealershipId} />
        <Breadcrumbs
          items={[
            {
              name: this.props.t('OnlineReport.home'),
              path: `/d/${this.props.params.dealershipId}`,
              isHome: true
            },
            {
              name: this.props.t('OnlineReport.reporting'),
              path: `/d/${this.props.params.dealershipId}/reporting`
            },
            {
              name: this.props.t('OnlineReport.online_retailing_report')
            }
          ]}
        />
        <div className="digitalReport__header">
          <div className="digitalReport__headerText">{this.props.t('OnlineReport.online_retailing_report')}</div>
          <div className="digitalReport__headerIcon--outer">
            <img className="digitalReport__headerIcon" src={ivendi} alt="headerIcon" />
          </div>
        </div>
        <div className="digitalReport__selectOuter">
          <div className="digitalReport__selectInner">
            <span className="digitalReport__selectLabel">{this.props.t('OnlineReport.select_month')}</span>
            <div className="digitalReport__select">
              <Select
                theme="alt"
                emptyValue={false}
                value={filterMonth}
                options={allMonths}
                onChange={this.onSelectMonth}
              />
            </div>
          </div>
        </div>
        <div>
          <MediaQuery minWidth="1100px">
            <div className="digitalReport__layoutOuter">
              <div className="digitalReport__layoutLeft">
                <Panel>
                  <PanelHeader>{this.props.t('OnlineReport.my_dealership')}</PanelHeader>
                  <DealerDetails dealership={dealership} />
                </Panel>
                <Panel>
                  <PanelHeader>
                    {this.props.t('OnlineReport.daily_average_online_statistics')}
                    <img
                      alt={this.props.t('OnlineReport.help')}
                      src={help}
                      className="digitalReport__faq"
                      onClick={() =>
                        this.handleFaqModalOpen(
                          this.props.t('OnlineReport.daily_average_online_statistics'),
                          this.props.t('FaqText.daily_statistics', { returnObjects: true })
                        )
                      }
                    />
                  </PanelHeader>
                  <DailyAverageStats
                    averageStock={data.AverageStock}
                    impressionsPerVehicle={data.AverageImpressionsPerVehicle}
                    quotesPerVehicle={data.AverageQuotesPerVehicle}
                  />
                </Panel>
                <Panel>
                  <PanelHeader>
                    {this.props.t('OnlineReport.i_vendi_platform_usage')}
                    <img
                      alt={this.props.t('OnlineReport.help')}
                      src={help}
                      className="digitalReport__faq"
                      onClick={() =>
                        this.handleFaqModalOpen(
                          this.props.t('OnlineReport.i_vendi_platform_usage'),
                          this.props.t('FaqText.platform_usage', { returnObjects: true })
                        )
                      }
                    />
                  </PanelHeader>
                  <PlatformUsage data={data} />
                </Panel>
                {/*<Panel>
                   <PanelHeader>
                       Other iVendi Sites We've Seen Your Audience
                       <img alt={this.props.t('OnlineReport.help')} src={help} className="digitalReport__faq" onClick={()=> this.handleFaqModalOpen('Other iVendi Sites We\'ve Seen Your Audience', FaqText['otherIvendiSites'])} />
                   </PanelHeader>
                   <TopFiveSitesVisited competitors={data.Competitors} />
                </Panel>*/}
                <Panel>
                  <PanelHeader>
                    {this.props.t('OnlineReport.what_is_your_busiest_time')}
                    <img
                      alt={this.props.t('OnlineReport.help')}
                      src={help}
                      className="digitalReport__faq"
                      onClick={() =>
                        this.handleFaqModalOpen(
                          this.props.t('OnlineReport.what_is_your_busiest_time'),
                          this.props.t('FaqText.your_busiest_time', { returnObjects: true })
                        )
                      }
                    />
                  </PanelHeader>
                  <DailyStatistics busiestWindow={data.BusiestWindow} />
                </Panel>
                <Panel>
                  <PanelHeader>
                    {this.props.t('OnlineReport.what_are_your_most_popular_finance_products')}
                    <img
                      alt={this.props.t('OnlineReport.help')}
                      src={help}
                      className="digitalReport__faq"
                      onClick={() =>
                        this.handleFaqModalOpen(
                          this.props.t('OnlineReport.what_are_your_most_popular_finance_products'),
                          this.props.t('FaqText.most_popular_finance_products', {
                            returnObjects: true
                          })
                        )
                      }
                    />
                  </PanelHeader>
                  <PopularProducts data={data.MostPopularProduct} />
                </Panel>
              </div>
              <div className="digitalReport__layoutRight">
                <Panel>
                  <PanelHeader>
                    {this.props.t('OnlineReport.monthly_web_solutions_statistics')}
                    <img
                      alt={this.props.t('OnlineReport.help')}
                      src={help}
                      className="digitalReport__faq"
                      onClick={() =>
                        this.handleFaqModalOpen(
                          this.props.t('OnlineReport.monthly_web_solutions_statistics'),
                          this.props.t('FaqText.monthly_web_solutions_statistics', {
                            returnObjects: true
                          })
                        )
                      }
                    />
                  </PanelHeader>
                  <FinanceCalculatorStatistics data={data} />
                </Panel>
                <Panel>
                  <PanelHeader>
                    {this.props.t('OnlineReport.online_leads')}
                    <img
                      alt={this.props.t('OnlineReport.help')}
                      src={help}
                      className="digitalReport__faq"
                      onClick={() =>
                        this.handleFaqModalOpen(
                          this.props.t('OnlineReport.online_leads'),
                          this.props.t('FaqText.apps_and_checks', { returnObjects: true })
                        )
                      }
                    />
                  </PanelHeader>
                  <ApplicationsAndChecks data={data} />
                </Panel>
                <Panel>
                  <PanelHeader>
                    {this.props.t('OnlineReport.what_are_your_busiest_days')}
                    <img
                      alt={this.props.t('OnlineReport.help')}
                      src={help}
                      className="digitalReport__faq"
                      onClick={() =>
                        this.handleFaqModalOpen(
                          this.props.t('OnlineReport.what_are_your_busiest_days'),
                          this.props.t('FaqText.monthly_traffic_view', {
                            returnObjects: true
                          })
                        )
                      }
                    />
                  </PanelHeader>
                  <MonthlyTrafficView trafficViews={data.TrafficViews} />
                </Panel>
                <Panel>
                  <PanelHeader>
                    {this.props.t('OnlineReport.where_is_your_audience')}
                    <img
                      alt={this.props.t('OnlineReport.help')}
                      src={help}
                      className="digitalReport__faq"
                      onClick={() =>
                        this.handleFaqModalOpen(
                          this.props.t('OnlineReport.where_is_your_audience'),
                          this.props.t('FaqText.where_is_your_audience', {
                            returnObjects: true
                          })
                        )
                      }
                    />
                  </PanelHeader>
                  <HeatMap
                    dealership={data.Dealership}
                    userDistribution={data.UserDistribution}
                    averageDistance={data.DistancePercentile50}
                  />
                </Panel>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth="1100px">
            <div className="digitalReport__layoutOuter">
              <Panel>
                <PanelHeader>{this.props.t('OnlineReport.my_dealership')}</PanelHeader>
                <DealerDetails dealership={dealership} />
              </Panel>
              <Panel>
                <PanelHeader>
                  {this.props.t('OnlineReport.daily_average_online_statistics')}
                  <img
                    alt={this.props.t('OnlineReport.help')}
                    src={help}
                    className="digitalReport__faq"
                    onClick={() =>
                      this.handleFaqModalOpen(
                        this.props.t('OnlineReport.daily_average_web_solutions_statistics'),
                        this.props.t('FaqText.daily_statistics', { returnObjects: true })
                      )
                    }
                  />
                </PanelHeader>
                <DailyAverageStats
                  averageStock={data.AverageStock}
                  impressionsPerVehicle={data.AverageImpressionsPerVehicle}
                  quotesPerVehicle={data.AverageQuotesPerVehicle}
                />
              </Panel>
              <Panel>
                <PanelHeader>
                  {this.props.t('OnlineReport.monthly_web_solutions_statistics')}
                  <img
                    alt={this.props.t('OnlineReport.help')}
                    src={help}
                    className="digitalReport__faq"
                    onClick={() =>
                      this.handleFaqModalOpen(
                        this.props.t('OnlineReport.monthly_web_solutions_statistics'),
                        this.props.t('FaqText.monthly_web_solutions_statistics', {
                          returnObjects: true
                        })
                      )
                    }
                  />
                </PanelHeader>
                <FinanceCalculatorStatistics data={data} />
              </Panel>
              <Panel>
                <PanelHeader>
                  {this.props.t('OnlineReport.online_leads')}
                  <img
                    alt={this.props.t('OnlineReport.help')}
                    src={help}
                    className="digitalReport__faq"
                    onClick={() =>
                      this.handleFaqModalOpen(
                        this.props.t('OnlineReport.online_leads'),
                        this.props.t('FaqText.apps_and_checks', { returnObjects: true })
                      )
                    }
                  />
                </PanelHeader>
                <ApplicationsAndChecks data={data} />
              </Panel>
              <Panel>
                <PanelHeader>
                  {this.props.t('OnlineReport.what_are_your_busiest_days')}
                  <img
                    alt={this.props.t('OnlineReport.help')}
                    src={help}
                    className="digitalReport__faq"
                    onClick={() =>
                      this.handleFaqModalOpen(
                        this.props.t('OnlineReport.what_are_your_busiest_days'),
                        this.props.t('FaqText.monthly_traffic_view', { returnObjects: true })
                      )
                    }
                  />
                </PanelHeader>
                <MonthlyTrafficView trafficViews={data.TrafficViews} />
              </Panel>
              <Panel>
                <PanelHeader>
                  {this.props.t('OnlineReport.i_vendi_platform_usage')}
                  <img
                    alt={this.props.t('OnlineReport.help')}
                    src={help}
                    className="digitalReport__faq"
                    onClick={() =>
                      this.handleFaqModalOpen(
                        this.props.t('OnlineReport.i_vendi_platform_usage'),
                        this.props.t('FaqText.platform_usage', { returnObjects: true })
                      )
                    }
                  />
                </PanelHeader>
                <PlatformUsage data={data} />
              </Panel>
              <Panel>
                <PanelHeader>
                  {this.props.t('OnlineReport.what_is_your_busiest_time')}
                  <img
                    alt={this.props.t('OnlineReport.help')}
                    src={help}
                    className="digitalReport__faq"
                    onClick={() =>
                      this.handleFaqModalOpen(
                        this.props.t('OnlineReport.what_is_your_busiest_time'),
                        this.props.t('FaqText.your_busiest_time', { returnObjects: true })
                      )
                    }
                  />
                </PanelHeader>
                <DailyStatistics busiestWindow={data.BusiestWindow} />
              </Panel>
              <Panel>
                <PanelHeader>
                  {this.props.t('OnlineReport.what_are_your_most_popular_finance_products')}
                  <img
                    alt={this.props.t('OnlineReport.help')}
                    src={help}
                    className="digitalReport__faq"
                    onClick={() =>
                      this.handleFaqModalOpen(
                        this.props.t('OnlineReport.what_are_your_most_popular_finance_products'),
                        this.props.t('FaqText.most_popular_finance_products', {
                          returnObjects: true
                        })
                      )
                    }
                  />
                </PanelHeader>
                <PopularProducts data={data.MostPopularProduct} />
              </Panel>
              <Panel>
                <PanelHeader>
                  {this.props.t('OnlineReport.where_is_your_audience')}
                  <img
                    alt={this.props.t('OnlineReport.help')}
                    src={help}
                    className="digitalReport__faq"
                    onClick={() =>
                      this.handleFaqModalOpen(
                        this.props.t('OnlineReport.where_is_your_audience'),
                        this.props.t('FaqText.where_is_your_audience', { returnObjects: true })
                      )
                    }
                  />
                </PanelHeader>
                <HeatMap
                  dealership={data.Dealership}
                  userDistribution={data.UserDistribution}
                  averageDistance={data.DistancePercentile50}
                />
              </Panel>
            </div>
          </MediaQuery>
        </div>
        <Modal isOpen={this.state.isModalOpen} onClose={this.closeModal} title={this.state.modalTitle}>
          <div className="digitalReport__faqText">
            {this.state.modalText.map((text, i) => (
              <p key={i}>{text}</p>
            ))}
          </div>
        </Modal>
      </Page>
    );
  }
}

OnlineReport.propTypes = {
  appStore: PropTypes.object,
  dealership: PropTypes.object,
  params: PropTypes.object,
  location: PropTypes.object
};
export default compose(
  withOutletContextProps,
  withParams,
  withLocation,
  withQuery,
  withTranslation('DigitalReport'),
  inject(['appStore']),
  observer
)(OnlineReport);
