import Request from '../mobx-models/Request';
import * as api from '../api/users';
import { observable, action } from 'mobx';
import User from '../mobx-models/User';

class UserStore {
  fetchUserRequest;
  saveUserDetailsRequest;
  removeUserRequest;
  fetchAllUsersRequest;
  addUserRequest;
  @observable user;
  @observable users = [];
  @observable manageUsersList = [];

  constructor() {
    this.fetchUserRequest = new Request();

    this.saveUserDetailsRequest = new Request();

    this.removeUserRequest = new Request();

    this.fetchAllUsersRequest = new Request();

    this.addUserRequest = new Request();
  }

  updateUserFromJSON = (json) => {
    const user = this.users.find((user) => user.id === (json.Id || json.UserId));
    if (!user) {
      const newUser = new User(this).updateFromJSON(json);
      this.users.push(newUser);
      return newUser;
    } else {
      user.updateFromJSON(json);
      return user;
    }
  };

  @action
  fetchUser = (dealershipId, userId) => {
    this.user = null;
    this.fetchUserRequest.onSuccess(this.onFetchUserSuccess).call(api.fetchUser, dealershipId, userId);
  };

  @action
  onFetchUserSuccess = (user) => {
    this.user = new User(this).updateFromJSON(user);
  };

  saveUserDetails = (dealershipId, userId, userData) => {
    this.saveUserDetailsRequest
      .onSuccess(this.onSaveUserDetailsSuccess)
      .call(api.saveUserDetails, dealershipId, userId, userData);
  };

  @action
  onSaveUserDetailsSuccess = (user) => {
    this.updateUserFromJSON(user);
  };

  fetchAllUsers = (dealershipId) => {
    this.fetchAllUsersRequest.onSuccess(this.onFetchAllUsersSuccess).call(api.fetchDealershipUsers, dealershipId);
  };

  @action
  onFetchAllUsersSuccess = (users) => {
    if (users && users.length) {
      this.manageUsersList = users.map((user) => this.updateUserFromJSON(user));
    }
  };

  removeUser = (dealershipId, userId) => {
    return new Promise((resolve, reject) => {
      this.removeUserRequest
        .onSuccess(() => {
          this.onRemoveUserSuccess(userId);
          resolve();
        })
        .onError(reject)
        .call(api.removeUser, dealershipId, userId);
    });
  };

  @action
  onRemoveUserSuccess = (userId) => {
    this.users = this.users.filter((user) => user.id !== userId);
    this.manageUsersList = this.manageUsersList.filter((user) => user.id !== userId);
  };

  addUser = (dealershipId, userData) => {
    return new Promise((resolve) => {
      this.addUserRequest
        .onSuccess((user) => {
          this.manageUsersList.push(this.updateUserFromJSON(user));
          resolve();
        })
        .call(api.addUser, dealershipId, userData);
    });
  };
}

export default UserStore;
