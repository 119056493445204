import { CountryCodes } from '../constants';

const getVehicleOfInterestId = (vehicle: Vehicle) => vehicle.VehicleId || vehicle.Id;

const definedAndEqual = (a: unknown, b: unknown): boolean => !!a && !!b && a === b;

export const compareMileage = (a: Vehicle, b: Vehicle) => {
  const mileageA = a.Condition === 'new' ? a.Mileage || 0 : a.Mileage;
  const mileageB = b.Condition === 'new' ? b.Mileage || 0 : b.Mileage;

  return mileageA?.toString() === mileageB?.toString();
};

const compareVehicle = (a: Vehicle, b: Vehicle, countryCode?: typeof CountryCodes[number]) => {
  const aVoiId = getVehicleOfInterestId(a);
  const bVoiId = getVehicleOfInterestId(b);

  // Matching ids - GB & DE
  if (definedAndEqual(aVoiId, bVoiId)) return true;

  // Matching Condition and CapId - Cap only exists in GB
  if (a.Condition === 'new' && b.Condition === 'new' && definedAndEqual(a.CapId, b.CapId)) return true;

  // Matching VRM and Mileage - GB only
  if (countryCode === 'gb' && definedAndEqual(a.Vrm, b.Vrm) && compareMileage(a, b)) return true;

  // Matching Make, Model, Derivative and Mileage - Fallback
  return a.Make === b.Make && a.Model === b.Model && a.Derivative === b.Derivative && compareMileage(a, b);
};

export type Vehicle = {
  Make: string;
  Model: string;
  Derivative: string;
  Mileage?: string | number;
  Condition: string;
  Vrm?: string | number;
  CapId?: string | number;
  GlassId?: string | number;
  [key: string]: unknown;
} & (
  | {
      VehicleId: string;
      Id?: never;
    }
  | { Id: string; VehicleId?: never }
  | { Id?: never; VehicleId?: never }
);

export { compareVehicle, getVehicleOfInterestId };
