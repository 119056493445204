import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './splitButtonOptionDefault.scss';
import Icon from './Icon/Icon';

class SplitButtonOptionDefault extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasMouseOver: false
    };
  }

  handleMouseOver = () => {
    this.setState({
      hasMouseOver: true
    });
  };
  handleMouseOut = () => {
    this.setState({
      hasMouseOver: false
    });
  };
  handleClick = (e) => {
    if (this.props.onClick) {
      if (this.props.buttonState === 'default') {
        this.props.onClick(e);
      }
    } else {
      if (this.props.buttonState !== 'default') {
        e.preventDefault();
      }
    }
  };

  render() {
    const { onClick, isLoading, stretch, buttonStyle, buttonState, t, ...remainingProps } = this.props;

    let buttonClasses = classnames(
      'splitButtonOptionDefault',
      this.props.stretch && 'stretch',
      `splitButtonOptionDefault--${this.props.buttonStyle}`,
      !this.props.disabled && this.state.hasMouseOver && `splitButtonOptionDefault--${this.props.buttonStyle}Hover`,
      this.props.disabled && 'splitButtonOptionDefault__disabled',
      !this.props.stretch && 'splitButtonOptionDefault__noStretch',
      this.props.buttonState === 'done' && 'splitButtonOptionDefault__done',
      this.props.buttonState === 'error' && 'splitButtonOptionDefault__error',
      this.props.buttonState === 'loading' && 'splitButtonOptionDefault__loadings'
    );
    const labelStyles = classnames(
      'splitButtonOptionDefault__label',
      this.props.buttonState !== 'default' && 'splitButtonOptionDefault__hideLabel'
    );
    return (
      <button
        {...remainingProps}
        onClick={this.handleClick}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        className={buttonClasses}
        disabled={this.props.isLoading || this.props.disabled}
        key="button"
        data-th="SplitButtonOptionDefault"
      >
        <span className={labelStyles}>{this.props.children}</span>

        {this.props.buttonState === 'done' && (
          <span className="splitButtonOptionDefault__tick">
            <Icon name="validation-tick" />
          </span>
        )}

        {this.props.buttonState === 'loading' && <span className="splitButtonOptionDefault__spinner" />}

        {this.props.buttonState === 'error' && (
          <span className="splitButtonOptionDefault__errorContainer">
            <span className="splitButtonOptionDefault__errorMessage">
              {this.props.t('SplitButtonOptionDefault.error')}
            </span>
          </span>
        )}
      </button>
    );
  }
}

SplitButtonOptionDefault.defaultProps = {
  isLoading: false,
  type: 'submit',
  stretch: true,
  buttonStyle: 'primary'
};
SplitButtonOptionDefault.propTypes = {
  stretch: PropTypes.bool,
  buttonStyle: PropTypes.string,
  disabled: PropTypes.bool,
  state: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  buttonState: PropTypes.string
};
export default withTranslation('Common')(SplitButtonOptionDefault);
