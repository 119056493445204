import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './textQuickQuoteBar.scss';
import Icon from '../Common/Icon/Icon';
import { withNavigate } from 'hocs/router';
import { compose } from 'redux';

class TextQuickQuoteBar extends React.Component {
  constructor() {
    super();
    this.state = {
      searchTerms: ''
    };
  }

  onUpdateSearch = (e) => {
    this.setState({
      searchTerms: e.currentTarget.value
    });
  };
  onSubmit = (e) => {
    e.preventDefault();
    let searchTerms = this.state.searchTerms;
    this.setState({
      searchTerms: ''
    });
    this.props.navigate({
      pathname: `/d/${this.props.dealershipId}/quickquote`,
      query: {
        searchTerms: searchTerms,
        vehicleType: this.props.vehicleCondition || 'used',
        class: this.props.vehicleClass
      }
    });
  };

  render() {
    return (
      <div className="textQuickQuoteBar">
        <form onSubmit={this.onSubmit} className="textQuickQuoteBar__form">
          <div className="textQuickQuoteBar__icon">
            <Icon name="search" />
          </div>
          <input
            className="textQuickQuoteBar__input"
            value={this.state.searchTerms}
            onChange={this.onUpdateSearch}
            placeholder={this.props.t('TextQuickQuoteBar.make_model_to_quote')}
            dataThook="TextQuickQuoteBarInput"
          />
          <button type="submit" className="textQuickQuoteBar__button">
            {this.props.t('TextQuickQuoteBar.quick_quote')}
          </button>
        </form>
      </div>
    );
  }
}

TextQuickQuoteBar.propTypes = {
  dealershipId: PropTypes.string,
  onStateChange: PropTypes.func,
  isOpen: PropTypes.bool,
  vehicleCondition: PropTypes.string,
  vehicleClass: PropTypes.string
};

export default compose(withNavigate, withTranslation('Header'))(TextQuickQuoteBar);
