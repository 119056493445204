const nameRegexMap = {
  AddCustomer: /^d\/[a-f0-9-]+\/customer\/add$/,
  AddVehicle: /^d\/[a-f0-9-]+\/consumers\/[a-f0-9-]+\/add$/,
  AddressHistory: /^d\/[a-f0-9-]+\/consumers\/[a-f0-9-]+\/application\/[a-f0-9-]+\/addresshistory$/,
  Affordability: /^d\/[a-f0-9-]+\/consumers\/[a-f0-9-]+\/application\/[a-f0-9-]+\/affordability$/,
  ApplicationEdit: /^d\/[a-f0-9-]+\/consumers\/[a-f0-9-]+\/application\/[a-f0-9-]+\/edit$/,
  ApplicationRepropose: /^d\/[a-f0-9-]+\/consumers\/[a-f0-9-]+\/application\/[a-f0-9-]+\/repropose$/,
  ApplicationRequote: /^d\/[a-f0-9-]+\/consumers\/[a-f0-9-]+\/application\/[a-f0-9-]+\/requote$/,
  ApplicationStatus: /^d\/[a-f0-9-]+\/consumers\/[a-f0-9-]+\/application\/[a-f0-9-]+\/status$/,
  ApplicationSummary: /^d\/[a-f0-9-]+\/consumers\/[a-f0-9-]+\/application\/[a-f0-9-]+\/applicationsummary$/,
  BankDetails: /^d\/[a-f0-9-]+\/consumers\/[a-f0-9-]+\/application\/[a-f0-9-]+\/bankdetails$/,
  BusinessAddress: /^d\/[a-f0-9-]+\/consumers\/[a-f0-9-]+\/application\/[a-f0-9-]+\/businessaddress$/,
  BusinessDetails: /^d\/[a-f0-9-]+\/consumers\/[a-f0-9-]+\/application\/[a-f0-9-]+\/businessdetails$/,
  CfcCheckDetails: /^d\/[a-f0-9-]+\/consumers\/[a-f0-9-]+\/cfcprospect\/[a-f0-9-]+\/quote\/[a-f0-9-]+$/,
  CfcProspects: /^d\/[a-f0-9-]+\/cfcprospects$/,
  Consumer: /^d\/[a-f0-9-]+\/consumers\/[a-f0-9-]+$/,
  CustomerList: /^d\/[a-f0-9-]+\/consumers$/,
  CustomerQuote: /^d\/[a-f0-9-]+\/consumers\/[a-f0-9-]+\/vehicle\/[a-f0-9-]+\/quote$/,
  EmploymentHistory: /^d\/[a-f0-9-]+\/consumers\/[a-f0-9-]+\/application\/[a-f0-9-]+\/employmenthistory$/,
  FinanceApplications: /^d\/[a-f0-9-]+\/financeapplications$/,
  Landing: /^d\/[a-f0-9-]+$/,
  LeadCloseOut: /^d\/[a-f0-9-]+\/consumers\/[a-f0-9-]+\/vehicle\/[a-f0-9-]+\/close\/.*/,
  LenderUpdatesPage: /^d\/[a-f0-9-]+\/lenderupdatespage$/,
  OrganisationContacts: /^d\/[a-f0-9-]+\/consumers\/[a-f0-9-]+\/application\/[a-f0-9-]+\/organisationcontacts$/,
  PersonalDetails: /^d\/[a-f0-9-]+\/consumers\/[a-f0-9-]+\/application\/[a-f0-9-]+\/personaldetails$/,
  QuickQuoteApply: /^d\/[a-f0-9-]+\/quickquote\/apply$/,
  QuickQuoteResults: /^d\/[a-f0-9-]+\/quickquote\/results$/,
  QuickQuoteVehicleDetails: /^d\/[a-f0-9-]+\/quickquote$/,
  Reporting: /^d\/[a-f0-9-]+\/reporting$/,
  SendDeal: /^d\/[a-f0-9-]+\/send-deal$/,
  Settings: /^d\/[a-f0-9-]+\/settings$/,
  SettingsDealership: /^d\/[a-f0-9-]+\/settings\/dealership$/,
  SettingsProduct: /^d\/[a-f0-9-]+\/settings\/product$/,
  ValueAddedProducts: /^d\/[a-f0-9-]+\/settings\/vaps$/,
  ViewApplicationSummary: /^d\/[a-f0-9-]+\/consumers\/[a-f0-9-]+\/application\/[a-f0-9-]+\/viewapplicationsummary$/,
  ReserveNow: /^d\/[a-f0-9-]+\/reservenow$/
};

export function getNameForPath(path) {
  for (let name in nameRegexMap) {
    if (nameRegexMap[name].test(path)) {
      return name;
    }
  }

  return null;
}
