import { action, observable } from 'mobx';
import dealershipEventEmitter from '~/utils/dealershipEventEmitter';
import { dedupe } from './helpers';

class DashboardQuotesStore {
  @observable quotes = [];

  queue = [];
  dequeueInterval = 2_000;
  dequeueIntervalId = null;

  enqueue = (quote) => {
    this.queue.push(quote);
  };

  dequeue = () => {
    if (this.queue.length === 0) {
      return;
    }
    const quote = this.queue.shift();
    this.updateNewQuotes(quote);
  };

  @action
  initData = (quotes) => {
    this.quotes = dedupe(quotes);
  };

  @action
  updateNewQuotes = (newQuoteData) => {
    const copyArr = [...this.quotes];
    // arbitrary quote limit to hold in the UI
    if (copyArr >= 42) {
      copyArr.pop();
    }
    copyArr.unshift(newQuoteData);
    this.quotes = dedupe(copyArr);
  };

  initFetchData = () => {
    this.quotes = [];
    // setInterval is used capture all quotes as they come in and limit how fast the UI updates. Might be less useful after 1s long update animations were removed...
    this.dequeueIntervalId = setInterval(this.dequeue, this.dequeueInterval);

    dealershipEventEmitter.on('finance_quotes_displayed_summary', (message) => {
      try {
        const initQuoteData = JSON.parse(message.Payload);
        this.initData(initQuoteData);

        dealershipEventEmitter.off('finance_quotes_displayed_summary');
      } catch (err) {
        console.error(err);
      }
    });
  };

  subscribeToQuotes = () => {
    dealershipEventEmitter.on('finance_quotes_displayed', (newQuoteData) => this.enqueue(newQuoteData));
  };
}

export default DashboardQuotesStore;
