import platformApiUtils from '@ivendi/platform-api-utils';
import { schemaMap } from '../../core/schema';
import dashboardCfcProspectsSchema from '~/core/schemas/dashboardCfcProspectsSchema';

export const fetchCfcProspects = ({
  dealershipId,
  userId,
  matchRate,
  activeFilter,
  page
}: {
  dealershipId: string;
  userId: string;
  matchRate: string;
  activeFilter: string;
  page: string;
}) => {
  return platformApiUtils
    .get(`v1/dashboard/cfcprospects/${dealershipId}`, {
      PageSize: 25,
      Page: page,
      AssignedTo: activeFilter === 'mine' ? [userId] : undefined,
      IsAssigned: activeFilter === 'unassigned' ? false : undefined,
      MatchRate: matchRate
    })
    .then((data) => schemaMap(data, dashboardCfcProspectsSchema));
};
