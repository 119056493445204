import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './sendDealToExistingCorporateCustomer.scss';
import TextInputWrappingInput from '../../Common/Form/TextInputWrappingInput';
import _ from 'lodash';
import * as lookups from '../../../api/lookups';
import ConsumerName from '../../Common/ConsumerName';
import Button from '../../Common/Button/Button';

class SendDealToExistingCorporateCustomer extends React.Component {
  lookupCustomerByTradingName = null;

  constructor(props) {
    super(props);
    this.state = {
      customer: null,
      existingCustomerId: null,
      isTradingNameTaken: false
    };
  }

  UNSAFE_componentWillMount() {
    this.lookupCustomerByTradingName = _.debounce(this.lookupCorporateCustomerByTradingName, 300);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.lookupCustomerByTradingName.cancel();
      this.setState({
        isTradingNameTaken: false
      });
      this.lookupCustomerByTradingName(nextProps.value);
    }
  }

  handleChange = (tradingName) => {
    this.props.onChange && this.props.onChange(tradingName);
  };
  lookupCorporateCustomerByTradingName = (tradingName) => {
    lookups
      .lookupCorporateCustomerByTradingName(tradingName, this.props.dealershipId)
      .then(this.onSuccess)
      .catch(this.onError);
  };
  onSuccess = (response) => {
    this.setState({
      customer: response,
      existingCustomerId: response.Id,
      isTradingNameTaken: true
    });
  };
  onError = () => {
    this.setState({
      existingCustomerId: null,
      isTradingNameTaken: false
    });
  };

  render() {
    const customer = this.state.customer;
    return (
      <div>
        <TextInputWrappingInput
          id="tradingName"
          value={this.props.value}
          onChange={this.handleChange}
          onBlur={this.props.onBlur}
          isValid={this.props.isValid}
          isInvalid={this.props.isInvalid}
        />
        {this.state.isTradingNameTaken && (
          <div className="sendDealToExistingCorporateCustomer" key="outer">
            <div className="sendDealToExistingCorporateCustomer__text">
              {this.props.t('SendDealToExistingCorporateCustomer.a_customer_already_belongs_to_this_trading_name')}
            </div>
            <div className="sendDealToExistingCorporateCustomer__customer">
              {customer.Title}.{' '}
              <ConsumerName
                name={`${customer.Firstname} ${
                  customer.Middlename ? customer.Middlename || customer.Middlenames : ''
                } ${customer.Surname}`}
              />
              <div>{customer.TradingName}</div>
            </div>
            <div className="sendDealToExistingCorporateCustomer__button">
              <Button
                id="SendDealToExistingCorporateCustomer"
                type="button"
                onClick={() => this.props.submit(customer.Id)}
              >
                {this.props.t('SendDealToExistingCorporateCustomer.send_deal_to_this_customer')}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

SendDealToExistingCorporateCustomer.propTypes = {
  submit: PropTypes.func,
  dealershipId: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
};
export default withTranslation('QuickQuote')(SendDealToExistingCorporateCustomer);
