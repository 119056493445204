import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFormFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import TextInputWrappingInput from '../../Common/Form/TextInputWrappingInput';
import { observable, action, reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import Validator from '../../../validators/Validator';
import SelectInput from '../../Common/Form/SelectInput';
import CheckboxWithLabel from '../../Common/Form/CheckboxWithLabel';
import PostcodeLookup from '../../Common/PostcodeLookup/PostcodeLookup';
import FormFooter from '../../Common/Form/FormFooter';
import TradingNameLookup from '../../Common/TradingNameLookup';
import EmailLookup from '../../Common/EmailLookup';
import './addCorporateForm.scss';
import { trimDeepObjectStrings } from '../../../core/helpers';
import { withNavigate } from 'hocs/router';
import { compose } from 'redux';
import { PhoneNumberInput } from '~/components/Common/Form/PhoneNumberInput';
class AddCorporateForm extends React.Component {
  @observable
  formData = {
    customerType: 'corporate',
    email: '',
    title: '',
    firstName: '',
    middleName: '',
    surname: '',
    position: '',
    landline: '',
    mobile: '',
    faxNumber: '',
    assignToSelf: false,
    tradingName: '',
    company: '',
    buildingName: '',
    buildingNumber: '',
    subBuilding: '',
    street: '',
    district: '',
    postTown: '',
    postCode: ''
  };

  constructor() {
    super();
    const validationRules = {
      title: 'required',
      firstName: 'required, maxlength:75, doesNotStartOrEndWithSpace',
      middleName: 'maxlength:75, doesNotStartOrEndWithSpace',
      surname: 'required, maxlength:75, doesNotStartOrEndWithSpace',
      position: 'required',
      email: 'required, email',
      landline: 'required:unless:mobile, businessPhone',
      mobile: 'required:unless:landline, mobile',
      faxNumber: 'phone',
      tradingName: 'required, maxlength:75, doesNotStartOrEndWithSpace',
      buildingName: 'maxlength:75, doesNotStartOrEndWithSpace',
      buildingNumber: 'maxlength:75, doesNotStartOrEndWithSpace',
      subBuilding: 'maxlength:75, doesNotStartOrEndWithSpace',
      street: 'maxlength:75, lenderAlphanumeric, doesNotStartOrEndWithSpace',
      district: 'maxlength:75, lenderAlphanumeric, doesNotStartOrEndWithSpace',
      postTown: 'maxlength:75, doesNotStartOrEndWithSpace',
      postCode: 'alphaNumSpace, doesNotStartOrEndWithSpace'
    };
    this.setUpValidation(validationRules);
  }

  setUpValidation(validationRules) {
    this.validator = new Validator();
    this.validator.setRules(validationRules);
    this.validationReactionDisposer = reaction(() => ({ ...this.formData }), this.validator.validate, {
      fireImmediately: true
    });
  }

  @action cleanupFormData = () => {
    this.formData = trimDeepObjectStrings(this.formData);
    this.validator.validate(this.formData);
  };
  handleSubmit = () => {
    const {
      appStore: { customerListStore }
    } = this.props;
    this.cleanupFormData();

    if (!this.validator.errorCount) {
      customerListStore
        .addCustomer(this.formData, this.props.dealershipId, this.formData.assignToSelf)
        .then((customerId) => {
          this.props.navigate(`/d/${this.props.dealershipId}/consumers/${customerId}`);
        });
    }
  };
  handleCancel = () => {
    this.props.navigate(-1);
  };

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  @action handlePostCodeLookup = (address) => {
    this.formData.company = address.Company;
    this.formData.buildingName = address.BuildingName;
    this.formData.buildingNumber = address.BuildingNumber;
    this.formData.subBuilding = address.SubBuilding;
    this.formData.street = address.Street;
    this.formData.district = address.District;
    this.formData.postTown = address.PostTown;
    this.formData.postCode = address.Postcode;
    this.cleanupFormData();
  };
  @action setCustomerType = (fieldName, customerType) => {
    this.formData.customerType = customerType;
    this.props.onCustomerTypeChange(customerType);
  };
  @action setEmail = (email) => (this.formData.email = email);
  @action setTitle = (fieldName, title) => (this.formData.title = title);
  @action setFirstName = (firstName) => (this.formData.firstName = firstName);
  @action setMiddleName = (middleName) => (this.formData.middleName = middleName);
  @action setSurname = (surname) => (this.formData.surname = surname);
  @action setPosition = (fieldName, position) => (this.formData.position = position);
  @action setLandline = (landline) => (this.formData.landline = landline);
  @action setMobile = (mobile) => (this.formData.mobile = mobile);
  @action setFaxNumber = (faxNumber) => (this.formData.faxNumber = faxNumber);
  @action setAssignToSelf = (value) => (this.formData.assignToSelf = value);
  @action setTradingName = (tradingName) => (this.formData.tradingName = tradingName);
  @action setCompany = (company) => (this.formData.company = company);
  @action setBuildingName = (buildingName) => (this.formData.buildingName = buildingName);
  @action setBuildingNumber = (buildingNumber) => (this.formData.buildingNumber = buildingNumber);
  @action setSubBuilding = (subBuilding) => (this.formData.subBuilding = subBuilding);
  @action setStreet = (street) => (this.formData.street = street);
  @action setDistrict = (district) => (this.formData.district = district);
  @action setPostTown = (postTown) => (this.formData.postTown = postTown);
  @action setPostCode = (postCode) => (this.formData.postCode = postCode);

  render() {
    const errors = this.validator.getErrors();
    const {
      appStore: { customerListStore, uiState }
    } = this.props;

    const funderCode = uiState.getPlatformCode;

    return (
      <MobxForm onSubmit={this.handleSubmit} focusOnFirstElement className="editCorporateForm">
        <section className="editCorporateForm__personalDetails">
          <h2 className="editCorporateForm__header editCorporateForm__personalDetailsHeader">
            {this.props.t('AddCorporateForm.business_contact_details')}
          </h2>

          <div className="editCorporateForm__fields">
            <MobxFormFieldGroup error={errors.customerType}>
              <MobxFormLabel htmlFor="customerType">{this.props.t('AddCorporateForm.customer_type')}</MobxFormLabel>
              <SelectInput
                id="customerType"
                value={this.formData.customerType}
                onChange={this.setCustomerType}
                options="ContactType"
                emptyValue={false}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.email}>
              <MobxFormLabel htmlFor="email">{this.props.t('AddCorporateForm.email')}</MobxFormLabel>
              <EmailLookup
                sector="corporate"
                onChange={this.setEmail}
                dealershipId={this.props.dealershipId}
                value={this.formData.email}
                autoComplete="none"
                placeholder={this.props.t('AddCorporateForm.enter_customer_email_address')}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.title}>
              <MobxFormLabel htmlFor="title">{this.props.t('AddCorporateForm.title')}</MobxFormLabel>
              <SelectInput
                id="title"
                value={this.formData.title}
                onChange={this.setTitle}
                options="CustomerTitles"
                funderCode={funderCode}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.firstName}>
              <MobxFormLabel htmlFor="firstName">{this.props.t('AddCorporateForm.first_name')}</MobxFormLabel>
              <TextInputWrappingInput id="firstName" value={this.formData.firstName} onChange={this.setFirstName} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.middleName}>
              <MobxFormLabel htmlFor="middleName">{this.props.t('AddCorporateForm.middle_name')}</MobxFormLabel>
              <TextInputWrappingInput id="middleNames" value={this.formData.middleName} onChange={this.setMiddleName} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.surname}>
              <MobxFormLabel htmlFor="surname">{this.props.t('AddCorporateForm.last_name')}</MobxFormLabel>
              <TextInputWrappingInput id="surname" value={this.formData.surname} onChange={this.setSurname} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.position}>
              <MobxFormLabel htmlFor="position">{this.props.t('AddCorporateForm.position')}</MobxFormLabel>
              <SelectInput
                id="position"
                value={this.formData.position}
                onChange={this.setPosition}
                options="Position"
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup
              error={errors.landline}
              errorMessage={
                errors.landline?.error === 'required' &&
                this.props.t('AddCorporateForm.either_business_telephone_or_mobile_is_required')
              }
            >
              <MobxFormLabel htmlFor="landline">{this.props.t('AddCorporateForm.business_telephone')}</MobxFormLabel>
              <PhoneNumberInput id="landline" value={this.formData.landline} onChange={this.setLandline} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup
              error={errors.mobile}
              errorMessage={
                errors.mobile?.error === 'required' &&
                this.props.t('AddCorporateForm.either_business_telephone_or_mobile_is_required')
              }
            >
              <MobxFormLabel htmlFor="mobile">{this.props.t('AddCorporateForm.or_mobile_telephone')}</MobxFormLabel>
              <PhoneNumberInput id="mobile" value={this.formData.mobile} onChange={this.setMobile} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.faxNumber}>
              <MobxFormLabel htmlFor="mobile">{this.props.t('AddCorporateForm.fax_number')}</MobxFormLabel>
              <PhoneNumberInput id="faxNumber" value={this.formData.faxNumber} onChange={this.setFaxNumber} />
            </MobxFormFieldGroup>
            {this.props.appStore.uiState.canAssign && (
              <CheckboxWithLabel id="assignToSelf" value={this.formData.assignToSelf} onChange={this.setAssignToSelf}>
                {this.props.t('AddCorporateForm.assign_this_lead_to_me')}
              </CheckboxWithLabel>
            )}
          </div>
        </section>

        <section className="editCorporateForm__addressDetails">
          <h2 className="editCorporateForm__header editCorporateForm__addressDetailsHeader">
            {this.props.t('AddCorporateForm.business_address_details')}
          </h2>

          <div className="editCorporateForm__fields">
            <MobxFormFieldGroup error={errors.tradingName}>
              <MobxFormLabel htmlFor="tradingName">{this.props.t('AddCorporateForm.trading_name')}</MobxFormLabel>
              <TradingNameLookup
                value={this.formData.tradingName}
                dealershipId={this.props.dealershipId}
                onChange={this.setTradingName}
              />
            </MobxFormFieldGroup>

            <PostcodeLookup onSubmit={this.handlePostCodeLookup} />

            <MobxFormFieldGroup error={errors.company}>
              <MobxFormLabel htmlFor="company">{this.props.t('AddCorporateForm.company_name')}</MobxFormLabel>
              <TextInputWrappingInput id="company" value={this.formData.company} onChange={this.setCompany} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.subBuilding}>
              <MobxFormLabel htmlFor="subBuilding">{this.props.t('AddCorporateForm.room_floor')}</MobxFormLabel>
              <TextInputWrappingInput
                id="subBuilding"
                value={this.formData.subBuilding}
                onChange={this.setSubBuilding}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.buildingName}>
              <MobxFormLabel htmlFor="buildingName">{this.props.t('AddCorporateForm.building_name')}</MobxFormLabel>
              <TextInputWrappingInput
                id="buildingName"
                value={this.formData.buildingName}
                onChange={this.setBuildingName}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.buildingNumber}>
              <MobxFormLabel htmlFor="buildingNumber">{this.props.t('AddCorporateForm.building_number')}</MobxFormLabel>
              <TextInputWrappingInput
                id="buildingNumber"
                value={this.formData.buildingNumber}
                onChange={this.setBuildingNumber}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.street}>
              <MobxFormLabel htmlFor="street">{this.props.t('AddCorporateForm.street')}</MobxFormLabel>
              <TextInputWrappingInput id="street" value={this.formData.street} onChange={this.setStreet} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.district}>
              <MobxFormLabel htmlFor="district">{this.props.t('AddCorporateForm.district')}</MobxFormLabel>
              <TextInputWrappingInput id="district" value={this.formData.district} onChange={this.setDistrict} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.postTown}>
              <MobxFormLabel htmlFor="postTown">{this.props.t('AddCorporateForm.city_town')}</MobxFormLabel>
              <TextInputWrappingInput id="postTown" value={this.formData.postTown} onChange={this.setPostTown} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.postCode}>
              <MobxFormLabel htmlFor="postCode">{this.props.t('AddCorporateForm.postcode')}</MobxFormLabel>
              <TextInputWrappingInput id="postCode" value={this.formData.postCode} onChange={this.setPostCode} />
            </MobxFormFieldGroup>
          </div>
        </section>

        <div className="editCorporateForm__footer">
          <FormFooter
            submitLabel={this.props.t('AddCorporateForm.add_customer_contact')}
            onCancel={this.handleCancel}
            errorMessage={
              customerListStore.addCustomerRequest.hasError
                ? this.props.t('AddCorporateForm.sorry_an_error_occurred_while_trying_to_add_this_customer')
                : ''
            }
            isSubmitting={customerListStore.addCustomerRequest.isLoading}
            hasSubmittingError={customerListStore.addCustomerRequest.hasError}
          />
        </div>
      </MobxForm>
    );
  }
}

AddCorporateForm.propTypes = {
  dealershipId: PropTypes.string,
  appStore: PropTypes.object,
  onCustomerTypeChange: PropTypes.func
};

export default compose(withNavigate, withTranslation('AddCustomer'), inject(['appStore']), observer)(AddCorporateForm);
