import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import './editNegativeEquityLoan.scss';
import { QuoteRequest } from '../../../core/quoteRequest';
import * as debug from '../../../debug';
import { trackSelfDescribingEvent } from '@ivendi/snowplow-js';
import Validator from '../../../validators/Validator';
import { reaction } from 'mobx';
import MobxForm from '../../MobxForm/MobxForm';
import CurrencyInput from '../../Common/Form/CurrencyInput';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import MobxButtonFieldGroup from '../../MobxForm/MobxButtonFieldGroup';
import Button from '../../Common/Button/Button';
import SelectInput from '../../Common/Form/SelectInput';
import { inject, observer } from 'mobx-react';
import FormFooter from '../../Common/Form/FormFooter';
import SettlementModal from '../modals/SettlementModal';
import Divider from '../../Common/Divider';
import { generateDefaultQuoteRequestValues } from '../components/utils';
import { hasExtendedTermLength } from '~/utils/quoteUtils';

class EditNegativeEquityLoan extends Component {
  quoteRequest = null;

  constructor(props) {
    super(props);
    this.state = {
      showFinanceFields: false,
      settlementModalOpen: false,
      hasLookedupSettlement: false,
      customerType: props.defaultValues.CustomerType || this.props.customerType
    };
    this.validationRules = {
      CashDeposit: 'required, currency',
      PartExchange: 'currency',
      OutstandingSettlement: 'currency',
      Term: 'required'
    };
    this.initQuoteRequest();
    this.setUpValidation();
  }

  UNSAFE_componentWillMount() {
    this.setState({
      showFinanceFields: this.quoteRequest.OutstandingSettlement || this.quoteRequest.PartExchange ? true : false,
      hasLookedupSettlement: this.quoteRequest.SettlementSource && this.quoteRequest.SettlementSource.includes('MNF')
    });
  }

  componentDidMount() {
    let ActualCashDeposit =
      this.quoteRequest.ActualCashDeposit && parseFloat(this.quoteRequest.ActualCashDeposit)
        ? parseFloat(this.quoteRequest.ActualCashDeposit)
        : 0;
    this.quoteRequest.set('CashDeposit', ActualCashDeposit);
    this.setState({
      ActualCashDeposit
    });
  }

  setCashDeposit = (CashDeposit) => {
    this.quoteRequest.set('CashDeposit', CashDeposit);
    this.setState({
      ActualCashDeposit: CashDeposit
    });
  };
  setPartExchange = (PartExchange) => this.quoteRequest.set('PartExchange', PartExchange);
  setOutstandingSettlement = (OutstandingSettlement, SettlementSource) => {
    this.quoteRequest.set('OutstandingSettlement', OutstandingSettlement);
    this.quoteRequest.set('SettlementSource', SettlementSource);
  };
  setTerm = (fieldName, Term) => this.quoteRequest.set('Term', Term);

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  setUpValidation() {
    this.validator = new Validator();
    this.validator.setRules(this.validationRules);
    this.validationReactionDisposer = reaction(() => ({ ...this.quoteRequest.toObject() }), this.validator.validate, {
      fireImmediately: true
    });
  }

  toggleField(field) {
    this.setState({
      [field]: !this.state[field]
    });
  }

  initQuoteRequest = () => {
    const { vehicle, defaultValues, vehicleClass, vehicleCondition } = this.props;
    const defaultQuoteRequestValues = generateDefaultQuoteRequestValues({
      defaultValues,
      countryCode: this.props.appStore.uiState.countryCode,
      vehicleClass,
      vehicleCondition,
      vehicle,
      isBnpp: this.props.appStore.uiState.isBnpp
    });
    this.quoteRequest = new QuoteRequest(defaultQuoteRequestValues);
  };

  handleSubmit = () => {
    if (!this.validator.errorCount) {
      this.quoteRequest.ValueAddedProducts.map((vap) => {
        if (vap.TaxTypeCode < 1 || vap.TaxTypeCode === undefined) {
          debug.error(
            'taxTypeZero ' +
              JSON.stringify({
                productTypes: this.props.appStore.vapStore.visibleProducts,
                vap: vap
              })
          );
        }
      });
      this.props.onSubmit(
        Object.assign({}, this.quoteRequest.toObject(), {
          CustomerType: this.state.customerType,
          LoanType: 'negativeEquityLoan'
        })
      );
      trackSelfDescribingEvent(
        'QuoteCostsFormSubmit',
        'ClickGenerateQuotesButton',
        JSON.stringify({
          vehicleClass: this.props.vehicleClass.toLowerCase(),
          VatAddedToDeposit: this.quoteRequest.VatAddedToDeposit ? 'true' : 'false'
        })
      );
    } else {
      this.validator.validate(this.quoteRequest.toObject());
    }
  };
  openSettlementModal = (e) => {
    e.preventDefault();
    this.setState({
      settlementModalOpen: true
    });
  };
  closeSettlementModal = () =>
    this.setState({
      settlementModalOpen: false
    });
  setSettlementFigure = (figure, agreementId) => {
    if (this.props.appStore.quotingStore.statusCode === 200 || this.props.appStore.quotingStore.statusCode === 201) {
      this.setOutstandingSettlement(figure, `MNF:${agreementId}`);
      this.setState({
        hasLookedupSettlement: true
      });
    }
  };
  removeSettlementFigure = () => {
    this.setOutstandingSettlement('');
    this.setState({
      hasLookedupSettlement: false
    });
    this.props.appStore.quotingStore.settlementRequest.reset();
    this.props.appStore.quotingStore.resetSettlement();
  };

  render() {
    const pdfLink = this.props.appStore.quotingStore.settlementDocumentPdfLink;
    const errors = this.validator.getErrors();
    const loadingState = this.props.isLoading ? 'loading' : 'done';
    const equity = this.quoteRequest.getNetDeposit();
    let newLoanAmount = equity < 0 ? Math.abs(equity) : 0;
    let depositRules;

    if (isNaN(parseFloat(this.quoteRequest.CashDeposit))) {
      depositRules = errors.CashDeposit;
    } else {
      depositRules =
        this.quoteRequest.getNetDeposit() <= 0
          ? errors.CashDeposit
          : {
              error: 'max',
              message: this.props.t('EditNegativeEquityLoan.equity_must_be_less_than_or_equal_to_zero')
            };
    }

    return (
      <div>
        <MobxForm onSubmit={this.handleSubmit}>
          <div className="quoteCostsForm__section">
            <MobxFieldGroup isInline error={errors.PartExchange}>
              <MobxFormLabel htmlFor="PartExchange">
                {this.props.t('EditNegativeEquityLoan.current_loan_amount')}
              </MobxFormLabel>
              <CurrencyInput
                trackingPage="EditNegativeEquityLoan"
                value={this.quoteRequest.Advance}
                disabled={true}
                id="Advance"
              />
            </MobxFieldGroup>

            <Divider />
            <MobxFieldGroup
              isInline
              information={
                this.quoteRequest.hasNegativeEquity &&
                this.props.t('EditNegativeEquityLoan.this_deal_contains_negative_equity')
              }
            >
              <MobxFormLabel>{this.props.t('EditNegativeEquityLoan.equity')}</MobxFormLabel>
              <CurrencyInput trackingPage="EditNegativeEquityLoan" value={equity} disabled={true} id="NetDeposit" />
            </MobxFieldGroup>

            <div>
              <MobxFieldGroup isInline error={errors.PartExchange}>
                <MobxFormLabel htmlFor="PartExchange">
                  {this.props.t('EditNegativeEquityLoan.part_exchange_value')}
                </MobxFormLabel>
                <CurrencyInput
                  trackingPage="EditNegativeEquityLoan"
                  value={this.quoteRequest.PartExchange}
                  onChange={this.setPartExchange}
                  id="PartExchange"
                />
              </MobxFieldGroup>

              {this.state.customerType.toLowerCase() === 'consumer' &&
              this.props.appStore.uiState.canRetrieveSettlement ? (
                <div>
                  {this.state.hasLookedupSettlement ? (
                    <MobxFieldGroup error={errors.OutstandingSettlement} isInline>
                      <MobxFormLabel htmlFor="OutstandingSettlement">
                        {this.props.t('EditNegativeEquityLoan.settlement_figure')}
                      </MobxFormLabel>
                      <CurrencyInput
                        trackingPage="EditNegativeEquityLoan"
                        value={this.quoteRequest.OutstandingSettlement}
                        onChange={this.setOutstandingSettlement}
                        id="SettlementFigure"
                        disabled
                        onRemove={this.removeSettlementFigure}
                      />
                      {pdfLink && (
                        <MobxFieldGroup isInline>
                          <a
                            type="application/pdf"
                            href={`/platform/v1/settlement/document?DealershipId=${this.props.dealershipId}&AgreementId=${this.props.appStore.quotingStore.agreementId}`}
                            download={`${this.props.appStore.quotingStore.agreementId}.pdf`}
                            className="quoteCostsForm__settlementPdf"
                          >
                            {this.props.t('EditNegativeEquityLoan.view_settlement_letter')}
                          </a>
                        </MobxFieldGroup>
                      )}
                    </MobxFieldGroup>
                  ) : (
                    <MobxButtonFieldGroup error={errors.OutstandingSettlement}>
                      <MobxFormLabel htmlFor="OutstandingSettlement">
                        {this.props.t('EditNegativeEquityLoan.settlement_figure')}
                      </MobxFormLabel>
                      <CurrencyInput
                        trackingPage="EditNegativeEquityLoan"
                        value={this.quoteRequest.OutstandingSettlement}
                        onChange={this.setOutstandingSettlement}
                        id="SettlementFigure"
                      />
                      <Button
                        onClick={this.openSettlementModal}
                        buttonStyle="secondary"
                        id="SettlementFigureLookupButton"
                        type="button"
                        trackingPage="EditNegativeEquityLoan"
                      >
                        {this.props.t('EditNegativeEquityLoan.lookup')}
                      </Button>
                    </MobxButtonFieldGroup>
                  )}
                </div>
              ) : (
                <MobxFieldGroup error={errors.OutstandingSettlement} isInline>
                  <MobxFormLabel htmlFor="OutstandingSettlement">
                    {this.props.t('EditNegativeEquityLoan.settlement_figure')}
                  </MobxFormLabel>
                  <CurrencyInput
                    trackingPage="EditNegativeEquityLoan"
                    value={this.quoteRequest.OutstandingSettlement}
                    onChange={this.setOutstandingSettlement}
                    id="SettlementFigure"
                  />
                </MobxFieldGroup>
              )}
            </div>

            <MobxFieldGroup isInline error={depositRules}>
              <MobxFormLabel htmlFor="Term">{this.props.t('EditNegativeEquityLoan.cash_deposit')}</MobxFormLabel>
              <CurrencyInput
                trackingPage="EditNegativeEquityLoan"
                value={this.state.ActualCashDeposit}
                onChange={this.setCashDeposit}
                id="CashDeposit"
              />
            </MobxFieldGroup>
            <Divider />

            <MobxFieldGroup isInline error={errors.Term}>
              <MobxFormLabel htmlFor="Term">{this.props.t('EditNegativeEquityLoan.term_months')}</MobxFormLabel>
              <SelectInput
                trackingPage="EditNegativeEquityLoan"
                value={this.quoteRequest.Term}
                onChange={this.setTerm}
                options={hasExtendedTermLength(this.props.vehicleClass) ? 'TermsExtended' : 'Terms'}
                id="Term"
                hasEditLimit={this.props.defaultValues.Term}
              />
            </MobxFieldGroup>
            <div className="editNegativeEquityLoan__termText">
              {this.props.t('EditNegativeEquityLoan.personal_loan_term_cannot_exceed_the_duration_of_the_vehicle_loan')}
            </div>
            <Divider />
            <div className="quoteCostsForm__section">
              <MobxFieldGroup isInline>
                <MobxFormLabel>{this.props.t('EditNegativeEquityLoan.new_loan_amount')}</MobxFormLabel>
                <CurrencyInput
                  trackingPage="EditNegativeEquityLoan"
                  value={newLoanAmount}
                  disabled={true}
                  id="BalanceToChange"
                />
              </MobxFieldGroup>
            </div>
          </div>
          <FormFooter
            submitLabel={this.props.t('EditNegativeEquityLoan.generate_quotes')}
            onCancel={this.props.onCancel}
            submittingState={loadingState}
            trackingPage="EditNegativeEquityLoan"
          />
        </MobxForm>
        <SettlementModal
          isOpen={this.state.settlementModalOpen}
          onClose={this.closeSettlementModal}
          setSettlementFigure={this.setSettlementFigure}
          dealershipId={this.props.dealershipId}
          customerId={this.props.customerId}
        />
      </div>
    );
  }
}

export default withTranslation('Quoting')(inject(['appStore'])(observer(EditNegativeEquityLoan)));
