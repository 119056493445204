import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import DateFormat from '../../Common/DateFormat';
import { getClientApp } from '../../../core/helpers';
import './consumerHubInfoPanel.scss';

function ConsumerHubInfoPanel({ application }) {
  const { t } = useTranslation('Application');
  const clientApp = getClientApp(application);
  const showIddViewedActivityItem =
    application.AcceptedInitialDisclosureDocument && application.ClientApp === 'new-vehicle-account';
  return (
    <div className="consumerHubInfoPanel">
      <span className="consumerHubInfoPanel__title">{t('ConsumerHubInfoPanel.online_application')}</span>
      <div className="consumerHubInfoPanel__timestamp">
        <DateFormat value={application.CreatedDate} format="HH:mm DD MMM YYYY" />
      </div>
      <ul className="consumerHubInfoPanel__activityList">
        <li className="consumerHubInfoPanel__activityItem">
          {t(`ConsumerHubInfoPanel.customer_completed_application_via_client_app`, {
            clientApp
          })}
        </li>
        {showIddViewedActivityItem && (
          <li className="consumerHubInfoPanel__activityItem">
            {t(
              `ConsumerHubInfoPanel.customer_acknowledged_that_they_ve_read_and_understood_the_initial_disclosure_document_via_client_app`,
              {
                clientApp
              }
            )}
          </li>
        )}
      </ul>
    </div>
  );
}

ConsumerHubInfoPanel.propTypes = {
  application: PropTypes.object
};
export default ConsumerHubInfoPanel;
