import { z, ZodError } from 'zod';
import { FetchError } from '~/components/Insights/services/utils/handleErrorResponse';
import { get } from '@ivendi/platform-api-utils';

const ResultSchema = z.object({
  Id: z.string(),
  Title: z.string(),
  Content: z.string(),
  CreatedDate: z.string(),
  CreatedTimestamp: z.number().optional()
});

const ModelSchema = z.object({
  Page: z.number(),
  PageSize: z.number(),
  Results: z.array(ResultSchema),
  SortDirection: z.string(),
  SortField: z.string(),
  Total: z.number()
});

const PlatformUpdatesSchema = z.object({
  Model: ModelSchema
});

export type PlatformUpdatesResponse = z.infer<typeof PlatformUpdatesSchema>;
export type ModelResponse = z.infer<typeof ModelSchema>;

const parsePlatformUpdates = (data: PlatformUpdatesResponse): ModelResponse => {
  const parsedResponse = PlatformUpdatesSchema.parse(data);

  parsedResponse.Model.Results.forEach((result) => {
    if (!result.CreatedTimestamp) {
      result.CreatedTimestamp = new Date(result.CreatedDate).getTime();
    }
  });

  return parsedResponse.Model;
};

export const getPlatformUpdates = async (
  dealershipId: string = '',
  page: number = 1,
  pageSize: number = 10,
  sortDirection: 'asc' | 'desc' = 'desc'
): Promise<ModelResponse> => {
  const options = {
    page,
    pageSize,
    sortDirection
  };
  try {
    const data: PlatformUpdatesResponse = await get(`v1/whatsnew/${dealershipId}`, options);
    return parsePlatformUpdates(data);
  } catch (error) {
    if (error instanceof ZodError || error instanceof FetchError) {
      throw error;
    }
    throw new Error('An unknown error occurred');
  }
};
