import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './dashboardPanelViewMore.scss';

const DashboardPanelViewMore = ({ link }) => {
  const { t } = useTranslation('Dashboard');
  return (
    <div className="dashboardPanelViewMore">
      <Link className="dashboardPanelViewMore__link" to={link} key="link">
        <div className="dashboardPanelViewMore__button" key="2">
          {t('DashboardPanelViewMore.more')}
        </div>
      </Link>
    </div>
  );
};

DashboardPanelViewMore.propTypes = {
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
export default DashboardPanelViewMore;
