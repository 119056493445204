import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Common/Button/Button';
import SearchBar from '../../Common/Search';
import SearchResults from './SearchResults';
import './searchBarWithResults.scss';

const SearchBarWithResults = (props) => {
  const { t } = useTranslation('AssignLead');

  const keyDownHandler = (e) => {
    if (e.keyCode === 40) {
      //Down Arrow
      e.preventDefault();
      props.makeNextSearchResultActive();
      return false;
    } else if (e.keyCode === 38) {
      //Up Arrow
      e.preventDefault();
      props.makePrevSearchResultActive();
      return false;
    } else if (e.keyCode === 13 && props.activeSearchResultIndex !== null) {
      //Enter
      e.preventDefault();
      props.selectCurrentActiveResult();
      return false;
    }
  };

  let searchBarValue;

  if (props.selectedSearchResult) {
    searchBarValue = `${props.selectedSearchResult.firstName} ${props.selectedSearchResult.surname}`;
  } else if (props.searchQuery) {
    searchBarValue = props.searchQuery;
  } else {
    searchBarValue = null;
  }

  return (
    <div className="searchBarWithResults" data-th="SearchBarWithResults">
      <div className="searchBarWithResults__container">
        <div className="searchBarWithResults__searchBar" key="searchBar">
          <SearchBar
            onSubmit={props.handleSearch}
            placeHolder={t('SearchBarWithResults.type_a_name_or_email_address')}
            searchOnChange
            searchOnClick
            tabIndex={1}
            debounceTime={350}
            value={searchBarValue}
            noMarginBottom
            onKeyDown={keyDownHandler}
          />
        </div>
        <div className="searchBarWithResults__addUserButton" key="addUserButton">
          <Button
            type="button"
            buttonStyle="primary"
            disabled={!props.selectedSearchResult}
            tabIndex={2}
            onClick={props.addUserToCurrentAssignees}
          >
            {t('SearchBarWithResults.add_user')}
          </Button>
        </div>
      </div>
      <div className="searchBarWithResults__results">
        {props.searchResults && props.searchResults.length ? (
          <SearchResults
            results={props.searchResults}
            onSelectResult={props.onSelectResult}
            searchQuery={props.searchQuery}
            activeSearchResultIndex={props.activeSearchResultIndex}
          />
        ) : null}
      </div>
    </div>
  );
};

SearchBarWithResults.propTypes = {
  handleSearch: PropTypes.func,
  searchResults: PropTypes.object,
  onSelectResult: PropTypes.func,
  searchQuery: PropTypes.string,
  selectedSearchResult: PropTypes.object,
  addUserToCurrentAssignees: PropTypes.func,
  activeSearchResultIndex: PropTypes.number,
  makeNextSearchResultActive: PropTypes.func,
  makePrevSearchResultActive: PropTypes.func,
  selectCurrentActiveResult: PropTypes.func
};
export default SearchBarWithResults;
