import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Page from '../../Common/Page';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import GlobalDefaultSettingsForm from '../forms/GlobalDefaultSettingsForm';
import Breadcrumbs from '../../Common/Breadcrumbs';
import { useParams, useNavigate } from 'react-router-dom';

const SettingsDealership = (props) => {
  const { t } = useTranslation('DealershipSettings');
  const params = useParams();
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(`/d/${params.dealershipId}/settings`);
  };

  return (
    <Page>
      <Breadcrumbs
        items={[
          {
            name: t('SettingsDealership.crumb_home'),
            path: `/d/${params.dealershipId}`
          },
          {
            name: t('SettingsDealership.crumb_settings'),
            path: `/d/${params.dealershipId}/settings`
          },
          {
            name: t('SettingsDealership.crumb_global_defaults')
          }
        ]}
      />
      <div>
        <Panel>
          <PanelHeader>{t('SettingsDealership.global_defaults')}</PanelHeader>
          <PanelContent>
            {!props.globalDefaultSettings.hasLoaded ? (
              <div>{t('SettingsDealership.loading')}</div>
            ) : (
              <GlobalDefaultSettingsForm
                onCancel={handleCancel}
                onSubmit={props.saveDealershipSettings}
                defaultValues={props.globalDefaultSettings}
                submittingState={props.globalDefaultSettings.savingState}
              />
            )}
          </PanelContent>
        </Panel>
      </div>
    </Page>
  );
};

SettingsDealership.propTypes = {
  dispatch: PropTypes.func,
  params: PropTypes.object.isRequired,
  globalDefaultSettings: PropTypes.object,
  session: PropTypes.object,
  dealership: PropTypes.object,
  handleCancel: PropTypes.func,
  saveDealershipSettings: PropTypes.func
};
export default SettingsDealership;
