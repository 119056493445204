import { AdvancedPaymentSchedule, GetPaymentTableDataResponse, QuotewarePaymentScheduleDetails } from '../types';

const getPaymentTableData = (
  paymentSchedule: QuotewarePaymentScheduleDetails[],
  firstPayment: number,
  finalPayment: number,
  duration: number,
  standardAmount: number
): GetPaymentTableDataResponse => {
  /**
   * hasPaymentSchedule is only true if length > 3 because for standard products the data is returned
   * in the quote object in the "firstPayment", "standardPayments" and "finalPayment" keys. When this
   * is the case the payment schedule for some products seemed to return inconsistent results.
   *
   * This can be seen when doing a VAT Assisted quote for an LCV vehicle for Motonovo as the VAT
   * becomes it's own payment as part of the overall loan.
   */
  const hasPaymentSchedule = paymentSchedule && paymentSchedule.length > 3;

  if (hasPaymentSchedule) {
    const base: AdvancedPaymentSchedule = {
      FirstPayment: paymentSchedule[0]!.Amount,
      FinalPayment: paymentSchedule[paymentSchedule.length - 1]?.Amount ?? 0
    };

    return paymentSchedule.reduce((acc, payment, index) => {
      if (index === 0 || index === paymentSchedule.length - 1) return acc;

      acc[`PAYMENT__${index}`] = { duration: payment.Number, amount: payment.Amount };

      return acc;
    }, base);
  } else {
    return {
      FirstPayment: firstPayment,
      StandardPayments: { duration: duration, amount: standardAmount },
      FinalPayment: finalPayment
    };
  }
};

export default getPaymentTableData;
