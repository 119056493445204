import { memo, useEffect, useState } from 'react';
import Select from 'react-select';
import i18n from '~/i18n';
import FilterItem from './FilterItem';
import ToggleSelect from '../ToggleSelect/ToggleSelect';
import { inject } from 'mobx-react';
import { AppStoreType } from '~/modules/Stock/types/Types';
import { useTranslation } from 'react-i18next';
import { trackDealershipMetricDateRangeSelect, trackDealershipMetricOriginSelect } from '~/tracking/avoTracking';
import styles from './chartFilters.module.scss';
import {
  ApplicationStatusMetricType,
  ChartVariant,
  ChartVariantType,
  FiltersStateType,
  LenderType,
  QuoteSource,
  QuoteSourceType,
  ReportConfigType
} from '~/components/Insights/types';
import { connect } from 'react-redux';
import { getEnabledDropdownOptions } from '~/components/Insights/services/utils/getEnabledDropdownOptions';
import ChartTypeFilter from '../ChartTypeFilter/ChartTypeFilter';
import { defaultSelectStyles, wideSelectStyles } from '../../services/helpers/defaultStyles';

interface ChartDataFiltersProps {
  reportConfig: ReportConfigType;
  options: {
    Lenders: LenderType[];
  };
  appStore?: AppStoreType;
  onFilterChange: (filters: FiltersStateType) => void;
}

const ChartDataFilters = memo(({ reportConfig, options, appStore, onFilterChange }: ChartDataFiltersProps) => {
  const { t } = useTranslation('Stock');
  const { lenderModules = [] } = appStore?.uiState || {};
  const {
    chartFilterConfig,
    metric,
    chartFilterConfig: { datePeriodConfig, lenderConfig, quoteSourceConfig, applicationStatusConfig, chartType }
  } = reportConfig;

  const {
    datePeriodOptions: { options: datePeriodOptions },
    quoteSourceOptions: { options: quoteSourceOptions },
    lenderOptions: { options: lenderOptions },
    applicationStatusOptions: { options: applicationStatusOptions }
  } = getEnabledDropdownOptions(chartFilterConfig, t, options?.Lenders, lenderModules);

  const [selectedDatePeriod, setSelectedDatePeriod] = useState(datePeriodConfig.default);
  const [selectedQuoteSource, setSelectedQuoteSource] = useState<QuoteSourceType[]>(quoteSourceConfig.default);
  const [selectedLender, setSelectedLender] = useState<string>(lenderConfig.default);
  const [selectedApplicationStatus, setSelectedApplicationStatus] = useState<ApplicationStatusMetricType>(
    applicationStatusConfig.default
  );
  const [selectedChartType, setSelectedChartType] = useState<ChartVariantType>(chartType);

  useEffect(() => {
    const chartFilters: FiltersStateType = {
      datePeriod: selectedDatePeriod,
      quoteSource: selectedQuoteSource,
      lender: selectedLender,
      applicationStatus: selectedApplicationStatus,
      chartType: chartFilterConfig.includeChartTypeFilter ? selectedChartType : ChartVariant.Line
    };
    onFilterChange(chartFilters);
  }, [
    selectedDatePeriod,
    selectedQuoteSource,
    selectedLender,
    selectedApplicationStatus,
    selectedChartType,
    chartFilterConfig.includeChartTypeFilter,
    onFilterChange
  ]);

  const handleChangeApplicationStatus = (applicationStatus: string[]) => {
    setSelectedApplicationStatus(applicationStatus[0] as ApplicationStatusMetricType);
  };

  const handleDatePeriodChange = (value: number) => {
    const selectOptions = datePeriodOptions.map((option) => ({
      value: option.value as number,
      label: option.label
    }));

    trackDealershipMetricDateRangeSelect({
      metricName: metric,
      valueFrom: selectedDatePeriod,
      valueTo: value,
      selectOptions
    });

    setSelectedDatePeriod(value);
  };

  const handleChangeLender = (lender: string[]) => {
    setSelectedLender(lender[0]);
  };

  const handleChangeQuoteSource = (quoteSource: QuoteSourceType[]) => {
    const changeQuoteSource = (value: QuoteSourceType[]) => {
      trackDealershipMetricOriginSelect({
        metricName: metric,
        metricOriginFrom: selectedQuoteSource,
        metricOriginTo: value
      });
      setSelectedQuoteSource(value);
    };
    // If "All" already is in the array and we're adding another source - when "All" is the first item
    if (quoteSource[0] === QuoteSource.Count && quoteSource.length > 1) {
      // Remove 'All' from the filters
      changeQuoteSource(quoteSource.filter((quoteSource: string) => quoteSource !== QuoteSource.Count));
      // If "All" is being added to the array when there are other origins already selected - when "All" is the last item
    } else if (quoteSource[quoteSource.length - 1] === QuoteSource.Count && quoteSource.length > 1) {
      // Leave only "All" in the filters
      changeQuoteSource(quoteSource.filter((quoteSource) => quoteSource === QuoteSource.Count));
      // If no filter is selected, default to "All"
    } else if (quoteSource.length === 0) {
      changeQuoteSource([QuoteSource.Count]);
    } else {
      // Set the selected origins
      changeQuoteSource(quoteSource);
    }
  };

  return (
    <>
      {chartFilterConfig.includeChartTypeFilter && (
        <ChartTypeFilter chartType={selectedChartType} onChange={setSelectedChartType} />
      )}
      <div className={styles['dropdown']}>
        {applicationStatusConfig.enabled && (
          <FilterItem label="Application Status">
            <ToggleSelect<string>
              onChange={handleChangeApplicationStatus}
              values={selectedApplicationStatus ? [selectedApplicationStatus] : []}
              options={applicationStatusOptions}
              singleSelection
            />
          </FilterItem>
        )}

        {quoteSourceConfig.enabled && (
          <FilterItem label="Quote Source">
            <ToggleSelect<QuoteSourceType>
              onChange={handleChangeQuoteSource}
              values={selectedQuoteSource}
              options={quoteSourceOptions}
            />
          </FilterItem>
        )}

        {datePeriodConfig.enabled && (
          <FilterItem label={t('ChartFilters.date_period')}>
            <Select
              options={datePeriodOptions}
              defaultValue={datePeriodOptions.find((option) => option.id === selectedDatePeriod)}
              styles={{ ...defaultSelectStyles }}
              onChange={(selectedOption) => {
                const id = selectedOption?.id;
                if (typeof id === 'number') {
                  handleDatePeriodChange(id);
                }
              }}
              key={i18n.language}
              isSearchable={false}
            />
          </FilterItem>
        )}
        {lenderConfig.enabled && (
          <FilterItem label="Lender">
            <ToggleSelect<string>
              onChange={handleChangeLender}
              values={selectedLender ? [selectedLender] : []}
              options={lenderOptions}
              singleSelection
              customStyles={{ ...defaultSelectStyles, ...wideSelectStyles }}
            />
          </FilterItem>
        )}
      </div>
    </>
  );
});

const mapStateToProps = (state: any) => ({
  options: state.options
});

export default inject('appStore')(connect(mapStateToProps)(ChartDataFilters));
