import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import EditApplicationAffordability from '../components/EditApplicationAffordability';
import * as applicationActions from '../../../redux/application/applicationActions';
import { getCustomer } from '../../../selectors/customerSelectors';
import { getLenderConfig } from '../../../core/lenders';
import { compose } from 'redux';
import { withParams } from 'hocs/router';
import { push } from '../../../routerHistory';

const EditApplicationAffordabilityContainer = (props) => {
  const { children, ...remainingProps } = props;

  return <EditApplicationAffordability {...remainingProps} />;
};

EditApplicationAffordabilityContainer.propTypes = {
  children: PropTypes.node
};

function mapStateToProps(state, ownProps) {
  const customer = getCustomer(state, ownProps.params.consumerId);
  return {
    consumer: customer,
    application: state.application,
    vehicle: _.find(customer.VehiclesOfInterest, { VehicleId: state.application.DealershipConsumerVehicleId }),
    validationRules: getLenderConfig(
      state.application.Quote && state.application.Quote.FunderCode,
      state.application.CustomerType,
      'validationRules'
    ),
    applicationFields: getLenderConfig(
      state.application.Quote && state.application.Quote.FunderCode,
      state.application.CustomerType,
      'applicationFields'
    )
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    submitApplicationSection: (formData) => {
      const { dealershipId, consumerId, applicantId } = ownProps.params;

      dispatch(
        applicationActions.submitApplicationSection(
          'AffordabilityDetails',
          applicantId,
          formData,
          `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit`
        )
      );
    },
    handleBack: () => {
      const { dealershipId, consumerId, applicantId } = ownProps.params;
      push(`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit`);
    }
  };
}

export default compose(withParams, connect(mapStateToProps, mapDispatchToProps))(EditApplicationAffordabilityContainer);
