import PropTypes, { InferProps } from 'prop-types';
import { useTranslation } from 'react-i18next';

import './quoteNotification.scss';
import icon from '../../../styles/icons/i.svg';
import Image from '../../Common/Image';

const QuoteNotification = ({ errorNumber, errorTitle, errorMessage = '' }: QuoteNotificationProps) => {
  const { t } = useTranslation('Quoting');

  const getErrorMessage = () => {
    if (errorNumber === 61) {
      return t(
        'QuoteNotification.the_product_is_above_the_maximum_flat_rate_lowering_the_scheme_rate_may_make_this_quote_available'
      );
    } else if (errorNumber === 64) {
      return t(
        'QuoteNotification.the_product_is_above_the_minimum_flat_rate_raising_the_scheme_rate_may_make_this_quote_available'
      );
    } else {
      return errorMessage;
    }
  };

  const message = getErrorMessage();

  return (
    <div className="quoteNotification">
      <div className="quoteNotification__image">
        <Image imageName={icon} />
      </div>

      <div>
        <div className="quoteNotification__title" role="heading" aria-level={1}>
          {errorTitle || t('QuoteNotification.this_quote_is_not_available')}
        </div>

        {!!message && (
          <p className="quoteNotification__message" role="definition">
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

const QuoteNotificationPropTypes = {
  errorNumber: PropTypes.number,
  errorTitle: PropTypes.string,
  errorMessage: PropTypes.string
};

type QuoteNotificationProps = InferProps<typeof QuoteNotificationPropTypes>;

QuoteNotification.propTypes = QuoteNotificationPropTypes;

export default QuoteNotification;
