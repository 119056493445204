export class FetchError extends Error {
  msg: string;
  info: string;
  status: number;
  constructor({ msg, info, status }: { msg: string; info: string; status: number }) {
    super(msg);
    this.msg = msg;
    this.info = info;
    this.status = status;
    this.name = 'FetchError';
  }
}

const isValidJSONResponse = async (response: Response) => {
  try {
    await response.clone().json();
    return true;
  } catch (e) {
    return false;
  }
};

const isValidTextResponse = async (response: Response) => {
  try {
    const text = await response.clone().text();
    return text.length > 0;
  } catch (e) {
    return false;
  }
};

const handleErrorResponse = async (response: Response): Promise<FetchError> => {
  if (await isValidJSONResponse(response)) {
    const json = await response.json();
    return new FetchError({
      msg: 'An error occurred during fetch request.',
      info: json.message || response.statusText,
      status: response.status
    });
  }

  if (await isValidTextResponse(response)) {
    const text = await response.text();
    return new FetchError({
      msg: 'An error occurred during fetch request.',
      info: text || response.statusText,
      status: response.status
    });
  }

  return new FetchError({
    msg: 'An error occurred during fetch request.',
    info: `Status: ${response.status} - an undefined Error occurred.` || response.statusText,
    status: response.status
  });
};

export default handleErrorResponse;
