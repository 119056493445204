export type ChartJsDataType = {
  datasets: {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
  }[];
  labels: string[];
};

export const ChartName = {
  ApplicationVolumes: 'ApplicationVolumes',
  ApplicationLoanValues: 'ApplicationLoanValues',
  AverageVehiclesInStock: 'AverageVehiclesInStock',
  VehicleSalesData: 'VehicleSalesData',
  OnlineQuotes: 'OnlineQuotes',
  AverageDaysInStock: 'AverageDaysInStock',
  EligibilityChecks: 'EligibilityChecks',
  SubmittedApplications: 'SubmittedApplications',
  SearchImpressions: 'SearchImpressions'
} as const;

export type ChartNameType = keyof typeof ChartName;

export const DataSet = {
  Sum: 'sum',
  Avg: 'avg'
} as const;
export type DataSetType = 'sum' | 'avg';

export const ChartDimensions = {
  Date: 'Date',
  Origin: 'Origin',
  Vehicle: 'Vehicle'
} as const;
export type ChartDimensionsType = keyof typeof ChartDimensions;

export const QuoteSource = {
  Count: 'count',
  Showroom: 'count showroom',
  Online: 'count online'
} as const;
export type QuoteSourceType = typeof QuoteSource[keyof typeof QuoteSource];
