import { toJS } from 'mobx';
import { hasCompanionQuotes } from '~/features';
import { findCompanionQuoteByType } from '../../QuoteCard/utils/getQuoteDisplaySchema';
import { getAllLoansDisplaySchema } from 'components/QuoteCard/utils/getQuoteDisplaySchema';
import { LoanTypeJs, QuoteCardVariantTypeJs } from '../../QuoteCard/types';

export const getVehicleLoanProductId = (quote) => {
  let quoteProductIdToUse = quote.ProductId;
  const isCombinedQuote = quote.VehicleLoan; // BNP

  // For multi-schedule quotes we want to use the product ID of the vehicle loan
  // because the aggregate quote doesn't have a valid product ID and therefore
  // it doesn't have product settings
  if (hasCompanionQuotes(quote)) {
    const VehicleLoan = findCompanionQuoteByType(quote.CompanionQuotes, LoanTypeJs.vehicleLoan);
    quoteProductIdToUse = VehicleLoan?.ProductId;
  }
  if (isCombinedQuote) {
    quoteProductIdToUse = quote.VehicleLoan.ProductId;
  }
  return quoteProductIdToUse;
};

export const capitaliseProperties = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(capitaliseProperties);
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce((newObj, key) => {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      newObj[capitalizedKey] = capitaliseProperties(obj[key]);
      return newObj;
    }, {});
  } else {
    return obj;
  }
};

export const getMultiLoans = (quote) => {
  const normalizedQuote = capitaliseProperties(toJS(quote));
  let multiLoans = null;

  if (hasCompanionQuotes(normalizedQuote)) {
    multiLoans = getAllLoansDisplaySchema({
      quote: normalizedQuote,
      options: { variant: QuoteCardVariantTypeJs.SendDeal, isBnpp: false }
    });
  }

  return multiLoans;
};

export const getAllVapsTotal = (quote) => {
  if (quote) {
    const value = quote.ValueAddedProducts.map((vap) => {
      return vap.price || vap.Price || 0;
    }).reduce((accumulator, current) => accumulator + parseFloat(current), 0);
    return typeof value === 'number' && !isNaN(value) ? value.toFixed(2) : null;
  }
  return null;
};

export const getAlteredCashDepositValue = (quote) => {
  if (typeof quote.WashoutNegativeEquityAmount === 'number' && !isNaN(quote.WashoutNegativeEquityAmount)) {
    return quote.WashoutNegativeEquityAmount;
  } else {
    return quote.TotalDeposit;
  }
};

export const shouldShowSingularFee = (quote) => {
  const { AcceptanceFee, AcceptanceFeeFirst, AcceptanceFeeLast, OptionToPurchaseFee } = quote;
  const fees = [AcceptanceFee, AcceptanceFeeFirst, AcceptanceFeeLast, OptionToPurchaseFee];
  const nonZeroFees = fees.filter((fee) => fee > 0);

  return nonZeroFees.length === 1;
};

export const getTotalFees = (quote) => {
  let totalFees = 0;

  if (quote.AcceptanceFeeLast > 0) {
    totalFees = quote.AcceptanceFeeFirst + quote.AcceptanceFeeLast + quote.OptionToPurchaseFee;
  } else if (quote.AcceptanceFee > 0) {
    totalFees = quote.AcceptanceFee + quote.OptionToPurchaseFee;
  } else if (quote.OptionToPurchaseFee > 0) {
    totalFees = quote.OptionToPurchaseFee;
  }

  return totalFees;
};
