import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Page from '../../Common/Page';
import TrackPageView from '../../Common/Tracking/TrackPageView';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import PanelContent from '../../Common/PanelContent';
import PanelHeader from '../../Common/PanelHeader';
import ReservationSetupForm from '../components/ReservationSetupForm';
import { withParams } from 'hocs/router';

@inject('appStore')
@observer
class ReserveNowSettings extends React.Component {
  render() {
    return (
      <Page>
        <TrackPageView dealershipId={this.props.params.dealershipId} />
        <Breadcrumbs
          items={[
            {
              name: this.props.t('ReserveNowSettings.home'),
              path: `/d/${this.props.params.dealershipId}`
            },
            {
              name: this.props.t('ReserveNowSettings.settings'),
              path: `/d/${this.props.params.dealershipId}/settings`
            },
            {
              name: this.props.t('ReserveNowSettings.vehicle_reservation')
            }
          ]}
        />
        <Panel>
          <PanelHeader>{this.props.t('ReserveNowSettings.vehicle_reservation')}</PanelHeader>
          <PanelContent>
            <ReservationSetupForm dealershipId={this.props.params.dealershipId} />
          </PanelContent>
        </Panel>
      </Page>
    );
  }
}

ReserveNowSettings.propTypes = {
  appStore: PropTypes.object,
  params: PropTypes.shape({
    dealershipId: PropTypes.string
  })
};
export default withTranslation('ReserveNow')(withParams(ReserveNowSettings));
