import React from 'react';
import PropTypes from 'prop-types';
import './assignLeadModalUserList.scss';

class AssignLeadModalUserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doesOverflow: false
    };
  }

  /*eslint-disable react/no-did-mount-set-state, react/no-did-update-set-state */
  componentDidMount() {
    if (this.refs.container && this.refs.container.clientHeight > 149) {
      this.setState({
        doesOverflow: true
      });
    } else {
      this.setState({
        doesOverflow: false
      });
    }
  }

  componentDidUpdate() {
    if (this.refs.container && this.refs.container.clientHeight > 149 && !this.state.doesOverflow) {
      this.setState({
        doesOverflow: true
      });
    } else if (this.refs.container && this.refs.container.clientHeight <= 149 && this.state.doesOverflow) {
      this.setState({
        doesOverflow: false
      });
    }
  }
  /*eslint-enable react/no-did-mount-set-state, react/no-did-update-set-state */

  render() {
    const childrenArray = React.Children.toArray(this.props.children);
    return (
      <ul className="assignLeadModalUserList" ref="container">
        {childrenArray.map((child, index) =>
          React.cloneElement(child, {
            isLast: index === childrenArray.length - 1,
            noBottomBorder: this.state.doesOverflow
          })
        )}
      </ul>
    );
  }
}

AssignLeadModalUserList.propTypes = {
  children: PropTypes.node
};

export default AssignLeadModalUserList;
