import { types } from '../schema';

export default [
  {
    AprOnly: types.Boolean,
    FinanceType: types.String,
    FunderCode: types.String,
    FunderProductCode: types.String,
    FunderProductUID: types.String,
    IvendiDealershipId: types.String,
    Name: types.String,
    ProductUid: types.String,
    QuotewareId: types.String,
    Settings: {
      CommissionType: types.String,
      Id: types.String,
      ProductId: types.String,
      Rate: types.String,
      Visible: types.Boolean
    },
    isAprProduct: types.Boolean
  }
];
