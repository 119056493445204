import React from 'react';
import PropTypes from 'prop-types';
import './tableRow.scss';
import classnames from 'classnames';

const TableRow = ({ children, isSelected, height }) => {
  let classes = classnames('tableRow__tr', isSelected && 'tableRow__selectedBorder', !isSelected && 'tableRow__border');

  if (height) {
    return (
      <tr className={classes} style={{ height: `${height}px` }}>
        {children}
      </tr>
    );
  }

  return <tr className={classes}>{children}</tr>;
};

TableRow.propTypes = {
  children: PropTypes.node,
  border: PropTypes.bool,
  isSelected: PropTypes.bool,
  height: PropTypes.number
};

TableRow.defaultProps = {
  border: true
};

export default TableRow;
