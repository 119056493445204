import platformApiUtils from '@ivendi/platform-api-utils';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQuery } from '@tanstack/react-query';
import { RepExample } from '~/types/repExample';

export function setRepExample(dealershipId: string, quoteId: string, repType: string) {
  return platformApiUtils.post(`v1/rep-example/${dealershipId}`, {
    QuoteId: quoteId,
    RepresentativeExampleType: repType
  });
}

export function fetchExistingRepExample(dealershipId: string) {
  return platformApiUtils.get<RepExample>(`v1/rep-example/${dealershipId}`);
}

export function reconfirmExistingRepExample(dealershipId: string) {
  return platformApiUtils.put(`v1/rep-example/${dealershipId}/reconfirm`);
}

export const SERVICE_PREFIX = 'rep-example';

export const repExampleQueries = createQueryKeys(SERVICE_PREFIX, {
  get: (dealershipId: string) => {
    return {
      queryKey: [dealershipId ?? ''],
      queryFn: () => {
        if (dealershipId) {
          return fetchExistingRepExample(dealershipId);
        }
      }
    };
  }
});

export const useRepExample = (dealershipId: string) => useQuery(repExampleQueries.get(dealershipId));

export const useReconfirmRepExample = (dealershipId: string, onSuccess: () => void) => {
  return useMutation({
    mutationFn: () => {
      return reconfirmExistingRepExample(dealershipId);
    },
    onSuccess
  });
};
