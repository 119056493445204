import produce from 'immer';
import defaultRules, { LenderCorporateConfig } from './default';

export default produce(defaultRules, (draft: LenderCorporateConfig) => {
  draft.applicationFields.OrganisationContacts!.CountryOfBirth = true;
  draft.applicationFields.OrganisationContacts!.Nationality = true;

  draft.validationRules!.OrganisationContacts!.Items![0]!.CountryOfBirth = 'required';
  draft.validationRules!.OrganisationContacts!.Items![0]!.Nationality = 'required';
});
