import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './panelContent.scss';

const PanelContent = ({ noPadding, children }) => {
  const contentStyles = classnames({
    panelContent: true,
    'panelContent--padding': !noPadding
  });
  return (
    <div className={contentStyles} data-th="PanelContent">
      {children}
    </div>
  );
};

PanelContent.propTypes = {
  children: PropTypes.node,
  noPadding: PropTypes.bool
};

PanelContent.defaultProps = {
  noPadding: false
};

export default PanelContent;
