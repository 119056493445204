import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import car from '../../styles/icons/car.svg';
import van from '../../styles/icons/lcv-placeholder.svg';
import bike from '../../styles/icons/bike-placeholder.svg';
import motorHome from '../../styles/icons/motorhome-placeholder.svg';
import './pushDealVehicleImage.scss';
import { VehicleClassJs } from '~/types/vehicle';

const getPlaceHolderByVehicleClass = (vehicleClass) => {
  let placeholder = car;

  if (vehicleClass === VehicleClassJs.lcv) {
    placeholder = van;
  } else if (vehicleClass === VehicleClassJs.motorbike) {
    placeholder = bike;
  } else if (vehicleClass === VehicleClassJs.motorhome) {
    placeholder = motorHome;
  } else if (vehicleClass === VehicleClassJs.touringCaravan) {
    placeholder = motorHome;
  }

  return placeholder;
};

const PushDealVehicleImage = ({ vehicleImageUrls, vehicleClass }) => {
  const { t } = useTranslation('PushDeal');
  const hasVehicleImage = vehicleImageUrls !== null && vehicleImageUrls.length > 0;
  const vehicleImageUrl = vehicleImageUrls.map((image) => image);
  const placeholder = getPlaceHolderByVehicleClass(vehicleClass);
  return hasVehicleImage ? (
    <div className="pushDealVehicleImage__outer">
      <img
        className="pushDealVehicleImage"
        src={vehicleImageUrl}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = placeholder;
        }}
        alt={t('PushDealVehicleImage.vehicle')}
      />
    </div>
  ) : (
    <div className="pushDealVehicleImage__outer--placeholder">
      <img className="pushDealVehicleImage--placeholder" src={placeholder} alt={t('PushDealVehicleImage.vehicle')} />
    </div>
  );
};

PushDealVehicleImage.propTypes = {
  vehicleImageUrls: PropTypes.array,
  vehicleClass: PropTypes.string
};
export default PushDealVehicleImage;
