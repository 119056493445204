import { watchCustomerContainer } from './customer/customerContainerSagas';
import { watchApplication } from './application/applicationSagas';
import { watchCustomerQuoteContainer } from './customerQuote/customerQuoteContainerSagas';
import { watchSendDeal } from './sendDeal/sendDealSagas';
import { watchEditCustomerContainer } from './editCustomer/editCustomerContainerSagas';
import { watchAddVehicleSuccess } from './customer/customer';
import { watchAddVehicleContainer } from './addVehicle/addVehicleSagas';
import {
  watchLatestDealsContainer,
  watchLatestDealsFilters,
  watchLatestDealsContainerUnload
} from './latestDeals/latestDealsSagas';
import { watchViewMonthlyPayments, watchChangePaymentTerm, watchAvoTrackedQuotingActions } from './quote/quotingSagas';

import { fork } from 'redux-saga/effects';

export function* rootSaga() {
  yield [
    fork(watchCustomerContainer),
    fork(watchApplication),
    fork(watchCustomerQuoteContainer),
    fork(watchSendDeal),
    fork(watchEditCustomerContainer),
    fork(watchAddVehicleSuccess),
    fork(watchAddVehicleContainer),
    fork(watchLatestDealsContainer),
    fork(watchLatestDealsContainerUnload),
    fork(watchLatestDealsFilters),
    fork(watchViewMonthlyPayments),
    fork(watchChangePaymentTerm),
    fork(watchAvoTrackedQuotingActions)
  ];
}
