import React from 'react';
import VehicleImage from '~Common/VehicleImage';

import './BroadcastStockListVehicleImage.scss';

const BroadcastStockListVehicleImage = ({
  imgHr,
  vehicleClass,
  imgParams = 'width=200&height=140&scale=both&bgcolor=#000000'
}) => {

  if (imgHr) {
    const imageSource = `${imgHr}?${imgParams}`
    return (
      <>
        <VehicleImage
          vehicleClass={vehicleClass}
          iconFontSize="100px"
          iconMargin="25px auto"
          imageSource={imageSource}
        />
      </>
    );
  }

  return (
    <div className="stockListVehicleImage__placeholder">
      <VehicleImage vehicleClass={vehicleClass} iconFontSize="100px" iconMargin="25px auto" />
    </div>
  );
};

export default BroadcastStockListVehicleImage;
