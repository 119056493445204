import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Icon from '../Common/Icon/Icon';
import LineClamp from '../Dashboard/components/panel/LineClamp';
import { Link } from 'react-router-dom';
import './pushDealSentDetails.scss';

const PushDealSentDetails = ({ t, consumer, currentDeal, responsibleUserName, customerId, appStore, vehicleId }) => {
  return (
    <div className="pushDealSentDetails" data-th="PushDealSentDetails">
      <div className="pushDealSentDetails__item">
        <div className="pushDealSentDetails__icon">
          <Icon name="dealership-user" />
        </div>
        <div className="pushDealSentDetails__info">
          <div className="pushDealSentDetails__text">
            <div className="pushDealSentDetails__infoTitle">{t('PushDealSentDetails.sent_by')}</div>
            <div className="pushDealSentDetails__infoName">{responsibleUserName}</div>
          </div>
          <div title={currentDeal.responsibleUserName} className="pushDealSentDetails__infoEmail">
            <LineClamp>{currentDeal.responsibleUserName}</LineClamp>
          </div>
        </div>
      </div>
      <div className="pushDealSentDetails__arrow">
        <div className="pushDealSentDetails__arrowTop" />
        <div className="pushDealSentDetails__arrowBottom" />
      </div>
      <div className="pushDealSentDetails__item">
        <div className="pushDealSentDetails__icon">
          <Icon name="consumer" />
        </div>
        <div className="pushDealSentDetails__info">
          <div className="pushDealSentDetails__text">
            <div className="pushDealSentDetails__infoTitle">{t('PushDealSentDetails.sent_to')}</div>
            <div className="pushDealSentDetails__infoName">
              <Link
                className="pushDealSentDetails__customerName"
                to={{
                  pathname: `/d/${currentDeal.dealershipId}/consumers/${customerId}`,
                  search: `?vehicleId=${vehicleId}`
                }}
              >
                {consumer.Firstname + ' ' + consumer.Surname}
              </Link>
            </div>
          </div>
          <div
            title={appStore.pushDealStore.customerPushDeals.customerEmail}
            className="pushDealSentDetails__infoEmail"
          >
            <LineClamp>{appStore.pushDealStore.customerPushDeals.customerEmail}</LineClamp>
          </div>
        </div>
      </div>
    </div>
  );
};

PushDealSentDetails.propTypes = {
  appStore: PropTypes.object,
  consumer: PropTypes.object,
  currentDeal: PropTypes.object
};
export default withTranslation('PushDeal')(inject(['appStore'])(observer(PushDealSentDetails)));
