import React from 'react';
import PropTypes from 'prop-types';
import './table.scss';

const TableBody = ({ children, ...props }) => (
  <tbody {...props} className="table__tbody">
    {children}
  </tbody>
);

TableBody.propTypes = {
  children: PropTypes.node
};

export default TableBody;
