import { createQueryKeys } from '@lukemorales/query-key-factory';
// import ms from 'ms';
import { lookupByEmail } from '.';

export const SERVICE_PREFIX = 'lookup';

export const lookupQueries = createQueryKeys(SERVICE_PREFIX, {
  byEmail: ({ email, dealershipId, sector }: Parameters<typeof lookupByEmail>['0']) => ({
    queryKey: [SERVICE_PREFIX, { email, dealershipId, sector }],
    queryFn: () => lookupByEmail({ email, dealershipId, sector })
    // These are currently ignored by the factory https://github.com/lukemorales/query-key-factory/issues/85
    // keepPreviousData: true,
    // cacheTime: ms('5m'),
    // staleTime: ms('5m')
  })
});
