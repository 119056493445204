import React from 'react';
import PropTypes from 'prop-types';
import './outerRight.scss';

const OuterRight = ({ children }) => {
  return (
    <div className="outerRight" key="OuterRight">
      {children}
    </div>
  );
};

OuterRight.propTypes = {
  children: PropTypes.node
};

export default OuterRight;
