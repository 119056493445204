import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './editQuoteCommissionsForm.scss';
import Button from '../../Common/Button/Button';
import Validator from '../../../validators/Validator';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import SelectInput from '../../Common/Form/Select';
import CurrencyInput from '../../Common/Form/CurrencyInput';
import PercentageInput from '../../Common/Form/PercentageInput';
import { reaction } from 'mobx';
import RadioButton from '../../Common/Form/RadioButton';
import ExclamationWarning from '../../Common/ExclamationWarning';

class EditQuoteCommissionsForm extends React.Component {
  constructor() {
    super();
    this.state = {
      formData: {
        CommissionType: '',
        Rate: ''
      }
    };
    this.setupValidation({
      CommissionType: 'required',
      Rate: 'required, number'
    });
  }

  setupValidation(validationRules) {
    this.validator = new Validator();
    this.validator.setRules(validationRules);
    this.validationReactionDisposer = reaction(() => ({ ...this.state.formData }), this.validator.validate, {
      fireImmediately: true
    });
  }

  UNSAFE_componentWillMount() {
    let state = Object.assign(this.props.defaultValues, {
      applyTo: 'quote',
      formData: {
        CommissionType: '',
        Rate: ''
      }
    });

    if (this.props.defaultValues.AprOnly) {
      state.CommissionType = 'APR';
    }

    this.setState({
      formData: { ...state }
    });
    this.validator.validate({ ...state });
  }

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  onSubmit = () => {
    this.validator.validate(this.state.formData);

    if (!this.validator.errorCount) {
      this.props.onSubmit(this.state.formData);
    }
  };
  handleCancel = (e) => {
    e.preventDefault();
    this.props.onCancel();
  };
  setCommission = (e) => {
    let formData = this.state.formData;
    formData.CommissionType = e.currentTarget.value;
    this.setState({
      formData
    });
    this.validator.validate(formData);
  };
  setRate = (Rate) => {
    let formData = this.state.formData;
    formData.Rate = Rate;
    this.setState({
      formData
    });
    this.validator.validate(formData);
  };
  setRatePercentage = (id, Rate) => {
    this.setRate(Rate);
  };
  setUpdateAllQuotes = (e) => {
    let formData = this.state.formData;
    formData.applyTo = e.target.value;
    this.setState({
      formData
    });
  };

  render() {
    let showCurrency = (this.state.formData && this.state.formData.CommissionType) === 'CommissionFixed';
    let errors = this.validator.getErrors();
    return (
      <MobxForm onSubmit={this.onSubmit} FocusOnFirstElement>
        <h2 className="editQuoteCommissionsForm__modalHeader">
          {this.props.t('EditQuoteCommissionsForm.edit_schemes')}
        </h2>

        {this.props.hasCheckedEligibility && (
          <ExclamationWarning
            message={this.props.t(
              'EditQuoteCommissionsForm.changing_the_scheme_details_may_affect_the_match_rates_returned_for_one_or_more_of_the_quotes'
            )}
          />
        )}
        <div className="editQuoteCommissionsForm__divider" />

        <MobxFieldGroup isInline htmlFor="CommissionType" error={errors.CommissionType}>
          <MobxFormLabel>{this.props.t('EditQuoteCommissionsForm.commission_type')}</MobxFormLabel>
          <SelectInput
            options="CommissionType"
            value={this.state.formData.CommissionType}
            onChange={this.setCommission}
            disabled={this.props.defaultValues.AprOnly}
          />
        </MobxFieldGroup>

        {showCurrency ? (
          <MobxFieldGroup isInline htmlFor="Rate" error={errors.Rate}>
            <MobxFormLabel>{this.props.t('EditQuoteCommissionsForm.rate')}</MobxFormLabel>
            <CurrencyInput value={this.state.formData.Rate} onChange={this.setRate} />
          </MobxFieldGroup>
        ) : (
          <MobxFieldGroup isInline htmlFor="Rate" error={errors.Rate}>
            <MobxFormLabel>{this.props.t('EditQuoteCommissionsForm.rate')}</MobxFormLabel>
            <PercentageInput value={this.state.formData.Rate} onChange={this.setRatePercentage} />
          </MobxFieldGroup>
        )}

        <div className="editQuoteCommissionsForm__applyTo">
          <div className="editQuoteCommissionsForm__radioButton">
            <RadioButton
              name="Update This Quote Only"
              onChange={this.setUpdateAllQuotes}
              value="quote"
              checked={this.state.formData.applyTo === 'quote'}
              id="applyToQuote"
            >
              {this.props.t('EditQuoteCommissionsForm.update_this_quote_only')}
            </RadioButton>
          </div>
          <div>
            <RadioButton
              name="Update All Quotes"
              onChange={this.setUpdateAllQuotes}
              value="all"
              checked={this.state.formData.applyTo === 'all'}
              id="applyToAll"
            >
              {this.props.t('EditQuoteCommissionsForm.update_all_applicable_quotes')}
            </RadioButton>
          </div>
        </div>

        <div className="editQuoteCommissionsForm__divider" />
        <div className="editQuoteCommissionsForm__formFooter">
          <div className="editQuoteCommissionsForm__applyButton" key="applyButton">
            <Button
              type="submit"
              isLoading={this.props.submittingState === 'loading'}
              hasError={this.props.submittingState === 'error'}
              buttonStyle="secondary"
            >
              {this.props.t('EditQuoteCommissionsForm.apply')}
            </Button>
          </div>
          <div className="editQuoteCommissionsForm__cancelButton">
            <Button type="button" buttonStyle="cancel" onClick={this.handleCancel}>
              {this.props.t('EditQuoteCommissionsForm.cancel')}
            </Button>
          </div>
        </div>
      </MobxForm>
    );
  }
}

EditQuoteCommissionsForm.propTypes = {
  options: PropTypes.object,
  onCancel: PropTypes.func,
  hasCheckedEligibility: PropTypes.bool,
  aprOnly: PropTypes.bool
};
export default withTranslation('Quoting')(EditQuoteCommissionsForm);
