import { withTranslation } from 'react-i18next';
import React from 'react';
import './vehicleReservations.scss';
import PropTypes from 'prop-types';
import VehicleReservationItem from './VehicleReservationItem';
import { inject, observer } from 'mobx-react';
import DashboardPanelNoItems from '../../Dashboard/components/panel/DashboardPanelNoItems';
import { splitCamelCaseToString } from '../../../core/helpers';

const VehicleReservations = ({ dealershipId, appStore, reservationStatus, t }) => {
  const data = appStore.reserveNowStore.reservedData;
  const reservationStatusToString = splitCamelCaseToString(reservationStatus);
  return (
    <div data-th="VehicleReservations">
      {data && data.reservations && data.reservations.length > 0 ? (
        <div className="vehicleReservations">
          {data &&
            data.reservations.map((item, i) => (
              <VehicleReservationItem data={item} key={i} dealershipId={dealershipId} />
            ))}
        </div>
      ) : (
        <DashboardPanelNoItems>
          {t('VehicleReservations.no_status_reservations_found', { reservationStatusToString })}
        </DashboardPanelNoItems>
      )}
    </div>
  );
};

VehicleReservations.propTypes = {
  dealershipId: PropTypes.string,
  appStore: PropTypes.object,
  reservationStatus: PropTypes.string
};
export default withTranslation('ReserveNow')(inject(['appStore'])(observer(VehicleReservations)));
