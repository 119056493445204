import React from 'react';
import PropTypes from 'prop-types';
import './informationWarning.scss';
import Icon from './Icon/Icon';

const InformationWarning = ({ text, children }) => (
  <div className="informationWarning">
    <div className="informationWarning__icon">
      <Icon name="information" />
    </div>
    <div className="informationWarning__textOuter">
      <div className="informationWarning__text">{text}</div>
      <div className="informationWarning__children">{children}</div>
    </div>
  </div>
);

InformationWarning.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node
};

export default InformationWarning;
