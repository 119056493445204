import { Trans, withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Page from '../Common/Page';
import Breadcrumbs from '../Common/Breadcrumbs';
import Panel from '../Common/Panel';
import PanelHeader from '../Common/PanelHeader';
import PanelToolBar from '../Common/PanelToolBar';
import Button from '../Common/Button/Button';
import './valueAddedProductSettings.scss';
import ValueAddedProductsTableRow from './ValueAddedProductsTableRow';
import Table from '../Common/Table/Table';
import TableBody from '../Common/Table/TableBody';
import '../Common/AlignRight';
import Th from '../Common/Th';
import LoadingSpinnerTailSpin from '../Common/Loading/LoadingSpinnerTailSpin';
import _ from 'lodash';
import { observer, inject } from 'mobx-react';
import Modal from '../Common/Modal/Modal';
import { withParams } from 'hocs/router';

class ValueAddedProductSettings extends React.Component {
  constructor() {
    super();
    this.state = {
      sortBy: 'ProductTypeText',
      sortDirection: 'asc',
      isModalOpen: false,
      vapIdToDelete: null
    };
  }

  closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };
  onDelete = (vapId) => {
    return () => {
      this.setState({
        isModalOpen: true,
        vapIdToDelete: vapId
      });
      this.vapNameToDelete = _.find(this.props.appStore.vapStore.valueAddedProducts, (p) => p.Id === vapId).Name;
    };
  };
  onConfirmDelete = () => {
    this.props.appStore.vapStore.onDeleteProduct(this.props.params.dealershipId, this.state.vapIdToDelete).then(() => {
      this.setState({
        isModalOpen: false
      });
    });
  };

  sortBy(sortBy, defaultSortDirection) {
    return () => {
      let sortDirection = defaultSortDirection || 'asc';

      if (sortBy === this.state.sortBy) {
        sortDirection = this.state.sortDirection === 'asc' ? 'desc' : 'asc';
      }

      this.setState({
        sortBy,
        sortDirection
      });
    };
  }

  getArrowDirection(fieldName) {
    return this.state.sortBy === fieldName ? this.state.sortDirection : '';
  }

  render() {
    let vapStore = this.props.appStore.vapStore;
    let products = this.props.appStore.vapStore.valueAddedProducts;
    let canEditVaps = this.props.appStore.uiState.canEditVaps;
    const { hasOrders } = this.props.appStore.uiState;
    const nameLabel = hasOrders
      ? this.props.t('ValueAddedProductSettings.public_name')
      : this.props.t('ValueAddedProductSettings.name');

    if (this.state.sortBy === 'ProductTypeText') {
      products = _.orderBy(products, [this.state.sortBy, 'Name'], [this.state.sortDirection, 'asc']);
    } else {
      products = _.orderBy(products, this.state.sortBy);

      if (this.state.sortDirection === 'desc') {
        products.reverse();
      }
    }

    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: this.props.t('ValueAddedProductSettings.home'),
              path: `/d/${this.props.params.dealershipId}`
            },
            {
              name: this.props.t('ValueAddedProductSettings.settings'),
              path: `/d/${this.props.params.dealershipId}/settings`
            },
            {
              name: this.props.t('ValueAddedProductSettings.value_added_products'),
              path: `/d/${this.props.params.dealershipId}/settings/vaps`
            }
          ]}
        />

        <Panel>
          <PanelHeader>{this.props.t('ValueAddedProductSettings.value_added_products')}</PanelHeader>
          <PanelToolBar>
            <div className="valueAddedProductSettings__toolbarRight alignRight">
              {(vapStore.updateVisibilityRequest.isSuccessful || vapStore.updateDefaultRequest.isSuccessful) && (
                <div className="valueAddedProductSettings__toolbarSaved">
                  {this.props.t('ValueAddedProductSettings.changes_saved')}
                </div>
              )}
              {(vapStore.updateVisibilityRequest.hasError || vapStore.updateDefaultRequest.hasError) && (
                <div className="valueAddedProductSettings__toolbarSavingError">
                  {this.props.t('ValueAddedProductSettings.there_was_an_error_saving_your_changes')}
                </div>
              )}
              {canEditVaps && (
                <div className="valueAddedProductSettings__addNewVapButton">
                  <Button to={`/d/${this.props.params.dealershipId}/settings/vaps/add`}>
                    {this.props.t('ValueAddedProductSettings.add_new_vap')}
                  </Button>
                </div>
              )}
            </div>
          </PanelToolBar>
          <div className="valueAddedProductSettings__contentWrapper">
            <div className="valueAddedProductSettings__content">
              <Table>
                <thead>
                  <tr className="valueAddedProductSettings__headerRow">
                    <Th
                      className="valueAddedProductSettings__th"
                      onClick={this.sortBy('ProductTypeText')}
                      arrowDirection={this.getArrowDirection('ProductTypeText')}
                      notBold
                    >
                      {this.props.t('ValueAddedProductSettings.type')}
                    </Th>
                    <Th
                      className="valueAddedProductSettings__th"
                      onClick={this.sortBy('Name')}
                      arrowDirection={this.getArrowDirection('Name')}
                      notBold
                    >
                      {nameLabel}
                    </Th>
                    <Th
                      className="valueAddedProductSettings__th"
                      onClick={this.sortBy('Description')}
                      arrowDirection={this.getArrowDirection('Description')}
                      notBold
                    >
                      {this.props.t('ValueAddedProductSettings.description')}
                    </Th>
                    <Th
                      className="valueAddedProductSettings__th"
                      onClick={this.sortBy('Price')}
                      arrowDirection={this.getArrowDirection('Price')}
                      notBold
                    >
                      {this.props.t('ValueAddedProductSettings.gross_price')}
                    </Th>
                    <Th
                      className="valueAddedProductSettings__th"
                      onClick={this.sortBy('TaxTypeText')}
                      arrowDirection={this.getArrowDirection('TaxTypeText')}
                      notBold
                    >
                      {this.props.t('ValueAddedProductSettings.tax_type')}
                    </Th>
                    <Th
                      className="valueAddedProductSettings__th"
                      onClick={this.sortBy('Visible')}
                      arrowDirection={this.getArrowDirection('Visible')}
                      notBold
                    >
                      {this.props.t('ValueAddedProductSettings.visible')}
                    </Th>
                    <Th
                      className="valueAddedProductSettings__th"
                      onClick={this.sortBy('Default')}
                      arrowDirection={this.getArrowDirection('Default')}
                      notBold
                    >
                      {this.props.t('ValueAddedProductSettings.default')}
                    </Th>
                    {canEditVaps && (
                      <Th className="valueAddedProductSettings__th" notBold>
                        {this.props.t('ValueAddedProductSettings.edit')}
                      </Th>
                    )}
                    {canEditVaps && (
                      <Th className="valueAddedProductSettings__th" notBold>
                        {this.props.t('ValueAddedProductSettings.delete')}
                      </Th>
                    )}
                  </tr>
                </thead>
                {vapStore.fetchVapsRequest.isLoading && (
                  <TableBody>
                    <tr>
                      <td className="valueAddedProductSettings__loadingSpinner" colSpan={canEditVaps ? 9 : 7}>
                        <LoadingSpinnerTailSpin />
                      </td>
                    </tr>
                  </TableBody>
                )}
                {!vapStore.fetchVapsRequest.isLoading && vapStore.fetchVapsRequest.hasError && (
                  <TableBody>
                    <tr>
                      <td colSpan={7} className="valueAddedProductSettings__errorMessage">
                        {this.props.t('ValueAddedProductSettings.error_occurred_try_later')}
                      </td>
                    </tr>
                  </TableBody>
                )}
                {!vapStore.fetchVapsRequest.isLoading &&
                  vapStore.fetchVapsRequest.isSuccessful &&
                  vapStore.valueAddedProducts.length > 0 && (
                    <TableBody>
                      {products.map((vap, index) => (
                        <ValueAddedProductsTableRow
                          product={vap}
                          key={index}
                          editRoute={`/d/${this.props.params.dealershipId}/settings/vaps/edit/${vap.Id}`}
                          onDelete={this.onDelete}
                          canEditVaps={canEditVaps}
                          setVisibility={vapStore.setVisibility}
                          setDefault={vapStore.setDefault}
                          dealershipId={this.props.params.dealershipId}
                        />
                      ))}
                    </TableBody>
                  )}
                {!vapStore.fetchVapsRequest.isLoading &&
                  vapStore.fetchVapsRequest.isSuccessful &&
                  vapStore.valueAddedProducts.length < 1 && (
                    <TableBody>
                      <tr>
                        <td colSpan={canEditVaps ? 9 : 7} className="valueAddedProductSettings__emptyTableMessage">
                          {this.props.t(
                            'ValueAddedProductSettings.currently_dont_have_vaps_set_up_for_your_dealership'
                          )}
                        </td>
                      </tr>
                      {canEditVaps && (
                        <tr>
                          <td colSpan={9} className="valueAddedProductSettings__emptyTableMessage">
                            <div className="valueAddedProductSettings__emptyTableMessageButton">
                              <Button to={`/d/${this.props.params.dealershipId}/settings/vaps/add`}>
                                {this.props.t('ValueAddedProductSettings.add_new_vap')}
                              </Button>
                            </div>
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  )}
              </Table>
            </div>
          </div>
        </Panel>
        <Modal isOpen={this.state.isModalOpen} onClose={this.closeModal}>
          <div className="valueAddedProductSettings__modalText">
            <Trans
              ns="ValueAddedProductSettings"
              i18nKey={'ValueAddedProductSettings.sure_you_want_to_delete_this_vap'}
              values={{ selectedVap: this.vapNameToDelete }}
            />
          </div>
          {vapStore.removeProductRequest.hasError && !vapStore.removeProductRequest.isLoading && (
            <div className="valueAddedProductSettings__modalError">
              {this.props.t('ValueAddedProductSettings.error_delete_this_vap_try_later')}
            </div>
          )}
          <div className="valueAddedProductSettings__modalButtons">
            <div className="valueAddedProductSettings__modalButton">
              <Button onClick={this.closeModal} buttonStyle="cancel" id="cancelDelete">
                {this.props.t('ValueAddedProductSettings.cancel')}
              </Button>
            </div>
            <div className="valueAddedProductSettings__modalButton">
              <Button
                onClick={this.onConfirmDelete}
                isLoading={vapStore.removeProductRequest.isLoading}
                hasError={vapStore.removeProductRequest.hasError}
                id="confirmDelete"
              >
                {this.props.t('ValueAddedProductSettings.delete_vap')}
              </Button>
            </div>
          </div>
        </Modal>
      </Page>
    );
  }
}

ValueAddedProductSettings.propTypes = {
  params: PropTypes.object,
  appStore: PropTypes.object
};
export default withParams(
  withTranslation('ValueAddedProductSettings')(inject('appStore')(observer(ValueAddedProductSettings)))
);
