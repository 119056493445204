import React from 'react';
import PropTypes from 'prop-types';
import './groupLead.scss';
import DateFormat from '../../Common/DateFormat';
const GroupLead = ({ item }) => (
  <div className="groupLead__leads">
    <div className="groupLead__dealerName">{item.OriginalDealership.Name}</div>
    <div className="groupLead__createdSource">
      {!!item.CreatedSource && (
        <div>
          {item.CreatedSource} <DateFormat value={item.Created || item.CreatedDate} format="DD/MM/YY" parse="utc" />
        </div>
      )}
    </div>
  </div>
);

GroupLead.propTypes = {
  item: PropTypes.object
};

export default GroupLead;
