import React from 'react';
import './platformUpdatesIndicator.scss';

const PlatformUpdatesIndicator = () => {
  return (
    <div className="platformUpdatesIndicator">
      <div className="platformUpdatesIndicator__outer" />
      <div className="platformUpdatesIndicator__inner" />
    </div>
  );
};

export default PlatformUpdatesIndicator;
