import React from 'react';
import PropTypes from 'prop-types';
import './inputTooltip.scss';
import classnames from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import cross from '../../styles/icons/close-red-square.svg';
import { useTranslation } from 'react-i18next';

const InputTooltip = (props) => {
  const { t } = useTranslation('Common');

  const tooltipStyles = classnames(
    'inputTooltip',
    props.show && 'inputTooltip--isVisible',
    props.inline && 'inputTooltip--inline'
  );

  return (
    <TransitionGroup>
      {props.show ? (
        <CSSTransition key="inputTooltipAnim" classNames="inputTooltip-animation" timeout={{ enter: 0, exit: 400 }}>
          <div className={tooltipStyles}>
            <div className="inputTooltip__inner">
              {props.handleClick ? (
                <button className="inputTooltip__cross" onClick={props.handleClick}>
                  <img className="inputTooltip__crossImage" src={cross} aria-label={t('InputTooltip.close')} />
                </button>
              ) : (
                ''
              )}
              {props.children}
            </div>
            <div className="inputTooltip__triangle" />
          </div>
        </CSSTransition>
      ) : null}
    </TransitionGroup>
  );
};

InputTooltip.propTypes = {
  children: PropTypes.node,
  handleClick: PropTypes.func,
  inline: PropTypes.bool,
  show: PropTypes.bool
};

export default InputTooltip;
