import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Common/Icon/Icon';
import './activityTimelineItem.scss';

class ActivityTimelineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContent: props.showContent
    };
  }
  handleClick = () => {
    this.setState((prevState) => ({
      showContent: !prevState.showContent
    }));
  };

  render() {
    const { id, title, children, isOrder, showInProgress } = this.props;
    const { showContent } = this.state;

    const arrowClassname = classnames(
      'activityTimelineItem__headerArrow',
      showContent && 'activityTimelineItem__headerArrow--up'
    );
    const contentClassname = classnames(
      'activityTimelineItem__content',
      showContent && 'activityTimelineItem__content--open'
    );

    return (
      <div className="activityTimelineItem">
        <div className="activityTimelineItem__header" onClick={this.handleClick}>
          {isOrder ? (
            showInProgress ? (
              <div className="activityTimelineItem__headerIconOuter--inProgress">
                <div className="activityTimelineItem__headerIconInner--inProgress">
                  <Icon name="inprogress" />
                </div>
              </div>
            ) : (
              <div className="activityTimelineItem__headerIconOuter--orderSummary">
                <div className="activityTimelineItem__headerIconInner--orderSummary">
                  <Icon name="tick" />
                </div>
              </div>
            )
          ) : (
            <div className="activityTimelineItem__headerIconOuter">
              <div className="activityTimelineItem__headerIconInner">
                <Icon name="validation-tick" />
              </div>
            </div>
          )}
          <div className="activityTimelineItem__headerTitle">{title}</div>
          <div className={arrowClassname}>
            <Icon name="down" />
          </div>
        </div>
        <div id={`activityTimelineItem__content--${id}`} className={contentClassname}>
          {children}
        </div>
        <div className="activityTimelineItem__line" />
      </div>
    );
  }
}

ActivityTimelineItem.defaultProps = {
  showContent: false,
  showInProgress: false,
  isOrder: false
};

ActivityTimelineItem.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any
};

export default ActivityTimelineItem;
