import { ApplicationProgress } from './ApplicationNavigation';

type ApplicationNavigationItemsProps = {
  dealershipId: string | undefined;
  consumerId: string | undefined;
  applicantId: string | undefined;
  progress: ApplicationProgress;
  t: any;
};
export const getCorporateCreateNavigationItems = ({
  dealershipId,
  consumerId,
  applicantId,
  progress,
  t
}: ApplicationNavigationItemsProps) => [
  {
    id: 'personal_details',
    label: t('ApplicationNavigation.business_details'),
    image: 'dealership',
    link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/businessdetails`,
    progress: progress.businessDetails
  },
  {
    id: 'address',
    label: t('ApplicationNavigation.business_address'),
    image: 'location',
    link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/businessaddress`,
    progress: progress.addressHistory
  },
  {
    id: 'employment',
    label: t('ApplicationNavigation.organisation_contacts'),
    image: 'customers',
    link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/organisationcontacts`,
    progress: progress.organisationContacts
  },
  {
    id: 'bank',
    label: t('ApplicationNavigation.bank_details'),
    image: 'bank',
    link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/bankdetails`,
    progress: progress.bank
  },
  {
    id: 'summary',
    label: t('ApplicationNavigation.summary'),
    image: 'summary',
    link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/applicationsummary`,
    progress: progress.total
  }
];

export const getPersonalCreateNavigationItems = ({
  dealershipId,
  consumerId,
  applicantId,
  progress,
  t
}: ApplicationNavigationItemsProps) => [
  {
    id: 'personal_details',
    label: t('ApplicationNavigation.personal_details'),
    image: 'consumer',
    link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/personaldetails`,
    progress: progress.personal
  },
  {
    id: 'address',
    label: t('ApplicationNavigation.address'),
    image: 'location',
    link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/addresshistory`,
    progress: progress.address
  },
  {
    id: 'employment',
    label: t('ApplicationNavigation.employment'),
    image: 'employment',
    link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/employmenthistory`,
    progress: progress.employment
  },
  {
    id: 'bank',
    label: t('ApplicationNavigation.bank_details'),
    image: 'bank',
    link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/bankdetails`,
    progress: progress.bank
  },
  {
    id: 'bank',
    label: t('ApplicationNavigation.affordability'),
    image: 'affordability',
    link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/affordability`,
    progress: progress.affordability
  },
  {
    id: 'summary',
    label: t('ApplicationNavigation.summary'),
    image: 'summary',
    link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/applicationsummary`,
    progress: progress.total
  }
];
