import Table from '../../../components/Common/Table/Table';
import TableHeader from '../../../components/Common/Table/TableHeader';
import TableCell from '../../../components/Common/Table/TableCell';
import Checkbox from '../../../components/Common/Form/Checkbox';
import Button from '../../../components/Common/Button/Button';
import TableBody from '../../../components/Common/Table/TableBody';
import TableRow from '../../../components/Common/Table/TableRow';
import ToggleSwitch from '../../../components/Common/ToggleSwitch';
import React from 'react';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import IconButton from '../../../modules/Stock/containers/IconButton';
import TableHead from '../../Reporting/PartialSummarySections/SummaryTable/TableHead';

import classes from './BroadcastTable.module.scss';

const BroadcastTable = ({ data, selected, handleOnSelectAll, handleOnSelect, handleEditClick, handleUpdateState }) => {
  const { t } = useTranslation('BroadcastSettings');

  // function that turns filter data into readable pipe delimited string.
  const renderFilters = (broadcast) => {
    const filters = [];

    if (broadcast.classFilter) {
      if (!!broadcast.classFilter.includeCar) filters.push(t('BroadcastSettings.car'));
      if (!!broadcast.classFilter.includeMotorbike) filters.push(t('BroadcastSettings.bike'));
      if (!!broadcast.classFilter.includeLcv) filters.push(t('BroadcastSettings.lcv'));
      if (!!broadcast.classFilter.includeMotorhome) filters.push(t('BroadcastSettings.motorhome'));
    }
    if (broadcast.hasVehicleFilter) filters.push(t('BroadcastSettings.vehicleFilter'));
    if (broadcast.ageFilter) {
      if (broadcast.ageFilter.min !== null && broadcast.ageFilter.max !== null) {
        filters.push(
          t('BroadcastSettings.age_between', { min: broadcast.ageFilter.min, max: broadcast.ageFilter.max })
        );
      } else {
        if (broadcast.ageFilter.min !== null)
          filters.push(t('BroadcastSettings.age_filter_min', { value: broadcast.ageFilter.min }));
        if (broadcast.ageFilter.max !== null)
          filters.push(t('BroadcastSettings.age_filter_max', { value: broadcast.ageFilter.max }));
      }
    }
    if (broadcast.priceFilter) {
      if (broadcast.priceFilter.min !== null && broadcast.priceFilter.max !== null) {
        filters.push(
          t('BroadcastSettings.price_between', {
            min: broadcast.priceFilter.min,
            max: broadcast.priceFilter.max
          })
        );
      } else {
        if (broadcast.priceFilter.min !== null)
          filters.push(t('BroadcastSettings.price_filter_min', { value: broadcast.priceFilter.min }));
        if (broadcast.priceFilter.max !== null)
          filters.push(t('BroadcastSettings.price_filter_max', { value: broadcast.priceFilter.max }));
      }
    }

    if (filters.length === 0) return t('BroadcastSettings.no_filters');

    return `${((broadcast.classFilter &&
      (broadcast.classFilter.includeCar ||
        broadcast.classFilter.includeMotorbike ||
        broadcast.classFilter.includeLcv ||
        broadcast.classFilter.includeMotorhome)) ||
      (broadcast.ageFilter && (broadcast.ageFilter.min !== null || broadcast.ageFilter.max !== null)) ||
      (broadcast.priceFilter && (broadcast.priceFilter.min !== null || broadcast.priceFilter.max !== null)) ||
      broadcast.hasVehicleFilter) &&
      t('BroadcastSettings.filter_by')}${filters.join(' | ')}`;
  };

  const renderTable = (isMobile = false) => {
    return (
      <Table>
        <TableHeader>
          <TableHead>
            <Checkbox value={selected.length === data.length} onChange={handleOnSelectAll} />
          </TableHead>
          <TableHead> {t('BroadcastSettings.name')}</TableHead>
          <TableHead>{t('BroadcastSettings.filters')}</TableHead>
          <TableHead>{t('BroadcastSettings.enabled')}</TableHead>
          <TableHead>
            <Button>&nbsp;</Button>
          </TableHead>
        </TableHeader>
        <TableBody>
          {data.map((broadcast) => (
            <TableRow key={broadcast.subscriptionId}>
              <TableCell>
                <Checkbox
                  value={selected.includes(broadcast.subscriptionId)}
                  onChange={() => handleOnSelect(broadcast.subscriptionId)}
                />
              </TableCell>
              <TableCell>{broadcast.name}</TableCell>
              <TableCell>{renderFilters(broadcast)}</TableCell>
              <TableCell>
                <ToggleSwitch
                  handleUpdate={(state) => handleUpdateState(state, broadcast.subscriptionId)}
                  isActive={broadcast.subscriptionState === 'Enabled'}
                />
              </TableCell>
              {isMobile ? (
                <div className={classes.mobileEditButton} onClick={() => handleEditClick(broadcast.subscriptionId)}>
                  <IconButton className={classes.iconButton} name="edit" />
                </div>
              ) : (
                <TableCell>
                  <Button
                    className={[classes.editButton]}
                    buttonStyle="primary"
                    type="button"
                    onClick={() => handleEditClick(broadcast.subscriptionId)}
                  >
                    {t('BroadcastSettings.edit')}
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <div className={classes.container}>
      <MediaQuery minWidth={820}>
        <div className={classes.desktopTable}>{renderTable()}</div>
      </MediaQuery>
      <MediaQuery maxWidth={819}>
        <div className={classes.mobileTable}>{renderTable(true)}</div>
      </MediaQuery>
    </div>
  );
};

export default BroadcastTable;
