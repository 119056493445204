import platformApiUtils from '@ivendi/platform-api-utils';
import { schemaMap } from '../../core/schema';
import fundersSchema from '../../core/schemas/fundersSchema';
import * as actionTypes from '../actionTypes';

export function fetchFundersInfo() {
  return (dispatch, getState) => {
    let options = getState().funders;

    if (!options.isLoading && !options.hasFetched) {
      dispatch({
        types: [
          actionTypes.FETCH_FUNDERS_INFO,
          actionTypes.FETCH_FUNDERS_INFO_SUCCESS,
          actionTypes.FETCH_FUNDERS_INFO_ERROR
        ],
        callAPI: () => platformApiUtils.get('v1/funders'),
        parseResponse: (data) => schemaMap(data, fundersSchema)
      });
    }
  };
}
