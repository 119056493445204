import { useTranslation } from 'react-i18next';
import React from 'react';
import './incompleteReservationTooltip.scss';

const IncompleteReservationTooltip = () => {
  const { t } = useTranslation('ReserveNow');
  return (
    <div className="incompleteReservationTooltip">
      <div className="incompleteReservationTooltip__triangle" />
      <div className="incompleteReservationTooltip__inner">
        {t('IncompleteReservationTooltip.might_not_have_completed_authorisation')}
      </div>
    </div>
  );
};

export default IncompleteReservationTooltip;
