import { Trans, withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Button from '../../Common/Button/Button';
import UserIcon from '../../Common/UserIcon';
import PermissionsGroupItem from '../../Permissions/PermissionsGroupItem';
import PermissionsGroup from '../../Permissions/PermissionsGroup';
import clone from 'lodash/clone';
import ButtonWithIcon from '../../Common/Button/ButtonWithIcon';
import './settingsAccountViewDetails.scss';
import { canSeeReportingInsights } from '~/features';

const settingGroups = ['consumers', 'applications', 'quoting', 'insights'];

class SettingsAccountViewDetails extends React.Component {
  state = {
    openSettingGroups: new Set()
  };
  handleToggleSettingGroup = (groupId) => {
    const newSettingGroups = clone(this.state.openSettingGroups);
    this.props.trackingPage &&
      window.ga &&
      window.ga(
        'send',
        'event',
        this.props.trackingPage,
        `ToggleCollapseSettingsGroup`,
        `${groupId}PermsGroup${!newSettingGroups.has(groupId) ? 'Expanded' : 'Collapsed'}`
      );

    if (newSettingGroups.has(groupId)) {
      newSettingGroups.delete(groupId);
    } else {
      newSettingGroups.add(groupId);
    }

    this.setState({
      openSettingGroups: newSettingGroups
    });
  };

  get allGroupsExpanded() {
    return this.state.openSettingGroups.size === settingGroups.length;
  }
  handleExpandAll = () => {
    const newSettingGroups = clone(this.state.openSettingGroups);

    if (this.allGroupsExpanded) {
      newSettingGroups.clear();
    } else {
      settingGroups.forEach((groupId) => newSettingGroups.add(groupId));
    }

    this.props.trackingPage &&
      window.ga &&
      window.ga(
        'send',
        'event',
        this.props.trackingPage,
        `ToggleCollapseSettingsGroup`,
        `allPermsGroup${!this.allGroupsExpanded ? 'Expanded' : 'Collapsed'}`
      );
    this.setState({
      openSettingGroups: newSettingGroups
    });
  };

  render() {
    const { user, dealershipId } = this.props;
    const { openSettingGroups } = this.state;
    const isOwnUser = this.props.user && (this.props.user.id ? this.props.user.id : '') === this.props.currentUser;

    return (
      <div>
        <UserIcon />
        <div className="settingsAccountViewDetails">
          <div className="settingsAccountViewDetails__inner">
            <div className="settingsAccountViewDetails__detailContainer" key="dt1">
              <div className="settingsAccountViewDetails__label" key="label1">
                {this.props.t('SettingsAccountViewDetails.first_name')}
              </div>
              <div className="settingsAccountViewDetails__data" key="data1">
                {user.firstName}
              </div>
            </div>
            <div className="settingsAccountViewDetails__detailContainer" key="dt2">
              <div className="settingsAccountViewDetails__label" key="label2">
                {this.props.t('SettingsAccountViewDetails.last_name')}
              </div>
              <div className="settingsAccountViewDetails__data" key="data2">
                {user.surname}
              </div>
            </div>
            <div className="settingsAccountViewDetails__detailContainer" key="dt3">
              <div className="settingsAccountViewDetails__label" key="label3">
                {this.props.t('SettingsAccountViewDetails.email')}
              </div>
              <div className="settingsAccountViewDetails__data" key="data3">
                {user.email}
              </div>
            </div>
            <div className="settingsAccountViewDetails__detailContainer" key="dt4">
              <div className="settingsAccountViewDetails__label" key="label4">
                {this.props.t('SettingsAccountViewDetails.mobile_phone')}
              </div>
              <div className="settingsAccountViewDetails__data" key="data4">
                {user.mobile ? (
                  user.mobile
                ) : (
                  <span className="settingsAccountViewDetails__data--optional">
                    {this.props.t('SettingsAccountViewDetails.not_set')}
                  </span>
                )}
              </div>
            </div>
            <div className="settingsAccountViewDetails__detailContainer" key="dt4">
              <div className="settingsAccountViewDetails__label" key="label4">
                {this.props.t('SettingsAccountViewDetails.user_group')}
              </div>
              <div className="settingsAccountViewDetails__data" key="data4">
                {user.claims.includes('dealershipadmin') ? (
                  <>{this.props.t('SettingsAccountViewDetails.admin')}</>
                ) : (
                  <>{this.props.t('SettingsAccountViewDetails.standard')}</>
                )}
              </div>
            </div>
            <div className="settingsAccountViewDetails__detailContainer" key="dt5" />
            {!user.claims.includes('dealershipadmin') && (
              <div
                className="settingsAccountViewDetails__detailContainer settingsAccountViewDetails__changePassword"
                key="dt6"
              >
                <Button to={`/d/${dealershipId}/settings/changepassword`} buttonStyle="secondary">
                  {this.props.t('SettingsAccountViewDetails.change_password')}
                </Button>
              </div>
            )}
          </div>
          <div className="settingsAccountViewDetails__permissionsDetail">
            <div className="settingsAccountViewDetails__permissionsHeader">
              {this.props.t('SettingsAccountViewDetails.user_permissions')}
            </div>
            <div className="settingsAccountViewDetails__permissionsDesc">
              {!isOwnUser ? (
                user.claims.includes('dealershipadmin') ? (
                  <Trans ns="ManageUsers" i18nKey="SettingsAccountViewDetails.user_is_admin_permissions_read_only" />
                ) : (
                  this.props.t(
                    'SettingsAccountViewDetails.below_are_the_permissions_that_are_currently_set_for_this_user'
                  )
                )
              ) : (
                this.props.t(
                  'SettingsAccountViewDetails.this_is_your_own_user_and_you_are_unable_to_edit_your_own_permissions'
                )
              )}
            </div>
            <div className="settingsAccountEditPermissions__btnExpand">
              <div>
                <ButtonWithIcon
                  onClick={this.handleExpandAll}
                  iconName={!this.allGroupsExpanded ? 'plus' : 'minus'}
                  buttonStyle="secondary"
                >
                  {!this.allGroupsExpanded
                    ? this.props.t('SettingsAccountViewDetails.expand_all_groups')
                    : this.props.t('SettingsAccountViewDetails.collapse_all_groups')}
                </ButtonWithIcon>
              </div>
            </div>
            <PermissionsGroup
              groupId="consumers"
              label={this.props.t('SettingsAccountViewDetails.manage_customers')}
              openSettingGroups={openSettingGroups}
              groupToggleLabel=""
              handleToggleOpen={this.handleToggleSettingGroup}
              disabled={true}
            >
              <PermissionsGroupItem
                label={this.props.t('SettingsAccountViewDetails.delete_customer')}
                icon="bin"
                isActive={user.claims.includes('dealershipadmin') || user.claims.includes('delete-customers')}
              />
              <PermissionsGroupItem
                label={this.props.t('SettingsAccountViewDetails.assign_leads')}
                icon="assign"
                isActive={user.claims.includes('dealershipadmin') || user.claims.includes('canassign')}
              />
              <PermissionsGroupItem
                label={this.props.t('SettingsAccountViewDetails.close_deal')}
                icon="cross"
                isActive={user.claims.includes('dealershipadmin') || user.claims.includes('close-deal')}
              />
            </PermissionsGroup>

            {this.props.appStore.uiState.canUseFinancing && (
              <PermissionsGroup
                groupId="applications"
                label={this.props.t('SettingsAccountViewDetails.applications')}
                openSettingGroups={openSettingGroups}
                groupToggleLabel=""
                handleToggleOpen={this.handleToggleSettingGroup}
                disabled={true}
              >
                <PermissionsGroupItem
                  label={this.props.t('SettingsAccountViewDetails.view_applications')}
                  icon="application"
                  isActive={user.claims.includes('dealershipadmin') || user.claims.includes('view-applications')}
                />
                <PermissionsGroupItem
                  label={this.props.t('SettingsAccountViewDetails.create_applications')}
                  icon="approved"
                  isActive={user.claims.includes('dealershipadmin') || user.claims.includes('create-applications')}
                />
              </PermissionsGroup>
            )}
            {this.props.appStore.uiState.canQuote && (
              <PermissionsGroup
                groupId="quoting"
                label={this.props.t('SettingsAccountViewDetails.quoting')}
                openSettingGroups={openSettingGroups}
                groupToggleLabel=""
                disabled={true}
                handleToggleOpen={this.handleToggleSettingGroup}
              >
                <PermissionsGroupItem
                  icon="tick-speech"
                  label={this.props.t('SettingsAccountViewDetails.generate_quotes')}
                  isActive={user.claims.includes('dealershipadmin') || user.claims.includes('generate-quotes')}
                />
                {!this.props.appStore.uiState.isBdk && (
                  <PermissionsGroupItem
                    icon="edit-schemes"
                    label={this.props.t('SettingsAccountViewDetails.edit_schemes')}
                    isActive={user.claims.includes('dealershipadmin') || user.claims.includes('edit-schemes')}
                  />
                )}
                <PermissionsGroupItem
                  icon="vaps"
                  label={this.props.t('SettingsAccountViewDetails.edit_and_remove_vaps')}
                  isActive={user.claims.includes('dealershipadmin') || user.claims.includes('edit-vaps')}
                />
              </PermissionsGroup>
            )}
            {this.props.appStore.uiState.hasWebshopEnabled && (
              <PermissionsGroup
                groupId="webshops"
                label={'Webshop'}
                handleToggleOpen={this.handleToggleSettingGroup}
                openSettingGroups={openSettingGroups}
                disabled={true}
                trackingPage={this.props.trackingPage}
              >
                <PermissionsGroupItem
                  id="webshops"
                  icon="webshop"
                  label={'Webshop'}
                  isActive={user.claims.includes('dealershipadmin') || user.claims.includes('webshops')}
                />
              </PermissionsGroup>
            )}
            {this.props.appStore.uiState.canUseInsights && (
              <PermissionsGroup
                groupId="insights"
                label={this.props.t('SettingsAccountViewDetails.insights')}
                handleToggleOpen={this.handleToggleSettingGroup}
                openSettingGroups={openSettingGroups}
                disabled={true}
                trackingPage={this.props.trackingPage}
              >
                <PermissionsGroupItem
                  icon="report"
                  label={this.props.t('SettingsAccountViewDetails.finance_reports')}
                  isActive={user.claims.includes('dealershipadmin') || user.claims.includes('reporting-finance')}
                />
                <PermissionsGroupItem
                  icon="report"
                  label={this.props.t('SettingsAccountViewDetails.stock_reports')}
                  isActive={user.claims.includes('dealershipadmin') || user.claims.includes('reporting-stock')}
                />
              </PermissionsGroup>
            )}
          </div>
        </div>
        <div className="settingsAccountViewDetails__buttonContainer">
          <div className="settingsAccountViewDetails__cancel">
            <Button to={`/d/${dealershipId}/settings`} buttonStyle="cancel">
              {this.props.t('SettingsAccountViewDetails.go_back')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

SettingsAccountViewDetails.propTypes = {
  dealershipId: PropTypes.string.isRequired,
  user: PropTypes.object
};

export default withTranslation('ManageUsers')(inject('appStore')(observer(SettingsAccountViewDetails)));
