import React from 'react';
import PropTypes from 'prop-types';
import './summaryTable.scss';

const Table = ({ children }) => {
  return (
    <table className="summaryTable">
      <tbody>{children}</tbody>
    </table>
  );
};

Table.propTypes = {
  children: PropTypes.node
};

export default Table;
