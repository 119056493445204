import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import OrdersItem from './OrdersItem';
import './orders.scss';
import DashboardPanelNoItems from '../../Dashboard/components/panel/DashboardPanelNoItems';

function Orders({ orders, dealershipId, canUseOrders }) {
  const { t } = useTranslation('DigitalDeals');
  const shouldShowOrders = orders && orders.length > 0;
  const noItemsText = t('Orders.you_havent_recieved_any_orders');

  return (
    <>
      {shouldShowOrders ? (
        <div className="orders">
          {orders &&
            orders.map((order, index) => (
              <OrdersItem order={order} key={index} dealershipId={dealershipId} isCustomerRecord={false} />
            ))}
        </div>
      ) : (
        <>
          {canUseOrders ? (
            <DashboardPanelNoItems>{noItemsText}</DashboardPanelNoItems>
          ) : (
            <div className="orders__contactUs">
              <Trans
                ns="DigitalDeals"
                i18nKey="Orders.upsell"
                components={{
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  tel: <a href="tel:03302290028" />,
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  mail: <a href="mailto:tellmemore@ivendi.com" />
                }}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

Orders.propTypes = {
  orders: PropTypes.array,
  dealershipId: PropTypes.string
};
export default Orders;
