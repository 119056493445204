import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './eligibilityModalRuleList.scss';
import _ from 'lodash';
import EligibilityModalRuleListItem from './EligibilityModalRuleListItem';

const EligibilityModalRuleList = ({ decisionMessages, t }) => {
  return (
    <div>
      {decisionMessages.length >= 1 ? (
        <div className="eligibilityModalRuleList__text">
          {t('EligibilityModalRuleList.the_information_below_should_give_an_indication_of_the_reasons_for_this_result')}
        </div>
      ) : (
        ''
      )}
      <ul className="eligibilityModalRuleList__ul" key="ul">
        {_.map(decisionMessages, (each, index) => {
          return <EligibilityModalRuleListItem key={index} decisionMessage={each} />;
        })}
      </ul>
    </div>
  );
};

EligibilityModalRuleList.propTypes = {
  decisionMessages: PropTypes.array
};

export default withTranslation('Quoting')(EligibilityModalRuleList);
