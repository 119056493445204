import _ from 'lodash';
import { calculateTotalMonthsFromCollection } from '../helpers';
import moment from 'moment';
import { parsePaymentSchedules } from './quoteParser';

export function parseApplication(rawData) {
  let {
    PersonalDetails,
    BusinessDetails,
    AddressHistory,
    EmploymentHistory,
    AffordabilityDetails,
    BankDetails,
    LenderNotes,
    Vehicle,
    CustomerType,
    BusinessContacts,
    PrivacyPreferences,
    Tags,
    ...applicationData
  } = rawData;

  if (Vehicle.Class) {
    Vehicle.Class = Vehicle.Class.toLowerCase();
  }

  if (Vehicle.Condition) {
    Vehicle.Condition = Vehicle.Condition.toLowerCase();
  }

  BankDetails = BankDetails || {};
  if (!BankDetails.TimeAtBank) {
    BankDetails.TimeAtBank = {};
  }

  let application;

  if (CustomerType.toLowerCase() === 'corporate') {
    let newContacts;

    if (BusinessContacts && Array.isArray(BusinessContacts) && BusinessContacts.length) {
      let newContactsItems = _.map(BusinessContacts, (contact) => {
        let newContact = _.cloneDeep(contact);
        newContact.DateOfBirth = newContact.DateOfBirth
          ? moment(newContact.DateOfBirth).format('DD/MM/YYYY')
          : undefined;

        if (newContact && Array.isArray(newContact.AddressHistory)) {
          newContact.Items = newContact.AddressHistory;
          newContact.totalMonths = calculateTotalMonthsFromCollection(newContact.Items, 'TimeAtAddress');
        } else {
          newContact.Items = [];
          newContact.totalMonths = 0;
        }

        delete newContact.AddressHistory;

        return newContact;
      });

      newContacts = {
        Items: newContactsItems
      };
    } else {
      newContacts = {
        Items: [
          {
            Items: [],
            isEditing: true,
            totalMonths: 0
          }
        ]
      };
    }

    if (AddressHistory && Array.isArray(AddressHistory)) {
      AddressHistory = _.map(AddressHistory, (address) => {
        address.PremisesType = address.Residency;

        delete address.Residency;
        return address;
      });
    }

    application = {
      BusinessDetails: {
        ...BusinessDetails,
        DateEstablished: BusinessDetails.DateEstablished
          ? moment(BusinessDetails.DateEstablished).format('DD/MM/YYYY')
          : undefined
      },
      BankDetails: BankDetails,
      LenderNotes: LenderNotes,
      Vehicle: Vehicle,
      OrganisationContacts: newContacts,
      AddressHistory: {
        Items: AddressHistory,
        totalMonths: calculateTotalMonthsFromCollection(AddressHistory, 'TimeAtAddress')
      },
      CustomerType: CustomerType && CustomerType.toLowerCase(),
      ...applicationData
    };
  } else {
    const {
      DateOfBirth,
      NoOfDependants,
      DirectorOrBoardMemberCountryOfOperation,
      DirectorOrBoardMemberCompanySector,
      CountryOfBirth,
      FirstName,
      LastName,
      ...remainingPersonalDetails
    } = PersonalDetails;

    let bornUK = '';
    if (CountryOfBirth === 'GB') {
      bornUK = 'true';
    } else if (CountryOfBirth !== '') {
      bornUK = 'false';
    }

    Tags = Tags ? JSON.parse(Tags) : '';

    application = {
      PersonalDetails: {
        ...remainingPersonalDetails,
        FirstName: FirstName && FirstName.trim(),
        LastName: LastName && LastName.trim(),
        DateOfBirth: DateOfBirth ? moment(DateOfBirth).format('DD/MM/YYYY') : undefined,
        NoOfDependants: NoOfDependants === '6+' ? 6 : NoOfDependants, //NewVehicle sends us 6+ vs 6 on NGDP
        CountryOfBirth: {
          BornUK: bornUK,
          BirthPlace: CountryOfBirth
        },
        DirectorOrBoardMember: {
          Director:
            DirectorOrBoardMemberCountryOfOperation !== '' && DirectorOrBoardMemberCompanySector !== ''
              ? 'true'
              : 'false',
          CountryOfOperation: DirectorOrBoardMemberCountryOfOperation,
          CompanySector: DirectorOrBoardMemberCompanySector
        }
      },
      AddressHistory: {
        Items: AddressHistory,
        totalMonths: calculateTotalMonthsFromCollection(AddressHistory, 'TimeAtAddress')
      },
      EmploymentHistory: {
        Items: EmploymentHistory,
        totalMonths: calculateTotalMonthsFromCollection(EmploymentHistory, 'TimeAtEmployment')
      },
      BankDetails: BankDetails,
      AffordabilityDetails: AffordabilityDetails,
      LenderNotes: LenderNotes,
      Vehicle: Vehicle,
      CustomerType: CustomerType && CustomerType.toLowerCase(),
      PrivacyPreferences,
      Tags,
      PreApprovalData: { ...applicationData?.Agreements[0]?.PreApprovalData },
      ...applicationData
    };
  }

  if (application.Quote) {
    let quote = application.Quote;

    let platformMeta;

    if (quote.PlatformMeta) {
      try {
        platformMeta = JSON.parse(quote.PlatformMeta);
      } catch (err) {
        platformMeta = null;
      }
    }

    quote.PlatformMeta = platformMeta;

    if (quote.PlatformMeta && quote.PlatformMeta.CashPrice) {
      let vehiclePrice;

      if (quote.PlatformMeta.VehiclePrice) {
        vehiclePrice = quote.PlatformMeta.VehiclePrice;
      } else {
        vehiclePrice = quote.PlatformMeta.CashPrice;
      }

      application.QuoteRequest = {
        VehiclePrice: vehiclePrice,
        BasicPrice: quote.PlatformMeta.BasicPrice,
        VatPercent: quote.PlatformMeta.VatPercent,
        VatAmount: quote.PlatformMeta.VatAmount,
        VatAddedToDeposit: quote.PlatformMeta.VatAddedToDeposit,
        NonVatableItems: quote.PlatformMeta.NonVatableItems,
        Insurance: quote.PlatformMeta.Insurance,
        Warranty: quote.PlatformMeta.Warranty,
        Other: quote.PlatformMeta.Other,
        CashDeposit: quote.ActualCashDeposit,
        PartExchange: quote.PartExchange,
        OutstandingSettlement: quote.OutstandingSettlement,
        SettlementSource: quote.SettlementSource,
        Term: quote.TermQuoted,
        AnnualDistance: quote.AnnualDistanceQuoted,
        ValueAddedProducts: quote.ValueAddedProducts,
        Cashback: quote.Cashback
      };
    } else {
      application.QuoteRequest = {
        VehiclePrice: quote.CashPrice,
        BasicPrice: quote.CashPrice,
        CashDeposit: parseFloat((quote.TotalDeposit - quote.PartExchange + quote.OutstandingSettlement).toFixed(2)),
        PartExchange: quote.PartExchange,
        OutstandingSettlement: quote.OutstandingSettlement,
        SettlementSource: quote.SettlementSource,
        Term: quote.TermQuoted,
        AnnualDistance: quote.AnnualDistanceQuoted,
        ValueAddedProducts: quote.ValueAddedProducts,
        Cashback: quote.Cashback
      };
    }

    application.Quote.PaymentSchedule = parsePaymentSchedules(quote);
  } else {
    application.QuoteRequest = {};
  }

  if (rawData.PrivacyPreferences && rawData.PrivacyPreferences.LenderMarketingByEmailConsent) {
    application.marketingPreference = rawData.PrivacyPreferences.LenderMarketingByEmailConsent;
  }

  if (rawData.Arranged) {
    application.Arranged = rawData.Arranged;
  }

  return application;
}
