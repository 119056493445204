import React from 'react';
import PropTypes from 'prop-types';
import './fieldGroup.scss';
import Icon from '../Icon/Icon';

const FieldGroup = (props) => (
  <div className="fieldGroup">
    <label className="fieldGroup__label" htmlFor={props.id}>
      {props.label}
      {props.infoIcon ? <div className="fieldGroup__helpIcon" key="icon" onClick={props.handleModal} /> : ''}
    </label>

    {props.isValid && !props.disabled ? (
      <div className="fieldGroup__icon fieldGroup__tickIcon" key="icon1">
        <Icon name="validation-tick" />
      </div>
    ) : null}
    {props.isInvalid && !props.disabled ? (
      <div className="fieldGroup__icon fieldGroup__crossIcon" key="icon2">
        <Icon name="validation-cross" />
      </div>
    ) : (
      ''
    )}
    <div className="fieldGroup__inputContainer">{props.children}</div>

    {props.errorMessage ? <div className="fieldGroup__errorMessage">{props.errorMessage}</div> : ''}
  </div>
);

FieldGroup.propTypes = {
  validationValueLink: PropTypes.object,
  inputId: PropTypes.string,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  label: PropTypes.string,
  infoIcon: PropTypes.bool,
  errorMessage: PropTypes.string,
  handleModal: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  id: PropTypes.string
};

FieldGroup.defaultProps = {
  disabled: false
};

export default FieldGroup;
