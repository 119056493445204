import * as actionTypes from '../actionTypes';
import uuid from 'uuid';
import _ from 'lodash';

export function resetSendDeal() {
  return {
    type: actionTypes.RESET_SEND_DEAL_MODAL
  };
}

export function setPage(page) {
  return {
    type: actionTypes.SET_SEND_DEAL_PAGE,
    page
  };
}

export function sendDealToCustomer() {
  return (dispatch, getState) => {
    const state = getState();
    const customerId = state.quotes.customerId;
    const quoteIds = _.chain(state.modals.sendDeal.quotes)
      .filter((quote) => quote.isSendable)
      .map((quote) => quote.QuoteId)
      .value();
    const vehicleId = _.get(state.quotes, 'vehicle.VehicleId');

    dispatch({
      type: actionTypes.SEND_DEAL_TO_CUSTOMER,
      customerId,
      quoteIds,
      vehicleId
    });
  };
}

export function sendDealFromQuickQuote(formData) {
  return (dispatch, getState) => {
    const state = getState();
    const customerId = uuid.v4();
    const quoteIds = _.chain(state.modals.sendDeal.quotes)
      .filter((quote) => quote.isSendable)
      .map((quote) => quote.QuoteId)
      .value();
    const applicationId = uuid.v4();
    const vehicleId = uuid.v4();
    const dealershipId = state.dealership.Id;
    const vehicle = state.quickQuote.vehicle;

    dispatch({
      type: actionTypes.SEND_DEAL_TO_CUSTOMER_FROM_QUICK_QUOTE,
      formData,
      customerId,
      quoteIds,
      applicationId,
      vehicleId,
      dealershipId,
      vehicle
    });
  };
}

export function sendDealToExistingCustomerFromQuickQuote(customerId) {
  return (dispatch, getState) => {
    const state = getState();
    const quoteIds = _.chain(state.modals.sendDeal.quotes)
      .filter((quote) => quote.isSendable)
      .map((quote) => quote.QuoteId)
      .value();
    const vehicleId = uuid.v4();

    dispatch({
      type: actionTypes.SEND_DEAL_TO_EXISTING_CUSTOMER_FROM_QUICK_QUOTE,
      customerId,
      quoteIds,
      vehicleId
    });
  };
}
