import { observable } from 'mobx';
import moment from 'moment';
import ValueAddedProduct from './ValueAddedProduct';

class VehicleStatus {
  @observable AcceptanceFeeLast;
  @observable AccessoriesInsurance;
  @observable AccessoriesOther;
  @observable AccessoriesWarranty;
  @observable AdditionalCommission;
  @observable Advance;
  @observable AnnualDistanceQuoted;
  @observable BasicPrice;
  @observable CashDeposit;
  @observable CashPrice;
  @observable ChargePerOverDistanceUnit;
  @observable Commission;
  @observable DateOfHandover;
  @observable DateOfPurchase;
  @observable Deposit;
  @observable FinalPayment;
  @observable FinanceType;
  @observable FirstPayment;
  @observable FlatRate;
  @observable FollowedBy;
  @observable FollowingPayments;
  @observable FunderCode;
  @observable InterestCharges;
  @observable NonVatableItems;
  @observable Notes;
  @observable OptionToPurchaseFee;
  @observable OtherFunder;
  @observable PartExchange;
  @observable PlatformMeta;
  @observable RepresentativeApr;
  @observable Settlement;
  @observable Status;
  @observable Term;
  @observable TotalAmountPayable;
  @observable TotalPrice;
  @observable VAT;
  @observable VATAmount;
  @observable ValueAddedProducts = [];
  @observable VehiclePrice;

  updateFromJSON(json = {}) {
    this.AcceptanceFeeLast = json.AcceptanceFeeLast;
    this.AccessoriesInsurance = json.AccessoriesInsurance;
    this.AccessoriesOther = json.AccessoriesOther;
    this.AccessoriesWarranty = json.AccessoriesWarranty;
    this.AdditionalCommission = json.AdditionalCommission;
    this.Advance = json.Advance;
    this.AnnualDistanceQuoted = json.AnnualDistanceQuoted;
    this.BasicPrice = json.BasicPrice;
    this.CashDeposit = json.CashDeposit;
    this.CashPrice = json.CashPrice;
    this.ChargePerOverDistanceUnit = json.ChargePerOverDistanceUnit;
    this.Commission = json.Commission;
    this.DateOfPurchase = json.DateOfPurchase ? moment(json.DateOfPurchase).format('DD/MM/YYYY') : undefined;
    this.DateOfHandover = json.DateOfHandover ? moment(json.DateOfHandover).format('DD/MM/YYYY') : undefined;
    this.Deposit = json.Deposit;
    this.FinalPayment = json.FinalPayment;
    this.FinanceType = json.FinanceType;
    this.FirstPayment = json.FirstPayment;
    this.FlatRate = json.FlatRate;
    this.FollowedBy = json.FollowedBy;
    this.FollowingPayments = json.FollowingPayments;
    this.FunderCode = json.FunderCode;
    this.InterestCharges = json.InterestCharges;
    this.NonVatableItems = json.NonVatableItems;
    this.Notes = json.Notes;
    this.OptionToPurchaseFee = json.OptionToPurchaseFee;
    this.OtherFunder = json.OtherFunder;
    this.PartExchange = json.PartExchange;
    this.PlatformMeta = json.PlatformMeta;
    this.RepresentativeApr = json.RepresentativeApr;
    this.Settlement = json.Settlement;
    this.Status = json.Status;
    this.Term = json.Term;
    this.TotalAmountPayable = json.TotalAmountPayable;
    this.TotalPrice = json.TotalPrice;
    this.VAT = json.VAT;
    this.VATAmount = json.VATAmount;
    this.VehiclePrice = json.VehiclePrice;

    this.ValueAddedProducts = json.ValueAddedProducts.map((item) => {
      const vap = new ValueAddedProduct(item);
      vap.updateFromJSON(item);

      return vap;
    });
  }
}

export default VehicleStatus;
