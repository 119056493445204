import React from 'react';
import PropTypes from 'prop-types';
import passwordStength from 'password-strength';
import classnames from 'classnames';
import './passwordStrength.scss';

const PasswordStrength = ({ password, isInvalid }) => {
  const score = passwordStength(password);
  if (isInvalid && score.strength === 'strong') {
    score.strength = 'medium';
  }
  const styles = classnames('passwordStrength', `passwordStrength--${score.strength}`);

  return <div className={styles} />;
};

PasswordStrength.propTypes = {
  password: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool
};

export default PasswordStrength;
