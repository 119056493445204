import React from 'react';
import PropTypes from 'prop-types';
import './noPanelData.scss';

const NoPanelData = ({ text }) => <div className="noPanelData">{text}</div>;

NoPanelData.propTypes = {
  text: PropTypes.string
};

export default NoPanelData;
