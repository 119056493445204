import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import InputTooltip from './InputTooltip';
import TextInputWrappingInput from '../Common/Form/TextInputWrappingInput';
import './tradingNameLookup.scss';
import _ from 'lodash';
import * as lookups from '../../api/lookups';

class TradingNameLookup extends React.Component {
  lookupCustomerByTradingName = null;

  constructor(props) {
    super(props);
    this.state = {
      existingCustomerId: null,
      isTradingNameTaken: false
    };
  }

  UNSAFE_componentWillMount() {
    this.lookupCustomerByTradingName = _.debounce(this.lookupCorporateCustomerByTradingName, 300);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.lookupCustomerByTradingName.cancel();
      this.setState({
        isTradingNameTaken: false
      });
      this.lookupCustomerByTradingName(nextProps.value);
    }
  }

  handleChange = (tradingName) => {
    this.props.onChange && this.props.onChange(tradingName);
  };
  lookupCorporateCustomerByTradingName = (tradingName) => {
    lookups
      .lookupCorporateCustomerByTradingName(tradingName, this.props.dealershipId)
      .then(this.onSuccess)
      .catch(this.onError);
  };
  onSuccess = (response) => {
    this.setState({
      existingCustomerId: response.Id,
      isTradingNameTaken: true
    });
  };
  onError = () => {
    this.setState({
      existingCustomerId: null,
      isTradingNameTaken: false
    });
  };
  handleTooltipClick = () =>
    this.setState({
      isTradingNameTaken: false
    });

  render() {
    return (
      <div>
        <TextInputWrappingInput
          id="tradingName"
          value={this.props.value}
          onChange={this.handleChange}
          onBlur={this.props.onBlur}
          isValid={this.props.isValid}
          isInvalid={this.props.isInvalid}
        />
        <div className="tradingNameLookup__tooltip">
          <InputTooltip show={this.state.isTradingNameTaken} handleClick={this.handleTooltipClick}>
            <div>
              <p className="tradingNameLookup__message">
                {this.props.t('TradingNameLookup.trading_name_already_exists')}{' '}
                <Link to={`/d/${this.props.dealershipId}/consumers/${this.state.existingCustomerId}`} className="link">
                  {this.props.t('TradingNameLookup.click_here')}
                </Link>
              </p>
              <div className="tradingNameLookup__actions">
                <Link
                  to={`/d/${this.props.dealershipId}/consumers/${this.state.existingCustomerId}`}
                  className="button--viewMore"
                >
                  {this.props.t('TradingNameLookup.view_contact_record')}
                </Link>
              </div>
            </div>
          </InputTooltip>
        </div>
      </div>
    );
  }
}

TradingNameLookup.propTypes = {
  value: PropTypes.string,
  dealershipId: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool
};
export default withTranslation('Common')(TradingNameLookup);
