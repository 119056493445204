import { useQuery } from '@tanstack/react-query';
import { getPlatformUpdates, ModelResponse } from './index';

export const useGetPlatformUpdates = (
  dealershipId: string,
  page: number = 1,
  pageSize: number = 10,
  sortDirection: 'desc' | 'asc' = 'desc'
) => {
  return useQuery<ModelResponse, Error>(
    ['platformUpdates', dealershipId, page, pageSize, sortDirection],
    () => getPlatformUpdates(dealershipId, page, pageSize, sortDirection),
    {
      staleTime: 5000,
      retry: false,
      enabled: !!dealershipId
    }
  );
};
