import _ from 'lodash';
import { validate } from '../core/validators';
import { getLenderConfig } from './lenders';
import { countItemsInObject } from './helpers';

export function getApplicationProgress(application) {
  const validationRules = getLenderConfig(application.Quote.FunderCode, application.CustomerType, 'validationRules');

  const emptyConsumerApplication = {
    PersonalDetails: {},
    AddressHistory: {
      Items: [{}],
      totalMonths: 0
    },
    EmploymentHistory: {
      Items: [{}],
      totalMonths: 0
    },
    BankDetails: {},
    AffordabilityDetails: {}
  };

  const emptyCorporateApplication = {
    BusinessDetails: {},
    AddressHistory: {
      Items: [{}],
      totalMonths: 0
    },
    OrganisationContacts: {
      Items: [{}]
    },
    BankDetails: {}
  };

  const validationErrors = validate(application, validationRules);

  if (application.CustomerType.toLowerCase() === 'corporate') {
    const baseValidationErrors = validate(emptyCorporateApplication, validationRules);

    const corporateProgress = {
      businessDetails: calculatePercentageComplete(baseValidationErrors, validationErrors, 'BusinessDetails'),
      addressHistory: calculatePercentageCompleteForAddresses(
        baseValidationErrors,
        validationErrors,
        application,
        'AddressHistory'
      ),
      organisationContacts: calculatePercentageCompleteForOrganisationContacts(
        baseValidationErrors,
        validationErrors,
        application,
        'OrganisationContacts'
      ),
      bank: calculatePercentageComplete(baseValidationErrors, validationErrors, 'BankDetails')
    };

    const numSections = 4;

    return {
      ...corporateProgress,
      total: parseInt(_.toArray(corporateProgress).reduce((a, b) => a + b) / numSections, 10)
    };
  } else {
    const baseValidationErrors = validate(emptyConsumerApplication, validationRules);

    const consumerProgress = {
      personal: calculatePercentageComplete(baseValidationErrors, validationErrors, 'PersonalDetails'),
      address: calculatePercentageCompleteForAddresses(
        baseValidationErrors,
        validationErrors,
        application,
        'AddressHistory'
      ),
      employment: calculatePercentageCompleteForAddresses(
        baseValidationErrors,
        validationErrors,
        application,
        'EmploymentHistory'
      ),
      bank: calculatePercentageComplete(baseValidationErrors, validationErrors, 'BankDetails'),
      affordability: calculatePercentageComplete(baseValidationErrors, validationErrors, 'AffordabilityDetails')
    };

    const numSections = 5;

    return {
      ...consumerProgress,
      total: parseInt(_.toArray(consumerProgress).reduce((a, b) => a + b) / numSections, 10)
    };
  }
}

export function getCfcProgress(cfcCheck) {
  const validationRules = getLenderConfig('def', 'cfc', 'validationRules');

  const emptyCfcCheck = {
    PersonalDetails: {},
    AddressHistory: {
      Items: [{}],
      totalMonths: 0
    },
    EmploymentHistory: {
      Items: [{}],
      totalMonths: 0
    }
  };

  const baseValidationErrors = validate(emptyCfcCheck, validationRules);
  const validationErrors = validate(cfcCheck, validationRules);

  const progress = {
    personal: calculatePercentageComplete(baseValidationErrors, validationErrors, 'PersonalDetails'),
    address: calculatePercentageCompleteForAddresses(
      baseValidationErrors,
      validationErrors,
      cfcCheck,
      'AddressHistory'
    ),
    employment: calculatePercentageCompleteForAddresses(
      baseValidationErrors,
      validationErrors,
      cfcCheck,
      'EmploymentHistory'
    )
  };

  const numSections = 3;

  return {
    ...progress,
    total: parseInt(_.toArray(progress).reduce((a, b) => a + b) / numSections, 10)
  };
}

function calculatePercentageCompleteForAddresses(baseValidationErrors, validationErrors, application, path) {
  //todo remove hardcoded value of 36 from here and pull from lender config somehow.
  const data = _.get(application, path);
  if (!data || !data.Items) {
    return 0;
  }

  let timePercent;

  if (data.totalMonths && data.totalMonths < 36) {
    timePercent = 100 - ((36 - data.totalMonths) * 100) / 36;
  } else if (data.totalMonths && data.totalMonths >= 36) {
    timePercent = 100;
  } else {
    timePercent = 0;
  }

  const formPercent =
    data.Items.length === 0 ? 0 : calculatePercentageComplete(baseValidationErrors, validationErrors, path + '.Items');
  return parseInt((timePercent + formPercent) / 2, 10);
}

function calculatePercentageCompleteForOrganisationContacts(baseValidationErrors, validationErrors, application, path) {
  const data = _.get(application, path);
  const individualContactProgresses = _.map(data.Items, (contact, index) => {
    const addressesPercent = calculatePercentageCompleteForAddresses(
      baseValidationErrors,
      validationErrors,
      application,
      'OrganisationContacts.Items.' + index
    );
    const formPercent = calculatePercentageComplete(
      baseValidationErrors,
      validationErrors,
      'OrganisationContacts.Items.' + index
    );

    return Math.round(parseInt((addressesPercent + formPercent) / 2, 10));
  });

  return individualContactProgresses.reduce((a, b) => a + b) / data.Items.length;
}

function calculatePercentageComplete(baseValidationErrors, validationErrors, path) {
  const numRequiredFields = baseValidationErrors ? countItemsInObject(_.get(baseValidationErrors, path)) : 0;
  const numErrors = validationErrors ? countItemsInObject(_.get(validationErrors, path)) : 0;

  if (numRequiredFields === 0 && numErrors === 0) {
    return 100;
  } else if (numRequiredFields === 0) {
    return parseInt(100 / (numErrors + 1), 10);
  } else {
    return Math.round(parseInt((Math.max(numRequiredFields - numErrors, 0) / numRequiredFields) * 100, 10));
  }
}
