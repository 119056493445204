import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import CompareQuotesTableItem from './CompareQuotesTableItem';
import CompareQuotesVideos from './CompareQuotesVideos';
import PrintedQuote from '../../Application/components/PrintedQuote';
import { printPageByRef } from '~/core/print';
import Button from '../../Common/Button/Button';
import trashIcon from '../../../styles/icons/bin.png';
import printIcon from '../../../styles/icons/print.png';
import exclamationSign from '../../../styles/icons/exclamation-sign.svg';
import QuoteLegalText from './QuoteLegalText/QuoteLegalText';
import CombinedPrintedQuote from '../../Application/components/CombinedPrintedQuote';
import { inject } from 'mobx-react';
import './compareQuotesTable.scss';
import { hasCompanionQuotes } from '~/features';
import { QuoteCardVariantTypeJs } from '~/components/QuoteCard/types';

class CompareQuotesTable extends React.Component {
  printAreaRefs = {};

  handleProceed(quote) {
    if (quote.VehicleLoan) {
      return () => {
        this.props.proceedToBreakDown(quote);
      };
    }

    return () => {
      this.props.onProceedWithFinanceOption(quote);
    };
  }

  handleRemove(quoteId) {
    return (e) => {
      e.preventDefault();
      this.props.onRemoveQuote(quoteId);
    };
  }

  handlePrint(index) {
    return (e) => {
      this.props.onPrint(index);
      e.preventDefault();
      printPageByRef(this.printAreaRefs[index]);
    };
  }

  render() {
    const includesPCPQuote = this.props.quotes.some((quote) => quote.FinanceType === 'PCP');

    return (
      <div className="compareQuotesTable">
        <div className="compareQuotesTable__print">
          {this.props.quotes.map((quote, index) => {
            this.printAreaRefs[index] = React.createRef();

            return (
              <div ref={this.printAreaRefs[index]} key={'print' + index + quote.ProductId}>
                {quote.VehicleLoan ? (
                  <CombinedPrintedQuote
                    quote={quote}
                    vehicle={this.props.vehicle}
                    assignees={this.props.assignees}
                    appStore={this.props.appStore}
                  />
                ) : (
                  <PrintedQuote
                    quote={quote}
                    vehicle={this.props.vehicle}
                    assignees={this.props.assignees}
                    appStore={this.props.appStore}
                  />
                )}
              </div>
            );
          })}
        </div>
        <table className="compareQuotesTable__table">
          <tbody>
            <tr>
              <th />
              {this.props.quotes.map((quote, index) => {
                return (
                  <td className="compareQuotesTable__smallPrint" key={`qsp${index}`}>
                    {quote.isUnavailable ? (
                      <div className="compareQuotesTable__overlay">
                        <img className="compareQuotesTable__exclamation" src={exclamationSign} alt="" />{' '}
                        {this.props.t(
                          'CompareQuotesTable.this_quote_is_no_longer_available_based_on_the_parameters_selected'
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </td>
                );
              })}
            </tr>
            <tr className="compareQuotesTableItem">
              <th>&nbsp;</th>
              {this.props.quotes.map((quote, index) =>
                hasCompanionQuotes(quote) ? (
                  <td className="compareQuotesTable__smallPrint" key={index}>
                    <div className="compareQuotesTable__smallPrintInner">
                      {this.props.t('CompareQuotesTable.multiple_finance_components')}
                    </div>
                  </td>
                ) : (
                  <td></td>
                )
              )}
            </tr>
            <CompareQuotesTableItem
              quotes={this.props.quotes}
              type="logo"
              field="FunderCode"
              header={this.props.t('CompareQuotesTable.lender')}
            />
            <CompareQuotesTableItem
              quotes={this.props.quotes}
              type="money"
              field="FollowingPayments"
              header={this.props.t('CompareQuotesTable.regular_monthly_payment')}
            />
            <CompareQuotesTableItem
              quotes={this.props.quotes}
              type="product"
              field="ProductName"
              header={this.props.t('CompareQuotesTable.product')}
            />
            <CompareQuotesTableItem
              quotes={this.props.quotes}
              type="months"
              field="Term"
              header={this.props.t('CompareQuotesTable.duration_of_agreement')}
            />
            <CompareQuotesTableItem
              quotes={this.props.quotes}
              type="paymentschedule"
              header={this.props.t('CompareQuotesTable.payment_schedule')}
            />
            <CompareQuotesTableItem
              quotes={this.props.quotes}
              type="money"
              field="CashPrice"
              header={this.props.t('CompareQuotesTable.total_price')}
            />
            <CompareQuotesTableItem
              quotes={this.props.quotes}
              type="money"
              field="VehiclePrice"
              header={this.props.t('CompareQuotesTable.vehicle_price')}
            />
            <CompareQuotesTableItem
              quotes={this.props.quotes}
              type="vaps"
              field="ValueAddedProducts"
              header={this.props.t('CompareQuotesTable.value_added_products')}
            />

            <CompareQuotesTableItem
              quotes={this.props.quotes}
              type="money"
              field="TotalDeposit"
              header={this.props.t('CompareQuotesTable.total_deposit')}
            />
            <CompareQuotesTableItem
              quotes={this.props.quotes}
              type="money"
              field="AcceptanceFeeFirst"
              header={this.props.t('CompareQuotesTable.administration_fee_a')}
            />
            <CompareQuotesTableItem
              quotes={this.props.quotes}
              type="money"
              field="AcceptanceFeeLast"
              header={this.props.t('CompareQuotesTable.administration_fee_b')}
            />
            <CompareQuotesTableItem
              quotes={this.props.quotes}
              type="money"
              field="OptionToPurchaseFee"
              header={this.props.t('CompareQuotesTable.option_to_purchase_fee')}
            />
            <CompareQuotesTableItem
              quotes={this.props.quotes}
              type="money"
              field="TotalAmountPayable"
              header={this.props.t('CompareQuotesTable.total_amount_payable')}
            />
            <CompareQuotesTableItem
              quotes={this.props.quotes}
              type="money"
              field="BalancePayable"
              header={this.props.t('CompareQuotesTable.total_amount_of_credit')}
            />
            <CompareQuotesTableItem
              quotes={this.props.quotes}
              type="repapr"
              field="RepresentativeApr"
              header={this.props.t('CompareQuotesTable.representative_apr')}
            />
            <CompareQuotesTableItem
              quotes={this.props.quotes}
              type="money"
              field="InterestCharges"
              header={this.props.t('CompareQuotesTable.interest_charges')}
            />
            <CompareQuotesTableItem
              quotes={this.props.quotes}
              type="annualRate"
              field="AnnualRate"
              header={this.props.t('CompareQuotesTable.annual_fixed_interest_rate')}
            />
            <CompareQuotesTableItem
              quotes={this.props.quotes}
              type="number"
              field="AnnualDistanceQuoted"
              header={this.props.t('CompareQuotesTable.annual_mileage')}
            />
            {includesPCPQuote && (
              <CompareQuotesTableItem
                quotes={this.props.quotes}
                type="excess"
                field="ChargePerOverDistanceUnit"
                header={this.props.t('CompareQuotesTable.excess_per_mile')}
              />
            )}
            <tr className="compareQuotesTable__zebra">
              <th>&nbsp;</th>
              {this.props.quotes.map((quote, index) => (
                <td className="compareQuotesTable__smallPrint" key={index}>
                  <div className="compareQuotesTable__smallPrintInner">
                    <QuoteLegalText quote={quote} variant={QuoteCardVariantTypeJs.FinanceQuote} />
                  </div>
                </td>
              ))}
            </tr>
            <tr>
              <th className="compareQuotesTable__buttonRow">&nbsp;</th>
              {this.props.quotes.map((quote, index) => {
                return (
                  <td className="compareQuotesTable__buttonRow" key={'buttonTd' + index}>
                    {!quote.isUnavailable ? (
                      <div>
                        <button
                          className="compareQuotesTable__button compareQuotesTable__removeButton"
                          key={'remove' + index}
                          type="button"
                          onClick={this.handleRemove(quote.QuoteId)}
                        >
                          <img src={trashIcon} className="compareQuotesTable__icon" key={'icon1' + index} />{' '}
                          {this.props.t('CompareQuotesTable.remove_from_shortlist')}
                        </button>
                        <button
                          className="compareQuotesTable__button compareQuotesTable__printButton"
                          key={'print' + index}
                          type="button"
                          onClick={this.handlePrint(index)}
                        >
                          <img src={printIcon} className="compareQuotesTable__icon" key={'icon2' + index} />{' '}
                          {this.props.t('CompareQuotesTable.print_this_quote')}
                        </button>
                      </div>
                    ) : (
                      ''
                    )}
                  </td>
                );
              })}
            </tr>
            <tr>
              <th>&nbsp;</th>
              {this.props.quotes.map((quote, index) => {
                return (
                  <td key={'proceedButton' + index}>
                    {!quote.isUnavailable ? (
                      <div className="compareQuotesTable__proceedContainer" key={'proceed' + index}>
                        <Button
                          isLoading={
                            this.props.chosenQuoteId === quote.QuoteId && this.props.proceedingState === 'loading'
                          }
                          hasError={
                            this.props.chosenQuoteId === quote.QuoteId && this.props.proceedingState === 'error'
                          }
                          buttonStyle="secondary"
                          type="button"
                          onClick={this.handleProceed(quote)}
                        >
                          {this.props.t('CompareQuotesTable.proceed')}
                        </Button>
                      </div>
                    ) : (
                      ''
                    )}
                  </td>
                );
              })}
            </tr>
            <tr className="compareQuotesTable__videoContainer">
              <th>&nbsp;</th>
              {this.props.quotes.map((quote, index) => {
                return (
                  <td className="compareQuotesTable__video" key={'vid' + index}>
                    {!quote.isUnavailable ? (
                      <div className="compareQuotesTable__videoInner" key={'vidInner' + index}>
                        <CompareQuotesVideos email={this.props.email} quotes={quote} />
                      </div>
                    ) : (
                      ''
                    )}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

CompareQuotesTable.propTypes = {
  quotes: PropTypes.array,
  vehicle: PropTypes.object,
  onProceedWithFinanceOption: PropTypes.func,
  handleRemoveQuoteFromCompare: PropTypes.func,
  email: PropTypes.string,
  onPrint: PropTypes.func,
  onRemoveQuote: PropTypes.func.isRequired,
  assignees: PropTypes.array,
  proceedingState: PropTypes.string,
  chosenQuoteId: PropTypes.string,
  proceedToBreakDown: PropTypes.func
};
export default withTranslation('Quoting')(inject('appStore')(CompareQuotesTable));
