import { useQuery } from '@tanstack/react-query';
import { handleRequest } from '../../../../../../../../shared/services/handleRequest';

interface MetricsData {
  lastModified: string;
  points?: string[];
  datasets: {
    label?: string;
    sum?: number[];
    min?: number[];
    max?: number[];
    avg?: number[];
  }[];
}

export type Metric = 'vehicle_search_click:count' | 'finance_quotes_displayed:count';

interface Props {
  vehicleId?: string;
  dealershipId?: string;
  metric: Metric;
}

const useVehicleMetrics = ({ vehicleId, dealershipId, metric }: Props) => {
  const currentDate = new Date();
  const oneDayBefore = new Date(currentDate);
  oneDayBefore.setDate(currentDate.getDate() - 1);

  const body = {
    metric,
    fromDate: oneDayBefore.toISOString(),
    toDate: currentDate.toISOString(),
    dimensions: ['Vehicle'],
    datePeriod: 'Hour',
    vehicleId
  };

  return useQuery<MetricsData>([`useVehicleMetrics`, metric, dealershipId, vehicleId], async () => {
    const response = await handleRequest(`/api-module/analytics/metric/${dealershipId}/query`, {
      method: 'POST',
      body: JSON.stringify(body)
    });
    return await response.json();
  });
};

export default useVehicleMetrics;
