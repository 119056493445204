import React, { memo } from 'react';
import Icon from '~Common/Icon/Icon';
import cn from 'classnames';

import styles from './Chip.module.scss';
import { useTranslation } from 'react-i18next';

const intentToIconName = {
  error: 'alert-new',
  warning: 'warning-alert',
  success: 'check',
  pending: 'clock',
  info: 'info'
};

const Chip = ({ className, intent = 'info', children }) => {
  const { t } = useTranslation('Stock');

  const classes = cn(
    styles.chip,
    {
      [styles.error]: intent === 'error',
      [styles.warning]: intent === 'warning',
      [styles.success]: intent === 'success',
      [styles.pending]: intent === 'pending'
    },
    className
  );

  return (
    <div className={classes}>
      <Icon className={styles.icon} name={intentToIconName[intent]} />
      <span>{children ? children : t(`chips.${intent}`)}</span>
    </div>
  );
};

export default memo(Chip);
