import React from 'react';
import LoadingSpinner from './LoadingSpinner';
import LoadingSpinnerTailSpin from './LoadingSpinnerTailSpin';
import './loadingCenterWrapped.scss';
import PropTypes from 'prop-types';
function getLoader(loader, props) {
  switch (loader) {
    case 'spinner':
      return <LoadingSpinner {...props} />;
    case 'spinner-tailspin':
      return <LoadingSpinnerTailSpin {...props} />;
    default:
      return React.cloneElement(loader, props);
  }
}
const LoadingCenterWrapped = ({ loader, size }) => (
  <div className="loadingCenterWrapped">{getLoader(loader, { size })}</div>
);

LoadingCenterWrapped.propTypes = {
  loader: PropTypes.oneOfType([PropTypes.node, PropTypes.oneOf(['spinner', 'spinner-tailspin'])]).isRequired,
  size: PropTypes.string
};

LoadingCenterWrapped.defaultProps = {
  loader: 'spinner'
};

export default LoadingCenterWrapped;
