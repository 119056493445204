import { useEffect } from 'react';
import { connect } from 'react-redux';
import { whoAmI } from '../../../redux/session/sessionActions';
import { Outlet } from 'react-router-dom';
import DealerAccessDeniedPage from '~/components/Common/DealerAccessDeniedPage';

interface SignedInContainerProps {
  isSignedIn: boolean;
  fetchSession: () => void;
}

const SignedInContainer: React.FC<SignedInContainerProps> = ({ isSignedIn, fetchSession }) => {
  useEffect(() => {
    fetchSession();
  }, [fetchSession]);

  if (!isSignedIn) {
    return <DealerAccessDeniedPage />;
  }

  return <Outlet />;
};

const mapStateToProps = (state: any) => ({
  isSignedIn: state.session.isSignedIn
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchSession: () => dispatch(whoAmI())
});

export default connect(mapStateToProps, mapDispatchToProps)(SignedInContainer);
