import { Trans, withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import SentDealsItem from './SentDealsItem';
import DashboardPanelNoItems from '../../Dashboard/components/panel/DashboardPanelNoItems';
import './sentDeals.scss';
import { splitCamelCaseToString } from '../../../core/helpers';
import Feature, { Content, NotEnabled, NotImplemented } from '../../Common/Feature';
import NotImplementedMessage from '../../Common/NotImplemented';

const isInvalidCustomer = (customer) => {
  return !customer.deals[0] || !customer.deals[0].vehicle;
};

const SentDeals = ({ customersWithDeals, dealStatus, dealershipId, canUsePushDeal, t }) => {
  const shouldShowSentDeals = customersWithDeals && customersWithDeals.length > 0;
  const noItemsText =
    dealStatus === 'all'
      ? t('SentDeals.you_havent_sent_any_deals')
      : // TODO: i18n query translations
        t('SentDeals.no_deal_status_deals_found', {
          dealStatus: splitCamelCaseToString(dealStatus)
        });
  return (
    <div>
      {shouldShowSentDeals ? (
        <div className="sentDeals">
          {customersWithDeals.map((customer, i) => {
            if (isInvalidCustomer(customer)) {
              return null;
            } else {
              return <SentDealsItem customer={customer} key={i} dealershipId={dealershipId} />;
            }
          })}
        </div>
      ) : (
        <Feature name="pushdeal">
          <NotImplemented>
            <div className="sentDeals__message">
              <NotImplementedMessage />
            </div>
          </NotImplemented>
          <NotEnabled>
            <div className="sentDeals__message">
              <Trans
                ns="DigitalDeals"
                i18nKey="SentDeals.upsell"
                components={{
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  tel: <a href="tel:03302290028" />,
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  mail: <a href="mailto:tellmemore@ivendi.com" />
                }}
              />
            </div>
          </NotEnabled>
          <Content>
            <DashboardPanelNoItems>{noItemsText}</DashboardPanelNoItems>
          </Content>
        </Feature>
      )}
    </div>
  );
};

SentDeals.propTypes = {
  customersWithDeals: PropTypes.array,
  dealStatus: PropTypes.string,
  dealershipId: PropTypes.string
};
export default withTranslation('DigitalDeals')(inject(['appStore'])(observer(SentDeals)));
