import { ApiResponseType, DataSet } from '~/components/Insights/types';

const sumDataSet = (apiResponse: ApiResponseType, dataSet: 'sum' | 'avg' = 'sum') => {
  if (dataSet === DataSet.Avg) {
    const average = apiResponse.datasets[0].avg;

    return average[average.length - 1];
  }

  return apiResponse.datasets[0].sum.reduce((a, b) => a + b, 0);
};

export default sumDataSet;
