import platformApiUtils from '@ivendi/platform-api-utils';
import uuid from 'uuid';
import setCookie from '../utils/setCookie';

export const fetchDealershipUsers = (dealershipId) => {
  return new Promise((resolve, reject) => {
    platformApiUtils
      .get(`v1/dealerships/${dealershipId}/users/?Page=1&PageSize=1000&SortDirection=desc&SortField=lastActive`)
      .then((response) => {
        if (response && response.Results) {
          resolve(response.Results);
        } else {
          reject(response);
        }
      })
      .catch(reject);
  });
};

export const addUser = async (dealershipId, data) => {
  const userId = uuid.v4();
  const CSRFToken = await fetchCSRFToken();
  const NSRFCookieName = 'NCSRF';
  setCookie(NSRFCookieName, encodeURIComponent(CSRFToken), 80);
  return platformApiUtils
    .post(
      'v1/dealerships/' + dealershipId + '/users',
      {
        UserId: userId,
        ...data
      },
      { CSRFToken: CSRFToken }
    )
    .then(() => {
      setCookie(NSRFCookieName, '', 0);
      return {
        Id: userId,
        LastActive: -1,
        Firstname: data.firstName,
        Surname: data.surname,
        Mobile: data.mobile,
        Email: data.email,
        Claims: data.claims && data.claims.length > 0 ? data.claims : [],
        IsDealerAdmin: false
      };
    })
    .catch(() => {
      setCookie(NSRFCookieName, '', 0);
    });
};

export const saveUserDetails = async (dealershipId, userId, data) => {
  const CSRFToken = await fetchCSRFToken();
  const NSRFCookieName = 'NCSRF';
  setCookie(NSRFCookieName, encodeURIComponent(CSRFToken), 80);
  return platformApiUtils
    .put('v1/dealerships/' + dealershipId + '/users/' + userId, data, { CSRFToken: CSRFToken })
    .then(() => {
      setCookie(NSRFCookieName, '', 0);
      return {
        Id: userId,
        Firstname: data.firstName,
        Surname: data.surname,
        Mobile: data.mobile,
        Email: data.email,
        Claims: data.claims && data.claims.length > 0 ? data.claims : []
      };
    })
    .catch(() => {
      setCookie(NSRFCookieName, '', 0);
    });
};

export const fetchUser = (dealershipId, userId) => {
  return platformApiUtils.get(`v1/dealerships/${dealershipId}/users/${userId}`);
};

export const removeUser = async (dealershipId, userId) => {
  const CSRFToken = await fetchCSRFToken();
  const NSRFCookieName = 'NCSRF';
  setCookie(NSRFCookieName, encodeURIComponent(CSRFToken), 80);
  return platformApiUtils
    .del('v1/dealerships/' + dealershipId + '/users/' + userId, {}, { CSRFToken: CSRFToken })
    .then(() => {
      setCookie(NSRFCookieName, '', 0);
    })
    .catch(() => {
      setCookie(NSRFCookieName, '', 0);
    });
};

const fetchCSRFToken = () => {
  return platformApiUtils.get('v1/token').catch(() => {
    return undefined;
  });
};
