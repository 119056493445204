import platformApiUtils from '@ivendi/platform-api-utils';

export function setReservationSettings(dealershipId, data) {
  return platformApiUtils.post(`v1/reserve-now/${dealershipId}/settings`, {
    StripePublicKey: data.stripeKey,
    ReservationDurationInDays: data.reservationPeriod,
    ReservationAmount: parseFloat(data.reservationFee) * 100
  });
}

export function setAcceptTerms(dealershipId, version) {
  return platformApiUtils.post(`v1/reserve-now/${dealershipId}/accept-terms`, {
    VersionAccepted: version
  });
}

export function setPrivateStripeKey(dealershipId, privateStripeKey) {
  return platformApiUtils.post(`v1/reserve-now/${dealershipId}/stripe-private-key`, {
    StripePrivateKey: privateStripeKey
  });
}

export function getReservationSettings(dealershipId) {
  return new Promise((resolve, reject) => {
    platformApiUtils
      .get(`v1/reserve-now/${dealershipId}/settings`)
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * @param {string} dealershipId
 * @param {number} page
 * @param {number} pageSize
 * @param {string} reservationstatus
 * @returns {Promise<object[]>} `resolve(response)` or  `reject()`
 */
export function getReservedVehiclesByDealer(dealershipId, page, pageSize, reservationstatus) {
  return new Promise((resolve, reject) => {
    platformApiUtils
      .get(
        `v1/${dealershipId}/reserve-now/rnapi/dealer/${dealershipId}?page=${page}&pagesize=${pageSize}&reservationstatus=${reservationstatus}`
      )
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function getPrivateStripeKey(dealershipId) {
  return new Promise((resolve, reject) => {
    platformApiUtils
      .get(`v1/reserve-now/${dealershipId}/stripe-private-key`)
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function refundCustomer(dealershipId, stripeId) {
  return platformApiUtils.post(`v1/${dealershipId}/reserve-now/rnapi/vehicle/issue-refund`, {
    dealershipId: dealershipId,
    stripeChargeId: stripeId
  });
}

export function setPaymentAsDeposit(dealershipId, stripeId) {
  return platformApiUtils.post(`v1/${dealershipId}/reserve-now/rnapi/Vehicle/use-as-deposit`, {
    dealershipId: dealershipId,
    stripeChargeId: stripeId,
    usedAsDeposit: true
  });
}
