import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import PushDealActivityContainerSection from './PushDealActivityContainerSection';
import PushDealQuotePreview from './PushDealQuotePreview';
import MoneyFormat from '../Common/MoneyFormat';
import { inject } from 'mobx-react';
import { formatNumber } from '../../core/helpers';
import './pushDealPricingDetails.scss';

const PushDealPricingDetails = ({
  currentDeal,
  quotes,
  offerPrice,
  vehiclePrice,
  vapsTotal,
  newCarOffers,
  appStore
}) => {
  const { t } = useTranslation('PushDeal');
  const { annualDistanceQuoted, requestedDeposit, termQuoted, FinanceDepositAllowance } = currentDeal.quotes[0];
  const shouldShowQuotes = quotes && quotes.length > 0;

  return (
    <div className="pushDealPricingDetails">
      <div className="pushDealPricingDetails__info">
        {FinanceDepositAllowance && (
          <PushDealActivityContainerSection title={t('PushDealPricingDetails.deposit_contribution')}>
            <MoneyFormat value={FinanceDepositAllowance} />
          </PushDealActivityContainerSection>
        )}
        <PushDealActivityContainerSection title={t('PushDealPricingDetails.customer_deposit')}>
          <MoneyFormat value={requestedDeposit} />
        </PushDealActivityContainerSection>
        <PushDealActivityContainerSection title={t('PushDealPricingDetails.term')}>
          {termQuoted} {t('PushDealPricingDetails.months')}
        </PushDealActivityContainerSection>
        <PushDealActivityContainerSection title={t('PushDealPricingDetails.annual_mileage')}>
          {t('PushDealPricingDetails.annual_distance_quoted_miles_p_a', {
            annualDistanceQuoted: formatNumber(annualDistanceQuoted, 0, appStore.uiState.countryCode)
          })}
        </PushDealActivityContainerSection>
      </div>
      {shouldShowQuotes && (
        <div className="pushDealPricingDetails__financeQuotes">
          <div className="pushDealPricingDetails__quotes">
            {quotes.map((quote, i) => {
              quote.VehiclePrice = offerPrice || vehiclePrice;
              const newCarOffer =
                newCarOffers &&
                Array.isArray(newCarOffers) &&
                newCarOffers.find((offer) => offer.ProductUid === quote.ProductId);
              return <PushDealQuotePreview key={i} quote={quote} newCarOffer={newCarOffer} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

PushDealPricingDetails.propTypes = {
  currentDeal: PropTypes.object,
  quotes: PropTypes.array,
  offerPrice: PropTypes.number,
  vehiclePrice: PropTypes.number,
  vapsTotal: PropTypes.string,
  appStore: PropTypes.object
};
export default inject('appStore')(PushDealPricingDetails);
