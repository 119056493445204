import { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import PropTypes from 'prop-types';
import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import Select from '../../Common/Form/Select';
import LoadingDots from '../../Common/Loading/LoadingDots';
import Table from '../components/Table/LeagueTableTable';
import TableHead from '../components/Table/LeagueTableHeader';
import TableHeaderItem from '../components/Table/LeagueTableHeaderItem';
import TableBody from '../components/Table/LeagueTableBody';
import TableRow from '../components/Table/LeagueTableRow';
import TableCell from '../components/Table/LeagueTableCell';
import LeagueTableGroupedHeader from '../components/Table/LeagueTableGroupedHeader';
import LeagueTableGroupedHeaderItem from '../components/Table/LeagueTableGroupedHeaderItem';
import { trueFalseToYesNo } from '../../../core/helpers';
import ivendi from '../../../styles/icons/ivendi_logo.svg';
import MarketingPage from '../../DigitalReport/components/MarketingPage';
import TrackPageView from '../../Common/Tracking/TrackPageView';
import { compose } from 'redux';
import { withLocation, withParams, withQuery } from 'hocs/router';
import './leagueTable.scss';

class LeagueTable extends Component {
  UNSAFE_componentWillMount() {
    this.props.appStore.leagueTableStore.initLeagueTables(
      this.props.params.dealershipId,
      this.props.query.filterMonth,
      'GroupMonthlyReports',
      'monthly/league/',
      'DealershipValueReports'
    );
  }

  onSelectMonth = (event) => {
    event.preventDefault();
    this.props.appStore.leagueTableStore.selectMonth(
      this.props.params.dealershipId,
      event.target.value,
      'monthly/league/',
      'DealershipValueReports'
    );
  };

  onSortLeagueTable = (field) => {
    this.props.appStore.leagueTableStore.onSortLeagueTable(field);
  };

  render() {
    const data = this.props.appStore.leagueTableStore.leagueTable;
    const filterMonth = this.props.appStore.leagueTableStore.filterMonth;
    const allMonths = toJS(this.props.appStore.leagueTableStore.options);
    const isLoading = this.props.appStore.leagueTableStore.isLoadingLeagueTables;
    const sortField = this.props.appStore.leagueTableStore.sortField;
    const sortDirection = this.props.appStore.leagueTableStore.sortDirection;

    if (isLoading) {
      return <LoadingDots />;
    }

    if (this.props.appStore.leagueTableStore.leagueTableError === true) {
      return <MarketingPage dealership={this.props.dealership} />;
    }

    return (
      <Page>
        <TrackPageView dealershipId={this.props.params.dealershipId} />
        <Breadcrumbs
          items={[
            { name: 'Home', path: `/d/${this.props.params.dealershipId}` },
            { name: 'Reporting', path: `/d/${this.props.params.dealershipId}/reporting` },
            { name: 'League Table' }
          ]}
        />
        <div className="leagueTable__header">
          <div className="leagueTable__headerText">Group League Table</div>
          <div className="leagueTable__headerIcon--outer">
            <img className="leagueTable__headerIcon" src={ivendi} alt="iVendi" />
          </div>
        </div>
        <div className="leagueTable__selectOuter">
          <div className="leagueTable__selectInner">
            <span className="leagueTable__selectLabel">Select Month:</span>
            <div className="leagueTable__select">
              <Select
                theme="alt"
                emptyValue={false}
                value={filterMonth}
                options={allMonths}
                onChange={this.onSelectMonth}
              />
            </div>
          </div>
        </div>
        <Panel>
          <PanelHeader> Group League Table</PanelHeader>
          <PanelContent>
            <Table>
              <LeagueTableGroupedHeader>
                <LeagueTableGroupedHeaderItem colSpan="2" />
                <LeagueTableGroupedHeaderItem colSpan="3">Daily</LeagueTableGroupedHeaderItem>
                <LeagueTableGroupedHeaderItem colSpan="6">Monthly</LeagueTableGroupedHeaderItem>
                <LeagueTableGroupedHeaderItem colSpan="4">Platform Usage</LeagueTableGroupedHeaderItem>
              </LeagueTableGroupedHeader>
              <TableHead
                arrowDirection={sortDirection}
                sortField={sortField}
                onClick={(field) => this.onSortLeagueTable(field)}
              >
                <TableHeaderItem fieldName="Dealership.DisplayName">Rooftop</TableHeaderItem>
                <TableHeaderItem fieldName="Rank">Rank</TableHeaderItem>
                <TableHeaderItem fieldName="AverageStock">Avg. Daily Stock</TableHeaderItem>
                <TableHeaderItem fieldName="AverageImpressionsPerVehicle">Avg. FPAs per Vehicle</TableHeaderItem>
                <TableHeaderItem fieldName="AverageQuotesPerVehicle">Avg. Quotes per Vehicle</TableHeaderItem>
                <TableHeaderItem fieldName="TotalQuotes">Total Quotes</TableHeaderItem>
                <TableHeaderItem fieldName="Reach">Total Unique Buyers</TableHeaderItem>
                <TableHeaderItem fieldName="TotalImpressions">FPAs</TableHeaderItem>
                <TableHeaderItem fieldName="CfcProspects">CFC Prospects</TableHeaderItem>
                <TableHeaderItem fieldName="WebApplications">Web Applications</TableHeaderItem>
                <TableHeaderItem fieldName="PageViews.product">Product Page Views</TableHeaderItem>
                <TableHeaderItem fieldName="TotalLogins">iVendi Platform Logged in</TableHeaderItem>
                <TableHeaderItem fieldName="Submissions">Platform Apps Submitted</TableHeaderItem>
                <TableHeaderItem fieldName="ApprovalRate">Approval Rate (%)</TableHeaderItem>
                <TableHeaderItem fieldName="RejectionRate">Rejection Rate (%)</TableHeaderItem>
                <TableHeaderItem fieldName="SubmittedLenders">No. of Lenders Providing Finance</TableHeaderItem>
                <TableHeaderItem fieldName="AverageDistanceInMilesToViewer">Rooftop to Viewer (miles)</TableHeaderItem>
              </TableHead>
              <TableBody>
                {data.map((dealer, i) => (
                  <TableRow key={i}>
                    <TableCell>{dealer.Dealership.DisplayName}</TableCell>
                    <TableCell>{dealer.Rank}</TableCell>
                    <TableCell>{dealer.AverageStock}</TableCell>
                    <TableCell>{dealer.AverageImpressionsPerVehicle}</TableCell>
                    <TableCell>{dealer.AverageQuotesPerVehicle}</TableCell>
                    <TableCell>{dealer.TotalQuotes}</TableCell>
                    <TableCell>{dealer.Reach}</TableCell>
                    <TableCell>{dealer.TotalImpressions}</TableCell>
                    <TableCell>{dealer.CfcProspects}</TableCell>
                    <TableCell>{dealer.WebApplications}</TableCell>
                    <TableCell>{dealer.PageViews.product}</TableCell>
                    <TableCell>{trueFalseToYesNo(dealer.LoggedIn)}</TableCell>
                    <TableCell>{dealer.Submissions}</TableCell>
                    <TableCell>{dealer.ApprovalRate}</TableCell>
                    <TableCell>{dealer.RejectionRate}</TableCell>
                    <TableCell>{dealer.SubmittedLenders}</TableCell>
                    <TableCell>{parseInt(dealer.AverageDistanceInMilesToViewer, 10)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PanelContent>
        </Panel>
      </Page>
    );
  }
}

LeagueTable.propTypes = {
  appStore: PropTypes.object,
  params: PropTypes.object,
  query: PropTypes.object,
  dealership: PropTypes.object,
  location: PropTypes.object
};

export default compose(withParams, withQuery, withLocation, inject(['appStore']), observer)(LeagueTable);
