import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextInputWrappingInput from '../Form/TextInputWrappingInput';
import InputTooltip from '../InputTooltip';
import ExistingCustomerMessage from '../../AddCustomer/components/ExistingCustomerMessage';
import './emailLookup.scss';
import { useQuery } from '@tanstack/react-query';
import { lookupQueries } from 'api/lookups/hooks';
import { useDebounce } from '@react-hook/debounce';
import LoadingSpinner from '../Loading/LoadingSpinner';

const propTypes = {
  value: PropTypes.string.isRequired,
  currentEmail: PropTypes.string,
  onChange: PropTypes.func,
  onLookup: PropTypes.func,
  showPrompt: PropTypes.bool,
  dealershipId: PropTypes.string.isRequired,
  sector: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string
};

type Props = Omit<PropTypes.InferProps<typeof propTypes>, 'sector' | 'onLookup'> & {
  sector: 'residential' | 'corporate';
  onLookup?: (data: QueryFactoryData<typeof lookupQueries.byEmail>) => void;
};

const EmailLookup = ({
  value,
  onChange,
  currentEmail,
  sector,
  dealershipId,
  onBlur,
  autoComplete,
  placeholder,
  showPrompt,
  onLookup
}: Props) => {
  const [suppressed, setIsSupressed] = React.useState(false);
  const [debouncedValue, setDebouncedValue] = useDebounce(value, 500);

  const { data: existingCustomer, isFetching } = useQuery({
    ...lookupQueries.byEmail({
      email: debouncedValue,
      sector,
      dealershipId
    }),
    enabled: debouncedValue !== currentEmail && debouncedValue?.includes('@')
  });

  useEffect(() => {
    if (existingCustomer === undefined) return;

    onLookup?.(existingCustomer);
  }, [existingCustomer, onLookup]);

  useEffect(() => {
    setIsSupressed(!value || value === currentEmail);
  }, [value, currentEmail]);

  useEffect(() => {
    setDebouncedValue(value);
  }, [value, setDebouncedValue]);

  const handleChange = (email: string) => {
    onChange?.(email);
  };

  const handleEmailTooltipClick = () => setIsSupressed(true);

  return (
    <div>
      <div className="emailLookup__input">
        <TextInputWrappingInput
          id="email"
          type="email"
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          autoComplete={autoComplete}
          placeholder={placeholder}
        />
        {isFetching && (
          <div data-th="email-lookup-loader" className="emailLookup__loader">
            <LoadingSpinner size="small" />
          </div>
        )}
      </div>

      {showPrompt && !suppressed && (
        <div className="emailLookup__tooltip">
          <InputTooltip
            show={existingCustomer && !!existingCustomer?.Id}
            handleClick={sector === 'corporate' ? handleEmailTooltipClick : undefined}
          >
            <ExistingCustomerMessage messageType={sector} customer={existingCustomer} dealershipId={dealershipId} />
          </InputTooltip>
        </div>
      )}
    </div>
  );
};

EmailLookup.defaultProps = {
  showPrompt: true
};

EmailLookup.propTypes = propTypes;

export default EmailLookup;

