import React from 'react';
import PropTypes from 'prop-types';
import './quoteFunderLogo.scss';
import FunderLogo from '../../Common/FunderLogo';

const QuoteFunderLogo = ({ funder }) => {
  return (
    <div className="quoteFunderLogo">
      <div className="quoteFunderLogo__inner" key="outer">
        <FunderLogo funder={funder} />
      </div>
    </div>
  );
};

QuoteFunderLogo.propTypes = {
  funder: PropTypes.string
};

export default QuoteFunderLogo;
