import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Common/Icon/Icon';
import './reservePaymentTag.scss';
import { useTranslation } from 'react-i18next';

export const getTagData = (data, isRefundHidden) => {
  if (data.paid === false) {
    return { color: 'yellow', text: 'incomplete', icon: 'inprogress' };
  } else if (data.paymentUsedAsDeposit === true) {
    return { color: 'green', text: 'used_as_deposit', icon: 'arrow-circle' };
  } else if (!isRefundHidden && !data.overdue) {
    return { color: 'blue', text: 'reserved', icon: 'padlock' };
  } else if (isRefundHidden) {
    return { color: 'green', text: 'refunded', icon: 'validation-tick' };
  } else if (data.overdue) {
    return { color: 'red', text: 'expired', icon: 'cross' };
  } else {
    return { color: 'blue', text: 'reserved', icon: 'padlock' };
  }
};

const ReservePaymentTag = ({ data, isRefundHidden }) => {
  const { t } = useTranslation('ReserveNow');

  const tagData = getTagData(data, isRefundHidden) || {};

  return (
    <div className={`reservePaymentTag reservePaymentTag--${tagData.color}`}>
      <Icon name={tagData.icon} />
      <span className="reservePaymentTag__text">{t(`ReservePaymentTag.${tagData.text}`)}</span>
    </div>
  );
};

ReservePaymentTag.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string
};

export default ReservePaymentTag;
