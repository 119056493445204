import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import './organisationContactsPanel.scss';
import Table from './SummaryTable/Table';
import TableRow from './SummaryTable/TableRow';
import OuterLeft from './SummaryTable/OuterLeft';
import OuterRight from './SummaryTable/OuterRight';
import { titleCase, splitCamelCaseToString } from '../../../core/helpers';
import AddressHistoryPanelItem from './AddressHistoryPanelItem';
import TableHead from './SummaryTable/TableHead';
import TableCell from './SummaryTable/TableCell';
import WordBreak from '../../Common/WordBreak';
import OptionsValue from '../../Common/OptionsValue';

const OrganisationContactsPanel = ({ contact }) => {
  const { t } = useTranslation('Reporting');
  return (
    <div className="organisationContactsPanel">
      <div className="organisationContactsPanel__header">{t('OrganisationContactsPanel.personal_details')}</div>
      <OuterLeft>
        <Table>
          <TableRow>
            <TableHead width="50%">{t('OrganisationContactsPanel.name')}</TableHead>
            <TableCell>{titleCase(contact.Title + ' ' + contact.FirstName + ' ' + contact.LastName)}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('OrganisationContactsPanel.position')}</TableHead>
            <TableCell>{splitCamelCaseToString(contact.Position)}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('OrganisationContactsPanel.date_of_birth')}</TableHead>
            <TableCell>{contact.DateOfBirth}</TableCell>
          </TableRow>
          {contact.CountryOfBirth && 
            <TableRow>
              <TableHead width="50%">{t('OrganisationContactsPanel.country_of_birth')}</TableHead>
              <TableCell>
                <OptionsValue type={'Nationality'} value={contact.CountryOfBirth} />
              </TableCell>
            </TableRow>
          }
          {contact.Nationality && 
            <TableRow>
              <TableHead width="50%">{t('OrganisationContactsPanel.nationality')}</TableHead>
              <TableCell>
                <OptionsValue type={'Nationality'} value={contact.Nationality} />
              </TableCell>
            </TableRow>
          }
          <TableRow>
            <TableHead width="50%">{t('OrganisationContactsPanel.gender')}</TableHead>
            <TableCell>{contact.Gender && splitCamelCaseToString(contact.Gender)}</TableCell>
          </TableRow>
        </Table>
      </OuterLeft>
      <OuterRight>
        <Table>
          <TableRow>
            <TableHead width="50%">{t('OrganisationContactsPanel.no_dependants')}</TableHead>
            <TableCell>{contact.NoOfDependants}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('OrganisationContactsPanel.marital_status')}</TableHead>
            <TableCell>{splitCamelCaseToString(contact.MaritalStatus)}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('OrganisationContactsPanel.email')}</TableHead>
            <TableCell>
              <WordBreak>{contact.Email}</WordBreak>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('OrganisationContactsPanel.home_telephone')}</TableHead>
            <TableCell>{contact.HomeTelephone}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('OrganisationContactsPanel.mobile_telephone')}</TableHead>
            <TableCell>{contact.MobileTelephone}</TableCell>
          </TableRow>
        </Table>
      </OuterRight>

      {_.map(contact.Items, (address, index) => (
        <div className="organisationContactsPanel__address" key={`ah${index}`}>
          <AddressHistoryPanelItem address={address} index={index} key={`ahp${index}`} />
        </div>
      ))}
    </div>
  );
};

OrganisationContactsPanel.propTypes = {
  contact: PropTypes.object
};
export default OrganisationContactsPanel;
