import React from 'react';
import PropTypes from 'prop-types';
import './summaryTable.scss';

const TableHead = ({ children, width }) => (
  <th className="summaryTable__th" width={width}>
    {children}
  </th>
);

TableHead.propTypes = {
  children: PropTypes.node,
  width: PropTypes.string
};

export default TableHead;
