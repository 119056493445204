import React from 'react';
import PropTypes from 'prop-types';
import NoAssignees from '../components/NoAssignees';
import AssignLeadButton from '../components/AssignLeadButton';
import AssignLeadInitials from '../components/AssignLeadInitials';
import { observer, inject } from 'mobx-react';
import AssignLeadModalContainer from '../containers/AssignLeadModalContainer';

const AssignLead = ({
  appStore,
  initialAssignees,
  openModal,
  size,
  customerId,
  customerName,
  customerType,
  closeModal,
  isModalOpen,
  dealershipId,
  initials,
  avatarColor
}) => {
  return (
    <div data-th="AssignLead">
      {initialAssignees.length ? (
        <AssignLeadInitials
          initials={initials}
          avatarColor={avatarColor}
          onClick={openModal}
          initialAssignees={initialAssignees}
          size={size}
          additionalAssigneesCount={initialAssignees.length - 1}
        />
      ) : (
        <div>
          {appStore.uiState.canAssign ? (
            <AssignLeadButton buttonSize={size} initialAssignees={initialAssignees} onClick={openModal} />
          ) : (
            <NoAssignees />
          )}
        </div>
      )}
      <AssignLeadModalContainer
        dealershipId={dealershipId}
        isOpen={isModalOpen}
        customerId={customerId}
        customerName={customerName}
        customerType={customerType}
        initialAssignees={initialAssignees}
        closeModal={closeModal}
      />
    </div>
  );
};

AssignLead.propTypes = {
  appStore: PropTypes.object,
  initialAssignees: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  size: PropTypes.string,
  customerId: PropTypes.string,
  customerName: PropTypes.string,
  customerType: PropTypes.string,
  dealershipId: PropTypes.string,
  isModalOpen: PropTypes.bool,
  initials: PropTypes.string,
  avatarColor: PropTypes.number
};

export default inject(['appStore'])(observer(AssignLead));
