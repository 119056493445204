import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ErrorApology from '../../Common/ErrorApology';
import * as componentActions from '../../../redux/component/componentActions';
import { getCustomer } from '../../../selectors/customerSelectors';
import EditCustomer from '../components/EditCustomer';
import { compose } from 'redux';
import { withParams } from 'hocs/router';

class EditCustomerContainer extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.load('EditCustomerContainer');
  }

  componentWillUnmount() {
    this.props.unload('EditCustomerContainer');
  }

  render() {
    const { children, ...remainingProps } = this.props;

    if (this.props.components.EditCustomerContainer && this.props.components.EditCustomerContainer.errorMessage) {
      return <ErrorApology>{this.props.components.EditCustomerContainer.errorMessage}</ErrorApology>;
    }

    if (!(this.props.components.EditCustomerContainer && this.props.components.EditCustomerContainer.hasLoaded)) {
      return null;
    }

    return <EditCustomer {...remainingProps} />;
  }
}

EditCustomerContainer.propTypes = {
  params: PropTypes.object,
  children: PropTypes.any,
  load: PropTypes.func,
  unload: PropTypes.func,
  components: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  return {
    consumer: getCustomer(state, ownProps.params.consumerId),
    components: state.components
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    load: (componentName) => {
      dispatch(componentActions.componentLoad(componentName, ownProps.params));
    },
    unload: (componentName) => {
      dispatch(componentActions.componentUnload(componentName, ownProps.params));
    }
  };
}

export default compose(withParams, connect(mapStateToProps, mapDispatchToProps))(EditCustomerContainer);
