import React from 'react';
import PropTypes from 'prop-types';
import WistiaVideo from './WistiaVideo';

const FCAVideos = ({ source, email }) => {
  let videoSource;
  if (source === 'hirepurchase') {
    videoSource = 'b4kwzjf4eb';
  } else if (source === 'pcpurchase') {
    videoSource = 'gopav72b3z';
  } else if (source === 'lpplan') {
    videoSource = 'yn3vtju5dn';
  }

  return (
    <div>
      <WistiaVideo source={videoSource} email={email} />
    </div>
  );
};

FCAVideos.propTypes = {
  source: PropTypes.string,
  email: PropTypes.string
};

export default FCAVideos;
