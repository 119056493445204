import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../Common/Icon/Icon';
import { formatNumber } from '../../../../core/helpers';
import './vehicleDetailsMileage.scss';
import classnames from 'classnames';
import { inject } from 'mobx-react';

const VehicleDetailsMileage = ({ mileage, isSmall, appStore }) => {
  const { t } = useTranslation('Customer');
  let classes = classnames(!isSmall && 'vehicleDetailsMileage', isSmall && 'vehicleDetailsMileage--small');
  return (
    <div className={classes} data-th="VehicleDetailsMileage">
      <div className="vehicleDetailsMileage__icon">
        <Icon name="mileage" />
      </div>
      <div className="vehicleDetailsMileage__text">
        {mileage
          ? `${formatNumber(mileage, 0, appStore.uiState.countryCode)} ${t('VehicleDetailsMileage.miles')}`
          : t('VehicleDetailsMileage.no_mileage_data')}
      </div>
    </div>
  );
};

VehicleDetailsMileage.propTypes = {
  mileage: PropTypes.number,
  isSmall: PropTypes.bool,
  appStore: PropTypes.object
};
export default inject('appStore')(VehicleDetailsMileage);
