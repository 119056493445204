import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './partExchangePanel.scss';
import Table from './SummaryTable/Table';
import TableRow from './SummaryTable/TableRow';
import TableHead from './SummaryTable/TableHead';
import TableCell from './SummaryTable/TableCell';
import OuterLeft from './SummaryTable/OuterLeft';
import OuterRight from './SummaryTable/OuterRight';
import MoneyFormat from '../../Common/MoneyFormat';

const PartExchangePanelItem = ({ partExchange, index, partExchangesCount }) => {
  const { t } = useTranslation('Reporting');
  return (
    <div className="partExchangePanel__item">
      {partExchangesCount > 1 && (
        <div className="partExchangePanel__itemHeader">
          {t('PartExchangePanelItem.part_exchange')} {index + 1}
        </div>
      )}
      <OuterLeft>
        <Table>
          <TableRow>
            <TableHead width="50%">{t('PartExchangePanelItem.make')}</TableHead>
            <TableCell>{partExchange.Make}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('PartExchangePanelItem.model')}</TableHead>
            <TableCell>{partExchange.Model}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('PartExchangePanelItem.derivative')}</TableHead>
            <TableCell>{partExchange.Derivative}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('PartExchangePanelItem.registration_number')}</TableHead>
            <TableCell>{partExchange.Vrm}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('PartExchangePanelItem.condition')}</TableHead>
            <TableCell>{partExchange.Condition}</TableCell>
          </TableRow>
        </Table>
      </OuterLeft>
      <OuterRight>
        <Table>
          <TableRow>
            <TableHead width="50%">{t('PartExchangePanelItem.service_history')}</TableHead>
            <TableCell>{partExchange.ServiceHistory}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('PartExchangePanelItem.number_of_keys')}</TableHead>
            <TableCell>{partExchange.SetsOfKeys}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('PartExchangePanelItem.number_of_previous_owners')}</TableHead>
            <TableCell>{partExchange.NumberOfPreviousOwners}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('PartExchangePanelItem.estimated_value')}</TableHead>
            <TableCell>
              <MoneyFormat value={partExchange.EstimatedValue} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('PartExchangePanelItem.settlement_figure')}</TableHead>
            <TableCell>
              <MoneyFormat value={partExchange.OutstandingFinance} />
            </TableCell>
          </TableRow>
        </Table>
      </OuterRight>
    </div>
  );
};

PartExchangePanelItem.propTypes = {
  partExchange: PropTypes.object,
  index: PropTypes.number,
  partExchangesCount: PropTypes.number
};
export default PartExchangePanelItem;
