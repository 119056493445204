import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ApplicationPage from '../../Application/components/ApplicationPage';
import AddressHistoryForm from '../../Common/forms/AddressHistoryForm';

const CFCAddressHistory = ({ t, defaultValues, onSubmit, validationRules, dealershipId, progress, application }) => {
  return (
    <ApplicationPage
      title={t('CFCAddressHistory.address_history')}
      subTitle={t('CFCAddressHistory.please_enter_at_least_3_years_of_address_history')}
    >
      <AddressHistoryForm
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        validationRules={validationRules}
        dealershipId={dealershipId}
        progress={progress}
        application={application}
      />
    </ApplicationPage>
  );
};

CFCAddressHistory.propTypes = {
  defaultValues: PropTypes.object,
  validationRules: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dealershipId: PropTypes.string,
  application: PropTypes.object
};
export default withTranslation('Quoting')(CFCAddressHistory);
