import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import ApplicationPage from './ApplicationPage';
import OrganisationContactsForm from '../forms/OrganisationContactsForm';
import { compose } from 'redux';
import { withOutletContextProps, withParams } from 'hocs/router';

class OrganisationContacts extends React.Component {
  static propTypes = {
    params: PropTypes.object,
    application: PropTypes.object,
    applicationFields: PropTypes.object,
    validationRules: PropTypes.object,
    submitApplicationSection: PropTypes.func,
    saveApplicationSection: PropTypes.func
  };
  handleSubmit = (formData) => {
    const { dealershipId, consumerId, applicantId } = this.props.params;
    this.props.submitApplicationSection(
      'OrganisationContacts',
      this.props.params.applicantId,
      formData.Items,
      `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/bankdetails`
    );
  };
  handleSave = (formData) => {
    this.props.saveApplicationSection('OrganisationContacts', this.props.params.applicantId, formData.Items);
  };

  render() {
    return (
      <ApplicationPage title={this.props.t('OrganisationContacts.organisation_contacts')}>
        <OrganisationContactsForm
          defaultValues={this.props.application.OrganisationContacts}
          onSubmit={this.handleSubmit}
          onSave={this.handleSave}
          applicationFields={this.props.applicationFields.OrganisationContacts}
          validationRules={this.props.validationRules.OrganisationContacts}
          savingState={this.props.application.sectionSavingState}
          submittingState={this.props.application.sectionSubmittingState}
          dealershipId={this.props.params.dealershipId}
          quoteId={this.props.application.QuoteId}
          progress={this.props.progress}
          funderCode={this.props.application?.Quote?.FunderCode}
        />
      </ApplicationPage>
    );
  }
}

export default compose(withTranslation('Application'), withParams, withOutletContextProps)(OrganisationContacts);
