import { Trans, withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { countItemsInObject } from '../../../core/helpers';
import ApplicationPage from '../../Application/components/ApplicationPage';
import FormFooter from '../../Common/Form/FormFooter';
import CFCSummaryTables from './CFCSummaryTables';
import CheckboxWithLabel from '../../Common/Form/CheckboxWithLabel';
import UserName from '../../Common/UserName';
import ExclamationWarning from '../../Common/ExclamationWarning';
import './cfcSummary.scss';
import { inject, observer } from 'mobx-react';
import CrainDisclaimerModal from '../modals/CrainDisclaimerModal';

class CFCSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkbox: false,
      termsModalOpen: false
    };
  }

  handleSubmit = () => {
    if (this.state.checkbox) {
      this.props.onSubmit();
      this.setState({
        termsModalOpen: false
      });
    } else {
      if (this.refs.checkboxWarning) {
        this.refs.checkboxWarning.style.display = 'block';
      }
    }
  };
  handleCheckboxUpdate = (checked) => {
    this.setState({
      checkbox: checked
    });
  };
  handleTermsModalStatus = () => {
    if (!this.state.checkbox) {
      return (this.refs.checkboxWarning.style.display = 'block');
    }

    this.setState((prevState) => ({
      termsModalOpen: !prevState.termsModalOpen
    }));
  };
  isPersonalDetailsValid = () => {
    let numErrors = countItemsInObject(_.get(this.props.cfcCheck, 'validationErrors.PersonalDetails'));
    return numErrors === 0;
  };

  render() {
    const readyToSubmit = countItemsInObject(_.get(this.props.cfcCheck, 'validationErrors')) === 0;
    let firstName = _.get(this.props.cfcCheck.PersonalDetails, 'FirstName') || '';
    let lastName = _.get(this.props.cfcCheck.PersonalDetails, 'LastName') || '';
    return (
      <ApplicationPage>
        <div>
          <CFCSummaryTables
            cfcCheck={this.props.cfcCheck}
            validationErrors={this.props.cfcCheck.validationErrors}
            showValidationTicks={true}
            vehicle={this.props.vehicle}
            errorCount={this.props.errorCount}
          />
        </div>

        {this.isPersonalDetailsValid() && (
          <div>
            <div className="cfcSummary__divider" />
            <div className="cfcSummary__checkbox">
              <CheckboxWithLabel onChange={this.handleCheckboxUpdate} id="readBenefits" value={this.state.checkbox}>
                <Trans
                  ns="Quoting"
                  i18nKey="CFCSummary.authorisation_confirmation"
                  components={{
                    CustomerName: (
                      <span className="cfcSummary__highlight">
                        {firstName} {lastName}
                      </span>
                    ),
                    TermsLink: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cfcSummary__tnc"
                        href="https://my.newvehicle.com/finance-check-terms-and-conditions"
                      />
                    ),
                    UserName: (
                      <span className="cfcSummary__highlight">
                        <UserName />
                      </span>
                    )
                  }}
                />
              </CheckboxWithLabel>
              <div className="cfcSummary__checkboxWarning" ref="checkboxWarning">
                {this.props.t('CFCSummary.you_must_confirm_this_to_proceed')}
              </div>
            </div>
          </div>
        )}

        {this.props.hasAddressError && (
          <ExclamationWarning
            title={this.props.t('CFCSummary.the_customer_cannot_be_found_at_the_given_address')}
            message={this.props.t(
              'CFCSummary.please_make_sure_the_personal_and_address_details_are_entered_and_up_to_date'
            )}
          />
        )}

        <CrainDisclaimerModal
          isOpen={this.state.termsModalOpen}
          onClose={this.handleTermsModalStatus}
          onContinue={this.handleSubmit}
        />

        <FormFooter
          submitLabel={this.props.t('CFCSummary.continue')}
          onSubmit={this.handleTermsModalStatus}
          submittingState={this.props.checkingEligibilityState}
          submitDisabled={!readyToSubmit}
        />
      </ApplicationPage>
    );
  }
}

CFCSummary.propTypes = {
  vehicle: PropTypes.object.isRequired,
  cfcCheck: PropTypes.object.isRequired,
  validationRules: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  checkingEligibilityState: PropTypes.string,
  hasAddressError: PropTypes.bool
};
export default withTranslation('Quoting')(inject('appStore')(observer(CFCSummary)));
