import { observable, computed, action } from 'mobx';

class Request {
  @observable isSuccessful = false;
  @observable isLoading = false;
  @observable error = null;

  onSuccessCallback;
  onErrorCallback;
  delay = null;

  setDelay = (ms) => {
    this.delay = ms;
    return this;
  };

  @action
  call(func, ...args) {
    const promise = func.apply(null, args);
    this.isLoading = true;
    this.error = null;
    this.isSuccessful = false;

    return promise.then(this.handleSuccessWithDelay).catch(this.handleErrorWithDelay);
  }

  handleSuccessWithDelay = (response) => {
    if (this.delay) {
      setTimeout(() => this.handleSuccess(response), this.delay);
    } else {
      this.handleSuccess(response);
    }

    return response;
  };

  handleErrorWithDelay = (response) => {
    if (this.delay) {
      setTimeout(() => this.handleError(response), this.delay);
    } else {
      this.handleError(response);
    }

    return response;
  };

  @action
  handleSuccess = (response) => {
    this.onSuccessCallback && this.onSuccessCallback(response);
    this.isLoading = false;
    this.isSuccessful = true;
  };

  @action
  handleError = (error) => {
    this.onErrorCallback && this.onErrorCallback(error);
    this.isLoading = false;
    this.error = error || 'Error';
  };

  onSuccess(func) {
    //todo allow registration of multiple callbacks
    this.onSuccessCallback = func;
    return this;
  }

  onError(error) {
    //todo allow registration of multiple callbacks
    this.onErrorCallback = error;
    return this;
  }

  @action
  reset() {
    this.isLoading = false;
    this.error = null;
    this.isSuccessful = false;

    return this;
  }

  @computed
  get hasError() {
    return !!this.error;
  }
}

export default Request;
