import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './webFinanceApplications.scss';
import Pagination from '../../Common/Pagination';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelToolBar from '../../Common/PanelToolBar';
import WebFinanceApplicationsItem from './WebFinanceApplicationsItem';
import DashboardFilter from './DashboardFilter';
import DashboardListHeader from './DashboardListHeader';

const WebFinanceApplications = ({
  items,
  filter,
  onFilter,
  dealership,
  handleClick,
  currentPage,
  totalItems,
  pageSize,
  changePage
}) => {
  const { t } = useTranslation('Dashboard');
  return (
    <div className="webFinanceApplications">
      <Panel>
        <PanelHeader>{t('WebFinanceApplications.finance_applications')}</PanelHeader>
        <PanelToolBar hasPadding={false}>
          <div className="webFinanceApplications__filter">
            <DashboardFilter filter={filter} onClick={onFilter} />
          </div>
        </PanelToolBar>
        {items && items.length > 0 ? (
          <div className="webFinanceApplications__applicant">
            <DashboardListHeader />
            <div>
              {items.map((info, index) => (
                <WebFinanceApplicationsItem
                  application={info}
                  key={index}
                  dealership={dealership}
                  handleClick={handleClick}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="webFinanceApplications__noApplications">
            {t('WebFinanceApplications.you_have_no_current_finance_applications')}
          </div>
        )}
        <div className="webFinanceApplications__paginationContainer">
          <Pagination currentPage={currentPage} totalItems={totalItems} pageSize={pageSize} onChange={changePage} />
        </div>
      </Panel>
    </div>
  );
};

WebFinanceApplications.propTypes = {
  items: PropTypes.array,
  filter: PropTypes.string,
  onFilter: PropTypes.func,
  dealership: PropTypes.object,
  handleClick: PropTypes.func,
  changePage: PropTypes.func.isRequired,
  totalItems: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
export default WebFinanceApplications;
