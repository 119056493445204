import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './linkedUsersModal.scss';
import _ from 'lodash';
import Button from '../../Common/Button/Button';
import LinkedUsersModalItem from './LinkedUsersModalItem';

const LinkedUsersModal = ({ aliases, t, onClose }) => {
  const array = aliases;

  const aliasesMinusUser = _.drop(array);

  return (
    <div>
      <div className="linkedUsersModal__header">
        {t('LinkedUsersModal.this_email_address_has_also_been_used_in_applications_for')}
      </div>
      <div className="linkedUsersModal__main">
        {aliasesMinusUser.length > 1 ? (
          <ul>
            {aliasesMinusUser.map((alias, index) => (
              <li>
                <LinkedUsersModalItem alias={alias} key={index} />
              </li>
            ))}
          </ul>
        ) : (
          <>
            {aliasesMinusUser.map((alias, index) => (
              <LinkedUsersModalItem alias={alias} key={index} />
            ))}
          </>
        )}
      </div>
      <div className="linkedUsersModal__bottom">
        <div className="linkedUsersModal__bottom--btn" key="btn">
          <Button onClick={onClose} buttonStyle="cancel">
            {t('LinkedUsersModal.close')}
          </Button>
        </div>
      </div>
    </div>
  );
};

LinkedUsersModal.propTypes = {
  count: PropTypes.number,
  aliases: PropTypes.array,
  onClose: PropTypes.func
};

export default withTranslation('Customer')(LinkedUsersModal);
