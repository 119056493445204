import platformApiUtils from '@ivendi/platform-api-utils';

export function fetchProductTypes() {
  return platformApiUtils.get('v1/options/vaps/product-types');
}

export function fetchValueAddedProducts(dealershipId) {
  return platformApiUtils.get(`v1/dealershipSettings/${dealershipId}/vaps`);
}

export function addProduct(dealershipId, product) {
  return platformApiUtils.post(`v1/dealershipSettings/${dealershipId}/vap`, {
    ProductTypeCode: product.productTypeCode,
    Name: product.name,
    Description: product.description,
    Price: product.price,
    Visible: product.visibility,
    Default: product.isDefault,
    VapId: product.id
  });
}

export function removeProduct(dealershipId, vapId) {
  return platformApiUtils.del(`v1/dealershipSettings/${dealershipId}/vap`, {
    VapId: vapId
  });
}

export function editProduct(dealershipId, product) {
  return platformApiUtils.put(`v1/dealershipSettings/${dealershipId}/vap`, {
    Name: product.name,
    Description: product.description,
    Price: product.price,
    VapId: product.id,
    ProductTypeCode: product.productTypeCode,
    Visible: product.visibility,
    Default: product.isDefault
  });
}

export function setVisibility(dealershipId, vapId, isVisible) {
  return platformApiUtils.put(`v1/dealershipSettings/${dealershipId}/vap/visibility`, {
    VapId: vapId,
    Visible: isVisible
  });
}

export function setDefault(dealershipId, vapId, isDefault) {
  return platformApiUtils.put(`v1/dealershipSettings/${dealershipId}/vap/default`, {
    VapId: vapId,
    Default: isDefault
  });
}
