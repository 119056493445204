import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import AssignLeadModalUserList from './AssignLeadModalUserList';
import AssignLeadModalUserListItem from './AssignLeadModalUserListItem';
import FormFooter from '../../Common/Form/FormFooter';
import ConsumerName from '../../Common/ConsumerName';
import './assignLeadFormNonEditable.scss';

const AssignLeadFormView = (props) => {
  const { t } = useTranslation('AssignLead');
  return (
    <div data-th="AssignLeadFormView">
      <h1 className="assignLeadFormNonEditable__title">{t('AssignLeadFormView.assigned_leads')}</h1>
      <div className="assignLeadFormNonEditable__divider assignLeadFormNonEditable__dividerTop" />

      <span className="assignLeadFormNonEditable__customerNameTitle">{t('AssignLeadFormView.customer_name')}</span>
      <span className="assignLeadFormNonEditable__customerName">
        <ConsumerName name={props.customerName} />
      </span>

      <div className="assignLeadFormNonEditable__divider assignLeadFormNonEditable__dividerBottom" />

      <h2 className="assignLeadFormNonEditable__subHeading">{t('AssignLeadFormView.users_assigned_to_this_lead')}</h2>

      <div className="assignLeadFormNonEditable__userList">
        <AssignLeadModalUserList>
          {!props.currentLeadAssignees.length && (
            <li className="assignLeadFormNonEditable__noAssignedUsers">
              {t('AssignLeadFormView.no_users_have_been_assigned_yet')}
            </li>
          )}
          {props.currentLeadAssignees.map((assignee, index) => (
            <AssignLeadModalUserListItem
              key={`assignee${index}`}
              isLast={index === props.currentLeadAssignees.length - 1}
              nonEditable
              assignee={assignee}
            />
          ))}
        </AssignLeadModalUserList>
      </div>

      <FormFooter submittingState={props.submittingState} hideSubmit onCancel={props.onCancel} />
    </div>
  );
};

AssignLeadFormView.propTypes = {
  customerName: PropTypes.string,
  assignees: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  currentLeadAssignees: PropTypes.object,
  submittingState: PropTypes.string
};
export default AssignLeadFormView;
