import React, { useState, useEffect } from 'react';
import { inject } from 'mobx-react';
import cn from 'classnames';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';

import Button from '~Common/Button/Button';
import { updateImages, deleteImage } from '~/api/stockModule';

import { generateImageUrlFromIds } from '../../../../../../../../../../shared/helpers/vehicleImages';

import { DragItem } from './components';

import './stockUploadedImages.scss';
import { useParams } from 'react-router-dom';

const StockUploadedImages = (props) => {
  const { t } = useTranslation('Stock');
  const stockEditStore = props.appStore.stockEditStore;
  const { pushDangerNotification } = props.appStore.notificationStore;
  const { dealershipId, vehicleId } = useParams();
  const [imageCategory, setImageCategory] = useState({ main: [], imperfection: [] });
  const [hasOrderChanged, setHasOrderChanged] = useState({ main: false, imperfection: false });

  const setGallery = (items, tag) => {
    if (tag === 'null' || tag === 'main') {
      setImageCategory((state) => ({ ...state, main: items }));
    } else {
      setImageCategory((state) => ({ ...state, [tag]: items }));
    }
  };
  const discardGalleryOrder = (tag) => {
    if (tag === 'null' || tag === 'main') {
      setHasOrderChanged({ ...hasOrderChanged, main: false });
    } else {
      setHasOrderChanged({ ...hasOrderChanged, [tag]: false });
    }
  };

  // set gallery and check if there are imperfection
  // skip if User is re-ordering gallery
  useEffect(() => {
    const allImages = toJS(props.imagesMetadata);

    if (!hasOrderChanged.main) {
      const images = allImages.filter((item) => item.tag !== 'imperfection');
      setGallery(images, 'main');
    }
    if (!hasOrderChanged.imperfection) {
      const images = allImages.filter((item) => item.tag === 'imperfection');
      setGallery(images, 'imperfection');
    }
  }, [props.imagesMetadata, hasOrderChanged]);

  const handleDiscardOrder = (tag) => {
    const allImages = toJS(props.imagesMetadata);

    const items =
      tag === 'imperfections'
        ? allImages.filter((item) => item.tag === tag)
        : allImages.filter((item) => item.tag !== 'imperfection');

    setGallery(items, tag);
    discardGalleryOrder(tag);
  };

  const handleUpdateOrder = async (tag) => {
    const allImages = toJS(props.imagesMetadata);

    // updating a gallery use the remaining gallery's old order instead of new order.
    let updatedOrder;
    if (tag === 'imperfection') {
      // ensure imperfection images are at the end of the list
      updatedOrder = [...allImages.filter((item) => item.tag !== 'imperfection'), ...imageCategory.imperfection];
    } else {
      // ensure imperfection images are at the end of the list
      updatedOrder = [...imageCategory.main, ...allImages.filter((item) => item.tag === 'imperfection')];
    }
    const status = await updateImages(dealershipId, vehicleId, updatedOrder);

    if (status === 200) {
      discardGalleryOrder(tag);
      stockEditStore.setVehicleImages(updatedOrder);
    } else {
      pushDangerNotification('Something went wrong, please try again later', 'Danger');
    }
  };

  function getNewOrder(array, currentIndex, destinationIndex) {
    array.splice(currentIndex, 0, array.splice(destinationIndex, 1)[0]);
    return array;
  }

  const handleMoveItem = (sourceId, destinationId, tag) => {
    // init re-order UI state
    if (!hasOrderChanged[tag]) {
      setHasOrderChanged({ ...hasOrderChanged, [tag]: true });
    }
    const sourceIndex = imageCategory[tag].findIndex((item) => item.fileName === sourceId);
    const destinationIndex = imageCategory[tag].findIndex((item) => item.fileName === destinationId);
    const newOrder = getNewOrder(imageCategory[tag], sourceIndex, destinationIndex);

    setGallery(newOrder, tag);
  };

  const handleDeleteImage = async (imageName) => {
    const status = await deleteImage(dealershipId, vehicleId, imageName);

    if (status === 200) {
      const allImages = toJS(props.imagesMetadata);
      const newImgArr = allImages.filter((item) => item.fileName !== imageName);

      //
      setHasOrderChanged({ main: false, imperfection: false });
      stockEditStore.setVehicleImages(newImgArr);
    } else {
      pushDangerNotification('Something went wrong, please try again later', 'Danger');
    }
  };

  const hideUploadButton = !hasOrderChanged.main;
  const showImperfections = imageCategory.imperfection.length > 0;

  return (
    <>
      {/* <h3 className="stockUploadedImages__panelSubTitle">Uploaded images:</h3> */}
      {/* <div className="stockUploadedImages__explanation">
                 <Icon name="information" />
                 <p>
                     The images below are displayed in your vehicle's gallery in the order they appear below. Drag images
                     to reorder them. Images may take up to 6 hours to appear.
                 </p>
             </div> */}
      <div className="stockUploadedImages__actionBar">
        {hideUploadButton && (
          <Button className="stockUploadedImages__uploadButton" onClick={props.showUploadModal}>
            {t('StockUploadedImages.upload_images')}
          </Button>
        )}

        {hasOrderChanged.main && (
          <div className="stockUploadedImages__sortButtons">
            <Button
              className="stockUploadedImages__discardButton"
              buttonStyle="cancel"
              onClick={() => handleDiscardOrder('main')}
            >
              {t('StockUploadedImages.discard_order')}
            </Button>
            <Button buttonStyle="secondary" onClick={() => handleUpdateOrder('main')}>
              {t('StockUploadedImages.update_order')}
            </Button>
          </div>
        )}
      </div>
      <div className="stockUploadedImages__editImageGallery">
        {imageCategory.main.map(({ fileName }, index) => {
          const imgUrl = generateImageUrlFromIds({ fileName, dealershipId, vehicleId });
          const displayHeroLabel = index === 0;
          return (
            <DragItem
              className="stockUploadedImages__dragWrapper"
              key={fileName}
              id={fileName}
              gallery="main"
              onMoveItem={(sourceId, destinationId) => handleMoveItem(sourceId, destinationId, 'main')}
            >
              <div>
                <div onClick={() => handleDeleteImage(fileName)} className="stockUploadedImages__dragNumber">
                  {index + 1}
                </div>
                <div className="stockUploadedImages__dragImage" style={{ backgroundImage: `url(${imgUrl})` }} />
                {displayHeroLabel && (
                  <div className={cn(displayHeroLabel && 'stockUploadedImages__dragHeroLabel')}>
                    {t('StockUploadedImages.primary_image')}
                  </div>
                )}
              </div>
            </DragItem>
          );
        })}
      </div>
      {showImperfections && (
        <>
          <div className="stockUploadedImages__actionBar">
            <div>
              <span
                className={cn('stockUploadedImages__imperfectionTitle', {
                  'stockUploadedImages__imperfectionTitle--hiddenMobile': hasOrderChanged.imperfection
                })}
              >
                {t('StockUploadedImages.imperfection')}
              </span>
            </div>
            {hasOrderChanged.imperfection && (
              <div className="stockUploadedImages__sortButtons">
                <Button
                  className="stockUploadedImages__discardButton"
                  buttonStyle="cancel"
                  onClick={() => handleDiscardOrder('imperfection')}
                >
                  {t('StockUploadedImages.discard_order')}
                </Button>
                <Button buttonStyle="secondary" onClick={() => handleUpdateOrder('imperfection')}>
                  {t('StockUploadedImages.update_order')}
                </Button>
              </div>
            )}
          </div>

          <div className="stockUploadedImages__editImageGallery">
            {imageCategory.imperfection.map(({ fileName }, index) => {
              const imgUrl = generateImageUrlFromIds({ fileName, dealershipId, vehicleId });
              return (
                <DragItem
                  className="stockUploadedImages__dragWrapper"
                  key={fileName}
                  id={fileName}
                  gallery="imperfection"
                  onMoveItem={(sourceId, destinationId) => handleMoveItem(sourceId, destinationId, 'imperfection')}
                >
                  <div>
                    <div onClick={() => handleDeleteImage(fileName)} className="stockUploadedImages__dragNumber">
                      {index + 1}
                    </div>
                    <div className="stockUploadedImages__dragImage" style={{ backgroundImage: `url(${imgUrl})` }} />
                  </div>
                </DragItem>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default inject('appStore')(StockUploadedImages);
