import { action, observable, computed } from 'mobx';
import objectValidation from '../core/objectValidation';
import { countItemsInObject } from '../core/helpers';
import { CountryCodesJS } from '~/constants';

class Validator {
  @observable errors = {};
  filteredRulesList = {};

  constructor(countryCode = 'gb') {
    this.countryCode = countryCode;
  }

  /*
   * setRules() iterates through the provided rules object and determines if a rule should be added to the filteredRulesList:
   *
   * STANDARD rules can be immediately added to the filteredRulesList without alteration. They will be passed as a string or an array.
   *  e.g  Advance: 'currency' or  ValueAddedProducts: [{ProductTypeCode: 'required', Id: 'required' } ]
   *
   * COUNTRY CODE SPECIFIC rules are provided when a field has differing rules dependent on countryCode
   *  e.g DateOfHandover: { de: 'date', gb: 'date', default: 'date, required' }
   *  In this scenario, setRules will only add to its filteredRulesList the rule that is a match for the countryCode in state
   *  If no countryCode-specific rule is found for the current country code, it falls back to the 'default' rule, if available, or sets the rule to null.
   *
   */

  setRules = (rules) => {
    for (let fieldName in rules) {
      const rule = rules[fieldName];
      let countryCodeSpecificRule = null;

      if (this.isCountryCodeSpecificRule(rule)) {
        countryCodeSpecificRule = this.getCountryCodeRule(rule, this.countryCode);
      }

      if (countryCodeSpecificRule) {
        this.filteredRulesList[fieldName] = countryCodeSpecificRule;
      } else if (!this.isCountryCodeSpecificRule(rule)) {
        this.filteredRulesList[fieldName] = rule;
      }
    }
  };

  // checks rule is countryCode-specific rule. Should be object format e.g, DateOfHandover: { de: 'date', gb: 'date', default: 'date, required' }
  isCountryCodeSpecificRule = (rule) => {
    return (
      !Array.isArray(rule) &&
      typeof rule === 'object' &&
      rule !== null &&
      CountryCodesJS.some((x) => rule[x] !== undefined)
    );
  };

  // returns countryCode-specific rule if it has key matching the countryCode in state, otherwise returns default fallback or null
  getCountryCodeRule = (rule, countryCode) => {
    if (rule[countryCode] && CountryCodesJS.includes(countryCode)) {
      return rule[countryCode];
    } else {
      return rule['default'] || null;
    }
  };

  @action
  validate = (data) => {
    this.errors = objectValidation(data, this.filteredRulesList);
  };

  @computed
  get errorCount() {
    return countItemsInObject(this.errors);
  }

  getErrors() {
    return this.errors || {};
  }
}

export default Validator;
