import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { inject } from 'mobx-react';

import Panel from '~Common/Panel';
import PanelContent from '~Common/PanelContent';
import PanelHeader from '~Common/PanelHeader';
import SelectInput from '~Common/Form/SelectInput';
import TextInput from '~Common/Form/TextInput';
import Button from '~Common/Button/Button';
import MobxForm from '~MobxForm/MobxForm';
import MobxFormLabel from '~MobxForm/MobxFormLabel';
import MobxFieldGroup from '~MobxForm/MobxFieldGroup';
import { fetchVehicle, fetchVisVehicle } from '~/api/stockModule';
import * as debug from '~/debug';

import { useValidator } from '../../../../shared/hooks';

import './stockLookupContainer.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { StockBreadcrumbs, StockContainerLayout } from '../../../../shared/components';

const validationRules = {
  'vrm/vin': 'required',
  condition: 'required'
};

const StockLookupContainer = (props) => {
  const { t } = useTranslation('Stock');
  const navigate = useNavigate();
  const params = useParams();
  const [formData, setFormData] = useState({
    'vrm/vin': '',
    condition: 'used'
  }); // idle|submitting|error

  const [formState, setFormState] = useState('idle');
  const isLoading = formState === 'submitting';
  const hasError = formState === 'error';
  const { errors, errorCount, validate } = useValidator(validationRules);
  const { isDe } = props.appStore.uiState;
  useEffect(() => {
    validate(formData);
  }, [formData, validate]);

  const handleChange = (name, value) => {
    setFormData((formData) => ({ ...formData, [name]: value }));
  };

  const handleSubmit = async (evt) => {
    if (errorCount > 0) return;
    setFormState('submitting');

    try {
      let visData;

      if (isDe) {
        // Cap lookup is not set up for DE for now
        // we'll skip the lookup and navigate to the next page with vrm/vin formData
        handleFetchVehicleSuccess();
        return;
      }
      const vehicleData = await fetchVehicle(formData['vrm/vin']);
      if (vehicleData.CapData.length === 1) {
        const { CapCode, CapId, VehicleClass } = vehicleData.CapData[0];
        visData = await fetchVisVehicle(CapCode, CapId, VehicleClass);
      }

      handleFetchVehicleSuccess(vehicleData, visData);
    } catch (e) {
      if (e.status === 404) {
        handleFetchVehicleSuccess();
      } else {
        handleFetchVehicleError(e);
      }
    }
  };

  const handleFetchVehicleSuccess = (capData, taxonomyData) => {
    const {
      setCapDataResponse,
      setTaxonomyDataResponse,
      setMiscVehicleData,
      setVehicleTaxonomyData
    } = props.appStore.stockCreateStore;
    setCapDataResponse(capData);

    if (taxonomyData) {
      setTaxonomyDataResponse(taxonomyData);
    }

    const vehicleMiscData = {
      condition: formData.condition,
      lookupTerm: formData['vrm/vin']
    };
    setMiscVehicleData(vehicleMiscData);

    if (taxonomyData && taxonomyData.response.length >= 1) {
      const vehicleData = taxonomyData.response[0];
      setVehicleTaxonomyData(vehicleData);
      navigate(`/d/${params.dealershipId}/stock/add/create`);
    } else {
      navigate(`/d/${params.dealershipId}/stock/add/refine`);
    }
  };

  const handleFetchVehicleError = (e) => {
    setFormState('error');
    debug.error(e);
  };

  return (
    <StockContainerLayout>
      {/* StockContainerLayout expects an array of children (?)
             or at least an array like object.
         */}
      {[
        <div key="add vehicle record panel">
          <StockBreadcrumbs
            items={[
              {
                name: 'Stock List',
                path: `/d/${params.dealershipId}/stock/list`
              },
              {
                name: t('StockLookupContainer.add_vehicle_record')
              }
            ]}
          />
          <Panel>
            <PanelHeader>{t('StockLookupContainer.add_vehicle_record')}</PanelHeader>
            <PanelContent>
              <MobxForm onSubmit={handleSubmit}>
                <MobxFieldGroup isInline error={errors.condition}>
                  <MobxFormLabel htmlFor="condition">{t('StockLookupContainer.condition')}</MobxFormLabel>
                  <SelectInput
                    emptyValue={false}
                    options="VehicleType"
                    id="condition"
                    value={formData.condition}
                    onChange={handleChange}
                  />
                </MobxFieldGroup>

                <MobxFieldGroup isInline error={errors['vrm/vin']} className="stockLookupContainer__vrm-vin-lookup">
                  <MobxFormLabel htmlFor="vrm/vin">{t('StockLookupContainer.vrm_vin')}</MobxFormLabel>
                  <TextInput id={'vrm/vin'} onChange={handleChange} value={formData['vrm/vin']} />
                  <Button
                    isLoading={isLoading}
                    hasError={hasError}
                    disabled={isLoading}
                    buttonStyle="primary"
                    size="small"
                    dataThook="stockLookupBtn"
                  >
                    {t('StockLookupContainer.lookup')}
                  </Button>
                </MobxFieldGroup>
              </MobxForm>
            </PanelContent>
          </Panel>
        </div>
      ]}
    </StockContainerLayout>
  );
};

export default inject('appStore')(StockLookupContainer);
