import React from 'react';
import PropTypes from 'prop-types';
import CheckboxWithLabel from '../../Common/Form/CheckboxWithLabel';
import InformationWarning from '../../Common/InformationWarning';
import FormConsumerVehicleDetails from '../../Common/FormConsumerVehicleDetails';
import { observer, inject } from 'mobx-react';
import { observable, action, reaction, toJS } from 'mobx';
import Validator from '../../../validators/Validator';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxLabel from '../../MobxForm/MobxFormLabel';
import DateField from '../../Common/Form/DateField';
import TextInputWrappingInput from '../../Common/Form/TextInputWrappingInput';
import CurrencyInput from '../../Common/Form/CurrencyInput';
import PercentageInput from '../../Common/Form/PercentageInput';
import SelectInput from '../../Common/Form/SelectInput';
import Button from '../../Common/Button/Button';
import AddVapModal from '../../Quoting/forms/AddVapModal';
import ValueAddedProduct from '../../Quoting/forms/ValueAddedProduct';
import _ from 'lodash';
import { compose } from 'redux';
import { withNavigate } from '../../../hocs/router';
import './paidOutForm.scss';

@observer
class PaidOutForm extends React.Component {
  constructor() {
    super();
    this.validationRules = {
      DateOfPurchase: 'required, date',
      DateOfHandover: 'date',
      TotalPrice: 'currency',
      VehiclePrice: 'currency',
      BasicPrice: 'currency',
      VAT: 'currency',
      VATAmount: 'currency',
      NonVatableItems: 'currency',
      AccessoriesInsurance: 'currency',
      AccessoriesWarranty: 'currency',
      AccessoriesOther: 'currency',
      CashDeposit: 'currency',
      PartExchange: 'currency',
      Settlement: 'currency',
      Advance: 'currency',
      AnnualDistanceQuoted: 'number',
      ChargePerOverDistanceUnit: 'currency',
      AcceptanceFeeLast: 'currency',
      OptionToPurchaseFee: 'currency',
      FlatRate: 'number',
      RepresentativeApr: 'number',
      Term: 'number',
      FirstPayment: 'currency',
      FollowedBy: 'number',
      FollowingPayments: 'currency',
      FinalPayment: 'currency',
      TotalAmountPayable: 'currency',
      InterestCharges: 'currency',
      Commission: 'currency',
      AdditionalCommission: 'currency'
    };

    this.state = {
      isVapModalOpen: false,
      ValueAddedProducts: [],
      vapToEdit: null
    };
  }

  @observable
  formData = {
    DateOfPurchase: '',
    DateOfHandover: '',
    TotalPrice: '',
    VehiclePrice: '',
    BasicPrice: '',
    VAT: '',
    VATAmount: '',
    NonVatableItems: '',
    AccessoriesInsurance: '',
    AccessoriesWarranty: '',
    AccessoriesOther: '',
    CashDeposit: '',
    PartExchange: '',
    Settlement: '',
    Advance: '',
    AnnualDistanceQuoted: '',
    ChargePerOverDistanceUnit: '',
    AcceptanceFeeLast: '',
    OptionToPurchaseFee: '',
    FlatRate: '',
    RepresentativeApr: '',
    Term: '',
    FirstPayment: '',
    FollowedBy: '',
    FollowingPayments: '',
    FinalPayment: '',
    TotalAmountPayable: '',
    InterestCharges: '',
    Commission: '',
    AdditionalCommission: '',
    FunderCode: '',
    OtherFunder: '',
    FinanceType: '',
    RemoveFromDashboard: false,
    ValueAddedProducts: []
  };

  @action setDateOfPurchase = (DateOfPurchase) => (this.formData.DateOfPurchase = DateOfPurchase.currentTarget.value);
  @action setDateOfHandover = (DateOfHandover) => (this.formData.DateOfHandover = DateOfHandover.currentTarget.value);
  @action setTotalPrice = (TotalPrice) => (this.formData.TotalPrice = TotalPrice);
  @action setVehiclePrice = (VehiclePrice) => (this.formData.VehiclePrice = VehiclePrice);
  @action setBasicPrice = (BasicPrice) => (this.formData.BasicPrice = BasicPrice);
  @action setVAT = (id, VAT) => (this.formData.VAT = VAT);
  @action setVATAmount = (VATAmount) => (this.formData.VATAmount = VATAmount);
  @action setNonVatableItems = (NonVatableItems) => (this.formData.NonVatableItems = NonVatableItems);
  @action
  setAccessoriesInsurance = (AccessoriesInsurance) => (this.formData.AccessoriesInsurance = AccessoriesInsurance);
  @action setAccessoriesWarranty = (AccessoriesWarranty) => (this.formData.AccessoriesWarranty = AccessoriesWarranty);
  @action setAccessoriesOther = (AccessoriesOther) => (this.formData.AccessoriesOther = AccessoriesOther);
  @action setCashDeposit = (CashDeposit) => (this.formData.CashDeposit = CashDeposit);
  @action setPartExchange = (PartExchange) => (this.formData.PartExchange = PartExchange);
  @action setSettlement = (Settlement) => (this.formData.Settlement = Settlement);
  @action setAdvance = (Advance) => (this.formData.Advance = Advance);
  @action
  setAnnualDistanceQuoted = (AnnualDistanceQuoted) => (this.formData.AnnualDistanceQuoted = AnnualDistanceQuoted);
  @action
  setChargePerOverDistanceUnit = (ChargePerOverDistanceUnit) =>
    (this.formData.ChargePerOverDistanceUnit = ChargePerOverDistanceUnit);
  @action setAcceptanceFeeLast = (AcceptanceFeeLast) => (this.formData.AcceptanceFeeLast = AcceptanceFeeLast);
  @action setOptionToPurchaseFee = (OptionToPurchaseFee) => (this.formData.OptionToPurchaseFee = OptionToPurchaseFee);
  @action setFlatRate = (id, FlatRate) => (this.formData.FlatRate = FlatRate);
  @action setRepresentativeApr = (id, RepresentativeApr) => (this.formData.RepresentativeApr = RepresentativeApr);
  @action setTerm = (id, Term) => (this.formData.Term = Term);
  @action setFirstPayment = (FirstPayment) => (this.formData.FirstPayment = FirstPayment);
  @action setFollowedBy = (FollowedBy) => (this.formData.FollowedBy = FollowedBy);
  @action setFollowingPayments = (FollowingPayments) => (this.formData.FollowingPayments = FollowingPayments);
  @action setFinalPayment = (FinalPayment) => (this.formData.FinalPayment = FinalPayment);
  @action setTotalAmountPayable = (TotalAmountPayable) => (this.formData.TotalAmountPayable = TotalAmountPayable);
  @action setInterestCharges = (InterestCharges) => (this.formData.InterestCharges = InterestCharges);
  @action setCommission = (Commission) => (this.formData.Commission = Commission);
  @action
  setAdditionalCommission = (AdditionalCommission) => (this.formData.AdditionalCommission = AdditionalCommission);
  @action setFunderCode = (id, FunderCode) => (this.formData.FunderCode = FunderCode);
  @action setOtherFunder = (OtherFunder) => (this.formData.OtherFunder = OtherFunder);
  @action setNotes = (Notes) => (this.formData.Notes = Notes);
  @action setFinanceType = (id, FinanceType) => (this.formData.FinanceType = FinanceType);
  @action toggleRemoveFromDashboard = () => (this.formData.RemoveFromDashboard = !this.formData.RemoveFromDashboard);
  @action setVaps = (data) => (this.formData.ValueAddedProducts = data);
  @action setVapPrice = (index, price) => (this.formData.ValueAddedProducts[index].Price = price);

  UNSAFE_componentWillMount() {
    const vehicle = this.props.appStore.customerStore.customer.getVehicle(this.props.vehicleId);
    this.setInitialValues(vehicle);
    this.setupValidation();

    this.setState({
      ValueAddedProducts: _.get(vehicle, 'Status.Status')
        ? vehicle.Status.ValueAddedProducts
        : _.get(this.props.appStore.closeDealStore.closeDealData, 'ValueAddedProducts')
    });
  }

  @action
  setInitialValues = (vehicle) => {
    Object.assign(
      this.formData,
      _.get(vehicle, 'Status.Status') ? vehicle.Status : this.props.appStore.closeDealStore.closeDealData
    );
  };

  componentWillUnmount() {
    this.validationReaction();
  }

  setupValidation = () => {
    this.validator = new Validator();
    this.validator.setRules(this.validationRules);
    this.validationReaction = reaction(() => ({ ...this.formData }), this.validator.validate, {
      fireImmediately: true
    });
  };

  submit = () => {
    if (!this.validator.errorCount) {
      this.props.onSubmit(toJS(this.formData)).then(() => {
        this.props.navigate(`/d/${this.props.dealershipId}/consumers/${this.props.appStore.customerStore.customer.Id}`);
      });
    }
  };

  removeVap = (index) => {
    let vapState = this.state.ValueAddedProducts;
    vapState = vapState.filter((v, i) => i !== index);

    this.setState({
      ValueAddedProducts: vapState
    });
    this.setVaps(vapState);
  };

  editVap = (index) => {
    this.setState(
      {
        vapToEdit: index
      },
      () => {
        this.setState({
          isVapModalOpen: true
        });
      }
    );
  };

  openAddVapModal = () => {
    this.setState({
      isVapModalOpen: true
    });
  };

  closeAddVapModal = () => {
    this.setState({
      isVapModalOpen: false
    });
  };

  handleSubmitAddVapModal = (productId, price, productTypeCode, shouldRemoveExistingDuplicate) => {
    this.props.onSubmitVapsModal(productTypeCode).then(
      action(() => {
        const chosenProduct = _.find(this.props.appStore.closeDealStore.vapProductType, { Id: productId });

        if (this.state.vapToEdit !== null) {
          this.state.ValueAddedProducts[this.state.vapToEdit] = chosenProduct; //eslint-disable-line
        } else {
          this.state.ValueAddedProducts.push(chosenProduct);

          if (shouldRemoveExistingDuplicate) {
            const toRemove = _.find(this.state.ValueAddedProducts, (vap) => vap.ProductTypeCode === productTypeCode);

            this.removeVap(_.indexOf(this.state.ValueAddedProducts, toRemove));
          }
        }

        this.setVaps(this.state.ValueAddedProducts);
        this.setState({
          vapToEdit: null
        });
        this.closeAddVapModal();
      })
    );
  };

  render() {
    let errors = this.validator.getErrors();

    return (
      <MobxForm className="paidOutForm" onSubmit={this.submit} focusOnFirstElement>
        <div className="paidOutForm__formLeft">
          <div className="paidOutForm__inner" key="leftInner">
            <div className="paidOutForm__infoWarning" key="info1">
              <InformationWarning text="The following details will be stored against the customer record and will be useful for reporting as it will form a historic record of actions related to this vehicle." />
            </div>
            <div className="paidOutForm__vehicleDetails">
              <FormConsumerVehicleDetails consumer={this.props.consumer} vehicle={this.props.vehicle} />
            </div>
            <div className="paidOutForm__inputs">
              <div className="paidOutForm__dateInputs" key="dateInputs">
                <div className="paidOutForm__dateLeft">
                  <MobxFieldGroup error={errors.DateOfPurchase}>
                    <MobxLabel htmlFor="DateOfPurchase">Customer Purchase Date</MobxLabel>
                    <DateField
                      value={this.formData.DateOfPurchase}
                      onChange={this.setDateOfPurchase}
                      tabIndex="2"
                      id="DateOfPurchase"
                    />
                  </MobxFieldGroup>
                </div>
                <div className="paidOutForm__dateRight">
                  <MobxFieldGroup error={errors.DateOfHandover}>
                    <MobxLabel htmlFor="DateOfPurchase">Customer Handover Date</MobxLabel>
                    <DateField
                      value={this.formData.DateOfHandover}
                      onChange={this.setDateOfHandover}
                      tabIndex="3"
                      id="DateOfHandover"
                    />
                  </MobxFieldGroup>
                </div>
              </div>
            </div>
            <div className="soldFinance__qualifying">
              {this.props.appStore.closeDealStore.closeDealData.VATQualifying ? 'VAT Qualifying' : 'VAT Margin'}
            </div>
            <MobxFieldGroup size="small" error={errors.TotalPrice}>
              <MobxLabel htmlFor="TotalPrice">Total Price</MobxLabel>
              <CurrencyInput value={this.formData.TotalPrice} onChange={this.setTotalPrice} tabIndex="4" />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.VehiclePrice}>
              <MobxLabel htmlFor="VehiclePrice">Vehicle Price</MobxLabel>
              <CurrencyInput value={this.formData.VehiclePrice} onChange={this.setVehiclePrice} tabIndex="5" />
            </MobxFieldGroup>
            {this.props.appStore.closeDealStore.closeDealData.VATQualifying && (
              <div>
                <MobxFieldGroup size="small" error={errors.BasicPrice}>
                  <MobxLabel htmlFor="BasicPrice">Basic Price</MobxLabel>
                  <CurrencyInput value={this.formData.BasicPrice} onChange={this.setBasicPrice} tabIndex="6" />
                </MobxFieldGroup>

                <MobxFieldGroup size="small" error={errors.VAT}>
                  <MobxLabel htmlFor="VAT">VAT Percent</MobxLabel>
                  <PercentageInput value={this.formData.VAT} onChange={this.setVAT} tabIndex="7" />
                </MobxFieldGroup>

                <MobxFieldGroup size="small" error={errors.VATAmount}>
                  <MobxLabel htmlFor="VATAmount">VAT Amount *</MobxLabel>
                  <CurrencyInput value={this.formData.VATAmount} onChange={this.setVATAmount} tabIndex="8" />
                </MobxFieldGroup>
              </div>
            )}

            {this.formData.NonVatableItems ||
            this.formData.AccessoriesInsurance ||
            this.formData.AccessoriesWarranty ||
            this.formData.AccessoriesOther ? (
              <div>
                <MobxFieldGroup size="small" error={errors.NonVatableItems}>
                  <MobxLabel htmlFor="NonVatableItems">Non VATable Items *</MobxLabel>
                  <CurrencyInput
                    value={this.formData.NonVatableItems}
                    onChange={this.setNonVatableItems}
                    tabIndex="9"
                  />
                </MobxFieldGroup>

                <MobxFieldGroup size="small" error={errors.AccessoriesInsurance}>
                  <MobxLabel htmlFor="AccessoriesInsurance">Accessories - Insurance *</MobxLabel>
                  <CurrencyInput
                    value={this.formData.AccessoriesInsurance}
                    onChange={this.setAccessoriesInsurance}
                    tabIndex="10"
                  />
                </MobxFieldGroup>

                <MobxFieldGroup size="small" error={errors.AccessoriesWarranty}>
                  <MobxLabel htmlFor="AccessoriesWarranty">Accessories - Warranty *</MobxLabel>
                  <CurrencyInput
                    value={this.formData.AccessoriesWarranty}
                    onChange={this.setAccessoriesWarranty}
                    tabIndex="11"
                  />
                </MobxFieldGroup>

                <MobxFieldGroup size="small" error={errors.AccessoriesOther}>
                  <MobxLabel htmlFor="AccessoriesOther">Accessories - Other *</MobxLabel>
                  <CurrencyInput
                    value={this.formData.AccessoriesOther}
                    onChange={this.setAccessoriesOther}
                    tabIndex="12"
                  />
                </MobxFieldGroup>
              </div>
            ) : (
              <div>
                {this.state.ValueAddedProducts &&
                  this.state.ValueAddedProducts.map((vap, index) => (
                    <ValueAddedProduct
                      key={`valueAddedProduct-${index}`}
                      index={index}
                      label={vap.ProductTypeText}
                      value={vap.Price}
                      onChange={this.setVapPrice}
                      onRemove={() => this.removeVap(index)}
                      onEdit={() => this.editVap(index)}
                      error={errors.ValueAddedProducts && errors.ValueAddedProducts[index]}
                      size="small"
                      isInline={false}
                    />
                  ))}
                <div className="paidOutForm__vapsButton">
                  <Button type="button" buttonStyle="secondary" onClick={this.openAddVapModal}>
                    Add VAP
                  </Button>
                </div>
              </div>
            )}

            <MobxFieldGroup size="small" error={errors.CashDeposit}>
              <MobxLabel htmlFor="CashDeposit">Cash Deposit</MobxLabel>
              <CurrencyInput value={this.formData.CashDeposit} onChange={this.setCashDeposit} tabIndex="13" />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.PartExchange}>
              <MobxLabel htmlFor="PartExchange">Part Exchange Value</MobxLabel>
              <CurrencyInput value={this.formData.PartExchange} onChange={this.setPartExchange} tabIndex="14" />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.Settlement}>
              <MobxLabel htmlFor="Settlement">Settlement Value</MobxLabel>
              <CurrencyInput value={this.formData.Settlement} onChange={this.setSettlement} tabIndex="15" />
            </MobxFieldGroup>

            <div className="paidOutForm__asterisk">* These items are included in the Total Price.</div>
          </div>
        </div>

        <div className="paidOutForm__formRight">
          <div className="paidOutForm__inner" key="rightInner">
            <div className="paidOutForm__mediaDivider" key="divider41" />
            <MobxFieldGroup size="small" error={errors.FunderCode}>
              <MobxLabel htmlFor="FunderCode">Lender</MobxLabel>
              <SelectInput
                value={this.formData.FunderCode}
                onChange={this.setFunderCode}
                tabIndex="16"
                options="Lenders"
                id="Lenders"
              />
            </MobxFieldGroup>

            {this.formData.FunderCode === 'Other' && (
              <MobxFieldGroup size="small" error={errors.OtherFunder}>
                <MobxLabel htmlFor="OtherFunder">Please Specify</MobxLabel>
                <TextInputWrappingInput
                  value={this.formData.OtherFunder}
                  onChange={this.setOtherFunder}
                  tabIndex="17"
                  type="text"
                />
              </MobxFieldGroup>
            )}

            <MobxFieldGroup size="small" error={errors.FinanceType}>
              <MobxLabel htmlFor="Product">Product</MobxLabel>
              <SelectInput
                value={this.formData.FinanceType}
                onChange={this.setFinanceType}
                tabIndex="18"
                options="ProductType"
                id="ProductType"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.Advance}>
              <MobxLabel htmlFor="Advance">Amount of Advance</MobxLabel>
              <CurrencyInput value={this.formData.Advance} onChange={this.setAdvance} tabIndex="19" />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.Advance}>
              <MobxLabel htmlFor="Advance">Agreement Term</MobxLabel>
              <SelectInput
                value={this.formData.Term}
                onChange={this.setTerm}
                tabIndex="20"
                type="select"
                options="CloseDealTerms"
                id="Advance"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.FirstPayment}>
              <MobxLabel htmlFor="FirstPayment">First payment</MobxLabel>
              <CurrencyInput value={this.formData.FirstPayment} onChange={this.setFirstPayment} tabIndex="21" />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.FollowedBy}>
              <MobxLabel htmlFor="FollowedBy">Followed by (number of payments)</MobxLabel>
              <TextInputWrappingInput
                value={this.formData.FollowedBy}
                onChange={this.setFollowedBy}
                tabIndex="22"
                type="text"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.FollowingPayments}>
              <MobxLabel htmlFor="FollowingPayments">Payments of</MobxLabel>
              <CurrencyInput
                value={this.formData.FollowingPayments}
                onChange={this.setFollowingPayments}
                tabIndex="23"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.FinalPayment}>
              <MobxLabel htmlFor="FinalPayment">Final Payment</MobxLabel>
              <CurrencyInput value={this.formData.FinalPayment} onChange={this.setFinalPayment} tabIndex="24" />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.TotalAmountPayable}>
              <MobxLabel htmlFor="TotalAmountPayable">Total Amount Payable</MobxLabel>
              <CurrencyInput
                value={this.formData.TotalAmountPayable}
                onChange={this.setTotalAmountPayable}
                tabIndex="25"
              />
            </MobxFieldGroup>

            <div className="paidOutForm__divider" key="divider1" />

            <MobxFieldGroup size="small" error={errors.AnnualDistanceQuoted}>
              <MobxLabel htmlFor="AnnualDistanceQuoted">Mileage p.a</MobxLabel>
              <TextInputWrappingInput
                value={this.formData.AnnualDistanceQuoted}
                onChange={this.setAnnualDistanceQuoted}
                tabIndex="26"
                type="select"
                options="MileageOptions"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.ChargePerOverDistanceUnit}>
              <MobxLabel htmlFor="ChargePerOverDistanceUnit">Excess per Mile</MobxLabel>
              <CurrencyInput
                value={this.formData.ChargePerOverDistanceUnit}
                onChange={this.setChargePerOverDistanceUnit}
                tabIndex="27"
              />
            </MobxFieldGroup>

            <div className="paidOutForm__divider" key="divider2" />

            <MobxFieldGroup size="small" error={errors.RepresentativeApr}>
              <MobxLabel htmlFor="RepresentativeApr">APR</MobxLabel>
              <PercentageInput
                size="small"
                value={this.formData.RepresentativeApr}
                onChange={this.setRepresentativeApr}
                tabIndex="28"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.FlatRate}>
              <MobxLabel htmlFor="FlatRate">Annual Fixed Interest Rate</MobxLabel>
              <PercentageInput size="small" value={this.formData.FlatRate} onChange={this.setFlatRate} tabIndex="29" />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.InterestCharges}>
              <MobxLabel htmlFor="InterestCharges">Interest Charges</MobxLabel>
              <CurrencyInput value={this.formData.InterestCharges} onChange={this.setInterestCharges} tabIndex="30" />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.AcceptanceFeeLast}>
              <MobxLabel htmlFor="AcceptanceFeeLast">Administration Fee(s)</MobxLabel>
              <CurrencyInput
                value={this.formData.AcceptanceFeeLast}
                onChange={this.setAcceptanceFeeLast}
                tabIndex="31"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.OptionToPurchaseFee}>
              <MobxLabel htmlFor="OptionToPurchaseFee">Option to Purchase Fee</MobxLabel>
              <CurrencyInput
                value={this.formData.OptionToPurchaseFee}
                onChange={this.setOptionToPurchaseFee}
                tabIndex="32"
              />
            </MobxFieldGroup>

            <div className="paidOutForm__divider" key="divider3" />

            <MobxFieldGroup size="small" error={errors.Commission}>
              <MobxLabel htmlFor="Commission">Commission</MobxLabel>
              <CurrencyInput value={this.formData.Commission} onChange={this.setCommission} tabIndex="33" />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.AdditionalCommission}>
              <MobxLabel htmlFor="AdditionalCommission">Additional Commission</MobxLabel>
              <CurrencyInput
                value={this.formData.AdditionalCommission}
                onChange={this.setAdditionalCommission}
                tabIndex="34"
              />
            </MobxFieldGroup>
          </div>
        </div>
        <div className="paidOutForm__bottom">
          <div className="paidOutForm__divider" key="divider5" />

          <MobxFieldGroup error={errors.Notes}>
            <MobxLabel htmlFor="Notes">Close Out Comment</MobxLabel>
            <TextInputWrappingInput value={this.formData.Notes} onChange={this.setNotes} tabIndex="35" type="text" />
          </MobxFieldGroup>

          <div className="paidOutForm__infoWarning" key="info2">
            <InformationWarning text="Selecting to hide the lead will remove visibility from the homepage dashboard. The details will still be accessible from the contacts area of the platform. Removing dormant leads from your dashboard is a good house-keeping practice." />
          </div>
          <div className="paidOutForm__checkbox">
            <CheckboxWithLabel
              onChange={this.toggleRemoveFromDashboard}
              value={this.formData.RemoveFromDashboard}
              id="removeFromDashboard"
            >
              Hide this lead from the homepage after closing
            </CheckboxWithLabel>
          </div>
        </div>
        <div className="paidOutForm__buttons">{this.props.children}</div>

        <AddVapModal
          isOpen={this.state.isVapModalOpen}
          onClose={this.closeAddVapModal}
          onSubmit={this.handleSubmitAddVapModal}
          vapToEdit={this.state.vapToEdit !== null ? this.state.ValueAddedProducts[this.state.vapToEdit] : null}
          currentVaps={this.state.ValueAddedProducts}
        />
      </MobxForm>
    );
  }
}

PaidOutForm.propTypes = {
  onSubmit: PropTypes.func,
  children: PropTypes.node,
  consumer: PropTypes.object,
  vehicle: PropTypes.object,
  defaultValues: PropTypes.object,
  appStore: PropTypes.object,
  vehicleId: PropTypes.string,
  dealershipId: PropTypes.string,
  onSubmitVapsModal: PropTypes.func
};

export default compose(withNavigate, inject(['appStore']), observer)(PaidOutForm);
