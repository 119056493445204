const OnlineQuotesIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5021 11.8789L11.8334 6.28631C11.6417 6.14779 11.3803 6.07853 11.1363 6.11316L9.5677 6.35557C8.62654 4.57216 6.88367 3.34281 4.86194 3.01384H4.84451C4.33908 2.92726 3.85107 3.25624 3.76393 3.74105C3.67678 4.24318 4.00793 4.72799 4.49593 4.81456C5.73337 5.00502 6.84881 5.68029 7.58082 6.68455L4.73994 7.15204C4.2345 7.23862 3.88593 7.70611 3.97307 8.20824L5.00137 14.5627C5.03622 14.8051 5.17565 15.0302 5.3848 15.1687L13.1057 20.6575C13.9423 21.2462 15.0926 21.0557 15.6852 20.2419L19.8855 14.4415C20.4955 13.6277 20.3212 12.485 19.5021 11.8789ZM14.1863 19.1511L6.76167 13.8874L5.94252 8.81425L8.43483 8.41601C8.57426 8.90082 8.67883 9.38563 8.73112 9.88776C8.45226 10.0436 8.22568 10.286 8.06882 10.563C7.56339 11.4461 7.85968 12.5715 8.74855 13.0737C9.63741 13.5758 10.7703 13.2814 11.2757 12.3984C11.7811 11.5153 11.4849 10.3899 10.596 9.88776C10.5437 9.29906 10.4391 8.69305 10.2823 8.12166L10.9794 8.01778L18.4041 13.368L14.1863 19.1511Z"
        fill="#30364E"
      />
      <path
        d="M11.88 13.6581L12.1347 13.3052L14.4138 14.929L14.159 15.2819L11.88 13.6581ZM12.2985 13.0784L12.5532 12.7255L14.8322 14.3493L14.5775 14.7022L12.2985 13.0784ZM13.1315 16.1464C12.9004 15.9817 12.7124 15.7927 12.5676 15.5793C12.4277 15.3651 12.3354 15.1382 12.2907 14.8985C12.246 14.6589 12.2509 14.4185 12.3054 14.1775C12.3599 13.9364 12.467 13.7053 12.6267 13.484C12.7864 13.2628 12.9725 13.0879 13.1848 12.9593C13.3972 12.8307 13.6248 12.749 13.8676 12.7142C14.1104 12.6794 14.3554 12.6949 14.6024 12.7607C14.8543 12.8257 15.0958 12.9405 15.327 13.1052C15.5835 13.2879 15.7837 13.4984 15.9276 13.7367C16.0736 13.9721 16.1539 14.2244 16.1687 14.4937L15.4421 14.5866C15.4235 14.3995 15.3724 14.2338 15.2888 14.0894C15.2072 13.9422 15.0917 13.8154 14.9423 13.709C14.8042 13.6105 14.6612 13.5426 14.5133 13.505C14.3654 13.4675 14.2188 13.4606 14.0736 13.4844C13.9284 13.5081 13.7903 13.5603 13.6595 13.641C13.5316 13.7237 13.417 13.8351 13.3159 13.9751C13.2149 14.1151 13.1454 14.2586 13.1076 14.4055C13.0727 14.5545 13.0671 14.7011 13.0911 14.8454C13.115 14.9896 13.1686 15.1253 13.2518 15.2525C13.335 15.3796 13.4457 15.4924 13.5838 15.5908C13.7332 15.6973 13.8901 15.7667 14.0546 15.7991C14.2211 15.8286 14.3975 15.8207 14.5839 15.7754L14.7283 16.489C14.4669 16.5614 14.1995 16.5681 13.9262 16.5091C13.6529 16.4501 13.388 16.3292 13.1315 16.1464Z"
        fill="#30364E"
      />
    </svg>
  );
};

export default OnlineQuotesIcon;
