import _ from 'lodash';

import { push } from 'routerHistory';

export function navigateAfterSignIn(path) {
  return (__, getState) => {
    if (path) {
      push(path);
    } else {
      const state = getState();
      let dealershipId = _.get(state.session, 'Dealerships[0].Id');

      const currentDealership = _.chain(state)
        .get('session.Dealerships')
        .find({ Id: dealershipId })
        .value();

      const countryCode = (typeof currentDealership.CountryCode !== 'string' || currentDealership.CountryCode === ''
        ? 'gb'
        : currentDealership.CountryCode
      )?.toLowerCase();

      if (countryCode === 'gb') {
        push(`/d/${dealershipId}`);
      } else {
        push(`/d/${dealershipId}/stock/list`);
      }
    }
  };
}
