import { useTranslation } from 'react-i18next';
import { milesToKilometres } from '../../../core/helpers';

const Distance = ({ miles, countryCode }) => {
  const { t } = useTranslation('Customer');
  const milesCount = Math.round(miles);
  const kilometresCount = milesToKilometres(miles);

  if (countryCode === 'gb') {
    return <div>{t('DealershipDistance.miles_away', { count: milesCount })}</div>;
  } else {
    return <div>{t('DealershipDistance.kilometres_away', { count: kilometresCount })}</div>;
  }
};

export default Distance;
