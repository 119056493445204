import { Trans, useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import { ReactComponent as ErrorIllustration } from './images/error_illustration.svg';
import { useParams, useNavigate } from 'react-router-dom';
import './error.scss';

const Error = () => {
  const { t } = useTranslation('Error');
  const navigate = useNavigate();
  const params = useParams();

  const errorCodeLookup = new Set(['204', '404']);
  const errorCode = errorCodeLookup.has(params.errorCode ?? '') ? params?.errorCode : 'default';

  return (
    <div className="error__container">
      <section className="error__info">
        <h1>
          <Trans ns="Error" i18nKey={`Error.status_title_${errorCode}`} />
        </h1>
        <h2>
          <Trans ns="Error" i18nKey={`Error.status_subtitle_${errorCode}`} />
        </h2>
        <p className="error__statusText">
          <Trans ns="Error" i18nKey={`Error.status_text_${errorCode}`} />
        </p>

        <Button
          className="error__back"
          buttonStyle="primary"
          onClick={() => {
            navigate(-1);
          }}
          id="continueFpn"
        >
          {t('Error.go_back')}
        </Button>
      </section>
      <div className="error__imageWrapper">
        <ErrorIllustration className="error__image" />
      </div>
    </div>
  );
};

export default Error;
