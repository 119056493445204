import { PartialDeep } from 'type-fest';
import sharedDefaultRules from '../default';

export type LenderCorporateConfig = {
  applicationStatuses: PartialDeep<typeof sharedDefaultRules['applicationStatuses']> &
    Record<
      string,
      {
        isComplete: boolean;
        isEditable: boolean;
        isError: boolean;
        isPaidOut: boolean;
        isPending: boolean;
        isReproposable: boolean;
        isSubmitting: boolean;
        [key: string]: unknown;
      }
    >;
  editSections: {
    vehicle: boolean;
    quote: boolean;
    employment: boolean;
    affordability: boolean;
    bankDetails: boolean;
  };
  applicationFields: PartialDeep<typeof defaultCorporateRules['applicationFields'], { recurseIntoArrays: true }>;
  validationRules: PartialDeep<typeof defaultCorporateRules['validationRules'], { recurseIntoArrays: true }>;
};

const defaultCorporateRules = {
  applicationStatuses: sharedDefaultRules.applicationStatuses,
  editSections: {
    vehicle: true,
    quote: true,
    employment: false,
    affordability: false,
    bankDetails: true
  },

  applicationFields: {
    BusinessDetails: {
      TradingName: true,
      RegisteredName: true,
      CompanyType: true,
      CompanyRegistrationNumber: true,
      CompanySector: true,
      DateEstablished: true,
      VatNumber: true,
      NumberOfDirectorsOrPartners: true,
      NumberOfEmployees: true,
      EmailAddress: true,
      Landline: true,
      Mobile: true,
      FaxNumber: true
    },
    AddressHistory: {
      BuildingName: true,
      BuildingNumber: true,
      Postcode: true,
      PostTown: true,
      Street: true,
      Residency: true,
      TimeAtAddress: true
    },
    AffordabilityDetails: {
      GrossAnnual: false
    },
    OrganisationContacts: {
      CountryOfBirth: false,
      Nationality: false
    }
  },
  validationRules: {
    BusinessDetails: {
      TradingName: 'required',
      CompanyType: 'required',
      CompanyRegistrationNumber: 'required, alphaNumSpace, maxlength:100',
      CompanySector: 'required',
      DateEstablished: 'date, datePast',
      VatNumber: 'number:int, maxlength:9, minlength:9',
      NumberOfDirectorsOrPartners: 'required, number:int, maxlength:6',
      NumberOfEmployees: 'required, number:int, maxlength:100',
      EmailAddress: 'required, email, maxlength:100',
      Landline: 'required:unless:Mobile, businessPhone',
      Mobile: 'required:unless:Landline, mobile',
      FaxNumber: 'phone'
    },
    AffordabilityDetails: {
      Income: {
        GrossAnnual: 'optional'
      }
    },
    AddressHistory: {
      Items: [
        {
          BuildingName: 'required:unless:BuildingNumber, maxlength:40',
          BuildingNumber: 'required:unless:BuildingName, maxlength:10',
          Postcode: 'required, maxlength:10, alphaNumSpace',
          PostTown: 'required, maxlength:35, lenderAlphanumeric',
          Street: 'required, maxlength:75, lenderAlphanumeric',
          District: 'maxlength:55, lenderAlphanumeric',
          PremisesType: 'required',
          TimeAtAddress: {
            Years: 'required, number:int, maxlength:8',
            Months: 'required, number:int, maxlength:8, max:12'
          }
        }
      ],
      totalMonths: 'min:36'
    },
    OrganisationContacts: {
      Items: [
        {
          Title: 'required',
          FirstName: 'required, maxlength:25',
          MiddleNames: 'maxlength:25',
          LastName: 'required, maxlength:40',
          Position: 'required',
          DateOfBirth: 'required, date, youngerThan99, olderThan18',
          CountryOfBirth: 'optional',
          Nationality: 'optional',
          Gender: 'required',
          MaritalStatus: 'required',
          Email: 'required, email, maxlength:100',
          HomeTelephone: 'required:unless:MobileTelephone, landline',
          MobileTelephone: 'required:unless:HomeTelephone, mobile',
          Items: [
            {
              BuildingName: 'required:unless:BuildingNumber, maxlength:35',
              BuildingNumber: 'required:unless:BuildingName, maxlength:10',
              Postcode: 'required, maxlength:10, alphaNumSpace',
              PostTown: 'required, maxlength:35, lenderAlphanumeric',
              Street: 'required, maxlength:75, lenderAlphanumeric',
              District: 'lenderAlphanumeric',
              Residency: 'required',
              TimeAtAddress: {
                Years: 'required, number:int',
                Months: 'required, number:int, max:12'
              }
            }
          ],
          totalMonths: 'min:36'
        }
      ]
    },
    BankDetails: {
      AccountName: 'required',
      SortCode: 'required, sortCode',
      AccountNumber: 'required, accountNumber',
      TimeAtBank: {
        Years: 'required, number:int, max:100',
        Months: 'required, number:int, max:12'
      }
    }
  }
};

export default defaultCorporateRules;
