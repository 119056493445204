import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  draft.applicationFields.AffordabilityDetails!.NetMonthly = false;

  draft.applicationStatuses.Pending!.isEditable = false;
  draft.applicationStatuses.Pending!.isSubmitting = true;
  draft.applicationStatuses.Submitted!.isEditable = false;
  draft.applicationStatuses.Submitted!.isSubmitting = true;
  draft.editSections!.employment = false;
  draft.editSections!.affordability = false;

  draft.validationRules.AffordabilityDetails!.Income!.GrossAnnual = 'currency';
  draft.validationRules.AffordabilityDetails!.ReplacementLoan!.DetailsOfReplacementLoan =
    'required:iftrue: IsReplacementLoan';

  delete draft.validationRules.AffordabilityDetails!.Income!.NetMonthly;
});
