import * as React from 'react';

/* eslint react/prop-types: 0 */

export const ColorIcon = ({ classProp }) => {
  return (
    <span className={classProp}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.72 50.724">
        <g id="color_2" data-name="color 2" transform="translate(-6.736 -6.544)">
          <path
            id="brush"
            d="M944,543V519h32v24Z"
            transform="translate(-265.692 -1027.953) rotate(45)"
            opacity="0.402"
          />
          <path id="bristle" d="M0,0H5V24H0Z" transform="translate(53.92 25.636) rotate(45)" opacity="0.401" />
          <path
            id="bristle-2"
            data-name="bristle"
            d="M0,0H5V24H0Z"
            transform="translate(48.263 19.979) rotate(45)"
            opacity="0.401"
          />
          <path
            id="bristle-3"
            data-name="bristle"
            d="M0,0H5V24H0Z"
            transform="translate(42.607 14.322) rotate(45)"
            opacity="0.401"
          />
          <path
            id="bristle-4"
            data-name="bristle"
            d="M0,0H5V24H0Z"
            transform="translate(36.95 8.665) rotate(45)"
            opacity="0.401"
          />
          <path
            id="handle"
            d="M11.967,17.622C9.988,19.6,5.547,28.158,7.525,30.137l4.253,4.253L4.172,41.112a4.056,4.056,0,0,0-.312.293,4.7,4.7,0,0,0,.085,6.645l3.584,3.584c.1.1.2.2.309.291a4.7,4.7,0,0,0,6.632-.515l6.718-7.61,4.253,4.253c1.979,1.978,10.62-2.275,12.6-4.253Z"
            transform="translate(4.21 4.211)"
          />
        </g>
      </svg>
    </span>
  );
};

export const MakeIcon = ({ classProp }) => {
  return (
    <span className={classProp}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.089 50.471">
        <g id="make_2" data-name="make 2" transform="translate(-1061 -614.595)">
          <path
            id="shield"
            d="M86.416,47.095c-.866,6.419-2.885,21.455-8.464,27.873A42.7,42.7,0,0,1,63.622,85.607l-.577.264-.673-.264A36.686,36.686,0,0,1,45.54,73.3c-3.27-4.309-5.386-13.629-6.348-27.7,0-.264-.192-2.55-.192-2.55l2.02.352a24.844,24.844,0,0,0,12.407-1.231,23.725,23.725,0,0,0,8.368-5.54L62.948,35.4,64.1,36.631A24.079,24.079,0,0,0,84.877,43.4l2.212-.264C87.089,43.05,86.608,46.127,86.416,47.095Z"
            transform="translate(1022 579.195)"
            fill="#010101"
            opacity="0.403"
          />
          <path
            id="left_crest"
            data-name="left crest"
            d="M48.029,75.164a31.29,31.29,0,0,0,13.5,11.029h1.941V64.4H44.5C45.559,70.4,46.882,73.576,48.029,75.164Z"
            transform="translate(1021.353 575.609)"
            fill="#010101"
            opacity="0.7"
          />
          <path
            id="right_crest"
            data-name="right crest"
            d="M61.495,40.394V61.486H79.939a141.116,141.116,0,0,0,2.294-14.117.667.667,0,0,1,.088-.353C76.395,48.88,64.467,43.624,61.495,40.394Z"
            transform="translate(1023.327 578.607)"
            fill="#010101"
            opacity="0.701"
          />
        </g>
      </svg>
    </span>
  );
};

export const ModelIcon = ({ classProp }) => {
  return (
    <span className={classProp}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 12 195.198 195.983">
        <g id="model22" transform="translate(35.099 27.491)">
          <path
            id="Path_118"
            data-name="Path 118"
            d="M79,0l80,46v92L79,183,0,138V46Zm0,23L20,58v68l59,34,60-34V58Z"
            transform="translate(56.401 -27.491) rotate(30)"
            fillRule="evenodd"
          />
          <path id="Path_119" data-name="Path 119" d="M79,47,41,69v45l38,23,39-23V69Z" fillRule="evenodd" />
          <path id="Path_120" data-name="Path 120" d="M-1.5,92,38,24h79.5l42,68-40,70H40Z" opacity="0.297" />
        </g>
      </svg>
    </span>
  );
};

export const AgeIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 49.671">
      <g id="reg-date_2" data-name="reg-date 2" transform="translate(-20.937 -8.329)">
        <path id="base" d="M3.5,0h46a3.5,3.5,0,0,1,0,7H3.5a3.5,3.5,0,0,1,0-7Z" transform="translate(20.937 51)" />
        <path
          id="cake"
          d="M223.692,712.116a2.2,2.2,0,0,1-2.2,2.2H179.7a2.2,2.2,0,0,1-2.2-2.2c.048-14.829.1-14.788.152-14.747h45.854Z"
          transform="translate(-153.1 -662.316)"
          opacity="0.396"
        />
        <path
          id="icing"
          d="M218.636,339.516a6.035,6.035,0,0,1-10.767,3.738l-1.076-1.366-1.083,1.366a6.039,6.039,0,0,1-9.463-.007l-1.076-1.359-1.083,1.366a6.033,6.033,0,0,1-9.463,0l-1.076-1.366-1.083,1.366a6.034,6.034,0,0,1-10.767-3.738c0-3.324.128-6.028,3.459-6.028.635,0,5.88-.007,9.48,0l5.442.007H200.3c6.159.007,14.007-.007,14.938-.007C218.573,333.488,218.636,336.2,218.636,339.516Z"
          transform="translate(-147.7 -306.319)"
        />
        <path id="wick" d="M0,0,1.476.491,3,0V7.293H0Z" transform="translate(62.937 20)" opacity="0.466" />
        <path
          id="wick-2"
          data-name="wick"
          d="M0,0,1.476.491,3,0V7.293H0Z"
          transform="translate(45.937 20)"
          opacity="0.466"
        />
        <path
          id="wick-3"
          data-name="wick"
          d="M0,0,1.476.491,3,0V7.293H0Z"
          transform="translate(28.937 20)"
          opacity="0.466"
        />
        <path
          id="flame"
          d="M55.569,6S51.936,15.859,52,17.806s1.785,3.935,3.57,3.935a3.884,3.884,0,0,0,3.57-3.935C59.09,15.666,55.569,6,55.569,6Z"
          transform="translate(-8.095 2.329)"
          opacity="0.47"
        />
        <path
          id="flame-2"
          data-name="flame"
          d="M55.569,6S51.936,15.859,52,17.806s1.785,3.935,3.57,3.935a3.884,3.884,0,0,0,3.57-3.935C59.09,15.666,55.569,6,55.569,6Z"
          transform="translate(-25.095 2.329)"
          opacity="0.47"
        />
        <path
          id="flame-3"
          data-name="flame"
          d="M55.569,6S51.936,15.859,52,17.806s1.785,3.935,3.57,3.935a3.884,3.884,0,0,0,3.57-3.935C59.09,15.666,55.569,6,55.569,6Z"
          transform="translate(8.905 2.329)"
          opacity="0.47"
        />
      </g>
    </svg>
  </span>
);

export const BusinessIcon = ({ classProp }) => (
  <svg className={classProp} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.5 3.4V2H5V3.4H9.5ZM8.5 0H9.5H10.5C11 0 11.5 0.400002 11.5 1V2V3.4V3.8H13.6C14.2 3.8 14.7 4.3 14.6 4.8V13.2C14.6 13.7 14.2 14.2 13.6 14.2H1C0.5 14.2 0 13.8 0 13.2V4.8C0 4.3 0.4 3.8 1 3.8H3V3.4V2V1C3 0.5 3.4 0 4 0H5H8.5ZM12.9 12.7V5.60001H1.7V12.7H12.9Z"
      fill="currentColor"
    />
  </svg>
);

export const BudgetIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.989 46.938">
      <g id="budget_2" data-name="budget 2" transform="translate(33.405 95)">
        <path
          id="pig"
          d="M1.285,24.842A2.441,2.441,0,0,1,.2,21.783c.485-1.075.03-2.61,1.089-2.117,2.438,1.053,4.859.616,5.3,3.27.236-.133,5.2-4.421,6.063-4.72,0,0,.928,3.325,2.4,3.939l12.374-2.91c3.066-4.476,6.41-3.519,6.41-3.519a10.79,10.79,0,0,1,5.508-3.186,2.592,2.592,0,0,1,2.772-.145,2.677,2.677,0,0,1,1.352,2.462s-1,4.44-1.023,4.809c1.261.892,1.657-.3,5.006,2.826s3.167,3.449,3.67,5.209H52.84A3.187,3.187,0,0,1,56,30.908v8.566a3.189,3.189,0,0,1-3.162,3.215H45.354a21.007,21.007,0,0,1-1.889,1.6v3.767a3.19,3.19,0,0,1-3.157,3.2H33.986a3.189,3.189,0,0,1-3.166-3.212V46.971H22.39V48.04a3.188,3.188,0,0,1-3.157,3.212H12.911A3.189,3.189,0,0,1,9.746,48.04s-.021-3.407-.021-3.767C3.045,39.535,2.346,31.238,3.7,28.064c.141-.2.8-1.046.95-1.24C5.061,25.666,2.33,25.243,1.285,24.842Zm39.145,5.689A2.468,2.468,0,0,0,42.9,28.064a2.468,2.468,0,0,0-4.935,0A2.476,2.476,0,0,0,40.431,30.531Z"
          transform="translate(-33.418 -99.314)"
          opacity="0.4"
        />
        <path
          id="coin"
          d="M10.96,0a10.96,10.96,0,0,1,8.121,18.32s-15.829.5-16.5-.283A10.968,10.968,0,0,1,10.96,0Z"
          transform="translate(-24.364 -95)"
        />
      </g>
    </svg>
  </span>
);

export const DoorsIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -6 54.461 50.2">
      <g id="doors_2" data-name="doors 2" transform="translate(-1 -2.9)">
        <path
          id="door"
          d="M4.623,47.1H51.838a3.387,3.387,0,0,0,3.623-3.2V5.2a2.426,2.426,0,0,0-2.6-2.3H29.08A18.052,18.052,0,0,0,15.719,8.6C1,24.9,1.453,24.2,1.34,24.6a1.77,1.77,0,0,0-.34,1V43.9A3.387,3.387,0,0,0,4.623,47.1ZM47.2,31.725H41.421c-.679,0-1.261-1.434-1.261-2.034S40.742,28,41.421,28H47.2c.679,0,1.266,1.091,1.266,1.691S47.875,31.725,47.2,31.725ZM19.8,11.5A12.466,12.466,0,0,1,29.08,7.6H50.14V23.4H9.152Z"
        />
        <path
          id="window"
          d="M9.149,23.415l9.983-11.348s4.2-4.542,8.112-4.5,23.117,0,23.117,0l-.2,15.864Z"
          opacity="0.398"
        />
        <path
          id="big_glare_2"
          data-name="big glare 2"
          d="M35.358,10.107H43.35l-6.114,11.17H28.394Z"
          transform="translate(5)"
          opacity="0.369"
        />
        <path
          id="big_glare"
          data-name="big glare"
          d="M35.358,10.107h8.768l-6.89,11.17H28.394Z"
          transform="translate(-1)"
          opacity="0.544"
        />
        <path id="glare" d="M33.394,21.277l6.884-11.1h2.579l-6.71,11.1Z" transform="translate(-11)" opacity="0.366" />
      </g>
    </svg>
  </span>
);

export const LocationIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-8 0 42 42.88">
      <g id="location_2" data-name="location 2" transform="translate(1.145 2.829)">
        <path
          id="circle"
          d="M13.861,25.721A11.861,11.861,0,1,0,2,13.861,11.861,11.861,0,0,0,13.861,25.721Z"
          transform="translate(0.649 -0.351)"
          fillRule="evenodd"
          opacity="0.397"
        />
        <path
          id="arrows"
          d="M0,10.87,2.737,0,5.474,10.87,2.737,21.741Z"
          transform="translate(20.106 4.044) rotate(45)"
          fillRule="evenodd"
          opacity="0.489"
        />
        <path
          id="main_shape"
          data-name="main shape"
          d="M17.038,42.159c.521-.431,1.1-.932,1.723-1.5a54.636,54.636,0,0,0,5.217-5.449c4.8-5.78,7.7-11.814,7.7-17.853C31.673,7.771,24.583,0,15.837,0S0,7.771,0,17.356C0,23.4,2.9,29.43,7.7,35.209a54.636,54.636,0,0,0,5.217,5.449c.623.568,1.2,1.069,1.723,1.5.315.261.543.442.669.538a.865.865,0,0,0,1.066,0C16.5,42.6,16.723,42.42,17.038,42.159ZM15.756,28.113c-6.409,0-11.85-5.329-11.85-10.756,0-8.458,5.96-12.391,11.931-12.527S27.5,8.9,27.5,17.356A11.8,11.8,0,0,1,15.756,28.113Z"
          transform="translate(-1.145 -2.829)"
          fillRule="evenodd"
        />
        <path
          id="dial"
          d="M3.5,0A3.5,3.5,0,1,1,0,3.5,3.5,3.5,0,0,1,3.5,0Z"
          transform="translate(11 10.249)"
          opacity="0.634"
        />
      </g>
    </svg>
  </span>
);

export const ArrowDownIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <path
        d="M38.227,12.03,23.781,26.475a2.021,2.021,0,0,1-2.9,0L6.435,12.03a2.052,2.052,0,1,1,2.9-2.9L22.312,22.1,35.323,9.129a2.021,2.021,0,0,1,2.9,0A2.081,2.081,0,0,1,38.227,12.03Z"
        transform="translate(9.701 14.191)"
      />
    </svg>{' '}
  </span>
);

export const EarthIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <path
        d="M26.712,3.173A23.539,23.539,0,1,0,50.25,26.712,23.539,23.539,0,0,0,26.712,3.173ZM7.853,26.712c0-.261.008-.52.019-.779A18.843,18.843,0,0,1,26.082,7.869a4.329,4.329,0,0,1-.875,2.312,1.459,1.459,0,0,1-1.269.424c-.861-.053-1.581-.128-2.216-.195-2.973-.312-4.395-.325-6.9,2.643-1.731,2.045-.883,4.208-.2,5.947.84,2.139,1.269,3.547-.4,5.405A10.837,10.837,0,0,1,7.877,27.6C7.864,27.3,7.853,27.01,7.853,26.712ZM18.6,43.741c2.805-3.672,3.069-5.253,1.443-7.16a3.071,3.071,0,0,1-.805-2.733,4.356,4.356,0,0,1,2.379-3.739,6.865,6.865,0,0,1,3.237-.771,6.524,6.524,0,0,1,3.128.747,2.891,2.891,0,0,1,1.592,1.973c.728,3.419-3.891,9.568-5.629,11.885a6.182,6.182,0,0,1-1.187,1.211A18.867,18.867,0,0,1,18.6,43.741Zm18.643-1.387A18.779,18.779,0,0,1,26.712,45.57c-.069,0-.139-.005-.208-.005,3.5-4.685,6.912-10,6.027-14.141a5.852,5.852,0,0,0-3.083-3.987,10.136,10.136,0,0,0-9.275.013,7.4,7.4,0,0,0-3.952,6.187,6,6,0,0,0,1.523,4.907c.1.12.237.28.288.36-.016.208-.245.965-2.064,3.3a18.866,18.866,0,0,1-7.7-11.531l-.016-.083a13.865,13.865,0,0,0,8.2-4.165c3.008-3.341,1.781-6.464.971-8.531-.683-1.749-.8-2.307-.3-2.893,1.584-1.872,1.787-1.851,4.283-1.589.664.069,1.416.149,2.341.205A4.484,4.484,0,0,0,27.4,12.264,7.445,7.445,0,0,0,29.114,8.01,18.742,18.742,0,0,1,37.8,11.464l-1.6,5.9c-1.024,3.416.824,4.949,2.171,6.069,1.184.984,2.043,1.7,1.96,3.683-.077,1.9-1.139,3.776-2.165,5.592C36.581,35.52,34.648,38.941,37.248,42.354Zm2.368-1.9c-1.248-1.736-.421-3.413,1.189-6.264,1.147-2.027,2.448-4.325,2.555-6.957A6.86,6.86,0,0,0,40.312,21.1c-1.208-1.005-1.661-1.379-1.2-2.891l1.227-4.533a18.839,18.839,0,0,1-.72,26.776Z"
        transform="translate(5.288 5.288)"
      />
    </svg>{' '}
  </span>
);

export const EcoIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <path
        d="M17.479,40.934a25.168,25.168,0,0,0-.464,3.643,2.753,2.753,0,0,1-2.391,2.6c-1.121-.015-1.806-.9-2.108-2.08,0-.083-.97-1.913,1.661-9.632.224-.773.475-1.544.747-2.307a14.836,14.836,0,0,1-4.1-10.712c0-11.133,9.811-23.205,35.088-16.824,1.091.275,1.229.331,1.229.331a1.253,1.253,0,0,1,.619,2.24s-.888.923-1.827,2.051c-4.467,5.371-5.253,8.8-6.7,14.96-1.757,7.453-7.093,11.595-14.472,11.595a15.487,15.487,0,0,1-5.987-1.109A38.463,38.463,0,0,0,17.479,40.934Zm7.275-8.6c5.371,0,8.867-2.779,10.139-8.165.357-1.512.475-2,.651-2.715a35.912,35.912,0,0,1,2.389-7.1A29.944,29.944,0,0,1,41.143,9.11C34.181,7.614,26.325,7.966,21.3,11.2a13.053,13.053,0,0,0-6.021,11.237,11.062,11.062,0,0,0,1.629,6.165A27.039,27.039,0,0,1,26.97,17.625a2.226,2.226,0,0,1,2.288,3.819A22.992,22.992,0,0,0,20.434,31.5,10.352,10.352,0,0,0,24.754,32.337Z"
        transform="translate(2.062 6.407)"
      />
    </svg>
  </span>
);

export const Filters = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <defs></defs>
      <path
        d="M30.507,18.248a5.771,5.771,0,0,1,5.44,3.845H38.2a1.923,1.923,0,1,1,0,3.845H35.947a5.771,5.771,0,0,1-10.88,0H7.433a1.923,1.923,0,1,1,0-3.845H25.067A5.771,5.771,0,0,1,30.507,18.248ZM18.971,4.789a5.771,5.771,0,0,1,5.44,3.845H38.2a1.923,1.923,0,1,1,0,3.845H24.411a5.771,5.771,0,0,1-10.88,0h-6.1a1.923,1.923,0,1,1,0-3.845h6.1a5.772,5.772,0,0,1,5.443-3.845Zm-3.848,26.92a5.771,5.771,0,0,1,5.44,3.845H38.2a1.923,1.923,0,0,1,0,3.845H20.563a5.771,5.771,0,0,1-10.88,0H7.433a1.923,1.923,0,0,1,0-3.845H9.683A5.771,5.771,0,0,1,15.123,31.709Zm1.923,5.768A1.923,1.923,0,1,0,15.123,39.4h0a1.923,1.923,0,0,0,1.923-1.923ZM32.427,24.018A1.923,1.923,0,1,0,30.5,25.941h0a1.923,1.923,0,0,0,1.925-1.92v-.005ZM20.891,10.56a1.923,1.923,0,1,0-1.923,1.923h0a1.923,1.923,0,0,0,1.923-1.923Z"
        transform="translate(9.183 7.982)"
      />
    </svg>
  </span>
);

export const FuelIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg id="fuel_2" data-name="fuel 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 -6 53.033 49">
      <path
        id="can"
        d="M18.014,0H25.57a6.874,6.874,0,0,1,4.143,1.346l4.131,3.132a4.6,4.6,0,0,0,2.765.9h2.27a1.128,1.128,0,0,1,.688.24L41.879,3.63a1.169,1.169,0,0,1,1.475,0l5.9,5.067a.81.81,0,0,1,0,1.267l-2.5,2.145.177.159a.833.833,0,0,1,.286.616V37.625c0,2.963-2.808,5.375-6.259,5.375H18.014c-3.448,0-6.259-2.412-6.259-5.375V5.375C11.755,2.412,14.565,0,18.014,0Zm3.2,34.4,8.278-7.154L37.771,34.4l1.477-1.266-8.278-7.155,8.278-7.152L37.773,17.56l-8.279,7.152L21.216,17.56l-1.474,1.267,8.275,7.152-8.282,7.155Zm-3.2-27.027h6.259c.576,0,1.385-1.464,1.385-1.959s-.808-1.831-1.385-1.831H18.014c-.576,0-1.466,1.337-1.466,1.831S17.438,7.373,18.014,7.373Z"
        transform="translate(-11.755)"
      />
      <path
        id="petrol_drop"
        data-name="petrol drop"
        d="M59.515,6S51.866,24.828,52,28.546s3.758,7.515,7.515,7.515,7.617-3.427,7.515-7.515S59.515,6,59.515,6Z"
        transform="translate(-14 1.938)"
        opacity="0.404"
      />
    </svg>
  </span>
);

export const MileageIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -6 43.797 44">
      <g id="mileage_2" data-name="mileage 2" transform="translate(-1 -13.5)">
        <path
          id="_5"
          data-name="5"
          d="M50.806,34.291l3.673-2.119L53.773,30.9,50.1,33.019S50.665,34.361,50.806,34.291Z"
          transform="translate(-14.416 -5.109)"
        />
        <path
          id="_4"
          data-name="4"
          d="M43.919,21.3,41.8,24.973l1.272.706,2.119-3.673Z"
          transform="translate(-11.979 -2.29)"
        />
        <path id="_3" data-name="3" d="M31,18.1v4.238h1.413V18.1Z" transform="translate(-8.808 -1.351)" />
        <path
          id="_2"
          data-name="2"
          d="M18.672,21.2l-1.272.706s1.43,2.667,2.119,3.673l1.272-.706Z"
          transform="translate(-4.815 -2.261)"
        />
        <path
          id="_1"
          data-name="1"
          d="M12.18,33.019,8.506,30.9,7.8,32.172l3.673,2.119C11.615,34.361,12.18,33.019,12.18,33.019Z"
          transform="translate(-1.997 -5.109)"
        />
        <path
          id="dial"
          d="M22.9,13.5A21.917,21.917,0,0,0,1,35.4a.667.667,0,0,0,.706.706H44.09A.667.667,0,0,0,44.8,35.4,21.917,21.917,0,0,0,22.9,13.5ZM18.731,34.48l-16.318.071a20.5,20.5,0,0,1,40.971,0H27.066Z"
        />
        <path
          id="dial_inner"
          data-name="dial inner"
          d="M22.9,13.5A21.917,21.917,0,0,0,1,35.4a.667.667,0,0,0,.706.706H44.09A.667.667,0,0,0,44.8,35.4,21.917,21.917,0,0,0,22.9,13.5Z"
          opacity="0.393"
        />
        <path
          id="arrow"
          d="M-6.729,24.494,7.95,4.85,4.78,29.584C-.025,35.344-8.011,29.3-6.729,24.494Z"
          transform="translate(18.739 19.858) rotate(-3)"
        />
      </g>
    </svg>
  </span>
);

export const PowaIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <path
        d="M47.481,15.8A12.328,12.328,0,0,0,25.135,8.6h0L6,34.821H6A8.506,8.506,0,1,0,18.844,45.973h0L41.415,26.416l-.005-.005A12.338,12.338,0,0,0,47.481,15.8ZM12.356,45.32A4.843,4.843,0,1,1,17.2,40.477,4.849,4.849,0,0,1,12.356,45.32ZM20.8,39.509A8.52,8.52,0,0,0,12.5,31.947l10.469-14.2a12.358,12.358,0,0,0,10.92,10.333ZM35.14,24.44A8.641,8.641,0,1,1,43.78,15.8,8.653,8.653,0,0,1,35.14,24.44Zm0-12.76A4.117,4.117,0,1,0,39.257,15.8,4.123,4.123,0,0,0,35.14,11.68Zm0,5.152A1.035,1.035,0,1,1,36.175,15.8,1.036,1.036,0,0,1,35.14,16.832Z"
        transform="translate(6.353 5.76)"
      />
    </svg>
  </span>
);

export const PowerIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <path
        d="M26.49,12.092,12.757,28.087h6.712A2.332,2.332,0,0,1,21.8,30.417l-.005,11.317L35.541,25.753H28.8a2.332,2.332,0,0,1-2.333-2.333Zm4.643,8.995h9.875c2.576,0,3.309,1.581,1.624,3.541L21.69,49.007c-2.52,2.933-4.563,2.184-4.563-1.7V32.756H7.253c-2.576,0-3.309-1.581-1.624-3.541L26.57,4.836c2.52-2.933,4.563-2.184,4.563,1.7V21.087Z"
        transform="translate(7.87 5.079)"
      />
    </svg>
  </span>
);

export const SearchIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <path
        d="M6.6,34.67a1.631,1.631,0,0,0,2.307,2.307h0L17.788,28.1a12.249,12.249,0,1,0-2.307-2.307Zm18.6-7.328a8.992,8.992,0,1,1,8.992-8.992A9,9,0,0,1,25.2,27.342Z"
        transform="translate(10.204 10.202)"
      />
    </svg>
  </span>
);

export const TransmissionIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -3 46 47">
      <g id="transmission_2" data-name="transmission 2" transform="translate(-2 -2)">
        <path
          id="square"
          d="M6,0H40a6,6,0,0,1,6,6V38a6,6,0,0,1-6,6H6a6,6,0,0,1-6-6V6A6,6,0,0,1,6,0Z"
          transform="translate(2 2)"
          opacity="0.389"
        />
        <path
          id="transmission"
          d="M7.282,18.7V11.644a3.423,3.423,0,1,1,2.282,0V18.7h9.128V11.644a3.423,3.423,0,1,1,2.282,0V18.7h8.987c.63,0,3.141-.511,3.141-1.141V11.644a3.423,3.423,0,1,1,2.282,0v5.915c0,1.89-3.533,3.421-5.423,3.423H20.974v8.056a3.423,3.423,0,1,1-2.282,0V20.982H9.564v8.056a3.984,3.984,0,0,1,.959.524A3.307,3.307,0,0,1,11.8,32.844a3.423,3.423,0,1,1-4.515-3.806Z"
          transform="translate(3.846 3.838)"
        />
      </g>
    </svg>
  </span>
);

export const BodyStyleIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg id="bodystyle_2" data-name="bodystyle 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 -12 75.166 58">
      <path
        id="car"
        d="M.07,55.274V55.2a5.386,5.386,0,0,1,.143-.932c.976-5.591,4.363-10.641,8.869-12.076a39.873,39.873,0,0,1,6.759-1.416,5.365,5.365,0,0,0,4.288-3.038c4-7.1,9.59-11.181,16.439-12.718a48.356,48.356,0,0,1,19.39.056c3.712.617,7,3.277,8.959,7.24.819,1.575,1.457,3.317,2.343,4.826A17.728,17.728,0,0,0,70.338,41.5a12.126,12.126,0,0,1,4.145,7.547c.308,2.059.511,4.137.683,6.224h-.038a6,6,0,0,1,0,.634v4.659a1.7,1.7,0,0,1-1.5,1.817H69.742a10.619,10.619,0,0,0-10.378-12.07c-6.7-.074-11.882,5.855-11.1,11.651-25.188.28-23.728.559-23.728.419a11.136,11.136,0,0,0-11.074-12.07C8.036,50.55,2.786,55.531,2.989,60.566c0,.14-.468,1.258-.468,1.4L1.5,62.383A1.7,1.7,0,0,1,0,60.566v-5.05Q0,55.4,0,55.274ZM45.578,42.742c4.506,0,8.891.14,13.269-.065,2.155-.093,2.658-1.509,1.862-4.044-2.643-8.386-6.909-11.125-15.132-9.56Zm-19.382,0c4.889.093,9.762,0,14.937,0V29.213c-5.64-1.3-14.1,4.1-16.521,10.492-.736,2-.308,3.047,1.585,3.084Z"
        transform="translate(0 -24.066)"
      />
      <path
        id="tyre"
        d="M21.075,57a9.076,9.076,0,1,1-9.1,9.038A9.076,9.076,0,0,1,21.075,57Z"
        transform="translate(38.019 -28.909)"
        opacity="0.392"
      />
      <path
        id="rim"
        d="M4.295,0A4.295,4.295,0,1,0,8.589,4.295,4.295,4.295,0,0,0,4.295,0Z"
        transform="translate(54.771 32.926)"
      />
      <path
        id="tyre-2"
        data-name="tyre"
        d="M21.075,57a9.076,9.076,0,1,1-9.1,9.038A9.076,9.076,0,0,1,21.075,57Z"
        transform="translate(-7.281 -28.909)"
        opacity="0.392"
      />
      <path
        id="rim-2"
        data-name="rim"
        d="M4.295,0A4.295,4.295,0,1,0,8.589,4.295,4.295,4.295,0,0,0,4.295,0Z"
        transform="translate(9.471 32.926)"
      />
    </svg>
  </span>
);

export const Information = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="20px" height="20px">
      <path d="M16,27.2C9.8,27.2,4.8,22.1,4.8,16S9.8,4.7,16,4.7S27.2,9.8,27.2,16S22.1,27.2,16,27.2 M16,3.2C8.9,3.2,3.2,8.9,3.2,16S8.9,28.8,16,28.8S28.8,23.1,28.8,16S23,3.2,16,3.2 M17.4,21.2c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8h-3c-0.5,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h0.8v-7.5h-0.8c-0.5,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h1.5c0.4,0,0.8,0.3,0.8,0.8v8.3H17.4z M16,10.7c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8S16.4,10.7,16,10.7z" />
    </svg>
  </span>
);

export const VRMIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" width="18.5" height="10.5" viewBox="0 0 49.489 28.555">
      <path
        id="vrm-path"
        d="M53.957,37.5H12.426a3.982,3.982,0,0,0-3.979,3.975V62.076a3.982,3.982,0,0,0,3.979,3.975H53.957a3.982,3.982,0,0,0,3.979-3.975V41.471A3.981,3.981,0,0,0,53.957,37.5ZM12.426,63.067a.994.994,0,0,1-.995-.991V41.471a.994.994,0,0,1,.995-.991h7.212V63.067Zm42.526-.991a.994.994,0,0,1-.995.991H22.622V40.48H53.957a.994.994,0,0,1,.995.991Z"
        transform="translate(-8.447 -37.496)"
      />
    </svg>
  </span>
);

export const BodyStyleCarIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.417 54.736">
      <g id="bodyStyleCar" transform="translate(-1.183 -3.562)">
        <path
          id="Path_10"
          d="M81.276,30.4,65.967,25.672H9.411c-.14,0-4.228,2.046-4.228,4.727V49.571c0,2.835,1.691,4.727,4.228,4.727h2.113c0-6.619,4.65-11.818,10.569-11.818s10.569,5.2,10.569,11.818H58.026c0-6.619,4.65-11.818,10.568-11.818s10.569,5.2,10.569,11.818h2.114c2.536,0,11.271-1.892,11.271-4.727h0V41.282c0-.219.119.515,0,0,0,0,.007-2.12,0-2.152-.1-.512-.562-2.977-4.154-5.741C86.734,32.112,81.276,30.4,81.276,30.4Z"
          fill="none"
          stroke="#120b0b"
          strokeWidth="6"
        />
        <path
          id="Path_11"
          d="M3.715,19.559l13.521-18H57.984l6.467,18Z"
          transform="translate(6.939 6)"
          fill="none"
          stroke="#070606"
          strokeWidth="6"
        />
      </g>
    </svg>
  </span>
);

export const BodyStyleBikeIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 115 76.714">
      <g id="bigboybike" transform="translate(-1160 -214.286)">
        <g id="bodyStyleBIke" transform="translate(1169 226)">
          <path
            id="Path_1"
            d="M17,43A138.892,138.892,0,0,0,.748,43c0-6,5.4-13.836,11.4-13.836l9.568-7.477c1.132-3.315,7.827-10.4,11.331-10.4,0,0,16.951-.015,17.069,0v10.4s-5.425.409-7.021,0c-.773-.192-3.662-2.862-10.048,3.157-.919,0-.5,6.156-.5,6.156H49c3.914,0,10.99,1.788,13.239,5.229l21.768-8.475c6,0,19.135,14.453,18.884,18.264S83,43,83,43A20.987,20.987,0,0,0,62.239,61h5.052v6H38V64A20.985,20.985,0,0,0,26.551,45.313S23.451,43.578,17,43Z"
            transform="translate(-1 -20)"
            fill="none"
            stroke="#505050"
            strokeWidth="6"
          />
        </g>
        <g id="Ellipse_1" transform="translate(1160 257)" fill="#fff" stroke="#505050" strokeWidth="9">
          <circle cx="16.5" cy="16.5" r="16.5" stroke="none" />
          <circle cx="16.5" cy="16.5" r="12" fill="none" />
        </g>
        <g id="Ellipse_2" transform="translate(1160 257)" fill="#fff" stroke="#505050" strokeWidth="9">
          <circle cx="16.5" cy="16.5" r="16.5" stroke="none" />
          <circle cx="16.5" cy="16.5" r="12" fill="none" />
        </g>
        <g id="Ellipse_3" transform="translate(1242 258)" fill="#fff" stroke="#505050" strokeWidth="9">
          <circle cx="16.5" cy="16.5" r="16.5" stroke="none" />
          <circle cx="16.5" cy="16.5" r="12" fill="none" />
        </g>
      </g>
    </svg>
  </span>
);

export const TaxIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 23">
      <g id="taxone" transform="translate(-1227 -483)">
        <path
          id="Path_143"
          data-name="Path 143"
          d="M-1239.5-464a9.511,9.511,0,0,1-9.5-9.5,9.511,9.511,0,0,1,9.5-9.5,9.511,9.511,0,0,1,9.5,9.5A9.51,9.51,0,0,1-1239.5-464Zm0-17a7.509,7.509,0,0,0-7.5,7.5,7.509,7.509,0,0,0,7.5,7.5,7.508,7.508,0,0,0,7.5-7.5A7.508,7.508,0,0,0-1239.5-481Z"
          transform="translate(2476 966)"
        />
        <path
          id="Path_144"
          data-name="Path 144"
          d="M1229.166,488.439l3.712,2.576,3.573-1.576,3.887,1.576,3.542-2.576.714,1.537-4.256,2.973-3.887-1.295-3.573,1.295-4.536-2.973Z"
        />
        <path
          id="Path_145"
          data-name="Path 145"
          d="M2.5,0A2.5,2.5,0,1,1,0,2.5,2.5,2.5,0,0,1,2.5,0Z"
          transform="translate(1234 494)"
        />
      </g>
    </svg>
  </span>
);

export const CCIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 56.25">
      <g id="cc2" transform="translate(0 0)">
        <path
          id="Path_101"
          data-name="Path 101"
          d="M70.313,21.875a4.693,4.693,0,0,0-4.687,4.688V31.25H62.5V25.378a6.289,6.289,0,0,0-1.831-4.419l-3.5-3.5a6.288,6.288,0,0,0-4.419-1.831h-9V9.375h4.688a4.688,4.688,0,1,0,0-9.376H29.688a4.688,4.688,0,1,0,0,9.375h4.688v6.25H28.5a6.289,6.289,0,0,0-4.419,1.831l-4.419,4.419H17.188A4.693,4.693,0,0,0,12.5,26.562V31.25H9.376V26.562a4.688,4.688,0,1,0-9.376,0v18.75a4.688,4.688,0,1,0,9.376,0V40.625H12.5v4.688A4.693,4.693,0,0,0,17.188,50h2.478l4.419,4.419A6.289,6.289,0,0,0,28.5,56.25H49.622a6.289,6.289,0,0,0,4.419-1.831l6.628-6.628A6.289,6.289,0,0,0,62.5,43.372V40.625h3.125v4.688a4.688,4.688,0,1,0,9.376,0V26.562a4.693,4.693,0,0,0-4.687-4.687m1.563,23.438h0C71.855,46.161,71.852,25.711,71.876,45.313Z"
          transform="translate(-0.001)"
          fill="#040504"
          opacity="0.313"
        />
        <path
          id="Path_124"
          data-name="Path 124"
          d="M3.6,32.8s7.953,5.173,21.88,2.206c.8-.169,1.942-.458,3.12-.806,15.7-4.646,27.369.673,27.369.673L55.341,38.2C38.32,34.16,29.53,37.9,29.53,37.9,10.427,42.541,3.6,36.6,3.6,36.6Z"
          transform="translate(8)"
          opacity="0.409"
        />
        <path
          id="Path_128"
          data-name="Path 128"
          d="M5,0H25a5,5,0,0,1,0,10H5A5,5,0,0,1,5,0Z"
          transform="translate(24)"
        />
        <path
          id="Path_127"
          data-name="Path 127"
          d="M5,0H25a5,5,0,0,1,0,10H5A5,5,0,0,1,5,0Z"
          transform="translate(75 20) rotate(90)"
        />
        <path
          id="Path_126"
          data-name="Path 126"
          d="M5,0H25a5,5,0,0,1,0,10H5A5,5,0,0,1,5,0Z"
          transform="translate(10 20) rotate(90)"
        />
      </g>
    </svg>
  </span>
);

export const BhpIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.983 77.368">
      <g id="horsey" transform="translate(-34.053 7.773)">
        <path
          id="Path_121"
          data-name="Path 121"
          d="M4.053,32.521C4.053,35,5.727,38.9,8.206,38.9c1.136,0,3.939,2.11,6.322,0s3.209-8.439,3.209-8.439,4.633,2.165,5.42,1.182c.9,1.2-3.177,6.14.017,17.121-.162,1.88-2.638,4.1-1.017,6.2.22,1-3.033,3.716-4.131,5.654s-3.314,3.19-3.021,7.2a9.2,9.2,0,0,0,3.816,6.352C19.995,77,20.313,83.23,20.372,84.182c.043.687,9.183-5.628,6.872-6.474,0,0-2.415-2.578-2.831-3.16-2.195-3.055-2.331-2.819-2.5-6.252,0,0,3.96-3.256,5.742-3.869a79.978,79.978,0,0,0,4.495,7.834,9.4,9.4,0,0,1-.756,3.566c-.765,1.6-.258,4.963-1.172,5.229l-.948,4.95V87.6l8.988-.006c.8-2.939-2.4-4.84-2.464-6.176s.165-5.144.165-5.144,2.427-13.342,2.677-14.686c.724-.32,3.749,3.844,10.152,3.844s11.544-.742,15.462-3.844,2.647,3.78,3.111,4.832-.757,1.911,1.145,5.84a9.573,9.573,0,0,1,0,8.8c-.849,1.6-2.187,1.761-2.314,2.317l-1.782,4.218,8.995,0-1.065-5.413s3.516-12.728,3.7-13.083a23.232,23.232,0,0,1,2.186,3.156S79.7,82.728,76.662,83.182A33.347,33.347,0,0,1,75.2,87.589s8.1.154,7.921-1.182-.979-3.512-.979-4.225.534-.713.979-1.867-.377-5.051-.377-5.051.732-4.722.809-6.146,3.227-2.924.577-5.623c-.914-.776-.782-1.491-1.386-3.473a28.12,28.12,0,0,1-.976-5.617c.15-.373,1.718-5.715,1.342-8.6C90.524,56.193,86.964,62.5,86.964,62.5l7.072-.912C91.307,43.3,86.631,41.4,79.939,38.9c-.872-1-1.951-2.972-3.277-3.894-7.432-4.739-10.849.5-17.56,0s-18.529-3.112-20.838-5c-3.125-6.587-8.051-13.208-21.733-15.741,0,0-1.65-3.636-4.488-4.035a43.847,43.847,0,0,1-2.109,7.3C4.2,31.245,4.053,31.867,4.053,32.521Z"
          transform="translate(30 -18)"
          opacity="0.412"
        />
        <path
          id="Path_123"
          data-name="Path 123"
          d="M2,0A2,2,0,1,1,0,2,2,2,0,0,1,2,0Z"
          transform="translate(43 1.408)"
        />
        <path
          id="Path_122"
          data-name="Path 122"
          d="M81.5-7.773h22.563L87.115,8.408h13.5l-39.7,33.475L73.779,20.451H60.914Z"
        />
      </g>
    </svg>
  </span>
);

export const ArrowDown = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 451.847 451.847" fill="rgba(180, 180, 180, 0.6)">
      <path d="M225.923 354.706c-8.098 0-16.195-3.092-22.369-9.263L9.27 151.157c-12.359-12.359-12.359-32.397 0-44.751 12.354-12.354 32.388-12.354 44.748 0l171.905 171.915 171.906-171.909c12.359-12.354 32.391-12.354 44.744 0 12.365 12.354 12.365 32.392 0 44.751L248.292 345.449c-6.177 6.172-14.274 9.257-22.369 9.257z" />
    </svg>
  </span>
);

export const Microphone = ({ classProp }) => (
  <span className={classProp}>
    <svg
      width="32"
      height="32"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 32 32"
      style={{ transform: 'rotate(360deg)' }}
    >
      <path
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 2c-4 0-4 4-4 4v10s0 4 4 4s4-4 4-4V6s0-4-4-4zM8 17s0 7 8 7s8-7 8-7M13 29h6m-3-5v5"
      />
    </svg>
  </span>
);

export const FinanceIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 300">
      <path
        id="Path_1"
        data-name="Path 1"
        d="M18.63,70.22V263H61.02V183.35h80.73V150.41H61.02V105.86h93.15V70.22Z"
        transform="translate(-18.13 -69.72)"
        stroke="#0a0909"
        strokeWidth="1"
      />
    </svg>
  </span>
);

export const TrimIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 10 57 87">
      <g id="trim_2" data-name="trim 2" transform="translate(7.726 7.893)">
        <path
          id="bottom"
          d="M80.321,78.987a5.7,5.7,0,0,0-5.258,2.4h-24.5a5.833,5.833,0,0,0-5.727-2.4c-3.286.621-4.413,4.527-4.037,7.1a7.826,7.826,0,0,0,2.253,3.995,17.926,17.926,0,0,0,5.633,4.35.888.888,0,0,0,.469.089H76.1a.358.358,0,0,0,.282-.089,16.721,16.721,0,0,0,5.633-4.35,7.826,7.826,0,0,0,2.253-3.995C84.733,83.514,83.513,79.519,80.321,78.987Z"
          transform="translate(-42.343 -36.317)"
          opacity="0.708"
        />
        <path
          id="centre_top"
          data-name="centre top"
          d="M1.052,0H15.509V8.193H1.052Z"
          transform="translate(12.04 14.796)"
          opacity="0.594"
        />
        <path
          id="head"
          d="M69.406,41.6H56.339a2.95,2.95,0,0,1-2.1-.863,1.775,1.775,0,0,1-.439-1.57l1.491-6.122A2.565,2.565,0,0,1,57.83,31.4H67.915a2.565,2.565,0,0,1,2.543,1.648L71.95,39.17a1.775,1.775,0,0,1-.439,1.57A2.95,2.95,0,0,1,69.406,41.6Z"
          transform="translate(-42.573 -29.648)"
          opacity="0.641"
        />
        <path
          id="prong"
          d="M59.054,42A1.321,1.321,0,0,0,57.9,43.4v4.641a1.176,1.176,0,1,0,2.308,0V43.4A1.321,1.321,0,0,0,59.054,42Z"
          transform="translate(-43.037 -33.498)"
        />
        <path
          id="prong-2"
          data-name="prong"
          d="M68.954,42A1.321,1.321,0,0,0,67.8,43.4v4.641a1.176,1.176,0,1,0,2.308,0V43.4A1.321,1.321,0,0,0,68.954,42Z"
          transform="translate(-44.149 -33.498)"
        />
        <path
          id="centre_bottom"
          data-name="centre bottom"
          d="M1.01,5.154H13.928v14.5H1.01Z"
          transform="translate(12.806 24.436)"
          opacity="0.601"
        />
        <path
          id="right_bit"
          data-name="right bit"
          d="M76.543,76.895c1.42-1.687,4.16-4.052,7.61-2.266,1.437-1.929-3.154-11.4-2.8-17.441.178-3.107-3.3-9.587-5.7-9.587H75.3V76.895Z"
          transform="translate(-44.991 -32.804)"
          opacity="0.596"
        />
        <path
          id="left_bit"
          data-name="left bit"
          d="M83.11,76.895c-1.42-1.687-4.16-4.052-7.61-2.266-1.437-1.929,3.154-12.168,2.8-18.2C78.122,53.317,81.6,47.6,84,47.6h.355V76.895Z"
          transform="translate(-74.143 -32.804)"
          opacity="0.6"
        />
        <path
          id="Path_99"
          data-name="Path 99"
          d="M13.876,18.837h2.893v8.245H31.086V18.837H34.02V48.175h-3.7V33.744H17.486V48.175h-3.61Z"
          transform="translate(-3.665 -4.042)"
          opacity="0.343"
        />
      </g>
    </svg>
  </span>
);

export const CloseX = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 694 694">
      <path
        d="M347,694a352.555,352.555,0,0,1-35.479-1.792A346.48,346.48,0,0,1,1.792,382.479a352.2,352.2,0,0,1,0-70.957A346.481,346.481,0,0,1,311.522,1.792a352.2,352.2,0,0,1,70.957,0,346.482,346.482,0,0,1,309.729,309.73,352.192,352.192,0,0,1,0,70.957A346.481,346.481,0,0,1,382.479,692.208,352.555,352.555,0,0,1,347,694Zm-.226-296.693h0l116.69,117.142a35.429,35.429,0,0,0,57.914-11.771,35.644,35.644,0,0,0-7.786-38.551L396.9,346.984l116.69-117.142a35.515,35.515,0,1,0-50.128-50.322L346.775,296.663,230.084,179.52a35.429,35.429,0,0,0-57.914,11.771,35.643,35.643,0,0,0,7.787,38.551l116.69,117.142L179.957,464.127a35.514,35.514,0,1,0,50.127,50.322l116.69-117.142Z"
        transform="translate(0 0)"
        fill="#c35a5a"
      />
    </svg>
  </span>
);

export const TownIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-150 0 1000 1000">
      <path d="M320 0c-177 0-320 143-320 320s160 416 320 704c160-288 320-527 320-704s-143-320-320-320z m0 448c-71 0-128-57-128-128s57-128 128-128 128 57 128 128-57 128-128 128z"></path>
    </svg>
  </span>
);

export const RecencyIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 187.852 187.852"
      enableBackground="new 0 0 187.852 187.852"
    >
      <g>
        <path d="m185.975,75.172c-0.661-3.247-3.826-5.339-7.077-4.682-0.432,0.088-0.843,0.223-1.23,0.395-2.524,1.115-4.025,3.867-3.452,6.682 6.364,31.255-6.146,63.636-31.873,82.495-17.538,12.856-39.179,18.089-60.937,14.739-21.758-3.352-40.821-14.856-53.677-32.395-26.73-36.468-18.81-87.883 17.656-114.615 17.538-12.855 39.18-18.088 60.937-14.739 21.13,3.255 39.701,14.217 52.531,30.91l-5.516-.793c-3.283-0.474-6.322,1.805-6.793,5.085-0.472,3.28 1.805,6.321 5.085,6.793l19.915,2.864c0.132,0.019 0.262,0.018 0.394,0.028 0.156,0.012 0.313,0.034 0.468,0.034 2.936,0 5.501-2.157 5.932-5.147l2.864-19.915c0.472-3.28-1.805-6.321-5.085-6.793-3.281-0.473-6.321,1.805-6.793,5.085l-.811,5.639c-14.725-19.262-36.074-31.909-60.363-35.65-24.925-3.842-49.735,2.17-69.858,16.92-20.124,14.753-33.322,36.604-37.161,61.529-3.839,24.926 2.169,49.735 16.921,69.859 14.751,20.123 36.602,33.32 61.528,37.16 4.914,0.757 9.821,1.131 14.691,1.131 19.833,0 39.012-6.209 55.167-18.052 29.49-21.618 43.832-58.738 36.537-94.567z" />
        <path d="m126.601,96.171h-20.48c-1.502-3.399-4.234-6.132-7.633-7.633v-46.034c0-3.313-2.687-6-6-6s-6,2.687-6,6v46.034c-5.236,2.314-8.904,7.55-8.904,13.633 0,8.218 6.686,14.904 14.904,14.904 6.083,0 11.319-3.668 13.633-8.904h20.48c3.313,0 6-2.687 6-6s-2.687-6-6-6zm-37.018,6c0-1.602 1.303-2.905 2.904-2.905s2.905,1.303 2.905,2.905-1.303,2.904-2.905,2.904-2.904-1.303-2.904-2.904z" />
      </g>
    </svg>
  </span>
);

export const ClassIcon = ({ classProp }) => (
  <span className={classProp}>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 8.999 16.873">
      <path
        id="Path_13"
        data-name="Path 13"
        d="M14.926,9H12.735a17.174,17.174,0,0,1-3.39-2.607V5.953H6.605l-.7-.52-.706.52H4.261l-.7-.77-.695.77H.909A.942.942,0,0,1,0,4.984V4.015a.942.942,0,0,1,.909-.969H9.345v-.4A14.106,14.106,0,0,1,12.613,0h2.443a1.883,1.883,0,0,1,1.817,1.939V6.922A2.017,2.017,0,0,1,14.926,9Zm-.909-7.061a.909.909,0,0,0-.908.908V6.152a.909.909,0,0,0,1.817,0V2.847A.91.91,0,0,0,14.017,1.939Z"
        transform="translate(0 16.873) rotate(-90)"
      />
    </svg>
  </span>
);

export const IconSetOne = {
  recentlyadded: RecencyIcon,
  location: LocationIcon,
  year: AgeIcon,
  price: BudgetIcon,
  mileage: MileageIcon,
  trim: TrimIcon,
  bhp: BhpIcon,
  doors: DoorsIcon,
  make: MakeIcon,
  fuel: FuelIcon,
  bodystyle: BodyStyleIcon,
  budget: BudgetIcon,
  finance: FinanceIcon,
  colour: ColorIcon,
  model: ModelIcon,
  transmission: TransmissionIcon,
  town: TownIcon,
  cc: CCIcon,
  tax: TaxIcon,
  vehicletype: ClassIcon,
  vrm: VRMIcon
};

export default IconSetOne;
