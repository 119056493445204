import React, { memo } from 'react';
import AlertCard from '~/components/Common/AlertCard/AlertCard';
import { getPublicWarnings, Warning, PublicWarnings } from './utils';

type PublicWarningMessageProps = {
  publicWarnings: PublicWarnings;
};

const AdditionalWarning: React.FC<{ warning: Warning }> = ({ warning }) => (
  <div className="additional-warning">{warning.Message}</div>
);

const PublicWarningMessage: React.FC<PublicWarningMessageProps> = ({ publicWarnings }) => {
  if (!publicWarnings) {
    return null;
  }

  const warnings = getPublicWarnings(publicWarnings);
  if (!warnings.length) {
    return null;
  }

  const [firstWarning, ...additionalWarnings] = warnings;

  return (
    <AlertCard iconName="information" paragraph={firstWarning.Message}>
      {additionalWarnings.map((warning, index) => (
        <AdditionalWarning key={warning.Message} warning={warning} />
      ))}
    </AlertCard>
  );
};

export default memo(PublicWarningMessage);

