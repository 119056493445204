import React from 'react';
import Icon from '../Common/Icon/Icon';
import ToggleSwitch from '../Common/ToggleSwitch';
import './permissionsGroupItem.scss';
import PropTypes from 'prop-types';
import TrackEvent from '../Common/Tracking/TrackEvent';
import startCase from 'lodash/startCase';

const PermissionsGroupItem = ({ id, label, icon, isActive, handleToggle, disabled, trackingPage }) => {
  const handleToggleById = (bool) => {
    handleToggle(bool, id);
  };
  return (
    <div className="permissionGroupItem">
      <div className="permissionGroupItem__icon">
        <Icon name={icon} />
      </div>
      <label className="permissionGroupItem__label" htmlFor={id}>
        {label}
      </label>
      <div className="permissionGroupItem__toggle">
        <TrackEvent
          featureName="permissionGroupItemToggle"
          interactionName={`permissionGroupItemToggle${startCase(id)
            .split(' ')
            .join('')}${isActive ? 'Disabled' : 'Enabled'}`}
        >
          <ToggleSwitch
            id={id}
            trackingPage={trackingPage}
            disabled={disabled}
            dataTag={`${id}SettingToggle`}
            isActive={isActive}
            handleUpdate={handleToggleById}
          />
        </TrackEvent>
      </div>
    </div>
  );
};
export default PermissionsGroupItem;
PermissionsGroupItem.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func,
  trackingPage: PropTypes.string
};
