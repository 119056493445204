import { Trans, useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NoPanelData from '../../../Dashboard/components/NoPanelData';
import OrdersItem from '../../../DigitalDeals/components/OrdersItem';
import './onlineOrders.scss';

function OnlineOrders(props) {
  const { t } = useTranslation('Customer');
  const { customerOnlineOrders, dealershipId, canUseOrders } = props;
  const [showMore, setShowMore] = useState(false);
  return (
    <>
      {!customerOnlineOrders || customerOnlineOrders.length === 0 ? (
        <>
          {canUseOrders ? (
            <div className="onlineOrders__noDeals">
              <NoPanelData text={t('OnlineOrders.there_are_currently_no_online_orders_for_this_vehicle')} />
            </div>
          ) : (
            <div className="onlineOrders__contactUs">
              <Trans
                ns="Customer"
                i18nKey="OnlineOrders.buy_online_contact"
                components={{
                  a: <a />
                }}
              />
            </div>
          )}
        </>
      ) : (
        <div className="onlineOrders__container">
          {showMore ? (
            <div className="onlineOrders__deals">
              {customerOnlineOrders.length && (
                <div>
                  {customerOnlineOrders.map((order, i) => {
                    return <OrdersItem order={order} dealershipId={dealershipId} isCustomerRecord={true} />;
                  })}
                </div>
              )}
            </div>
          ) : (
            <div className="onlineOrders__deals">
              {customerOnlineOrders.length && (
                <div>
                  <OrdersItem order={customerOnlineOrders[0]} dealershipId={dealershipId} isCustomerRecord={true} />
                </div>
              )}
            </div>
          )}
          {customerOnlineOrders.length > 1 && (
            <div className="onlineOrders__showMoreButton" onClick={() => setShowMore(!showMore)}>
              {showMore ? t('OnlineOrders.show_less') : t('OnlineOrders.show_more')}
            </div>
          )}
        </div>
      )}
    </>
  );
}

OnlineOrders.propTypes = {
  customerOnlineOrders: PropTypes.array,
  dealershipId: PropTypes.string
};
export default OnlineOrders;
