import produce from 'immer';
import MOTConsumer from '../consumer/MOT';
import defaultRules, { LenderCorporateConfig } from './default';

export default produce(defaultRules, (draft: LenderCorporateConfig) => {
  draft.applicationStatuses = MOTConsumer.applicationStatuses;
  draft.validationRules.BusinessDetails!.CompanyRegistrationNumber =
    'required:ifoneof:CompanyType:["LimitedCompany", "LimitedLiabilityPartnership", "PublicLimitedCompany"]';
  draft.validationRules.OrganisationContacts!.Items![0]!.DateOfBirth =
    'required:ifoneof:Position:["Director"], date, youngerThan99, olderThan18';
});
