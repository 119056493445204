import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  draft.applicationFields.AffordabilityDetails = {
    Other: true,
    DetailsOfExpectedChanges: false,
    ChangesExpected: false,
    MortgageOrRental: false
  };
  draft.validationRules.AffordabilityDetails = {
    MonthlyExpenditure: {
      Other: 'currency, required,  max:999999'
    }
  };
});
