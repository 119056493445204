import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import AlertCard from '~/components/Common/AlertCard/AlertCard';
import { trackRequoteNewRateSelected } from '~/tracking/avoTracking';
import { inject } from 'mobx-react';
import { getFormattedAPR } from '~/components/Quoting/components/FormatAPR';
import { ApplicationStatusRequoteNewRateSelectedProperties } from '~/lib/Avo';
import { AppStore } from '~/modules/Stock/types/Types';

export interface RequoteAlertCardProps extends ApplicationStatusRequoteNewRateSelectedProperties {
  canRequote?: boolean;
  appStore?: AppStore;
}

const RequoteAlertCard: React.FC<RequoteAlertCardProps> = (props) => {
  const { t } = useTranslation('Application');
  const params = useParams();

  const { canRequote, appStore, ...restProps } = props;

  const newRateAlertContent = {
    title: canRequote
      ? t('ApplicationStatus.new_rate_has_been_applied', { newRate: getFormattedAPR(props.preApprovedRate, appStore) })
      : t('ApplicationStatus.quote_has_expired'),
    paragraph: canRequote
      ? t('ApplicationStatus.please_note_new_rate')
      : t('ApplicationStatus.please_generate_another_quote')
  };

  const handleRequoteClick = () => {
    trackRequoteNewRateSelected({ ...restProps });
  };

  return (
    <div className="applicationStatus__alert">
      <AlertCard
        iconName="warning"
        title={newRateAlertContent.title}
        paragraph={newRateAlertContent.paragraph}
        data-th="requoteAlertCard"
      >
        {canRequote && (
          <Link
            onClick={handleRequoteClick}
            to={`/d/${params.dealershipId}/consumers/${params.consumerId}/application/${params.applicantId}/requote`}
            className="link"
          >
            {t('ApplicationStatus.click_here_to_proceed')}
          </Link>
        )}
      </AlertCard>
    </div>
  );
};

export default inject('appStore')(RequoteAlertCard);

