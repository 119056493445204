import { CompanionQuotes, DisplayQuoteFormat, LoanType, QuoteTypes, QuoteVariants } from '../types';
import { purgeQuoteKeys } from './purgeQuoteKeys';
import { formatQuoteToDisplay, formatSortAndNameQuoteKeys } from './formatQuoteToDisplay';

type FormatAllQuotesProps = {
  quote: QuoteTypes;
  options: FormatQuoteOptionsProp;
};

export type MultiQuotesFormatResponse = {
  [LoanType.vehicleLoan]?: DisplayQuoteFormat;
  [LoanType.vapsLoan]?: DisplayQuoteFormat;
  [LoanType.negativeEquityLoan]?: DisplayQuoteFormat;
};

export const findCompanionQuoteByType = (quotes: CompanionQuotes | undefined, type: LoanType) =>
  quotes ? quotes.find((quote) => quote?.LoanType === type) : undefined;

export const getAllLoansDisplaySchema = ({ quote, options }: FormatAllQuotesProps): MultiQuotesFormatResponse => {
  const VehicleLoan = findCompanionQuoteByType(quote.CompanionQuotes, LoanType.vehicleLoan);
  const VapsLoan = findCompanionQuoteByType(quote.CompanionQuotes, LoanType.vapsLoan);
  const NegativeEquityLoan = findCompanionQuoteByType(quote.CompanionQuotes, LoanType.negativeEquityLoan);

  options['hasNegativeEquityLoan'] = NegativeEquityLoan !== undefined;

  /*
   * Refactor - Move the LoanType property to the quote schema coming back from the API
   * rather than define it here every time.
   */
  const formattedQuotes = {
    [LoanType.vehicleLoan]:
      VehicleLoan?.LoanType === LoanType.vehicleLoan
        ? getQuoteDisplaySchema({
            quote: { ...VehicleLoan },
            options
          })
        : undefined,
    [LoanType.negativeEquityLoan]:
      NegativeEquityLoan && NegativeEquityLoan?.LoanType === LoanType.negativeEquityLoan
        ? getQuoteDisplaySchema({
            quote: { ...NegativeEquityLoan },
            options
          })
        : undefined,
    [LoanType.vapsLoan]:
      VapsLoan && VapsLoan?.LoanType === LoanType.vapsLoan
        ? getQuoteDisplaySchema({
            quote: { ...VapsLoan },
            options
          })
        : undefined
  };

  return formattedQuotes;
};

export type FormatQuoteOptionsProp = {
  variant: QuoteVariants;
  isBnpp: boolean;
  hasNegativeEquityLoan?: boolean;
};
export type FormatQuoteProps = {
  quote: QuoteTypes;
  options: FormatQuoteOptionsProp;
};
export const getQuoteDisplaySchema = ({ quote, options }: FormatQuoteProps): DisplayQuoteFormat => {
  const renamedQuote = formatSortAndNameQuoteKeys(quote, options);
  const purgedQuote = purgeQuoteKeys(renamedQuote);
  const displayQuote = formatQuoteToDisplay(purgedQuote);

  return displayQuote;
};
