import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import PanelContent from '../../Common/PanelContent';
import PanelHeader from '../../Common/PanelHeader';
import MiniPanel from '../../Common/MiniPanel';
import MiniPanelHeader from '../../Common/MiniPanelHeader';
import VehiclePanel from '../../Reporting/PartialSummarySections/VehiclePanel';
import AccessoriesPanel from '../../Reporting/PartialSummarySections/AccessoriesPanel';
import DealerInfo from '../../Common/DealerInfo';
import { useTranslation } from 'react-i18next';
import { findCompanionQuoteByType } from '~/components/QuoteCard/utils/getQuoteDisplaySchema';
import { LoanTypeJs, QuoteSummaryVariantTypeJs } from '~/components/QuoteCard/types';
import QuotePanelV2 from '../../Reporting/PartialSummarySections/QuotePanelV2';
import FormFooter from '~/components/Common/Form/FormFooter';
import { printPageByRef } from '~/core/print';
import '../../Common/PrintPanels/printArea.scss';
import './selfServiceQuote.scss';
import { hasCompanionQuotes } from '~/features';

const SelfServiceQuote = ({
  dealership,
  consumer,
  quote,
  vehicle,
  proceedingState,
  applyWithSelfServiceQuote,
  cancel
}) => {
  const { t } = useTranslation('Quoting');
  const printAreaRef = useRef();

  const handlePrint = () => {
    printPageByRef(printAreaRef);
  };
  let vehicleLoan;
  let vapsLoan;
  let negativeEquityLoan;
  if (hasCompanionQuotes(quote)) {
    vehicleLoan = findCompanionQuoteByType(quote.CompanionQuotes, LoanTypeJs.vehicleLoan);
    vapsLoan = findCompanionQuoteByType(quote.CompanionQuotes, LoanTypeJs.vapsLoan);
    negativeEquityLoan = findCompanionQuoteByType(quote.CompanionQuotes, LoanTypeJs.negativeEquityLoan);
  }

  return (
    <Page>
      <Breadcrumbs
        items={[
          {
            name: t('SelfServiceQuote.home'),
            path: `/d/${dealership.Id}`
          },
          {
            name: t('SelfServiceQuote.customer_list'),
            path: `/d/${dealership.Id}/consumers`
          },
          {
            name: t('SelfServiceQuote.consumer'),
            path: `/d/${dealership.Id}/consumers/${consumer.Id}`
          },
          {
            name: t('SelfServiceQuote.self_service_quote')
          }
        ]}
        consumer={consumer}
      />
      <Panel>
        <PanelHeader>{t('SelfServiceQuote.self_service_quote_information')}</PanelHeader>
        <PanelContent>
          <div className="printArea" ref={printAreaRef}>
            <DealerInfo dealership={dealership} />
            <MiniPanel>
              <MiniPanelHeader title={t('SelfServiceQuote.vehicle')} />
              <VehiclePanel vehicle={vehicle} quote={quote} />
            </MiniPanel>
            {quote.Insurance === 0 &&
            quote.Warranty === 0 &&
            quote.OtherAccessories === 0 &&
            quote.NonVatableItems === 0 ? (
              ''
            ) : (
              <MiniPanel>
                <MiniPanelHeader title={t('SelfServiceQuote.vehicle_accessories')} />
                <AccessoriesPanel quote={quote} />
              </MiniPanel>
            )}
            <MiniPanel>
              <MiniPanelHeader title={t('SelfServiceQuote.quote')} />
              <QuotePanelV2
                quote={quote}
                loanType={LoanTypeJs.standardLoan}
                variant={QuoteSummaryVariantTypeJs.SelfServiceSummary}
              />
            </MiniPanel>
            {/* Standard or Aggregate Quote END */}

            {/* Companion Quotes START */}
            {vehicleLoan && (vapsLoan || negativeEquityLoan) && (
              <>
                <div className="SelfServiceQuote__MultiFinance">
                  {t('SelfServiceQuote.this_quote_is_made_up_of_multiple_finance_components')}
                </div>
                <MiniPanel>
                  <MiniPanelHeader title={t('SelfServiceQuote.vehicle_loan')} />
                  <QuotePanelV2
                    quote={vehicleLoan}
                    loanType={LoanTypeJs.vehicleLoan}
                    variant={QuoteSummaryVariantTypeJs.SelfServiceSummary}
                    hasNegativeEquityLoan={negativeEquityLoan !== undefined}
                  />
                </MiniPanel>
              </>
            )}
            {negativeEquityLoan && (
              <MiniPanel>
                <MiniPanelHeader title={t('SelfServiceQuote.negative_equity_loan')} />
                <QuotePanelV2
                  quote={negativeEquityLoan}
                  loanType={LoanTypeJs.negativeEquityLoan}
                  variant={QuoteSummaryVariantTypeJs.SelfServiceSummary}
                />
              </MiniPanel>
            )}
            {vapsLoan && (
              <MiniPanel>
                <MiniPanelHeader title={t('SelfServiceQuote.value_added_products_loan')} />
                <QuotePanelV2
                  quote={vapsLoan}
                  loanType={LoanTypeJs.vapsLoan}
                  variant={QuoteSummaryVariantTypeJs.SelfServiceSummary}
                />
              </MiniPanel>
            )}
            {/* Companion Quotes END */}
            <FormFooter
              submitLabel={t('EditProductCommissionsForm.apply')}
              onSubmit={() => applyWithSelfServiceQuote(vehicle, quote, consumer.CustomerType)}
              submitDisabled={false}
              onCancel={cancel}
              onPrint={handlePrint}
              submittingState={proceedingState}
            />
          </div>
        </PanelContent>
      </Panel>
    </Page>
  );
};

SelfServiceQuote.propTypes = {
  consumer: PropTypes.object,
  dealership: PropTypes.object,
  quote: PropTypes.object,
  vehicle: PropTypes.object,
  cancel: PropTypes.func,
  applyWithSelfServiceQuote: PropTypes.func,
  proceedingState: PropTypes.string
};

export default SelfServiceQuote;
