import React from 'react';
import PropTypes from 'prop-types';

const Tabs = (props) => {
  return (
    <div>
      <div>{React.cloneElement(props.children[0], { onClick: props.setActiveTab, currentTab: props.currentTab })}</div>

      {React.cloneElement(props.children[1], { currentTab: props.currentTab })}
    </div>
  );
};

Tabs.propTypes = {
  currentTab: PropTypes.number,
  setActiveTab: PropTypes.func,
  children: PropTypes.node
};

export default Tabs;
