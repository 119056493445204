import { Outlet, useLocation, useParams } from 'react-router-dom';
import Breadcrumbs from '../Common/Breadcrumbs';
import Sidebar from './Sidebar/Sidebar';
import { useTranslation } from 'react-i18next';
import { camelCase, snakeCase } from 'lodash';
import { inject } from 'mobx-react';
import type UiState from '~/mobx-stores/UiState';
import styles from './insightsContainer.module.scss';

  const InsightsContainer: React.FC<{ appStore?: { uiState: UiState } }> = ({ appStore }) => {
  const { t } = useTranslation('Insights');
  const { dealershipId } = useParams<{ dealershipId: string }>();
  const location = useLocation();
  const baseUrl = `/d/${dealershipId}`;
  const { canUseInsights, canSeeFinanceInsights } = (appStore?.uiState as UiState) || {};
 
  const currentReport = snakeCase(location.pathname.split('/').pop()) || ''; 

  const permittedReportUrl = canUseInsights
    ? canSeeFinanceInsights
      ? 'insights/application-volumes'
      : 'insights/average-vehicles-stock'
    : '';

  return (
    <div className={styles['container']}>
      <div className={styles['sidebar']}>
        <Sidebar />
      </div>
      <div className={styles['content']}>
        <Breadcrumbs
          items={[
            {
              name: t('InsightsContainer.home'),
              path: baseUrl
            },
            {
              name: t('InsightsContainer.insights'),
              path: `${baseUrl}/${permittedReportUrl}`
            },
            {
              name: t(`InsightsContainer.${currentReport}`),
              dataTh: `${camelCase(currentReport)}Breadcrumbs`
            }
          ]}
        />

        <Outlet />
      </div>
    </div>
  );
};

export default inject('appStore')(InsightsContainer);

