import React from 'react';
import cn from 'classnames';

export type IconNames =
  | 'account-settings'
  | 'add-customer'
  | 'affordability'
  | 'alert-new'
  | 'alert'
  | 'application-alt'
  | 'application'
  | 'approved'
  | 'arrow-circle'
  | 'assign'
  | 'bank'
  | 'bike'
  | 'bin'
  | 'broadcast'
  | 'burger'
  | 'calendar'
  | 'camera'
  | 'car'
  | 'check'
  | 'clicks'
  | 'clock'
  | 'connection'
  | 'consumer'
  | 'credit-score'
  | 'cross-2'
  | 'cross'
  | 'customers'
  | 'bar-chart'
  | 'dealership-user'
  | 'dealership'
  | 'deals_icon'
  | 'discover'
  | 'distance'
  | 'down'
  | 'e-sign'
  | 'edit-schemes'
  | 'edit'
  | 'email'
  | 'employment'
  | 'error'
  | 'export'
  | 'eye'
  | 'finance-check'
  | 'finance-leads'
  | 'full-page-ad'
  | 'global-settings'
  | 'group-report'
  | 'home-dashboard'
  | 'home'
  | 'info'
  | 'information'
  | 'inprogress'
  | 'insights-report'
  | 'insights-car'
  | 'insights-star'
  | 'league-table'
  | 'left'
  | 'line-chart'
  | 'linked-consumer'
  | 'location'
  | 'mail_success'
  | 'map'
  | 'medal'
  | 'mileage'
  | 'minus'
  | 'motorhome'
  | 'multi-actions'
  | 'note'
  | 'notification'
  | 'online-order'
  | 'opened-deal'
  | 'padlock'
  | 'partial-application'
  | 'payment_icon'
  | 'pending-circle'
  | 'pending'
  | 'pie-chart'
  | 'play'
  | 'plus'
  | 'price-position'
  | 'printer'
  | 'proceed'
  | 'product-settings'
  | 'question'
  | 'quick-quote-eur'
  | 'quick-quote'
  | 'quote'
  | 'rejected'
  | 'report'
  | 'reporting'
  | 'repropose'
  | 'right'
  | 'search'
  | 'self-service-deals'
  | 'send-deal'
  | 'settings'
  | 'sign-out'
  | 'sm-ageIcon'
  | 'sm-bhpIcon'
  | 'sm-bodyStyleIcon'
  | 'sm-budgetIcon'
  | 'sm-ccIcon'
  | 'sm-classIcon'
  | 'sm-colorIcon'
  | 'sm-doorIcon'
  | 'sm-ecoIcon'
  | 'sm-fuelIcon'
  | 'sm-locationIcon'
  | 'sm-locationIcon2'
  | 'sm-makeIcon'
  | 'sm-mileageIcon'
  | 'sm-modelIcon'
  | 'sm-powerIcon'
  | 'sm-powerIcon2'
  | 'sm-recencyIcon'
  | 'sm-taxIcon'
  | 'sm-transmissionIcon'
  | 'sm-trimIcon'
  | 'spinner'
  | 'star_1'
  | 'star_gray_1'
  | 'star_gray'
  | 'star'
  | 'stock-settings'
  | 'stock'
  | 'summary'
  | 'tag'
  | 'table-chart'
  | 'tick-in-circle'
  | 'tick-speech'
  | 'tick'
  | 'unique-buyers'
  | 'update'
  | 'upload'
  | 'validation-cross'
  | 'validation-tick'
  | 'van'
  | 'vaps'
  | 'warning'
  | 'webshop';

type IconProps = {
  name: IconNames | string;
  className?: string;
  testId?: string;
};

const Icon = ({ name, className, testId }: IconProps) => {
  return <div className={cn(`icon icon-${name}`, className)} data-th={testId} />;
};

export default Icon;

