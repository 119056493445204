import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as addVehicleActions from '../../../redux/addVehicle/addVehicleActions';
import AddVehicle from '../components/AddVehicle';
import { getCustomer } from '../../../selectors/customerSelectors';
import ErrorApology from '../../Common/ErrorApology';
import * as componentActions from '../../../redux/component/componentActions';
import { withParams } from 'hocs/router';
import { compose } from 'redux';

const AddVehicleContainer = (props) => {
  const { children, ...remainingProps } = props;

  useEffect(() => {
    props.load('AddVehicleContainer');
    return () => {
      props.unload('AddVehicleContainer');
    };
  }, []);

  if (props.components.AddVehicleContainer?.errorMessage) {
    return <ErrorApology>{props.components.AddVehicleContainer.errorMessage}</ErrorApology>;
  }

  if (!props.components.AddVehicleContainer?.hasLoaded) {
    return null;
  }

  return <AddVehicle {...remainingProps} />;
};

AddVehicleContainer.propTypes = {
  children: PropTypes.node,
  load: PropTypes.func,
  unload: PropTypes.func,
  components: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  return {
    customer: getCustomer(state, ownProps.params.consumerId),
    dealership: state.dealership,
    dealerships: state.session.Dealerships,
    submittingState: state.ui.addVehicle.submittingState,
    submitError: state.ui.addVehicle.submitError,
    components: state.components
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    load: (componentName) => {
      dispatch(componentActions.componentLoad(componentName, ownProps.params));
    },
    unload: (componentName) => {
      dispatch(componentActions.componentUnload(componentName, ownProps.params));
    },
    handleAddVehicle: (formData, consumerId, customerType, dealershipId) => {
      dispatch(addVehicleActions.addVehicle(consumerId, formData, customerType, dealershipId));
    },
    clearErrors: () => {
      dispatch(addVehicleActions.clearError());
    }
  };
}

export default compose(withParams, connect(mapStateToProps, mapDispatchToProps))(AddVehicleContainer);
