import platformApiUtils from '@ivendi/platform-api-utils';
import * as actionTypes from '../actionTypes';
import { schemaMap } from '../../core/schema';
import dashboardFinanceApplicationsSchema from '../../core/schemas/dashboardFinanceApplicationsSchema';

export function fetchFinanceApplications({ matchRate, activeFilter, page }) {
  return (dispatch, getState) => {
    let state = getState();
    const dealershipId = state.dealership.Id;
    dispatch({
      types: [
        actionTypes.FETCH_FINANCE_APPLICATIONS,
        actionTypes.FETCH_FINANCE_APPLICATIONS_SUCCESS,
        actionTypes.FETCH_FINANCE_APPLICATIONS_ERROR
      ],
      callAPI: () =>
        platformApiUtils.get(`v1/dashboard/applications/${dealershipId}`, {
          PageSize: 25,
          Page: page,
          AssignedTo: activeFilter === 'mine' ? [state.session.UserId] : undefined,
          IsAssigned: activeFilter === 'unassigned' ? false : undefined,
          MatchRate: matchRate
        }),
      parseResponse: (data) => schemaMap(data, dashboardFinanceApplicationsSchema)
    });
  };
}
