import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  draft.applicationFields.AffordabilityDetails!.NetMonthly = false;

  delete draft.validationRules.AffordabilityDetails!.Income!.NetMonthly;
  draft.validationRules.EmploymentHistory!.Items![0]!.OccupationType =
    'validateFieldBasedOnOtherFieldValue:ReceivingDisabilityBenefit:OccupationBasis:Unemployed';
});
