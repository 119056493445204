import { formatToNumDecimalPlaces } from './utils';
import { inject } from 'mobx-react';

// FormatAPR and getFormattedAPR (used for APR) - value to 2 decimal places for DE, to 1 decimal place for UK
// FormatNumToDecimals (used for Annual Interest Rate) - value to 2 decimal places for both DE & UK

type AprDecimalPointsPerCountryType = {
  [key: string]: number;
};
export const aprDecimalPointsPerCountry: AprDecimalPointsPerCountryType = {
  gb: 1,
  de: 2
};
export const getAprDecimalPointsPerCountry = (countryCode: string) => {
  if (countryCode) {
    return aprDecimalPointsPerCountry[countryCode.toLowerCase()];
  }
  return aprDecimalPointsPerCountry['gb'];
};

type FormatAPRProps = {
  value: string | null | undefined;
  appStore?: any;
};

export const getFormattedAPR = (value: string | number, appStore: any): string | null => {
  if (value === null || value === undefined || !appStore) return null;
  const formattedValue = typeof value === 'number' ? value.toString() : value;

  const decimalPlaces = getAprDecimalPointsPerCountry(appStore.uiState.countryCode || 'gb');
  return formatToNumDecimalPlaces(formattedValue, decimalPlaces, '%');
};

export const FormatAPR = ({ value, appStore }: FormatAPRProps) => {
  const decimalPlaces = getAprDecimalPointsPerCountry(appStore.uiState.countryCode!);
  const formattedValue = formatToNumDecimalPlaces(value, decimalPlaces, '%');
  return <>{formattedValue}</>;
};

export type FormatNumToDecimalsProps = {
  value: string | null | undefined;
  decimal: number;
  appendChar: string;
};

export const FormatNumToDecimals = ({ value, decimal = 1, appendChar = '' }: FormatNumToDecimalsProps) => {
  const formattedValue = formatToNumDecimalPlaces(value, decimal, appendChar);
  return <>{formattedValue}</>;
};

export default inject('appStore')(FormatAPR);
