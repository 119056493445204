/* eslint-disable jsx-a11y/alt-text */
import PropTypes from 'prop-types';
import cn from 'classnames';
import './funderLogo.scss';

// Some lenders require formats different from .jpg - they can be overridden here
const alternativeLogoFormats: Record<string, string> = {
  adm: 'adm.png'
};

type FunderLogoProps = {
  funder?: string | null;
  maxWidth?: string | number;
  bare?: boolean;
  className?: string;
};

const FunderLogo = ({ funder, maxWidth, bare = false, className = '' }: FunderLogoProps) => {
  const funderLowerCase = funder ? funder.toLowerCase() : null;
  const funderLogo = funderLowerCase ? alternativeLogoFormats[funderLowerCase] ?? `${funderLowerCase}.jpg` : null;
  const logoVersion = process.env.REACT_APP_FUNDER_LOGO_VERSION || '1.0.0';

  const logo = funderLogo ? (
    <img
      className={cn('funderLogo', className)}
      style={{ maxWidth }}
      src={`https://d2bkdfyoj2xgsx.cloudfront.net/funderlogo/${funderLogo}?maxheight=85&v=${logoVersion}`}
    />
  ) : null;

  return bare ? logo : <div data-th="funder-logo">{logo}</div>;
};

FunderLogo.propTypes = {
  bare: PropTypes.bool, // Temporary prop to remove wrapping div
  funder: PropTypes.string,
  className: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default FunderLogo;
