import { inject } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from '../../hocs/router';
import Breadcrumbs from '../Common/Breadcrumbs';
import LoadingDots from '../Common/Loading/LoadingDots';
import Page from '../Common/Page';
import Panel from '../Common/Panel';
import PanelContent from '../Common/PanelContent';
import PanelHeader from '../Common/PanelHeader';

function createClient(urlGenerator, handler) {
  const handleReponse = (response) => {
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json();
      } else {
        return response.text();
      }
    } else {
      throw new Error(`${response.status}`);
    }
  };
  const createRequest = (method) => (path, obj) =>
    fetch(
      urlGenerator(path),
      obj instanceof FormData
        ? {
            method,
            body: obj
          }
        : {
            method,
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(obj)
          }
    ).then(handler ? handler : handleReponse);
  return {
    get: (path) => fetch(urlGenerator(path)).then(handler ? handler : handleReponse),
    put: createRequest('PUT'),
    post: createRequest('POST'),
    patch: createRequest('PATCH'),
    delete: createRequest('DELETE')
  };
}

export const canLoad = () => !!window.Webshops;

const pathReplacementMap = {
  '/dd-api': '/uk/api/webshops/dealership-directory',
  '/dd-widget': '/uk/apps/webshops/dealership-directory',
  '/hub-api': '/uk/api/webshops/enquiry-hub',
  '/hub-widget': '/uk/apps/webshops/enquiry-hub'
};

const Webshop = withRouter(({ isLoading, params: { dealershipId, section }, appStore, navigate }) => {
  const { hasWebshopEnabled, canUseWebshop } = appStore.uiState;
  const {
    i18n: { language }
  } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const showModule = () => hasWebshopEnabled && canUseWebshop && canLoad();

  React.useEffect(() => {
    if (!showModule()) {
      return;
    }
    const getUrl = (path) => {
      let replacedPath = undefined;
      Object.keys(pathReplacementMap).forEach((pathPrefix) => {
        if (path.startsWith(pathPrefix)) {
          replacedPath = path.replace(pathPrefix, pathReplacementMap[pathPrefix]);
        }
      });
      return replacedPath ? replacedPath : '/uk/api/webshops/platform-plugin' + path;
    };
    const httpClient = createClient(getUrl);
    window.Webshops.appendDetails({
      elementId: 'webshop-widget',
      httpClient: httpClient,
      handleLoading: setLoading,
      dealershipId,
      section,
      urlGenerator: getUrl,
      locale: language,
      getErrorActions: (error) => {
        if (['401', '204'].some((code) => error.message.includes(code))) {
          return [
            {
              label: error.message.includes('401') ? 'Sign in' : 'Go to Dashboard',
              handler: () => {
                navigate(`/d/${dealershipId}`);
              }
            }
          ];
        }
        return [];
      }
    });
  }, [language]);

  if (!showModule()) {
    window.location.pathname = `/dealer/platform/d/${dealershipId}`;
    return <></>;
  }
  return (
    <Page isLoading={isLoading}>
      {dealershipId && (
        <Breadcrumbs
          items={[
            {
              name: 'Home',
              path: `/d/${dealershipId}`
            },
            {
              name: 'Webshop'
            }
          ]}
        />
      )}
      <div>
        <Panel>
          <PanelHeader>Webshop</PanelHeader>
          <PanelContent noPadding={true}>
            {loading && <LoadingDots />}
            <div id="webshop-widget" />
          </PanelContent>
        </Panel>
      </div>
    </Page>
  );
});

export default inject(['appStore'])(Webshop);
