import { hasCompanionQuotes } from '~/features';
import { QuoteTypes, QuoteCardVariantType } from './types';

type UseGetQuoteChecksProps = {
  quote: QuoteTypes;
  variant: QuoteCardVariantType;
};

export const useGetQuoteChecks = ({ quote, variant }: UseGetQuoteChecksProps) => {
  const isRequote = quote?.isRequote === true;
  const isCombinedQuote = quote?.PersonalLoan || quote?.NegativeEquityLoan;
  const isFinanceQuote = variant === 'FinanceQuote';
  const isFinanceApplication = variant === 'FinanceApplication';
  const isSavedQuote = variant === 'SavedQuote';
  const isSendDeal = variant === 'SendDeal';
  const isCarFinanceCheck = variant === 'CarFinanceCheck';
  const isRiskBasedPricingQuote = quote.CreditTier && quote.CreditTier !== 'None' && !isRequote;
  const supportsPreApproval = quote?.SupportsPreApproval;
  return {
    isRequote,
    isCombinedQuote,
    isFinanceQuote,
    isFinanceApplication,
    isSavedQuote,
    isSendDeal,
    isCarFinanceCheck,
    isRiskBasedPricingQuote,
    hasCompanionQuotes: hasCompanionQuotes(quote),
    supportsPreApproval
  };
};
