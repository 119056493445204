import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import NoPanelData from '../../../Dashboard/components/NoPanelData';
import Tabs from '../../../Common/Tabs/Tabs';
import TabList from '../../../Common/Tabs/TabList';
import TabContent from '../../../Common/Tabs/TabContent';
import SelfServicedDealsTabsItem from './SelfServicedDealsTabsItem';
import DealItem from './DealItem';

class Deals extends React.Component {
  selectSentDeal = (index) => {
    this.props.selectSentDeal(index);
  };

  componentDidMount() {
    this.props.selectedSentDealIndex > this.props.deals.length && this.selectSentDeal(0);
  }

  render() {
    return (
      <div className="savedSelfServiceDeals">
        {this.props.deals.length > 0 ? (
          <Tabs currentTab={this.props.selectedSentDealIndex} setActiveTab={this.selectSentDeal}>
            <TabList tabStyle="largeCfc">
              {this.props.deals.map((deal, index) => (
                <SelfServicedDealsTabsItem deal={deal} key={'tab' + index} />
              ))}
            </TabList>
            <TabContent border={false}>
              {this.props.deals.map((deal, index) => (
                <DealItem
                  deal={deal}
                  key={'deal' + index}
                  consumer={this.props.consumer}
                  vehicleId={this.props.vehicleId}
                  dealershipId={this.props.dealershipId}
                  vehicleClass={this.props.vehicleClass}
                />
              ))}
            </TabContent>
          </Tabs>
        ) : (
          <NoPanelData text={this.props.t('Deals.there_are_no_current_self_serviced_deals_for_this_customer')} />
        )}
      </div>
    );
  }
}

Deals.propTypes = {
  deals: PropTypes.array,
  consumer: PropTypes.object,
  dealershipId: PropTypes.string,
  vehicleId: PropTypes.string,
  selectSentDeal: PropTypes.func,
  selectedSentDealIndex: PropTypes.number,
  vehicleClass: PropTypes.string
};
export default withTranslation('Customer')(Deals);
