const EligibilityIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_431_997)">
        <path
          d="M3.77293 17.3233C2.84361 17.3233 2.09131 16.516 2.09131 15.5188V6.49643C2.09131 5.49922 2.84361 4.69196 3.77293 4.69196H17.2112C18.1405 4.69196 18.8928 5.49922 18.8928 6.49643V11.989C21.1645 12.4955 22.6248 14.8698 22.1675 17.3074C21.6955 19.7451 19.4828 21.3121 17.2112 20.8214C16.7686 20.7264 16.3556 20.5523 15.9573 20.3149C13.9512 21.5654 11.3845 20.8214 10.2192 18.6687C9.99789 18.2413 9.83563 17.7981 9.74713 17.3074H3.77293V17.3233ZM10.4994 13.7143C9.11283 13.7143 7.97699 12.4955 7.97699 11.0076C7.97699 9.51971 9.11283 8.3009 10.4994 8.3009C11.886 8.3009 13.0219 9.51971 13.0219 11.0076C13.0219 11.3558 12.9629 11.7199 12.8301 12.0365C13.8922 11.7516 15.0133 11.9257 15.9573 12.5113C16.3556 12.2739 16.7686 12.0998 17.2112 12.0048V6.51226H3.77293V15.5346H9.73237C9.86513 14.8698 10.1159 14.2525 10.4994 13.7143ZM18.052 19.1277C19.4386 19.1277 20.5744 17.9089 20.5744 16.421C20.5744 14.9331 19.4386 13.7143 18.052 13.7143C17.8012 13.7143 17.5505 13.7618 17.2997 13.8409C18.3028 15.3922 18.3028 17.4499 17.2997 19.0011C17.5357 19.0802 17.8012 19.1277 18.052 19.1277ZM4.61375 13.2711C4.61375 13.0179 4.79076 12.8121 5.02678 12.8121H6.7084C6.94442 12.8121 7.12143 13.0179 7.12143 13.2711V14.1575C7.12143 14.4108 6.94442 14.6166 6.7084 14.6166H5.02678C4.79076 14.6166 4.61375 14.4108 4.61375 14.1575V13.2711ZM13.8479 7.8577C13.8479 7.60444 14.0249 7.39866 14.261 7.39866H15.9426C16.1786 7.39866 16.3556 7.60444 16.3556 7.8577V8.7441C16.3556 8.99736 16.1786 9.20314 15.9426 9.20314H14.261C14.0249 9.20314 13.8479 8.99736 13.8479 8.7441V7.8577ZM13.8479 19.1277C15.2345 19.1277 16.3704 17.9248 16.3704 16.421C16.3704 14.9173 15.2493 13.7143 13.8479 13.7143C12.4466 13.7143 11.3255 14.9173 11.3255 16.421C11.3255 17.9248 12.4613 19.1277 13.8479 19.1277Z"
          fill="#30364E"
        />
      </g>
      <defs>
        <clipPath id="clip0_431_997">
          <rect width="21" height="17" fill="white" transform="translate(2 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EligibilityIcon;
