import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Icon from '../Common/Icon/Icon';
import FunderLogo from '../Common/FunderLogo';
import DateFormat from '../Common/DateFormat';
import LenderStatusLarge from './LenderStatusLarge';
import MoneyFormat from '../Common/MoneyFormat';
import Button from '../Common/Button/Button';
import { observer, inject } from 'mobx-react';
import LoadingSpinner from '../Common/Loading/LoadingSpinner';
import tick from '../../styles/icons/saved-tick-thinner.svg';
import cross from '../../styles/icons/cross-thinner.svg';
import FormatAPR from '~/components/Quoting/components/FormatAPR';
import './cancelApplicationModal.scss';

const CancelApplicationModal = ({ t, ...props }) => {
  const { consumer, quote } = props;

  if (props.appStore.applicationStatusStore.isCancelLoading === true) {
    return (
      <div className="cancelApplicationModal__loading">
        <div className="cancelApplicationModal__loadingSpinner">
          <LoadingSpinner size="xlarge" />
        </div>
        <div className="cancelApplicationModal__header">{t('CancelApplicationModal.cancelling_application')}</div>
        <div>{t('CancelApplicationModal.this_wont_take_long')}</div>
      </div>
    );
  }

  if (
    props.appStore.applicationStatusStore.isCancelLoading === false &&
    props.appStore.applicationStatusStore.isCancelSuccessful === true
  ) {
    return (
      <div className="cancelApplicationModal__success">
        <div className="cancelApplicationModal__successIcon">
          <img src={tick} />
        </div>
        <div className="cancelApplicationModal__successHeader">{t('CancelApplicationModal.application_cancelled')}</div>
        <div className="cancelApplicationModal__successText">
          {t('CancelApplicationModal.take_seconds_for_lender_to_update_application_status')}
        </div>
        <div className="cancelApplicationModal__successButton">
          <Button onClick={props.onClose}>{t('CancelApplicationModal.view_lender_updates')}</Button>
        </div>
      </div>
    );
  }

  if (props.appStore.applicationStatusStore.isCancelSuccessful === false) {
    return (
      <div className="cancelApplicationModal__success">
        <div className="cancelApplicationModal__successIcon">
          <img src={cross} />
        </div>
        <div className="cancelApplicationModal__successHeader">{t('CancelApplicationModal.cancellation_failed')}</div>
        <div className="cancelApplicationModal__successText">
          {t('CancelApplicationModal.sorry_application_hasnt_been_cancelled')}
        </div>
        <div className="cancelApplicationModal__successButton">
          <Button type="button" buttonStyle="cancel" onClick={props.onClose}>
            {t('CancelApplicationModal.go_back')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="cancelApplicationModal">
      <div className="cancelApplicationModal__header">
        {t('CancelApplicationModal.are_you_sure_you_want_to_cancel')}
      </div>
      <div className="cancelApplicationModal__subheader">
        {t('CancelApplicationModal.cannot_be_undone_once_cancelled')}
      </div>
      <div className="cancelApplicationModal__consumer">
        <div className="cancelApplicationModal__consumerImage">
          <Icon name="consumer" />
        </div>
        <div className="cancelApplicationModal__consumerData">
          <div className="cancelApplicationModal__name">
            {`${consumer.Title} ${consumer.Firstname} ${consumer.Surname}`}
          </div>
          <div className="cancelApplicationModal__tel">{consumer.Mobile || consumer.Landline}</div>
        </div>
      </div>
      <div className="cancelApplicationModal__quote">
        <div className="cancelApplicationModal__quoteTop">
          <div className="cancelApplicationModal__quoteData">
            <div className="cancelApplicationModal__funder">
              <FunderLogo funder={quote.FunderCode} />
            </div>
            <div className="cancelApplicationModal__product">
              <div>
                <b>{quote.ProductName}</b>
              </div>
              <div>
                <DateFormat value={props.modifiedDate} format="DD MMM HH:mm" />
              </div>
            </div>
          </div>
          <div className="cancelApplicationModal__status">
            <LenderStatusLarge status={props.applicationStatus} />
          </div>
        </div>
        <div className="cancelApplicationModal__quoteBottom">
          <div className="cancelApplicationModal__quoteFigure">
            <div className="cancelApplicationModal__figureName">
              {t('CancelApplicationModal.total_amount_of_credit')}
            </div>
            <div className="cancelApplicationModal__figureValue">
              <MoneyFormat value={quote.Advance} />
            </div>
          </div>
          <div className="cancelApplicationModal__quoteFigure">
            <div className="cancelApplicationModal__figureName">{t('CancelApplicationModal.apr')}</div>
            <div className="cancelApplicationModal__figureValue">
              <FormatAPR value={quote.RepresentativeApr} />
            </div>
          </div>
          <div className="cancelApplicationModal__quoteFigure">
            <div className="cancelApplicationModal__figureName">{t('CancelApplicationModal.agreement_term')}</div>
            <div className="cancelApplicationModal__figureValue">
              {t('CancelApplicationModal.months', { term: quote.Term })}
            </div>
          </div>
          <div className="cancelApplicationModal__quoteFigure">
            <div className="cancelApplicationModal__figureName">{t('CancelApplicationModal.monthly_payment')}</div>
            <div className="cancelApplicationModal__figureValue">
              <MoneyFormat value={quote.FollowingPayments} />
            </div>
          </div>
        </div>
      </div>
      <div className="cancelApplicationModal__buttons">
        <div className="cancelApplicationModal__button">
          <Button type="button" buttonStyle="cancel" onClick={props.onClose}>
            {t('CancelApplicationModal.go_back')}
          </Button>
        </div>
        <div className="cancelApplicationModal__button">
          <Button type="button" buttonStyle="primary" onClick={props.onCancelApplication}>
            {t('CancelApplicationModal.cancel_application')}
          </Button>
        </div>
      </div>
    </div>
  );
};

CancelApplicationModal.propTypes = {
  appStore: PropTypes.object,
  quote: PropTypes.object,
  consumer: PropTypes.object,
  modifiedDate: PropTypes.string,
  applicationStatus: PropTypes.string,
  onClose: PropTypes.func,
  onCancelApplication: PropTypes.func
};
export default withTranslation('ApplicationStatus')(inject(['appStore'])(observer(CancelApplicationModal)));
