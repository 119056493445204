import { takeEveryByComponentName } from '../helpers';
import { call, put, select } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import { fetchCustomer } from '../../api/customer';
import { componentMount, componentError } from '../component/componentActions';
import { logError } from '../../core/helpers';
import { getCustomer } from '../../selectors/customerSelectors';

const componentName = 'CustomerQuoteContainer';

export function* watchCustomerQuoteContainer() {
  yield* takeEveryByComponentName(actionTypes.COMPONENT_LOAD, componentName, handleLoadCustomerQuoteContainer);
}

export function* handleLoadCustomerQuoteContainer(action) {
  const customerInMemory = yield select(getCustomer, action.params.consumerId);

  if (!customerInMemory) {
    let response;
    yield put({ type: actionTypes.FETCH_CONSUMER });

    try {
      response = yield call(fetchCustomer, action.params.consumerId);
    } catch (e) {
      logError(e);
      yield put(componentError(componentName, 'Customer not found.', e));
      return;
    }

    yield put({ type: actionTypes.FETCH_CONSUMER_SUCCESS, response });
  }

  yield put(componentMount(componentName));
}
