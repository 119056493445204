import * as actionTypes from '../actionTypes';
import _ from 'lodash';

const defaultState = {
  total: 0,
  isLoading: false,
  items: []
};

export default function financeApplicationsReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.FETCH_FINANCE_APPLICATIONS:
      return Object.assign({}, state, { isLoading: true });

    case actionTypes.FETCH_FINANCE_APPLICATIONS_SUCCESS:
      return Object.assign({}, state, {
        items: action.response ? action.response.Data : [],
        isLoading: false,
        total: action.response.Total
      });

    case actionTypes.FETCH_FINANCE_APPLICATIONS_ERROR:
      return Object.assign({}, state, { error: action.payload, isLoading: false });

    case actionTypes.CHANGE_DEALERSHIP:
      return _.cloneDeep(defaultState);

    default:
      return state;
  }
}
