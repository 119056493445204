import { observer, inject } from 'mobx-react';
import PropTypes, { InferProps } from 'prop-types';
import SplitButton from '../../Common/SplitButton';
import SplitButtonOption from '../../Common/SplitButtonOption';
import SplitButtonOptionDefault from '../../Common/SplitButtonOptionDefault';
import QuoteButtonWrapper from './QuoteButtonWrapper';

const QuoteToolBarSplitButton = ({
  onChangeDetails,
  enableSendDeal,
  onSendOffer,
  enableCheckEligibility,
  onCheckEligibility,
  numSelectedQuotesForCompare = 0,
  onPricePosition,
  onHideMoreOptions,
  appStore
}: QuoteToolBarSplitButtonPropTypes) => {
  return (
    <QuoteButtonWrapper>
      <SplitButton>
        <SplitButtonOptionDefault onClick={onChangeDetails}>Edit quotes</SplitButtonOptionDefault>

        {enableSendDeal && <SplitButtonOption onClick={onSendOffer}>Send deal</SplitButtonOption>}

        {enableCheckEligibility && (
          <SplitButtonOption onClick={onCheckEligibility}>Check eligibility</SplitButtonOption>
        )}

        {numSelectedQuotesForCompare! > 0 && !appStore.uiState.isBdk && (
          <SplitButtonOption onClick={onPricePosition}>Price position</SplitButtonOption>
        )}

        <SplitButtonOption onClick={onHideMoreOptions}>More/less options</SplitButtonOption>
      </SplitButton>
    </QuoteButtonWrapper>
)};

const QuoteToolBarSplitButtonProps = {
  onChangeDetails: PropTypes.func.isRequired,
  enableSendDeal: PropTypes.bool,
  onSendOffer: PropTypes.func,
  enableCheckEligibility: PropTypes.bool,
  onCheckEligibility: PropTypes.func,
  numSelectedQuotesForCompare: PropTypes.number,
  onPricePosition: PropTypes.func,
  onHideMoreOptions: PropTypes.func.isRequired,
  appStore: PropTypes.any
}

type QuoteToolBarSplitButtonPropTypes = InferProps<typeof QuoteToolBarSplitButtonProps>
QuoteToolBarSplitButton.propTypes = QuoteToolBarSplitButtonProps

export default inject('appStore')(observer(QuoteToolBarSplitButton));
