import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddCustomer from '../components/AddCustomer';
import { getCustomer } from '../../../selectors/customerSelectors';
import { getDefaultCustomerType } from '../../../selectors/sessionSelectors';
import { withParams } from 'hocs/router';
import { compose } from 'redux';

const AddCustomerContainer = (props) => {
  const { children, ...remainingProps } = props;

  return <AddCustomer {...remainingProps} />;
};

AddCustomerContainer.propTypes = {
  children: PropTypes.node
};

function mapStateToProps(state, ownProps) {
  return {
    customer: getCustomer(state, ownProps.params.consumerId),
    defaultCustomerType: getDefaultCustomerType(state)
  };
}

export default compose(withParams, connect(mapStateToProps))(AddCustomerContainer);
