import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './applicationProgressBar.scss';
import classnames from 'classnames';

const ApplicationProgressBar = ({ progress }) => {
  const { t } = useTranslation('Application');
  let figure = 100 - progress;
  let progressStyles = {
    transform: 'translateX(-' + figure + '%)'
  };
  const barStyles = classnames(
    'applicationProgressBar__progressBar',
    progress < 30 && 'applicationProgressBar__progressBar--red',
    progress > 30 && progress < 99 && 'applicationProgressBar__progressBar--orange',
    progress === 100 && 'applicationProgressBar__progressBar--green'
  );
  return (
    <div className="applicationProgressBar">
      <div className="applicationProgressBar__number" key="number">
        {progress}%{' '}
        <span className="applicationProgressBar__numberComplete">{t('ApplicationProgressBar.complete')}</span>
      </div>
      <div className="applicationProgressBar__barOuter" key="barCont">
        <div className="applicationProgressBar__back" key="back" />
        <div className={barStyles} style={progressStyles} />
      </div>
    </div>
  );
};

ApplicationProgressBar.propTypes = {
  progress: PropTypes.number
};
export default ApplicationProgressBar;
