import * as actionTypes from '../actionTypes';

export function open(name, params = {}) {
  if (typeof params !== 'object') {
    throw new Error('action.params should be an object');
  }

  return {
    type: actionTypes.OPEN_MODAL,
    name,
    params
  };
}

export function close(name) {
  return {
    type: actionTypes.CLOSE_MODAL,
    name
  };
}

//the dispatch thunks in use are currently referenced from quoteActions but think maybe better here
export const initProductInfoModal = (quoteFinanceType, productInfoType, isPersonalLoan, funderCode) => {
  return (dispatch) => {
    dispatch(open(productInfoType));
    dispatch({
      type: actionTypes.SETUP_PRODUCT_INFO_MODAL,
      quoteFinanceType,
      isPersonalLoan,
      funderCode
    });
  };
};

export function cleanupProductInfoModal() {
  return {
    type: actionTypes.CLEANUP_PRODUCT_INFO_MODAL
  };
}
