import { withTranslation } from 'react-i18next';
import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Button from '../Common/Button/Button';
import FormFooter from '../Common/Form/FormFooter';
import CheckboxWithLabel from '../Common/Form/CheckboxWithLabel';
import pushDealLoadingImage from './images/sendDealCarLoader.svg';
import pushDealMailConfirmationImage from './images/mailConfirmation.svg';
import PushDealQuotePreview from './PushDealQuotePreview';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../core/helpers';
import TrackEvent from '../Common/Tracking/TrackEvent';
import * as snowplow from '@ivendi/snowplow-js';
import MoneyFormat from '../Common/MoneyFormat';
import './pushDealConfirm.scss';

class PushDealConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasCheckedConfirm: false
    };
  }

  onSubmit = () => {
    this.props.appStore.pushDealStore.sendDeal();
    window.ga && window.ga('send', 'event', 'PushDeal_confirm', 'ButtonPress', `PushDeal_SendDeal`);
    snowplow.trackSelfDescribingEvent(
      'pushDeal',
      'pushDealConfirm__submitDeal',
      JSON.stringify({
        customerId: this.props.appStore.pushDealStore.customer.Id,
        dealershipId: this.props.appStore.pushDealStore.dealershipId
      })
    );
  };
  onCancel = () => {
    this.props.changeTitle('selectQuotes');
  };

  componentWillUnmount() {
    this.props.appStore.pushDealStore.changeConfirmDealState(false);
  }

  componentDidMount() {
    this.props.appStore.pushDealStore.resetConfirm();
  }

  toggleConfirm = () => {
    this.setState((prevState) => ({
      hasCheckedConfirm: !prevState.hasCheckedConfirm
    }));
    window.ga && window.ga('send', 'event', 'PushDeal_Confirm', 'Toggle', `PushDeal_customerConfirm`);
  };
  renderLoadingInfo = () => {
    return (
      <div className="pushDealConfirm__imageContainer">
        <img className="pushDealConfirm__sendDealLoadingImage" src={pushDealLoadingImage} ref="image" />
        <p className="pushDealConfirm__imageUpperText">
          {this.props.t('PushDealConfirm.your_offer_is_being_sent_to_the_customer')}
        </p>
        <p className="pushDealConfirm__imageLowerText">{this.props.t('PushDealConfirm.this_shouldnt_take_long')}</p>
      </div>
    );
  };
  renderPushDealConfirmation = () => {
    return (
      <div className="pushDealConfirm__imageContainer">
        <img className="pushDealConfirm__sendDealConfirmationImage" src={pushDealMailConfirmationImage} ref="image" />
        <p className="pushDealConfirm__imageUpperText">
          {this.props.t('PushDealConfirm.your_offer_has_been_sent_successfully')}
        </p>
        <p className="pushDealConfirm__imageLowerText">
          {this.props.t(
            'PushDealConfirm.keep_track_of_the_customers_progress_in_the_offers_area_on_the_customer_record'
          )}
        </p>
        <div className="pushDealConfirm__buttonContainer">
          <TrackEvent featureName="pushDeal" interactionName="pushDealConfirm__sendAnotherDeal">
            <Button onClick={() => this.props.changeTitle('pricing')}>
              {this.props.t('PushDealConfirm.send_another')}
            </Button>
          </TrackEvent>
        </div>
        <div className="pushDealConfirm__buttonContainer">
          <TrackEvent
            featureName="pushDeal"
            interactionName="pushDealConfirm__toCustomerRecord"
            linkTo={`/d/${this.props.appStore.pushDealStore.dealershipId}/consumers/${this.props.appStore.pushDealStore.customer.Id}`}
          >
            <Link
              to={`/d/${this.props.appStore.pushDealStore.dealershipId}/consumers/${this.props.appStore.pushDealStore.customer.Id}`}
              className="link"
            >
              <Button>{this.props.t('PushDealConfirm.go_to_customer_record')}</Button>
            </Link>
          </TrackEvent>
        </div>
      </div>
    );
  };

  render() {
    const {
      quoteRequest,
      customer,
      selectedQuotes,
      sendDealError,
      confirmDealLoadingState,
      confirmDealSent,
      discountedPrice,
      OfferStatus
    } = this.props.appStore.pushDealStore;
    const {
      VehiclePrice,
      Term,
      AnnualDistance,
      CashDeposit,
      PartExchange,
      OutstandingSettlement,
      ValueAddedProducts,
      LenderVaps,
      DiscountAmount
    } = quoteRequest;
    const requiredInfo = selectedQuotes.length !== 0 && customer.Id !== '';
    const allVaps = [...ValueAddedProducts, ...LenderVaps];

    return (
      <div className="pushDealConfirm__container">
        <div>
          {sendDealError && (
            <div class="pushDealConfirm__error">
              {this.props.t('PushDealConfirm.there_was_an_error_sending_your_deal')}
            </div>
          )}
          {confirmDealSent && !confirmDealLoadingState && !sendDealError && this.renderPushDealConfirmation()}
          {confirmDealLoadingState && !sendDealError && this.renderLoadingInfo()}
          {!confirmDealSent && !confirmDealLoadingState && !sendDealError && (
            <>
              <div className="pushDealConfirm__vehicleContainer">
                <div className="pushDealConfirm__customerInfoContainer">
                  <div className="pushDealConfirm__section">
                    <div className="pushDealConfirm__customerInfo">
                      <div className="pushDealConfirm__sendingTo">{this.props.t('PushDealConfirm.sending_to')}</div>
                      <div className="pushDealConfirm__customerName">{`${customer.Firstname} ${customer.Surname}`}</div>
                      <div className="pushDealConfirm__customerEmail">{customer.Id !== '' ? customer.Email : ''}</div>
                    </div>
                    <div className="pushDealConfirm__editCustomerButton">
                      <TrackEvent featureName="pushDeal" interactionName="pushDealConfirm__changeCustomer">
                        <Button
                          buttonStyle="secondary"
                          size="small"
                          onClick={() => {
                            this.props.changeTitle('selectCustomer');
                          }}
                        >
                          {this.props.t('PushDealConfirm.change_customer')}
                        </Button>
                      </TrackEvent>
                    </div>
                  </div>
                </div>
                <div className="pushDealConfirm__inner">
                  <div className="pushDealConfirm__section">
                    <div className="pushDealConfirm__sectionTitle">
                      {this.props.t('PushDealConfirm.vehicle_pricing')}
                    </div>
                    <div className="pushDealConfirm__editButton">
                      <TrackEvent featureName="pushDeal" interactionName="pushDealConfirm__editPrice">
                        <Button
                          buttonStyle="secondary"
                          size="small"
                          onClick={() => {
                            this.props.changeTitle('pricing');
                          }}
                        >
                          {this.props.t('PushDealConfirm.edit')}
                        </Button>
                      </TrackEvent>
                    </div>
                  </div>
                  <div className="pushDealConfirm__section">
                    <div className="pushDealConfirm__subtitle">{this.props.t('PushDealConfirm.vehicle_price')}</div>
                    <div className={OfferStatus && DiscountAmount ? 'pushDealConfirm__strikeout' : ''}>
                      {<MoneyFormat value={VehiclePrice} />}
                    </div>
                  </div>
                  {OfferStatus && DiscountAmount && (
                    <div className="pushDealConfirm__section">
                      <div className="pushDealConfirm__subtitle">{this.props.t('PushDealConfirm.offer_price')}</div>
                      <div>{<MoneyFormat value={discountedPrice} />}</div>
                    </div>
                  )}
                  {OfferStatus && DiscountAmount && (
                    <div className="pushDealConfirm__section">
                      <div className="pushDealConfirm__subtitle">{this.props.t('PushDealConfirm.discount_amount')}</div>
                      <div className="pushDealConfirm__highlightedValue">{<MoneyFormat value={DiscountAmount} />}</div>
                    </div>
                  )}
                </div>
                {allVaps.length > 0 && (
                  <div className="pushDealConfirm__inner">
                    <div className="pushDealConfirm__section">
                      <div className="pushDealConfirm__sectionTitle">
                        {this.props.t('PushDealConfirm.value_added_products')}
                      </div>
                      <div className="pushDealConfirm__editButton">
                        <TrackEvent featureName="pushDeal" interactionName="pushDealConfirm__editVaps">
                          <Button
                            buttonStyle="secondary"
                            size="small"
                            onClick={() => {
                              this.props.changeTitle('pricing');
                            }}
                          >
                            {this.props.t('PushDealConfirm.edit')}
                          </Button>
                        </TrackEvent>
                      </div>
                    </div>

                    {allVaps.length > 0 &&
                      allVaps.map((vap, index) => (
                        <div className="pushDealConfirm__section" key={`valueAddedProduct-${index}`}>
                          <div className="pushDealConfirm__vapText">
                            <div>{vap?.ProductTypeText}</div>
                            <div>{vap.Name}</div>
                          </div>
                          <div>
                            {vap.Price ? <MoneyFormat value={vap.Price} /> : this.props.t('PushDealConfirm.see_quote')}
                          </div>
                        </div>
                      ))}
                  </div>
                )}
                <div className="pushDealConfirm__inner">
                  <div className="pushDealConfirm__section">
                    <div className="pushDealConfirm__sectionTitle">{this.props.t('PushDealConfirm.finance_terms')}</div>
                    <div className="pushDealConfirm__editButton">
                      <TrackEvent featureName="pushDeal" interactionName="pushDealConfirm__editTerms">
                        <Button
                          buttonStyle="secondary"
                          size="small"
                          onClick={() => {
                            this.props.changeTitle('pricing');
                          }}
                        >
                          {this.props.t('PushDealConfirm.edit')}
                        </Button>
                      </TrackEvent>
                    </div>
                  </div>
                  <div className="pushDealConfirm__section">
                    <div className="pushDealConfirm__subtitle">{this.props.t('PushDealConfirm.cash_deposit')}</div>
                    <div>{<MoneyFormat value={CashDeposit} />}</div>
                  </div>
                  {PartExchange > 0 && (
                    <div className="pushDealConfirm__section">
                      <div className="pushDealConfirm__subtitle">{this.props.t('PushDealConfirm.part_exchange')}</div>
                      <div>{<MoneyFormat value={PartExchange} />}</div>
                    </div>
                  )}
                  {OutstandingSettlement > 0 && (
                    <div className="pushDealConfirm__section">
                      <div className="pushDealConfirm__subtitle">
                        {this.props.t('PushDealConfirm.settlement_figure')}
                      </div>
                      <div>{<MoneyFormat value={OutstandingSettlement} />}</div>
                    </div>
                  )}
                  <div className="pushDealConfirm__section">
                    <div className="pushDealConfirm__subtitle">{this.props.t('PushDealConfirm.mileage')}</div>
                    <div>
                      {AnnualDistance
                        ? this.props.t('PushDealConfirm.annual_distance_miles_p_a', {
                            annualDistance: formatNumber(AnnualDistance, 0, this.props.appStore.uiState.countryCode)
                          })
                        : this.props.t('PushDealConfirm.no_mileage_data')}
                    </div>
                  </div>
                  <div className="pushDealConfirm__section">
                    <div className="pushDealConfirm__subtitle">{this.props.t('PushDealConfirm.term')}</div>
                    <div>{this.props.t('PushDealConfirm.month_other', { count: Term })}</div>
                  </div>
                </div>
                <div className="pushDealConfirm__quoteSection">
                  <div className="pushDealConfirm__quotePreviewSection">
                    <div className="pushDealConfirm__sectionTitle">
                      {this.props.t('PushDealConfirm.finance_quotes')}
                    </div>
                    <div className="pushDealConfirm__editButton">
                      <TrackEvent featureName="pushDeal" interactionName="pushDealConfirm__changeQuotes">
                        <Button
                          buttonStyle="secondary"
                          size="small"
                          onClick={() => {
                            this.props.changeTitle('selectQuotes');
                          }}
                        >
                          {this.props.t('PushDealConfirm.change_quotes')}
                        </Button>
                      </TrackEvent>
                    </div>
                    {selectedQuotes.map((quote, i) => {
                      const newCarOffer =
                        this.props.appStore.quotingStore.newCarOffers &&
                        Array.isArray(this.props.appStore.quotingStore.newCarOffers) &&
                        this.props.appStore.quotingStore.newCarOffers.find(
                          (offer) => offer.ProductUid === quote.ProductId
                        );
                      return <PushDealQuotePreview key={i} quote={quote} newCarOffer={newCarOffer} />;
                    })}
                  </div>
                </div>
              </div>
              <div className="pushDealConfirm__checkbox">
                <CheckboxWithLabel
                  onChange={this.toggleConfirm}
                  value={this.state.hasCheckedConfirm}
                  id="hasCheckedConfirm"
                >
                  {this.props.t(
                    'PushDealConfirm.i_confirm_that_the_customer_has_given_me_permission_to_send_them_this_deal_via_email'
                  )}
                </CheckboxWithLabel>
              </div>
            </>
          )}

          {!confirmDealSent && !confirmDealLoadingState && (
            <FormFooter
              onSubmit={this.onSubmit}
              submitDisabled={!requiredInfo || sendDealError || !this.state.hasCheckedConfirm}
              submitLabel={this.props.t('PushDealConfirm.send_deal')}
              onCancel={this.onCancel}
              cancelLabel={this.props.t('PushDealConfirm.back')}
              trackingPage="PushDealConfirm"
            />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation('PushDeal')(inject('appStore')(observer(PushDealConfirm)));
