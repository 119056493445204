import platformApiUtils from '@ivendi/platform-api-utils';
import moment from 'moment';
import { Moment } from 'moment';
import { z } from 'zod';

export const VehicleVrmLookupV2Schema = z.object({
  CapCode: z.string().nullable(),
  CapId: z.number().nullable(),
  CashPrice: z.number().nullable(),
  Derivative: z.string().nullable(),
  Derivatives: z
    .string()
    .array()
    .optional(),
  DerivativeIntroduced: z.string().nullable(),
  DerivativeDiscontinued: z.string().nullable(),
  Make: z.string().nullable(),
  MakeId: z.number().nullable(),
  Model: z.string().nullable(),
  ModelDiscontinued: z.number().nullable(),
  ModelId: z.number().nullable(),
  ModelIntroduced: z.number().nullable(),
  NumberOfPreviousKeepers: z.number().nullable(),
  Registered: z.string().nullable(),
  VehicleClass: z.string().nullable()
});

export const VehicleVrmLookupV2ResponseSchema = z.object({
  CapData: VehicleVrmLookupV2Schema.partial().array(),
  GlassData: z.string().nullable(),
  Vin: z.string().nullable(),
  VehiclePrice: z.number().nullable(),
  CurrentOdometerReading: z.number().optional()
});

export type VehicleVrmLookupV2Response = z.infer<typeof VehicleVrmLookupV2ResponseSchema>;
export type VehicleVrmLookupV2 = z.infer<typeof VehicleVrmLookupV2Schema>;

export const fetchVehicleVrmLookupV2 = async (
  vrm: string,
  dealershipId: string
): Promise<VehicleVrmLookupV2Response | Error> => {
  if (typeof vrm !== 'string') {
    throw new Error('VRM must be valid and a string');
  }

  try {
    const registration = vrm.replace(/\s+/g, '');
    const vehicle = await platformApiUtils.get('v2/vrmlookup/' + registration + `?DealershipId=${dealershipId}`, null);

    return VehicleVrmLookupV2ResponseSchema.parse(vehicle);
  } catch (error) {
    throw new Error('No vehicle found in VRM lookup');
  }
};

type FetchCapDataType = {
  class?: string;
  makeId?: string;
  modelId?: string;
  regDate?: Moment | string;
};

export const fetchCapData = async (params: FetchCapDataType = {}) => {
  try {
    let vehicleClass = params.class ? params.class.toLowerCase() : 'car';
    let endpoint = `v1/options/cap/${vehicleClass}/makes`;

    if (params.makeId) {
      endpoint += `/${params.makeId}/models`;
    }

    if (params.makeId && params.modelId) {
      endpoint += `/${params.modelId}/derivatives`;
    }

    let registrationDate = params.regDate ? { Date: moment(params.regDate, 'DD/MM/YYYY').valueOf() } : { Date: null };

    return await platformApiUtils.get(endpoint, registrationDate);
  } catch (error) {
    return [];
  }
};
