import * as debug from '../../../../../../../debug';

const fetchJSON = async (url: string, init?: RequestInit) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      ...init
    });

    if (!response?.ok || response.status === 204) {
      throw Error(response.statusText);
    } else {
      const jsonResponse = await response.json();
      return jsonResponse;
    }
  } catch (err) {
    debug.error(err);
    return Promise.reject();
  }
};

export default fetchJSON;
