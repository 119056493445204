import React from 'react';
import PropTypes from 'prop-types';
import './helpNotification.scss';
import Icon from './Icon/Icon';

const HelpNotification = ({ children }) => {
  return (
    <div className="helpNotification">
      <div className="helpNotification__questionIcon">
        <Icon name="question" />
      </div>
      <div className="helpNotification__text">{children}</div>
    </div>
  );
};

HelpNotification.propTypes = {
  children: PropTypes.node
};

export default HelpNotification;
