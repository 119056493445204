import React from 'react';
import PropTypes from 'prop-types';

const LineClamp = ({ lineClamp, children }) => {
  let styles = {
    display: '-webkit-box',
    WebkitLineClamp: lineClamp,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  };

  return <span style={styles}>{children}</span>;
};

LineClamp.propTypes = {
  lineClamp: PropTypes.number.isRequired,
  children: PropTypes.any
};

LineClamp.defaultProps = {
  lineClamp: 2
};

export default LineClamp;
