// Generated by Avo VERSION 162.4.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function () {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj: any) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function (obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name: any) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value: any) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items: any) {
    var iterator: any = {
      next: function () {
        var value = items.shift();
        return { done: value === undefined, value: value }
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function () {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers: any) {
    // @ts-ignore
    (this as any).map = {};

    if (headers instanceof Headers) {
      (headers as any).forEach(function (value: any, name: any) {
        // @ts-ignore
        this.append(name, value);
        // @ts-ignore
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function (header: any) {
        // @ts-ignore
        this.append(header[0], header[1]);
        // @ts-ignore
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function (name: any) {
        // @ts-ignore
        this.append(name, headers[name]);
        // @ts-ignore
      }, this);
    }
  }

  Headers.prototype.append = function (name: any, value: any) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function (name: any) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function (name: any) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function (name: any) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function (name: any, value: any) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function (callback: any, thisArg: any) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function () {
    var items: any = [];
    this.forEach(function (_value: any, name: any) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function () {
    var items: any = [];
    this.forEach(function (value: any) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function () {
    var items: any = [];
    this.forEach(function (value: any, name: any) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body: any) {
    if (body.bodyUsed) {
      return true;
    }
    body.bodyUsed = true;
    return false;
  }

  function fileReaderReady(reader: any) {
    return new Promise(function (resolve: any, reject: any) {
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function () {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf: any) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]!);
    }
    return chars.join('')
  }

  function bufferClone(buf: any) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    // @ts-ignore
    (this as any).bodyUsed = false;

    // @ts-ignore
    (this as any)._initBody = function (body: any) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      // @ts-ignore
      (this as any).blob = function () {
        var rejected = consumed(this);
        if (rejected) {
          return Promise.reject(new TypeError('Already read'));
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob);
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]));
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob');
        } else {
          return Promise.resolve(new Blob([this._bodyText]));
        }
      };

      // @ts-ignore
      (this as any).arrayBuffer = function () {
        if (this._bodyArrayBuffer) {
          if (consumed(this)) {
            return Promise.reject(new TypeError('Already read'));
          } else {
            return Promise.resolve(this._bodyArrayBuffer);
          }
        } else {
          return this.blob().then(readBlobAsArrayBuffer);
        }
      };
    }

    // @ts-ignore
    (this as any).text = function () {
      var rejected = consumed(this);
      if (rejected) {
        return Promise.reject(new TypeError('Already read'));
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob);
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer));
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text');
      } else {
        return Promise.resolve(this._bodyText);
      }
    };

    if (support.formData) {
      // @ts-ignore
      (this as any).formData = function () {
        return this.text().then(decode)
      };
    }

    // @ts-ignore
    (this as any).json = function () {
      return this.text().then(JSON.parse)
    };

    // @ts-ignore
    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method: any) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input: any, options: any) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if ((input as any).bodyUsed) {
        throw new TypeError('Already read')
      }
      // @ts-ignore
      (this as any).url = (input as any).url;
      // @ts-ignore
      this.credentials = (input as any).credentials;
      if (!options.headers) {
        // @ts-ignore
        this.headers = new Headers((input as any).headers);
      }
      // @ts-ignore
      this.method = (input as any).method;
      // @ts-ignore
      this.mode = (input as any).mode;
      // @ts-ignore
      this.signal = (input as any).signal;
      if (!body && (input as any)._bodyInit != null) {
        body = (input as any)._bodyInit;
        (input as any).bodyUsed = true;
      }
    } else {
      // @ts-ignore
      this.url = String(input);
    }

    // @ts-ignore
    this.credentials = options.credentials || this.credentials || 'same-origin';
    // @ts-ignore
    if (options.headers || !this.headers) {
      // @ts-ignore
      this.headers = new Headers(options.headers);
    }
    // @ts-ignore
    this.method = normalizeMethod(options.method || this.method || 'GET');
    // @ts-ignore
    this.mode = options.mode || this.mode || null;
    // @ts-ignore
    this.signal = options.signal || this.signal;
    // @ts-ignore
    this.referrer = null;

    // @ts-ignore
    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    // @ts-ignore
    this._initBody(body);
  }

  Request.prototype.clone = function () {
    // @ts-ignore
    return new Request(this, { body: this._bodyInit })
  };

  function decode(body: any) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function (bytes: any) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders: any) {
    // @ts-ignore
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function (line: any) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit: any, options: any) {
    if (!options) {
      options = {};
    }

    // @ts-ignore
    this.type = 'default';
    // @ts-ignore
    this.status = options.status === undefined ? 200 : options.status;
    // @ts-ignore
    this.ok = this.status >= 200 && this.status < 300;
    // @ts-ignore
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    // @ts-ignore
    this.headers = new Headers(options.headers);
    // @ts-ignore
    this.url = options.url || '';
    // @ts-ignore
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function () {
    // @ts-ignore
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      // @ts-ignore
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function () {
    // @ts-ignore
    var response = new Response(null, { status: 0, statusText: '' });
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function (url: any, status: any) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    // @ts-ignore
    return new Response(null, { status: status, headers: { location: url } })
  };

  (self as any).DOMException = (self as any).DOMException;
  try {
    new (self as any).DOMException();
  } catch (err) {
    (self as any).DOMException = function (message: any, name: any) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    (self as any).DOMException.prototype = Object.create(Error.prototype);
    (self as any).DOMException.prototype.constructor = (self as any).DOMException;
  }

  function fetch(input: any, init: any) {
    return new Promise(function (resolve, reject) {
      // @ts-ignore
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new (self as any).DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function () {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        (options as any).url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
        // @ts-ignore
        resolve(new Response(body, options));
      };

      xhr.onerror = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function () {
        reject(new (self as any).DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function (value: any, name: any) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function () {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    (self as any).fetch = fetch;
    (self as any).Headers = Headers;
    (self as any).Request = Request;
    (self as any).Response = Response;
  }
})();

export enum AvoEnv {
  Prod = "prod",
  Staging = "staging",
  Dev = "dev",
}

export interface CustomDestination {
  make?(env: AvoEnv, apiKey: string): void;
  logEvent?: (eventName: string, eventProperties: Record<string, any>) => void;
  setUserProperties?: (userId: string, userProperties: Record<string, any>) => void;
  identify?: (userId: string) => void;
  unidentify?: () => void;
  logPage?: (pageName: string, eventProperties: Record<string, any>) => void;
  revenue?: (amount: number, eventProperties: Record<string, any>) => void;
  setGroupProperties?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  addCurrentUserToGroup?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  logEventWithGroups?: (
    eventName: string,
    eventProperties: any,
    groupTypesToGroupIds: Record<string, string>,
  ) => void;
}

// @ts-ignore
interface AvoAssertMessage {
  eventName?: string;
  tag?: string;
  propertyId?: string;
  message?: string;
  additionalProperties?: string[],
  shape?: any,
  shapeUserProps?: any,
  actualType?: string
}

let __AVO_ENV__: AvoEnv | null = null;
// @ts-ignore
let __AVO_NOOP__: boolean = false;
// @ts-ignore
let __AVO_LOGGER__: AvoLogger | null = null;
// @ts-ignore
let __STRICT__: boolean | null = null;
// @ts-ignore
let __REPORT_FAILURE_AS__: 'error' | 'warn' | 'log' | null = null;

// @ts-ignore
let __WEB_DEBUGGER__: boolean = true;
export const avoInspectorApiKey = "wYdr2nl0i3vj8uIFJ5XM";
// @ts-ignore
interface AvoInspector {}
let __INSPECTOR__: AvoInspector | null = null;


// polyfill Object.assign
// @ts-ignore
declare interface ObjectConstructor {
  assign: any;
}
// @ts-ignore
if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target: any, _varArgs: any) { // .length of function is 2
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      let to = Object(target);

      for (let index = 1; index < arguments.length; index++) {
        let nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (let nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

interface AvoLogger {
  logDebug(env: AvoEnv | null, message: string): boolean;
  logWarn(env: AvoEnv | null, message: string): boolean;
  logError(env: AvoEnv | null, error: string): boolean;
}

enum webDebuggerArea {
  BottomRight = "BottomRight",
  BottomLeft = "BottomLeft",
  TopRight = "TopRight",
  TopLeft = "TopLeft"
}

interface bottomRightParameters {
  bottom: number;
  right: number;
}

interface bottomLeftParameters {
  bottom: number;
  left: number;
}

interface topRightParameters {
  top: number;
  right: number;
}

interface topLeftParameters {
  top: number;
  left: number;
}

interface webDebuggerPosition {
  position: webDebuggerArea;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface webDebuggerOptions {
  position?: webDebuggerPosition;
}

interface WebDebuggerPositionSetter {
  BottomRight(p: bottomRightParameters): webDebuggerPosition;
  BottomLeft(p: bottomLeftParameters): webDebuggerPosition;
  TopRight(p: topRightParameters): webDebuggerPosition;
  TopLeft(p: topLeftParameters): webDebuggerPosition;
}

export const WebDebuggerPosition: WebDebuggerPositionSetter = {
  BottomRight: ({ bottom, right }) => ({
    position: webDebuggerArea.BottomRight,
    bottom,
    right,
  }),
  BottomLeft: ({ bottom, left }) => ({
    position: webDebuggerArea.BottomLeft,
    bottom,
    left,
  }),
  TopRight: ({ top, right }) => ({
    position: webDebuggerArea.TopRight,
    top,
    right,
  }),
  TopLeft: ({ top, left }) => ({
    position: webDebuggerArea.TopLeft,
    top,
    left,
  }),
}


let InternalAvoLogger: any = {
  logEventSent: function logEventSent(eventName: string, eventProperties: any, userProperties: any) {
    const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties);

    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
  },

  log: function log(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] " + message);
  },

  warn: function warn(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.warn("[avo] " + message);
  },

  error: function error(message: string, error: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
      return
    }
    typeof console !== 'undefined' && console.error("[avo] " + message, error);
  }
};

function convertPropertiesArrayToMap(propertiesArray: [{id: string, name: string, value: string}]): {string: string | null | undefined} {
    let result: {string: string} = {}

    propertiesArray.forEach(value => {
      result[value.name] = value.value
    })

    return result
}

// @ts-ignore
let array_difference: any;
// @ts-ignore
let AvoAssert: any;
array_difference = function array_difference(a1: any[], a2: any[]) {
  let result: any[] = [];
  for (let i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
}

AvoAssert = {
  assertObject: function assertObject(propertyId: string, propName: string, obj: any) {
    if (typeof obj !== 'object') {
      let message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
      return [{tag: 'expectedObjectType', propertyId, message, actualType: typeof obj}];
    } else {
      return [];
    }
  },

  assertString: function assertString(propertyId: string, propName: string, str: string) {
    if (typeof str !== 'string') {
      let message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
      return [{tag: 'expectedStringType', propertyId, message, actualType: typeof str}];
    } else {
      return [];
    }
  },

  assertInt: function assertInt(propertyId: string, propName: string, int: number) {
    if (typeof int === 'number' && int !== Math.round(int)) {
      let message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: 'float'}];
    } else if (typeof int !== 'number') {
      let message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: typeof int}];
    } else {
      return [];
    }
  },

  assertLong: function assertLong(propertyId: string, propName: string, long: number) {
    if (typeof long === 'number' && long !== Math.round(long)) {
      let message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: 'float'}];
    } else if (typeof long !== 'number') {
      let message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: typeof long}];
    } else {
      return [];
    }
  },

  assertFloat: function assertFloat(propertyId: string, propName: string, float: number) {
    if (typeof float !== 'number') {
      let message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
      return [{tag: 'expectedFloatType', propertyId, message, actualType: typeof float}];
    } else {
      return [];
    }
  },

  assertBool: function assertBool(propertyId: string, propName: string, bool: boolean) {
    if (typeof bool !== 'boolean') {
      let message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
      return [{tag: 'expectedBoolType', propertyId, message, actualType: typeof bool}];
    } else {
      return [];
    }
  },

  assertMax: function assertMax(
    propertyId: string,
    propName: string,
    max: number,
    value: number
  ) {
    if (value > max) {
      let message = propName +
        ' has a maximum value of ' +
        max +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMax', propertyId, message}];
    } else {
      return [];
    }
  },

  assertMin: function assertMin(
    propertyId: string,
    propName: string,
    min: number,
    value: number
  ) {
    if (value < min) {
      let message = propName +
        ' has a minimum value of ' +
        min +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMin', propertyId, message}];
    } else {
      return [];
    }
  },

  assertList: function assertList(propertyId: string, propName: string, value: any) {
    if (!Array.isArray(value)) {
      let message = propName + ' should be of type list but you provided type ' + typeof value;
      return [{tag: 'expectedList', propertyId, message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },
};

let _avo_invoke: any;
let _avo_invoke_meta: any;
let _avo_sampling_rate = 1.0;
_avo_invoke = function _avo_invoke(env: AvoEnv, eventId: string, hash: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "AA7E42keLpNVI1hyKBz7",
          "br": "master",
          "en": env,
          "ev": eventId,
          "ha": hash,
          "sc": "s5Rw4iVTjTG0gbHpgybD",
          "se": (new Date()).toISOString(),
          "so": "KhjBh9UMg",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke_meta = function _avo_invoke_meta(env: AvoEnv, type: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "AA7E42keLpNVI1hyKBz7",
          "br": "master",
          "en": env,
          "ty": type,
          "sc": "s5Rw4iVTjTG0gbHpgybD",
          "se": (new Date()).toISOString(),
          "so": "KhjBh9UMg",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}


let _avo_debugger_log: any;
let _avo_debugger_events_during_boot: any = [];
let _avo_debugger_ready = false;

if (typeof (window as any) !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    let iframe: any = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      let message = {
        type_: "avo-debugger-boot-events",
        schemaId: "s5Rw4iVTjTG0gbHpgybD",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId: string, eventName: string, messages: any[], eventProperties: any[], userProperties: any[], groupProperties: any[]) {
  if (typeof (window as any) === 'undefined') { return; }
  let event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties,
    userProperties,
    groupProperties
  };

  if (_avo_debugger_ready) {
    let message = {type_: "avo-debugger-events", events: [event]};
    (document.getElementById("avo-debugger") as any).contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}

function _avo_debugger_send_position(position: webDebuggerPosition) {
  if (typeof window === 'undefined') { return; }
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    if (event.data.type_ === "avo-debugger-ready" && position !== null) {
      var message = {type_: "avo-debugger-position", position: position};
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  })
}


export interface PageProperties {
}

export const ConsumerEntityType = {
  'BUSINESS': 'Business',
  'PRIVATE_INDIVIDUAL': 'PrivateIndividual',
} as const;
export type ConsumerEntityTypeType = typeof ConsumerEntityType;
export type ConsumerEntityTypeValueType = ConsumerEntityTypeType[keyof ConsumerEntityTypeType];

export const CountryCode = {
  'GB': 'gb',
  'DE': 'de',
} as const;
export type CountryCodeType = typeof CountryCode;
export type CountryCodeValueType = CountryCodeType[keyof CountryCodeType];

export const FinanceQuoteJourney = {
  'QUICK_QUOTE': 'quick_quote',
  'INTERCEPTED': 'intercepted',
} as const;
export type FinanceQuoteJourneyType = typeof FinanceQuoteJourney;
export type FinanceQuoteJourneyValueType = FinanceQuoteJourneyType[keyof FinanceQuoteJourneyType];

export const RequoteNewRateSelectedOrigin = {
  'URL': 'url',
  'CTA': 'cta',
} as const;
export type RequoteNewRateSelectedOriginType = typeof RequoteNewRateSelectedOrigin;
export type RequoteNewRateSelectedOriginValueType = RequoteNewRateSelectedOriginType[keyof RequoteNewRateSelectedOriginType];

export const FinanceProductType = {
  'HP': 'hp',
  'PCP': 'pcp',
  'CS': 'cs',
  'LP': 'lp',
  'FS': 'fs',
  'SC': 'sc',
  'PCH': 'pch',
  'CH': 'ch',
  'FL': 'fl',
  'OL': 'ol',
  'CP': 'cp',
  'BP': 'bp',
  'DEFAULT': 'default',
} as const;
export type FinanceProductTypeType = typeof FinanceProductType;
export type FinanceProductTypeValueType = FinanceProductTypeType[keyof FinanceProductTypeType];

let sysPlatformName: any;
let sysPlatformVersion: any;
let sysPlatformId: any;
let sysCookieConsentMarketingGranted: any;
let sysCookieConsentFunctionalGranted: any;
let sysCookieConsentAnalyticalGranted: any;
let sysWebshopId: any;
let sysWebshopCampaignId: any;
let sysCountryCode: any;

export function setSystemProperties(properties: {platformName: string;
  platformVersion: string; platformId: string;
  cookieConsentMarketingGranted: boolean;
  cookieConsentFunctionalGranted: boolean;
  cookieConsentAnalyticalGranted: boolean;
  webshopId: string | null | undefined;
  webshopCampaignId: string | null | undefined;
  countryCode: CountryCodeValueType | null | undefined}) {
  if (properties.platformName !== null && properties.platformName !== undefined) {
    sysPlatformName = properties.platformName;
  }
  if (properties.platformVersion !== null && properties.platformVersion !== undefined) {
    sysPlatformVersion = properties.platformVersion;
  }
  if (properties.platformId !== null && properties.platformId !== undefined) {
    sysPlatformId = properties.platformId;
  }
  if (properties.cookieConsentMarketingGranted !== null && properties.cookieConsentMarketingGranted !== undefined) {
    sysCookieConsentMarketingGranted = properties.cookieConsentMarketingGranted;
  }
  if (properties.cookieConsentFunctionalGranted !== null && properties.cookieConsentFunctionalGranted !== undefined) {
    sysCookieConsentFunctionalGranted = properties.cookieConsentFunctionalGranted;
  }
  if (properties.cookieConsentAnalyticalGranted !== null && properties.cookieConsentAnalyticalGranted !== undefined) {
    sysCookieConsentAnalyticalGranted = properties.cookieConsentAnalyticalGranted;
  }
  if (properties.webshopId !== null && properties.webshopId !== undefined) {
    sysWebshopId = properties.webshopId;
  }
  if (properties.webshopCampaignId !== null && properties.webshopCampaignId !== undefined) {
    sysWebshopCampaignId = properties.webshopCampaignId;
  }
  if (properties.countryCode !== null && properties.countryCode !== undefined) {
    sysCountryCode = properties.countryCode;
  }
}

let RudderstackDealerPlatformJs: any;

export function initAvo(options: {env: AvoEnv; webDebugger?: boolean;
  webDebuggerOptions?: webDebuggerOptions; strict?: boolean; noop?: boolean;
  reportFailureAs?: 'error' | 'warn' | 'log'; inspector?: AvoInspector;
  avoLogger?: AvoLogger}, systemProperties: {platformName: string;
  platformVersion: string; platformId: string;
  cookieConsentMarketingGranted: boolean;
  cookieConsentFunctionalGranted: boolean;
  cookieConsentAnalyticalGranted: boolean;
  webshopId: string | null | undefined;
  webshopCampaignId: string | null | undefined;
  countryCode: CountryCodeValueType | null | undefined},
  destinationOptions: any,
  RudderstackDealerPlatformJsDestination: CustomDestination) {
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env;
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == AvoEnv.Prod) {
    InternalAvoLogger.warn("[avo] ****************************************************");
    InternalAvoLogger.warn("[avo] WARNING Avo cannot be initialized in noop mode in production:");
    InternalAvoLogger.warn("[avo] - Overwriting configuration with noop=false.");
    InternalAvoLogger.warn("[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true");
    InternalAvoLogger.warn("[avo] ****************************************************");
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log("[avo] ****************************************************");
    InternalAvoLogger.log("[avo] Avo is now initialized in noop mode. This means:");
    InternalAvoLogger.log("[avo] - No events will be sent");
    InternalAvoLogger.log("[avo] - No network requests are made");
    InternalAvoLogger.log("[avo] ****************************************************");
  }
  if (options.strict !== undefined) {
    __STRICT__ = options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) {
    __REPORT_FAILURE_AS__ = options.reportFailureAs;
  }
  __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && (window as any).location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== AvoEnv.Prod));
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== 'prod') {
    InternalAvoLogger.warn("[avo] Avo Inspector not provided in initAvo() call");
  }

  setSystemProperties(systemProperties);

  destinationOptions = destinationOptions || {};

  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if (options.webDebuggerOptions?.position) {
      _avo_debugger_send_position(options.webDebuggerOptions.position)
    }

(function() {
  if (typeof (window as any) === 'undefined') { return; }
  let init = function() {
    let iframe: any = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.id = "avo-debugger";
    iframe.src = "https://www.avo.app/_debugger";
    iframe.style = "display: none;";
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === AvoEnv.Prod) {
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
    }
    if (__AVO_ENV__ === AvoEnv.Staging) {
    }

    RudderstackDealerPlatformJs = RudderstackDealerPlatformJsDestination;
    if (__AVO_ENV__ === 'prod') {
      RudderstackDealerPlatformJs && RudderstackDealerPlatformJs.make && RudderstackDealerPlatformJs.make(__AVO_ENV__, "2KuYnBoYyNBTEq6bbsuQAVMmLEL");
    } else if (__AVO_ENV__ === 'dev') {
      RudderstackDealerPlatformJs && RudderstackDealerPlatformJs.make && RudderstackDealerPlatformJs.make(__AVO_ENV__, "2KuYGRUUu8uxFTOp2zziHvmK49q");
    } else {
      RudderstackDealerPlatformJs && RudderstackDealerPlatformJs.make && RudderstackDealerPlatformJs.make(__AVO_ENV__, "2KuYYz17K7an4jhxBRJ4dzApzDV");
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
    }
  }
}

export function setAvoLogger(avoLogger: AvoLogger | null) {
  __AVO_LOGGER__ = avoLogger;
}

export interface FinanceEligibilityCheckSubmittedProperties {
  vehicleVrm: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleVin: string | null | undefined;
  ivendiVehicleId: string | null | undefined;
  vehicleTaxonomySource: string | null | undefined;
  vehicleTaxonomyCountry: string | null | undefined;
  vehicleTaxonomyCode: string | null | undefined;
  vehicleClass: string;
  vehicleOemCodes: string[] | null | undefined;
  vehicleDerivative: string | null | undefined;
  vehicleFuel: string | null | undefined;
  vehicleDoors: string | null | undefined;
  vehicleCondition: string | null | undefined;
  vehicleImageUrl: string | null | undefined;
  vehicleAccuracyScore: string | null | undefined;
  algoliaObjectId: string | null | undefined;
  vehiclePrice: number;
  vehicleEngineSize: string | null | undefined;
  vehicleMileage: number;
  dealershipId: string;
}
/**
 * finance_eligibility_check_submitted: consumer has completed all fields and submitted
 *
 * @param properties the properties associatied with this event
 * @param properties.vehicleVrm: vehicle vrm

 * @param properties.vehicleMake: Vehicle Make
 * @param properties.vehicleModel: Vehicle Model
 * @param properties.vehicleVin: vehicle vin
 * @param properties.ivendiVehicleId: Our universal vehicle id (if available)
 * @param properties.vehicleTaxonomySource: no description
 * @param properties.vehicleTaxonomyCountry: no description
 * @param properties.vehicleTaxonomyCode: no description
 * @param properties.vehicleClass: no description
 * @param properties.vehicleOemCodes: no description
 * @param properties.vehicleDerivative: no description
 * @param properties.vehicleFuel: no description
 * @param properties.vehicleDoors: no description
 * @param properties.vehicleCondition: no description
 * @param properties.vehicleImageUrl: no description
 * @param properties.vehicleAccuracyScore: no description
 * @param properties.algoliaObjectId: Algolia's unique id for a vehicle search document
 * @param properties.vehiclePrice: Vehicle Price
 * @param properties.vehicleEngineSize: no description
 * @param properties.vehicleMileage: no description
 * @param properties.dealershipId: ID of a dealership
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/oWcOOZOkwQ}
 */
export function financeEligibilityCheckSubmitted(
  properties: FinanceEligibilityCheckSubmittedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "-UqrurLDHx", name: "vehicle_vrm", value: properties.vehicleVrm});
  eventPropertiesArray.push({id: "O9E-Dnhdqz", name: "vehicle_make", value: properties.vehicleMake});
  eventPropertiesArray.push({id: "aTRNhI5D9F", name: "vehicle_model", value: properties.vehicleModel});
  properties.vehicleVin !== undefined && properties.vehicleVin !== null ?
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: properties.vehicleVin}) :
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: null});
  properties.ivendiVehicleId !== undefined && properties.ivendiVehicleId !== null ?
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: properties.ivendiVehicleId}) :
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: null});
  properties.vehicleTaxonomySource !== undefined && properties.vehicleTaxonomySource !== null ?
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: properties.vehicleTaxonomySource}) :
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: null});
  properties.vehicleTaxonomyCountry !== undefined && properties.vehicleTaxonomyCountry !== null ?
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: properties.vehicleTaxonomyCountry}) :
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: null});
  properties.vehicleTaxonomyCode !== undefined && properties.vehicleTaxonomyCode !== null ?
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: properties.vehicleTaxonomyCode}) :
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: null});
  eventPropertiesArray.push({id: "YAMqZFa6Tg", name: "vehicle_class", value: properties.vehicleClass});
  properties.vehicleOemCodes !== undefined && properties.vehicleOemCodes !== null ?
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: properties.vehicleOemCodes}) :
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: null});
  properties.vehicleDerivative !== undefined && properties.vehicleDerivative !== null ?
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: properties.vehicleDerivative}) :
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: null});
  properties.vehicleFuel !== undefined && properties.vehicleFuel !== null ?
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: properties.vehicleFuel}) :
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: null});
  properties.vehicleDoors !== undefined && properties.vehicleDoors !== null ?
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: properties.vehicleDoors}) :
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: null});
  properties.vehicleCondition !== undefined && properties.vehicleCondition !== null ?
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: properties.vehicleCondition}) :
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: null});
  properties.vehicleImageUrl !== undefined && properties.vehicleImageUrl !== null ?
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: properties.vehicleImageUrl}) :
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: null});
  properties.vehicleAccuracyScore !== undefined && properties.vehicleAccuracyScore !== null ?
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: properties.vehicleAccuracyScore}) :
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: null});
  properties.algoliaObjectId !== undefined && properties.algoliaObjectId !== null ?
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: properties.algoliaObjectId}) :
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: null});
  eventPropertiesArray.push({id: "pwOlscPL2G", name: "vehicle_price", value: properties.vehiclePrice});
  properties.vehicleEngineSize !== undefined && properties.vehicleEngineSize !== null ?
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: properties.vehicleEngineSize}) :
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: null});
  eventPropertiesArray.push({id: "GA16odh9aI", name: "vehicle_mileage", value: properties.vehicleMileage});
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "oWcOOZOkwQ", "686db60b6ee48cbbb3982eb77a049cedb4241a6b7080e51246c095426aca0ca4", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("finance_eligibility_check_submitted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("oWcOOZOkwQ", "finance_eligibility_check_submitted", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("finance_eligibility_check_submitted", eventProperties, "oWcOOZOkwQ", "686db60b6ee48cbbb3982eb77a049cedb4241a6b7080e51246c095426aca0ca4");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("finance_eligibility_check_submitted", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FinanceEligibilityCheckActivatedProperties {
  vehicleVrm: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleVin: string | null | undefined;
  ivendiVehicleId: string | null | undefined;
  vehicleTaxonomySource: string | null | undefined;
  vehicleTaxonomyCountry: string | null | undefined;
  vehicleTaxonomyCode: string | null | undefined;
  vehicleClass: string;
  vehicleOemCodes: string[] | null | undefined;
  vehicleDerivative: string | null | undefined;
  vehicleFuel: string | null | undefined;
  vehicleDoors: string | null | undefined;
  vehicleCondition: string | null | undefined;
  vehicleImageUrl: string | null | undefined;
  vehicleAccuracyScore: string | null | undefined;
  algoliaObjectId: string | null | undefined;
  vehiclePrice: number;
  vehicleEngineSize: string | null | undefined;
  vehicleMileage: number;
  dealershipId: string;
}
/**
 * finance_eligibility_check_activated: initial finance check forms displayed

 *
 * @param properties the properties associatied with this event
 * @param properties.vehicleVrm: vehicle vrm

 * @param properties.vehicleMake: Vehicle Make
 * @param properties.vehicleModel: Vehicle Model
 * @param properties.vehicleVin: vehicle vin
 * @param properties.ivendiVehicleId: Our universal vehicle id (if available)
 * @param properties.vehicleTaxonomySource: no description
 * @param properties.vehicleTaxonomyCountry: no description
 * @param properties.vehicleTaxonomyCode: no description
 * @param properties.vehicleClass: no description
 * @param properties.vehicleOemCodes: no description
 * @param properties.vehicleDerivative: no description
 * @param properties.vehicleFuel: no description
 * @param properties.vehicleDoors: no description
 * @param properties.vehicleCondition: no description
 * @param properties.vehicleImageUrl: no description
 * @param properties.vehicleAccuracyScore: no description
 * @param properties.algoliaObjectId: Algolia's unique id for a vehicle search document
 * @param properties.vehiclePrice: Vehicle Price
 * @param properties.vehicleEngineSize: no description
 * @param properties.vehicleMileage: no description
 * @param properties.dealershipId: ID of a dealership
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/o9nejH6uJ}
 */
export function financeEligibilityCheckActivated(
  properties: FinanceEligibilityCheckActivatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "-UqrurLDHx", name: "vehicle_vrm", value: properties.vehicleVrm});
  eventPropertiesArray.push({id: "O9E-Dnhdqz", name: "vehicle_make", value: properties.vehicleMake});
  eventPropertiesArray.push({id: "aTRNhI5D9F", name: "vehicle_model", value: properties.vehicleModel});
  properties.vehicleVin !== undefined && properties.vehicleVin !== null ?
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: properties.vehicleVin}) :
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: null});
  properties.ivendiVehicleId !== undefined && properties.ivendiVehicleId !== null ?
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: properties.ivendiVehicleId}) :
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: null});
  properties.vehicleTaxonomySource !== undefined && properties.vehicleTaxonomySource !== null ?
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: properties.vehicleTaxonomySource}) :
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: null});
  properties.vehicleTaxonomyCountry !== undefined && properties.vehicleTaxonomyCountry !== null ?
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: properties.vehicleTaxonomyCountry}) :
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: null});
  properties.vehicleTaxonomyCode !== undefined && properties.vehicleTaxonomyCode !== null ?
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: properties.vehicleTaxonomyCode}) :
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: null});
  eventPropertiesArray.push({id: "YAMqZFa6Tg", name: "vehicle_class", value: properties.vehicleClass});
  properties.vehicleOemCodes !== undefined && properties.vehicleOemCodes !== null ?
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: properties.vehicleOemCodes}) :
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: null});
  properties.vehicleDerivative !== undefined && properties.vehicleDerivative !== null ?
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: properties.vehicleDerivative}) :
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: null});
  properties.vehicleFuel !== undefined && properties.vehicleFuel !== null ?
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: properties.vehicleFuel}) :
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: null});
  properties.vehicleDoors !== undefined && properties.vehicleDoors !== null ?
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: properties.vehicleDoors}) :
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: null});
  properties.vehicleCondition !== undefined && properties.vehicleCondition !== null ?
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: properties.vehicleCondition}) :
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: null});
  properties.vehicleImageUrl !== undefined && properties.vehicleImageUrl !== null ?
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: properties.vehicleImageUrl}) :
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: null});
  properties.vehicleAccuracyScore !== undefined && properties.vehicleAccuracyScore !== null ?
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: properties.vehicleAccuracyScore}) :
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: null});
  properties.algoliaObjectId !== undefined && properties.algoliaObjectId !== null ?
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: properties.algoliaObjectId}) :
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: null});
  eventPropertiesArray.push({id: "pwOlscPL2G", name: "vehicle_price", value: properties.vehiclePrice});
  properties.vehicleEngineSize !== undefined && properties.vehicleEngineSize !== null ?
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: properties.vehicleEngineSize}) :
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: null});
  eventPropertiesArray.push({id: "GA16odh9aI", name: "vehicle_mileage", value: properties.vehicleMileage});
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "o9nejH6uJ", "0ce5b3bc14b68e668d802ac56a02aed85a3f0abf9a228ccfbae3d0e2acc406ab", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("finance_eligibility_check_activated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("o9nejH6uJ", "finance_eligibility_check_activated", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("finance_eligibility_check_activated", eventProperties, "o9nejH6uJ", "0ce5b3bc14b68e668d802ac56a02aed85a3f0abf9a228ccfbae3d0e2acc406ab");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("finance_eligibility_check_activated", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FinanceEligibilityCheckCompletedProperties {
  vehicleVrm: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleVin: string | null | undefined;
  ivendiVehicleId: string | null | undefined;
  vehicleTaxonomySource: string | null | undefined;
  vehicleTaxonomyCountry: string | null | undefined;
  vehicleTaxonomyCode: string | null | undefined;
  vehicleClass: string;
  vehicleOemCodes: string[] | null | undefined;
  vehicleDerivative: string | null | undefined;
  vehicleFuel: string | null | undefined;
  vehicleDoors: string | null | undefined;
  vehicleCondition: string | null | undefined;
  vehicleImageUrl: string | null | undefined;
  vehicleAccuracyScore: string | null | undefined;
  algoliaObjectId: string | null | undefined;
  vehiclePrice: number;
  vehicleEngineSize: string | null | undefined;
  vehicleMileage: number;
  dealershipId: string;
  eligibilityHighestMatchRate: string | null | undefined;
}
/**
 * finance_eligibility_check_completed: On successful eligibility check
 *
 * @param properties the properties associatied with this event
 * @param properties.vehicleVrm: vehicle vrm

 * @param properties.vehicleMake: Vehicle Make
 * @param properties.vehicleModel: Vehicle Model
 * @param properties.vehicleVin: vehicle vin
 * @param properties.ivendiVehicleId: Our universal vehicle id (if available)
 * @param properties.vehicleTaxonomySource: no description
 * @param properties.vehicleTaxonomyCountry: no description
 * @param properties.vehicleTaxonomyCode: no description
 * @param properties.vehicleClass: no description
 * @param properties.vehicleOemCodes: no description
 * @param properties.vehicleDerivative: no description
 * @param properties.vehicleFuel: no description
 * @param properties.vehicleDoors: no description
 * @param properties.vehicleCondition: no description
 * @param properties.vehicleImageUrl: no description
 * @param properties.vehicleAccuracyScore: no description
 * @param properties.algoliaObjectId: Algolia's unique id for a vehicle search document
 * @param properties.vehiclePrice: Vehicle Price
 * @param properties.vehicleEngineSize: no description
 * @param properties.vehicleMileage: no description
 * @param properties.dealershipId: ID of a dealership
 * @param properties.eligibilityHighestMatchRate: no description
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/NIuqTaSbJS}
 */
export function financeEligibilityCheckCompleted(
  properties: FinanceEligibilityCheckCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "-UqrurLDHx", name: "vehicle_vrm", value: properties.vehicleVrm});
  eventPropertiesArray.push({id: "O9E-Dnhdqz", name: "vehicle_make", value: properties.vehicleMake});
  eventPropertiesArray.push({id: "aTRNhI5D9F", name: "vehicle_model", value: properties.vehicleModel});
  properties.vehicleVin !== undefined && properties.vehicleVin !== null ?
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: properties.vehicleVin}) :
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: null});
  properties.ivendiVehicleId !== undefined && properties.ivendiVehicleId !== null ?
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: properties.ivendiVehicleId}) :
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: null});
  properties.vehicleTaxonomySource !== undefined && properties.vehicleTaxonomySource !== null ?
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: properties.vehicleTaxonomySource}) :
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: null});
  properties.vehicleTaxonomyCountry !== undefined && properties.vehicleTaxonomyCountry !== null ?
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: properties.vehicleTaxonomyCountry}) :
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: null});
  properties.vehicleTaxonomyCode !== undefined && properties.vehicleTaxonomyCode !== null ?
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: properties.vehicleTaxonomyCode}) :
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: null});
  eventPropertiesArray.push({id: "YAMqZFa6Tg", name: "vehicle_class", value: properties.vehicleClass});
  properties.vehicleOemCodes !== undefined && properties.vehicleOemCodes !== null ?
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: properties.vehicleOemCodes}) :
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: null});
  properties.vehicleDerivative !== undefined && properties.vehicleDerivative !== null ?
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: properties.vehicleDerivative}) :
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: null});
  properties.vehicleFuel !== undefined && properties.vehicleFuel !== null ?
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: properties.vehicleFuel}) :
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: null});
  properties.vehicleDoors !== undefined && properties.vehicleDoors !== null ?
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: properties.vehicleDoors}) :
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: null});
  properties.vehicleCondition !== undefined && properties.vehicleCondition !== null ?
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: properties.vehicleCondition}) :
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: null});
  properties.vehicleImageUrl !== undefined && properties.vehicleImageUrl !== null ?
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: properties.vehicleImageUrl}) :
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: null});
  properties.vehicleAccuracyScore !== undefined && properties.vehicleAccuracyScore !== null ?
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: properties.vehicleAccuracyScore}) :
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: null});
  properties.algoliaObjectId !== undefined && properties.algoliaObjectId !== null ?
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: properties.algoliaObjectId}) :
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: null});
  eventPropertiesArray.push({id: "pwOlscPL2G", name: "vehicle_price", value: properties.vehiclePrice});
  properties.vehicleEngineSize !== undefined && properties.vehicleEngineSize !== null ?
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: properties.vehicleEngineSize}) :
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: null});
  eventPropertiesArray.push({id: "GA16odh9aI", name: "vehicle_mileage", value: properties.vehicleMileage});
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  properties.eligibilityHighestMatchRate !== undefined && properties.eligibilityHighestMatchRate !== null ?
    eventPropertiesArray.push({id: "8QxX5sHK1D", name: "eligibility_highest_match_rate", value: properties.eligibilityHighestMatchRate}) :
    eventPropertiesArray.push({id: "8QxX5sHK1D", name: "eligibility_highest_match_rate", value: null});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "NIuqTaSbJS", "6468a192074b38e5c633a52f84ddb9100a5ff382849dcb5537618e600015e39e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("finance_eligibility_check_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("NIuqTaSbJS", "finance_eligibility_check_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("finance_eligibility_check_completed", eventProperties, "NIuqTaSbJS", "6468a192074b38e5c633a52f84ddb9100a5ff382849dcb5537618e600015e39e");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("finance_eligibility_check_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FinanceEligibilityCheckFailedProperties {
  vehicleVrm: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleVin: string | null | undefined;
  ivendiVehicleId: string | null | undefined;
  vehicleTaxonomySource: string | null | undefined;
  vehicleTaxonomyCountry: string | null | undefined;
  vehicleTaxonomyCode: string | null | undefined;
  vehicleClass: string;
  vehicleOemCodes: string[] | null | undefined;
  vehicleDerivative: string | null | undefined;
  vehicleFuel: string | null | undefined;
  vehicleDoors: string | null | undefined;
  vehicleCondition: string | null | undefined;
  vehicleImageUrl: string | null | undefined;
  vehicleAccuracyScore: string | null | undefined;
  algoliaObjectId: string | null | undefined;
  vehiclePrice: number;
  vehicleEngineSize: string | null | undefined;
  vehicleMileage: number;
  dealershipId: string;
  errorMessage: string;
}
/**
 * finance_eligibility_check_failed: On failure of eligibility check
 *
 * @param properties the properties associatied with this event
 * @param properties.vehicleVrm: vehicle vrm

 * @param properties.vehicleMake: Vehicle Make
 * @param properties.vehicleModel: Vehicle Model
 * @param properties.vehicleVin: vehicle vin
 * @param properties.ivendiVehicleId: Our universal vehicle id (if available)
 * @param properties.vehicleTaxonomySource: no description
 * @param properties.vehicleTaxonomyCountry: no description
 * @param properties.vehicleTaxonomyCode: no description
 * @param properties.vehicleClass: no description
 * @param properties.vehicleOemCodes: no description
 * @param properties.vehicleDerivative: no description
 * @param properties.vehicleFuel: no description
 * @param properties.vehicleDoors: no description
 * @param properties.vehicleCondition: no description
 * @param properties.vehicleImageUrl: no description
 * @param properties.vehicleAccuracyScore: no description
 * @param properties.algoliaObjectId: Algolia's unique id for a vehicle search document
 * @param properties.vehiclePrice: Vehicle Price
 * @param properties.vehicleEngineSize: no description
 * @param properties.vehicleMileage: no description
 * @param properties.dealershipId: ID of a dealership
 * @param properties.errorMessage: no description
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/6QgeBuCeHi}
 */
export function financeEligibilityCheckFailed(
  properties: FinanceEligibilityCheckFailedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "-UqrurLDHx", name: "vehicle_vrm", value: properties.vehicleVrm});
  eventPropertiesArray.push({id: "O9E-Dnhdqz", name: "vehicle_make", value: properties.vehicleMake});
  eventPropertiesArray.push({id: "aTRNhI5D9F", name: "vehicle_model", value: properties.vehicleModel});
  properties.vehicleVin !== undefined && properties.vehicleVin !== null ?
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: properties.vehicleVin}) :
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: null});
  properties.ivendiVehicleId !== undefined && properties.ivendiVehicleId !== null ?
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: properties.ivendiVehicleId}) :
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: null});
  properties.vehicleTaxonomySource !== undefined && properties.vehicleTaxonomySource !== null ?
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: properties.vehicleTaxonomySource}) :
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: null});
  properties.vehicleTaxonomyCountry !== undefined && properties.vehicleTaxonomyCountry !== null ?
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: properties.vehicleTaxonomyCountry}) :
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: null});
  properties.vehicleTaxonomyCode !== undefined && properties.vehicleTaxonomyCode !== null ?
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: properties.vehicleTaxonomyCode}) :
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: null});
  eventPropertiesArray.push({id: "YAMqZFa6Tg", name: "vehicle_class", value: properties.vehicleClass});
  properties.vehicleOemCodes !== undefined && properties.vehicleOemCodes !== null ?
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: properties.vehicleOemCodes}) :
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: null});
  properties.vehicleDerivative !== undefined && properties.vehicleDerivative !== null ?
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: properties.vehicleDerivative}) :
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: null});
  properties.vehicleFuel !== undefined && properties.vehicleFuel !== null ?
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: properties.vehicleFuel}) :
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: null});
  properties.vehicleDoors !== undefined && properties.vehicleDoors !== null ?
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: properties.vehicleDoors}) :
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: null});
  properties.vehicleCondition !== undefined && properties.vehicleCondition !== null ?
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: properties.vehicleCondition}) :
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: null});
  properties.vehicleImageUrl !== undefined && properties.vehicleImageUrl !== null ?
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: properties.vehicleImageUrl}) :
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: null});
  properties.vehicleAccuracyScore !== undefined && properties.vehicleAccuracyScore !== null ?
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: properties.vehicleAccuracyScore}) :
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: null});
  properties.algoliaObjectId !== undefined && properties.algoliaObjectId !== null ?
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: properties.algoliaObjectId}) :
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: null});
  eventPropertiesArray.push({id: "pwOlscPL2G", name: "vehicle_price", value: properties.vehiclePrice});
  properties.vehicleEngineSize !== undefined && properties.vehicleEngineSize !== null ?
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: properties.vehicleEngineSize}) :
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: null});
  eventPropertiesArray.push({id: "GA16odh9aI", name: "vehicle_mileage", value: properties.vehicleMileage});
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  eventPropertiesArray.push({id: "kBIayprrF6", name: "error_message", value: properties.errorMessage});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "6QgeBuCeHi", "7f74798115be1a86e8f60da5f3b0d1a68ee03bedbb496417899e300674236723", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("finance_eligibility_check_failed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("6QgeBuCeHi", "finance_eligibility_check_failed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("finance_eligibility_check_failed", eventProperties, "6QgeBuCeHi", "7f74798115be1a86e8f60da5f3b0d1a68ee03bedbb496417899e300674236723");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("finance_eligibility_check_failed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PageViewProperties {
  customDestinationPageName_: string;
  pageTags: string[] | null | undefined;
  dealershipId: string | null | undefined;
  vehicleVrm: string | null | undefined;
  vehicleRmid: string | null | undefined;
  financeFunderCode: string | null | undefined;
  financeQuoteId: string | null | undefined;
  consumerId: string | null | undefined;
  pageProperties: PageProperties[] | null | undefined;
  vehicleImageUrl: string | null | undefined;
  quoteeId: string | null | undefined;
  errorMessage: string | null | undefined;
  ivendiVehicleId: string | null | undefined;
  vehicleVin: string | null | undefined;
}
/**
 * page_view: Generic page view event

 *
 * @param properties the properties associatied with this event
 * @param properties.customDestinationPageName_: Name of the page or screen
 * @param properties.pageTags: Array of tags to describe the current page. Reporting will allow filtering by these tags.
 * @param properties.dealershipId: ID of a dealership
 * @param properties.vehicleVrm: vehicle vrm

 * @param properties.vehicleRmid: The unique iVendi vehicle identifier generated by feed processing or the stock module
 * @param properties.financeFunderCode: Unique funder abbreviation, normally 3 chars
 * @param properties.financeQuoteId: no description
 * @param properties.consumerId: no description
 * @param properties.pageProperties: no description
 * @param properties.vehicleImageUrl: no description
 * @param properties.quoteeId: Quotee UID in qw (not dealership_id but can be the same value)
 * @param properties.errorMessage: no description
 * @param properties.ivendiVehicleId: Our universal vehicle id (if available)
 * @param properties.vehicleVin: vehicle vin
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/ncStVUgD8_}
 */
export function pageView(properties: PageViewProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.pageTags !== undefined && properties.pageTags !== null ?
    eventPropertiesArray.push({id: "c9k6d2eImL", name: "page_tags", value: properties.pageTags}) :
    eventPropertiesArray.push({id: "c9k6d2eImL", name: "page_tags", value: null});
  properties.dealershipId !== undefined && properties.dealershipId !== null ?
    eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId}) :
    eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: null});
  properties.vehicleVrm !== undefined && properties.vehicleVrm !== null ?
    eventPropertiesArray.push({id: "-UqrurLDHx", name: "vehicle_vrm", value: properties.vehicleVrm}) :
    eventPropertiesArray.push({id: "-UqrurLDHx", name: "vehicle_vrm", value: null});
  properties.vehicleRmid !== undefined && properties.vehicleRmid !== null ?
    eventPropertiesArray.push({id: "4IBjIeN6Q", name: "vehicle_rmid", value: properties.vehicleRmid}) :
    eventPropertiesArray.push({id: "4IBjIeN6Q", name: "vehicle_rmid", value: null});
  properties.financeFunderCode !== undefined && properties.financeFunderCode !== null ?
    eventPropertiesArray.push({id: "W5d0ysKAhl", name: "finance_funder_code", value: properties.financeFunderCode}) :
    eventPropertiesArray.push({id: "W5d0ysKAhl", name: "finance_funder_code", value: null});
  properties.financeQuoteId !== undefined && properties.financeQuoteId !== null ?
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: properties.financeQuoteId}) :
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: null});
  properties.consumerId !== undefined && properties.consumerId !== null ?
    eventPropertiesArray.push({id: "0pZ76tErl", name: "consumer_id", value: properties.consumerId}) :
    eventPropertiesArray.push({id: "0pZ76tErl", name: "consumer_id", value: null});
  properties.pageProperties !== undefined && properties.pageProperties !== null ?
    eventPropertiesArray.push({id: "aLv0scxk0", name: "page_properties", value: properties.pageProperties}) :
    eventPropertiesArray.push({id: "aLv0scxk0", name: "page_properties", value: null});
  properties.vehicleImageUrl !== undefined && properties.vehicleImageUrl !== null ?
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: properties.vehicleImageUrl}) :
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: null});
  properties.quoteeId !== undefined && properties.quoteeId !== null ?
    eventPropertiesArray.push({id: "AFvAEWyBD", name: "quotee_id", value: properties.quoteeId}) :
    eventPropertiesArray.push({id: "AFvAEWyBD", name: "quotee_id", value: null});
  properties.errorMessage !== undefined && properties.errorMessage !== null ?
    eventPropertiesArray.push({id: "kBIayprrF6", name: "error_message", value: properties.errorMessage}) :
    eventPropertiesArray.push({id: "kBIayprrF6", name: "error_message", value: null});
  properties.ivendiVehicleId !== undefined && properties.ivendiVehicleId !== null ?
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: properties.ivendiVehicleId}) :
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: null});
  properties.vehicleVin !== undefined && properties.vehicleVin !== null ?
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: properties.vehicleVin}) :
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: null});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "ncStVUgD8_", "41b780bcfd7d01b83da0e26c965f65d3c7d5551ca455ad5ef72cc5f1a294cfd1", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("page_view", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("ncStVUgD8_", "page_view", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    // destination RudderstackDealerPlatformJs
    if (RudderstackDealerPlatformJs.logPage) {
      RudderstackDealerPlatformJs.logPage(properties.customDestinationPageName_, (Object as any).assign({}, eventProperties));
    } else {
      InternalAvoLogger.error('Page method is not implemented in the destination', '');
    }
  } else {
    // do nothing
  }
}

export interface OutboundLinkProperties {
  targetUrl: string;
  targetTags: string[] | null | undefined;
  dealershipId: string | null | undefined;
  vehicleVrm: string | null | undefined;
  vehicleRmid: string | null | undefined;
  financeFunderCode: string | null | undefined;
  financeQuoteId: string | null | undefined;
  consumerId: string | null | undefined;
  vehicleVin: string | null | undefined;
}
/**
 * outbound_link: Use to track outbound links from iVendi applications to other sites, such as lender terms and conditions.
 *
 * @param properties the properties associatied with this event
 * @param properties.targetUrl: URL being targetted
 * @param properties.targetTags: Array describing the target, things like where, what, anchor [ 'Motonovo', 'Terms & Conditions', '#privacy' ]
 * @param properties.dealershipId: ID of a dealership
 * @param properties.vehicleVrm: vehicle vrm

 * @param properties.vehicleRmid: The unique iVendi vehicle identifier generated by feed processing or the stock module
 * @param properties.financeFunderCode: Unique funder abbreviation, normally 3 chars
 * @param properties.financeQuoteId: no description
 * @param properties.consumerId: no description
 * @param properties.vehicleVin: vehicle vin
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/2_QEToU3sH}
 */
export function outboundLink(properties: OutboundLinkProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "DRVGwObiZ", name: "target_url", value: properties.targetUrl});
  properties.targetTags !== undefined && properties.targetTags !== null ?
    eventPropertiesArray.push({id: "xm_nT-q2-", name: "target_tags", value: properties.targetTags}) :
    eventPropertiesArray.push({id: "xm_nT-q2-", name: "target_tags", value: null});
  properties.dealershipId !== undefined && properties.dealershipId !== null ?
    eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId}) :
    eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: null});
  properties.vehicleVrm !== undefined && properties.vehicleVrm !== null ?
    eventPropertiesArray.push({id: "-UqrurLDHx", name: "vehicle_vrm", value: properties.vehicleVrm}) :
    eventPropertiesArray.push({id: "-UqrurLDHx", name: "vehicle_vrm", value: null});
  properties.vehicleRmid !== undefined && properties.vehicleRmid !== null ?
    eventPropertiesArray.push({id: "4IBjIeN6Q", name: "vehicle_rmid", value: properties.vehicleRmid}) :
    eventPropertiesArray.push({id: "4IBjIeN6Q", name: "vehicle_rmid", value: null});
  properties.financeFunderCode !== undefined && properties.financeFunderCode !== null ?
    eventPropertiesArray.push({id: "W5d0ysKAhl", name: "finance_funder_code", value: properties.financeFunderCode}) :
    eventPropertiesArray.push({id: "W5d0ysKAhl", name: "finance_funder_code", value: null});
  properties.financeQuoteId !== undefined && properties.financeQuoteId !== null ?
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: properties.financeQuoteId}) :
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: null});
  properties.consumerId !== undefined && properties.consumerId !== null ?
    eventPropertiesArray.push({id: "0pZ76tErl", name: "consumer_id", value: properties.consumerId}) :
    eventPropertiesArray.push({id: "0pZ76tErl", name: "consumer_id", value: null});
  properties.vehicleVin !== undefined && properties.vehicleVin !== null ?
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: properties.vehicleVin}) :
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: null});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "2_QEToU3sH", "dc5fbf4393cb3fcd7aa2108626b50f088458bf3d1128340784a28911b25ca004", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("outbound_link", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("2_QEToU3sH", "outbound_link", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("outbound_link", eventProperties, "2_QEToU3sH", "dc5fbf4393cb3fcd7aa2108626b50f088458bf3d1128340784a28911b25ca004");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("outbound_link", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FinanceProductSuitabilityContentViewedProperties {
  financeProductType: FinanceProductTypeValueType | null | undefined;
  dealershipId: string;
  financeFunderCode: string | null | undefined;
  financeFunderProductId: string | null | undefined;
  financeContentId: string;
  financeContentVersion: number;
  financeQuoteId: string | null | undefined;
}
/**
 * finance_product_suitability_content_viewed: Raised when the product suitability information content is retrieved from the content service and subsequently displayed.
 *
 * @param properties the properties associatied with this event
 * @param properties.financeProductType: What type of finance product is this. HP, PCP, CS
 * @param properties.dealershipId: ID of a dealership
 * @param properties.financeFunderCode: Unique funder abbreviation, normally 3 chars
 * @param properties.financeFunderProductId: ID of a product for a specific funder
 * @param properties.financeContentId: ID of content returned from the content service.
 * @param properties.financeContentVersion: Version of content returned from the content service
 * @param properties.financeQuoteId: no description
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/qLFgEy-PT4}
 */
export function financeProductSuitabilityContentViewed(
  properties: FinanceProductSuitabilityContentViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.financeProductType !== undefined && properties.financeProductType !== null ?
    eventPropertiesArray.push({id: "Np7P1YMiI", name: "finance_product_type", value: properties.financeProductType}) :
    eventPropertiesArray.push({id: "Np7P1YMiI", name: "finance_product_type", value: null});
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  properties.financeFunderCode !== undefined && properties.financeFunderCode !== null ?
    eventPropertiesArray.push({id: "W5d0ysKAhl", name: "finance_funder_code", value: properties.financeFunderCode}) :
    eventPropertiesArray.push({id: "W5d0ysKAhl", name: "finance_funder_code", value: null});
  properties.financeFunderProductId !== undefined && properties.financeFunderProductId !== null ?
    eventPropertiesArray.push({id: "9uvMTQi9T", name: "finance_funder_product_id", value: properties.financeFunderProductId}) :
    eventPropertiesArray.push({id: "9uvMTQi9T", name: "finance_funder_product_id", value: null});
  eventPropertiesArray.push({id: "cmtIeJfia", name: "finance_content_id", value: properties.financeContentId});
  eventPropertiesArray.push({id: "GDI9160Fi", name: "finance_content_version", value: properties.financeContentVersion});
  properties.financeQuoteId !== undefined && properties.financeQuoteId !== null ?
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: properties.financeQuoteId}) :
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: null});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "qLFgEy-PT4", "c59d1283a3df4e713b03893456c754f36f2cd66aff1731a410ec6d0306369488", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("finance_product_suitability_content_viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("qLFgEy-PT4", "finance_product_suitability_content_viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("finance_product_suitability_content_viewed", eventProperties, "qLFgEy-PT4", "c59d1283a3df4e713b03893456c754f36f2cd66aff1731a410ec6d0306369488");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("finance_product_suitability_content_viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FinanceProductSuitabilityVideoViewedProperties {
  financeProductType: FinanceProductTypeValueType | null | undefined;
  dealershipId: string;
  timeViewed: number;
  financeFunderCode: string | null | undefined;
  financeFunderProductId: string | null | undefined;
  financeQuoteId: string | null | undefined;
}
/**
 * finance_product_suitability_video_viewed: Raised after a product video view has completed, either fully or partially.
 *
 * @param properties the properties associatied with this event
 * @param properties.financeProductType: What type of finance product is this. HP, PCP, CS
 * @param properties.dealershipId: ID of a dealership
 * @param properties.timeViewed: How long was the video viewed for in milliseconds.
 * @param properties.financeFunderCode: Unique funder abbreviation, normally 3 chars
 * @param properties.financeFunderProductId: ID of a product for a specific funder
 * @param properties.financeQuoteId: no description
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/4UiAnkyFZP}
 */
export function financeProductSuitabilityVideoViewed(
  properties: FinanceProductSuitabilityVideoViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.financeProductType !== undefined && properties.financeProductType !== null ?
    eventPropertiesArray.push({id: "Np7P1YMiI", name: "finance_product_type", value: properties.financeProductType}) :
    eventPropertiesArray.push({id: "Np7P1YMiI", name: "finance_product_type", value: null});
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  eventPropertiesArray.push({id: "XL-IVjkQi", name: "time_viewed", value: properties.timeViewed});
  properties.financeFunderCode !== undefined && properties.financeFunderCode !== null ?
    eventPropertiesArray.push({id: "W5d0ysKAhl", name: "finance_funder_code", value: properties.financeFunderCode}) :
    eventPropertiesArray.push({id: "W5d0ysKAhl", name: "finance_funder_code", value: null});
  properties.financeFunderProductId !== undefined && properties.financeFunderProductId !== null ?
    eventPropertiesArray.push({id: "9uvMTQi9T", name: "finance_funder_product_id", value: properties.financeFunderProductId}) :
    eventPropertiesArray.push({id: "9uvMTQi9T", name: "finance_funder_product_id", value: null});
  properties.financeQuoteId !== undefined && properties.financeQuoteId !== null ?
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: properties.financeQuoteId}) :
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: null});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "4UiAnkyFZP", "61ffd9c51c24b58a5978aaec4d6889764751fabc161459ec034540457e38e502", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("finance_product_suitability_video_viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("4UiAnkyFZP", "finance_product_suitability_video_viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("finance_product_suitability_video_viewed", eventProperties, "4UiAnkyFZP", "61ffd9c51c24b58a5978aaec4d6889764751fabc161459ec034540457e38e502");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("finance_product_suitability_video_viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FinanceProductSuitabilityQuestionAndAnswerProperties {
  dealershipId: string;
  financeProductType: FinanceProductTypeValueType;
  productSuitabilityQuestion: string;
  productSuitabilityAnswer: string;
  financeFunderCode: string;
  financeFunderProductId: string;
  financeQuoteId: string | null | undefined;
}
/**
 * finance_product_suitability_question_and_answer: Raised when any product suitability question is answered.
 *
 * @param properties the properties associatied with this event
 * @param properties.dealershipId: ID of a dealership
 * @param properties.financeProductType: What type of finance product is this. HP, PCP, CS
 * @param properties.productSuitabilityQuestion: no description
 * @param properties.productSuitabilityAnswer: no description
 * @param properties.financeFunderCode: Unique funder abbreviation, normally 3 chars
 * @param properties.financeFunderProductId: ID of a product for a specific funder
 * @param properties.financeQuoteId: no description
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/WdFFJPNAUt}
 */
export function financeProductSuitabilityQuestionAndAnswer(
  properties: FinanceProductSuitabilityQuestionAndAnswerProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  eventPropertiesArray.push({id: "Np7P1YMiI", name: "finance_product_type", value: properties.financeProductType});
  eventPropertiesArray.push({id: "zLf_LD3O5", name: "product_suitability_question", value: properties.productSuitabilityQuestion});
  eventPropertiesArray.push({id: "7QTocOski", name: "product_suitability_answer", value: properties.productSuitabilityAnswer});
  eventPropertiesArray.push({id: "W5d0ysKAhl", name: "finance_funder_code", value: properties.financeFunderCode});
  eventPropertiesArray.push({id: "9uvMTQi9T", name: "finance_funder_product_id", value: properties.financeFunderProductId});
  properties.financeQuoteId !== undefined && properties.financeQuoteId !== null ?
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: properties.financeQuoteId}) :
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: null});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "WdFFJPNAUt", "b8fdf5436c7c4326982ce12465023c95a7483c88de2548f77bab3582ab84a0c4", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("finance_product_suitability_question_and_answer", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("WdFFJPNAUt", "finance_product_suitability_question_and_answer", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("finance_product_suitability_question_and_answer", eventProperties, "WdFFJPNAUt", "b8fdf5436c7c4326982ce12465023c95a7483c88de2548f77bab3582ab84a0c4");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("finance_product_suitability_question_and_answer", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FinanceProductSuitabilityVulnerabilityContentViewedProperties {
  financeProductType: FinanceProductTypeValueType;
  dealershipId: string;
  financeFunderCode: string;
  financeFunderProductId: string;
  financeContentId: string;
  financeContentVersion: number;
  financeQuoteId: string | null | undefined;
}
/**
 * finance_product_suitability_vulnerability_content_viewed: Raised when the product suitability vulnerability information is displayed.
 *
 * @param properties the properties associatied with this event
 * @param properties.financeProductType: What type of finance product is this. HP, PCP, CS
 * @param properties.dealershipId: ID of a dealership
 * @param properties.financeFunderCode: Unique funder abbreviation, normally 3 chars
 * @param properties.financeFunderProductId: ID of a product for a specific funder
 * @param properties.financeContentId: ID of content returned from the content service.
 * @param properties.financeContentVersion: Version of content returned from the content service
 * @param properties.financeQuoteId: no description
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/cqPHKtTvp}
 */
export function financeProductSuitabilityVulnerabilityContentViewed(
  properties: FinanceProductSuitabilityVulnerabilityContentViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "Np7P1YMiI", name: "finance_product_type", value: properties.financeProductType});
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  eventPropertiesArray.push({id: "W5d0ysKAhl", name: "finance_funder_code", value: properties.financeFunderCode});
  eventPropertiesArray.push({id: "9uvMTQi9T", name: "finance_funder_product_id", value: properties.financeFunderProductId});
  eventPropertiesArray.push({id: "cmtIeJfia", name: "finance_content_id", value: properties.financeContentId});
  eventPropertiesArray.push({id: "GDI9160Fi", name: "finance_content_version", value: properties.financeContentVersion});
  properties.financeQuoteId !== undefined && properties.financeQuoteId !== null ?
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: properties.financeQuoteId}) :
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: null});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "cqPHKtTvp", "94972b926b76a685355cb38a0355606fa5256d085dc800f85284fb1058a93e1d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("finance_product_suitability_vulnerability_content_viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("cqPHKtTvp", "finance_product_suitability_vulnerability_content_viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("finance_product_suitability_vulnerability_content_viewed", eventProperties, "cqPHKtTvp", "94972b926b76a685355cb38a0355606fa5256d085dc800f85284fb1058a93e1d");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("finance_product_suitability_vulnerability_content_viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FinanceProductSuitabilityVulnerabilityOutboundLinkProperties {
  financeProductType: FinanceProductTypeValueType;
  dealershipId: string;
  targetUrl: string;
  targetTags: string[];
  financeFunderCode: string;
  financeFunderProductId: string;
  financeQuoteId: string | null | undefined;
}
/**
 * finance_product_suitability_vulnerability_outbound_link: Raised when an outbound link is followed from product suitability vulnerability information.
 *
 * @param properties the properties associatied with this event
 * @param properties.financeProductType: What type of finance product is this. HP, PCP, CS
 * @param properties.dealershipId: ID of a dealership
 * @param properties.targetUrl: URL being targetted
 * @param properties.targetTags: Array describing the target, things like where, what, anchor [ 'Motonovo', 'Terms & Conditions', '#privacy' ]
 * @param properties.financeFunderCode: Unique funder abbreviation, normally 3 chars
 * @param properties.financeFunderProductId: ID of a product for a specific funder
 * @param properties.financeQuoteId: no description
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/iBk8oBda3}
 */
export function financeProductSuitabilityVulnerabilityOutboundLink(
  properties: FinanceProductSuitabilityVulnerabilityOutboundLinkProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "Np7P1YMiI", name: "finance_product_type", value: properties.financeProductType});
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  eventPropertiesArray.push({id: "DRVGwObiZ", name: "target_url", value: properties.targetUrl});
  eventPropertiesArray.push({id: "xm_nT-q2-", name: "target_tags", value: properties.targetTags});
  eventPropertiesArray.push({id: "W5d0ysKAhl", name: "finance_funder_code", value: properties.financeFunderCode});
  eventPropertiesArray.push({id: "9uvMTQi9T", name: "finance_funder_product_id", value: properties.financeFunderProductId});
  properties.financeQuoteId !== undefined && properties.financeQuoteId !== null ?
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: properties.financeQuoteId}) :
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: null});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "iBk8oBda3", "6475153550147bc53d7c9459374e373cde604b45e13ddc861f225c9a647eb6c1", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("finance_product_suitability_vulnerability_outbound_link", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("iBk8oBda3", "finance_product_suitability_vulnerability_outbound_link", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("finance_product_suitability_vulnerability_outbound_link", eventProperties, "iBk8oBda3", "6475153550147bc53d7c9459374e373cde604b45e13ddc861f225c9a647eb6c1");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("finance_product_suitability_vulnerability_outbound_link", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FinanceProductLearnMoreOpenedProperties {
  dealershipId: string;
}
/**
 * finance_product_learn_more_opened: The "unsure - learn more" section on the calc widget is opened.
 *
 * When to trigger this event:
 * 1.
 * View in Avo: https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/qWhbEAGRD-/trigger/F9INW7Uvl
 *
 * @param properties the properties associatied with this event
 * @param properties.dealershipId: ID of a dealership
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/qWhbEAGRD-}
 */
export function financeProductLearnMoreOpened(
  properties: FinanceProductLearnMoreOpenedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "qWhbEAGRD-", "5575a17a73ca17c251ecc4c5d7d548dd6d0bf8223acf545d338f284e3a0afed7", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("finance_product_learn_more_opened", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("qWhbEAGRD-", "finance_product_learn_more_opened", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("finance_product_learn_more_opened", eventProperties, "qWhbEAGRD-", "5575a17a73ca17c251ecc4c5d7d548dd6d0bf8223acf545d338f284e3a0afed7");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("finance_product_learn_more_opened", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FinanceProductLearnMoreSubSectionOpenedProperties {
  dealershipId: string;
  learnMoreSubSectionTags: string[];
}
/**
 * finance_product_learn_more_sub_section_opened: A sub section of the "unsure - learn more" section is opened.
 *
 * @param properties the properties associatied with this event
 * @param properties.dealershipId: ID of a dealership
 * @param properties.learnMoreSubSectionTags: Tags to describe the learn more sub section being tracked. These will be used for reporting purposes.
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/8KKSwnoKH6}
 */
export function financeProductLearnMoreSubSectionOpened(
  properties: FinanceProductLearnMoreSubSectionOpenedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  eventPropertiesArray.push({id: "1VZX2gema", name: "learn_more_sub_section_tags", value: properties.learnMoreSubSectionTags});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "8KKSwnoKH6", "bed553975eabae3bb76a7476ed75308524cea97ecf5b76268030173fcb923150", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("finance_product_learn_more_sub_section_opened", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("8KKSwnoKH6", "finance_product_learn_more_sub_section_opened", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("finance_product_learn_more_sub_section_opened", eventProperties, "8KKSwnoKH6", "bed553975eabae3bb76a7476ed75308524cea97ecf5b76268030173fcb923150");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("finance_product_learn_more_sub_section_opened", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface VehicleFinanceApplicationStartedProperties {
  dealershipId: string;
  financeFunderCode: string;
  financeFunderProductId: string;
  financeProductType: FinanceProductTypeValueType;
  financeApplicationId: string;
}
/**
 * vehicle_finance_application_started: The application form for vehicle finance has been opened. This is required to report application starts for consumer duty as there is no equivalent server side event to follow.
 *
 * @param properties the properties associatied with this event
 * @param properties.dealershipId: ID of a dealership
 * @param properties.financeFunderCode: Unique funder abbreviation, normally 3 chars
 * @param properties.financeFunderProductId: ID of a product for a specific funder
 * @param properties.financeProductType: What type of finance product is this. HP, PCP, CS
 * @param properties.financeApplicationId: no description
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/ovJ9FHjdg0}
 */
export function vehicleFinanceApplicationStarted(
  properties: VehicleFinanceApplicationStartedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  eventPropertiesArray.push({id: "W5d0ysKAhl", name: "finance_funder_code", value: properties.financeFunderCode});
  eventPropertiesArray.push({id: "9uvMTQi9T", name: "finance_funder_product_id", value: properties.financeFunderProductId});
  eventPropertiesArray.push({id: "Np7P1YMiI", name: "finance_product_type", value: properties.financeProductType});
  eventPropertiesArray.push({id: "eN3-O2L4tX", name: "finance_application_id", value: properties.financeApplicationId});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "ovJ9FHjdg0", "e258cf2e891a1694f5bf17519406ee3c40fc45bdd99f2c707f52ed31dd64ffdd", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("vehicle_finance_application_started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("ovJ9FHjdg0", "vehicle_finance_application_started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("vehicle_finance_application_started", eventProperties, "ovJ9FHjdg0", "e258cf2e891a1694f5bf17519406ee3c40fc45bdd99f2c707f52ed31dd64ffdd");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("vehicle_finance_application_started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FinanceQuoteApplySelectedProperties {
  vehicleVrm: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleVin: string | null | undefined;
  ivendiVehicleId: string | null | undefined;
  vehicleTaxonomySource: string | null | undefined;
  vehicleTaxonomyCountry: string | null | undefined;
  vehicleTaxonomyCode: string | null | undefined;
  vehicleClass: string;
  vehicleOemCodes: string[] | null | undefined;
  vehicleDerivative: string | null | undefined;
  vehicleFuel: string | null | undefined;
  vehicleDoors: string | null | undefined;
  vehicleCondition: string | null | undefined;
  vehicleImageUrl: string | null | undefined;
  vehicleAccuracyScore: string | null | undefined;
  algoliaObjectId: string | null | undefined;
  vehiclePrice: number;
  vehicleEngineSize: string | null | undefined;
  vehicleMileage: number;
  financeQuoteId: string | null | undefined;
  financeQuotePosition: number;
  financeFunderCode: string;
  financeQuoteProductType: string;
  financeQuoteTerm: number;
  financeQuoteMileage: number;
  financeQuotesCount: number;
  financeQuotesSortField: string;
  financeQuotesSortDirection: string;
  financeQuoteProductId: string;
  financeQuoteMonthlyPayment: number;
  dealershipId: string;
  financeQuoteTotalDeposit: number;
  eventTrigger: string;
  eventTriggerText: string;
  financeQuoteJourney: FinanceQuoteJourneyValueType[] | null | undefined;
}
/**
 * finance_quote_apply_selected: When clicking the widget
apply button
 *
 * @param properties the properties associatied with this event
 * @param properties.vehicleVrm: vehicle vrm

 * @param properties.vehicleMake: Vehicle Make
 * @param properties.vehicleModel: Vehicle Model
 * @param properties.vehicleVin: vehicle vin
 * @param properties.ivendiVehicleId: Our universal vehicle id (if available)
 * @param properties.vehicleTaxonomySource: no description
 * @param properties.vehicleTaxonomyCountry: no description
 * @param properties.vehicleTaxonomyCode: no description
 * @param properties.vehicleClass: no description
 * @param properties.vehicleOemCodes: no description
 * @param properties.vehicleDerivative: no description
 * @param properties.vehicleFuel: no description
 * @param properties.vehicleDoors: no description
 * @param properties.vehicleCondition: no description
 * @param properties.vehicleImageUrl: no description
 * @param properties.vehicleAccuracyScore: no description
 * @param properties.algoliaObjectId: Algolia's unique id for a vehicle search document
 * @param properties.vehiclePrice: Vehicle Price
 * @param properties.vehicleEngineSize: no description
 * @param properties.vehicleMileage: no description
 * @param properties.financeQuoteId: no description
 * @param properties.financeQuotePosition: no description
 * @param properties.financeFunderCode: Unique funder abbreviation, normally 3 chars
 * @param properties.financeQuoteProductType: no description
 * @param properties.financeQuoteTerm: no description
 * @param properties.financeQuoteMileage: no description
 * @param properties.financeQuotesCount: no description
 * @param properties.financeQuotesSortField: no description
 * @param properties.financeQuotesSortDirection: no description
 * @param properties.financeQuoteProductId: no description
 * @param properties.financeQuoteMonthlyPayment: no description
 * @param properties.dealershipId: ID of a dealership
 * @param properties.financeQuoteTotalDeposit: no description
 * @param properties.eventTrigger: no description
 * @param properties.eventTriggerText: no description
 * @param properties.financeQuoteJourney: no description
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/3E4f2kGZ3a}
 */
export function financeQuoteApplySelected(
  properties: FinanceQuoteApplySelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "-UqrurLDHx", name: "vehicle_vrm", value: properties.vehicleVrm});
  eventPropertiesArray.push({id: "O9E-Dnhdqz", name: "vehicle_make", value: properties.vehicleMake});
  eventPropertiesArray.push({id: "aTRNhI5D9F", name: "vehicle_model", value: properties.vehicleModel});
  properties.vehicleVin !== undefined && properties.vehicleVin !== null ?
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: properties.vehicleVin}) :
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: null});
  properties.ivendiVehicleId !== undefined && properties.ivendiVehicleId !== null ?
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: properties.ivendiVehicleId}) :
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: null});
  properties.vehicleTaxonomySource !== undefined && properties.vehicleTaxonomySource !== null ?
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: properties.vehicleTaxonomySource}) :
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: null});
  properties.vehicleTaxonomyCountry !== undefined && properties.vehicleTaxonomyCountry !== null ?
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: properties.vehicleTaxonomyCountry}) :
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: null});
  properties.vehicleTaxonomyCode !== undefined && properties.vehicleTaxonomyCode !== null ?
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: properties.vehicleTaxonomyCode}) :
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: null});
  eventPropertiesArray.push({id: "YAMqZFa6Tg", name: "vehicle_class", value: properties.vehicleClass});
  properties.vehicleOemCodes !== undefined && properties.vehicleOemCodes !== null ?
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: properties.vehicleOemCodes}) :
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: null});
  properties.vehicleDerivative !== undefined && properties.vehicleDerivative !== null ?
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: properties.vehicleDerivative}) :
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: null});
  properties.vehicleFuel !== undefined && properties.vehicleFuel !== null ?
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: properties.vehicleFuel}) :
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: null});
  properties.vehicleDoors !== undefined && properties.vehicleDoors !== null ?
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: properties.vehicleDoors}) :
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: null});
  properties.vehicleCondition !== undefined && properties.vehicleCondition !== null ?
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: properties.vehicleCondition}) :
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: null});
  properties.vehicleImageUrl !== undefined && properties.vehicleImageUrl !== null ?
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: properties.vehicleImageUrl}) :
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: null});
  properties.vehicleAccuracyScore !== undefined && properties.vehicleAccuracyScore !== null ?
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: properties.vehicleAccuracyScore}) :
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: null});
  properties.algoliaObjectId !== undefined && properties.algoliaObjectId !== null ?
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: properties.algoliaObjectId}) :
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: null});
  eventPropertiesArray.push({id: "pwOlscPL2G", name: "vehicle_price", value: properties.vehiclePrice});
  properties.vehicleEngineSize !== undefined && properties.vehicleEngineSize !== null ?
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: properties.vehicleEngineSize}) :
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: null});
  eventPropertiesArray.push({id: "GA16odh9aI", name: "vehicle_mileage", value: properties.vehicleMileage});
  properties.financeQuoteId !== undefined && properties.financeQuoteId !== null ?
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: properties.financeQuoteId}) :
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: null});
  eventPropertiesArray.push({id: "xlouzGxs_v", name: "finance_quote_position", value: properties.financeQuotePosition});
  eventPropertiesArray.push({id: "W5d0ysKAhl", name: "finance_funder_code", value: properties.financeFunderCode});
  eventPropertiesArray.push({id: "n8ldXLB0MP", name: "finance_quote_product_type", value: properties.financeQuoteProductType});
  eventPropertiesArray.push({id: "_tCUrC7oQ_", name: "finance_quote_term", value: properties.financeQuoteTerm});
  eventPropertiesArray.push({id: "YnOi5eICfz", name: "finance_quote_mileage", value: properties.financeQuoteMileage});
  eventPropertiesArray.push({id: "rzsGNxNUy", name: "finance_quotes_count", value: properties.financeQuotesCount});
  eventPropertiesArray.push({id: "o-zy4pgbp", name: "finance_quotes_sort_field", value: properties.financeQuotesSortField});
  eventPropertiesArray.push({id: "ItBAagbHN", name: "finance_quotes_sort_direction", value: properties.financeQuotesSortDirection});
  eventPropertiesArray.push({id: "rQirtqK4s", name: "finance_quote_product_id", value: properties.financeQuoteProductId});
  eventPropertiesArray.push({id: "Zpmf1g7SW", name: "finance_quote_monthly_payment", value: properties.financeQuoteMonthlyPayment});
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  eventPropertiesArray.push({id: "CFrCMFZMO", name: "finance_quote_total_deposit", value: properties.financeQuoteTotalDeposit});
  eventPropertiesArray.push({id: "4uruCn5_P", name: "event_trigger", value: properties.eventTrigger});
  eventPropertiesArray.push({id: "B2P7Wzkjk", name: "event_trigger_text", value: properties.eventTriggerText});
  properties.financeQuoteJourney !== undefined && properties.financeQuoteJourney !== null ?
    eventPropertiesArray.push({id: "HKMUJOv84", name: "finance_quote_journey", value: properties.financeQuoteJourney}) :
    eventPropertiesArray.push({id: "HKMUJOv84", name: "finance_quote_journey", value: null});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "3E4f2kGZ3a", "4557adf1570df4eac2ce1ef020517509225400c0b7cefe05ff8d320490bc870b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("finance_quote_apply_selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("3E4f2kGZ3a", "finance_quote_apply_selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("finance_quote_apply_selected", eventProperties, "3E4f2kGZ3a", "4557adf1570df4eac2ce1ef020517509225400c0b7cefe05ff8d320490bc870b");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("finance_quote_apply_selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FinanceQuotesDisplayedProperties {
  vehicleVrm: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleVin: string | null | undefined;
  ivendiVehicleId: string | null | undefined;
  vehicleTaxonomySource: string | null | undefined;
  vehicleTaxonomyCountry: string | null | undefined;
  vehicleTaxonomyCode: string | null | undefined;
  vehicleClass: string;
  vehicleOemCodes: string[] | null | undefined;
  vehicleDerivative: string | null | undefined;
  vehicleFuel: string | null | undefined;
  vehicleDoors: string | null | undefined;
  vehicleCondition: string | null | undefined;
  vehicleImageUrl: string | null | undefined;
  vehicleAccuracyScore: string | null | undefined;
  algoliaObjectId: string | null | undefined;
  vehiclePrice: number;
  vehicleEngineSize: string | null | undefined;
  vehicleMileage: number | null | undefined;
  financeFunderCodesList: string[];
  financeMonthlyPaymentsList: number[];
  financeProductTypesList: string[];
  dealershipId: string;
  financeQuoteCashDeposit: number;
  financeQuoteTerm: number;
  financeQuoteMileage: number;
  consumerPersonalisedQuote: boolean;
  financeProductIdsList: string[];
  financeQuotesCount: number;
  financeRepresentativeAprsList: number[];
  financeTermsList: number[];
  financeQuoteIdsList: string[];
  financeProductNamesList: string[];
  consumerCreditTier: string | null | undefined;
  consumerEntityType: ConsumerEntityTypeValueType;
  financeQuoteBalloonPayment: number;
}
/**
 * finance_quotes_displayed: Widget has recieved quotes from quoteware, audits the display order
 *
 * @param properties the properties associatied with this event
 * @param properties.vehicleVrm: vehicle vrm

 * @param properties.vehicleMake: Vehicle Make
 * @param properties.vehicleModel: Vehicle Model
 * @param properties.vehicleVin: vehicle vin
 * @param properties.ivendiVehicleId: Our universal vehicle id (if available)
 * @param properties.vehicleTaxonomySource: no description
 * @param properties.vehicleTaxonomyCountry: no description
 * @param properties.vehicleTaxonomyCode: no description
 * @param properties.vehicleClass: no description
 * @param properties.vehicleOemCodes: no description
 * @param properties.vehicleDerivative: no description
 * @param properties.vehicleFuel: no description
 * @param properties.vehicleDoors: no description
 * @param properties.vehicleCondition: no description
 * @param properties.vehicleImageUrl: no description
 * @param properties.vehicleAccuracyScore: no description
 * @param properties.algoliaObjectId: Algolia's unique id for a vehicle search document
 * @param properties.vehiclePrice: Vehicle Price
 * @param properties.vehicleEngineSize: no description
 * @param properties.vehicleMileage: no description
 * @param properties.financeFunderCodesList: no description
 * @param properties.financeMonthlyPaymentsList: no description
 * @param properties.financeProductTypesList: no description
 * @param properties.dealershipId: ID of a dealership
 * @param properties.financeQuoteCashDeposit: no description
 * @param properties.financeQuoteTerm: no description
 * @param properties.financeQuoteMileage: no description
 * @param properties.consumerPersonalisedQuote: no description
 * @param properties.financeProductIdsList: no description
 * @param properties.financeQuotesCount: no description
 * @param properties.financeRepresentativeAprsList: no description
 * @param properties.financeTermsList: no description
 * @param properties.financeQuoteIdsList: no description
 * @param properties.financeProductNamesList: no description
 * @param properties.consumerCreditTier: no description
 * @param properties.consumerEntityType: no description
 * @param properties.financeQuoteBalloonPayment: no description
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/Z4rZtdczM8}
 */
export function financeQuotesDisplayed(
  properties: FinanceQuotesDisplayedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "-UqrurLDHx", name: "vehicle_vrm", value: properties.vehicleVrm});
  eventPropertiesArray.push({id: "O9E-Dnhdqz", name: "vehicle_make", value: properties.vehicleMake});
  eventPropertiesArray.push({id: "aTRNhI5D9F", name: "vehicle_model", value: properties.vehicleModel});
  properties.vehicleVin !== undefined && properties.vehicleVin !== null ?
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: properties.vehicleVin}) :
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: null});
  properties.ivendiVehicleId !== undefined && properties.ivendiVehicleId !== null ?
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: properties.ivendiVehicleId}) :
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: null});
  properties.vehicleTaxonomySource !== undefined && properties.vehicleTaxonomySource !== null ?
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: properties.vehicleTaxonomySource}) :
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: null});
  properties.vehicleTaxonomyCountry !== undefined && properties.vehicleTaxonomyCountry !== null ?
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: properties.vehicleTaxonomyCountry}) :
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: null});
  properties.vehicleTaxonomyCode !== undefined && properties.vehicleTaxonomyCode !== null ?
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: properties.vehicleTaxonomyCode}) :
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: null});
  eventPropertiesArray.push({id: "YAMqZFa6Tg", name: "vehicle_class", value: properties.vehicleClass});
  properties.vehicleOemCodes !== undefined && properties.vehicleOemCodes !== null ?
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: properties.vehicleOemCodes}) :
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: null});
  properties.vehicleDerivative !== undefined && properties.vehicleDerivative !== null ?
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: properties.vehicleDerivative}) :
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: null});
  properties.vehicleFuel !== undefined && properties.vehicleFuel !== null ?
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: properties.vehicleFuel}) :
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: null});
  properties.vehicleDoors !== undefined && properties.vehicleDoors !== null ?
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: properties.vehicleDoors}) :
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: null});
  properties.vehicleCondition !== undefined && properties.vehicleCondition !== null ?
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: properties.vehicleCondition}) :
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: null});
  properties.vehicleImageUrl !== undefined && properties.vehicleImageUrl !== null ?
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: properties.vehicleImageUrl}) :
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: null});
  properties.vehicleAccuracyScore !== undefined && properties.vehicleAccuracyScore !== null ?
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: properties.vehicleAccuracyScore}) :
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: null});
  properties.algoliaObjectId !== undefined && properties.algoliaObjectId !== null ?
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: properties.algoliaObjectId}) :
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: null});
  eventPropertiesArray.push({id: "pwOlscPL2G", name: "vehicle_price", value: properties.vehiclePrice});
  properties.vehicleEngineSize !== undefined && properties.vehicleEngineSize !== null ?
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: properties.vehicleEngineSize}) :
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: null});
  properties.vehicleMileage !== undefined && properties.vehicleMileage !== null ?
    eventPropertiesArray.push({id: "GA16odh9aI", name: "vehicle_mileage", value: properties.vehicleMileage}) :
    eventPropertiesArray.push({id: "GA16odh9aI", name: "vehicle_mileage", value: null});
  eventPropertiesArray.push({id: "I1V3qhuCDa", name: "finance_funder_codes_list", value: properties.financeFunderCodesList});
  eventPropertiesArray.push({id: "IUHv20HQ2E", name: "finance_monthly_payments_list", value: properties.financeMonthlyPaymentsList});
  eventPropertiesArray.push({id: "DoyB5sr86Z", name: "finance_product_types_list", value: properties.financeProductTypesList});
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  eventPropertiesArray.push({id: "OHS68QoVHU", name: "finance_quote_cash_deposit", value: properties.financeQuoteCashDeposit});
  eventPropertiesArray.push({id: "_tCUrC7oQ_", name: "finance_quote_term", value: properties.financeQuoteTerm});
  eventPropertiesArray.push({id: "YnOi5eICfz", name: "finance_quote_mileage", value: properties.financeQuoteMileage});
  eventPropertiesArray.push({id: "kyJyZiLjEp", name: "consumer_personalised_quote", value: properties.consumerPersonalisedQuote});
  eventPropertiesArray.push({id: "8Z3Vb8xPq", name: "finance_product_ids_list", value: properties.financeProductIdsList});
  eventPropertiesArray.push({id: "rzsGNxNUy", name: "finance_quotes_count", value: properties.financeQuotesCount});
  eventPropertiesArray.push({id: "yCLZhsptI", name: "finance_representative_aprs_list", value: properties.financeRepresentativeAprsList});
  eventPropertiesArray.push({id: "m7TW52BoK", name: "finance_terms_list", value: properties.financeTermsList});
  eventPropertiesArray.push({id: "dNnRYY6Ow", name: "finance_quote_ids_list", value: properties.financeQuoteIdsList});
  eventPropertiesArray.push({id: "Q3l6d8UEg", name: "finance_product_names_list", value: properties.financeProductNamesList});
  properties.consumerCreditTier !== undefined && properties.consumerCreditTier !== null ?
    eventPropertiesArray.push({id: "SQdo3NMqT", name: "consumer_credit_tier", value: properties.consumerCreditTier}) :
    eventPropertiesArray.push({id: "SQdo3NMqT", name: "consumer_credit_tier", value: null});
  eventPropertiesArray.push({id: "2fEnYW22M", name: "consumer_entity_type", value: properties.consumerEntityType});
  eventPropertiesArray.push({id: "vj7XqZP7z", name: "finance_quote_balloon_payment", value: properties.financeQuoteBalloonPayment});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Z4rZtdczM8", "c704c4b9e0182b55df8873299da993d7e789f716ac9fe6139be3a76b81e6fc23", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("finance_quotes_displayed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Z4rZtdczM8", "finance_quotes_displayed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("finance_quotes_displayed", eventProperties, "Z4rZtdczM8", "c704c4b9e0182b55df8873299da993d7e789f716ac9fe6139be3a76b81e6fc23");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("finance_quotes_displayed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FinanceQuotesFailedProperties {
  vehicleVrm: string | null | undefined;
  vehicleMake: string | null | undefined;
  vehicleModel: string | null | undefined;
  vehicleVin: string | null | undefined;
  ivendiVehicleId: string | null | undefined;
  vehicleTaxonomySource: string | null | undefined;
  vehicleTaxonomyCountry: string | null | undefined;
  vehicleTaxonomyCode: string | null | undefined;
  vehicleClass: string | null | undefined;
  vehicleOemCodes: string[] | null | undefined;
  vehicleDerivative: string | null | undefined;
  vehicleFuel: string | null | undefined;
  vehicleDoors: string | null | undefined;
  vehicleCondition: string | null | undefined;
  vehicleImageUrl: string | null | undefined;
  vehicleAccuracyScore: string | null | undefined;
  algoliaObjectId: string | null | undefined;
  vehiclePrice: number;
  vehicleEngineSize: string | null | undefined;
  vehicleMileage: number;
  dealershipId: string | null | undefined;
  financeQuoteMileage: number;
  financeQuoteTerm: number | null | undefined;
  financeQuoteCashDeposit: number;
  financeQuoteErrorMessage: string | null | undefined;
  financeQuoteBalloonPayment: number;
  financeQuoteJourney: FinanceQuoteJourneyValueType[];
}
/**
 * finance_quotes_failed: Widget quote but it fails to provide figures
 *
 * @param properties the properties associatied with this event
 * @param properties.vehicleVrm: vehicle vrm

 * @param properties.vehicleMake: Vehicle Make
 * @param properties.vehicleModel: Vehicle Model
 * @param properties.vehicleVin: vehicle vin
 * @param properties.ivendiVehicleId: Our universal vehicle id (if available)
 * @param properties.vehicleTaxonomySource: no description
 * @param properties.vehicleTaxonomyCountry: no description
 * @param properties.vehicleTaxonomyCode: no description
 * @param properties.vehicleClass: no description
 * @param properties.vehicleOemCodes: no description
 * @param properties.vehicleDerivative: no description
 * @param properties.vehicleFuel: no description
 * @param properties.vehicleDoors: no description
 * @param properties.vehicleCondition: no description
 * @param properties.vehicleImageUrl: no description
 * @param properties.vehicleAccuracyScore: no description
 * @param properties.algoliaObjectId: Algolia's unique id for a vehicle search document
 * @param properties.vehiclePrice: Vehicle Price
 * @param properties.vehicleEngineSize: no description
 * @param properties.vehicleMileage: no description
 * @param properties.dealershipId: ID of a dealership
 * @param properties.financeQuoteMileage: no description
 * @param properties.financeQuoteTerm: no description
 * @param properties.financeQuoteCashDeposit: no description
 * @param properties.financeQuoteErrorMessage: no description
 * @param properties.financeQuoteBalloonPayment: no description
 * @param properties.financeQuoteJourney: no description
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/9l4tJ9jdg}
 */
export function financeQuotesFailed(properties: FinanceQuotesFailedProperties
  ) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.vehicleVrm !== undefined && properties.vehicleVrm !== null ?
    eventPropertiesArray.push({id: "-UqrurLDHx", name: "vehicle_vrm", value: properties.vehicleVrm}) :
    eventPropertiesArray.push({id: "-UqrurLDHx", name: "vehicle_vrm", value: null});
  properties.vehicleMake !== undefined && properties.vehicleMake !== null ?
    eventPropertiesArray.push({id: "O9E-Dnhdqz", name: "vehicle_make", value: properties.vehicleMake}) :
    eventPropertiesArray.push({id: "O9E-Dnhdqz", name: "vehicle_make", value: null});
  properties.vehicleModel !== undefined && properties.vehicleModel !== null ?
    eventPropertiesArray.push({id: "aTRNhI5D9F", name: "vehicle_model", value: properties.vehicleModel}) :
    eventPropertiesArray.push({id: "aTRNhI5D9F", name: "vehicle_model", value: null});
  properties.vehicleVin !== undefined && properties.vehicleVin !== null ?
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: properties.vehicleVin}) :
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: null});
  properties.ivendiVehicleId !== undefined && properties.ivendiVehicleId !== null ?
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: properties.ivendiVehicleId}) :
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: null});
  properties.vehicleTaxonomySource !== undefined && properties.vehicleTaxonomySource !== null ?
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: properties.vehicleTaxonomySource}) :
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: null});
  properties.vehicleTaxonomyCountry !== undefined && properties.vehicleTaxonomyCountry !== null ?
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: properties.vehicleTaxonomyCountry}) :
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: null});
  properties.vehicleTaxonomyCode !== undefined && properties.vehicleTaxonomyCode !== null ?
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: properties.vehicleTaxonomyCode}) :
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: null});
  properties.vehicleClass !== undefined && properties.vehicleClass !== null ?
    eventPropertiesArray.push({id: "YAMqZFa6Tg", name: "vehicle_class", value: properties.vehicleClass}) :
    eventPropertiesArray.push({id: "YAMqZFa6Tg", name: "vehicle_class", value: null});
  properties.vehicleOemCodes !== undefined && properties.vehicleOemCodes !== null ?
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: properties.vehicleOemCodes}) :
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: null});
  properties.vehicleDerivative !== undefined && properties.vehicleDerivative !== null ?
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: properties.vehicleDerivative}) :
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: null});
  properties.vehicleFuel !== undefined && properties.vehicleFuel !== null ?
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: properties.vehicleFuel}) :
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: null});
  properties.vehicleDoors !== undefined && properties.vehicleDoors !== null ?
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: properties.vehicleDoors}) :
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: null});
  properties.vehicleCondition !== undefined && properties.vehicleCondition !== null ?
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: properties.vehicleCondition}) :
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: null});
  properties.vehicleImageUrl !== undefined && properties.vehicleImageUrl !== null ?
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: properties.vehicleImageUrl}) :
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: null});
  properties.vehicleAccuracyScore !== undefined && properties.vehicleAccuracyScore !== null ?
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: properties.vehicleAccuracyScore}) :
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: null});
  properties.algoliaObjectId !== undefined && properties.algoliaObjectId !== null ?
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: properties.algoliaObjectId}) :
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: null});
  eventPropertiesArray.push({id: "pwOlscPL2G", name: "vehicle_price", value: properties.vehiclePrice});
  properties.vehicleEngineSize !== undefined && properties.vehicleEngineSize !== null ?
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: properties.vehicleEngineSize}) :
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: null});
  eventPropertiesArray.push({id: "GA16odh9aI", name: "vehicle_mileage", value: properties.vehicleMileage});
  properties.dealershipId !== undefined && properties.dealershipId !== null ?
    eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId}) :
    eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: null});
  eventPropertiesArray.push({id: "YnOi5eICfz", name: "finance_quote_mileage", value: properties.financeQuoteMileage});
  properties.financeQuoteTerm !== undefined && properties.financeQuoteTerm !== null ?
    eventPropertiesArray.push({id: "_tCUrC7oQ_", name: "finance_quote_term", value: properties.financeQuoteTerm}) :
    eventPropertiesArray.push({id: "_tCUrC7oQ_", name: "finance_quote_term", value: null});
  eventPropertiesArray.push({id: "OHS68QoVHU", name: "finance_quote_cash_deposit", value: properties.financeQuoteCashDeposit});
  properties.financeQuoteErrorMessage !== undefined && properties.financeQuoteErrorMessage !== null ?
    eventPropertiesArray.push({id: "1cwZsJtXW", name: "finance_quote_error_message", value: properties.financeQuoteErrorMessage}) :
    eventPropertiesArray.push({id: "1cwZsJtXW", name: "finance_quote_error_message", value: null});
  eventPropertiesArray.push({id: "vj7XqZP7z", name: "finance_quote_balloon_payment", value: properties.financeQuoteBalloonPayment});
  eventPropertiesArray.push({id: "HKMUJOv84", name: "finance_quote_journey", value: properties.financeQuoteJourney});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "9l4tJ9jdg", "69d6074166026f5408964df610dd2dd2651819351b9ce32655825471cc951bdd", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("finance_quotes_failed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("9l4tJ9jdg", "finance_quotes_failed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("finance_quotes_failed", eventProperties, "9l4tJ9jdg", "69d6074166026f5408964df610dd2dd2651819351b9ce32655825471cc951bdd");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("finance_quotes_failed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FinanceQuoteEligibilitySelectedProperties {
  vehicleVrm: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleVin: string | null | undefined;
  ivendiVehicleId: string | null | undefined;
  vehicleTaxonomySource: string | null | undefined;
  vehicleTaxonomyCountry: string | null | undefined;
  vehicleTaxonomyCode: string | null | undefined;
  vehicleClass: string;
  vehicleOemCodes: string[] | null | undefined;
  vehicleDerivative: string | null | undefined;
  vehicleFuel: string | null | undefined;
  vehicleDoors: string | null | undefined;
  vehicleCondition: string | null | undefined;
  vehicleImageUrl: string | null | undefined;
  vehicleAccuracyScore: string | null | undefined;
  algoliaObjectId: string | null | undefined;
  vehiclePrice: number;
  vehicleEngineSize: string | null | undefined;
  vehicleMileage: number;
  financeQuoteId: string | null | undefined;
  financeQuotePosition: number;
  financeFunderCode: string;
  financeQuoteProductType: string;
  financeQuoteTerm: number;
  financeQuoteMileage: number;
  financeQuotesCount: number;
  financeQuotesSortField: string;
  financeQuotesSortDirection: string;
  financeQuoteProductId: string;
  financeQuoteMonthlyPayment: number;
  dealershipId: string;
  financeQuoteTotalDeposit: number;
  eventTrigger: string;
  eventTriggerText: string | null | undefined;
}
/**
 * finance_quote_eligibility_selected: When clicking the widget
eligibility button
 *
 * @param properties the properties associatied with this event
 * @param properties.vehicleVrm: vehicle vrm

 * @param properties.vehicleMake: Vehicle Make
 * @param properties.vehicleModel: Vehicle Model
 * @param properties.vehicleVin: vehicle vin
 * @param properties.ivendiVehicleId: Our universal vehicle id (if available)
 * @param properties.vehicleTaxonomySource: no description
 * @param properties.vehicleTaxonomyCountry: no description
 * @param properties.vehicleTaxonomyCode: no description
 * @param properties.vehicleClass: no description
 * @param properties.vehicleOemCodes: no description
 * @param properties.vehicleDerivative: no description
 * @param properties.vehicleFuel: no description
 * @param properties.vehicleDoors: no description
 * @param properties.vehicleCondition: no description
 * @param properties.vehicleImageUrl: no description
 * @param properties.vehicleAccuracyScore: no description
 * @param properties.algoliaObjectId: Algolia's unique id for a vehicle search document
 * @param properties.vehiclePrice: Vehicle Price
 * @param properties.vehicleEngineSize: no description
 * @param properties.vehicleMileage: no description
 * @param properties.financeQuoteId: no description
 * @param properties.financeQuotePosition: no description
 * @param properties.financeFunderCode: Unique funder abbreviation, normally 3 chars
 * @param properties.financeQuoteProductType: no description
 * @param properties.financeQuoteTerm: no description
 * @param properties.financeQuoteMileage: no description
 * @param properties.financeQuotesCount: no description
 * @param properties.financeQuotesSortField: no description
 * @param properties.financeQuotesSortDirection: no description
 * @param properties.financeQuoteProductId: no description
 * @param properties.financeQuoteMonthlyPayment: no description
 * @param properties.dealershipId: ID of a dealership
 * @param properties.financeQuoteTotalDeposit: no description
 * @param properties.eventTrigger: no description
 * @param properties.eventTriggerText: no description
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/5oISaQg7-}
 */
export function financeQuoteEligibilitySelected(
  properties: FinanceQuoteEligibilitySelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "-UqrurLDHx", name: "vehicle_vrm", value: properties.vehicleVrm});
  eventPropertiesArray.push({id: "O9E-Dnhdqz", name: "vehicle_make", value: properties.vehicleMake});
  eventPropertiesArray.push({id: "aTRNhI5D9F", name: "vehicle_model", value: properties.vehicleModel});
  properties.vehicleVin !== undefined && properties.vehicleVin !== null ?
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: properties.vehicleVin}) :
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: null});
  properties.ivendiVehicleId !== undefined && properties.ivendiVehicleId !== null ?
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: properties.ivendiVehicleId}) :
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: null});
  properties.vehicleTaxonomySource !== undefined && properties.vehicleTaxonomySource !== null ?
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: properties.vehicleTaxonomySource}) :
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: null});
  properties.vehicleTaxonomyCountry !== undefined && properties.vehicleTaxonomyCountry !== null ?
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: properties.vehicleTaxonomyCountry}) :
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: null});
  properties.vehicleTaxonomyCode !== undefined && properties.vehicleTaxonomyCode !== null ?
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: properties.vehicleTaxonomyCode}) :
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: null});
  eventPropertiesArray.push({id: "YAMqZFa6Tg", name: "vehicle_class", value: properties.vehicleClass});
  properties.vehicleOemCodes !== undefined && properties.vehicleOemCodes !== null ?
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: properties.vehicleOemCodes}) :
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: null});
  properties.vehicleDerivative !== undefined && properties.vehicleDerivative !== null ?
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: properties.vehicleDerivative}) :
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: null});
  properties.vehicleFuel !== undefined && properties.vehicleFuel !== null ?
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: properties.vehicleFuel}) :
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: null});
  properties.vehicleDoors !== undefined && properties.vehicleDoors !== null ?
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: properties.vehicleDoors}) :
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: null});
  properties.vehicleCondition !== undefined && properties.vehicleCondition !== null ?
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: properties.vehicleCondition}) :
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: null});
  properties.vehicleImageUrl !== undefined && properties.vehicleImageUrl !== null ?
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: properties.vehicleImageUrl}) :
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: null});
  properties.vehicleAccuracyScore !== undefined && properties.vehicleAccuracyScore !== null ?
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: properties.vehicleAccuracyScore}) :
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: null});
  properties.algoliaObjectId !== undefined && properties.algoliaObjectId !== null ?
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: properties.algoliaObjectId}) :
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: null});
  eventPropertiesArray.push({id: "pwOlscPL2G", name: "vehicle_price", value: properties.vehiclePrice});
  properties.vehicleEngineSize !== undefined && properties.vehicleEngineSize !== null ?
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: properties.vehicleEngineSize}) :
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: null});
  eventPropertiesArray.push({id: "GA16odh9aI", name: "vehicle_mileage", value: properties.vehicleMileage});
  properties.financeQuoteId !== undefined && properties.financeQuoteId !== null ?
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: properties.financeQuoteId}) :
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: null});
  eventPropertiesArray.push({id: "xlouzGxs_v", name: "finance_quote_position", value: properties.financeQuotePosition});
  eventPropertiesArray.push({id: "W5d0ysKAhl", name: "finance_funder_code", value: properties.financeFunderCode});
  eventPropertiesArray.push({id: "n8ldXLB0MP", name: "finance_quote_product_type", value: properties.financeQuoteProductType});
  eventPropertiesArray.push({id: "_tCUrC7oQ_", name: "finance_quote_term", value: properties.financeQuoteTerm});
  eventPropertiesArray.push({id: "YnOi5eICfz", name: "finance_quote_mileage", value: properties.financeQuoteMileage});
  eventPropertiesArray.push({id: "rzsGNxNUy", name: "finance_quotes_count", value: properties.financeQuotesCount});
  eventPropertiesArray.push({id: "o-zy4pgbp", name: "finance_quotes_sort_field", value: properties.financeQuotesSortField});
  eventPropertiesArray.push({id: "ItBAagbHN", name: "finance_quotes_sort_direction", value: properties.financeQuotesSortDirection});
  eventPropertiesArray.push({id: "rQirtqK4s", name: "finance_quote_product_id", value: properties.financeQuoteProductId});
  eventPropertiesArray.push({id: "Zpmf1g7SW", name: "finance_quote_monthly_payment", value: properties.financeQuoteMonthlyPayment});
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  eventPropertiesArray.push({id: "CFrCMFZMO", name: "finance_quote_total_deposit", value: properties.financeQuoteTotalDeposit});
  eventPropertiesArray.push({id: "4uruCn5_P", name: "event_trigger", value: properties.eventTrigger});
  properties.eventTriggerText !== undefined && properties.eventTriggerText !== null ?
    eventPropertiesArray.push({id: "B2P7Wzkjk", name: "event_trigger_text", value: properties.eventTriggerText}) :
    eventPropertiesArray.push({id: "B2P7Wzkjk", name: "event_trigger_text", value: null});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "5oISaQg7-", "4c56035fff21a5a9ed99dc45db9cf6e372189bc1d25eaa687f31e5d81b230291", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("finance_quote_eligibility_selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("5oISaQg7-", "finance_quote_eligibility_selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("finance_quote_eligibility_selected", eventProperties, "5oISaQg7-", "4c56035fff21a5a9ed99dc45db9cf6e372189bc1d25eaa687f31e5d81b230291");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("finance_quote_eligibility_selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FinanceQuotesSortedProperties {
  vehicleVrm: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleVin: string | null | undefined;
  ivendiVehicleId: string | null | undefined;
  vehicleTaxonomySource: string | null | undefined;
  vehicleTaxonomyCountry: string | null | undefined;
  vehicleTaxonomyCode: string | null | undefined;
  vehicleClass: string;
  vehicleOemCodes: string[] | null | undefined;
  vehicleDerivative: string | null | undefined;
  vehicleFuel: string | null | undefined;
  vehicleDoors: string | null | undefined;
  vehicleCondition: string | null | undefined;
  vehicleImageUrl: string | null | undefined;
  vehicleAccuracyScore: string | null | undefined;
  algoliaObjectId: string | null | undefined;
  vehiclePrice: number;
  vehicleEngineSize: string | null | undefined;
  vehicleMileage: number;
  financeQuotesSortDirection: string;
  financeQuotesSortField: string;
  consumerCreditTier: string | null | undefined;
}
/**
 * finance_quotes_sorted: When consumer changes the sort order of quotes displayed

 *
 * @param properties the properties associatied with this event
 * @param properties.vehicleVrm: vehicle vrm

 * @param properties.vehicleMake: Vehicle Make
 * @param properties.vehicleModel: Vehicle Model
 * @param properties.vehicleVin: vehicle vin
 * @param properties.ivendiVehicleId: Our universal vehicle id (if available)
 * @param properties.vehicleTaxonomySource: no description
 * @param properties.vehicleTaxonomyCountry: no description
 * @param properties.vehicleTaxonomyCode: no description
 * @param properties.vehicleClass: no description
 * @param properties.vehicleOemCodes: no description
 * @param properties.vehicleDerivative: no description
 * @param properties.vehicleFuel: no description
 * @param properties.vehicleDoors: no description
 * @param properties.vehicleCondition: no description
 * @param properties.vehicleImageUrl: no description
 * @param properties.vehicleAccuracyScore: no description
 * @param properties.algoliaObjectId: Algolia's unique id for a vehicle search document
 * @param properties.vehiclePrice: Vehicle Price
 * @param properties.vehicleEngineSize: no description
 * @param properties.vehicleMileage: no description
 * @param properties.financeQuotesSortDirection: no description
 * @param properties.financeQuotesSortField: no description
 * @param properties.consumerCreditTier: no description
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/QZEcRaW99g}
 */
export function financeQuotesSorted(properties: FinanceQuotesSortedProperties
  ) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "-UqrurLDHx", name: "vehicle_vrm", value: properties.vehicleVrm});
  eventPropertiesArray.push({id: "O9E-Dnhdqz", name: "vehicle_make", value: properties.vehicleMake});
  eventPropertiesArray.push({id: "aTRNhI5D9F", name: "vehicle_model", value: properties.vehicleModel});
  properties.vehicleVin !== undefined && properties.vehicleVin !== null ?
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: properties.vehicleVin}) :
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: null});
  properties.ivendiVehicleId !== undefined && properties.ivendiVehicleId !== null ?
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: properties.ivendiVehicleId}) :
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: null});
  properties.vehicleTaxonomySource !== undefined && properties.vehicleTaxonomySource !== null ?
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: properties.vehicleTaxonomySource}) :
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: null});
  properties.vehicleTaxonomyCountry !== undefined && properties.vehicleTaxonomyCountry !== null ?
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: properties.vehicleTaxonomyCountry}) :
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: null});
  properties.vehicleTaxonomyCode !== undefined && properties.vehicleTaxonomyCode !== null ?
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: properties.vehicleTaxonomyCode}) :
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: null});
  eventPropertiesArray.push({id: "YAMqZFa6Tg", name: "vehicle_class", value: properties.vehicleClass});
  properties.vehicleOemCodes !== undefined && properties.vehicleOemCodes !== null ?
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: properties.vehicleOemCodes}) :
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: null});
  properties.vehicleDerivative !== undefined && properties.vehicleDerivative !== null ?
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: properties.vehicleDerivative}) :
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: null});
  properties.vehicleFuel !== undefined && properties.vehicleFuel !== null ?
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: properties.vehicleFuel}) :
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: null});
  properties.vehicleDoors !== undefined && properties.vehicleDoors !== null ?
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: properties.vehicleDoors}) :
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: null});
  properties.vehicleCondition !== undefined && properties.vehicleCondition !== null ?
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: properties.vehicleCondition}) :
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: null});
  properties.vehicleImageUrl !== undefined && properties.vehicleImageUrl !== null ?
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: properties.vehicleImageUrl}) :
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: null});
  properties.vehicleAccuracyScore !== undefined && properties.vehicleAccuracyScore !== null ?
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: properties.vehicleAccuracyScore}) :
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: null});
  properties.algoliaObjectId !== undefined && properties.algoliaObjectId !== null ?
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: properties.algoliaObjectId}) :
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: null});
  eventPropertiesArray.push({id: "pwOlscPL2G", name: "vehicle_price", value: properties.vehiclePrice});
  properties.vehicleEngineSize !== undefined && properties.vehicleEngineSize !== null ?
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: properties.vehicleEngineSize}) :
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: null});
  eventPropertiesArray.push({id: "GA16odh9aI", name: "vehicle_mileage", value: properties.vehicleMileage});
  eventPropertiesArray.push({id: "ItBAagbHN", name: "finance_quotes_sort_direction", value: properties.financeQuotesSortDirection});
  eventPropertiesArray.push({id: "o-zy4pgbp", name: "finance_quotes_sort_field", value: properties.financeQuotesSortField});
  properties.consumerCreditTier !== undefined && properties.consumerCreditTier !== null ?
    eventPropertiesArray.push({id: "SQdo3NMqT", name: "consumer_credit_tier", value: properties.consumerCreditTier}) :
    eventPropertiesArray.push({id: "SQdo3NMqT", name: "consumer_credit_tier", value: null});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "QZEcRaW99g", "fd47ff494bb0eb9d3e3f67299cdf2101f43b472ae105622344bdf3e1efc23fa3", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("finance_quotes_sorted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("QZEcRaW99g", "finance_quotes_sorted", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("finance_quotes_sorted", eventProperties, "QZEcRaW99g", "fd47ff494bb0eb9d3e3f67299cdf2101f43b472ae105622344bdf3e1efc23fa3");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("finance_quotes_sorted", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ExpandMultiScheduleQuoteProperties {
  financeQuoteId: string | null | undefined;
  financeQuotePosition: number;
  financeFunderCode: string;
  financeQuoteProductType: string;
  financeQuoteTerm: number;
  financeQuoteMileage: number;
  financeQuotesCount: number;
  financeQuotesSortField: string;
  financeQuotesSortDirection: string;
  financeQuoteProductId: string;
  financeQuoteMonthlyPayment: number;
  dealershipId: string;
  financeQuoteTotalDeposit: number;
  eventTrigger: string;
  eventTriggerText: string;
}
/**
 * expand_multi_schedule_quote: When expanding the multi schedule quote
 *
 * @param properties the properties associatied with this event
 * @param properties.financeQuoteId: no description
 * @param properties.financeQuotePosition: no description
 * @param properties.financeFunderCode: Unique funder abbreviation, normally 3 chars
 * @param properties.financeQuoteProductType: no description
 * @param properties.financeQuoteTerm: no description
 * @param properties.financeQuoteMileage: no description
 * @param properties.financeQuotesCount: no description
 * @param properties.financeQuotesSortField: no description
 * @param properties.financeQuotesSortDirection: no description
 * @param properties.financeQuoteProductId: no description
 * @param properties.financeQuoteMonthlyPayment: no description
 * @param properties.dealershipId: ID of a dealership
 * @param properties.financeQuoteTotalDeposit: no description
 * @param properties.eventTrigger: no description
 * @param properties.eventTriggerText: no description
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/7k8O5RGuX}
 */
export function expandMultiScheduleQuote(
  properties: ExpandMultiScheduleQuoteProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.financeQuoteId !== undefined && properties.financeQuoteId !== null ?
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: properties.financeQuoteId}) :
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: null});
  eventPropertiesArray.push({id: "xlouzGxs_v", name: "finance_quote_position", value: properties.financeQuotePosition});
  eventPropertiesArray.push({id: "W5d0ysKAhl", name: "finance_funder_code", value: properties.financeFunderCode});
  eventPropertiesArray.push({id: "n8ldXLB0MP", name: "finance_quote_product_type", value: properties.financeQuoteProductType});
  eventPropertiesArray.push({id: "_tCUrC7oQ_", name: "finance_quote_term", value: properties.financeQuoteTerm});
  eventPropertiesArray.push({id: "YnOi5eICfz", name: "finance_quote_mileage", value: properties.financeQuoteMileage});
  eventPropertiesArray.push({id: "rzsGNxNUy", name: "finance_quotes_count", value: properties.financeQuotesCount});
  eventPropertiesArray.push({id: "o-zy4pgbp", name: "finance_quotes_sort_field", value: properties.financeQuotesSortField});
  eventPropertiesArray.push({id: "ItBAagbHN", name: "finance_quotes_sort_direction", value: properties.financeQuotesSortDirection});
  eventPropertiesArray.push({id: "rQirtqK4s", name: "finance_quote_product_id", value: properties.financeQuoteProductId});
  eventPropertiesArray.push({id: "Zpmf1g7SW", name: "finance_quote_monthly_payment", value: properties.financeQuoteMonthlyPayment});
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  eventPropertiesArray.push({id: "CFrCMFZMO", name: "finance_quote_total_deposit", value: properties.financeQuoteTotalDeposit});
  eventPropertiesArray.push({id: "4uruCn5_P", name: "event_trigger", value: properties.eventTrigger});
  eventPropertiesArray.push({id: "B2P7Wzkjk", name: "event_trigger_text", value: properties.eventTriggerText});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "7k8O5RGuX", "fb13cd442d4229c734c634c827994847e5bba91d234b74acd57510ad665ef2fd", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("expand_multi_schedule_quote", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("7k8O5RGuX", "expand_multi_schedule_quote", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("expand_multi_schedule_quote", eventProperties, "7k8O5RGuX", "fb13cd442d4229c734c634c827994847e5bba91d234b74acd57510ad665ef2fd");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("expand_multi_schedule_quote", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface DashboardLenderUpdatesApplicationSelectedProperties {
  financeApplicationId: string;
  financeApplicationStatus: string;
  dealershipId: string;
  financeQuoteId: string | null | undefined;
}
/**
 * dashboard_lender_updates_application_selected: When an application is clicked on the lender updates section of the platform dashboard
 *
 * @param properties the properties associatied with this event
 * @param properties.financeApplicationId: no description
 * @param properties.financeApplicationStatus: The finance application status/decision
 * @param properties.dealershipId: ID of a dealership
 * @param properties.financeQuoteId: no description
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/sm2z9xeV10}
 */
export function dashboardLenderUpdatesApplicationSelected(
  properties: DashboardLenderUpdatesApplicationSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "eN3-O2L4tX", name: "finance_application_id", value: properties.financeApplicationId});
  eventPropertiesArray.push({id: "Q4UDt3-yI", name: "finance_application_status", value: properties.financeApplicationStatus});
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  properties.financeQuoteId !== undefined && properties.financeQuoteId !== null ?
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: properties.financeQuoteId}) :
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: null});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "sm2z9xeV10", "8572d4ab4d406b558750bb3a1beb155b5f16d031166593abba73aa3a4bf63151", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("dashboard_lender_updates_application_selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("sm2z9xeV10", "dashboard_lender_updates_application_selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("dashboard_lender_updates_application_selected", eventProperties, "sm2z9xeV10", "8572d4ab4d406b558750bb3a1beb155b5f16d031166593abba73aa3a4bf63151");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("dashboard_lender_updates_application_selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ApplicationStatusRequoteNewRateSelectedProperties {
  financeApplicationId: string;
  dealershipId: string;
  financeQuoteId: string | null | undefined;
  advertisedRate: number;
  preApprovedRate: number;
  requoteNewRateSelectedOrigin: RequoteNewRateSelectedOriginValueType[];
}
/**
 * application_status_requote_new_rate_selected: When the requote new rate button is clicked on the application status page
 *
 * @param properties the properties associatied with this event
 * @param properties.financeApplicationId: no description
 * @param properties.dealershipId: ID of a dealership
 * @param properties.financeQuoteId: no description
 * @param properties.advertisedRate: no description
 * @param properties.preApprovedRate: no description
 * @param properties.requoteNewRateSelectedOrigin: Was the requote url or cta clicked
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/_69xRhoxK3}
 */
export function applicationStatusRequoteNewRateSelected(
  properties: ApplicationStatusRequoteNewRateSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "eN3-O2L4tX", name: "finance_application_id", value: properties.financeApplicationId});
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  properties.financeQuoteId !== undefined && properties.financeQuoteId !== null ?
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: properties.financeQuoteId}) :
    eventPropertiesArray.push({id: "9Rp9J_tRXe", name: "finance_quote_id", value: null});
  eventPropertiesArray.push({id: "EdPsItmMO", name: "advertised_rate", value: properties.advertisedRate});
  eventPropertiesArray.push({id: "uMafyaJ3E", name: "pre_approved_rate", value: properties.preApprovedRate});
  eventPropertiesArray.push({id: "KpaffkI2y", name: "requote_new_rate_selected_origin", value: properties.requoteNewRateSelectedOrigin});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "_69xRhoxK3", "7a8bd30805b031fd33acc96ed6a69149577ca9df5870a64e7b43f376679c7ddc", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("application_status_requote_new_rate_selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("_69xRhoxK3", "application_status_requote_new_rate_selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("application_status_requote_new_rate_selected", eventProperties, "_69xRhoxK3", "7a8bd30805b031fd33acc96ed6a69149577ca9df5870a64e7b43f376679c7ddc");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("application_status_requote_new_rate_selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface DealershipUserSwitchedDealershipProperties {
  dealershipUserId: string;
  dealershipIdFrom: string;
  dealershipIdTo: string;
}
/**
 * dealership_user_switched_dealership: Fired when a platform user switches from one dealer to another
 *
 * @param properties the properties associatied with this event
 * @param properties.dealershipUserId: ID of the user within a dealership
 * @param properties.dealershipIdFrom: The dealership ID being switched from
 * @param properties.dealershipIdTo: The dealership ID being switched to
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/V9opfaRdmN}
 */
export function dealershipUserSwitchedDealership(
  properties: DealershipUserSwitchedDealershipProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "51NdOS3zLx", name: "dealership_user_id", value: properties.dealershipUserId});
  eventPropertiesArray.push({id: "vfwj-zwGU", name: "dealership_id_from", value: properties.dealershipIdFrom});
  eventPropertiesArray.push({id: "VxZ4fFyJa", name: "dealership_id_to", value: properties.dealershipIdTo});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "V9opfaRdmN", "6bf39b45d148ca2f80a33a972821c2cc28b8e321c6ab6f74af7fe7f3ebf682ff", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("dealership_user_switched_dealership", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("V9opfaRdmN", "dealership_user_switched_dealership", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("dealership_user_switched_dealership", eventProperties, "V9opfaRdmN", "6bf39b45d148ca2f80a33a972821c2cc28b8e321c6ab6f74af7fe7f3ebf682ff");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("dealership_user_switched_dealership", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface DealershipMetricTileSelectedProperties {
  metricName: string;
}
/**
 * dealership_metric_tile_selected: Dealer Platform metric tile is selected
 *
 * @param properties the properties associatied with this event
 * @param properties.metricName: dealership metric name
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/xQ3Lgfu-eA}
 */
export function dealershipMetricTileSelected(
  properties: DealershipMetricTileSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "gfS7bO0Gbb", name: "metric_name", value: properties.metricName});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "xQ3Lgfu-eA", "3294c459ad8657246d255dcb94d8112aabbe49019044602f53628b9b9270872e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("dealership_metric_tile_selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("xQ3Lgfu-eA", "dealership_metric_tile_selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("dealership_metric_tile_selected", eventProperties, "xQ3Lgfu-eA", "3294c459ad8657246d255dcb94d8112aabbe49019044602f53628b9b9270872e");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("dealership_metric_tile_selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface DealershipMetricDateRangeChangedProperties {
  metricName: string;
  metricDateRangeFrom: string;
  metricDateRangeTo: string;
}
/**
 * dealership_metric_date_range_changed: Date range changed for a dealership metric
 *
 * @param properties the properties associatied with this event
 * @param properties.metricName: dealership metric name
 * @param properties.metricDateRangeFrom: Date range being changed from for a dealership metric
 * @param properties.metricDateRangeTo: Date range being changed to for a dealership metric
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/sR8sPlPDwD}
 */
export function dealershipMetricDateRangeChanged(
  properties: DealershipMetricDateRangeChangedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "gfS7bO0Gbb", name: "metric_name", value: properties.metricName});
  eventPropertiesArray.push({id: "lHLb47BmJf", name: "metric_date_range_from", value: properties.metricDateRangeFrom});
  eventPropertiesArray.push({id: "M6YNoKpEyj", name: "metric_date_range_to", value: properties.metricDateRangeTo});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "sR8sPlPDwD", "ea7a5c476ec0ee2218acd124c0888d41f168903fd811cef3c50b5f3c09a382cf", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("dealership_metric_date_range_changed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("sR8sPlPDwD", "dealership_metric_date_range_changed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("dealership_metric_date_range_changed", eventProperties, "sR8sPlPDwD", "ea7a5c476ec0ee2218acd124c0888d41f168903fd811cef3c50b5f3c09a382cf");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("dealership_metric_date_range_changed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface DealershipMetricOriginChangedProperties {
  metricName: string;
  metricOriginFrom: string[];
  metricOriginTo: string[];
}
/**
 * dealership_metric_origin_changed: Quote source origin changed for a dealership metric
 *
 * @param properties the properties associatied with this event
 * @param properties.metricName: dealership metric name
 * @param properties.metricOriginFrom: Dealership metric source origin value being changed from
 * @param properties.metricOriginTo: Dealership metric source origin value being changed to
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/ZVvF193QTE}
 */
export function dealershipMetricOriginChanged(
  properties: DealershipMetricOriginChangedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "gfS7bO0Gbb", name: "metric_name", value: properties.metricName});
  eventPropertiesArray.push({id: "S9zHTiKMgl", name: "metric_origin_from", value: properties.metricOriginFrom});
  eventPropertiesArray.push({id: "zOBe0fDDz", name: "metric_origin_to", value: properties.metricOriginTo});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "ZVvF193QTE", "0358525c533aa4527d85cd707eedca8034d904a3d3d1866e74aac02fd0a07e6e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("dealership_metric_origin_changed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("ZVvF193QTE", "dealership_metric_origin_changed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("dealership_metric_origin_changed", eventProperties, "ZVvF193QTE", "0358525c533aa4527d85cd707eedca8034d904a3d3d1866e74aac02fd0a07e6e");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("dealership_metric_origin_changed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface QuoteEditVehicleSelectedProperties {
  vehicleVrm: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleVin: string | null | undefined;
  ivendiVehicleId: string | null | undefined;
  vehicleTaxonomySource: string | null | undefined;
  vehicleTaxonomyCountry: string | null | undefined;
  vehicleTaxonomyCode: string | null | undefined;
  vehicleClass: string;
  vehicleOemCodes: string[] | null | undefined;
  vehicleDerivative: string | null | undefined;
  vehicleFuel: string | null | undefined;
  vehicleDoors: string | null | undefined;
  vehicleCondition: string | null | undefined;
  vehicleImageUrl: string | null | undefined;
  vehicleAccuracyScore: string | null | undefined;
  algoliaObjectId: string | null | undefined;
  vehiclePrice: number;
  vehicleEngineSize: string | null | undefined;
  vehicleMileage: number;
  dealershipId: string;
  dealershipUserId: string;
}
/**
 * quote_edit_vehicle_selected: When the user is on the Quote Details page and clicks the Edit Vehicle Details CTA on the Vehicle Details Card
 *
 * When to trigger this event:
 * 1. When the user is on the Quote Details page and clicks the Edit Vehicle Details CTA
 * View in Avo: https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/avnNoXgdKy-/trigger/bR0C9cvEo4c
 *
 * @param properties the properties associatied with this event
 * @param properties.vehicleVrm: vehicle vrm

 * @param properties.vehicleMake: Vehicle Make
 * @param properties.vehicleModel: Vehicle Model
 * @param properties.vehicleVin: vehicle vin
 * @param properties.ivendiVehicleId: Our universal vehicle id (if available)
 * @param properties.vehicleTaxonomySource: no description
 * @param properties.vehicleTaxonomyCountry: no description
 * @param properties.vehicleTaxonomyCode: no description
 * @param properties.vehicleClass: no description
 * @param properties.vehicleOemCodes: no description
 * @param properties.vehicleDerivative: no description
 * @param properties.vehicleFuel: no description
 * @param properties.vehicleDoors: no description
 * @param properties.vehicleCondition: no description
 * @param properties.vehicleImageUrl: no description
 * @param properties.vehicleAccuracyScore: no description
 * @param properties.algoliaObjectId: Algolia's unique id for a vehicle search document
 * @param properties.vehiclePrice: Vehicle Price
 * @param properties.vehicleEngineSize: no description
 * @param properties.vehicleMileage: no description
 * @param properties.dealershipId: ID of a dealership
 * @param properties.dealershipUserId: ID of the user within a dealership
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/avnNoXgdKy-}
 */
export function quoteEditVehicleSelected(
  properties: QuoteEditVehicleSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "-UqrurLDHx", name: "vehicle_vrm", value: properties.vehicleVrm});
  eventPropertiesArray.push({id: "O9E-Dnhdqz", name: "vehicle_make", value: properties.vehicleMake});
  eventPropertiesArray.push({id: "aTRNhI5D9F", name: "vehicle_model", value: properties.vehicleModel});
  properties.vehicleVin !== undefined && properties.vehicleVin !== null ?
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: properties.vehicleVin}) :
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: null});
  properties.ivendiVehicleId !== undefined && properties.ivendiVehicleId !== null ?
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: properties.ivendiVehicleId}) :
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: null});
  properties.vehicleTaxonomySource !== undefined && properties.vehicleTaxonomySource !== null ?
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: properties.vehicleTaxonomySource}) :
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: null});
  properties.vehicleTaxonomyCountry !== undefined && properties.vehicleTaxonomyCountry !== null ?
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: properties.vehicleTaxonomyCountry}) :
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: null});
  properties.vehicleTaxonomyCode !== undefined && properties.vehicleTaxonomyCode !== null ?
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: properties.vehicleTaxonomyCode}) :
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: null});
  eventPropertiesArray.push({id: "YAMqZFa6Tg", name: "vehicle_class", value: properties.vehicleClass});
  properties.vehicleOemCodes !== undefined && properties.vehicleOemCodes !== null ?
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: properties.vehicleOemCodes}) :
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: null});
  properties.vehicleDerivative !== undefined && properties.vehicleDerivative !== null ?
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: properties.vehicleDerivative}) :
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: null});
  properties.vehicleFuel !== undefined && properties.vehicleFuel !== null ?
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: properties.vehicleFuel}) :
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: null});
  properties.vehicleDoors !== undefined && properties.vehicleDoors !== null ?
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: properties.vehicleDoors}) :
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: null});
  properties.vehicleCondition !== undefined && properties.vehicleCondition !== null ?
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: properties.vehicleCondition}) :
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: null});
  properties.vehicleImageUrl !== undefined && properties.vehicleImageUrl !== null ?
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: properties.vehicleImageUrl}) :
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: null});
  properties.vehicleAccuracyScore !== undefined && properties.vehicleAccuracyScore !== null ?
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: properties.vehicleAccuracyScore}) :
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: null});
  properties.algoliaObjectId !== undefined && properties.algoliaObjectId !== null ?
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: properties.algoliaObjectId}) :
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: null});
  eventPropertiesArray.push({id: "pwOlscPL2G", name: "vehicle_price", value: properties.vehiclePrice});
  properties.vehicleEngineSize !== undefined && properties.vehicleEngineSize !== null ?
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: properties.vehicleEngineSize}) :
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: null});
  eventPropertiesArray.push({id: "GA16odh9aI", name: "vehicle_mileage", value: properties.vehicleMileage});
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  eventPropertiesArray.push({id: "51NdOS3zLx", name: "dealership_user_id", value: properties.dealershipUserId});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "avnNoXgdKy-", "3b4faa8e2762e4aa697a4bbd458ce239030385b888634b0116c605a1ca96ad7e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("quote_edit_vehicle_selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("avnNoXgdKy-", "quote_edit_vehicle_selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("quote_edit_vehicle_selected", eventProperties, "avnNoXgdKy-", "3b4faa8e2762e4aa697a4bbd458ce239030385b888634b0116c605a1ca96ad7e");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("quote_edit_vehicle_selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ApplicationEditVehicleSelectedProperties {
  vehicleVrm: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleVin: string | null | undefined;
  ivendiVehicleId: string | null | undefined;
  vehicleTaxonomySource: string | null | undefined;
  vehicleTaxonomyCountry: string | null | undefined;
  vehicleTaxonomyCode: string | null | undefined;
  vehicleClass: string;
  vehicleOemCodes: string[] | null | undefined;
  vehicleDerivative: string | null | undefined;
  vehicleFuel: string | null | undefined;
  vehicleDoors: string | null | undefined;
  vehicleCondition: string | null | undefined;
  vehicleImageUrl: string | null | undefined;
  vehicleAccuracyScore: string | null | undefined;
  algoliaObjectId: string | null | undefined;
  vehiclePrice: number;
  vehicleEngineSize: string | null | undefined;
  vehicleMileage: number;
  dealershipId: string;
  dealershipUserId: string;
}
/**
 * application_edit_vehicle_selected: When the user is on the Quote Details page and clicks the Edit Vehicle Details CTA
 *
 * When to trigger this event:
 * 1. When the user clicks the 'Vehicle Details' Edit card on the Edit Application page
 * View in Avo: https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/isjidc2T1/trigger/aIdfNVDxVO
 *
 * @param properties the properties associatied with this event
 * @param properties.vehicleVrm: vehicle vrm

 * @param properties.vehicleMake: Vehicle Make
 * @param properties.vehicleModel: Vehicle Model
 * @param properties.vehicleVin: vehicle vin
 * @param properties.ivendiVehicleId: Our universal vehicle id (if available)
 * @param properties.vehicleTaxonomySource: no description
 * @param properties.vehicleTaxonomyCountry: no description
 * @param properties.vehicleTaxonomyCode: no description
 * @param properties.vehicleClass: no description
 * @param properties.vehicleOemCodes: no description
 * @param properties.vehicleDerivative: no description
 * @param properties.vehicleFuel: no description
 * @param properties.vehicleDoors: no description
 * @param properties.vehicleCondition: no description
 * @param properties.vehicleImageUrl: no description
 * @param properties.vehicleAccuracyScore: no description
 * @param properties.algoliaObjectId: Algolia's unique id for a vehicle search document
 * @param properties.vehiclePrice: Vehicle Price
 * @param properties.vehicleEngineSize: no description
 * @param properties.vehicleMileage: no description
 * @param properties.dealershipId: ID of a dealership
 * @param properties.dealershipUserId: ID of the user within a dealership
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/isjidc2T1}
 */
export function applicationEditVehicleSelected(
  properties: ApplicationEditVehicleSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "-UqrurLDHx", name: "vehicle_vrm", value: properties.vehicleVrm});
  eventPropertiesArray.push({id: "O9E-Dnhdqz", name: "vehicle_make", value: properties.vehicleMake});
  eventPropertiesArray.push({id: "aTRNhI5D9F", name: "vehicle_model", value: properties.vehicleModel});
  properties.vehicleVin !== undefined && properties.vehicleVin !== null ?
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: properties.vehicleVin}) :
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: null});
  properties.ivendiVehicleId !== undefined && properties.ivendiVehicleId !== null ?
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: properties.ivendiVehicleId}) :
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: null});
  properties.vehicleTaxonomySource !== undefined && properties.vehicleTaxonomySource !== null ?
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: properties.vehicleTaxonomySource}) :
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: null});
  properties.vehicleTaxonomyCountry !== undefined && properties.vehicleTaxonomyCountry !== null ?
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: properties.vehicleTaxonomyCountry}) :
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: null});
  properties.vehicleTaxonomyCode !== undefined && properties.vehicleTaxonomyCode !== null ?
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: properties.vehicleTaxonomyCode}) :
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: null});
  eventPropertiesArray.push({id: "YAMqZFa6Tg", name: "vehicle_class", value: properties.vehicleClass});
  properties.vehicleOemCodes !== undefined && properties.vehicleOemCodes !== null ?
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: properties.vehicleOemCodes}) :
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: null});
  properties.vehicleDerivative !== undefined && properties.vehicleDerivative !== null ?
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: properties.vehicleDerivative}) :
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: null});
  properties.vehicleFuel !== undefined && properties.vehicleFuel !== null ?
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: properties.vehicleFuel}) :
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: null});
  properties.vehicleDoors !== undefined && properties.vehicleDoors !== null ?
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: properties.vehicleDoors}) :
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: null});
  properties.vehicleCondition !== undefined && properties.vehicleCondition !== null ?
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: properties.vehicleCondition}) :
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: null});
  properties.vehicleImageUrl !== undefined && properties.vehicleImageUrl !== null ?
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: properties.vehicleImageUrl}) :
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: null});
  properties.vehicleAccuracyScore !== undefined && properties.vehicleAccuracyScore !== null ?
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: properties.vehicleAccuracyScore}) :
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: null});
  properties.algoliaObjectId !== undefined && properties.algoliaObjectId !== null ?
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: properties.algoliaObjectId}) :
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: null});
  eventPropertiesArray.push({id: "pwOlscPL2G", name: "vehicle_price", value: properties.vehiclePrice});
  properties.vehicleEngineSize !== undefined && properties.vehicleEngineSize !== null ?
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: properties.vehicleEngineSize}) :
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: null});
  eventPropertiesArray.push({id: "GA16odh9aI", name: "vehicle_mileage", value: properties.vehicleMileage});
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  eventPropertiesArray.push({id: "51NdOS3zLx", name: "dealership_user_id", value: properties.dealershipUserId});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "isjidc2T1", "88f965e190e813cdbb7cd749d476de6835191dd9bafe113e762ed785af840d2f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("application_edit_vehicle_selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("isjidc2T1", "application_edit_vehicle_selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("application_edit_vehicle_selected", eventProperties, "isjidc2T1", "88f965e190e813cdbb7cd749d476de6835191dd9bafe113e762ed785af840d2f");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("application_edit_vehicle_selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ApplicationEditVehicleAmendedProperties {
  vehicleVrm: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleVin: string | null | undefined;
  ivendiVehicleId: string | null | undefined;
  vehicleTaxonomySource: string | null | undefined;
  vehicleTaxonomyCountry: string | null | undefined;
  vehicleTaxonomyCode: string | null | undefined;
  vehicleClass: string;
  vehicleOemCodes: string[] | null | undefined;
  vehicleDerivative: string | null | undefined;
  vehicleFuel: string | null | undefined;
  vehicleDoors: string | null | undefined;
  vehicleCondition: string | null | undefined;
  vehicleImageUrl: string | null | undefined;
  vehicleAccuracyScore: string | null | undefined;
  algoliaObjectId: string | null | undefined;
  vehiclePrice: number;
  vehicleEngineSize: string | null | undefined;
  vehicleMileage: number;
  dealershipId: string;
  dealershipUserId: string;
}
/**
 * application_edit_vehicle_amended: When the user is on the Quote Details page and clicks the Edit Vehicle Details CTA
 *
 * When to trigger this event:
 * 1. When the user is on the Application Summary Page, has updated the vehicle and clicks the CTA 'Update Vehicle'
 * View in Avo: https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/BMEUPkX2x/trigger/Gh0vIJ1DhC
 *
 * @param properties the properties associatied with this event
 * @param properties.vehicleVrm: vehicle vrm

 * @param properties.vehicleMake: Vehicle Make
 * @param properties.vehicleModel: Vehicle Model
 * @param properties.vehicleVin: vehicle vin
 * @param properties.ivendiVehicleId: Our universal vehicle id (if available)
 * @param properties.vehicleTaxonomySource: no description
 * @param properties.vehicleTaxonomyCountry: no description
 * @param properties.vehicleTaxonomyCode: no description
 * @param properties.vehicleClass: no description
 * @param properties.vehicleOemCodes: no description
 * @param properties.vehicleDerivative: no description
 * @param properties.vehicleFuel: no description
 * @param properties.vehicleDoors: no description
 * @param properties.vehicleCondition: no description
 * @param properties.vehicleImageUrl: no description
 * @param properties.vehicleAccuracyScore: no description
 * @param properties.algoliaObjectId: Algolia's unique id for a vehicle search document
 * @param properties.vehiclePrice: Vehicle Price
 * @param properties.vehicleEngineSize: no description
 * @param properties.vehicleMileage: no description
 * @param properties.dealershipId: ID of a dealership
 * @param properties.dealershipUserId: ID of the user within a dealership
 *
 * @see {@link https://www.avo.app/schemas/s5Rw4iVTjTG0gbHpgybD/events/BMEUPkX2x}
 */
export function applicationEditVehicleAmended(
  properties: ApplicationEditVehicleAmendedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "-UqrurLDHx", name: "vehicle_vrm", value: properties.vehicleVrm});
  eventPropertiesArray.push({id: "O9E-Dnhdqz", name: "vehicle_make", value: properties.vehicleMake});
  eventPropertiesArray.push({id: "aTRNhI5D9F", name: "vehicle_model", value: properties.vehicleModel});
  properties.vehicleVin !== undefined && properties.vehicleVin !== null ?
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: properties.vehicleVin}) :
    eventPropertiesArray.push({id: "c1_gNO97B", name: "vehicle_vin", value: null});
  properties.ivendiVehicleId !== undefined && properties.ivendiVehicleId !== null ?
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: properties.ivendiVehicleId}) :
    eventPropertiesArray.push({id: "dLpEAzspH", name: "ivendi_vehicle_id", value: null});
  properties.vehicleTaxonomySource !== undefined && properties.vehicleTaxonomySource !== null ?
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: properties.vehicleTaxonomySource}) :
    eventPropertiesArray.push({id: "59RZImC_L", name: "vehicle_taxonomy_source", value: null});
  properties.vehicleTaxonomyCountry !== undefined && properties.vehicleTaxonomyCountry !== null ?
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: properties.vehicleTaxonomyCountry}) :
    eventPropertiesArray.push({id: "pMOOKqhBu", name: "vehicle_taxonomy_country", value: null});
  properties.vehicleTaxonomyCode !== undefined && properties.vehicleTaxonomyCode !== null ?
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: properties.vehicleTaxonomyCode}) :
    eventPropertiesArray.push({id: "ZqhkM-KvT", name: "vehicle_taxonomy_code", value: null});
  eventPropertiesArray.push({id: "YAMqZFa6Tg", name: "vehicle_class", value: properties.vehicleClass});
  properties.vehicleOemCodes !== undefined && properties.vehicleOemCodes !== null ?
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: properties.vehicleOemCodes}) :
    eventPropertiesArray.push({id: "R17iWvROO", name: "vehicle_oem_codes", value: null});
  properties.vehicleDerivative !== undefined && properties.vehicleDerivative !== null ?
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: properties.vehicleDerivative}) :
    eventPropertiesArray.push({id: "iAmcRz7CfL", name: "vehicle_derivative", value: null});
  properties.vehicleFuel !== undefined && properties.vehicleFuel !== null ?
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: properties.vehicleFuel}) :
    eventPropertiesArray.push({id: "v3TfAm73MP", name: "vehicle_fuel", value: null});
  properties.vehicleDoors !== undefined && properties.vehicleDoors !== null ?
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: properties.vehicleDoors}) :
    eventPropertiesArray.push({id: "LP6cWFJjOM", name: "vehicle_doors", value: null});
  properties.vehicleCondition !== undefined && properties.vehicleCondition !== null ?
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: properties.vehicleCondition}) :
    eventPropertiesArray.push({id: "K345j_7sv", name: "vehicle_condition", value: null});
  properties.vehicleImageUrl !== undefined && properties.vehicleImageUrl !== null ?
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: properties.vehicleImageUrl}) :
    eventPropertiesArray.push({id: "1-LW_6byU5", name: "vehicle_image_url", value: null});
  properties.vehicleAccuracyScore !== undefined && properties.vehicleAccuracyScore !== null ?
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: properties.vehicleAccuracyScore}) :
    eventPropertiesArray.push({id: "RJtyAAHDe", name: "vehicle_accuracy_score", value: null});
  properties.algoliaObjectId !== undefined && properties.algoliaObjectId !== null ?
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: properties.algoliaObjectId}) :
    eventPropertiesArray.push({id: "PHynRyL8N3", name: "algolia_object_id", value: null});
  eventPropertiesArray.push({id: "pwOlscPL2G", name: "vehicle_price", value: properties.vehiclePrice});
  properties.vehicleEngineSize !== undefined && properties.vehicleEngineSize !== null ?
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: properties.vehicleEngineSize}) :
    eventPropertiesArray.push({id: "FIAI3I38F", name: "vehicle_engine_size", value: null});
  eventPropertiesArray.push({id: "GA16odh9aI", name: "vehicle_mileage", value: properties.vehicleMileage});
  eventPropertiesArray.push({id: "v5TjyIbQxa", name: "dealership_id", value: properties.dealershipId});
  eventPropertiesArray.push({id: "51NdOS3zLx", name: "dealership_user_id", value: properties.dealershipUserId});
  eventPropertiesArray.push({id: "Nkf7XSsbrf", name: "platform_name", value: sysPlatformName});
  eventPropertiesArray.push({id: "0eVQjYs48", name: "platform_version", value: sysPlatformVersion});
  eventPropertiesArray.push({id: "j5mmD1csxz", name: "platform_id", value: sysPlatformId});
  eventPropertiesArray.push({id: "i251A_fwW-", name: "cookie_consent_marketing_granted", value: sysCookieConsentMarketingGranted});
  eventPropertiesArray.push({id: "MzL9T0KK07", name: "cookie_consent_functional_granted", value: sysCookieConsentFunctionalGranted});
  eventPropertiesArray.push({id: "-d-iNT_oRz", name: "cookie_consent_analytical_granted", value: sysCookieConsentAnalyticalGranted});
  eventPropertiesArray.push({id: "vS-meh69lv", name: "webshop_id", value: sysWebshopId});
  eventPropertiesArray.push({id: "2_jLbkokT3", name: "webshop_campaign_id", value: sysWebshopCampaignId});
  eventPropertiesArray.push({id: "6TEmMxEzKo", name: "country_code", value: sysCountryCode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "BMEUPkX2x", "f6cb6eeffcc0e8b7f24f4764a8eea3e97a0be4d88f35a6008aeae7b6dcb2eb6d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("application_edit_vehicle_amended", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("BMEUPkX2x", "application_edit_vehicle_amended", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("application_edit_vehicle_amended", eventProperties, "BMEUPkX2x", "f6cb6eeffcc0e8b7f24f4764a8eea3e97a0be4d88f35a6008aeae7b6dcb2eb6d");
    }
    // destination RudderstackDealerPlatformJs
    RudderstackDealerPlatformJs.logEvent("application_edit_vehicle_amended", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export default {
  AvoEnv,
  initAvo,
  setSystemProperties,
  avoInspectorApiKey,
  ConsumerEntityType,
  CountryCode,
  FinanceQuoteJourney,
  RequoteNewRateSelectedOrigin,
  FinanceProductType,
  financeEligibilityCheckSubmitted,
  financeEligibilityCheckActivated,
  financeEligibilityCheckCompleted,
  financeEligibilityCheckFailed,
  pageView,
  outboundLink,
  financeProductSuitabilityContentViewed,
  financeProductSuitabilityVideoViewed,
  financeProductSuitabilityQuestionAndAnswer,
  financeProductSuitabilityVulnerabilityContentViewed,
  financeProductSuitabilityVulnerabilityOutboundLink,
  financeProductLearnMoreOpened,
  financeProductLearnMoreSubSectionOpened,
  vehicleFinanceApplicationStarted,
  financeQuoteApplySelected,
  financeQuotesDisplayed,
  financeQuotesFailed,
  financeQuoteEligibilitySelected,
  financeQuotesSorted,
  expandMultiScheduleQuote,
  dashboardLenderUpdatesApplicationSelected,
  applicationStatusRequoteNewRateSelected,
  dealershipUserSwitchedDealership,
  dealershipMetricTileSelected,
  dealershipMetricDateRangeChanged,
  dealershipMetricOriginChanged,
  quoteEditVehicleSelected,
  applicationEditVehicleSelected,
  applicationEditVehicleAmended,
}

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["financeEligibilityCheckSubmitted","financeEligibilityCheckActivated","financeEligibilityCheckCompleted","financeEligibilityCheckFailed","pageView","outboundLink","financeProductSuitabilityContentViewed","financeProductSuitabilityVideoViewed","financeProductSuitabilityQuestionAndAnswer","financeProductSuitabilityVulnerabilityContentViewed","financeProductSuitabilityVulnerabilityOutboundLink","financeProductLearnMoreOpened","financeProductLearnMoreSubSectionOpened","vehicleFinanceApplicationStarted","financeQuoteApplySelected","financeQuotesDisplayed","financeQuotesFailed","financeQuoteEligibilitySelected","financeQuotesSorted","expandMultiScheduleQuote","dashboardLenderUpdatesApplicationSelected","applicationStatusRequoteNewRateSelected","dealershipUserSwitchedDealership","dealershipMetricTileSelected","dealershipMetricDateRangeChanged","dealershipMetricOriginChanged","quoteEditVehicleSelected","applicationEditVehicleSelected","applicationEditVehicleAmended"]
