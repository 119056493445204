import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AccountSettings from '../components/AccountSettings';
import { inject, observer } from 'mobx-react';
import ErrorApology from '../../Common/ErrorApology';
import { compose } from 'redux';
import { withParams } from 'hocs/router';

class AccountSettingsContainer extends React.Component {
  UNSAFE_componentWillMount() {
    const currentUserId = this.props.session.UserId;
    const { dealershipId } = this.props.params;
    this.props.appStore.userStore.fetchUser(dealershipId, currentUserId);
  }

  saveUserDetails = (userId, data) => {
    const { dealershipId } = this.props.params;
    this.props.appStore.userStore.saveUserDetails(dealershipId, userId, data);
  };

  render() {
    if (this.props.appStore.userStore.fetchUserRequest.isLoading) {
      return null;
    }

    if (this.props.appStore.userStore.fetchUserRequest.hasError) {
      return <ErrorApology>{this.props.t('AccountSettingsContainer.error_fetching_user')}</ErrorApology>;
    }

    return (
      <AccountSettings
        user={this.props.appStore.userStore.user}
        userId={this.props.params.userId}
        dealershipId={this.props.params.dealershipId}
        currentUserId={this.props.session.UserId}
        saveUserDetails={this.saveUserDetails}
        isSubmitting={this.props.appStore.userStore.saveUserDetailsRequest.isLoading}
        hasSubmittingError={this.props.appStore.userStore.saveUserDetailsRequest.hasError}
      />
    );
  }
}

AccountSettingsContainer.propTypes = {
  fetchCurrentUser: PropTypes.func,
  saveUserDetails: PropTypes.func,
  user: PropTypes.object,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  appStore: PropTypes.object,
  params: PropTypes.object,
  session: PropTypes.object
};

function mapStateToProps(state) {
  return {
    session: state.session
  };
}

export default compose(
  withParams,
  withTranslation('ManageUsers'),
  inject(['appStore']),
  connect(mapStateToProps),
  observer
)(AccountSettingsContainer);
