import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Settings from '../components/Settings';
import { withParams } from 'hocs/router';

const SettingsContainer = (props) => {
  const { children, ...remainingProps } = props;
  return <Settings {...remainingProps} />;
};

SettingsContainer.propTypes = {
  children: PropTypes.node
};

function mapStateToProps(state) {
  return {
    session: state.session,
    dealership: state.dealership
  };
}

export default withParams(connect(mapStateToProps)(SettingsContainer));
