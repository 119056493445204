import React, { useEffect } from 'react';
import { inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import Page from '~Common/Page';
import Panel from '~Common/Panel';
import PanelHeader from '~Common/PanelHeader';

import VisLookUp from '~Common/VisLookup';
import { StockRefinementSearch } from './components';
import { StockBreadcrumbs } from '../../../../shared/components';
import { useNavigate, useParams } from 'react-router-dom';

const StockRefinementContainer = (props) => {
  const { t } = useTranslation('Stock');
  const navigate = useNavigate();
  const params = useParams();
  const { condition, lookupTerm } = props.appStore.stockCreateStore.miscVehicleData;
  const hasVehicleData = !!condition && !!lookupTerm;
  const { capDataResponse } = props.appStore.stockCreateStore;

  useEffect(() => {
    !hasVehicleData &&
      navigate(`/d/${params.dealershipId}/stock/add/lookup`, {
        replace: true
      });
  }, [hasVehicleData, params.dealershipId, props.router, navigate]);

  return (
    <Page>
      <StockBreadcrumbs
        items={[
          {
            name: t('Common.stock_list'),
            path: `/d/${params.dealershipId}/stock/list`
          },
          {
            name: t('Common.refine_vehicle')
          }
        ]}
      />

      <Panel>
        <PanelHeader>{t('StockRefinementContainer.refine_vehicle_record')} </PanelHeader>
        <VisLookUp
          defaultValues={{
            class: capDataResponse ? capDataResponse.CapData[0].VehicleClass : '',
            facetFilters: capDataResponse ? capDataResponse.CapData.map(({ CapId }) => CapId) : []
          }}
          dealershipId={params.dealershipId}
        >
          <StockRefinementSearch />
        </VisLookUp>
      </Panel>
    </Page>
  );
};

export default inject('appStore')(StockRefinementContainer);
