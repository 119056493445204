import React from 'react';
import { observer } from 'mobx-react';

const getDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

const observerForHooks = (mapObservableToProps) => {
  if (Object.prototype.toString.call(mapObservableToProps) !== '[object Object]') {
    throw new TypeError('observerForHooks requires an object to be passed.');
  }

  return (Component) => {
    const ObserverForHooks = (props) => {
      const currentProps = props;
      const additionalProps = Object.keys(mapObservableToProps).reduce((acc, curKey) => {
        acc[curKey] = mapObservableToProps[curKey](currentProps);
        return acc;
      }, {});
      return <Component {...currentProps} {...additionalProps} />;
    };

    ObserverForHooks.displayName = `ObserverForHooks(${getDisplayName(Component)})`;

    return observer(ObserverForHooks);
  };
};

export default observerForHooks;
