import platformApiUtils from '@ivendi/platform-api-utils';

export function requestResetPassword(email) {
  return platformApiUtils.post('v1/password-reset-request/', { Email: email });
}

export function checkPasswordResetGuid(guid) {
  return platformApiUtils.get('v1/password-change/' + guid);
}

export function resetPassword(guid, newPassword) {
  return platformApiUtils.post('v1/password-change/' + guid, { NewPassword: newPassword });
}

export function changePassword(newPassword, oldPassword) {
  return platformApiUtils.post('v1/password', {
    NewPassword: newPassword,
    OldPassword: oldPassword
  });
}
