import { Dispatch, useEffect, useState } from 'react';
import Select, { StylesConfig } from 'react-select';
import ToggleSwitch from '~/components/Common/ToggleSwitch';
import { OptionType } from '~/components/Insights/types';
import s from './toggleSelect.module.scss';

interface ToggleSelectProps<T> {
  options: OptionType[];
  values: T[];
  onChange: Dispatch<T[]>;
  singleSelection?: boolean;
  customStyles?: StylesConfig<any, false>;
}

const ToggleSelect = <T extends string | number>({
  options,
  values,
  onChange,
  singleSelection = false,
  customStyles = undefined
}: ToggleSelectProps<T>) => {
  const [toggleStates, setToggleStates] = useState<Record<T, boolean>>({} as Record<T, boolean>);

  useEffect(() => {
    const newState: Record<T, boolean> = {} as Record<T, boolean>;
    options.forEach((option) => {
      newState[option.id as T] = values.includes(option.id as T);
    });
    setToggleStates(newState);
  }, [values, options]);

  const handleToggleChange = (id: T) => {
    if (singleSelection) {
      const newSelectedValues: T[] = [id];
      onChange(newSelectedValues);
      return;
    }
    // Multi-selection logic (if needed)
    let newSelectedValues = [...values];
    if (newSelectedValues.includes(id)) {
      // If already selected, remove it
      newSelectedValues = newSelectedValues.filter((val) => val !== id);
    } else {
      // If not selected, add it
      newSelectedValues.push(id);
    }
    onChange(newSelectedValues);
  };

  const SwitchOption = ({ data }: { data: OptionType & { isActive: boolean } }) => {
    const { id, isActive, label, isDisabled } = data;
    return (
      <span className={s['optionContainer']}>
        {label}
        <ToggleSwitch
          handleUpdate={() => handleToggleChange(id as T)}
          isActive={isActive}
          isDisabled={isDisabled}
          showToggleText={false}
          smallBlueVariant={true}
        />
      </span>
    );
  };

  const displayOptions = options.map((option) => ({
    ...option,
    isActive: toggleStates[option.id as T]
  }));

  const selectedOption = displayOptions.find((option) => values.includes(option.id as T)) || displayOptions[0];

  const defaultStyles: StylesConfig<any, false> = {
    control: (css) => ({
      ...css,
      width: 170
    }),
    menu: ({ width, ...css }) => ({
      ...css,
      width: 'max-content',
      minWidth: '100%'
    }),
    option: (css) => ({ ...css, width: 170 })
  };

  return (
    <Select
      placeholder="Select option"
      components={{ Option: SwitchOption }}
      filterOption={null}
      isSearchable={false}
      styles={customStyles || defaultStyles}
      isMulti={false}
      maxMenuHeight={380}
      value={selectedOption}
      options={displayOptions}
    />
  );
};

export default ToggleSelect;
