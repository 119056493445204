import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

const NotImplemented = () => {
  const { t } = useTranslation('Common');

  return (
    <div className={styles.container}>
      <p>{t('NotImplemented.sorry_this_feature_is_not_yet_available_in_your_country')}</p>
    </div>
  );
};

export default NotImplemented;
