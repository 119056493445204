function normalise(val) {
  if (typeof val === 'string') {
    return val.toUpperCase();
  }
  return val;
}

export function compareVehicleSpecs(objA, objB) {
  for (const prop in objA) {
    if (objA[prop] === '' || objB[prop] === '') {
      return false;
    }
    if (normalise(objA[prop]) !== normalise(objB[prop])) {
      return false;
    }
  }
  return true;
}
