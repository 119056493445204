import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppStoreType,
  ChartName,
  ChartNameType,
  ChartVariant,
  FiltersStateType,
  ReportConfigType
} from '~/components/Insights/types';
import getReportConfig, { getDefaultFilters } from '~/components/Insights/services/utils/getReportConfig';
import ReportDetails from '../ReportDetails/ReportDetails';
import { inject } from 'mobx-react';
import LineChart from '../../Charts/LineChart/LineChart';
import ChartDataFilters from '../../Filters/ChartFilters/ChartDataFilters';
import styles from './report.module.scss';
export type ReportProps = {
  appStore?: AppStoreType;
  chartName?: ChartNameType;
};

const ApplicationVolumesReport = ({ appStore }: ReportProps) => {
  const { t } = useTranslation('Insights');
  const reportConfig = getReportConfig(ChartName.ApplicationVolumes, appStore);
  const { title, description, metric, userCanViewReport, chartFilterConfig } = reportConfig;
  const { applicationStatusConfig } = chartFilterConfig;

  const [filters, setFilters] = useState<FiltersStateType>(getDefaultFilters());
  const handleFilterChange = useCallback(
    (chartFilters: FiltersStateType) => {
      setFilters(chartFilters);
    },
    [setFilters]
  );

  if (!userCanViewReport) return null;

  const updatedReportConfig = useMemo(
    () => ({
      ...reportConfig,
      metric: (applicationStatusConfig.enabled && filters.applicationStatus) || metric
    }),
    [reportConfig, applicationStatusConfig.enabled, filters.applicationStatus, metric]
  ) as ReportConfigType;

  const showLineChart = filters.chartType === ChartVariant.Line;
  const showTable = filters.chartType === ChartVariant.Table;

  return (
    <>
      <ReportDetails title={t(`Report.${title}`)} description={t(`Report.${description}`)} />

      <main className={styles['mainContent']}>
        <div className={styles['chartFilter']}>
          <ChartDataFilters onFilterChange={handleFilterChange} reportConfig={reportConfig} />
        </div>

        <div className={styles['chartWrapper']}>
          {showLineChart && <LineChart filters={filters} reportConfig={updatedReportConfig} />}
          {showTable && <p>Table: upcoming</p>}
        </div>
      </main>
    </>
  );
};

export default inject('appStore')(ApplicationVolumesReport);

