export const ArrangedFlags = {
  Showroom: 'Showroom',
  Phone: 'Phone',
  Online: 'Online',
  DistanceSale: 'DistanceSale'
};

export const CountryCodes = ['gb', 'de', 'it', 'es', 'pt', 'fr', 'us', 'be', 'se', 'nl', 'za', 'nz'] as const;

export const CountryCodesJS = Object.freeze(['gb', 'de', 'it', 'es', 'pt', 'fr', 'us', 'be', 'se', 'nl', 'za', 'nz']);

export const CountryCodeToSymbol = {
  gb: '£',
  de: '€',
  es: '€',
  fr: '€',
  it: '€',
  pt: '€',
  us: '$',
  be: '€',
  se: 'kr',
  nl: '€',
  za: '£',
  nz: '$'
};

export const CountryCodeToCurrency = {
  gb: 'GBP',
  de: 'EUR',
  es: 'EUR',
  fr: 'EUR',
  it: 'EUR',
  pt: 'EUR',
  us: 'USD',
  be: 'EUR',
  se: 'SEK',
  nl: 'EUR',
  za: 'GBP',
  nz: 'NZD'
};

export const CurrencyCodeToSymbol = {
  GBP: '£',
  EUR: '€',
  USD: '$',
  SEK: 'kr',
  NZD: '$'
};

export const CurrencyCodes = Object.keys(CurrencyCodeToSymbol);

export const VapProductTypes = {
  Unknown: 0,
  GapInsurance: 1,
  TyreOrAlloyInsurance: 2,
  SmartInsurance: 3,
  MotInsurance: 4,
  Warranty: 5,
  OtherInsurance: 6,
  DeliveryCharges: 7,
  AdministrationFee: 8,
  DealerGuarantee: 9,
  PaintOrFabricProtection: 10,
  VehicleAccessories: 11,
  Fuel: 12,
  MotorcycleExtras: 13,
  OtherStandardVatExtras: 14,
  RoadFundLicence: 15,
  MotorcycleHelmet: 16,
  OtherVatExemptExtras: 17,
  ServicePlanInsurance: 18,
  ServicePlan: 19
} as const;
