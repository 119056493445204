import React from 'react';
import PropTypes from 'prop-types';

const LeagueTableGroupedHeader = ({ children }) => (
  <thead>
    <tr>{children}</tr>
  </thead>
);

LeagueTableGroupedHeader.propTypes = {
  children: PropTypes.node
};

export default LeagueTableGroupedHeader;
