import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './panelToolBarLabel.scss';
import DateFormat from '../Common/DateFormat';

const PanelToolBarLabel = ({ customerOrigin, createdDate, originalDealership }) => {
  const { t } = useTranslation('Common');
  return (
    <div className="panelToolBarLabel">
      <div>
        {t('PanelToolBarLabel.first_seen')} {t(`PanelToolBarLabel.${customerOrigin}`, customerOrigin)},{' '}
        <DateFormat value={createdDate} format="DD MMM YYYY" />
      </div>
      {originalDealership && (
        <div>
          {t('PanelToolBarLabel.dealership')} {originalDealership}
        </div>
      )}
    </div>
  );
};

PanelToolBarLabel.propTypes = {
  customerOrigin: PropTypes.string,
  createdDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.number]),
  originalDealership: PropTypes.string
};
export default PanelToolBarLabel;
