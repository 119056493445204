import React from 'react';
import PropTypes from 'prop-types';
import './addressHistoryPanel.scss';
import AddressHistoryPanelItem from './AddressHistoryPanelItem';

const AddressHistoryPanel = ({ addresses }) => (
  <div className="addressHistoryPanel">
    {addresses.map((address, index) => {
      return (
        <div className="addressHistoryPanel__inner" key={'add' + index}>
          <AddressHistoryPanelItem address={address} index={index} key="index" />
        </div>
      );
    })}
  </div>
);

AddressHistoryPanel.propTypes = {
  addresses: PropTypes.array
};

AddressHistoryPanel.defaultProps = {
  addresses: []
};

export default AddressHistoryPanel;
