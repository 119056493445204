import React from 'react';
import PropTypes from 'prop-types';
import { observable, action } from 'mobx';
import ReactDOM from 'react-dom';
import _ from 'lodash';

class MobxForm extends React.Component {
  constructor(props) {
    super(props);
    this.form = observable({
      isSubmitted: false,
      resetFormState: this.resetSubmit
    });
  }

  getChildContext() {
    return { form: this.form };
  }

  componentDidMount() {
    if (this.props.focusOnFirstElement) {
      this.focusOnFirstElement();
    } else if (this.props.focusOnNthElement) {
      this.focusOnNthElement(this.props.focusOnNthElement);
    }
    this.props.resetFunction && this.props.resetFunction(this.resetSubmit);
  }

  focusOnNthElement = (n) => {
    setTimeout(() => {
      let domNode;
      //findDOMNode will throw if called on unmounted component (due to setTimeout)
      try {
        domNode = ReactDOM.findDOMNode(this);
        const els = domNode.querySelectorAll('input,select');
        if (els && els[n - 1]) {
          els[n - 1].focus();
        }
      } catch (e) {console.error(e)}
    }, 10);
  };

  focusOnFirstElement = () => {
    setTimeout(() => {
      //findDOMNode will throw if called on unmounted component (due to setTimeout)
      try {
        const domNode = ReactDOM.findDOMNode(this);
        const els = domNode.querySelectorAll('input,select');
        _.each(els, (el) => {
          if (!el.disabled) {
            el.focus();
            return false;
          }
        });
      } catch (e) {console.error(e)}
    }, 10);
  };

  focusOnFirstError() {
    setTimeout(() => {
      const els = document.querySelectorAll('[data-is-invalid]');
      if (els.length > 0) {
        els[0].focus();
      }
    }, 10);
  }

  @action
  handleSubmit = (event) => {
    event.preventDefault();
    this.form.isSubmitted = true;
    this.focusOnFirstError();
    this.props.onSubmit(event);
  };

  @action
  resetSubmit = () => {
    this.form.isSubmitted = false;
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className={this.props.className} data-th={this.props.dataTh}>
        {this.props.children}
      </form>
    );
  }
}

MobxForm.childContextTypes = {
  form: PropTypes.object
};

MobxForm.propTypes = {
  focusOnFirstElement: PropTypes.bool,
  focusOnNthElement: PropTypes.number,
  resetFunction: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.any,
  onBlur: PropTypes.func,
  handleSave: PropTypes.func,
  className: PropTypes.string
};

export default MobxForm;
