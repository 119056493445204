import { observable, action } from 'mobx';

class LegalDocument {
  @observable name = null;
  @observable html = null;
  @observable metadata = null;

  constructor(name) {
    this.name = name;
  }

  @action
  setDocument(doc) {
    this.html = doc.html;
    this.metadata = doc.metadata;
  }
}

export default LegalDocument;
