import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './dashboardVehicle.scss';
import VehicleImage from '../../Common/VehicleImage';

const DashboardVehicle = ({ vehicle, vehicleImage, imageHeight, imageWidth, pushDeal }) => {
  const { t } = useTranslation('Dashboard');

  if (pushDeal) {
    return (
      <div className="dashboardVehicle">
        {vehicle.vrm ? (
          <div className="dashboardVehicle__reg">
            <div className="dashboardVehicle__regBlue" />
            {vehicle.vrm}
          </div>
        ) : (
          <div className="dashboardVehicle__reg">
            <div className="dashboardVehicle__regBlue" />
            {t('DashboardVehicle.no_reg')}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="dashboardVehicle">
        <div className="dashboardVehicle__imageContainer">
          <VehicleImage
            imageSource={vehicleImage}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            vehicleClass={vehicle.Class}
            iconFontSize="45px"
          />
        </div>

        {vehicle.Vrm ? (
          <div className="dashboardVehicle__reg">
            <div className="dashboardVehicle__regBlue" />
            {vehicle.Vrm}
          </div>
        ) : (
          <div className="dashboardVehicle__reg">
            <div className="dashboardVehicle__regBlue" />
            {t('DashboardVehicle.no_reg')}
          </div>
        )}
        <div>
          {vehicle.VehicleCount > 1 ? (
            <div className="dashboardVehicle__count" title="Other vehicles of interest for this customer">
              +{vehicle.VehicleCount - 1}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
};

DashboardVehicle.propTypes = {
  vehicle: PropTypes.object,
  vehicleImage: PropTypes.string,
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  pushDeal: PropTypes.bool
};
export default DashboardVehicle;
