import React from 'react';
import PropTypes from 'prop-types';
import './assignLeadModalUserListItem.scss';
import classnames from 'classnames';

const AssignLeadModalUserListItem = (props) => {
  let buttonClasses = classnames(
    'assignLeadModalUserListItem__redXButton',
    props.nonEditable && 'assignLeadModalUserListItem__hideButtonBorder'
  );

  let listClasses = classnames(
    'assignLeadModalUserListItem__list',
    props.noBottomBorder && props.isLast && 'assignLeadModalUserListItem__noBottomBorder',
    !props.nonEditable && 'assignLeadModalUserListItem__clickable'
  );

  return (
    <li className={listClasses} data-th="AssignLeadModalUserListItem">
      <span className="assignLeadModalUserListItem__name">
        {props.assignee.firstName} {props.assignee.surname}
      </span>{' '}
      <span className="assignLeadModalUserListItem__email" />
      {!props.nonEditable && (
        <button
          tabIndex={props.tabIndex}
          key="button"
          className={buttonClasses}
          onClick={props.nonEditable ? undefined : props.onClick}
        >
          <div className="assignLeadModalUserListItem__redX" />
        </button>
      )}
    </li>
  );
};

AssignLeadModalUserListItem.propTypes = {
  noBottomBorder: PropTypes.bool,
  nonEditable: PropTypes.bool,
  onClick: PropTypes.func,
  assignee: PropTypes.object,
  isLast: PropTypes.bool,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default AssignLeadModalUserListItem;
