import _ from 'lodash';

const defaultState = {};

export default function userEntityReducer(state = defaultState, action) {
  let newState = state;

  if (action.response && action.response.entities && action.response.entities.users) {
    newState = _.cloneDeep(state);
    _.merge(newState, action.response.entities.users);
  }

  return newState;
}
