const SearchImpressionsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_431_1049)">
        <path
          d="M17.5212 12.5C17.5212 15.692 14.9095 18.282 11.6906 18.282C9.71417 18.282 7.55417 17.19 6.89064 15.888C6.89064 15.888 1.93534 15.874 1.94946 15.888C0.269459 15.888 0.368283 14.376 0.368283 13.942L0.396518 2.07003C0.396518 1.21603 1.08828 0.530029 1.94946 0.530029H12.8483C13.7095 0.530029 14.4012 1.21603 14.4012 2.07003V7.36203C15.3433 7.85607 16.1317 8.59557 16.6815 9.50093C17.2312 10.4063 17.5216 11.4432 17.5212 12.5ZM12.8483 2.07003H1.94946V13.942H6.04358C6.04358 13.942 5.97299 13.676 5.95887 13.662C5.80358 12.892 5.80358 12.108 5.95887 11.338H4.27887C3.85534 11.338 3.5024 10.988 3.5024 10.568C3.5024 10.148 3.85534 9.79803 4.27887 9.79803H6.50946C7.72358 7.53003 10.293 6.31203 12.8342 6.83003C12.8483 4.29603 12.8483 2.07003 12.8483 2.07003ZM3.5024 7.47403C3.5024 7.05403 3.84122 6.70403 4.27887 6.70403H5.83181C6.25534 6.70403 6.60828 7.05403 6.60828 7.47403C6.60828 7.89403 6.25534 8.24403 5.83181 8.24403H4.27887C3.85534 8.24403 3.51652 7.90803 3.5024 7.47403ZM3.5024 4.39403C3.5024 3.97403 3.85534 3.62403 4.27887 3.62403H10.5189C10.9424 3.62403 11.2953 3.97403 11.2953 4.39403C11.2953 4.81403 10.9424 5.16403 10.5189 5.16403H4.27887C3.85534 5.16403 3.5024 4.81403 3.5024 4.39403ZM11.6765 16.742C14.0342 16.742 15.9542 14.838 15.9542 12.5C15.9542 10.162 14.0342 8.25803 11.6765 8.25803C9.31887 8.25803 7.39887 10.162 7.39887 12.5C7.39887 14.838 9.31887 16.742 11.6765 16.742ZM10.3495 14.964L8.79652 13.424C8.48593 13.13 8.47181 12.64 8.76828 12.332C9.06475 12.024 9.55887 12.01 9.86946 12.304L9.91181 12.346L10.9283 13.354L13.4836 10.806C13.7942 10.498 14.2742 10.498 14.5848 10.806C14.8953 11.114 14.8953 11.59 14.5848 11.898L11.4648 14.992C11.1542 15.272 10.66 15.272 10.3495 14.964Z"
          fill="#30364E"
        />
      </g>
      <defs>
        <clipPath id="clip0_431_1049">
          <rect width="18" height="18.75" fill="white" transform="translate(0.25)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchImpressionsIcon;
