import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  draft.applicationFields.EmploymentHistory!.GrossAnnual = true;
  draft.applicationFields.AffordabilityDetails!.ForeseeFutureDownturnInFinances = false;
  draft.applicationFields.AffordabilityDetails!.DownturnReason = false;
  draft.applicationFields.AffordabilityDetails!.IsReplacementLoan = false;
  draft.applicationFields.AffordabilityDetails!.DetailsOfReplacementLoan = false;
  draft.applicationFields.AffordabilityDetails!.ChangesExpected = true;
  draft.applicationFields.AffordabilityDetails!.DetailsOfExpectedChanges = true;
  draft.applicationFields.AffordabilityDetails!.NetMonthly = false;

  draft.validationRules.EmploymentHistory!.Items![0]!.Postcode = 'required, alphaNumSpace';
  draft.validationRules.EmploymentHistory!.Items![0]!.TimeAtEmployment!.Years = 'required, number:int';
  draft.validationRules.EmploymentHistory!.Items![0]!.GrossAnnual = 'currency';
  draft.validationRules.EmploymentHistory!.totalMonths = 'min:36';
  delete draft.validationRules.AffordabilityDetails!.Income!.NetMonthly;
  delete draft.validationRules.AffordabilityDetails!.ReplacementLoan;
  delete draft.validationRules.AffordabilityDetails!.PersonalCircumstances!.ForeseeFutureDownturnInFinances;
  delete draft.validationRules.AffordabilityDetails!.PersonalCircumstances!.DownturnReason;
  draft.validationRules.AffordabilityDetails!.PersonalCircumstances!.ChangesExpected = 'required';
});
