import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './eligibilityResultModal.scss';
import Button from '../../Common/Button/Button';
import { getDecisionMessages, getMatchRateFromScore, hasCreditPolicyRule } from '../../../core/decisionMessages';
import EligibilityModalGaugeSection from '../components/EligibilityModalGaugeSection';
import EligibilityModalRuleList from '../components/EligibilityModalRuleList';
import Modal from '../../Common/Modal/Modal';
import gaugeBackgroundMedium from '../../../styles/icons/dial.svg';
import gaugeBackgroundHigh from '../../../styles/icons/dial_high.svg';
import gaugeBackgroundLow from '../../../styles/icons/dial_low.svg';

const EligibilityResultModal = (props) => {
  const { t } = useTranslation('Quoting');
  let matchRate = getMatchRateFromScore(props.matchrate);
  let hasCreditPolicyMessage = hasCreditPolicyRule(props.rules);
  let decisionMessages = getDecisionMessages(props.rules);

  return (
    <Modal isOpen={props.isOpen} onClose={props.close}>
      <div>
        {matchRate === 'high' && (
          <div>
            <div className="eligibilityResultModal__title">{t('EligibilityResultModal.high_eligibility_result')}</div>
            <EligibilityModalGaugeSection
              matchrate={props.matchrate}
              gaugeBkg={gaugeBackgroundHigh}
              header={t('EligibilityResultModal.it_looks_like_your_customer_would_be_approved')}
              paragraphOne={t(
                'EligibilityResultModal.the_enquiry_results_have_indicated_that_if_your_customer_were_to_apply_for_credit_against_this_quote_and_lender_there_is_a_high_chance_of_approval'
              )}
              paragraphThree={t(
                'EligibilityResultModal.please_note_the_indication_of_credit_is_indicative_only_and_is_subject_to_a_full_application_and_credit_check_with_the_respective_lender'
              )}
            />
          </div>
        )}

        {matchRate === 'medium' && (
          <div>
            <div className="eligibilityResultModal__title">{t('EligibilityResultModal.medium_eligibility_result')}</div>
            <EligibilityModalGaugeSection
              matchrate={props.matchrate}
              gaugeBkg={gaugeBackgroundMedium}
              header={t('EligibilityResultModal.it_looks_like_your_customer_would_be_approved')}
              paragraphOne={t(
                'EligibilityResultModal.the_enquiry_results_have_indicated_that_if_your_customer_were_to_apply_for_credit_against_this_quote_and_lender_there_is_a_medium_chance_of_approval'
              )}
              paragraphTwo={t(
                'EligibilityResultModal.there_may_be_a_few_aspects_that_we_are_uncertain_about_based_on_either_your_customers_profile_or_the_structure_of_the_deal'
              )}
              paragraphThree={t(
                'EligibilityResultModal.please_note_the_indication_of_credit_is_indicative_only_and_is_subject_to_a_full_application_and_credit_check_with_the_respective_lender'
              )}
            />
          </div>
        )}

        {matchRate === 'low' && hasCreditPolicyMessage && (
          <div>
            <div className="eligibilityResultModal__title">{t('EligibilityResultModal.low_eligibility_result')}</div>
            <EligibilityModalGaugeSection
              matchrate={props.matchrate}
              gaugeBkg={gaugeBackgroundLow}
              header={t('EligibilityResultModal.it_looks_unlikely_this_lender_can_help_with_this_quote')}
              paragraphOne={t(
                'EligibilityResultModal.your_customers_results_have_indicated_there_is_a_low_chance_of_approval_for_this_lender_and_quote'
              )}
              paragraphTwo={t(
                'EligibilityResultModal.this_may_be_based_on_either_the_customer_profile_or_the_structure_of_the_deal'
              )}
              paragraphThree={t(
                'EligibilityResultModal.please_note_the_indication_of_credit_is_indicative_only_and_a_full_application_to_the_lender_may_return_a_more_positive_outcome'
              )}
              paragraphFour={t('EligibilityResultModal.it_may_be_worth_considering_other_lenders_and_or_quotes')}
            />
          </div>
        )}

        {matchRate === 'low' && !hasCreditPolicyMessage && (
          <div>
            <div className="eligibilityResultModal__title">{t('EligibilityResultModal.low_eligibility_result')}</div>
            <EligibilityModalGaugeSection
              matchrate={props.matchrate}
              gaugeBkg={gaugeBackgroundLow}
              header={t(
                'EligibilityResultModal.based_on_the_structure_of_this_deal_it_looks_unlikely_this_lender_can_help'
              )}
              paragraphOne={t(
                'EligibilityResultModal.your_enquiry_results_have_indicated_that_there_is_a_low_chance_of_approval_for_this_lender_and_quote'
              )}
              paragraphTwo={t(
                'EligibilityResultModal.this_may_be_based_on_either_the_customer_profile_or_the_structure_of_the_deal'
              )}
              paragraphThree={t(
                'EligibilityResultModal.please_note_the_indication_of_credit_is_indicative_only_and_a_full_application_to_the_lender_may_return_a_more_positive_outcome'
              )}
              paragraphFour={t(
                'EligibilityResultModal.however_based_on_this_lenders_vehicle_policies_a_change_of_vehicle_may_help_to_achieve_an_improved_outcome'
              )}
            />
          </div>
        )}

        <EligibilityModalRuleList decisionMessages={decisionMessages} />
        <div className="eligibilityResultModal__closeContainer">
          <div className="eligibilityResultModal__button" key="btn">
            <Button type="button" onClick={() => props.close()} buttonStyle="cancel">
              {t('EligibilityResultModal.close')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

EligibilityResultModal.defaultProps = {
  rules: []
};
EligibilityResultModal.propTypes = {
  matchrate: PropTypes.number,
  rules: PropTypes.array,
  close: PropTypes.func,
  isOpen: PropTypes.bool
};
export default EligibilityResultModal;
