import React from 'react';
import PropTypes from 'prop-types';
import './horizontalScrollIndicator.scss';
import HorizontalScrollIndicatorCircle from './HorizontalScrollIndicatorCircle';
import Icon from '../Icon/Icon';

class HorizontalScrollIndicator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0
    };

    this.state.circleActiveStates = this.getCircleActiveStates(
      props.scrollFraction,
      props.numCircles,
      props.numActiveCircles
    );
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.scrollFraction !== this.props.scrollFraction) {
      this.setState({
        circleActiveStates: this.getCircleActiveStates(
          nextProps.scrollFraction,
          nextProps.numCircles,
          nextProps.numActiveCircles
        )
      });
    }
  }

  getCircleActiveStates = (scrollFraction, numCircles, numActiveCircles) => {
    const circleActiveStates = [];

    for (let i = 0; i < numCircles; i++) {
      circleActiveStates.push(this.isCircleActive(i, scrollFraction, numCircles, numActiveCircles));
    }

    return circleActiveStates;
  };

  isCircleActive = (index, scrollFraction, numCircles, numActiveCircles) => {
    const activeIndex = Math.floor(scrollFraction * (numCircles - 1));
    return Math.abs(activeIndex - index) < numActiveCircles - 1;
  };

  render() {
    return (
      <div className="horizontalScrollIndicator">
        <div className="horizontalScrollIndicator__leftArrow" onClick={this.props.onClickLeft}>
          <Icon name="down" />
        </div>
        <div className="horizontalScrollIndicator__indicatorContainer">
          {this.state.circleActiveStates.map((isActive, index) => (
            <HorizontalScrollIndicatorCircle key={index} index={index} isActive={isActive} />
          ))}
        </div>
        <div className="horizontalScrollIndicator__rightArrow" onClick={this.props.onClickRight}>
          <Icon name="down" />
        </div>
      </div>
    );
  }
}

HorizontalScrollIndicator.defaultProps = {
  numCircles: 6,
  numActiveCircles: 3
};

HorizontalScrollIndicator.propTypes = {
  scrollFraction: PropTypes.number,
  numCircles: PropTypes.number,
  numActiveCircles: PropTypes.number,
  onClickLeft: PropTypes.func,
  onClickRight: PropTypes.func
};

export default HorizontalScrollIndicator;
