import React from 'react';
import PropTypes from 'prop-types';
import ScrollableTable from '../../../Common/ScrollableTable/ScrollableTable';
import './monthlyPriceTable.scss';

const MonthlyPriceTable = ({ children }) => {
  return (
    <ScrollableTable scrollAmountInPx={250}>
      <table className="monthlyPriceTable">{children}</table>
    </ScrollableTable>
  );
};

MonthlyPriceTable.propTypes = {
  children: PropTypes.node
};

export default MonthlyPriceTable;
