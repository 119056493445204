import { convertMarkdownToHtml } from '../core/helpers';
import * as api from '../api/legalDocuments';
import Request from '../mobx-models/Request';
import LegalDocument from '../mobx-models/LegalDocument';
import { observable, action, runInAction } from 'mobx';
import i18n from '../i18n';
import appStore from './appStore';

export const createDocumentUrl = (product, docPath, withCountryCode) => {
  return withCountryCode
    ? `${product}/${appStore.uiState.countryCode}/${docPath}-${i18n.language}`
    : `${product}/${docPath}-${i18n.language}`;
};

class LegalDocumentStore {
  @observable fpnDocuments = {};
  @observable marketingDocuments = {};
  @observable goodPracticeDocs = {};
  @observable goodPracticeDocsVersion;
  @observable CrainDisclaimer;
  @observable reserveConditions;
  @observable reserveConditionsVersion;

  fetchGoodPracticeDocsRequest = new Request();

  @action
  fetchGoodPracticeDocs = () => {
    return this.fetchGoodPracticeDocsRequest
      .onSuccess(
        action((response) => {
          const gpd = convertMarkdownToHtml(response);
          this.goodPracticeDocs = gpd;
          this.goodPracticeDocsVersion = this.goodPracticeDocs.metadata.Version;
        })
      )
      .call(api.loadDocument, createDocumentUrl('dp', 'good-practice'));
  };

  // @action
  fetchCrainDisclaimer = (lender) => {
    return new Promise((resolve, reject) => {
      if (this.CrainDisclaimer) {
        resolve(this.CrainDisclaimer);
        return;
      }

      api
        .loadDocument(`dp/cfc-crain-disclaimer`)
        .then(
          action((response) => {
            let document = new LegalDocument('cfc-crain-disclaimer');

            document.setDocument(convertMarkdownToHtml(response));
            this.CrainDisclaimer = document;
            resolve(document);
          })
        )
        .catch((error) => reject(error));
    });
  };

  @action
  fetchLenderMarketing = (lender) => {
    return new Promise((resolve, reject) => {
      if (this.marketingDocuments[lender]) {
        resolve(this.marketingDocuments[lender]);
        return;
      }

      api
        .loadDocument(`lender/marketing/${lender}`)
        .then((response) => {
          let document = new LegalDocument(lender);
          document.setDocument(convertMarkdownToHtml(response));
          this.marketingDocuments[lender] = document;
          resolve(document);
        })
        .catch((error) => reject(error));
    });
  };

  getLenderMarketing(lender) {
    return this.marketingDocuments[lender];
  }

  @action
  fetchReserveConditions = () => {
    return api.loadDocument(createDocumentUrl('dp', 'reserve-terms', true)).then((response) => {
      let document = new LegalDocument('reserve-terms');
      document.setDocument(convertMarkdownToHtml(response));
      runInAction(() => {
        this.reserveConditions = document;
      }); // workaround for mobx getting pissy about thenables sometimes
      return document;
    });
  };

  getReserveConditions() {
    return this.reserveConditions;
  }
}

export default LegalDocumentStore;
