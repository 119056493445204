import { useEffect, useState } from 'react';
import { inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';

import ToggleSwitch from '../../../components/Common/ToggleSwitch';
import BroadcastStockListContainer from '../../BroadcastStockListContainer';
import Checkbox from '../../../components/Common/Form/Checkbox';
import Button from '../../../components/Common/Button/Button';
import Modal from '~Common/Modal/Modal';
import Icon from '~/components/Common/Icon/Icon';

import classes from './broadcastForm.module.scss';

const BroadcastForm = ({
  dealershipId,
  formData,
  handleClassUpdate,
  handleDeselectAllVehicles,
  handleFilterSelectUpdate,
  handleInputChange,
  handleOnCancel,
  handleOnSubmit,
  handleSelectAllVehicles,
  handleSelectUpdate,
  handleSelectVehicle,
  handleToggleVehicleFilter,
  hasInputErrors,
  hasVehicleFilter,
  priceRange,
  recipientOptions,
  selectedVehicles,
  subscriptionId,
  yearRange
}) => {
  const [showModal, setShowModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation('BroadcastSettings');
  const cannotSubmit = hasInputErrors.priceFilter || hasInputErrors.ageFilter;
  const canSetFpaUrlTemplate = formData.format === 'GVAtab';

  const handleCopyClick = (e) => {
    e.preventDefault();
    copy(formData?.dealerDownloadLocation);
    setCopied(true);
  };

  useEffect(() => {
    if (!copied) {
      return;
    }
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }, [copied]);

  return (
    <>
      <div className={classes.container}>
        <form onSubmit={handleOnSubmit}>
          {subscriptionId === 'new' && (
            <div className={classes.recipientOptions}>
              <label htmlFor="recipient">{t('BroadcastSettings.broadcast_recipient')}</label>
              <select
                value={formData.recipient}
                className={classes.selectInput}
                name="recipient"
                onChange={handleSelectUpdate}
              >
                {recipientOptions.map((option) => (
                  <option key={option.broadcastRecipientId} value={option.broadcastRecipientId}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {canSetFpaUrlTemplate ? (
            <div className={classes.fpaUrlTemplate}>
              <div className={classes.labelWithButton}>
                <label htmlFor="fpaUrlTemplate">
                  {t('BroadcastSettings.fpa_url_template')}
                  <span className={classes.requiredStar}>*</span>
                </label>
                <button
                  className={classes.modalButton}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowModal(true);
                  }}
                >
                  <Icon name="question" />
                </button>
              </div>
              <input
                placeholder="https://www.acmemotors.co.uk/{{location}}/{{make}}/{{model}}/id-{{id}}"
                name="fpaUrlTemplate"
                value={formData.fpaUrlTemplate}
                required={true}
                onChange={handleInputChange}
              />
              <span className={classes.inputError}>
                {hasInputErrors.fpaUrlTemplate && hasInputErrors.fpaUrlTemplate}
              </span>
            </div>
          ) : null}

          {canSetFpaUrlTemplate && formData.dealerDownloadLocation ? (
            <div className={classes.dealerDownloadLocationWrapper}>
              <p> {t('BroadcastSettings.You_can_find_your_feed_at_the_following_location')}</p>
              <div className={classes.dealerDownloadLocationInputButton}>
                <Button
                  className={classes.copyButton}
                  buttonStyle="secondary"
                  onClick={handleCopyClick}
                  dataThook={'amend'}
                  type="button"
                >
                  <span>{copied ? t('BroadcastSettings.copied') : t('BroadcastSettings.copy')}</span>
                </Button>
                <input disabled className={classes.dealerDownloadLocation} value={formData.dealerDownloadLocation} />
              </div>
              <p>
                {t(
                  'BroadcastSettings.This_should_be_configured_as_your_product_feed_in_your_Google_Merchant_Centre_account'
                )}
              </p>
            </div>
          ) : null}

          <div className={classes.filterOptions}>
            <div className={classes.titleRow}>
              <span className={classes.stockFilterSwitch}>
                <label>{t('BroadcastSettings.use_stock_filter')}</label>
                <ToggleSwitch
                  isActive={hasVehicleFilter}
                  defaultValue={false}
                  handleUpdate={handleToggleVehicleFilter}
                />
              </span>
            </div>

            {hasVehicleFilter ? (
              <BroadcastStockListContainer
                dealershipId={dealershipId}
                onSelectVehicle={handleSelectVehicle}
                selectedVehicles={selectedVehicles}
                selectAll={handleSelectAllVehicles}
                deselectAll={handleDeselectAllVehicles}
              />
            ) : (
              <>
                <div>
                  <label>
                    <strong>{t('BroadcastSettings.class_filters')}</strong>
                  </label>
                  <div className={classes.classFilters}>
                    <div className={classes.classSwitch}>
                      <label htmlFor={`includeCar`}>{t('BroadcastSettings.class_filter_car')}</label>
                      <Checkbox
                        value={formData.classFilter ? formData.classFilter.includeCar : false}
                        name="includeCar"
                        onChange={handleClassUpdate('includeCar')}
                      />
                    </div>

                    <div className={classes.classSwitch}>
                      <label htmlFor={`includeMotorbike`}>{t('BroadcastSettings.class_filter_bike')}</label>
                      <Checkbox
                        value={formData.classFilter ? formData.classFilter.includeMotorbike : false}
                        name="includeMotorbike"
                        onChange={handleClassUpdate('includeMotorbike')}
                      />
                    </div>

                    <div className={classes.classSwitch}>
                      <label htmlFor={`includeVan`}>{t('BroadcastSettings.class_filter_lcv')}</label>
                      <Checkbox
                        value={formData.classFilter ? formData.classFilter.includeLcv : false}
                        name="includeVan"
                        onChange={handleClassUpdate('includeLcv')}
                      />
                    </div>

                    <div className={classes.classSwitch}>
                      <label htmlFor={`includeMotorhome`}>{t('BroadcastSettings.class_filter_motor_home')}</label>
                      <Checkbox
                        value={formData.classFilter ? formData.classFilter.includeMotorhome : false}
                        name="includeMotorhome"
                        onChange={handleClassUpdate('includeMotorhome')}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.rangeFilter}>
                  <label>{t('BroadcastSettings.age_filter')}</label>
                  <div>
                    <select
                      className={classes.selectInput}
                      name="min_age"
                      value={(formData.ageFilter && formData.ageFilter.min) || ''}
                      onChange={handleFilterSelectUpdate}
                    >
                      <option value={''}>{t('BroadcastSettings.no_min_age')}</option>
                      {yearRange.map((value) => (
                        <option key={value.value} value={value.value}>
                          {value.tag}
                        </option>
                      ))}
                    </select>
                    <select
                      className={classes.selectInput}
                      name="max_age"
                      value={(formData.ageFilter && formData.ageFilter.max) || ''}
                      onChange={handleFilterSelectUpdate}
                    >
                      <option value={''}>{t('BroadcastSettings.no_max_age')}</option>
                      {yearRange.map((value) => (
                        <option key={value.value} value={value.value}>
                          {value.tag}
                        </option>
                      ))}
                    </select>
                  </div>
                  <span className={classes.inputError}>
                    {hasInputErrors.ageFilter && t('BroadcastSettings.minimum_age_cannot_be_greater_than_maximum_age')}
                  </span>
                </div>
                <div className={classes.rangeFilter}>
                  <label>{t('BroadcastSettings.price_filter')}</label>
                  <div>
                    <select
                      className={classes.selectInput}
                      name="min_price"
                      value={formData.priceFilter?.min || ''}
                      onChange={handleFilterSelectUpdate}
                    >
                      <option value={''}>{t('BroadcastSettings.no_min_price')}</option>
                      {priceRange.map((value) => (
                        <option key={value.value} value={value.value}>
                          {value.tag}
                        </option>
                      ))}
                    </select>

                    <select
                      className={classes.selectInput}
                      name="max_price"
                      value={formData.priceFilter?.max || ''}
                      onChange={handleFilterSelectUpdate}
                    >
                      <option value={''}>{t('BroadcastSettings.no_max_price')}</option>
                      {priceRange.map((value) => (
                        <option key={value.value} value={value.value}>
                          {value.tag}
                        </option>
                      ))}
                    </select>
                  </div>
                  <span className={classes.inputError}>
                    {hasInputErrors.priceFilter &&
                      t('BroadcastSettings.minimum_price_cannot_be_greater_than_maximum_price')}
                  </span>
                </div>
              </>
            )}
          </div>
          {hasVehicleFilter && (
            <div className={classes.buttonGroup}>
              <Button
                className={classes.button}
                buttonStyle="cancel"
                type="button"
                name="cross"
                onClick={handleOnCancel}
              >
                {t('BroadcastSettings.cancel')}
              </Button>
              <Button
                className={[classes.button, classes.primaryButtonColor]}
                onClick={handleOnSubmit}
                disabled={cannotSubmit}
              >
                {t('BroadcastSettings.save')}
              </Button>
            </div>
          )}
        </form>
      </div>
      <Modal title={t('BroadcastSettings.fpa_url_template')} onClose={() => setShowModal(false)} isOpen={showModal}>
        <>
          <p>
            {t(
              "BroadcastSettings.Your_vehicle_pages_are_managed_using_the_FPA_URL_Template_When_we_create_feeds_that_include_your_vehicles,_they_contain_a_link_to_a_page_with_the_vehicle's_details_on_your_website_This_page_is_called_the_Full_Page_Advert_(FPA)"
            )}
          </p>
          <p>
            {t(
              'BroadcastSettings.The_FPA_for_each_vehicle_on_your_site_will_have_a_URL_structure_unique_to_your_site_The_FPA_URL_Template_allows_you_to_configure_your_feeds_to_direct_customers_to_the_correct_page_on_your_site_This_template_is_highly_flexible,_enabling_any_combination_of_static_text_and_a_selection_of_vehicle_attributes'
            )}
          </p>
          <p>
            {t(
              'BroadcastSettings.You_can_utilize_dynamic_data,_which_is_determined_per_vehicle_The_following_are_accepted'
            )}
          </p>
          <ul>
            <li>
              {' '}
              <strong>{`{{make}}`}</strong>: {t('BroadcastSettings.Vehicle_make')}
            </li>
            <li>
              {' '}
              <strong>{`{{model}}`}</strong>:{t('BroadcastSettings.Vehicle_model')}
            </li>
            <li>
              {' '}
              <strong>{`{{id}}`}</strong>: {t('BroadcastSettings.Your_Vehicle_Id_(often_provided_in_a_feed)')}{' '}
              <strong>{`{{rmid}}`}</strong>: {t('BroadcastSettings.iVendi_Vehicle_Id')}{' '}
              <strong>{`{{location}}`}</strong>: {t('BroadcastSettings.Town_of_the_dealership_the_vehicle_is_in')}
            </li>
            <li>
              {' '}
              <strong>{`{{deriv}}`}</strong>: {t('BroadcastSettings.Vehicle_derivative')}
            </li>
            <li>
              {' '}
              <strong>{`{{doors}}`}</strong>: {t('BroadcastSettings.Number_of_doors')}
            </li>
            <li>
              {' '}
              <strong>{`{{bodystyle}}`}</strong>: {t('BroadcastSettings.Vehicle_body_style')}
            </li>
            <li>
              {' '}
              <strong>{`{{colour}}`}</strong>: {t('BroadcastSettings.Vehicle_colour')}
            </li>
            <li>
              {' '}
              <strong>{`{{vrm}}`}</strong>: {t('BroadcastSettings.Vehicle_registration')}
            </li>
            <li>
              {' '}
              <strong>{`{{trans}}`}</strong>: {t('BroadcastSettings.Vehicle_transmission')}
            </li>
            <li>
              {' '}
              <strong>{`{{fuel}}`}</strong>: {t('BroadcastSettings.Vehicle_fuel_type')}
            </li>
            <li>
              {' '}
              <strong>{`{{hdlne}}`}</strong>: {t('BroadcastSettings.Vehicle_Attention_Grabber')}
            </li>
          </ul>
          <p>
            {t(
              'BroadcastSettings.For_example_if_your_current_URL_structure_for_a_Ford_Fiesta_located_in_Colwyn_Bay_with_an_ID_of_123_reads'
            )}
            :
          </p>
          <code className={classes.highlightedText}>
            https://acmemotors.co.uk/<strong>colwyn-bay</strong>/<strong>ford</strong>/<strong>fiesta</strong>/id-
            <strong>123</strong>/
          </code>

          <p>{t("BroadcastSettings.Then_the_'FPA_Url_Template'_would_read")}:</p>
          <code className={classes.highlightedText}>
            https://acmemotors.co.uk/<strong>{`{{location}}`}</strong>/<strong>{`{{make}}`}</strong>/
            <strong>{`{{model}}`}</strong>/id-<strong>{`{{id}}`}</strong>
          </code>
        </>
      </Modal>
    </>
  );
};

export default inject('appStore')(BroadcastForm);
