import { DownArrow, UpArrow } from '../svgs';

type Props = {
  change: number;
};

const Arrow = (props: Props) => {
  const { change } = props;

  return <div>{change > 0 ? <UpArrow /> : <DownArrow />}</div>;
};

export default Arrow;
