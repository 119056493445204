import { useTranslation } from 'react-i18next';
import './forgotPasswordRequested.scss';
import Button from '../Common/Button/Button';

const ForgotPasswordRequested = () => {
  const { t } = useTranslation('ForgotPassword');
  return (
    <div className="forgotPasswordRequested">
      <h1 className="forgotPasswordRequested__header">{t('ForgotPasswordRequested.thank_you')}</h1>
      <p className="forgotPasswordRequested__text">
        {t('ForgotPasswordRequested.if_your_email_address_matches_a_registered_account')}
      </p>

      <ul className="forgotPasswordRequested__list">
        <li>{t('ForgotPasswordRequested.most_people_receive_their_emails_promptly')}</li>
        <li>{t('ForgotPasswordRequested.please_check_your_junk_or_your_spam_folder')}</li>
        <li>{t('ForgotPasswordRequested.a_new_request_will_invalidate_previous_emails_sent')}</li>
      </ul>

      <p className="forgotPasswordRequested__text">
        {t('ForgotPasswordRequested.still_need_help_please_contact_your_systems_administrator')}
      </p>

      <div className="forgotPasswordRequested__actions">
        <Button to="/auth/signin">{t('ForgotPasswordRequested.back_to_sign_in')}</Button>
      </div>
    </div>
  );
};

export default ForgotPasswordRequested;
