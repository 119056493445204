import LeagueTableStore from './LeagueTableStore';
import ReportsStore from './ReportsStore';
import AuthStore from './AuthStore';
import UiState from './UiState';
import UserStore from './UserStore';
import QuickQuoteStore from './QuickQuoteStore';
import CustomerListStore from './CustomerListStore';
import AssignLeadStore from './AssignLeadStore';
import VapStore from './VapStore';
import CloseDealStore from './CloseDealStore';
import CustomerStore from './CustomerStore';
import QuotingStore from './QuotingStore';
import LegalDocumentStore from './LegalDocumentStore';
import SessionStore from './SessionStore';
import DeleteCustomerStore from './DeleteCustomerStore';
import StockStore from './StockStore';
import ReserveNowStore from './ReserveNowStore';
import RepExampleStore from './RepExampleStore';
import PushDealStore from './PushDealStore';
import CustomerHistoryStore from './CustomerHistoryStore';
import OrdersStore from './OrdersStore';
import ApplicationStatusStore from './ApplicationStatusStore';
import SentDealsStore from './SentDealsStore';
import StockCreateStore from './StockCreateStore';
import StockEditStore from './StockEditStore';
import NotificationStore from './NotificationStore';
import DashboardQuotesStore from './DashboardQuotesStore/';

class AppStore {
  authStore;
  uiState;
  reportsStore;
  featureFlagStore;
  leagueTableStore;
  userStore;
  quickQuoteStore;
  customerListStore;
  assignLeadStore;
  vapStore;
  closeDealStore;
  customerStore;
  quotingStore;
  legalDocumentStore;
  sessionStore;
  deleteCustomerStore;
  stockStore;
  stockCreateStore;
  stockEditStore;
  reserveNowStore;
  notificationStore;
  repExampleStore;
  applicationStatusStore;
  pushDealStore;
  customerHistoryStore;
  sentDealsStore;
  dashboardQuotesStore;
  ordersStore;

  constructor() {
    this.authStore = new AuthStore();
    this.uiState = new UiState();
    this.reportsStore = new ReportsStore();
    this.leagueTableStore = new LeagueTableStore();
    this.userStore = new UserStore();
    this.quickQuoteStore = new QuickQuoteStore();
    this.customerListStore = new CustomerListStore(this);
    this.assignLeadStore = new AssignLeadStore(this);
    this.vapStore = new VapStore(this);
    this.closeDealStore = new CloseDealStore();
    this.customerStore = new CustomerStore();
    this.quotingStore = new QuotingStore();
    this.legalDocumentStore = new LegalDocumentStore();
    this.sessionStore = new SessionStore();
    this.deleteCustomerStore = new DeleteCustomerStore();
    this.stockStore = new StockStore(this.uiState);
    this.stockCreateStore = new StockCreateStore();
    this.stockEditStore = new StockEditStore();
    this.notificationStore = new NotificationStore();
    this.reserveNowStore = new ReserveNowStore();
    this.repExampleStore = new RepExampleStore();
    this.pushDealStore = new PushDealStore(this);
    this.customerHistoryStore = new CustomerHistoryStore();
    this.applicationStatusStore = new ApplicationStatusStore();
    this.sentDealsStore = new SentDealsStore();
    this.dashboardQuotesStore = new DashboardQuotesStore();
    this.ordersStore = new OrdersStore(this);
  }
}

export default new AppStore();
