import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import Breadcrumbs from '../../Common/Breadcrumbs';
import Page from '../../Common/Page';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import VehicleForm from '../../Common/forms/VehicleForm';
import { withRouter } from '../../../hocs/router';
import VisQuotePage from '../../VisQuoting/VisQuotePage';
import { compose } from 'redux';
import { inject, observer } from 'mobx-react';

class AddVehicle extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    dealerships: PropTypes.array,
    handleAddVehicle: PropTypes.func,
    submittingState: PropTypes.string,
    submitError: PropTypes.string
  };

  constructor() {
    super();
    this.state = {
      vehicleClass: '',
      defaults: {}
    };
  }

  componentDidMount() {
    this.props.clearErrors();
    this.getDefaultSettings();
  }

  onSubmitSuccess = (formData) => {
    this.props.handleAddVehicle(
      formData,
      this.props.customer.Id,
      this.props.customer.CustomerType,
      this.props.params.dealershipId
    );
  };

  cancel = () => {
    const { dealershipId, consumerId } = this.props.params;
    this.props.navigate(`/d/${dealershipId}/consumers/${consumerId}`);
  };

  getDefaultSettings = () => {
    const settings = _.chain(this.props.dealerships)
      .find({ Id: this.props.params.dealershipId })
      .get('Settings')
      .value();

    if (this.props.query.class) {
      settings.VehicleClass = this.props.query.class.toLowerCase();
    } else {
      settings.VehicleClass.toLowerCase();
    }

    let data = {
      ...this.state.defaults,
      Condition: settings.VehicleType,
      Class: settings.VehicleClass,
      AnnualDistance: settings.DefaultAnnualMileage,
      RegistrationDate:
        settings.VehicleType === 'new'
          ? moment()
              .subtract(6, 'months')
              .format('DD/MM/YYYY')
          : ''
    };

    if (this.props.query.vrm) {
      data.Vrm = this.props.query.vrm.toUpperCase();
    }

    if (this.props.query.mileage) {
      data.Mileage = this.props.query.mileage.toString();
    }

    if (this.props.query.price) {
      data.Price = this.props.query.price;
    }

    this.setState({ defaults: data });
  };

  getErrorMessage(error) {
    if (error === 'VehicleAlreadyExists') {
      return this.props.t('AddVehicle.vehicle_already_exists');
    } else if (error === 'AddVehicleError') {
      return this.props.t('AddVehicle.add_vehicle_error');
    } else {
      return null;
    }
  }

  updateVehicleClass = (vehicleClass) => {
    this.setState((prevState) => {
      return { vehicleClass: vehicleClass, defaults: { ...prevState.defaults, Class: vehicleClass } };
    });
  };

  renderForm = () => {
    const { defaults } = this.state;

    if (this.props.appStore.uiState.shouldUseVisLookup(this.state.vehicleClass || defaults.Class)) {
      return (
        <VisQuotePage
          onCancel={this.handleCancel}
          onSubmit={this.onSubmitSuccess}
          onUpdateVehicleClass={this.updateVehicleClass}
          initialData={defaults}
          dealershipId={this.props.params.dealershipId}
        />
      );
    }

    return (
      <VehicleForm
        onCancel={this.cancel}
        onSubmit={this.onSubmitSuccess}
        initialData={defaults}
        submittingState={this.props.submittingState}
        errorMessage={this.getErrorMessage(this.props.submitError)}
        onUpdateVehicleClass={this.updateVehicleClass}
        dealershipId={this.props.params.dealershipId}
      />
    );
  };

  render() {
    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: this.props.t('AddVehicle.home'),
              path: `/d/${this.props.params.dealershipId}`
            },
            {
              name: this.props.t('AddVehicle.customer_list'),
              path: `/d/${this.props.params.dealershipId}/consumers`
            },
            {
              name: 'Consumer',
              path: `/d/${this.props.params.dealershipId}/consumers/${this.props.customer.Id}`
            },
            {
              name: this.props.t('AddVehicle.add_customer_vehicle'),
              path: `/d/${this.props.params.dealershipId}/consumers/${this.props.customer.Id}/add`
            }
          ]}
          consumer={this.props.customer}
        />

        <Panel>
          <PanelHeader>{this.props.t('AddVehicle.add_customer_vehicle')}</PanelHeader>

          <PanelContent>{this.renderForm()}</PanelContent>
        </Panel>
      </Page>
    );
  }
}

export default compose(withRouter, withTranslation('CustomerList'), inject('appStore'), observer)(AddVehicle);
