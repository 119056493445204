import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PlatformUpdatesIndicator from '../PlatformUpdates/PlatformUpdatesIndicator';
import Icon from '../Common/Icon/Icon';
import { Link } from 'react-router-dom';
import './whatsNewSidebarLink.scss';

import { compose } from 'redux';

const WhatsNewSidebarLink = (props) => {
  const { t } = useTranslation('Sidebar');

  return (
    <Link className="whatsNewSidebarLink" to={props.link} onClick={props.onClick}>
      <div className="whatsNewSidebarLink__inner">
        <div className="whatsNewSidebarLink__iconContainer">
          <div className="whatsNewSidebarLink__icon">
            <Icon name="update" />
          </div>
          <div className="whatsNewSidebarLink__indicator">
            {props.hasPlatformUpdates && <PlatformUpdatesIndicator />}
          </div>
        </div>
        <div className="whatsNewSidebarLink__textContainer">
          <div className="whatsNewSidebarLink__header">{t('WhatsNewSidebarLink.platform_updates')}</div>
        </div>
      </div>
    </Link>
  );
};

WhatsNewSidebarLink.propTypes = {
  link: PropTypes.string,
  onClick: PropTypes.func,
  hasPlatformUpdates: PropTypes.bool
};

export default compose(WhatsNewSidebarLink);
