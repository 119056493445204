import React from 'react';
import PropTypes from 'prop-types';
import * as snowplow from '@ivendi/snowplow-js';
import cn from 'classnames';

class TrackEvent extends React.Component {
  componentDidMount() {
    this.span.addEventListener(this.props.eventType, this.handleEvent, false);
  }

  componentWillUnmount() {
    this.span.removeEventListener(this.props.eventType, this.handleEvent, false);
  }

  trackClick = () => {
    snowplow.trackSelfDescribingEvent(
      this.props.featureName,
      this.interactionName,
      this.eventContent,
      this.props.context
    );
  };

  trackLink = () => {
    if (this.props.quoteId) {
      snowplow.trackLinkClick(this.props.linkTo, this.interactionName, 'quote', this.props.quoteId);
    } else {
      snowplow.trackLinkClick(this.props.linkTo, this.interactionName);
    }
  };

  handleEvent = (event) => {
    if (!this.props.interactionName) {
      return;
    }

    if (typeof this.props.interactionName === 'function') {
      this.interactionName = this.props.interactionName(event);

      if (!this.interactionName) {
        return;
      }
    } else {
      this.interactionName = this.props.interactionName;
    }

    if (typeof this.props.eventContent === 'function') {
      this.eventContent = this.props.eventContent(event);
    } else {
      this.eventContent = this.props.eventContent;
    }

    if (this.props.linkTo) {
      this.trackLink();
    } else {
      this.trackClick();
    }
  };

  render() {
    return (
      <span ref={(span) => (this.span = span)} className={cn('trackEvent', this.props.className)}>
        {this.props.children}
      </span>
    );
  }
}

TrackEvent.defaultProps = {
  eventType: 'click'
};

TrackEvent.propTypes = {
  featureName: PropTypes.string,
  interactionName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  eventType: PropTypes.string,
  eventContent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.any,
  quoteStore: PropTypes.object,
  quoteId: PropTypes.string,
  linkTo: PropTypes.string
};

export default TrackEvent;
