import React from 'react';
import PropTypes from 'prop-types';
import './helpVideoImageLink.scss';
const HelpVideoImageLink = ({ video, onClick }) => {
  const handleClick = () => {
    onClick(video.id);
  };

  if (!video) {
    return null;
  }

  return (
    <div className="helpVideoImageLink" onClick={handleClick} key="container">
      <div className="helpVideoImageLink__inner">
        <div className="helpVideoImageLink__imageContainer" key="imageContainer">
          <img src={video.thumbnail} alt={video.title} width="200" height="125" />
        </div>
        <div className="helpVideoImageLink__text">{video.title}</div>
      </div>
    </div>
  );
};
HelpVideoImageLink.propTypes = {
  video: PropTypes.object,
  onClick: PropTypes.func
};

export default HelpVideoImageLink;
