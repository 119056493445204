import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import QuoteCard from '~/components/QuoteCard/QuoteCard';
import EligibilitySummaryButton from '../../Common/EligibilitySummaryButton';
import NoPanelData from '../../Dashboard/components/NoPanelData';
import CarFinanceChecksTabsItem from './CarFinanceChecksTabsItem';
import Tabs from '../../Common/Tabs/Tabs';
import TabList from '../../Common/Tabs/TabList';
import TabContent from '../../Common/Tabs/TabContent';

import './carFinanceChecks.scss';
import { QuoteCardVariantTypeJs } from '~/components/QuoteCard/types';

class CarFinanceChecks extends React.Component {
  componentWillUnmount() {
    this.selectCfcCheck(0);
  }

  selectCfcCheck = (index) => {
    this.props.selectCfcCheck(index);
  };

  render() {
    const canUseShowroomCfc = this.props.appStore.uiState.canUseCfcIvendiv2;

    return (
      <div className="savedCarFinanceChecks">
        {this.props.checks.length > 0 ? (
          <div>
            <Tabs currentTab={this.props.selectedCfcCheckIndex} setActiveTab={this.selectCfcCheck}>
              <TabList tabStyle="largeCfc">
                {this.props.checks.map((check, index) => (
                  <CarFinanceChecksTabsItem check={check} key={`cfcti${index}`} />
                ))}
              </TabList>
              <TabContent border={false}>
                {this.props.checks.map((check, index) => (
                  <div key={`checkDiv${index}`}>
                    {canUseShowroomCfc && check.PersonalScorePercentage !== null && (
                      <EligibilitySummaryButton
                        onClick={() =>
                          this.props.onEligibilitySummaryClick(check.PersonalScorePercentage, check.CraScorePercentage)
                        }
                      />
                    )}
                    <div className="carFinanceChecks">
                      {check.Quotes.map((quote) => (
                        <QuoteCard
                          variant={QuoteCardVariantTypeJs.CarFinanceCheck}
                          quote={quote}
                          financeCheck={check}
                          actions={{
                            viewApplication: () => this.props.handleCfcCheckDetailsLink(quote, check.CfcProspectId),
                            onEligibilityMatchClick: () =>
                              this.props.onEligibilityMatchClick(quote.MatchRate, quote.DecisionMessages)
                          }}
                          vehicleClass={this.props.vehicleClass}
                          key={`CarFinanceCheck-${quote.QuoteId}`}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </TabContent>
            </Tabs>
          </div>
        ) : (
          <NoPanelData text={this.props.t('CarFinanceChecks.there_are_no_current_car_finance_checks')} />
        )}
      </div>
    );
  }
}

CarFinanceChecks.propTypes = {
  checks: PropTypes.array,
  onApply: PropTypes.func,
  dealership: PropTypes.object,
  vehicleId: PropTypes.string,
  consumer: PropTypes.object,
  appStore: PropTypes.object,
  handleCfcCheckDetailsLink: PropTypes.func,
  onSortCfcProspects: PropTypes.func,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  onEligibilityMatchClick: PropTypes.func,
  selectCfcCheck: PropTypes.func,
  selectedCfcCheckIndex: PropTypes.number,
  onEligibilitySummaryClick: PropTypes.func,
  vehicleClass: PropTypes.string
};

export default withTranslation('Customer')(inject(['appStore'])(observer(CarFinanceChecks)));
