import React from 'react';
import PropTypes from 'prop-types';

const Truncator = ({ maxLength, children }) => {
  if (!children) {
    return <div />;
  }

  let text = children;
  if (children.length > maxLength) {
    text = text.slice(0, maxLength) + '...';
  }

  return <div>{text}</div>;
};

Truncator.propTypes = {
  maxLength: PropTypes.number,
  children: PropTypes.string
};

export default Truncator;
