import PropTypes from 'prop-types';
import Icon from '../Common/Icon/Icon';
import './deleteCustomerActivityItem.scss';

const DeleteCustomerActivityItem = ({ icon, count, text }) => {
  return (
    <div className="deleteCustomerActivityItem">
      <div className="deleteCustomerActivityItem__icon">
        <Icon name={icon} />
      </div>
      <div className="deleteCustomerActivityItem__number">{count || 0}</div>
      <div className="deleteCustomerActivityItem__text">{text}</div>
    </div>
  );
};

DeleteCustomerActivityItem.propTypes = {
  icon: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string
};

export default DeleteCustomerActivityItem;
