import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import MoneyFormat from '../../../Common/MoneyFormat';
import DateFormat from '../../../Common/DateFormat';

import ProductsTables from './ProductsTables';
import DealTableItem from './DealTableItem';

import './selfServiceDealItem.scss';

const DealItem = ({ deal, dealershipId, vehicleId, consumer, vehicleClass }) => {
  const { t } = useTranslation('Customer');
  return (
    <div>
      <div className="selfServiceDealItem">
        <div className="selfServiceDealItem__header">{t('DealItem.deal_sent_to_customer')}</div>
        <div className="selfServiceDealItem__tableOuter">
          <div className="selfServiceDealItem__item selfServiceDealItem__dealCode" key="item1">
            <DealTableItem label={t('DealItem.deal_reference')}>
              <div className="selfServiceDealItem__code" key="info1">
                {deal.Code}
              </div>
            </DealTableItem>
          </div>
          <div className="selfServiceDealItem__item selfServiceDealItem__date" key="item2">
            <DealTableItem label={t('DealItem.deal_created_date')}>
              <DateFormat value={deal.SavedDate} parse="utc" format="DD MMM YYYY HH:mm" />
            </DealTableItem>
          </div>
          <div className="selfServiceDealItem__item selfServiceDealItem__cashPrice" key="item3">
            <DealTableItem label={t('DealItem.cash_price')}>
              <MoneyFormat value={deal.CashPrice} />
            </DealTableItem>
          </div>
          <div className="selfServiceDealItem__item selfServiceDealItem__deposit" key="item4">
            <DealTableItem label={t('DealItem.cash_deposit')}>
              <MoneyFormat value={deal.CashDeposit} />
            </DealTableItem>
          </div>
          <div className="selfServiceDealItem__item selfServiceDealItem__partExchange" key="item5">
            <DealTableItem label={t('DealItem.part_exchange')}>
              <MoneyFormat value={deal.PartExchange} />
            </DealTableItem>
          </div>
          <div className="selfServiceDealItem__item selfServiceDealItem__settlement" key="item6">
            <DealTableItem label={t('DealItem.outstanding_finance')}>
              <MoneyFormat value={deal.OutstandingSettlement} />
            </DealTableItem>
          </div>
          <div
            className="selfServiceDealItem__item selfServiceDealItem__noBorder selfServiceDealItem__balance"
            key="item7"
          >
            <DealTableItem label={t('DealItem.balance_to_change')}>
              <MoneyFormat value={deal.BalancePayable} />
            </DealTableItem>
          </div>
        </div>
      </div>
      <div className="selfServiceDealItem__tablesOuter">
        <ProductsTables
          quotes={deal.Quotes}
          consumer={consumer}
          dealershipId={dealershipId}
          vehicleId={vehicleId}
          vehicleClass={vehicleClass}
        />
      </div>
    </div>
  );
};

DealItem.propTypes = {
  deal: PropTypes.object,
  consumer: PropTypes.object,
  dealershipId: PropTypes.string,
  vehicleId: PropTypes.string,
  vehicleClass: PropTypes.string
};
export default DealItem;
