import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './Vrm.scss';

const Vrm = ({ vrm, className }) => {
  const { t } = useTranslation('Common');
  return (
    <div className={cn('Vrm', className)}>
      <div className="Vrm__reg">
        <div className="Vrm__regBlue" />
        {vrm ? vrm : t('Vrm.no_reg')}
      </div>
    </div>
  );
};

Vrm.propTypes = {
  vrm: PropTypes.string,
  className: PropTypes.string
};
export default Vrm;
