import { action } from 'mobx';
class StockCreateStore {
  capDataResponse = null;
  taxonomyDataResponse = null;
  vehicleTaxonomyData = null;
  miscVehicleData = { condition: '', lookupTerm: '' };
  isUniqueVehicleRecord = false;
  userFormData = {};

  @action
  setCapDataResponse = (capDataResponse) => {
    this.capDataResponse = capDataResponse;
  };

  @action
  setTaxonomyDataResponse = (taxonomyDataResponse) => {
    this.taxonomyDataResponse = taxonomyDataResponse;
  };
  @action
  setVehicleTaxonomyData = (vehicleTaxonomyData) => {
    this.vehicleTaxonomyData = vehicleTaxonomyData;
  };

  @action
  setMiscVehicleData = (miscVehicleData) => {
    this.miscVehicleData = miscVehicleData;
  };

  @action
  setIsUniqueVehicleRecord = () => {
    this.isUniqueVehicleRecord = true;
  };

  @action
  setUserFormData = (name, value) => {
    this.userFormData = {
      ...this.userFormData,
      [name]: value
    };
  };

  @action
  resetFormData = () => {
    this.userFormData = {};
  };

  @action
  resetData = () => {
    this.capDataResponse = null;
    this.taxonomyDataResponse = null;
    this.vehicleTaxonomyData = null;
    this.miscVehicleData = { condition: '', lookupTerm: '' };
    this.isUniqueVehicleRecord = false;
    this.userFormData = {};
  };
}

export default StockCreateStore;
