import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'redux';

import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import FormFooter from '../../Common/Form/FormFooter';
import ConsumerVehicleInfoPanel from '../../Common/ConsumerVehicleInfoPanel';
import { withNavigate, withOutletContextProps, withParams } from '../../../hocs/router';

class ViewLenderNotes extends React.Component {
  handleBack = () => {
    const { dealershipId, consumerId, applicantId } = this.props.params;
    this.props.navigate(`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/status`);
  };

  render() {
    const stamp = _.find(this.props.application.LenderNotes, {
      Timestamp: parseFloat(this.props.params.timestamp)
    });

    const { dealershipId, consumerId, applicantId } = this.props.params;
    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: this.props.t('ViewLenderNotes.home'),
              path: `/d/${dealershipId}`
            },
            {
              name: this.props.t('ViewLenderNotes.consumers'),
              path: `/d/${dealershipId}/consumers`
            },
            {
              name: this.props.t('ViewLenderNotes.consumer'),
              path: `/d/${dealershipId}/consumers/${consumerId}`
            },
            {
              name: this.props.t('ViewLenderNotes.application_status'),
              path: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/status`
            },
            {
              name: this.props.t('ViewLenderNotes.lender_notes')
            }
          ]}
          consumer={this.props.consumer}
        />
        <ConsumerVehicleInfoPanel vehicle={this.props.vehicle} quote={this.props.application.Quote} />
        <Panel>
          <PanelHeader>{this.props.t('ViewLenderNotes.lender_notes')}</PanelHeader>
          <PanelContent>
            <div
              className="viewLenderNotes__notes"
              dangerouslySetInnerHTML={{
                __html: stamp.ExtraInfo
              }}
            />
            <FormFooter
              hideSubmit={true}
              onCancel={this.handleBack}
              cancelLabel={this.props.t('ViewLenderNotes.go_back')}
            />
          </PanelContent>
        </Panel>
      </Page>
    );
  }
}

ViewLenderNotes.propTypes = {
  params: PropTypes.object,
  application: PropTypes.object,
  consumer: PropTypes.object,
  vehicle: PropTypes.object
};

export default compose(
  withParams,
  withNavigate,
  withOutletContextProps,
  withTranslation('Application')
)(ViewLenderNotes);
