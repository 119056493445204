import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  draft.applicationFields.AffordabilityDetails!.ChangesExpected = true;
  draft.applicationFields.AffordabilityDetails!.NetMonthly = false;
  draft.applicationFields.AffordabilityDetails!.DetailsOfExpectedChanges = true;

  draft.validationRules.AffordabilityDetails!.PersonalCircumstances!.ChangesExpected = 'required';
  draft.validationRules.EmploymentHistory!.Items![0]!.OccupationType =
    'validateFieldBasedOnOtherFieldValue:ReceivingDisabilityBenefit:OccupationBasis:Unemployed';
  delete draft.validationRules.AffordabilityDetails!.Income!.NetMonthly;
});
