import React from 'react';
import PropTypes from 'prop-types';
import FunderLogo from '../../../Common/FunderLogo';
import './monthlyPriceViewerProductHeader.scss';

const MonthlyPriceViewerProductHeader = ({ productName, funderCode, label }) => {
  return (
    <th className="monthlyPriceViewerProductHeader">
      <span className="monthlyPriceViewerProductHeader__productName">{productName}</span>
      <div className="monthlyPriceViewerProductHeader__funderLogo">
        {funderCode && <FunderLogo funder={funderCode} />}
      </div>
      {label && <span className="monthlyPriceViewerProductHeader__label">{label}</span>}
    </th>
  );
};

MonthlyPriceViewerProductHeader.propTypes = {
  label: PropTypes.string,
  product: PropTypes.object,
  productName: PropTypes.string,
  funderCode: PropTypes.string
};

export default MonthlyPriceViewerProductHeader;
