import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import DateFormat from '../../Common/DateFormat';
import './lenderNotesItem.scss';
import { withNavigate, withParams } from '../../../hocs/router';

class LenderNotesItem extends React.Component {
  static propTypes = {
    note: PropTypes.object,
    params: PropTypes.object,
    navigate: PropTypes.func
  };
  handleMore = () => {
    const { dealershipId, consumerId, applicantId } = this.props.params;
    this.props.navigate(
      `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/lendernotes/${this.props.note.Timestamp}`
    );
  };

  render() {
    return (
      <div className="lenderNotesItem__wrapper">
        <span className="lenderNotesItem__updateText">
          {this.props.note.Text}
          {this.props.note.ExtraInfo ? (
            <span className="lenderNotesItem__moreLink" onClick={this.handleMore}>
              {this.props.t('LenderNotesItem.more')}
            </span>
          ) : (
            ''
          )}
        </span>
        <span className="lenderNotesItem__date">
          {this.props.note.Name},{' '}
          <DateFormat dataTh="lenderNotesItemDate" value={this.props.note.Timestamp} format="DD/MM/YYYY HH:mm:ss" />
        </span>
      </div>
    );
  }
}

export default compose(withParams, withNavigate, withTranslation('Application'))(LenderNotesItem);
