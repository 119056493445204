import { useMemo } from 'react';

import { useVehicleData } from '../../services';

const useSortedVehicleData = ({ vehicleId, dealershipId }) => {
  const { isLoading, isError, data, isIdle } = useVehicleData(dealershipId, vehicleId);
  const loadingState = useMemo(() => {
    if (isIdle) return 'idle';
    if (isLoading) return 'loading';
    if (isError) return 'error';
  }, [isIdle, isLoading, isError]);

  const mediaCompanyVehicleData = useMemo(() => {
    const stockTaxonomyObject = data?.taxonomies?.find((taxonomy) => taxonomy?.origin?.toLowerCase() === 'stock');
    return {
      vrmYear: data?.advertInformation?.registrationDate,
      mileage: data?.advertInformation?.mileage,
      price: data?.advertInformation?.price,
      dealerReference: data?.dealer?.dealerReference,
      ...stockTaxonomyObject, // class, capId,
      ...data?.identity, // vrm, vin, condition
      ...data?.suppliedTaxonomyDetails
    };
  }, [data]);

  const iVendiCompanyVehicleData = useMemo(() => {
    const stockTaxonomyObject = data?.taxonomies?.find((taxonomy) => taxonomy?.origin?.toLowerCase() === 'dealer');
    return {
      ...stockTaxonomyObject,
      ...data?.taxonomyDetails
    };
  }, [data]);

  return {
    mediaCompanyVehicleData,
    iVendiCompanyVehicleData,
    loadingState: loadingState,
    dealerPlatformUpload: undefined
  };
};

export default useSortedVehicleData;
