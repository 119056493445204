import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './reporting.scss';
import Page from '../Common/Page';
import Breadcrumbs from '../Common/Breadcrumbs';
import Panel from '../Common/Panel';
import PanelContent from '../Common/PanelContent';
import PanelHeader from '../Common/PanelHeader';
import LargeMenuButton from '../Application/components/LargeMenuButton';
import LargeMenuButtonWrapper from '../Application/components/LargeMenuButtonWrapper';
import LoadingDots from '../Common/Loading/LoadingDots';
import { observer, inject } from 'mobx-react';
import { compose } from 'redux';
import { withParams } from 'hocs/router';

class Reporting extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.appStore.reportsStore.initReportMonths(this.props.params.dealershipId);
  }

  render() {
    const { isLoading, appStore } = this.props;
    const allReports = appStore && appStore.reportsStore && appStore.reportsStore.allReports;
    const dealershipMonthlyReportsLength =
      allReports && allReports.DealershipMonthlyReports && allReports.DealershipMonthlyReports.length;
    const dealershipGroupReportsLength =
      allReports && allReports.GroupMonthlyReports && allReports.GroupMonthlyReports.length;
    const hasOnlineGroup = dealershipMonthlyReportsLength > 0 && dealershipGroupReportsLength === 0;

    return (
      <Page testHook="Reporting" isLoading={isLoading}>
        <Breadcrumbs
          items={[
            {
              name: `${this.props.t('Reporting.home')}`,
              path: `/d/${this.props.params.dealershipId}`
            },
            {
              name: `${this.props.t('Reporting.reporting_title')}`
            }
          ]}
        />
        {appStore.reportsStore.isLoadingReports ? (
          <LoadingDots />
        ) : (
          <Panel>
            <PanelHeader>{this.props.t('Reporting.reporting_title')}</PanelHeader>
            <PanelContent>
              <LargeMenuButtonWrapper>
                {appStore.uiState.canViewExportClosedDeals && (
                  <div className="reporting__button" key="btn2">
                    <LargeMenuButton
                      icon="export"
                      link={`/d/${this.props.params.dealershipId}/reporting/exportclosedleads`}
                      text={this.props.t('Reporting.export_leads')}
                      buttonText={this.props.t('Reporting.view')}
                      dataThook="export_leads"
                    />
                  </div>
                )}
                <div className="reporting__button" key="btn3">
                  <LargeMenuButton
                    icon="report"
                    link={`/d/${this.props.params.dealershipId}/reporting/onlinereport`}
                    text={this.props.t('Reporting.online_retailing_report')}
                    buttonText={this.props.t('Reporting.view')}
                    dataThook="online_retailing_report"
                  />
                </div>
                {!hasOnlineGroup && (
                  <div className="reporting__button" key="btn4">
                    <LargeMenuButton
                      icon="group-report"
                      link={`/d/${this.props.params.dealershipId}/reporting/groupreport`}
                      text={this.props.t('Reporting.group_report')}
                      buttonText={this.props.t('Reporting.view')}
                      dataThook="group_report"
                    />
                  </div>
                )}
                {!hasOnlineGroup && (
                  <div className="reporting__button" key="btn5">
                    <LargeMenuButton
                      icon="league-table"
                      link={`/d/${this.props.params.dealershipId}/reporting/leaguetable`}
                      text={this.props.t('Reporting.group_league_table')}
                      buttonText={this.props.t('Reporting.view')}
                      dataThook="group_league_table"
                    />
                  </div>
                )}
              </LargeMenuButtonWrapper>
            </PanelContent>
          </Panel>
        )}
      </Page>
    );
  }
}

Reporting.propTypes = {
  dealership: PropTypes.object,
  isLoading: PropTypes.bool,
  appStore: PropTypes.object,
  params: PropTypes.object
};
export default compose(withParams, withTranslation('Reporting'), inject(['appStore']), observer)(Reporting);
