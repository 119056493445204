import { ApiResponseType } from '../../types';

export const getDefaultLineChartData = (): ApiResponseType => {
  return {
    lastModified: 'lastModified',
    points: ['points'],
    datasets: [
      {
        avg: [0],
        label: 'label',
        max: [0],
        med: [0],
        min: [0],
        sum: [0],
        users: [0]
      }
    ]
  };
};
