import {
  ChartFilterConfigType,
  ChartNameType,
  ChartVariant,
  DataSet,
  FiltersStateType,
  Metric,
  OptionName,
  ChartName,
  QuoteSource,
  ReportConfigType
} from '../../types';

const baseChartFilterConfig: ChartFilterConfigType = {
  chartType: ChartVariant.Line,
  includeChartTypeFilter: false,
  showLegend: false,
  dataSet: DataSet.Sum,
  priorityChartFilter: OptionName.DatePeriod,
  datePeriodConfig: {
    enabled: true,
    default: 7,
    includeDay: false,
    includeLast30Days: false
  },
  quoteSourceConfig: {
    enabled: false,
    default: [QuoteSource.Count]
  },
  lenderConfig: {
    enabled: false,
    default: 'all'
  },
  applicationStatusConfig: {
    enabled: false,
    default: 'all'
  }
};

const createChartFilterConfig = (overrides: Partial<ChartFilterConfigType> = {}): ChartFilterConfigType => ({
  ...baseChartFilterConfig,
  ...overrides
});

const baseInsightsConfig: ChartFilterConfigType = createChartFilterConfig({
  includeChartTypeFilter: true,
  showLegend: true,
  priorityChartFilter: OptionName.ApplicationStatus,
  datePeriodConfig: {
    ...baseChartFilterConfig.datePeriodConfig,
    default: 30,
    includeDay: true,
    includeLast30Days: true
  },
  quoteSourceConfig: {
    ...baseChartFilterConfig.quoteSourceConfig,
    enabled: true
  },
  lenderConfig: {
    ...baseChartFilterConfig.lenderConfig,
    enabled: true
  },
  applicationStatusConfig: {
    ...baseChartFilterConfig.applicationStatusConfig,
    enabled: true
  }
});

export const getDefaultFilters = (): FiltersStateType => {
  return {
    [OptionName.DatePeriod]: baseChartFilterConfig.datePeriodConfig.default,
    [OptionName.QuoteSource]: baseChartFilterConfig.quoteSourceConfig.default,
    [OptionName.Lender]: baseChartFilterConfig.lenderConfig.default,
    [OptionName.ApplicationStatus]: baseChartFilterConfig.applicationStatusConfig.default,
    chartType: baseChartFilterConfig.chartType
  };
};

const reportConfig: Record<ChartNameType, ReportConfigType> = {
  /* -- Insights Reports -- */
  [ChartName.ApplicationVolumes]: {
    title: 'application_volumes',
    metric: Metric.Accepted,
    description: 'application_volumes_text',
    chartFilterConfig: baseInsightsConfig,
    fallback: 'You do not have permission to view this report.'
  },

  [ChartName.ApplicationLoanValues]: {
    title: 'application_loan_values',
    metric: Metric.Accepted, // temporary placeholder value
    description: 'application_loan_values_text',
    chartFilterConfig: baseInsightsConfig,
    fallback: 'You do not have permission to view this report.'
  },

  [ChartName.VehicleSalesData]: {
    title: 'vehicle_sales_data',
    metric: Metric.Accepted, // temporary placeholder value
    description: 'vehicle_sales_data_text',
    chartFilterConfig: baseInsightsConfig,
    fallback: 'You do not have permission to view this report.'
  },

  [ChartName.AverageVehiclesInStock]: {
    title: 'average_vehicles_in_stock',
    metric: Metric.Accepted, // temporary placeholder value
    description: 'average_vehicles_in_stock_text',
    chartFilterConfig: createChartFilterConfig({
      ...baseInsightsConfig,
      dataSet: DataSet.Avg
    }),
    fallback: 'You do not have permission to view this report.'
  },

  /* -- Metrics Reports -- */
  [ChartName.OnlineQuotes]: {
    title: 'online_quotes',
    metric: Metric.QuotesDisplayed,
    chartFilterConfig: createChartFilterConfig({
      priorityChartFilter: OptionName.QuoteSource,
      showLegend: true,
      datePeriodConfig: {
        ...baseChartFilterConfig.datePeriodConfig,
        includeDay: true
      },
      quoteSourceConfig: {
        ...baseChartFilterConfig.quoteSourceConfig,
        enabled: true
      }
    })
  },

  [ChartName.AverageDaysInStock]: {
    title: 'average_days_in_stock',
    metric: Metric.DaysInStock,
    chartFilterConfig: createChartFilterConfig({
      priorityChartFilter: OptionName.QuoteSource,
      dataSet: DataSet.Avg,
      datePeriodConfig: {
        ...baseChartFilterConfig.datePeriodConfig,
        includeDay: true
      }
    })
  },

  [ChartName.EligibilityChecks]: {
    title: 'eligibility_checks',
    metric: Metric.EligibilityCheckCompleted,
    chartFilterConfig: createChartFilterConfig({
      priorityChartFilter: OptionName.QuoteSource,
      datePeriodConfig: {
        ...baseChartFilterConfig.datePeriodConfig,
        includeDay: true
      }
    })
  },

  [ChartName.SubmittedApplications]: {
    title: 'submitted_applications',
    metric: Metric.Submitted,
    chartFilterConfig: createChartFilterConfig({
      priorityChartFilter: OptionName.QuoteSource,
      datePeriodConfig: {
        ...baseChartFilterConfig.datePeriodConfig,
        includeDay: true
      }
    })
  },

  [ChartName.SearchImpressions]: {
    title: 'search_impressions',
    metric: Metric.DealerSearchImpression,
    chartFilterConfig: createChartFilterConfig({
      priorityChartFilter: OptionName.QuoteSource,
      datePeriodConfig: {
        ...baseChartFilterConfig.datePeriodConfig,
        default: 7,
        includeDay: true
      }
    })
  }
};

const getReportConfig = (chartName: ChartNameType, appStore: any): ReportConfigType => {
  const config = reportConfig[chartName];
  if (!config) {
    throw new Error(`Report config for "${chartName}" does not exist.`);
  }

  const financeInsightsCharts = new Set<ChartNameType>(['ApplicationVolumes', 'ApplicationLoanValues']);
  const stockInsightsCharts = new Set<ChartNameType>(['VehicleSalesData', 'AverageVehiclesInStock']);

  let userCanViewReport = true;

  if (financeInsightsCharts.has(chartName)) {
    userCanViewReport = appStore.uiState.canSeeFinanceInsights;
  } else if (stockInsightsCharts.has(chartName)) {
    userCanViewReport = appStore.uiState.canSeeStockInsights;
  }

  return {
    ...config,
    userCanViewReport
  };
};

export default getReportConfig;

