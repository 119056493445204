import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './userHeader.scss';

const UserHeader = ({ lastName, firstName, dealerships, current }) => {
  let initials = '';
  if (firstName && lastName) {
    initials = firstName.substring(0, 1).toUpperCase() + lastName.substring(0, 1).toUpperCase();
  }
  const currentDealer = _.find(dealerships, { Id: current });
  return (
    <div className="userHeader" data-th="UserHeader">
      <div className="userHeader__initialContainer">{initials}</div>
      <div className="userHeader__nameContainer">
        <div className="userHeader__name">{firstName ? firstName : <span>&nbsp;</span>}</div>
        <div title={currentDealer.Name} className="userHeader__dealer">
          {currentDealer ? currentDealer.Name : <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  );
};

UserHeader.defaultProps = {
  firstName: '',
  lastName: ''
};
UserHeader.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  dealerships: PropTypes.array,
  current: PropTypes.string
};

export default UserHeader;
