import React from 'react';
import PropTypes from 'prop-types';
import './summaryTable.scss';

const TableCell = ({ children }) => <td className="summaryTable__td">{children}</td>;

TableCell.propTypes = {
  children: PropTypes.node
};

export default TableCell;
