import { useTranslation } from 'react-i18next';
import React from 'react';
import './selectedLabel.scss';
import Icon from './Icon/Icon';

const SelectedLabel = ({ showRemove }) => {
  const { t } = useTranslation('Common');

  if (showRemove) {
    return (
      <span data-th="removeButton" className="selectedLabel__container--hovered">
        <span className="selectedLabel__tick">
          <Icon name="validation-cross" />
        </span>

        <div>{t('SelectedLabel.remove')}</div>
      </span>
    );
  }

  return (
    <span data-th="selectedTick" className="selectedLabel__container">
      <span className="selectedLabel__tick">
        <Icon name="validation-tick" />
      </span>

      <div>{t('SelectedLabel.selected')}</div>
    </span>
  );
};

export default SelectedLabel;
