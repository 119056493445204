import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import PanelToolBar from '../Common/PanelToolBar';
import Button from '../Common/Button/Button';
import './closeDealToolbar.scss';

const CloseDealToolbar = ({ onClick }) => {
  const { t } = useTranslation('CloseDeal');
  return (
    <PanelToolBar>
      <div className="closeDealToolbar__button" key="btn">
        <Button type="button" buttonStyle="secondary" onClick={onClick}>
          {t('CloseDealToolbar.reopen_deal')}
        </Button>
      </div>
    </PanelToolBar>
  );
};

CloseDealToolbar.propTypes = {
  onClick: PropTypes.func
};
export default CloseDealToolbar;
