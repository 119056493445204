import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import ConsumerName from '../../Common/ConsumerName';
import Button from '../../Common/Button/Button';
import './saveQuickQuoteExistingCustomer.scss';
import { getOptionKey } from '~/core/helpers';

const SaveQuickQuoteExistingCustomer = ({
  customer,
  handleSubmit,
  submittingExistingCustomerState,
  existingCustomerActionLabel
}) => {
  const { t } = useTranslation('QuickQuote');

  const onClick = () => {
    handleSubmit(customer.Id);
  };

  const genderNeutralOptions = new Set(['NotSpecified', 'PreferNotToSay', 'NonBinary']);

  const titleOptionText =
    customer.Title && !genderNeutralOptions.has(customer.Title) ? getOptionKey(customer.Title).text : '';

  return (
    <div className="saveQuickQuoteExistingCustomer__outer" key="outer">
      <div className="saveQuickQuoteExistingCustomer__text">
        {t('SaveQuickQuoteExistingCustomer.a_customer_already_belongs_to_this_email_address')}
      </div>
      <div className="saveQuickQuoteExistingCustomer__customer">
        {titleOptionText && `${titleOptionText}. `}
        <ConsumerName
          name={`${customer.Firstname} ${customer.Middlename ? customer.Middlename || customer.Middlenames : ''} ${
            customer.Surname
          }`}
        />
      </div>
      <div className="saveQuickQuoteExistingCustomer__button">
        <Button
          data-id="SaveQuoteExistingCustomer"
          isLoading={submittingExistingCustomerState === 'loading'}
          type="button"
          onClick={onClick}
        >
          {existingCustomerActionLabel}
        </Button>
      </div>
    </div>
  );
};

SaveQuickQuoteExistingCustomer.propTypes = {
  customer: PropTypes.object,
  handleSubmit: PropTypes.func,
  submittingExistingCustomerState: PropTypes.string,
  existingCustomerActionLabel: PropTypes.string
};
export default SaveQuickQuoteExistingCustomer;
