import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Page from '../Common/Page';
import Breadcrumbs from '../Common/Breadcrumbs';
import Panel from '../Common/Panel';
import PanelHeader from '../Common/PanelHeader';
import Button from '../Common/Button/Button';
import Select from '../Common/Form/Select';
import _ from 'lodash';
import Form from '../MobxForm/MobxForm';
import FormLabel from '../MobxForm/MobxFormLabel';
import FieldGroup from '../MobxForm/MobxFieldGroup';
import CurrencyInput from '../Common/Form/CurrencyInput';
import './valueAddedProductForms.scss';
import { observer, inject } from 'mobx-react';
import Validator from '../../validators/Validator';
import ProductNameLookup from '../Common/ProductNameLookup';
import ToggleSwitch from '../Common/ToggleSwitch';
import InformationWarning from '../Common/InformationWarning';
import Modal from '../Common/Modal/Modal';
import DescriptionTextArea from '../Common/DescriptionTextArea';
import { withNavigate, withParams } from 'hocs/router';
import { compose } from 'redux';

class EditValueAddedProductForm extends React.Component {
  setProductType = (event) =>
    this.setState({
      productType: event.currentTarget.value,
      productTypeText: this.props.appStore.vapStore.getProductTextForProductType(event.currentTarget.value),
      taxType: this.props.appStore.vapStore.getTaxTypeForProductType(event.currentTarget.value),
      isDefault: event.currentTarget.value === '1' ? false : this.state.isDefault,
      vapHasBeenUpdated: true
    });
  setName = (name) =>
    this.setState({
      name,
      vapHasBeenUpdated: true
    });
  setDescription = (description) =>
    this.setState({
      description,
      vapHasBeenUpdated: true
    });
  setPrice = (price) =>
    this.setState({
      price,
      vapHasBeenUpdated: true
    });
  setVisibility = (visibility) =>
    this.setState({
      visibility,
      isDefault: false,
      vapHasBeenUpdated: true
    });
  setDefault = (isDefault) =>
    this.setState({
      isDefault,
      vapHasBeenUpdated: true
    });

  constructor() {
    super();
    this.state = {
      vapHasBeenUpdated: false,
      productType: '',
      name: '',
      description: '',
      price: '',
      taxType: null,
      visibility: false,
      isDefault: false,
      isDefaultModalOpen: false,
      productTypeText: ''
    };
    const validationRules = {
      productType: 'required',
      name: 'required, doesNotStartOrEndWithSpace, maxlength:100, alphanumericWithDigitsPunctuationAndSpaces',
      description: 'doesNotStartOrEndWithSpace, maxlength:500, alphanumericWithDigitsPunctuationAndSpaces',
      price: 'required, min:0'
    };
    this.validator = new Validator();
    this.validator.setRules(validationRules);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (!_.isEqual(this.state, nextState)) {
      this.validator.validate(nextState);
    }
  }

  UNSAFE_componentWillMount() {
    this.props.appStore.vapStore.editProductRequest.reset();
    let vap = this.props.appStore.vapStore.getById(this.props.params.vapId);
    this.setState(
      {
        id: vap?.Id,
        name: vap.Name,
        description: vap.Description,
        price: vap.Price,
        productType: vap.ProductTypeCode,
        productTypeText: this.props.appStore.vapStore.getProductTextForProductType(vap.ProductTypeCode),
        taxType: this.props.appStore.vapStore.getTaxTypeForProductType(vap.ProductTypeCode),
        visibility: vap.Visible,
        isDefault: vap.Default
      },
      () => {
        this.validator.validate(this.state);
      }
    );
  }

  onSubmit = () => {
    const findUsedProduct = _.find(this.props.appStore.vapStore.valueAddedProducts, {
      ProductTypeCode: parseInt(this.state.productType, 10),
      Default: true
    });

    let isAllowedMultipleTypes = false;

    if (
      this.state.productType === '11' ||
      this.state.productType === '13' ||
      this.state.productType === '14' ||
      this.state.productType === '17'
    ) {
      isAllowedMultipleTypes = true;
    }

    if (
      this.state.isDefault &&
      findUsedProduct &&
      findUsedProduct.Default &&
      !isAllowedMultipleTypes &&
      !this.validator.errorCount
    ) {
      this.setState({
        isDefaultModalOpen: true
      });
      return;
    }

    if (!this.validator.errorCount) {
      this.props.appStore.vapStore.onSubmitEdit(this.props.params.dealershipId, this.state).then(() => {
        if (this.props.appStore.vapStore.editProductRequest.isSuccessful) {
          this.props.navigate(`/d/${this.props.params.dealershipId}/settings/vaps`);
        }
      });
    } else {
      this.validator.validate(this.state);
    }
  };
  onModalSubmit = () => {
    const findUsedProduct = _.find(this.props.appStore.vapStore.valueAddedProducts, {
      ProductTypeCode: parseInt(this.state.productType, 10),
      Default: true
    });

    this.props.appStore.vapStore.setDefault(findUsedProduct?.Id, false, this.props.params.dealershipId);

    if (!this.validator.errorCount) {
      this.props.appStore.vapStore.onSubmitEdit(this.props.params.dealershipId, this.state).then(() => {
        if (this.props.appStore.vapStore.editProductRequest.isSuccessful) {
          this.props.navigate(`/d/${this.props.params.dealershipId}/settings/vaps`);
        }
      });
    } else {
      this.validator.validate(this.state);
    }
  };
  closeDefaultModal = () => {
    this.setState({
      isDefaultModalOpen: false
    });
  };

  render() {
    let vapStore = this.props.appStore.vapStore;
    let errors = this.validator.errors ? this.validator.errors : {};
    const submitDisabled = !this.state.vapHasBeenUpdated;
    let canEditVaps = this.props.appStore.uiState.canEditVaps;
    const { hasOrders } = this.props.appStore.uiState;
    const nameLabel = hasOrders
      ? this.props.t('EditValueAddedProductForm.public_name')
      : this.props.t('EditValueAddedProductForm.name');
    let breadcrumbs = (
      <Breadcrumbs
        items={[
          {
            name: this.props.t('EditValueAddedProductForm.home'),
            path: `/d/${this.props.params.dealershipId}`
          },
          {
            name: this.props.t('EditValueAddedProductForm.settings'),
            path: `/d/${this.props.params.dealershipId}/settings`
          },
          {
            name: this.props.t('EditValueAddedProductForm.value_added_products'),
            path: `/d/${this.props.params.dealershipId}/settings/vaps`
          },
          {
            name: this.props.t('EditValueAddedProductForm.edit_a_vap'),
            path: `/d/${this.props.params.dealershipId}/settings/vaps/edit/${this.props.params.vapId}`
          }
        ]}
      />
    );

    if (!canEditVaps) {
      return (
        <Page>
          {breadcrumbs}
          <Panel>
            <PanelHeader>{this.props.t('EditValueAddedProductForm.value_added_products')}</PanelHeader>
            <div className="valueAddedProductForms__errorMessage">
              {this.props.t('AddValueAddedProductForm.permissionDenied')}
            </div>
          </Panel>
        </Page>
      );
    }

    return (
      <Page>
        {breadcrumbs}
        <Panel>
          <PanelHeader>{this.props.t('EditValueAddedProductForm.value_added_products')}</PanelHeader>

          {vapStore.fetchVapsRequest.isSuccessful && vapStore.fetchProductTypesRequest.isSuccessful && this.state.id && (
            <Form onSubmit={this.onSubmit}>
              <div className="valueAddedProductForms__form">
                <FieldGroup error={errors.productType} isInline>
                  <FormLabel>{this.props.t('EditValueAddedProductForm.type')}</FormLabel>
                  <Select
                    value={this.state.productType}
                    options={vapStore.parsedOptions}
                    onChange={this.setProductType}
                    dataThook="Type"
                  />
                </FieldGroup>

                {this.state.taxType && (
                  <div className="valueAddedProductForms__taxType">
                    {this.props.t('EditValueAddedProductForm.tax_type', {
                      taxType: this.state.taxType
                    })}
                  </div>
                )}

                <FieldGroup error={errors.name} isInline>
                  <FormLabel>{nameLabel}</FormLabel>
                  <ProductNameLookup onChange={this.setName} value={this.state.name} />
                </FieldGroup>

                <FieldGroup error={errors.description} isInline>
                  <FormLabel>{this.props.t('EditValueAddedProductForm.description')}</FormLabel>
                  <DescriptionTextArea
                    description={this.state.description}
                    setDescription={this.setDescription}
                    dataThook="Description"
                  />
                </FieldGroup>

                <FieldGroup error={errors.price} isInline>
                  <FormLabel>{this.props.t('EditValueAddedProductForm.price')}</FormLabel>
                  <CurrencyInput value={this.state.price} onChange={this.setPrice} dataThook="Price" />
                </FieldGroup>
                <FieldGroup isInline>
                  <FormLabel>{this.props.t('EditValueAddedProductForm.visible_on_platform')}</FormLabel>
                  <ToggleSwitch handleUpdate={this.setVisibility} isActive={this.state.visibility} dataTag="Visible" />
                </FieldGroup>
                <FieldGroup isInline>
                  <FormLabel>{this.props.t('EditValueAddedProductForm.default_when_quoting')}</FormLabel>
                  {parseInt(this.state.productType, 10) !== 1 ? (
                    <ToggleSwitch
                      handleUpdate={this.setDefault}
                      isActive={this.state.isDefault}
                      disabled={!this.state.visibility}
                      dataTag="Default"
                    />
                  ) : (
                    <div className="valueAddedProductForms__informationCard">
                      <InformationWarning
                        text={this.props.t(
                          'EditValueAddedProductForm.gap_insurance_products_cannot_be_automatically_added_when_quoting'
                        )}
                      />
                    </div>
                  )}
                </FieldGroup>
                {parseInt(this.state.productType, 10) === 1 && (
                  <FieldGroup isInline>
                    <div />
                    <div className="valueAddedProductForms__informationCard">
                      <InformationWarning
                        text={this.props.t(
                          'EditValueAddedProductForm.confirm_your_dealership_holds_fca_regulatory_permissions'
                        )}
                      />
                    </div>
                  </FieldGroup>
                )}
              </div>
              <div className="valueAddedProductForms__formFooter">
                <div className="valueAddedProductForms__formFooterButton">
                  <Button buttonStyle="cancel" to={`/d/${this.props.params.dealershipId}/settings/vaps`}>
                    {this.props.t('EditValueAddedProductForm.cancel')}
                  </Button>
                </div>
                {!vapStore.editProductRequest.isLoading && vapStore.editProductRequest.hasError && (
                  <div className="valueAddedProductForms__formFooterError">
                    {this.props.t('EditValueAddedProductForm.problem_submitting_request_try_later')}
                  </div>
                )}
                <div className="valueAddedProductForms__formFooterButton">
                  <Button
                    disabled={submitDisabled}
                    type="submit"
                    isLoading={vapStore.editProductRequest.isLoading}
                    hasError={vapStore.editProductRequest.hasError}
                    dataThook="editVapButton"
                  >
                    {this.props.t('EditValueAddedProductForm.update_vap')}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Panel>
        <Modal isOpen={this.state.isDefaultModalOpen} onClose={this.closeDefaultModal}>
          <div className="valueAddedProductForms__modal">
            <div className="valueAddedProductForms__modalText">
              {this.props.t('EditValueAddedProductForm.already_added_VAP_update_for_default', {
                productType: this.state.productTypeText
              })}
            </div>
            <div className="valueAddedProductForms__modalButtons">
              <div className="valueAddedProductForms__modalButtonAdd">
                <Button
                  type="button"
                  onClick={this.onModalSubmit}
                  isLoading={vapStore.addProductRequest.isLoading}
                  hasError={vapStore.addProductRequest.hasError}
                >
                  {this.props.t('EditValueAddedProductForm.update_vap')}
                </Button>
              </div>
              <div className="valueAddedProductForms__modalButtonCancel">
                <Button buttonStyle="cancel" onClick={this.closeDefaultModal}>
                  {this.props.t('EditValueAddedProductForm.cancel')}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </Page>
    );
  }
}

EditValueAddedProductForm.propTypes = {
  params: PropTypes.object,
  appStore: PropTypes.object
};

export default compose(
  withNavigate,
  withParams,
  withTranslation('ValueAddedProductSettings'),
  inject(['appStore']),
  observer
)(EditValueAddedProductForm);
