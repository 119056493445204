import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '~/components/Common/Breadcrumbs';
import Button from '~/components/Common/Button/Button';
import Page from '~/components/Common/Page';
import Panel from '~/components/Common/Panel';
// @ts-ignore
import RepExampleText from '@ivendi/rep-example';
import { inject } from 'mobx-react';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useReconfirmRepExample, useRepExample } from '~/api/repExample/repExample';
import AlertCard from '~/components/Common/AlertCard/AlertCard';
import DateFormat from '~/components/Common/DateFormat';
import Divider from '~/components/Common/Divider';
import LoadingCenterWrapped from '~/components/Common/Loading/LoadingCenterWrapped';
import PanelHeader from '~/components/Common/PanelHeader';
import { getFormattedAPR } from '~/components/Quoting/components/FormatAPR';
import { AppStore } from '~/modules/Stock/types/Types';
import { RepExample } from '~/types/repExample';
import RepExampleInfoCard from './RepExampleInfoCard';
import './repExampleView.scss';

const RepExampleNotSet = () => {
  const { t } = useTranslation('RepExampleSettings');
  return (
    <RepExampleInfoCard dataTh="repExampleView__notSet">
      <div>{t('RepExampleView.set_your_representative_example')}</div>
      <div>{t('RepExampleView.select_a_representative_vehicle_to_use_in_your_example')}</div>
      <div>{t('RepExampleView.enter_the_price_details_and_representative_apr')}</div>
      <div>{t('RepExampleView.choose_from_real_quotes_from_the_lenders_you_offer')}</div>
    </RepExampleInfoCard>
  );
};

const RepExampleCustom = ({ data }: { data: RepExample }) => {
  const { t } = useTranslation('RepExampleSettings');
  return (
    <>
      <div className="repExampleView__title">{t('RepExampleView.your_current_representative_example')}</div>
      <p className="repExampleView__summary">{t('RepExampleView.your_current_representative_example_summary')}</p>
      <div className="repExampleView__example">
        <RepExampleText quote={data} />
      </div>
      {data.LastModifiedTimestamp > 0 && (
        <p className="repExampleView__dateSet" data-th="repExampleView__dateSet">
          {t('RepExampleView.date_set')}{' '}
          <DateFormat value={data.LastModifiedTimestamp} format="DD/MM/YYYY [at] HH:mm" />
        </p>
      )}
      {data.UpdatingUserEmail && (
        <p className="repExampleView__setBy" data-th="repExampleView__setBy">
          {t('RepExampleView.set_by_userName', { userName: data.UpdatingUserEmail })}
        </p>
      )}
    </>
  );
};

const RepExampleView = (props: { appStore: AppStore }) => {
  const { canSetRepExample } = props.appStore.uiState || {};
  const { t } = useTranslation('RepExampleSettings');
  const { dealershipId } = useParams();
  const navitgate = useNavigate();
  const { data, isFetching, refetch } = useRepExample(dealershipId!);
  const [showUpdateNotification, setShowUpdateNotification] = useState(false);
  const onReconfirmedSuccessfully = () => refetch().then(() => setShowUpdateNotification(true));
  const { mutate: reconfirm, isLoading: isUpdating } = useReconfirmRepExample(dealershipId!, onReconfirmedSuccessfully);
  const isRepExampleSet = data?.RepresentativeExampleType === 3;
  const currentRepExample = useCallback(
    () => (isRepExampleSet ? <RepExampleCustom data={data} /> : <RepExampleNotSet />),
    [isRepExampleSet, data]
  );
  const { state: routerState } = useLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // check for showUpdateNotification flag coming back from edit form and clear
    // the flag from the router state so it doesn't show again if page is reloaded
    if (routerState?.showUpdateNotification) {
      setShowUpdateNotification(true);
      window.history.replaceState({}, '');
    }
  });

  const onChangeRepExample = () => {
    navitgate(`/d/${dealershipId}/settings/repexample/edit`);
    window.ga && window.ga('send', 'event', 'RepExSelect', 'ChangeCustomRepExample', 'RepExampleCustomSelect');
  };

  const onCancel = () => {
    navitgate(`/d/${dealershipId}/settings`);
    window.ga && window.ga('send', 'event', 'RepExSelect', 'Cancel', 'RepExampleCancel');
  };

  const getExpiryDate = () => {
    const date = new Date(data!.LastModifiedTimestamp);
    date.setDate(date.getDate() + 30);
    return <DateFormat value={date.toISOString()} format="DD/MM/YYYY" />;
  };

  const isRepExampleOld = isRepExampleSet && moment(data?.LastModified).isBefore(moment().subtract(30, 'days'));

  return (
    <Page className="repExampleView">
      <Breadcrumbs
        items={[
          {
            name: t('Breadcrumbs.home'),
            path: `/d/${dealershipId}`
          },
          {
            name: t('Breadcrumbs.settings'),
            path: `/d/${dealershipId}/settings`
          },
          {
            name: t('Breadcrumbs.rep_example'),
            path: `/d/${dealershipId}/settings/repexample`
          }
        ]}
      />
      {showUpdateNotification && data && !isFetching && (
        <AlertCard
          iconName="tick"
          title={t('RepExampleView.confirmation_of_updated_rate')}
          className="repExampleView__alert"
          dataTh="repExampleView__alert"
          paragraph={
            <>
              <p>{t('RepExampleView.thank_you_for_updating_your_representative_example')}</p>
              <ul>
                <li>{t('RepExampleView.rate_offered_x', { rate: getFormattedAPR(data.APR, props.appStore) })}</li>
                <li>
                  {t('RepExampleView.valid_until')} {getExpiryDate()} {t('RepExampleView.30_days_from_today')}
                </li>
              </ul>
              <p>{t('RepExampleView.no_further_action_is_required')}</p>
            </>
          }
        />
      )}
      {isRepExampleOld && !isFetching && (
        <AlertCard
          className="repExampleView__alert"
          iconName="warning"
          title={t('RepExampleView.update_your_representative_examples')}
          dataTh="repExampleView__warning"
        >
          {t('RepExampleView.your_representative_example_should_be')}
        </AlertCard>
      )}
      <Panel>
        <PanelHeader>{t('RepExampleView.representative_example_settings')}</PanelHeader>
        <div className="repExampleView__container" data-th="repExampleView__container">
          {canSetRepExample && isFetching && <LoadingCenterWrapped />}
          {canSetRepExample && !isFetching && currentRepExample()}
          {!canSetRepExample && (
            <p data-th="RepExampleView__notEnabled">
              {t('RepExampleView.sorry_representative_example_settings_are_not_enabled_for_your_current_dealership')}
            </p>
          )}
          <Divider />
          <div className="repExampleView__buttons">
            {canSetRepExample && (
              <Button to={`/d/${dealershipId}/settings`} buttonStyle="cancel" onClick={onCancel}>
                {t('RepExampleView.cancel')}
              </Button>
            )}
            {canSetRepExample && isRepExampleSet && (
              <Button
                buttonStyle="secondary"
                onClick={reconfirm}
                disabled={isFetching || isUpdating}
                dataThook="repExampleView__reconfirm"
              >
                {t('RepExampleView.reconfirm_rate')}
              </Button>
            )}
            {canSetRepExample && (
              <Button
                buttonStyle="primary"
                onClick={onChangeRepExample}
                disabled={isFetching || isUpdating}
                dataThook="repExampleView__update"
              >
                {t('RepExampleView.update')}
              </Button>
            )}
            {!canSetRepExample && <Button to={`/d/${dealershipId}`}>{t('RepExampleView.return_to_dashboard')}</Button>}
          </div>
        </div>
      </Panel>
    </Page>
  );
};

export default inject('appStore')(RepExampleView);
