import React from 'react';
import PropTypes from 'prop-types';
import QuickQuoteDummyNavigationItem from './QuickQuoteDummyNavigationItem';
import './quickQuoteDummyNavigation.scss';

const QuickQuoteDummyNavigation = ({ applicationType, progress }) => {
  const type = applicationType && applicationType.toLowerCase();
  return (
    <div>
      <ul className="quickQuoteDummyNavigation__list">
        <QuickQuoteDummyNavigationItem
          link={type === 'corporate' ? 'BusinessDetails' : 'PersonalDetails'}
          imageName="consumer"
          text={type === 'corporate' ? 'Business Details' : 'Personal Details'}
          progress={type === 'corporate' ? progress.businessDetails : progress.personal}
        />
        <QuickQuoteDummyNavigationItem
          link={type === 'corporate' ? 'BusinessAddress' : 'AddressHistory'}
          imageName="location"
          text={type === 'corporate' ? 'Business Address' : 'Address'}
          progress={type === 'corporate' ? progress.addressHistory : progress.address}
        />
        <QuickQuoteDummyNavigationItem
          link={type === 'corporate' ? 'OrganisationContacts' : 'EmploymentHistory'}
          imageName="employment"
          text={type === 'corporate' ? 'Organisation Contacts' : 'Employment'}
          progress={type === 'corporate' ? progress.organisationContacts : progress.employment}
        />
        <QuickQuoteDummyNavigationItem
          link="BankDetails"
          imageName="bank"
          text="Bank Details"
          progress={progress.bank}
        />
        {type === 'consumer' && (
          <QuickQuoteDummyNavigationItem
            link="Affordability"
            imageName="affordability"
            text="Affordability"
            progress={progress.affordability}
          />
        )}
        <QuickQuoteDummyNavigationItem
          link="ApplicationSummary"
          imageName="summary"
          text="Summary"
          progress={progress.total}
        />
      </ul>
    </div>
  );
};

QuickQuoteDummyNavigation.propTypes = {
  progress: PropTypes.object.isRequired,
  applicationType: PropTypes.string
};

export default QuickQuoteDummyNavigation;
