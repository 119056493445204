import { checkClaim } from '../core/claims';
import { observable, action, computed } from 'mobx';
import { CountryCodes } from '../constants';
import { setTheme } from '../theme';
import { isMotorhomeOrTouringCaravan } from '~/types/vehicle';
import { canSeeRbpMultilender, canSeeReportingInsights, canSeeHomePage } from '~/features';

const features = {
  pushdeal: (state) => {
    return {
      /*
       * Once claims are updated to include country code,
       * this can be updated to use the claim
       * checkClaimImplementation(state.dealershipModules, 'feature-pushdeal')
       */
      isFeatureEnabled: checkClaim(state.dealershipModules, 'feature-pushdeal'),
      isFeatureImplemented: ['gb', 'de'].some((countryCode) => countryCode === state.countryCode)
    };
  }
};

class UiState {
  @observable claims = null;
  @observable isSysAdmin = null;
  @observable userClaims = null;
  @observable dealershipModules = null;
  @observable dealershipId = null;
  @observable countryCode = null;
  @observable platformBrand = null;
  @observable lenders = {};

  checkFeature(feature) {
    return features[feature]?.(this) ?? { isFeatureEnabled: false, isFeatureImplemented: false };
  }

  @computed
  get isDealershipAdmin() {
    return checkClaim(this.userClaims, 'dealershipadmin') || this.isSysAdmin;
  }

  @computed
  get isMotonovo() {
    return this.platformBrand === 'motonovo' || checkClaim(this.dealershipModules, 'motonovo');
  }

  @computed
  get isBnpp() {
    return this.platformBrand === 'bnpparibas' || checkClaim(this.dealershipModules, 'bnpparibas');
  }

  @computed
  get isBdk() {
    return this.platformBrand === 'bdk' || checkClaim(this.dealershipModules, 'bdk');
  }

  @computed
  get isUk() {
    return this.countryCode === 'gb';
  }

  @computed
  get isDe() {
    return this.countryCode === 'de';
  }

  @computed
  get hasEnabledLenderModules() {
    return (
      this.dealershipModules.some((lenderModule) => /^lender-[a-z]{3}-enabled$/.test(lenderModule)) ||
      Object.values(this.lenders || {}).some((lender) => lender.Enabled)
    );
  }

  @computed
  get lenderModules() {
    return this.dealershipModules.filter((lenderModule) => /^lender-[a-z]{3}-enabled$/.test(lenderModule));
  }

  @computed
  get hasSingleLender() {
    const lenderModulesCount = this.lenderModules.length;
    const lendersCount = Object.values(this.lenders || {}).filter((lender) => lender.Enabled).length;

    return lenderModulesCount === 1 || lendersCount === 1;
  }

  @computed
  get isMotonovoOnlyDealership() {
    return this.hasSingleLender && this.lenderModules.some((lenderModule) => lenderModule === `lender-mot-enabled`);
  }

  @computed
  get getPlatformCode() {
    return this.isMotonovo ? 'MOT' : this.isBdk ? 'BDK' : null;
  }

  @computed
  get hasStockEnabled() {
    return checkClaim(this.dealershipModules, 'feature-stockmanagement');
  }

  @computed
  get canViewMetricsOnSidebar() {
    return this.countryCode === 'de';
  }

  @computed
  get canViewMetricsBreadcrumbs() {
    return this.countryCode !== 'de';
  }

  @computed
  get canViewSubmittedApplicationsCard() {
    return this.countryCode !== 'de';
  }

  @computed
  get canViewSearchImpressionsCard() {
    return this.countryCode === 'de';
  }

  @computed
  get hasWebshopEnabled() {
    return checkClaim(this.dealershipModules, 'webshops');
  }

  @computed
  get canUseWebshop() {
    return this.isDealershipAdmin || checkClaim(this.userClaims, 'webshops');
  }

  @computed
  get canSeeFinanceInsights() {
    return !this.hasSingleLender && (this.isDealershipAdmin || checkClaim(this.userClaims, 'reporting-finance'));
  }

  @computed
  get canSeeStockInsights() {
    return !this.hasSingleLender && (this.isDealershipAdmin || checkClaim(this.userClaims, 'reporting-stock'));
  }

  @computed
  get canUseInsights() {
    return (
      canSeeReportingInsights() &&
      !this.hasSingleLender &&
      (this.isDealershipAdmin || this.canSeeFinanceInsights || this.canSeeStockInsights)
    );
  }

  @computed
  get hasFindAndFundModule() {
    return checkClaim(this.dealershipModules, 'faf');
  }

  @computed
  get hasJuhuModule() {
    return checkClaim(this.dealershipModules, 'juhuautopresence');
  }

  @computed
  get hasMotonovoTheme() {
    return this.isMotonovo;
  }

  @computed
  get hasDefaultTheme() {
    return this.platformBrand === 'multilender' || checkClaim(this.dealershipModules, 'multilender');
  }

  @computed
  get hasBnppTheme() {
    return this.isBnpp;
  }

  @computed
  get hasBdkTheme() {
    return this.isBdk;
  }

  @computed
  get canEditProducts() {
    return this.isDealershipAdmin;
  }

  @computed
  get canEditVaps() {
    return this.isDealershipAdmin;
  }

  @computed
  get canUseLenderVaps() {
    return this.isBdk;
  }

  @computed
  get canQuote() {
    return checkClaim(this.dealershipModules, 'quoting');
  }
  @computed
  get canQuoteMotorhomesAndCaravans() {
    return checkClaim(this.dealershipModules, 'enable-motorhomes-caravans');
  }

  @computed
  get canViewExportClosedDeals() {
    return this.isDealershipAdmin;
  }

  @computed
  get canEditAccountSettings() {
    return this.isDealershipAdmin;
  }

  @computed
  get canViewGlobalSettings() {
    return this.isDealershipAdmin;
  }

  get canViewFinanceProducts() {
    return this.countryCode === 'gb';
  }

  @computed
  get canQuoteVAT() {
    return this.countryCode === 'gb';
  }

  @computed
  get canUseStockSettings() {
    // There's no non-DE endpoint set up for stock settings
    return process.env.REACT_APP_REGION === 'de' && this.countryCode === 'de';
  }

  @computed
  get canManageUsers() {
    return this.isDealershipAdmin;
  }

  @computed
  get canAddUser() {
    return !this.isMotonovo;
  }

  @computed
  get canAddAccessories() {
    return !this.isMotonovo;
  }

  canUseDistanceSale(customerType, funderCode) {
    const supportedFunders = new Set(['STE', 'MIV', 'ADM']);
    return customerType?.toLowerCase() !== 'corporate' && supportedFunders.has(funderCode);
  }

  get canUseIDD() {
    return this.countryCode === 'gb';
  }

  get canUseVrmLookup() {
    return this.countryCode === 'gb';
  }

  @computed
  get canUseShowroomCfc() {
    return checkClaim(this.dealershipModules, 'ivendi-showroom-cfc');
  }

  @computed
  get canUseCfcIvendiv2() {
    return (
      checkClaim(this.dealershipModules, 'ivendi-showroom-cfc') || checkClaim(this.dealershipModules, 'cfc-ivendiv2')
    );
  }

  @computed
  get canSelfServeQuotes() {
    return checkClaim(this.dealershipModules, 'fass');
  }

  @computed
  get canRepropose() {
    return !this.isMotonovo && !this.isBnpp && !this.isBdk;
  }

  @computed
  get canAssign() {
    return this.isDealershipAdmin || checkClaim(this.userClaims, 'canassign');
  }

  @computed
  get canShowroomQuote() {
    return this.hasEnabledLenderModules;
  }

  @computed
  get canViewReportingHeatMap() {
    return !this.isMotonovo;
  }

  @computed
  get canRetrieveSettlement() {
    return checkClaim(this.dealershipModules, 'lender-mot-enabled');
  }

  @computed
  get canUseMotorhomes() {
    return checkClaim(this.dealershipModules, 'lender-cre-enabled');
  }

  @computed
  get canUseTouringCaravans() {
    return (
      process.env.REACT_APP_CAN_USE_TOURING_CARAVANS === 'true' &&
      checkClaim(this.dealershipModules, 'lender-cre-enabled')
    );
  }

  @computed
  get canViewReservations() {
    return checkClaim(this.dealershipModules, 'feature-reservenow');
  }

  canSubmitApplication = (lenderCode) => {
    return checkClaim(this.dealershipModules, `lender-${lenderCode.toLowerCase()}-platsubmit`);
  };

  canUseLender = (lenderCode) => {
    return (
      checkClaim(this.dealershipModules, `lender-${lenderCode.toLowerCase()}-enabled`) ||
      Boolean(this.lenders?.[lenderCode.toUpperCase()]?.Enabled)
    );
  };

  get canUseQuickQuote() {
    return this.countryCode === 'gb';
  }

  @computed
  get canAccessGroupExports() {
    return (
      this.isDealershipAdmin ||
      (checkClaim(this.userClaims, 'group') && checkClaim(this.dealershipModules, 'group-exports-enabled'))
    );
  }

  @computed
  get canEditQuotingVaps() {
    return this.isDealershipAdmin || checkClaim(this.userClaims, 'edit-vaps');
  }

  @computed
  get canDeleteConsumers() {
    return this.isDealershipAdmin || checkClaim(this.userClaims, 'delete-customers');
  }

  @computed
  get canCloseDeals() {
    return this.isDealershipAdmin || checkClaim(this.userClaims, 'close-deal');
  }

  @computed
  get canViewConsumerApplications() {
    return this.isDealershipAdmin || checkClaim(this.userClaims, 'view-applications');
  }

  @computed
  get canEditQuotingCommissions() {
    return this.isDealershipAdmin || checkClaim(this.userClaims, 'edit-schemes');
  }

  @computed
  get canCreateApplications() {
    return (this.isDealershipAdmin || checkClaim(this.userClaims, 'create-applications')) && !this.isBdk;
  }
  @computed
  get canGenerateQuotes() {
    return this.isDealershipAdmin || checkClaim(this.userClaims, 'generate-quotes');
  }

  @computed
  get canSetRepExample() {
    return (this.isSysAdmin || this.isDealershipAdmin) && this.countryCode === 'gb';
  }

  @computed
  get canUsePushDeal() {
    return checkClaim(this.dealershipModules, 'feature-pushdeal');
  }

  @computed
  get hasOrders() {
    return this.canUsePushDeal || this.canUseOrders;
  }

  @computed
  get canUseFinancing() {
    return this.countryCode === 'gb';
  }

  @computed
  get canUseReporting() {
    return this.countryCode === 'gb';
  }

  @computed
  get canUseOrders() {
    return checkClaim(this.dealershipModules, 'feature-buyonline');
  }

  @computed
  get canUseRbp() {
    return (
      checkClaim(this.dealershipModules, 'pilot-riskbasedpricing') ||
      (canSeeRbpMultilender() && !this.hasSingleLender) ||
      (this.hasSingleLender && checkClaim(this.dealershipModules, 'lender-adm-riskbasedpricing'))
    );
  }

  @computed
  get hideConsumerDutyContent() {
    return checkClaim(this.dealershipModules, 'lender-product-content');
  }

  get canAddVehicles() {
    return checkClaim(this.dealershipModules, 'feature-stockupload');
  }

  get canBroadcast() {
    return checkClaim(this.dealershipModules, 'feature-stockbroadcast');
  }

  get canFixIssues() {
    return this.countryCode !== 'de';
  }

  get canViewDashboard() {
    return this.countryCode === 'gb';
  }

  get canViewHomePage() {
    return this.hasDefaultTheme && canSeeHomePage();
  }

  @computed
  get canViewDataAccuracy() {
    return this.countryCode === 'gb';
  }

  shouldUseVisLookup = (vehicleClass) => {
    return this.countryCode === 'de' || isMotorhomeOrTouringCaravan(vehicleClass);
  };

  @computed
  get showVRM() {
    return this.countryCode !== 'de';
  }

  @computed
  get canEditVATRate() {
    return this.countryCode === 'de';
  }

  @computed
  get showVehicleNumber() {
    return this.countryCode === 'de';
  }

  @computed
  get canAmendConsumption() {
    return this.countryCode === 'de';
  }

  @computed
  get canAmendEmissions() {
    return this.countryCode === 'de';
  }

  @computed
  get canAmendEfficiency() {
    return this.countryCode === 'de';
  }

  @computed
  get canViewBroadcastDataAccuracy() {
    return this.countryCode !== 'de';
  }

  @computed
  get canViewBroadcastVrmPlate() {
    return this.countryCode !== 'de';
  }

  @computed
  get dateDelimiter() {
    return this.countryCode === 'de' ? '.' : '/';
  }

  @computed
  get dateFormat() {
    return `DD${this.dateDelimiter}MM${this.dateDelimiter}YYYY`;
  }

  @action
  setFeatures = ({
    claims,
    isSysAdmin,
    dealershipModules,
    userClaims,
    dealershipId,
    countryCode,
    platformBrand,
    lenders
  }) => {
    const countryCodeLower = (typeof countryCode !== 'string' || countryCode === ''
      ? 'gb'
      : countryCode
    )?.toLowerCase();

    if (!CountryCodes.includes(countryCodeLower)) {
      throw new Error('Unknown Country Code');
    }

    const platformBrandLower = platformBrand?.toLowerCase();

    this.claims = claims;
    this.isSysAdmin = isSysAdmin;
    this.lenders = lenders;
    this.userClaims = userClaims;
    this.dealershipModules = dealershipModules;
    this.dealershipId = dealershipId;
    this.countryCode = countryCodeLower;
    this.platformBrand = platformBrandLower;

    if (this.hasBdkTheme) {
      setTheme('theme-bdk');
    } else if (this.hasDefaultTheme) {
      setTheme('theme-default');
    } else if (this.hasMotonovoTheme) {
      setTheme('theme-motonovo');
    } else if (this.hasBnppTheme) {
      setTheme('theme-bnpp');
    } else {
      setTheme('theme-default');
    }
  };
}

export default UiState;
