import { useTranslation } from 'react-i18next';
import React from 'react';
import './ordersAndDealsHelpModal.scss';

const OrdersAndDealsHelpModal = () => {
  const { t } = useTranslation('DigitalDeals');
  return (
    <div className="ordersAndDealsHelpModal">
      <h1 className="ordersAndDealsHelpModal__title">{t('OrdersAndDealsHelpModal.orders_deals')}</h1>
      <h2 className="ordersAndDealsHelpModal__subheader">{t('OrdersAndDealsHelpModal.what_are_online_orders')}</h2>
      <div className="ordersAndDealsHelpModal__text">{t('OrdersAndDealsHelpModal.online_orders_help_text')}</div>
      <h2 className="ordersAndDealsHelpModal__subheader">{t('OrdersAndDealsHelpModal.what_are_digital_deals')}</h2>
      <div className="ordersAndDealsHelpModal__text">{t('OrdersAndDealsHelpModal.digital_deals_help_text')}</div>
    </div>
  );
};

export default OrdersAndDealsHelpModal;
