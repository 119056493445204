import { useTranslation } from 'react-i18next';
import React, { useRef, useState } from 'react';
import { inject } from 'mobx-react';

import PanelContent from '~Common/PanelContent';
import Icon from '~Common/Icon/Icon';
import Button from '~Common/Button/Button';
import Modal from '~Common/Modal/Modal';

import { useSelectedVehicle, useMetaData, useSearchState, useFacetFilters } from '~Common/VisLookup/hooks';
import { Hits, SearchBox, Pagination, SelectedFacets } from '~Common/VisLookup/components';

import './stockRefinementSearch.scss';
import { useNavigate, useParams } from 'react-router-dom';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const StockRefinementSearch = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation(['Stock', 'Common']);
  const { condition, lookupTerm } = props.appStore.stockCreateStore.miscVehicleData;
  const searchBarRef = useRef(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const { selectedVehicleId } = useSelectedVehicle();
  const { hasResults, noResults, touched, vehicles } = useMetaData();
  const searchState = useSearchState();
  const { facetFilters } = useFacetFilters();
  const displaySearch = !selectedVehicleId;
  const displayPagination = !selectedVehicleId && vehicles.length > 0 && searchState === 'idle';
  const isVehicleSelected = selectedVehicleId !== null;
  const hasFacetFilters = facetFilters.length > 0;

  const createManually = () => {
    const { stockCreateStore } = props.appStore;
    const selectedVehicleData = {};
    stockCreateStore.setVehicleTaxonomyData(selectedVehicleData);
    navigate(`/d/${params.dealershipId}/stock/add/create`);
  };

  const handlePaginationChange = () => {
    scrollToRef(searchBarRef);
  };

  const handleNextClick = () => {
    const { stockCreateStore } = props.appStore;
    const selectedVehicleData =
      selectedVehicleId !== null && vehicles.filter((v) => v.objectID === selectedVehicleId)[0];
    stockCreateStore.setVehicleTaxonomyData(selectedVehicleData);
    navigate(`/d/${params.dealershipId}/stock/add/create`);
  };

  const handleCancel = () => {
    navigate(`/d/${params.dealershipId}/stock/list`, {
      replace: true
    });
  };

  return (
    <>
      <PanelContent noPadding>
        <div className="stockRefinementSearch__vehicleMiscInformation">
          <div className="stockRefinementSearch__vehicleConditions">
            {t('StockRefinementSearch.condition')}
            <span>{t(`Options.${condition}`, { ns: 'Common' })}</span>
          </div>
          <div className="stockRefinementSearch__vehicleVRMVIN">
            {t('StockRefinementSearch.vrm_vin')}
            <span>{lookupTerm}</span>
          </div>
        </div>
        <div className="stockRefinementSearch__comparison--outer">
          {displaySearch && <SearchBox ref={searchBarRef} />}
          {hasFacetFilters ? <SelectedFacets /> : null}
          <div className="stockRefinementSearch__visResults">
            {hasResults && (
              <p className="stockRefinementSearch__confirmVehicleText">
                {t('StockRefinementSearch.confirm_your_vehicle_derivative_from_the_search_results_below')}
              </p>
            )}
            <div className="stockRefinementSearch__manualCreation">
              <span className="stockRefinementSearch__createManuallyText">
                {t('StockRefinementSearch.i_dont_see_my_vehicle_in_the_list')}
              </span>
              <Icon name="search" />
              <button
                className="stockRefinementSearch__createManuallyButton"
                onClick={createManually}
                data-th="createManually"
              >
                {t('StockRefinementSearch.create_manually')}
              </button>
            </div>
            <Hits />
            {displayPagination && <Pagination onChange={handlePaginationChange} />}
            {(touched && noResults) || selectedVehicleId !== null ? (
              <div className="stockRefinementSearch__buttonContainer">
                <div className="stockRefinementSearch__button">
                  <Button onClick={() => setShowCancelModal(true)} buttonStyle="cancel" dataThook="stockRefCancelBtn">
                    {t('StockRefinementSearch.cancel')}
                  </Button>
                </div>
                <div className="stockRefinementSearch__button">
                  <Button
                    disabled={!(searchState === 'idle' && (noResults || isVehicleSelected))}
                    state={''}
                    onClick={handleNextClick}
                    dataThook="stockRefNextBtn"
                  >
                    {t('StockRefinementSearch.next')}
                  </Button>
                </div>
              </div>
            ) : null}{' '}
          </div>
        </div>
      </PanelContent>
      <Modal
        title={t('StockRefinementSearch.are_you_sure')}
        onClose={() => setShowCancelModal(false)}
        isOpen={showCancelModal}
        onConfirm={handleCancel}
        buttonText={t('StockRefinementSearch.i_understand')}
      >
        <div className="stockRefinementSearch__confirmationModal">
          {t(
            'StockRefinementSearch.if_you_proceed_your_vehicle_will_not_be_saved_and_youll_be_returned_to_your_stock_list'
          )}
        </div>
      </Modal>
    </>
  );
};

export default inject('appStore')(StockRefinementSearch);
