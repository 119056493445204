import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './dashboardEligibilityMark.scss';

const DashboardEligibilityMark = ({ matchRate, count }) => {
  const { t } = useTranslation('Dashboard');
  let status;
  let statusClassNameTag;
  const matchRates = ['high', 'medium', 'low'];

  if (matchRates.includes(matchRate)) {
    status = t(`DashboardEligibilityMark.${matchRate}`);
    statusClassNameTag = matchRate;
  } else {
    status = t('DashboardEligibilityMark.unknown');
    statusClassNameTag = 'UNKNOWN';
  }

  const classNames = classnames(
    'dashboardEligibilityMark',
    `dashboardEligibilityMark--${statusClassNameTag.toLowerCase()}`
  );

  return (
    <div className={classNames}>
      <div className="dashboardEligibilityMark__dot" />
      <div className="dashboardEligibilityMark__status">
        {status.toUpperCase()}{' '}
        <span className="dashboardEligibilityMark__text">{t('DashboardEligibilityMark.eligibility') + ' '}</span>
        <span className="dashboardEligibilityMark__count">
          ({count}
          &nbsp;
          {t('DashboardEligibilityMark.check', { count })})
        </span>
      </div>
    </div>
  );
};

DashboardEligibilityMark.defaultProps = {
  matchRate: 'unknown'
};
DashboardEligibilityMark.propTypes = {
  matchRate: PropTypes.string,
  count: PropTypes.number
};
export default DashboardEligibilityMark;
