import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ApplicationSummaryPanelHeader from '../../Application/components/ApplicationSummaryPanelHeader';
import MiniPanel from '../../Common/MiniPanel';
import OptionsValue from '../../Common/OptionsValue';
import AddressLine from '../../Common/AddressLine';
import Table from '../../Reporting/PartialSummarySections/SummaryTable/Table';
import TableHead from '../../Reporting/PartialSummarySections/SummaryTable/TableHead';
import TableRow from '../../Reporting/PartialSummarySections/SummaryTable/TableRow';
import TableCell from '../../Reporting/PartialSummarySections/SummaryTable/TableCell';
import './cfcAddressHistorySummaryTable.scss';
import WordBreak from '../../Common/WordBreak';

const CFCAddressHistorySummaryTable = ({ addresses, t, section, showValidationTicks, errorCount }) => {
  return (
    <MiniPanel>
      <ApplicationSummaryPanelHeader
        title={t('CFCAddressHistorySummaryTable.address_history')}
        section={section}
        showValidationTicks={showValidationTicks}
        errorCount={errorCount}
      />
      <div data-th="cfcAddressHistorySummaryTable" className="cfcAddressHistorySummaryTable__panel">
        {_.map(addresses, (address, index) => {
          return (
            <Table key={`addressHistory${index}`}>
              <TableRow>
                <TableHead width="25%">
                  <strong>
                    {t('CFCAddressHistorySummaryTable.address')} {index + 1}
                  </strong>
                </TableHead>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableHead>{t('CFCAddressHistorySummaryTable.address')}</TableHead>
                <TableCell>
                  <WordBreak>
                    <AddressLine address={address} />
                  </WordBreak>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableHead>{t('CFCAddressHistorySummaryTable.residency_status')}</TableHead>
                <TableCell>
                  <OptionsValue type="Residency" value={address.Residency} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableHead>{t('CFCAddressHistorySummaryTable.time_at_address')}</TableHead>
                <TableCell>
                  <span>
                    {t('CFCAddressHistorySummaryTable.year', {
                      count: address.TimeAtAddress.Years || 0
                    })}{' '}
                    {t('CFCAddressHistorySummaryTable.month', {
                      count: address.TimeAtAddress.Months || 0
                    })}
                  </span>
                </TableCell>
              </TableRow>
            </Table>
          );
        })}
      </div>
    </MiniPanel>
  );
};

CFCAddressHistorySummaryTable.propTypes = {
  personal: PropTypes.object,
  section: PropTypes.object,
  showValidationTicks: PropTypes.bool,
  addresses: PropTypes.array,
  errorCount: PropTypes.object
};

export default withTranslation('Quoting')(CFCAddressHistorySummaryTable);
