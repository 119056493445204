import React from 'react';
import PropTypes from 'prop-types';
import './threeColumnLayout.scss';

const ThreeColumnLayout = ({ children }) => (
  <div>
    {React.Children.toArray(children).map((child, i) => {
      return (
        <div className="threeColumnLayout" key={'col' + i}>
          {child}
        </div>
      );
    })}
  </div>
);

ThreeColumnLayout.propTypes = {
  children: PropTypes.node
};

export default ThreeColumnLayout;
