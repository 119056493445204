import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import CheckboxWithLabel from '../Common/Form/CheckboxWithLabel';
import InformationWarning from '../Common/InformationWarning';
import FormConsumerVehicleDetails from '../Common/FormConsumerVehicleDetails';
import { observer, inject } from 'mobx-react';
import { observable, action, reaction, toJS } from 'mobx';
import Validator from '../../validators/Validator';
import MobxForm from '../MobxForm/MobxForm';
import MobxFieldGroup from '../MobxForm/MobxFieldGroup';
import MobxLabel from '../MobxForm/MobxFormLabel';
import DateField from '../Common/Form/DateField';
import TextInputWrappingInput from '../Common/Form/TextInputWrappingInput';
import CurrencyInput from '../Common/Form/CurrencyInput';
import PercentageInput from '../Common/Form/PercentageInput';
import Button from '../Common/Button/Button';
import AddVapModal from '../Quoting/forms/AddVapModal';
import EditVapModal from '../Quoting/forms/EditVapModal';
import ValueAddedProduct from '../Quoting/forms/ValueAddedProduct';
import _ from 'lodash';
import { compose } from 'redux';
import { withParams, withNavigate } from 'hocs/router';
import './soldCash.scss';

@observer
class SoldCash extends React.Component {
  constructor() {
    super();
    this.validationRules = {
      DateOfPurchase: 'required,date',
      DateOfHandover: 'date',
      TotalPrice: 'currency',
      VehiclePrice: 'currency',
      BasicPrice: 'currency',
      VAT: 'number',
      VATAmount: 'currency',
      NonVatableItems: 'currency',
      AccessoriesInsurance: 'currency',
      AccessoriesWarranty: 'currency',
      AccessoriesOther: 'currency',
      PartExchange: 'currency',
      Settlement: 'currency'
    };
    this.state = {
      isAddVapModalOpen: false,
      isEditVapModalOpen: false,
      vapToEdit: null
    };
  }

  @observable
  formData = {
    DateOfPurchase: '',
    DateOfHandover: '',
    TotalPrice: '',
    VehiclePrice: '',
    BasicPrice: '',
    VAT: '',
    VATAmount: '',
    NonVatableItems: '',
    AccessoriesInsurance: '',
    AccessoriesWarranty: '',
    AccessoriesOther: '',
    PartExchange: '',
    Settlement: '',
    RemoveFromDashboard: false,
    ValueAddedProducts: []
  };
  @action
  setDateOfPurchase = (DateOfPurchase) => (this.formData.DateOfPurchase = DateOfPurchase.currentTarget.value);
  @action
  setDateOfHandover = (DateOfHandover) => (this.formData.DateOfHandover = DateOfHandover.currentTarget.value);
  @action
  setTotalPrice = (TotalPrice) => (this.formData.TotalPrice = TotalPrice);
  @action
  setVehiclePrice = (VehiclePrice) => (this.formData.VehiclePrice = VehiclePrice);
  @action
  setBasicPrice = (BasicPrice) => (this.formData.BasicPrice = BasicPrice);
  @action
  setVAT = (id, VAT) => (this.formData.VAT = VAT);
  @action
  setVATAmount = (VATAmount) => (this.formData.VATAmount = VATAmount);
  @action
  setNonVatableItems = (NonVatableItems) => (this.formData.NonVatableItems = NonVatableItems);
  @action
  setAccessoriesInsurance = (AccessoriesInsurance) => (this.formData.AccessoriesInsurance = AccessoriesInsurance);
  @action
  setAccessoriesWarranty = (AccessoriesWarranty) => (this.formData.AccessoriesWarranty = AccessoriesWarranty);
  @action
  setAccessoriesOther = (AccessoriesOther) => (this.formData.AccessoriesOther = AccessoriesOther);
  @action
  setPartExchange = (PartExchange) => (this.formData.PartExchange = PartExchange);
  @action
  setSettlement = (Settlement) => (this.formData.Settlement = Settlement);
  @action
  setNotes = (Notes) => (this.formData.Notes = Notes);
  @action
  toggleRemoveFromDashboard = () => (this.formData.RemoveFromDashboard = !this.formData.RemoveFromDashboard);
  @action
  setVapPrice = (index, price) => (this.formData.ValueAddedProducts[index].Price = price);

  UNSAFE_componentWillMount() {
    const vehicle = this.props.appStore.customerStore.customer.getVehicle(this.props.params.vehicleId);
    this.setInitialValues(vehicle);
    this.setupValidation();
  }

  @action
  setInitialValues = (vehicle) => {
    Object.assign(
      this.formData,
      _.get(vehicle, 'Status.Status') ? vehicle.Status : this.props.appStore.closeDealStore.closeDealData
    );
  };

  componentWillUnmount() {
    this.validationReaction();
  }

  setupValidation = () => {
    this.validator = new Validator();
    this.validator.setRules(this.validationRules);
    this.validationReaction = reaction(() => ({ ...this.formData }), this.validator.validate, {
      fireImmediately: true
    });
  };
  submit = () => {
    const closeDealStore = this.props.appStore.closeDealStore;
    const customerStore = this.props.appStore.customerStore;

    if (!this.validator.errorCount) {
      closeDealStore
        .markLeadAsSoldCash(
          toJS(this.formData),
          customerStore.customer.CustomerType,
          this.props.params.vehicleId,
          this.props.params.consumerId
        )
        .then(() => {
          this.props.navigate(`/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}`);
        });
    }
  };
  @action
  removeVap = (index) => {
    let vapState = this.formData.ValueAddedProducts;
    vapState = vapState.filter((v, i) => i !== index);
    this.formData.ValueAddedProducts = vapState;
  };
  editVap = (index) => {
    this.setState(
      {
        vapToEdit: index
      },
      () => {
        this.setState({
          isEditVapModalOpen: true
        });
      }
    );
  };
  openAddVapModal = () => {
    this.setState({
      isAddVapModalOpen: true
    });
  };
  closeAddVapModal = () => {
    this.setState({
      isAddVapModalOpen: false,
      isEditVapModalOpen: false,
      vapToEdit: null
    });
  };
  goBack = () => {
    this.props.navigate(-1);
  };
  @action
  handleSubmitAddVapModal = (productId, price, productTypeCode, shouldRemoveExistingDuplicate) => {
    this.props.appStore.closeDealStore.fetchVapProductType(this.props.params.dealershipId, productTypeCode).then(
      action(() => {
        const chosenProduct = _.find(this.props.appStore.closeDealStore.vapProductType, {
          Id: productId
        });

        if (shouldRemoveExistingDuplicate) {
          const toRemove = _.find(this.formData.ValueAddedProducts, (vap) => vap.ProductTypeCode === productTypeCode);

          this.removeVap(_.indexOf(this.formData.ValueAddedProducts, toRemove));
        }

        this.formData.ValueAddedProducts.push(chosenProduct);
        this.closeAddVapModal();
      })
    );
  };
  @action
  handleSubmitEditVapModal = (productId, price, productTypeCode, shouldRemoveExistingDuplicate) => {
    this.props.appStore.closeDealStore.fetchVapProductType(this.props.params.dealershipId, productTypeCode).then(
      action(() => {
        const chosenProduct = this.props.appStore.closeDealStore.updateVapPrice(productId, price);

        if (shouldRemoveExistingDuplicate) {
          const toRemove = _.find(this.formData.ValueAddedProducts, (vap) => vap.ProductTypeCode === productTypeCode);

          this.formData.ValueAddedProducts[this.state.vapToEdit] = chosenProduct;
          this.removeVap(_.indexOf(this.formData.ValueAddedProducts, toRemove));
        } else {
          this.formData.ValueAddedProducts[this.state.vapToEdit] = chosenProduct;
        }

        this.closeAddVapModal();
      })
    );
  };

  render() {
    if (this.props.appStore.customerStore.isLoadingCustomer) {
      return null;
    }
    const {
      uiState,
      closeDealStore,
      customerStore: { customer }
    } = this.props.appStore;

    let errors = this.validator.getErrors();
    const vehicle = customer.getVehicle(this.props.params.vehicleId);

    return (
      <MobxForm onSubmit={this.submit} className="soldCash" focusOnFirstElement>
        <div className="soldCash__formLeft">
          <div className="soldCash__left" key="left">
            <div className="soldCash__infoWarning" key="info1">
              <InformationWarning
                text={this.props.t(
                  'SoldCash.the_following_details_will_be_stored_against_the_customer_record_and_will_be_useful_for_reporting_as_it_will_form_a_historic_record_of_actions_related_to_this_vehicle'
                )}
              />
            </div>
            <div className="soldCash__vehicleDetails">
              <FormConsumerVehicleDetails consumer={customer} vehicle={vehicle} />
            </div>
            <div className="soldCash__dateFields" key="dateFields">
              <div className="soldCash__dateField">
                <MobxFieldGroup error={errors.DateOfPurchase}>
                  <MobxLabel htmlFor="DateOfPurchase">{this.props.t('SoldCash.customer_purchase_date')}</MobxLabel>
                  <DateField
                    value={this.formData.DateOfPurchase}
                    onChange={this.setDateOfPurchase}
                    tabIndex="2"
                    id="DateOfPurchase"
                  />
                </MobxFieldGroup>
              </div>
              <div className="soldCash__dateField">
                <MobxFieldGroup error={errors.DateOfHandover}>
                  <MobxLabel htmlFor="DateOfPurchase">{this.props.t('SoldCash.customer_handover_date')}</MobxLabel>
                  <DateField
                    value={this.formData.DateOfHandover}
                    onChange={this.setDateOfHandover}
                    tabIndex="3"
                    id="DateOfHandover"
                  />
                </MobxFieldGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="soldCash__formRight">
          <div className="soldCash__right" key="right">
            <div className="soldCash__qualifying">
              {this.formData.VATQualifying
                ? this.props.t('SoldCash.vat_qualifying')
                : this.props.t('SoldCash.vat_margin')}
            </div>
            <MobxFieldGroup size="small" error={errors.TotalPrice}>
              <MobxLabel htmlFor="TotalPrice">{this.props.t('SoldCash.total_price')}</MobxLabel>
              <CurrencyInput
                value={this.formData.TotalPrice}
                onChange={this.setTotalPrice}
                id="TotalPrice"
                tabIndex="4"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.VehiclePrice}>
              <MobxLabel htmlFor="VehiclePrice">{this.props.t('SoldCash.vehicle_price')}</MobxLabel>
              <CurrencyInput
                value={this.formData.VehiclePrice}
                onChange={this.setVehiclePrice}
                id="VehiclePrice"
                tabIndex="5"
              />
            </MobxFieldGroup>

            {this.formData.VATQualifying && (
              <div>
                <MobxFieldGroup size="small" error={errors.BasicPrice}>
                  <MobxLabel htmlFor="BasicPrice">{this.props.t('SoldCash.basic_price')}</MobxLabel>
                  <CurrencyInput
                    value={this.formData.BasicPrice}
                    onChange={this.setBasicPrice}
                    id="BasicPrice"
                    tabIndex="6"
                  />
                </MobxFieldGroup>

                <MobxFieldGroup size="small" error={errors.VAT}>
                  <MobxLabel htmlFor="VAT">{this.props.t('SoldCash.vat_percent')}</MobxLabel>
                  <PercentageInput value={this.formData.VAT} onChange={this.setVAT} id="VATPercent" tabIndex="7" />
                </MobxFieldGroup>

                <MobxFieldGroup size="small" error={errors.VATAmount}>
                  <MobxLabel htmlFor="VATAmount">{this.props.t('SoldCash.vat_amount')}</MobxLabel>
                  <CurrencyInput
                    value={this.formData.VATAmount}
                    onChange={this.setVATAmount}
                    id="VATAmount"
                    tabIndex="8"
                  />
                </MobxFieldGroup>
              </div>
            )}

            <>
              {this.formData.NonVatableItems ||
              this.formData.AccessoriesInsurance ||
              this.formData.AccessoriesWarranty ||
              this.formData.AccessoriesOther ? (
                <div>
                  <MobxFieldGroup size="small" error={errors.NonVatableItems}>
                    <MobxLabel htmlFor="NonVatableItems">{this.props.t('SoldCash.non_va_table_items')}</MobxLabel>
                    <CurrencyInput
                      value={this.formData.NonVatableItems}
                      onChange={this.setNonVatableItems}
                      id="NonVATableItems"
                      tabIndex="9"
                    />
                  </MobxFieldGroup>

                  <MobxFieldGroup size="small" error={errors.AccessoriesInsurance}>
                    <MobxLabel htmlFor="AccessoriesInsurance">
                      {this.props.t('SoldCash.accessories_insurance')}
                    </MobxLabel>
                    <CurrencyInput
                      value={this.formData.AccessoriesInsurance}
                      onChange={this.setAccessoriesInsurance}
                      id="AccessoriesInsurance"
                      tabIndex="10"
                    />
                  </MobxFieldGroup>

                  <MobxFieldGroup size="small" error={errors.AccessoriesWarranty}>
                    <MobxLabel htmlFor="AccessoriesWarranty">{this.props.t('SoldCash.accessories_warranty')}</MobxLabel>
                    <CurrencyInput
                      value={this.formData.AccessoriesWarranty}
                      onChange={this.setAccessoriesWarranty}
                      id="AccessoriesWarranty"
                      tabIndex="11"
                    />
                  </MobxFieldGroup>

                  <MobxFieldGroup size="small" error={errors.AccessoriesOther}>
                    <MobxLabel htmlFor="AccessoriesOther">{this.props.t('SoldCash.accessories_other')}</MobxLabel>
                    <CurrencyInput
                      value={this.formData.AccessoriesOther}
                      onChange={this.setAccessoriesOther}
                      id="AccessoriesOther"
                      tabIndex="12"
                    />
                  </MobxFieldGroup>
                </div>
              ) : (
                <div className="soldCash__vapsButtonContainer">
                  {this.formData.ValueAddedProducts &&
                    this.formData.ValueAddedProducts.map((vap, index) => (
                      <ValueAddedProduct
                        key={`valueAddedProduct-${index}`}
                        index={index}
                        label={vap.ProductTypeText}
                        value={vap.Price}
                        onChange={this.setVapPrice}
                        onRemove={() => this.removeVap(index)}
                        onEdit={() => this.editVap(index)}
                        error={errors.ValueAddedProducts && errors.ValueAddedProducts[index]}
                        size="small"
                        isInline={false}
                      />
                    ))}
                  <div className="soldCash__vapsButton">
                    <Button type="button" buttonStyle="secondary" onClick={this.openAddVapModal}>
                      {this.props.t('SoldCash.add_vap')}
                    </Button>
                  </div>
                </div>
              )}
            </>

            <MobxFieldGroup size="small" error={errors.PartExchange}>
              <MobxLabel htmlFor="PartExchange">{this.props.t('SoldCash.part_exchange_value')}</MobxLabel>
              <CurrencyInput
                value={this.formData.PartExchange}
                onChange={this.setPartExchange}
                id="PartExchangeValue"
                tabIndex="13"
              />
            </MobxFieldGroup>

            <MobxFieldGroup size="small" error={errors.Settlement}>
              <MobxLabel htmlFor="Settlement">{this.props.t('SoldCash.settlement_value')}</MobxLabel>
              <CurrencyInput
                value={this.formData.Settlement}
                onChange={this.setSettlement}
                id="SettlementValue"
                tabIndex="14"
              />
            </MobxFieldGroup>
            <div className="soldCash__asterisk">
              {this.props.t('SoldCash.these_items_are_included_in_the_total_price')}
            </div>
          </div>
        </div>
        <div className="soldCash__bottom">
          <div className="soldCash__divider" key="divider1" />
          <MobxFieldGroup error={errors.Notes}>
            <MobxLabel htmlFor="Notes">{this.props.t('SoldCash.close_out_comment')}</MobxLabel>
            <TextInputWrappingInput
              value={this.formData.Notes}
              onChange={this.setNotes}
              id="CloseOutComment"
              tabIndex="15"
              type="text"
            />
          </MobxFieldGroup>
          {uiState.canViewDashboard && (
            <>
              <div className="soldCash__infoWarning" key="info2">
                <InformationWarning
                  text={this.props.t(
                    'SoldCash.selecting_to_hide_the_deal_will_remove_visibility_from_the_homepage_dashboard_the_details_will_still_be_accessible_from_the_contacts_area_of_the_platform_removing_dormant_deals_from_your_dashboard_is_a_good_house_keeping_practice'
                  )}
                />
              </div>
              <div className="soldCash__checkbox">
                <CheckboxWithLabel
                  onChange={this.toggleRemoveFromDashboard}
                  value={this.formData.RemoveFromDashboard}
                  id="removeFromDashboard"
                >
                  {this.props.t('SoldCash.hide_this_lead_from_the_homepage_after_closing')}
                </CheckboxWithLabel>
              </div>
            </>
          )}
        </div>
        <div className="soldCash__buttons">
          <div className="soldCash__proceedButton">
            <Button
              type="submit"
              isLoading={closeDealStore.sendCloseDealRequest.isLoading}
              hasError={closeDealStore.sendCloseDealRequest.hasError}
            >
              {this.props.t('SoldCash.proceed')}
            </Button>
          </div>
          <div className="soldCash__cancelButton">
            <Button buttonStyle="cancel" type="button" onClick={this.goBack}>
              {this.props.t('SoldCash.cancel')}
            </Button>
          </div>
        </div>
        <AddVapModal
          isOpen={this.state.isAddVapModalOpen}
          onClose={this.closeAddVapModal}
          onSubmit={this.handleSubmitAddVapModal}
          currentVaps={this.formData.ValueAddedProducts}
          vehicleClass={vehicle.Class}
        />
        <EditVapModal
          isOpen={this.state.isEditVapModalOpen}
          onClose={this.closeAddVapModal}
          onSubmit={this.handleSubmitEditVapModal}
          vapToEdit={this.formData.ValueAddedProducts[this.state.vapToEdit]}
          currentVaps={this.formData.ValueAddedProducts}
          vehicleClass={vehicle.Class}
        />
      </MobxForm>
    );
  }
}

SoldCash.propTypes = {
  appStore: PropTypes.object,
  params: PropTypes.object
};

export default compose(
  withParams,
  withNavigate,
  withTranslation('CloseDeal'),
  inject(['appStore']),
  observer
)(SoldCash);
