import React from 'react';
import PropTypes from 'prop-types';
import './productInfoCardLayout.scss';

const ProductInfoCardLayout = ({ children }) => <div className="productInfoCardLayout">{children}</div>;

ProductInfoCardLayout.propTypes = {
  children: PropTypes.node
};

export default ProductInfoCardLayout;
