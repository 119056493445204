import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import QuoteCard from '~/components/QuoteCard/QuoteCard';
import NoPanelData from '../../Dashboard/components/NoPanelData';
import { QuoteCardVariantTypeJs } from '~/components/QuoteCard/types';

import './savedQuickQuotes.scss';

const SavedQuickQuotes = (props) => {
  const { t } = useTranslation('Customer');
  return (
    <div className="savedQuickQuotes">
      {props.savedQuotes.length > 0 ? (
        props.savedQuotes.map((savedQuote) => {
          const { Quote } = savedQuote;
          return (
            <QuoteCard
              variant={QuoteCardVariantTypeJs.SavedQuote}
              quote={Quote}
              application={savedQuote}
              actions={{
                viewApplication: () => props.viewSavedQuote(Quote)
              }}
              vehicleClass={props.vehicleClass}
              key={`SavedQuote-${Quote.QuoteId}`}
            />
          );
        })
      ) : (
        <NoPanelData text={t('SavedQuickQuotes.there_are_no_saved_quotes_for_this_vehicle')} />
      )}
    </div>
  );
};

SavedQuickQuotes.propTypes = {
  onSortSavedQuotes: PropTypes.func,
  consumer: PropTypes.object,
  savedQuotes: PropTypes.array,
  viewSavedQuote: PropTypes.func,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  vehicleId: PropTypes.string,
  vehicleClass: PropTypes.string
};
export default SavedQuickQuotes;
