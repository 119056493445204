import React from 'react';
import PropTypes from 'prop-types';
import './twoColumnLayout.scss';

const TwoColumnLayout = ({ children }) => (
  <div>
    {React.Children.toArray(children).map((child, i) => {
      return (
        <div className="twoColumnLayout" key={'col' + i}>
          {child}
        </div>
      );
    })}
  </div>
);

TwoColumnLayout.propTypes = {
  children: PropTypes.node
};

export default TwoColumnLayout;
