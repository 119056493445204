import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LatestDealsPage from '../pages/LatestDeals';
import * as latestDealsActions from '../../../redux/latestDeals/latestDealsActions';
import * as componentActions from '../../../redux/component/componentActions';
import ErrorApology from '../../Common/ErrorApology';
import { getStatusOptions, getLatestDeals } from '../selectors/latestDealsSelectors';
import { compose } from 'redux';
import { withNavigate, withParams, withQuery } from 'hocs/router';

class LatestDealsContainer extends Component {
  componentDidMount() {
    this.props.load('LatestDealsContainer');
  }

  componentWillUnmount() {
    this.props.unload('LatestDealsContainer');
  }

  render() {
    const { children, ...remainingProps } = this.props;

    if (this.props.components.LatestDealsContainer && this.props.components.LatestDealsContainer.errorMessage) {
      return <ErrorApology>{this.props.components.LatestDealsContainer.errorMessage}</ErrorApology>;
    }

    if (!(this.props.components.LatestDealsContainer && this.props.components.LatestDealsContainer.hasLoaded)) {
      return null;
    }

    return <LatestDealsPage {...remainingProps} />;
  }
}

LatestDealsContainer.propTypes = {
  dealership: PropTypes.object,
  load: PropTypes.func,
  unload: PropTypes.func,
  children: PropTypes.node,
  latestDeals: PropTypes.array,
  components: PropTypes.object,
  handleConsumer: PropTypes.func,
  handleApplicationClick: PropTypes.func,
  options: PropTypes.object,
  handleSearchDateChange: PropTypes.func,
  setStatusFilter: PropTypes.func,
  handleLatestDealsPage: PropTypes.func
};

function mapStateToProps(state) {
  return {
    session: state.session,
    dealership: state.dealership,
    components: state.components,
    latestDeals: getLatestDeals(state),
    statuses: getStatusOptions(state),
    options: state.options,
    status: state.latestDeals.status,
    page: state.latestDeals.page,
    pageSize: state.latestDeals.pageSize,
    total: state.latestDeals.total,
    timePeriod: state.latestDeals.timePeriod
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    load: (componentName) => {
      dispatch(componentActions.componentLoad(componentName, null, ownProps.query));
    },
    unload: (componentName) => {
      dispatch(componentActions.componentUnload(componentName, ownProps.params));
    },
    handleConsumer: (customerId) => {
      ownProps.navigate(`/d/${ownProps.params.dealershipId}/consumers/${customerId}`);
    },
    handleApplicationClick: (customerId, applicationId) => {
      ownProps.navigate(
        `/d/${ownProps.params.dealershipId}/consumers/${customerId}/application/${applicationId}/status`
      );
    },
    setStatusFilter: (status) => {
      dispatch(latestDealsActions.setStatusFilter(status));
    },
    handleSearchDateChange: (from, to, timePeriod) => {
      dispatch(latestDealsActions.searchLatestDealsByDate(from, to, timePeriod));
    },
    handleLatestDealsPage: (page) => {
      dispatch(latestDealsActions.changePage(page));
    }
  };
}

export default compose(
  withNavigate,
  withParams,
  withQuery,
  connect(mapStateToProps, mapDispatchToProps)
)(LatestDealsContainer);
