import React from 'react';
import PropTypes from 'prop-types';
import './divider.scss';

const Divider = (props) => <div style={props.margin && { margin: `${props.margin}` }} className="divider__div" />;

Divider.propTypes = {
  margin: PropTypes.string
};

export default Divider;
