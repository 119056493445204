import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Panel from '~/components/Common/Panel';
import PanelContent from '~/components/Common/PanelContent';
import PanelHeader from '~/components/Common/PanelHeader';
import { FinanceNavigatorFormSection } from '../types/financeNavigatorForm';
import { useFinanceNavigatorForm } from '../hooks/useFinanceNavigatorForm';
import { FinanceNavigatorPersonalDetails } from '../forms/FinanceNavigatorPersonalDetails';
import { FinanceNavigatorAddressHistory } from '../forms/FinanceNavigatorAddressHistory';
import { FinanceNavigatorSummary } from '../forms/FinanceNavigatorSummary';
import { trackPageViewed } from '~/tracking/avoTracking';
import { useParams } from 'react-router-dom';
import { splitCamelCaseToString } from '~/core/helpers';
import { FinanceNavigatorNavigation } from './FinanceNavigatorNavigation';
import { FinanceNavigatorEmploymentHistory } from '../forms/FinanceNavigatorEmploymentHistory';
import { FinanceNavigatorAffordabilityDetails } from '../forms/FinanceNavigatorAffordabilityDetails';
import { CustomerVehicleOfInterest, Vehicle } from '~/types/vehicle';
import './financeNavigatorContainer.scss';
import { DataProtectionNoticeModal } from '../components/DataProtectionNoticeModal/DataProtectionNoticeModal';

interface FinanceNavigatorContainerProps {
  onCancel: () => void;
  vehicle: CustomerVehicleOfInterest | Vehicle;
  quoteIds: string[];
  quotes: { QuoteId: string; FunderCode: string; Errors: [] }[];
}

export const FinanceNavigatorContainer = ({ onCancel, vehicle, quotes, quoteIds }: FinanceNavigatorContainerProps) => {
  const { dealershipId } = useParams();
  const { t } = useTranslation('FinanceNavigator');
  const {
    formData,
    progress,
    setPersonalDetails,
    setAddressHistory,
    setEmploymentHistory,
    setAffordabilityDetails
  } = useFinanceNavigatorForm();
  const [section, setSection] = useState<FinanceNavigatorFormSection>('PersonalDetails');
  const [dataProtectionModalIsOpen, setDataProtectionModalIsOpen] = useState(false);
  useEffect(() => trackPageViewed(`Finance Navigator - ${splitCamelCaseToString(section)}`, dealershipId!), [
    section,
    dealershipId
  ]);
  const uniqueFunderCodes = quotes
    .map((x) => x.FunderCode)
    .filter((x, i, a) => a.indexOf(x) === i) // get distinct values
    .sort((a, b) => a.localeCompare(b)); // sort alphabetically

  return (
    <>
      <Panel>
        <PanelHeader>{t('FinanceNavigator.finance_navigator')}</PanelHeader>
        <PanelContent>
          <div className="financeNavigator__container">
            <FinanceNavigatorNavigation progress={progress} section={section} setSection={setSection} />
            <div className="financeNavigator__form">
              {section === 'PersonalDetails' && (
                <FinanceNavigatorPersonalDetails
                  personalDetails={formData.PersonalDetails}
                  onSubmit={(personalDetails) => {
                    setPersonalDetails(personalDetails);
                    setSection('AddressHistory');
                  }}
                  onCancel={onCancel}
                />
              )}
              {section === 'AddressHistory' && (
                <FinanceNavigatorAddressHistory
                  addressHistory={formData.AddressHistory}
                  onSubmit={(addressHistory) => {
                    setAddressHistory(addressHistory);
                    setSection('EmploymentHistory');
                  }}
                  onCancel={() => setSection('PersonalDetails')}
                />
              )}
              {section === 'EmploymentHistory' && (
                <FinanceNavigatorEmploymentHistory
                  employmentHistory={formData.EmploymentHistory}
                  onSubmit={(employmentHistory) => {
                    setEmploymentHistory(employmentHistory);
                    setSection('AffordabilityDetails');
                  }}
                  onCancel={() => setSection('AddressHistory')}
                />
              )}
              {section === 'AffordabilityDetails' && (
                <FinanceNavigatorAffordabilityDetails
                  affordabilityDetails={formData.AffordabilityDetails}
                  onSubmit={(affordabilityDetails) => {
                    setAffordabilityDetails(affordabilityDetails);
                    setSection('Summary');
                  }}
                  onCancel={() => setSection('EmploymentHistory')}
                />
              )}
              {section === 'Summary' && (
                <FinanceNavigatorSummary
                  formData={formData}
                  vehicle={vehicle}
                  onCancel={() => setSection('AffordabilityDetails')}
                  onSubmit={(_formData) => {
                    setDataProtectionModalIsOpen(true);
                  }}
                />
              )}
            </div>
          </div>
        </PanelContent>
      </Panel>
      <DataProtectionNoticeModal
        isOpen={dataProtectionModalIsOpen}
        onClose={() => setDataProtectionModalIsOpen(false)}
        funderCodes={uniqueFunderCodes}
        onSubmit={() => {}} // TODO: Call API and show results page
      />
    </>
  );
};
