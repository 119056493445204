import React from 'react';
import PropTypes from 'prop-types';
import './panelSection.scss';
import classNames from 'classnames';

const PanelSection = ({ title, children, grayStyle }) => {
  const classes = classNames('panelSection', grayStyle && 'panelSection--grayStyle');

  return (
    <div className={classes}>
      <h2 className="panelSection__heading">{title}</h2>
      {children}
    </div>
  );
};

PanelSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  grayStyle: PropTypes.bool
};

export default PanelSection;
