import React from 'react';
import PropTypes from 'prop-types';
import './exclamationWarning.scss';
import helpIcon from '../../styles/icons/i.svg';
import Image from './Image';

const ExclamationWarning = ({ title, message, children }) => {
  return (
    <div className="exclamationWarning">
      <div className="exclamationWarning__tableRow">
        <div className="exclamationWarning__imageContainer">
          <div className="exclamationWarning__image">
            <Image imageName={helpIcon} />
          </div>
        </div>

        <div className="exclamationWarning__body">
          {title && <span className="exclamationWarning__title">{title}</span>}
          <span>{message || children}</span>
        </div>
      </div>
    </div>
  );
};

ExclamationWarning.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string
};

export default ExclamationWarning;
