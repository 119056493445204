import { observable, action } from 'mobx';
import * as platformApiUtils from '@ivendi/platform-api-utils';
import moment from 'moment';
import _ from 'lodash';

class ReportsStore {
  @observable allReports;
  @observable report = null;
  @observable isLoadingReports = false;
  @observable options = [];
  @observable filterMonth = '';
  @observable reportError = false;

  @action
  initReportMonths = (dealershipId) => {
    this.reportError = false;
    this.isLoadingReports = true;
    platformApiUtils
      .get('v1/report/rooftop/monthly/' + dealershipId + '/')
      .then(
        action((response) => {
          this.allReports = response;
          this.isLoadingReports = false;
        })
      )
      .catch(
        action(() => {
          this.isLoadingReports = false;
          this.reportError = true;
        })
      );
  };

  @action
  initReports = (dealershipId, date, reportType, path) => {
    this.reportError = false;
    this.isLoadingReports = true;
    platformApiUtils
      .get('v1/report/rooftop/monthly/' + dealershipId + '/')
      .then(
        action((response) => {
          this.allReports = response;
          const formattedReportMonths = this.allReports[reportType].map((month) => {
            return moment(month).format('YYYY-MM-DD');
          });
          const isUsedDate = _.includes(formattedReportMonths, date);

          if (date && isUsedDate) {
            this.filterMonth =
              this.allReports[reportType].length === 1
                ? moment(this.allReports[reportType][0]).format('YYYY-MM-DD')
                : moment(date).format('YYYY-MM-DD');
          } else {
            this.filterMonth =
              this.allReports[reportType].length === 1
                ? moment(this.allReports[reportType][0]).format('YYYY-MM-DD')
                : moment(this.allReports[reportType][1]).format('YYYY-MM-DD');
          }

          this.options = this.allReports[reportType].map((month) => {
            return { key: moment(month).format('YYYY-MM-DD'), value: moment(month).format('MMMM YYYY') };
          });

          if (this.allReports[reportType].length === 0) {
            this.reportError = true;
            throw new Error('no reports');
          }
        })
      )
      .then(() => platformApiUtils.get('v1/report/rooftop/' + path + dealershipId + '/' + this.filterMonth + '/'))

      .then(
        action((response) => {
          this.report = response;
          this.isLoadingReports = false;
        })
      )
      .catch(
        action(() => {
          this.isLoadingReports = false;
          this.reportError = true;
        })
      );
  };

  @action
  fetchReport = (dealershipId, date, path) => {
    this.isLoadingReports = true;
    platformApiUtils
      .get('v1/report/rooftop/' + path + dealershipId + '/' + date + '/')
      .then(
        action((response) => {
          this.isLoadingReports = false;
          this.report = response;
        })
      )
      .catch(() => {
        this.isLoadingReports = false;
      });
  };

  @action
  selectMonth = (dealershipId, month, path) => {
    this.filterMonth = moment(month).format('YYYY-MM-DD');
    this.fetchReport(dealershipId, month, path);
  };
}

export default ReportsStore;
