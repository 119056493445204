import platformApiUtils from '@ivendi/platform-api-utils';
import { throttle, merge, forEach } from 'lodash';
import * as actionTypes from '../actionTypes';
import { schemaMap } from '../../core/schema';
import dashboardSchema from '../../core/schemas/dashboardSchema';
import dashboardCfcProspectsSchema from '../../core/schemas/dashboardCfcProspectsSchema';
import dashboardFinanceApplicationsSchema from '../../core/schemas/dashboardFinanceApplicationsSchema';
import dashboardLenderUpdatesSchema from '../../core/schemas/dashboardLenderUpdatesSchema';
import dealershipEventEmitter from '../../utils/dealershipEventEmitter';
import { normalize, arrayOf } from 'normalizr';
import { dashboardItemNormalizrSchema } from '../../core/normalizrSchemas';
import { parseDashboardData } from '../../core/apiDataParsers/dashboardDataParser';
import * as debug from '../../debug';

export function updateDashboardAssignedTo(customerId, newAssignees, leadIsNowAssigned, leadIsNowUnassigned) {
  return {
    type: actionTypes.DASHBOARD_UPDATE_ASSIGNED_TO,
    customerId,
    newAssignees,
    leadIsNowAssigned,
    leadIsNowUnassigned
  };
}

export function subscribeToUpdates() {
  return function(dispatch) {
    const onMessage = throttle(
      () => {
        dispatch(fetchDashboardData());
      },
      10000,
      { leading: false }
    );
    dealershipEventEmitter.on('DashboardSearchIndexingEventHandler/DashboardSearchReadModelUpdated', onMessage);
  };
}

export function unSubscribeFromUpdates() {
  return function() {
    dealershipEventEmitter.off('DashboardSearchIndexingEventHandler/DashboardSearchReadModelUpdated');
    dealershipEventEmitter.off('finance_quotes_displayed');
  };
}

function getDashboardOptions(state) {
  let dashboardOptions = state.dashboard.options;
  const sections = ['Applications', 'CfcProspects', 'LenderUpdates'];
  let options = {};
  forEach(sections, (section) => {
    options[section] = {
      PageSize: dashboardOptions[section].PageSize,
      AssignedTo: dashboardOptions[section].activeFilter === 'mine' ? [state.session.UserId] : undefined,
      IsAssigned: dashboardOptions[section].activeFilter === 'unassigned' ? false : undefined,
      MatchRate: dashboardOptions[section].MatchRate,
      Status: dashboardOptions[section].status
    };
  });

  return options;
}

export function fetchDashboardData(canViewReservations) {
  return (dispatch, getState) => {
    let state = getState();
    if (canViewReservations === true) {
      Object.assign(
        {},
        state,
        (state.dashboard.options.Applications.PageSize = 3),
        (state.dashboard.options.CfcProspects.PageSize = 3),
        (state.dashboard.options.LenderUpdates.PageSize = 3)
      );
    }

    let dealershipId = state.dealership.Id;
    dispatch({
      types: [
        actionTypes.FETCH_DASHBOARD_DATA,
        actionTypes.FETCH_DASHBOARD_DATA_SUCCESS,
        actionTypes.FETCH_DASHBOARD_DATA_ERROR
      ],
      callAPI: () => platformApiUtils.post('v1/dashboard/all/' + dealershipId, getDashboardOptions(state)),
      parseResponse: (data) => {
        const response = schemaMap(data, dashboardSchema);
        const applications = normalize(response.Applications.Data, arrayOf(dashboardItemNormalizrSchema));
        const cfcProspects = normalize(response.CfcProspects.Data, arrayOf(dashboardItemNormalizrSchema));
        const lenderUpdates = normalize(response.LenderUpdates.Data, arrayOf(dashboardItemNormalizrSchema));

        response.Applications.Data = applications.result;
        response.CfcProspects.Data = cfcProspects.result;
        response.LenderUpdates.Data = lenderUpdates.result;

        response.entities = merge(applications.entities, cfcProspects.entities, lenderUpdates.entities);

        return parseDashboardData(response);
      }
    });
  };
}

export function fetchDashboardSection(dealershipId, section) {
  return (dispatch, getState) => {
    let state = getState();
    let options = getDashboardOptions(state);
    dispatch({
      types: [
        actionTypes.FETCH_DASHBOARD_SECTION,
        actionTypes.FETCH_DASHBOARD_SECTION_SUCCESS,
        actionTypes.FETCH_DASHBOARD_SECTION_ERROR
      ],
      payload: { section },
      callAPI: () => {
        switch (section) {
          case 'CfcProspects':
            return platformApiUtils.get('v1/dashboard/cfcprospects/' + dealershipId, options.CfcProspects);

          case 'Applications':
            return platformApiUtils.get('v1/dashboard/applications/' + dealershipId, options.Applications);

          case 'LenderUpdates':
            return platformApiUtils.get('v1/dashboard/lenderupdates/' + dealershipId, options.LenderUpdates);
          default:
            debug.error(`attempted fetch unknown dashboard route: ${section}`);
        }
      },
      parseResponse: (data) => {
        let response;
        let normalizedData;

        switch (section) {
          case 'CfcProspects':
            response = schemaMap(data, dashboardCfcProspectsSchema);
            normalizedData = normalize(response.Data, arrayOf(dashboardItemNormalizrSchema));
            response.entities = normalizedData.entities;
            response.Data = normalizedData.result;
            break;
          case 'Applications':
            response = schemaMap(data, dashboardFinanceApplicationsSchema);
            normalizedData = normalize(response.Data, arrayOf(dashboardItemNormalizrSchema));
            response.entities = normalizedData.entities;
            response.Data = normalizedData.result;
            break;

          case 'LenderUpdates':
            response = schemaMap(data, dashboardLenderUpdatesSchema);
            normalizedData = normalize(response.Data, arrayOf(dashboardItemNormalizrSchema));
            response.entities = normalizedData.entities;
            response.Data = normalizedData.result;

            break;

          default:
            break;
        }

        return response;
      }
    });
  };
}
export function filterDashboardLeads(leadState, userId) {
  return {
    type: actionTypes.FILTER_DASHBOARD_LEADS,
    leadState,
    userId
  };
}

export function filterDashboardSection(section, options) {
  return {
    type: actionTypes.FILTER_DASHBOARD_SECTION,
    options,
    section
  };
}

export function resetDashboard() {
  return {
    type: actionTypes.RESET_DASHBOARD
  };
}
