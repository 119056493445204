import React from 'react';
import Icon from '../../Common/Icon/Icon';
import './StatusIcon.scss';

const getIconName = (status) => {
  switch (status) {
    case 'Error':
    case 'NotTakenUp':
    case 'WaitingForFurtherInformation':
    case 'Rejected':
      return 'cross';

    case 'Not Submitted':
    case 'Submitting':
    case 'Pending':
      return 'pending-circle';

    default:
      return 'tick';
  }
};

const StatusIcon = ({ status }) => {
  let icon = getIconName(status);

  return (
    <div className={`statusIcon statusIcon--${icon}`} data-th="status-icon">
      <Icon name={icon} />
    </div>
  );
};

export default StatusIcon;
