import React, { useState } from 'react';
import MultiLoansDetails from './MultiLoansDetails';
import FieldToggle from '~/components/Common/Form/FieldToggle';
import { useTranslation } from 'react-i18next';
import { MultiQuotesFormatResponse } from '../../utils/getQuoteDisplaySchema';
import './multiLoansContainer.scss';

type MultiLoansHeaderProps = {
  isLoansExpanded: boolean;
  toggleMultiScheduleComponents: (isExpanded: boolean) => void;
};

export const MultiLoansHeader: React.FC<MultiLoansHeaderProps> = ({
  isLoansExpanded,
  toggleMultiScheduleComponents
}) => {
  const { t } = useTranslation('QuoteCard');

  return (
    <header className="multiLoansHeader">
      <h1 className="multiLoansHeader__divider"></h1>
      <p>{t('MultiLoansContainer.multiple_finance_components')}</p>
      <div className="multiLoansHeader__button">
        <FieldToggle
          text="Components"
          onClick={() => {
            toggleMultiScheduleComponents && toggleMultiScheduleComponents(!isLoansExpanded);
          }}
          showHide={isLoansExpanded}
          trackingItem={'IndividualQuote'}
        />
      </div>
    </header>
  );
};

export type MultiLoansContainerProps = {
  loans: MultiQuotesFormatResponse;
  toggleMultiScheduleComponents: (isExpanded: boolean) => void;
  isSingleColumn?: boolean;
};

const MultiLoansContainer: React.FC<MultiLoansContainerProps> = ({
  loans,
  toggleMultiScheduleComponents,
  isSingleColumn
}) => {
  const [isLoansExpanded, setIsLoansExpanded] = useState(false);

  return (
    <div className="multiLoansContainer">
      <MultiLoansHeader
        isLoansExpanded={isLoansExpanded}
        toggleMultiScheduleComponents={(isExpanded) => {
          toggleMultiScheduleComponents && toggleMultiScheduleComponents(isExpanded);
          setIsLoansExpanded(isExpanded);
        }}
      />

      {isLoansExpanded && <MultiLoansDetails loans={loans} isSingleColumn={isSingleColumn} />}
    </div>
  );
};

export default MultiLoansContainer;
