import { Control, Controller, FormProvider, useFieldArray, useForm, UseFormSetValue } from 'react-hook-form';
import { FieldGroupRHF } from '~/components/Common/Form/FieldGroupRHF';
import FormFooter from '~/components/Common/Form/FormFooter';
import SelectInput from '~/components/Common/Form/SelectInput';
import { employmentHistoryValidator, useRhfValidationResolver } from './validation';
import ApplicationCard from '~/components/Application/components/ApplicationCard';
import MiniPanel from '~/components/Common/MiniPanel';
import MiniPanelHeader from '~/components/Common/MiniPanelHeader';
import YearsMonthsField from '~/components/Common/Form/YearsMonthsField';
import { useState, useEffect } from 'react';
import { FinanceNavigatorFormEmploymentHistory } from '../types/financeNavigatorForm';
import { initialFormData } from '../hooks/useFinanceNavigatorForm';
import ExclamationWarningWithButton from '~/components/Common/ExclamationWarningWithButton';
import { useTranslation } from 'react-i18next';
import { getYearsAndMonthsFromTotalMonths } from '~/utils/application';
import { FinanceNavigatorSubTitle, FinanceNavigatorTitle } from '../components/FinanceNavigatorTitle';
import './financeNavigatorEmploymentHistory.scss';

export interface FinanceNavigatorEmploymentHistoryProps {
  employmentHistory: FinanceNavigatorFormEmploymentHistory;
  onSubmit: (employmentHistory: FinanceNavigatorFormEmploymentHistory) => void;
  onCancel: () => void;
}

export const FinanceNavigatorEmploymentHistory = ({
  employmentHistory,
  onSubmit,
  onCancel
}: FinanceNavigatorEmploymentHistoryProps) => {
  const { t } = useTranslation('FinanceNavigator');
  return (
    <div>
      <FinanceNavigatorTitle text={t('EmploymentHistoryForm.employment_history')} />
      <FinanceNavigatorSubTitle text={t('EmploymentHistoryForm.please_enter_at_least_3_years_of_employment_history')} />
      <EmploymentHistoryFormRhf onSubmit={onSubmit} onCancel={onCancel} defaultValues={employmentHistory} />
    </div>
  );
};

type EmploymentHistoryFormRhfProps = {
  defaultValues: FinanceNavigatorFormEmploymentHistory;
  onSubmit: (data: FinanceNavigatorFormEmploymentHistory) => void;
  onCancel: () => void;
};

export const EmploymentHistoryFormRhf = ({ onSubmit, onCancel, defaultValues }: EmploymentHistoryFormRhfProps) => {
  const { t } = useTranslation('FinanceNavigator');
  const [editingEmployment, setEditingEmployment] = useState(-1);
  const resolver = useRhfValidationResolver(employmentHistoryValidator);
  const methods = useForm<FinanceNavigatorFormEmploymentHistory>({
    defaultValues: defaultValues,
    mode: 'all',
    resolver
  });
  const { handleSubmit, formState, getValues, control, setValue } = methods;
  const { fields, remove, append } = useFieldArray({ control, name: 'Items' });
  const [showTotalMonthsError, setShowTotalMonthsError] = useState(false);

  useEffect(() => {
    if (fields.length === 1) {
      setEditingEmployment(0);
    }
  }, [fields]);

  const handleRemoveEmployment = (index: number) => {
    remove(index);
  };

  const handleAddEmployment = () => {
    append(initialFormData.EmploymentHistory.Items[0]);
    setEditingEmployment(fields.length);
    setShowTotalMonthsError(false);
  };

  const handleSubmitWithValidation = (data: FinanceNavigatorFormEmploymentHistory) => {
    const totalMonths = data.Items.reduce(
      (curr: number, next: any) => curr + (next.TimeAtEmployment?.TotalMonths || 0),
      0
    );
    if (totalMonths >= 36) {
      onSubmit({ ...data, TotalMonths: totalMonths });
    } else {
      setShowTotalMonthsError(true);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSubmitWithValidation)} data-th="employmentHistoryForm">
        {fields &&
          fields.map((field, index) => {
            // @ts-ignore
            const errors = formState.errors.Items?.[index] || {};
            const errorsCount = Object.keys(errors).length;
            return (
              <MiniPanel key={`mini_${index}`}>
                <MiniPanelHeader title={`Employment ${index + 1}`} />
                {/* Preview Mode */}
                {fields.length > 1 && editingEmployment !== index ? (
                  <ApplicationCard
                    type="employment"
                    data={getValues(`Items.${index}`)}
                    edit={() => setEditingEmployment(index)}
                    remove={() => handleRemoveEmployment(index)}
                    showRemove={fields.length > 1}
                    errorCount={errorsCount}
                    submitted={false}
                    key={`card_${field.id}`}
                  />
                ) : (
                  <EditEmploymentHistoryForm
                    key={`form_${field.id}`}
                    control={control}
                    setValue={setValue}
                    index={index}
                  />
                )}
              </MiniPanel>
            );
          })}
        {showTotalMonthsError && (
          <ExclamationWarningWithButton
            title={t('EmploymentHistoryForm.thank_you')}
            message={t('EmploymentHistoryForm.but_you_need_to_give_at_least_3_years_of_employment_history')}
            buttonText={t('EmploymentHistoryForm.add_new_employment')}
            onClick={handleAddEmployment}
          />
        )}
        <FormFooter
          submitLabel={t('FinanceNavigator.next')}
          submitDisabled={!methods.formState.isValid}
          hideSubmit={false}
          submittingState=""
          cancelLabel={t('FinanceNavigator.back')}
          onCancel={onCancel}
        />
      </form>
    </FormProvider>
  );
};

const EditEmploymentHistoryForm = ({
  index,
  control,
  setValue
}: {
  index: number;
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
}) => {
  const { t } = useTranslation('FinanceNavigator');
  return (
    <div className="financeNavigatorEmploymentHistory__editForm">
      {/* Employment Form */}
      <Controller
        name={`Items.${index}.OccupationBasis`}
        control={control}
        render={({ field, fieldState }) => (
          <FieldGroupRHF label={t('EmploymentHistoryForm.occupation_basis')} name={field.name}>
            {/* @ts-ignore - missing properties */}
            <SelectInput
              options="EmploymentBasis"
              id={field.name}
              value={field.value}
              onBlur={field.onBlur}
              onChange={(_id: string, value: string) => field.onChange(value)}
              isInvalid={fieldState.invalid}
              dataThook={field.name}
            />
          </FieldGroupRHF>
        )}
      />
      <Controller
        name={`Items.${index}.OccupationType`}
        control={control}
        render={({ field, fieldState }) => (
          <FieldGroupRHF label={t('EmploymentHistoryForm.occupation_type')} name={field.name}>
            {/* @ts-ignore - missing properties */}
            <SelectInput
              options="OccupationType"
              id={field.name}
              value={field.value}
              onBlur={field.onBlur}
              onChange={(_id: string, value: string) => field.onChange(value)}
              isInvalid={fieldState.invalid}
              dataThook={field.name}
            />
          </FieldGroupRHF>
        )}
      />
      <Controller
        name={`Items.${index}.TimeAtEmployment.TotalMonths`}
        control={control}
        render={({ field, fieldState }) => (
          <FieldGroupRHF label={t('EmploymentHistoryForm.time_at_employment')} name={field.name}>
            <YearsMonthsField
              id={field.name}
              name={field.name}
              value={field.value}
              onBlur={field.onBlur}
              onChange={(totalMonths: number) => {
                const { years, months } = getYearsAndMonthsFromTotalMonths(totalMonths);
                setValue(`Items.${index}.TimeAtEmployment.Months`, months);
                setValue(`Items.${index}.TimeAtEmployment.Years`, years);
                field.onChange(totalMonths);
              }}
              isInvalid={fieldState.invalid}
            />
          </FieldGroupRHF>
        )}
      />
    </div>
  );
};
