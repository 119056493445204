import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './valueAddedProductsTableRow.scss';
import { Link } from 'react-router-dom';
import Icon from '../Common/Icon/Icon';
import ToggleSwitch from '../Common/ToggleSwitch';
import Truncator from '../Common/Truncator';
import { inject, observer } from 'mobx-react';
import Modal from '../Common/Modal/Modal';
import Button from '../Common/Button/Button';
import _ from 'lodash';
import MoneyFormat from '../Common/MoneyFormat';
import { VapProductTypes } from '~/constants';
import { disableGapProducts } from '~/features';

class ValueAddedProductsTableRow extends React.Component {
  constructor() {
    super();
    this.state = {
      isDefaultModalOpen: false
    };
  }

  onToggleVisibility = (checked) => {
    this.props.setVisibility(this.props.product.Id, checked, this.props.dealershipId);

    if (!checked && this.props.product.Default) {
      this.props.setDefault(this.props.product.Id, false, this.props.dealershipId);
    }
  };
  onToggleDefault = (checked) => {
    const productCode = parseInt(this.props.product.ProductTypeCode, 10);

    const findUsedProduct = _.find(this.props.appStore.vapStore.valueAddedProducts, {
      ProductTypeCode: productCode,
      Default: true
    });

    let isAllowedMultipleTypes = false;

    if (productCode === 11 || productCode === 13 || productCode === 14 || productCode === 17) {
      isAllowedMultipleTypes = true;
    }

    if (findUsedProduct && findUsedProduct.Id !== this.props.product.Id && !isAllowedMultipleTypes) {
      this.setState({
        isDefaultModalOpen: true
      });
    } else {
      this.props.setDefault(this.props.product.Id, checked, this.props.dealershipId);
    }
  };
  handleModalClose = () => {
    this.setState({
      isDefaultModalOpen: false
    });
  };
  onModalSubmit = () => {
    const findUsedProduct = _.find(this.props.appStore.vapStore.valueAddedProducts, {
      ProductTypeCode: parseInt(this.props.product.ProductTypeCode, 10),
      Default: true
    });

    this.props.setDefault(findUsedProduct.Id, false, this.props.dealershipId);
    this.props.appStore.vapStore.setDefaultFromModal(this.props.product.Id, true, this.props.dealershipId);
    this.handleModalClose();
  };

  render() {
    let { product, editRoute, onDelete, canEditVaps } = this.props;
    const canEditProduct =
      canEditVaps && !(disableGapProducts() && product.ProductTypeCode === VapProductTypes.GapInsurance);
    return (
      <tr className="valueAddedProductsTableRow__tr" data-th="ValueAddedProductsTableRow">
        <td className="valueAddedProductsTableRow__td" data-heading="Type">
          {product.ProductTypeText}
        </td>
        <td className="valueAddedProductsTableRow__td" data-heading="Name">
          <Truncator maxLength={51}>{product.Name}</Truncator>
        </td>
        <td className="valueAddedProductsTableRow__td" data-heading="Description">
          <Truncator maxLength={51}>{product.Description}</Truncator>
        </td>
        <td className="valueAddedProductsTableRow__td" data-heading="Price">
          <MoneyFormat value={product.Price} />
        </td>
        <td className="valueAddedProductsTableRow__td" data-heading="TaxType">
          {product.TaxTypeText}
        </td>
        <td className="valueAddedProductsTableRow__td valueAddedProductsTableRow__centeredItem" data-heading="Visible">
          <ToggleSwitch
            showValueOnly={!canEditVaps}
            isActive={product.Visible}
            handleUpdate={this.onToggleVisibility}
            disabled={!canEditProduct}
          />
        </td>
        <td className="valueAddedProductsTableRow__td valueAddedProductsTableRow__centeredItem" data-heading="Default">
          {!(product.ProductTypeCode === VapProductTypes.GapInsurance) && (
            <ToggleSwitch
              showValueOnly={!canEditVaps}
              disabled={!product.Visible}
              isActive={product.Default}
              handleUpdate={this.onToggleDefault}
            />
          )}
        </td>
        {canEditVaps && (
          <td className="valueAddedProductsTableRow__td" data-heading="Edit">
            {canEditProduct && (
              <Link to={editRoute} className="valueAddedProductsTableRow__icon valueAddedProductsTableRow__icon--edit">
                <Icon name="edit" />
              </Link>
            )}
          </td>
        )}
        {canEditVaps && (
          <td className="valueAddedProductsTableRow__td" data-heading="Delete">
            <div
              onClick={onDelete(product.Id)}
              className="valueAddedProductsTableRow__icon valueAddedProductsTableRow__icon--delete"
            >
              <Icon name="bin" />
            </div>
          </td>
        )}
        <Modal isOpen={this.state.isDefaultModalOpen} onClose={this.handleModalClose}>
          <div className="valueAddedProductForms__modal">
            <div className="valueAddedProductForms__modalText">
              {this.props.t('ValueAddedProductsTableRow.already_added_VAP_update_for_default', {
                productType: product.ProductTypeText
              })}
            </div>
            <div className="valueAddedProductForms__modalButtons">
              <div className="valueAddedProductForms__modalButtonAdd">
                <Button type="button" onClick={this.onModalSubmit}>
                  {this.props.t('ValueAddedProductsTableRow.update_vap')}
                </Button>
              </div>
              <div className="valueAddedProductForms__modalButtonCancel">
                <Button buttonStyle="cancel" onClick={this.handleModalClose}>
                  {this.props.t('ValueAddedProductsTableRow.cancel')}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </tr>
    );
  }
}

ValueAddedProductsTableRow.propTypes = {
  product: PropTypes.object,
  editRoute: PropTypes.string,
  onDelete: PropTypes.func,
  canEditVaps: PropTypes.bool,
  setVisibility: PropTypes.func,
  setDefault: PropTypes.func,
  appStore: PropTypes.object,
  dealershipId: PropTypes.string
};
export default withTranslation('ValueAddedProductSettings')(inject('appStore')(observer(ValueAddedProductsTableRow)));
