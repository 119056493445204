import { withTranslation } from 'react-i18next';
import { useState } from 'react';
import _ from 'lodash';
import SettingsProductTableItem from './SettingsProductTableItem';
import Th from '../../Common/Th';
import { inject } from 'mobx-react';
import observerForHooks from '../../../hocs/observerForHooks';
import './settingsProductTable.scss';

const SettingsProductTable = (props) => {
  let { products, appStore } = props;

  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  const getArrowDirection = (fieldName) => {
    return sortBy === fieldName ? sortDirection : '';
  };

  const handleSortBy = (fieldName) => {
    return () => {
      if (fieldName !== sortBy) {
        setSortDirection('asc');
      } else {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      }

      setSortBy(fieldName);
    };
  };

  const sortedProducts = _.orderBy(products, sortBy);
  if (sortDirection === 'desc') {
    sortedProducts.reverse();
  }

  return (
    <div className="settingsProductTable">
      <table className="settingsProductTable__table">
        <thead className="settingsProductTable__tableHeader">
          <tr className="settingsProductTable__headerRow">
            <Th onClick={handleSortBy('FinanceType')} arrowDirection={getArrowDirection('FinanceType')} notBold={true}>
              {props.t('ProductTables.product_type')}
            </Th>
            <Th onClick={handleSortBy('Name')} arrowDirection={getArrowDirection('Name')} notBold={true}>
              {props.t('ProductTables.product_name')}
            </Th>
            <Th onClick={handleSortBy('FunderCode')} arrowDirection={getArrowDirection('FunderCode')} notBold={true}>
              {props.t('ProductTables.lender')}
            </Th>
            <Th
              onClick={handleSortBy('Settings.CommissionType')}
              arrowDirection={getArrowDirection('Settings.CommissionType')}
              notBold={true}
            >
              {props.t('ProductTables.commission')}
            </Th>
            <Th
              onClick={handleSortBy('Settings.Visible')}
              arrowDirection={getArrowDirection('Settings.Visible')}
              notBold={true}
              isCentred={true}
            >
              {props.t('ProductTables.visible_on_platform')}
            </Th>

            {appStore.uiState.canEditProducts && (
              <Th key="settingsProductHasClaim" notBold={true} isCentred={true}>
                {props.t('ProductTables.select')}
              </Th>
            )}
          </tr>
        </thead>
        <tbody>
          {sortedProducts.map((product, index) => {
            return (
              <SettingsProductTableItem
                key={product.ProductUid}
                index={index}
                product={product}
                options={props.options}
                funders={props.funders}
                handleChangeVisibility={props.handleChangeVisibility}
                handleChangeSelected={props.handleChangeSelected}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default withTranslation('ProductSettings')(inject(['appStore'])(observerForHooks(SettingsProductTable)));
