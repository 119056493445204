import { types } from '../schema';

const dealershipSchema = {
  Address1: types.String,
  Address2: types.String,
  CompanyName: types.String,
  County: types.String,
  CountryCode: types.String,
  Email: types.String,
  Phone: types.String,
  PlatformQuotewareId: types.String,
  PostalTown: types.String,
  Postcode: types.String,
  TradingName: types.String,
  WebQuotewareId: types.String,
  QuotewareId: types.String,
  Website: types.String
};

export default dealershipSchema;
