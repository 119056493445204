import { observable, action, computed, toJS } from 'mobx';
import algoliasearch from 'algoliasearch';
import algoliasearchHelper from 'algoliasearch-helper';
import debounce from 'lodash/debounce';
import platformApiUtils from '@ivendi/platform-api-utils';
import AlgoliaVehicle from '../mobx-models/AlgoliaVehicle';
import { visRequest, fetchVisVehicle } from '../api/visVehicles';
import { getLocalStorageValue, setLocalStorageValue } from '../core/helpers';

const algoliaOptions = {
  facets: ['accuracyScore', 'onJuhu', 'dealerPlatformUpload'],
  disjunctiveFacets: ['accuracyScore', 'onJuhu', 'dealerPlatformUpload']
};

class StockStore {
  @observable isLoadingStock;
  @observable hasError;
  @observable hasVisError;
  @observable vehicles;
  @observable currentPage;
  @observable currentFixIssuesPage;
  @observable totalVehicles;
  @observable vehiclesPerPage;
  @observable searchQuery;
  @observable counts;
  @observable filters;
  @observable facets;
  @observable hasActiveFacets;
  @observable hitsPerPage;
  @observable visVehicles;
  @observable visSearchQuery;
  @observable selectedVisVehicle;
  @observable isLoadingVisVehicles;
  @observable currentVehicle;
  @observable searchSuggestionsHelper;
  @observable suggestions;
  @observable suggestionsQuery;
  @observable numberOfVisVehicles;
  @observable dataPendingInfo;
  @observable showDataPending;
  @observable isPendingDataLoading;
  @observable overrideTaxonomy;
  @observable stockListScrollPosition;

  constructor(uiState) {
    this.searchQuery = '';
    this.suggestionsQuery = '';
    this.vehicles = [];
    this.currentVehicle = {};
    this.counts = {
      accuracyScore: {},
      fafAgeRuleOk: {},
      fafMileageRuleOk: {},
      fafPriceRuleOk: {},
      onJuhu: {},
      dealerPlatformUpload: {}
    };
    this.selectedVisVehicle = {};
    this.visSearchQuery = '';
    this.facets = { accuracyScore: [], onJuhu: [], dealerPlatformUpload: [] };
    this.hasActiveFacets = false;
    this.hitsPerPage = 20;
    this.hasError = false;
    this.hasVisError = false;
    this.currentFixIssuesPage = 0;
    this.suggestions = [];
    this.numberOfVisVehicles = 0;
    this.dataPendingInfo = {};
    this.showDataPending = false;
    this.isPendingDataLoading = false;
    this.overrideTaxonomy = false;
    this.uiState = uiState;
    this.scrollPosition = 0;
  }
  @action
  auth = async (dealershipId, vehicleVrm) => {
    this.dealershipId = dealershipId;
    this.apiKeys = await platformApiUtils.get(`v1/stock/search?dealershipid=${this.dealershipId}`);
    this.stockKey = this.apiKeys.Stock;
    this.suggestionsKey = this.apiKeys.QS;
    this.algolia = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, this.stockKey);
    this.suggestionsAlgolia = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, this.suggestionsKey);
    this.algoliaIndex = this.algolia.initIndex(process.env.REACT_APP_ALGOLIA_INDEX);

    //remove commenting when index supports query suggestions
    //this.suggestionsIndex = this.suggestionsAlgolia.initIndex('UK_Dev_iVendi_Stock_QS');

    !vehicleVrm ? this.fetchStock() : this.fetchStock(vehicleVrm);
  };

  @action
  getVehicleIds = () =>
    new Promise((resolve) => {
      let helper = algoliasearchHelper(this.algolia, this.algoliaIndex.indexName, {
        ...algoliaOptions,
        hitsPerPage: 1000
      });

      helper.setQuery(this.searchQuery);
      helper.setPage(0).search();

      helper.on('result', (data) => {
        resolve(data.results.hits.map((hit) => hit.id));
      });
    });

  @action
  fetchStock = (vehicleVrm) => {
    this.isLoadingStock = true;
    if (!this.algoliaIndex) {
      return;
    }

    //remove commenting when index supports query suggestions
    // if (this.suggestionsQuery.length > 0) {
    //     this.suggestionsIndex.search({ query: this.suggestionsQuery }, (err, data) => {
    //         this.getSuggestions(data);
    //     });
    // }

    let helper = algoliasearchHelper(this.algolia, this.algoliaIndex.indexName, {
      ...algoliaOptions,
      hitsPerPage: this.hitsPerPage
    });

    this.facets['accuracyScore'].forEach((value) => {
      helper.addDisjunctiveFacetRefinement('accuracyScore', +value);
    });

    this.facets['onJuhu'].forEach((value) => {
      helper.addDisjunctiveFacetRefinement('onJuhu', value);
    });

    this.facets['dealerPlatformUpload'].forEach((value) => {
      helper.addDisjunctiveFacetRefinement('dealerPlatformUpload', value);
    });

    // must call search on setPage if called before  setQuery the helper will reset page to default value of 0

    if (vehicleVrm) {
      helper.setQuery(vehicleVrm);
      helper.setPage(0).search();
    } else {
      helper.setQuery(this.searchQuery);
      helper.setPage(this.currentPage).search();
    }

    if (!vehicleVrm) {
      helper.on('result', ({ results }) => {
        this.onFetchStockSuccess(null, results);
        return results;
      });
    }

    helper.on('error', ({ error }) => {
      this.onFetchStockError(error);
    });

    if (vehicleVrm) {
      helper.on('result', ({ results }) => {
        this.updateCurrentVehicle(results.hits[0]);
      });
    }
  };

  @action
  onFetchStockSuccess = (error, results) => {
    if (error) {
      return;
    }
    let vehicles = [];

    const deletedVehiclesIds = JSON.parse(sessionStorage.getItem('deletedVehiclesIds')) || [];
    const filteredHits = results.hits.filter(({ id }) => {
      return deletedVehiclesIds.every((deletedVehiclesId) => deletedVehiclesId !== id);
    });

    filteredHits.forEach((vehicle) => {
      // remove locally stored vehicle id for optimistic ui update

      if (vehicle && vehicle.taxonomies && vehicle.taxonomies.length > 1) {
        const stockObj = vehicle.taxonomies.find((item) => item.origin === 'stock') || null;
        const localVehicles = getLocalStorageValue('updatedVehicleIds', () => []);
        const hasVehiclesToFilter = Array.isArray(localVehicles) && localVehicles.length;

        if (hasVehiclesToFilter) {
          const filterVehicles = localVehicles.filter((localVehicle) => {
            if (localVehicle?.secondaryId !== stockObj?.secondaryId) {
              return localVehicle;
            } else return [];
          });
          setLocalStorageValue('updatedVehicleIds', filterVehicles);
        } else {
          setLocalStorageValue('updatedVehicleIds', []);
        }
      }
      let newVehicle = new AlgoliaVehicle();
      newVehicle.updateFromJSON(vehicle);
      vehicles.push(newVehicle);
    });
    this.vehicles = vehicles;
    this.currentPage = results.page;
    this.totalVehicles = results.nbHits > 1000 ? 1000 : results.nbHits;
    this.vehiclesPerPage = results.hitsPerPage;
    Object.keys(this.counts).forEach((facet) => {
      results.disjunctiveFacets.forEach((disjunctiveFacet) => {
        if (disjunctiveFacet.name === facet) {
          this.counts[facet] = disjunctiveFacet.data;
        }
      });
    });
    this.isLoadingStock = false;
  };

  @action
  onFetchStockError = (error) => {
    window.R7Insight.log({
      type: 'AlgoliaFetchVehiclesError',
      error: `statusCode=${error.statusCode} message=${error.message} dealershipID=${this.dealershipId} searchQuery=${this.searchQuery} facetsSelected=${this.facets}`,
      level: 'Error'
    });

    this.hasError = true;
    this.isLoadingStock = false;
  };

  @action
  handlePageChange = (pageNum) => {
    this.currentPage = pageNum - 1;
    this.fetchStock();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  @action
  handleFixIssuesPageChange = (pageNum) => {
    this.currentFixIssuesPage = pageNum - 1;
    this.updateVisVehicles();
  };

  @action
  updateSearchQuery = (id, value) => {
    this.searchQuery = value;
    this.suggestionsQuery = value;
    this.currentPage = 0;
    this.updateVehicles();
  };

  @action
  resetFacets = () => {
    this.facets = { accuracyScore: [], onJuhu: [], dealerPlatformUpload: [] };
    this.hasActiveFacets = false;
    this.counts = {
      accuracyScore: {},
      fafAgeRuleOk: {},
      fafMileageRuleOk: {},
      fafPriceRuleOk: {},
      onJuhu: {},
      dealerPlatformUpload: {}
    };
    this.searchQuery = '';
    this.currentPage = 0;
  };

  @action
  resetVehicles = () => {
    this.vehicles = [];
  };
  @action
  setHitsPerPage = (num) => {
    this.hitsPerPage = num;
  };

  updateVehicles = debounce(() => {
    this.fetchStock();
  }, 500);

  @action
  toggleFilter = (facetName, value) => {
    this.facets[facetName].indexOf(value) !== -1
      ? this.facets[facetName].splice(this.facets[facetName].indexOf(value), 1)
      : this.facets[facetName].push(value);

    const hasActive = Object.keys(toJS(this.facets)).some((facetName) => this.facets[facetName]?.length > 0);
    this.hasActiveFacets = hasActive;
    this.currentPage = 0;
    this.fetchStock();
  };

  @action
  getVisVehiclesWithQuery = (query) => {
    return new Promise((resolve, reject) => {
      visRequest({
        query,
        currentPage: this.currentFixIssuesPage,
        pageSize: 20
      })
        .then((data) => {
          this.updateVisSearchQuery(data, query);
          resolve(data);
        })
        .catch(reject);
    });
  };

  @action
  updateVisSearchQuery = (data, searchQuery) => {
    if (data.response.length > 0) {
      this.visSearchQuery = searchQuery;
    }
  };

  @action
  updateResults = (data) => {
    this.visVehicles = data.response;
    this.numberOfVisVehicles = data.numberOfRecords;
    this.currentFixIssuesPage = data.currentPage;
    this.isLoadingVisVehicles = false;
  };

  @action
  getVisVehicles = (query) => {
    this.isLoadingVisVehicles = true;
    this.getVisVehiclesWithQuery(
      `${query.make} ${query.model} ${query.derivative} ${query.fuelType} ${query.transmission} ${
        query.vrmYear !== undefined ? query.vrmYear : ''
      }`.replace(/[^0-9a-zA-Z .-]/g, '')
    )
      .then((data) => {
        if (data.response.length === 0) {
          this.getVisVehiclesWithQuery(
            `${query.make} ${query.model} ${query.fuelType} ${query.transmission} ${
              query.vrmYear !== undefined ? query.vrmYear : ''
            }`.replace(/[^0-9a-zA-Z .-]/g, '')
          ).then((data) => {
            if (data.response.length === 0) {
              this.getVisVehiclesWithQuery(`${query.make} ${query.model}`.replace(/[^0-9a-zA-Z .-]/g, '')).then(
                this.updateResults
              );
            } else {
              this.updateResults(data);
            }
          });
        } else {
          this.updateResults(data);
        }
      })
      .catch((error) => {
        this.handleVisError();
        return error;
      });
  };

  @action
  fetchVisVehicle = (primaryId, secondaryId) => {
    this.isPendingDataLoading = true;
    return new Promise((resolve, reject) => {
      fetchVisVehicle(primaryId, secondaryId)
        .then((data) => {
          this.handleDataPendingLoading();
          resolve(data);
        })
        .catch((error) => {
          this.handleDataPendingLoading();
          reject(error);
        });
    });
  };

  @action
  handleDataPendingLoading = () => {
    this.isPendingDataLoading = false;
  };

  @action
  handleVisError = () => {
    this.hasVisError = true;
  };

  @action
  changeVisVehicleStateToLoading = () => {
    this.isLoadingVisVehicles = true;
  };

  @action
  changeVisVehicleStateToNotLoading = () => {
    this.isLoadingVisVehicles = false;
  };

  @action
  handleVisSearchQueryChange = (id, value) => {
    this.currentFixIssuesPage = 0;
    this.visSearchQuery = value;
    this.updateVisVehiclesWithDebounce();
    this.currentPage = 0;
  };

  updateVisVehicles = () => {
    if (this.visSearchQuery !== '') {
      this.changeVisVehicleStateToLoading();
      visRequest({
        query: this.visSearchQuery,
        currentPage: this.currentFixIssuesPage,
        pageSize: 20
      })
        .then(
          action((data) => {
            this.visVehicles = data.response;
            this.currentFixIssuesPage = data.currentPage;
            this.numberOfVisVehicles = data.numberOfRecords;
            this.changeVisVehicleStateToNotLoading();
          })
        )
        .catch((error) => {
          return error;
        });
    } else {
      this.resetVisVehicles();
    }
  };

  updateVisVehiclesWithDebounce = debounce(() => {
    this.updateVisVehicles();
  }, 1000);

  @action
  resetVisVehicles = () => {
    this.visVehicles = [];
  };

  @action
  resetSelectedVisVehicleAndQuery = () => {
    this.selectedVisVehicle = {};
    this.visSearchQuery = '';
  };

  @action
  updateCurrentVehicle = (vehicle) => {
    let newVehicle = new AlgoliaVehicle();
    newVehicle.updateFromJSON(vehicle);
    this.currentVehicle = newVehicle;
  };

  @action
  selectVisVehicle = (selectedVisVehicle) => {
    this.selectedVisVehicle = selectedVisVehicle;
  };

  @action
  removeSelectVisVehicle = () => {
    this.selectedVisVehicle = {};
  };

  @action
  getSuggestions = (data) => {
    //remove commenting when index supports query suggestions
    // this.suggestions = data.hits.map((hit) => {
    //     return hit.query;
    // });
  };
  @action removeCurrentVehicle = () => {
    this.currentVehicle = {};
  };

  @action
  handleSuggestionSelection = (selectedItem) => {
    //remove commenting when index supports query suggestions
    // this.searchQuery = selectedItem;
    // this.resetSuggestionsQuery();
    // this.fetchStock();
  };
  @action resetSuggestionsQuery = () => {
    this.suggestionsQuery = '';
    this.suggestions = [];
  };

  @action
  handleNewDataPending = (vehicle) => {
    this.dataPendingInfo = vehicle;
    this.showDataPending = true;
  };

  @action
  handleDataPendingClose = () => {
    this.dataPendingInfo = {};
    this.showDataPending = false;
  };

  @action
  setOverrideTaxonomy = (value) => {
    this.overrideTaxonomy = value;
  };

  @action
  setStockListScrollPosition = (value) => {
    this.stockListScrollPosition = value;
  };

  @computed
  get vehicleProvider() {
    return this.uiState.countryCode === 'de' ? 'schwacke' : 'cap';
  }
}

export default StockStore;
