import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AssignLeadForm from '../components/AssignLeadForm';
import AssignLeadFormNonEditable from '../components/AssignLeadFormNonEditable';
import Modal from '../../Common/Modal/Modal';
import { observer, inject } from 'mobx-react';
import { updateDashboardAssignedTo } from '../../../redux/dashboard/dashboardActions';
import { updateCustomerAssignedTo } from '../../../redux/consumer/consumerActions';

class AssignLeadModalContainer extends React.Component {
  UNSAFE_componentWillUpdate(nextProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      this.props.appStore.assignLeadStore.startAssigningLeads(this.props.initialAssignees);
    }
  }

  closeModal = (e) => {
    e && e.preventDefault();
    this.props.closeModal(e);
    this.props.appStore.assignLeadStore.resetResults();
  };

  handleSubmit = () => {
    const {
      appStore: { assignLeadStore }
    } = this.props;

    const { leadIsNowAssigned, leadIsNowUnassigned } = assignLeadStore;

    assignLeadStore.submitAssignedLeads(this.props.customerId).then((newAssignees) => {
      window.setTimeout(this.closeModal, 500);

      this.props.onSubmit({ newAssignees, leadIsNowAssigned, leadIsNowUnassigned });
    });
  };

  handleSearch = (query) => {
    const {
      appStore: { assignLeadStore }
    } = this.props;
    assignLeadStore.searchUsers(this.props.dealershipId, query);
  };

  render() {
    const {
      appStore: { assignLeadStore }
    } = this.props;
    return (
      <div>
        <Modal isOpen={this.props.isOpen} onClose={this.closeModal}>
          <div>
            {this.props.appStore.uiState.canAssign ? (
              <AssignLeadForm
                currentLeadAssignees={assignLeadStore.currentLeadAssignees}
                handleSearch={this.handleSearch}
                searchResults={assignLeadStore.searchResults}
                onSelectResult={assignLeadStore.selectResult}
                searchQuery={assignLeadStore.searchQuery}
                selectedSearchResult={assignLeadStore.selectedSearchResult}
                addUserToCurrentAssignees={assignLeadStore.addUserToCurrentAssignees}
                removeUserFromCurrentAssignees={assignLeadStore.removeUserFromCurrentAssignees}
                customerName={this.props.customerName}
                onCancel={this.closeModal}
                submitEnabled={assignLeadStore.submitEnabled}
                activeSearchResultIndex={assignLeadStore.activeSearchResultIndex}
                makeNextSearchResultActive={assignLeadStore.makeNextSearchResultActive}
                makePrevSearchResultActive={assignLeadStore.makePrevSearchResultActive}
                selectCurrentActiveResult={assignLeadStore.selectCurrentActiveResult}
                customerType={this.props.customerType}
                onSubmit={this.handleSubmit}
                isLoading={assignLeadStore.submitAssignedLeadsRequest.isLoading}
                hasError={assignLeadStore.submitAssignedLeadsRequest.hasError}
              />
            ) : (
              <AssignLeadFormNonEditable
                currentLeadAssignees={assignLeadStore.currentLeadAssignees}
                customerName={this.props.customerName}
                onCancel={this.closeModal}
              />
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

//todo remove when dashboard converted to MobX
function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSubmit: ({ newAssignees, leadIsNowAssigned, leadIsNowUnassigned }) => {
      dispatch(updateDashboardAssignedTo(ownProps.customerId, newAssignees, leadIsNowAssigned, leadIsNowUnassigned));
      dispatch(updateCustomerAssignedTo(ownProps.customerId, newAssignees));
    }
  };
}

AssignLeadModalContainer.propTypes = {
  customerName: PropTypes.string,
  customerId: PropTypes.string,
  closeModal: PropTypes.func,
  currentLeadAssignees: PropTypes.array,
  onSubmitModal: PropTypes.func,
  handleSearch: PropTypes.func,
  searchResults: PropTypes.array,
  onSelectResult: PropTypes.func,
  searchQuery: PropTypes.string,
  selectedSearchResult: PropTypes.object,
  addUserToAssignedList: PropTypes.func,
  removeUserFromCurrentAssignees: PropTypes.func,
  submittingState: PropTypes.string,
  changesToPost: PropTypes.array,
  makeNextSearchResultActive: PropTypes.func,
  makePrevSearchResultActive: PropTypes.func,
  selectCurrentActiveResult: PropTypes.func,
  activeSearchResultIndex: PropTypes.number,
  customerType: PropTypes.string,
  isOpen: PropTypes.bool,
  appStore: PropTypes.object,
  initialAssignees: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  dealershipId: PropTypes.string,
  onSubmit: PropTypes.func
};

export default inject(['appStore'])(connect(null, mapDispatchToProps)(observer(AssignLeadModalContainer)));
