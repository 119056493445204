import React from 'react';
import './loadingSpinnerTailSpin.scss';
import spinner from '../../../styles/icons/tail-spin.svg';
import fallback from '../../../styles/icons/tail-spin.gif';

const LoadingSpinnerTailSpin = () => (
  <object className="loadinSpinnerTailSpin" data={spinner} type="image/svg+xml">
    <img src={fallback} width="150" height="150" alt="" />
  </object>
);

export default LoadingSpinnerTailSpin;
