import React from 'react';
import PropTypes from 'prop-types';
import './tilesLayout.scss';

const TilesLayout = ({ children }) => (
  <div className="tileLayout__layout" key="layout">
    {React.Children.map(children, (child, i) => (
      <div key={i} className="tileLayout__child">
        {React.cloneElement(child)}
      </div>
    ))}
  </div>
);

TilesLayout.propTypes = {
  children: PropTypes.node
};

export default TilesLayout;
