import moment from 'moment';
import { objectToSearchParams } from 'utils/objectToSearchParams';

export function getTileProps(tile, dealershipId, isReserveSettingsSet, isDealershipAdmin) {
  let type = tile.Type;

  const pathPrefix = `/d/${dealershipId}/`;

  const paths = {
    consumers: pathPrefix + 'consumers',
    latestdeals: pathPrefix + 'latestdeals',
    sentDeals: pathPrefix + 'orders-and-deals',
    reserveNow: pathPrefix + 'reservenow',
    reserveSettings: pathPrefix + 'settings/reserve'
  };

  const links = {
    myassigned: {
      link: {
        pathname: paths.consumers,
        search: objectToSearchParams({
          filter: 'myAssigned'
        }).toString()
      }
    },
    unassigned: {
      link: {
        pathname: paths.consumers,
        search: objectToSearchParams({
          filter: 'unassigned',
          from: moment()
            .subtract(30, 'days')
            .valueOf(),
          timePeriod: 'lastmonth'
        }).toString()
      }
    },
    assigned: {
      link: {
        pathname: paths.consumers,
        search: objectToSearchParams({
          filter: 'assigned'
        }).toString()
      }
    },
    customers: {
      link: paths.consumers
    },
    applications: {
      link: paths.latestdeals
    },
    rejected: {
      link: {
        pathname: paths.latestdeals,
        search: objectToSearchParams({
          status: 'rejected'
        }).toString()
      }
    },
    accepted: {
      link: {
        pathname: paths.latestdeals,
        search: objectToSearchParams({
          status: 'accepted'
        }).toString()
      }
    },
    reservations: {
      link: {
        pathname: !isReserveSettingsSet && isDealershipAdmin ? paths.reserveSettings : paths.reserveNow,
        search: objectToSearchParams({
          page: 1,
          pageSize: 5,
          reservationStatus: 'active'
        }).toString()
      }
    },
    overdue: {
      link: {
        pathname: paths.reserveNow,
        search: objectToSearchParams({
          page: 1,
          pageSize: 5,
          reservationStatus: 'overdue'
        }).toString()
      }
    },
    deals: {
      link: {
        pathname: paths.sentDeals,
        search: objectToSearchParams({
          activeTab: 'DigitalDeals',
          page: 1,
          pageSize: 5,
          dealStatus: 'all',
          sortBy: 'sentAt'
        }).toString()
      }
    }
  };
  const link = type ? links[type].link : {};
  return { ...tile, link: link };
}
