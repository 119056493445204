import PropTypes from 'prop-types';
import CommissionValue from '../../Common/CommissionValue';

import './schemes.scss';

const Schemes = (props) => (
  <div>
    {props.showSchemes === true ? (
      <div>
        {props.label && <div className="schemes__label">{props.label}: </div>}
        <CommissionValue commissions={props.commissions} />
      </div>
    ) : (
      ''
    )}
  </div>
);

Schemes.propTypes = {
  showSchemes: PropTypes.bool,
  commissions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  label: PropTypes.string
};

export default Schemes;
