import Request from '../mobx-models/Request';
import * as api from '../api/customer';
import { observable, action } from 'mobx';
import CustomerList from '../mobx-models/CustomerList';

class CustomerListStore {
  @observable customerListItems = [];

  customers = [];
  total = 0;
  addCustomerRequest;
  updateCustomerRequest;
  fetchCustomerListRequest;

  constructor(store) {
    this.store = store;
    this.addCustomerRequest = new Request();
    this.updateCustomerRequest = new Request();
    this.fetchCustomerListRequest = new Request();
  }

  updateCustomerFromJSON = (json) => {
    const customer = this.customers.find((customer) => customer.id === json.Id);

    if (!customer) {
      const newCustomer = new CustomerList(this.store.userStore).updateFromJSON(json);

      this.customers.push(newCustomer);
      return newCustomer;
    } else {
      customer.updateFromJSON(json);
      return customer;
    }
  };

  updateCustomerAssignedLeads = (customerId, assignedLeads) => {
    const customer = this.customers.find((customer) => customer.id === customerId);

    if (customer) {
      customer.updateAssignedLeads(assignedLeads);
    }
  };

  @action
  reset = () => {
    this.customerListItems = [];
    this.customers = [];
    this.total = 0;
  };

  @action
  fetchCustomerList = (dealershipId, searchParams, currentUserId) => {
    this.customerListItems = [];
    this.fetchCustomerListRequest
      .onSuccess(this.handleFetchCustomerListSuccess)
      .call(api.fetchCustomerList, dealershipId, searchParams, currentUserId);
  };

  @action
  handleFetchCustomerListSuccess = (response) => {
    if (response && response.Results) {
      this.customerListItems = response.Results.map((customer) => this.updateCustomerFromJSON(customer));
      this.total = response.Total;
    } else {
      this.customers = [];
    }
  };

  addCustomer = (customer, dealershipId, assignToSelf) => {
    return this.addCustomerRequest.call(api.addCustomer, customer, dealershipId, assignToSelf);
  };

  updateCustomer = (customer, customerId) => {
    return this.updateCustomerRequest.call(api.updateCustomer, customer, customerId);
  };
}

export default CustomerListStore;
