import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './monthlyPriceViewerPaymentsRow.scss';
import MoneyFormat from '../../../Common/MoneyFormat';

const MonthlyPriceViewerPaymentsRow = ({ onTermClick, term, monthlyPayments, isLast }) => {
  const handleTermClick = () => onTermClick(term);

  const firstTdClassNames = classnames(
    'monthlyPriceViewerPaymentsRow__termCell',
    isLast && 'monthlyPriceViewerPaymentsRow__lastItem monthlyPriceViewerPaymentsRow__lastTerm'
  );

  const remainingTdClassNames = classnames(
    'monthlyPriceViewerPaymentsRow__paymentCell',
    isLast && 'monthlyPriceViewerPaymentsRow__lastItem'
  );

  return (
    <tr onClick={handleTermClick} className="monthlyPriceViewerPaymentsRow">
      <td className={firstTdClassNames}>{term}</td>

      {monthlyPayments.map((product, productIndex) => (
        <td key={productIndex} className={remainingTdClassNames}>
          {typeof product.Payments[term] !== 'undefined' ? (
            <span className="monthlyPriceViewerPaymentsRow__payment">
              <MoneyFormat value={product.Payments[term]} />
            </span>
          ) : (
            '-'
          )}
        </td>
      ))}
    </tr>
  );
};

MonthlyPriceViewerPaymentsRow.propTypes = {
  term: PropTypes.number,
  monthlyPayments: PropTypes.array,
  isLast: PropTypes.bool,
  onTermClick: PropTypes.func
};

export default MonthlyPriceViewerPaymentsRow;
