import { LenderType, OptionType } from '../../types';

export const getAvailableLenders = (availableLenders: string[], allLenders: LenderType[]): OptionType[] => {
  const availableLendersFunderCodes = new Set(availableLenders.map((lender) => lender.split('-')[1]));
  const allAvailableLenders = allLenders
    .filter((lender) => availableLendersFunderCodes.has(lender.key.toLowerCase()))
    .map((lender) => ({
      id: lender.key.toLowerCase(),
      value: lender.key.toLowerCase(),
      label: lender.value
    }));
  return allAvailableLenders;
};
