import PropTypes from 'prop-types';
import Pagination from '../../Common/Pagination';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import LenderUpdatesPanelItem from '../../Dashboard/LenderUpdatesPanel/LenderUpdatesPanelItem';
import NoPanelData from '../../Dashboard/components/NoPanelData';
import LenderUpdatesFilter from '~/components/Dashboard/LenderUpdatesPanel/LenderUpdatesFilter';
import { useTranslation } from 'react-i18next';
import './lenderUpdates.scss';

const LenderUpdates = ({
  items,
  filter,
  facets,
  options,
  onFilter,
  dealershipId,
  currentPage,
  totalItems,
  pageSize,
  changePage
}) => {
  const { t } = useTranslation('Dashboard');
  const filterBy = (value) => {
    onFilter(value);
  };

  const getOptionsValue = (name) => {
    const obj = options.LenderStatus.find((status) => status.key.toLowerCase() === name.toLowerCase());
    return obj ? obj.value : name;
  };

  const getLenderStatuses = () => {
    if (Array.isArray(facets)) {
      const statusFacet = facets.find((facet) => facet.Name === 'Status');
      const items = statusFacet ? statusFacet.Items : [];

      return items.map((obj) => ({
        key: obj.Name,
        value: getOptionsValue(obj.Name)
      }));
    } else {
      return [];
    }
  };

  let lenderStatuses = getLenderStatuses().sort((a, b) => a.value.localeCompare(b.value));

  const statusOptions = [].concat([{ key: '', value: 'All' }], lenderStatuses);

  return (
    <div className="lenderUpdates">
      <Panel>
        <PanelHeader>Lender Updates</PanelHeader>
        <div className="lenderUpdates__sortContainer">
          <div className="lenderUpdates__sort">
            <div className="lenderUpdates__selectWrapper">
              <LenderUpdatesFilter
                filterLenderUpdates={filterBy}
                value={filter}
                options={statusOptions}
                text={t('Landing.status')}
              />
            </div>
          </div>
        </div>
        <div className="lenderUpdates__updateContainer">
          {items && items.length > 0 ? (
            items.map((item, index) => (
              <LenderUpdatesPanelItem
                dealershipId={dealershipId}
                item={item}
                key={'lenderUpdates' + index}
                index={index + 1}
                length={items.length}
              />
            ))
          ) : (
            <NoPanelData text="You have no current lender updates." />
          )}
          <div className="lenderUpdates__paginationContainer">
            <Pagination currentPage={currentPage} totalItems={totalItems} pageSize={pageSize} onChange={changePage} />
          </div>
        </div>
      </Panel>
    </div>
  );
};

LenderUpdates.propTypes = {
  items: PropTypes.array,
  facets: PropTypes.array,
  onFilter: PropTypes.func,
  filter: PropTypes.string,
  showFooter: PropTypes.bool,
  handleClick: PropTypes.func,
  viewMoreLink: PropTypes.string,
  changePage: PropTypes.func.isRequired,
  totalItems: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalPages: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

LenderUpdates.defaultProps = {
  options: []
};

export default LenderUpdates;
