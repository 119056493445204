import React from 'react';
import PropTypes from 'prop-types';
import './warningTooltip.scss';
import ReactDOM from 'react-dom';

const portalRoot = document.getElementById('root');

class WarningTooltip extends React.Component {
  componentDidMount() {
    this.repositionTooltip();
    window.addEventListener('resize', this.repositionTooltip);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.repositionTooltip);
  }

  repositionTooltip = () => {
    let el = document.getElementById(this.props.refNum);

    if (this.refs.messageContainer && this.refs.message && this.refs.message.offsetWidth && el && this.refs.triangle) {
      let offset = el.getBoundingClientRect();
      let width = el.clientWidth;
      let height = el.clientHeight;
      let x = offset.left + width / 2;
      let y = offset.top + height + window.scrollY;

      this.refs.triangle.style.left = x + 'px';
      this.refs.triangle.style.top = y + 'px';

      let messageWidth = this.refs.message.offsetWidth;
      let halfWidth = messageWidth / 2;

      let left;
      let gap = 20;
      let twoGap = gap * 2;

      if (messageWidth + twoGap > window.innerWidth) {
        left = gap;
        messageWidth = Math.max(window.innerWidth - twoGap, 300);
      } else if (x - halfWidth - gap < 0) {
        left = gap;
      } else if (x + halfWidth + gap > window.innerWidth) {
        left = window.innerWidth - gap - messageWidth;
      } else {
        left = x - halfWidth;
      }

      this.refs.messageContainer.style.top = y + 14 + 'px';
      this.refs.messageContainer.style.left = left + 'px';
      this.refs.messageContainer.style.width = messageWidth + 'px';
    }
  };

  render() {
    return ReactDOM.createPortal(
      <div className="warningTooltip" ref="tooltip">
        <div className="warningTooltip__triangle" ref="triangle" />
        <div className="warningTooltip__messageContainer" ref="messageContainer">
          <div className="warningTooltip__message" ref="message">
            <div className="warningTooltip__icon" />
            <div className="warningTooltip__text">{this.props.children}</div>
          </div>
        </div>
      </div>,
      portalRoot
    );
  }
}

WarningTooltip.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool
};

export default WarningTooltip;
