import { observable, action } from 'mobx';
import * as platformApiUtils from '@ivendi/platform-api-utils';
import moment from 'moment';
import _ from 'lodash';

class LeagueTableStore {
  @observable allLeagueTables = null;
  @observable leagueTable = [];
  @observable isLoadingLeagueTables = false;
  @observable options = [];
  @observable filterMonth = '';
  @observable sortDirection = 'asc';
  @observable sortField = 'Rank';
  @observable leagueTableError = false;

  @action
  initLeagueTables = (dealershipId, date, reportType, path, reportValue) => {
    this.leagueTableError = false;
    this.isLoadingLeagueTables = true;
    platformApiUtils
      .get('v1/report/rooftop/monthly/' + dealershipId + '/')
      .then(
        action((response) => {
          this.allLeagueTables = response;

          const formattedReportMonths = this.allLeagueTables[reportType].map((month) => {
            return moment(month).format('YYYY-MM-DD');
          });
          const isUsedDate = _.includes(formattedReportMonths, date);

          if (date && isUsedDate) {
            this.filterMonth =
              this.allLeagueTables[reportType].length === 1
                ? moment(this.allLeagueTables[reportType][0]).format('YYYY-MM-DD')
                : moment(date).format('YYYY-MM-DD');
          } else {
            this.filterMonth =
              this.allLeagueTables[reportType].length === 1
                ? moment(this.allLeagueTables[reportType][0]).format('YYYY-MM-DD')
                : moment(this.allLeagueTables[reportType][1]).format('YYYY-MM-DD');
          }

          this.options = this.allLeagueTables[reportType].map((month) => {
            return { key: moment(month).format('YYYY-MM-DD'), value: moment(month).format('MMMM YYYY') };
          });
        })
      )
      .then(() => platformApiUtils.get('v1/report/rooftop/' + path + dealershipId + '/' + this.filterMonth + '/'))
      .then(
        action((response) => {
          if (response && response[reportValue]) {
            this.leagueTable = response[reportValue];
          }
          this.isLoadingLeagueTables = false;
        })
      )
      .catch(
        action(() => {
          this.isLoadingLeagueTables = false;
          this.leagueTableError = true;
        })
      );
  };

  @action
  fetchLeagueTable = (dealershipId, date, path, reportValue) => {
    this.isLoadingLeagueTables = true;
    platformApiUtils
      .get('v1/report/rooftop/' + path + dealershipId + '/' + date + '/')
      .then(
        action((response) => {
          this.isLoadingLeagueTables = false;
          if (response && response[reportValue]) {
            this.leagueTable = response[reportValue];
          }
        })
      )
      .catch(
        action(() => {
          this.isLoadingLeagueTables = false;
        })
      );
  };

  @action
  selectMonth = (dealershipId, month, path, reportValue) => {
    this.filterMonth = moment(month).format('YYYY-MM-DD');
    this.fetchLeagueTable(dealershipId, month, path, reportValue);
  };

  @action
  onSortLeagueTable = (sortField) => {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      this.sortField = sortField;
    } else {
      this.sortDirection = 'asc';
      this.sortField = sortField;
    }

    this.leagueTable = _.orderBy(this.leagueTable, sortField, this.sortDirection);
  };
}

export default LeagueTableStore;
