import s from './sidebar.module.scss';
import Icon from '~/components/Common/Icon/Icon';
import { NavLink, useParams } from 'react-router-dom';
import { inject } from 'mobx-react';
import classNames from 'classnames';
import type UiState from '~/mobx-stores/UiState'; 
import { useTranslation } from 'react-i18next';

type SidebarLinkProps = {
  to: string; 
  label: string;
  icon: string;
  dataTh: string;
};

export const SidebarLink = (props: SidebarLinkProps) => { 

return (
  <NavLink to={props.to}  className={({ isActive }) =>
    classNames(s.sidebarLink, { [s.active]: isActive })
  } data-th={props.dataTh}>
    <div className={s['iconWrapper']}>
      <Icon name={props.icon} />
    </div>
    <span>{props.label}</span>
    
  </NavLink>
)};


type Props = {
  appStore?: {
    uiState: UiState;
  };
};

const Sidebar = (props: Props) => { 
const { canSeeFinanceInsights, canSeeStockInsights } = props.appStore!.uiState; 
const params = useParams<{ dealershipId: string }>();
const baseUrl = `/d/${params.dealershipId}/insights`

const { t } = useTranslation('Insights')



  return (
    <div className={s['container']}>
    
      {canSeeFinanceInsights && (
        <div className={s['financeReports']}>
          <h1 className={s['title']}>{t('Sidebar.finance_reports')}</h1>  
          <SidebarLink  to={`${baseUrl}/application-volumes`} 
          label={t('Sidebar.application_volumes')} icon="insights-report" dataTh="applicationVolumesLink" />

          <SidebarLink
            to={`${baseUrl}/application-loan-values`}
            label={t('Sidebar.application_loan_values')} 
            icon="insights-report"
            dataTh="applicationLoanValuesLink"
          />
        </div>
      )}

      {canSeeStockInsights && (
        <>
          <h1 className={s['title']}>{t('Sidebar.stock_reports')} </h1>
          <SidebarLink
             to={`${baseUrl}/average-vehicles-stock`}
             label={t('Sidebar.average_vehicles_in_stock')}
            icon="insights-car"
            dataTh="averageVehiclesInStockLink"
          /> 
          <SidebarLink to={`${baseUrl}/vehicle-sales-data`} 
          label={t('Sidebar.vehicle_sales_data')} icon="insights-star" dataTh="vehicleSalesDataLink" />
        </>
      )}
    </div>
  );
};

export default inject('appStore')(Sidebar);