import { withTranslation } from 'react-i18next';
import clone from 'lodash/clone';
import set from 'lodash/set';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import Validator from '../../../validators/Validator';
import DateInput from '../../Common/Form/DateInput';
import FormFooter from '../../Common/Form/FormFooter';
import SelectInput from '../../Common/Form/SelectInput';
import TextInput from '../../Common/Form/TextInput';
import Modal from '../../Common/Modal/Modal';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import ButtonInfoIcon from '../../Common/Button/ButtonInfoIcon';
import Input2 from '../../Common/Form/Input2';
import _ from 'lodash';
import { PhoneNumberInput } from '~/components/Common/Form/PhoneNumberInput';

class BusinessDetailsForm extends React.Component {
  state = {
    formData: {
      CompanyRegistrationNumber: '',
      CompanySector: '',
      CompanyType: '',
      DateEstablished: '',
      EmailAddress: '',
      FaxNumber: '',
      Landline: '',
      Mobile: '',
      NumberOfDirectorsOrPartners: 1,
      NumberOfEmployees: 1,
      RegisteredAddress: false,
      RegisteredName: '',
      TradingName: '',
      VatNumber: ''
    }
  };

  UNSAFE_componentWillMount() {
    this.setupValidation(this.props.validationRules);
    this.setInitialValues(this.props.defaultValues);
  }

  setInitialValues = (data) => {
    this.setState(() => {
      this.validator.validate(data);
      return {
        formData: data
      };
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.validationRules !== this.props.validationRules || prevProps.isVatAssist !== this.props.isVatAssist) {
      this.setupValidation(this.props.validationRules);
    }
  }

  setupValidation(rules) {
    let validationRules = _.cloneDeep(rules);

    if (this.props.isVatAssist) {
      validationRules.VatNumber = 'number:int, maxlength:9, minlength:9, required';
    }

    this.validator = new Validator();
    this.validator.setRules(validationRules);
    this.validationReactionDisposer = reaction(() => ({ ...this.state.formData }), this.validator.validate, {
      fireImmediately: true
    });
  }

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  openVatAssistModal = () => {
    this.setState({
      vatModalOpen: true
    });
  };
  closeVatAssistModal = () => {
    this.setState({
      vatModalOpen: false
    });
  };
  handleFieldChange = (id, value) => {
    const oldData = clone(this.state.formData);
    const newData = set(oldData, id, value);
    this.validator.validate(newData);
    this.setState({
      formData: newData
    });
  };
  onSave = () => {
    this.props.onSave(this.state.formData);
  };
  submitForm = () => {
    this.validator.validate(this.state.formData);

    if (!this.validator.errorCount) {
      this.props.onSubmit(this.state.formData);
    }
  };

  render() {
    const errors = this.validator.getErrors();
    return (
      <div>
        <MobxForm onSubmit={this.submitForm}>
          <MobxFieldGroup isInline error={errors.TradingName}>
            <MobxFormLabel>{this.props.t('BusinessDetailsForm.trading_name')}</MobxFormLabel>
            <TextInput
              id="TradingName"
              ref="one"
              value={this.state.formData.TradingName}
              onChange={this.handleFieldChange}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.RegisteredName}>
            <MobxFormLabel>{this.props.t('BusinessDetailsForm.registered_name')}</MobxFormLabel>
            <TextInput
              id="RegisteredName"
              value={this.state.formData.RegisteredName}
              onChange={this.handleFieldChange}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.CompanyType}>
            <MobxFormLabel>{this.props.t('BusinessDetailsForm.company_type')}</MobxFormLabel>
            <SelectInput
              id="CompanyType"
              options="CompanyType"
              value={this.state.formData.CompanyType}
              onChange={this.handleFieldChange}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.CompanyRegistrationNumber}>
            <MobxFormLabel>{this.props.t('BusinessDetailsForm.company_registration_number')}</MobxFormLabel>
            <TextInput
              id="CompanyRegistrationNumber"
              value={this.state.formData.CompanyRegistrationNumber}
              onChange={this.handleFieldChange}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.CompanySector}>
            <MobxFormLabel>{this.props.t('BusinessDetailsForm.company_sector')}</MobxFormLabel>
            <SelectInput
              id="CompanySector"
              options="CompanySector"
              value={this.state.formData.CompanySector}
              onChange={this.handleFieldChange}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.DateEstablished}>
            <MobxFormLabel>{this.props.t('BusinessDetailsForm.date_established')}</MobxFormLabel>
            <DateInput
              id="DateEstablished"
              value={this.state.formData.DateEstablished}
              onChange={this.handleFieldChange}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.VatNumber}>
            <MobxFormLabel>
              {this.props.t('BusinessDetailsForm.vat_number')}{' '}
              {this.props.isVatAssist && <ButtonInfoIcon onClick={this.openVatAssistModal} />}
            </MobxFormLabel>
            <Input2
              id="VatNumber"
              type="text"
              inlineField
              // TODO: i18n?
              prefix="GB"
              onChange={(e) => this.handleFieldChange('VatNumber', e.currentTarget.value)}
              value={this.state.formData.VatNumber}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.NumberOfDirectorsOrPartners}>
            <MobxFormLabel>{this.props.t('BusinessDetailsForm.number_of_directors_partners')}</MobxFormLabel>
            <TextInput
              id="NumberOfDirectorsOrPartners"
              type="number"
              value={this.state.formData.NumberOfDirectorsOrPartners}
              onChange={this.handleFieldChange}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.NumberOfEmployees}>
            <MobxFormLabel>{this.props.t('BusinessDetailsForm.number_of_employees')}</MobxFormLabel>
            <TextInput
              id="NumberOfEmployees"
              type="number"
              min="1"
              value={this.state.formData.NumberOfEmployees}
              onChange={this.handleFieldChange}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.EmailAddress}>
            <MobxFormLabel>{this.props.t('BusinessDetailsForm.email_address')}</MobxFormLabel>
            <TextInput
              id="EmailAddress"
              type="email"
              value={this.state.formData.EmailAddress}
              onChange={this.handleFieldChange}
              autoComplete="none"
              placeholder={this.props.t('BusinessDetailsForm.enter_customer_email_address')}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.Landline}>
            <MobxFormLabel>{this.props.t('BusinessDetailsForm.business_telephone')}</MobxFormLabel>
            <PhoneNumberInput
              id="Landline"
              value={this.state.formData.Landline}
              onChange={(value) => this.handleFieldChange('Landline', value)}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.Mobile}>
            <MobxFormLabel>{this.props.t('BusinessDetailsForm.or_mobile_telephone')}</MobxFormLabel>
            <PhoneNumberInput
              id="Mobile"
              value={this.state.formData.Mobile}
              onChange={(value) => this.handleFieldChange('Mobile', value)}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.FaxNumber}>
            <MobxFormLabel>{this.props.t('BusinessDetailsForm.fax_number')}</MobxFormLabel>
            <PhoneNumberInput
              id="FaxNumber"
              value={this.state.formData.FaxNumber}
              onChange={(value) => this.handleFieldChange('FaxNumber', value)}
            />
          </MobxFieldGroup>
          <FormFooter
            onSave={this.onSave}
            submittingState={this.props.submittingState}
            savingState={this.props.savingState}
          />
        </MobxForm>
        <Modal onClose={this.closeVatAssistModal} isOpen={this.state.vatModalOpen}>
          {this.props.t('BusinessDetailsForm.vat_assist_is_only_available_to_vat_registered_business_customers')}
        </Modal>
      </div>
    );
  }
}

BusinessDetailsForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  validationRules: PropTypes.object.isRequired,
  savingState: PropTypes.string.isRequired,
  submittingState: PropTypes.string
};
export default withTranslation('Application')(observer(BusinessDetailsForm));
