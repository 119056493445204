import i18n from 'i18n';
import appStore from 'mobx-stores/appStore';
import { snakeCase } from 'snake-case';

const tOpts = {
  ns: 'DigitalDeals'
};

export const shouldShowType = () => {
  return appStore.uiState.isDe;
};

export const getEligibilityStatusTranslation = (status?: string | null) => {
  if (snakeCase(status || '') === 'high') {
    return i18n.t('OrderSummaryTable.high', tOpts);
  }

  if (snakeCase(status || '') === 'low') {
    return i18n.t('OrderSummaryTable.low', tOpts);
  }

  return status;
};
