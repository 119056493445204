import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './fieldToggle.scss';

class FieldToggle extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    showHide: PropTypes.bool,
    text: PropTypes.string,
    trackingPage: PropTypes.string,
    trackingItem: PropTypes.string
  };
  handleClick = () => {
    this.props.onClick();
    this.props.trackingPage &&
      window.ga &&
      window.ga(
        'send',
        'event',
        this.props.trackingPage,
        `${this.props.showHide ? 'Hide' : 'Show'}${
          this.props.trackingItem ? this.props.trackingItem : 'ToggleFieldItem'
        }`,
        this.props.id
      );
  };

  render() {
    return (
      <div className="fieldToggle" onClick={this.handleClick} data-text={this.props.text}>
        {this.props.showHide ? (
          <div className="fieldToggle__inner" key="inner1">
            <div className="fieldToggle__upArrow" />
            {this.props.t('FieldToggle.hide')} {this.props.text}
          </div>
        ) : (
          <div className="fieldToggle__inner" key="inner2">
            <div className="fieldToggle__downArrow" />
            {this.props.t('FieldToggle.show')} {this.props.text}
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation('Common')(FieldToggle);
