import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  draft.applicationFields.PersonalDetails!.DualNationality = true;
  draft.applicationFields.AffordabilityDetails!.NetMonthly = false;

  draft.validationRules.PersonalDetails!.DualNationality = 'required';
  delete draft.validationRules.AffordabilityDetails!.Income!.NetMonthly;
});
