import { createQueryKeys } from '@lukemorales/query-key-factory';
import { fetchCfcProspects } from '.';

export const SERVICE_PREFIX = 'dashboard';

export const dashboardQueries = createQueryKeys(SERVICE_PREFIX, {
  cfcProspects: ({
    matchRate,
    activeFilter,
    page,
    dealershipId,
    userId
  }: Parameters<typeof fetchCfcProspects>['0']) => ({
    queryKey: [SERVICE_PREFIX, { matchRate, activeFilter, page, dealershipId, userId }],
    queryFn: () => fetchCfcProspects({ matchRate, activeFilter, page, dealershipId, userId })
  })
});
