import { action, observable, extendObservable } from 'mobx';

class CorporateQuickQuote {
  @observable dealershipId;
  @observable vehicle;
  @observable quote;

  tradingName;
  title;
  firstName;
  middleName;
  lastName;
  position;
  email;
  businessTelephone;
  mobileTelephone;
  assignToSelf;

  constructor() {
    extendObservable(this, {
      tradingName: '',
      title: '',
      firstName: '',
      middleName: '',
      lastName: '',
      position: '',
      email: '',
      businessTelephone: '',
      mobileTelephone: '',
      assignToSelf: false
    });
  }

  @action setTradingName = (value) => (this.tradingName = value);
  @action setTitle = (value) => (this.title = value);
  @action setFirstName = (value) => (this.firstName = value);
  @action setMiddleName = (value) => (this.middleName = value);
  @action setLastName = (value) => (this.lastName = value);
  @action setPosition = (value) => (this.position = value);
  @action setEmail = (value) => (this.email = value);
  @action setBusinessTelephone = (value) => (this.businessTelephone = value);
  @action setMobileTelephone = (value) => (this.mobileTelephone = value);
  @action setAssignToSelf = (value) => (this.assignToSelf = value);

  @action setDealershipId = (dealershipId) => (this.dealershipId = dealershipId);
  @action setVehicle = (vehicle) => (this.vehicle = vehicle);
  @action setQuote = (quote) => (this.quote = quote);

  toJS = () => {
    return {
      tradingName: this.tradingName,
      title: this.title,
      firstName: this.firstName,
      middleName: this.middleName,
      lastName: this.lastName,
      position: this.position,
      email: this.email,
      businessTelephone: this.businessTelephone,
      mobileTelephone: this.mobileTelephone,
      assignToSelf: this.assignToSelf
    };
  };
}

export default CorporateQuickQuote;
