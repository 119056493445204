import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Page from '../Common/Page';
import Breadcrumbs from '../Common/Breadcrumbs';
import Panel from '../Common/Panel';
import PanelHeader from '../Common/PanelHeader';
import Button from '../Common/Button/Button';
import Select from '../Common/Form/Select';
import _ from 'lodash';
import Form from '../MobxForm/MobxForm';
import FormLabel from '../MobxForm/MobxFormLabel';
import FieldGroup from '../MobxForm/MobxFieldGroup';
import CurrencyInput from '../Common/Form/CurrencyInput';
import './valueAddedProductForms.scss';
import { observer, inject } from 'mobx-react';
import Validator from '../../validators/Validator';
import ProductNameLookup from '../Common/ProductNameLookup';
import ToggleSwitch from '../Common/ToggleSwitch';
import InformationWarning from '../Common/InformationWarning';
import Modal from '../Common/Modal/Modal';
import DescriptionTextArea from '../Common/DescriptionTextArea';
import { withNavigate, withParams } from 'hocs/router';
import { compose } from 'redux';
class AddValueAddedProductForm extends React.Component {
  constructor() {
    super();
    this.state = {
      productType: '',
      name: '',
      description: '',
      price: '',
      taxType: null,
      visibility: true,
      isDefault: false,
      isDefaultModalOpen: false,
      productTypeText: ''
    };
    const validationRules = {
      productType: 'required',
      name: 'required, doesNotStartOrEndWithSpace, maxlength:100, alphanumericWithDigitsPunctuationAndSpaces',
      description: 'doesNotStartOrEndWithSpace, maxlength:500, alphanumericWithDigitsPunctuationAndSpaces',
      price: 'required, min:0'
    };
    this.validator = new Validator();
    this.validator.setRules(validationRules);
  }

  UNSAFE_componentWillMount() {
    this.props.appStore.vapStore.addProductRequest.reset();
    this.validator.validate(this.state);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (!_.isEqual(this.state, nextState)) {
      this.validator.validate(nextState);
    }
  }

  setProductType = (event) => {
    this.setState({
      productType: event.currentTarget.value,
      productTypeText: this.props.appStore.vapStore.getProductTextForProductType(event.currentTarget.value),
      taxType: this.props.appStore.vapStore.getTaxTypeForProductType(event.currentTarget.value),
      isDefault: event.currentTarget.value === '1' ? false : this.state.isDefault
    });
  };
  setName = (name) =>
    this.setState({
      name
    });
  setDescription = (description) =>
    this.setState({
      description
    });
  setPrice = (price) =>
    this.setState({
      price
    });
  setVisibility = (visibility) =>
    this.setState({
      visibility,
      isDefault: false
    });
  setDefault = (isDefault) =>
    this.setState({
      isDefault
    });
  onSubmit = () => {
    const findUsedProduct = _.find(this.props.appStore.vapStore.valueAddedProducts, {
      ProductTypeCode: parseInt(this.state.productType, 10),
      Default: true
    });

    let isAllowedMultipleTypes = false;

    if (
      this.state.productType === '11' ||
      this.state.productType === '13' ||
      this.state.productType === '14' ||
      this.state.productType === '17'
    ) {
      isAllowedMultipleTypes = true;
    }

    if (
      this.state.isDefault &&
      findUsedProduct &&
      findUsedProduct.Default &&
      !isAllowedMultipleTypes &&
      !this.validator.errorCount
    ) {
      this.setState({
        isDefaultModalOpen: true
      });
      return;
    }

    if (!this.validator.errorCount) {
      this.props.appStore.vapStore.onSubmitAdd(this.props.params.dealershipId, this.state).then(() => {
        if (this.props.appStore.vapStore.addProductRequest.isSuccessful) {
          this.props.navigate(`/d/${this.props.params.dealershipId}/settings/vaps`);
        }
      });
    } else {
      this.validator.validate(this.state);
    }
  };
  onModalSubmit = () => {
    const findUsedProduct = _.find(this.props.appStore.vapStore.valueAddedProducts, {
      ProductTypeCode: parseInt(this.state.productType, 10),
      Default: true
    });

    this.props.appStore.vapStore.setDefault(findUsedProduct.Id, false, this.props.params.dealershipId);

    if (!this.validator.errorCount) {
      this.props.appStore.vapStore.onSubmitAdd(this.props.params.dealershipId, this.state).then(() => {
        if (this.props.appStore.vapStore.addProductRequest.isSuccessful) {
          this.props.navigate(`/d/${this.props.params.dealershipId}/settings/vaps`);
        }
      });
    } else {
      this.validator.validate(this.state);
    }
  };
  closeDefaultModal = () => {
    this.setState({
      isDefaultModalOpen: false
    });
  };

  render() {
    let vapStore = this.props.appStore.vapStore;
    let errors = this.validator.errors ? this.validator.errors : {};
    const { hasOrders } = this.props.appStore.uiState;
    const nameLabel = hasOrders
      ? this.props.t('AddValueAddedProductForm.public_name')
      : this.props.t('AddValueAddedProductForm.name');
    let breadcrumbs = (
      <Breadcrumbs
        items={[
          {
            name: this.props.t('AddValueAddedProductForm.home'),
            path: `/d/${this.props.params.dealershipId}`
          },
          {
            name: this.props.t('AddValueAddedProductForm.settings'),
            path: `/d/${this.props.params.dealershipId}/settings`
          },
          {
            name: this.props.t('AddValueAddedProductForm.value_added_products'),
            path: `/d/${this.props.params.dealershipId}/settings/vaps`
          },
          {
            name: this.props.t('AddValueAddedProductForm.create_new_vap'),
            path: `/d/${this.props.params.dealershipId}/settings/vaps/add/${this.props.params.vapId}`
          }
        ]}
      />
    );
    let canEditVaps = this.props.appStore.uiState.canEditVaps;

    if (!canEditVaps) {
      return (
        <Page>
          {breadcrumbs}

          <Panel>
            <PanelHeader>{this.props.t('AddValueAddedProductForm.value_added_products')}</PanelHeader>
            <div className="valueAddedProductForms__errorMessage">
              {this.props.t('AddValueAddedProductForm.permissionDenied')}
            </div>
          </Panel>
        </Page>
      );
    }

    return (
      <Page>
        {breadcrumbs}
        <Panel>
          <PanelHeader>{this.props.t('AddValueAddedProductForm.value_added_products')}</PanelHeader>

          {!vapStore.fetchProductTypesRequest.isLoading &&
            vapStore.fetchProductTypesRequest.isSuccessful &&
            vapStore.parsedOptions &&
            vapStore.parsedOptions.length > 0 && (
              <Form onSubmit={this.onSubmit}>
                <div className="valueAddedProductForms__form">
                  <FieldGroup error={errors.productType} isInline>
                    <FormLabel>{this.props.t('AddValueAddedProductForm.type')}</FormLabel>
                    <Select
                      value={this.state.productType}
                      options={vapStore.parsedOptions}
                      onChange={this.setProductType}
                      dataThook="Type"
                    />
                  </FieldGroup>
                  {this.state.taxType && (
                    <div className="valueAddedProductForms__taxType">
                      {this.props.t('AddValueAddedProductForm.tax_type', {
                        taxType: this.state.taxType
                      })}
                    </div>
                  )}
                  <FieldGroup error={errors.name} isInline>
                    <FormLabel>{nameLabel}</FormLabel>
                    <ProductNameLookup onChange={this.setName} value={this.state.name} />
                  </FieldGroup>
                  <FieldGroup error={errors.description} isInline>
                    <FormLabel>{this.props.t('AddValueAddedProductForm.description')}</FormLabel>
                    <DescriptionTextArea
                      description={this.state.description}
                      setDescription={this.setDescription}
                      dataThook="Description"
                    />
                  </FieldGroup>
                  <FieldGroup error={errors.price} isInline>
                    <FormLabel>{this.props.t('AddValueAddedProductForm.gross_price')}</FormLabel>
                    <CurrencyInput value={this.state.price} onChange={this.setPrice} dataThook="Price" />
                  </FieldGroup>
                  <FieldGroup isInline>
                    <FormLabel>{this.props.t('AddValueAddedProductForm.visible_on_platform')}</FormLabel>
                    <ToggleSwitch
                      handleUpdate={this.setVisibility}
                      isActive={this.state.visibility}
                      dataTag="Visible"
                      dataThook="VisibleOnPlatform"
                    />
                  </FieldGroup>
                  <FieldGroup isInline>
                    <FormLabel>{this.props.t('AddValueAddedProductForm.default_when_quoting')}</FormLabel>
                    {parseInt(this.state.productType, 10) !== 1 ? (
                      <ToggleSwitch
                        handleUpdate={this.setDefault}
                        isActive={this.state.isDefault}
                        disabled={!this.state.visibility}
                        dataTag="Default"
                        dataThook="DefaultWhenQuoting"
                      />
                    ) : (
                      <div className="valueAddedProductForms__informationCard">
                        <InformationWarning
                          text={this.props.t(
                            'AddValueAddedProductForm.gap_insurance_products_cannot_be_automatically_added_when_quoting'
                          )}
                        />
                      </div>
                    )}
                  </FieldGroup>
                  {parseInt(this.state.productType, 10) === 1 && (
                    <FieldGroup isInline>
                      <div />
                      <div className="valueAddedProductForms__informationCard">
                        <InformationWarning
                          text={this.props.t(
                            'AddValueAddedProductForm.confirm_your_dealership_holds_fca_regulatory_permissions'
                          )}
                        />
                      </div>
                    </FieldGroup>
                  )}
                </div>
                <div className="valueAddedProductForms__formFooter">
                  <div className="valueAddedProductForms__formFooterButton">
                    <Button buttonStyle="cancel" to={`/d/${this.props.params.dealershipId}/settings/vaps`}>
                      {this.props.t('AddValueAddedProductForm.cancel')}
                    </Button>
                  </div>
                  {!vapStore.addProductRequest.isLoading && vapStore.addProductRequest.hasError && (
                    <div className="valueAddedProductForms__formFooterError">
                      {this.props.t('AddValueAddedProductForm.problem_submitting_request_try_later')}
                    </div>
                  )}
                  <div className="valueAddedProductForms__formFooterButton">
                    <Button
                      type="submit"
                      isLoading={vapStore.addProductRequest.isLoading}
                      hasError={vapStore.addProductRequest.hasError}
                      dataThook="addVapButton"
                    >
                      {this.props.t('AddValueAddedProductForm.add_vap')}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
        </Panel>
        <Modal isOpen={this.state.isDefaultModalOpen} onClose={this.closeDefaultModal}>
          <div className="valueAddedProductForms__modal">
            <div className="valueAddedProductForms__modalText">
              {this.props.t('AddValueAddedProductForm.already_added_VAP_update_for_default', {
                productType: this.state.productTypeText
              })}
            </div>
            <div className="valueAddedProductForms__modalButtons">
              <div className="valueAddedProductForms__modalButtonAdd">
                <Button
                  type="button"
                  onClick={this.onModalSubmit}
                  isLoading={vapStore.addProductRequest.isLoading}
                  hasError={vapStore.addProductRequest.hasError}
                >
                  {this.props.t('AddValueAddedProductForm.add_vap')}
                </Button>
              </div>
              <div className="valueAddedProductForms__modalButtonCancel">
                <Button buttonStyle="cancel" onClick={this.closeDefaultModal}>
                  {this.props.t('AddValueAddedProductForm.cancel')}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </Page>
    );
  }
}

AddValueAddedProductForm.propTypes = {
  params: PropTypes.object,
  appStore: PropTypes.object
};

export default compose(
  withNavigate,
  withParams,
  withTranslation('ValueAddedProductSettings'),
  inject(['appStore']),
  observer
)(AddValueAddedProductForm);
