import React from 'react';
import { inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import MoneyFormat from '~Common/MoneyFormat';
import { formatNumber } from '~/core/helpers';

import { VehicleFact, VehicleImage } from './components';

import './stockModuleVehicleCard.scss';

const StockModuleVehicleCard = (props) => {
  const { t } = useTranslation('Stock');
  const {
    attentionGrabber,
    title,
    derivative,
    mileage,
    fuel,
    transmission,
    location,
    tax,
    description,
    price,
    bhp,
    doors,
    bodyStyle,
    colour,
    cc,
    vrm,
    imgUrl
  } = props.vehicleRecord;
  return (
    <div className="stockModuleVehicleCard" data-cy="cardView">
      <VehicleImage imgUrl={imgUrl} />
      <div className="stockModuleVehicleCard__vehicle-info">
        <div className="stockModuleVehicleCard__title">
          <div className="stockModuleVehicleCard__vehicleTitleWrapper">
            <div data-cy="VehicleTitle" className="stockModuleVehicleCard__vehicleTitleMain">
              {title}
            </div>
          </div>
        </div>

        <div className="stockModuleVehicleCard__derivative">{derivative}</div>

        <div className="stockModuleVehicleCard__heartIcon" />

        {attentionGrabber && (
          <div className="stockModuleVehicleCard__attentionGrabberBackground">
            <div className="stockModuleVehicleCard__attentionGrabberTextTruncation" data-cy="AttentionGrabberWrapper">
              <span data-cy="AttentionGrabber" className="stockModuleVehicleCard__attentionGrabberText">
                {attentionGrabber}
              </span>
            </div>
          </div>
        )}

        <div>
          <VehicleFact type="vrm" content={vrm?.toUpperCase() || '-'} factsPerRow={2} />
          <VehicleFact type="fuel" content={fuel || '-'} factsPerRow={2} />
          <VehicleFact
            type="mileage"
            content={mileage ? formatNumber(mileage, 0, props.appStore.uiState.countryCode) : '-'}
            factsPerRow={2}
          />
          <VehicleFact type="transmission" content={transmission || '-'} factsPerRow={2} />
          {location && <VehicleFact type="location" content={location} factsPerRow={2} />}
          {tax && <VehicleFact type="tax" content={tax} factsPerRow={2} />}
          <VehicleFact type="bhp" content={bhp || '-'} factsPerRow={2} />
          <VehicleFact type="doors" content={doors || '-'} factsPerRow={2} />
          <VehicleFact type="bodyStyle" content={bodyStyle || '-'} factsPerRow={2} />
          {colour && <VehicleFact type="color" content={colour} factsPerRow={2} />}
          {cc && <VehicleFact type="cc" content={cc} factsPerRow={2} />}
        </div>

        <div className="stockModuleVehicleCard__description">{description}</div>

        <div data-cy="CashPrice" className="stockModuleVehicleCard__price">
          <span className="stockModuleVehicleCard__priceLabel">{t('StockModuleVehicleCard.price')}</span>
          <div className="stockModuleVehicleCard__priceValue">
            <MoneyFormat value={price} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject('appStore')(StockModuleVehicleCard);
