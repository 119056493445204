import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import SettingsProductTabs from './SettingsProductTabs';
import SettingsProductTable from './SettingsProductTable';
import EditProductCommissionsForm from '../../Quoting/forms/EditProductCommissionsForm';
import Button from '../../Common/Button/Button';
import { observer, inject } from 'mobx-react';
import Modal from '../../Common/Modal/Modal';
import LoadingSpinner from '../../Common/Loading/LoadingSpinner';
import './settingsProduct.scss';
import { compose } from 'redux';
import { withParams } from 'hocs/router';

class SettingsProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      sortBy: 'ProductType',
      sortDirection: 'asc',
      products: [],
      isModalOpen: false
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({
      products: _.cloneDeep(this.props.products.items)
    });
  }

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  setActiveTab = (index) => {
    this.setState({
      activeTab: index
    });
  };

  handleEdit = () => {
    this.openModal();
  };

  handleEditCommissions = (commissions) => {
    const products = _.cloneDeep(this.state.products);

    _.chain(products)
      .filter((item) => (this.state.activeTab === 0 ? item.AprOnly === false : item.AprOnly === true))
      .filter((product) => product.isSelected)
      .each(function(product) {
        product.Settings.Rate = commissions.Rate;
        product.Settings.CommissionType = commissions.CommissionType;
        product.hasChanged = true;
      })
      .value();

    this.setState({ products, isModalOpen: false });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      products: _.cloneDeep(nextProps.products.items)
    });
  }

  handleSave = () => {
    this.props.handleSave(this.state.products, this.props.params.dealershipId);
  };

  handleChangeVisibility = (productId, isVisible) => {
    const products = _.cloneDeep(this.state.products);

    const product = _.find(products, { ProductUid: productId });
    product.Settings.Visible = isVisible;
    product.hasChanged = true;

    this.setState({ products });
  };

  handleChangeSelected = (productId, checked) => {
    const products = _.cloneDeep(this.state.products);

    const product = _.find(products, { ProductUid: productId });
    product.isSelected = checked;

    this.setState({ products });
  };

  getProducts(type) {
    if (type === 'APR') {
      return _.filter(this.state.products, (item) => item.AprOnly === true);
    } else {
      return _.filter(this.state.products, (item) => item.AprOnly === false);
    }
  }

  render() {
    let products;
    let showEditButton = false;

    if (this.state.activeTab === 0) {
      products = this.getProducts();
      showEditButton = _.filter(products, { isSelected: true }).length > 0;
    } else {
      products = this.getProducts('APR');
      showEditButton = _.filter(products, { isSelected: true }).length > 0;
    }

    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: this.props.t('SettingsProduct.home'),
              path: `/d/${this.props.params.dealershipId}`
            },
            {
              name: this.props.t('SettingsProduct.settings'),
              path: `/d/${this.props.params.dealershipId}/settings`
            },
            {
              name: this.props.t('SettingsProduct.finance_products'),
              path: `/d/${this.props.params.dealershipId}/settings/product`
            }
          ]}
        />

        <Panel>
          <PanelHeader>{this.props.t('SettingsProduct.finance_products')}</PanelHeader>
          <div className="settingsProduct__contentWrapper">
            {!this.props.products.isLoading ? (
              <>
                <PanelContent>
                  <div>
                    <SettingsProductTabs setActiveTab={this.setActiveTab} activeTab={this.state.activeTab} />

                    <div className="settingsProduct__tableWrapper">
                      <SettingsProductTable
                        products={products}
                        options={this.props.options}
                        funders={this.props.funders}
                        key="traditional"
                        handleChangeVisibility={this.handleChangeVisibility}
                        handleChangeSelected={this.handleChangeSelected}
                      />
                    </div>
                  </div>
                </PanelContent>

                <div className="settingsProduct__divider" />

                <div className="settingsProduct__footer">
                  <div className="settingsProduct__cancelButton">
                    <Button buttonStyle="cancel" onClick={this.props.handleCancel}>
                      {this.props.t('SettingsProduct.cancel')}
                    </Button>
                  </div>
                  {this.props.appStore.uiState.canEditProducts && [
                    <div className="settingsProduct__saveButton" key="saveButton">
                      <Button
                        buttonStyle="primary"
                        isLoading={this.props.products.savingState === 'loading'}
                        hasError={this.props.products.savingState === 'error'}
                        type="button"
                        onClick={this.handleSave}
                      >
                        {this.props.t('SettingsProduct.save')}
                      </Button>
                    </div>,
                    <div className="settingsProduct__editButton" key="editButton">
                      <Button
                        buttonStyle="secondary"
                        isLoading={false}
                        disabled={!showEditButton}
                        onClick={this.handleEdit}
                      >
                        {this.props.t('SettingsProduct.edit_selected_commissions')}
                      </Button>
                    </div>
                  ]}
                </div>
              </>
            ) : (
              <div className="settingsProduct__loading">
                <LoadingSpinner />
              </div>
            )}
          </div>
        </Panel>
        <Modal isOpen={this.state.isModalOpen} onClose={this.closeModal}>
          <EditProductCommissionsForm
            options={this.props.options}
            closeModal={this.closeModal}
            onSubmit={this.handleEditCommissions}
            aprOnly={this.state.activeTab === 1}
          />
        </Modal>
      </Page>
    );
  }
}

SettingsProduct.propTypes = {
  params: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  form: PropTypes.object,
  products: PropTypes.object,
  options: PropTypes.object,
  funders: PropTypes.array,
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
  appStore: PropTypes.object
};
export default compose(withParams, withTranslation('ProductSettings'), inject(['appStore']), observer)(SettingsProduct);
