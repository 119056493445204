import * as actionTypes from '../actionTypes';

const defaultState = {
  currentView: 'SUMMARY_VIEW'
};

export default function sendDealReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SEND_DEAL_TO_CUSTOMER:
    case actionTypes.SEND_DEAL_TO_CUSTOMER_FROM_QUICK_QUOTE:
      return Object.assign({}, state, { currentView: 'LOADING_VIEW', customerId: action.customerId });

    case actionTypes.SEND_DEAL_TO_EXISTING_CUSTOMER_FROM_QUICK_QUOTE:
      return Object.assign({}, state, {
        currentView: 'LOADING_VIEW',
        customerId: action.customerId,
        isExistingCustomer: true
      });

    case actionTypes.SEND_DEAL_TO_CUSTOMER_SUCCESS:
    case actionTypes.SEND_DEAL_TO_CUSTOMER_FROM_QUICK_QUOTE_SUCCESS:
    case actionTypes.SEND_DEAL_TO_EXISTING_CUSTOMER_FROM_QUICK_QUOTE_SUCCESS:
      return Object.assign({}, state, {
        currentView: 'SUCCESS_VIEW',
        returnedCustomerId: action.response.CustomerId ? action.response.CustomerId : state.customerId,
        returnedVehicleId: action.response.VehicleId
      });

    case actionTypes.SEND_DEAL_TO_CUSTOMER_ERROR:
    case actionTypes.SEND_DEAL_TO_CUSTOMER_FROM_QUICK_QUOTE_ERROR:
    case actionTypes.SEND_DEAL_TO_EXISTING_CUSTOMER_FROM_QUICK_QUOTE_ERROR:
      return Object.assign({}, state, { currentView: 'ERROR_VIEW' });

    case actionTypes.RESET_SEND_DEAL_MODAL:
      return Object.assign({}, defaultState);

    case actionTypes.SET_SEND_DEAL_PAGE:
      return Object.assign({}, state, { currentView: action.page });

    default:
      return state;
  }
}
