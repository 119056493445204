import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './accessoriesPanel.scss';
import Table from './SummaryTable/Table';
import TableRow from './SummaryTable/TableRow';
import OuterLeft from './SummaryTable/OuterLeft';
import OuterRight from './SummaryTable/OuterRight';
import TableHead from './SummaryTable/TableHead';
import TableCell from './SummaryTable/TableCell';
import MoneyFormat from '../../Common/MoneyFormat';

const AccessoriesPanel = ({ quote }) => {
  const { t } = useTranslation('Reporting');
  return (
    <div className="accessoriesPanel">
      <div className="accessoriesPanel__text">
        {t('AccessoriesPanel.following_vehicle_accessories_included_in_price')}
      </div>
      <OuterLeft>
        <Table>
          <TableRow>
            <TableHead width="50%">{t('AccessoriesPanel.insurance')}</TableHead>
            <TableCell>
              <MoneyFormat value={quote.Insurance} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('AccessoriesPanel.warranty')}</TableHead>
            <TableCell>
              <MoneyFormat value={quote.Warranty} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('AccessoriesPanel.total_accessories')}</TableHead>
            <TableCell>
              <MoneyFormat value={quote.Accessories} />
            </TableCell>
          </TableRow>
        </Table>
      </OuterLeft>
      <OuterRight>
        <Table>
          <TableRow>
            <TableHead width="50%">{t('AccessoriesPanel.other')}</TableHead>
            <TableCell>
              <MoneyFormat value={quote.OtherAccessories} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('AccessoriesPanel.non_va_table_items')}</TableHead>
            <TableCell>
              <MoneyFormat value={quote.NonVatableItems} />
            </TableCell>
          </TableRow>
        </Table>
      </OuterRight>
    </div>
  );
};

AccessoriesPanel.propTypes = {
  quote: PropTypes.object
};
export default AccessoriesPanel;
