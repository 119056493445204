const getLender = (country) => {
  switch (country.toLowerCase()) {
    case 'de':
      return 'schwacke';
    case 'gb':
    case 'uk':
    default:
      return 'multilender';
  }
};

const normaliseVisCountry = (country) => {
  switch (country.toLowerCase()) {
    case 'de':
      return 'de';
    case 'gb':
    case 'uk':
    default:
      return 'uk';
  }
};

export const visRequest = ({ query, klass = '', currentPage = 1, pageSize = 5, country = 'uk' }) => {
  const params = new URLSearchParams({
    country: normaliseVisCountry(country),
    lender: getLender(country),
    class: klass,
    page: currentPage,
    pageSize,
    query
  });
  const url = `/platform/v1/vis/Search?${params.toString()}`;

  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        'content-type': 'application/json'
      },
      method: 'GET'
    })
      .then((response) => response.json())
      .then(resolve)
      .catch(reject);
  });
};

export const fetchVisVehicle = (primaryId, secondaryId, klass = 'car') => {
  const params = new URLSearchParams({
    country: 'uk',
    lender: 'multilender',
    class: klass,
    primaryTaxonomyId: primaryId,
    secondaryTaxonomyId: secondaryId
  });
  const url = `/platform/v1/vis/taxonomy?${params.toString()}`;
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        'content-type': 'application/json'
      },
      method: 'GET'
    })
      .then((response) => response.json())
      .then(resolve)
      .catch(reject);
  });
};

export const visMotorhomeRequest = ({ query, currentPage = 1, pageSize = 5 }) => {
  const params = new URLSearchParams({
    country: 'uk',
    lender: 'multilender',
    pageSize,
    page: currentPage,
    query
  });
  const url = `/platform/v1/vis/motorhomes-search?${params.toString()}`;

  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        'content-type': 'application/json'
      },
      method: 'GET'
    })
      .then((response) => response.json())
      .then(resolve)
      .catch(reject);
  });
};
