import React from 'react';
import PropTypes from 'prop-types';
import DateFormat from '../../Common/DateFormat';
import './commentsListItem.scss';

const CommentsListItem = ({ comment }) => {
  return (
    <div className="commentsListItem__container" data-th="CommentsListItem">
      <div className="commentsListItem__initialsContainer">
        <div className="commentsListItem__initials">{comment.Initials}</div>
      </div>
      <div className="commentsListItem__main">
        <div className="commentsListItem__top">
          <div className="commentsListItem__userName">
            {comment.Firstname} {comment.Surname}
          </div>
          <div className="commentsListItem__date">
            <div className="commentsListItem__dot">-</div>
            <DateFormat value={comment.Created} format="DD MMM YYYY HH:mm" parse="utc" />
          </div>
        </div>
        <div className="commentsListItem__messageContainer">
          <div className="commentsListItem__messageBody">{comment.MessageBody}</div>
        </div>
      </div>
    </div>
  );
};

CommentsListItem.propTypes = {
  comment: PropTypes.object
};

export default CommentsListItem;
