import React from 'react';
import PropTypes from 'prop-types';
import './activityTimelineContent.scss';
import DateFormat from '../Common/DateFormat';

const ActivityTimelineContent = ({ date, children, dataTh }) => (
  <div className="activityTimelineContent">
    {date && (
      <div className="activityTimelineContent__date">
        <DateFormat value={date} format="HH:mm DD MMM" />
      </div>
    )}
    <div className="activityTimelineContent__text" data-th={dataTh}>
      {children}
    </div>
  </div>
);

ActivityTimelineContent.propTypes = {
  children: PropTypes.node,
  date: PropTypes.string
};

export default ActivityTimelineContent;
