import { withTranslation } from 'react-i18next';

import React from 'react';
import PropTypes from 'prop-types';

class Map extends React.Component {
  componentDidMount() {
    this.renderMap();
  }

  componentDidUpdate() {
    this.renderMap();
  }

  renderMap() {
    const zoom = 6;
    const latitude = this.props.lat ? this.props.lat : 53.945148;
    const longitude = this.props.lon ? this.props.lon : -2.520933;
    this.map = new window.google.maps.Map(this.refs.map, {
      center: {
        lat: latitude,
        lng: longitude
      },
      zoom: zoom,
      mapTypeId: 'satellite',
      scrollwheel: false
    });
    const hm = this.props.data.map(function(x) {
      return {
        location: new window.google.maps.LatLng(x.Latitude, x.Longitude),
        weight: x.UserCount
      };
    });
    const heatmap = new window.google.maps.visualization.HeatmapLayer({
      data: hm,
      map: this.map
    });
    const radius = 20;
    heatmap.set('radius', radius);
  }

  render() {
    return (
      <div ref="map" className="heatMap__map">
        {this.props.t('Map.map')}
      </div>
    );
  }
}

Map.propTypes = {
  lat: PropTypes.number,
  lon: PropTypes.number,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  name: PropTypes.string
};
export default withTranslation('DigitalReport')(Map);
