export function daysSinceGivenDate(givenDateISOString: string): number | null {
  if (typeof givenDateISOString !== 'string') {
    return null;
  }
  const givenDate = new Date(givenDateISOString);
  const currentDate = new Date();

  // to appease TS have to add .valueOf()
  const timeDifference = currentDate?.valueOf() - givenDate?.valueOf();

  // Convert milliseconds to days
  const daysSince = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysSince;
}
