import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Page from '../Common/Page';
import Breadcrumbs from '../Common/Breadcrumbs';
import Panel from '../Common/Panel';
import PanelContent from '../Common/PanelContent';
import PanelHeader from '../Common/PanelHeader';
import PaidOutForm from './Forms/PaidOutForm';
import { observer, inject } from 'mobx-react';
import LoadingSpinner from '../Common/Loading/LoadingSpinner';
import Button from '../Common/Button/Button';
import './Forms/paidOutForm.scss';
import ErrorApology from '../Common/ErrorApology';

import { compose } from 'redux';
import { withParams, withNavigate } from '../../hocs/router';
class PaidOut extends Component {
  UNSAFE_componentWillMount() {
    this.props.appStore.closeDealStore.fetchCloseDealData(
      this.props.params.consumerId,
      this.props.params.vehicleId,
      this.props.params.dealershipId
    );

    this.props.appStore.customerStore.fetchCustomerData(this.props.params.consumerId, this.props.params.vehicleId);
  }

  goBack = () => {
    this.props.navigate(-1);
  };

  render() {
    if (this.props.appStore.customerStore.isLoadingCustomer) {
      return null;
    }

    if (this.props.appStore.customerStore.hasError) {
      return <ErrorApology>Error fetching customer.</ErrorApology>;
    }

    const closeDealStore = this.props.appStore.closeDealStore;
    let { customer } = this.props.appStore.customerStore;
    let { children, ...props } = this.props;
    const vehicle = customer.getVehicle(this.props.params.vehicleId);

    if (!props.appStore.closeDealStore.hasCloseDealLoaded || props.appStore.customerStore.isLoadingCustomer) {
      return <LoadingSpinner />;
    }

    return (
      <Page testHook="PaidOut" isLoading={props.appStore.closeDealStore.isLoading}>
        <Breadcrumbs
          items={[
            { name: 'Home', path: `/d/${props.params.dealershipId}` },
            { name: 'Customer List', path: `/d/${props.params.dealershipId}/consumers` },
            {
              name: 'Consumer',
              path: `/d/${props.params.dealershipId}/consumers/${props.params.consumerId}`
            },
            { name: 'Paid Out' }
          ]}
          consumer={customer}
        />
        <Panel>
          <PanelHeader>Lead Close Out</PanelHeader>
          <PanelContent>
            <PaidOutForm
              onSubmit={(formData) =>
                closeDealStore.markLeadAsPaidOut(
                  formData,
                  customer.CustomerType,
                  this.props.params.vehicleId,
                  this.props.params.consumerId
                )
              }
              consumer={customer}
              vehicle={vehicle}
              vehicleId={this.props.params.VehicleId}
              dealershipId={props.params.dealershipId}
              defaultValues={closeDealStore.closeDealData}
              onSubmitVapsModal={closeDealStore.fetchVapProductType}
            >
              <div className="soldFinance__buttons">
                <div className="soldFinance__proceedButton">
                  <Button
                    type="submit"
                    isLoading={closeDealStore.sendCloseDealRequest.isLoading}
                    hasError={closeDealStore.sendCloseDealRequest.hasError}
                  >
                    Proceed
                  </Button>
                </div>
                <div className="soldFinance__cancelButton">
                  <Button buttonStyle="cancel" type="button" onClick={this.goBack}>
                    Cancel
                  </Button>
                </div>
              </div>
            </PaidOutForm>
          </PanelContent>
        </Panel>
      </Page>
    );
  }
}

PaidOut.propTypes = {
  appStore: PropTypes.object,
  params: PropTypes.object,
  children: PropTypes.node
};

export default compose(withNavigate, withParams, inject(['appStore']), observer)(PaidOut);
