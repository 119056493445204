import React from 'react';
import classNames from 'classnames';
import './loadingSpinner.scss';

type LoadingSpinnerProps = {
  size: 'xlarge' | 'large' | 'normal' | 'small';
  center: boolean;
};

const LoadingSpinner = ({ size, center }: LoadingSpinnerProps) => {
  let classes = classNames('loadingSpinner', `loadingSpinner--${size}`, {
    'loadingSpinner--center': center
  });

  return (
    <div className={classes} data-th="LoadingSpinner">
      <div className="loadingSpinner__track" />
      <div className="loadingSpinner__handle" />
    </div>
  );
};

LoadingSpinner.defaultProps = {
  size: 'normal',
  center: false
};

export default LoadingSpinner;
