import { createSelector } from 'reselect';
import { getOptions } from '../../../selectors/optionsSelector';
import _ from 'lodash';

export const getLatestDealsState = (state) => state.latestDeals;
export const getStatusOptions = createSelector([getLatestDealsState, getOptions], (latestDeals, options) => {
  return _.map(latestDeals.statuses, (status) => {
    const lenderStatus = _.find(options.LenderStatus, (item) => {
      return item.key.toLowerCase() === status.Name;
    });
    return {
      key: status.Name,
      value: lenderStatus.value
    };
  });
});

export const getLatestDeals = createSelector([getLatestDealsState], (latestDeals) => {
  return _.map(latestDeals.items, (item) => {
    const { Applications, ...deal } = item;
    return {
      Applications: _.orderBy(Applications, 'LastModifiedTimestamp', 'desc'),
      ...deal
    };
  });
});
