import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import observerForHooks from '../../../hocs/observerForHooks';
import NotImplementedDefault from '../NotImplemented';

const Feature = ({ name, appStore, children }) => {
  const { t } = useTranslation('Common');
  const { isFeatureEnabled, isFeatureImplemented } = appStore.uiState.checkFeature(name);

  if (typeof children === 'function') {
    return children({ isFeatureEnabled, isFeatureImplemented });
  }

  const childrenArray = React.Children.toArray(children);
  const featureContent = childrenArray.find((child) => child.type.name === Content.name);
  const featureNotEnabled = childrenArray.find((child) => child.type.name === NotEnabled.name);
  const featureNotImplemented = childrenArray.find((child) => child.type.name === NotImplemented.name);

  if (!isFeatureImplemented) {
    return featureNotImplemented || <NotImplementedDefault />;
  }

  if (!isFeatureEnabled) {
    return featureNotEnabled || t('Feature.not_enabled');
  }

  return featureContent || null;
};

const NotImplemented = ({ children }) => children;
const NotEnabled = ({ children }) => children;
const Content = ({ children }) => children;

Feature.propTypes = {
  name: PropTypes.oneOf(['pushdeal']).isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired
};

export { Feature as UnwrappedFeature, Content, NotEnabled, NotImplemented };
export default compose(inject('appStore'), observerForHooks)(Feature);
