import styles from './impressum.module.scss';

const Impressum = () => {
  return (
    <div className={styles.impressum}>
      <a
        href="https://ald-leasefinanz.de/ald/impressum"
        className={styles.impressum__button}
        rel="noopener noreferrer"
        target="_blank"
      >
        Impressum
      </a>
    </div>
  );
};

export default Impressum;

