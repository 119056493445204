import React from 'react';
import Icon from '~Common/Icon/Icon';
import cn from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';

import './RHF-ValidationWrapper.scss';

const RHFValidationWrapper = ({ name, labelComponent, children, rules, errorMessage }) => {
  const { control, formState } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ fieldState }) => {
        const { invalid, isTouched, error } = fieldState;
        const isInValid = error && invalid;
        const isValid = !isInValid;
        const showIsValid = (isTouched && isValid) || (formState.submitCount > 0 && isValid);

        return (
          <>
            <div className={cn('RHFValidationWrapper')}>
              <div className={cn('RHFValidationWrapper__label')}>{labelComponent}</div>

              <div
                className={cn(
                  'RHFValidationWrapper__icon',
                  showIsValid && 'RHFValidationWrapper__tick',
                  isInValid && 'RHFValidationWrapper__cross'
                )}
              >
                {showIsValid ? <Icon name="validation-tick" /> : null}
                {isInValid ? <Icon name="validation-cross" /> : null}
              </div>
              <div className={cn('RHFValidationWrapper__input')}>{children}</div>
            </div>
            <div className={cn('RHFValidationWrapper__errorMessage')}>
              {error && <span>{errorMessage || error?.message}</span>}
            </div>
          </>
        );
      }}
    />
  );
};

export default RHFValidationWrapper;
