import * as api from '../../api/customer';
import { call, put, select } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import * as actionTypes from '../actionTypes';
import { getCustomer } from '../../selectors/customerSelectors';

export function* fetchCustomerIfNeeded(customerId) {
  const customerInMemory = yield select(getCustomer, customerId);

  if (!customerInMemory) {
    yield call(fetchCustomerAlways, customerId);
  }
}

export function* fetchCustomerAlways(customerId) {
  yield put({ type: actionTypes.FETCH_CONSUMER });

  let customerResponse = yield call(api.fetchCustomer, customerId);

  yield put({ type: actionTypes.FETCH_CONSUMER_SUCCESS, response: customerResponse });
}

export function* addVehicleToCustomer(action) {
  yield put({ type: actionTypes.ADD_VEHICLE_TO_CUSTOMER, customerId: action.customerId, vehicle: action.vehicle });
}

export function* watchAddVehicleSuccess() {
  yield* takeEvery(actionTypes.ADD_CONSUMER_VEHICLE_SUCCESS, addVehicleToCustomer);
}
