import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './closeDealBanner.scss';

const CloseDealBanner = (props) => {
  const { t } = useTranslation('CloseDeal');
  let status = '';

  if (props.status === 'SoldCash') {
    status = t('CloseDealBanner.sold_cash');
  } else if (props.status === 'SoldFinance') {
    status = t('CloseDealBanner.sold_finance');
  } else if (props.status === 'NotAvailable') {
    status = t('CloseDealBanner.vehicle_not_available');
  } else if (props.status === 'NotOfInterest') {
    status = t('CloseDealBanner.lost_deal');
  } else if (props.closed && props.status === 'SoldPaidOut') {
    status = t('CloseDealBanner.paid_out_closed');
  } else if (props.status === 'SoldPaidOut') {
    status = t('CloseDealBanner.paid_out_open');
  }
  return (
    <div className="closeDealBanner" key="container">
      <div className="closeDealBanner__triangle" />
      <div className="closeDealBanner__text">{status}</div>
    </div>
  );
};

CloseDealBanner.propTypes = {
  closed: PropTypes.bool,
  status: PropTypes.string
};

export default CloseDealBanner;
