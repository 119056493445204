import { withTranslation } from 'react-i18next';
import React from 'react';
import PageTitle from '../../Common/PageTitle';

const ReplacementModal = ({ t }) => {
  return (
    <div>
      <PageTitle text={t('ReplacementModal.is_this_a_replacement_loan')} />
      <p>
        {t(
          'ReplacementModal.please_confirm_if_this_new_monthly_commitment_is_a_replacement_for_an_existing_loan_repayment_which_will_be_settled_as_part_of_this_process'
        )}
      </p>
    </div>
  );
};

export default withTranslation('Application')(ReplacementModal);
