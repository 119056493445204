export function getLenderUpdatesColor(status) {
  let color = '';
  switch (status) {
    case 'Unknown':
    case 'Submitting':
    case 'Referred':
    case 'Pending':
    case 'Prospect':
    case 'Intercepted':
    case 'SentForPayout':
    case 'Other':
      color = 'medium';
      break;

    case 'Accepted':
    case 'ConditionalAccept':
    case 'RateAdjustedAccepted':
    case 'ProposalAdjustedAccepted':
    case 'PaidOut':
    case 'Submitted':
    case 'Refunded':
      color = 'high';
      break;

    case 'NoDecisionYet':
    case 'ProblemPayout':
    case 'Error':
    case 'WaitingForFurtherInformation':
    case 'NotTakenUp':
    case 'Rejected':
    case 'NotProceeding':
    case 'Overdue':
    case 'Cancelled':
      color = 'low';
      break;

    case 'Reserved':
      color = 'blue';
      break;

    default:
      color = status;
  }
  return color;
}
