import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { countItemsInObject } from '../../../core/helpers';
import CFCVehicleSummaryTable from './CFCVehicleSummaryTable';
import CFCPersonalDetailsSummaryTable from './CFCPersonalDetailsSummaryTable';
import CFCAddressHistorySummaryTable from './CFCAddressHistorySummaryTable';
import CFCEmploymentHistorySummaryTable from './CFCEmploymentHistorySummaryTable';
import './cfcSummaryTables.scss';
import { withTranslation } from 'react-i18next';

class CFCSummaryTables extends React.Component {
  render() {
    const cfcCheck = this.props.cfcCheck;
    const vehicle = this.props.vehicle;

    const addresses = _.get(cfcCheck, 'AddressHistory.Items') || [];

    //If no address history, add a blank one so we still see the fields
    if (!addresses.length) {
      addresses.push({
        TimeAtAddress: {}
      });
    }

    const employments = _.get(cfcCheck, 'EmploymentHistory.Items') || [];
    //If no employment history, add a blank one so we still see the fields
    if (!employments.length) {
      employments.push({
        TimeAtEmployment: {}
      });
    }

    let sections = ['PersonalDetails', 'EmploymentHistory', 'AddressHistory'];

    sections = sections.map((section) => {
      let errors = this.props.validationErrors ? this.props.validationErrors[section] : null;
      let errorCount = countItemsInObject(errors);

      let errorMessage;

      if (errorCount === 1 && errors.totalMonths) {
        let totalMonths = _.get(this.props.cfcCheck, '[' + section + '].totalMonths') || 0;
        let monthsNeeded = 36 - totalMonths;
        errorMessage = this.props.t('CFCSummaryTables.months_needed_months_of_section_is_still_required', {
          monthsNeeded,
          section: _.startCase(section)
        });
      } else {
        if (section === 'EmploymentHistory' || section === 'AddressHistory') {
          errorCount = errorCount === 0 ? 0 : errorCount - 1;
        }
        errorMessage = this.props.t('CFCSummaryTables.field_required', {
          count: errorCount
        });
      }

      return {
        section,
        errorCount,
        errorMessage
      };
    });

    return (
      <div className="cfcSummaryTables" data-th="CFCSummaryTables">
        <CFCVehicleSummaryTable vehicle={vehicle} showValidationTicks={this.props.showValidationTicks} />

        <div className="cfcSummaryTables__spacer" />

        <CFCPersonalDetailsSummaryTable
          personal={cfcCheck.PersonalDetails}
          section={_.find(sections, { section: 'PersonalDetails' })}
          showValidationTicks={this.props.showValidationTicks}
          errorCount={_.find(sections, { section: 'PersonalDetails' }).errorCount}
        />

        <div className="cfcSummaryTables__spacer" />

        <CFCAddressHistorySummaryTable
          addresses={addresses}
          showValidationTicks={this.props.showValidationTicks}
          section={_.find(sections, { section: 'AddressHistory' })}
          errorCount={_.find(sections, { section: 'AddressHistory' }).errorCount}
        />

        <div className="cfcSummaryTables__spacer" />

        <CFCEmploymentHistorySummaryTable
          employments={employments}
          showValidationTicks={this.props.showValidationTicks}
          section={_.find(sections, { section: 'EmploymentHistory' })}
          errorCount={_.find(sections, { section: 'EmploymentHistory' }).errorCount}
        />

        <div className="cfcSummaryTables__spacer" />
      </div>
    );
  }
}

CFCSummaryTables.propTypes = {
  t: PropTypes.func,
  cfcCheck: PropTypes.object.isRequired,
  showValidationTicks: PropTypes.bool,
  vehicle: PropTypes.object,
  validationErrors: PropTypes.object
};

export default withTranslation('Quoting')(CFCSummaryTables);
