import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import Breadcrumbs from '../../Common/Breadcrumbs';
import ConsumerVehicleInfoPanel, { ConsumerVehicleType } from '../../Common/ConsumerVehicleInfoPanel';
import InformationWarning from '../../Common/InformationWarning';
import ApplicationFormWrapper from '../../Common/layouts/ApplicationFormWrapper';
import ApplicationNavigationWrapper from '../../Common/layouts/ApplicationNavigationWrapper';
import Page from '../../Common/Page';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelToolBar from '../../Common/PanelToolBar';
import ApplicationNavigation, { ApplicationProgress } from './ApplicationNavigation';
import DateFormat from '../../Common/DateFormat';
import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import useQueryParams from '~/hooks/useQueryParams';

import './applicationFormlayout.scss';
import { Outlet } from 'react-router-dom';

// TODO: Move to the breadcrumb component once it's converted to TS
export type Breadcrumb = {
  name: string;
  path?: string;
  query?: {
    [key: string]: string | number;
  };
};

type ApplicationFormLayoutProps = {
  application: {
    CreatedDate: string;
    CustomerType: string;
    Tags: { multiQuoteRefId: string };
    Quote?: { FunderCode: string };
    progress: ApplicationProgress;
  };
  consumer: { Id: string };
  vehicle: ConsumerVehicleType;
};
const ApplicationFormLayout = () => {
  const outletContext = useOutletContext<ApplicationFormLayoutProps>();
  const { application, vehicle, consumer } = outletContext;
  const { t } = useTranslation('Application');
  const { dealershipId, consumerId, applicantId } = useParams();
  const [query] = useQueryParams();
  const location = useLocation();

  useEffect(() => {
    const scrollOpts = {
      top: 0,
      behaviour: 'smooth'
    };

    if (window && window.scrollTo) {
      window.scrollTo(scrollOpts);
    }
  }, [location.pathname]);

  const getBreadCrumbs = () => {
    const editing = location.pathname.includes('/edit/');
    let crumbs: Breadcrumb[] = [
      {
        name: t('ApplicationFormLayout.home'),
        path: `/d/${dealershipId}`
      }
    ];

    if (query.order) {
      crumbs.push({
        name: t('ApplicationFormLayout.orders_deals'),
        path: `/d/${dealershipId}/orders-and-deals`,
        query: {
          page: 1,
          pageSize: 5,
          dealStatus: 'all',
          sortBy: 'sentAt'
        }
      });
      crumbs.push({
        name: t('ApplicationFormLayout.order_summary'),
        path: `/d/${dealershipId}/orders-and-deals/order/${query.order}`
      });
    } else {
      crumbs.push({
        name: t('ApplicationFormLayout.customer_list'),
        path: `/d/${dealershipId}/consumers`
      });
      crumbs.push({
        name: t('ApplicationFormLayout.consumer'),
        path: `/d/${dealershipId}/consumers/${consumer.Id}`
      });
    }

    if (editing) {
      crumbs.push({
        name: t('EditApplicationBankDetails.application_status'),
        path: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/status`
      });
      crumbs.push({
        name: t('EditApplicationBankDetails.edit_application'),
        path: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit`
      });
      // TO DO - Make the section name dynamic
      crumbs.push({
        name: t('ApplicationNavigation.personal_details')
      });
    } else {
      crumbs.push({
        name: t('ApplicationFormLayout.application_form')
      });
    }

    return crumbs;
  };

  return (
    <Page>
      <Breadcrumbs items={getBreadCrumbs()} consumer={consumer} />
      <ConsumerVehicleInfoPanel vehicle={vehicle} />
      <Panel>
        <PanelHeader>{t('ApplicationFormLayout.application_form')}</PanelHeader>
        {application.Tags && application.Tags.multiQuoteRefId && (
          <PanelToolBar>
            <div className="applicationFormlayout__applicationNumber">
              <InformationWarning>
                {t('ApplicationFormLayout.part_of_a_deal_created')}{' '}
                <DateFormat value={application.CreatedDate} format="DD/MM/YYYY - HH:mm" />
              </InformationWarning>
            </div>
          </PanelToolBar>
        )}
        <div className="application__outer">
          <ApplicationNavigationWrapper>
            <ApplicationNavigation
              progress={application.progress}
              applicationType={application.CustomerType}
              funderCode={application?.Quote?.FunderCode || ''}
            />
          </ApplicationNavigationWrapper>
          <ApplicationFormWrapper>
            <Outlet context={{ ...outletContext, progress: outletContext.application.progress }} />
          </ApplicationFormWrapper>
        </div>
      </Panel>
    </Page>
  );
};

export default ApplicationFormLayout;
