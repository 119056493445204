import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Common/Icon/Icon';
import './dealerDetails.scss';

const DealerDetails = ({ dealership, groupName }) => (
  <div className="dealerDetails__outer">
    <div className="dealerDetails__row">
      <div className="dealerDetails__imageOuter">
        <div className="dealerDetails__image">
          <Icon name="location" />
        </div>
      </div>
      <div className="dealerDetails__dataOuter">
        {groupName ? (
          <div className="dealerDetails__groupName">{groupName}</div>
        ) : (
          <div className="dealerDetails__address">
            <div>{dealership.Address1}</div>
            <div>{dealership.Address2}</div>
            <div>{dealership.PostalTown}</div>
            <div>{dealership.Postcode}</div>
          </div>
        )}
      </div>
    </div>
  </div>
);

DealerDetails.propTypes = {
  dealership: PropTypes.object,
  groupName: PropTypes.string
};

export default DealerDetails;
