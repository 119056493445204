import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import QuoteItem from '../../Common/QuoteItem';
import Button from '../../Common/Button/Button';
import MoneyFormat from '../../Common/MoneyFormat';
import './combinedQuoteModal.scss';
import FormatAPR from '../components/FormatAPR';

const CombinedQuotesModal = ({ quote, onProceed }) => {
  const { t } = useTranslation('Quoting');

  const handleProceed = () => onProceed(quote);
  const numLoans = quote.PersonalLoan && quote.NegativeEquityLoan ? 3 : 2;
  return (
    <>
      <div className="combinedQuotesModal__subHeader">
        {t('CombinedQuotesModal.this_quote_is_made_up_of_count_loans', {
          count: numLoans
        })}
      </div>
      <QuoteItem quote={quote} />
      <table className="combinedQuotesModal__breakdown">
        <thead>
          <tr className="combinedQuotesModal__breakdown--header">
            <td className="combinedQuotesModal__breakdown--headerText">{t('CombinedQuotesModal.loan_type')}</td>
            <td className="combinedQuotesModal__breakdown--headerText">{t('CombinedQuotesModal.apr')}</td>
            <td className="combinedQuotesModal__breakdown--headerText">
              {t('CombinedQuotesModal.total_amount_of_credit')}
            </td>
            <td className="combinedQuotesModal__breakdown--headerText">{t('CombinedQuotesModal.agreement_term')}</td>
            <td className="combinedQuotesModal__breakdown--headerText">
              {t('CombinedQuotesModal.regular_monthly_payment')}
            </td>
          </tr>
        </thead>
        <tbody>
          {quote.VehicleLoan && (
            <tr className="combinedQuotesModal__breakdown--quote">
              <td className="combinedQuotesModal__breakdown--loanType">{t('CombinedQuotesModal.vehicle_loan')}</td>
              <td className="combinedQuotesModal__breakdown--apr">
                <FormatAPR value={quote.VehicleLoan.RepresentativeApr} />
              </td>
              <td className="combinedQuotesModal__breakdown--totalPayable">
                <MoneyFormat value={quote.VehicleLoan.BalancePayable} />
              </td>
              <td className="combinedQuotesModal__breakdown--term">
                {quote.VehicleLoan.Term} {t('CombinedQuotesModal.months')}
              </td>
              <td className="combinedQuotesModal__breakdown--monthlyPayment">
                <MoneyFormat value={quote.VehicleLoan.FollowingPayments} />
              </td>
            </tr>
          )}

          {quote.PersonalLoan && (
            <tr className="combinedQuotesModal__breakdown--quote">
              <td className="combinedQuotesModal__breakdown--loanType">
                {t('CombinedQuotesModal.value_added_products_loan')}
              </td>
              <td className="combinedQuotesModal__breakdown--apr">
                <FormatAPR value={quote.PersonalLoan.RepresentativeApr} />
              </td>
              <td className="combinedQuotesModal__breakdown--totalPayable">
                <MoneyFormat value={quote.PersonalLoan.BalancePayable} />
              </td>
              <td className="combinedQuotesModal__breakdown--term">
                {quote.PersonalLoan.Term} {t('CombinedQuotesModal.months')}
              </td>
              <td className="combinedQuotesModal__breakdown--monthlyPayment">
                <MoneyFormat value={quote.PersonalLoan.FollowingPayments} />
              </td>
            </tr>
          )}

          {quote.NegativeEquityLoan && (
            <tr className="combinedQuotesModal__breakdown--quote">
              <td className="combinedQuotesModal__breakdown--loanType">
                {t('CombinedQuotesModal.negative_equity_loan')}
              </td>
              <td className="combinedQuotesModal__breakdown--apr">
                <FormatAPR value={quote.NegativeEquityLoan.RepresentativeApr} />
              </td>
              <td className="combinedQuotesModal__breakdown--totalPayable">
                <MoneyFormat value={quote.NegativeEquityLoan.BalancePayable} />
              </td>
              <td className="combinedQuotesModal__breakdown--term">
                {quote.NegativeEquityLoan.Term} {t('CombinedQuotesModal.months')}
              </td>
              <td className="combinedQuotesModal__breakdown--monthlyPayment">
                <MoneyFormat value={quote.NegativeEquityLoan.FollowingPayments} />
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="combinedQuotesModal__proceedButton">
        <Button onClick={handleProceed}>{t('CombinedQuotesModal.view_full_breakdown')}</Button>
      </div>
    </>
  );
};

CombinedQuotesModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onProceed: PropTypes.func,
  quote: PropTypes.object
};
export default CombinedQuotesModal;
