import { takeEveryByComponentName } from '../helpers';
import { put, call } from 'redux-saga/effects';
import { componentMount, componentError } from '../component/componentActions';
import * as actionTypes from '../actionTypes';
import { logError } from '../../core/helpers';
import { fetchCustomerIfNeeded } from '../customer/customer';

export function* watchAddVehicleContainer() {
  yield* takeEveryByComponentName(actionTypes.COMPONENT_LOAD, 'AddVehicleContainer', handleLoadAddVehicleContainer);
}

export function* handleLoadAddVehicleContainer(action) {
  try {
    yield call(fetchCustomerIfNeeded, action.params.consumerId);
    yield put(componentMount('AddVehicleContainer'));
  } catch (e) {
    logError(e);
    yield put(componentError('AddVehicleContainer', 'Customer not found.', e));
  }
}
