import * as actionTypes from '../actionTypes';
import _ from 'lodash';

const defaultState = {
  Data: [],
  Facets: {
    Unfiltered: []
  }
};

export default function lenderUpdatesReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.FETCH_LENDER_UPDATES:
      return Object.assign({}, state, { isLoading: true });

    case actionTypes.FETCH_LENDER_UPDATES_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          isLoading: false,
          total: action.response.Total
        },
        action.response
      );

    case actionTypes.FETCH_LENDER_UPDATES_ERROR:
      return Object.assign({}, state, { error: action.payload, isLoading: false });

    case actionTypes.CHANGE_DEALERSHIP:
      return _.cloneDeep(defaultState);

    default:
      return state;
  }
}
