import * as actionTypes from '../actionTypes';

export function searchLatestDealsByDate(from, to, timePeriod) {
  return {
    type: actionTypes.FILTER_LATEST_DEALS_BY_DATE,
    from,
    to,
    timePeriod
  };
}

export function setStatusFilter(status) {
  return {
    type: actionTypes.FILTER_LATEST_DEALS_BY_STATUS,
    status
  };
}

export function changePage(page) {
  return {
    type: actionTypes.CHANGE_PAGE_LATEST_DEALS,
    page
  };
}

export function setLatestDealsOptions(options) {
  return {
    type: actionTypes.SET_LATEST_DEALS_OPTIONS,
    options
  };
}

export function resetLatestDeals() {
  return {
    type: actionTypes.RESET_LATEST_DEALS
  };
}
