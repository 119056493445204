import { TFunction } from 'i18next';
import { dropdownOptions, DropdownConfigType, OptionsListType, OptionName, OptionType } from '../../types';

const filterDatePeriodOptions = (option: OptionType, config: DropdownConfigType) => {
  const DAY_OPTION_ID = 1;
  const LAST_30_DAYS_OPTION_ID = 30;

  if (option.id === DAY_OPTION_ID && !config.includeDay) return false;
  if (option.id === LAST_30_DAYS_OPTION_ID && !config.includeLast30Days) return false;
  return true;
};

export const getFormattedDropdownOptions = (
  optionName: keyof OptionsListType,
  config: DropdownConfigType,
  t: TFunction,
  availableLenders?: OptionType[]
): { options: OptionType[] } => {
  if (!config?.enabled) {
    return { options: [] };
  }

  let filteredOptions: OptionType[] = dropdownOptions[optionName] || [];

  if (optionName === OptionName.DatePeriod) {
    filteredOptions = filteredOptions.filter((option) => filterDatePeriodOptions(option, config));
  }

  if (optionName === OptionName.Lender && availableLenders) {
    filteredOptions = [...filteredOptions, ...availableLenders];
  }

  return {
    options: filteredOptions.map((option) => ({
      ...option,
      label: t(option.label, { ns: 'Stock' })
    }))
  };
};
