import React from 'react';
import PropTypes from 'prop-types';
import './cardCell.scss';

const CardCell = ({ children }) => (
  <div className="cardCell__outer" key="outer">
    {children}
  </div>
);

CardCell.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string
};

export default CardCell;
