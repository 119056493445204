import _ from 'lodash';
import { connect } from 'react-redux';
import EditApplicationEmploymentHistory from '../components/EditApplicationEmploymentHistory';
import * as applicationActions from '../../../redux/application/applicationActions';
import { getCustomer } from '../../../selectors/customerSelectors';
import { getLenderConfig } from '../../../core/lenders';
import { push } from '../../../routerHistory';
import { compose } from 'redux';
import { withParams } from 'hocs/router';

function mapStateToProps(state, ownProps) {
  const customer = getCustomer(state, ownProps.params.consumerId);

  return {
    consumer: customer,
    application: state.application,
    vehicle: _.find(customer.VehiclesOfInterest, { VehicleId: state.application.VehicleId }),
    validationRules: getLenderConfig(
      state.application.Quote && state.application.Quote.FunderCode,
      state.application.CustomerType,
      'validationRules'
    ),
    applicationFields: getLenderConfig(
      state.application.Quote && state.application.Quote.FunderCode,
      state.application.CustomerType,
      'applicationFields'
    )
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    handleSubmit: (formData) => {
      const { dealershipId, consumerId, applicantId } = ownProps.params;
      dispatch(
        applicationActions.submitApplicationSection(
          'EmploymentHistory',
          applicantId,
          formData.Items,
          `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit`
        )
      );
    },
    handleBack: () => {
      const { dealershipId, consumerId, applicantId } = ownProps.params;
      push(`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit`);
    }
  };
}

export default compose(withParams, connect(mapStateToProps, mapDispatchToProps))(EditApplicationEmploymentHistory);
