import { types } from '../schema';

export default {
  Funders: [
    {
      BuildingName: types.String,
      BuildingNumber: types.String,
      Code: types.String,
      Country: types.String,
      District: types.String,
      Id: types.String,
      Name: types.String,
      Organisation: types.String,
      PostTown: types.String,
      Postcode: types.String,
      Street: types.String,
      SubBuilding: types.String,
      Disclaimer: types.String
    }
  ]
};
