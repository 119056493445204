import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import LenderUpdatesPage from '../components/LenderUpdatesPage';
import * as lenderUpdatesActions from '../../../redux/lenderUpdates/lenderUpdatesActions';
import { lenderUpdatesSelector } from '../../LenderUpdatesPage/selectors/lenderUpdatesSelectors';
import { compose } from 'redux';
import { withParams, withQuery, withNavigate } from '../../../hocs/router';

class LenderUpdatesContainer extends Component {
  componentDidMount() {
    const { status, activeFilter, page } = this.props.query;
    this.props.fetchLenderUpdates({
      status,
      activeFilter,
      page
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.query, nextProps.query)) {
      const { status, activeFilter, page } = nextProps.query;
      this.props.fetchLenderUpdates({
        status,
        activeFilter,
        page
      });
    }
  }

  handlePageChange = (page) => {
    window.scrollTo(0, 0);
    this.props.navigate({
      pathname: `/d/${this.props.params.dealershipId}/lenderupdatespage`,
      query: {
        ...this.props.query,
        page: page
      }
    });
  };

  handleFilterLenderUpdatesLeads = (activeFilter) => {
    this.props.navigate({
      pathname: `/d/${this.props.params.dealershipId}/lenderupdatespage`,
      query: {
        ...this.props.query,
        activeFilter: activeFilter,
        page: 1
      }
    });
  };

  handleFilterByStatus = (status) => {
    this.props.navigate({
      pathname: `/d/${this.props.params.dealershipId}/lenderupdatespage`,
      query: {
        ...this.props.query,
        status: status,
        page: 1
      }
    });
  };

  render() {
    return (
      <LenderUpdatesPage
        dealershipId={this.props.params.dealershipId}
        changePage={this.handlePageChange}
        filterByStatus={this.handleFilterByStatus}
        filterLenderUpdatesLeads={this.handleFilterLenderUpdatesLeads}
        page={this.props.query.page}
        total={this.props.total}
        pageSize={25}
        activeFilter={this.props.query.activeFilter}
        items={this.props.items}
        facets={this.props.facets}
        options={this.props.options}
        status={this.props.query.status}
      />
    );
  }
}

LenderUpdatesContainer.propTypes = {
  children: PropTypes.any,
  fetchLenderUpdates: PropTypes.func,
  filterLenderUpdates: PropTypes.func,
  location: PropTypes.object,
  items: PropTypes.array,
  total: PropTypes.number,
  facets: PropTypes.array,
  options: PropTypes.array,
  params: PropTypes.object
};

function mapStateToProps(state) {
  return {
    session: state.session,
    isLoading: state.session.isLoading || !state.products.hasInitiallyLoaded,
    options: state.options,
    lenderUpdatesOptions: state.lenderUpdates.options,
    items: lenderUpdatesSelector(state),
    facets: state.lenderUpdates.Facets.Unfiltered,
    total: state.lenderUpdates.total
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchLenderUpdates: ({ status, activeFilter, page }) => {
      dispatch(lenderUpdatesActions.fetchLenderUpdates({ status, activeFilter, page }));
    }
  };
}

export default compose(
  withParams,
  withQuery,
  withNavigate,
  connect(mapStateToProps, mapDispatchToProps)
)(LenderUpdatesContainer);
