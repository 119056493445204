import './loadingDots.scss';

const LoadingDots = () => {
  return (
    <div className="loadingIndicator" data-th="loadingDots">
      <div className="loadingIndicator__segment" />
      <div className="loadingIndicator__segment" />
      <div className="loadingIndicator__segment" />
    </div>
  );
};

export default LoadingDots;
