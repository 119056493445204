import React from 'react';
import PropTypes from 'prop-types';
import './cardInformationItem.scss';

const CardInformationItem = ({ label, value }) => {
  return (
    <div className="cardInformationItem">
      <div className="cardInformationItem__left">{label}</div>
      <div className="cardInformationItem__right">{value}</div>
    </div>
  );
};

CardInformationItem.propTypes = {
  value: PropTypes.any,
  label: PropTypes.node
};

export default CardInformationItem;
