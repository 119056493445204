import _ from 'lodash';
import { createSelector } from 'reselect';
import { getDefaultCustomerType } from '../../../selectors/sessionSelectors';
import { QuoteRequest } from '../../../core/quoteRequest';

export const getQuotesState = (state) => {
  return _.get(state, 'quotes');
};

export const getSortedQuotes = createSelector([getQuotesState], (quotes) => {
  let sortBy = [
    function(quote) {
      return quote.Errors && quote.Errors.length ? true : false;
    },
    quotes.sortField
  ];
  let sortDirection = ['asc', quotes.sortDirection, 'asc'];

  return _.orderBy(quotes.returnedQuotes, sortBy, sortDirection);
});

export const getDefaultQuoteDetails = (state, quote, isQuickQuote) => {
  const customerType = state.quotes.customerType || getDefaultCustomerType(state);
  const quoteRequest = getQuotesState(state).quoteRequest;
  const settings = _.chain(state.session.Dealerships)
    .find({ Id: state.dealership.Id })
    .get('Settings')
    .value();
  return Object.assign(
    {},
    quoteRequest,
    quote
      ? {
          Advance: quote.BalancePayable,
          ActualCashDeposit: quote.ActualCashDeposit || quoteRequest.ActualCashDeposit,
          AnnualDistance: quote.AnnualDistance || quote.AnnualDistanceQuoted || settings.DefaultAnnualMileage
        }
      : {},
    isQuickQuote ? { CustomerType: customerType } : {}
  );
};

export const getFullQuoteDetails = (state) => {
  const quoteRequest = new QuoteRequest(state.quotes.quoteRequest);

  return {
    ...quoteRequest,
    TotalDeposit:
      parseFloat(quoteRequest.CashDeposit) +
      (parseFloat(quoteRequest.PartExchange) || 0) -
      (parseFloat(quoteRequest.OutstandingSettlement) || 0)
  };
};
