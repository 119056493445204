import * as DialogPrimitive from '@radix-ui/react-dialog';
import cn from 'classnames';

import Icon from '~Common/Icon/Icon';

import c from './stockModal.module.scss';

const StockModal = ({ isOpen, onDismiss, title, paragraph, children, dataTh = 'stockModal' }) => {
  return (
    <DialogPrimitive.Root open={isOpen} onOpenChange={onDismiss}>
      <DialogPrimitive.Overlay
        className={cn(c.overlay, {
          [c.overlayOpen]: isOpen
        })}
      >
        <DialogPrimitive.Content
          data-th={dataTh}
          className={cn(c.dialog, {
            [c.dialogOpen]: isOpen
          })}
          aria-labelledby={title}
        >
          {title && (
            <div className={c.header}>
              <Icon className={c.icon} name="alert" /> <h2 className={c.title}>{title}</h2>
            </div>
          )}
          <button data-th="closeModalButton" className={c.closeCross} onClick={onDismiss}>
            <Icon name="cross-2" />
          </button>

          {paragraph && <div className={c.content}>{paragraph}</div>}
          {children}
        </DialogPrimitive.Content>
      </DialogPrimitive.Overlay>
    </DialogPrimitive.Root>
  );
};

export default StockModal;
