import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import ApplicationPage from './ApplicationPage';
import EmploymentHistoryForm from '../forms/EmploymentHistoryForm';
import ConsumerVehicleInfoPanel from '../../Common/ConsumerVehicleInfoPanel';
import { compose } from 'redux';
import { withOutletContextProps, withParams } from 'hocs/router';

const EditApplicationEmploymentHistory = ({
  t,
  params,
  consumer,
  vehicle,
  applicationFields,
  application,
  handleSubmit,
  handleBack,
  validationRules
}) => {
  return (
    <Page>
      <Breadcrumbs
        items={[
          {
            name: t('EditApplicationEmploymentHistory.home'),
            path: `/d/${params.dealershipId}`
          },
          {
            name: t('EditApplicationEmploymentHistory.customer_list'),
            path: `/d/${params.dealershipId}/consumers`
          },
          {
            name: t('EditApplicationEmploymentHistory.consumer'),
            path: `/d/${params.dealershipId}/consumers/${params.consumerId}`
          },
          {
            name: t('EditApplicationEmploymentHistory.application_status'),
            path: `/d/${params.dealershipId}/consumers/${params.consumerId}/application/${params.applicantId}/status`
          },
          {
            name: t('EditApplicationEmploymentHistory.edit_application'),
            path: `/d/${params.dealershipId}/consumers/${params.consumerId}/application/${params.applicantId}/edit`
          },
          {
            name: t('EditApplicationEmploymentHistory.edit_employment_history')
          }
        ]}
        consumer={consumer}
      />

      <ConsumerVehicleInfoPanel vehicle={vehicle} />

      <Panel>
        <PanelHeader>{t('EditApplicationEmploymentHistory.edit_application')}</PanelHeader>
        <ApplicationPage title={t('EditApplicationEmploymentHistory.employment_history')}>
          <EmploymentHistoryForm
            applicationFields={applicationFields.EmploymentHistory}
            defaultValues={application.EmploymentHistory}
            application={application}
            onSubmit={handleSubmit}
            onCancel={handleBack}
            validationRules={validationRules.EmploymentHistory}
            submittingState={application.sectionSubmittingState}
            dealershipId={params.dealershipId}
            quoteId={application.QuoteId}
            quote={application.Quote}
          />
        </ApplicationPage>
      </Panel>
    </Page>
  );
};

EditApplicationEmploymentHistory.propTypes = {
  params: PropTypes.object,
  application: PropTypes.object,
  validationRules: PropTypes.object,
  consumer: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleBack: PropTypes.func,
  vehicle: PropTypes.object
};

export default compose(
  withTranslation('Application'),
  withParams,
  withOutletContextProps
)(EditApplicationEmploymentHistory);
