import React from 'react';
import { inject } from 'mobx-react';

import { StockCreateContainer } from './components';
import { useNavigate, useParams } from 'react-router-dom';

const StockCreateWrapper = (props) => {
  const { miscVehicleData } = props.appStore.stockCreateStore;
  const { condition, lookupTerm } = miscVehicleData;
  const hasVehicleData = !!condition && !!lookupTerm;
  const navigate = useNavigate();
  const params = useParams();

  if (hasVehicleData) {
    return <StockCreateContainer />;
  } else {
    navigate(`/d/${params.dealershipId}/stock/add/lookup`, {
      replace: true
    });
    return null;
  }
};

export default inject('appStore')(StockCreateWrapper);
