import React from 'react';
import PropTypes from 'prop-types';
import './quoteInfoLabel.scss';

const QuoteInfoLabel = ({ children }) => {
  return <span className="quoteInfoLabel">{children}</span>;
};

QuoteInfoLabel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default QuoteInfoLabel;
