import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './dashboardFilterButton.scss';
const DashboardFilterButton = ({ isActive, handleClick, filterType, children }) => {
  const onClick = () => {
    handleClick(filterType !== undefined && filterType);
  };
  const classBtn = classnames('dashboardFilter__filter', isActive && 'dashboardFilter__filter--active');
  return (
    <button className={classBtn} onClick={onClick}>
      {children}
      <div className={`dashboardFilter__bar dashboardFilter__bar--${filterType}`} />
    </button>
  );
};
DashboardFilterButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  filterType: PropTypes.string,
  children: PropTypes.node
};
export default DashboardFilterButton;
