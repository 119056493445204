import React from 'react';
import PropTypes from 'prop-types';
import './quoteFigureItem.scss';

const QuoteFigureItem = ({ children }) => (
  <div className="quoteFigureItem" data-th={children.length > 0 ? children[0].props.children : ''}>
    <div className="quoteFigureItem__label">{children.length > 0 && children[0]}</div>
    <div className="quoteFigureItem__value">{children.length > 1 && children[1]}</div>
  </div>
);

QuoteFigureItem.propTypes = {
  children: PropTypes.any
};

export default QuoteFigureItem;
