import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getPageTags, PageName } from 'core/pageTracking';
import Avo from '../lib/Avo';

export const usePageTracking = (pageName: PageName) => {
  const { dealershipId } = useParams<{
    dealershipId: string | undefined;
  }>();

  useEffect(() => {
    if (!pageName) return; // Page not yet tagged

    Avo.pageView({
      customDestinationPageName_: pageName,
      pageTags: [...getPageTags(pageName)],
      dealershipId: dealershipId || undefined,
      vehicleVrm: undefined,
      vehicleVin: undefined,
      vehicleRmid: undefined,
      financeFunderCode: undefined,
      financeQuoteId: undefined,
      consumerId: undefined,
      pageProperties: undefined,
      vehicleImageUrl: undefined,
      quoteeId: undefined,
      errorMessage: undefined,
      ivendiVehicleId: undefined
    });
  }, [pageName, dealershipId]);
};

const TrackedPage: FC<{
  children?: React.ReactNode;
  pageName: PageName;
  // @ts-ignore
}> = ({ children, pageName }) => {
  usePageTracking(pageName);

  return <>{children}</>;
};

export default TrackedPage;
