import { connect } from 'react-redux';
import * as consumerActions from '../../../redux/consumer/consumerActions';
import ErrorApology from '../../Common/ErrorApology';
import CfcCheckDetails from '../CfcCheckDetails';
import { getCustomer } from '../../../selectors/customerSelectors';
import { compose } from 'redux';
import { withOutletContextProps, withParams } from 'hocs/router';
import { useQuery } from '@tanstack/react-query';
import { cfcCheckDetailsQueries } from '~/api/cfcCheckDetails/hooks';

const CfcCheckDetailsContainer = (props) => {
  const { isLoading, isError, data } = useQuery(
    cfcCheckDetailsQueries.details({ cfcProspectId: props.params.cfcProspectId, quoteId: props.params.quoteId })
  );

  if (isError) {
    return <ErrorApology>Failed to fetch CFC Check</ErrorApology>;
  }

  if (isLoading) {
    return null;
  }

  return <CfcCheckDetails {...props} cfcCheckDetails={data} />;
};

function mapStateToProps(state, ownProps) {
  const customer = getCustomer(state, ownProps.params.consumerId);
  return {
    customer: customer,
    dealership: state.dealership,
    applicationLoadingState: state.ui.customer.createApplicantLoadingState,
    assignees: customer ? customer.assignedTo : []
  };
}

function mapDispatchToProps(dispatch) {
  return {
    applyForCfcCheck: (prospectId, quoteId, quote, dealershipId, consumerId) =>
      dispatch(consumerActions.createApplicantFromCfcProspect(prospectId, quoteId, quote, dealershipId, consumerId))
  };
}

export default compose(
  withOutletContextProps,
  withParams,
  connect(mapStateToProps, mapDispatchToProps)
)(CfcCheckDetailsContainer);
