import React from 'react';
import PropTypes from 'prop-types';
import ConsumerVehicleImage from './ConsumerVehicleImage';
import VehicleDetailsInformation from './VehicleDetailsInformation';
import { setUrlAsCloudfront } from '../../../../core/helpers';
import './vehicleDetails.scss';

const VehicleDetails = ({ vehicle, consumer, latestPrice, deal }) => {
  const vehicleImage = vehicle.CdnVehicleImageUrl
    ? `${setUrlAsCloudfront(vehicle.CdnVehicleImageUrl)}?width=200&scale=both&format=webp`
    : deal?.vehicleImageUrls?.[0];

  return (
    <div className="vehicleDetails__outer" data-th="VehicleDetails">
      <div>
        <ConsumerVehicleImage vehicleImage={vehicleImage} vehicleClass={vehicle.Class} />
      </div>
      <div className="vehicleDetails__info">
        <VehicleDetailsInformation consumer={consumer} vehicle={vehicle} latestPrice={latestPrice} />
      </div>
    </div>
  );
};

VehicleDetails.propTypes = {
  vehicle: PropTypes.object,
  consumer: PropTypes.object
};

export default VehicleDetails;
