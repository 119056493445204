import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import EditApplicationBankDetails from '../components/EditApplicationBankDetails';
import * as applicationActions from '../../../redux/application/applicationActions';
import { getCustomer } from '../../../selectors/customerSelectors';
import { getLenderConfig } from '../../../core/lenders';
import { compose } from 'redux';
import { withParams } from 'hocs/router';
import { push } from '../../../routerHistory';

const EditApplicationBankDetailsContainer = (props) => {
  const { children, ...remainingProps } = props;

  return <EditApplicationBankDetails {...remainingProps} />;
};

EditApplicationBankDetailsContainer.propTypes = {
  children: PropTypes.node,
  consumer: PropTypes.object,
  application: PropTypes.object,
  vehicle: PropTypes.object,
  validationRules: PropTypes.object,
  applicationFields: PropTypes.any
};

function mapStateToProps(state, ownProps) {
  const customer = getCustomer(state, ownProps.params.consumerId);
  return {
    consumer: customer,
    application: state.application,
    vehicle: _.find(customer.VehiclesOfInterest, { VehicleId: state.application.DealershipConsumerVehicleId }),
    validationRules: getLenderConfig(
      state.application.Quote && state.application.Quote.FunderCode,
      state.application.CustomerType,
      'validationRules'
    ),
    applicationFields: getLenderConfig(
      state.application.Quote && state.application.Quote.FunderCode,
      state.application.CustomerType,
      'applicationFields'
    )
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    handleSubmit: (formData) => {
      const { dealershipId, consumerId, applicantId } = ownProps.params;

      dispatch(
        applicationActions.submitBankDetails(
          formData,
          `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit`
        )
      );
    },
    handleBack: () => {
      const { dealershipId, consumerId, applicantId } = ownProps.params;
      push(`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit`);
    }
  };
}

export default compose(withParams, connect(mapStateToProps, mapDispatchToProps))(EditApplicationBankDetailsContainer);
