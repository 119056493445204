import PropTypes from 'prop-types';
import './miniPanel.scss';

const MiniPanel = ({ children, ...props }) => (
  <div className="miniPanel" {...props}>
    {children}
  </div>
);

MiniPanel.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any
};

export default MiniPanel;
