import React from 'react';
import PropTypes from 'prop-types';
import './webFinanceApplicationsItem.scss';
import DashboardTableItem from './DashboardTableItem';

const WebFinanceApplicationsItem = ({ application, handleClick }) => {
  const onClick = () => {
    const idType =
      application.DealershipConsumer && application.DealershipConsumer.Id
        ? application.DealershipConsumer.Id
        : application.CorporateCustomer.Id;
    handleClick(idType);
  };

  return (
    <div className="webFinanceApplicationsItem" key="cont" onClick={onClick}>
      <DashboardTableItem data={application} />
    </div>
  );
};

WebFinanceApplicationsItem.propTypes = {
  application: PropTypes.object,
  dealership: PropTypes.object,
  handleClick: PropTypes.func
};

export default WebFinanceApplicationsItem;
