import * as actionTypes from '../actionTypes';

const defaultState = [];

export default function fundersReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.FETCH_FUNDERS_INFO_SUCCESS:
      return action.response && action.response.Funders;

    default:
      return state;
  }
}
