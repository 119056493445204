/**
 * Returns Human-readable label `financeTypeName`
 * and video shortcode `wistiaSource` when supplied with a `financeType` from the expected strings
 * @param {string} financeType two character finance type code from pre-defined set.
 * @param {boolean} [isCreation=false] returns different result for certain cases if `isCreation === true`
 * @default
 * {financeTypeName:'Hire Purchase',wistiaSource:'hirepurchase'}
 * @example
 *
 * getFinanceTypeWistia('HP');
 * // => {financeTypeName:'Hire Purchase', wistiaSource:'hirepurchase'}
 * getFinanceTypeWistia('FS',false);
 * // => {financeTypeName:'Personal Loan', wistiaSource:''}
 * getFinanceTypeWistia('FS');
 * // => {financeTypeName:'Personal Loan', wistiaSource:''}
 * getFinanceTypeWistia('FS',true);
 * // => {financeTypeName:'Creation Personal Loan', wistiaSource:''}
 */
export function getFinanceTypeWistia(financeType, isCreation = false) {
  let financeTypeName;
  let wistiaSource;
  if (financeType === (null || undefined || '')) {
    throw TypeError('financeType is a required param');
  }
  switch (financeType) {
    case 'HP':
      financeTypeName = 'Hire Purchase';
      wistiaSource = 'hirepurchase';
      break;
    case 'CS':
      financeTypeName = 'Conditional Sale';
      wistiaSource = 'hirepurchase';
      break;
    case 'PCP':
      financeTypeName = 'Personal Contract Purchase';
      wistiaSource = 'pcpurchase';
      break;
    case 'LP':
      financeTypeName = 'Lease Purchase';
      wistiaSource = 'lpplan';
      break;
    case 'ML':
      financeTypeName = 'Motor Loan';
      wistiaSource = 'hirepurchase';
      break;
    case 'FS':
      financeTypeName = isCreation ? 'Creation Personal Loan' : 'Personal Loan';
      wistiaSource = '';
      break;
    default:
      financeTypeName = 'Hire Purchase';
      wistiaSource = 'hirepurchase';
      break;
  }
  return { financeTypeName, wistiaSource };
}
