import Avo from 'lib/Avo';

const env = (() => {
  if (process.env.REACT_APP_ENVIRONMENT === 'production' || process.env.REACT_APP_ENVIRONMENT === 'beta') {
    return 'production';
  }

  if (process.env.REACT_APP_ENVIRONMENT === 'demo' || process.env.REACT_APP_ENVIRONMENT === 'uat') {
    return 'uat';
  }

  return 'development';
})();

const avoEnv = (() => {
  if (env === 'production') {
    return Avo.AvoEnv.Prod;
  }

  if (env === 'uat') {
    return Avo.AvoEnv.Staging;
  }

  return Avo.AvoEnv.Dev;
})();

export { env, avoEnv };
