import _ from 'lodash';
import flat from 'flat';
import { detailedDiff } from 'deep-object-diff';
import * as debug from '~/debug';

export const types = {
  Array: <T extends any>(data: T) => (Array.isArray(data) ? data : []),
  Object: <T extends any>(data: T) => (typeof data === 'object' && data !== null ? data : {}),
  String: <T extends any>(data: T) => {
    if (typeof data === 'object' && data !== null) {
      throw new TypeError('Unexpected object');
    }
    // eslint-disable-next-line no-new-wrappers
    return data === null || typeof data === 'undefined' ? '' : new String(data).valueOf();
  },
  Number: <T extends any>(data: T) =>
    // eslint-disable-next-line no-new-wrappers
    data === null || typeof data === 'undefined' ? null : new Number(data).valueOf(),
  Boolean: <T extends any>(data: T) => {
    if (data === null || data === undefined) {
      return data;
    }
    if (data === 'true') {
      return true;
    }

    if (data === 'false') {
      return false;
    }
    // eslint-disable-next-line no-new-wrappers
    return new Boolean(data).valueOf();
  },
  NullableBoolean: (data: any) => {
    if (data === null || data === undefined) {
      return null;
    }

    if (data === 'true') {
      return true;
    }

    if (data === 'false') {
      return false;
    }
    // eslint-disable-next-line no-new-wrappers
    return new Boolean(data).valueOf();
  }
};
/*eslint-disable*/

export function schemaMap(data: unknown, schema: any, path: string[] = []): any {
  const schemaPath = path.map((pathItem) => pathItem.replace(/^\d+$/g, '0'));
  const schemaData = path.length === 0 ? schema : _.get(schema, schemaPath);

  if (typeof schemaData === 'object' && !Array.isArray(schemaData)) {
    let newObject = {};
    _.forEach(schemaData, (item, key) => {
      _.set(newObject, key, schemaMap(data, schema, path.concat([key])));
    });

    return newObject;
  } else if (Array.isArray(schemaData)) {
    return _.map(path.length === 0 ? data : _.get(data, path), (item, index) =>
      schemaMap(data, schema, path.concat([index.toString()]))
    );
  } else {
    return schemaData(_.get(data, path));
  }
}

import { z, ZodRawShape, ZodTypeAny } from 'zod';

export const StringSchema = z.preprocess(types.String, z.string().nullable());

export const BooleanSchema = z.preprocess(types.Boolean, z.boolean().nullish());
export const BooleanSchemaNullable = z.preprocess(
  types.NullableBoolean,
  z
    .boolean()
    .nullable()
    .default(null)
);

export const ArraySchema = <S extends ZodTypeAny>(schema?: S) =>
  z.preprocess(types.Array, z.array(schema || z.any()).optional());

export const ObjectSchema = <S extends ZodRawShape>(schemaShape: S) => {
  return z.preprocess(types.Object, z.object(schemaShape).optional());
};

export const NumberSchema = z.preprocess(types.Number, z.number().nullable());

const definedDifferences = (data: any, keys: string[]) => {
  return keys.filter((key) => data[key] !== undefined);
};

const calculateDiff = (mapped: any, parsed: any) => {
  const diffResult = detailedDiff(mapped, parsed);

  const added = definedDifferences(mapped, Object.keys(flat(diffResult.added)));
  const deleted = definedDifferences(mapped, Object.keys(flat(diffResult.deleted)));
  const updated = definedDifferences(mapped, Object.keys(flat(diffResult.updated)));
  const hasDiff = added.length > 0 || deleted.length > 0 || updated.length > 0;

  return { added, deleted, updated, hasDiff };
};

export const schemaParse = <S extends ZodTypeAny>(name: string, response: unknown, zSchema: S, schema: any) => {
  const parsed = zSchema.safeParse(response);
  const mapped = schemaMap(response, schema);

  if (!parsed.success) {
    debug.log({
      level: 'Error',
      error: 'ZodParseError',
      name,
      errorMap: parsed.error.flatten()?.fieldErrors // Check this doesn't log PII
    });

    return mapped as z.output<typeof zSchema>;
  }

  const { hasDiff, added, deleted, updated } = calculateDiff(mapped, parsed.data);

  if (hasDiff) {
    debug.log({
      level: 'Error',
      error: 'ZodParseMismatch',
      name,
      diff: { added, deleted, updated }
    });
  }

  if (!hasDiff && parsed.success && process.env.NODE_ENV === 'development') {
    console.debug('ZodParseSuccess:', name);
  }

  return mapped as z.output<typeof zSchema>;
};
