import { FC, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { initialize } from '../redux/app/appActions';
import ErrorBoundary from '../components/Errors/ErrorBoundary';
import i18n from '../i18n';
import * as rudderanalytics from 'rudder-sdk-js';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { Outlet, useParams } from 'react-router-dom';

const propTypes = {
  initializeApp: PropTypes.func.isRequired,
  growthbook: PropTypes.any,
  session: PropTypes.shape({
    UserId: PropTypes.string,
    Email: PropTypes.string
  }).isRequired
};

const mapStateToProps = (state: any) => ({
  session: state.session
});

const mapDispatchToProps = {
  initializeApp: (dealershipId: string) => initialize(dealershipId)
};

type Props = FC<PropTypes.InferProps<typeof propTypes> & typeof mapDispatchToProps>;

const AppContainer: Props = (props) => {
  const { initializeApp, session } = props;
  const { dealershipId } = useParams<{
    dealershipId: string | undefined;
  }>();
  const growthbook = useGrowthBook();

  useEffect(() => {
    const init = () => {
      initializeApp(dealershipId);
    };

    init();

    i18n.on('languageChanged', () => {
      init();
    });

    return () => {
      i18n.off('languageChanged', init);
    };
  }, [dealershipId, initializeApp]);

  useEffect(() => {
    if (session.UserId !== '' && session.Email !== '' && dealershipId) {
      // @ts-ignore
      rudderanalytics.identify(session.UserId, {
        email: session.Email,
        dealership_id: dealershipId
      });
    }
  }, [session.UserId, session.Email, dealershipId]);

  useEffect(() => {
    // Load features asynchronously when the app renders
    if (growthbook) {
      growthbook.loadFeatures();
      growthbook.refreshFeatures();
      growthbook.setAttributes({
        url: window.location.href,
        userAgent: navigator.userAgent,
        dealershipId: dealershipId
      });
    }
  }, [growthbook, dealershipId]);

  return (
    <ErrorBoundary>
      <div>
        <Outlet />
      </div>
    </ErrorBoundary>
  );
};

AppContainer.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
