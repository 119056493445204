export type Address = {
  CompanyName?: string;
  Organisation?: string;
  Company?: string;
  SubBuilding?: string;
  BuildingName?: string;
  BuildingNumber?: string;
  Street?: string;
  District?: string;
  PostalTown?: string;
  PostTown?: string;
  Postcode?: string;
  Address1?: string;
  Address2?: string;
};

export const isSet = (value: string | undefined): boolean => {
  return Boolean(value?.trim() && typeof value === 'string');
};

export const getFormattedAddress = (address: Address | null = {}): string => {
  if (address === null) {
    return '';
  }

  const addressParts =
    address.Address1 || address.Address2
      ? [address.Address1, address.Address2]
      : [
          address.SubBuilding,
          address.BuildingName,
          `${address.BuildingNumber || ''} ${address.Street || ''}`,
          address.District
        ];

  let parts = [
    address.CompanyName || address.Organisation || address.Company,
    ...addressParts,
    address.PostalTown || address.PostTown
  ]
    .filter(isSet)
    .map((v) => v?.trim()?.toLowerCase());

  if (isSet(address?.Postcode)) {
    parts.push(address.Postcode);
  }

  return parts.length > 0 ? parts.join(', ') : '';
};
