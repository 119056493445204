import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  draft.applicationFields.AffordabilityDetails!.ForeseeFutureDownturnInFinances = false;
  draft.applicationFields.AffordabilityDetails!.DownturnReason = false;
  draft.applicationFields.AffordabilityDetails!.IsReplacementLoan = false;
  draft.applicationFields.AffordabilityDetails!.DetailsOfReplacementLoan = false;
  draft.applicationFields.AffordabilityDetails!.MortgageOrRental = true;

  delete draft.validationRules.AffordabilityDetails!.PersonalCircumstances;
  delete draft.validationRules.AffordabilityDetails!.ReplacementLoan;
  draft.validationRules.AffordabilityDetails!.MonthlyExpenditure!.MortgageOrRental = 'currency, required, max:999999';
});
