import VehicleStatus from './VehicleStatus';
import { observable } from 'mobx';

class Vehicle {
  @observable Class;
  @observable Condition = 'used';
  @observable Make;
  @observable SMake;
  @observable Model;
  @observable SModel;
  @observable Derivative;
  @observable SDerivative;
  @observable Id;
  @observable Vrm;
  @observable Vin;
  @observable Mileage;
  @observable RegistrationDate;
  @observable Status = {};
  @observable CdnVehicleImageUrl;

  updateFromJSON(json = {}) {
    this.Make = json.Make || json.SuppliedMake;
    this.Model = json.Model || json.SuppliedModel;
    this.Derivative = json.Derivative || json.SuppliedDerivative;
    this.CapId = json.CapId;
    this.Vrm = json.Vrm;
    this.Vin = json.Vin;
    this.Id = json.VehicleId;
    this.Class = json.Class;
    this.Mileage = json.Mileage;
    this.RegistrationDate = json.RegistrationDate;
    this.Condition = json.Condition;
    this.CdnVehicleImageUrl = json.CdnVehicleImageUrl;

    const status = json && json.Status ? new VehicleStatus(json.Status) : null;
    if (status) {
      status.updateFromJSON(json.Status);
      this.Status = status;
    }
  }
}

export default Vehicle;
