import * as actionTypes from '../actionTypes';

const initialState = {
  globalHasLoaded: true
};

const componentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COMPONENT_LOAD:
      return Object.assign({}, state, { [action.componentName]: { hasLoaded: false }, globalHasLoaded: false });

    case actionTypes.COMPONENT_UNLOAD:
      return Object.assign({}, state, { [action.componentName]: { hasLoaded: false } });

    case actionTypes.COMPONENT_MOUNT:
      return Object.assign({}, state, { [action.componentName]: { hasLoaded: true }, globalHasLoaded: true });

    case actionTypes.COMPONENT_UNMOUNT:
      return Object.assign({}, state, { [action.componentName]: { hasLoaded: false } });

    case actionTypes.COMPONENT_ERROR:
      return Object.assign({}, state, {
        [action.componentName]: { hasLoaded: true, errorMessage: action.errorMessage, error: action.error },
        globalHasLoaded: true
      });
    default:
      return state;
  }
};

export default componentsReducer;
