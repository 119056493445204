import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EligibilityResultsModal from '../../Quoting/modals/EligibilityResultModal';
import * as modalActions from '../../../redux/modal/modalActions';

const EligibilityResultsModalContainer = ({ closeModal, matchrate, rules, isOpen }) => {
  return <EligibilityResultsModal close={closeModal} matchrate={matchrate} rules={rules} isOpen={isOpen} />;
};

EligibilityResultsModalContainer.propTypes = {
  closeModal: PropTypes.func,
  matchrate: PropTypes.number,
  rules: PropTypes.array,
  isOpen: PropTypes.bool
};

function mapStateToProps(state) {
  let params = state.modals.eligibilityModal || {};

  return {
    matchrate: params.matchRate,
    rules: params.decisionMessages,
    isOpen: state.modals.eligibilityModal ? state.modals.eligibilityModal.isOpen : false
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(modalActions.close('eligibilityModal'));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityResultsModalContainer);
