import React from 'react';
import PropTypes from 'prop-types';
import './wordBreak.scss';

const WordBreak = ({ children }) => <div className="wordBreak">{children}</div>;

WordBreak.propTypes = {
  children: PropTypes.node
};

export default WordBreak;
