import React from 'react';
import PropTypes from 'prop-types';
import './leagueTableHeader.scss';

const LeagueTableHeader = (props) => (
  <thead className="leagueTableHeader">
    <tr className="leagueTableHeader__tr">
      {React.Children.toArray(props.children).map((child) =>
        React.cloneElement(child, {
          onClick: props.onClick,
          arrowDirection: props.arrowDirection,
          sortField: props.sortField
        })
      )}
    </tr>
  </thead>
);

LeagueTableHeader.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  arrowDirection: PropTypes.string,
  sortField: PropTypes.string
};

export default LeagueTableHeader;
