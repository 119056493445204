export const getStatus = (error: any) => {
  if (error instanceof Response) {
    return error.status;
  }

  if (!isNaN(Number(error?.status))) {
    return Number(error.status);
  }

  return null;
};
