import { observable } from 'mobx';

class ValueAddedProduct {
  @observable Description;
  @observable Id;
  @observable Name;
  @observable Price;
  @observable ProductTypeCode;
  @observable ProductTypeText;
  @observable TaxTypeCode;

  updateFromJSON(json = {}) {
    this.Description = json.Description || json.description;
    this.Id = json.VapId || json.vapId;
    this.Name = json.Name || json.name;
    this.Price = json.Price || json.price;
    this.ProductTypeCode = json.ProductTypeCode || json.productTypeCode;
    this.ProductTypeText = json.ProductTypeText || json.productTypeText;
    this.TaxTypeCode = json.TaxTypeCode || json.taxTypeCode;
  }
}

export default ValueAddedProduct;
