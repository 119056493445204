import React from 'react';
import PropTypes from 'prop-types';

const LeagueTableBody = ({ children }) => <tbody>{children}</tbody>;

LeagueTableBody.propTypes = {
  children: PropTypes.node
};

export default LeagueTableBody;
