import React from 'react';
import PropTypes from 'prop-types';
import './largeMenuButtonWrapper.scss';

const LargeMenuButtonWrapper = ({ children }) => {
  return (
    <div className="largeMenuButtonWrapper">
      <div className="largeMenuButtonWrapper__inner" key="inner">
        {children}
      </div>
    </div>
  );
};

LargeMenuButtonWrapper.propTypes = {
  children: PropTypes.node
};

export default LargeMenuButtonWrapper;
