import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

export default function(WrappedComponent) {
  @observer
  class WithFormState extends React.Component {
    render() {
      return (
        <WrappedComponent
          isFormSubmitted={this.context.form.isSubmitted}
          resetFormState={this.context.form.resetFormState}
          {...this.props}
        />
      );
    }
  }

  WithFormState.contextTypes = {
    form: PropTypes.object
  };

  return WithFormState;
}
