import React from 'react';
import DetailsTableItem from './DetailsTableItem';
import cn from 'classnames';
import { DisplayQuoteFormat, FormattedQuoteKey, LoanType } from '../../types';
import './detailsTable.scss';

interface DetailsTableProps {
  data: DisplayQuoteFormat;
  title: string;
  loanType: LoanType;
  variant?: 'light' | 'dark';
  isSingleColumn?: boolean;
}

const DetailsTable: React.FC<DetailsTableProps> = ({
  data,
  title,
  loanType,
  variant = 'light',
  isSingleColumn = false
}) => {
  const formattedTableItems = (Object.keys(data) as FormattedQuoteKey[])
    .filter((label) => data[label].value !== undefined)
    .map((label, index) => (
      <DetailsTableItem label={label} value={data[label]} key={`${index}-${label}-${data[label].value}`} />
    ));

  const tableClassName = cn('detailsTable__container', {
    'detailsTable__container--dark': variant === 'dark',
    'detailsTable__container--light': variant === 'light',
    'detailsTable__container--single-column': isSingleColumn
  });

  return (
    <div className={tableClassName} data-th={`${loanType}LoanSection`}>
      <header className="detailsTable__tableHeader" id="collapsible-table-title">
        <h1>
          <span>{title}</span>
        </h1>
      </header>
      <dl>{formattedTableItems}</dl>
    </div>
  );
};

export default DetailsTable;
