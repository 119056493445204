import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import CloseDealOptionsButtonItem from './CloseDealOptionsButtonItem';
import { useParams } from 'react-router-dom';
import './closeDealOptionsButtons.scss';

const CloseDealOptionsButtons = ({ section, appStore }) => {
  const { t } = useTranslation('CloseDeal');
  const params = useParams();
  return (
    <div className="closeDealOptionsButtons__outer">
      <div className="closeDealOptionsButtons__header">{t('CloseDealOptionsButtons.select_a_deal_close_out_type')}</div>
      <CloseDealOptionsButtonItem
        text={t('CloseDealOptionsButtons.sold_finance')}
        isActive={section === 'soldfinance'}
        to={`/d/${params.dealershipId}/consumers/${params.consumerId}/vehicle/${params.vehicleId}/close/soldfinance`}
      />
      <CloseDealOptionsButtonItem
        text={t('CloseDealOptionsButtons.sold_cash')}
        isActive={section === 'soldcash'}
        to={`/d/${params.dealershipId}/consumers/${params.consumerId}/vehicle/${params.vehicleId}/close/soldcash`}
      />
      <CloseDealOptionsButtonItem
        text={t('CloseDealOptionsButtons.vehicle_not_available')}
        isActive={section === 'notavailable'}
        to={`/d/${params.dealershipId}/consumers/${params.consumerId}/vehicle/${params.vehicleId}/close/notavailable`}
      />
      <CloseDealOptionsButtonItem
        text={t('CloseDealOptionsButtons.lost_deal')}
        isActive={section === 'notofinterest'}
        to={`/d/${params.dealershipId}/consumers/${params.consumerId}/vehicle/${params.vehicleId}/close/notofinterest`}
      />
    </div>
  );
};

CloseDealOptionsButtons.propTypes = {
  section: PropTypes.string,
  params: PropTypes.object,
  appStore: PropTypes.object
};

export default inject('appStore')(CloseDealOptionsButtons);
