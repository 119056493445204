import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import QuickQuoteConsumerDetailsForm from '../forms/QuickQuoteConsumerDetailsForm';
import Modal from '../../Common/Modal/Modal';
import { connect } from 'react-redux';
import * as modalActions from '../../../redux/modal/modalActions';
import './saveQuickQuoteModal.scss';

const SaveQuickQuoteModal = (props) => {
  const { t } = useTranslation('QuickQuote');
  return (
    <Modal isOpen={props.isOpen} onClose={props.closeModal}>
      <div data-th="QuickQuoteConsumerDetailsForm">
        <h1 className="saveQuickQuoteModal__header">{t('SaveQuickQuoteModal.save_quote_to_customer')}</h1>
        <QuickQuoteConsumerDetailsForm
          submitLabel={t('SaveQuickQuoteModal.save')}
          existingCustomerActionLabel={t('SaveQuickQuoteModal.save_quote_to_this_customer')}
          onSave={props.submit}
          onCancel={props.closeModal}
          submittingState={props.submittingState}
          submittingExistingCustomerState={props.submittingExistingCustomerState}
          submitExistingCustomer={props.submitExistingCustomer}
          dealershipId={props.dealershipId}
          quote={props.quote}
        />
      </div>
    </Modal>
  );
};

SaveQuickQuoteModal.propTypes = {
  submit: PropTypes.func,
  submitExistingCustomer: PropTypes.func,
  cancel: PropTypes.func,
  submittingState: PropTypes.string,
  submittingExistingCustomerState: PropTypes.string,
  existingCustomer: PropTypes.object,
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  dealershipId: PropTypes.string,
  quote: PropTypes.object
};

function mapStateToProps(state) {
  return {
    isOpen: state.modals.saveQuickQuote ? state.modals.saveQuickQuote.isOpen : false
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(modalActions.close('saveQuickQuote'));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveQuickQuoteModal);
