import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import SelectInput from '../../Common/Form/SelectInput';
import FormFooter from '../../Common/Form/FormFooter';
import TradingNameLookup from '../../Common/TradingNameLookup';
import EmailLookup from '../../Common/EmailLookup';
import CheckboxLabel from '../../Common/Form/CheckboxLabel';
import Checkbox from '../../Common/Form/Checkbox';
import './saveCorporateQuickQuoteForm.scss';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFormFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import TextInputWrappingInput from '../../Common/Form/TextInputWrappingInput';
import { observer, inject } from 'mobx-react';
import { push } from '../../../routerHistory';

class SaveCorporateQuickQuoteForm extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.appStore.quickQuoteStore.startNewCorporateQuickQuote(
      this.props.dealershipId,
      this.props.vehicle,
      this.props.quote
    );
  }

  componentWillUnmount() {
    this.props.appStore.quickQuoteStore.tearDown();
  }

  handleSubmit = () => {
    const {
      appStore: { quickQuoteStore }
    } = this.props;

    if (quickQuoteStore.corporateQuickQuoteValidator.isValid) {
      quickQuoteStore.saveCorporateQuickQuote().then((response) => {
        this.props.closeModal();
        push(`/d/${this.props.dealershipId}/consumers/${response.CustomerId}`);
      });
    }
  };

  render() {
    const {
      appStore: { quickQuoteStore }
    } = this.props;
    const errors = quickQuoteStore.corporateQuickQuoteValidator.getErrors();
    const quickQuote = quickQuoteStore.corporateQuickQuote;
    return (
      <MobxForm onSubmit={this.handleSubmit} focusOnFirstElement>
        <MobxFormFieldGroup error={errors.tradingName}>
          <MobxFormLabel htmlFor="tradingName">
            {this.props.t('SaveCorporateQuickQuoteForm.trading_name')}
          </MobxFormLabel>
          <TradingNameLookup
            dealershipId={this.props.dealershipId}
            onChange={quickQuote.setTradingName}
            value={quickQuote.tradingName}
          />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.title}>
          <MobxFormLabel htmlFor="title">{this.props.t('SaveCorporateQuickQuoteForm.title')}</MobxFormLabel>
          <SelectInput
            id="title"
            value={quickQuote.title}
            onChange={(id, title) => quickQuote.setTitle(title)}
            options="Title"
          />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.firstName}>
          <MobxFormLabel htmlFor="firstName">{this.props.t('SaveCorporateQuickQuoteForm.first_name')}</MobxFormLabel>
          <TextInputWrappingInput id="firstName" value={quickQuote.firstName} onChange={quickQuote.setFirstName} />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.middleName}>
          <MobxFormLabel htmlFor="middleName">{this.props.t('SaveCorporateQuickQuoteForm.middle_name')}</MobxFormLabel>
          <TextInputWrappingInput id="middleName" value={quickQuote.middleName} onChange={quickQuote.setMiddleName} />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.lastName}>
          <MobxFormLabel htmlFor="lastName">{this.props.t('SaveCorporateQuickQuoteForm.last_name')}</MobxFormLabel>
          <TextInputWrappingInput id="lastName" value={quickQuote.lastName} onChange={quickQuote.setLastName} />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.position}>
          <MobxFormLabel htmlFor="position">{this.props.t('SaveCorporateQuickQuoteForm.position')}</MobxFormLabel>
          <SelectInput
            id="position"
            value={quickQuote.position}
            onChange={(id, position) => quickQuote.setPosition(position)}
            options="Position"
          />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.email}>
          <MobxFormLabel htmlFor="email">{this.props.t('SaveCorporateQuickQuoteForm.customer_email')}</MobxFormLabel>
          <EmailLookup
            sector="corporate"
            dealershipId={this.props.dealershipId}
            onChange={quickQuote.setEmail}
            value={quickQuote.email}
            autoComplete="none"
            placeholder={this.props.t('SaveCorporateQuickQuoteForm.enter_customer_email_address')}
          />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.businessTelephone}>
          <MobxFormLabel htmlFor="businessTelephone">
            {this.props.t('SaveCorporateQuickQuoteForm.business_telephone_or')}
          </MobxFormLabel>
          <TextInputWrappingInput
            id="businessTelephone"
            value={quickQuote.businessTelephone}
            onChange={quickQuote.setBusinessTelephone}
          />
        </MobxFormFieldGroup>

        <MobxFormFieldGroup error={errors.mobileTelephone}>
          <MobxFormLabel htmlFor="mobileTelephone">
            {this.props.t('SaveCorporateQuickQuoteForm.mobile_telephone')}
          </MobxFormLabel>
          <TextInputWrappingInput
            id="mobileTelephone"
            value={quickQuote.mobileTelephone}
            onChange={quickQuote.setMobileTelephone}
          />
        </MobxFormFieldGroup>
        {this.props.appStore.uiState.canAssign && (
          <CheckboxLabel>
            <Checkbox value={quickQuote.assignToSelf} onChange={quickQuote.setAssignToSelf} />
            {this.props.t('SaveCorporateQuickQuoteForm.assign_this_lead_to_me')}
          </CheckboxLabel>
        )}
        <FormFooter
          submitLabel={this.props.t('SaveCorporateQuickQuoteForm.save')}
          onCancel={this.props.closeModal}
          isSubmitting={quickQuoteStore.saveCorporateQuickQuoteRequest.isLoading}
          hasSubmittingError={quickQuoteStore.saveCorporateQuickQuoteRequest.hasError}
        />
      </MobxForm>
    );
  }
}

SaveCorporateQuickQuoteForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  appStore: PropTypes.object,
  quote: PropTypes.object,
  vehicle: PropTypes.object,
  dealershipId: PropTypes.string
};
export default withTranslation('QuickQuote')(inject(['appStore'])(observer(SaveCorporateQuickQuoteForm)));
