import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Page from '../Common/Page';
import Breadcrumbs from '../Common/Breadcrumbs';
import Panel from '../Common/Panel';
import PanelContent from '../Common/PanelContent';
import PanelHeader from '../Common/PanelHeader';
import CfcCheckDetailsPanels from './CfcCheckDetailsPanels';
import CfcCheckDetailsButtons from './CfcCheckDetailsButtons';
import AssignLeadContainer from '../AssignLead/containers/AssignLeadContainer';
import PanelToolBar from '../Common/PanelToolBar';
import ConsumerDutyContainer from '../ConsumerDuty/containers/ConsumerDutyContainer';
import { inject, observer } from 'mobx-react';
import { withParams } from 'hocs/router';
import { printPageByRef } from '~/core/print';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import './cfcCheckDetails.scss';

@inject('appStore')
@observer
class CfcCheckDetails extends Component {
  constructor() {
    super();
    this.state = {
      showConsumerDuty: false
    };
  }
  printAreaRef = React.createRef();

  handleGoBackConsumerDuty = () => {
    this.setState({ showConsumerDuty: false });
  };

  handleSubmitConsumerDuty = () => {
    const { cfcProspectId, quoteId, dealershipId, consumerId } = this.props.params;
    this.props.applyForCfcCheck(cfcProspectId, quoteId, this.props.cfcCheckDetails.Quote, dealershipId, consumerId);
  };

  handleBack() {
    window.history.back();
  }

  handlePrint = () => {
    printPageByRef(this.printAreaRef);
  };

  handleApply = () => {
    this.setState({ showConsumerDuty: true });
  };

  render() {
    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: this.props.t('Consumer.crumb_home'),
              path: `/d/${this.props.params.dealershipId}`
            },
            {
              name: this.props.t('Consumer.crumb_customer_list'),
              path: `/d/${this.props.params.dealershipId}/consumers`
            },
            {
              name: 'Consumer',
              path: `/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}`
            },
            {
              name: 'CFC Check Details'
            }
          ]}
          consumer={this.props.customer}
        />
        {this.state.showConsumerDuty ? (
          <ConsumerDutyContainer
            dealershipId={this.props.params.dealershipId}
            onCancel={this.handleGoBackConsumerDuty}
            chosenQuote={this.props.cfcCheckDetails.Quote}
            customerType="consumer" // We don't have CFC checks for corporate customers
            onContinue={this.handleSubmitConsumerDuty}
          />
        ) : (
          <Panel>
            <PanelHeader>CFC Check Details</PanelHeader>
            <PanelToolBar>
              <div className="cfcCheckDetails__assignButton alignRight">
                <AssignLeadContainer
                  dealershipId={this.props.params.dealershipId}
                  customerId={this.props.customer.Id}
                  customerName={`${this.props.customer.Firstname} ${this.props.customer.Surname}`}
                  customerType={this.props.customer.CustomerType.toLowerCase()}
                  initialAssignees={this.props.customer.assignedTo}
                />
              </div>
            </PanelToolBar>
            <PanelContent>
              <div className="printArea" ref={this.printAreaRef}>
                <CfcCheckDetailsPanels
                  dealership={this.props.dealership}
                  cfcCheckDetails={this.props.cfcCheckDetails}
                />
              </div>
              <CfcCheckDetailsButtons
                quote={this.props.cfcCheckDetails.Quote}
                handleBack={this.handleBack}
                handleApply={this.handleApply}
                hideSubmit={this.props.appStore.uiState.canCreateApplications === false}
                loadingState={this.props.applicationLoadingState}
                handlePrint={this.handlePrint}
              />
            </PanelContent>
          </Panel>
        )}
      </Page>
    );
  }
}

CfcCheckDetails.propTypes = {
  params: PropTypes.object,
  dealership: PropTypes.object,
  cfcCheckDetails: PropTypes.object,
  applyForCfcCheck: PropTypes.func,
  customer: PropTypes.object,
  applicationLoadingState: PropTypes.string,
  assignees: PropTypes.array
};

CfcCheckDetails.defaultProps = {
  cfcCheckDetails: {}
};

export default compose(withParams, withTranslation('Customer'))(CfcCheckDetails);
