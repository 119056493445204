import React from 'react';
import { inject } from 'mobx-react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

const StockAddContainer = ({ appStore }) => {
  const params = useParams();
  const navigate = useNavigate();
  // check stock upload is enabled for dealership
  const addVehicleEnabled = appStore.uiState.canAddVehicles;
  if (!addVehicleEnabled) {
    navigate(`/d/${params.dealershipId}/stock/list`, {
      replace: true
    });
    return null;
  }

  return <Outlet />;
};

export default inject('appStore')(StockAddContainer);
