import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import Button from '../../../components/Common/Button/Button';
import PanelHeader from '../../../components/Common/PanelHeader';
import PanelFooter from '../../../components/Common/PanelFooter';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import WistiaVideo from '../../../components/Common/WistiaVideo';
import { useFunderConsumerDutyContent } from '../../../api/contentService/hooks';
import { ConsumerDutyDetails } from '../../../api/contentService/utils';
import AlertCard from '../../../components/Common/AlertCard/AlertCard';
import FunderLogo from '../../../components/Common/FunderLogo';
import styles from './lenderContent.module.scss';
import { addScript, initVideoTracking } from '../../../tracking/videoTracking';
import { useTrackFinanceProductSuitabilityContentViewed, useTrackPageViewed } from '../../../tracking/hooks';
import { LenderVulnerableCustomers } from './LenderVulnerableCustomers';
import { useNavigate } from 'hooks/useNavigate';
import { ConsumerDutyProductDetails } from '~/api/contentService';

interface LenderContentProps {
  consumerDutyDetails: ConsumerDutyDetails;
  onContinue: () => void;
  onCancel: () => void;
}

const LenderContent: React.FC<LenderContentProps> = ({ consumerDutyDetails, onContinue, onCancel }) => {
  const { t } = useTranslation('Quoting');
  const navigate = useNavigate();

  const { dealershipId, quoteId, funderCode: chosenQuoteFunderCode } = consumerDutyDetails;
  const { isLoading, isError: isErrorContent, data: consumerDuty } = useFunderConsumerDutyContent(consumerDutyDetails);

  // Track viewing consumer duty details
  useTrackFinanceProductSuitabilityContentViewed(
    {
      ...consumerDuty,
      funderCode: chosenQuoteFunderCode
    } as ConsumerDutyProductDetails,
    dealershipId,
    quoteId
  );
  useTrackPageViewed('Lender Content', dealershipId);

  if (isLoading) {
    return <LoadingSpinner center size="large" />;
  }
  if (isErrorContent || !consumerDuty) {
    navigate(`/d/${dealershipId}/error/204`);
    return null;
  }

  const {
    funderCode,
    introductionStatement,
    targetGroup,
    benefits,
    considerations,
    support,
    videoId,
    facilityTypeName,
    facilityTypeDescription
  } = consumerDuty;

  const handleTrackVideo = () => {
    addScript('//fast.wistia.com/assets/external/E-v1.js');
    if (consumerDuty) {
      initVideoTracking(videoId, consumerDuty, consumerDutyDetails.dealershipId, quoteId);
    }
  };

  return (
    <>
      <PanelHeader>{t('LenderContent.finance_product_information')}</PanelHeader>
      <main>
        <div className={styles.alertCardWrapper}>
          <AlertCard
            iconName="information"
            title={t('LenderQuestionnaire.important_information')}
            paragraph={t('LenderQuestionnaire.under_consumer_duty_you_need_to')}
          />
        </div>
        <div className={styles.funderLogo}>
          <FunderLogo funder={funderCode} />
        </div>
        <ProductName facilityTypeName={facilityTypeName} facilityTypeDescription={facilityTypeDescription || ''} />
        <ContentSection
          title={t('LenderContent.product_information_statement')}
          content={introductionStatement || ''}
        />
        <ContentSection title={t('LenderContent.who_is_it_for')} content={targetGroup} />
        {videoId && <WistiaVideo source={videoId} startTracking={handleTrackVideo} />}
        <ContentSection title={t('LenderContent.benefits')} content={benefits} />
        <ContentSection title={t('LenderContent.considerations')} content={considerations} />
        <ContentSection title={t('LenderContent.how_the_lender_will_support_you')} content={support} />
        <LenderVulnerableCustomers consumerDuty={consumerDuty} dealershipId={dealershipId} quoteId={quoteId} />
      </main>
      <PanelFooter
        cancel={
          <Button buttonStyle="cancel" onClick={onCancel} id="continueFpn">
            {t('ConsumerDutyContainer.cancel')}
          </Button>
        }
        save={
          <Button onClick={onContinue} id="continueFpn">
            {t('ConsumerDutyContainer.continue')}
          </Button>
        }
      />
    </>
  );
};

type ProductNameProps = {
  facilityTypeName: string | null | undefined;
  facilityTypeDescription: string | null | undefined;
};
export const ProductName = ({ facilityTypeName, facilityTypeDescription }: ProductNameProps) =>
  facilityTypeName && facilityTypeDescription ? (
    <h1 className={styles.productName}>
      {facilityTypeName} ({facilityTypeDescription})
    </h1>
  ) : null;

type ContentSectionProps = {
  title: string;
  content: string;
};
export const ContentSection = ({ title, content }: ContentSectionProps) => {
  if (content) {
    return (
      <section className={styles.sectionContainer}>
        <h3 className={styles.sectionTitle}>{title}</h3>
        <div className={styles.sectionContent}>{parse(content)}</div>
      </section>
    );
  }
  return null;
};

export default LenderContent;
