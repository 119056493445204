import React from 'react';
import PropTypes from 'prop-types';
import './lenderUpdatesFilter.scss';
import Select from '../../Common/Form/Select';

const LenderUpdatesFilter = ({ filterLenderUpdates, value, options, text }) => {
  const handleFilter = (evt) => {
    const value = evt.target.value;
    filterLenderUpdates(value);
  };
  return (
    <div className="lenderUpdatesFilter__sort">
      <span className="lenderUpdatesFilter__sortText">{text}</span>
      <div className="lenderUpdatesFilter__selectWrapper">
        <Select onChange={handleFilter} theme="alt" emptyValue={false} value={value} options={options} />
      </div>
    </div>
  );
};
LenderUpdatesFilter.propTypes = {
  filterLenderUpdates: PropTypes.func,
  value: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  text: PropTypes.string
};

export default LenderUpdatesFilter;
