import React from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import './repExampleInfoCard.scss';

const RepExampleInfoCard = ({ children, dataTh }) => {
  if (children) {
    const financeInfo = children.slice(1);
    return (
      <div className="repExampleInfoCard" data-th={dataTh}>
        <div className="repExampleInfoCard__title">{children[0]}</div>
        <div className="repExampleInfoCard__financeInfo">
          {financeInfo.map((bulletPoint, i) => (
            <ul className="repExampleInfoCard__bulletPoints" key={i}>
              <li>{bulletPoint}</li>
            </ul>
          ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

RepExampleInfoCard.propTypes = {
  children: arrayOf(PropTypes.node),
  dataTh: PropTypes.string
};

export default RepExampleInfoCard;
