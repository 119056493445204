import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import ConsumerRecordDetails from '../components/ConsumerRecordDetails';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Page from '../../Common/Page';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import Button from '../../Common/Button/Button';
import ConsumerVehicleDetails from '../components/ConsumerVehicleDetails';
import CorporateRecordPanel from '../components/CorporateRecordPanel';
import CarFinanceChecks from '../components/CarFinanceChecks';
import PanelToolBar from '../../Common/PanelToolBar';
import NoPanelData from '../../Dashboard/components/NoPanelData';
import LinkedUsersModal from '../modals/LinkedUsersModal';
import AlignRight from '../../Common/AlignRight';
import QuoteTabItem from '../components/QuoteTabItem';
import Tabs from '../../Common/Tabs/Tabs';
import TabList from '../../Common/Tabs/TabList';
import TabContent from '../../Common/Tabs/TabContent';
import CustomerVehicleTab from './../components/CustomerVehicleTab';
import Modal from '../../Common/Modal/Modal';
import { inject, observer } from 'mobx-react';
import SavedQuickQuotes from '../components/SavedQuickQuotes';
import FinanceApplications from '../components/FinanceApplications';
import Deals from '../components/SelfService/Deals';
import PushDeals from '../components/SelfService/PushDeals';
import OnlineOrders from '../components/SelfService/OnlineOrders';
import CollapsiblePanel from '../../Common/CollapsiblePanel';
import findIndex from 'lodash/findIndex';
import { withRouter } from '../../../hocs/router';
import { connect } from 'react-redux';
import './consumerPage.scss';

@inject('appStore')
@observer
class Consumer extends React.Component {
  constructor(props) {
    super(props);

    this.financeTabs = [
      ...(this.props.appStore.uiState.canUseFinancing ? ['FinanceApplications', 'CfcProspects'] : []),
      'SavedQuotes',
      ...(this.props.enableSendDeal ? ['SentDeals'] : [])
    ];

    this.state = {
      vehicle: this.props.consumer.VehiclesOfInterest[this.props.currentVehicleTabIndex],
      isModalOpen: false,
      activeFinanceTabName: this.financeTabs.includes(this.props.selectedFinanceTab)
        ? this.props.selectedFinanceTab
        : this.financeTabs[0]
    };
  }

  componentDidMount() {
    this.state.vehicle
      ? this.getOrdersData()
      : this.setState({
          orders: [],
          activeOrdersTab: 0
        });

    if (this.props.query.vehicleId) {
      let index = findIndex(this.props.consumer.VehiclesOfInterest, {
        VehicleId: this.props.query.vehicleId
      });
      this.selectVehicle(index);
    } //this.props.appStore.quotingStore.getNewCarOffers(this.props.dealership.WebQuotewareId)
  }

  getOrdersData = () => {
    let ordersArray = [];
    const promiseArray = this.props.consumer.VehiclesOfInterest.map((vehicleItem) => {
      return new Promise((resolve, reject) => {
        this.props.appStore.ordersStore
          .getOrdersCustomerRecord(
            this.props.params.dealershipId,
            this.state.page,
            this.state.pageSize,
            this.props.consumer.Id,
            vehicleItem.VehicleId
          )
          .then((response) => {
            if (response.Orders?.length > 0) {
              ordersArray.push({
                vehicleId: vehicleItem.VehicleId,
                orders: response.Orders
              });
            }

            resolve();
          });
      });
    });
    return Promise.all(promiseArray).then(() => {
      this.setUiState(ordersArray, this.props.currentVehicleTabIndex);
      this.setState({
        customerOrders: ordersArray
      });
    });
  };
  getCurrentVehicleOrders = (orders = [], vehicleId) => {
    for (let i = 0; i < orders.length; i++) {
      if (orders[i].vehicleId === vehicleId) {
        return orders[i].orders;
      }
    }
  };
  setUiState = (newOrders, currentVehicleIndex) => {
    const currentIndex = currentVehicleIndex;
    const vehicleId = this.props.consumer.VehiclesOfInterest[currentIndex].VehicleId;
    const deals = this.getFilteredDeals(this.props.consumer.VehiclesOfInterest[currentIndex]);
    const customerOrders = newOrders || this.state.customerOrders;
    let filteredOrders = this.getCurrentVehicleOrders(customerOrders, vehicleId);
    let activeOrdersTab = 0;
    let numberOfChildren = 0;

    if (filteredOrders && filteredOrders.length > 0) {
      activeOrdersTab = 0;
      numberOfChildren = filteredOrders.length;
    } else if (deals && deals.length > 0) {
      activeOrdersTab = 1;
      numberOfChildren = deals.length;
    }

    this.setState({
      activeOrdersTab: activeOrdersTab,
      childrenHeightOnlineTransactions: this.getChildrenHeightOnlineTransactions(activeOrdersTab, numberOfChildren),
      showContent: (filteredOrders && filteredOrders.length > 0) || (deals && deals.length > 0),
      filteredCustomerPushDeals: deals
    });
  };
  toggleLinkedUsersModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen
    }));
  };
  getFinanceTabIndex = (name) => {
    const index = this.financeTabs.indexOf(name);
    return index < 0 ? 0 : index;
  };
  selectFinanceTab = (index) => {
    this.setState({
      activeFinanceTabName: this.financeTabs[index]
    });
    this.props.selectFinanceTab(this.financeTabs[index]);
    this.getChildrenHeightFinance(this.financeTabs[index]);
  };
  selectVehicle = (index) => {
    this.props.selectVehicle(this.props.consumer.VehiclesOfInterest[index]);
    this.setState({
      vehicle: this.props.consumer.VehiclesOfInterest[index],
      activeFinanceTabName: this.state.activeFinanceTabName
    });
    (this.props.appStore.uiState.hasOrders || this.props.appStore.uiState.canUsePushDeal) &&
      this.setOrdersTabActive(this.state.activeOrdersTab);
    this.getChildrenHeightFinance(this.state.activeFinanceTabName);
    this.setUiState(null, index);
  };
  setOrdersTabActive = (index) => {
    const deals = this.props.appStore.pushDealStore.customerPushDeals.deals;
    const numberOfChildren =
      index === 0 ? this.getCurrentVehicleOrders(this.state.customerOrders) : deals && deals.length;
    this.setState({
      activeOrdersTab: index,
      childrenHeightOnlineTransactions: this.getChildrenHeightOnlineTransactions(index, numberOfChildren)
    });
  };
  getChildrenHeightOnlineTransactions = (activeOrdersTab, numberOfChildren) => {
    const maxItemHeight = 250;
    const calculatedHeight = numberOfChildren > 0 ? numberOfChildren * maxItemHeight : 1 * maxItemHeight;
    return activeOrdersTab >= 0 ? calculatedHeight : 0;
  };
  getArrayLargestChildren = (activeTabArray) => {
    let largestChild;
    activeTabArray.forEach((item) => {
      if (item.Quotes.length > 0) {
        largestChild = item.Quotes.length;
      }
    });
    return largestChild;
  };
  getChildrenHeightFinance = () => {
    const activeFinanceTab = this.props.selectedFinanceTab;
    const maxItemHeight = 1800;
    const activeFinanceTabName = activeFinanceTab === 'SentDeals' ? 'SelfServiceCodes' : activeFinanceTab;
    const activeTabArray = this.state.vehicle ? this.state.vehicle[activeFinanceTabName] : 0;
    let height = maxItemHeight;

    if (activeTabArray && activeTabArray.length > 0) {
      if (activeFinanceTabName === 'SelfServiceCodes' || activeFinanceTabName === 'CfcProspects') {
        height = this.getArrayLargestChildren(activeTabArray) * maxItemHeight;
      } else {
        height = activeTabArray.length * maxItemHeight;
      }
    } else {
      height = 1 * maxItemHeight;
    }

    return height;
  };
  getFilteredOrders = (vehicle) => {
    const consumer = this.props.consumer;
    const customerOrders = this.state.customerOrders;
    let vehicleId = null;

    if (vehicle) {
      vehicleId = vehicle.VehicleId;
    } else if (consumer.VehiclesOfInterest[this.props.currentVehicleTabIndex]) {
      vehicleId = consumer.VehiclesOfInterest[this.props.currentVehicleTabIndex].VehicleId;
    } else {
      return [];
    }

    let filteredOrders = this.getCurrentVehicleOrders(customerOrders, vehicleId);
    return filteredOrders;
  };
  getFilteredDeals = (vehicle) => {
    const consumer = this.props.consumer;
    const { customerPushDeals } = this.props.appStore.pushDealStore;
    let vehicleID = null;

    if (vehicle) {
      vehicleID = vehicle.VehicleId;
    } else if (consumer.VehiclesOfInterest[this.props.currentVehicleTabIndex]) {
      vehicleID = consumer.VehiclesOfInterest[this.props.currentVehicleTabIndex].VehicleId;
    } else {
      return [];
    }

    if (
      customerPushDeals &&
      consumer.VehiclesOfInterest &&
      consumer.VehiclesOfInterest.length > 0 &&
      customerPushDeals.deals
    ) {
      const filteredDeals = customerPushDeals.deals
        .filter((deal) => {
          return deal.dealerPlatformVehicleOfInterestId === vehicleID;
        })
        .sort(function(a, b) {
          let date1 = new Date(a.addedAt);
          let date2 = new Date(b.addedAt);
          return date2.getTime() - date1.getTime();
        });

      return filteredDeals.length > 0 ? filteredDeals : [];
    } else {
      return [];
    }
  };
  getLatestPrice = (vehicle) => {
    const deals = this.getFilteredDeals(vehicle);
    const orders = this.getFilteredOrders(vehicle);
    const latestVehiclePrice = vehicle.LatestPrice || 0;
    const dealPrice =
      (deals?.[0]?.quotes?.[0]?.platformMeta && JSON.parse(deals[0].quotes[0].platformMeta)?.VehiclePrice) || 0;
    const orderPrice = (orders && orders.length > 0 && orders[0].VehicleDetails.VehicleCashPrice) || 0;
    const OrderLastUpdated = orders && orders.length > 0 && new Date(orders[0].LastModifiedTimestamp);
    const DealLastUpdated = deals && deals.length > 0 && new Date(deals[0].lastUpdated);
    const vehicleLastUpdated = latestVehiclePrice && new Date(vehicle.LastUpdated);
    let price = null;
    const data = [
      {
        name: 'orders',
        lastUpdated: OrderLastUpdated
      },
      {
        name: 'deals',
        lastUpdated: DealLastUpdated
      },
      {
        name: 'other',
        lastUpdated: vehicleLastUpdated
      }
    ];
    const latestPriceObj = data.reduce((prev, curr) => {
      return prev.lastUpdated > curr.lastUpdated ? prev : curr;
    });

    if (latestPriceObj.name === 'other') {
      price = latestVehiclePrice;
    } else if (latestPriceObj.name === 'orders') {
      price = orderPrice;
    } else if (latestPriceObj.name === 'deals') {
      price = dealPrice;
    }

    return price;
  };
  financeJSX = (consumer, customerPushDeals, vehicle) => {
    // const newCarOffers = this.props.appStore.quotingStore.newCarOffers;
    return (
      <>
        {consumer.VehiclesOfInterest.length === 0 && customerPushDeals && customerPushDeals.deals ? (
          <NoPanelData text={this.props.t('Consumer.there_are_currently_no_vehicles_of_interest_for_this_customer')} />
        ) : (
          <div className="consumerPage__quotesOuter">
            {vehicle && (
              <Tabs
                currentTab={this.getFinanceTabIndex(this.state.activeFinanceTabName)}
                setActiveTab={this.selectFinanceTab}
              >
                <TabList>
                  {this.props.appStore.uiState.canUseFinancing && (
                    <QuoteTabItem
                      dataThook="financeApplicationsTab"
                      text={this.props.t('Consumer.finance_applications')}
                      iconName="application"
                      count={vehicle.FinanceApplications.length}
                    />
                  )}
                  {this.props.appStore.uiState.canUseFinancing && (
                    <QuoteTabItem
                      dataThook="financeChecksTab"
                      text={this.props.t('Consumer.car_finance_checks')}
                      iconName="finance-check"
                      count={vehicle.CfcProspects.length}
                    />
                  )}
                  <QuoteTabItem
                    dataThook="savedQuotesTab"
                    text={this.props.t('Consumer.saved_quotes')}
                    iconName="quote"
                    count={vehicle.SavedQuotes.length}
                  />
                  {this.props.enableSendDeal && (
                    <QuoteTabItem
                      dataThook="selfServicedDealsTab"
                      text={this.props.t('Consumer.self_serviced_deals')}
                      iconName="self-service-deals"
                      count={vehicle.SelfServiceCodes.length}
                    />
                  )}
                </TabList>
                <TabContent>
                  {this.props.appStore.uiState.canUseFinancing && (
                    <FinanceApplications
                      financeApplications={vehicle.FinanceApplications}
                      vehicleId={vehicle.VehicleId}
                      onSortFinanceApplications={this.props.onSortFinanceApplications}
                      viewApplication={this.props.handleFinanceApplicationsView}
                      handleEdit={this.props.handleFinanceApplicationsEdit}
                      sortDirection={vehicle.SortFilters.FinanceApplications.sortDirection}
                      sortField={vehicle.SortFilters.FinanceApplications.sortField}
                      vehicleClass={vehicle.Class}
                    />
                  )}
                  {this.props.appStore.uiState.canUseFinancing && (
                    <CarFinanceChecks
                      checks={vehicle.CfcProspects}
                      dealership={this.props.dealership}
                      onApply={this.props.applyForCfcCheck}
                      consumer={consumer}
                      vehicleId={vehicle.VehicleId}
                      handleCfcCheckDetailsLink={this.props.handleCfcCheckDetailsLink}
                      onSortCfcProspects={this.props.onSortCfcProspects}
                      sortDirection={vehicle.SortFilters.CfcProspects.sortDirection}
                      sortField={vehicle.SortFilters.CfcProspects.sortField}
                      onEligibilityMatchClick={this.props.openEligibilityModal}
                      onEligibilitySummaryClick={this.props.openEligibilitySummaryModal}
                      selectCfcCheck={this.props.selectCfcCheck}
                      selectedCfcCheckIndex={this.props.selectedCfcCheckIndex}
                      vehicleClass={vehicle.Class}
                    />
                  )}
                  <SavedQuickQuotes
                    onSortSavedQuotes={this.props.onSortSavedQuotes}
                    consumer={consumer}
                    vehicleId={vehicle.VehicleId}
                    savedQuotes={vehicle.SavedQuotes}
                    viewSavedQuote={this.props.handleViewSavedQuote}
                    sortDirection={vehicle.SortFilters.SavedQuotes.sortDirection}
                    sortField={vehicle.SortFilters.SavedQuotes.sortField}
                    vehicleClass={vehicle.Class}
                  />
                  {this.props.enableSendDeal && (
                    <Deals
                      deals={vehicle.SelfServiceCodes}
                      dealershipId={this.props.params.dealershipId}
                      consumer={consumer}
                      vehicleId={vehicle.VehicleId}
                      selectSentDeal={this.props.selectSentDeal}
                      selectedSentDealIndex={this.props.selectedSentDealIndex}
                      vehicleClass={vehicle.Class}
                    />
                  )}
                </TabContent>
              </Tabs>
            )}
          </div>
        )}
      </>
    );
  };

  render() {
    const consumer = this.props.consumer;
    const vehicle = this.state.vehicle;
    const { customerPushDeals } = this.props.appStore.pushDealStore;
    const filteredCustomerOnlineOrders = this.getFilteredOrders();
    const childrenHeightFinance = this.state.vehicle && this.getChildrenHeightFinance();
    return (
      <Page testHook="ConsumerPage">
        <Breadcrumbs
          items={[
            {
              name: this.props.t('Consumer.crumb_home'),
              path: `/d/${this.props.params.dealershipId}`
            },
            {
              name: this.props.t('Consumer.crumb_customer_list'),
              path: `/d/${this.props.params.dealershipId}/consumers`
            },
            {
              name: this.props.t('Consumer.crumb_consumer'),
              path: `/d/${this.props.params.dealershipId}/consumers/${consumer.Id}`
            }
          ]}
          consumer={consumer}
        />

        {consumer.CustomerType === 'Consumer' || consumer.CustomerType === 'consumer' ? (
          <ConsumerRecordDetails
            consumer={consumer}
            params={this.props.params}
            handleModalOpen={this.toggleLinkedUsersModal}
            handleDealershipDistanceModal={() =>
              this.props.openDealershipDistanceModal(
                consumer.DistanceToDealership,
                this.props.dealership.Postcode,
                consumer.Address.Postcode
              )
            }
          />
        ) : (
          <CorporateRecordPanel
            consumer={consumer}
            params={this.props.params}
            handleDealershipDistanceModal={() =>
              this.props.openDealershipDistanceModal(
                consumer.DistanceToDealership,
                this.props.dealership.Postcode,
                consumer.Address.Postcode
              )
            }
          />
        )}
        <div>
          <Panel noMargin={false}>
            <PanelHeader>{this.props.t('Consumer.vehicles_of_interest')}</PanelHeader>
            {!this.props.appStore.uiState.isBdk && (
              <PanelToolBar>
                <AlignRight>
                  <Button
                    buttonStyle="secondary"
                    to={`/d/${this.props.params.dealershipId}/consumers/${consumer.Id}/add`}
                    id="AddVehicle"
                  >
                    {this.props.t('Consumer.add_vehicle')}
                  </Button>
                </AlignRight>
              </PanelToolBar>
            )}

            {consumer.VehiclesOfInterest.length === 0 && customerPushDeals && customerPushDeals.deals ? (
              <NoPanelData
                text={this.props.t('Consumer.there_are_currently_no_vehicles_of_interest_for_this_customer')}
              />
            ) : (
              <Tabs currentTab={this.props.currentVehicleTabIndex} setActiveTab={this.selectVehicle}>
                <TabList tabStyle="large">
                  {consumer.VehiclesOfInterest.map((vehicle, index) => (
                    <CustomerVehicleTab
                      vehicle={vehicle}
                      key={index}
                      latestPrice={this.getLatestPrice(vehicle, index)}
                    />
                  ))}
                </TabList>
                <TabContent border={false}>
                  {consumer.VehiclesOfInterest.map((vehicle, index) => {
                    const deal = customerPushDeals.deals?.find(
                      (deal) => deal.dealerPlatformVehicleOfInterestId === vehicle.VehicleId
                    );

                    return (
                      <div key={index}>
                        <ConsumerVehicleDetails
                          key={index}
                          params={this.props.params}
                          vehicle={vehicle}
                          deal={deal}
                          consumer={consumer}
                          latestPrice={this.getLatestPrice(vehicle, index)}
                          onNotesClick={() => this.props.openCommentsModal(consumer.Id, vehicle.VehicleId)}
                        />
                        {!this.props.appStore.uiState.hasOrders &&
                          this.financeJSX(consumer, customerPushDeals, vehicle)}
                      </div>
                    );
                  })}
                </TabContent>
              </Tabs>
            )}
          </Panel>

          {this.props.appStore.uiState.hasOrders && (
            <CollapsiblePanel
              title={this.props.t('Consumer.orders_deals')}
              id="OnlineTransactions"
              childrenHeight={this.state.childrenHeightOnlineTransactions}
              showContent={this.state.showContent}
              currentVehicle={this.state.vehicle}
            >
              <div className="consumerPage__quotesOuter">
                <Tabs currentTab={this.state.activeOrdersTab} setActiveTab={this.setOrdersTabActive}>
                  <TabList>
                    <QuoteTabItem
                      dataThook="onlineOrdersTab"
                      text={this.props.t('Consumer.online_orders')}
                      iconName="online-order"
                      count={(filteredCustomerOnlineOrders && filteredCustomerOnlineOrders.length) || 0}
                    />

                    <QuoteTabItem
                      dataThook="digitalDealsTab"
                      text={this.props.t('Consumer.digital_deals')}
                      iconName="send-deal"
                      count={
                        (customerPushDeals &&
                          this.state.filteredCustomerPushDeals &&
                          this.state.filteredCustomerPushDeals.length) ||
                        0
                      }
                    />
                  </TabList>
                  <TabContent>
                    <OnlineOrders
                      customerOnlineOrders={filteredCustomerOnlineOrders}
                      dealershipId={this.props.params.dealershipId}
                      canUseOrders={this.props.appStore.uiState.canUseOrders}
                    />

                    <PushDeals
                      customerPushDeals={this.state.filteredCustomerPushDeals}
                      consumerId={consumer.Id}
                      isCustomerRecord={true}
                      canUsePushDeal={this.props.appStore.uiState.canUsePushDeal}
                    />
                  </TabContent>
                </Tabs>
              </div>
            </CollapsiblePanel>
          )}
          {this.props.appStore.uiState.hasOrders && (
            <CollapsiblePanel
              title={this.props.t('Consumer.finance')}
              id="Finance"
              childrenHeight={childrenHeightFinance}
              showContent={true}
            >
              {this.financeJSX(consumer, customerPushDeals, vehicle)}
            </CollapsiblePanel>
          )}
        </div>
        <Modal isOpen={this.state.isModalOpen} onClose={this.toggleLinkedUsersModal}>
          <LinkedUsersModal
            ref="linkedUsersModal"
            count={consumer.AliasCount}
            aliases={consumer.Aliases}
            onClose={this.toggleLinkedUsersModal}
          />
        </Modal>
      </Page>
    );
  }
}

Consumer.propTypes = {
  query: PropTypes.object,
  params: PropTypes.object,
  appStore: PropTypes.object,
  consumer: PropTypes.object,
  dealership: PropTypes.object,
  applyForCfcCheck: PropTypes.func,
  session: PropTypes.object,
  openCommentsModal: PropTypes.func,
  handleCfcCheckDetailsLink: PropTypes.func,
  selectVehicle: PropTypes.func,
  selectedFinanceTab: PropTypes.string,
  currentVehicleTabIndex: PropTypes.number,
  onSortSavedQuotes: PropTypes.func,
  onSortFinanceApplications: PropTypes.func,
  onSortCfcProspects: PropTypes.func,
  handleFinanceApplicationsView: PropTypes.func,
  handleFinanceApplicationsEdit: PropTypes.func,
  handleViewSavedQuote: PropTypes.func,
  openEligibilityModal: PropTypes.func,
  openEligibilitySummaryModal: PropTypes.func,
  openDealershipDistanceModal: PropTypes.func,
  enableSendDeal: PropTypes.bool,
  selectFinanceTab: PropTypes.func,
  selectCfcCheck: PropTypes.func,
  selectedCfcCheckIndex: PropTypes.number,
  selectSentDeal: PropTypes.func,
  selectedSentDealIndex: PropTypes.number
};

export default withRouter(withTranslation('Customer')(observer(connect(null)(Consumer))));
