import _ from 'lodash';

let themeName;

export function getThemeName() {
  return themeName;
}

export function setTheme(name) {
  themeName = name;
  const otherThemes = _.without(['theme-default', 'theme-motonovo', 'theme-bnpp', 'theme-bdk'], themeName);

  if (typeof window !== 'undefined') {
    const body = document.querySelector('body');
    _.each(otherThemes, (theme) => {
      body.classList.remove(theme);
    });

    body.classList.add(themeName);
  }
}
