import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import * as productActions from '../../../redux/product/productActions';
import SettingsProduct from '../components/SettingsProduct';
import { withParams } from 'hocs/router';
import { push } from '../../../routerHistory';

const SettingsProductContainer = (props) => {
  const { children, ...remainingProps } = props;

  return <SettingsProduct {...remainingProps} />;
};

SettingsProductContainer.propTypes = {
  children: PropTypes.node
};

function mapStateToProps(state) {
  return {
    products: state.products,
    funders: state.funders,
    options: state.options
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    handleCancel: () => push(`/d/${ownProps.params.dealershipId}/settings`),
    handleSave: (products, dealershipId) => {
      const changedProductSettings = _.chain(products)
        .filter({ hasChanged: true })
        .map(function(product) {
          return product.Settings;
        })
        .value();

      dispatch(productActions.saveAllProducts(dealershipId, changedProductSettings));
    }
  };
}

export default withParams(connect(mapStateToProps, mapDispatchToProps)(SettingsProductContainer));
