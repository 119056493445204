import _ from 'lodash';

function getAssignmentCount(data, name) {
  return _.chain(data)
    .get('Views')
    .find({ Name: name })
    .get('Value')
    .value();
}

export function parseDashboardData(response) {
  let { AssignmentTile, ...data } = response;

  let newData = Object.assign({}, data, {
    AllAssigned: getAssignmentCount(AssignmentTile, 'AllAssigned'),
    Unassigned: getAssignmentCount(AssignmentTile, 'Unassigned'),
    MyAssigned: getAssignmentCount(AssignmentTile, 'MyAssigned')
  });

  return newData;
}
