import platformApiUtils from '@ivendi/platform-api-utils';
import { z } from 'zod';

// http://dealerplatform-dealership-api-de-test-svc.dealerplatform-de-test.svc.cluster.local/index.html
export const StockSettingsSchema = z.object({
  id: z.string().optional(),
  mobileSearchApiUsername: z.string().optional(),
  mobileSearchApiPassword: z.string().optional(),
  mobileDeAccountNumber: z.string().optional()
});
export const UpdateStockSettingsSchema = StockSettingsSchema.partial();

export type StockSettings = z.infer<typeof StockSettingsSchema>;
export type UpdateStockSettings = z.infer<typeof UpdateStockSettingsSchema>;

export const getStockSettings = async (dealershipId: string) => {
  const data = await platformApiUtils.get(`dealershipservice/api/v1/stocksettings/${dealershipId}`);
  return StockSettingsSchema.parse(data);
};

export const updateStockSettings = async ({
  dealershipId,
  stockSettings
}: {
  dealershipId: string;
  stockSettings: UpdateStockSettings;
}) => {
  const body = await UpdateStockSettingsSchema.parse(stockSettings);
  await platformApiUtils.put(
    `dealershipservice/api/v1/stocksettings/${dealershipId}/add-stock-settings-from-dealer-platform`,
    body
  );
};
