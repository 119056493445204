import PropTypes from 'prop-types';
import Input from './Input';
import set from 'lodash/set';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';

import './dateInput.scss';

export const splitDate = (date) => {
  if (!date) {
    return ['', '', ''];
  }

  return date.split('/');
};

export const buildDate = (inputId, inputValue, value) => {
  let [day, month, year] = splitDate(value);
  let date = {
    day,
    month,
    year
  };

  let type = inputId.split('_')[1];

  date[type] = inputValue;

  const builtDate = Object.values(date).join('/');

  if (builtDate === '//') {
    return '';
  }
  return builtDate;
};

const DateInput = (props) => {
  const { t } = useTranslation('Common');
  const { countryCode, dateDelimiter } = props.appStore.uiState;

  const handleChange = (event) => {
    if (event.target.value.length > event.target.maxLength) {
      return;
    }
    props.onChange && props.onChange(props.id, buildDate(event.target.id, event.target.value, props.value));
  };

  const handleBlur = (event) => {
    props.onBlur && props.onBlur(props.id, buildDate(event.target.id, event.target.value, props.value));
  };

  const onBlurFormat = (event, type, value) => {
    if (value.length === 1 && (type === 'day' || type === 'month')) {
      handleChange(set(event, 'target.value', `0${value}`));
    }
    handleBlur(event);
  };

  const renderInput = (type, placeholder) => {
    let { id, disabled, isValid, isInvalid } = props;
    let [day, month, year] = splitDate(props.value);

    let dateObj = {
      day,
      month,
      year
    };

    return (
      <Input
        id={`${id}_${type}`}
        placeholder={placeholder}
        type="number"
        disabled={disabled}
        isValid={isValid}
        isInvalid={isInvalid}
        onChange={handleChange}
        onBlur={(e) => onBlurFormat(e, type, dateObj[type])}
        onWheel={(e) => e.target.blur()}
        value={dateObj[type]}
        maxLength={placeholder.length}
      />
    );
  };

  if (countryCode === 'de') {
    return (
      <div className="dateInput">
        <div className="dateInput__input">{renderInput('year', t('DateInput.yyyy'))}</div>
        <div className="dateInput__slash">{dateDelimiter}</div>
        <div className="dateInput__input">{renderInput('month', t('DateInput.mm'))}</div>
        <div className="dateInput__slash">{dateDelimiter}</div>
        <div className="dateInput__input">{renderInput('day', t('DateInput.dd'))}</div>
      </div>
    );
  }

  return (
    <div className="dateInput">
      <div className="dateInput__input">{renderInput('day', t('DateInput.dd'))}</div>
      <div className="dateInput__slash">{dateDelimiter}</div>
      <div className="dateInput__input">{renderInput('month', t('DateInput.mm'))}</div>
      <div className="dateInput__slash">{dateDelimiter}</div>
      <div className="dateInput__input">{renderInput('year', t('DateInput.yyyy'))}</div>
    </div>
  );
};

DateInput.propTypes = {
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

export default inject(['appStore'])(DateInput);
