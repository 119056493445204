import { takeEvery } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects'; // fork
import * as actionTypes from '../actionTypes';
import { setLatestDealsOptions, resetLatestDeals } from './latestDealsActions';
import { takeEveryByComponentName } from '../helpers';
import { componentMount, componentError, componentUnmount } from '../component/componentActions';
import { logError } from '../../core/helpers';
import * as api from '../../api/latestDeals';

export const getLatestDealsState = (state) => ({
  dealershipId: state.dealership.Id,
  userId: state.session.UserId,
  page: state.latestDeals.page,
  pageSize: state.latestDeals.pageSize,
  status: state.latestDeals.status,
  from: state.latestDeals.from,
  to: state.latestDeals.to
});

export function* watchLatestDealsFilters() {
  yield* takeEvery(
    [
      actionTypes.CHANGE_PAGE_LATEST_DEALS,
      actionTypes.FILTER_LATEST_DEALS_BY_STATUS,
      actionTypes.FILTER_LATEST_DEALS_BY_DATE
    ],
    reloadLatestDeals
  );
}

export function* watchLatestDealsContainer() {
  yield* takeEveryByComponentName(actionTypes.COMPONENT_LOAD, 'LatestDealsContainer', handleLatestDealsContainerLoad);
}

export function* watchLatestDealsContainerUnload() {
  yield call(
    takeEveryByComponentName,
    actionTypes.COMPONENT_UNLOAD,
    'LatestDealsContainer',
    handleLatestDealsContainerUnload
  );
}

export function* fetchLatestDeals() {
  let stateInfo = yield select(getLatestDealsState);
  yield put({ type: actionTypes.FETCH_LATEST_DEALS });
  const response = yield call(
    api.fetchLatestDeals,
    stateInfo.dealershipId,
    stateInfo.status,
    stateInfo.page,
    stateInfo.to,
    stateInfo.from
  );
  yield put({ type: actionTypes.FETCH_LATEST_DEALS_SUCCESS, response });
}

export function* reloadLatestDeals() {
  try {
    yield call(fetchLatestDeals);
  } catch (e) {
    logError(e);
    yield put(componentError('LatestDealsContainer', 'Error fetching latest deals.', e));
    return;
  }
}

export function* handleLatestDealsContainerLoad(action) {
  yield put(setLatestDealsOptions(action.query));
  try {
    yield call(fetchLatestDeals);
  } catch (e) {
    logError(e);
    yield put(componentError('LatestDealsContainer', 'Error fetching latest deals.', e));
    return;
  }
  yield put(componentMount('LatestDealsContainer'));
}

export function* handleLatestDealsContainerUnload() {
  yield put(resetLatestDeals());
  yield put(componentUnmount('LatestDealsContainer'));
}
