import { Trans, withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import './deleteCustomer.scss';
import Page from '../Common/Page';
import Breadcrumbs from '../Common/Breadcrumbs';
import Panel from '../Common/Panel';
import PanelHeader from '../Common/PanelHeader';
import PanelContent from '../Common/PanelContent';
import LoadingDots from '../Common/Loading/LoadingDots';
import DeleteCustomerActivityItem from './DeleteCustomerActivityItem';
import MobxForm from '../MobxForm/MobxForm';
import TextInputWrappingInput from '../Common/Form/TextInputWrappingInput';
import Button from '../Common/Button/Button';
import _ from 'lodash';
import ExclamationWarningWithButton from '../Common/ExclamationWarningWithButton';
import { compose } from 'redux';
import { withNavigate, withOutletContext, withParams } from '../../hocs/router';
class DeleteCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Email: ''
    };
  }

  setEmail = (Email) => {
    this.setState({
      Email: Email
    });
  };

  UNSAFE_componentWillMount() {
    this.props.appStore.customerStore.fetchCustomerData(this.props.params.consumerId);
  }

  submit = () => {
    this.props.appStore.deleteCustomerStore
      .deleteCustomer(this.props.params.consumerId, this.props.appStore.customerStore.customer.CustomerType)
      .then(() => {
        this.handleToCustomerList();
      });
  };

  handleCancel = () => {
    this.props.navigate(`/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}/edit`);
  };

  handleToCustomerList = () => {
    this.props.navigate(`/d/${this.props.params.dealershipId}/consumers/`);
  };

  render() {
    if (this.props.appStore.customerStore.isLoadingCustomer) {
      return <LoadingDots />;
    }

    const customer = this.props.appStore.customerStore.customer;
    const isAssigned = customer && customer.AssignedTo && customer.AssignedTo.length > 0;
    const assignee = isAssigned ? customer.AssignedTo && customer.AssignedTo[0] : {};
    const isAssignedToMe = isAssigned ? assignee.DealershipUserId === this.props.outletContext.session.UserId : false;
    const deleteEligibilityChecks = customer.FinanceChecks ? customer.FinanceChecks.length : '0';
    const deleteSavedQuotes = customer.SavedQuotes ? customer.SavedQuotes.length : '0';
    const deleteSelfServiceDeals = customer.SelfServiceCodes ? customer.SelfServiceCodes.length : '0';
    const deleteCustomerActivity = !_.isEmpty(customer.Vehicles) ? customer.Vehicles.length : '0';

    const getNumOfApplications = (customer) => {
      if (customer.CustomerType === 'Consumer') {
        return customer.FinanceApplications?.length || 0;
      } else if (customer.CustomerType === 'Corporate') {
        return customer.CorporateApplications?.length || 0;
      } else {
        return 0;
      }
    };

    const numOfApplications = getNumOfApplications(customer);

    if (!customer) {
      return (
        <Page>
          <Breadcrumbs
            items={[
              {
                name: this.props.t('DeleteCustomer.home'),
                path: `/d/${this.props.params.dealershipId}`
              },
              {
                name: this.props.t('DeleteCustomer.customer_list'),
                path: `/d/${this.props.params.dealershipId}/consumers`
              },
              {
                name: this.props.t('DeleteCustomer.delete_customer')
              }
            ]}
          />
          <Panel>
            <PanelHeader>{this.props.t('DeleteCustomer.delete_customer')}</PanelHeader>
            <PanelContent>
              <ExclamationWarningWithButton
                buttonText={this.props.t('DeleteCustomer.go_back')}
                onClick={this.handleToCustomerList}
                message={<Trans ns="DeleteCustomer" i18nKey={'DeleteCustomer.customer_not_found_already_deleted'} />}
              />
            </PanelContent>
          </Panel>
        </Page>
      );
    }

    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: this.props.t('DeleteCustomer.home'),
              path: `/d/${this.props.params.dealershipId}`
            },
            {
              name: this.props.t('DeleteCustomer.customer_list'),
              path: `/d/${this.props.params.dealershipId}/consumers`
            },
            {
              name: this.props.t('DeleteCustomer.consumer'),
              path: `/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}`
            },
            {
              name: this.props.t('DeleteCustomer.delete_customer')
            }
          ]}
          consumer={customer}
        />
        <div className="deleteCustomer">
          <Panel>
            <PanelHeader>{this.props.t('DeleteCustomer.delete_customer')}</PanelHeader>
            <PanelContent>
              <div className="deleteCustomer__content">
                {this.props.appStore.uiState.canDeleteConsumers ? (
                  <>
                    <div className="deleteCustomer__title">
                      {this.props.t('DeleteCustomer.about_to_delete_customer_from_records')}
                    </div>
                    <div className="deleteCustomer__innerPanel">
                      {customer.CustomerType === 'Corporate' && (
                        <div>
                          <strong>{customer.TradingName}</strong>
                        </div>
                      )}
                      <strong>
                        <span className="deleteCustomer__name">
                          {customer.Firstname} {customer.Surname}
                        </span>{' '}
                        ({customer.Email})
                      </strong>
                      {isAssigned && !isAssignedToMe && (
                        <div className="deleteCustomer__assignee">
                          <Trans
                            ns="DeleteCustomer"
                            i18nKey={'DeleteCustomer.customer_currently_assigned_to'}
                            components={{
                              span: <span className="deleteCustomer__name" />
                            }}
                            values={{
                              firstName: assignee.Firstname,
                              surname: assignee.Surname
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="deleteCustomer__title">
                      {this.props.t('DeleteCustomer.will_lose_visibility_of_finance_activity')}
                    </div>
                    <div className="deleteCustomer__innerPanel">
                      <DeleteCustomerActivityItem
                        icon="application"
                        count={numOfApplications}
                        text={this.props.t('DeleteCustomer.finance_application', {
                          count: { numOfApplications }
                        })}
                      />
                      <DeleteCustomerActivityItem
                        icon="finance-check"
                        count={deleteEligibilityChecks}
                        text={this.props.t('DeleteCustomer.finance_eligibility_check', {
                          count: { deleteEligibilityChecks }
                        })}
                      />
                      <DeleteCustomerActivityItem
                        icon="quote"
                        count={deleteSavedQuotes}
                        text={this.props.t('DeleteCustomer.saved_quote', {
                          count: { deleteSavedQuotes }
                        })}
                      />
                      <DeleteCustomerActivityItem
                        icon="self-service-deals"
                        count={deleteSelfServiceDeals}
                        text={this.props.t('DeleteCustomer.self_serviced_deal', {
                          count: { deleteSelfServiceDeals }
                        })}
                      />
                      <DeleteCustomerActivityItem
                        icon="car"
                        count={deleteCustomerActivity}
                        text={this.props.t('DeleteCustomer.vehicle_of_interest', {
                          count: { deleteCustomerActivity }
                        })}
                      />
                      <div className="deleteCustomer__innerPanelSmallprint">
                        {this.props.t('DeleteCustomer.data_retention_policy_we_will_retain_information')}
                      </div>
                    </div>
                    {customer && customer.Aliases && customer.Aliases.length > 1 && (
                      <div>
                        <div className="deleteCustomer__title">
                          {this.props.t('DeleteCustomer.more_linked_applicants_to_this_customer_record')}
                        </div>
                        <div className="deleteCustomer__innerPanel">
                          {customer.Aliases.map((alias, i) => (
                            <div key={i} className="deleteCustomer__alias">
                              {alias.Firstname} {alias.Surname}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {customer && customer.Aliases && customer.Aliases.length > 1 && (
                      <div>
                        <div className="deleteCustomer__title">
                          {this.props.t('DeleteCustomer.more_linked_applicants_to_this_customer_record')}
                        </div>
                        <div className="deleteCustomer__innerPanel">
                          {customer.Aliases.map((alias, i) => (
                            <div key={i} className="deleteCustomer__alias">
                              {alias.Firstname} {alias.Surname}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <MobxForm onSubmit={this.submit}>
                      <div className="deleteCustomer__inputText">
                        {this.props.t('DeleteCustomer.type_the_customers_email_address_to_confirm')}
                      </div>
                      <TextInputWrappingInput
                        value={this.state.Email}
                        onChange={this.setEmail}
                        tabIndex="1"
                        id="deleteEmail"
                        type="text"
                        placeholder={this.props.t('DeleteCustomer.email')}
                      />
                      <div className="deleteCustomer__buttons">
                        <div>
                          <Button type="button" buttonStyle="cancel" onClick={this.handleCancel}>
                            {this.props.t('DeleteCustomer.cancel')}
                          </Button>
                        </div>
                        <div>
                          <Button disabled={!(this.state.Email === customer.Email)} type="submit" id="deleteCustomer">
                            {this.props.t('DeleteCustomer.delete_customer')}
                          </Button>
                        </div>
                      </div>
                    </MobxForm>
                  </>
                ) : (
                  <ExclamationWarningWithButton
                    buttonText={this.props.t('DeleteCustomer.go_back')}
                    onClick={this.handleCancel}
                    message={this.props.t('DeleteCustomer.do_not_have_the_required_permission')}
                  />
                )}
              </div>
            </PanelContent>
          </Panel>
        </div>
      </Page>
    );
  }
}

DeleteCustomer.propTypes = {
  appStore: PropTypes.object,
  params: PropTypes.object,
  outletContext: {
    session: PropTypes.object
  }
};

export default compose(
  withParams,
  withNavigate,
  withTranslation('DeleteCustomer'),
  withOutletContext,
  inject(['appStore']),
  observer
)(DeleteCustomer);
