import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  /* Affordability Details FIELDS */

  draft.applicationFields.AffordabilityDetails!.ForeseenYearlyIncome = true;
  draft.applicationFields.AffordabilityDetails!.MortgageOrRental = true;
  draft.applicationFields.AffordabilityDetails!.HasOtherHouseholdIncome = true;
  draft.applicationFields.AffordabilityDetails!.OtherHouseholdIncome = true;

  /* Affordability Details VALIDATION */

  draft.validationRules.AffordabilityDetails!.Income!.GrossAnnual = 'required, currency, minlength:1, maxlength:37';
  draft.validationRules.AffordabilityDetails!.Income!.HasOtherHouseholdIncome = 'required';
  draft.validationRules.AffordabilityDetails!.Income!.OtherHouseholdIncome =
    'required:iftrue:HasOtherHouseholdIncome, currency';

  draft.validationRules.AffordabilityDetails!.PersonalCircumstances!.ForeseenYearlyIncome =
    'required:iftrue:ForeseeFutureDownturnInFinances, currency';

  draft.validationRules.AffordabilityDetails!.MonthlyExpenditure!.MortgageOrRental =
    'required, currency, minlength:1, maxlength:37';

  /* Personal Details VALIDATION */
  draft.validationRules.PersonalDetails!.FirstName =
    'required, alphaWithPunctuationAndSpaces, doesNotStartOrEndWithSpace, minlength:2, maxlength:25';
  draft.validationRules.PersonalDetails!.MiddleNames =
    'optional, alphaWithPunctuationAndSpaces, doesNotStartOrEndWithSpace, minlength:2, maxlength:40';
  draft.validationRules.PersonalDetails!.LastName =
    'required, alphaWithPunctuationAndSpaces, doesNotStartOrEndWithSpace, minlength:2, maxlength:40';
  draft.validationRules.PersonalDetails!.MobileTelephone = 'required, mobile';
  draft.validationRules.PersonalDetails!.HomeTelephone = 'optional, landline';
  draft.validationRules.PersonalDetails!.Nationality = 'required';

  /* Address History VALIDATION */
  draft.validationRules.AddressHistory!.Items![0]!.BuildingName =
    'required:unless:BuildingNumber, doesNotStartWithSpecialCharacter, extendedLenderAlphanumeric, doesNotStartOrEndWithSpace, minlength:1, maxlength:100';
  draft.validationRules.AddressHistory!.Items![0]!.BuildingNumber =
    'required:unless:BuildingName, doesNotStartWithSpecialCharacter, extendedLenderAlphanumeric, doesNotStartOrEndWithSpace, minlength:1, maxlength:100';

  draft.validationRules.AddressHistory!.Items![0]!.Street =
    'required, doesNotStartWithSpecialCharacter, lenderAlphanumeric, doesNotStartOrEndWithSpace, minlength:1, maxlength:100';
  draft.validationRules.AddressHistory!.Items![0]!.District =
    'optional, alphaAndWhitespace, doesNotStartOrEndWithSpace, minlength:1, maxlength:100';
  draft.validationRules.AddressHistory!.Items![0]!.PostTown =
    'required, lenderAlphanumeric, doesNotStartOrEndWithSpace, minlength:1, maxlength:100';
  draft.validationRules.AddressHistory!.Items![0]!.Postcode = 'required, alphaNumSpace, minlength:1, maxlength:8';

  /* Employment History VALIDATION */
  draft.validationRules.EmploymentHistory!.Items![0]!.Employer = 'required, minlength:1, maxlength:100';
  draft.validationRules.EmploymentHistory!.Items![0]!.Occupation = 'required, minlength:1, maxlength:100';

  /* Bank Details VALIDATION */
  draft.validationRules.BankDetails!.AccountName = 'required, minlength:1, maxlength:25';

  /* Application Status CTAs */
  draft.applicationStatuses.Accepted!.isEditable = false;
  draft.applicationStatuses.ConditionalAccept!.isEditable = false;
  draft.applicationStatuses.Intercepted!.isEditable = false;
  draft.applicationStatuses.NoDecisionYet!.isEditable = false;
  draft.applicationStatuses.NotProceeding!.isEditable = false;
  draft.applicationStatuses.NotTakenUp!.isEditable = false;
  draft.applicationStatuses.Other!.isEditable = false;
  draft.applicationStatuses.PaidOut!.isEditable = false;
  draft.applicationStatuses.Pending!.isEditable = false;
  draft.applicationStatuses.ProblemPayout!.isEditable = false;
  draft.applicationStatuses.Prospect!.isEditable = false;
  draft.applicationStatuses.Referred!.isEditable = false;
  draft.applicationStatuses.Rejected!.isEditable = false;
  draft.applicationStatuses.Submitted!.isEditable = false;
  draft.applicationStatuses.Submitting!.isEditable = false;
  draft.applicationStatuses.SentForPayout!.isEditable = false;
  draft.applicationStatuses.WaitingForFurtherInformation!.isEditable = false;
  draft.applicationStatuses.Unknown!.isEditable = false;
  draft.applicationStatuses.Error!.isEditableWhenFinalDecisionIsReceived = false;
});
