import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import ApplicationPage from './ApplicationPage';
import BankDetailsForm from '../forms/BankDetailsForm';
import { withRouter } from 'hocs/router';
import { compose } from 'redux';

class BankDetails extends React.Component {
  static propTypes = {
    params: PropTypes.object,
    application: PropTypes.object,
    validationRules: PropTypes.object,
    saveBankDetails: PropTypes.func,
    submitBankDetails: PropTypes.func
  };
  handleSave = (formData) => {
    this.props.saveBankDetails(this.props.params.applicantId, formData, this.props.application.CustomerType);
  };
  handleSubmit = (formData) => {
    const { dealershipId, consumerId, applicantId } = this.props.params;
    const nextPage =
      this.props.application.CustomerType === 'corporate'
        ? `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/applicationsummary`
        : `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/affordability`;
    this.props.submitBankDetails(formData, nextPage);
  };

  render() {
    return (
      <ApplicationPage title={this.props.t('BankDetails.bank_details')}>
        <BankDetailsForm
          defaultValues={this.props.application.BankDetails}
          onSubmit={this.handleSubmit}
          onSave={this.handleSave}
          validationRules={this.props.validationRules.BankDetails}
          savingState={this.props.application.sectionSavingState}
          submittingState={this.props.application.sectionSubmittingState}
          bankLookupError={this.props.application.bankLookupError}
          funderCode={this.props.application.Quote.FunderCode}
        />
      </ApplicationPage>
    );
  }
}

export default compose(withRouter, withTranslation('Application'))(BankDetails);
