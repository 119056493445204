import { types } from '../schema';

export default {
  BusinessAccountType: types.Object,
  BusinessPremisesType: types.Object,
  CompanySector: types.Object,
  CompanySectorExtended: types.Object,
  CompanyTypes: types.Object,
  DrivingLicenseType: types.Object,
  EmploymentBasis: types.Object,
  LenderStatus: types.Object,
  MaritalStatus: {
    Divorced: types.String,
    LivingWithPartner: types.String,
    Married: types.String,
    Separated: types.String,
    Single: types.String,
    Widowed: types.String
  },
  Nationality: types.Object,
  OccupationType: types.Object,
  Position: types.Object,
  Residency: types.Object,
  Title: types.Object,
  FinanceTitles: types.Object,
  CustomerTitles: types.Object,
  Gender: types.Object
};
