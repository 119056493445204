import { ApplicationStatusMetric, Metric, MetricType } from '../../types';

type LabelEntry = {
  key: string;
  value: string;
};

export const isValidMetric = (metric: MetricType): boolean => {
  return Object.values(Metric).includes(metric as typeof Metric[keyof typeof Metric]) || metric === 'all';
};

const createMetricLabelLookup = (): Record<MetricType, string> => {
  const reverseLookup: Record<MetricType, string> = {} as Record<MetricType, string>;

  Object.entries(Metric).forEach(([key, value]) => {
    reverseLookup[value as MetricType] = key;
  });

  return reverseLookup;
};

export const mapMetricsToLabels = (values: MetricType | MetricType[]): LabelEntry[] => {
  const valueArray = Array.isArray(values) ? values : [values];
  const reverseLookup = createMetricLabelLookup();

  return valueArray
    .filter((value) => isValidMetric(value))
    .map((value) => {
      const key = value as MetricType;
      const label = reverseLookup[key];
      return { key: value, value: label || value };
    });
};

// Array of application status metrics
export const applicationStatusMetrics: string[] = Object.values(ApplicationStatusMetric);
// Array of all metrics
export const allMetrics: MetricType[] = Object.values(Metric) as MetricType[];
