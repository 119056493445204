import React from 'react';
import { inject, observer } from 'mobx-react';

import { Outlet } from 'react-router-dom';
import { ToastNotification } from './components';
import { getThemeName } from 'theme';
import { setStockModuleCssVarTheme } from 'modules/Stock/styles/dealershipThemes';

@inject('appStore')
@observer
class StockContainer extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      themeName: getThemeName() || 'theme-default'
    };
  }

  componentDidMount() {
    const themeName = getThemeName();
    this.setState({
      themeName
    });
    // gives access to css vars for Stock Module only (not global), Can be lifted to global if need arises
    setStockModuleCssVarTheme(themeName, this.wrapperRef.current);
  }

  componentDidUpdate(_, prevState) {
    const themeName = getThemeName();

    if (prevState.themeName !== themeName) {
      this.setState({
        themeName
      });
      setStockModuleCssVarTheme(themeName, this.wrapperRef.current);
    }
  }

  componentWillUnmount() {
    this.props.appStore.stockStore.resetFacets();
    this.props.appStore.stockStore.resetVehicles();
  }
  render() {
    return (
      <>
        <div ref={this.wrapperRef} className="stockModuleContainer">
          <Outlet />
        </div>
        <ToastNotification />
      </>
    );
  }
}
export default StockContainer;
