import { Outlet } from 'react-router-dom';
import { compose } from 'redux';
import { observer, inject } from 'mobx-react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withParams } from 'hocs/router';
import { push } from 'routerHistory';
import * as applicationActions from '../../../redux/application/applicationActions';
import { getCustomer } from '../../../selectors/customerSelectors';
import { getLenderConfig } from '../../../core/lenders';

// This is the new application edit container component - first used for Personal Details
// The idea is for this to eventually replace the existing containers:
// - EditAPplicationAffordabilityContainer
// - EditApplicationBankDetailsContainer
// - EditApplicationEmploymentHistoryContainer

const EditApplicationContainer = (props) => {
  const outletContext = { ...props };

  return <Outlet context={outletContext} />;
};
function mapStateToProps(state, ownProps) {
  const customer = getCustomer(state, ownProps.params.consumerId.toLowerCase());
  return {
    consumer: customer,
    application: state.application,
    vehicle: state.application.Vehicle,
    validationRules: getLenderConfig(
      state.application.Quote && state.application.Quote.FunderCode,
      state.application.CustomerType,
      'validationRules'
    ),
    applicationFields: getLenderConfig(
      state.application.Quote && state.application.Quote.FunderCode,
      state.application.CustomerType,
      'applicationFields'
    ),
    editApplicationFieldsDisabled: getLenderConfig(
      state.application.Quote && state.application.Quote.FunderCode,
      state.application.CustomerType,
      'editApplicationFieldsDisabled'
    ),
    quotes: state.quotes,
    funders: state.funders
  };
}
function mapDispatchToProps(dispatch, ownProps) {
  return {
    handleSaveSection: (sectionName, formData) => {
      const { dealershipId, consumerId, applicantId } = ownProps.params;

      dispatch(
        applicationActions.saveApplicationSection(
          sectionName,
          applicantId,
          formData,
          `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit`
        )
      );
    },
    handleCancel: () => {
      const { dealershipId, consumerId, applicantId } = ownProps.params;
      push(`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit`);
    }
  };
}

export default compose(
  withParams,
  withTranslation('Application'),
  inject('appStore'),
  connect(mapStateToProps, mapDispatchToProps),
  observer
)(EditApplicationContainer);
