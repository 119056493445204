import _ from 'lodash';
import platformApiUtils from '@ivendi/platform-api-utils';
import * as actionTypes from '../actionTypes';

import { fetchOptions } from '../options/optionsActions';
import { fetchFundersInfo } from '../funder/funderActions';
import { navigateAfterSignIn } from '../navigation/navigationActions';
import { push } from 'routerHistory';

export function signIn(email, password, returnPath) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SIGN_IN });

    let onSuccess = (response) => {
      if (response?.Claims?.indexOf('iar') === -1) {
        dispatch({ type: actionTypes.SIGN_IN_SUCCESS, response });
        dispatch(fetchOptions());
        dispatch(fetchFundersInfo());
        dispatch(navigateAfterSignIn(returnPath));
      } else {
        onError();
      }
    };

    let onError = (err) => {
      dispatch({
        type: actionTypes.SIGN_IN_ERROR,
        hasIncorrectLogin: (err && err.status) === 401,
        hasSsoLoginError: _.get(err, 'body.Reason') === 'MNF SSO'
      });
    };

    platformApiUtils
      .post('v1/sign-in', {
        Username: email,
        Password: password
      })
      .then(onSuccess, onError);
  };
}

export function whoAmI() {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.WHO_AM_I });

    platformApiUtils.get('v1/whoami').then(
      function(response) {
        dispatch({ type: actionTypes.WHO_AM_I_SUCCESS, response });
        dispatch(fetchOptions());
        dispatch(fetchFundersInfo());
      },
      function() {
        dispatch({ type: actionTypes.WHO_AM_I_ERROR });
      }
    );
  };
}

export function updateWhoAmI() {
  return (dispatch) => {
    dispatch({
      types: [actionTypes.UPDATE_WHO_AM_I, actionTypes.UPDATE_WHO_AM_I_SUCCESS, actionTypes.UPDATE_WHO_AM_I_ERROR],
      callAPI: () => platformApiUtils.get('v1/whoami')
    });
  };
}

export function signOut(message) {
  platformApiUtils.get('v1/sign-out/').then(() => {
    if (!window.location.pathname.includes('/auth/signin')) {
      push('/auth/signin?returnpath=' + window.location.pathname);
    }
  });

  return { type: actionTypes.SIGN_OUT, message };
}

export function endSession() {
  return (dispatch) => {
    dispatch({ type: actionTypes.END_SESSION });

    if (!window.location.pathname.includes('/auth/signin')) {
      push('/auth/signin?returnpath=' + window.location.pathname);
    }
  };
}
