import { handleErrorResponse } from '../handleErrorResponse';
import * as debug from '~/debug';

export const handleRequest = async (url: string, init: RequestInit = {}) => {
  const { headers, ...restOfInit } = init;
  try {
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      ...restOfInit
    });

    if (!response.ok) {
      throw await handleErrorResponse(response);
    }

    return response;
  } catch (err) {
    debug.error(String(err));
    throw err;
  }
};
