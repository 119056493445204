import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import invert from 'lodash/invert';
import MediaQuery from 'react-responsive';

import Button from '~Common/Button/Button';
import ErrorApology from '~Common/ErrorApology';
import Pagination from '~Common/Pagination';
import Panel from '~Common/Panel';
import PanelContent from '~Common/PanelContent';
import PanelHeader from '~Common/PanelHeader';
import TrackPageView from '~Common/Tracking/TrackPageView';
import Icon from '~Common/Icon/Icon';

import { FilterIcon } from '../../shared/icons';
import { PushSidebar, StockListV2, StockListFilterV2 } from './components/index.js';
import SearchBar from './components/SearchBar';
import { StockBreadcrumbs, StockContainerLayout } from '../../shared/components/index.js';
import { withRouter } from '~/hocs/router';

import styles from './StockListContainer.module.scss';

const labelMap = { 1: 'good', 2: 'potential-issues', 3: 'severe-issues', 4: 'no-taxonomy' };
class StockListContainer extends React.Component {
  componentDidMount() {
    if (!localStorage.getItem('updatedVehicleIds')) {
      localStorage.setItem('updatedVehicleIds', JSON.stringify([]));
    }

    let { stockStore } = this.props.appStore;
    stockStore.setHitsPerPage(10);
    stockStore.auth(this.props.params.dealershipId);

    if (this.props.location.search) {
      const search = this.props.location.search;
      const params = new URLSearchParams(search);
      const q = params.get('q');

      q && this.handleSearch('stockSearch', q);

      const page = params.get('page');
      if (page) {
        if (Number(page) > 0) {
          stockStore.handlePageChange(page);
        }
      }

      const accuracyScoresParams = params.get('accuracyScores');
      if (accuracyScoresParams && accuracyScoresParams.length > 0) {
        const accuracyScores =
          accuracyScoresParams !== 'all'
            ? accuracyScoresParams.split(',').map((score) => Number(invert(labelMap)[score]))
            : [1, 2, 3, 4];
        accuracyScores
          // only toggle "on" inside componentDidMount
          .filter((score) => {
            return !toJS(stockStore.facets.accuracyScore).includes(score);
          })
          .forEach((score) => {
            this.handleToggleFilter('accuracyScore', score);
          });
      }
    }
  }

  handleSearch = (id, value) => {
    this.props.appStore.stockStore.updateSearchQuery(id, value);
    this.updateQuery({
      q: value
    });
  };

  updateQuery = (newParams) => {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const oldParams = {
      q: params.get('q'),
      accuracyScores: params.get('accuracyScores'),
      advertisedOnJuhu: params.get('advertisedOnJuhu')
    };
    this.props.navigate({
      pathname: `/d/${this.props.params.dealershipId}/stock/list`,
      query: { ...oldParams, ...newParams }
    });
  };

  renderPaginationSummary(stockStore) {
    if (!stockStore.vehicles.length) {
      return;
    }

    let total = stockStore.totalVehicles > 999 ? '1000+' : stockStore.totalVehicles;
    return (
      <div className={styles.paginationSummary}>
        {total} {this.props.t('StockListContainer.vehicles_in_your_dealership')}
      </div>
    );
  }

  handleSuggestionSelection = (value) => {
    let { stockStore } = this.props.appStore;
    stockStore.handleSuggestionSelection(value);
  };

  handlePageChange = (page) => {
    document.querySelector('.globalLayout__content-inner').scrollTo(0, 0);
    let { stockStore } = this.props.appStore;
    stockStore.handlePageChange(page);
    this.updateQuery({
      page
    });
  };

  handleToggleFilter = (filter, value) => {
    let { stockStore } = this.props.appStore;
    stockStore.toggleFilter(filter, value);

    const accuracyScores =
      stockStore.facets.accuracyScore.length === 4
        ? 'all'
        : [stockStore.facets.accuracyScore.map((score) => labelMap[score])];

    const advertisedOnJuhu =
      stockStore.facets.onJuhu.includes(true) && stockStore.facets.onJuhu.includes(false)
        ? 'all'
        : stockStore.facets.onJuhu;

    const platformFilter = advertisedOnJuhu.length;

    const query = platformFilter
      ? {
          accuracyScores,
          advertisedOnJuhu
        }
      : {
          accuracyScores
        };
    this.updateQuery(query);
  };

  handleAddClick = () => {
    this.props.navigate(`/d/${this.props.params.dealershipId}/stock/add/lookup`);
  };

  renderButton = () => {
    return (
      <div className={styles.addVehicleBtn}>
        <Button
          type="button"
          buttonStyle="primary"
          size="small"
          onClick={this.handleAddClick}
          dataThook="stockAddVehicleBtn"
        >
          {this.props.t('StockListContainer.add_vehicle')}
        </Button>
      </div>
    );
  };

  resetFacets = () => {
    this.props.appStore.stockStore.resetFacets();
    this.props.appStore.stockStore.fetchStock();
    this.props.navigate({
      pathname: this.props.location.pathname,
      query: undefined
    });
  };

  render() {
    let { stockStore, uiState } = this.props.appStore;
    const addVehicleEnabled = uiState.canAddVehicles;

    if (stockStore.hasError) {
      return <ErrorApology>{this.props.t('StockListContainer.error_fetching_stock_list')}</ErrorApology>;
    }

    return (
      <StockContainerLayout showSidebar={stockStore.showDataPending} testHook="Stock">
        <div>
          <TrackPageView dealershipId={this.props.params.dealershipId} />
          <StockBreadcrumbs
            items={[
              {
                name: this.props.t('Common.stock_list')
              }
            ]}
          />

          <div className={styles['filterAndSearchBarContainer']}>
            <StockListFilterV2 handleToggleFilter={this.handleToggleFilter} resetFacets={this.resetFacets} />
            <SearchBar
              id="stockSearch"
              onChange={this.handleSearch}
              value={stockStore.searchQuery}
              placeholder={this.props.t('StockListContainer.search_vrm_brand_model_etc')}
              dataThook={'stockSearch'}
            />
            {stockStore.hasActiveFacets && (
              <div data-th="clearFiltersTag" onClick={this.resetFacets} className={styles['resetTag']}>
                <FilterIcon /> {this.props.t('StockListFilter.clear_filters')}{' '}
                <span className={styles['resetTagCross']}>
                  <Icon name="cross-2" />
                </span>
              </div>
            )}
          </div>

          <Panel className="stockListContainer__panelOuter">
            <PanelHeader>
              <div className={styles.panelHeaderCont}>
                {this.props.t('StockListContainer.stock_list')}
                {addVehicleEnabled && <MediaQuery minWidth={769}>{this.renderButton()}</MediaQuery>}
              </div>
            </PanelHeader>
            <PanelContent>
              <>
                {!uiState.hasStockEnabled && (
                  <div className={styles.vehiclesNotFound}>
                    {this.props.t(
                      'StockListContainer.we_are_not_receiving_a_feed_of_your_stock_for_an_easier_quoting_journey_you_can_arrange_for_your_media_company_to_send_over_your_stock'
                    )}
                  </div>
                )}
                {addVehicleEnabled && <MediaQuery maxWidth={768}>{this.renderButton()}</MediaQuery>}
                <div className={styles.stockListContainer}>
                  <div className={styles.container}>
                    <div className={styles.results}>
                      <div data-th="paginationSummary" className={styles.paginationSummary}>
                        {this.renderPaginationSummary(stockStore)}
                      </div>
                      <div className={styles.searchSuggestions}>
                        {stockStore.suggestions.length > 0 && stockStore.searchQuery.length > 0 && (
                          <div className={styles.suggestionsContainer}>
                            {stockStore.suggestions.map((query, i) => {
                              return (
                                <div
                                  onClick={() => this.handleSuggestionSelection(query)}
                                  key={i}
                                  className={styles.suggestionListItem}
                                >
                                  {query}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                      <StockListV2
                        vehicles={stockStore.vehicles}
                        isLoadingStock={stockStore.isLoadingStock}
                        handleNewDataPending={stockStore.handleNewDataPending}
                      />
                    </div>
                  </div>
                </div>
                {stockStore.totalVehicles > 0 && (
                  <Pagination
                    currentPage={stockStore.currentPage + 1}
                    totalItems={stockStore.totalVehicles}
                    pageSize={stockStore.vehiclesPerPage}
                    onChange={this.handlePageChange}
                  />
                )}
              </>
            </PanelContent>
          </Panel>
        </div>

        {stockStore.showDataPending && <PushSidebar />}
      </StockContainerLayout>
    );
  }
}

StockListContainer.contextTypes = {
  router: PropTypes.object
};
StockListContainer.propTypes = {
  appStore: PropTypes.object,
  params: PropTypes.shape({
    dealershipId: PropTypes.string
  })
};
export default withRouter(withTranslation('Stock')(inject('appStore')(observer(StockListContainer))));
