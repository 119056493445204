import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { inject } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '~Common/Button/Button';
import Modal from '~Common/Modal/Modal';

import { isFieldEnabled } from '../../../../../../../../shared/helpers/vehicleFormFields';
import { StockVehicleFormFields } from '../../../../../../../../shared/components';

import './editVehicleForm.scss';

const EditVehicleForm = ({ vehicleTaxonomyData, handleSubmit, dealerPlatformUpload, appStore }) => {
  const { t } = useTranslation('Stock');
  const navigate = useNavigate();
  const { dealershipId, vehicleId } = useParams();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isTaxonomyModalOpen, setIsTaxonomyModalOpen] = useState(false);
  const { formState, watch } = useFormContext();

  const { setOverrideTaxonomy } = appStore.stockStore;

  const isSubmitting = formState.isSubmitting;
  const isSubmitUnsuccessful = !formState.isSubmitSuccessful;

  const primaryId = vehicleTaxonomyData?.primaryId || vehicleTaxonomyData?.PrimaryTaxonomyId;
  const secondaryId = vehicleTaxonomyData?.secondaryId || vehicleTaxonomyData?.SecondaryTaxonomyId;
  const isFeedVehicle = !dealerPlatformUpload;
  const hasTaxonomyData = (primaryId && secondaryId) || isFeedVehicle;
  const fieldEnabled = (fieldName) => isFieldEnabled(fieldName, watch('class'), hasTaxonomyData, dealerPlatformUpload);

  const handleShowModal = (modalName) => {
    switch (modalName) {
      case 'cancelModal':
        setIsCancelModalOpen(true);
        break;
      case 'taxonomyModal':
        setIsTaxonomyModalOpen(true);
        break;
      default:
        throw new Error(`Modal - ${modalName} does not exist`);
    }
  };

  const closeModal = (name) => {
    switch (name) {
      case 'cancelModal':
        setIsCancelModalOpen(false);
        break;
      case 'taxonomyModal':
        setIsTaxonomyModalOpen(false);
        break;
      default:
        throw new Error(`Modal ${name} does not exist`);
    }
  };

  const handleNavigation = (page) => {
    switch (page) {
      case 'stockListPage':
        navigate(`/d/${dealershipId}/stock/list`);
        break;
      case 'fixIssuesPage':
        setOverrideTaxonomy(true);
        navigate(`/d/${dealershipId}/stock/${vehicleId}/fix-issues`);
        break;
      default:
        throw new Error(`Invalid navigation page - ${page}`);
    }
  };

  return (
    <>
      <form>
        <StockVehicleFormFields
          fieldEnabled={fieldEnabled}
          isFeedVehicle={isFeedVehicle}
          onClick={() => handleShowModal('taxonomyModal')}
        />

        <div className="editVehicleForm__buttons">
          <div className="createVehicleForm__cancel-btn-cont">
            <Button type="button" buttonStyle="cancel" onClick={() => handleShowModal('cancelModal')}>
              {t('EditVehicleForm.cancel')}
            </Button>
          </div>

          <div className="createVehicleForm__submit-btn-cont">
            <Button
              onClick={handleSubmit}
              isLoading={isSubmitting}
              hasError={isSubmitUnsuccessful}
              disabled={isSubmitting}
              buttonStyle="primary"
            >
              {t('EditVehicleForm.update')}
            </Button>
          </div>
        </div>
      </form>
      <Modal
        title={t('EditVehicleForm.are_you_sure')}
        onClose={() => closeModal('cancelModal')}
        isOpen={isCancelModalOpen}
        onConfirm={() => handleNavigation('stockListPage')}
        buttonText={t('EditVehicleForm.i_understand')}
      >
        <div className="stockCreateContainer__confirmationModal">
          {t('EditVehicleForm.if_you_proceed_your_vehicle_will_not_be_saved_and_youll_be_returned_to_your_stock_list')}
        </div>
      </Modal>
      <Modal
        title={t('EditVehicleForm.are_you_sure')}
        onClose={() => closeModal('taxonomyModal')}
        isOpen={isTaxonomyModalOpen}
        onConfirm={() => handleNavigation('fixIssuesPage')}
        buttonText={t('EditVehicleForm.i_understand')}
      >
        <div className="stockCreateContainer__confirmationModal">
          {t('EditVehicleForm.if_you_proceed_override_taxonomy')}
        </div>
      </Modal>
    </>
  );
};

export default inject('appStore')(EditVehicleForm);
