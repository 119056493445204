import React from 'react';

export const WebshopIcon = () => (
  <svg viewBox="23.422 24.805 123.157 100.389" width="25px" height="25px">
    <path
      fill="white"
      d="M 135.846 24.805 L 34.154 24.805 C 28.236 24.805 23.422 30.118 23.422 36.648 L 23.422 113.351 C 23.422 119.881 28.236 125.194 34.154 125.194 L 135.847 125.194 C 141.765 125.194 146.579 119.882 146.579 113.351 L 146.579 36.649 C 146.579 30.118 141.764 24.805 135.846 24.805 Z M 136.579 113.352 C 136.579 114.464 136.029 115.124 135.847 115.195 L 34.19 115.199 C 33.971 115.124 33.421 114.464 33.421 113.351 L 33.421 58.95 L 136.579 58.95 L 136.579 113.352 Z M 136.579 48.95 L 33.421 48.95 L 33.421 36.649 C 33.421 35.559 33.949 34.902 34.176 34.806 L 135.81 34.8 C 136.029 34.876 136.579 35.536 136.579 36.649 L 136.579 48.95 Z M 43.798 42.052 C 43.798 44.339 41.322 45.768 39.342 44.625 C 38.422 44.094 37.856 43.113 37.856 42.052 C 37.856 39.765 40.332 38.336 42.313 39.479 C 43.232 40.01 43.798 40.991 43.798 42.052 Z M 52.249 42.052 C 52.249 44.339 49.773 45.768 47.793 44.625 C 46.873 44.094 46.307 43.113 46.307 42.052 C 46.307 39.765 48.783 38.336 50.764 39.479 C 51.683 40.01 52.249 40.991 52.249 42.052 Z M 60.409 42.052 C 60.409 44.339 57.933 45.768 55.953 44.625 C 55.033 44.094 54.467 43.113 54.467 42.052 C 54.467 39.765 56.943 38.336 58.924 39.479 C 59.843 40.01 60.409 40.991 60.409 42.052 Z"
      fill-rule="evenodd"
      clip-rule="evenodd"
    ></path>
  </svg>
);
