import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Icon from '~Common/Icon/Icon';
import Modal from '~Common/Modal/Modal';

import { StockVehicleDetailsSidebar, StockModuleVehicleCard } from './components';

import './StockVehicleSidePanel.scss';
import { useFormContext } from 'react-hook-form';

const StockVehicleSidePanel = () => {
  const { watch } = useFormContext();
  const { t } = useTranslation('Stock');
  const [showModel, setShowModal] = useState(false);

  const vehicle = {
    condition: watch('condition'),
    class: watch('class'),
    title: watch('title'),
    derivative: watch('derivative'),
    fuel: watch('fuelType'),
    transmission: watch('transmission'),
    description: watch('description'),
    attentionGrabber: watch('attentionGrabber'),
    options: watch('options'),
    doors: watch('doors'),
    vrm: watch('vrm'),
    price: watch('price'),
    cc: watch('cc'),
    colour: watch('colour'),
    vatStatus: watch('vatStatus'),
    mileage: watch('mileage'),
    bodyStyle: watch('bodyStyle'),
    bhp: watch('bhp'),
    imgUrl: watch('imgUrl'),
    make: watch('make'),
    model: watch('model'),
    dealerReference: watch('dealerReference'),
    vin: watch('vin')
  };
  return (
    <>
      <div className="StockVehicleSidePanel__container">
        <div className="StockVehicleSidePanel__vehicleCard">
          <StockVehicleDetailsSidebar vehicle={vehicle} />
        </div>
        <div className="StockVehicleSidePanel__buttons">
          <div data-th="previewAdvert" className="StockVehicleSidePanel__previewAd" onClick={() => setShowModal(true)}>
            <Icon name="eye" />
            <span>{t('StockVehicleSidePanel.preview_advert')}</span>
          </div>
        </div>
      </div>
      <Modal title="" onClose={() => setShowModal(false)} isOpen={showModel}>
        <div className="StockVehicleSidePanel__vehicleCardPreviewWrapper">
          <StockModuleVehicleCard vehicleRecord={vehicle} />
        </div>
      </Modal>
    </>
  );
};

StockVehicleSidePanel.propTypes = {
  handleChange: PropTypes.func,
  vehicle: PropTypes.object
};
export default StockVehicleSidePanel;
