import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from '../Common/Button/Button';
import Modal from '../Common/Modal/Modal';
import Checkbox from '../Common/Form/Checkbox';
import { observer, inject } from 'mobx-react';
import MobxFormLabel from '../MobxForm/MobxFormLabel';
import SelectInput from '../Common/Form/SelectInput';
import MobxFieldGroup from '../MobxForm/MobxFieldGroup';
import MobxForm from '../MobxForm/MobxForm';
import InputDate from '../Common/Form/InputDate';
import Validator from '../../validators/Validator';
import './exportClosedLeadsForm.scss';
class ExportClosedLeadsForm extends React.Component {
  constructor() {
    super();

    const validationRules = {
      leadType: 'required'
    };

    this.setUpValidation(validationRules);
  }

  UNSAFE_componentWillMount() {
    this.setInitialFormData();
  }

  setInitialFormData = (data) => {
    this.setState({
      formData: {
        startDate: moment()
          .startOf('month')
          .valueOf(),
        endDate: moment()
          .endOf('day')
          .valueOf(),
        shouldExportAsGroup: false
      },
      shouldExportAsGroup: false,
      isFormSubmitted: false
    });
  };

  setUpValidation(validationRules) {
    this.validator = new Validator();
    this.validator.setRules(validationRules);
  }

  onValidationSuccess() {
    this.setState({
      isConfirmModalOpen: true
    });
  }

  handleFieldChange = (id, value) => {
    this.setState((prevState) => {
      const currentState = prevState;
      const newState = Object.assign(currentState.formData, {
        [id]: value
      });
      this.validator.validate(newState);
      return { ...newState };
    });
  };

  onAccept = () => {
    this.props.onSubmit(this.state);
    this.setState({
      isConfirmModalOpen: false
    });
  };

  handleCloseConfirmModal = () => {
    this.setState({
      isConfirmModalOpen: false
    });
  };

  toggleExportAsGroup = (value) => {
    this.setState({ shouldExportAsGroup: value });
  };

  handleDateChange = (id, val) => {
    this.setState((prevState) => {
      const currentState = prevState;
      const newState = Object.assign(currentState.formData, {
        [id]: moment(val.currentTarget.value)
          .startOf('day')
          .valueOf()
      });
      return { ...newState };
    });
  };

  submitForm = () => {
    this.setState({ isFormSubmitted: true });
    this.validator.validate(this.state);
    if (!this.validator.errorCount) {
      this.onValidationSuccess();
    }
  };

  render() {
    const errors = this.validator.getErrors();
    return (
      <MobxForm onSubmit={this.submitForm}>
        <div className="exportClosedLeadsForm__inner">
          <MobxFieldGroup isInline isFormSubmitted={this.state.isFormSubmitted} error={errors.leadType}>
            <MobxFormLabel>
              <div>Report</div>
            </MobxFormLabel>
            <SelectInput
              id="leadType"
              type="select"
              label="Report"
              size="small"
              options="ExportLeadType"
              onChange={(id, val) => this.handleFieldChange(id, val)}
              value={this.state.formData.leadType}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline isFormSubmitted={this.state.isFormSubmitted}>
            <MobxFormLabel>
              <div>From</div>
            </MobxFormLabel>
            <InputDate
              value={this.state.formData.startDate}
              onChange={(val) => this.handleDateChange('startDate', val)}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline isFormSubmitted={this.state.isFormSubmitted}>
            <MobxFormLabel>
              <div>To</div>
            </MobxFormLabel>
            <InputDate value={this.state.formData.endDate} onChange={(val) => this.handleDateChange('endDate', val)} />
          </MobxFieldGroup>
          {this.props.appStore.uiState.canAccessGroupExports && (
            <div className="exportClosedLeadsForm__groupExports">
              <Checkbox value={this.state.shouldExportAsGroup} onChange={this.toggleExportAsGroup} />
              <div className="exportClosedLeadsForm__checkboxLabel">Export report for dealership group</div>
            </div>
          )}
        </div>
        <div className="exportClosedLeadsForm__buttonOuter">
          <div className="exportClosedLeadsForm__export" key="export">
            <Button buttonStyle="primary">Export</Button>
          </div>
          <div className="exportClosedLeadsForm__cancel" key="cancel">
            <Button buttonStyle="cancel" type="button" onClick={this.props.handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
        <Modal
          isOpen={this.state.isConfirmModalOpen}
          onClose={this.handleCloseConfirmModal}
          title="Your GDPR Responsibilities"
        >
          <div className="exportClosedLeadsForm__modal">
            <div className="exportClosedLeadsForm__modalText">
              This report contains high volumes of personally identifiable information. It is your responsibility as a
              data controller to ensure this report is used appropriately.
            </div>
            <div className="exportClosedLeadsForm__modalText">
              By continuing, you confirm that you will use this report in line with the GDPR, and are aware that this
              export will be recorded for audit purposes.
            </div>
            <div className="exportClosedLeadsForm__modalButton">
              <Button type="button" onClick={this.onAccept} buttonStyle="primary">
                I understand
              </Button>
            </div>
          </div>
        </Modal>
      </MobxForm>
    );
  }
}

ExportClosedLeadsForm.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  appStore: PropTypes.func
};

export default inject('appStore')(observer(ExportClosedLeadsForm));
