import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './page.scss';

const Page = ({ children, isLoading, className }) => (
  <div className={cn(isLoading ? 'page__outer page__isLoading' : 'page__outer', className)} key="page">
    {children}
  </div>
);

Page.propTypes = {
  children: PropTypes.any,
  isLoading: PropTypes.bool,
  className: PropTypes.string
};

export default Page;
