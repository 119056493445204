import { useTranslation } from 'react-i18next';
import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { pluralise } from '../../core/helpers';
import './fileUploadInput.scss';
import Button from './Button/Button';
import Icon from './Icon/Icon';
import Modal from './Modal/Modal';
import DeleteIddModal from '../Settings/components/DeleteIddModal';
import LoadingSpinner from './Loading/LoadingSpinner';

function FileUploadInput({
  onReceiveFile,
  files,
  maxFiles,
  removeFile,
  hasPrevFile,
  dealershipId,
  handleDelete,
  originalFilename,
  isPageLoading,
  handleEdit,
  maxFileSizeInKb,
  errorMessage,
}) {
  const { t } = useTranslation('Common');
  const onDrop = useCallback((acceptedFiles) => {
    onReceiveFile(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop
  });
  const [showCurrentFile, setShowCurrentFile] = useState(hasPrevFile);
  const [filesState, setFileState] = useState(files);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  useEffect(() => {
    setShowCurrentFile(hasPrevFile);
  }, [hasPrevFile]);
  useEffect(() => {
    setFileState(files);
    setShowCurrentFile(false);
    hasPrevFile && files.length > 0 && handleEdit();
  }, [files]);

  const handleModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteFile = () => {
    handleDelete();
    handleModalClose();
  };

  if (isPageLoading) {
    return (
      <div className="fileUploadInput__loading">
        <LoadingSpinner />
      </div>
    );
  }

  if (showCurrentFile) {
    return (
      <div>
        <div className="fileUploadInput__prevFileOuter">
          <div className="fileUploadInput__readyCard">
            {originalFilename}
            <div className="fileUploadInput__buttonOuter">
              <a
                href={`/platform/v1/public/dealership/${dealershipId}/initial-disclosure-document`}
                target="_blank"
                rel="noopener noreferrer"
                className="fileUploadInput__removeButton"
                title={t('FileUploadInput.preview')}
              >
                <Icon name="eye" />
              </a>
              <button title={t('FileUploadInput.edit')} className="fileUploadInput__removeButton" {...getRootProps()}>
                <input {...getInputProps()} />
                <Icon name="edit" />
              </button>
              <button preview="Delete" className="fileUploadInput__removeButton" onClick={handleModalOpen}>
                <Icon name="bin" />
              </button>
            </div>
          </div>
          <Modal
            isOpen={isDeleteModalOpen}
            onClose={handleModalClose}
            title={t('FileUploadInput.are_you_sure_you_want_to_delete')}
          >
            <DeleteIddModal handleDelete={handleDeleteFile} handleClose={handleModalClose} />
          </Modal>
        </div>
        {maxFileSizeInKb && <p className="fileUploadInput__maxFileSize">{t('FileUploadInput.max_file_size', { fileSize: maxFileSizeInKb / 1000 })}</p>}
        <p className="fileUploadInput__explainerText">{t('FileUploadInput.idd_is_now_available')}</p>
      </div>
    );
  }

  return (
    <div>
      <div className="fileUploadInput__fileUploader">
        <div className="fileUploadInput__uploader">
          {filesState && filesState.length > 0 ? (
            <div className="fileUploadInput__ready">
              <div>
                {filesState.map((file, index) => (
                  <div className="fileUploadInput__readyCard" key={index}>
                    {file.name}
                    <button className="fileUploadInput__removeButton" onClick={() => removeFile(index)}>
                      <Icon name="bin" />
                    </button>
                  </div>
                ))}
              </div>
              {maxFiles && filesState.length > maxFiles && (
                <div className="fileUploadInput__warning">
                  {t('FileUploadInput.expected_only', { count: maxFiles })}
                </div>
              )}
              {errorMessage && (
                <div className="fileUploadInput__warning">
                  {errorMessage}
                </div>
              )}
            </div>
          ) : (
            <div {...getRootProps()} className="fileUploadInput__uploaderInput">
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>{t('FileUploadInput.drop_the_file_here')}</p>
              ) : (
                <div className="fileUploadInput__uploaderInner">
                  <Button buttonStyle="secondary">
                    {t('FileUploadInput.upload_pdf')}
                  </Button>
                  <p>{t('FileUploadInput.or_drag_and_drop_pdf_file_here')}</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {maxFileSizeInKb && <p className="fileUploadInput__maxFileSize">{t('FileUploadInput.max_file_size', { fileSize: maxFileSizeInKb / 1000 })}</p>}
      <p className="fileUploadInput__explainerText">{t('FileUploadInput.idd_will_be_instantly_available')}</p>
    </div>
  );
}

FileUploadInput.propTypes = {
  onReceiveFile: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  maxFiles: PropTypes.number,
  removeFile: PropTypes.func.isRequired,
  hasPrevFile: PropTypes.bool,
  dealershipId: PropTypes.string,
  handleDelete: PropTypes.func,
  originalFilename: PropTypes.string,
  isPageLoading: PropTypes.bool,
  handleEdit: PropTypes.func,
  maxFileSizeInKb: PropTypes.number,
  errorMessage: PropTypes.string,
};
export default FileUploadInput;
