import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Input from './Input2';
import './yearsMonthsField.scss';

class YearsMonthsField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      years: props.value ? Math.floor(props.value / 12) : '',
      months: props.value ? props.value % 12 : '',
      yearsFocus: null,
      monthsFocus: null
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value === 0 && prevProps.value !== 0) {
      this.updateYearsMonthsState();
    }
  }

  updateYearsMonthsState = () => {
    this.setState({
      years: '',
      months: ''
    });
  };

  handleYearsChange = (event) => {
    this.setState(
      {
        years: event.target.value,
        months: this.state.months || 0
      },
      this.triggerChange
    );
  };

  handleMonthsChange = (event) => {
    this.setState(
      {
        months: event.target.value,
        years: this.state.years || 0
      },
      this.triggerChange
    );
  };

  triggerChange() {
    this.props.onChange(this.getTotalMonths());
  }

  getTotalMonths() {
    let { years, months } = this.state;
    if (/^\d+$/g.test(years.toString() + months.toString())) {
      if (years === '' || months === '') {
        if (years === '') {
          return parseInt(months, 10) || 0;
        } else if (months === '') {
          let intYears = parseInt(years, 10) || 0;
          return intYears * 12;
        }
      } else {
        let intYears = parseInt(years, 10) || 0;
        let intMonths = parseInt(months, 10) || 0;
        return intYears * 12 + intMonths;
      }
    } else {
      return years.toString() + months.toString();
    }
  }

  handleBlur = () => {
    this.props.onBlur && this.props.onBlur();
    this.setState({
      yearsFocus: false,
      monthsFocus: false,
      years: this.state.years === '' ? 0 : this.state.years,
      months: this.state.months === '' ? 0 : this.state.months
    });
  };

  handleYearsFocus = () => {
    this.setState({ yearsFocus: true });
  };

  handleMonthsFocus = () => {
    this.setState({ monthsFocus: true });
  };

  render() {
    const { id, name, isValid, isInvalid } = this.props;

    return (
      <div className="yearsMonthsField">
        <div className="yearsMonthsField__years">
          <Input
            id={id + '_Years'}
            name={name + '_Years'}
            value={this.state.years}
            type={'number'}
            onChange={this.handleYearsChange}
            onFocus={this.handleYearsFocus}
            onBlur={this.handleBlur}
            onWheel={(e) => e.target.blur()}
            pattern="\d*"
            isValid={isValid}
            isInvalid={isInvalid}
            maxLength={3}
            suffix={this.props.t('YearsMonthsField.years')}
          />
        </div>
        <div className="yearsMonthsField__months">
          <Input
            id={id + '_Months'}
            name={name + '_Months'}
            value={this.state.months}
            type={'number'}
            onChange={this.handleMonthsChange}
            onFocus={this.handleMonthsFocus}
            onBlur={this.handleBlur}
            onWheel={(e) => e.target.blur()}
            pattern="\d*"
            isValid={isValid}
            isInvalid={isInvalid}
            maxLength={2}
            suffix={this.props.t('YearsMonthsField.months')}
          />
        </div>
      </div>
    );
  }
}

YearsMonthsField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool
};

export default withTranslation('Common')(YearsMonthsField);
