import { FunderDetailsResponse, SupportServiceContent, SupportServiceSection } from '../../../api/contentService';
import { SupportServiceContentTypesEnum } from '../../../api/contentService/utils';

import phoneIcon from '../../../styles/icons/phone.svg';
import emailIcon from '../../../styles/icons/open-email.svg';
import productInfo from '../../../styles/icons/product-information.svg';

export const funderDetailsToServiceFormat = (funderDetails: FunderDetailsResponse): SupportServiceContent | null => {
  const funderService: SupportServiceContent = {
    name: funderDetails.Name || '',
    sections: [],
    id: funderDetails.Code,
    language: 'string',
    dateCreated: 'string'
  };
  const resources: SupportServiceSection = {
    resources: []
  };

  if (funderDetails.PhoneNumber) {
    resources.resources.push({
      type: SupportServiceContentTypesEnum['phone'],
      label: 'Phone',
      value: funderDetails.PhoneNumber
    });
  }
  if (funderDetails.Email) {
    resources.resources.push({
      type: SupportServiceContentTypesEnum['email'],
      label: 'Email',
      value: funderDetails.Email
    });
  }
  funderService.sections.push(resources);

  if (funderDetails.PhoneNumber || funderDetails.Email) {
    return funderService;
  }
  return null;
};

export type LenderContactItemType = 'phone' | 'email' | 'http' | 'website';

export const getContentIconByType = (type: LenderContactItemType) => {
  switch (type) {
    case 'phone':
      return phoneIcon;
    case 'email':
      return emailIcon;
    case 'http':
      return productInfo;
    case 'website':
      return productInfo;
  }
};

export const formatLink = (type: LenderContactItemType, value: string | number) => {
  switch (type) {
    case 'phone':
      return `tel:${value}`;
    case 'email':
      return `mailto:${value}?subject=Enquiry`;
    case 'http' || 'website':
      return `${value}`;
  }
};
