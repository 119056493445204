import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../Reporting/PartialSummarySections/SummaryTable/Table';
import TableHead from '../../Reporting/PartialSummarySections/SummaryTable/TableHead';
import TableRow from '../../Reporting/PartialSummarySections/SummaryTable/TableRow';
import TableCell from '../../Reporting/PartialSummarySections/SummaryTable/TableCell';
import { capitalize, getOptionKey } from '../../../core/helpers';
import MiniPanel from '../../Common/MiniPanel';
import ApplicationSummaryPanelHeader from '../../Application/components/ApplicationSummaryPanelHeader';
import './cfcVehicleSummaryTable.scss';

const CFCVehicleSummaryTable = ({ vehicle, t, showValidationTicks }) => {
  return (
    <MiniPanel>
      <ApplicationSummaryPanelHeader
        title={t('CFCVehicleSummaryTable.vehicle')}
        section={{}}
        showValidationTicks={showValidationTicks}
      />

      <div className="cfcVehicleSummaryTable__panel">
        <Table>
          <TableRow>
            <TableHead width="50%">{t('CFCVehicleSummaryTable.make')}</TableHead>
            <TableCell>{vehicle.Make}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead>{t('CFCVehicleSummaryTable.model')}</TableHead>
            <TableCell>{vehicle.Model}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead>{t('CFCVehicleSummaryTable.derivative')}</TableHead>
            <TableCell>{vehicle.Derivative}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead>{t('CFCVehicleSummaryTable.registration_number')}</TableHead>
            <TableCell>{vehicle.SanitizedVrm || vehicle.Vrm}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead>{t('CFCVehicleSummaryTable.condition')}</TableHead>
            <TableCell>{capitalize(vehicle.Condition)}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead>{t('CFCVehicleSummaryTable.class')}</TableHead>
            <TableCell>{vehicle.Class ? getOptionKey(vehicle.Class.toLowerCase()).text : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead>{t('CFCVehicleSummaryTable.mileage')}</TableHead>
            <TableCell>{vehicle.Mileage}</TableCell>
          </TableRow>
        </Table>
      </div>
    </MiniPanel>
  );
};

CFCVehicleSummaryTable.propTypes = {
  vehicle: PropTypes.object,
  showValidationTicks: PropTypes.bool
};

export default withTranslation('Quoting')(CFCVehicleSummaryTable);
