import React from 'react';
import PropTypes from 'prop-types';
import './percentageInput.scss';
import classnames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ManagedInput from './ManagedInput';

class PercentageInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasMouseOver: false,
      hasFocus: false
    };
  }

  handleMouseEnter = () => this.setState({ hasMouseOver: true });
  handleMouseLeave = () => this.setState({ hasMouseOver: false });

  handleFocus = () => {
    this.setState({ hasFocus: true });
  };
  handleBlur = () => {
    this.props.onBlur && this.props.onBlur();
    this.setState({
      hasFocus: false
    });
    if (this.props.trackingPage && window.ga) {
      window.ga('send', 'event', this.props.trackingPage, 'FieldBlur', this.props.id);
    }
  };

  render() {
    const inputStyles = classnames(
      this.props.pushDealStyle ? 'percentageInput__input--pushDeal' : 'percentageInput__input',
      this.props.showError &&
        !this.state.hasFocus &&
        !this.state.hasMouseOver &&
        !this.props.isValid &&
        !this.props.disabled &&
        'percentageInput__invalid',
      this.state.hasMouseOver && 'percentageInput__input--hover',
      this.state.hasFocus && 'percentageInput__input--focus',
      this.props.disabled && 'percentageInput__disabled',
      this.props.size === 'small' && 'percentageInput__input--small'
    );

    let suffixClasses = classnames(
      this.props.pushDealStyle ? 'percentageInput__suffix--pushDeal' : 'percentageInput__suffix',
      this.props.showError &&
        !this.state.hasFocus &&
        !this.state.hasMouseOver &&
        !this.props.isValid &&
        !this.props.disabled &&
        'percentageInput__suffix--invalid',
      this.state.hasMouseOver && 'percentageInput__suffix--hover',
      this.state.hasFocus && 'percentageInput__suffix--focus',
      this.props.size === 'small' && 'percentageInput__suffix--small'
    );

    let containerClasses = classnames(this.props.pushDealStyle ? 'percentageInput--pushDeal' : 'percentageInput');

    return (
      <div
        className={containerClasses}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onFocus={this.handleFocus}
      >
        <ManagedInput
          dataThook={this.props.dataThook}
          className={inputStyles}
          id={this.props.id}
          value={this.props.value}
          type="number"
          autoComplete="off"
          onFocus={this.props.onFocus}
          onBlur={this.handleBlur}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
          isValid={this.props.isValid}
        />
        <div className={suffixClasses}>%</div>
      </div>
    );
  }
}
PercentageInput.defaultProps = {
  pushDealStyle: false,
  disabled: false
};

PercentageInput.propTypes = {
  type: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  suffix: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  showError: PropTypes.bool,
  value: PropTypes.any,
  field: ImmutablePropTypes.mapContains({
    value: PropTypes.any,
    isValid: PropTypes.bool
  }),
  size: PropTypes.string,
  trackingPage: PropTypes.string,
  dataThook: PropTypes.string
};

PercentageInput.defaultProps = {
  type: 'text',
  valid: false,
  invalid: false
};

export default PercentageInput;
