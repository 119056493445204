import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getStockSettings, updateStockSettings } from '.';

export const SERVICE_PREFIX = 'stocksettings';

export const stockSettingsQueries = createQueryKeys(SERVICE_PREFIX, {
  detail: (dealershipId: Parameters<typeof getStockSettings>['0']) => ({
    queryKey: [dealershipId],
    queryFn: () => getStockSettings(dealershipId)
  })
});

// Need to look into mutation factory patterns
export const useModifyStockSettings = () => {
  const queryClient = useQueryClient();

  return useMutation(updateStockSettings, {
    onSuccess: (_, { dealershipId }) => {
      queryClient.invalidateQueries(stockSettingsQueries.detail(dealershipId).queryKey);
    }
  });
};
