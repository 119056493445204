import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './searchDate.scss';
import moment from 'moment';
import Select from './Form/Select';
import InputDate from './Form/InputDate';
import Icon from './Icon/Icon';

class SearchDate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDateInput: false,
      startDate: moment()
        .startOf('month')
        .valueOf(),
      endDate: moment()
        .endOf('day')
        .valueOf(),
      newDate: null,
      boundDate: null
    };
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.value === 'searchdate' && nextProps.value !== 'searchdate') {
      this.setState({
        showDateInput: false
      });
    }
  }

  onChange = (e) => {
    const sortDate = e.target.value;
    let from;
    let to;
    const value = sortDate;
    this.setState({
      showDateInput: value === 'searchdate'
    });

    e.preventDefault();

    if (value === 'searchdate') {
      this.setState({
        showDateInput: true
      });
    }

    if (value === 'yesterday') {
      from = moment()
        .startOf('day')
        .subtract(1, 'days')
        .valueOf();
      to = moment()
        .startOf('day')
        .valueOf();
    } else if (value === 'last7days') {
      from = moment()
        .startOf('day')
        .subtract(1, 'weeks')
        .valueOf();
    } else if (value === 'lastmonth') {
      from = moment()
        .subtract(30, 'days')
        .valueOf();
    }

    this.props.onChange(from, to, value);
  };

  onSubmit = (e) => {
    const from = this.state.startDate.valueOf();
    const to = this.state.endDate.valueOf();
    e.preventDefault();
    this.props.onChange(from, to, this.props.value);
    this.setState({
      showDateInput: true
    });
  };

  handleStartDateChange = (event) => {
    this.setState({
      startDate: moment(event.currentTarget.value)
        .startOf('day')
        .valueOf()
    });
  };

  handleEndDateChange = (event) => {
    this.setState({
      endDate: moment(event.currentTarget.value)
        .endOf('day')
        .valueOf()
    });
  };

  render() {
    let options = [
      { key: '', value: this.props.t('SearchDate.all') },
      { key: 'yesterday', value: this.props.t('SearchDate.yesterday') },
      { key: 'last7days', value: this.props.t('SearchDate.last_7_days') },
      { key: 'lastmonth', value: this.props.t('SearchDate.last_30_days') },
      { key: 'searchdate', value: this.props.t('SearchDate.search_date') }
    ];

    if (this.props.hideByDate) {
      options.shift();
    }

    return (
      <div className="searchDate" key="container">
        <div className="searchDate__selectContainer">
          <Select theme="alt" emptyValue={false} value={this.props.value} onChange={this.onChange} options={options} />
        </div>

        {this.state.showDateInput ? (
          <form className="searchDate__inputForm" onSubmit={this.onSubmit}>
            <div className="searchDate__inputContainer" key="inputs">
              <div className="searchDate__inputInner">
                <div className="searchDate__input searchDate__inputOne" key="inputOne">
                  <InputDate value={this.state.startDate} onChange={this.handleStartDateChange} />
                </div>

                <div className="searchDate__input searchDate__inputTwo" key="inputTwo">
                  <InputDate value={this.state.endDate} onChange={this.handleEndDateChange} />
                </div>
              </div>
              <button className="searchDate__button" key="button" type="submit">
                <Icon name="search" />
              </button>
            </div>
          </form>
        ) : (
          ''
        )}
      </div>
    );
  }
}

SearchDate.propTypes = {
  consumer: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  hideByDate: PropTypes.bool,
  value: PropTypes.string
};

export default withTranslation('Common')(SearchDate);
