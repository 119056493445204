import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Page from '../../Common/Page';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import MiniPanel from '../../Common/MiniPanel';
import MiniPanelHeader from '../../Common/MiniPanelHeader';
import VehiclePanel from '../../Reporting/PartialSummarySections/VehiclePanel';
import AccessoriesPanel from '../../Reporting/PartialSummarySections/AccessoriesPanel';
import DealerInfo from '../../Common/DealerInfo';
import FormFooter from '../../Common/Form/FormFooter';
import { withParams } from 'hocs/router';
import ConsumerDutyContainer from '../../ConsumerDuty/containers/ConsumerDutyContainer';
import { withTranslation } from 'react-i18next';
import { printPageByRef } from '~/core/print';
import QuotePanelV2 from '../../Reporting/PartialSummarySections/QuotePanelV2';
import { findCompanionQuoteByType } from '~/components/QuoteCard/utils/getQuoteDisplaySchema';
import { LoanTypeJs, QuoteSummaryVariantTypeJs } from '~/components/QuoteCard/types';
import { hasCompanionQuotes } from '~/features';

class SavedQuoteDetailsPage extends React.Component {
  constructor() {
    super();
    this.state = {
      showFairProcessing: false
    };
  }

  printAreaRef = React.createRef();

  handlePrint = () => {
    printPageByRef(this.printAreaRef);
  };

  handleCloseFairProcessingModal = () => {
    this.setState({ showFairProcessing: false });
  };

  handleSubmitFairProcessingModal = () => {
    this.props.handleApply(this.props.vehicle, this.props.quote, this.props.consumer.CustomerType);
  };

  handleApply = () => {
    this.setState({ showFairProcessing: true });
  };

  render() {
    const { dealershipId, consumerId } = this.props.params;

    const { t, consumer, quote: rootQuote } = this.props;

    let vehicleLoan;
    let vapsLoan;
    let negativeEquityLoan;
    if (hasCompanionQuotes(rootQuote)) {
      vehicleLoan = findCompanionQuoteByType(rootQuote.CompanionQuotes, LoanTypeJs.vehicleLoan);
      vapsLoan = findCompanionQuoteByType(rootQuote.CompanionQuotes, LoanTypeJs.vapsLoan);
      negativeEquityLoan = findCompanionQuoteByType(rootQuote.CompanionQuotes, LoanTypeJs.negativeEquityLoan);
    }

    return (
      <Page testHook="SaveQuoteDetailsPage">
        <Breadcrumbs
          items={[
            {
              name: t('SavedQuoteDetailsPage.home'),
              path: `/d/${dealershipId}`
            },
            {
              name: t('SavedQuoteDetailsPage.customer_list'),
              path: `/d/${dealershipId}/consumers`
            },
            {
              name: 'Consumer',
              path: `/d/${dealershipId}/consumers/${consumerId}`
            },
            {
              name: t('SavedQuoteDetailsPage.saved_quote')
            }
          ]}
          consumer={consumer}
        />
        {this.state.showFairProcessing ? (
          <ConsumerDutyContainer
            dealershipId={this.props.params.dealershipId}
            chosenQuote={rootQuote} // Missing (funder)ProductId and (funder)ProductCode
            customerType={consumer.CustomerType}
            onCancel={this.handleCloseFairProcessingModal}
            onContinue={this.handleSubmitFairProcessingModal}
          />
        ) : (
          <Panel noMargin={false}>
            <PanelHeader>{t('SavedQuoteDetailsPage.saved_quote_details')}</PanelHeader>
            <PanelContent>
              <div className="printArea" ref={this.printAreaRef}>
                <DealerInfo dealership={this.props.dealership} />
                <MiniPanel>
                  <MiniPanelHeader title={t('SavedQuoteDetailsPage.vehicle')} />
                  <VehiclePanel vehicle={this.props.vehicle} quote={rootQuote} />
                </MiniPanel>
                {rootQuote.Insurance === 0 &&
                rootQuote.Warranty === 0 &&
                rootQuote.OtherAccessories === 0 &&
                rootQuote.NonVatableItems === 0 ? (
                  ''
                ) : (
                  <MiniPanel>
                    <MiniPanelHeader title={t('SavedQuoteDetailsPage.vehicle_accessories')} />
                    <AccessoriesPanel quote={rootQuote} />
                  </MiniPanel>
                )}
                {/* Standard or Aggregate Quote START */}
                <MiniPanel>
                  <MiniPanelHeader title={t('SavedQuoteDetailsPage.quote')} />
                  <QuotePanelV2
                    quote={rootQuote}
                    loanType={LoanTypeJs.standardLoan}
                    variant={QuoteSummaryVariantTypeJs.SavedQuoteSummary}
                  />
                </MiniPanel>

                {/* Standard or Aggregate Quote END */}

                {/* Companion Quotes START */}
                {vehicleLoan && (vapsLoan || negativeEquityLoan) && (
                  <MiniPanel>
                    <h3 className="savedQuoteDetailsPage__multiloansHeader">
                      {t('SavedQuoteDetailsPage.multiple_finance_components')}
                    </h3>
                    <MiniPanelHeader title={t('SavedQuoteDetailsPage.vehicle_loan')} />
                    <QuotePanelV2
                      quote={vehicleLoan}
                      loanType={LoanTypeJs.vehicleLoan}
                      variant={QuoteSummaryVariantTypeJs.SavedQuoteSummary}
                      hasNegativeEquityLoan={negativeEquityLoan !== undefined}
                    />
                  </MiniPanel>
                )}
                {negativeEquityLoan && (
                  <MiniPanel>
                    <MiniPanelHeader title={t('SavedQuoteDetailsPage.negative_equity_loan')} />
                    <QuotePanelV2
                      quote={negativeEquityLoan}
                      loanType={LoanTypeJs.negativeEquityLoan}
                      variant={QuoteSummaryVariantTypeJs.SavedQuoteSummary}
                    />
                  </MiniPanel>
                )}
                {vapsLoan && (
                  <MiniPanel>
                    <MiniPanelHeader title={t('SavedQuoteDetailsPage.value_added_products_loan')} />
                    <QuotePanelV2
                      quote={vapsLoan}
                      loanType={LoanTypeJs.vapsLoan}
                      variant={QuoteSummaryVariantTypeJs.SavedQuoteSummary}
                    />
                  </MiniPanel>
                )}
                {/* Companion Quotes END */}
              </div>
              <FormFooter
                submitLabel={this.props.t(
                  `SavedQuoteDetailsPage.${this.props.quote.SupportsPreApproval ? 'get_pre_approval' : 'apply'}`
                )}
                hideSubmit={!this.props.appStore.uiState.canCreateApplications}
                onSubmit={this.handleApply}
                submittingState={this.props.proceedingState}
                onPrint={this.handlePrint}
              />
            </PanelContent>
          </Panel>
        )}
      </Page>
    );
  }
}

SavedQuoteDetailsPage.propTypes = {
  params: PropTypes.object,
  vehicle: PropTypes.object,
  quote: PropTypes.object,
  dealership: PropTypes.object,
  consumer: PropTypes.object,
  handleApply: PropTypes.func,
  handlePrint: PropTypes.func,
  proceedingState: PropTypes.string
};

export default withTranslation('Reporting')(withParams(SavedQuoteDetailsPage));
