import PropTypes from 'prop-types';
import CloseDealOptionsButtonCircle from './CloseDealOptionsButtonCircle';
import classnames from 'classnames';
import './closeDealOptionsButtonItem.scss';
import { Link } from 'react-router-dom';

const CloseDealOptionsButtonItem = ({ to, text, isActive }) => (
  <div className="closeDealOptionsButtonItem">
    <Link className="closeDealOptionsButtonItem__button" id={text.replaceAll(/([()\s])/g, '')} to={to}>
      <div
        className={classnames(
          'closeDealOptionsButtonItem__outer',
          isActive && 'closeDealOptionsButtonItem__outer--active'
        )}
      >
        <CloseDealOptionsButtonCircle isActive={isActive} />
        <div className="closeDealOptionsButtonItem__text">{text}</div>
      </div>
    </Link>
  </div>
);

CloseDealOptionsButtonItem.propTypes = {
  text: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  to: PropTypes.string
};

export default CloseDealOptionsButtonItem;
