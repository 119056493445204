import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './label.module.scss';

type Props = {
  children: React.ReactNode;
  htmlFor?: string;
  size?: 'small';
  className?: string;
};

const Label = ({ htmlFor, children, size, className }: Props) => {
  const classNames = cn(className, styles.label, size === 'small' && 'Label--small');

  return (
    <label className={classNames} htmlFor={htmlFor}>
      {children}
    </label>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  htmlFor: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string
};

export default Label;
