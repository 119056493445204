import React from 'react';
import s from './subModal.module.scss';

const SubModal = ({ title, paragraph, children }) => {
  return (
    <div className={s.subModal}>
      <h3 className={s.title}>{title}</h3>
      <p className={s.paragraph}>{paragraph}</p>
      <div className={s.actionButtons}>{children}</div>
    </div>
  );
};

export default SubModal;
