import { takeEveryByComponentName } from '../helpers';
import { put, call } from 'redux-saga/effects';
import { componentMount, componentError } from '../component/componentActions';
import * as actionTypes from '../actionTypes';
import { logError } from '../../core/helpers';
import { fetchCustomerIfNeeded } from '../customer/customer';

export function* watchEditCustomerContainer() {
  yield* takeEveryByComponentName(actionTypes.COMPONENT_LOAD, 'EditCustomerContainer', handleLoadEditCustomerContainer);
}

export function* handleLoadEditCustomerContainer(action) {
  try {
    yield call(fetchCustomerIfNeeded, action.params.consumerId);
  } catch (e) {
    logError(e);
    yield put(componentError('EditCustomerContainer', 'Customer not found.', e));
    return;
  }

  yield put(componentMount('EditCustomerContainer'));
}
