import _ from 'lodash';

export const getSession = (state) => {
  return _.get(state, 'session');
};

export const getDefaultCustomerType = (state) => {
  const settings = _.chain(state.session.Dealerships)
    .find({ Id: state.dealership.Id })
    .get('Settings')
    .value();

  return settings.UsageType === 'business' ? 'corporate' : 'consumer';
};
