import React from 'react';
import classNames from 'classnames';
import './card.scss';

interface CardProps {
  children: React.ReactNode;
  className?: string;
  withBorder?: boolean;
  onClick?: () => void;
}

const Card = (props: CardProps) => {
  const { children, className, onClick } = props;
  return (
    <div
      className={classNames('card', className && className, props.withBorder && 'card__withBorder')}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Card;
