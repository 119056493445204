import React from 'react';
import CircleConsumerImage from '../Customer/components/ConsumerRecordItems/CircleConsumerImage';
import './userIcon.scss';

const UserIcon = () => {
  return (
    <div className="userIcon__outer">
      <div className="userIcon__imageContainer" key="imageContainer">
        <div className="userIcon__image">
          <CircleConsumerImage />
        </div>
      </div>
    </div>
  );
};

export default UserIcon;
