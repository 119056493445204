import { useMemo } from 'react';

const useNow = () => {
  const now = useMemo(() => {
    return new Date().toISOString();
  }, []);

  return now;
};

export default useNow;
