import React from 'react';
import PropTypes from 'prop-types';
import BreadcrumbItem from './BreadcrumbItem';
import _ from 'lodash';
import { titleCase } from '../../core/helpers';
import './breadcrumbs.scss';
import Icon from './Icon/Icon';
import { withNavigate } from 'hocs/router';

class Breadcrumbs extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    consumer: PropTypes.object,
    application: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      isCollapsed: false
    };

    this.naturalWidth = 0;
    this.onResize = this.getThrottledResizeHandler();
  }

  shouldCollapse = () => {
    return this.naturalWidth > this.refs.breadcrumbsOuter.offsetWidth;
  };

  setCollapsed = () => {
    if (this.refs.breadcrumbsOuter) {
      this.setState({
        isCollapsed: this.shouldCollapse()
      });
    }
  };

  getThrottledResizeHandler = () => {
    const throttleMs = 300;

    return _.throttle(this.setCollapsed, throttleMs);
  };

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  componentDidMount() {
    if (this.refs.breadcrumbsInner) {
      this.naturalWidth = this.refs.breadcrumbsInner.offsetWidth;
      this.setCollapsed();
    }
  }

  handleBack(item) {
    return () => this.props.navigate({ pathname: item.path, query: item.query });
  }

  getVehicle() {
    let vehicleId =
      this.props.application && this.props.application.Vehicle && this.props.application.Vehicle.VehicleId;

    if (vehicleId) {
      return _.find(this.props.consumer.VehiclesOfInterest, { VehicleId: vehicleId });
    }
  }

  getItem(item) {
    let consumer = this.props.consumer;

    switch (item.name) {
      case 'Consumer':
        let vehicle = this.getVehicle();

        let query = vehicle ? { vehicleId: vehicle.VehicleId } : {};

        return {
          name:
            consumer.CustomerType === 'Corporate'
              ? consumer.TradingName
              : titleCase(`${consumer.Firstname} ${consumer.Surname}`),
          path: item.path,
          query: query
        };

      default:
        return item;
    }
  }

  getItems() {
    return this.props.items.map((item) => this.getItem(item));
  }

  render() {
    let items = this.getItems();

    return (
      <div className="breadcrumbs__outer" ref="breadcrumbsOuter">
        <ul className="breadcrumbs__ul" ref="breadcrumbsInner">
          {this.state.isCollapsed ? (
            <span>
              <div className="breadcrumbs__collapsed" onClick={this.handleBack(items[items.length - 2])}>
                <div className="breadcrumbs__backButton">
                  <Icon name="left" />
                </div>
              </div>
              <BreadcrumbItem item={_.last(items)} isLast={true} isFirst={true} isCollapsed={true} />
            </span>
          ) : (
            items.map((item, index) => (
              <BreadcrumbItem
                item={item}
                key={`breadcrumb-item-${index}`}
                isLast={index === items.length - 1}
                isFirst={index === 0}
                isCollapsed={false}
              />
            ))
          )}
        </ul>
      </div>
    );
  }
}

export default withNavigate(Breadcrumbs);
