import React from 'react';
import PropTypes from 'prop-types';
import './eligibilityWrapper.scss';

const EligibilityWrapper = ({ children }) => <div className="eligibilityWrapper">{children}</div>;

EligibilityWrapper.propTypes = {
  children: PropTypes.node
};
export default EligibilityWrapper;
