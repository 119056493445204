import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import VideoTop from '../../Common/VideoTop';
import FCAVideos from '../../Common/FCAVideos';
import classnames from 'classnames';
import { getFinanceTypeWistia } from '../../../core/quoteGetFinanceTypeWistia';
import benefitsAndRisksMessages from '../benefitRisksMessages';
import './benefitsAndRisks.scss';

const BenefitsAndRisks = ({ quote, hideVideo, email, children }) => {
  const { t } = useTranslation('Application');
  const financeNameWistia = getFinanceTypeWistia(quote.FinanceType, true);
  const { financeTypeName, wistiaSource } = financeNameWistia;
  const benefitsAndRisks = benefitsAndRisksMessages();
  let hideVideoClasses = classnames(
    'benefitsAndRisks__benefitsAndRisksHeaders',
    hideVideo && 'benefitsAndRisks__printHeading',
    !hideVideo && 'benefitsAndRisks__heading'
  );
  return (
    <div className="benefitsAndRisks__body">
      <div className="benefitsAndRisks__bodyInner">
        {wistiaSource !== '' ? (
          <>
            {hideVideo ? (
              ''
            ) : (
              <div className="benefitsAndRisks__bodySection">
                <div key="benefits-video" className="benefitsAndRisks__video">
                  <VideoTop />
                  <FCAVideos source={wistiaSource} email={email} />
                </div>
              </div>
            )}
            {hideVideo ? (
              <div className="benefitsAndRisks__printProduct">
                <h1 className="benefitsAndRisks__printTitle">
                  {financeTypeName} {quote.FinanceType === 'LP' && t('BenefitsAndRisks.or_hp_balloon')}
                </h1>
                <p className="benefitsAndRisks__printParagraph">
                  {financeTypeName} {quote.FinanceType === 'LP' && t('BenefitsAndRisks.also_known_as_hp_balloon')}{' '}
                  {t(
                    'BenefitsAndRisks.has_been_selected_please_review_the_benefits_and_risks_which_are_detailed_below_the_product_video_contains_additional_information_which_we_recommend_is_viewed_prior_to_the_application'
                  )}
                </p>
              </div>
            ) : (
              <div className="benefitsAndRisks__bodySection" xs="12" md="6" padding="0">
                <h1 className="benefitsAndRisks__title">
                  {financeTypeName} {quote.FinanceType === 'LP' && t('BenefitsAndRisks.or_hp_balloon')}
                </h1>
                <p className="benefitsAndRisks__paragraph">
                  {financeTypeName} {quote.FinanceType === 'LP' && t('BenefitsAndRisks.also_known_as_hp_balloon')}{' '}
                  {t(
                    'BenefitsAndRisks.has_been_selected_please_review_the_benefits_and_risks_which_are_detailed_below_the_product_video_contains_additional_information_which_we_recommend_is_viewed_prior_to_the_application'
                  )}
                </p>
              </div>
            )}
          </>
        ) : (
          <>
            <h1 className="benefitsAndRisks__title">{financeTypeName}</h1>
            <p className="benefitsAndRisks__paragraph--novideo">
              {t(
                'BenefitsAndRisks.a_creation_personal_loan_can_be_used_to_fund_the_purchase_of_value_added_products_such_as_gap_insurance_or_warranty_or_negative_equity'
              )}
            </p>
          </>
        )}
      </div>
      <div key="text2" className="benefitsAndRisks__disclaimerText">
        <div className={hideVideo ? 'benefitsAndRisks__printBenefitsAndRisks' : 'benefitsAndRisks__benefitsAndRisks'}>
          <div className="benefitsAndRisks__benefits" key="benefits">
            <h2 key="heading7" className={hideVideoClasses}>
              {t('BenefitsAndRisks.benefits')}
            </h2>
            {hideVideo ? '' : <div className="benefitsAndRisks__divider" />}
            <ul className="benefitsAndRisks__benefitsUL">
              {benefitsAndRisks[quote.FinanceType].benefits.map(function(element, index) {
                return <li key={index}>{element}</li>;
              })}
            </ul>
          </div>
          <div className="benefitsAndRisks__risks" key="risks">
            <h2 key="heading8" className="benefitsAndRisks__heading benefitsAndRisks__benefitsAndRisksHeaders">
              {t('BenefitsAndRisks.risks')}
            </h2>
            {hideVideo ? '' : <div className="benefitsAndRisks__divider" />}
            <ul className="benefitsAndRisks__risksUL">
              {benefitsAndRisks[quote.FinanceType].risks.map(function(element, index) {
                return <li key={index}>{element}</li>;
              })}
            </ul>
          </div>
        </div>
      </div>
      {!hideVideo && <div className="benefitsAndRisks__divider2" />}
      {children}
    </div>
  );
};

BenefitsAndRisks.propTypes = {
  quote: PropTypes.object.isRequired,
  hideVideo: PropTypes.bool,
  email: PropTypes.string.isRequired,
  applicationLoadingState: PropTypes.string,
  children: PropTypes.any
};
export default BenefitsAndRisks;
