import { legendPadding } from '../helpers/legendPadding';

export const getChartOptions = (showLegend: boolean, data: any) => ({
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: data && showLegend ? true : false,
      position: 'top' as const,
      align: 'start' as const,
      onClick: () => {}
    },
    ...(showLegend ? { legendPadding } : {})
  },
  interaction: {
    intersect: false
  }
});
