import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import PushDealActivityContainerSection from './PushDealActivityContainerSection';
import MoneyFormat from '../Common/MoneyFormat';
import './pushDealPartExDetails.scss';

const PushDealPartExDetails = ({ currentDeal }) => {
  const { t } = useTranslation('PushDeal');
  return (
    <div className="pushDealPartExDetails">
      <PushDealActivityContainerSection title={t('PushDealPartExDetails.value')}>
        <MoneyFormat value={currentDeal.quotes[0].partExchange} />
      </PushDealActivityContainerSection>
      <PushDealActivityContainerSection title={t('PushDealPartExDetails.outstanding_finance')}>
        <MoneyFormat value={currentDeal.quotes[0].outstandingSettlement} />
      </PushDealActivityContainerSection>
      <PushDealActivityContainerSection title={t('PushDealPartExDetails.equity')}>
        <MoneyFormat value={currentDeal.quotes[0].partExchange - currentDeal.quotes[0].outstandingSettlement} />
      </PushDealActivityContainerSection>
    </div>
  );
};

PushDealPartExDetails.propTypes = {
  currentDeal: PropTypes.object
};
export default PushDealPartExDetails;
