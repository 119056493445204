import React from 'react';
import PropTypes from 'prop-types';
import './leagueTableRow.scss';

const LeagueTableRow = ({ children }) => <tr className="leagueTableRow">{children}</tr>;

LeagueTableRow.propTypes = {
  children: PropTypes.node
};

export default LeagueTableRow;
