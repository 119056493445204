import React from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';

import MoneyFormat from '~Common/MoneyFormat';
import Vrm from '~Common/Vrm';

import { StockVehicleImage } from './components';
import { VehicleNumber } from '../../../../../shared/components';

import './StockVehicleDetailsSidebar.scss';

const StockVehicleDetailsSidebar = ({ vehicle, appStore }) => {
  const {
    imgUrl,
    vrm = '-',
    class: klass,
    price = '-',
    offerPrice,
    make = '-',
    model = '-',
    derivative = '-',
    dealerReference,
    vin
  } = vehicle;

  const { showVRM, showVehicleNumber } = appStore.uiState;

  return (
    <div className="StockVehicleDetailsSidebar">
      <div className="StockVehicleDetailsSidebar__image">
        <StockVehicleImage vehicleImageUrl={imgUrl} vehicleClass={klass} />
      </div>
      <div className="StockVehicleDetailsSidebar__vehicleDetails">
        <div className="StockVehicleDetailsSidebar__vehicleInfo">
          <span className="StockVehicleDetailsSidebar__vehicleMake">{`${make} ${model}`}</span>
          <span className="StockVehicleDetailsSidebar__vehicleDerivative">{derivative}</span>
        </div>
        {showVRM ? <Vrm vrm={vrm || ' '} /> : null}
        {showVehicleNumber ? <VehicleNumber value={dealerReference || vin} /> : null}
        <div className="StockVehicleDetailsSidebar__vehiclePricing">
          <div className="StockVehicleDetailsSidebar__vehiclePrice">
            <span className={offerPrice ? 'StockVehicleDetailsSidebar__vehiclePrice--strikeout' : ''}>
              <MoneyFormat value={price} />
            </span>
          </div>
          <div className="StockVehicleDetailsSidebar__vehicleOfferPrice">
            {offerPrice && (
              <span>
                <MoneyFormat value={offerPrice} />
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

StockVehicleDetailsSidebar.propTypes = {
  vehicle: PropTypes.object
};

export default inject('appStore')(StockVehicleDetailsSidebar);
