import React from 'react';
import PropTypes from 'prop-types';
import './quoteTableHeaderItem.scss';
import classnames from 'classnames';

const TableHeaderItem = (props) => {
  const thStyles = classnames(
    'quoteTableHeaderItem',
    props.fieldName !== undefined && 'quoteTableHeaderItem__thInteractive',
    props.altStyle && 'quoteTableHeaderItem__alt',
    props.fieldName === props.sortField && 'quoteTableHeaderItem__active'
  );
  return (
    <th className={thStyles} onClick={() => props.fieldName && props.onClick(props.fieldName)}>
      {props.text}
      {props.fieldName === props.sortField && props.fieldName !== undefined && (
        <span>
          {props.arrowDirection === 'asc' ? <span>↑</span> : ''}
          {props.arrowDirection === 'desc' ? <span>↓</span> : ''}
        </span>
      )}
    </th>
  );
};

TableHeaderItem.propTypes = {
  onClick: PropTypes.func,
  arrowDirection: PropTypes.string,
  fieldName: PropTypes.string,
  sortField: PropTypes.string,
  text: PropTypes.string,
  altStyle: PropTypes.bool
};

export default TableHeaderItem;
