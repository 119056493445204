import { inject } from 'mobx-react';
import { useState } from 'react';
import Button from '~/components/Common/Button/Button';
import Input from '~/components/Common/Form/Input';
import {
  LookupAddressResult,
  LookupPostcodeResult,
  useAddressLookup,
  usePostcodeLookup
} from '~/api/postcodeLookup/postcodeLookupApi';
import { AppStore } from '~/modules/Stock/types/Types';
import './postcodeLookup.scss';
import Label from '~/components/Common/Form/Label';
import { useTranslation } from 'react-i18next';
import InlineExclamationWarning from '~/components/Common/InlineExclamationWarning';
import classNames from 'classnames';

export interface PostcodeLookupProps {
  onSubmit: (address: LookupAddressResult) => void;
  defaultValue?: string;
  appStore?: AppStore;
  inline?: boolean;
}

const PostcodeLookup = ({ onSubmit, defaultValue, appStore, inline }: PostcodeLookupProps) => {
  const { countryCode } = appStore?.uiState || {};
  const { t } = useTranslation('Common');
  const [postcode, setPostcode] = useState(defaultValue || '');
  const [addresses, setAddresses] = useState<LookupPostcodeResult[]>();
  const { mutate: postcodeLookup, isLoading: isPostcodeLookupLoading, isError } = usePostcodeLookup(
    countryCode!,
    setAddresses
  );
  const handleSubmit = (data: LookupAddressResult) => {
    setAddresses([]);
    onSubmit(data);
  };
  const { mutate: addressLookup, isLoading: isAddressLookupLoading } = useAddressLookup(handleSubmit);
  const handlePostcodeLookup = () => {
    setAddresses([]);
    postcodeLookup(postcode);
  };
  const isLoading = isPostcodeLookupLoading || isAddressLookupLoading;
  return (
    <div className="postcodeLookup" data-th="postcodeLookup">
      <div className={classNames('postcodeLookup__field', inline && 'postcodeLookup__field--inline')}>
        <Label>{t('PostcodeLookup.postcode_lookup')}</Label>
        <div className="postcodeLookup__field--input">
          <Input id="postcodeLookup" value={postcode} onChange={(evt: any) => setPostcode(evt.target.value)} />
          <Button
            dataTestId="postcodeLookup__find"
            onClick={handlePostcodeLookup}
            isLoading={isPostcodeLookupLoading}
            hasError={isError}
            type="button"
          >
            {t('PostcodeLookup.find')}
          </Button>
        </div>
        {inline && <div className="postcodeLookup__field--spacer" />}
      </div>
      {isError && (
        <InlineExclamationWarning dataTh="postcodeLookup__UnableToLocatePostcodeError">
          {t('PostcodeLookup.unable_to_locate_postcode')}
        </InlineExclamationWarning>
      )}
      {addresses && addresses.length > 0 && (
        <div className={classNames('postcodeLookup__field', inline && 'postcodeLookup__field--inline')}>
          <Label>{t('PostcodeLookup.select_your_address')}</Label>
          <select
            className="postcodeLookup__field--select"
            data-th="postcodeLookup__addressSelect"
            disabled={isLoading}
            onChange={(evt) => addressLookup(evt.currentTarget.value)}
          >
            {isLoading && <option key="optionDefault">{t('PostcodeLookup.loading')}</option>}
            {!isLoading && (
              <>
                <option key="optionDefault">- - -</option>
                {addresses.map((address, i) => (
                  <option value={address.Id} key={`option${i}`}>
                    {address.Suggestion}
                  </option>
                ))}
              </>
            )}
          </select>
          {inline && <div className="postcodeLookup__field--spacer" />}
        </div>
      )}
    </div>
  );
};

export default inject('appStore')(PostcodeLookup);
