import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { compose } from 'redux';
import { inject } from 'mobx-react';
import observerForHooks from 'hocs/observerForHooks';
import { AppStore } from '~/modules/Stock/types/Types';
import Button from '../../../components/Common/Button/Button';
import PanelHeader from '../../../components/Common/PanelHeader';
import PanelFooter from '../../../components/Common/PanelFooter';
import AlertCard from '../../../components/Common/AlertCard/AlertCard';
import styles from './lenderQuestionnaire.module.scss';

import {
  QuestionProps,
  getSuitabilityContent,
  getCommissionDisclosureQuestion,
  getRepaymentContent,
  RepaymentContent,
  CheckboxQuestion,
  SuitabilityContent
} from '../../../api/suitability/config';
import { FunderType, VehicleLoanDataType } from '~/components/QuoteCard/types';
import Divider from '../../../components/Common/Divider';
import CheckboxWithLabel from '../../../components/Common/Form/CheckboxWithLabel';
import { getFinanceTypeWistia } from '../../../core/quoteGetFinanceTypeWistia';
import { Question } from './Question';
import { useTrackPageViewed } from '../../../tracking/hooks';
import { trackFinanceProductSuitabilityQuestionAndAnswer } from '../../../tracking/avoTracking';
import { FacilityType, getFundersContentFilters } from '~/api/contentService/utils';
import { connect } from 'react-redux';
import { canSeeCommissionDisclosureQuestion } from '~/features';
import { useFundersContent } from '~/api/contentService/hooks';

export type SuitabilityQuestionsProps = {
  funders: any;
  dealership: any;
  quote: VehicleLoanDataType;
  financeType: FacilityType;
  dealershipId: string;
  onContinue: () => void;
  onCancel: () => void;
  appStore?: AppStore;
};

export type QuestionState = Record<string, string>;

export const hasSelectedNo = (selectedQuestions: QuestionState): boolean =>
  Object.values(selectedQuestions).includes('false');

export const hasAnsweredAll = (selectedQuestions: QuestionState, allQuestions: number): boolean =>
  Object.values(selectedQuestions).length > 0 && Object.values(selectedQuestions).length === allQuestions;

const SuitabilityQuestions = ({
  quote,
  funders,
  dealership,
  financeType,
  dealershipId,
  onContinue,
  onCancel,
  appStore
}: SuitabilityQuestionsProps) => {
  const { t } = useTranslation('Quoting');
  const [selectedQuestions, setSelectedQuestions] = useState<QuestionState>({});
  const { data: fundersContent } = useFundersContent(getFundersContentFilters(quote?.FunderCode));
  const { uiState } = appStore!;

  useTrackPageViewed('Product Understanding and Suitability', dealershipId);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    trackFinanceProductSuitabilityQuestionAndAnswer({
      dealershipId,
      consumerDutyContent: {
        id: '',
        version: 0,
        // @ts-ignore - We don't have quote typed properly yet
        facilityTypeName: quote.FinanceType,
        funderProductId: quote.FunderProductUID,
        funderCode: quote.FunderCode
      },
      productSuitabilityQuestion: event.target.name,
      productSuitabilityAnswer: event.target.value === 'false' ? 'No' : 'Yes'
    });
    setSelectedQuestions((prevSelectedQuestions) => ({
      ...prevSelectedQuestions,
      [event.target.name]: event.target.value.toString()
    }));
  };

  const funder = funders && (funders.find((funder: FunderType) => funder.Code === quote.FunderCode) as FunderType);

  const repaymentContent: RepaymentContent | undefined = getRepaymentContent(quote);
  const suitabilityContent: SuitabilityContent | undefined = getSuitabilityContent(quote, financeType);
  const commissionDisclosureQuestion = getCommissionDisclosureQuestion({
    quote,
    dealership,
    funder,
    fiduciaryText: fundersContent?.commissionDisclosureShort || ''
  });

  if (!repaymentContent || !suitabilityContent) {
    return null;
  }

  const { checkbox }: SuitabilityContent = suitabilityContent;

  const questions = [...repaymentContent.questions, ...suitabilityContent.questions];

  // Emergency feature to display commission disclosure question for lenders that support it
  if (canSeeCommissionDisclosureQuestion() && (uiState.hasDefaultTheme || uiState.hasBnppTheme)) {
    questions.push(commissionDisclosureQuestion);
  }

  const allQuestionsNumber = questions.length + (checkbox?.length || 0);
  const formattedFinanceType = financeType && getFinanceTypeWistia(financeType, false);
  return (
    <>
      {formattedFinanceType && (
        <PanelHeader>
          <Trans
            ns="Quoting"
            i18nKey="SuitabilityQuestions.suitability_questions"
            values={{
              formattedFinanceType: formattedFinanceType.financeTypeName
            }}
          />
        </PanelHeader>
      )}
      <main>
        <div className={styles.alertCardWrapper}>
          <AlertCard
            iconName="information"
            title={t('SuitabilityQuestions.important_information')}
            paragraph={t('SuitabilityQuestions.under_consumer_duty_you_need_to')}
          />
        </div>
        <div className={styles.questionsContainer}>
          {questions.map((question: QuestionProps, index) => {
            const { questionText, heading, validationMsg } = question;
            return (
              <Question
                questionTitle={heading}
                questionText={questionText}
                validationType="alert"
                validationTitle={t('SuitabilityQuestions.further_advice_for_customers')}
                validationMsg={validationMsg}
                onChange={handleChange}
                key={questionText}
              />
            );
          })}

          <Divider />
          <h1 className={styles.header}>{t('SuitabilityQuestions.please_go_through')}</h1>

          {checkbox?.map((question: CheckboxQuestion, index) => (
            <>
              <h3 className={styles.questionTitle}>{question.heading}</h3>
              <div className={styles.questionContainer}>
                <p>{question.questionText}</p>
              </div>
              <div>
                <CheckboxWithLabel
                  value={selectedQuestions[question.questionText] === 'true'}
                  key={`checkbox-${index}`}
                  name={question.questionText}
                  id="checkbox-id"
                  onChange={(value) => {
                    // We're not getting the whole event
                    // so we're recreating it
                    handleChange({
                      // @ts-ignore
                      target: {
                        name: question.questionText,
                        value
                      }
                    });
                  }}
                >
                  {t('SuitabilityQuestions.i_understand')}
                </CheckboxWithLabel>
              </div>
            </>
          ))}
        </div>
      </main>
      <PanelFooter
        cancel={
          <Button buttonStyle="cancel" onClick={onCancel} id="cancelFpn">
            {t('ConsumerDutyContainer.cancel')}
          </Button>
        }
        save={
          <Button
            disabled={hasSelectedNo(selectedQuestions) || !hasAnsweredAll(selectedQuestions, allQuestionsNumber)}
            onClick={() => {
              onContinue();
            }}
            id="continueFpn"
          >
            {t('ConsumerDutyContainer.continue')}
          </Button>
        }
      />
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    funders: state.funders,
    dealership: state.session.Dealerships.find((dealership: any) => dealership.Id === state.dealership.Id)
  };
}

export default compose(inject('appStore'), connect(mapStateToProps), observerForHooks)(SuitabilityQuestions);
