import _ from 'lodash';
import { createSelector } from 'reselect';

const getLenderUpdates = (state) => state.lenderUpdates.Data;

const getDashboardLenderUpdates = (state) => state.dashboard.LenderUpdates.Data;
const getDashboardItems = (state) => state.dashboard.items;

export const lenderUpdatesSelector = createSelector(getLenderUpdates, (lenderUpdates) =>
  _.map(lenderUpdates, (lenderUpdate) => {
    return Object.assign({}, lenderUpdate, {
      isSelfService: lenderUpdate.Application.Source.ClientApp === 'motonovo-fass-consumer'
    });
  })
);

export const dashboardLenderUpdatesSelector = createSelector(
  getDashboardItems,
  getDashboardLenderUpdates,
  (dashboardItems, lenderUpdates) => {
    return lenderUpdates.map((id) =>
      Object.assign({}, dashboardItems[id], {
        isSelfService: dashboardItems[id].Application.Source.ClientApp === 'motonovo-fass-consumer'
      })
    );
  }
);
