import React from 'react';
import PropTypes from 'prop-types';
import ApplicationPage from '../../Application/components/ApplicationPage';
import QuickQuoteDummyNavigation from './QuickQuoteDummyNavigation';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Page from '../../Common/Page';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PersonalDetailsForm from '../../Application/forms/PersonalDetailsForm';
import './quickQuoteApply.scss';
import ApplicationNavigationWrapper from '../../Common/layouts/ApplicationNavigationWrapper';
import ApplicationFormWrapper from '../../Common/layouts/ApplicationFormWrapper';
import ApplicationSubHeader from '../../Common/layouts/ApplicationSubHeader';
import { getLenderConfig } from '../../../core/lenders';
import { getApplicationProgress } from '../../../core/applicationProgress';
import { withOutletContextProps, withParams } from 'hocs/router';
import { compose } from 'redux';
import i18n from '../../../i18n';

class QuickQuoteApply extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      progress: {
        personal: 0,
        address: 0,
        employment: 0,
        bank: 0,
        affordability: 0,
        total: 0
      }
    };
  }

  UNSAFE_componentWillMount() {
    let funderCode = this.props.chosenQuote && this.props.chosenQuote.FunderCode;
    this.setState({
      validationRules: getLenderConfig(funderCode, 'consumer', 'validationRules'),
      applicationFields: getLenderConfig(funderCode, 'consumer', 'applicationFields')
    });
    window.scrollTo(0, 0);
  }

  updateProgress = (formData) => {
    const pseudoApplication = {
      PersonalDetails: formData,
      AddressHistory: {
        Items: [],
        totalMonths: 0
      },
      EmploymentHistory: {
        Items: [],
        totalMonths: 0
      },
      BankDetails: {
        TimeAtBank: {}
      },
      AffordabilityDetails: {
        Income: {},
        MonthlyExpenditure: {},
        PersonalCircumstances: {},
        ReplacementLoan: {}
      },
      CustomerType: 'consumer',
      Quote: {
        FunderCode: 'def' //todo use Quote.FunderCode
      }
    };

    const progress = getApplicationProgress(pseudoApplication);

    this.setState({
      progress: progress
    });
  };

  render() {
    let isVatAssist = this.props.chosenQuote && this.props.chosenQuote.ProductCode.includes('VAT Assist');
    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: 'Home',
              path: `/d/${this.props.params.dealershipId}`
            },
            {
            name: i18n.t('QuickQuoteBreadcrumb.quick_quote', {
                ns: 'QuickQuote'
              })
            }
          ]}
        />
        <Panel>
          <PanelHeader>Application Form</PanelHeader>
          <div className="quickQuoteApply">
            <ApplicationNavigationWrapper>
              <QuickQuoteDummyNavigation progress={this.state.progress} applicationType={'consumer'} />
            </ApplicationNavigationWrapper>
            <ApplicationFormWrapper>
              <ApplicationPage title="Personal Details">
                <ApplicationSubHeader text="Personal Details must be completed before continuing with the application." />
                <ApplicationSubHeader text="A customer record will be created from this information." />
                <div className="quickQuoteApply__form">
                  <PersonalDetailsForm
                    onSubmit={this.props.onSubmitPersonalDetails}
                    validationRules={this.state.validationRules.PersonalDetails}
                    submittingState={this.props.quickQuote.submittingState}
                    onFieldChange={this.updateProgress}
                    applicationFields={this.state.applicationFields.PersonalDetails}
                    funderCode={this.props.chosenQuote && this.props.chosenQuote.FunderCode}
                    isVatAssist={isVatAssist}
                    existingCustomer={false}
                  />
                </div>
              </ApplicationPage>
            </ApplicationFormWrapper>
          </div>
        </Panel>
      </Page>
    );
  }
}

QuickQuoteApply.propTypes = {
  quickQuote: PropTypes.object,
  chosenQuote: PropTypes.object,
  onSubmitPersonalDetails: PropTypes.func
};

export default compose(withParams, withOutletContextProps)(QuickQuoteApply);
