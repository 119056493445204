import React from 'react';
import PropTypes from 'prop-types';
import helpIcon from '../../styles/icons/i.svg';
import Image from './Image';
import './inlineWarning.scss';

const InlineWarning = ({ children }) => {
  return (
    <div className="inlineWarning">
      <div className="inlineWarning__tableRow">
        <div className="inlineWarning__imageContainer">
          <div className="inlineWarning__image">
            <Image imageName={helpIcon} />
          </div>
        </div>

        <div className="inlineWarning__body">
          <span>{children}</span>
        </div>
      </div>
    </div>
  );
};

InlineWarning.propTypes = {
  children: PropTypes.string
};

export default InlineWarning;
