import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMultipleFundersContent } from '~/api/contentService/hooks';
import { getFundersContentFilters } from '~/api/contentService/utils';
import Checkbox from '~/components/Common/Form/Checkbox';
import FormFooter from '~/components/Common/Form/FormFooter';
import Modal from '~/components/Common/Modal/Modal';
import './dataProtectionNoticeModal.scss';

export interface DataProtectionNoticeModalProps extends DataProtectionNoticeProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const DataProtectionNoticeModal = (props: DataProtectionNoticeModalProps) => {
  const { isOpen, onClose, funderCodes, onSubmit } = props;
  const { t } = useTranslation('FinanceNavigator');
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('DataProtectionNotice.before_you_continue')}>
      <DataProtectionNotice funderCodes={funderCodes} onClose={onClose} onSubmit={onSubmit} />
    </Modal>
  );
};

export interface DataProtectionNoticeProps {
  funderCodes: string[];
  onClose: () => void;
  onSubmit: () => void;
}

export const DataProtectionNotice = (props: DataProtectionNoticeProps) => {
  const { funderCodes, onClose, onSubmit } = props;
  const { t } = useTranslation('FinanceNavigator');
  const [confimedConsent, setConfirmedConsent] = useState(false);
  const { data: funderDetails } = useMultipleFundersContent(funderCodes.map((x) => getFundersContentFilters(x)!));
  return (
    <div className="dataProtectionNoticeModal" data-th="dataProtectionNoticeModal">
      <section>
        <h2>{t('DataProtectionNotice.how_finance_avigator_works')}</h2>
        <p>{t('DataProtectionNotice.finance_navigator_will_use_a_combination_of_data_supplied_from_lenders')}</p>
        <p>{t('DataProtectionNotice.each_of_the_lenders_mentioned_below')}</p>
        {funderDetails && (
          <ul>
            {funderDetails.map((x) => (
              <li key={x.funderCode}>
                <span data-th={`funder${x.funderCode}__name`}>{x.organisation}:</span>{' '}
                <a target="_blank" rel="noreferrer" href={x.dpnUrl} data-th={`funder${x.funderCode}__dpnUrl`}>
                  {x.dpnUrl}
                </a>
              </li>
            ))}
          </ul>
        )}
      </section>
      <section>
        <h2>{t('DataProtectionNotice.about_credit_reference_agencies')}</h2>
        <p>{t('DataProtectionNotice.the_identities_of_the_credit_reference_agencies')}</p>
        <ul>
          <li>
            Callcredit:{' '}
            <a target="_blank" rel="noreferrer" href="http://www.callcredit.co.uk/crain">
              http://www.callcredit.co.uk/crain
            </a>
          </li>
          <li>
            Equifax:{' '}
            <a target="_blank" rel="noreferrer" href="https://www.equifax.co.uk/crain">
              https://www.equifax.co.uk/crain
            </a>
          </li>
          <li>
            Experian:{' '}
            <a target="_blank" rel="noreferrer" href="http://www.experian.co.uk/crain">
              http://www.experian.co.uk/crain
            </a>
          </li>
        </ul>
      </section>
      <div className="dataProtectionNoticeModal__consent">
        <Checkbox
          value={confimedConsent}
          onChange={setConfirmedConsent}
          id="confirmConsent"
          dataThook="confirmConsent"
        />
        <label htmlFor="confirmConsent">{t('DataProtectionNotice.i_confirm_that_i_have_consent')}</label>
      </div>
      <FormFooter
        onCancel={onClose}
        onSubmit={onSubmit}
        submitLabel={t('DataProtectionNotice.get_results')}
        submitDisabled={!confimedConsent}
      />
    </div>
  );
};
