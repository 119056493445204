import PropTypes from 'prop-types';
import ButtonWithIcon from '../Common/Button/ButtonWithIcon';
import CheckBox from '../Common/Form/Checkbox';
import { useTranslation } from 'react-i18next';
import './visCard.scss';

const VisCard = ({ vehicle, isSelected, onClick }) => {
  const { t } = useTranslation('VisQuoting');
  const handleSelect = () => {
    if (!isSelected) {
      onClick(vehicle);
    }
  };

  const handleDeselect = () => {
    onClick(vehicle);
  };

  let yearsInProduction = '';
  if (vehicle.YearsInProduction && vehicle.YearsInProduction.length > 1) {
    yearsInProduction =
      '(' +
      vehicle.YearsInProduction[0] +
      ' - ' +
      vehicle.YearsInProduction[vehicle.YearsInProduction.length - 1] +
      ')';
  } else if (vehicle.YearsInProduction && vehicle.YearsInProduction.length > 0) {
    yearsInProduction = '(' + vehicle.YearsInProduction[0] + ')';
  }

  return (
    <div className="visCard" onClick={handleSelect}>
      <div className="visCard__title">
        {vehicle.Make} {vehicle.Model} {yearsInProduction}
        <div className="visCard__select">
          {isSelected ? (
            <div className="visCard__buttonWrap">
              <ButtonWithIcon iconName="validation-cross" buttonStyle="red" onClick={handleDeselect}>
                {t('VisCard.remove')}
              </ButtonWithIcon>
            </div>
          ) : (
            <div className="visCard__mobile">
              {t('VisCard.select')}
              <CheckBox value={isSelected} onChange={isSelected ? handleDeselect : handleSelect} />
            </div>
          )}
        </div>
      </div>
      <div className="visCard__derivative">{vehicle.Derivative}</div>
      <ul className="visCard__details">
        {vehicle?.FuelType && <li>{vehicle.FuelType}</li>}
        {vehicle?.Generation && <li>{vehicle.Generation}</li>}
        {vehicle?.NoofSeats && (
          <li>
            {t('VisCard.door', {
              count: +vehicle.Doors
            })}
          </li>
        )}
        {vehicle?.NoofSeats && (
          <li>
            {t('VisCard.seat', {
              count: +vehicle.NoofSeats
            })}
          </li>
        )}
        {vehicle?.DriveTrain && <li>{vehicle.DriveTrain}</li>}
        {vehicle?.PS && <li>{vehicle.PS}PS</li>}
      </ul>
    </div>
  );
};

VisCard.propTypes = {
  vehicle: PropTypes.object,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func
};

export default VisCard;
