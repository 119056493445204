import React from 'react';
import PropTypes from 'prop-types';
import './partExchangePanel.scss';
import PartExchangePanelItem from './PartExchangePanelItem';

const PartExchangePanel = ({ partExchanges }) => (
  <div className="partExchangePanel">
    {partExchanges.map((partExchange, index) => {
      return (
        <div className="partExchangePanel__inner" key={'partex' + index}>
          <PartExchangePanelItem
            partExchange={partExchange}
            partExchangesCount={partExchanges.length}
            index={index}
            key="index"
          />
        </div>
      );
    })}
  </div>
);

PartExchangePanel.propTypes = {
  partExchanges: PropTypes.array
};

PartExchangePanel.defaultProps = {
  partExchanges: []
};

export default PartExchangePanel;
