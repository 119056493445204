// copied from src/styles/themes/* theme-motonovo.scss theme-bdk.scss etc
const themes = {
  'theme-default': {
    sidebarBackground: '#30364e',
    headerBackground: '#282c3e',
    headerForeground: '#fff',
    headerBorderBottom: 'transparent',
    bodyMain: '#64bc46',
    bodySecondary: '#30364e'
  },
  'theme-motonovo': {
    sidebarBackground: '#394550',
    headerBackground: '#fff',
    headerForeground: '#5a5a5a',
    headerBorderBottom: '#e1e1e1',
    bodyMain: '#1bdbaf',
    bodySecondary: '#394550'
  },
  'theme-bnpp': {
    sidebarBackground: '#00965e',
    headerBackground: '#ffffff',
    headerForeground: '#00965e',
    headerBorderBottom: '#e1e1e1',
    bodyMain: '#00965e',
    bodySecondary: '#00965e'
  },
  'theme-bdk': {
    sidebarBackground: '#192c7e',
    headerBackground: '#ffffff',
    headerForeground: '#192c7e',
    headerBorderBottom: '#e1e1e1',
    bodyMain: '#192c7e',
    bodySecondary: '#192c7e'
  }
};

/** Example use:
 * background: var(--var-bodyMain);
 */
export const setStockModuleCssVarTheme = (themeName, ref) => {
  Object.keys(themes[themeName]).forEach((rule) => {
    ref?.style.setProperty(`--var-${rule}`, themes[themeName][rule]);
  });
};
