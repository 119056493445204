import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './textArea.scss';

class TextArea extends React.Component {
  handleChange = (event) => {
    this.props.onChange && this.props.onChange(event.currentTarget.value, this.props.id);
  };

  render() {
    let { id, value, disabled, isValid, isInvalid, className, placeholder } = this.props;

    const inputStyles = classNames(
      'textArea',
      isValid && !disabled && 'is-valid',
      isInvalid && !disabled && 'is-invalid',
      disabled && 'is-disabled',
      className
    );

    const dataAttrs = {};

    if (isInvalid) {
      dataAttrs['data-is-invalid'] = true;
    }

    return (
      <textarea
        className={inputStyles}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={this.handleChange}
        id={id}
        autoComplete="off"
        disabled={disabled}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        data-th={`${this.props.dataThook}TextArea`}
        {...dataAttrs}
      />
    );
  }
}

TextArea.propTypes = {
  value: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  id: PropTypes.string,
  dataThook: PropTypes.string
};

export default TextArea;
