import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Common/Modal/Modal';
import DistanceMap from './DistanceMap';

const DealershipDistanceModal = (props) => (
  <Modal isOpen={props.isOpen} onClose={props.onClose}>
    <div>
      <DistanceMap
        dealerPostcode={props.dealerPostcode}
        customerPostcode={props.customerPostcode}
        consumer={props.consumer}
        distance={props.distance}
        countryCode={props.countryCode}
      />
    </div>
  </Modal>
);

DealershipDistanceModal.propTypes = {
  distance: PropTypes.string,
  consumer: PropTypes.object,
  dealerPostcode: PropTypes.string,
  customerPostcode: PropTypes.string,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  countryCode: PropTypes.string,
};

export default DealershipDistanceModal;
