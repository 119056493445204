import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import ApplicationPage from './ApplicationPage';
import BusinessDetailsForm from '../forms/BusinessDetailsForm';
import { compose } from 'redux';
import { withRouter } from 'hocs/router';

class BusinessDetails extends React.Component {
  static propTypes = {
    params: PropTypes.object,
    application: PropTypes.object,
    applicationFields: PropTypes.object,
    validationRules: PropTypes.object,
    submitApplicationSection: PropTypes.func,
    saveApplicationSection: PropTypes.func
  };
  handleSubmit = (formData) => {
    const { dealershipId, consumerId, applicantId } = this.props.params;
    this.props.submitApplicationSection(
      'BusinessDetails',
      applicantId,
      formData,
      `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/businessaddress`
    );
  };
  handleSave = (formData) => {
    this.props.saveApplicationSection('BusinessDetails', this.props.params.applicantId, formData);
  };

  render() {
    let isVatAssist = this.props.application.Quote.ProductCode.includes('VAT Assist');
    return (
      <ApplicationPage title={this.props.t('BusinessDetails.business_details')}>
        <BusinessDetailsForm
          defaultValues={this.props.application.BusinessDetails}
          onSubmit={this.handleSubmit}
          onSave={this.handleSave}
          applicationFields={this.props.applicationFields.BusinessDetails}
          validationRules={this.props.validationRules.BusinessDetails}
          savingState={this.props.application.sectionSavingState}
          submittingState={this.props.application.sectionSubmittingState}
          isVatAssist={isVatAssist}
        />
      </ApplicationPage>
    );
  }
}

export default compose(withTranslation('Application'), withRouter)(BusinessDetails);
