import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Common/Button/Button';

const AssignLeadButton = (props) => {
  const { t } = useTranslation('AssignLead');

  const onClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.onClick(props.initialAssignees);
  };

  return (
    <div data-th="AssignLeadButton">
      {props.buttonSize === 'small' ? (
        <Button type="button" buttonStyle="secondary" size="small" onClick={onClickHandler}>
          {t('AssignLeadButton.assign')}
        </Button>
      ) : (
        <Button type="button" buttonStyle="secondary" onClick={onClickHandler}>
          {t('AssignLeadButton.assign')}
        </Button>
      )}
    </div>
  );
};

AssignLeadButton.propTypes = {
  onClick: PropTypes.func,
  buttonSize: PropTypes.string,
  initialAssignees: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};
AssignLeadButton.defaultProps = {
  initialAssignees: []
};
export default AssignLeadButton;
