import { inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import CompactQuickQuote from './CompactQuickQuote';
import QuickQuoteSearchBar from './QuickQuoteSearchBar';

import './quickQuoteBar.scss';

const QuickQuoteBar = ({ appStore, isOpen, vehicleClass, onStateChange, dealershipId, vehicleCondition }) => {
  const { i18n } = useTranslation('Header');

  if (!appStore.uiState.canUseQuickQuote) {
    return '';
  }

  return i18n.language === 'en' && appStore.uiState.isUk ? (
    <QuickQuoteSearchBar
      isOpen={isOpen}
      vehicleClass={vehicleClass}
      onStateChange={onStateChange}
      dealershipId={dealershipId}
      vehicleCondition={vehicleCondition}
    />
  ) : (
    <CompactQuickQuote dealershipId={dealershipId} vehicleCondition={vehicleCondition} />
  );
};

export default inject(['appStore'])(QuickQuoteBar);
