import { CSSProperties } from 'react';
import PropTypes from 'prop-types';
import styles from './gauge.module.scss';

const propTypes = {
  matchrate: PropTypes.number.isRequired,
  background: PropTypes.string.isRequired
};

type Props = PropTypes.InferProps<typeof propTypes>;

const Gauge = ({ matchrate, background }: Props) => {
  // var startAngle = -115;
  var angle;
  if (matchrate <= 17) {
    angle = -75;
  } else if (matchrate === 50) {
    angle = 0;
  } else if (matchrate === 83) {
    angle = 75;
  }

  return (
    <svg className={styles.gauge} width="100%" height="100%">
      <image href={background} width="100%" height="100%" />
      <g
        style={{
          translate: '50% 50%'
        }}
      >
        <g
          style={
            {
              '--needle': `${angle}deg`
            } as CSSProperties
          }
          className={styles.needle}
        >
          <polygon transform="translate(-2, 1)" points="-3,10 3,10 0.5,-30 -0.5,-30" fill="#ccc" />
          <polygon points="-3,10 3,10 0.5,-30 -0.5,-30" fill="#000000" />
          <circle cx="0" cy="0" r="5" />
        </g>
      </g>
    </svg>
  );
};

export default Gauge;
