import React from 'react';
import PropTypes from 'prop-types';
import DashboardTableItem from './DashboardTableItem';
import './cfcProspectsItem.scss';

const CFCProspectsItem = ({ applications: application, handleClick }) => {
  const onClick = () => {
    handleClick(application.DealershipConsumer.Id, application.Vehicle.Id);
  };

  return (
    <div className="cfcProspectsItem" key="cont" onClick={onClick}>
      <DashboardTableItem data={application} />
    </div>
  );
};

CFCProspectsItem.propTypes = {
  applications: PropTypes.object,
  handleClick: PropTypes.func
};
export default CFCProspectsItem;
