import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './bankDetailsPanel.scss';
import Table from './SummaryTable/Table';
import TableRow from './SummaryTable/TableRow';
import OuterLeft from './SummaryTable/OuterLeft';
import OuterRight from './SummaryTable/OuterRight';
import { getTimeAtAddressString } from '../../../core/helpers';
import TableHead from './SummaryTable/TableHead';
import TableCell from './SummaryTable/TableCell';
import AddressLine from '../../Common/AddressLine';
import WordBreak from '../../Common/WordBreak';

class BankDetailsPanel extends React.Component {
  getHiddenValue = (value) => {
    return value.split('').map(() => '*');
  };

  render() {
    const { details, hideBankDetails } = this.props;
    const address = {
      CompanyName: details.Bank,
      Address1: details.ContactAddressLine1,
      Address2: details.ContactAddressLine2,
      PostalTown: details.ContactPostTown,
      Postcode: details.ContactPostcode
    };
    return (
      <div className="bankDetailsPanel">
        <OuterLeft>
          <Table>
            <TableRow>
              <TableHead width="50%">{this.props.t('BankDetailsPanel.account_name')}</TableHead>
              <TableCell>
                <WordBreak>
                  {hideBankDetails ? this.getHiddenValue(details.AccountName) : details.AccountName}
                </WordBreak>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHead width="50%">{this.props.t('BankDetailsPanel.sort_code')}</TableHead>
              <TableCell>{hideBankDetails ? this.getHiddenValue(details.SortCode) : details.SortCode}</TableCell>
            </TableRow>
            <TableRow>
              <TableHead width="50%">{this.props.t('BankDetailsPanel.account_number')}</TableHead>
              <TableCell>
                {hideBankDetails ? this.getHiddenValue(details.AccountNumber) : details.AccountNumber}
              </TableCell>
            </TableRow>
          </Table>
        </OuterLeft>
        <OuterRight>
          <Table>
            <TableRow>
              <TableHead width="50%">{this.props.t('BankDetailsPanel.time_at_bank')}</TableHead>
              <TableCell>{hideBankDetails ? '****************' : getTimeAtAddressString(details.TimeAtBank)}</TableCell>
            </TableRow>
            <TableRow>
              <TableHead width="50%">{this.props.t('BankDetailsPanel.bank_address')}</TableHead>
              <TableCell>
                {hideBankDetails ? '************ ***************' : <AddressLine address={address} />}
              </TableCell>
            </TableRow>
          </Table>
        </OuterRight>
      </div>
    );
  }
}

BankDetailsPanel.propTypes = {
  details: PropTypes.object,
  hideBankDetails: PropTypes.bool
};
BankDetailsPanel.defaultProps = {
  details: {}
};
export default withTranslation('Reporting')(BankDetailsPanel);
