import { withTranslation } from 'react-i18next';
import PageTitle from '../../Common/PageTitle';

const ModalOtherOutgoings = ({ t, funder }) => {
  const isADM = funder === 'ADM';
  return (
    <div>
      <PageTitle text={t('ModalOtherOutgoings.other_committed_monthly_outgoings_which_are_not_debt_related')} />
      <p>
        {t(
          'ModalOtherOutgoings.other_committed_expenditure_is_that_which_is_outside_of_usual_household_bills_and_credit_commitments_for_example_childcare_fees_the_main_items_to_include_are'
        )}
      </p>
      <ul>
        {isADM && <li>{t('ModalOtherOutgoings.household_bills')}</li>}
        <li>{t('ModalOtherOutgoings.childcare_fees')}</li>
        <li>{t('ModalOtherOutgoings.school_fees')}</li>
        <li>{t('ModalOtherOutgoings.student_loan')}</li>
        <li>{t('ModalOtherOutgoings.other')}</li>
      </ul>
    </div>
  );
};

export default withTranslation('Application')(ModalOtherOutgoings);
