import _ from 'lodash';
import { action, extendObservable } from 'mobx';

class CustomerList {
  constructor(store) {
    this.store = store;
  }

  @action
  updateFromJSON = (data) => {
    const latestVehicle = _(data.VehiclesOfInterest)
      .orderBy('CreatedTimestamp')
      .last();

    extendObservable(this, {
      dealershipId: data.DealershipId,
      id: data.Id,
      latestVehicle: latestVehicle
        ? {
            make: latestVehicle.Make,
            model: latestVehicle.Model,
            derivative: latestVehicle.Derivative
          }
        : null,
      customerType: data.Corporate && data.Corporate.TradingName ? 'corporate' : 'consumer',
      createdDateTimestamp: data.CreatedDateTimestamp,
      lastModifiedTimestamp: data.LastModifiedTimestamp
    });

    if (this.customerType === 'corporate') {
      extendObservable(this, {
        tradingName: data.Corporate.TradingName,
        landline: data.Corporate.Landline,
        mobile: data.Corporate.Mobile
      });
    } else {
      extendObservable(this, {
        title: data.Consumer.Title,
        firstName: data.Consumer.Firstname,
        middleName: data.Consumer.Middlenames,
        surname: data.Consumer.Surname,
        landline: data.Consumer.Landline,
        mobile: data.Consumer.Mobile,
        email: data.Consumer.Email,
        aliasCount: data.Consumer.AliasCount
      });
    }

    this.updateAssignedLeadsFromJSON(data.AssignedTo);

    return this;
  };

  @action
  updateAssignedLeadsFromJSON = (json) => {
    const assignedLeads =
      json &&
      json.map((user) => {
        return this.store.updateUserFromJSON(user);
      });

    extendObservable(this, {
      assignedTo: assignedLeads || []
    });
  };

  @action
  updateAssignedLeads = (assignedLeads) => {
    extendObservable(this, {
      assignedTo: assignedLeads
    });
  };
}

export default CustomerList;
