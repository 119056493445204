import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import PanelHeader from '../../../components/Common/PanelHeader';
import PanelFooter from '../../../components/Common/PanelFooter';
import { useGetLenderPrivacyDocs } from '../../../api/legaldocs/hooks';
import parse from 'html-react-parser';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import '../lenderPrivacy.scss';
import { useTrackPageViewed } from '../../../tracking/hooks';

const createMarkup = (docs: { html: string }) => {
  let html = docs.html;
  html = html.replace(/<a href="(.*?)">/g, '<a href="$1" target="blank" >');
  return {
    __html: html
  };
};

type LenderPrivacyProps = {
  funderCode: string;
  dealershipId: string;
  onContinue: () => void;
  onCancel: () => void;
};

const LenderPrivacy = ({ funderCode, onContinue, onCancel, dealershipId }: LenderPrivacyProps) => {
  const { t } = useTranslation('Quoting');
  const [loading, setLoading] = useState(false);
  const { isLoading: isLoadingDocs, isError: isErrorDocs, data: docs } = useGetLenderPrivacyDocs(funderCode);

  useTrackPageViewed('Lender Privacy Policy', dealershipId);

  if (isLoadingDocs || loading) {
    return <LoadingSpinner center size="large" />;
  }

  return (
    <>
      <PanelHeader>
        <h1>{t('ConsumerDutyContainer.lender_privacy_policy')}</h1>
      </PanelHeader>
      {docs && <h2 className="lenderPrivacy__sub-title">{t('ConsumerDutyContainer.before_continuing')}</h2>}
      <main className="lenderPrivacy">
        <section>
          {docs && <div>{parse(createMarkup(docs).__html)}</div>}
          {isErrorDocs && <p>{t('ConsumerDutyContainer.an_error_occurred')}</p>}
        </section>
      </main>
      <PanelFooter
        cancel={
          <Button buttonStyle="cancel" onClick={onCancel} id="cancelFpn">
            {t('ConsumerDutyContainer.cancel')}
          </Button>
        }
        save={
          <Button
            onClick={() => {
              onContinue();
              setLoading(true);
            }}
            id="continueFpn"
          >
            {t('ConsumerDutyContainer.continue')}
          </Button>
        }
      />
    </>
  );
};

export default LenderPrivacy;
