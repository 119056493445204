import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { action, reaction, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import Validator from '../../../validators/Validator';
import FormFooter from '../../Common/Form/FormFooter';
import SelectInput from '../../Common/Form/SelectInput';
import TextInputWrappingInput from '../../Common/Form/TextInputWrappingInput';
import Modal from '../../Common/Modal/Modal';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import DateInput from '../../Common/Form/DateInput';
import Input2 from '../../Common/Form/Input2';
import Checkbox from '../../Common/Form/Checkbox';
import { compose } from 'redux';
import { withLocation } from 'hocs/router';
import './personalDetailsForm.scss';
import { PhoneNumberInput } from '~/components/Common/Form/PhoneNumberInput';

class PersonalDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vatModalOpen: false
    };
  }

  @observable
  formData = {};

  componentDidUpdate(prevProps) {
    if (this.props.validationRules !== prevProps.validationRules) {
      this.setInitialValues();
      this.initialiseValidator();
    }
  }

  UNSAFE_componentWillMount() {
    this.setInitialValues();
    this.initialiseValidator();
  }

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  @action
  setInitialValues = () => {
    this.formData = {
      DrivingLicence: this.props.defaultValues.DrivingLicence,
      DrivingLicenceNumber: this.props.defaultValues.DrivingLicenceNumber,
      Email: this.props.defaultValues.Email,
      Title: this.props.defaultValues.Title,
      FirstName: this.props.defaultValues.FirstName,
      MiddleNames: this.props.defaultValues.MiddleNames,
      LastName: this.props.defaultValues.LastName,
      Gender: this.props.defaultValues.Gender,
      HomeTelephone: this.props.defaultValues.HomeTelephone,
      MobileTelephone: this.props.defaultValues.MobileTelephone,
      Nationality: this.props.defaultValues.Nationality,
      DualNationality: this.props.defaultValues.DualNationality,
      MaritalStatus: this.props.defaultValues.MaritalStatus,
      VatNumber: this.props.defaultValues.VatNumber,
      DateOfBirth: this.props.defaultValues.DateOfBirth,
      NoOfDependants: this.props.defaultValues.NoOfDependants,
      CountryOfBirth: {
        BornUK: this.props.defaultValues.CountryOfBirth && this.props.defaultValues.CountryOfBirth.BornUK,
        BirthPlace: this.props.defaultValues.CountryOfBirth && this.props.defaultValues.CountryOfBirth.BirthPlace
      },
      DirectorOrBoardMember: {
        Director:
          this.props.defaultValues.DirectorOrBoardMember && this.props.defaultValues.DirectorOrBoardMember.Director,
        CountryOfOperation:
          this.props.defaultValues.DirectorOrBoardMember &&
          this.props.defaultValues.DirectorOrBoardMember.CountryOfOperation,
        CompanySector:
          this.props.defaultValues.DirectorOrBoardMember && this.props.defaultValues.DirectorOrBoardMember.CompanySector
      },
      AssignToSelf: this.props.defaultValues.AssignToSelf
    };
  };
  initialiseValidator = () => {
    let validationRules = _.cloneDeep(this.props.validationRules);

    if (this.props.isVatAssist) {
      validationRules.VatNumber = 'number:int, maxlength:9, minlength:9, required';
    }

    this.setUpValidation(validationRules);
    this.validator.validate(this.formData);
  };

  setUpValidation(validationRules) {
    this.validator = new Validator();
    this.validator.setRules(validationRules);
    this.validationReactionDisposer = reaction(() => ({ ...this.state }), this.validator.validate, {
      fireImmediately: true
    });
  }

  submitForm = () => {
    if (!this.validator.errorCount) {
      this.props.onSubmit(this.formData);
    } else {
      this.validator.validate(this.formData);
    }
  };
  onSave = () => {
    this.props.onSave(this.formData);
  };
  openVatAssistModal = () => {
    this.setState({
      vatModalOpen: true
    });
  };
  closeVatAssistModal = () => {
    this.setState({
      vatModalOpen: false
    });
  };
  @action
  setFieldValue = (fieldName, value) => {
    this.formData = _.set(this.formData, fieldName, value);
    this.validator.validate(this.formData);
  };
  @action
  setYesNoField = (fieldName, value) => {
    this.formData = _.set(this.formData, fieldName, value && value.toString());
    this.validator.validate(this.formData);
  };
  convertToBoolean = (string) => {
    if (!string) {
      return null;
    }

    let value = null;

    if (string.toLowerCase() === 'true') {
      value = true;
    } else if (string.toLowerCase() === 'false') {
      value = false;
    }

    return value;
  };

  render() {
    const applicationFields = this.props.applicationFields;
    const errors = this.validator.getErrors();
    // Editing version
    let editApplicationFieldsDisabled = null;
    const editing = this.props.location?.pathname.includes('/edit/');
    if (editing) {
      editApplicationFieldsDisabled = this.props.editApplicationFieldsDisabled;
    }

    return (
      <div className="personalDetailsForm" data-th="PersonalDetailsForm">
        <MobxForm focusOnFirstElement={true} onSubmit={this.submitForm}>
          <MobxFieldGroup isInline error={errors.Title}>
            <MobxFormLabel htmlFor="Title">{this.props.t('PersonalDetailsForm.title')}</MobxFormLabel>
            <SelectInput
              disabled={editApplicationFieldsDisabled?.Title}
              id="Title"
              options="FinanceTitles"
              funderCode={this.props.funderCode}
              size="medium"
              value={this.formData.Title}
              onChange={this.setFieldValue}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.FirstName}>
            <MobxFormLabel htmlFor="FirstName">{this.props.t('PersonalDetailsForm.first_name')}</MobxFormLabel>
            <TextInputWrappingInput
              type="text"
              id="FirstName"
              value={this.formData.FirstName}
              onChange={(value) => this.setFieldValue('FirstName', value)}
              disabled={editApplicationFieldsDisabled?.FirstName}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.MiddleNames}>
            <MobxFormLabel htmlFor="MiddleNames">{this.props.t('PersonalDetailsForm.middle_name')}</MobxFormLabel>
            <TextInputWrappingInput
              id="MiddleNames"
              value={this.formData.MiddleNames}
              onChange={(value) => this.setFieldValue('MiddleNames', value)}
              disabled={editApplicationFieldsDisabled?.MiddleNames}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.LastName}>
            <MobxFormLabel htmlFor="LastName">{this.props.t('PersonalDetailsForm.last_name')}</MobxFormLabel>
            <TextInputWrappingInput
              id="LastName"
              value={this.formData.LastName}
              onChange={(value) => this.setFieldValue('LastName', value)}
              disabled={editApplicationFieldsDisabled?.LastName}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.Email}>
            <MobxFormLabel htmlFor="Email">{this.props.t('PersonalDetailsForm.email')}</MobxFormLabel>
            <TextInputWrappingInput
              id="Email"
              type="email"
              value={this.formData.Email}
              onChange={(value) => this.setFieldValue('Email', value)}
              autoComplete="none"
              placeholder={this.props.t('PersonalDetailsForm.enter_customer_email_address')}
              disabled={editApplicationFieldsDisabled?.Email}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.HomeTelephone}>
            <MobxFormLabel htmlFor="HomeTelephone">{this.props.t('PersonalDetailsForm.home_telephone')}</MobxFormLabel>
            <PhoneNumberInput
              id="HomeTelephone"
              value={this.formData.HomeTelephone}
              onChange={(value) => this.setFieldValue('HomeTelephone', value)}
              disabled={editApplicationFieldsDisabled?.HomeTelephone}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.MobileTelephone}>
            <MobxFormLabel htmlFor="MobileTelephone">
              {this.props.t('PersonalDetailsForm.or_mobile_telephone')}
            </MobxFormLabel>
            <PhoneNumberInput
              id="MobileTelephone"
              value={this.formData.MobileTelephone}
              onChange={(value) => this.setFieldValue('MobileTelephone', value)}
              disabled={editApplicationFieldsDisabled?.MobileTelephone}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.Gender}>
            <MobxFormLabel htmlFor="Gender">{this.props.t('PersonalDetailsForm.gender')}</MobxFormLabel>
            <SelectInput
              id="Gender"
              options="Gender"
              value={this.formData.Gender}
              funderCode={this.props.funderCode}
              onChange={this.setFieldValue}
              disabled={editApplicationFieldsDisabled?.Gender}
            />
          </MobxFieldGroup>

          <MobxFieldGroup isInline error={errors.DateOfBirth}>
            <MobxFormLabel htmlFor="DateOfBirth">{this.props.t('PersonalDetailsForm.date_of_birth')}</MobxFormLabel>
            <DateInput
              id="DateOfBirth"
              value={this.formData.DateOfBirth}
              onChange={this.setFieldValue}
              disabled={editApplicationFieldsDisabled?.DateOfBirth}
            />
          </MobxFieldGroup>

          {applicationFields && applicationFields.BornUK && (
            <MobxFieldGroup isInline error={errors.CountryOfBirth && errors.CountryOfBirth.BornUK}>
              <MobxFormLabel htmlFor="CountryOfBirth.BornUK">
                {this.props.t('PersonalDetailsForm.were_you_born_in_the_uk')}
              </MobxFormLabel>
              <SelectInput
                id="CountryOfBirth.BornUK"
                options="YesOrNo"
                value={this.convertToBoolean(this.formData.CountryOfBirth.BornUK)}
                onChange={this.setYesNoField}
                disabled={editApplicationFieldsDisabled?.CountryOfBirth?.BornUK}
              />
            </MobxFieldGroup>
          )}
          {applicationFields &&
            applicationFields.BirthPlace &&
            this.formData.CountryOfBirth &&
            this.formData.CountryOfBirth.BornUK &&
            this.formData.CountryOfBirth.BornUK.toString() === 'false' && (
              <MobxFieldGroup isInline error={errors.CountryOfBirth && errors.CountryOfBirth.BirthPlace}>
                <MobxFormLabel htmlFor="CountryOfBirth.BirthPlace">
                  {this.props.t('PersonalDetailsForm.please_select_your_country_of_birth')}
                </MobxFormLabel>
                <SelectInput
                  id="CountryOfBirth.BirthPlace"
                  options="Nationality"
                  value={this.formData.CountryOfBirth.BirthPlace}
                  onChange={this.setFieldValue}
                  disabled={editApplicationFieldsDisabled?.CountryOfBirth?.BirthPlace}
                />
              </MobxFieldGroup>
            )}
          <MobxFieldGroup isInline error={errors.Nationality}>
            <MobxFormLabel htmlFor="Nationality">{this.props.t('PersonalDetailsForm.nationality')}</MobxFormLabel>
            <SelectInput
              id="Nationality"
              options="Nationality"
              value={this.formData.Nationality}
              onChange={this.setFieldValue}
              disabled={editApplicationFieldsDisabled?.Nationality}
            />
          </MobxFieldGroup>
          {applicationFields && applicationFields.DualNationality && (
            <MobxFieldGroup isInline error={errors.DualNationality}>
              <MobxFormLabel htmlFor="DualNationality">
                {this.props.t('PersonalDetailsForm.dual_nationality')}
              </MobxFormLabel>
              <SelectInput
                id="DualNationality"
                options="Nationality"
                value={this.formData.DualNationality}
                onChange={this.setFieldValue}
                extraOption={{
                  key: 'notApplicable',
                  value: this.props.t('PersonalDetailsForm.not_applicable')
                }}
                disabled={editApplicationFieldsDisabled?.DualNationality}
              />
            </MobxFieldGroup>
          )}
          {applicationFields && applicationFields.Director && (
            <MobxFieldGroup isInline error={errors.DirectorOrBoardMember && errors.DirectorOrBoardMember.Director}>
              <MobxFormLabel htmlFor="DirectorOrBoardMember.Director">
                {this.props.t('PersonalDetailsForm.are_you_self_employed_or_a_director_or_board_member_of_a_company')}
              </MobxFormLabel>
              <SelectInput
                id="DirectorOrBoardMember.Director"
                options="YesOrNo"
                value={this.convertToBoolean(this.formData.DirectorOrBoardMember.Director)}
                onChange={this.setYesNoField}
                disabled={editApplicationFieldsDisabled?.DirectorOrBoardMember?.Director}
              />
            </MobxFieldGroup>
          )}
          {applicationFields &&
            applicationFields.CountryOfOperation &&
            this.formData.DirectorOrBoardMember &&
            this.formData.DirectorOrBoardMember.Director &&
            this.formData.DirectorOrBoardMember.Director.toString() === 'true' && (
              <MobxFieldGroup
                isInline
                error={errors.DirectorOrBoardMember && errors.DirectorOrBoardMember.CountryOfOperation}
              >
                <MobxFormLabel htmlFor="DirectorOrBoardMember.CountryOfOperation">
                  {this.props.t('PersonalDetailsForm.please_select_your_country_of_operation')}
                </MobxFormLabel>
                <SelectInput
                  id="DirectorOrBoardMember.CountryOfOperation"
                  options="Nationality"
                  onChange={this.setFieldValue}
                  value={this.formData.DirectorOrBoardMember.CountryOfOperation}
                  disabled={editApplicationFieldsDisabled?.DirectorOrBoardMember?.CountryOfOperation}
                />
              </MobxFieldGroup>
            )}
          {applicationFields &&
            applicationFields.CompanySector &&
            this.formData.DirectorOrBoardMember &&
            this.formData.DirectorOrBoardMember.Director &&
            this.formData.DirectorOrBoardMember.Director.toString() === 'true' && (
              <MobxFieldGroup
                isInline
                error={errors.DirectorOrBoardMember && errors.DirectorOrBoardMember.CompanySector}
              >
                <MobxFormLabel htmlFor="DirectorOrBoardMember.CompanySector">
                  {this.props.t('PersonalDetailsForm.what_is_your_sector_of_activity')}
                </MobxFormLabel>
                <SelectInput
                  id="DirectorOrBoardMember.CompanySector"
                  options="CompanySectorExtended"
                  value={this.formData.DirectorOrBoardMember.CompanySector}
                  onChange={this.setFieldValue}
                  disabled={editApplicationFieldsDisabled?.DirectorOrBoardMember?.CompanySector}
                />
              </MobxFieldGroup>
            )}
          <MobxFieldGroup isInline error={errors.MaritalStatus}>
            <MobxFormLabel htmlFor="MaritalStatus">{this.props.t('PersonalDetailsForm.marital_status')}</MobxFormLabel>
            <SelectInput
              id="MaritalStatus"
              options="MaritalStatus"
              funderCode={this.props.funderCode}
              value={this.formData.MaritalStatus}
              onChange={this.setFieldValue}
              disabled={editApplicationFieldsDisabled?.MaritalStatus}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.NoOfDependants}>
            <MobxFormLabel htmlFor="NoOfDependants">
              {this.props.t('PersonalDetailsForm.no_of_dependants')}
            </MobxFormLabel>
            <SelectInput
              id="NoOfDependants"
              options="Dependants"
              onChange={this.setFieldValue}
              value={this.formData.NoOfDependants}
              disabled={editApplicationFieldsDisabled?.NoOfDependants}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.DrivingLicence}>
            <MobxFormLabel htmlFor="DrivingLicence">
              {this.props.t('PersonalDetailsForm.driving_licence')}
            </MobxFormLabel>
            <SelectInput
              id="DrivingLicence"
              funderCode={this.props.funderCode}
              options={this.props.funderCode === 'CRE' ? 'BnppLicenceType' : 'DrivingLicenseType'}
              value={this.formData.DrivingLicence}
              onChange={this.setFieldValue}
              disabled={editApplicationFieldsDisabled?.DrivingLicence}
            />
          </MobxFieldGroup>

          {applicationFields && applicationFields.DrivingLicenceNumber && (
            <MobxFieldGroup isInline error={errors.DrivingLicenceNumber}>
              <MobxFormLabel htmlFor="DrivingLicenceNumber">
                {this.props.t('PersonalDetailsForm.driving_licence_number')}
              </MobxFormLabel>
              <TextInputWrappingInput
                id="DrivingLicenceNumber"
                value={this.formData.DrivingLicenceNumber}
                onChange={(value) => this.setFieldValue('DrivingLicenceNumber', value)}
                disabled={editApplicationFieldsDisabled?.DrivingLicenceNumber}
              />
            </MobxFieldGroup>
          )}
          {this.props.isVatAssist && (
            <MobxFieldGroup isInline={true} error={errors.VatNumber}>
              <MobxFormLabel htmlFor="VatNumber">
                <span className="personalDetailsForm__vat">
                  {this.props.t('PersonalDetailsForm.vat_number')}{' '}
                  <button className="personalDetailsForm__vatButton" type="button" onClick={this.openVatAssistModal} />
                </span>
              </MobxFormLabel>
              <Input2
                id="VatNumber"
                type="text"
                inlineField
                prefix="GB"
                onChange={(e) => this.setFieldValue('VatNumber', e.currentTarget.value)}
                value={this.formData.VatNumber}
                disabled={editApplicationFieldsDisabled?.VatNumber}
              />
            </MobxFieldGroup>
          )}
          {!this.props.existingCustomer && this.props.appStore.uiState.canAssign && (
            <MobxFieldGroup isInline error={errors.AssignToSelf}>
              <div className="personalDetailsForm__assignToSelf">
                <Checkbox
                  id="AssignToSelf"
                  value={this.formData.AssignToSelf}
                  onChange={(bool) => this.setFieldValue('AssignToSelf', bool)}
                  disabled={editApplicationFieldsDisabled?.AssignToSelf}
                />
                <label className="personalDetailsForm__assignToSelf__label" htmlFor="AssignToSelf">
                  {this.props.t('PersonalDetailsForm.assign_this_lead_to_me')}
                </label>
              </div>
            </MobxFieldGroup>
          )}
          <FormFooter
            onSave={this.props.onSave ? this.onSave : null}
            onCancel={this.props.onCancel}
            submittingState={this.props.submittingState}
            savingState={this.props.savingState}
          />
        </MobxForm>
        <Modal onClose={this.closeVatAssistModal} isOpen={this.state.vatModalOpen}>
          <div className="personalDetailsForm__modalContent">
            {this.props.t(
              'PersonalDetailsForm.moto_novo_vat_assist_is_only_available_to_vat_registered_business_customers'
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

PersonalDetailsForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  // onSave: PropTypes.func,
  // onCancel: PropTypes.func,
  validationRules: PropTypes.object,
  savingState: PropTypes.string,
  submittingState: PropTypes.string,
  onFieldChange: PropTypes.func,
  funderCode: PropTypes.string,
  isVatAssist: PropTypes.bool,
  existingCustomer: PropTypes.bool,
  editApplicationFieldsDisabled: PropTypes.object
};
PersonalDetailsForm.defaultProps = {
  defaultValues: {
    DrivingLicence: '',
    Email: '',
    Title: '',
    FirstName: '',
    MiddleNames: '',
    LastName: '',
    Gender: '',
    HomeTelephone: '',
    MobileTelephone: '',
    Nationality: '',
    DualNationality: '',
    MaritalStatus: '',
    VatNumber: '',
    DateOfBirth: '',
    NoOfDependants: '',
    CountryOfBirth: {
      BornUK: 'true',
      BirthPlace: ''
    },
    Director: 'false',
    CountryOfOperation: '',
    CompanySector: '',
    AssignToSelf: false
  },
  existingCustomer: true
};
export default compose(
  withLocation,
  withTranslation('Application'),
  inject(['appStore']),
  observer
)(PersonalDetailsForm);
