import { withTranslation } from 'react-i18next';
import './pushDealItem.scss';
import QuoteButton from '../../../QuotesList/components/QuoteButton';
import MoneyFormat from '../../../Common/MoneyFormat';
import Icon from '../../../Common/Icon/Icon';
import moment from 'moment';
import { Link } from 'react-router-dom';
import TrackEvent from '../../../Common/Tracking/TrackEvent';

const PushDealItem = (props) => {
  const { vehicleCashPrice, quotes, id, dealershipId, responsibleUserName } = props.deal;
  const { isCustomerRecord } = props;
  const dealSentDate = moment(props.deal.addedAt).format('D MMM YY HH:mm');

  return (
    <>
      {!isCustomerRecord ? (
        <div className="pushDealItem">
          <div className="pushDealItem__sentDealOuter">
            <span className="pushDealItem__sentDeal">
              {props.t('PushDealItem.sent_by')} {responsibleUserName}
            </span>
          </div>
          <div className="pushDealItem__dealBreakdown">
            <div className="pushDealItem__dealBreakdownItem">
              <div className="pushDealItem__dealBreakdownItemHeader">{props.t('PushDealItem.total_price')}</div>
              <div className="pushDealItem__dealBreakdownItemInfo">{<MoneyFormat value={vehicleCashPrice} />}</div>
            </div>
            <div className="pushDealItem__dealBreakdownItem">
              <div className="pushDealItem__dealBreakdownItemHeader">{props.t('PushDealItem.finance')}</div>
              <div className="pushDealItem__dealBreakdownItemInfo">
                {props.t('PushDealItem.finance_quote_available', {
                  count: quotes.length
                })}
              </div>
            </div>
            <div className="pushDealItem__dealBreakdownItem">
              <div className="pushDealItem__dealBreakdownItemHeader">{props.t('PushDealItem.based_on')}</div>
              <div className="pushDealItem__dealBreakdownItemInfo">
                <MoneyFormat value={quotes[0].requestedDeposit} />{' '}
                <span>
                  {props.t('PushDealItem.deposit_over', {
                    count: quotes[0].term
                  })}
                </span>
              </div>
            </div>
            {/* <div className="pushDealItem__dealBreakdownItem">
    <div className="pushDealItem__dealBreakdownItemHeader">Status</div>
    <div className="pushDealItem__dealBreakdownItemDealStatus">Deal Opened</div>
    </div> */}
            <div className="pushDealItem__dealBreakdownItem">
              <div className="pushDealItem__dealBreakdownButton">
                <TrackEvent
                  featureName="pushDeal"
                  interactionName="pushDeal__viewDealBreakdown"
                  linkTo={`/d/${dealershipId}/consumers/${props.consumerId}/send-deal/${id}`}
                >
                  <QuoteButton
                    label={props.t('PushDealItem.view')}
                    to={`/d/${dealershipId}/consumers/${props.consumerId}/send-deal/${id}`}
                  />
                </TrackEvent>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Link
          to={`/d/${dealershipId}/consumers/${props.consumerId}/send-deal/${id}`}
          className="pushDealItem--customerRecord"
        >
          <div className="pushDealItem__header--customerRecord">
            <div className="pushDealItem__orderIcon--customerRecord">
              <Icon name="send-deal" />
            </div>
            <div className="pushDealItem__orderRef--customerRecord">{props.t('PushDealItem.digital_deal')}</div>
            <div>
              {props.t('PushDealItem.sent_by')} {responsibleUserName}
            </div>
            <div className="pushDealItem__orderDate">{dealSentDate}</div>
          </div>
          <div className="pushDealItem__content--customerRecord">
            <div className="pushDealItem__info--customerRecord">
              <div className="pushDealItem__infoItem--customerRecord">
                <div className="pushDealItem__subHeader">{props.t('PushDealItem.total_price')}</div>
                <MoneyFormat value={vehicleCashPrice} />
              </div>
              <div className="pushDealItem__infoItem--customerRecord">
                <div className="pushDealItem__subHeader">{props.t('PushDealItem.payment_options')}</div>
                <div className="pushDealItem__dealBreakdownItemInfo">
                  {props.t('PushDealItem.finance_quote_available', {
                    count: quotes.length
                  })}
                </div>
              </div>
              <div className="pushDealItem__infoItem--customerRecord">
                <div className="pushDealItem__subHeader">{props.t('PushDealItem.based_on')}</div>
                <div className="pushDealItem__dealBreakdownItemInfo">
                  <MoneyFormat value={quotes[0].requestedDeposit} />{' '}
                  <span>
                    {props.t('PushDealItem.deposit_over', {
                      count: quotes[0].term
                    })}
                  </span>
                </div>
              </div>
              {/* <div className="pushDealItem__infoItem--customerRecord">
         <div className="pushDealItem__subHeader">Status</div>
         <div>CHANGE THIS </div>
      </div> */}
            </div>
          </div>
        </Link>
      )}
    </>
  );
};

export default withTranslation('Customer')(PushDealItem);
