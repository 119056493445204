import { Trans, useTranslation } from 'react-i18next';
import React from 'react';

const NoQuotes = () => {
  const { t } = useTranslation('Quoting');
  return (
    <div>
      <p>{t('NoQuotes.sorry_we_are_unable_to_return_any_quotes_for_this_vehicle_based_on_the_parameters_entered')}</p>
      <p>
        <Trans ns="Quoting" i18nKey="NoQuotes.this_may_be_because_of_the_balance_to_finance_vehicle_or_mileage" />
      </p>
    </div>
  );
};

export default NoQuotes;
