import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import LenderStatus from '../Common/LenderStatus/LenderStatus';
import Button from '../Common/Button/Button';
import { Link } from 'react-router-dom';
import LenderStatusLarge from './LenderStatusLarge';
import classnames from 'classnames';
import LoadingSpinner from '../Common/Loading/LoadingSpinner';
import DateFormat from '../Common/DateFormat';
import { observer, inject } from 'mobx-react';
import StatusIcon from './StatusIcon';
import './relatedApplicationStatus.scss';

class RelatedApplicationStatus extends React.Component {
  updateCustomerData = () => {
    this.props.appStore.customerStore.fetchCustomerData(this.props.customerId);
  };

  render() {
    let applicationId = this.props.application.ApplicantId || this.props.application.Id;
    let classes = classnames('relatedApplicationStatus', this.props.main && 'relatedApplicationStatus__main');
    return (
      <div className={classes}>
        {this.props.main && this.props.application.Status === 'Submitting' && (
          <div className="relatedApplicationStatus__loadingSpinner">
            <LoadingSpinner />
          </div>
        )}{' '}
        <div className={`relatedApplicationStatus__icon`}>
          <StatusIcon status={this.props.application.LenderStatus || this.props.application.Status} />
        </div>
        <div className="relatedApplicationStatus__name">{this.props.application.Quote.ProductName}</div>
        {this.props.application.LenderStatus === 'Not Submitted' ? (
          <>
            <div className="relatedApplicationStatus__notSubmittedMesage">
              {this.props.t('RelatedApplicationStatus.not_submitted')}
            </div>
            <div className="relatedApplicationStatus__button">
              <Button
                to={{
                  pathname: `/d/${this.props.dealershipId}/consumers/${this.props.customerId}/application/${applicationId}/applicationsummary`,
                  search: `?prefill=true`
                }}
                disabled={this.props.disabled}
              >
                {this.props.t('RelatedApplicationStatus.go_to_application')}
              </Button>
            </div>
          </>
        ) : (
          <>
            {this.props.main ? (
              <div className="relatedApplicationStatus__statusLarge">
                <LenderStatusLarge closed status={this.props.application.Status} />
              </div>
            ) : (
              <>
                <div className="relatedApplicationStatus__links">
                  <Link
                    onClick={this.updateCustomerData}
                    className="relatedApplicationStatus__link"
                    to={`/d/${this.props.dealershipId}/consumers/${this.props.customerId}/application/${applicationId}/status`}
                  >
                    {this.props.t('RelatedApplicationStatus.view_proposal_history')}
                  </Link>
                </div>
                <div className="relatedApplicationStatus__updated">
                  {this.props.t('RelatedApplicationStatus.last_updated')}
                  <div>
                    <DateFormat value={this.props.application.LastModifiedTimestamp} format="calendar" />
                  </div>
                </div>
                <div className="relatedApplicationStatus__status">
                  <LenderStatus status={this.props.application.LenderStatus || this.props.application.Status} />
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

RelatedApplicationStatus.propTypes = {
  application: PropTypes.object
};
export default withTranslation('ApplicationStatus')(inject(['appStore'])(observer(RelatedApplicationStatus)));
