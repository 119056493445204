import React from 'react';
import PropTypes from 'prop-types';
import './percentageChart.scss';

class PercentageChart extends React.Component {
  static propTypes = {
    percent: PropTypes.number,
    color: PropTypes.string,
    icon: PropTypes.string
  };

  render() {
    const dasharray = `${this.props.percent * 100} ${100 - this.props.percent * 100}`;
    return (
      <div className="donut">
        <svg width="100%" height="100%" viewBox="0 0 40 40">
          <circle class="donut-hole" cx="20" cy="20" r="15.91549430918954" fill="#fff"></circle>
          <circle
            class="donut-ring"
            cx="20"
            cy="20"
            r="15.91549430918954"
            fill="transparent"
            stroke-width="2.5"
          ></circle>
          <circle
            class="donut-segment"
            cx="20"
            cy="20"
            r="15.91549430918954"
            fill="transparent"
            stroke={this.props.color}
            strokeWidth="2.5"
            strokeDasharray={dasharray}
            strokeDashoffset="25"
            style={{
              '--dasharray': dasharray
            }}
          ></circle>
        </svg>
        <div className="donut-text">
          {this.props.icon && (
            <div className={`donut-text-icon`}>
              <img src={this.props.icon} alt="" />
            </div>
          )}
          <div>{Math.round(this.props.percent * 100)}%</div>
        </div>
      </div>
    );
  }
}

export default PercentageChart;
