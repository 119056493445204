import React from 'react';
import PropTypes from 'prop-types';
import './productName.scss';

const ProductName = ({ productName, onClick, disabled }) => (
  <span data-th="ProductName">
    {onClick && !disabled ? (
      <span onClick={onClick} className="productName" key="onClick">
        {productName}
      </span>
    ) : (
      <span key="name">{productName}</span>
    )}
  </span>
);

ProductName.defaultProps = {
  disabled: false,
  productName: ''
};

ProductName.propTypes = {
  productName: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default ProductName;
