import _ from 'lodash';

export const findSelfServiceQuote = (state, customerId, quoteId) => {
  let quotes = [];

  _.each(_.get(state.entities.customers[customerId], 'SelfServiceCodes'), (deal) => {
    quotes = [].concat(quotes, _.get(deal, 'Quotes', []));
  });

  return _.find(quotes, { QuoteId: quoteId });
};

export const findSelfServiceVehicle = (state, customerId, vehicleId) => {
  return _.chain(state.entities.customers[customerId])
    .get('VehiclesOfInterest')
    .find({ VehicleId: vehicleId })
    .value();
};
