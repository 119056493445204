import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Page from '../Common/Page';
import Breadcrumbs from '../Common/Breadcrumbs';
import Panel from '../Common/Panel';
import PanelHeader from '../Common/PanelHeader';
import PanelContent from '../Common/PanelContent';
import PlatformUpdatesItem from './PlatformUpdatesItem';
import { useGetPlatformUpdates } from '~/api/whatsnew/hooks';
import LoadingDots from '../Common/Loading/LoadingDots';
import Pagination from '../Common/Pagination';
import { filterRecentUpdates } from '~/api/whatsnew/utils';
import './platformUpdates.scss';

const PlatformUpdates = () => {
  const { t } = useTranslation('PlatformUpdates');
  const params = useParams<{ dealershipId: string }>();
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedUpdateId, setExpandedUpdateId] = useState<string | null>(null);
  const maxPageSize = 5;

  const { data, isLoading, isError } = useGetPlatformUpdates(params.dealershipId || '');

  const { updatesToDisplay, filteredTotal } = useMemo(() => {
    const recentUpdates = filterRecentUpdates(data?.Results || []);
    const filteredTotal = recentUpdates.length || 0;
    const updatesToDisplay = recentUpdates.slice((currentPage - 1) * maxPageSize, currentPage * maxPageSize);
    return { updatesToDisplay, filteredTotal };
  }, [data, currentPage, maxPageSize]);

  useEffect(() => {
    if (updatesToDisplay.length > 0) {
      setExpandedUpdateId(updatesToDisplay[0].Id);
    }
  }, [updatesToDisplay]);
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const handleToggleExpand = (id: string) => {
    setExpandedUpdateId((prevId) => (prevId === id ? null : id));
  };

  return (
    <Page>
      <Breadcrumbs
        items={[
          { name: t('PlatformUpdates.home'), path: `/d/${params.dealershipId}` },
          { name: t('PlatformUpdates.whats_new') }
        ]}
      />
      <Panel>
        <PanelHeader>{t('PlatformUpdates.whats_new')}</PanelHeader>
        <PanelContent>
          <div className="platformUpdates__inner">
            <div>
              {isLoading || !data ? <LoadingDots /> : null}

              {isError || (data && !updatesToDisplay.length) ? (
                <div data-th="no_updates">{t('PlatformUpdates.no_updates')}</div>
              ) : (
                updatesToDisplay.map((update) => (
                  <PlatformUpdatesItem
                    update={update}
                    isExpanded={expandedUpdateId === update.Id}
                    onToggleExpand={() => handleToggleExpand(update.Id)}
                  />
                ))
              )}
            </div>
          </div>
        </PanelContent>
        {filteredTotal > maxPageSize && (
          <Pagination
            currentPage={currentPage}
            totalItems={filteredTotal}
            pageSize={maxPageSize}
            onChange={handlePageChange}
          />
        )}
      </Panel>
    </Page>
  );
};

export default PlatformUpdates;
