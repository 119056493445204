export type Warning = {
  Number: number;
  Message: string;
};

export type PublicWarnings = Warning[];

// Whitelist of PublicWarnings we want to display, identified by their Number
export const publicWarningsDisplayWhitelist = new Set([518]);

export const getPublicWarnings = (publicWarnings: PublicWarnings) => {
  return publicWarnings.filter((w) => publicWarningsDisplayWhitelist.has(w.Number));
};
