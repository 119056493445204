import PropTypes, { InferProps } from 'prop-types';
import './quoteButtonWrapper.scss';

const QuoteButtonWrapper = ({ children }: QuoteButtonWrapperPropsType) => {
  return (
    <div className="quoteButtonWrapper__outer" key="outer">
      {children}
    </div>
  );
};

const QuoteButtonWrapperProps = {
  children: PropTypes.node,
};

type QuoteButtonWrapperPropsType = InferProps<typeof QuoteButtonWrapperProps>
QuoteButtonWrapper.propTypes = QuoteButtonWrapperProps

export default QuoteButtonWrapper;
