import React from 'react';
import { useTranslation } from 'react-i18next';
import { CarIcon } from '../../../../../../../../shared/icons';
import s from './vehicleMetric.module.scss';

interface Props {
  value: number;
}
const DaysInStock = ({ value }: Props) => {
  const { t } = useTranslation('Stock');
  return (
    <div data-th="daysInStock" className={s['vehicleMetric']}>
      <div className={s['vehicleMetricIcon']}>
        <CarIcon />
      </div>
      <span>
        <span data-th="vehicleMetricValue" className={s['vehicleMetricValue']}>
          {value}
        </span>{' '}
        {t('VehicleMetrics.days_in_stock')}
      </span>
    </div>
  );
};

export default DaysInStock;

