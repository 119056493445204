import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './selfServicedDealsTabsItem.scss';
import DateFormat from '../../../Common/DateFormat';
import MoneyFormat from '../../../Common/MoneyFormat';

const SelfServicedDealsTabsItem = ({ deal }) => {
  const { t } = useTranslation('Customer');
  let status;
  let statusDate;

  if (deal.SavedDate && !deal.OpenedDate && !deal.SubmittedDate) {
    status = t('SelfServicedDealsTabsItem.deal_sent');
    statusDate = deal.SavedDate;
  } else if (deal.SavedDate && deal.OpenedDate && !deal.SubmittedDate) {
    status = t('SelfServicedDealsTabsItem.deal_opened');
    statusDate = deal.OpenedDate;
  } else if (deal.SavedDate && deal.OpenedDate && deal.SubmittedDate) {
    status = t('SelfServicedDealsTabsItem.deal_submitted');
    statusDate = deal.SubmittedDate;
  }

  return (
    <div className="selfServicedDealsTabsItem">
      <div className="selfServicedDealsTabsItem__top">
        <div className="selfServicedDealsTabsItem__status">{status}</div>
        <div className="selfServicedDealsTabsItem__date">
          <DateFormat value={statusDate} parse="utc" format="DD/MM/YY HH:mm" />
        </div>
      </div>
      <div className="selfServicedDealsTabsItem__table">
        <div className="selfServicedDealsTabsItem__row selfServicedDealsTabsItem__topRow" key="top">
          <div className="selfServicedDealsTabsItem__left">{t('SelfServicedDealsTabsItem.cash_price')}</div>
          <div className="selfServicedDealsTabsItem__right">
            <MoneyFormat value={deal.Quotes[0].CashPrice} />
          </div>
        </div>
        <div className="selfServicedDealsTabsItem__row" key="bottom">
          <div className="selfServicedDealsTabsItem__left">{t('SelfServicedDealsTabsItem.net_deposit')}</div>
          <div className="selfServicedDealsTabsItem__right">
            <MoneyFormat value={deal.Quotes[0].TotalDeposit} />
          </div>
        </div>
      </div>
    </div>
  );
};

SelfServicedDealsTabsItem.propTypes = {
  deal: PropTypes.object
};
export default SelfServicedDealsTabsItem;
