import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import MoneyFormat from '../../Common/MoneyFormat';

const PaymentSchedule = ({ payments = [], type }) => {
  const { t } = useTranslation('Quoting');
  let last = payments.length - 1;
  let repExamplePayments = payments.slice(1, -1);

  if (type === 'repExample') {
    return (
      <span>
        {repExamplePayments.map((payment, index) => (
          <span key={index}>
            {payment.Label.toLowerCase()} {payment.Prefix.toLowerCase()} <MoneyFormat value={payment.Amount} />
          </span>
        ))}
      </span>
    );
  }

  return type === 'inline' ? (
    <span>
      {payments.map((payment, index) => (
        <span key={index}>
          {index < last ? (
            <span>
              {index === 0
                ? t('PaymentSchedule.first_payment_of')
                : t('PaymentSchedule.followed_by_payment_prefix', {
                    paymentPrefix: payment.Prefix
                  })}{' '}
              <MoneyFormat value={payment.Amount} />,{' '}
            </span>
          ) : (
            <span>
              {t('PaymentSchedule.with_a')}{' '}
              <strong>
                {t('PaymentSchedule.final_payment_of')} <MoneyFormat value={payment.Amount} />
              </strong>
              .{' '}
            </span>
          )}
        </span>
      ))}
    </span>
  ) : (
    <div>
      {payments.map((payment, index) => (
        <div key={index}>
          {index < last ? (
            <span>
              {index === 0 ? t('PaymentSchedule.first_payment_of') : payment.Prefix}{' '}
              <MoneyFormat value={payment.Amount} />
            </span>
          ) : (
            <span>
              {t('PaymentSchedule.final_payment_of')} <MoneyFormat value={payment.Amount} />
            </span>
          )}
        </div>
      ))}
    </div>
  );
};

PaymentSchedule.propTypes = {
  type: PropTypes.string,
  payments: PropTypes.array
};
export default PaymentSchedule;
