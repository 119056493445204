import i18n from '../../i18n';
import { IOption } from './options.types';

export const makeMileOption = (key: number): IOption => {
  const mileOption: IOption = { key: key, value: key.toString() };
  return mileOption;
};

export const makeMileOptions = (start: number, end: number, extra: number) => {
  const mileOptions: IOption[] = [];
  let count = start;
  const incrementBy = 1000;

  if (extra) {
    const addExtra: IOption = makeMileOption(extra);
    mileOptions.push(addExtra);
  }

  while (count <= end) {
    const mileOption: IOption = makeMileOption(count);
    mileOptions.push(mileOption);
    count = count + incrementBy;
  }

  return mileOptions;
};

export const makeMonthOption = (key: number): IOption => {
  const monthOption: IOption = {
    key: key,
    value: i18n.t('Helpers.months', {
      ns: 'Common',
      term: key
    })
  };
  return monthOption;
};

export const makeMonthOptions = (start: number, end: number, incrementBy?: number): IOption[] => {
  const monthOptions: IOption[] = [];

  let count = start;
  const increment = incrementBy ? incrementBy : 1;
  while (count <= end) {
    const monthOption: IOption = makeMonthOption(count);
    monthOptions.push(monthOption);
    count = count + increment;
  }

  return monthOptions;
};

export const makeDayOption = (key: number): IOption => {
  const label = i18n.t('Helpers.days', {
    ns: 'Common',
    count: key
  });
  const dayOption: IOption = { key: key, value: label };

  return dayOption;
};

export const makeDayOptions = (start: number, end: number): IOption[] => {
  const dayOptions: IOption[] = [];

  let count = start;
  while (count <= end) {
    const dayOption: IOption = makeDayOption(count);
    dayOptions.push(dayOption);
    count++;
  }

  return dayOptions;
};
