import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './tileNumberOld.scss';

class TileNumber extends Component {
  UNSAFE_componentWillMount() {
    this.setState({
      isGreater: false,
      items: [
        {
          id: new Date(),
          number: this.props.number
        }
      ]
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.number !== nextProps.number) {
      this.setState({
        isGreater: nextProps.number > this.props.number ? true : false,
        items: [
          {
            id: new Date(),
            number: nextProps.number
          }
        ]
      });
    }
  }

  render() {
    return (
      <div className="tileNumberOld__numCont">
        {this.state.items.map((item) => (
          <div key={item.id} className="tileNumberOld__number" style={{ color: this.props.color, fontSize: '2rem' }}>
            {item.number > 999 ? '999+' : item.number}
          </div>
        ))}
      </div>
    );
  }
}

TileNumber.propTypes = {
  number: PropTypes.number,
  color: PropTypes.string
};

TileNumber.defaultProps = {
  items: []
};

export default TileNumber;
