import { useTranslation } from 'react-i18next';
import ApplicationNavigationItem from './ApplicationNavigationItem';
import { getLenderConfig } from '../../../core/lenders';
import { useParams, useLocation } from 'react-router-dom';
import { getCorporateCreateNavigationItems, getPersonalCreateNavigationItems } from './applicationNavigationItems';
import './applicationNavigation.scss';

type NavigationItem = {
  id: string;
  label: string;
  image: string;
  link: string;
  progress: number;
  isActive?: boolean;
};
export type ApplicationProgress = {
  personal: number;
  address: number;
  employment: number;
  businessDetails: number;
  addressHistory: number;
  organisationContacts: number;
  bank: number;
  affordability: number;
  total: number;
};
type ApplicationNavigationProps = {
  progress: ApplicationProgress;
  applicationType: string;
  funderCode: string;
};
const ApplicationNavigation = ({ applicationType, funderCode, progress }: ApplicationNavigationProps) => {
  const { t } = useTranslation('Application');
  const { dealershipId, consumerId, applicantId } = useParams();
  const location = useLocation();
  const editing = location.pathname.includes('/edit/');

  const getNavigationItems = (applicationType: string) => {
    const navigationItemsProps = {
      dealershipId,
      consumerId,
      applicantId,
      progress,
      t
    };
    let navigationItems: NavigationItem[];

    if (applicationType === 'corporate') {
      navigationItems = getCorporateCreateNavigationItems(navigationItemsProps);
    } else {
      navigationItems = getPersonalCreateNavigationItems(navigationItemsProps);
    }

    if (editing && applicationType !== 'corporate') {
      const editSections = getLenderConfig(funderCode, applicationType, 'editSections');
      navigationItems = [];

      // Add more sections here for edit application
      // as we migrate them from the old way of editing
      // Might have to think about the navigation between them if more sections are added
      if (editSections.personalDetails) {
        navigationItems.push({
          id: 'personal_details',
          label: t('ApplicationNavigation.personal_details'),
          image: 'consumer',
          link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit/personaldetails`,
          progress: progress.personal
        });
      }
    }

    return navigationItems;
  };

  const navigationItems = getNavigationItems(applicationType.toLowerCase());

  return (
    <div>
      <ul className="applicationNavigation">
        {navigationItems.map((item, index) => (
          <ApplicationNavigationItem
            key={index}
            link={item.link}
            isActive={item.isActive}
            imageName={item.image}
            text={item.label}
            progress={item.progress}
            numItems={navigationItems.length}
            isLast={index === navigationItems.length - 1}
          />
        ))}
      </ul>
    </div>
  );
};

export default ApplicationNavigation;
