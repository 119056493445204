import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button/Button';
import './unexpectedError.scss';

const UnexpectedError = ({ handleReturnToHomePage }) => {
  const { t } = useTranslation('Common');
  const homepageHref = (process.env.PUBLIC_URL || '/dealer/platform') + '/';
  return (
    <div className="unexpectedError">
      <div className="unexpectedError__message">
        <h1 className="unexpectedError__heading">{t('UnexpectedError.sorry_an_unexpected_error_has_occurred')}</h1>
        <p className="unexpectedError__text">{t('UnexpectedError.error_has_been_logged')}</p>

        <div className="unexpectedError__returnToHomePageButton">
          <Button buttonStyle="primary" href={homepageHref} onClick={handleReturnToHomePage}>
            {t('UnexpectedError.return_to_home_page')}
          </Button>
        </div>
      </div>
    </div>
  );
};

UnexpectedError.propTypes = {
  children: PropTypes.node,
  handleReturnToHomePage: PropTypes.func
};
export default UnexpectedError;
