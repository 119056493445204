import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchMonthlyFinanceConversion } from '~/api/reporting/reportingApi';
import Card from '~/components/Common/Card/Card';
import Select from '~/components/Common/Form/Select';
import FunderLogo from '~/components/Common/FunderLogo';
import LoadingDots from '~/components/Common/Loading/LoadingDots';
import { Tooltip } from '~/components/Common/Tooltip/Tooltip';
import { formatMoney } from '~/core/helpers';
import './monthlyFinanceConversion.scss';
import ErrorApology from '~/components/Common/ErrorApology';
import Button from '~/components/Common/Button/Button';
import Icon from '~/components/Common/Icon/Icon';

interface MonthlyFinanceConversionProps {
  dealershipId: string;
  countryCode: string;
}

const MonthlyFinanceConversion = (props: MonthlyFinanceConversionProps) => {
  const { dealershipId, countryCode } = props;
  const { t } = useTranslation('Home', { keyPrefix: 'MonthlyFinanceConversion' });
  const [numberOfMonths, setNumberOfMonths] = useState(1);
  const { data, isLoading, isError, refetch } = useQuery(
    [`monthlyFinanceConversion`, dealershipId, numberOfMonths],
    () => fetchMonthlyFinanceConversion(dealershipId, numberOfMonths),
    { staleTime: Infinity }
  );

  const paidOutApplicationsByLender = Object.keys(data?.PaidOutApplicationsByLender || {})
    .map((key) => ({
      ...data!.PaidOutApplicationsByLender[key],
      FunderCode: key
    }))
    .sort((a, b) => b.Count - a.Count)
    .slice(0, 2);

  const className = 'monthlyFinanceConversion';

  return (
    <div className={className}>
      <div className={`${className}__header`}>
        <Tooltip
          content={t('monthly_finance_conversion_tooltip')}
          open={undefined}
          defaultOpen={undefined}
          onOpenChange={undefined}
          variant={`${className}Tooltip`}
          data-th={`${className}__tooltip`}
        >
          <h2 data-th={`${className}__heading`}>{t('monthly_finance_conversion')}</h2>
        </Tooltip>
        <span className={`${className}__input`}>
          <Select
            // @ts-ignore
            emptyValue={false}
            value={numberOfMonths}
            disabled={isLoading}
            options={[
              { key: 1, value: t('last_month') },
              { key: 3, value: t('last_3_months') }
            ]}
            onChange={(evt: any) => setNumberOfMonths(evt.target.value)}
            dataThook={className}
            name="timePeriod"
          />
          <label htmlFor="timePeriod" className={`${className}__inputLabel`}>
            {t('time_period_in_calendar_months')}
          </label>
        </span>
      </div>
      {isLoading && <LoadingDots />}
      {!isLoading && isError && (
        <ErrorApology>
          <Button onClick={refetch}>{t('try_again')}</Button>
        </ErrorApology>
      )}
      {!isLoading && data && (
        <div data-th={`${className}__chart`} className={`${className}__chart`}>
          <Card className={`${className}__chartItem`} withBorder>
            <div>
              <h4 data-th={`${className}__newCustomers__label`}>{t('new_customers_added')}</h4>
              <span data-th={`${className}__newCustomers__value`}>{data.Customers.Count}</span>
            </div>
            {/* <Icon name="right" /> */}
          </Card>
          <Card className={`${className}__chartItem`} withBorder>
            <div>
              <h4 data-th={`${className}__customersWhoSubmitted__label`}>
                {t('customers_who_submitted_finance_application')}
              </h4>
              <span data-th={`${className}__customersWhoSubmitted__value`}>
                {data.CustomersWhoSubmittedAnApplication.Count}
              </span>
            </div>
            {/* <Icon name="right" /> */}
          </Card>
          <Card className={`${className}__chartItem`} withBorder>
            <div>
              <h4 data-th={`${className}__paidOutApplications__label`}>{t('paid_out_applications')}</h4>
              <span data-th={`${className}__paidOutApplications__value`}>
                {t('x_customers_x_loan_value', {
                  count: data.PaidOutApplications.Count,
                  amount: formatMoney(data.PaidOutApplications.Amount, countryCode)
                })}
              </span>
            </div>
            {/* <Icon name="right" /> */}
          </Card>
          <Card className={`${className}__chartItem`} withBorder>
            <div>
              <h4 data-th={`${className}__uniqueLendersProposed__label`}>
                {t('x_unique_lenders_you_proposed_to', {
                  count: paidOutApplicationsByLender.length
                })}
              </h4>
              <div className={`${className}__chartItem__lenders`}>
                {paidOutApplicationsByLender.map(({ FunderCode, Count }, i) => (
                  <div key={i}>
                    <FunderLogo funder={FunderCode} maxWidth={80} />
                    <span data-th={`${className}__paidOutApplicationsLender${i}__value`}>
                      {t('x_applications', { count: Count })}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            {/* <Icon name="right" /> */}
          </Card>
        </div>
      )}
    </div>
  );
};

export default MonthlyFinanceConversion;
