import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Input from './Input2';
import './inputDate.scss';
import 'react-datepicker/dist/react-datepicker.css';

class InputDate extends React.Component {
  onDateInputChange = (event) => {
    const value = moment(event.currentTarget.value).valueOf();
    this.props.onChange({
      currentTarget: { value }
    });
  };

  onDatePickerChange = (date) => {
    if (date) {
      const value = date.valueOf();
      this.props.onChange({
        currentTarget: { value }
      });
    }
  };

  render() {
    const keywords = ['iPhone', 'iPad', 'Android'];
    const found = _.some(keywords, function(word) {
      return navigator.userAgent.indexOf(word) >= 0;
    });

    const type = found ? 'date' : 'text';
    const date = moment(this.props.value).toDate();

    if (type === 'date') {
      return (
        <div className="inputDate" key="1">
          <Input type="date" value={date.format('YYYY-MM-DD')} onChange={this.onDateInputChange} />
        </div>
      );
    } else {
      return (
        <div className="inputDate" key="2">
          <DatePicker selected={date} onChange={this.onDatePickerChange} dateFormat="dd/MM/yyyy" />
        </div>
      );
    }
  }
}

InputDate.propTypes = {
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any
};

export default InputDate;
