import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './applicationsAndChecks.scss';
import Icon from '../../Common/Icon/Icon';
import { commafy, getPercentageChange } from '../../../core/helpers';
import CfcMatchRateTile from './CfcMatchRateTile';

const ApplicationsAndChecks = ({ data }) => {
  const { t } = useTranslation('DigitalReport');
  const currentWebApplications = commafy(_.get(data, 'WebApplications.Current')) || 0;
  const webApplicationsPercentageDifference = _.get(data, 'WebApplications.PercentageDifference');
  const currentCfcProspects = commafy(_.get(data, 'CfcProspects.Current')) || 0;
  const cfcProspectsPercentageDifference = _.get(data, 'CfcProspects.PercentageDifference');

  return (
    <div>
      <div className="applicationsAndChecks__row">
        <div className="applicationsAndChecks__iconOuter">
          <Icon name="application" />
        </div>
        <div className="applicationsAndChecks__data">
          <div className="applicationsAndChecks__dataTop">
            <div className="applicationsAndChecks__figure">{currentWebApplications}</div>
            {webApplicationsPercentageDifference ? (
              <div className="applicationsAndChecks__comparison">
                ({getPercentageChange(webApplicationsPercentageDifference)})
              </div>
            ) : null}
          </div>
          <div className="applicationsAndChecks__dataBottom">
            {t('ApplicationsAndChecks.total_online_applications')}
          </div>
        </div>
      </div>
      <div className="applicationsAndChecks__row">
        <div className="applicationsAndChecks__iconOuter">
          <Icon name="tick" />
        </div>
        <div className="applicationsAndChecks__data">
          <div className="applicationsAndChecks__dataTop">
            <div className="applicationsAndChecks__figure">{currentCfcProspects}</div>
            {cfcProspectsPercentageDifference > 0 && (
              <div className="applicationsAndChecks__comparison">
                ({getPercentageChange(cfcProspectsPercentageDifference)})
              </div>
            )}
          </div>
          <div className="applicationsAndChecks__dataBottom">{t('ApplicationsAndChecks.total_cfc_checks')}</div>
          {currentCfcProspects > 0 && (
            <div className="applicationsAndChecks__cfcMatchrateOuter">
              <CfcMatchRateTile matchRate="high" data={data.CfcMatchRateCounts.Green} />
              <CfcMatchRateTile matchRate="medium" data={data.CfcMatchRateCounts.Amber} />
              <CfcMatchRateTile matchRate="low" data={data.CfcMatchRateCounts.Red} />
              <CfcMatchRateTile matchRate="unknown" data={data.CfcMatchRateCounts.Unknown} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ApplicationsAndChecks.propTypes = {
  data: PropTypes.object
};
export default ApplicationsAndChecks;
