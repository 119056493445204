import React from 'react';
import PropTypes from 'prop-types';
import './leagueTableGroupedHeaderItem.scss';

const LeagueTableGroupedHeaderItem = ({ children, colSpan }) => (
  <th colSpan={colSpan} className={children ? 'leagueTableGroupedHeaderItem' : 'leagueTableGroupedHeaderItem--empty'}>
    {children}
  </th>
);

LeagueTableGroupedHeaderItem.propTypes = {
  children: PropTypes.node,
  colSpan: PropTypes.string
};

export default LeagueTableGroupedHeaderItem;
