import * as actionTypes from '../actionTypes';
import _ from 'lodash';

const defaultState = {
  items: [],
  statuses: [],
  page: 1,
  pageSize: 5,
  status: '',
  to: null,
  from: null,
  total: 0
};

export default function latestDealsReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.FETCH_LATEST_DEALS:
      return Object.assign({}, state, { isLoading: true });

    case actionTypes.FETCH_LATEST_DEALS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        items: action.response.Data,
        statuses: _.chain(action.response)
          .get('Facets.Unfiltered')
          .find({ Name: 'Status' })
          .get('Items')
          .orderBy('Name')
          .value(),
        page: action.response.Page,
        pageSize: action.response.PageSize,
        total: action.response.Total
      });

    case actionTypes.FETCH_LATEST_DEALS_ERROR:
      return Object.assign({}, state, { error: action.payload, isLoading: false });

    case actionTypes.FILTER_LATEST_DEALS_BY_STATUS:
      return Object.assign({}, state, {
        status: action.status
      });

    case actionTypes.FILTER_LATEST_DEALS_BY_DATE:
      return Object.assign({}, state, {
        from: action.from,
        to: action.to,
        timePeriod: action.timePeriod
      });

    case actionTypes.CHANGE_PAGE_LATEST_DEALS:
      return Object.assign({}, state, { page: action.page });

    case actionTypes.SET_LATEST_DEALS_OPTIONS:
      return Object.assign({}, state, action.options);

    case actionTypes.RESET_LATEST_DEALS:
      return _.cloneDeep(defaultState);

    default:
      return state;
  }
}
