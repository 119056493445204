import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Button from '../../Common/Button/Button';
import PasswordStrength from '../../Common/PasswordStrength';
import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import FormFooter from '../../Common/Form/FormFooter';
import './changePassword.scss';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFormFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import TextInputWrappingInput from '../../Common/Form/TextInputWrappingInput';
import { observable, action, reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import Validator from '../../../validators/Validator';
import { withParams, withNavigate } from 'hocs/router';

class ChangePassword extends React.Component {
  changePasswordRequestError = this.props.t('ChangePassword.password_incorrect');

  @observable
  formData = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  };

  constructor(props) {
    super(props);
    const validationRules = {
      currentPassword: 'required',
      newPassword: 'required, minlength:12, lowercaseUppercaseLetterNumberSymbol',
      confirmPassword: 'required, equals:newPassword'
    };
    this.setUpValidation(validationRules);
  }

  setUpValidation(validationRules) {
    this.validator = new Validator();
    this.validator.setRules(validationRules);
    this.validationReactionDisposer = reaction(() => ({ ...this.formData }), this.validator.validate, {
      fireImmediately: true
    });
  }

  handleSubmit = () => {
    const {
      appStore: { authStore }
    } = this.props;

    if (!this.validator.errorCount) {
      authStore.changePassword(this.formData.newPassword, this.formData.currentPassword);
    }
  };

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  cancel = () => {
    this.props.navigate(`/d/${this.props.params.dealershipId}/settings`);
  };
  @action
  setCurrentPassword = (currentPassword) => {
    this.formData.currentPassword = currentPassword;
  };
  @action
  setNewPassword = (newPassword) => {
    this.formData.newPassword = newPassword;
  };
  @action
  setConfirmPassword = (confirmPassword) => {
    this.formData.confirmPassword = confirmPassword;
  };

  render() {
    const errors = this.validator.getErrors();
    const {
      appStore: { authStore }
    } = this.props;
    return (
      <Page>
        <div className="changePassword__breadcrumbs">
          <Breadcrumbs
            items={[
              {
                name: 'Home',
                path: `/d/${this.props.params.dealershipId}`
              },
              {
                name: 'Settings',
                path: `/d/${this.props.params.dealershipId}/settings`
              },
              {
                name: 'Change Password'
              }
            ]}
          />
        </div>

        {authStore.changePasswordRequest.isSuccessful ? (
          <Panel>
            <PanelHeader>{this.props.t('ChangePassword.thank_you')}</PanelHeader>
            <PanelContent>
              <p>{this.props.t('ChangePassword.your_password_has_been_successfully_changed')}</p>
              <Button to={`/d/${this.props.params.dealershipId}/settings`}>
                {this.props.t('ChangePassword.back_to_settings')}
              </Button>
            </PanelContent>
          </Panel>
        ) : (
          <Panel>
            <PanelHeader>{this.props.t('ChangePassword.change_my_password')}</PanelHeader>
            <PanelContent>
              <MobxForm onSubmit={this.handleSubmit} focusOnFirstElement>
                <div className="changePassword__notice">
                  <p>
                    <Trans ns="ChangePassword" i18nKey={'ChangePassword.a_strong_password_is_important'} />
                  </p>
                  <p>{this.props.t('ChangePassword.an_ideal_password_is_at_least_12_characters_long')}</p>
                </div>

                <MobxFormFieldGroup error={errors.currentPassword}>
                  <MobxFormLabel htmlFor="currentPassword">
                    {this.props.t('ChangePassword.current_password')}
                  </MobxFormLabel>
                  <TextInputWrappingInput
                    id="currentPassword"
                    value={this.formData.currentPassword}
                    onChange={this.setCurrentPassword}
                    type="password"
                  />
                </MobxFormFieldGroup>

                <MobxFormFieldGroup error={errors.newPassword}>
                  <MobxFormLabel htmlFor="newPassword">{this.props.t('ChangePassword.new_password')}</MobxFormLabel>
                  <TextInputWrappingInput
                    id="newPassword"
                    value={this.formData.newPassword}
                    onChange={this.setNewPassword}
                    type="password"
                  />
                </MobxFormFieldGroup>

                <div className="changePassword__strengthIndicator">
                  <label>{this.props.t('ChangePassword.strength')}</label>
                  <PasswordStrength password={this.formData.newPassword} isInvalid={errors.newPassword} />
                </div>

                <MobxFormFieldGroup error={errors.confirmPassword}>
                  <MobxFormLabel htmlFor="confirmPassword">
                    {this.props.t('ChangePassword.confirm_password')}
                  </MobxFormLabel>
                  <TextInputWrappingInput
                    id="confirmPassword"
                    value={this.formData.confirmPassword}
                    onChange={this.setConfirmPassword}
                    type="password"
                  />
                </MobxFormFieldGroup>

                <FormFooter
                  submitLabel={this.props.t('ChangePassword.change_password')}
                  isSubmitting={authStore.changePasswordRequest.isLoading}
                  hasSubmittingError={authStore.changePasswordRequest.hasError}
                  onCancel={this.cancel}
                  errorMessage={authStore.changePasswordRequest.hasError && this.changePasswordRequestError}
                />
              </MobxForm>
            </PanelContent>
          </Panel>
        )}
      </Page>
    );
  }
}

ChangePassword.propTypes = {
  params: PropTypes.object,
  appStore: PropTypes.object,
  authStore: PropTypes.object
};

export default compose(
  withParams,
  withNavigate,
  withTranslation('ChangePassword'),
  inject(['appStore']),
  observer
)(ChangePassword);
