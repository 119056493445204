import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

const OptionsValue = (props) => {
  let value =
    _.chain(props.options[props.type])
      .find({ key: props.value })
      .get('value')
      .value() || '';

  return <span>{value}</span>;
};

OptionsValue.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    options: state.options
  };
}

export default connect(mapStateToProps)(OptionsValue);
