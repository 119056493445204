import React from 'react';
import PropTypes from 'prop-types';
import './quotesHeader.scss';

const QuotesHeader = ({ children, onClick, sortField, arrowDirection }) => (
  <div className="quotesHeader">
    {React.Children.toArray(children).map((child) =>
      React.cloneElement(child, {
        onClick: onClick,
        arrowDirection: arrowDirection,
        sortField: sortField
      })
    )}
  </div>
);

QuotesHeader.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  arrowDirection: PropTypes.string,
  sortField: PropTypes.string,
  altStyle: PropTypes.bool
};

export default QuotesHeader;
