import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import VehicleNotesModal from '../components/VehicleNotesModal';
import * as consumerActions from '../../../redux/consumer/consumerActions';
import * as modalActions from '../../../redux/modal/modalActions';
import { getCustomer } from '../../../selectors/customerSelectors';

const VehicleNotesContainer = ({
  notes,
  addNoteToVehicle,
  customerId,
  vehicleId,
  customerType,
  userInitials,
  addNoteState,
  closeModal
}) => {
  return (
    <VehicleNotesModal
      comments={notes}
      onSubmit={(formData) => addNoteToVehicle(customerId, vehicleId, formData, customerType)}
      userInitials={userInitials}
      loadingState={addNoteState}
      closeModal={closeModal}
      data-th="VehicleNotesContainer"
    />
  );
};

VehicleNotesContainer.propTypes = {
  customerId: PropTypes.string,
  customerType: PropTypes.string,
  vehicleId: PropTypes.string,
  notes: PropTypes.array,
  addNoteToVehicle: PropTypes.func,
  closeModal: PropTypes.func,
  userInitials: PropTypes.string,
  addNoteState: PropTypes.string
};

function mapStateToProps(state) {
  let params = state.modals.vehicleNotes || {};
  const customer = getCustomer(state, params.customerId) || {};

  let vehicleNotes = _.filter(customer.DealerNotes, { VehicleId: params.vehicleId });

  return {
    customerId: params.customerId,
    customerType: customer.CustomerType,
    vehicleId: params.vehicleId,
    notes: vehicleNotes,
    userInitials: state.session.Initials,
    addNoteState: state.ui.customer.addCommentState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(modalActions.close('vehicleNotes'));
    },
    addNoteToVehicle: (consumerId, vehicleId, formData, customerType) => {
      dispatch(consumerActions.addNoteToVehicle(consumerId, vehicleId, formData, customerType));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleNotesContainer);
