import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './compareView.scss';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelToolBar from '../../Common/PanelToolBar';
import PanelContent from '../../Common/PanelContent';
import Button from '../../Common/Button/Button';
import Icon from '../../Common/Icon/Icon';
import Tabs from '../../Common/Tabs/Tabs';
import TabList from '../../Common/Tabs/TabList';
import TabContent from '../../Common/Tabs/TabContent';
import TabItem from './MonthlyPriceViewer/TabItem';
import MonthlyPriceViewer from './MonthlyPriceViewer/MonthlyPriceViewer';
import CompareQuotesTable from './CompareQuotesTable';
import NoQuotes from './NoQuotes';
import ConfirmChangeMonthlyPaymentsTermModal from '../modals/ConfirmChangeMonthlyPaymentsTermModal';
import SelectMonthlyPaymentModal from '../modals/SelectMonthlyPaymentModal';

class CompareView extends React.Component {
  handleTabClick = (tabIndex) => {
    if (tabIndex === 0) {
      this.props.changeToMonthlyPaymentsView();
    } else {
      this.props.changeToCompareView();
    }
  };

  render() {
    return (
      <Panel>
        <PanelHeader>{this.props.title}</PanelHeader>

        <PanelToolBar>
          <div className="alignRight">
            <div className="compareView__changeDetails">
              <Button type="button" onClick={this.props.onChangeCostsClick}>
                {this.props.t('CompareView.edit_quotes')}
              </Button>
            </div>
            <div className="compareView__returnToList">
              <Button type="button" buttonStyle="cancel" onClick={this.props.onReturnToListClick}>
                {this.props.t('CompareView.return_to_list_view')}
              </Button>
            </div>
          </div>
        </PanelToolBar>
        <PanelContent>
          <Tabs currentTab={this.props.currentTabIndex} setActiveTab={this.handleTabClick}>
            <TabList tabStyle="default">
              <TabItem dataHook="PricePositionTab" icon={<Icon name="price-position" />}>
                {this.props.t('CompareView.price_position')}
              </TabItem>
              <TabItem dataHook="ProductCompare">{this.props.t('CompareView.product_compare')}</TabItem>
            </TabList>
            <TabContent border={false}>
              <MonthlyPriceViewer
                quoteDetails={this.props.fullQuoteDetails}
                monthlyPayments={this.props.monthlyPayments}
                isLoading={this.props.isFetchingMonthlyPayments || this.props.isFetchingQuotes}
                hasError={this.props.hasFetchMonthlyPaymentsError}
                onTermClick={this.props.selectTerm}
                onPaymentClick={this.props.selectMonthlyPayment}
                updateMonthlyPayments={this.props.updateMonthlyPayments}
                vehicleCondition={this.props.vehicle.Condition}
                quotes={this.props.quotes}
              />
              {this.props.quotes.quotesForCompare.length ? (
                <CompareQuotesTable
                  quotes={this.props.quotes.quotesForCompare}
                  vehicle={this.props.vehicle}
                  onProceedWithFinanceOption={this.props.handleProceedWithQuote}
                  proceedingState={this.props.proceedingState}
                  onRemoveQuote={this.props.handleRemoveQuoteFromCompare}
                  email={this.props.customerEmail}
                  onPrint={this.props.onPrint}
                  assignees={this.props.assignees}
                  chosenQuoteId={this.props.chosenQuoteId}
                  applyingState={this.props.applyingState}
                  savingState={this.props.savingState}
                  proceedToBreakDown={this.props.proceedToBreakDown}
                />
              ) : (
                <NoQuotes />
              )}
            </TabContent>
          </Tabs>
        </PanelContent>

        <ConfirmChangeMonthlyPaymentsTermModal
          onCancel={this.props.closeModal}
          onCompare={this.props.changeMonthlyPaymentTerm}
        />
        <SelectMonthlyPaymentModal
          onApply={this.props.handleProceedWithQuote}
          onSave={this.props.onSaveQuote}
          applyingState={this.props.applyingState}
          savingState={this.props.savingState}
        />
      </Panel>
    );
  }
}

CompareView.propTypes = {
  children: PropTypes.node,
  onChangeCostsClick: PropTypes.func,
  onReturnToListClick: PropTypes.func,
  handleTabClick: PropTypes.func,
  title: PropTypes.string,
  currentTab: PropTypes.number,
  changeToMonthlyPaymentsView: PropTypes.func,
  changeToCompareView: PropTypes.func,
  currentTabIndex: PropTypes.number,
  fullQuoteDetails: PropTypes.object,
  monthlyPayments: PropTypes.array,
  isFetchingMonthlyPayments: PropTypes.bool,
  hasFetchMonthlyPaymentsError: PropTypes.bool,
  quotes: PropTypes.object,
  vehicle: PropTypes.object,
  handleProceedWithQuote: PropTypes.func,
  proceedingState: PropTypes.string,
  savingState: PropTypes.string,
  applyingState: PropTypes.string,
  handleRemoveQuoteFromCompare: PropTypes.func,
  customerEmail: PropTypes.string,
  onPrint: PropTypes.func,
  assignees: PropTypes.array,
  chosenQuoteId: PropTypes.string,
  closeModal: PropTypes.func,
  isFetchingQuotes: PropTypes.bool,
  selectTerm: PropTypes.func,
  changeMonthlyPaymentTerm: PropTypes.func,
  selectMonthlyPayment: PropTypes.func,
  onSaveQuote: PropTypes.func,
  updateMonthlyPayments: PropTypes.func,
  proceedToBreakDown: PropTypes.func
};
export default withTranslation('Quoting')(CompareView);
