import { z } from 'zod';

const Mileage = z.coerce
  .number()
  .positive()
  .int();

const newVehicleSchema = z.object({
  Make: z.string(),
  Model: z.string(),
  Derivative: z.string(),
  Class: z.string(),
  Mileage: Mileage.optional()
});

const usedVehicleSchema = newVehicleSchema.extend({
  Mileage
});

export const VehicleValidForQuoting = z.union([newVehicleSchema, usedVehicleSchema]);
