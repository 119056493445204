import React from 'react';
import { useTranslation } from 'react-i18next';
import './combinedQuoteMessage.scss';
import PropTypes from 'prop-types';

const CombinedQuoteMessage = ({ numberOfIcons, onClick, colour }) => {
  const { t } = useTranslation('Common');
  const backgroundColour = colour || 'white';
  return (
    <div className="combinedQuoteMessage" onClick={onClick}>
      {Array(numberOfIcons)
        .fill()
        .map((icon, key) => (
          <div className={`combinedQuoteMessage__icon combinedQuoteMessage__icon--${backgroundColour}`} key={key}>
            {t('CombinedQuoteMessage.currency_symbol')}
          </div>
        ))}
      <div className="combinedQuoteMessage__text">{t('CombinedQuoteMessage.combined_quote')}</div>
    </div>
  );
};

CombinedQuoteMessage.propTypes = {
  numberOfIcons: PropTypes.number,
  onClick: PropTypes.func
};

export default CombinedQuoteMessage;
