import { action, observable } from 'mobx';
import { nanoid } from 'nanoid';

class NotificationStore {
  @observable
  notifications = [];

  @action
  setNotification(message) {
    this.notifications = [...this.notifications, message];
  }

  generateMessage = (message, title, type) => ({
    id: nanoid(),
    title,
    type, // success, danger, info
    message
  });

  delToast = (id) => [...this.notifications].filter((el) => el.id !== id);

  @action
  removeNotification = (id) => {
    this.notifications = this.delToast(id);
  };

  @action
  pushSuccessNotification = (message, title) => {
    this.setNotification(this.generateMessage(message, title, 'success'));
  };
  @action
  pushInfoNotification = (message, title) => {
    this.setNotification(this.generateMessage(message, title, 'info'));
  };
  @action
  pushDangerNotification = (message, title) => {
    this.setNotification(this.generateMessage(message, title, 'danger'));
  };
}

export default NotificationStore;
