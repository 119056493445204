import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './distanceMap.scss';
import Layout from './DistanceModalConsumerLayout';
import CustomerInfo from './CustomerInfo';
import Address from '../../Customer/components/ConsumerRecordItems/Address';
import DealershipDistance from '../../Customer/components/ConsumerRecordItems/DealershipDistance';

class DistanceMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapError: false
    };
  }

  displayRoute(response, status, directionsDisplay) {
    if (status === window.google.maps.DirectionsStatus.OK) {
      directionsDisplay.setDirections(response);
    } else {
      this.setState({
        mapError: true
      });
    }
  }

  calculateAndDisplayRoute(directionsService, directionsDisplay, address1, address2) {
    directionsService.route(
      {
        origin: address1,
        destination: address2,
        travelMode: window.google.maps.TravelMode.DRIVING,
        region: this.props.countryCode
      },
      (response, status) => {
        this.displayRoute(response, status, directionsDisplay);
      }
    );
  }

  componentDidMount() {
    let map;
    const options = {
      zoom: 8
    };
    let directionsService;
    let directionsDisplay;
    let address1;
    let address2;

    if (window.google && window.google.maps && window.google.maps.Map) {
      map = new window.google.maps.Map(document.getElementById('map'), options);
      directionsService = new window.google.maps.DirectionsService();
      directionsDisplay = new window.google.maps.DirectionsRenderer({
        map: map
      });
      const regionName = new Intl.DisplayNames(['en'], { type: 'region' }).of(this.props.countryCode.toUpperCase());
      address1 = `${this.props.dealerPostcode}, ${regionName}`;
      address2 = `${this.props.customerPostcode}, ${regionName}`;
    }

    this.calculateAndDisplayRoute(directionsService, directionsDisplay, address1, address2);
  }

  render() {
    return (
      <div className="distanceMap" ref="outer">
        <div className="distanceMap__header">{this.props.t('DistanceMap.dealership_distance')}</div>
        {this.state.mapError ? (
          <div>{this.props.t('DistanceMap.map_has_failed_to_load')}</div>
        ) : (
          <div className="distanceMap__map" id="map" key="map" />
        )}

        <Layout>
          <CustomerInfo consumer={this.props.consumer} />
          <Address address={this.props.consumer.Address} />
          <DealershipDistance distance={this.props.distance} />
        </Layout>
      </div>
    );
  }
}

DistanceMap.propTypes = {
  dealerPostcode: PropTypes.string,
  customerPostcode: PropTypes.string,
  consumer: PropTypes.object,
  distance: PropTypes.string,
  countryCode: PropTypes.string
};
export default withTranslation('DealershipDistance')(DistanceMap);
