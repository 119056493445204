import _ from 'lodash';
import * as actionTypes from '../actionTypes';

const defaultState = {};

export default function customerEntityReducer(state = defaultState, action) {
  let newState = state;
  let customer;

  if (action.response && action.response.entities && action.response.entities.customers) {
    newState = _.cloneDeep(state);
    _.merge(newState, action.response.entities.customers);
  }

  switch (action.type) {
    case actionTypes.ADD_VEHICLE_TO_CUSTOMER:
      newState = _.cloneDeep(newState);

      const newVehicle = Object.assign({}, action.vehicle, {
        Status: {}
      });

      newState[action.customerId].VehiclesOfInterest.unshift(newVehicle);
      newState[action.customerId].LatestVehicle = newVehicle.VehicleId;

      return newState;

    case actionTypes.ADD_COMMENT_TO_CONSUMER_SUCCESS:
      newState = _.cloneDeep(newState);
      newState[action.customerId].DealerNotes.unshift({
        VehicleId: action.VehicleId,
        Initials: action.Initials,
        Firstname: action.Firstname,
        Surname: action.Surname,
        MessageBody: action.Comment,
        Created: action.Created
      });

      return newState;

    case actionTypes.CUSTOMER_UPDATE_ASSIGNED_TO:
      newState = _.cloneDeep(state);
      customer = newState[action.customerId];

      if (customer) {
        customer.AssignedTo = action.newAssignees;
      }

      return newState;

    case actionTypes.CLEAR_CUSTOMER_ENTITY_CACHE:
      newState = _.cloneDeep(defaultState);
      return newState;

    default:
      return newState;
  }
}
