import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './closeDealOptionsButtonCircle.scss';

const CloseDealOptionsButtonCircle = ({ isActive }) => {
  return (
    <div className="closeDealOptionsButtonCircle">
      <div
        className={classnames(
          'closeDealOptionsButtonCircle__outerCircle',
          isActive && 'closeDealOptionsButtonCircle__outerCircle--active'
        )}
      >
        <div className="closeDealOptionsButtonCircle__middleCircle">
          <div
            className={classnames(
              'closeDealOptionsButtonCircle__innerCircle',
              isActive && 'closeDealOptionsButtonCircle__innerCircle--active'
            )}
          />
        </div>
      </div>
    </div>
  );
};

CloseDealOptionsButtonCircle.propTypes = {
  isActive: PropTypes.bool
};

export default CloseDealOptionsButtonCircle;
