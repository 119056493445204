const mapValue = (obj, mapper) => Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, mapper(value)]));
const mapObject = (obj, mapper) => Object.entries(obj).map(mapper);

// { mr: 'mr', mx: 'mx' } => [{ key: 'mr', value: 'mr' }, { key: 'mx', value: 'mx' }]
export const formatOverridesValues = (options) =>
  mapObject(options, ([key, value]) => ({
    key,
    value
  }));

// Does obj match to { string: string }
const isValueDict = (obj) => Object.values(obj).some((value) => typeof value === 'string');

export const formatOverrides = (input) => {
  if (isValueDict(input)) {
    return formatOverridesValues(input);
  } else {
    return mapValue(input, formatOverrides);
  }
};
