import { TFunction } from 'i18next';
import { ChartFilterConfigType, OptionName } from '../../types';
import { getFormattedDropdownOptions } from './getFormattedDropdownOptions';
import { getAvailableLenders } from './getAvailableLenders';

export const getEnabledDropdownOptions = (
  chartFilterConfig: ChartFilterConfigType,
  t: TFunction,
  options?: any,
  lenderModules?: any
) => {
  const { datePeriodConfig, lenderConfig, quoteSourceConfig, applicationStatusConfig } = chartFilterConfig;

  const availableLenders = lenderModules && options && getAvailableLenders(lenderModules, options);

  return {
    datePeriodOptions: datePeriodConfig.enabled
      ? getFormattedDropdownOptions(OptionName.DatePeriod, datePeriodConfig, t)
      : { options: [] },

    quoteSourceOptions: quoteSourceConfig.enabled
      ? getFormattedDropdownOptions(OptionName.QuoteSource, quoteSourceConfig, t)
      : { options: [] },

    lenderOptions: lenderConfig.enabled
      ? getFormattedDropdownOptions(OptionName.Lender, lenderConfig, t, availableLenders)
      : { options: [] },

    applicationStatusOptions: applicationStatusConfig.enabled
      ? getFormattedDropdownOptions(OptionName.ApplicationStatus, applicationStatusConfig, t)
      : { options: [] }
  };
};
