import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { StockListComparisonCardValues } from './components';
import { useTranslation } from 'react-i18next';

import styles from './stockListComparisonCard.module.scss';

const StockListComparisonCard = ({
  heading,
  comparisonVehicle,
  currentVehicle,
  isSuppliedVehicle = false,
  className
}) => {
  const { t } = useTranslation('Stock');

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.inner}>
        <p className={styles.heading}>{heading}</p>
        <div className={styles.content}>
          <StockListComparisonCardValues
            name={t('StockVehicleFormFields.make')}
            isSuppliedVehicle={isSuppliedVehicle}
            property={currentVehicle && currentVehicle.make}
            comparisonValue={comparisonVehicle && comparisonVehicle.make}
          />
          <StockListComparisonCardValues
            name={t('StockVehicleFormFields.model')}
            isSuppliedVehicle={isSuppliedVehicle}
            property={currentVehicle && currentVehicle.model}
            comparisonValue={comparisonVehicle && comparisonVehicle.model}
          />
          <StockListComparisonCardValues
            name={t('StockVehicleFormFields.fuel_type')}
            isSuppliedVehicle={isSuppliedVehicle}
            property={currentVehicle && currentVehicle.fuel}
            comparisonValue={comparisonVehicle && comparisonVehicle.fuel}
          />
          <StockListComparisonCardValues
            name={t('StockVehicleFormFields.body_style')}
            isSuppliedVehicle={isSuppliedVehicle}
            property={currentVehicle && currentVehicle.bodyStyle}
            comparisonValue={comparisonVehicle && comparisonVehicle.bodyStyle}
          />
          <StockListComparisonCardValues
            name={t('StockVehicleFormFields.doors')}
            isSuppliedVehicle={isSuppliedVehicle}
            property={currentVehicle && currentVehicle.doors}
            comparisonValue={comparisonVehicle && comparisonVehicle.doors}
          />
          <StockListComparisonCardValues
            name={t('StockVehicleFormFields.transmission')}
            isSuppliedVehicle={isSuppliedVehicle}
            property={currentVehicle && currentVehicle.trans}
            comparisonValue={comparisonVehicle && comparisonVehicle.trans}
          />
        </div>
      </div>
    </div>
  );
};

StockListComparisonCard.propTypes = {
  heading: PropTypes.string
};

export default StockListComparisonCard;
