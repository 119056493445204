import { takeEveryByComponentName } from '../helpers';
import { takeEvery } from 'redux-saga';
import { call, put, fork } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import { componentUnmount, componentMount, componentError } from '../component/componentActions';
import { fetchApplicationIfNeeded, fetchApplicationAlways } from './application';
import { fetchCustomerIfNeeded, fetchCustomerAlways } from '../customer/customer';
import { logError } from '../../core/helpers';
import { push } from '../../routerHistory';

const componentName = 'ApplicationContainer';

export function* watchApplication() {
  yield [fork(watchLoadApplicationContainer), fork(watchCloneApplication)];
}

export function* handleLoadApplicationContainer(action) {
  try {
    yield call(fetchApplicationIfNeeded, action.params.applicantId);
    yield call(fetchCustomerIfNeeded, action.params.consumerId);
  } catch (e) {
    logError(e);
    yield put(componentError(componentName, 'Application not found.', e));
    return;
  }
  yield put(componentMount(componentName));
}

export function* handleCloneApplication(action) {
  yield put(componentUnmount(componentName));
  try {
    yield call(fetchCustomerAlways, action.consumerId);
    yield call(fetchApplicationAlways, action.applicantId);
  } catch (e) {
    logError(e);
    yield put(componentError(componentName, 'Application not found.', e));
    return;
  }
  yield call(push, `/d/${action.dealershipId}/consumers/${action.consumerId}/application/${action.applicantId}`);
  yield put(componentMount(componentName));
}

//Watchers

function* watchLoadApplicationContainer() {
  yield* takeEveryByComponentName(actionTypes.COMPONENT_LOAD, componentName, handleLoadApplicationContainer);
}

function* watchCloneApplication() {
  yield* takeEvery(actionTypes.CLONE_APPLICATION_SUCCESS, handleCloneApplication);
}
