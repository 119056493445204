import PropTypes from 'prop-types';
import PushDealVehicleImage from './PushDealVehicleImage';
import DashboardVehicle from '../Dashboard/components/DashboardVehicle';
import MoneyFormat from '../Common/MoneyFormat';
import classNames from 'classnames';
import { inject } from 'mobx-react';
import './VehicleDetailsSidebar.scss';

const VehicleDetailsSidebar = ({ currentDeal, vehiclePrice, offerPrice, stockUploadStyles, ...props }) => {
  const { isBdk } = props.appStore.uiState;
  const { vehicle, vehicleDiscountFromListPrice } = currentDeal;
  const classes = classNames('VehicleDetailsSidebar', stockUploadStyles && 'VehicleDetailsSidebar--stockUploadStyles');
  const vehicleImageUrls = currentDeal.vehicleImageUrls.map((vehicleImageUrl) => vehicleImageUrl);
  const vehicleClass = vehicle.class && vehicle.class.toLowerCase();

  return (
    <div className={classes} data-th="VehicleDetailsSidebar">
      <div className="VehicleDetailsSidebar__image">
        <PushDealVehicleImage vehicleImageUrls={vehicleImageUrls} vehicleClass={vehicleClass} />
      </div>
      <div className="VehicleDetailsSidebar__vehicleDetails">
        <div className="VehicleDetailsSidebar__vehicleInfo">
          <span className="VehicleDetailsSidebar__vehicleMake">{`${vehicle.make} ${vehicle.model}`}</span>
          <span className="VehicleDetailsSidebar__vehicleDerivative">{vehicle.derivative}</span>
        </div>
        {!isBdk && (
          <div className="VehicleDetailsSidebar__vehicleReg">
            <DashboardVehicle vehicle={vehicle} pushDeal />
          </div>
        )}
        <div className="VehicleDetailsSidebar__vehiclePricing">
          <div className="VehicleDetailsSidebar__vehiclePrice">
            <span className={vehicleDiscountFromListPrice ? 'VehicleDetailsSidebar__vehiclePrice--strikeout' : ''}>
              <MoneyFormat value={vehiclePrice} />
            </span>
          </div>
          <div className="VehicleDetailsSidebar__vehicleOfferPrice">
            {vehicleDiscountFromListPrice && (
              <span>
                <MoneyFormat value={offerPrice} />
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

VehicleDetailsSidebar.propTypes = {
  consumer: PropTypes.object,
  currentDeal: PropTypes.object,
  stockUploadStyles: PropTypes.bool,
  appStore: PropTypes.object
};

export default inject(['appStore'])(VehicleDetailsSidebar);
