import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './addressHistoryPanel.scss';
import Table from './SummaryTable/Table';
import TableRow from './SummaryTable/TableRow';
import { getTimeAtAddressString } from '../../../core/helpers';
import TableHead from './SummaryTable/TableHead';
import TableCell from './SummaryTable/TableCell';
import AddressLine from '../../Common/AddressLine';
import OptionsValue from '../../Common/OptionsValue';
import WordBreak from '../../Common/WordBreak';

const AddressHistoryPanelItem = ({ address, index }) => {
  const { t } = useTranslation('Reporting');
  return (
    <div className="addressHistoryPanel__item">
      <div className="addressHistoryPanel__itemHeader">
        {t('AddressHistoryPanelItem.address')} {index + 1}
      </div>
      <Table>
        <TableRow>
          <TableHead width="25%">{t('AddressHistoryPanelItem.address')}</TableHead>
          <TableCell>
            <WordBreak>
              <AddressLine address={address} />
            </WordBreak>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableHead width="25%">{t('AddressHistoryPanelItem.residency_status')}</TableHead>
          <TableCell>
            <OptionsValue type={'Residency'} value={address.Residency} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableHead width="25%">{t('AddressHistoryPanelItem.time_at_address')}</TableHead>
          <TableCell>{getTimeAtAddressString(address.TimeAtAddress)}</TableCell>
        </TableRow>
      </Table>
    </div>
  );
};

AddressHistoryPanelItem.propTypes = {
  address: PropTypes.object,
  index: PropTypes.number
};
export default AddressHistoryPanelItem;
