import React from 'react';
import { inject } from 'mobx-react';

import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { StockEditContainer } from './components';

const StockEditWrapper = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const addVehicleEnabled = props.appStore.uiState.canAddVehicles;

  if (addVehicleEnabled) {
    return (
      <StockEditContainer>
        <Outlet />
      </StockEditContainer>
    );
  } else {
    navigate(`/d/${params.dealershipId}/stock/list`, {
      replace: true
    });
    return null;
  }
};

export default inject('appStore')(StockEditWrapper);
