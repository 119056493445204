import React from 'react';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';

import Panel from '~Common/Panel';
import PanelHeader from '~Common/PanelHeader';
import PanelContent from '~Common/PanelContent';
import i18n from '~/i18n';
import * as debug from '~/debug';
import { updateFeedVehicle, updateVehicle } from '~/api/stockModule';

import { KeyInformationSection } from '../../../../../../shared/components';
import { EditVehicleForm, QuickTip } from './components';

import './stockEditVehicleDetails.scss';

const StockEditVehicleDetails = (props) => {
  const { handleSubmit, watch } = useFormContext();
  const navigate = useNavigate();
  const { dealershipId, vehicleId } = useParams();

  const { t } = useTranslation('Stock');
  const { vehicleData, vehicleTaxonomyData } = props.appStore.stockEditStore;
  const { vehicleProvider } = props.appStore.stockStore;
  const primaryId = vehicleTaxonomyData?.primaryId || vehicleTaxonomyData?.PrimaryTaxonomyId;
  const secondaryId = vehicleTaxonomyData?.secondaryId || vehicleTaxonomyData?.SecondaryTaxonomyId;

  const handleChangeVehicleClick = () => {
    navigate(`/d/${dealershipId}/stock/edit/${vehicleId}/refine`);
  };

  const lng = i18n.language;

  const onSubmit = async (data) => {
    try {
      if (dealerPlatformUpload) {
        await updateVehicle({
          dealershipId: dealershipId,
          origin: 'dealer',
          provider: vehicleProvider,
          primaryId,
          secondaryId,
          dealerPlatformUpload: dealerPlatformUpload,
          attentionGrabber: data.attentionGrabber,
          bodyStyle: data.bodyStyle,
          class: data.class,
          colour: data.colour,
          condition: data.condition,
          dealerReference: data.dealerReference,
          derivative: data.derivative,
          description: data.description,
          doors: data.doors,
          fuel: data.fuelType,
          make: data.make,
          mileage: data.mileage,
          model: data.model,
          noOfSeats: data.seats,
          numberOfPreviousKeepers: data.keepers,
          options: data.options,
          price: data.price,
          registrationDate: moment.utc(data.regDate, 'DD/MM/YYYY'),
          trans: data.transmission,
          vatStatus: data.vatStatus,
          vehicleId: data.vehicleId,
          vin: data.vin,
          vrm: data.vrm,
          currency: data.currency,
          mileageUnit: data.mileageUnit,
          auctionId: data.auctionId,
          drivingPosition: data.drivingPosition,
          consumptionInner: data.consumptionInner,
          consumptionOuter: data.consumptionOuter,
          consumptionCombined: data.consumptionCombined,
          includesVat: data.includesVat === 'true' ? true : data.includesVat === 'false' ? false : null,
          vatRate: data.vatRate,
          emission: data.emission,
          energyEfficiencyClass: data.energyEfficiencyClass
        });
      } else {
        await updateFeedVehicle({
          attentionGrabber: data.attentionGrabber,
          description: data.description,
          options: data.options,
          dealerReference: data.dealerReference,
          dealershipId: dealershipId,
          vehicleId: data.vehicleId,
          currency: data.currency,
          mileageUnit: data.mileageUnit,
          auctionId: data.auctionId,
          drivingPosition: data.drivingPosition
        });
      }
    } catch (e) {
      debug.error(e);
    }
  };

  const dealerPlatformUpload = vehicleData.dealerPlatformUpload;
  const showDisabledFieldsPanel = !!watch('class');
  return (
    <Panel>
      <PanelHeader>
        <div className="stockEditContainer__panelTitleContainer">
          {t('StockEditVehicleDetails.vehicle_summary')}
          {!dealerPlatformUpload && (
            <div className={`stockEditContainer__tipButtonPosition-${lng}`}>
              <QuickTip title={t('StockEditVehicleDetails.editing_a_feed_vehicle')}>
                {t(
                  'StockEditVehicleDetails.to_change_other_vehicle_information_you_will_need_to_change_this_in_your_source_inventory_management_systems'
                )}
              </QuickTip>
            </div>
          )}
        </div>
      </PanelHeader>
      <PanelContent>
        {showDisabledFieldsPanel && dealerPlatformUpload && (
          <KeyInformationSection onClick={handleChangeVehicleClick} dealerPlatformUpload={dealerPlatformUpload} />
        )}
        <EditVehicleForm
          handleSubmit={handleSubmit(onSubmit)}
          vehicleTaxonomyData={vehicleTaxonomyData || vehicleData.taxonomies[0]}
          dealerPlatformUpload={dealerPlatformUpload}
          resetData={() => {}}
        />
      </PanelContent>
    </Panel>
  );
};

export default inject('appStore')(StockEditVehicleDetails);
