import * as actionTypes from '../actionTypes';

const defaultState = {
  submittingState: '',
  submitError: null
};

export default function addVehicleReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.ADD_CONSUMER_VEHICLE:
      return Object.assign({}, state, { submittingState: 'loading', submitError: null });

    case actionTypes.ADD_CONSUMER_VEHICLE_SUCCESS:
      return Object.assign({}, state, { submittingState: 'done' });

    case actionTypes.ADD_CONSUMER_VEHICLE_ERROR:
      return Object.assign({}, state, { submittingState: 'error', submitError: action.error });

    case actionTypes.ADD_CONSUMER_VEHICLE_ERROR_CLEAR:
      return Object.assign({}, state, defaultState);

    default:
      return state;
  }
}
