import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import languages from './languages';
import Modal from '../Common/Modal/Modal';
import LanguageSelectModal from './LanguageSelectModal';

function LanguageSelectTrigger({ children }) {
  const { i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  if (!languages || !i18n.isInitialized) return null;

  const currentId = i18n.language.substring(0, 2);
  const currentLanguage = languages.filter((id) => id.language === currentId)[0];

  return (
    <>
      {children({
        currentLanguage,
        handleModalOpen,
        handleModalClose
      })}

      <Modal isOpen={isModalOpen} onClose={handleModalClose} autoWidth>
        <LanguageSelectModal selectedLanguage={currentId} />
      </Modal>
    </>
  );
}

export default LanguageSelectTrigger;
