import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './errorApology.scss';

const ErrorApology = ({ children }) => {
  const { t } = useTranslation('Common');
  return (
    <div className="errorApology">
      <div className="errorApology__message">
        <h1 className="errorApology__heading">{t('ErrorApology.sorry_an_error_has_occurred')}</h1>
        <p className="errorApology__text">{children}</p>
      </div>
    </div>
  );
};

ErrorApology.propTypes = {
  children: PropTypes.node
};
export default ErrorApology;
