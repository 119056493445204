import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SavedQuoteDetailsPage from '../components/SavedQuoteDetailsPage';
import ErrorApology from '../../Common/ErrorApology';
import { savedQuoteSelector, vehicleOfInterestSelector } from '../selectors/savedQuoteSelectors';
import * as applicationActions from '../../../redux/application/applicationActions';
import * as analyticsActions from '../../../redux/analytics/analyticsActions';
import { getCustomer } from '../../../selectors/customerSelectors';
import { compose } from 'redux';
import { withOutletContextProps, withParams } from 'hocs/router';

const SavedQuoteDetailsContainer = (props) => {
  const { children, ...remainingProps } = props;

  if (!props.quote) {
    return <ErrorApology>Quote not found.</ErrorApology>;
  }

  if (!props.vehicle) {
    return <ErrorApology>Vehicle not found.</ErrorApology>;
  }

  return <SavedQuoteDetailsPage {...remainingProps} />;
};

SavedQuoteDetailsContainer.propTypes = {
  children: PropTypes.node,
  vehicle: PropTypes.object,
  quote: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  const { quoteId, consumerId } = ownProps.params;
  const savedQuote = savedQuoteSelector(state, { quoteId, consumerId });
  const customer = getCustomer(state, consumerId);
  return {
    proceedingState: state.quotes.proceedingState,
    consumer: customer,
    quote: savedQuote.Quote,
    vehicle: vehicleOfInterestSelector(state, { quoteId, consumerId })
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    handleApply: (vehicle, quote, customerType) =>
      dispatch(applicationActions.createApplication(vehicle, quote, ownProps.params.consumerId, customerType)),
    onPrint: () => dispatch(analyticsActions.trackPageInteraction('SavedQuotePrint', ownProps.params.dealershipId))
  };
}

export default compose(
  withParams,
  withOutletContextProps,
  connect(mapStateToProps, mapDispatchToProps)
)(SavedQuoteDetailsContainer);
