import PropTypes from 'prop-types';
import Table from '../Reporting/PartialSummarySections/SummaryTable/Table';
import TableRow from '../Reporting/PartialSummarySections/SummaryTable/TableRow';
import TableHead from '../Reporting/PartialSummarySections/SummaryTable/TableHead';
import TableCell from '../Reporting/PartialSummarySections/SummaryTable/TableCell';
import { splitCamelCaseToString, getTimeAtAddressString } from '../../core/helpers';
import '../Reporting/PartialSummarySections/addressHistoryPanel.scss';

const EmploymentHistoryPanelItem = ({ employment, index }) => {
  return (
    <div className="employmentHistoryPanelItem">
      <div className="addressHistoryPanel__itemHeader">Employment {index + 1}</div>
      <Table>
        <TableRow>
          <TableHead width="25%">Occupation Basis</TableHead>
          <TableCell>{splitCamelCaseToString(employment.OccupationBasis)}</TableCell>
        </TableRow>
        <TableRow>
          <TableHead width="25%">Occupation Type</TableHead>
          <TableCell>{splitCamelCaseToString(employment.OccupationType)}</TableCell>
        </TableRow>
        <TableRow>
          <TableHead width="25%">Time in Employment</TableHead>
          <TableCell>{getTimeAtAddressString(employment.TimeAtEmployment)}</TableCell>
        </TableRow>
      </Table>
    </div>
  );
};

EmploymentHistoryPanelItem.propTypes = {
  employment: PropTypes.object,
  index: PropTypes.number
};

export default EmploymentHistoryPanelItem;
