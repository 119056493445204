import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import flags from './languages';
import moment from 'moment';
import LanguageFlag from './LanguageFlag';
import { connect } from 'react-redux';
import './languageSelectModal.scss';
import { ModalContext } from '../Common/Modal/Modal';
import { fetchOptions, recreateOptions } from '../../redux/options/optionsActions';

function LanguageSelectModal({ selectedLanguage, recreateOptions, fetchOptions }) {
  const { i18n } = useTranslation();
  const { closeModal } = useContext(ModalContext);
  const handleChangeLanguage = async (language) => {
    await i18n.changeLanguage(language);
    moment.locale(language);
    fetchOptions();
    recreateOptions();
    closeModal();
  };

  return (
    <div className="languageSelectModal">
      {flags.map((flag) => (
        <button
          className={`languageSelectModal__button  ${selectedLanguage === flag.language &&
            'languageSelectModal__button--active'}`}
          onClick={() => handleChangeLanguage(flag.language)}
          key={`${flag.language}-flag-key`}
          aria-label={`select language ${flag.label}`}
        >
          <LanguageFlag imgSrc={flag.imageSrc} />
          <span className="languageSelectModal__label">{flag.label}</span>
        </button>
      ))}
    </div>
  );
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOptions: () => dispatch(fetchOptions()),
    recreateOptions: () => dispatch(recreateOptions())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelectModal);
