import platformApiUtils from '@ivendi/platform-api-utils';
import { schemaMap } from '../core/schema';
import applicationSchema from '../core/schemas/applicationSchema';
import { parseApplication } from '../core/apiDataParsers/applicationDataParser';
import uuid from 'uuid';
import moment from 'moment';
import { trackVehicleFinanceApplicationStarted } from '../tracking/avoTracking';

export const fetchApplication = (applicantId) => {
  return new Promise(function(resolve, reject) {
    platformApiUtils
      .get('v1/customerapplication/' + applicantId)
      .then((response) => resolve(parseApplication(schemaMap(response, applicationSchema))))
      .catch((error) => reject(error));
  });
};

export const submitMultipleApplications = (
  customerId,
  vehicleId,
  quoteIds,
  customerType,
  multiRefId,
  quote,
  dealershipId
) => {
  let multiQuoteRefId = multiRefId || uuid.v4();
  let applicationCount = multiRefId ? quoteIds.length + 1 : quoteIds.length;

  let promiseArray = quoteIds.map((quoteId) => {
    let appId = uuid.v4();

    return new Promise((resolve, rej) => {
      platformApiUtils
        .post('v1/customer/' + customerId + '/application', {
          ApplicationId: appId,
          DealershipConsumerId: customerId,
          VehicleId: vehicleId,
          QuoteId: quoteId,
          CustomerType: customerType,
          Tags: JSON.stringify({
            multiQuoteRefId,
            applicationCount
          })
        })
        .then(() => {
          // Only track the application if it is the vehicle one
          // we don't want to track the personal loan or negative equity loan applications
          if (quote.VehicleLoan.QuoteId === quoteId) {
            trackVehicleFinanceApplicationStarted({
              dealershipId,
              financeFunderCode: quote.FunderCode,
              financeFunderProductId: quote.FunderProductUID,
              financeProductType: quote.FinanceType,
              financeApplicationId: appId,
              quoteId
            });
          }
          resolve(appId);
        })
        .catch(rej);
    });
  });

  return Promise.all(promiseArray);
};

export const submitFullApplication = (applicationData) => {
  delete applicationData.BankDetails.Sortcode;

  return platformApiUtils.post(`v2/applicant/${applicationData.Id}/update-and-full-submit`, {
    Id: applicationData.Id,
    QuoteId: applicationData.QuoteId,
    ImageUrl: applicationData.Vehicle.CdnVehicleImageUrl,
    PersonalDetails: {
      Title: applicationData.PersonalDetails.Title,
      FirstName: applicationData.PersonalDetails.FirstName,
      MiddleName: applicationData.PersonalDetails.MiddleNames,
      LastName: applicationData.PersonalDetails.LastName,
      Email: applicationData.PersonalDetails.Email,
      HomeTelephone: applicationData.PersonalDetails.HomeTelephone,
      MobileTelephone: applicationData.PersonalDetails.MobileTelephone,
      Gender: applicationData.PersonalDetails.Gender,
      DateOfBirth: applicationData.PersonalDetails.DateOfBirth
        ? moment.utc(applicationData.PersonalDetails.DateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : '',
      NoOfDependants: applicationData.PersonalDetails.NoOfDependants,
      MaritalStatus: applicationData.PersonalDetails.MaritalStatus,
      DrivingLicence: applicationData.PersonalDetails.DrivingLicence,
      DrivingLicenceNumber: applicationData.PersonalDetails.DrivingLicenceNumber,
      Nationality: applicationData.PersonalDetails.Nationality,
      DualNationality: applicationData.PersonalDetails.DualNationality,
      CountryOfBirth:
        applicationData.PersonalDetails.CountryOfBirth.BornUK === 'false'
          ? applicationData.PersonalDetails.CountryOfBirth.BirthPlace
          : 'GB',
      DirectorOrBoardMemberCountryOfOperation:
        applicationData.PersonalDetails.DirectorOrBoardMember.Director === 'false'
          ? null
          : applicationData.PersonalDetails.DirectorOrBoardMember.CountryOfOperation,
      DirectorOrBoardMemberCompanySector:
        applicationData.PersonalDetails.DirectorOrBoardMember.Director === 'false'
          ? null
          : applicationData.PersonalDetails.DirectorOrBoardMember.CompanySector,
      VatNumber: applicationData.PersonalDetails.VatNumber
    },
    AddressHistory: applicationData.AddressHistory.Items.map((item) => ({
      ...item,
      TimeAtAddress: item.TimeAtAddress.TotalMonths
    })),
    EmploymentHistory: applicationData.EmploymentHistory.Items.map((item) => ({
      ...item,
      TimeAtEmployment: item.TimeAtEmployment.TotalMonths
    })),
    BankDetails: {
      ...applicationData.BankDetails,
      TimeAtBank: applicationData.BankDetails.TimeAtBank.TotalMonths
    },
    Affordability: {
      GrossAnnual: applicationData.AffordabilityDetails.Income.GrossAnnual,
      HasOtherHouseholdIncome: applicationData.AffordabilityDetails.Income.HasOtherHouseholdIncome,
      OtherHouseholdIncome: applicationData.AffordabilityDetails.Income.OtherHouseholdIncome,
      NetMonthly: applicationData.AffordabilityDetails.Income.NetMonthly,
      MortgageOrRental: applicationData.AffordabilityDetails.MonthlyExpenditure.MortgageOrRental,
      Other: applicationData.AffordabilityDetails.MonthlyExpenditure.Other,
      ChangesExpected: applicationData.AffordabilityDetails.PersonalCircumstances.ChangesExpected,
      DetailsOfExpectedChanges: applicationData.AffordabilityDetails.PersonalCircumstances.DetailsOfExpectedChanges,
      IsReplacementLoan: applicationData.AffordabilityDetails.ReplacementLoan.IsReplacementLoan,
      DetailsOfReplacementLoan: applicationData.AffordabilityDetails.ReplacementLoan.DetailsOfReplacementLoan,
      ForeseeFutureDownturnInFinances:
        applicationData.AffordabilityDetails.PersonalCircumstances.ForeseeFutureDownturnInFinances,
      DownturnReason: applicationData.AffordabilityDetails.PersonalCircumstances.DownturnReason,
      ForeseenYearlyIncome: applicationData.AffordabilityDetails.PersonalCircumstances.ForeseenYearlyIncome
    },
    PrivacyPreferences: {
      LenderMarketingByEmailConsent: applicationData.marketingPreference,
      LenderMarketingDisclaimerVersionId: applicationData.marketingVersion
    },
    ArrangedFlags: applicationData.ArrangedFlags,
    Tags: JSON.stringify(applicationData.Tags)
  });
};

/**
 * @exports submitChangeApplicantDecision
 * @param {string} decision guid
 * @param {string} consumerId guid
 * @param {string} applicationId guid
 * @param {number} agreementNumber random 16 digit int
 * @example submitChangeApplication('rejected','guid-string','guid-string',1234567890123456)
 */
export const submitChangeApplicantDecision = (decision, consumerId, applicantId, agreementNumber) => {
  return platformApiUtils.post(
    `v1/change-applicant-decision/${decision}/customers/${consumerId}/application/${applicantId}/agreementNumber/iv-no-poll-${agreementNumber}`
  );
};
