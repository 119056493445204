import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import EditConsumerForm from './EditConsumerForm';
import EditCorporateForm from './EditCorporateForm';
import { useParams } from 'react-router-dom';

const EditCustomer = ({ consumer }) => {
  const params = useParams();
  const { t } = useTranslation('EditCustomer');
  const customerType = consumer.CustomerType;
  return (
    <Page>
      <Breadcrumbs
        items={[
          {
            name: t('EditCustomer.home'),
            path: `/d/${params.dealershipId}`
          },
          {
            name: t('EditCustomer.customer_list'),
            path: `/d/${params.dealershipId}/consumers`
          },
          {
            name: t('EditCustomer.consumer'),
            path: `/d/${params.dealershipId}/consumers/${params.consumerId}`
          },
          {
            name: t('EditCustomer.edit_customer')
          }
        ]}
        consumer={consumer}
      />
      <Panel>
        <PanelHeader>{t('EditCustomer.edit_customer_contact')}</PanelHeader>
        <PanelContent>
          {customerType === 'Consumer' ? (
            <EditConsumerForm customer={consumer} dealershipId={params.dealershipId} />
          ) : (
            <EditCorporateForm customer={consumer} dealershipId={params.dealershipId} />
          )}
        </PanelContent>
      </Panel>
    </Page>
  );
};

EditCustomer.propTypes = {
  consumer: PropTypes.object.isRequired,
  updateCustomer: PropTypes.func,
  submittingState: PropTypes.string
};
export default EditCustomer;
