import styles from './reportDetails.module.scss';

export type ReportDetailsProps = {
  title: string;
  description: string;
};

const ReportDetails = ({ title, description }: ReportDetailsProps) => {
  return (
    <div className={styles['container']}>
      <h1 className={styles['title']}>{title}</h1>
      <p className={styles['details']}>{description}</p>
    </div>
  );
};

export default ReportDetails;

