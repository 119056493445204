type VatPercentagePerCountryType = {
  [key: string]: number;
};

const vatPercentagePerCountry: VatPercentagePerCountryType = {
  uk: 20,
  de: 19
};

export const getCountryVatPercentage = (countryCode: string) => {
  const code = countryCode.toLowerCase() as keyof VatPercentagePerCountryType;
  return vatPercentagePerCountry[code] || 20;
};
