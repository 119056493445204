import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import CheckboxWithLabel from '../Common/Form/CheckboxWithLabel';
import InformationWarning from '../Common/InformationWarning';
import FormConsumerVehicleDetails from '../Common/FormConsumerVehicleDetails';
import { observer, inject } from 'mobx-react';
import { observable, action, toJS } from 'mobx';
import MobxForm from '../MobxForm/MobxForm';
import MobxFieldGroup from '../MobxForm/MobxFieldGroup';
import MobxLabel from '../MobxForm/MobxFormLabel';
import TextInputWrappingInput from '../Common/Form/TextInputWrappingInput';
import Button from '../Common/Button/Button';
import _ from 'lodash';
import { withParams, withNavigate } from 'hocs/router';
import './markAsNotAvailable.scss';

@observer
class MarkAsNotAvailable extends React.Component {
  @observable
  formData = {
    Notes: '',
    RemoveFromDashboard: false
  };

  @action
  setNotes = (Notes) => (this.formData.Notes = Notes);

  @action
  toggleRemoveFromDashboard = () => (this.formData.RemoveFromDashboard = !this.formData.RemoveFromDashboard);

  UNSAFE_componentWillMount() {
    const vehicle = this.props.appStore.customerStore.customer.getVehicle(this.props.params.vehicleId);
    this.setInitialValues(vehicle);
  }

  @action
  setInitialValues = (vehicle) => {
    Object.assign(
      this.formData,
      _.get(vehicle, 'Status.Status') ? vehicle.Status : this.props.appStore.closeDealStore.closeDealData
    );
  };
  submit = () => {
    const closeDealStore = this.props.appStore.closeDealStore;
    const customerStore = this.props.appStore.customerStore;
    closeDealStore
      .markLeadAsNotAvailable(
        toJS(this.formData),
        customerStore.customer.CustomerType,
        this.props.params.vehicleId,
        this.props.params.consumerId
      )
      .then(() => {
        this.props.navigate(`/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}`);
      });
  };
  goBack = () => {
    this.props.navigate(-1);
  };

  render() {
    if (this.props.appStore.customerStore.isLoadingCustomer) {
      return null;
    }
    const {
      uiState,
      closeDealStore,
      customerStore: { customer }
    } = this.props.appStore;

    const vehicle = customer.getVehicle(this.props.params.vehicleId);

    return (
      <MobxForm onSubmit={this.submit}>
        <div className="markAsNotAvailable__inner">
          <div className="markAsNotAvailable__vehicleDetails">
            <FormConsumerVehicleDetails consumer={customer} vehicle={vehicle} />
          </div>
          <div className="markAsNotAvailable__comment">
            <MobxFieldGroup>
              <MobxLabel htmlFor="Notes">{this.props.t('MarkAsNotAvailable.close_out_comment')}</MobxLabel>
              <TextInputWrappingInput
                value={this.formData.Notes}
                onChange={this.setNotes}
                tabIndex="1"
                id="CloseOutComment"
                type="text"
              />
            </MobxFieldGroup>
          </div>
          {uiState.canSeeDashboard && (
            <>
              <div className="markAsNotAvailable__infoWarning" key="info1">
                <InformationWarning
                  text={this.props.t(
                    'MarkAsNotAvailable.selecting_to_hide_the_deal_will_remove_visibility_from_the_homepage_dashboard_the_details_will_still_be_accessible_from_the_contacts_area_of_the_platform_removing_dormant_deals_from_your_dashboard_is_a_good_house_keeping_practice'
                  )}
                />
              </div>
              <div className="markAsNotAvailable__checkbox">
                <CheckboxWithLabel
                  onChange={this.toggleRemoveFromDashboard}
                  value={this.formData.RemoveFromDashboard}
                  id="removeFromDashboard"
                >
                  {this.props.t('MarkAsNotAvailable.hide_this_lead_from_the_homepage_after_closing')}
                </CheckboxWithLabel>
              </div>
            </>
          )}
        </div>
        <div className="markAsNotAvailable__buttons">
          <div className="markAsNotAvailable__proceedButton">
            <Button
              type="submit"
              isLoading={closeDealStore.sendCloseDealRequest.isLoading}
              hasError={closeDealStore.sendCloseDealRequest.hasError}
            >
              {this.props.t('MarkAsNotAvailable.proceed')}
            </Button>
          </div>
          <div className="markAsNotAvailable__cancelButton">
            <Button buttonStyle="cancel" type="button" onClick={this.goBack}>
              {this.props.t('MarkAsNotAvailable.cancel')}
            </Button>
          </div>
        </div>
      </MobxForm>
    );
  }
}

MarkAsNotAvailable.propTypes = {
  appStore: PropTypes.object,
  params: PropTypes.object
};

export default compose(
  withParams,
  withNavigate,
  withTranslation('CloseDeal'),
  inject(['appStore']),
  observer
)(MarkAsNotAvailable);
