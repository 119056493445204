export default function callApiMiddleware({ dispatch, getState }) {
  return function(next) {
    return function(action) {
      const { types, callAPI, parseResponse, shouldCallAPI = () => true, payload = {} } = action;

      if (!types) {
        // Normal action: pass it on
        return next(action);
      }

      if (!Array.isArray(types) || types.length !== 3 || !types.every((type) => typeof type === 'string')) {
        throw new Error('Expected an array of three string types.');
      }

      if (typeof callAPI !== 'function') {
        throw new Error('Expected fetch to be a function.');
      }

      if (!shouldCallAPI(getState())) {
        return false;
      }

      const [requestType, successType, failureType] = types;

      dispatch(
        Object.assign({}, payload, {
          type: requestType
        })
      );

      return callAPI().then(
        (response) => {
          let parsedResponse = parseResponse ? parseResponse(response) : response;

          dispatch(
            Object.assign(
              {},
              {
                response: parsedResponse,
                type: successType
              },
              payload
            )
          );

          if (action.onSuccess) {
            action.onSuccess(getState(), response);
          }
        },
        (error) =>
          dispatch(
            Object.assign({}, payload, {
              error: error,
              type: failureType
            })
          )
      );
    };
  };
}
