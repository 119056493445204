import { withTranslation } from 'react-i18next';
import { Component } from 'react';
import Validator from '../../../validators/Validator';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import CurrencyInput from '../../Common/Form/CurrencyInput';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import { inject, observer } from 'mobx-react';
import SelectInput from '../../Common/Form/SelectInput';
import PercentageInput from '../../Common/Form/PercentageInput';
import { reaction } from 'mobx';
import Button from '../../Common/Button/Button';
import PropTypes from 'prop-types';
import './repExampleVehiclePriceForm.scss';

class RepExampleVehiclePriceForm extends Component {
  constructor(props) {
    super(props);
    this.validationRules = {
      VehiclePrice: 'required, currency',
      Term: 'required',
      CashDeposit: 'required, currency',
      AnnualDistance: 'required',
      Apr: 'required, number'
    };
    this.setUpValidation();
  }

  setUpValidation() {
    this.validator = new Validator();
    this.validator.setRules(this.validationRules);
    this.validationReactionDisposer = reaction(
      () => ({ ...this.props.appStore.repExampleStore.pricingForm }),
      this.validator.validate,
      {
        fireImmediately: true
      }
    );
  }

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  handleSubmit = () => {
    if (!this.validator.errorCount) {
      this.props.appStore.repExampleStore.updateProductApr();
      this.props.appStore.repExampleStore.setCustomPage('showQuotes');
    }

    window.ga && window.ga('send', 'event', 'RepExSelectPriceDetails', 'ShowQuotes', 'RepExampleSubmitPriceDetails');
  };
  handleGoBack = () => {
    this.props.appStore.repExampleStore.setCustomPage('vehicleForm');
    window.ga &&
      window.ga(
        'send',
        'event',
        'RepExSelectPriceDetails',
        'RepExampleGoBackToVehicleDetails',
        'RepExampleBackToVehicleDetails'
      );
  };

  render() {
    const errors = this.validator.getErrors();
    const { repExampleStore } = this.props.appStore;
    const { pricingForm, handlePricingFormChange } = repExampleStore;
    return (
      <div>
        <MobxForm onSubmit={this.handleSubmit} className="repExampleVehiclePriceForm">
          <div className="repExampleVehiclePriceForm__section">
            <MobxFieldGroup isInline error={errors.VehiclePrice}>
              <MobxFormLabel htmlFor="VehiclePrice">
                {this.props.t('RepExampleVehiclePriceForm.vehicle_price')}
              </MobxFormLabel>
              <CurrencyInput
                dataThook="vehiclePrice"
                value={pricingForm.VehiclePrice}
                onChange={(value) => {
                  handlePricingFormChange('VehiclePrice', value);
                }}
              />
            </MobxFieldGroup>
            <MobxFieldGroup isInline error={errors.CashDeposit}>
              <MobxFormLabel>{this.props.t('RepExampleVehiclePriceForm.cash_deposit')}</MobxFormLabel>
              <CurrencyInput
                dataThook="cashDeposit"
                value={pricingForm.CashDeposit}
                onChange={(value) => {
                  handlePricingFormChange('CashDeposit', value);
                }}
              />
            </MobxFieldGroup>
            <MobxFieldGroup isInline error={errors.Term}>
              <MobxFormLabel>{this.props.t('RepExampleVehiclePriceForm.term_months')}</MobxFormLabel>
              <SelectInput
                dataThook="term"
                options="PreferredTerm"
                value={pricingForm.Term}
                onChange={(id, value) => {
                  handlePricingFormChange('Term', value);
                }}
              />
            </MobxFieldGroup>
            <MobxFieldGroup isInline error={errors.AnnualDistance}>
              <MobxFormLabel>{this.props.t('RepExampleVehiclePriceForm.annual_mileage')}</MobxFormLabel>
              <SelectInput
                dataThook="mileage"
                options="MileageOptions"
                value={pricingForm.AnnualDistance}
                onChange={(id, value) => {
                  handlePricingFormChange('AnnualDistance', value);
                }}
              />
            </MobxFieldGroup>
            <MobxFieldGroup isInline error={errors.Apr}>
              <MobxFormLabel>{this.props.t('RepExampleVehiclePriceForm.representative_apr')}</MobxFormLabel>
              <PercentageInput
                dataThook="apr"
                value={pricingForm.Apr}
                onChange={(id, value) => {
                  handlePricingFormChange('Apr', value);
                }}
              />
            </MobxFieldGroup>
          </div>

          <div className="repExampleVehiclePriceForm__repButton">
            <div className="repExampleVehiclePriceForm__repButtonLeft">
              <Button type="button" buttonStyle="cancel" onClick={this.handleGoBack}>
                {this.props.t('RepExampleVehiclePriceForm.go_back')}
              </Button>
            </div>
            <div className="repExampleVehiclePriceForm__repButtonRight">
              <Button type="submit" buttonStyle="primary" onClick={this.handleSubmit}>
                {this.props.t('RepExampleVehiclePriceForm.show_quotes')}
              </Button>
            </div>
          </div>
        </MobxForm>
      </div>
    );
  }
}

export default withTranslation('RepExampleSettings')(inject('appStore')(observer(RepExampleVehiclePriceForm)));
RepExampleVehiclePriceForm.propTypes = {
  appStore: PropTypes.object
};
