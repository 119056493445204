import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';

import Modal from '~Common/Modal/Modal';
import Icon from '~Common/Icon/Icon';

import './QuickTip.scss';

const QuickTip = ({ style, children, title }) => {
  const { t } = useTranslation('Stock');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const HoverText = () => {
    return (
      <div className="quickTip__hoverModal">
        <div className="quickTip__hoverModalText">{children}</div>
      </div>
    );
  };

  return (
    <div>
      {isMobile || isTablet ? (
        <span>
          <button style={style} className={'quickTip__button'} onClick={showModal} type="button">
            <Icon name="discover" />
            <span>{t('QuickTip.quick_tip')}</span>
          </button>
          <Modal title={`${title}`} onClose={closeModal} isOpen={isModalOpen}>
            {children}
          </Modal>
        </span>
      ) : (
        <span className="quickTip__hoverSectionContainer">
          <button
            style={style}
            className={'quickTip__button'}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            type="button"
          >
            <Icon name="discover" />
            <span className="quickTip__text">{t('QuickTip.quick_tip')}</span>
          </button>
          {isHovering && <HoverText />}
        </span>
      )}
    </div>
  );
};

export default QuickTip;
