import React, { useEffect } from 'react';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

import { StockListComparisonCard, Tab, TabList, TabPanel, Tabs } from './components';

import styles from './comparisonCards.module.scss';

const ComparisonCards = ({
  tabs = false,
  handleTabsChange,
  tabIndex,
  mediaCompanyVehicleCardData,
  ivendiVehicleCardData,
  selectedSessionVehicleCardData,
  isVehicleSelected = false
}) => {
  const { t } = useTranslation('Stock');

  useEffect(() => {
    if (tabs) {
      isEqual(mediaCompanyVehicleCardData, ivendiVehicleCardData) ? handleTabsChange(0) : handleTabsChange(1);
    }
    // Only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CardOneHeading = t('StockListFixIssuesContainer.supplied_vehicle_info');
  const CardOne = (
    <StockListComparisonCard
      heading={CardOneHeading}
      currentVehicle={mediaCompanyVehicleCardData}
      comparisonVehicle={mediaCompanyVehicleCardData}
      className={styles.comparisonCard}
      isSuppliedVehicle
    />
  );

  const CardTwoHeading = isVehicleSelected
    ? t('StockListFixIssuesContainer.vehicle_you_selected')
    : t('StockListFixIssuesContainer.what_we_use_for_quoting');

  const CardTwo = isVehicleSelected ? (
    <StockListComparisonCard
      heading={CardTwoHeading}
      currentVehicle={selectedSessionVehicleCardData}
      comparisonVehicle={mediaCompanyVehicleCardData}
      className={styles.comparisonCard}
    />
  ) : (
    <StockListComparisonCard
      heading={CardTwoHeading}
      currentVehicle={ivendiVehicleCardData}
      comparisonVehicle={mediaCompanyVehicleCardData}
      className={styles.comparisonCard}
    />
  );

  if (tabs) {
    return (
      <Tabs onValueChange={handleTabsChange} value={tabIndex}>
        <TabList>
          <Tab value={0}>{CardOneHeading}</Tab>
          <Tab value={1}>{CardTwoHeading}</Tab>
        </TabList>

        <TabPanel value={0}>{CardOne}</TabPanel>
        <TabPanel value={1}>{CardTwo}</TabPanel>
      </Tabs>
    );
  }

  return (
    <>
      {CardOne}
      {CardTwo}
    </>
  );
};

export default ComparisonCards;
