import { types } from '../schema';
import { CfcCheckDetailsQuoteSchema } from './quoteSchema';

const cfcCheckDetailsSchema = {
  AddressHistory: [
    {
      BuildingName: types.String,
      BuildingNumber: types.String,
      Country: types.String,
      County: types.String,
      District: types.String,
      Organisation: types.String,
      PostTown: types.String,
      Postcode: types.String,
      Residency: types.String,
      Street: types.String,
      SubBuilding: types.String,
      TimeAtAddress: {
        Months: types.Number,
        TotalMonths: types.Number,
        Years: types.Number
      }
    }
  ],
  CreatedDate: types.String,
  CreatedDateTimestamp: types.Number,
  CustomerId: types.String,
  CustomerType: types.String,
  DealershipId: types.String,
  EmploymentHistory: [
    {
      BuildingName: types.String,
      BuildingNumber: types.String,
      Country: types.String,
      County: types.String,
      District: types.String,
      Employer: types.String,
      Occupation: types.String,
      OccupationBasis: types.String,
      OccupationType: types.String,
      Organisation: types.String,
      PostTown: types.String,
      Postcode: types.String,
      Street: types.String,
      SubBuilding: types.String,
      TelephoneNumber: types.String,
      TimeAtEmployment: {
        Months: types.Number,
        TotalMonths: types.Number,
        Years: types.Number
      }
    }
  ],
  Id: types.String,
  LastModified: types.String,
  LastModifiedTimestamp: types.Number,
  PersonalDetails: {
    DateOfBirth: types.String,
    DrivingLicence: types.String,
    Email: types.String,
    FirstName: types.String,
    Gender: types.String,
    HomeTelephone: types.String,
    LastName: types.String,
    MaritalStatus: types.String,
    MiddleNames: types.String,
    MobileTelephone: types.String,
    Nationality: types.String,
    NoOfDependants: types.String,
    Title: types.String
  },
  Quote: CfcCheckDetailsQuoteSchema,
  QuoteId: types.String,
  Status: types.String,
  Vehicle: {
    CapId: types.Number,
    CapMakeId: types.Number,
    CapModelId: types.Number,
    CdnVehicleImageUrl: types.String,
    Class: types.String,
    Condition: types.String,
    CreatedTimestamp: types.Number,
    Derivative: types.String,
    HiddenOnDashboard: types.Boolean,
    Make: types.String,
    MakeId: types.Number,
    Mileage: types.Number,
    Model: types.String,
    ModelId: types.String,
    RegistrationDate: types.String,
    SanitizedVrm: types.String,
    Status: {
      AcceptanceFeeLast: types.Number,
      AccessoriesInsurance: types.Number,
      AccessoriesOther: types.Number,
      AccessoriesWarranty: types.Number,
      ActualCashDeposit: types.Number,
      Advance: types.Number,
      AnnualDistanceQuoted: types.Number,
      BasicPrice: types.Number,
      CashPrice: types.Number,
      ChargePerOverDistanceUnit: types.Number,
      Commission: types.Number,
      DateOfHandover: types.String,
      DateOfPurchase: types.String,
      Deposit: types.Number,
      FacilitatorName: types.String,
      FinalPayment: types.Number,
      FinanceType: types.String,
      FirstPayment: types.Number,
      FlatRate: types.Number,
      FollowedBy: types.Number,
      FollowingPayments: types.Number,
      NonVatableItems: types.Number,
      OptionToPurchaseFee: types.Number,
      PartExchange: types.Number,
      RepresentativeApr: types.Number,
      RequestedDeposit: types.Number,
      Settlement: types.Number,
      StandInValue: types.Number,
      Status: types.String,
      Term: types.Number,
      TotalAmountPayable: types.Number,
      VAT: types.Number,
      VATAmount: types.Number
    },
    VehicleId: types.String,
    Vrm: types.String
  },
  VehicleId: types.String,
  Version: types.Number
};

export default cfcCheckDetailsSchema;
