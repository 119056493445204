import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../Common/Icon/Icon';
import AddressLine from '../../../Common/AddressLine';
import './address.scss';

const Address = ({ address }) => {
  const { t } = useTranslation('Customer');
  return (
    <div className="address">
      <div className="address__inner">
        <div className="address__icon">
          <Icon name="location" />
        </div>
        <div className="address__bottom">{t('Address.address')}</div>
        <div className="address__addressOuter">
          <div className="address__text">
            <AddressLine address={address} noAddress={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

Address.propTypes = {
  address: PropTypes.object
};
export default Address;
