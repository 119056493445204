import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as modalActions from '../../../redux/modal/modalActions';
import ProceedFromCorporateQuickQuoteModal from '../modals/ProceedFromCorporateQuickQuoteModal';

const ProceedFromCorporateQuickQuoteContainer = (props) => {
  return <ProceedFromCorporateQuickQuoteModal {...props} />;
};

ProceedFromCorporateQuickQuoteContainer.propTypes = {
  onSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  submittingState: PropTypes.string,
  submittingExistingCustomerState: PropTypes.string,
  existingCustomer: PropTypes.object,
  isOpen: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    vehicle: state.quickQuote.vehicle,
    quote: state.quickQuote.quote,
    dealershipId: state.dealership.Id,
    isOpen: state.modals.proceedWithCorporateQuickQuote ? state.modals.proceedWithCorporateQuickQuote.isOpen : false
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(modalActions.close('proceedWithCorporateQuickQuote'));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProceedFromCorporateQuickQuoteContainer);
