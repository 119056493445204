import { GroupedVaps, ValueAddedProductType, VapToDisplay } from '../types';

const getVapsFromQuoteObject = (vaps: ValueAddedProductType[]) => {
  if (!vaps || vaps.length === 0) return undefined;

  if (vaps) {
    const groupedVaps = vaps.reduce((obj: GroupedVaps, item: ValueAddedProductType) => {
      const key = `VAP__${item.ProductTypeText}`;

      if (!obj[key]) {
        obj[key] = [];
      }

      obj[key].push(item);

      return obj;
    }, {});

    const vapsToDisplay: VapToDisplay = Object.keys(groupedVaps).reduce((obj: VapToDisplay, key: string) => {
      const vapGroup: ValueAddedProductType[] = groupedVaps[key];

      const price: number = vapGroup.reduce(
        (accumulator: number, currentVap: ValueAddedProductType) => accumulator + currentVap.Price,
        0
      );

      obj[key] = price;

      return obj;
    }, {});

    return vapsToDisplay || undefined;
  }
};

export default getVapsFromQuoteObject;
