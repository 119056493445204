import { useTranslation, withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import PanelToolbar from '../../Common/PanelToolBar';
import ManageUsersTable from './ManageUsersTable';
import Button from '../../Common/Button/Button';
import AlignRight from '../../Common/AlignRight';
import { observer, inject } from 'mobx-react';
import { withOutletContextProps, withParams } from 'hocs/router';
import { compose } from 'redux';

const ManageUsers = ({ t, params, appStore, users }) => {
  return (
    <Page>
      <Breadcrumbs
        items={[
          {
            name: t('ManageUsers.home'),
            path: `/d/${params.dealershipId}`
          },
          {
            name: t('ManageUsers.settings'),
            path: `/d/${params.dealershipId}/settings`
          },
          {
            name: t('ManageUsers.manage_users'),
            path: `/d/${params.dealershipId}/settings/users`
          }
        ]}
      />
      <Panel>
        <PanelHeader>{t('ManageUsers.manage_users')}</PanelHeader>
        <PanelToolbar>
          {appStore.uiState.canAddUser && (
            <AlignRight>
              <Button type="button" buttonStyle="primary" to={`/d/${params.dealershipId}/settings/users/adduser`}>
                {t('ManageUsers.add_user')}
              </Button>
            </AlignRight>
          )}
        </PanelToolbar>
        <PanelContent noPadding={true}>
          <ManageUsersTable users={users} dealershipId={params.dealershipId} />
        </PanelContent>
      </Panel>
    </Page>
  );
};

ManageUsers.propTypes = {
  users: PropTypes.array,
  params: PropTypes.object,
  appStore: PropTypes.object
};

export default compose(
  withParams,
  withOutletContextProps,
  withTranslation('ManageUsers'),
  inject(['appStore']),
  observer
)(ManageUsers);
