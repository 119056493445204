import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Common/Button/Button';
import Alert from '../Common/Alert';
import { Link } from 'react-router-dom';
import MobxForm from '../MobxForm/MobxForm';
import MobxFormFieldGroup from '../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../MobxForm/MobxFormLabel';
import TextInputWrappingInput from '../Common/Form/TextInputWrappingInput';
import { observable, action, reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import Validator from '../../validators/Validator';
import { compose } from 'redux';
import { withNavigate } from 'hocs/router';
import './forgotPassword.scss';
class ForgotPassword extends React.Component {
  @observable
  email = '';

  constructor() {
    super();
    const validationRules = {
      email: 'required, email'
    };
    this.setUpValidation(validationRules);
  }

  setUpValidation(validationRules) {
    this.validator = new Validator();
    this.validator.setRules(validationRules);
    this.validationReactionDisposer = reaction(
      () => this.email,
      (email) => {
        this.validator.validate({
          email
        });
      },
      {
        fireImmediately: true
      }
    );
  }

  @action
  setEmail = (email) => {
    this.email = email;
  };
  handleSubmit = () => {
    const {
      appStore: { authStore }
    } = this.props;

    if (!this.validator.errorCount) {
      authStore.requestResetPassword(this.email).then(() => {
        this.props.navigate('/auth/forgotpasswordrequested');
      });
    }
  };

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  render() {
    const errors = this.validator.getErrors();
    const {
      appStore: { authStore }
    } = this.props;
    return (
      <div>
        <h1 className="forgotPassword__header" key="header">
          {this.props.t('ForgotPassword.forgotten_password')}
        </h1>
        <p className="forgotPassword__text">{this.props.t('ForgotPassword.password_reset_help_text')}</p>

        <div className="forgotPassword__inputArea">
          <MobxForm onSubmit={this.handleSubmit} focusOnFirstElement>
            <MobxFormFieldGroup error={errors.email}>
              <MobxFormLabel htmlFor="email">{this.props.t('ForgotPassword.email')}</MobxFormLabel>
              <TextInputWrappingInput id="email" value={this.email} onChange={this.setEmail} />
            </MobxFormFieldGroup>

            <div className="forgotPassword__actions">
              <Button
                buttonStyle="primary"
                isLoading={authStore.forgotPasswordRequest.isLoading}
                hasError={authStore.forgotPasswordRequest.hasError}
                type="submit"
              >
                {this.props.t('ForgotPassword.submit')}
              </Button>
              <Link to="/auth/signin" className="forgotPassword__passwordLink" id="ForgotPassword">
                <h1 className="forgotPassword__backToLogin">{this.props.t('ForgotPassword.back_to_log_in')}</h1>
              </Link>
            </div>
          </MobxForm>

          {authStore.forgotPasswordRequest.hasError && (
            <div className="forgotPassword__error">
              <Alert>{this.props.t('ForgotPassword.there_has_been_an_error_with_your_request')}</Alert>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  dispatch: PropTypes.func,
  formData: PropTypes.object,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  appStore: PropTypes.object,
  authStore: PropTypes.object
};

export default compose(withNavigate, withTranslation('ForgotPassword'), inject(['appStore']), observer)(ForgotPassword);
