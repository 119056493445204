import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { fetchCapData } from '../../../api/vrmLookup';
import classnames from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import LoadingSpinner from '../Loading/LoadingSpinner';
import './selectInput.scss';

class SelectModelInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      models: []
    };
  }

  componentDidMount() {
    this.fetchCapData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.makeId !== this.props.makeId ||
      prevProps.vehicleClass !== this.props.vehicleClass ||
      (prevProps.registrationDate !== this.props.registrationDate &&
        moment(this.props.registrationDate, 'MM-DD-YYYY').isValid())
    ) {
      this.fetchCapData();
    }
  }

  fetchCapData = async () => {
    if (!this.props.makeId) {
      this.setState({
        models: []
      });
      return;
    }

    this.setState({
      isLoading: true
    });
    this.props.onFetchModels && this.props.onFetchModels();
    let modelKeys = {};
    let models = await fetchCapData({
      class: this.props.vehicleClass,
      makeId: this.props.makeId,
      regDate: this.props.registrationDate
    });
    models = _.sortBy(models, 'Value');
    models.forEach((model) => {
      modelKeys[model.Key] = model.Value;
    });
    this.props.onFetchModelsSuccess && this.props.onFetchModelsSuccess(modelKeys);
    setTimeout(() => {
      this.setState({
        isLoading: false,
        models
      });
    }, 250);
  };
  handleChange = (event) => {
    this.props.onChange && this.props.onChange(event);
  };
  renderOptions = () => {
    return this.state.models.map((model) => (
      <option key={model.Key} data-model={model.Value} value={model.Key}>
        {model.Value}
      </option>
    ));
  };

  render() {
    let { id, name, value, isValid, showError, disabled } = this.props;
    const selectStyles = classnames(
      'selectInput',
      !isValid && showError && 'selectInput--invalid',
      isValid && 'selectInput--valid',
      this.state.isLoading && 'selectInput--loading'
    );
    const dataAttrs = {};

    if (isValid === false) {
      dataAttrs['data-is-invalid'] = true;
    }

    if (this.state.isLoading) {
      return (
        <div className={selectStyles}>
          <LoadingSpinner size="small" />
        </div>
      );
    }

    return (
      <select
        className={selectStyles}
        id={id}
        name={name}
        value={value}
        onChange={this.handleChange}
        disabled={disabled}
        onBlur={this.props.onBlur}
        {...dataAttrs}
      >
        <option value="">{this.props.t('SelectModelInput.please_select')}</option>
        {this.renderOptions()}
      </select>
    );
  }
}

SelectModelInput.propTypes = {
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  makeId: PropTypes.string,
  vehicleClass: PropTypes.string,
  registrationDate: PropTypes.string,
  onFetchModels: PropTypes.func,
  onFetchModelsSuccess: PropTypes.func,
  name: PropTypes.string,
  showError: PropTypes.bool
};
export default withTranslation('Common')(SelectModelInput);
