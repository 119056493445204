import { takeEvery, delay } from 'redux-saga';
import { call, put, fork } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import { sendDeal, sendDealQuickQuote } from '../../api/sendDeal';
import { logError } from '../../core/helpers';

export function* watchSendDeal() {
  yield [
    fork(watchSendDealToCustomer),
    fork(watchSendDealToCustomerFromQuickQuote),
    fork(watchSendDealToExistingCustomerFromQuickQuote)
  ];
}

//----From Customer Quote----

export function* watchSendDealToCustomer() {
  yield* takeEvery(actionTypes.SEND_DEAL_TO_CUSTOMER, handleSendDeal);
}

export function* handleSendDeal(action) {
  let response;

  yield delay(2000);

  try {
    response = yield call(sendDeal, action.quoteIds, action.vehicleId, action.customerId);
  } catch (e) {
    logError(e);
    yield put({ type: actionTypes.SEND_DEAL_TO_CUSTOMER_ERROR, error: e });
    return;
  }

  yield put({ type: actionTypes.SEND_DEAL_TO_CUSTOMER_SUCCESS, response });
}

//----From Quick Quote----

export function* watchSendDealToCustomerFromQuickQuote() {
  yield* takeEvery(actionTypes.SEND_DEAL_TO_CUSTOMER_FROM_QUICK_QUOTE, handleSendDealFromQuickQuote);
}

export function* handleSendDealFromQuickQuote(action) {
  let response;

  yield delay(2000);

  try {
    response = yield call(
      sendDealQuickQuote,
      action.applicationId,
      action.customerId,
      action.vehicleId,
      action.dealershipId,
      action.vehicle,
      action.quoteIds,
      action.formData
    );
  } catch (e) {
    logError(e);
    yield put({ type: actionTypes.SEND_DEAL_TO_CUSTOMER_FROM_QUICK_QUOTE_ERROR, error: e });
    return;
  }

  yield put({ type: actionTypes.SEND_DEAL_TO_CUSTOMER_FROM_QUICK_QUOTE_SUCCESS, response });
}

//----Existing customer from quick quote----

export function* watchSendDealToExistingCustomerFromQuickQuote() {
  yield* takeEvery(actionTypes.SEND_DEAL_TO_EXISTING_CUSTOMER_FROM_QUICK_QUOTE, handleSendDeal);
}
