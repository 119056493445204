import { observable, action, computed } from 'mobx';
import Request from '../mobx-models/Request';
import * as dataLayer from '../api/closeDeal';
import _ from 'lodash';

class CloseDealStore {
  @observable closeDealData;
  @observable closeDealStatus;
  @observable isLoading;
  @observable isReopenLeadLoading = '';
  @observable vapProducts;
  @observable vapProductType;
  fetchCloseDealRequest = new Request();
  fetchCloseDealConsumer = new Request();
  sendCloseDealRequest = new Request();
  sendReopenLeadRequest = new Request();
  fetchVapProductsRequest = new Request();
  fetchVapProductTypeRequest = new Request();

  @action
  fetchCloseDealData = (consumerId, vehicleId, dealershipId) => {
    this.fetchCloseDealRequest
      .onSuccess(this.loadSuccess)
      .call(dataLayer.fetchCloseDealPreFillData, consumerId, vehicleId);

    this.fetchVapProductsRequest.onSuccess(this.loadVapSuccess).call(dataLayer.fetchVapProducts, dealershipId);
  };

  @action
  loadSuccess = (response) => {
    this.closeDealData = response;
    this.isLoading = false;
  };

  @action
  loadVapSuccess = (response) => {
    this.vapProducts = response;
  };

  @computed
  get hasCloseDealLoaded() {
    return !this.fetchCloseDealRequest.isLoading && !this.fetchVapProductsRequest.isLoading;
  }

  @action
  fetchVapProductType = (dealershipId, productTypeCode) => {
    return this.fetchVapProductTypeRequest
      .onSuccess(
        action((response) => {
          this.vapProductType = response;
        })
      )
      .call(dataLayer.fetchVapProductType, dealershipId, productTypeCode);
  };

  @action
  updateVapPrice = (productId, price) => {
    const chosenProduct = _.find(this.vapProductType, { Id: productId });
    chosenProduct.Price = price;

    return chosenProduct;
  };

  @action
  markLeadAsNotAvailable = (formData, customerType, vehicleId, consumerId) => {
    return this.sendCloseDealRequest.call(
      dataLayer.markLeadAsNotAvailable,
      vehicleId,
      formData,
      consumerId,
      customerType
    );
  };

  @action
  markLeadAsDead = (formData, customerType, vehicleId, consumerId) => {
    return this.sendCloseDealRequest.call(dataLayer.markLeadAsDead, vehicleId, formData, consumerId, customerType);
  };

  @action
  markLeadAsSoldFinance = (formData, customerType, vehicleId, consumerId) => {
    return this.sendCloseDealRequest.call(
      dataLayer.markLeadAsSoldFinance,
      vehicleId,
      formData,
      consumerId,
      customerType
    );
  };

  @action
  markLeadAsSoldCash = (formData, customerType, vehicleId, consumerId) => {
    return this.sendCloseDealRequest.call(dataLayer.markLeadAsSoldCash, vehicleId, formData, consumerId, customerType);
  };

  @action
  markLeadAsPaidOut = (formData, customerType, vehicleId, consumerId) => {
    return this.sendCloseDealRequest.call(dataLayer.markLeadAsPaidOut, vehicleId, formData, consumerId, customerType);
  };

  @action
  setCloseDealStatus = (status) => {
    this.closeDealStatus = status;
  };

  @action
  removeCloseDealStatus = () => {
    this.closeDealStatus = '';
  };

  @action
  reopenLead = (customerType, vehicleId, consumerId) => {
    return this.sendReopenLeadRequest.call(dataLayer.reopenLead, vehicleId, consumerId, customerType);
  };
}

export default CloseDealStore;
