import * as actionTypes from '../actionTypes';
import _ from 'lodash';

const defaultState = {
  items: [],
  isLoading: false,
  hasLoaded: false,
  hasInitiallyLoaded: false,
  savingState: '',
  hasError: false
};

function parseProducts(products) {
  _.forEach(products, (item) => {
    item.isAprProduct = item.RateType === 'APR';
  });

  return products;
}

export default function productsReducer(state = defaultState, action) {
  let newState;

  switch (action.type) {
    case actionTypes.FETCH_DEALERSHIP_PRODUCTS:
      return Object.assign({}, state, { isLoading: true, hasError: false });

    case actionTypes.FETCH_DEALERSHIP_PRODUCTS_SUCCESS:
      return Object.assign({}, state, {
        items: parseProducts(action.response),
        isLoading: false,
        hasInitiallyLoaded: true,
        hasError: false
      });

    case actionTypes.FETCH_DEALERSHIP_PRODUCTS_ERROR:
      return Object.assign({}, state, { isLoading: false, hasError: true });

    case actionTypes.SAVE_ALL_PRODUCTS:
      return Object.assign({}, state, { savingState: 'loading' });

    case actionTypes.SAVE_ALL_PRODUCTS_SUCCESS:
      newState = _.cloneDeep(state);

      newState.items = _.map(newState.items, (item) => {
        let changedItemSettings = _.find(action.productSettingsCollection, {
          ProductId: item.Settings.ProductId
        });

        if (typeof changedItemSettings !== 'undefined') {
          item.Settings = changedItemSettings;
        }

        return item;
      });

      return Object.assign({}, newState, { savingState: 'done' });

    case actionTypes.SAVE_ALL_PRODUCTS_ERROR:
      return Object.assign({}, state, { savingState: 'error' });

    default:
      return state;
  }
}
