import React from 'react';

import CommonPagination from '~Common/Pagination';

import { usePagination, useSearchResults } from '../../hooks';

import './pagination.scss';

const Pagination = ({ onChange }) => {
  const { refine } = usePagination();
  const searchResults = useSearchResults();

  const handleChange = (page) => {
    refine(page);
    onChange();
  };
  return (
    <div className="visLookup__pagination">
      <CommonPagination
        onChange={handleChange}
        pageSize={20}
        totalItems={searchResults.numberOfRecords}
        currentPage={searchResults.currentPage + 1}
      />
    </div>
  );
};

export default Pagination;
