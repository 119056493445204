import React from 'react';
import PropTypes from 'prop-types';
import './alert.scss';

const Alert = ({ children }) => {
  return <div className="alert">{children}</div>;
};

Alert.propTypes = {
  children: PropTypes.node
};

export default Alert;
