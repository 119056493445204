import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Icon from '../Common/Icon/Icon';
import { getLenderStatusStyle } from './utils/helpers';
import { LenderStatusNameType } from './utils/helpers';
import './lenderStatusLarge.scss';
import i18next from '~/__mocks__/i18next';

export interface LenderStatusLargeProps {
  status: LenderStatusNameType;
  t: typeof i18next.t;
}

const LenderStatusLarge = ({ status, t }: LenderStatusLargeProps) => {
  const { lenderStatusColor, iconName, customClass = null } = getLenderStatusStyle(status);
  const renderIcon = () => {
    if (iconName) {
      return <Icon name={iconName as string} />;
    }
    return null;
  };

  return (
    <div className="lenderStatusLarge" data-th="lenderStatusLarge">
      {customClass && <div className={customClass} data-th={customClass}></div>}
      <div key="statusIcon" className={`lenderStatusLarge__applicationStatus lenderStatusLarge__${lenderStatusColor}`}>
        <div className="lenderStatusLarge__listIcon">{renderIcon()}</div>
        <div className="lenderStatusLarge__iconText" key="iconText">
          <span className="lenderStatusLarge__iconTop">{t('LenderStatusLarge.status_label')}</span>
          <span
            className={`lenderStatusLarge__iconStatus lenderStatusLarge__${lenderStatusColor}`}
            key="status"
            data-th="LenderStatusText"
          >
            {t(`LenderStatus.${status}`)}
          </span>
        </div>
      </div>
    </div>
  );
};

LenderStatusLarge.propTypes = {
  status: PropTypes.string,
  options: PropTypes.object,
  to: PropTypes.string,
  query: PropTypes.object
};

export default withTranslation('ApplicationStatus')(LenderStatusLarge);

