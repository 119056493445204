import React, { useEffect } from 'react';
import { inject } from 'mobx-react';

import { StockListFixIssuesContainer } from './components';
import VisLookUp from '~Common/VisLookup/context/VisLookupContext';
import { useParams } from 'react-router-dom';

const StockFixWrapper = (props) => {
  const params = useParams();
  useEffect(() => {
    const { getVehicleData, clearStore } = props.appStore.stockEditStore;
    getVehicleData(params.dealershipId, params.vehicleId);
    return () => {
      clearStore();
    };
  }, [props.appStore.stockEditStore, params.dealershipId, params.vehicleId]);

  return (
    <VisLookUp dealershipId={params.dealershipId}>
      <StockListFixIssuesContainer>{props.children}</StockListFixIssuesContainer>
    </VisLookUp>
  );
};

export default inject('appStore')(StockFixWrapper);
