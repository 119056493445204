import { observable, action } from 'mobx';
import platformApiUtils from '@ivendi/platform-api-utils';
import Customer from '../mobx-models/Customer';
import { submitChangeApplicantDecision } from '../api/application';

class CustomerStore {
  @observable customer;
  @observable isLoadingCustomer;
  @observable hasError;
  @observable isLoadingDevStatus;

  @action
  fetchCustomerData = (consumerId) => {
    this.isLoadingCustomer = true;
    return platformApiUtils
      .get('v1/customer/' + consumerId)
      .then(this.onFetchCustomerSuccess)
      .catch(this.onFetchCustomerError);
  };

  @action
  onFetchCustomerSuccess = (response) => {
    this.customer = new Customer(response);
    this.customer.updateFromJSON(response);
    this.isLoadingCustomer = false;
    return this.customer;
  };

  @action
  onFetchCustomerError = () => {
    this.hasError = true;
    this.isLoadingCustomer = false;
  };

  @action
  sendChangeApplicantDecision = (newDecision, consumerId, applicantId, agreementNumber) => {
    this.isLoadingDevStatus = true;
    submitChangeApplicantDecision(newDecision, consumerId, applicantId, agreementNumber)
      .then(this.onSuccessChangeApplicantDecision)
      .catch(this.onErrorChangeApplicantDecision);
  };

  @action
  onSuccessChangeApplicantDecision = (response) => {
    this.isLoadingDevStatus = false;
  };

  @action
  onErrorChangeApplicantDecision = () => {
    this.isLoadingDevStatus = false;
    this.hasError = true;
  };
}

export default CustomerStore;
