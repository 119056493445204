import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './customerInfo.scss';
import CircleConsumerImage from '../../Customer/components/ConsumerRecordItems/CircleConsumerImage';
import ConsumerName from '../../Common/ConsumerName';

const CustomerInfo = ({ consumer }) => {
  const { t } = useTranslation('DealershipDistance');
  return (
    <div className="dealershipDistanceCustomerInfo">
      <div className="dealershipDistanceCustomerInfo__inner">
        <div className="dealershipDistanceCustomerInfo__image">
          <CircleConsumerImage customerType={consumer.CustomerType} isSmall={true} />
        </div>
        <div>
          <div className="dealershipDistanceCustomerInfo__name">
            {consumer.CustomerType === 'Consumer' ? (
              <ConsumerName
                name={`${consumer.Firstname} ${consumer.Middlename || consumer.Middlenames} ${consumer.Surname}`}
              />
            ) : (
              <div>{consumer.TradingName}</div>
            )}
          </div>
          <div>{consumer.Mobile ? consumer.Mobile : consumer.Landline}</div>
        </div>
      </div>
      <div className="dealershipDistanceCustomerInfo__bottom">{t('CustomerInfo.contact_information')}</div>
    </div>
  );
};

CustomerInfo.propTypes = {
  consumer: PropTypes.object
};
export default CustomerInfo;
