import { FinanceNavigatorForm } from '../types/financeNavigatorForm';
import VehiclePanel from '~/components/Reporting/PartialSummarySections/VehiclePanel';
import MiniPanel from '~/components/Common/MiniPanel';
import PersonalDetailsPanel from '~/components/Reporting/PartialSummarySections/PersonalDetailsPanel';
import MiniPanelHeader from '~/components/Common/MiniPanelHeader';
import AddressHistoryPanel from '~/components/Reporting/PartialSummarySections/AddressHistoryPanel';
import AffordabilityPanel from '~/components/Reporting/PartialSummarySections/AffordabilityPanel';
import EmploymentHistoryPanel from '~/components/CfcCheckDetails/EmploymentHistoryPanel';
import FormFooter from '~/components/Common/Form/FormFooter';
import { useTranslation } from 'react-i18next';
import { applicationValidator, ResolverResult, rhfValidationResolver } from './validation';
import { useEffect, useState } from 'react';
import { CustomerVehicleOfInterest, Vehicle } from '~/types/vehicle';
import { FinanceNavigatorTitle } from '../components/FinanceNavigatorTitle';

export interface FinanceNavigatorSummaryProps {
  formData: FinanceNavigatorForm;
  vehicle: CustomerVehicleOfInterest | Vehicle;
  onCancel: () => void;
  onSubmit: (formData: FinanceNavigatorForm) => void;
}

export const FinanceNavigatorSummary = ({ formData, vehicle, onCancel, onSubmit }: FinanceNavigatorSummaryProps) => {
  const { t } = useTranslation('FinanceNavigator');
  const [validationResult, setValidationResult] = useState<ResolverResult>();
  useEffect(() => {
    const result = rhfValidationResolver(formData, applicationValidator);
    setValidationResult(result);
  }, [formData]);
  const handleSubmit = () => {
    onSubmit(formData);
  };
  return (
    <div data-th="financeNavigatorSummary">
      <FinanceNavigatorTitle text={t('Summary.summary')} />
      <MiniPanel>
        <MiniPanelHeader title={t('Summary.vehicle_details')} />
        <VehiclePanel vehicle={vehicle} />
      </MiniPanel>
      <MiniPanel>
        <MiniPanelHeader title={t('Summary.personal_details')} />
        <PersonalDetailsPanel
          details={formData.PersonalDetails}
          applicationFields={{
            Title: true,
            FirstName: true,
            MiddleNames: true,
            LastName: true,
            Email: true,
            MobileTelephone: true,
            DateOfBirth: true,
            MaritalStatus: true,
            NoOfDependants: true
          }}
        />
      </MiniPanel>
      <MiniPanel>
        <MiniPanelHeader title={t('Summary.address_history')} />
        <AddressHistoryPanel addresses={formData.AddressHistory.Items} />
      </MiniPanel>
      <MiniPanel>
        <MiniPanelHeader title={t('Summary.employment_history')} />
        <EmploymentHistoryPanel employment={formData.EmploymentHistory.Items} />
      </MiniPanel>
      <MiniPanel>
        <MiniPanelHeader title={t('Summary.affordability_details')} />
        <AffordabilityPanel
          details={formData.AffordabilityDetails}
          applicationFields={{
            GrossAnnual: true,
            HasOtherHouseholdIncome: true,
            OtherHouseholdIncome: true,
            ForeseeFutureDownturnInFinances: true,
            IsReplacementLoan: true,
            DetailsOfReplacementLoan: true,
            NetMonthly: true,
            MortgageOrRental: true,
            DownturnReason: true,
            ForeseenYearlyIncome: true
          }}
        />
      </MiniPanel>
      <FormFooter
        submitLabel={t('Summary.submit')}
        submitDisabled={!validationResult?.isValid}
        hideSubmit={false}
        cancelLabel={t('FinanceNavigator.back')}
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </div>
  );
};
