import React from 'react';
import PropTypes from 'prop-types';
import './linkedUsersModalItem.scss';
import ConsumerName from '../../Common/ConsumerName';
import { getOptionKey } from '~/core/helpers';

const LinkedUsersModalItem = ({ alias }) => {
  const genderNeutralOptions = new Set(['NotSpecified', 'PreferNotToSay', 'NonBinary']);

  const titleOptionText = alias.Title && !genderNeutralOptions.has(alias.Title) ? getOptionKey(alias.Title).text : '';

  return (
    <div className="linkedUsersModalItem">
      {titleOptionText && `${titleOptionText}. `}
      <ConsumerName name={`${alias.Firstname} ${alias.Surname}`} />
    </div>
  );
};

LinkedUsersModalItem.propTypes = {
  alias: PropTypes.object
};

LinkedUsersModalItem.defaultProps = {
  alias: {}
};

export default LinkedUsersModalItem;
