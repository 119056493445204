import produce from 'immer';
import defaultRules, { LenderCorporateConfig } from './default';

export default produce(defaultRules, (draft: LenderCorporateConfig) => {
  delete draft.applicationStatuses.NotProceeding;
  delete draft.applicationStatuses.Other;
  delete draft.applicationStatuses.ProblemPayout;
  delete draft.applicationStatuses.Prospect;
  delete draft.applicationStatuses.SentForPayout;
  delete draft.applicationStatuses.Unknown;
  draft.applicationStatuses.Accepted!.isEditable = false;
  draft.applicationStatuses.ConditionalAccept!.isEditable = false;
  draft.applicationStatuses.WaitingForFurtherInformation!.isEditable = false;
  draft.applicationStatuses.WaitingForFurtherInformation!.isReproposable = true;
  draft.applicationStatuses.WaitingForFurtherInformation!.isComplete = true;
  draft.applicationStatuses.Error!.isEditable = false;
  draft.applicationStatuses.Error!.isReproposable = false;
  draft.applicationStatuses.NoDecisionYet!.isEditable = false;
  draft.applicationStatuses.NotTakenUp!.isEditable = false;
  draft.applicationStatuses.Pending!.isEditable = false;
  draft.applicationStatuses.Pending!.isPending = true;
  draft.applicationStatuses.Referred!.isEditable = false;
  draft.applicationStatuses.Referred!.isReproposable = false;
  draft.applicationStatuses.Rejected!.isEditable = false;
  draft.applicationStatuses.Submitted!.isEditable = false;
  draft.applicationStatuses.Submitted!.isSubmitting = true;

  draft.applicationFields.AffordabilityDetails!.GrossAnnual = true;
  delete draft.applicationFields.BusinessDetails;

  delete draft.validationRules.BusinessDetails;
  delete draft.validationRules.OrganisationContacts;
  draft.validationRules.AffordabilityDetails!.Income!.GrossAnnual = 'currency';
});
