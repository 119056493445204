import React from 'react';
import PropTypes from 'prop-types';
import './summaryTable.scss';

const TableRow = ({ children }) => <tr className="summaryTable__tr">{children}</tr>;

TableRow.propTypes = {
  children: PropTypes.node
};

export default TableRow;
