import platformApiUtils from '@ivendi/platform-api-utils';
import { schemaMap } from '../core/schema';
import { normalize } from 'normalizr';
import { parseConsumer } from '../core/apiDataParsers/consumerDataParser';
import consumerSchema from '../core/schemas/consumerSchema';
import { customerNormalizrSchema } from '../core/normalizrSchemas';
import * as debug from '../debug';
import uuid from 'uuid';

export const fetchCustomer = (customerId, dealershipId) => {
  return new Promise(function(resolve, reject) {
    platformApiUtils
      .get('v1/customer/' + customerId)
      .then((response) => {
        resolve(normalize(parseConsumer(schemaMap(response, consumerSchema)), customerNormalizrSchema));
      })
      .catch((e) => {
        debug.error('fetchCustomer');
        reject(e);
      });
  });
};

export const deleteCustomer = (customerId, customerType) => {
  return platformApiUtils.del('v1/customer/' + customerId + '?customertype=' + customerType);
};

export const addCustomer = (customer, dealershipId, assignToSelf) => {
  const customerId = uuid.v4();
  return platformApiUtils
    .post('v1/customer/' + customerId, {
      CustomerType: customer.customerType,
      Id: customerId,
      DealershipId: dealershipId,
      Email: customer.email,
      Title: customer.title,
      Firstname: customer.firstName,
      Middlenames: customer.middleName,
      Surname: customer.surname,
      Landline: customer.landline,
      Mobile: customer.mobile,
      BuildingName: customer.buildingName,
      BuildingNumber: customer.buildingNumber,
      District: customer.district,
      PostTown: customer.postTown,
      Postcode: customer.postCode,
      Street: customer.street,
      SubBuilding: customer.subBuilding,
      Position: customer.position,
      FaxNumber: customer.faxNumber,
      TradingName: customer.tradingName,
      CompanyName: customer.company,
      AssignToSelf: assignToSelf
    })
    .then(() => customerId);
};

export const updateCustomer = (customer, customerId) => {
  return platformApiUtils.put('v1/customer/' + customerId, {
    CustomerType: customer.customerType,
    Id: customerId,
    Email: customer.email,
    Title: customer.title,
    Firstname: customer.firstName,
    Middlenames: customer.middleName,
    Surname: customer.surname,
    Landline: customer.landline,
    Mobile: customer.mobile,
    Position: customer.position,
    FaxNumber: customer.faxNumber,
    TradingName: customer.tradingName,
    BuildingName: customer.buildingName,
    BuildingNumber: customer.buildingNumber,
    CompanyName: customer.company,
    District: customer.district,
    PostTown: customer.postTown,
    Postcode: customer.postCode,
    Street: customer.street,
    SubBuilding: customer.subBuilding
  });
};

export const fetchCustomerList = (dealershipId, searchParams, currentUserId) => {
  const { filter, ...remainingParams } = searchParams;

  let assignedTo;
  let assigned;

  if (filter === 'myAssigned') {
    assignedTo = currentUserId;
    assigned = 'true';
  } else if (filter) {
    assigned = (filter === 'assigned').toString();
  }

  return platformApiUtils.get('v1/Customers/' + dealershipId, {
    dealershipId,
    ...remainingParams,
    assignedTo,
    assigned
  });
};

export const getCustomerDeals = (customerId, dealershipId) => {
  return platformApiUtils.get(`vnext/Customer/${customerId}/deals?dealershipId=${dealershipId}`);
};
