import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './assignLeadInitials.scss';

const AssignLeadButtonInitials = ({
  initials,
  avatarColor,
  additionalAssigneesCount,
  onClick,
  initialAssignees,
  size
}) => {
  const { t } = useTranslation('AssignLead');

  const onClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(initialAssignees);
  };

  const initialsClassnames = classnames(
    'assignLeadInitials__initials',
    `assignLeadInitials__initials--color${(typeof avatarColor === 'number' && avatarColor.toString()) || '-1'}`,
    `assignLeadInitials__initials--${size}`
  );
  return (
    <button
      type="button"
      onClick={onClickHandler}
      title={initials}
      className="assignLeadInitials__container"
      data-th="AssignLeadButtonInitials"
    >
      <span className="assignLeadInitials__assignedTo">{t('AssignLeadButtonInitials.assigned_to')}</span>
      <span key="initials" className={initialsClassnames}>
        {initials}
      </span>
      {additionalAssigneesCount > 0 && <span className="assignLeadInitials__count">+{additionalAssigneesCount}</span>}
    </button>
  );
};

AssignLeadButtonInitials.defaultProps = {
  size: 'normal'
};
AssignLeadButtonInitials.propTypes = {
  initials: PropTypes.string,
  onClick: PropTypes.func,
  initialAssignees: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  size: PropTypes.string,
  avatarColor: PropTypes.number,
  additionalAssigneesCount: PropTypes.number
};
export default AssignLeadButtonInitials;
