import React from 'react';
import PropTypes from 'prop-types';
import './quoteTabItem.scss';
import Icon from '../../Common/Icon/Icon';
import i18n from '../../../i18n';
import cn from 'classnames';

const QuoteTabItem = (props) => {
  return (
    <div
      className={cn('quoteTabItem', {
        'quoteTabItem--fluid': i18n.language !== 'en'
      })}
      key="outer"
      data-th={props.dataThook}
    >
      <div className="quoteTabItem__iconOuter">
        <div className="quoteTabItem__icon" key="imageContainer">
          <Icon name={props.iconName} />
        </div>
      </div>
      <div className="quoteTabItem__textOuter">
        <div className="quoteTabItem__text" key="text">
          {props.text}
          <span className="quoteTabItem__count" key="count">
            &nbsp;({props.count})
          </span>
        </div>
      </div>
    </div>
  );
};

QuoteTabItem.propTypes = {
  text: PropTypes.string,
  count: PropTypes.number,
  dataThook: PropTypes.string
};

export default QuoteTabItem;
