import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './quoteInfo.scss';
import QuoteSmallPrint from './QuoteSmallPrint';
import FunderLogo from '../../Common/FunderLogo';
import ProductName from '../../QuotesList/components/ProductName';
import MoneyFormat from '../../Common/MoneyFormat';
import QuoteInfoLabel from './QuoteInfoLabel';
import QuoteInfoValue from './QuoteInfoValue';

const QuoteInfo = ({ quote }) => {
  const { t } = useTranslation('Quoting');
  return (
    <div className="quoteInfo">
      <div className="quoteInfo__top">
        <div className="quoteInfo__cell quoteInfo__funderLogoCell">
          <div className="quoteInfo__funderLogo">
            <FunderLogo funder={quote.FunderCode} />
          </div>
        </div>
        <div className="quoteInfo__fields">
          <div className="quoteInfo__labels">
            <div className="quoteInfo__cell">
              <QuoteInfoLabel>{t('QuoteInfo.product')}</QuoteInfoLabel>
            </div>
            <div className="quoteInfo__cell">
              <QuoteInfoLabel>{t('QuoteInfo.apr')}</QuoteInfoLabel>
            </div>
            <div className="quoteInfo__cell">
              <QuoteInfoLabel>{t('QuoteInfo.total_amount_payable')}</QuoteInfoLabel>
            </div>
            <div className="quoteInfo__cell">
              <QuoteInfoLabel>{t('QuoteInfo.agreement_term')}</QuoteInfoLabel>
            </div>
            <div className="quoteInfo__cell">
              <QuoteInfoLabel>{t('QuoteInfo.monthly_payments')}</QuoteInfoLabel>
            </div>
          </div>
          <div className="quoteInfo__values">
            <div className="quoteInfo__cell">
              <QuoteInfoValue>
                <ProductName productName={quote.ProductName} />
              </QuoteInfoValue>
            </div>
            <div className="quoteInfo__cell">
              <QuoteInfoValue>{quote.RepresentativeApr + '%'}</QuoteInfoValue>
            </div>
            <div className="quoteInfo__cell">
              <QuoteInfoValue>
                <MoneyFormat value={quote.TotalAmountPayable} />
              </QuoteInfoValue>
            </div>
            <div className="quoteInfo__cell">
              <QuoteInfoValue>{quote.Term + ' ' + t('QuoteInfo.months')}</QuoteInfoValue>
            </div>
            <div className="quoteInfo__cell">
              <QuoteInfoValue>
                <MoneyFormat value={quote.FollowingPayments} />
              </QuoteInfoValue>
            </div>
          </div>
        </div>
      </div>

      <div className="quoteInfo__bottom">
        <QuoteSmallPrint quote={quote} />
      </div>
    </div>
  );
};

QuoteInfo.propTypes = {
  quote: PropTypes.object
};
export default QuoteInfo;
