import * as actionTypes from '../actionTypes';
import _ from 'lodash';
import moment from 'moment';

const defaultState = {
  isLoading: false,
  hasLoaded: false,
  LenderUpdates: {
    Data: [],
    Facets: {
      Unfiltered: []
    }
  },
  Applications: {
    Data: []
  },
  CfcProspects: {
    Data: []
  },
  LenderStatuses: {},
  items: {},
  IsAssigned: false,
  options: {
    activeFilter: 'all',
    CfcProspects: {
      PageSize: 5,
      activeFilter: 'all'
    },
    Applications: {
      PageSize: 5,
      activeFilter: 'all'
    },
    LenderUpdates: {
      PageSize: 8,
      activeFilter: 'all'
    }
  },
  Tiles: []
};

export default function dashboardReducer(state = defaultState, action) {
  let newState = state;

  if (action.response && action.response.entities && action.response.entities.dashboardItem) {
    newState = _.cloneDeep(state);
    _.merge(newState.items, action.response.entities.dashboardItem);
  }

  switch (action.type) {
    case actionTypes.FETCH_DASHBOARD_DATA:
      return Object.assign({}, newState, { isLoading: true });

    case actionTypes.FETCH_DASHBOARD_DATA_SUCCESS:
      const { entities, ...response } = action.response;
      return Object.assign(
        {},
        newState,
        {
          isLoading: false,
          hasLoaded: true
        },
        {
          ...response
        }
      );

    case actionTypes.FETCH_DASHBOARD_DATA_ERROR:
      return Object.assign({}, newState, { isLoading: false });

    case actionTypes.FETCH_DASHBOARD_SECTION:
      return Object.assign({}, newState, { isLoading: true });

    case actionTypes.FETCH_DASHBOARD_SECTION_SUCCESS:
      return Object.assign({}, newState, {
        [action.section]: action.response ? action.response : [],
        isLoading: false
      });

    case actionTypes.FETCH_DASHBOARD_SECTION_ERROR:
      return Object.assign({}, newState, { error: action.payload, isLoading: false });

    case actionTypes.FILTER_DASHBOARD_SECTION:
      newState = _.cloneDeep(newState);

      newState.options[action.section] = Object.assign({}, newState.options[action.section], action.options);
      return newState;

    case actionTypes.FILTER_DASHBOARD_LEADS:
      newState = _.cloneDeep(state);

      newState.options = Object.assign({}, newState.options, {
        activeFilter: action.leadState
      });

      const sections = ['Applications', 'CfcProspects', 'LenderUpdates'];
      _.forEach(sections, (section) => {
        newState.options[section].activeFilter = action.leadState;
      });
      return newState;

    case actionTypes.RESET_DASHBOARD:
      return defaultState;

    case actionTypes.DASHBOARD_UPDATE_ASSIGNED_TO:
      newState = _.cloneDeep(newState);

      let dashboardItem = _.find(
        newState.items,
        (item) => (item.DealershipConsumer.Id || item.CorporateCustomer.Id) === action.customerId
      );

      if (dashboardItem) {
        //Unassigned count is last 30 days,
        //MyAssigned and AllAssigned are all time
        //Update AllAssigned only if LastModified is more than 30 days ago

        if (action.leadIsNowAssigned) {
          if (moment(dashboardItem.LastModified).isAfter(moment().subtract(30, 'days'))) {
            newState.Tiles[0].Count--;
          }
        } else if (action.leadIsNowUnassigned) {
          if (newState.Tiles[0].Type === 'unassigned') {
            if (moment(dashboardItem.LastModified).isAfter(moment().subtract(30, 'days'))) {
              newState.Tiles[0].Count++;
            }
          } else {
            newState.Tiles[0].Count--;
          }
        }
      }

      _.forEach(newState.items, (dashItem) => {
        if ((dashItem.DealershipConsumer.Id || dashItem.CorporateCustomer.Id) === action.customerId) {
          dashItem.AssignedTo = action.newAssignees;
        }
      });

      return newState;

    default:
      return newState;
  }
}
