import parse from 'html-react-parser';
import AlertCard from '../../../components/Common/AlertCard/AlertCard';
import RadioButtonGroup from '../../../components/Common/Form/RadioButtonGroup';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './question.module.scss';

export type QuestionProps = {
  questionTitle?: string;
  questionText: string;
  validationType?: 'alert' | 'warning' | 'success' | 'info';
  validationTitle?: string;
  validationMsg?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  index?: number;
};

export const Question = ({
  questionTitle,
  questionText,
  validationType,
  validationTitle,
  validationMsg,
  onChange,
  index
}: QuestionProps) => {
  const { t } = useTranslation('Quoting');
  const [questionValue, setQuestionValue] = useState<boolean | null>(null);
  const hasValidation = validationMsg !== null && validationMsg !== undefined;

  return (
    <>
      {questionTitle && <h3 className={styles.questionTitle}>{questionTitle}</h3>}
      {index !== undefined && !questionTitle && (
        <h3 className={styles.questionTitle}>{`${t('Question.question')} ${index + 1}`}</h3>
      )}

      {hasValidation && !questionValue && questionValue !== null && (
        <div className={styles.alertCardWrapper}>
          <AlertCard iconName={validationType || 'alert'} title={validationTitle} paragraph={validationMsg} />
        </div>
      )}

      <div className={styles.questionContainer}>
        <div>
          <p>{parse(questionText)}</p>
        </div>
        <div>
          <RadioButtonGroup
            options={[
              { id: `${questionText}-1`, label: t('Question.yes'), value: 'true' },
              { id: `${questionText}-2`, label: t('Question.no'), value: 'false' }
            ]}
            name={questionText}
            onChange={(event) => {
              setQuestionValue(event.target.value === 'true');
              onChange(event);
            }}
            checkedValue={`${questionValue}`}
            isGroupDisabled={false}
            variant="inline"
          />
        </div>
      </div>
    </>
  );
};
