import React, { useState, useEffect } from 'react';
import { useDebounceCallback } from '@react-hook/debounce';
import { useTranslation } from 'react-i18next';
import SearchBar from '~/components/Search/SearchBar';

import { useSearchQuery } from '../../hooks';

import './searchBox.scss';

const SearchBox = React.forwardRef((_, ref) => {
  const { refine, query } = useSearchQuery();
  const [value, setValue] = useState('');
  const { t } = useTranslation('Stock');

  const debouncedQueryChange = useDebounceCallback(() => {
    if (value.trim() === '') {
      return;
    }
    refine(value);
  }, 400);

  const handleQueryChange = (_, value) => {
    setValue(value);
    debouncedQueryChange();
  };

  useEffect(() => {
    if (query.trim() !== '') {
      setValue(query);
    }
  }, [query]);
  return (
    <div ref={ref} id="searchBarContainer" className="searchBox__searchBar" data-th="SearchBox">
      <p className="searchBox__SearchBarParagraph">{t('SearchBox.search_and_confirm_vehicle')}</p>
      <SearchBar
        id="visSearch"
        value={value}
        onChange={handleQueryChange}
        placeholder={t('SearchBox.search_make_model_derivative_etc')}
      />
    </div>
  );
});
export default SearchBox;
