export const INITIALIZE_APP = 'INITIALIZE_APP';
export const END_SESSION = 'END_SESSION';

/* Session Actions */

export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';

export const WHO_AM_I = 'WHO_AM_I';
export const WHO_AM_I_SUCCESS = 'WHO_AM_I_SUCCESS';
export const WHO_AM_I_ERROR = 'WHO_AM_I_ERROR';

export const UPDATE_WHO_AM_I = 'UPDATE_WHO_AM_I';
export const UPDATE_WHO_AM_I_SUCCESS = 'UPDATE_WHO_AM_I_SUCCESS';
export const UPDATE_WHO_AM_I_ERROR = 'UPDATE_WHO_AM_I_ERROR';

export const SIGN_OUT = 'SIGN_OUT';

export const CHANGE_DEALERSHIP = 'CHANGE_DEALERSHIP';

/* App Setup Actions */

export const FETCH_DEALERSHIP_INFO = 'FETCH_DEALERSHIP_INFO';
export const FETCH_DEALERSHIP_INFO_SUCCESS = 'FETCH_DEALERSHIP_INFO_SUCCESS';
export const FETCH_DEALERSHIP_INFO_ERROR = 'FETCH_DEALERSHIP_INFO_ERROR';

export const FETCH_FUNDERS_INFO = 'FETCH_FUNDERS_INFO';
export const FETCH_FUNDERS_INFO_SUCCESS = 'FETCH_FUNDERS_INFO_SUCCESS';
export const FETCH_FUNDERS_INFO_ERROR = 'FETCH_FUNDERS_INFO_ERROR';

export const FETCH_OPTIONS = 'FETCH_OPTIONS';
export const FETCH_OPTIONS_SUCCESS = 'FETCH_OPTIONS_SUCCESS';
export const FETCH_OPTIONS_ERROR = 'FETCH_OPTIONS_ERROR';
export const RECREATE_OPTIONS = 'RECREATE_OPTIONS';
export const RECREATE_CLAIM_BASED_OPTIONS = 'RECREATE_CLAIM_BASED_OPTIONS';

/* Dealership Settings */

export const FETCH_DEALERSHIP_SETTINGS = 'FETCH_DEALERSHIP_SETTINGS';
export const FETCH_DEALERSHIP_SETTINGS_SUCCESS = 'FETCH_DEALERSHIP_SETTINGS_SUCCESS';
export const FETCH_DEALERSHIP_SETTINGS_ERROR = 'FETCH_DEALERSHIP_SETTINGS_ERROR';

export const CLEANUP_DEALERSHIP_SETTINGS = 'CLEANUP_DEALERSHIP_SETTINGS';

export const SAVE_DEALERSHIP_SETTINGS = 'SAVE_DEALERSHIP_SETTINGS';
export const SAVE_DEALERSHIP_SETTINGS_SUCCESS = 'SAVE_DEALERSHIP_SETTINGS_SUCCESS';
export const SAVE_DEALERSHIP_SETTINGS_ERROR = 'SAVE_DEALERSHIP_SETTINGS_ERROR';

/* Consumer Actions */

export const INIT_CUSTOMER_PAGE = 'INIT_CUSTOMER_PAGE';

export const FETCH_CONSUMER = 'FETCH_CONSUMER';
export const FETCH_CONSUMER_SUCCESS = 'FETCH_CONSUMER_SUCCESS';

export const SELECT_CUSTOMER_VEHICLE = 'SELECT_CUSTOMER_VEHICLE';
export const SELECT_CUSTOMER_FINANCE_TAB = 'SELECT_CUSTOMER_FINANCE_TAB';
export const SELECT_CUSTOMER_CFC_CHECK = 'SELECT_CUSTOMER_CFC_CHECK';
export const SELECT_CUSTOMER_SENT_DEAL = 'SELECT_CUSTOMER_SENT_DEAL';

export const CLEAR_CUSTOMER_ENTITY_CACHE = 'CLEAR_CUSTOMER_ENTITY_CACHE';

/* Vehicle Lookup */

export const INIT_VEHICLE_LOOKUP = 'INIT_VEHICLE_LOOKUP';
export const UPDATE_LOOKUP_FIELD = 'UPDATE_LOOKUP_FIELD';

export const LOOKUP_VRM = 'LOOKUP_VRM';
export const LOOKUP_VRM_SUCCESS = 'LOOKUP_VRM_SUCCESS';
export const LOOKUP_VRM_ERROR = 'LOOKUP_VRM_ERROR';
export const LOOKUP_VRM_NO_CAP_ID = 'LOOKUP_VRM_NO_CAP_ID';

export const LOOKUP_VEHICLE_MAKES = 'LOOKUP_VEHICLE_MAKES';
export const LOOKUP_VEHICLE_MAKES_SUCCESS = 'LOOKUP_VEHICLE_MAKES_SUCCESS';
export const LOOKUP_VEHICLE_MAKES_ERROR = 'LOOKUP_VEHICLE_MAKES_ERROR';

export const LOOKUP_VEHICLE_MODELS = 'LOOKUP_VEHICLE_MODELS';
export const LOOKUP_VEHICLE_MODELS_SUCCESS = 'LOOKUP_VEHICLE_MODELS_SUCCESS';
export const LOOKUP_VEHICLE_MODELS_ERROR = 'LOOKUP_VEHICLE_MODELS_ERROR';

export const LOOKUP_VEHICLE_DERIVATIVES = 'LOOKUP_VEHICLE_DERIVATIVES';
export const LOOKUP_VEHICLE_DERIVATIVES_SUCCESS = 'LOOKUP_VEHICLE_DERIVATIVES_SUCCESS';
export const LOOKUP_VEHICLE_DERIVATIVES_ERROR = 'LOOKUP_VEHICLE_DERIVATIVES_ERROR';

/* Add Vehicle */

export const ADD_CONSUMER_VEHICLE = 'ADD_CONSUMER_VEHICLE';
export const ADD_CONSUMER_VEHICLE_SUCCESS = 'ADD_CONSUMER_VEHICLE_SUCCESS';
export const ADD_CONSUMER_VEHICLE_ERROR = 'ADD_CONSUMER_VEHICLE_ERROR';
export const ADD_VEHICLE_TO_CUSTOMER = 'ADD_VEHICLE_TO_CUSTOMER';
export const ADD_CONSUMER_VEHICLE_ERROR_CLEAR = 'ADD_VEHICLE_ERROR_CLEAR';

/* Quotes */

export const START_QUOTING = 'START_QUOTING';
export const START_REQUOTING = 'START_REQUOTING';
export const CHANGE_QUOTE_COMMISSIONS = 'CHANGE_QUOTE_COMMISSIONS';

export const CHANGE_QUOTE_VIEW = 'CHANGE_QUOTE_VIEW';

export const CHANGE_QUOTING_VEHICLE = 'CHANGE_QUOTING_VEHICLE';
export const CHANGE_COSTS = 'CHANGE_COSTS';
export const CHANGE_COSTS_SUCCESS = 'CHANGE_COSTS_SUCCESS';

export const FETCH_QUOTES = 'FETCH_QUOTES';
export const FETCH_QUOTES_SUCCESS = 'FETCH_QUOTES_SUCCESS';
export const FETCH_QUOTES_ERROR = 'FETCH_QUOTES_ERROR';

export const COMPARE_QUOTE_ADD = 'COMPARE_QUOTE_ADD';
export const COMPARE_QUOTE_REMOVE = 'COMPARE_QUOTE_REMOVE';

export const COMPARE_QUOTES_CLEANUP = 'COMPARE_QUOTES_CLEANUP';

export const RESET_COMPARE_QUOTES = 'RESET_COMPARE_QUOTES';

export const GET_SHOWROOM_CFC_PREFILL = 'GET_SHOWROOM_CFC_PREFILL';
export const GET_SHOWROOM_CFC_PREFILL_SUCCESS = 'GET_SHOWROOM_CFC_PREFILL_SUCCESS';
export const GET_SHOWROOM_CFC_PREFILL_ERROR = 'GET_SHOWROOM_CFC_PREFILL_ERROR';

export const UPDATE_ELIGIBILITY_FORM_DATA = 'UPDATE_ELIGIBILITY_FORM_DATA';

export const CHECK_ELIGIBILITY = 'CHECK_ELIGIBILITY';
export const CHECK_ELIGIBILITY_SUCCESS = 'CHECK_ELIGIBILITY_SUCCESS';
export const CHECK_ELIGIBILITY_ERROR = 'CHECK_ELIGIBILITY_ERROR';
export const END_QUOTING = 'END_QUOTING';

export const START_FINANCE_NAVIGATOR = 'START_FINANCE_NAVIGATOR';
export const CANCEL_FINANCE_NAVIGATOR = 'CANCEL_FINANCE_NAVIGATOR';

/* Application Actions */

export const CREATE_APPLICATION = 'CREATE_APPLICATION';
export const CREATE_APPLICATION_SUCCESS = 'CREATE_APPLICATION_SUCCESS';
export const CREATE_APPLICATION_ERROR = 'CREATE_APPLICATION_ERROR';

export const CLONE_APPLICATION = 'CLONE_APPLICATION';
export const CLONE_APPLICATION_SUCCESS = 'CLONE_APPLICATION_SUCCESS';
export const CLONE_APPLICATION_ERROR = 'CLONE_APPLICATION_ERROR';

export const FETCH_APPLICATION = 'FETCH_APPLICATION';
export const FETCH_APPLICATION_SUCCESS = 'FETCH_APPLICATION_SUCCESS';
export const FETCH_APPLICATION_ERROR = 'FETCH_APPLICATION_ERROR';

export const FETCH_APPLICATION_UPDATES = 'FETCH_APPLICATION_UPDATES';
export const FETCH_APPLICATION_UPDATES_SUCCESS = 'FETCH_APPLICATION_UPDATES_SUCCESS';
export const FETCH_APPLICATION_UPDATES_ERROR = 'FETCH_APPLICATION_UPDATES_ERROR';

/* Application Form Actions */

export const SAVE_APPLICATION_SECTION = 'SAVE_APPLICATION_SECTION';
export const SAVE_APPLICATION_SECTION_SUCCESS = 'SAVE_APPLICATION_SECTION_SUCCESS';
export const SAVE_APPLICATION_SECTION_ERROR = 'SAVE_APPLICATION_SECTION_ERROR';

export const SUBMIT_APPLICATION_SECTION = 'SUBMIT_APPLICATION_SECTION';
export const SUBMIT_APPLICATION_SECTION_SUCCESS = 'SUBMIT_APPLICATION_SECTION_SUCCESS';
export const SUBMIT_APPLICATION_SECTION_ERROR = 'SUBMIT_APPLICATION_SECTION_ERROR';

export const SAVE_BANK_DETAILS = 'SAVE_BANK_DETAILS';
export const SAVE_BANK_DETAILS_SUCCESS = 'SAVE_BANK_DETAILS_SUCCESS';
export const SAVE_BANK_DETAILS_ERROR = 'SAVE_BANK_DETAILS_ERROR';

export const SUBMIT_BANK_DETAILS = 'SUBMIT_BANK_DETAILS';
export const SUBMIT_BANK_DETAILS_SUCCESS = 'SUBMIT_BANK_DETAILS_SUCCESS';
export const SUBMIT_BANK_DETAILS_ERROR = 'SUBMIT_BANK_DETAILS_ERROR';

export const SUBMIT_APPLICATION = 'SUBMIT_APPLICATION';
export const SUBMIT_APPLICATION_SUCCESS = 'SUBMIT_APPLICATION_SUCCESS';
export const SUBMIT_APPLICATION_ERROR = 'SUBMIT_APPLICATION_ERROR';

export const CHANGE_APPLICATION_QUOTE_PRE_SUBMISSION = 'CHANGE_APPLICATION_QUOTE_PRE_SUBMISSION';
export const CHANGE_APPLICATION_QUOTE_PRE_SUBMISSION_SUCCESS = 'CHANGE_APPLICATION_QUOTE_PRE_SUBMISSION_SUCCESS';
export const CHANGE_APPLICATION_QUOTE_PRE_SUBMISSION_ERROR = 'CHANGE_APPLICATION_QUOTE_PRE_SUBMISSION_ERROR';

export const CHANGE_APPLICATION_QUOTE = 'CHANGE_APPLICATION_QUOTE';
export const CHANGE_APPLICATION_QUOTE_SUCCESS = 'CHANGE_APPLICATION_QUOTE_SUCCESS';
export const CHANGE_APPLICATION_QUOTE_ERROR = 'CHANGE_APPLICATION_QUOTE_ERROR';

export const CHANGE_APPLICATION_VEHICLE = 'CHANGE_APPLICATION_VEHICLE';
export const RESET_EDIT_VEHICLE = 'RESET_EDIT_VEHICLE';

export const RESUBMIT_APPLICATION = 'RESUBMIT_APPLICATION';
export const RESUBMIT_APPLICATION_SUCCESS = 'RESUBMIT_APPLICATION_SUCCESS';
export const RESUBMIT_APPLICATION_ERROR = 'RESUBMIT_APPLICATION_ERROR';

export const UPDATE_DECISION = 'UPDATE_DECISION';
export const UPDATE_DECISION_SUCCESS = 'UPDATE_DECISION_SUCCESS';
export const UPDATE_DECISION_ERROR = 'UPDATE_DECISION_ERROR';

/* Dashboard */

export const FETCH_DASHBOARD_DATA = 'FETCH_DASHBOARD_DATA';
export const FETCH_DASHBOARD_DATA_SUCCESS = 'FETCH_DASHBOARD_DATA_SUCCESS';
export const FETCH_DASHBOARD_DATA_ERROR = 'FETCH_DASHBOARD_DATA_ERROR';

export const FETCH_DASHBOARD_SECTION = 'FETCH_DASHBOARD_SECTION';
export const FETCH_DASHBOARD_SECTION_SUCCESS = 'FETCH_DASHBOARD_SECTION_SUCCESS';
export const FETCH_DASHBOARD_SECTION_ERROR = 'FETCH_DASHBOARD_SECTION_ERROR';

export const FILTER_DASHBOARD_SECTION = 'FILTER_DASHBOARD_SECTION';
export const RESET_DASHBOARD = 'RESET_DASHBOARD';
export const FILTER_DASHBOARD_LEADS = 'FILTER_DASHBOARD_LEADS';

/* Finance Applications */

export const FETCH_FINANCE_APPLICATIONS = 'FETCH_FINANCE_APPLICATIONS';
export const FETCH_FINANCE_APPLICATIONS_SUCCESS = 'FETCH_FINANCE_APPLICATIONS_SUCCESS';
export const FETCH_FINANCE_APPLICATIONS_ERROR = 'FETCH_FINANCE_APPLICATIONS_ERROR';

/* LENDER UPDATES */

export const FETCH_LENDER_UPDATES = 'FETCH_LENDER_UPDATES';
export const FETCH_LENDER_UPDATES_SUCCESS = 'FETCH_LENDER_UPDATES_SUCCESS';
export const FETCH_LENDER_UPDATES_ERROR = 'FETCH_LENDER_UPDATES_ERROR';

/* CFC Actions */

export const CREATE_APPLICANT_FROM_CFC = 'CREATE_APPLICANT_FROM_CFC';
export const CREATE_APPLICANT_FROM_CFC_SUCCESS = 'CREATE_APPLICANT_FROM_CFC_SUCCESS';
export const CREATE_APPLICANT_FROM_CFC_ERROR = 'CREATE_APPLICANT_FROM_CFC_ERROR';

/* Product Actions */

export const FETCH_DEALERSHIP_PRODUCTS = 'FETCH_DEALERSHIP_PRODUCTS';
export const FETCH_DEALERSHIP_PRODUCTS_SUCCESS = 'FETCH_DEALERSHIP_PRODUCTS_SUCCESS';
export const FETCH_DEALERSHIP_PRODUCTS_ERROR = 'FETCH_DEALERSHIP_PRODUCTS_ERROR';

export const SAVE_ALL_PRODUCTS = 'SAVE_ALL_PRODUCTS';
export const SAVE_ALL_PRODUCTS_SUCCESS = 'SAVE_ALL_PRODUCTS_SUCCESS';
export const SAVE_ALL_PRODUCTS_ERROR = 'SAVE_PRODUCT_FORM_ERROR';

/* Quick Quote Actions */

export const CREATE_QUICK_QUOTE_APPLICATION = 'CREATE_QUICK_QUOTE_APPLICATION';
export const CREATE_QUICK_QUOTE_APPLICATION_SUCCESS = 'CREATE_QUICK_QUOTE_APPLICATION_SUCCESS';
export const CREATE_QUICK_QUOTE_APPLICATION_ERROR = 'CREATE_QUICK_QUOTE_APPLICATION_ERROR';
export const CREATE_QUICK_QUOTE_APPLICATION_EXITING_CUSTOMER = 'CREATE_QUICK_QUOTE_APPLICATION_EXITING_CUSTOMER';
export const CREATE_QUICK_QUOTE_APPLICATION_EXITING_CUSTOMER_SUCCESS =
  'CREATE_QUICK_QUOTE_APPLICATION_EXITING_CUSTOMER_SUCCESS';
export const CREATE_QUICK_QUOTE_APPLICATION_EXITING_CUSTOMER_ERROR =
  'CREATE_QUICK_QUOTE_APPLICATION_EXITING_CUSTOMER_ERROR';
export const SAVE_QUICK_QUOTE_APPLICATION = 'SAVE_QUICK_QUOTE_APPLICATION';
export const SAVE_QUICK_QUOTE_APPLICATION_SUCCESS = 'SAVE_QUICK_QUOTE_APPLICATION_SUCCESS';
export const SAVE_QUICK_QUOTE_APPLICATION_ERROR = 'SAVE_QUICK_QUOTE_APPLICATION_ERROR';

export const CHANGE_QUICK_QUOTE_VEHICLE = 'CHANGE_QUICK_QUOTE_VEHICLE';
export const CHANGE_QUICK_QUOTE_QUOTE = 'CHANGE_QUICK_QUOTE_QUOTE';
export const SET_QUICK_QUOTE_EDITING_VEHICLE = 'CHANGE_QUICK_QUOTE_EDITING_VEHICLE';
export const RESET_QUICK_QUOTE_EDITING_VEHICLE = 'RESET_QUICK_QUOTE_EDITING_VEHICLE';
export const SET_QUICK_QUOTE_VEHICLE_DERIVATIVES = 'SET_QUICK_QUOTE_VEHICLE_DERIVATIVES';

export const SORT_QUOTE_MODULE = 'SORT_QUOTE_MODULE';

//Corporate Quick Quote

export const CREATE_CORPORATE_QUICK_QUOTE_APPLICATION = 'CREATE_CORPORATE_QUICK_QUOTE_APPLICATION';
export const CREATE_CORPORATE_QUICK_QUOTE_APPLICATION_SUCCESS = 'CREATE_CORPORATE_QUICK_QUOTE_APPLICATION_SUCCESS';
export const CREATE_CORPORATE_QUICK_QUOTE_APPLICATION_ERROR = 'CREATE_CORPORATE_QUICK_QUOTE_APPLICATION_ERROR';

export const SAVE_CORPORATE_QUICK_QUOTE = 'SAVE_CORPORATE_QUICK_QUOTE';
export const SAVE_CORPORATE_QUICK_QUOTE_SUCCESS = 'SAVE_CORPORATE_QUICK_QUOTE_SUCCESS';
export const SAVE_CORPORATE_QUICK_QUOTE_ERROR = 'SAVE_CORPORATE_QUICK_QUOTE_ERROR';

export const ADD_COMMENT_TO_CONSUMER = 'ADD_COMMENT_TO_CONSUMER';
export const ADD_COMMENT_TO_CONSUMER_SUCCESS = 'ADD_COMMENT_TO_CONSUMER_SUCCESS';
export const ADD_COMMENT_TO_CONSUMER_ERROR = 'ADD_COMMENT_TO_CONSUMER_ERROR';

export const SHOW_HIDE_LEAD_ON_DASHBOARD = 'SHOW_HIDE_LEAD_ON_DASHBOARD';
export const SHOW_HIDE_LEAD_ON_DASHBOARD_SUCCESS = 'SHOW_HIDE_LEAD_ON_DASHBOARD_SUCCESS';
export const SHOW_HIDE_LEAD_ON_DASHBOARD_ERROR = 'SHOW_HIDE_LEAD_ON_DASHBOARD_ERROR';

//PARTIAL AND ABANDONED LEADS

export const GET_PARTIAL_APPLICATION = 'GET_PARTIAL_APPLICATION';
export const GET_PARTIAL_APPLICATION_SUCCESS = 'GET_PARTIAL_APPLICATION_SUCCESS';

export const GET_PARTIAL_APPLICATIONS = 'GET_PARTIAL_APPLICATIONS';
export const GET_PARTIAL_APPLICATIONS_SUCCESS = 'GET_PARTIAL_APPLICATIONS_SUCCESS';

export const CHANGE_PARTIAL_APPLICATIONS_FILTER_DATE = 'CHANGE_PARTIAL_APPLICATIONS_FILTER_DATE';
export const CHANGE_PARTIAL_APPLICATIONS_SORT_BY = 'CHANGE_PARTIAL_APPLICATIONS_SORT_BY';
export const CHANGE_PARTIAL_APPLICATIONS_PAGE = 'CHANGE_PARTIAL_APPLICATIONS_PAGE';

export const RESET_PARTIAL_APPLICATIONS_OPTIONS = 'RESET_PARTIAL_APPLICATIONS_OPTIONS';

//PLATFORM UPDATES

export const GET_PLATFORM_UPDATES = 'GET_PLATFORM_UPDATES';
export const GET_PLATFORM_UPDATES_SUCCESS = 'GET_PLATFORM_UPDATES_SUCCESS';
export const GET_PLATFORM_UPDATES_ERROR = 'GET_PLATFORM_UPDATES';
export const RESET_PLATFORM_UPDATES_OPTIONS = 'RESET_PLATFORM_UPDATES_OPTIONS';
export const CHANGE_PLATFORM_UPDATES_PAGE = 'CHANGE_PLATFORM_UPDATES_PAGE';

//REOPEN LEADS
export const REOPEN_LEAD = 'REOPEN_LEAD';
export const REOPEN_LEAD_SUCCESS = 'REOPEN_LEAD_SUCCESS';
export const REOPEN_LEAD_ERROR = 'REOPEN_LEAD_ERROR';

//Assign Lead

export const DASHBOARD_UPDATE_ASSIGNED_TO = 'DASHBOARD_UPDATE_ASSIGNED_TO';
export const CUSTOMER_UPDATE_ASSIGNED_TO = 'CUSTOMER_UPDATE_ASSIGNED_TO';

//Modals
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const UPDATE_MODAL_STATE = 'UPDATE_MODAL_STATE';

//Product Info
export const SETUP_PRODUCT_INFO_MODAL = 'SETUP_PRODUCT_INFO_MODAL';
export const CLEANUP_PRODUCT_INFO_MODAL = 'CLEANUP_PRODUCT_INFO_MODAL';

//Component Loading Stuff
export const COMPONENT_LOAD = 'COMPONENT_LOAD';
export const COMPONENT_UNLOAD = 'COMPONENT_UNLOAD';
export const COMPONENT_MOUNT = 'COMPONENT_MOUNT';
export const COMPONENT_UNMOUNT = 'COMPONENT_UNMOUNT';
export const COMPONENT_ERROR = 'COMPONENT_ERROR';

//EMAIL LOOKUP
export const LOOKUP_EMAIL = 'LOOKUP_EMAIL';
export const LOOKUP_EMAIL_SUCCESS = 'LOOKUP_EMAIL_SUCCESS';

export const CLEAR_LOOKUP_EMAIL = 'CLEAR_LOOKUP_EMAIL';

export const SORT_CUSTOMER_SECTION = 'SORT_CUSTOMER_SECTION';

//Save customer quote
export const SAVE_CUSTOMER_QUOTE = 'SAVE_CUSTOMER_QUOTE';
export const SAVE_CUSTOMER_QUOTE_SUCCESS = 'SAVE_CUSTOMER_QUOTE_SUCCESS';
export const SAVE_CUSTOMER_QUOTE_ERROR = 'SAVE_CUSTOMER_QUOTE_ERROR';

//Send Deal
export const SEND_DEAL_TO_CUSTOMER = 'SEND_DEAL_TO_CUSTOMER';
export const SEND_DEAL_TO_CUSTOMER_SUCCESS = 'SEND_DEAL_TO_CUSTOMER_SUCCESS';
export const SEND_DEAL_TO_CUSTOMER_ERROR = 'SEND_DEAL_TO_CUSTOMER_ERROR';
export const RESET_SEND_DEAL_MODAL = 'RESET_SEND_DEAL_MODAL';
export const SET_SEND_DEAL_PAGE = 'SET_SEND_DEAL_PAGE';
export const SEND_DEAL_TO_CUSTOMER_FROM_QUICK_QUOTE = 'SEND_DEAL_TO_CUSTOMER_FROM_QUICK_QUOTE';
export const SEND_DEAL_TO_CUSTOMER_FROM_QUICK_QUOTE_SUCCESS = 'SEND_DEAL_TO_CUSTOMER_FROM_QUICK_QUOTE_SUCCESS';
export const SEND_DEAL_TO_CUSTOMER_FROM_QUICK_QUOTE_ERROR = 'SEND_DEAL_TO_CUSTOMER_FROM_QUICK_QUOTE_ERROR';
export const SEND_DEAL_TO_EXISTING_CUSTOMER_FROM_QUICK_QUOTE = 'SEND_DEAL_TO_EXISTING_CUSTOMER_FROM_QUICK_QUOTE';
export const SEND_DEAL_TO_EXISTING_CUSTOMER_FROM_QUICK_QUOTE_SUCCESS =
  'SEND_DEAL_TO_EXISTING_CUSTOMER_FROM_QUICK_QUOTE_SUCCESS';
export const SEND_DEAL_TO_EXISTING_CUSTOMER_FROM_QUICK_QUOTE_ERROR =
  'SEND_DEAL_TO_EXISTING_CUSTOMER_FROM_QUICK_QUOTE_ERROR';

//LATEST DEALS
export const FETCH_LATEST_DEALS = 'FETCH_LATEST_DEALS';
export const FETCH_LATEST_DEALS_SUCCESS = 'FETCH_LATEST_DEALS_SUCCESS';
export const FETCH_LATEST_DEALS_ERROR = 'FETCH_LATEST_DEALS_ERROR';

export const FILTER_LATEST_DEALS_BY_DATE = 'SEARCH_LATEST_DEALS_BY_DATE';
export const FILTER_LATEST_DEALS_BY_STATUS = 'FILTER_LATEST_DEALS_BY_STATUS';

export const CHANGE_PAGE_LATEST_DEALS = 'CHANGE_PAGE_LATEST_DEALS';
export const SET_LATEST_DEALS_OPTIONS = 'SET_LATEST_DEALS_OPTIONS';
export const RESET_LATEST_DEALS = 'RESET_LATEST_DEALS';

//MONTHLY PRICE VIEWER
export const VIEW_MONTHLY_PAYMENTS = 'VIEW_MONTHLY_PAYMENTS';

export const FETCH_MONTHLY_PAYMENTS = 'FETCH_MONTHLY_PAYMENTS';
export const FETCH_MONTHLY_PAYMENTS_SUCCESS = 'FETCH_MONTHLY_PAYMENTS_SUCCESS';
export const FETCH_MONTHLY_PAYMENTS_ERROR = 'FETCH_MONTHLY_PAYMENTS_ERROR';

export const CHANGE_MONTHLY_PAYMENT_TERM = 'CHANGE_MONTHLY_PAYMENT_TERM';
