import React from 'react';
import PropTypes from 'prop-types';
import './mediaDashboardVehicle.scss';
import VehicleImage from '../../Common/VehicleImage';
import SmallVrm from '../../Common/SmallVrm';

const MediaDashboardVehicle = ({ vehicle, vehicleImage }) => (
  <div className="mediaDashboardVehicle">
    <div className="mediaDashboardVehicle__imageContainer">
      <VehicleImage
        imageSource={vehicleImage}
        imageWidth="75px"
        imageHeight="57px"
        vehicleClass={vehicle.Class}
        iconFontSize="45px"
      />
    </div>
    <div className="mediaDashboardVehicle__vrm">
      <SmallVrm vrm={vehicle.Vrm} />
    </div>
    <div>
      {vehicle.VehicleCount > 1 ? (
        <div className="mediaDashboardVehicle__count" title="Other vehicles of interest for this customer">
          +{vehicle.VehicleCount - 1}
        </div>
      ) : (
        ''
      )}
    </div>
  </div>
);

MediaDashboardVehicle.propTypes = {
  vehicle: PropTypes.object,
  vehicleImage: PropTypes.string
};

export default MediaDashboardVehicle;
