import React from 'react';
import PropTypes from 'prop-types';
import './businessTag.scss';
import { BusinessIcon } from '../../modules/Stock/components/IconSetOne';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';

const BusinessTag = ({ userType, smallTag, t }) => {
  return (
    <div>
      {userType === 'corporate' || userType === 'Corporate' ? (
        <div
          className={cn('businessTag', {
            'businessTag--small': smallTag
          })}
        >
          <BusinessIcon classProp="businessTag__icon" />
          {smallTag !== true && <div className="businessTag__text">{t('BusinessTag.business')}</div>}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

BusinessTag.propTypes = {
  userType: PropTypes.string,
  smallTag: PropTypes.bool
};

export default withTranslation('Common')(BusinessTag);
