import { Trans, withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import MobxFormFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import MobxForm from '../../MobxForm/MobxForm';
import TextInput from '../../Common/Form/TextInput';
import SelectInput from '../../Common/Form/SelectInput';
import CurrencyInput from '../../Common/Form/CurrencyInput';
import Validator from '../../../validators/Validator';
import Button from '../../Common/Button/Button';
import './reservationSetupForm.scss';
import * as api from '../../../api/reserveNow';
import isEmpty from 'lodash/isEmpty';
import LoadingSpinner from '../../Common/Loading/LoadingSpinner';
import ReserveTermsModal from './ReserveTermsModal';
import ToggleButtonWithIcon from '../../Application/components/ToggleButtonWithIcon';
import Checkbox from '../../Common/Form/Checkbox';
import ExclamationWarning from '../../Common/ExclamationWarning';
import ExclamationWarningWithButton from '../../Common/ExclamationWarningWithButton';
import InformationWarning from '../../Common/InformationWarning';
import { compose } from 'redux';
import { withNavigate } from '../../../../src/hocs/router';
import i18n from 'i18n';

class ReservationSetupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingStripeKey: false,
      initPrivateStripeKey: '',
      privateStripeKey: '',
      stripeKey: '',
      reservationFee: 99,
      reservationPeriod: 7,
      showPrivateKey: false,
      termsModalOpen: false,
      docsVersion: 0,
      latestDocsVersion: 0,
      isPrivateKeySet: false
    };
    const validationRules = {
      privateStripeKey: 'required, maxlength:255, secretStripeKey',
      stripeKey: 'required, maxlength:255, publishableStripeKey',
      reservationFee: 'required, currency, min:1, max:5000',
      reservationPeriod: 'required',
      termsAccepted: 'required, acceptedTerms:true'
    };
    this.setUpValidation(validationRules);

    i18n.on('languageChanged', this.onLanguageChange);
  }

  onLanguageChange = () => {
    this.getTerms();
  };

  setUpValidation(validationRules) {
    this.validator = new Validator();
    this.validator.setRules(validationRules);
    this.validationReactionDisposer = reaction(() => ({ ...this.state }), this.validator.validate, {
      fireImmediately: true
    });
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });
    this.props.appStore.reserveNowStore
      .getReservationSettings(this.props.dealershipId)
      .then((resp) => {
        const isPrivateSet = resp && resp.StripePrivateKeyLastUpdatedResponsibleUserName === null ? false : true;
        const validationRules = {
          privateStripeKey: isPrivateSet ? 'maxlength:255' : 'required, maxlength:255, secretStripeKey',
          stripeKey: 'required, maxlength:255, publishableStripeKey',
          reservationFee: 'required, currency, min:1, max:5000',
          reservationPeriod: 'required',
          termsAccepted: 'required, acceptedTerms:true'
        };
        this.setUpValidation(validationRules);
        this.setInitialState(resp);
      })
      .then(this.getTerms)
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false
        });
      });
  }

  componentWillUnmount() {
    this.validationReactionDisposer();
    i18n.off('languageChanged', this.onLanguageChange);
  }

  getTerms = () => {
    this.props.appStore.legalDocumentStore.fetchReserveConditions().then((terms) => {
      this.setState({
        isError: false,
        isLoading: false,
        latestDocsVersion: terms.metadata.Version,
        termsAccepted: Number(terms.metadata.Version) === Number(this.state.docsVersion)
      });
    });
  };

  setPrivateStripeKeyState = (data) => {
    this.setState(
      {
        privateStripeKey: data.StripePrivateKey ? data.StripePrivateKey : '',
        showPrivateKey: true,
        initPrivateStripeKey: data.StripePrivateKey,
        isLoadingStripeKey: false
      },
      () => {
        this.validator.validate(this.state);
      }
    );
  };
  setInitialState = (data) => {
    this.setState(
      {
        stripeKey: data && data.StripePublicKey ? data.StripePublicKey : '',
        reservationFee: data && data.ReservationAmount ? data.ReservationAmount / 100 : 99,
        reservationPeriod: data && data.ReservationDurationInDays ? data.ReservationDurationInDays : 7,
        isPrivateKeySet: data && data.StripePrivateKeyLastUpdatedResponsibleUserName !== null ? true : false,
        docsVersion: data.TermsVersionAccepted
      },
      () => {
        this.validator.validate(this.state);
      }
    );
  };
  handleSubmit = () => {
    if (!this.validator.errorCount) {
      this.props.appStore.reserveNowStore.setReservationSettings(this.props.dealershipId, this.state).then(() => {
        if (!isEmpty(this.state.privateStripeKey) && this.state.initPrivateStripeKey !== this.state.privateStripeKey) {
          api.setPrivateStripeKey(this.props.dealershipId, this.state.privateStripeKey);
        }

        if (this.state.docsVersion !== this.state.latestDocsVersion) {
          api.setAcceptTerms(this.props.dealershipId, this.state.latestDocsVersion);
        }

        setTimeout(() => {
          this.props.navigate({
            pathname: `/d/${this.props.dealershipId}/settings`
          });
        }, 2000);
      });
    }
  };
  handleOpenModal = () => {
    this.setState({
      termsModalOpen: true
    });
  };
  handleCloseModal = () => {
    this.setState({
      termsModalOpen: false
    });
  };
  handleChangeValue = (key, value) => {
    let state = { ...this.state };
    state[key] = value;
    this.validator.validate(state);
    this.setState({ ...state });
  };
  setReservationFee = (value) => {
    this.validator.validate(this.state);
    this.setState({
      reservationFee: value
    });
  };
  toggleShowPrivateKey = () => {
    this.setState({
      isLoadingStripeKey: true
    });

    if (!this.state.showPrivateKey) {
      api.getPrivateStripeKey(this.props.dealershipId).then((resp) => {
        this.setPrivateStripeKeyState(resp);
      });
    } else {
      this.setState({
        isLoadingStripeKey: false,
        privateStripeKey: '',
        showPrivateKey: false
      });
    }
  };

  render() {
    if (this.props.appStore.reserveNowStore.isPageLoading) {
      return (
        <div className="vehicleReservations__loading">
          <LoadingSpinner />
        </div>
      );
    }

    const errors = this.validator.getErrors();
    let privatePlaceholder = '';

    if (this.state.isPrivateKeySet) {
      privatePlaceholder = '*********************************************';
    }

    if (this.state.isLoading) {
      return (
        <div className="reservationSetupForm reservationSetupForm--loading">
          <LoadingSpinner />
        </div>
      );
    }

    const showTestKeyWarning =
      !errors.privateStripeKey &&
      !errors.stripeKey &&
      ((this.state.showPrivateKey && this.state.privateStripeKey.startsWith('sk_test_')) ||
        this.state.stripeKey.startsWith('pk_test_'));
    const warningChildStyle = {
      paddingBottom: '8px'
    };
    return (
      <div>
        {!this.state.isError ? (
          <div className="reservationSetupForm__termsError">
            {this.state.latestDocsVersion !== 0 &&
              this.state.docsVersion !== 0 &&
              this.state.docsVersion !== this.state.latestDocsVersion && (
                <ExclamationWarning
                  title={this.props.t('ReservationSetupForm.terms_have_updated')}
                  message={this.props.t('ReservationSetupForm.you_are_required_to_accept')}
                />
              )}
          </div>
        ) : (
          <div className="reservationSetupForm__termsError">
            <ExclamationWarningWithButton
              title={this.props.t('ReservationSetupForm.terms_of_use_failed_to_load')}
              message={this.props.t('ReservationSetupForm.please_try_to_reload')}
              buttonText={this.props.t('ReservationSetupForm.refresh')}
              onClick={() => window && window.location.reload()}
            />
          </div>
        )}

        <ReserveTermsModal isOpen={this.state.termsModalOpen} handleClose={this.handleCloseModal} />
        {showTestKeyWarning && (
          <div className="reservationSetupForm__stripeWarning">
            <InformationWarning>
              <div style={warningChildStyle}>
                {this.props.t('ReservationSetupForm.please_use_your_stripe_live_api_keys')}
              </div>
              <div style={warningChildStyle}>
                <Trans ns="ReserveNow" i18nKey={'ReservationSetupForm.publishable_keys_start'} />
              </div>
              <div>
                {this.props.t('ReservationSetupForm.information_about_your_stripe_api_keys')}{' '}
                <a
                  href="https://stripe.com/docs/keys"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    window && window.ga && window.ga('send', 'event', 'reservationSetupForm', 'StripeDocsLinkClick')
                  }
                >
                  {this.props.t('ReservationSetupForm.https_stripe_com_docs_keys')}
                </a>
              </div>
            </InformationWarning>
          </div>
        )}

        <MobxForm focusOnNthElement={1} onSubmit={this.handleSubmit} className="reservationSetupForm">
          <MobxFormFieldGroup isInline error={errors.stripeKey}>
            <MobxFormLabel htmlFor="stripeKey">
              {this.props.t('ReservationSetupForm.publishable_stripe_key')}
            </MobxFormLabel>
            <TextInput id="stripeKey" value={this.state.stripeKey} onChange={this.handleChangeValue} maxLength="255" />
          </MobxFormFieldGroup>
          <MobxFormFieldGroup isInline error={errors.privateStripeKey}>
            <MobxFormLabel htmlFor="privateStripeKey">
              {this.props.t('ReservationSetupForm.secret_stripe_key')}
            </MobxFormLabel>
            <TextInput
              placeholder={privatePlaceholder}
              id="privateStripeKey"
              value={this.state.privateStripeKey}
              onChange={this.handleChangeValue}
              disabled={this.state.isPrivateKeySet && !this.state.showPrivateKey}
              maxLength="255"
            />
          </MobxFormFieldGroup>
          <MobxFormFieldGroup isInline error={errors.reservationFee}>
            <MobxFormLabel htmlFor="reservationFee">
              {this.props.t('ReservationSetupForm.reservation_fee')}
            </MobxFormLabel>
            <CurrencyInput id="reservationFee" value={this.state.reservationFee} onChange={this.setReservationFee} />
          </MobxFormFieldGroup>
          <MobxFormFieldGroup isInline error={errors.reservationPeriod}>
            <MobxFormLabel htmlFor="reservationPeriod">
              {this.props.t('ReservationSetupForm.reservation_period')}
            </MobxFormLabel>
            <SelectInput
              id="reservationPeriod"
              value={this.state.reservationPeriod}
              onChange={this.handleChangeValue}
              options="ReservationPeriod"
              emptyValue={false}
            />
          </MobxFormFieldGroup>
          {this.state.docsVersion !== 0 && this.state.latestDocsVersion !== 0 && (
            <MobxFormFieldGroup isInline error={errors.termsAccepted}>
              <div className="reservationSetupForm__checkboxOuter">
                <Checkbox
                  id="termsAccepted"
                  value={this.state.termsAccepted}
                  onChange={(value) => this.handleChangeValue('termsAccepted', value)}
                />
                <label htmlFor="termsAccepted" className="reservationSetupForm__checkboxLabel">
                  {this.props.t('ReservationSetupForm.i_accept_the_vehicle_reservations')}
                  <button className="reservationSetupForm__button--link" type="button" onClick={this.handleOpenModal}>
                    {this.props.t('ReservationSetupForm.terms_of_use')}
                  </button>
                </label>
              </div>
            </MobxFormFieldGroup>
          )}
          <div className="reservationSetupForm__toggleOuter">
            {!this.state.isLoadingStripeKey ? (
              <ToggleButtonWithIcon
                hideDetails={!this.state.showPrivateKey}
                className="reservationSetupForm__keyToggle"
                onClick={this.toggleShowPrivateKey}
                showLabel={
                  !this.state.showPrivateKey
                    ? this.props.t('ReservationSetupForm.show_edit')
                    : this.props.t('ReservationSetupForm.hide')
                }
              >
                {this.props.t('ReservationSetupForm.secret_stripe_key')}
              </ToggleButtonWithIcon>
            ) : (
              <LoadingSpinner size="small" />
            )}
          </div>
          <div className="reservationSetupForm__buttonOuter">
            <div className="reservationSetupForm__button">
              <Button type="cancel" buttonStyle="cancel" to={`/d/${this.props.dealershipId}/settings`}>
                {this.props.t('ReservationSetupForm.cancel')}
              </Button>
            </div>
            <div className="reservationSetupForm__button">
              <Button
                isLoading={this.props.appStore.reserveNowStore.setReservationSettingsRequest.isLoading}
                type="submit"
              >
                {this.props.t('ReservationSetupForm.save')}
              </Button>
            </div>
          </div>
        </MobxForm>
      </div>
    );
  }
}

ReservationSetupForm.propTypes = {
  appStore: PropTypes.object,
  dealershipId: PropTypes.string
};

export default compose(
  withNavigate,
  withTranslation('ReserveNow'),
  inject(['appStore']),
  observer
)(ReservationSetupForm);
