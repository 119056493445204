import _ from 'lodash';
import { calculateTotalMonthsFromCollection } from '../helpers';

export function cfcPrefillParser(rawData) {
  let addresses = _.map(rawData.Addresses, (address) => {
    let years;
    let months;

    if (address.MonthsAtAddress > 0) {
      years = Math.floor(parseInt(address.MonthsAtAddress, 10) / 12);
      months = parseInt(address.MonthsAtAddress, 10) % 12;
    }

    return {
      Residency: address.Residency,
      BuildingName: address.BuildingName,
      BuildingNumber: address.BuildingNumber,
      Country: address.Country,
      County: address.County,
      District: address.District,
      Postcode: address.Postcode,
      PostTown: address.PostTown,
      Street: address.Street,
      SubBuilding: address.SubBuilding,
      TimeAtAddress: {
        Years: years,
        Months: months
      }
    };
  });

  let employments = _.map(rawData.Employments, (employment) => {
    let years;
    let months;

    if (employment.MonthsAtJob > 0) {
      years = Math.floor(parseInt(employment.MonthsAtJob, 10) / 12);
      months = parseInt(employment.MonthsAtJob, 10) % 12;
    }

    return {
      OccupationBasis: employment.EmploymentBasis,
      OccupationType: employment.EmploymentType,
      TimeAtEmployment: {
        Years: years,
        Months: months
      }
    };
  });

  return {
    PersonalDetails: {
      DateOfBirth: rawData.DateOfBirth,
      NoOfDependants: rawData.Dependants,
      FirstName: rawData.Firstname,
      DrivingLicence: rawData.Licence,
      MaritalStatus: rawData.MaritalStatus,
      Title: rawData.Title,
      Email: rawData.Email,
      Telephone: rawData.Phone,
      LastName: rawData.Surname
    },
    AddressHistory: {
      Items: addresses,
      totalMonths: calculateTotalMonthsFromCollection(addresses, 'TimeAtAddress')
    },
    EmploymentHistory: {
      Items: employments,
      totalMonths: calculateTotalMonthsFromCollection(employments, 'TimeAtEmployment')
    }
  };
}
