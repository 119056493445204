import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Page from '../Common/Page';
import Breadcrumbs from '../Common/Breadcrumbs';
import Panel from '../Common/Panel';
import PanelContent from '../Common/PanelContent';
import PanelHeader from '../Common/PanelHeader';
import CloseDealToolbar from './CloseDealToolbar';
import Modal from '../Common/Modal/Modal';
import Button from '../Common/Button/Button';
import CloseDealOptionsButtons from './CloseDealOptionsButtons';
import './closeDeal.scss';
import { observer, inject } from 'mobx-react';
import LoadingDots from '../Common/Loading/LoadingDots';
import ErrorApology from '../Common/ErrorApology';
import _ from 'lodash';
import ExclamationWarning from '../Common/ExclamationWarning';
import { compose } from 'redux';
import { withLocation, withParams } from 'hocs/router';
import { Outlet } from 'react-router-dom';

class CloseDeal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false
    };
  }

  UNSAFE_componentWillMount() {
    this.props.appStore.closeDealStore.fetchCloseDealData(
      this.props.params.consumerId,
      this.props.params.vehicleId,
      this.props.params.dealershipId
    );
    this.props.appStore.customerStore.fetchCustomerData(this.props.params.consumerId).then(() => {
      const vehicle =
        this.props.appStore.customerStore.customer &&
        this.props.appStore.customerStore.customer.getVehicle(this.props.params.vehicleId);

      const closeDealStatus = _.get(vehicle, 'Status.Status');

      this.props.appStore.closeDealStore.setCloseDealStatus(closeDealStatus);
    });
  }

  handleReopenLead = () => {
    this.setState({
      isModalOpen: true
    });
  };
  close = (e) => {
    e.preventDefault();
    this.setState({
      isModalOpen: false
    });
  };
  handleReopenSubmit = () => {
    this.props.appStore.closeDealStore
      .reopenLead(
        this.props.appStore.customerStore.customer.CustomerType,
        this.props.params.vehicleId,
        this.props.params.consumerId
      )
      .then(() => {
        this.props.appStore.closeDealStore.removeCloseDealStatus();
        this.setState({
          isModalOpen: false
        });
      });
  };

  render() {
    const closeDealStore = this.props.appStore.closeDealStore;
    const { customer } = this.props.appStore.customerStore;
    const { children, ...props } = this.props;
    const closeDealStatus = this.props.appStore.closeDealStore.closeDealStatus;

    if (!props.appStore.closeDealStore.hasCloseDealLoaded || props.appStore.customerStore.isLoadingCustomer) {
      return <LoadingDots />;
    }

    if (this.props.appStore.customerStore.hasError) {
      return <ErrorApology />;
    }

    const currentSection = _.last(props.location.pathname.split('/'));

    return (
      <Page testHook="MarkAsNotAvailable" isLoading={props.appStore.closeDealStore.isLoading}>
        <Breadcrumbs
          items={[
            {
              name: this.props.t('CloseDeal.home'),
              path: `/d/${props.params.dealershipId}`
            },
            {
              name: this.props.t('CloseDeal.customer_list'),
              path: `/d/${props.params.dealershipId}/consumers`
            },
            {
              name: this.props.t('CloseDeal.consumer'),
              path: `/d/${props.params.dealershipId}/consumers/${props.params.consumerId}`
            },
            {
              name: this.props.t('CloseDeal.close_deal')
            }
          ]}
          consumer={customer}
        />

        <Panel>
          <PanelHeader>
            {closeDealStatus ? this.props.t('CloseDeal.edit_closed_deal') : this.props.t('CloseDeal.deal_close_out')}
          </PanelHeader>
          {closeDealStatus ? <CloseDealToolbar onClick={this.handleReopenLead} /> : ''}
          <PanelContent>
            {props.appStore.uiState.canCloseDeals ? (
              <>
                <CloseDealOptionsButtons section={currentSection} params={props.params} />
                <Outlet {...this.props} />
              </>
            ) : (
              <ExclamationWarning
                message={this.props.t('CloseDeal.you_do_not_have_the_required_permission_to_do_this_action')}
              />
            )}
          </PanelContent>
        </Panel>
        <Modal isOpen={this.state.isModalOpen} onClose={this.close}>
          <div className="closeDeal__inner">
            <div className="closeDeal__top">{this.props.t('CloseDeal.are_you_sure_you_want_to_reopen_this_deal')}</div>
            <div className="closeDeal__buttonContainer">
              <div className="closeDeal__reopen">
                <Button
                  buttonStyle="primary"
                  onClick={this.handleReopenSubmit}
                  isLoading={closeDealStore.sendReopenLeadRequest.isLoading}
                  hasError={closeDealStore.sendReopenLeadRequest.hasError}
                >
                  {this.props.t('CloseDeal.reopen')}
                </Button>
              </div>
              <div className="closeDeal__cancel">
                <Button buttonStyle="cancel" onClick={this.close}>
                  {this.props.t('CloseDeal.cancel')}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </Page>
    );
  }
}

CloseDeal.propTypes = {
  appStore: PropTypes.object,
  params: PropTypes.object
};

export default compose(
  withParams,
  withLocation,
  withTranslation('CloseDeal'),
  inject(['appStore']),
  observer
)(CloseDeal);
