import * as actionTypes from '../actionTypes';

export const componentLoad = (componentName, params, query) => {
  return {
    type: actionTypes.COMPONENT_LOAD,
    componentName,
    params,
    query
  };
};

export const componentUnload = (componentName, params) => {
  return {
    type: actionTypes.COMPONENT_UNLOAD,
    componentName,
    params
  };
};

export const componentMount = (componentName) => {
  return {
    type: actionTypes.COMPONENT_MOUNT,
    componentName
  };
};

export const componentUnmount = (componentName) => {
  return {
    type: actionTypes.COMPONENT_UNMOUNT,
    componentName
  };
};

export const componentError = (componentName, errorMessage, error) => {
  return {
    type: actionTypes.COMPONENT_ERROR,
    componentName,
    errorMessage,
    error
  };
};
