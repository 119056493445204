import React from 'react';
import { useTranslation } from 'react-i18next';
import './noAssignees.scss';

const NoAssignees = () => {
  const { t } = useTranslation('AssignLead');
  return <span className="noAssignees">{t('NoAssignees.unassigned')}</span>;
};

export default NoAssignees;
