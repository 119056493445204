import platformApiUtils from '@ivendi/platform-api-utils';
import * as actionTypes from '../actionTypes';
import * as sessionActions from '../session/sessionActions';
import { schemaMap } from '../../core/schema';
import dealershipSettingsSchema from '../../core/schemas/dealershipSettingsSchema';
import { push } from '../../routerHistory';

export function fetchDealershipSettings(dealershipId) {
  return {
    types: [
      actionTypes.FETCH_DEALERSHIP_SETTINGS,
      actionTypes.FETCH_DEALERSHIP_SETTINGS_SUCCESS,
      actionTypes.FETCH_DEALERSHIP_SETTINGS_ERROR
    ],
    callAPI: () => platformApiUtils.get('v1/dealershipsettings/' + dealershipId),
    parseResponse: (data) => schemaMap(data, dealershipSettingsSchema)
  };
}

export function cleanupDealershipSettings() {
  return {
    type: actionTypes.CLEANUP_DEALERSHIP_SETTINGS
  };
}

export function saveDealershipSettings(dealershipId, data) {
  return (dispatch) => {
    dispatch({
      types: [
        actionTypes.SAVE_DEALERSHIP_SETTINGS,
        actionTypes.SAVE_DEALERSHIP_SETTINGS_SUCCESS,
        actionTypes.SAVE_DEALERSHIP_SETTINGS_ERROR
      ],
      callAPI: () =>
        platformApiUtils.post('v1/dealershipsettings/' + dealershipId, {
          DepositType: data.DepositType,
          DepositValue: data.DepositValue,
          PreferredTerm: data.PreferredTerm,
          UsageType: data.UsageType,
          VehicleClass: data.VehicleClass,
          VehicleType: data.VehicleType,
          DefaultAnnualMileage: Number(data.DefaultAnnualMileage)
        }),
      onSuccess: () => {
        dispatch(sessionActions.updateWhoAmI());
        window.setTimeout(() => {
          push(`/d/${dealershipId}/settings`);
        }, 300);
      }
    });
  };
}
