import { TFunction } from 'i18next';
import AlgoliaVehicle from '~/mobx-models/AlgoliaVehicle';
import { isBike } from '../../../../../../../shared/helpers/transformTaxonomyData';
import { getYearFromDate } from '~/core/helpers';

interface Props {
  vehicle: AlgoliaVehicle;
  t: TFunction;
  lng: string;
  enableFallbackValue?: boolean;
  showMileage?: boolean;
  showBadgeEngineSize?: boolean;
}
export const getVehicleStatsString = ({
  vehicle,
  t,
  lng,
  enableFallbackValue = true,
  showMileage = true,
  showBadgeEngineSize = true
}: Props) => {
  let vehicleStats = [];

  const fallbackValue = enableFallbackValue ? t('StockListItem.date_of_reg_missing') : '';
  const shouldDisplaySeats = !isBike(vehicle?.Class);
  const registrationDate = getYearFromDate(vehicle?.RegistrationDate || vehicle?.Registered) || fallbackValue;
  const getPrimaryOrSuppliedValue = (primary: any, supplied: any) => primary || supplied;
  const bodyStyle = getPrimaryOrSuppliedValue(vehicle.BodyStyle, vehicle.SuppliedBodyStyle);
  const fuel = getPrimaryOrSuppliedValue(vehicle.SFuel, vehicle.SuppliedFuelType);
  const doors = getPrimaryOrSuppliedValue(vehicle.Doors, vehicle.SuppliedDoors);

  if (vehicle.DealerPlatformUpload) {
    vehicleStats.push(registrationDate);
    showMileage && vehicle.Mileage && vehicleStats.push(t('StockListItem.miles', { value: vehicle.Mileage }));
    vehicle.Fuel && vehicleStats.push(vehicle.Fuel);
    vehicle.Doors && vehicle.Doors !== '0' && vehicleStats.push(t('StockListItem.doors', { value: vehicle.Doors }));
    vehicle.Transmission && vehicleStats.push(vehicle.Transmission);
    vehicle.BodyStyle && vehicleStats.push(vehicle.BodyStyle);
    vehicle.CC && vehicleStats.push(t('StockListItem.cc', { value: vehicle.CC }));
    showBadgeEngineSize &&
      vehicle.BadgeEngineSize &&
      Number.isInteger(vehicle.BadgeEngineSize) &&
      vehicleStats.push(t('StockListItem.engineSize', { value: vehicle.BadgeEngineSize.toFixed(1) }));
    vehicle.NoOfSeats &&
      shouldDisplaySeats &&
      vehicleStats.push(t('StockListItem.seats', { value: vehicle.NoOfSeats }));

    if (lng === 'en') {
      vehicle.Bhp && vehicleStats.push(t('StockListItem.bhp', { value: vehicle.Bhp }));
    }
    if (lng === 'de') {
      vehicle.PwrKW && vehicleStats.push(t('StockListItem.kw', { value: vehicle.PwrKW }));
    }
  }
  if (!vehicle.DealerPlatformUpload) {
    vehicleStats.push(registrationDate);
    showMileage && vehicleStats.push(t('StockListItem.miles', { value: vehicle.Mileage }));
    fuel && vehicleStats.push(fuel);
    doors && doors !== '0' && vehicleStats.push(t('StockListItem.doors', { value: doors }));
    vehicle.STransmission && vehicleStats.push(vehicle.STransmission);
    bodyStyle && vehicleStats.push(bodyStyle);
    vehicle.CC && vehicleStats.push(t('StockListItem.cc', { value: vehicle.CC }));
    showBadgeEngineSize &&
      vehicle.BadgeEngineSize &&
      Number.isInteger(vehicle.BadgeEngineSize) &&
      vehicleStats.push(t('StockListItem.engineSize', { value: vehicle.BadgeEngineSize.toFixed(1) }));
    vehicle.NoOfSeats &&
      shouldDisplaySeats &&
      vehicleStats.push(t('StockListItem.seats', { value: vehicle.NoOfSeats }));

    if (lng === 'en') {
      vehicle.Bhp && vehicleStats.push(t('StockListItem.bhp', { value: vehicle.Bhp }));
    }
    if (lng === 'de') {
      vehicle.PwrKW && vehicleStats.push(t('StockListItem.kw', { value: vehicle.PwrKW }));
    }
  }

  return vehicleStats.filter(Boolean).join(' | ');
};
