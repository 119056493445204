import PropTypes from 'prop-types';
import Icon from '~Common/Icon/Icon';
import styles from './starRating.module.scss';

const StarRating = ({ stars, active }) => {
  return (
    <div className={styles.container}>
      {Array(stars)
        .fill()
        .map((e, i) => {
          return (
            <div className={i <= active - 1 ? styles.starFill : styles.star} key={i}>
              <Icon name="star" />
            </div>
          );
        })}
    </div>
  );
};

StarRating.propTypes = {
  stars: PropTypes.number,
  active: PropTypes.number
};

export default StarRating;
