import * as actionTypes from '../actionTypes';

export default function dealershipReducer(state = { isLoading: true }, action) {
  switch (action.type) {
    case actionTypes.FETCH_DEALERSHIP_INFO:
      return Object.assign({}, { isLoading: true, Id: action.dealershipId });

    case actionTypes.FETCH_DEALERSHIP_INFO_SUCCESS:
      return Object.assign({}, action.response, {
        isLoading: false,
        Id: action.dealershipId,
        WebQuotewareId: action.response.WebQuotewareId || action.response.QuotewareId
      });

    case actionTypes.FETCH_DEALERSHIP_INFO_ERROR:
      return Object.assign({ isLoading: false });

    default:
      return state;
  }
}
