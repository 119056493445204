import _ from 'lodash';
import { ConsumerDutyProductDetails } from '../api/contentService';
import { trackFinanceProductSuitabilityVideoViewed } from './avoTracking';

export const addScript = (src: string, async = true) => {
  if (!document.querySelector(`script[src="${src}"]`)) {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = async;
    s.src = src;
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(s);
  }
};

export const initVideoTracking = (
  videoId: string | null | undefined,
  consumerDutyContent: ConsumerDutyProductDetails,
  dealershipId: string,
  quoteId: string
) => {
  const trackEvent = (watchedSeconds: number) =>
    trackFinanceProductSuitabilityVideoViewed({
      consumerDutyContent,
      dealershipId,
      timeViewed: watchedSeconds,
      quoteId
    });
  // @ts-expect-error
  window._wq = window._wq || [];
  // @ts-expect-error
  window._wq.push({
    id: videoId,
    onReady: (video: any) => {
      // we count seconds watched, throttle prevents multiple counts per second when fast-forwarding the video
      let watchedSeconds = 0;

      video.bind(
        'secondchange',
        _.throttle(function() {
          watchedSeconds++;
        }, 1000)
      );
      video.bind('pause', () => trackEvent(watchedSeconds));
      video.bind('end', () => trackEvent(watchedSeconds));
    }
  });
};
