import { withTranslation } from 'react-i18next';
import { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { reaction } from 'mobx';
import MobxForm from '../MobxForm/MobxForm';
import MobxFieldGroup from '../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../MobxForm/MobxFormLabel';
import debounce from 'lodash/debounce';
import FormFooter from '../Common/Form/FormFooter';
import Icon from '../Common/Icon/Icon';
import Validator from '../../validators/Validator';
import TextInput from '../Common/Form/TextInput';
import CheckboxWithLabel from '../Common/Form/CheckboxWithLabel';
import classnames from 'classnames';
import Button from '../Common/Button/Button';
import AssignLeadContainer from '../AssignLead/containers/AssignLeadContainer';
import './pushDealSelectCustomer.scss';

class PushDealSelectCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discounted: false,
      isNewCustomer: false,
      showAddCustomerButton: true,
      hasMouseOver: false,
      hasFocus: false,
      searchEmail: '',
      trimmedEmail: '',
      customer: {
        firstname: '',
        surname: '',
        email: '',
        id: '',
        assignToSelf: true
      }
    };
    const validationRules = {
      email: 'required, email',
      firstname: 'required, maxlength:50',
      surname: 'required, maxlength:50'
    };
    this.setUpValidation(validationRules);
  }

  setUpValidation(validationRules) {
    this.validator = new Validator();
    this.validator.setRules(validationRules);
    this.validationReactionDisposer = reaction(
      () => ({ ...this.props.appStore.pushDealStore.customer }),
      this.validator.validate,
      {
        fireImmediately: true
      }
    );
  }

  componentDidMount() {
    const customer = this.props.appStore.pushDealStore.customer;
    this.setState(
      (prevState) => ({
        customer: {
          ...prevState.customer,
          firstname: customer.Firstname,
          surname: customer.Surname,
          email: customer.Email,
          id: customer.Id,
          assignToSelf: true
        },
        searchEmail: customer.Email,
        showAddCustomerButton: !customer.Firstname
      }),
      () => this.validator.validate(this.state)
    );
    if (this.props.email) {
      this.handleEmailSearchChange(null, this.props.email);
    }
  }

  handleEmailInputChange = (id, value) => {
    this.props.appStore.pushDealStore.handleEmailInputChange(id, value);
    this.setState(
      (prevState) => ({
        customer: { ...prevState.customer, email: value },
        isExistingCustomer: false
      }),
      () => this.validator.validate(this.state.customer)
    );
  };
  handleFirstnameInputChange = (id, value) => {
    this.setState(
      (prevState) => ({
        customer: { ...prevState.customer, firstname: value }
      }),
      () => this.validator.validate(this.state.customer)
    );
  };
  handleSurnameInputChange = (id, value) => {
    this.setState(
      (prevState) => ({
        customer: { ...prevState.customer, surname: value }
      }),
      () => this.validator.validate(this.state.customer)
    );
  };
  setAssignToSelf = (value) => {
    this.setState(
      (prevState) => ({
        customer: { ...prevState.customer, assignToSelf: value }
      }),
      () => this.validator.validate(this.state.customer)
    );
  };
  handleEmailSearchChange = (id, value) => {
    this.props.appStore.pushDealStore.handleEmailInputChange(id, value);
    this.debouncedCustomerSuggestions();
    let newEmailValue = '';
    let fillFirstName = '';
    let fillSurname = '';

    if (value.includes(' ') && !value.includes('@')) {
      fillFirstName = value.split(' ')[0];
      fillSurname = value.split(' ')[1];
    } else if (value.includes('@') && !value.includes(' ')) {
      newEmailValue = value;
    }

    this.setState((state) => {
      state.isNewCustomer = false;
      state.showAddCustomerButton = true;
      state.searchEmail = value;
      state.isExistingCustomer = false;
      state.customer.firstname = fillFirstName;
      state.customer.surname = fillSurname;
      state.customer.email = newEmailValue;
      return state;
    });
  };
  handleAddNewCustomer = () => {
    this.setState({
      isNewCustomer: true,
      showAddCustomerButton: false,
      searchEmail: ''
    });
    this.resetForm();
    window.ga && window.ga('send', 'event', 'PushDeal_SelectCustomer', 'ButtonPress', `AddNewCustomer`);
  };
  getReset = (func) => {
    this.resetForm = func;
  };
  useExistingCustomer = () => {
    this.setState((prevState) => ({
      customer: {
        ...prevState.customer,
        firstname: this.props.appStore.pushDealStore.customer.Firstname,
        surname: this.props.appStore.pushDealStore.customer.Surname,
        email: this.props.appStore.pushDealStore.customer.Email,
        id: this.props.appStore.pushDealStore.customer.Id
      },
      searchEmail: this.props.appStore.pushDealStore.customer.Email,
      isExistingCustomer: false,
      isNewCustomer: false
    }));
  };
  resetCustomerInfo = () => {
    this.setState((prevState) => ({
      customer: { ...prevState.customer, firstname: '', surname: '', email: '', id: '' },
      isNewCustomer: false,
      showAddCustomerButton: true,
      searchEmail: '',
      trimmedEmail: ''
    }));
    this.props.appStore.pushDealStore.resetCustomer();
  };
  handleSaveSuggestedCustomer = async (customer) => {
    await this.props.appStore.pushDealStore.saveSuggestedCustomer(customer);
    await this.props.appStore.customerStore.fetchCustomerData(customer.Id);
    this.setState((prevState) => ({
      customer: {
        ...prevState.customer,
        vehiclesOfInterest: customer.Consumer.VehiclesOfInterest,
        firstname: customer.Consumer.Firstname,
        surname: customer.Consumer.Surname,
        email: customer.Consumer.Email,
        id: customer.Id
      },
      showAddCustomerButton: false,
      searchEmail: customer.Consumer.Email
    }));
    window.ga && window.ga('send', 'event', 'PushDeal_SelectCustomer', 'ButtonPress', `PushDeal_SelectCustomer`);
  };
  handleMouseEnter = () =>
    this.setState({
      hasMouseOver: true
    });
  handleMouseLeave = () =>
    this.setState({
      hasMouseOver: false
    });
  handleFocus = () => {
    this.setState({
      hasFocus: true
    });
  };
  handleBlur = () => {
    this.props.appStore.pushDealStore.checkIfCustomerExists();
    this.setState({
      hasFocus: false
    });
  };
  onSubmit = async () => {
    this.validator.validate(this.state.customer);

    if (this.validator.errorCount === 0) {
      if (this.state.isNewCustomer === true) {
        await this.props.appStore.pushDealStore.checkIfCustomerExistsAndSave();
        this.setState({
          isExistingCustomer: this.props.appStore.pushDealStore.isExistingCustomer
        });
        this.validator.validate(this.state.customer);

        if (!this.state.isExistingCustomer) {
          this.props.appStore.pushDealStore
            .addNewCustomer(
              this.state.customer.email,
              this.state.customer.firstname,
              this.state.customer.surname, // this.state.customer.id,
              this.state.customer.assignToSelf
            )
            .then(() => {
              this.props.appStore.pushDealStore.fetchQuotes();
              this.props.nextSection();
            });
        }
      } else {
        this.props.appStore.pushDealStore.fetchQuotes();
        this.props.nextSection();
      }
    }
  };
  onCancel = () => {
    this.props.changeTitle('pricing');
    this.resetCustomerInfo();
  };
  debouncedCustomerSuggestions = debounce(this.props.appStore.pushDealStore.getSuggestedCustomers, 300);
  parseAssigned = (users) => {
    if (users === null || users === undefined) {
      return [];
    }

    return users.map((user) => {
      return {
        firstName: user.Firstname,
        surname: user.Surname,
        avatarColor: user.Avatar && typeof user.Avatar.Colour === 'number' ? user.Avatar.Colour : null
      };
    });
  };

  render() {
    const errors = this.validator.getErrors();
    const { suggestedCustomers, createCustomerIsLoading, createCustomerError } = this.props.appStore.pushDealStore;
    let prefixClassnames = classnames(
      'pushDealSelectCustomer__searchIcon',
      this.state.hasFocus && 'pushDealSelectCustomer__searchIcon--focus',
      this.state.hasMouseOver && 'pushDealSelectCustomer__searchIcon--hover'
    );
    let suffixClassnames = classnames(
      'pushDealSelectCustomer__removeIcon',
      this.state.hasFocus && 'pushDealSelectCustomer__removeIcon--focus',
      this.state.hasMouseOver && 'pushDealSelectCustomer__removeIcon--hover'
    );
    const assignedTo =
      this.props.appStore.customerStore &&
      this.props.appStore.customerStore.customer &&
      this.props.appStore.customerStore.customer.AssignedTo;
    const assignees = assignedTo !== null ? assignedTo : [];

    return (
      <MobxForm className="pushDealSelectCustomer__container" onSubmit={this.onSubmit} resetFunction={this.getReset}>
        <div className="pushDealSelectCustomer__customerSection">
          <div className="pushDealSelectCustomer__sectionInner">
            <div className="pushDealSelectCustomer__customerSearch">
              <div className="pushDealSelectCustomer__formContainer">
                <div className="pushDealSelectCustomer__label">
                  {this.props.t('PushDealSelectCustomer.find_or_add_customer')}
                </div>
                <div
                  className="pushDealSelectCustomer__inputSection"
                  onMouseEnter={this.handleMouseEnter}
                  onMouseLeave={this.handleMouseLeave}
                  onFocus={this.handleFocus}
                >
                  <div className={prefixClassnames}>
                    <Icon name="search" />
                  </div>
                  <TextInput
                    selectCustomerStyles={true}
                    className="pushDealSelectCustomer__input"
                    disabled={!!this.state.customer.id}
                    id="customerEmail"
                    onChange={this.handleEmailSearchChange}
                    isHovered={this.state.hasMouseOver}
                    hasFocus={this.state.hasFocus}
                    placeHolder={this.props.t('PushDealSelectCustomer.customer_email')}
                    value={this.state.searchEmail}
                    onBlur={this.handleBlur}
                  />
                  {!!this.state.customer.id && (
                    <button className={suffixClassnames} onClick={this.resetCustomerInfo} type="button">
                      <Icon name="bin" />
                    </button>
                  )}

                  {this.state.searchEmail.length > 0 && !this.state.isNewCustomer && this.state.customer.id === '' && (
                    <div className="pushDealSelectCustomer__suggestionsContainer">
                      <div
                        className="pushDealSelectCustomer__addNewCustomerListItem"
                        onClick={this.handleAddNewCustomer}
                      >
                        {this.props.t('PushDealSelectCustomer.add_new_customer')}
                      </div>
                      {suggestedCustomers.length <= 0 && (
                        <div className="pushDealSelectCustomer__noCustomersFound">
                          {this.props.t('PushDealSelectCustomer.no_customers_found')}
                        </div>
                      )}

                      {suggestedCustomers.map((customer, i) => {
                        if (!!customer.Consumer.Email) {
                          return (
                            <div
                              key={i}
                              className="pushDealSelectCustomer__listItem"
                              onClick={() => this.handleSaveSuggestedCustomer(customer)}
                            >
                              <div className="pushDealSelectCustomer__customerName">
                                {customer.Consumer.Firstname} {customer.Consumer.Surname}
                              </div>
                              <div className="pushDealSelectCustomer__suggestedCustomerEmail">
                                {customer.Consumer.Email}
                              </div>
                            </div>
                          );
                        } else {
                          return [];
                        }
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {this.state.customer.id !== '' && (
              <div className="pushDealSelectCustomer__customerCard">
                <div onClick={this.resetCustomerInfo} className="pushDealSelectCustomer__binIcon">
                  <Icon name="validation-cross" />
                </div>
                <div className="pushDealSelectCustomer__customerInfo">
                  <div className="pushDealSelectCustomer__customerName">
                    {`${this.state.customer.firstname} ${this.state.customer.surname}`}
                  </div>
                  <div className="pushDealSelectCustomer__customerEmail">{this.state.customer.email}</div>
                  {this.props.appStore.customerStore.customer &&
                    this.props.appStore.customerStore.customer !== undefined && (
                      <div className="pushDealSelectCustomer__customerAssign">
                        <AssignLeadContainer
                          size="small"
                          dealershipId={this.props.dealershipId}
                          customerId={this.state.customer.id}
                          customerName={`${this.state.customer.firstname} ${this.state.customer.surname}`}
                          customerType="consumer"
                          initialAssignees={this.parseAssigned(assignees)}
                        />
                      </div>
                    )}
                </div>
              </div>
            )}

            {this.state.isNewCustomer === true && (
              <div className="pushDealSelectCustomer__newCustomerCard">
                <div onClick={this.resetCustomerInfo} className="pushDealSelectCustomer__binIcon">
                  <Icon name="validation-cross" />
                </div>

                <div className="pushDealSelectCustomer__newCustomerInfo">
                  <div className="pushDealSelectCustomer__nameFields">
                    <div className="pushDealSelectCustomer__nameInput">
                      <MobxFieldGroup error={errors.firstname}>
                        <MobxFormLabel>{this.props.t('PushDealSelectCustomer.first_name')}</MobxFormLabel>
                        <TextInput
                          id="firstname"
                          value={this.state.customer.firstname}
                          onChange={this.handleFirstnameInputChange}
                        />
                      </MobxFieldGroup>
                    </div>
                    <div className="pushDealSelectCustomer__nameInput">
                      <MobxFieldGroup error={errors.surname}>
                        <MobxFormLabel>{this.props.t('PushDealSelectCustomer.last_name')}</MobxFormLabel>
                        <TextInput
                          id="surname"
                          value={this.state.customer.surname}
                          onChange={this.handleSurnameInputChange}
                        />
                      </MobxFieldGroup>
                    </div>
                  </div>
                  <div className="pushDealSelectCustomer__emailInput">
                    <MobxFieldGroup error={errors.email}>
                      <MobxFormLabel>{this.props.t('PushDealSelectCustomer.email_address')}</MobxFormLabel>
                      <TextInput id="email" value={this.state.customer.email} onChange={this.handleEmailInputChange} />
                    </MobxFieldGroup>
                  </div>
                  {this.props.appStore.uiState.canAssign && (
                    <div className="pushDealSelectCustomer__assignCheck">
                      <CheckboxWithLabel
                        id="assignToSelfPusDeal"
                        value={this.state.customer.assignToSelf}
                        onChange={this.setAssignToSelf}
                      >
                        {this.props.t('PushDealSelectCustomer.assign_this_lead_to_me')}
                      </CheckboxWithLabel>
                    </div>
                  )}
                  {this.state.isExistingCustomer && (
                    <div className="pushDealSelectCustomer__useExistingCustomer">
                      <div className="pushDealSelectCustomer__customerMessage">
                        {this.props.t('PushDealSelectCustomer.this_email_already_exists_for_another_customer')}
                      </div>
                      <Button
                        className="pushDealSelectCustomer__useExistingCustomerButton"
                        buttonStyle="secondary"
                        size="small"
                        onClick={this.useExistingCustomer}
                      >
                        {this.props.t('PushDealSelectCustomer.use_existing_customer')}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {createCustomerError && (
          <div class="pushDealSelectCustomer__error">
            {this.props.t('PushDealSelectCustomer.there_was_an_error_creating_the_customer')}
          </div>
        )}
        <FormFooter
          submitDisabled={
            !this.state.customer.firstname ||
            !this.state.customer.surname ||
            !this.state.customer.email ||
            !!this.state.isExistingCustomer
          }
          submitLabel={this.props.t('PushDealSelectCustomer.generate_quotes')}
          isSubmitting={createCustomerIsLoading}
          hasSubmittingError={createCustomerError}
          onCancel={this.onCancel}
          cancelLabel={this.props.t('PushDealSelectCustomer.back')}
          trackingPage="PushDealSelectCustomer"
        />
      </MobxForm>
    );
  }
}

export default withTranslation('PushDeal')(inject('appStore')(observer(PushDealSelectCustomer)));
