import * as actionTypes from '../actionTypes';

const defaultState = {
  vehicle: null,
  editingVehicle: null,
  submittingState: '',
  submittingExistingCustomerState: '',
  createCorporateApplicationState: ''
};

const quickQuoteReducer = function(state = defaultState, action) {
  let newState;

  switch (action.type) {
    case actionTypes.CREATE_QUICK_QUOTE_APPLICATION:
    case actionTypes.SAVE_QUICK_QUOTE_APPLICATION:
    case actionTypes.SAVE_CORPORATE_QUICK_QUOTE:
      return Object.assign({}, state, { submittingState: 'loading' });

    case actionTypes.CREATE_QUICK_QUOTE_APPLICATION_SUCCESS:
    case actionTypes.SAVE_QUICK_QUOTE_APPLICATION_SUCCESS:
    case actionTypes.SAVE_CORPORATE_QUICK_QUOTE_SUCCESS:
      return Object.assign({}, state, { submittingState: 'done' });

    case actionTypes.CREATE_QUICK_QUOTE_APPLICATION_ERROR:
    case actionTypes.SAVE_QUICK_QUOTE_APPLICATION_ERROR:
    case actionTypes.SAVE_CORPORATE_QUICK_QUOTE_ERROR:
      return Object.assign({}, state, { submittingState: 'error' });

    case actionTypes.CREATE_QUICK_QUOTE_APPLICATION_EXITING_CUSTOMER:
      return Object.assign({}, state, { submittingExistingCustomerState: 'loading' });

    case actionTypes.CREATE_QUICK_QUOTE_APPLICATION_EXITING_CUSTOMER_SUCCESS:
      return Object.assign({}, state, { submittingExistingCustomerState: 'done' });

    case actionTypes.CREATE_QUICK_QUOTE_APPLICATION_EXITING_CUSTOMER_ERROR:
      return Object.assign({}, state, { submittingExistingCustomerState: 'error' });

    case actionTypes.CHANGE_QUICK_QUOTE_VEHICLE:
      newState = Object.assign({}, state, { vehicle: action.payload.vehicle });
      newState.editingVehicle = null;
      return newState;

    case actionTypes.SET_QUICK_QUOTE_EDITING_VEHICLE:
      newState = Object.assign({}, state);
      newState.editingVehicle = newState.vehicle;
      return newState;

    case actionTypes.RESET_QUICK_QUOTE_EDITING_VEHICLE:
      newState = Object.assign({}, state);
      newState.editingVehicle = null;
      return newState;

    case actionTypes.CHANGE_QUICK_QUOTE_QUOTE:
      newState = Object.assign({}, state, { quote: action.payload.chosenQuote });
      return newState;

    case actionTypes.END_QUOTING:
      return Object.assign({}, state, {
        vehicle: {
          ...state.vehicle
        }
      });

    case actionTypes.CREATE_CORPORATE_QUICK_QUOTE_APPLICATION:
      return Object.assign({}, state, { createCorporateApplicationState: 'loading' });

    case actionTypes.CREATE_CORPORATE_QUICK_QUOTE_APPLICATION_SUCCESS:
      return Object.assign({}, state, { createCorporateApplicationState: 'done' });

    case actionTypes.CREATE_CORPORATE_QUICK_QUOTE_APPLICATION_ERROR:
      return Object.assign({}, state, { createCorporateApplicationState: 'error' });

    case actionTypes.SET_QUICK_QUOTE_VEHICLE_DERIVATIVES:
      newState = Object.assign({}, state);
      newState.derivatives = action.payload.derivatives;
      return newState;

    default:
      return state;
  }
};
export default quickQuoteReducer;
