import PropTypes from 'prop-types';
import Button from '../Common/Button/Button';
import { useTranslation } from 'react-i18next';
import './permissionDeniedMessage.scss';

const PermissionDeniedMessage = ({ children, header, buttonLabel, buttonStyle, buttonTo }) => {
  const { t } = useTranslation('Permissions');
  const headerWithDefault = header || t('PermissionDeniedMessage.permission_denied');
  const buttonLabelWithDefault = buttonLabel || t('PermissionDeniedMessage.return_to_customer_record');
  return (
    <div className="permissionDeniedMessage">
      <div className="permissionDeniedMessage__message">
        <h1 className="permissionDeniedMessage__heading">{headerWithDefault}</h1>
        <div className="permissionDeniedMessage__body">{children}</div>
        <div className="permissionDeniedMessage__button">
          <Button buttonStyle={buttonStyle} to={buttonTo}>
            {buttonLabelWithDefault}
          </Button>
        </div>
      </div>
    </div>
  );
};

PermissionDeniedMessage.defaultProps = {
  buttonStyle: 'primary'
};

PermissionDeniedMessage.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
  buttonTo: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonStyle: PropTypes.oneOf(['primary', 'secondary', 'cancel'])
};
export default PermissionDeniedMessage;
