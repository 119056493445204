import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Page from '../../Common/Page';
import WebFinanceApplications from './WebFinanceApplications';
import Breadcrumbs from '../../Common/Breadcrumbs';
import DashboardLeadsFilter from './DashboardLeadsFilter';

const FinanceApplications = (props) => {
  const { t } = useTranslation('Dashboard');

  return (
    <Page>
      <Breadcrumbs
        items={[
          {
            name: t('FinanceApplications.home'),
            path: `/d/${props.dealershipId}`
          },
          {
            name: t('FinanceApplications.finance_applications')
          }
        ]}
      />
      <div key="cont">
        <DashboardLeadsFilter onClick={props.filterFinanceApplicationsLeads} filter={props.activeFilter} />
        <div className="financeApplicationPage__container">
          <WebFinanceApplications
            items={props.items}
            onFilter={props.filterByMatchRate}
            filter={props.matchRate}
            handleClick={props.viewConsumer}
            totalItems={props.total}
            currentPage={props.page}
            pageSize={props.pageSize}
            changePage={props.changePage}
          />
        </div>
      </div>
    </Page>
  );
};

FinanceApplications.propTypes = {
  session: PropTypes.object.isRequired,
  dashboard: PropTypes.object,
  options: PropTypes.object,
  dealershipId: PropTypes.string,
  fetchFinanceApplications: PropTypes.func,
  financeApplications: PropTypes.object,
  filterFinanceApplications: PropTypes.func,
  filterFinanceApplicationsLeads: PropTypes.func,
  filterByMatchRate: PropTypes.func,
  viewConsumer: PropTypes.func,
  changePage: PropTypes.func,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  activeFilter: PropTypes.string,
  items: PropTypes.array,
  matchRate: PropTypes.string,
  total: PropTypes.number,
  pageSize: PropTypes.number
};

export default FinanceApplications;
