import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import './marketingPage.scss';
import marketingPageImage from '../../../styles/icons/marketingPageImage.png';
import TrackPageView from '../../Common/Tracking/TrackPageView';
import { useParams } from 'react-router-dom';

const MarketingPage = () => {
  const params = useParams();
  const { t } = useTranslation('DigitalReport');
  return (
    <Page>
      <TrackPageView dealershipId={params.dealershipId} />
      <Breadcrumbs
        items={[
          {
            name: t('MarketingPage.home'),
            path: `/d/${params.dealershipId}`,
            isHome: true
          },
          {
            name: t('MarketingPage.reporting'),
            path: `/d/${params.dealershipId}/reporting`
          },
          {
            name: t('MarketingPage.online_retailing_report')
          }
        ]}
      />
      <Panel>
        <PanelHeader>{t('MarketingPage.online_retailing_report')}</PanelHeader>
        <PanelContent>
          <div className="marketingPage__outer">
            <div className="marketingPage__header">
              {t('MarketingPage.the_online_retailing_report_is_not_currently_enabled_for_your_account')}
            </div>
            <div className="marketingPage__image">
              <img src={marketingPageImage} alt="" />
            </div>
            <div className="marketingPage__questions">
              <ul className="marketingPage__ul">
                <li>
                  <span className="marketingPage__span">
                    {t('MarketingPage.want_to_test_your_understanding_of_your_busiest_online_activity')}
                  </span>
                </li>
                <li>
                  <span className="marketingPage__span">
                    {t('MarketingPage.want_to_know_the_volume_of_finance_impressions')}
                  </span>
                </li>
                <li>
                  <span className="marketingPage__span">
                    {t('MarketingPage.want_to_know_the_volume_of_quotes_delivered')}
                  </span>
                </li>
              </ul>
              <div className="marketingPage__bottom">
                <div>{t('MarketingPage.enable_retailing_report_upsell')}</div>
                <div className="marketingPage__number">{t('MarketingPage.call_now_on_0345_226_0503')}</div>
              </div>
            </div>
          </div>
        </PanelContent>
      </Panel>
    </Page>
  );
};

MarketingPage.propTypes = {
  dealership: PropTypes.object
};
export default MarketingPage;
