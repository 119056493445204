import React from 'react';
import PropTypes from 'prop-types';
import './applicationNavigationWrapper.scss';

const ApplicationNavigationWrapper = ({ children }) => (
  <div className="applicationNavigationWrapper" key="wrapper">
    {children}
  </div>
);

ApplicationNavigationWrapper.propTypes = {
  children: PropTypes.node
};

export default ApplicationNavigationWrapper;
