import React from 'react';
import PropTypes from 'prop-types';
import './alignRight.scss';

const AlignRight = ({ children }) => <div className="alignRight">{children}</div>;

AlignRight.propTypes = {
  children: PropTypes.node
};

export default AlignRight;
