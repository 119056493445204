import platformApiUtils from '@ivendi/platform-api-utils';
import { getSessionId } from '../../sessionId';
import { INITIALIZE_APP } from '../actionTypes';
import { endSession } from '../session/sessionActions';
import * as debug from '../../debug';
import i18n from '../../i18n';

export function initialize(dealershipId) {
  return (dispatch) => {
    platformApiUtils.config({
      sessionId: getSessionId(),
      clientApp: 'ivendi-dealer-platform',
      clientAppVersion: '2',
      baseUrl: '/platform/',
      language: i18n.language,
      onResponse: (response) => {
        if (isUnauthorized(response, dealershipId)) {
          dispatch(endSession());
        }
      },
      debug: (log) => {
        log.path = log.path.replace('/platform', '');
        debug.log(log);
      }
    });

    dispatch({
      type: INITIALIZE_APP
    });
  };
}

function isUnauthorized(response, dealershipId) {
  const whiteList = [
    '/platform/v1/sign-in',
    `/platform/v1/reserve-now/${dealershipId}/settings`,
    `/platform/v1/${dealershipId}/reserve-now/rnapi/dealer/${dealershipId}?page=1&pagesize=3&reservationstatus=overdue`,
    `/platform/v1/${dealershipId}/reserve-now/rnapi/dealer/${dealershipId}?page=1&pagesize=3&reservationstatus=active`
  ];
  return response.status === 401 && whiteList.indexOf(response.path) === -1;
}
