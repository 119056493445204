import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import ApplicationPage from './ApplicationPage';
import AffordabilityForm from '../forms/AffordabilityForm';
import { withOutletContextProps, withParams } from 'hocs/router';

class Affordability extends React.Component {
  static propTypes = {
    params: PropTypes.object,
    application: PropTypes.object,
    applicationFields: PropTypes.object,
    validationRules: PropTypes.object,
    submitApplicationSection: PropTypes.func,
    saveApplicationSection: PropTypes.func
  };
  handleSave = (formData) => {
    this.props.saveApplicationSection('AffordabilityDetails', this.props.params.applicantId, formData);
  };
  handleSubmit = (formData) => {
    const { dealershipId, consumerId, applicantId } = this.props.params;
    this.props.submitApplicationSection(
      'AffordabilityDetails',
      this.props.params.applicantId,
      formData,
      `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/applicationsummary`
    );
  };

  render() {
    return (
      <ApplicationPage title={this.props.t('Affordability.affordability')}>
        <AffordabilityForm
          defaultValues={this.props.application.AffordabilityDetails}
          onSubmit={this.handleSubmit}
          onSave={this.handleSave}
          validationRules={this.props.validationRules.AffordabilityDetails}
          applicationFields={this.props.applicationFields.AffordabilityDetails}
          savingState={this.props.application.sectionSavingState}
          submittingState={this.props.application.sectionSubmittingState}
          funderCode={this.props.application.Quote.FunderCode}
        />
      </ApplicationPage>
    );
  }
}

export default withParams(withOutletContextProps(withTranslation('Application')(Affordability)));
