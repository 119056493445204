import platformApiUtils from '@ivendi/platform-api-utils';
import { action, observable } from 'mobx';
import * as debug from '../debug';

class SentDealsStore {
  @observable isSentDealsLoading = false;
  @observable sentDealsData = {};
  @observable sentDealsError = false;
  @observable sentDealsTileData = {
    Count: 0,
    Time: 'thisMonth',
    Type: 'deals'
  };

  @action
  getSentDealsData = (dealershipId, page, pageSize, dealStatus, sortBy, date) => {
    this.isSentDealsLoading = true;
    return platformApiUtils
      .get(
        `vnext/Dealership/${dealershipId}/dealsv2?dealStatus=${dealStatus}&sortBy=${sortBy}&pageNumber=${page}&pageSize=${pageSize}` //&date=${date}
      )
      .then((response) => {
        this.onSentDealsSuccess(response);
      })
      .catch((error) => {
        this.onSentDealsError(error);
      });
  };

  @action
  onSentDealsSuccess = (data) => {
    this.sentDealsData = data;
    this.isSentDealsLoading = false;
  };

  @action
  resetSentDeals = () => {
    this.sentDealsData = {};
  };

  @action
  onSentDealsError = (error) => {
    this.sentDealsError = true;
    this.isSentDealsLoading = false;
  };

  @action
  getSentDealsCount = (dealershipId) => {
    platformApiUtils
      .get(`vnext/Dealership/${dealershipId}/monthlyDeals`)
      .then((response) => {
        this.getSentDealsCountSuccess(response);
      })
      .catch((error) => {
        debug.error(`dashboard getSentDealsCount ${error}`);
      });
  };

  @action
  getSentDealsCountSuccess = (response) => {
    this.sentDealsTileData.Count = response.dealsSentCount;
  };
}

export default SentDealsStore;
