import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import AddConsumerForm from './AddConsumerForm';
import AddCorporateForm from './AddCorporateForm';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { withParams } from '../../../hocs/router';

class AddCustomer extends React.Component {
  @observable customerType;

  constructor(props) {
    super();
    this.customerType = props.defaultCustomerType;
  }

  handleCustomerTypeChange = (customerType) => {
    this.customerType = customerType;
  };

  render() {
    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: this.props.t('AddCustomer.home'),
              path: `/d/${this.props.params.dealershipId}`
            },
            {
              name: this.props.t('AddCustomer.customer_list'),
              path: `/d/${this.props.params.dealershipId}/consumers`
            },
            {
              name: this.props.t('AddCustomer.add_customer')
            }
          ]}
          consumer={this.props.customer}
        />

        <Panel>
          <PanelHeader>{this.props.t('AddCustomer.add_customer_contact')}</PanelHeader>
          <PanelContent>
            {this.customerType === 'consumer' ? (
              <AddConsumerForm
                dealershipId={this.props.params.dealershipId}
                onCustomerTypeChange={this.handleCustomerTypeChange}
              />
            ) : (
              <AddCorporateForm
                dealershipId={this.props.params.dealershipId}
                onCustomerTypeChange={this.handleCustomerTypeChange}
              />
            )}
          </PanelContent>
        </Panel>
      </Page>
    );
  }
}

AddCustomer.propTypes = {
  customerType: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  handleCustomerTypeChange: PropTypes.func,
  params: PropTypes.object.isRequired,
  customer: PropTypes.object,
  submittingState: PropTypes.string,
  defaultCustomerType: PropTypes.string
};

export default withParams(withTranslation('AddCustomer')(observer(AddCustomer)));
