import './helpNoVideoBDK.scss';

const HelpNoVideoBDK = () => {
  return (
    <div>
      <div className="helpNoVideoBDK__header">
        Online-Guide -{' '}
        <a href="https://documentation.ivendi.com/docs/BDK-Guide" target="_blank">
          BDK / iVendi Guide
        </a>
      </div>
      <div className="helpNoVideoBDK__header__noborder">
        BDK Händlerservice - <a href="tel:040 48091-7777">040 48091-7777</a>
      </div>
      <div className="helpNoVideoBDK__body">(Mo-Fr 08:00 - 18:00)</div>
      <a href="mailto:digitalsupport@bdk-bank.de">digitalsupport@bdk-bank.de</a>
    </div>
  );
};

export default HelpNoVideoBDK;
