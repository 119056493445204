import platformApiUtils from '@ivendi/platform-api-utils';
import { Provider as MobxStoreProvider } from 'mobx-react';
import moment from 'moment';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import PushDealContainer from '../PushDeal/PushDealContainer';

const PushDealWidget = ({ appStore, user, dealershipId }) => {
  const [dealership, setDealership] = React.useState();
  React.useEffect(() => {
    platformApiUtils.get('v1/dealerships/' + dealershipId).then(setDealership);
  }, [dealershipId]);
  if (!dealership) {
    return null;
  }
  return (
    <PushDealContainer
      appStore={appStore}
      entry="with-finance"
      email={user ? user.email : undefined}
      dealership={{ ...dealership, Id: dealershipId }}
      hideBreadcrumbs
    />
  );
};

const renderPushDealAction = ({ appStore, store, elementId, dealershipId, user, vehicles }) => {
  const vehicle = vehicles[0];
  appStore.pushDealStore.setVehicle(
    {
      ...vehicle,
      Condition: vehicle.condition ? vehicle.condition : '',
      Class: vehicle.class ? vehicle.class : '',
      Vrm: vehicle.vrm ? vehicle.vrm : '',
      CapId: vehicle.capId ? vehicle.capId : '',
      Make: vehicle.make ? vehicle.make : '',
      Model: vehicle.model ? vehicle.model : '',
      Derivative: vehicle.derivative ? vehicle.derivative : '',
      RegistrationDate: vehicle.registrationDate ? moment(new Date(vehicle.registrationDate)).format('DD/MM/YYYY') : '',
      Mileage: vehicle.mileage ? vehicle.mileage : ''
    },
    vehicle.price,
    undefined,
    10000,
    48,
    dealershipId
  );
  ReactDOM.render(
    <Suspense fallback={<></>}>
      <Provider store={store}>
        <MobxStoreProvider appStore={appStore}>
          <PushDealWidget appStore={appStore} vehicle={vehicle} dealershipId={dealershipId} user={user} />
        </MobxStoreProvider>
      </Provider>
    </Suspense>,
    document.getElementById(elementId)
  );
};

export const appendHubWidgets = (appStore, store) => {
  if (!window.location.href.includes('hub-push-deal=true') || window.location.href.includes('.com')) {
    return;
  }
  window.PushDeal = {
    getHubEventActions: (event, locale) => {
      if (!appStore.uiState.canUsePushDeal) {
        return [];
      }
      return [
        {
          id: 'send-deal',
          label: 'Send deal',
          type: 'secondary',
          handler: 'render'
        }
      ];
    },
    handleHubEventAction: (event, action, widgetProps) => {
      if (action.id === 'send-deal') {
        renderPushDealAction({ ...widgetProps, ...event, appStore, store });
      }
    }
  };
};
