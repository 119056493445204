import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import LoadingSpinner from '../../Common/Loading/LoadingSpinner';
import Modal from '../../Common/Modal/Modal';
import './reserveTermsModal.scss';

@inject('appStore')
@observer
class ReserveTermsModal extends React.Component {
  render() {
    return (
      <Modal isOpen={this.props.isOpen} onClose={this.props.handleClose} title={this.props.title} buttonText="Accept">
        {this.props.appStore.legalDocumentStore.reserveConditions &&
        this.props.appStore.legalDocumentStore.reserveConditions.html ? (
          <div
            className="reserveTermsModal__terms"
            dangerouslySetInnerHTML={{
              __html: this.props.appStore.legalDocumentStore.reserveConditions.html
            }}
          />
        ) : (
          <div className="reserveTermsModal__loading">
            <LoadingSpinner />
          </div>
        )}
      </Modal>
    );
  }
}

ReserveTermsModal.defaultProps = {
  isOpen: false
};

ReserveTermsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};
export default ReserveTermsModal;
