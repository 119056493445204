import React from 'react';
import PropTypes from 'prop-types';
import Image from './Image';
import logo from '../../styles/icons/cfc-logo.svg';
import './pageTitle.scss';

const PageTitle = ({ text }) => {
  return (
    <div className="pageTitle">
      {text === 'CFC Prospects' ? (
        <div className="pageTitle__logo">
          <Image imageName={logo} />
        </div>
      ) : (
        <span>{text}</span>
      )}
    </div>
  );
};

PageTitle.propTypes = {
  text: PropTypes.string
};

export default PageTitle;
