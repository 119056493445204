import { withTranslation } from 'react-i18next';
import clone from 'lodash/clone';
import set from 'lodash/set';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import Validator from '../../../validators/Validator';
import CurrencyInput from '../../Common/Form/CurrencyInput';
import FormFooter from '../../Common/Form/FormFooter';
import SelectInput from '../../Common/Form/SelectInput';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import './globalDefaultSettingsForm.scss';
import { hasExtendedTermLength } from '~/utils/quoteUtils';

class GlobalDefaultSettingsForm extends React.Component {
  validationRules = {
    VehicleType: 'required',
    UsageType: 'required',
    VehicleClass: 'required',
    PreferredTerm: 'required',
    DepositValue: 'number',
    DefaultAnnualMileage: 'required, number'
  };
  defaultValues = {
    VehicleType: 'used',
    UsageType: 'private',
    VehicleClass: 'car',
    PreferredTerm: '48',
    DepositValue: '',
    DefaultAnnualMileage: 10000
  };
  state = {
    formData: this.defaultValues
  };
  setInitialFormData = (data) => {
    this.setState({
      formData: data
    });
  };

  setupValidation(validationRules) {
    this.validator = new Validator();
    this.validator.setRules(validationRules);
    this.validationReactionDisposer = reaction(() => ({ ...this.state.formData }), this.validator.validate, {
      fireImmediately: true
    });
  }

  UNSAFE_componentWillMount() {
    this.setInitialFormData(this.props.defaultValues);
    this.setupValidation(this.validationRules);
  }

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  onValidationSuccess() {
    this.props.onSubmit(this.state.formData);
  }

  submitForm = () => {
    this.validator.validate(this.state.formData);

    if (!this.validator.errorCount) {
      this.onValidationSuccess();
    }
  };
  onChangeField = (id, value) => {
    const oldState = clone(this.state.formData);
    const newState = set(oldState, id, value);
    this.validator.validate(newState);
    this.setState({
      formData: newState
    });
  };

  render() {
    const vehicleClass = this.state.formData.VehicleClass;
    const extendedTermLength = hasExtendedTermLength(vehicleClass);
    const errors = this.validator.getErrors();
    return (
      <MobxForm onSubmit={this.submitForm} className="globalDefaultSettingsForm">
        <div className="globalDefaultSettingsForm__inner" key="inner">
          <MobxFieldGroup isInline error={errors.VehicleType}>
            <MobxFormLabel>{this.props.t('GlobalDefaultSettingsForm.we_primarily_sell')}</MobxFormLabel>
            <SelectInput
              id="VehicleType"
              options="VehicleType"
              value={this.state.formData.VehicleType}
              onChange={this.onChangeField}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.UsageType}>
            <MobxFormLabel>{this.props.t('GlobalDefaultSettingsForm.we_primarily_sell_to')}</MobxFormLabel>
            <SelectInput
              id="UsageType"
              options="BuyerType"
              value={this.state.formData.UsageType}
              onChange={this.onChangeField}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.VehicleClass}>
            <MobxFormLabel>{this.props.t('GlobalDefaultSettingsForm.vehicle_class')}</MobxFormLabel>
            <SelectInput
              id="VehicleClass"
              options="VehicleClass"
              value={this.state.formData.VehicleClass}
              onChange={this.onChangeField}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.PreferredTerm}>
            <MobxFormLabel>{this.props.t('GlobalDefaultSettingsForm.preferred_term')}</MobxFormLabel>
            <SelectInput
              id="PreferredTerm"
              options={extendedTermLength ? 'PreferredTermExtended' : 'PreferredTerm'}
              value={this.state.formData.PreferredTerm}
              onChange={this.onChangeField}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.DepositValue}>
            <MobxFormLabel>{this.props.t('GlobalDefaultSettingsForm.deposit_value')}</MobxFormLabel>
            <CurrencyInput
              id="DepositValue"
              value={this.state.formData.DepositValue}
              onChange={(val) => this.onChangeField('DepositValue', val)}
            />
          </MobxFieldGroup>
          <MobxFieldGroup isInline error={errors.DefaultAnnualMileage}>
            <MobxFormLabel>{this.props.t('GlobalDefaultSettingsForm.annual_mileage')}</MobxFormLabel>
            <SelectInput
              id="DefaultAnnualMileage"
              options="MileageOptions"
              value={this.state.formData.DefaultAnnualMileage}
              onChange={this.onChangeField}
            />
          </MobxFieldGroup>
        </div>
        <div className="globalDefaultSettingsForm__buttonContainer" key="buttonContainer">
          <FormFooter
            onCancel={this.props.onCancel}
            submittingState={this.props.submittingState}
            savingState={this.props.savingState}
            submitDisabled={this.validator.errorCount}
            submitLabel={this.props.t('GlobalDefaultSettingsForm.save')}
          />
        </div>
      </MobxForm>
    );
  }
}

GlobalDefaultSettingsForm.propTypes = {
  dispatch: PropTypes.func,
  dealership: PropTypes.object,
  onCancel: PropTypes.func
};

export default withTranslation('DealershipSettings')(inject(['appStore'])(observer(GlobalDefaultSettingsForm)));
