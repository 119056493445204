import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import StarRating from '~Common/Form/StarRating';
import Icon from '~Common/Icon/Icon';
import cn from 'classnames';

import classes from './stockListItemDataAccuracy.module.scss';

const StockListItemDataAccuracy = ({ activeStars, onClick, className }) => {
  const { t } = useTranslation('Stock');
  return (
    <div className={cn(classes.container, className)} onClick={onClick} data-th="StockListItemDataAccuracy">
      <div className={classes.top}>
        <p className={cn(classes.text, 'stockListItemDataAccuracy_text')}>
          {t('StockListItemDataAccuracy.data_accuracy')}
        </p>
        <div className={classes.icon}>
          <Icon name="question" />
        </div>
      </div>
      <span className={classes.rating}>
        <StarRating
          className={cn(classes.starRating, 'stockListItemDataAccuracy_star')}
          stars={3}
          active={activeStars}
        />
      </span>
    </div>
  );
};

StockListItemDataAccuracy.propTypes = {
  activeStars: PropTypes.number,
  onClick: PropTypes.func
};
export default StockListItemDataAccuracy;
