import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { inject } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LoadingSpinnerTailSpin from '../../../Common/Loading/LoadingSpinnerTailSpin';
import HelpNotification from '../../../Common/HelpNotification';
import ProductHeader from './MonthlyPriceViewerProductHeader';
import MonthlyPriceViewerPaymentsRow from './MonthlyPriceViewerPaymentsRow';
import CollapsibleCardInformation from '../../../Common/CollapsibleCardInformation';
import CollapsibleCardInformationItem from '../../../Common/CollapsibleCardInformationItem';
import CardInformationItem from '../../../Common/CardInformationItem';
import MoneyFormat from '../../../Common/MoneyFormat';
import MonthlyPriceTable from './MonthlyPriceTable';
import { formatNumber } from '../../../../core/helpers';
import { canSeeCashback } from 'features';
import './monthlyPriceViewer.scss';

const terms = [12, 18, 24, 27, 30, 33, 36, 39, 42, 45, 48, 51, 54, 57, 60];

class MonthlyPriceViewer extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.updateMonthlyPayments();
  }

  render() {
    let {
      isLoading,
      hasError,
      monthlyPayments,
      quoteDetails,
      onTermClick,
      onPaymentClick,
      vehicleCondition,
      quotes
    } = this.props;
    let hasAccessories =
      quoteDetails.Insurance || quoteDetails.Warranty || quoteDetails.NonVatableItems || quoteDetails.Other;
    let sortedVaps = _.orderBy(quoteDetails.ValueAddedProducts, 'ProductTextType');
    let vapsTotalValue = sortedVaps
      .map((vap) => vap.Price)
      .reduce((accumulator, current) => accumulator + parseFloat(current), 0);
    let groupedVaps = _.groupBy(sortedVaps, 'ProductTypeText');
    let netDeposit = quoteDetails.getNetDeposit();

    const isCombinedQuote = monthlyPayments.some((item) => item.combinedQuote === true);
    const hasQuotesFromCRE = (quotes.quotesForCompare || []).some((quote) => quote.FunderCode === 'CRE');
    const shouldShowCashback = canSeeCashback() && !hasQuotesFromCRE;

    return (
      <div className="monthlyPriceViewer">
        <div className="monthlyPriceViewer__quoteDetails">
          <CollapsibleCardInformation
            title={this.props.t('MonthlyPriceViewer.total_price')}
            headerInfo={quoteDetails.TotalPrice}
            formatter={MoneyFormat}
          >
            <CollapsibleCardInformationItem
              label={this.props.t('MonthlyPriceViewer.vehicle_price')}
              formatter={MoneyFormat}
              value={quoteDetails.VehiclePrice}
            />
            <CollapsibleCardInformationItem
              label={this.props.t('MonthlyPriceViewer.basic_price')}
              formatter={quoteDetails.VatQualifying && MoneyFormat}
              value={quoteDetails.VatQualifying ? quoteDetails.BasicPrice : this.props.t('MonthlyPriceViewer.n_a')}
            />
            <CollapsibleCardInformationItem
              label={this.props.t('MonthlyPriceViewer.vat_amount')}
              formatter={quoteDetails.VatQualifying && MoneyFormat}
              value={quoteDetails.VatQualifying ? quoteDetails.VatAmount || 0 : this.props.t('MonthlyPriceViewer.n_a')}
            />
            {hasAccessories && (
              <CollapsibleCardInformationItem
                label={this.props.t('MonthlyPriceViewer.non_va_table_items')}
                formatter={MoneyFormat}
                value={quoteDetails.NonVatableItems || 0}
              />
            )}
          </CollapsibleCardInformation>

          {hasAccessories ? (
            <CollapsibleCardInformation
              title={this.props.t('MonthlyPriceViewer.accessories')}
              headerInfo={quoteDetails.Accessories}
              formatter={MoneyFormat}
            >
              <CollapsibleCardInformationItem
                label={this.props.t('MonthlyPriceViewer.insurance')}
                formatter={MoneyFormat}
                value={quoteDetails.Insurance || 0}
              />
              <CollapsibleCardInformationItem
                label={this.props.t('MonthlyPriceViewer.warranty')}
                formatter={MoneyFormat}
                value={quoteDetails.Warranty || 0}
              />
              <CollapsibleCardInformationItem
                label={this.props.t('MonthlyPriceViewer.other')}
                formatter={MoneyFormat}
                value={quoteDetails.Other || 0}
              />
            </CollapsibleCardInformation>
          ) : (
            <CollapsibleCardInformation
              title={this.props.t('MonthlyPriceViewer.value_added_products')}
              headerInfo={vapsTotalValue}
              formatter={MoneyFormat}
            >
              {_.map(groupedVaps, (vapGroup, index) => {
                if (vapGroup.length > 1) {
                  return (
                    <div className="monthlyPriceViewer__vapGroup" key={index}>
                      <CollapsibleCardInformationItem label={`${vapGroup[0].ProductTypeText}:`} value="" />
                      {vapGroup.map((vap, key) => (
                        <CollapsibleCardInformationItem
                          label={`${vap.Name}:`}
                          formatter={MoneyFormat}
                          value={vap.Price || 0}
                          key={key}
                          subcategory
                        />
                      ))}
                    </div>
                  );
                }

                return (
                  <CollapsibleCardInformationItem
                    label={`${vapGroup[0].ProductTypeText}:`}
                    formatter={MoneyFormat}
                    value={vapGroup[0].Price || 0}
                    key={index}
                  />
                );
              })}
            </CollapsibleCardInformation>
          )}

          <CollapsibleCardInformation
            title={this.props.t('MonthlyPriceViewer.total_deposit')}
            headerInfo={netDeposit}
            formatter={MoneyFormat}
            warning={netDeposit < 0 && this.props.t('MonthlyPriceViewer.negative_equity')}
          >
            <CollapsibleCardInformationItem
              label={this.props.t('MonthlyPriceViewer.cash_deposit')}
              formatter={MoneyFormat}
              value={quoteDetails.CashDeposit || 0}
            />
            <CollapsibleCardInformationItem
              label={this.props.t('MonthlyPriceViewer.part_exchange_value')}
              formatter={MoneyFormat}
              value={quoteDetails.PartExchange || 0}
            />
            <CollapsibleCardInformationItem
              label={this.props.t('MonthlyPriceViewer.settlement_figure')}
              formatter={MoneyFormat}
              value={quoteDetails.OutstandingSettlement || 0}
            />
            {shouldShowCashback && (
              <CollapsibleCardInformationItem
                label={this.props.t('MonthlyPriceViewer.cashback')}
                formatter={MoneyFormat}
                value={quoteDetails.Cashback || 0}
              />
            )}
          </CollapsibleCardInformation>

          <CardInformationItem
            label={this.props.t('MonthlyPriceViewer.annual_mileage')}
            value={formatNumber(quoteDetails.AnnualDistance, 0, this.props.appStore.uiState.countryCode)}
          />

          <div className="monthlyPriceViewer__dropDownBalanceToChange">
            {this.props.t('MonthlyPriceViewer.balance_to_finance')}
            <div className="monthlyPriceViewer__dropDownItemPrice">
              <MoneyFormat value={quoteDetails.BalanceToChange} />
            </div>
          </div>
        </div>

        <div className="monthlyPriceViewer__helpNotification">
          <HelpNotification>
            {this.props.t(
              'MonthlyPriceViewer.regular_monthly_payments_for_each_selected_product_are_shown_below_we_recommend_that_you_present_the_full_product_details_to_the_customer_as_this_will_show_the_payment_schedule_along_with_details_of_the_final_payment_and_any_fees'
            )}
            {vehicleCondition === 'new' && (
              <div className="monthlyPriceViewer__newVehicleWarning">
                {this.props.t('MonthlyPriceViewer.currently_only_available_for_used_vehicles')}
              </div>
            )}
          </HelpNotification>
        </div>
        {(isCombinedQuote || monthlyPayments.length <= 1) && (
          <div className="monthlyPriceViewer__helpNotification">
            <HelpNotification>
              {this.props.t('MonthlyPriceViewer.price_position_is_not_available_for_combined_quotes')}
            </HelpNotification>
          </div>
        )}
        {isLoading && (
          <div className="monthlyPriceViewer__loadingSpinner">
            <LoadingSpinnerTailSpin />
          </div>
        )}

        {!isLoading && !hasError && monthlyPayments.length > 0 && (
          <MonthlyPriceTable>
            <tbody>
              <tr>
                <th className="monthlyPriceViewer__cell" />
                {monthlyPayments.map((product, headerIndex) => (
                  <ProductHeader
                    key={`productHeader-${headerIndex}`}
                    productName={product.ProductName}
                    funderCode={product.FunderCode}
                  />
                ))}
              </tr>
              <tr className="monthlyPriceViewer__columnHeader">
                <th className="monthlyPriceViewer__columnTitle">{this.props.t('MonthlyPriceViewer.requested_term')}</th>
                <th colSpan={monthlyPayments.length} className="monthlyPriceViewer__columnTitle">
                  {this.props.t('MonthlyPriceViewer.regular_monthly_payments')}
                </th>
              </tr>
              {terms.map((term, rowIndex) => (
                <MonthlyPriceViewerPaymentsRow
                  key={`paymentsRow-${rowIndex}`}
                  monthlyPayments={monthlyPayments}
                  term={term}
                  isLast={rowIndex === terms.length - 1}
                  onTermClick={onTermClick}
                  onPaymentClick={onPaymentClick}
                />
              ))}
            </tbody>
          </MonthlyPriceTable>
        )}

        {hasError && (
          <div className="monthlyPriceViewer__errorMessage">
            {this.props.t('MonthlyPriceViewer.error_fetching_monthly_payments')}
          </div>
        )}
      </div>
    );
  }
}

MonthlyPriceViewer.propTypes = {
  monthlyPayments: PropTypes.array,
  onChangeCostsClick: PropTypes.func,
  onReturnToListClick: PropTypes.func,
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
  onTermClick: PropTypes.func,
  onPaymentClick: PropTypes.func,
  updateMonthlyPayments: PropTypes.func,
  quoteDetails: PropTypes.object,
  vehicleCondition: PropTypes.string,
  quotes: PropTypes.object,
  appStore: PropTypes.object
};

export default withTranslation('Quoting')(inject(['appStore'])(MonthlyPriceViewer));
