import React, { ReactNode } from 'react';
import helpIcon from '../../styles/icons/i.svg';
import Image from './Image';
import './inlineExclamationWarning.scss';

interface InlineExclamationWarningProps {
  title?: string;
  message?: string;
  children?: ReactNode;
  dataTh?: string;
}

const InlineExclamationWarning = (props: InlineExclamationWarningProps) => {
  const { title, message, children, dataTh } = props;
  return (
    <div className="inlineExclamationWarning" data-th={dataTh}>
      <div className="inlineExclamationWarning__imageContainer">
        <div className="inlineExclamationWarning__image">
          <Image imageName={helpIcon} />
        </div>
      </div>
      <div className="inlineExclamationWarning__body">
        {title && <span className="inlineExclamationWarning__title">{title}</span>}
        {message && <span>{message}</span>}
        {children && <span>{children}</span>}
      </div>
    </div>
  );
};

export default InlineExclamationWarning;
