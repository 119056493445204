import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const UserName = ({ session }) => {
  return (
    <span>
      {session.Firstname} {session.LastName}
    </span>
  );
};

UserName.propTypes = {
  session: PropTypes.object
};

function mapStateToProps(state) {
  return {
    session: state.session
  };
}

export default connect(mapStateToProps)(UserName);
