import React  from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';
import { connect } from 'react-redux';
import { toJS } from 'mobx';
import MediaQuery from 'react-responsive';
import { cloneDeep } from 'lodash';
import { Dispatch } from 'redux';
import TrackEvent from '~Common/Tracking/TrackEvent';
import Button from '~Common/Button/Button';
import MoneyFormat from '~Common/MoneyFormat';
import * as quickQuoteActions from '~/redux/quickQuote/quickQuoteActions.js';
import AlgoliaVehicle from '~/mobx-models/AlgoliaVehicle';
import StockListItemDataAccuracy from './components/StockListItemDataAccuracyV2';
import { convertAccuracyScore } from './helpers/convertAccuracyScore';
import { getVehicleStatsString } from './helpers/getVehicleStatsString';
import VehicleImage from './components/VehicleImage';
import { GrayTag, RedTag } from '~Common/Tags';
import { AppStore } from '~SM/types/Types';
import { pushDeal } from './utils/pushDeal';
import DaysInStock from './components/VehicleMetrics/DaysInStock';
import VehicleMetric from './components/VehicleMetrics/VehicleMetric';
import { daysSinceGivenDate } from './helpers/daysSinceGivenDate';
import * as quoteActions from '~/redux/quote/quoteActions';
import s from './StockListItemV2.module.scss';
import { withNavigate } from '~/hocs/router';
import { useParams } from 'react-router-dom';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { formatVehicleObject } from '~/components/QuickQuote/components/utils';

interface Props {
  id: string;
  appStore: AppStore;
  vehicle: AlgoliaVehicle;
  navigate: typeof withNavigate;
  showDeleteModal: (vehicleId: string) => void;
  setIsDataAccuracyModalOpen: () => void;
  openDataIssueModal: (errors: string[]) => void;
  changeQuickQuoteVehicle: (vehicle: AlgoliaVehicle) => void;
  changeQuotingVehicle: (vehicle: AlgoliaVehicle) => void;
}
interface QuoteVehicle extends AlgoliaVehicle {
  imageSource?: string;
  DerivativeId?: string;
  MakeId?: string;
  ModelId?: string;
  CdnVehicleImageUrl?: string;
  Class: 'car' | 'lcv' | 'bike' | 'hgv' | 'motorhome' | 'van' | 'motorbike' | 'unknown';
}

const StockListItem = ({
  id,
  appStore,
  vehicle,
  navigate,
  setIsDataAccuracyModalOpen,
  openDataIssueModal,
  showDeleteModal,
  changeQuickQuoteVehicle
}: Props) => {
  const params = useParams();
  const { t, i18n } = useTranslation('Stock');
  const lng = i18n.language;

  const {
    canFixIssues,
    canAddVehicles,
    canGenerateQuotes,
    isBdk,
    canUsePushDeal,
    showVRM,
    countryCode,
    canViewDataAccuracy
  } = appStore.uiState;

  const hasDataIssues = vehicle?.AccuracyScore >= 2;
  const showVehicleCardMetrics = useFeatureIsOn('vim-913-vehicle-card-metrics');
  const shouldDisplayFixIssuesTag = canFixIssues && hasDataIssues;
  const trackingContext = { dealershipId: params.dealershipId, vrm: vehicle?.Vrm };

  const imageCount = React.useMemo(() => {
    return vehicle?.ImgUrls?.length ?? 0
  }, [vehicle.ImgUrls])

  const pushDealClick = () => {
    pushDeal({ vehicle, countryCode });
    window.scrollTo(0, 0);
    navigate({
      pathname: `/d/${params.dealershipId}/send-deal`,
      query: {
        entry: 'with-vehicle'
      }
    });
  };

  const handleScrollPosition = () => {
    appStore.stockStore.setStockListScrollPosition(document.querySelector('.globalLayout__content-inner')!.scrollTop);
    window.scrollTo(0, 0);
  };

  const navigateToFixIssuesPage = () => {
    vehicle.DealerPlatformUpload
      ? navigate(`/d/${params.dealershipId}/stock/edit/${vehicle.Id}/details`)
      : navigate(`/d/${params.dealershipId}/stock/${vehicle.Id}/fix-issues`);
    handleScrollPosition();
  };

  const navigateToAmendPage = () => {
    handleScrollPosition();
    navigate(`/d/${params.dealershipId}/stock/edit/${vehicle.Id}/details`);
  };

  const shouldDisplayDeleteTag = canAddVehicles && vehicle.DealerPlatformUpload;
  const hasValidationErrors = Array.isArray(vehicle?.ValidationErrorCodes) && vehicle.ValidationErrorCodes.length > 0;
  const vehicleErrors = hasValidationErrors
    ? vehicle.ValidationErrorCodes.map((errorKey: string) => t(`StockListItem.${errorKey}`))
    : null;

  const quoteVehicle = () => {
    changeQuickQuoteVehicle(vehicle);
    navigate({
      pathname: `/d/${params.dealershipId}/quickquote/results`
    });
  };

  //  No translation for Transact needed as it's a product name
  const vehicleSourceString = `${t('KeyInformationSection.source')}: ${vehicle?.DealerPlatformUpload
      ? t('StockListItem.dealer_created')
      : vehicle?.MediaCompanyName
        ? vehicle?.MediaCompanyName
        : t('StockListItem.unknown')
    }`;

  const vehicleIdentifierString = () => {
    if (showVRM) {
      return vehicle.Vrm || vehicle.Vin || vehicle.DealerReference || '-';
    }
    return vehicle.DealerReference || vehicle.Vin || '-';
  };

  const makeModelString = vehicle.DealerPlatformUpload
    ? `${vehicle.Make || '-'} ${vehicle.Model || '-'}`
    : `${vehicle.SMake || '-'} ${vehicle.SModel || '-'}`;
  const derivativeString = vehicle.DealerPlatformUpload
    ? ` ${vehicle.Derivative || '-'}`
    : ` ${vehicle.SDerivative || '-'}`;

  const daysInStock = vehicle?.InStockSince && daysSinceGivenDate(vehicle.InStockSince);

  return (
    <>
      <MediaQuery maxWidth={900}>
        <div className={s['vehicleCard']}>
          <div className={cn(s['vehicleImageContainer'])}>
            <VehicleImage
              imgHr={vehicle.ImgHr}
              imgUrls={vehicle.ImgUrls}
              imgPrfx={vehicle.ImgPrfx}
              vehicleClass={vehicle?.Class}
              videosMetadata={vehicle?.VideosMetadata}
              imagesMetadata={toJS(vehicle?.ImagesMetadata)}
              count={imageCount}
            />
          </div>
          <div className={s['mobileVehicleCardContentContainer']}>
            <div className={s['tagContainer']}>
              {shouldDisplayFixIssuesTag && (
                <RedTag dataTh="fixIssuesTag" onClick={navigateToFixIssuesPage} text={t('StockListItem.fix_issues')} />
              )}
              {vehicleErrors && (
                <RedTag
                  dataTh="notAdvertisedTag"
                  onClick={() => openDataIssueModal(vehicleErrors)}
                  text={t('StockListFilter.not_advertised')}
                />
              )}
              {shouldDisplayDeleteTag && (
                <GrayTag
                  dataTh="deleteVehicleTag"
                  onClick={() => showDeleteModal(vehicle.Id)}
                  text={t('DeleteButton.delete_vehicle')}
                />
              )}
            </div>
            <div className={s['mobilePriceStarRatingContainer']}>
              {canViewDataAccuracy && (
                <div className={s['mobileStarRatingContainer']}>
                  <StockListItemDataAccuracy
                    activeStars={convertAccuracyScore(4, vehicle.AccuracyScore)}
                    onClick={setIsDataAccuracyModalOpen}
                  />
                </div>
              )}
              <div data-th="price" className={s['price']}>
                <MoneyFormat countryCode={countryCode} value={vehicle.Price} />
              </div>
            </div>
            <div data-th="vehicleIdentifier" className={s['vehicleIdentifier']}>
              {vehicleIdentifierString()}
            </div>
            <div data-th="makeModel" className={s['makeModel']}>
              {makeModelString || t('StockListItem.unknown')}
            </div>
            <div data-th="derivative" className={s['derivative']}>
              {derivativeString || t('StockListItem.unknown')}
            </div>
            <div data-th="vehicleStats" className={s['vehicleStats']}>
              {getVehicleStatsString({ vehicle, t, lng })}
            </div>
            <div data-th="mobileVehicleMetrics" className={s['mobileVehicleMetricContainer']}>
              {typeof daysInStock === 'number' ? <DaysInStock value={daysInStock} /> : null}
              <VehicleMetric
                dataTh="customersInterested"
                metric="vehicle_search_click:count"
                vehicleId={vehicle.Id}
                dealershipId={params.dealershipId}
              />
              <VehicleMetric
                dataTh="vehicleQuotesInLast24Hours"
                metric="finance_quotes_displayed:count"
                vehicleId={vehicle.Id}
                dealershipId={params.dealershipId}
              />
            </div>
            <div className={s['buttonsContainerMobile']}>{renderButtons()}</div>
          </div>
          <div className={s['cardFooter']}>
            <div data-th="vehicleSource" className={s['vehicleSource']}>
              {vehicleSourceString}
            </div>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery minWidth={900}>
        <div className={s['vehicleCard']}>
          <div className={s['desktopCardGridContainer']}>
            <div className={s['desktopCardImageGridContainer']}>
              <div className={cn(s['vehicleImageContainer'])}>
                <VehicleImage
                  imgHr={vehicle.ImgHr}
                  imgUrls={vehicle.ImgUrls}
                  imgPrfx={vehicle.ImgPrfx}
                  vehicleClass={vehicle?.Class}
                  videosMetadata={vehicle?.VideosMetadata}
                  imagesMetadata={toJS(vehicle?.ImagesMetadata)}
                  count={imageCount}
                />
              </div>
            </div>
            <div className={s['cardDetailsGrid-A']}>
              <div className={s['tagContainer']}>
                {shouldDisplayFixIssuesTag && (
                  <RedTag
                    dataTh="fixIssuesTag"
                    onClick={navigateToFixIssuesPage}
                    text={t('StockListItem.fix_issues')}
                  />
                )}
                {vehicleErrors && (
                  <RedTag
                    dataTh="notAdvertisedTag"
                    onClick={() => openDataIssueModal(vehicleErrors)}
                    text={t('StockListFilter.not_advertised')}
                  />
                )}
                {shouldDisplayDeleteTag && (
                  <GrayTag
                    dataTh="deleteVehicleTag"
                    onClick={() => showDeleteModal(vehicle.Id)}
                    text={t('DeleteButton.delete_vehicle')}
                  />
                )}
              </div>
              <div className={s['cardDetailsContainer']}>
                <div data-th="vehicleIdentifier" className={s['vehicleIdentifier']}>
                  {vehicleIdentifierString()}
                </div>
                <div data-th="makeModel" className={s['makeModel']}>
                  {makeModelString}
                </div>
                <div data-th="derivative" className={s['derivative']}>
                  {derivativeString}
                </div>
                <div data-th="vehicleStats" className={s['vehicleStats']}>
                  {getVehicleStatsString({ vehicle, t, lng })}
                </div>
              </div>
            </div>
            <div className={s['cardDetailsGrid-B']}>
              {canViewDataAccuracy && (
                <div className={s['starRatingContainer']}>
                  <StockListItemDataAccuracy
                    activeStars={convertAccuracyScore(4, vehicle.AccuracyScore)}
                    onClick={setIsDataAccuracyModalOpen}
                  />
                </div>
              )}
              <div data-th="price" className={s['price']}>
                <MoneyFormat countryCode={countryCode} value={vehicle.Price} />
              </div>
              <div data-th="vehicleMetrics" className={s['vehicleMetricContainer']}>
                {typeof daysInStock === 'number' ? <DaysInStock value={daysInStock} /> : null}
                {showVehicleCardMetrics ? (
                  <VehicleMetric
                    dataTh="customersInterested"
                    metric="vehicle_search_click:count"
                    vehicleId={vehicle.Id}
                    dealershipId={params.dealershipId}
                  />
                ) : null}
                {showVehicleCardMetrics ? (
                  <VehicleMetric
                    dataTh="vehicleQuotesInLast24Hours"
                    metric="finance_quotes_displayed:count"
                    vehicleId={vehicle.Id}
                    dealershipId={params.dealershipId}
                  />
                ) : null}
              </div>
            </div>
            <div className={s['buttonsContainer']}>{renderButtons()}</div>
            <div className={s['cardFooter']}>
              <div data-th="vehicleSource" className={s['vehicleSource']}>
                {vehicleSourceString}
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>
    </>
  );

  function renderButtons() {
    return (
      <>
        {(isBdk || canGenerateQuotes) && (
          <TrackEvent
            featureName={`StockModuleRefineVehicleDetails--quote`}
            interactionName={`click`}
            eventContent={id}
            context={trackingContext}
            className={s.quoteButtonContainer}
          >
            <Button
              className={s.quoteButton}
              dataThook={'quote'}
              buttonStyle="primary"
              onClick={quoteVehicle}
              type="button"
              disabled={!isBdk && hasDataIssues}
            >
              {t('StockListItem.quote')}
            </Button>
          </TrackEvent>
        )}
        {canUsePushDeal && canGenerateQuotes && (
          <TrackEvent
            featureName={`StockModuleRefineVehicleDetails--sendDeal`}
            interactionName={`click`}
            eventContent={id}
            context={trackingContext}
          >
            <Button
              dataThook={'sendDeal'}
              buttonStyle="secondary"
              className={s.secondaryButton}
              onClick={pushDealClick}
            >
              <div className={s.iconButtonWrapper}>
                <span className={s.sendDealText}>{t('StockListItem.send_deal')}</span>
              </div>
            </Button>
          </TrackEvent>
        )}

        {canAddVehicles && (
          <TrackEvent
            featureName={`StockModuleRefineVehicleDetails--amend`}
            interactionName={`click`}
            eventContent={id}
            context={trackingContext}
          >
            <Button
              className={s.secondaryButton}
              buttonStyle="secondary"
              onClick={navigateToAmendPage}
              dataThook={'amend'}
              type="button"
            >
              <span className={s.amendText}>{t('StockListItem.amend')}</span>
            </Button>
          </TrackEvent>
        )}

        {!canAddVehicles && canFixIssues && (
          <TrackEvent
            featureName={`StockModuleRefineVehicleDetails--fixIssues`}
            interactionName={`click`}
            eventContent={id}
            context={trackingContext}
          >
            <Button type="button" dataThook={'fixIssues'} buttonStyle="secondary" onClick={navigateToFixIssuesPage}>
              <span className={s.amendText}>{t('StockListItem.amend')}</span>
            </Button>
          </TrackEvent>
        )}
      </>
    );
  }
};

interface QuoteVehicle extends AlgoliaVehicle {
  imageSource?: string;
  DerivativeId?: string;
  MakeId?: string;
  ModelId?: string;
  CdnVehicleImageUrl?: string;
}
function mapDispatchToProps<T>(dispatch: Dispatch<T>) {
  return {
    changeQuickQuoteVehicle: (vehicle: AlgoliaVehicle) => {
      let quoteVehicle: QuoteVehicle = cloneDeep(vehicle);
      if (quoteVehicle.Class?.toLowerCase() === 'van') {
        quoteVehicle.Class = 'lcv';
      }
      if (quoteVehicle.SClass?.toLowerCase() === 'van') {
        quoteVehicle.SClass = 'lcv';
      }

      quoteVehicle.CdnVehicleImageUrl = vehicle.ImgHr;
      // MakeId, ModelId might be depreciated, not seen on VIS, Algolia or StockModule responses
      quoteVehicle.MakeId = undefined;
      quoteVehicle.ModelId = undefined;
      quoteVehicle.DerivativeId = quoteVehicle.CapId;

      const formatedVehicle = formatVehicleObject(quoteVehicle);

      dispatch(quickQuoteActions.changeQuickQuoteVehicle(formatedVehicle));
      dispatch(quoteActions.changeQuotingVehicle(formatedVehicle));
    }
  };
}

export default withNavigate(connect(null, mapDispatchToProps)(inject('appStore')(StockListItem)));
