import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import RepExampleCustom from './RepExampleCustom';
import RadioButton from '../../Common/Form/RadioButton';
import RepExampleInfoCard from './RepExampleInfoCard';
import Button from '../../Common/Button/Button';
import RepExampleText from '@ivendi/rep-example';
import DateFormat from '../../Common/DateFormat';
import PropTypes from 'prop-types';
import './repExampleSettings.scss';
import ExclamationWarning from '../../Common/ExclamationWarning';
import LoadingCenterWrapped from '../../Common/Loading/LoadingCenterWrapped';
import { compose } from 'redux';
import { withParams } from 'hocs/router';

class RepExampleSettings extends Component {
  state = {
    currentRepExample: null
  };
  setRepExampleType = (value) => {
    this.props.appStore.repExampleStore.setRepExampleType(value);
  };
  handleChangeCustomRepExample = () => {
    const initialValues = {
      Term: this.props.preferredTerm,
      CashDeposit: this.props.preferredDeposit,
      AnnualDistance: this.props.preferredAnnualMileage
    };
    this.props.appStore.repExampleStore.resetRepExampleForms();
    this.props.appStore.repExampleStore.setInitialPricingValues(initialValues);
    this.props.appStore.repExampleStore.setCustomPage('vehicleForm');
    window.ga && window.ga('send', 'event', 'RepExSelect', 'ChangeCustomRepExample', 'RepExampleCustomSelect');
  };
  handleHpSubmit = () => {
    this.props.appStore.repExampleStore.setQuote(
      this.props.params.dealershipId,
      null,
      'HirePurchaseProductWithTheLowestMonthlyPayment',
      Date.now()
    );
    this.props.appStore.repExampleStore.resetRepExampleForms();
    this.setState({
      currentRepExample: 1,
      showNewRepExample: false
    });
    window.ga && window.ga('send', 'event', 'RepExSelect', 'Submit', 'RepExampleHpSubmit');
  };
  setNewCurrentRepExample = () => {
    this.setState({
      currentRepExample: 3
    });
    window.ga && window.ga('send', 'event', 'RepExSelectRepresentativeQuote', 'Proceed', 'RepExampleSelectQuote');
  };
  handleLowestSubmit = () => {
    this.props.appStore.repExampleStore.setQuote(
      this.props.params.dealershipId,
      null,
      'LowestMonthlyPayment',
      Date.now()
    );
    this.props.appStore.repExampleStore.resetRepExampleForms();
    this.setState({
      currentRepExample: 2,
      showNewRepExample: false
    });
    window.ga && window.ga('send', 'event', 'RepExSelect', 'Submit', 'RepExampleLowestSubmit');
  };
  handleBackButton = () => {
    window.ga && window.ga('send', 'event', 'RepExSelect', 'Cancel', 'RepExampleCancel');
  };

  componentDidMount() {
    this.props.appStore.repExampleStore.productSettings = this.props.productSettings;
    this.props.appStore.repExampleStore.quoteeUid = this.props.dealership.WebQuotewareId;
    this.props.appStore.repExampleStore.fetchExistingRepExample(this.props.params.dealershipId);
    this.props.appStore.repExampleStore.setCustomPage('');
  }

  componentWillUnmount = () => {
    this.props.appStore.repExampleStore.resetRepExampleForms();
    this.props.appStore.repExampleStore.resetQuote();
    this.props.appStore.repExampleStore.setRepExampleType(null);
    this.props.appStore.repExampleStore.resetPreviouslySetByUser();
  };

  render() {
    if (!this.props.appStore.uiState.canSetRepExample) {
      return (
        <Page>
          <div className="repExampleSettings__notEnabled">
            {this.props.t(
              'RepExampleSettings.sorry_representative_example_settings_are_not_enabled_for_your_current_dealership'
            )}
          </div>
          <div className="repExampleSettings__notEnabled--button">
            <Button to={`/d/${this.props.params.dealershipId}`}>
              {this.props.t('RepExampleSettings.return_to_dashboard')}
            </Button>
          </div>
        </Page>
      );
    }

    const { dealershipId } = this.props.params;
    const { repExampleSelected, quote, newQuote, customPage, initialRepExample } = this.props.appStore.repExampleStore;
    const showExistingRepExample =
      repExampleSelected === 'custom' &&
      customPage === '' &&
      Object.keys(quote).length > 0 &&
      Object.keys(newQuote).length === 0;
    let showNewRepExample;

    if (!this.state.showNewRepExample && initialRepExample !== 3) {
      showNewRepExample = false;
    } else if (this.state.currentRepExample !== 3) {
      showNewRepExample = false;
    } else {
      showNewRepExample = repExampleSelected === 'custom' && customPage === '' && Object.keys(newQuote).length > 0;
    }

    const timeStamp =
      this.props.appStore.repExampleStore.newRepTimestamp >
      this.props.appStore.repExampleStore.quote.LastModifiedTimestamp
        ? this.props.appStore.repExampleStore.newRepTimestamp
        : this.props.appStore.repExampleStore.quote.LastModifiedTimestamp;
    const currentRepExample = this.state.currentRepExample ? this.state.currentRepExample : initialRepExample;
    const isLoading =
      this.props.appStore.repExampleStore.getExistingRepExample.isLoading ||
      this.props.appStore.repExampleStore.setRepExample.isLoading;
    return (
      <Page>
        <div className="repExampleSettings__breadcrumbs">
          <Breadcrumbs
            items={[
              {
                name: this.props.t('RepExampleSettings.home'),
                path: `/d/${dealershipId}`
              },
              {
                name: this.props.t('RepExampleSettings.settings'),
                path: `/d/${dealershipId}/settings`
              },
              {
                name: this.props.t('RepExampleSettings.rep_example'),
                path: `/d/${dealershipId}/settings/repexample`
              }
            ]}
          />
        </div>
        <Panel>
          <PanelHeader>{this.props.t('RepExampleSettings.representative_example_settings')}</PanelHeader>
          <PanelContent>
            <div className="repExampleSettings__container">
              <div className="repExampleSettings__title">
                {this.props.t('RepExampleSettings.representative_example')}
              </div>
              {this.props.appStore.repExampleStore.previouslySetByUser && (
                <div className="repExampleSettings__previousUser">
                  {this.props.t('RepExampleSettings.previously_set_by_on', {
                    previouslySetByUser: this.props.appStore.repExampleStore.previouslySetByUser
                  })}{' '}
                  <DateFormat value={timeStamp} format="DD/MM/YYYY [at] HH:mm" />
                </div>
              )}
              <div className="repExampleSettings__info">
                {this.props.t(
                  'RepExampleSettings.choose_how_you_would_like_your_representative_example_to_be_displayed_on_your_website_finance_comparator_widget'
                )}
              </div>
              <div className="repExampleSettings__options">
                <RadioButton
                  id="radio-hp"
                  value="repExampleHp"
                  onChange={() => this.setRepExampleType('hp')}
                  checked={repExampleSelected === 'hp'}
                >
                  {this.props.t(
                    'RepExampleSettings.hire_purchase_product_with_the_lowest_monthly_payment_if_available'
                  )}
                  <div className="repExampleSettings__optionsSubText">
                    {this.props.t(
                      'RepExampleSettings.if_hire_purchase_is_unavailable_it_will_use_the_lowest_cs_pcp_or_lp_quote_in_this_order'
                    )}
                  </div>
                </RadioButton>
              </div>
              <div className="repExampleSettings__options">
                <RadioButton
                  id="radio-lowest"
                  value="repExampleLowest"
                  onChange={() => this.setRepExampleType('lowest')}
                  checked={repExampleSelected === 'lowest'}
                >
                  {this.props.t('RepExampleSettings.lowest_monthly_payment_all_products')}
                  <div className="repExampleSettings__optionsSubText">
                    {this.props.t(
                      'RepExampleSettings.looks_for_the_overall_lowest_payment_from_your_full_panel_of_products_and_uses_that_product'
                    )}
                  </div>
                </RadioButton>
              </div>
              <div className="repExampleSettings__options">
                <RadioButton
                  id="radio-custom"
                  value="repExampleCustom"
                  onChange={() => this.setRepExampleType('custom')}
                  checked={repExampleSelected === 'custom'}
                >
                  {this.props.t('RepExampleSettings.choose_your_own')}
                </RadioButton>
              </div>
            </div>
            {showExistingRepExample ? (
              <div className="repExampleSettings__repExampleContainer">
                {currentRepExample === 3 ? (
                  <div>
                    <div className="repExampleInfoCard__repTitle">
                      {this.props.t('RepExampleSettings.current_representative_example')}
                    </div>
                    <div className="repExampleSettings__repExample">
                      <RepExampleText quote={quote} />
                    </div>
                    <div className="repExampleSettings__buttons">
                      <div className="repExampleSettings__buttonWrap">
                        <Button to={`/d/${dealershipId}/settings`} buttonStyle="cancel" onClick={this.handleBackButton}>
                          {this.props.t('RepExampleSettings.cancel')}
                        </Button>
                      </div>
                      <div className="repExampleSettings__buttonWrap">
                        <Button buttonStyle="primary" onClick={this.handleChangeCustomRepExample}>
                          {this.props.t('RepExampleSettings.change_rep_example')}
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <RepExampleInfoCard>
                      <div>{this.props.t('RepExampleSettings.choose_your_own')}</div>
                      <div>
                        {this.props.t('RepExampleSettings.select_a_representative_vehicle_to_use_in_your_example')}
                      </div>
                      <div>{this.props.t('RepExampleSettings.enter_the_price_details_and_representative_apr')}</div>
                      <div>{this.props.t('RepExampleSettings.choose_from_real_quotes_from_the_lenders_you_offer')}</div>
                    </RepExampleInfoCard>
                    <div className="repExampleSettings__buttons">
                      <div className="repExampleSettings__buttonWrap">
                        <Button to={`/d/${dealershipId}/settings`} buttonStyle="cancel" onClick={this.handleBackButton}>
                          {this.props.t('RepExampleSettings.cancel')}
                        </Button>
                      </div>
                      <div className="repExampleSettings__buttonWrap">
                        <Button onClick={this.handleChangeCustomRepExample}>
                          {this.props.t('RepExampleSettings.set_representative_example')}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : showNewRepExample ? (
              <div className="repExampleSettings__repExampleContainer">
                <div className="repExampleInfoCard__repTitle">
                  {this.props.t('RepExampleSettings.current_representative_example')}
                </div>
                <div className="repExampleSettings__repExample">
                  <RepExampleText quote={newQuote} />
                </div>
                <div className="repExampleSettings__buttons">
                  <div className="repExampleSettings__buttonWrap">
                    <Button to={`/d/${dealershipId}/settings`} buttonStyle="cancel" onClick={this.handleBackButton}>
                      {this.props.t('RepExampleSettings.cancel')}
                    </Button>
                  </div>
                  <div className="repExampleSettings__buttonWrap">
                    <Button buttonStyle="primary" onClick={this.handleChangeCustomRepExample} loading={!newQuote}>
                      {this.props.t('RepExampleSettings.change_rep_example')}
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              (repExampleSelected === 'custom' || repExampleSelected === '') &&
              customPage === '' && <LoadingCenterWrapped />
            )}

            {repExampleSelected === 'custom' ? (
              <div>
                <RepExampleCustom dealershipId={dealershipId} setNewCurrentRepExample={this.setNewCurrentRepExample} />
              </div>
            ) : repExampleSelected === 'hp' ? (
              <div className="repExampleSettings__repExampleContainer">
                <RepExampleInfoCard>
                  <div>{this.props.t('RepExampleSettings.lowest_hire_purchase_monthly_payment_if_available')}</div>
                  <div>
                    {this.props.t(
                      'RepExampleSettings.takes_the_current_vehicle_the_consumer_is_viewing_on_your_website'
                    )}
                  </div>
                  <div>
                    {this.props.t(
                      'RepExampleSettings.uses_the_hire_purchase_product_available_with_the_lowest_monthly_payment_if_hp_is_not_available_it_will_use_the_lowest_cs_pcp_or_lp_quote_available_in_that_order'
                    )}
                  </div>
                  <div>
                    {this.props.t(
                      'RepExampleSettings.the_product_and_vehicle_are_used_to_automatically_generate_the_representative_example'
                    )}
                  </div>
                </RepExampleInfoCard>
                <div className="repExampleSettings__buttons">
                  <div className="repExampleSettings__buttonWrap">
                    <Button to={`/d/${dealershipId}/settings`} buttonStyle="cancel" onClick={this.handleBackButton}>
                      {this.props.t('RepExampleSettings.cancel')}
                    </Button>
                  </div>
                  {currentRepExample !== 1 && !showNewRepExample && (
                    <div className="repExampleSettings__buttonWrap">
                      <Button buttonStyle="primary" onClick={this.handleHpSubmit} isLoading={isLoading}>
                        {this.props.t('RepExampleSettings.save')}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              repExampleSelected === 'lowest' && (
                <div className="repExampleSettings__repExampleContainer">
                  <RepExampleInfoCard>
                    <div>{this.props.t('RepExampleSettings.lowest_monthly_payment_all')}</div>
                    <div>
                      {this.props.t(
                        'RepExampleSettings.takes_the_current_vehicle_the_consumer_is_viewing_on_your_website'
                      )}
                    </div>
                    <div>
                      {this.props.t(
                        'RepExampleSettings.uses_the_lowest_payment_from_your_full_panel_of_products_available_on_your_website'
                      )}
                    </div>
                    <div>
                      {this.props.t(
                        'RepExampleSettings.the_product_and_vehicle_are_used_to_automatically_generate_the_representative_example'
                      )}
                    </div>
                  </RepExampleInfoCard>
                  <div className="repExampleSettings__buttons">
                    <div className="repExampleSettings__buttonWrap">
                      <Button to={`/d/${dealershipId}/settings`} buttonStyle="cancel" onClick={this.handleBackButton}>
                        {this.props.t('RepExampleSettings.cancel')}
                      </Button>
                    </div>
                    {currentRepExample !== 2 && !showNewRepExample && (
                      <div className="repExampleSettings__buttonWrap">
                        <Button buttonStyle="primary" onClick={this.handleLowestSubmit} isLoading={isLoading}>
                          {this.props.t('RepExampleSettings.save')}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )
            )}

            {this.props.appStore.repExampleStore.getExistingRepExample.hasError &&
              this.props.appStore.repExampleStore.repExampleSelected === null && (
                <div className="repExampleSettings__repExampleContainer">
                  <div className="repExampleSettings__error">
                    <ExclamationWarning>
                      {this.props.t('RepExampleSettings.error_fetching_representative_example_please_try_again_later')}
                    </ExclamationWarning>
                  </div>
                  <div className="repExampleSettings__buttons">
                    <div className="repExampleSettings__buttonWrap">
                      <Button to={`/d/${dealershipId}/settings`} buttonStyle="cancel" onClick={this.handleBackButton}>
                        {this.props.t('RepExampleSettings.cancel')}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
          </PanelContent>
        </Panel>
      </Page>
    );
  }
}

RepExampleSettings.propTypes = {
  params: PropTypes.object,
  dealership: PropTypes.object,
  productSettings: PropTypes.array,
  appStore: PropTypes.object,
  preferredTerm: PropTypes.string,
  preferredDeposit: PropTypes.string,
  preferredAnnualMileage: PropTypes.number,
  defaultVehiclePricingValues: PropTypes.object
};

export default compose(
  withParams,
  withTranslation('RepExampleSettings'),
  inject(['appStore']),
  observer
)(RepExampleSettings);
