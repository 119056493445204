import * as actionTypes from '../actionTypes';
import _ from 'lodash';
import { validate } from '../../core/validators';
import { getApplicationProgress } from '../../core/applicationProgress';
import { calculateTotalMonthsFromCollection } from '../../core/helpers';
import { getApplicationStatusRules, getLenderConfig } from '../../core/lenders';
import { lenders } from '../../core/lenderList';

const defaultState = {
  isLoading: false,
  submittingState: '',
  sectionSavingState: '',
  sectionSubmittingState: '',
  updatingState: '',
  Status: null,
  LenderNotes: [],
  progress: {},
  totalProgress: 0,
  PreApprovalData: {},
  PersonalDetails: {},
  AddressHistory: {
    Items: [],
    totalMonths: 0
  },
  EmploymentHistory: {
    Items: [],
    totalMonths: 0
  },
  BankDetails: {
    TimeAtBank: {}
  },
  AffordabilityDetails: {
    Income: {},
    MonthlyExpenditure: {},
    PersonalCircumstances: {},
    ReplacementLoan: {}
  },
  BusinessDetails: {},
  OrganisationContacts: {
    Items: [
      {
        Items: [{}],
        isEditing: false
      }
    ]
  },
  CustomerType: 'consumer' //TODO need to check this is the correct field when API is updated
};

function validateApplication(application) {
  const validationRules = getLenderConfig(application.Quote.FunderCode, application.CustomerType, 'validationRules');
  return validate(application, validationRules);
}

function updateProgress(state) {
  return Object.assign({}, state, { progress: getApplicationProgress(state) });
}

function updateApplicationStatuses(state) {
  const funderCode = state.Quote && state.Quote.FunderCode;

  const statusRules = getApplicationStatusRules(funderCode, state.CustomerType, state.Status, lenders);

  return Object.assign({}, state, {
    ...statusRules
  });
}

function updateApplication(state, action) {
  let newState = _.cloneDeep(state);

  if (action.section === 'AddressHistory') {
    newState.AddressHistory = {
      Items: action.formData,
      totalMonths: calculateTotalMonthsFromCollection(action.formData, 'TimeAtAddress')
    };
  } else if (action.section === 'EmploymentHistory') {
    newState.EmploymentHistory = {
      Items: action.formData,
      totalMonths: calculateTotalMonthsFromCollection(action.formData, 'TimeAtEmployment')
    };
  } else if (action.section === 'OrganisationContacts') {
    newState.OrganisationContacts.Items = action.formData;
    _.forEach(newState.OrganisationContacts.Items, (contact) => {
      contact.totalMonths = calculateTotalMonthsFromCollection(contact.Items, 'TimeAtAddress');
    });
  } else if (action.section === 'BusinessAddress') {
    newState.AddressHistory = action.formData;
  } else {
    newState[action.section] = action.formData;
  }

  newState.validationErrors = validateApplication(newState);
  newState = updateProgress(newState);

  return newState;
}

export default function applicationReducer(state = defaultState, action) {
  var newState;

  switch (action.type) {
    case actionTypes.CLONE_APPLICATION:
      newState = _.cloneDeep(state);
      newState.applicationCloneState = 'loading';
      return newState;

    case actionTypes.CLONE_APPLICATION_SUCCESS:
      newState = _.cloneDeep(state);
      newState.isLoading = true;
      newState.applicationCloneState = 'done';
      return newState;

    case actionTypes.CLONE_APPLICATION_ERROR:
      newState = _.cloneDeep(state);
      newState.applicationCloneState = 'error';
      return newState;

    case actionTypes.FETCH_APPLICATION:
      newState = _.cloneDeep(defaultState);
      newState.isLoading = true;
      return newState;

    case actionTypes.FETCH_APPLICATION_SUCCESS:
    case actionTypes.FETCH_APPLICATION_UPDATES_SUCCESS:
      newState = _.cloneDeep(defaultState);
      const application = action.response;
      newState = Object.assign({}, newState, application, {
        isLoading: false,
        PreApprovalData: application?.Agreements[0]?.PreApprovalData ?? null,
        prevVehicle: application.Vehicle
      });
      newState.validationErrors = validateApplication(newState);
      newState = updateProgress(newState);
      newState = updateApplicationStatuses(newState);
      return newState;

    case actionTypes.FETCH_APPLICATION_ERROR:
      newState = _.cloneDeep(state);
      newState.isLoading = false;
      newState.error = action.error;
      return newState;

    //Save Sections

    case actionTypes.SAVE_APPLICATION_SECTION:
      return Object.assign({}, state, { sectionSavingState: 'loading' });

    case actionTypes.SAVE_APPLICATION_SECTION_SUCCESS:
      return Object.assign({}, updateApplication(state, action), { sectionSavingState: 'done' });

    case actionTypes.SAVE_APPLICATION_SECTION_ERROR:
      return Object.assign({}, state, { sectionSavingState: 'error' });

    //Save Bank Details
    case actionTypes.SAVE_BANK_DETAILS:
      return Object.assign({}, state, { sectionSavingState: 'loading', bankLookupError: false });

    case actionTypes.SAVE_BANK_DETAILS_SUCCESS:
      newState = _.cloneDeep(state);
      newState.BankDetails = action.formData;
      newState.validationErrors = validateApplication(newState);
      newState = updateProgress(newState);
      return Object.assign({}, newState, { sectionSavingState: 'done' });

    case actionTypes.SAVE_BANK_DETAILS_ERROR:
      return Object.assign({}, state, {
        sectionSavingState: 'error',
        bankLookupError: action.error === 'BankLookup'
      });

    //Submit Sections

    case actionTypes.SUBMIT_APPLICATION_SECTION:
      return Object.assign({}, state, { sectionSubmittingState: 'loading' });

    case actionTypes.SUBMIT_APPLICATION_SECTION_SUCCESS:
      return Object.assign({}, updateApplication(state, action), { sectionSubmittingState: 'done' });

    case actionTypes.SUBMIT_APPLICATION_SECTION_ERROR:
      return Object.assign({}, state, { sectionSubmittingState: 'error' });

    //Submit Bank Details

    case actionTypes.SUBMIT_BANK_DETAILS:
      return Object.assign({}, state, { sectionSubmittingState: 'loading', bankLookupError: false });

    case actionTypes.SUBMIT_BANK_DETAILS_SUCCESS:
      newState = _.cloneDeep(state);
      newState.BankDetails = action.formData;
      newState.validationErrors = validateApplication(newState);
      newState = updateProgress(newState);
      return Object.assign({}, newState, { sectionSubmittingState: 'done' });

    case actionTypes.SUBMIT_BANK_DETAILS_ERROR:
      return Object.assign({}, state, {
        sectionSubmittingState: 'error',
        bankLookupError: action.error === 'BankLookup'
      });

    //Submit Application

    case actionTypes.SUBMIT_APPLICATION:
    case actionTypes.RESUBMIT_APPLICATION:
    case actionTypes.CHANGE_APPLICATION_QUOTE:
    case actionTypes.CHANGE_APPLICATION_QUOTE_PRE_SUBMISSION:
      return Object.assign({}, state, { submittingState: 'loading' });

    case actionTypes.SUBMIT_APPLICATION_SUCCESS:
    case actionTypes.RESUBMIT_APPLICATION_SUCCESS:
      return Object.assign({}, state, { submittingState: 'done', Status: 'Submitting' });

    case actionTypes.CHANGE_APPLICATION_QUOTE_SUCCESS:
    case actionTypes.CHANGE_APPLICATION_QUOTE_PRE_SUBMISSION_SUCCESS:
      newState = _.cloneDeep(state);
      newState.validationErrors = validateApplication(newState);
      newState = updateProgress(newState);

      return Object.assign(newState, {
        submittingState: 'done',
        Quote: action.quote,
        QuoteRequest: action.quoteRequest,
        prevVehicle: action.vehicle,
        Vehicle: action.vehicle
      });

    case actionTypes.CHANGE_APPLICATION_VEHICLE:
      newState = _.cloneDeep(state);
      newState.validationErrors = validateApplication(newState);
      newState = updateProgress(newState);
      return Object.assign(newState, {
        prevVehicle: newState.Vehicle,
        Vehicle: action.payload.vehicle
      });

    case actionTypes.SUBMIT_APPLICATION_ERROR:
    case actionTypes.RESUBMIT_APPLICATION_ERROR:
    case actionTypes.CHANGE_APPLICATION_QUOTE_ERROR:
    case actionTypes.CHANGE_APPLICATION_QUOTE_PRE_SUBMISSION_ERROR:
      return Object.assign({}, state, { submittingState: 'error' });

    case actionTypes.UPDATE_DECISION:
      return Object.assign({}, state, { updatingState: 'loading' });

    case actionTypes.UPDATE_DECISION_SUCCESS:
      return Object.assign({}, state, { updatingState: 'done' });

    case actionTypes.UPDATE_DECISION_ERROR:
      return Object.assign({}, state, { updatingState: 'error' });

    case actionTypes.RESET_EDIT_VEHICLE:
      newState = _.cloneDeep(state);
      newState.Vehicle = newState.prevVehicle;
      newState.validationErrors = validateApplication(newState);
      newState = updateProgress(newState);
      return newState;

    case actionTypes.SHOW_HIDE_LEAD_ON_DASHBOARD:
      newState = _.cloneDeep(state);
      newState.Vehicle.HiddenOnDashboard = action.value;
      return newState;

    default:
      return state;
  }
}
