import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import FunderLogo from '../../Common/FunderLogo';
import _ from 'lodash';
import './platformUsage.scss';

class PlatformUsage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFullLenders: false
    };
  }

  handleShowMoreLendersToggle = () => {
    this.setState({
      showFullLenders: !this.state.showFullLenders
    });
  };

  render() {
    const { data } = this.props;
    const submittedLenders = data && data.SubmittedLenders ? data.SubmittedLenders : [];

    const slicedArray = _.slice(submittedLenders, 0, 6);

    return (
      <div className="platformUsage__outer">
        <table className="digitalReport__table">
          <tbody>
            <tr className="digitalReport__tr">
              <th className="digitalReport__th">{this.props.t('PlatformUsage.total_applications_submitted')}</th>
              <td className="digitalReport__td platformUsage__td">{data.Submissions}</td>
            </tr>
            <tr className="digitalReport__tr">
              <th className="digitalReport__th">{this.props.t('PlatformUsage.approval_rate')}</th>
              <td className="digitalReport__td platformUsage__td">{data.ApprovalRate}%</td>
            </tr>
            <tr className="digitalReport__tr">
              <th className="digitalReport__th">{this.props.t('PlatformUsage.rejection_rate')}</th>
              <td className="digitalReport__td platformUsage__td">{data.RejectionRate}%</td>
            </tr>
          </tbody>
        </table>
        <div className="platformUsage__lenders">
          <div>
            {this.props.t('PlatformUsage.lenders_submitted_to', {
              lenderCount: submittedLenders.length
            })}
          </div>
          {submittedLenders.length <= 6 ? (
            <div>
              {submittedLenders.map((funder, index) => (
                <div className="platformUsage__eachLender" key={index}>
                  <FunderLogo funder={funder} />
                </div>
              ))}
            </div>
          ) : (
            <div>
              {this.state.showFullLenders ? (
                <div>
                  {submittedLenders.map((funder, index) => (
                    <div className="platformUsage__eachLender" key={index}>
                      <FunderLogo funder={funder} />
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  {slicedArray.map((funder, index) => (
                    <div className="platformUsage__eachLender" key={index}>
                      <FunderLogo funder={funder} />
                    </div>
                  ))}
                </div>
              )}
              <button onClick={this.handleShowMoreLendersToggle} className="platformUsage__show">
                {this.state.showFullLenders
                  ? this.props.t('PlatformUsage.show_less')
                  : this.props.t('PlatformUsage.show_more')}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

PlatformUsage.propTypes = {
  data: PropTypes.object
};
export default withTranslation('DigitalReport')(PlatformUsage);
