import React from 'react';
import PropTypes from 'prop-types';
import './leagueTableCell.scss';

const LeagueTableCell = ({ children }) => <td className="leagueTableCell">{children}</td>;

LeagueTableCell.propTypes = {
  children: PropTypes.node
};

export default LeagueTableCell;
