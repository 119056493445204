import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../Common/Icon/Icon';
import './circleConsumerImage.scss';
import classnames from 'classnames';

const CircleConsumerImage = ({ isSmall = false, customerType = 'consumer' }) => {
  const type = customerType && customerType.toLowerCase();

  return (
    <div
      className={classnames(
        'circleConsumerImage',
        `circleConsumerImage--${type}`,
        isSmall && 'circleConsumerImage--small'
      )}
    >
      <Icon name={type === 'corporate' ? 'employment' : 'consumer'} />
    </div>
  );
};

CircleConsumerImage.propTypes = {
  customerType: PropTypes.string,
  isSmall: PropTypes.bool
};

export default CircleConsumerImage;
