import { FormattedQuote, FormattedQuoteKey } from '../types';

export const purgeQuoteKeys = (formattedQuote: FormattedQuote): FormattedQuote => {
  const EXCLUDED_KEYS = ['NegativeEquityLoan', 'PersonalLoan', 'VehicleLoan'];
  const purgedQuote: FormattedQuote = { ...formattedQuote };

  (Object.keys(formattedQuote) as FormattedQuoteKey[]).forEach((label) => {
    if (EXCLUDED_KEYS.includes(label)) {
      delete purgedQuote[label];
    }
  });

  return purgedQuote;
};
