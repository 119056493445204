import { useVisLookupContext, SET_PAGE } from '../../context/VisLookupContext';

const usePagination = () => {
  const { state, dispatch } = useVisLookupContext();
  const refine = (pageNumber) => {
    dispatch({
      type: SET_PAGE,
      payload: pageNumber
    });
  };

  return {
    refine,
    page: state.page
  };
};

export default usePagination;
