import { useTranslation } from 'react-i18next';
import React from 'react';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import ExclamationWarning from '../../Common/ExclamationWarning';

const QuotingNotAvailable = () => {
  const { t } = useTranslation('Quoting');
  return (
    <Panel>
      <PanelHeader>{t('QuotingNotAvailable.quoting')}</PanelHeader>
      <PanelContent>
        <ExclamationWarning message={t('QuotingNotAvailable.you_do_not_have_the_privileges_for_showroom_quoting')} />
      </PanelContent>
    </Panel>
  );
};

export default QuotingNotAvailable;
