import i18n from '../../i18n';

export const benefitsAndRisksMessages = () => ({
  HP: {
    benefits: [
      i18n.t('Application:benefitsAndRisksMessages.fixed_repayments_and_fixed_interest_rate'),
      i18n.t('Application:benefitsAndRisksMessages.option_to_hand_the_vehicle_back_early_terms_and_conditions_apply'),
      i18n.t('Application:benefitsAndRisksMessages.you_can_set_the_payments_and_term_to_suit_your_budget'),
      i18n.t('Application:benefitsAndRisksMessages.rebate_of_future_interest_if_you_settle_your_agreement_early'),
      i18n.t(
        'Application:benefitsAndRisksMessages.additional_customer_protection_with_regards_to_the_satisfactory_quality_of_your_vehicle'
      ),
      i18n.t('Application:benefitsAndRisksMessages.once_all_the_repayments_are_made_you_keep_the_vehicle'),
      i18n.t('Application:benefitsAndRisksMessages.straight_forward_application_process')
    ],
    risks: [
      i18n.t(
        'Application:benefitsAndRisksMessages.no_benefit_to_you_if_general_interest_rates_go_down_as_your_agreement_has_a_fixed_interest_rate'
      ),
      i18n.t(
        'Application:benefitsAndRisksMessages.the_lender_takes_security_in_the_vehicle_you_cannot_sell_or_give_the_vehicle_away_until_you_have_paid_all_payments_under_the_agreement'
      ),
      i18n.t(
        'Application:benefitsAndRisksMessages.the_vehicle_can_be_repossessed_if_you_dont_keep_your_repayments_up_to_date'
      ),
      i18n.t('Application:benefitsAndRisksMessages.finance_must_be_settled_when_you_sell_part_exchange_the_vehicle'),
      i18n.t('Application:benefitsAndRisksMessages.there_is_no_guaranteed_future_value_with_this_product')
    ]
  },
  CS: {
    benefits: [
      i18n.t('Application:benefitsAndRisksMessages.fixed_repayments_and_fixed_interest_rate'),
      i18n.t('Application:benefitsAndRisksMessages.option_to_hand_the_vehicle_back_early_terms_and_conditions_apply'),
      i18n.t('Application:benefitsAndRisksMessages.you_can_set_the_payments_and_term_to_suit_your_budget'),
      i18n.t('Application:benefitsAndRisksMessages.rebate_of_future_interest_if_you_settle_your_agreement_early'),
      i18n.t(
        'Application:benefitsAndRisksMessages.additional_customer_protection_with_regards_to_the_satisfactory_quality_of_your_vehicle'
      ),
      i18n.t('Application:benefitsAndRisksMessages.once_all_the_repayments_are_made_you_keep_the_vehicle'),
      i18n.t('Application:benefitsAndRisksMessages.straight_forward_application_process')
    ],
    risks: [
      i18n.t(
        'Application:benefitsAndRisksMessages.no_benefit_to_you_if_general_interest_rates_go_down_as_your_agreement_has_a_fixed_interest_rate'
      ),
      i18n.t(
        'Application:benefitsAndRisksMessages.the_lender_takes_security_in_the_vehicle_you_cannot_sell_or_give_the_vehicle_away_until_you_have_paid_all_payments_under_the_agreement'
      ),
      i18n.t(
        'Application:benefitsAndRisksMessages.the_vehicle_can_be_repossessed_if_you_dont_keep_your_repayments_up_to_date'
      ),
      i18n.t('Application:benefitsAndRisksMessages.finance_must_be_settled_when_you_sell_part_exchange_the_vehicle'),
      i18n.t('Application:benefitsAndRisksMessages.there_is_no_guaranteed_future_value_with_this_product')
    ]
  },
  PCP: {
    benefits: [
      i18n.t('Application:benefitsAndRisksMessages.part_of_the_loan_is_deferred_to_the_end_of_the_agreement'),
      i18n.t('Application:benefitsAndRisksMessages.lower_regular_monthly_payments_than_traditional_hire_purchase'),
      i18n.t('Application:benefitsAndRisksMessages.your_choice_to_buy_a_more_expensive_vehicle'),
      i18n.t('Application:benefitsAndRisksMessages.future_value_guaranteed_by_the_lender'),
      i18n.t('Application:benefitsAndRisksMessages.option_to_hand_the_vehicle_back_subject_to_terms_conditions'),
      i18n.t('Application:benefitsAndRisksMessages.fixed_repayments_and_interest_rate'),
      i18n.t('Application:benefitsAndRisksMessages.can_settle_or_part_exchange_at_anytime')
    ],
    risks: [
      i18n.t(
        'Application:benefitsAndRisksMessages.you_wont_be_able_to_keep_the_vehicle_unless_you_repay_the_final_payment'
      ),
      i18n.t('Application:benefitsAndRisksMessages.no_benefit_if_general_interest_rates_go_down'),
      i18n.t(
        'Application:benefitsAndRisksMessages.the_lender_takes_security_in_the_vehicle_you_cannot_sell_or_give_the_vehicle_away_until_you_have_paid_all_payments_under_the_agreement'
      ),
      i18n.t(
        'Application:benefitsAndRisksMessages.vehicle_can_be_repossessed_if_you_dont_keep_your_repayments_up_to_date'
      ),
      i18n.t('Application:benefitsAndRisksMessages.finance_must_be_settled_when_you_sell_part_exchange_the_vehicle'),
      i18n.t(
        'Application:benefitsAndRisksMessages.excess_charges_will_apply_if_you_choose_to_hand_the_vehicle_back_in_a_poor_state_or_with_excess_mileage'
      ),
      i18n.t(
        'Application:benefitsAndRisksMessages.if_you_wish_to_keep_the_car_at_the_end_of_the_agreement_a_significant_portion_of_the_finance_will_need_to_be_paid_at_that_time_and_so_you_should_prepare_for_this'
      )
    ]
  },
  LP: {
    benefits: [
      i18n.t(
        'Application:benefitsAndRisksMessages.part_of_the_loan_is_deferred_to_the_end_of_the_agreement_for_future_repayment'
      ),
      i18n.t('Application:benefitsAndRisksMessages.lower_regular_monthly_payments_than_traditional_hire_purchase'),
      i18n.t('Application:benefitsAndRisksMessages.you_have_the_choice_to_buy_a_more_expensive_vehicle'),
      i18n.t('Application:benefitsAndRisksMessages.fixed_repayments_and_interest_rate'),
      i18n.t(
        'Application:benefitsAndRisksMessages.you_can_settle_your_agreement_or_part_exchange_your_vehicle_at_any_time'
      )
    ],
    risks: [
      i18n.t(
        'Application:benefitsAndRisksMessages.you_wont_be_able_to_keep_the_vehicle_unless_you_repay_the_final_payment'
      ),
      i18n.t('Application:benefitsAndRisksMessages.future_value_is_not_guaranteed_by_the_lender'),
      i18n.t(
        'Application:benefitsAndRisksMessages.no_benefit_to_you_if_general_interest_rates_go_down_as_your_agreement_has_a_fixed_interest_rate'
      ),
      i18n.t(
        'Application:benefitsAndRisksMessages.the_lender_takes_security_in_the_vehicle_you_cannot_sell_or_give_the_vehicle_away_until_you_have_paid_all_payments_under_the_agreement'
      ),
      i18n.t(
        'Application:benefitsAndRisksMessages.the_vehicle_can_be_repossessed_if_you_dont_keep_your_repayments_up_to_date'
      ),
      i18n.t('Application:benefitsAndRisksMessages.finance_must_be_settled_when_you_sell_part_exchange_the_vehicle')
    ]
  },
  FSCRE: {
    benefits: [
      i18n.t('Application:benefitsAndRisksMessages.fixed_repayments_and_interest_rate'),
      i18n.t(
        'Application:benefitsAndRisksMessages.you_have_the_option_to_reduce_the_term_of_the_personal_loan_to_repay_the_cost_of_value_added_products_or_negative_equity_earlier_than_the_cost_of_the_vehicle'
      ),
      i18n.t('Application:benefitsAndRisksMessages.you_can_settle_your_personal_loan_at_any_time')
    ],
    risks: [
      i18n.t(
        'Application:benefitsAndRisksMessages.if_you_choose_to_exercise_your_termination_rights_on_your_vehicle_loan_this_would_not_apply_to_your_personal_loan_s'
      )
    ]
  },
  FS: {
    benefits: [
      i18n.t('Application:benefitsAndRisksMessages.fixed_repayments_and_interest_rate'),
      i18n.t(
        'Application:benefitsAndRisksMessages.you_have_the_option_to_reduce_the_term_of_the_personal_loan_to_repay_the_cost_of_value_added_products_or_negative_equity_earlier_than_the_cost_of_the_vehicle'
      ),
      i18n.t('Application:benefitsAndRisksMessages.you_can_settle_your_personal_loan_at_any_time')
    ],
    risks: [
      i18n.t(
        'Application:benefitsAndRisksMessages.if_you_choose_to_exercise_your_termination_rights_on_your_vehicle_loan_this_would_not_apply_to_your_personal_loan_s'
      )
    ]
  }
});
export default benefitsAndRisksMessages;
