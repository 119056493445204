import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './manageUsersTableItem.scss';
import Moment from 'moment';
import CustomerEmail from '../../Customer/components/ConsumerRecordItems/CustomerEmail';
import { withNavigate } from 'hocs/router';

class ManageUsersTableItem extends React.Component {
  handleClick = () => {
    const { user, dealershipId, navigate } = this.props;

    if (user && user.id) {
      navigate(`/d/${dealershipId}/settings/users/${user.id}`);
    }
  };

  render() {
    const { user } = this.props;
    return (
      <tr className="manageUsersTableItem__row" onClick={this.handleClick}>
        <td className="manageUsersTableItem__all">
          <b>{user.firstName}</b>
        </td>
        <td className="manageUsersTableItem__all">
          <b>{user.surname}</b>
        </td>
        <td className="manageUsersTableItem__all">
          <CustomerEmail email={user.email} readOnly={true} />
        </td>
        <td className="manageUsersTableItem__all">{user.mobile}</td>
        <td className="manageUsersTableItem__all">
          {user.lastActive === -1
            ? this.props.t('ManageUsersTableItem.not_available')
            : Moment(user.lastActive).format('DD/MM/YYYY')}
        </td>
      </tr>
    );
  }
}

ManageUsersTableItem.propTypes = {
  user: PropTypes.object.isRequired,
  dealershipId: PropTypes.string.isRequired
};

export default withNavigate(withTranslation('ManageUsers')(ManageUsersTableItem));
