import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import ApplicationPage from '../../Application/components/ApplicationPage';
import CFCEmploymentHistoryForm from '../forms/CFCEmploymentHistoryForm';

const CFCEmploymentHistory = ({ defaultValues, onSubmit, validationRules }) => {
  const { t } = useTranslation('Quoting');
  return (
    <ApplicationPage
      title={t('CFCEmploymentHistory.employment_history')}
      subTitle={t('CFCEmploymentHistory.please_enter_at_least_3_years_of_employment_history')}
    >
      <CFCEmploymentHistoryForm defaultValues={defaultValues} onSubmit={onSubmit} validationRules={validationRules} />
    </ApplicationPage>
  );
};

CFCEmploymentHistory.propTypes = {
  defaultValues: PropTypes.object,
  validationRules: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};
export default CFCEmploymentHistory;
