import { useNavigate as useNavigate_ } from 'react-router-dom';
import { parsePath } from 'history';
import { useCallback } from 'react';
import { objectToSearchParams } from 'utils/objectToSearchParams';

/*
 * Adds backwards compatibility for query object. `query` should be phased out and replaced with:
 *
 * navigate({ search: createSearchParams(query) })
 */

export function useNavigate() {
  let navigate = useNavigate_();

  return useCallback(
    (to, options) => {
      if (typeof to === 'number') return navigate(to, options);

      let searchFromQuery = undefined;

      if (typeof to === 'object' && to.query) {
        searchFromQuery = objectToSearchParams(to.query).toString();
        delete to.query;
      }

      to = typeof to === 'string' ? parsePath(to) : to;
      return navigate({ ...to, search: searchFromQuery || to.search }, options);
    },
    [navigate]
  );
}
