import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as applicationActions from '../../../redux/application/applicationActions';
import ErrorApology from '../../Common/ErrorApology';
import * as componentActions from '../../../redux/component/componentActions';
import * as quickQuoteActions from '../../../redux/quickQuote/quickQuoteActions';
import * as modalActions from '../../../redux/modal/modalActions';
import * as consumerActions from '../../../redux/consumer/consumerActions';
import { getCustomer } from '../../../selectors/customerSelectors';
import { push } from '../../../routerHistory';
import { compose } from 'redux';
import { withParams } from 'hocs/router';
import { Outlet } from 'react-router-dom';

class CustomerQuoteContainer extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.load('CustomerQuoteContainer');
  }

  componentWillUnmount() {
    this.props.unload('CustomerQuoteContainer');
  }

  render() {
    const { children, ...remainingProps } = this.props;

    if (this.props.components.CustomerQuoteContainer && this.props.components.CustomerQuoteContainer.errorMessage) {
      return <ErrorApology>{this.props.components.CustomerQuoteContainer.errorMessage}</ErrorApology>;
    }

    if (!(this.props.components.CustomerQuoteContainer && this.props.components.CustomerQuoteContainer.hasLoaded)) {
      return null;
    }

    return <Outlet context={{ ...remainingProps }} />;
  }
}

CustomerQuoteContainer.propTypes = {
  children: PropTypes.any,
  load: PropTypes.func,
  unload: PropTypes.func,
  components: PropTypes.object,
  params: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  const customer = getCustomer(state, ownProps.params.consumerId);

  return {
    session: state.session,
    consumer: customer,
    application: state.application,
    assignees: customer ? customer.assignedTo : [],
    components: state.components
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    createApplication: (vehicle, quote, customerType) =>
      dispatch(applicationActions.createApplication(vehicle, quote, ownProps.params.consumerId, customerType)),
    onCancel: () => {
      const { dealershipId, consumerId } = ownProps.params;
      push(`/d/${dealershipId}/consumers/${consumerId}`);
    },
    load: (componentName) => dispatch(componentActions.componentLoad(componentName, ownProps.params)),
    unload: (componentName) => dispatch(componentActions.componentUnload(componentName, ownProps.params)),
    openSaveQuoteModal: (quote) => {
      dispatch(quickQuoteActions.changeQuote(quote));
      dispatch(modalActions.open('saveQuickQuote'));
    },
    saveCustomerQuote: (quote) => {
      dispatch(consumerActions.saveCustomerQuote(quote, ownProps.params.consumerId));
    },
    onSendDeal: (quotes) => {
      dispatch(modalActions.open('sendDeal', { quotes, ...ownProps.params }));
    }
  };
}

export default compose(withParams, connect(mapStateToProps, mapDispatchToProps))(CustomerQuoteContainer);
