import React from 'react';
import PropTypes from 'prop-types';
import './applicationFormWrapper.scss';

const ApplicationFormWrapper = ({ children }) => <div className="applicationFormWrapper">{children}</div>;

ApplicationFormWrapper.propTypes = {
  children: PropTypes.node
};

export default ApplicationFormWrapper;
