import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Chip } from './components';

import styles from './stockListComparisonCardValues.module.scss';

const StockListComparisonCardValues = ({ name, property, comparisonValue, isSuppliedVehicle }) => {
  const { t } = useTranslation('Stock');

  const renderChip = () => {
    if (
      property &&
      property
        .toString()
        .toUpperCase()
        .localeCompare(comparisonValue.toUpperCase()) === 0
    ) {
      return (
        <span className={styles.chip}>
          {isSuppliedVehicle ? (
            <div className={styles.verticalSpace} />
          ) : (
            <Chip intent={'success'}>{t('StockListComparisonCardValues.matched')}</Chip>
          )}
        </span>
      );
    }
    return (
      <span className={styles.chip}>
        <Chip intent={'error'}>{t('StockListComparisonCardValues.not_matched')}</Chip>
      </span>
    );
  };

  return (
    <>
      {comparisonValue && renderChip()}
      <div className={styles.stockListComparisonCardValues}>
        <span className={styles.title}>{name}</span>
        <span className={styles.value}>{` ${property || '-'}`}</span>
      </div>
    </>
  );
};

StockListComparisonCardValues.propTypes = {
  name: PropTypes.string,
  property: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default StockListComparisonCardValues;
