import cn from 'classnames';
import { DisplayQuoteFormat, QuoteRow, QuoteRowType, QuoteTypes } from '../../types';
import { QuoteRowLabel } from '../QuoteRowInfo/QuoteRowLabel';
import { QuoteRowValue } from '../QuoteRowInfo/QuoteRowValue';
import { useTranslation } from 'react-i18next';

export type HeaderFiguresData = Pick<DisplayQuoteFormat, 'Apr' | 'AgreementTerm' | 'TotalAmountPayable'> & {
  MonthlyPayment: QuoteRow;
};
export const getHeaderFigures = (quote: QuoteTypes, displayQuote: DisplayQuoteFormat): HeaderFiguresData => ({
  MonthlyPayment: {
    value: quote.FollowingPayments,
    type: QuoteRowType.money
  },
  Apr: displayQuote.Apr,
  AgreementTerm: displayQuote.AgreementTerm,
  TotalAmountPayable: displayQuote.TotalAmountPayable
});

type HeaderFiguresProps = {
  data: HeaderFiguresData;
  canShowIndicativeAprTag: boolean | undefined;
  canShowApplicationStatus: boolean | undefined;
};
type KeysData = keyof HeaderFiguresData;
export const HeaderFigures = ({ data, canShowIndicativeAprTag, canShowApplicationStatus }: HeaderFiguresProps) => {
  const { t } = useTranslation('QuoteCard');

  const formattedHeaderItems = (Object.keys(data) as KeysData[]).map((label, index) => {
    return (
      <div
        className={cn('quoteCard__cell', {
          'quoteCard__cell--withApplicationStatus': canShowApplicationStatus
        })}
        key={`quote-card-figures-item-${label}-${index}`}
      >
        <dt className="quoteCard__cellValue">
          {/* @ts-ignore */}
          <QuoteRowValue label={label} value={data[label]} />
        </dt>
        <dd className="quoteCard__cellDescription">
          {/* @ts-ignore */}
          <QuoteRowLabel label={label} value={data[label]} translationModule="QuoteCardFigures" />{' '}
          {label === 'Apr' && canShowIndicativeAprTag && (
            <span className="quoteCard__alertTag" data-th="indicativeOnly">
              {t('QuoteCardFigures.indicative_only')}
            </span>
          )}
        </dd>
      </div>
    );
  });

  return <>{formattedHeaderItems}</>;
};
