import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './panel.scss';

const Panel = (props) => (
  <div className={cn(props.disabled ? 'panel__outer panel__isDisabled' : 'panel__outer', props.className)}>
    {props.children}
  </div>
);

Panel.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  noMargin: PropTypes.bool,
  className: PropTypes.string
};

export default Panel;
