import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Common/Icon/Icon';
import { useTranslation } from 'react-i18next';
import './toggleButtonWithIcon.scss';

const ToggleButtonWithIcon = ({ onClick, hideDetails, icon, text, children, showLabel, hideLabel }) => {
  const { t } = useTranslation('Application');

  const showText = showLabel ?? t('ToggleButtonWithIcon.show');
  const hideText = hideLabel ?? t('ToggleButtonWithIcon.hide');

  return (
    <button type="button" className="toggleButtonWithIcon" onClick={onClick}>
      <span className="toggleButtonWithIcon__icon">
        <Icon name={icon} />
      </span>
      <span className="toggleButtonWithIcon__text">
        {hideDetails ? showText : hideText} {text || children}
      </span>
    </button>
  );
};

ToggleButtonWithIcon.defaultProps = {
  hideDetails: false,
  icon: 'eye'
};

ToggleButtonWithIcon.propTypes = {
  onClick: PropTypes.func,
  hideDetails: PropTypes.bool,
  icon: PropTypes.string,
  children: PropTypes.node,
  text: PropTypes.string,
  hideLabel: PropTypes.string,
  showLabel: PropTypes.string
};

export default ToggleButtonWithIcon;
