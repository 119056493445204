import React from 'react';
import PropTypes from 'prop-types';
import './tabList.scss';
import classnames from 'classnames';
import _ from 'lodash';
import nextArrow from '../../../styles/icons/arrow_right.svg';
import prevArrow from '../../../styles/icons/arrow_left.svg';
import TabStyle from './TabStyle';

class TabList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contentOverflows: false
    };

    this.onResize = _.throttle(this.onResize, 300);
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentDidMount() {
    this.onResize();
    this.scrollTo(this.props.currentTab);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    if (this.refs.containerInner && this.refs.containerOuter) {
      this.setState({
        contentOverflows: this.refs.containerInner.offsetWidth > this.refs.containerOuter.offsetWidth
      });
    }
  };

  scrollTo = (targetTab) => {
    if (this.refs.containerOuter) {
      this.refs.containerOuter.scrollLeft = (targetTab - 1) * 200;
    }
  };

  nextTab = () => {
    this.setTab(this.props.currentTab + 1);
  };

  prevTab = () => {
    this.setTab(this.props.currentTab - 1);
  };

  setTab(index) {
    if (index !== this.props.currentTab) {
      this.props.onClick(index);
      this.scrollTo(index);
    }
  }

  render() {
    let { currentTab } = this.props;
    const children = React.Children.toArray(this.props.children);
    let currentTabComputed = currentTab > children.length - 1 ? children.length - 1 : currentTab;

    const tablListStyles = classnames(
      'tabList',
      `tablist__${this.props.tabStyle}--background`,
      this.props.hasShadowSpace && 'tabList__hasShadowSpace'
    );

    return (
      <div className={tablListStyles}>
        <div className="tabList__outer" ref="containerOuter">
          <div className="tabList__inner" ref="containerInner">
            <div className={`tablist__${this.props.tabStyle}`} />
            {children.map((child, index) => (
              <div key={`child${index}`} className="tabList__tab">
                <TabStyle
                  tabStyle={this.props.tabStyle}
                  onClick={() => this.setTab(index)}
                  isActive={currentTabComputed === index}
                >
                  {React.cloneElement(child, { isActive: currentTabComputed === index })}
                </TabStyle>
              </div>
            ))}
          </div>
        </div>

        {this.state.contentOverflows && currentTabComputed > 0 ? (
          <div className="tabList__prevArrow" onClick={this.prevTab}>
            <img src={prevArrow} className="tabList__arrowIcon" />
          </div>
        ) : (
          ''
        )}
        {this.state.contentOverflows && currentTabComputed < children.length - 1 ? (
          <div className="tabList__nextArrow" onClick={this.nextTab}>
            <img src={nextArrow} className="tabList__arrowIcon" />
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

TabList.propTypes = {
  children: PropTypes.node,
  tabStyle: PropTypes.string,
  currentTab: PropTypes.number,
  onClick: PropTypes.func
};

TabList.defaultProps = {
  tabStyle: 'default'
};

export default TabList;
