import platformApiUtils from '@ivendi/platform-api-utils';
import { useMutation } from '@tanstack/react-query';

export type LookupPostcodeResult = {
  Id: string;
  Suggestion: string;
  IsRetrievable: boolean;
};

export type LookupAddressResultRaw = {
  Id: string;
  FromCache: boolean;
  Company: string;
  Line1: string;
  Line2: string;
  City: string;
  ProvinceName: string;
  CountryName: string;
  PostalCode: string;
  Type: string;
  District: string;
  SubBuilding: string;
  BuildingNumber: string;
  BuildingName: string;
  Street: string;
  Street2: string | null;
  Label: string;
  LookupAdjustmentsApplied: boolean;
};

export type LookupAddressResult = {
  BuildingNumber: string;
  PostTown: string;
  Company: string;
  CountryName: string;
  District: string;
  Postcode: string;
  ProvinceName: string;
  Street: string;
  SubBuilding: string;
  BuildingName: string;
};

export function lookupPostcode(countryCode: string, postcode: string) {
  return platformApiUtils
    .post<LookupPostcodeResult[]>(`v2/addresses/intl-address/${countryCode}`, {
      Postcode: postcode
    })
    .then((data) => {
      if (!data || !data.length) {
        throw new Error('Address not found.');
      }
      return data;
    });
}

export function lookupAddressId(addressId: string) {
  return platformApiUtils
    .post<LookupAddressResultRaw>(`v2/addresses/intl-address-resolve/${addressId}`)
    .then((address) => ({
      BuildingName: address.BuildingName,
      BuildingNumber: address.BuildingNumber,
      PostTown: address.City,
      Company: address.Company,
      CountryName: address.CountryName,
      District: address.District,
      Postcode: address.PostalCode,
      ProvinceName: address.ProvinceName,
      Street: address.Street,
      SubBuilding: address.SubBuilding
    }));
}

export const usePostcodeLookup = (countryCode: string, onSuccess: (results: LookupPostcodeResult[]) => void) => {
  return useMutation({
    mutationFn: (postCode: string) => lookupPostcode(countryCode.toUpperCase(), postCode),
    onSuccess
  });
};

export const useAddressLookup = (onSuccess: (address: LookupAddressResult) => void) => {
  return useMutation({
    mutationFn: (addressId: string) => lookupAddressId(addressId),
    onSuccess
  });
};
