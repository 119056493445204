import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import './valueAddedProductSettings.scss';
import LoadingDots from '../Common/Loading/LoadingDots';
import Page from '../Common/Page';
import Panel from '../Common/Panel';
import PanelContent from '../Common/PanelContent';
import PanelHeader from '../Common/PanelHeader';
import { compose } from 'redux';
import { withParams } from 'hocs/router';
import { Outlet } from 'react-router-dom';

class ValueAddedProductContainer extends React.Component {
  UNSAFE_componentWillMount() {
    let {
      appStore: { vapStore }
    } = this.props;
    vapStore.fetchValueAddedProducts(this.props.params.dealershipId);
    vapStore.fetchProductTypes();
  }

  render() {
    if (this.props.appStore.vapStore.isLoading) {
      return (
        <div className="valueAddedProductSettings__loading">
          <LoadingDots />
        </div>
      );
    }

    if (this.props.appStore.vapStore.hasLoadingError) {
      return (
        <Page>
          <Panel>
            <PanelHeader>{this.props.t('ValueAddedProductContainer.value_added_products')}</PanelHeader>
            <PanelContent>
              <div className="valueAddedProductSettings__errorMessage">
                {this.props.t('ValueAddedProductContainer.error_loading_your_vap_try_again_later')}
              </div>
            </PanelContent>
          </Panel>
        </Page>
      );
    }

    return <Outlet />;
  }
}

ValueAddedProductContainer.propTypes = {
  params: PropTypes.object,
  appStore: PropTypes.object,
  children: PropTypes.node
};
export default compose(
  withParams,
  withTranslation('ValueAddedProductSettings'),
  inject(['appStore']),
  observer
)(ValueAddedProductContainer);
