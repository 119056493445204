import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import './select.scss';

class Select extends React.Component {
  static propTypes = {
    options: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    optionsList: PropTypes.any,
    isInvalid: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    theme: PropTypes.string,
    emptyValue: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    size: PropTypes.string,
    dataThook: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    this.setState({
      value: nextprops.value === undefined || nextprops.value === null ? '' : nextprops.value
    });
  }

  getOptions() {
    if (typeof this.props.options === 'object') {
      return this.props.options;
    }

    return this.props.optionsList[this.props.options] || [];
  }

  render() {
    let options = [];
    const selectStyles = classnames(this.props.className, {
      select: !this.props.theme,
      'select--alt': this.props.theme === 'alt' ? true : false,
      'select--isInvalid': this.props.isInvalid,
      'select--small': this.props.size === 'small' ? true : false
    });

    if (this.props.emptyValue) {
      options.push({ key: '', value: this.props.t('Select.please_select') });
    }

    options = options.concat(this.getOptions());

    const selectedValue = this.props.value ? this.props.value.toString() : '';

    const dataAttrs = {};

    if (this.props.isInvalid) {
      dataAttrs['data-is-invalid'] = true;
    }

    return (
      <select
        className={selectStyles}
        value={this.state.value}
        onChange={this.props.onChange}
        id={this.props.id}
        onBlur={this.props.onBlur}
        disabled={this.props.disabled}
        data-th={this.props.dataThook ? `${this.props.dataThook}Select` : `${this.props.id}Select`}
        {...dataAttrs}
      >
        {options.map((option, i) => (
          <option key={i} value={option.key} defaultValue={selectedValue}>
            {option.value}
          </option>
        ))}
      </select>
    );
  }
}

Select.defaultProps = {
  options: {},
  style: 'default',
  emptyValue: true
};

function mapStateToProps(state) {
  return {
    optionsList: state.options
  };
}

export default withTranslation('Common')(connect(mapStateToProps)(Select));
