import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';

const PaginationSummary = (props) => {
  const { t } = useTranslation('Common');
  return <div>{t('PaginationSummary.showing_results', { Total: props.total })}</div>;
};

PaginationSummary.propTypes = {
  total: PropTypes.number
};
export default PaginationSummary;
