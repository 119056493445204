import React from 'react';
import PropTypes from 'prop-types';
import './exclamationWarningWithButton.scss';
import helpIcon from '../../styles/icons/i.svg';
import Image from './Image';
import Button from './Button/Button';

const ExclamationWarningWithButton = ({ title, message, onClick, buttonText }) => {
  return (
    <div className="exclamationWarningWithButton">
      <div className="exclamationWarningWithButton__table">
        <div className="exclamationWarningWithButton__tableRow">
          <div className="exclamationWarningWithButton__imageOuter">
            <div className="exclamationWarningWithButton__image">
              <Image imageName={helpIcon} />
            </div>
          </div>

          <div className="exclamationWarningWithButton__body">
            {title && <span className="exclamationWarningWithButton__title">{title}</span>}
            <span>{message}</span>
          </div>
          <div className="exclamationWarningWithButton__buttonOuter" key="buttonContainer">
            <Button size="small" onClick={onClick} buttonStyle="secondary" type="button">
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

ExclamationWarningWithButton.propTypes = {
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  buttonText: PropTypes.string
};

export default ExclamationWarningWithButton;
