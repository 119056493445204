import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  draft.applicationFields.AffordabilityDetails!.ForeseeFutureDownturnInFinances = false;
  draft.applicationFields.AffordabilityDetails!.DownturnReason = false;
  draft.applicationFields.AffordabilityDetails!.IsReplacementLoan = false;
  draft.applicationFields.AffordabilityDetails!.DetailsOfReplacementLoan = false;
  draft.applicationFields.AffordabilityDetails!.MortgageOrRental = true;
  draft.applicationFields.AffordabilityDetails!.ChangesExpected = true;

  draft.validationRules.AffordabilityDetails!.Income!.NetMonthly = 'optional, currency';
  delete draft.validationRules.AffordabilityDetails!.ReplacementLoan;
  delete draft.validationRules.AffordabilityDetails!.PersonalCircumstances!.ForeseeFutureDownturnInFinances;
  delete draft.validationRules.AffordabilityDetails!.PersonalCircumstances!.DownturnReason;
  draft.validationRules.AffordabilityDetails!.PersonalCircumstances!.ChangesExpected = 'required';
  draft.validationRules.AffordabilityDetails!.PersonalCircumstances!.DetailsOfExpectedChanges =
    'required:iftrue:ChangesExpected';
  draft.validationRules.AffordabilityDetails!.MonthlyExpenditure!.MortgageOrRental = 'currency';
});
