import platformApiUtils from '@ivendi/platform-api-utils';

export const getOrders = (dealershipId, page = 1, pageSize = 5) => {
  return new Promise((resolve, reject) => {
    platformApiUtils
      .get(`v1/orders/${dealershipId}?page=${page}&pageSize=${pageSize}`)
      .then(resolve)
      .catch(reject);
  });
};

export const getOrdersCustomerRecord = (
  dealershipId,
  page = 1,
  pageSize = 5,
  DealershipConsumerId,
  VehicleOfInterestId
) => {
  return new Promise(function(resolve, reject) {
    platformApiUtils
      .post(`v1/orders/${dealershipId}?page=${page}&pageSize=${pageSize}`, {
        DealershipConsumerId,
        VehicleOfInterestId
      })
      .then(resolve)
      .catch(reject);
  });
};

export const getOrder = (dealershipId, orderId) => {
  return new Promise((resolve, reject) => {
    platformApiUtils
      .get(`v1/orders/${dealershipId}/${orderId}`)
      .then(resolve)
      .catch(reject);
  });
};
