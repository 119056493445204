import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Page from '../../Common/Page';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import PanelToolBar from '../../Common/PanelToolBar';
import Pagination from '../../Common/Pagination';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Select from '../../Common/Form/Select';
import SearchDate from '../../Common/SearchDate';
import LatestDealCard from '../components/LatestDealCard';
import AlignRight from '../../Common/AlignRight';
import { compose } from 'redux';
import { withParams } from 'hocs/router';

class LatestDealsPage extends Component {
  handleStatusFilter = (event) => {
    this.props.setStatusFilter(event.currentTarget.value);
  };

  render() {
    const statusOptions = [].concat(
      [
        {
          key: '',
          value: this.props.t('LatestDealsPage.all')
        }
      ],
      this.props.statuses
    );
    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: this.props.t('LatestDealsPage.home'),
              path: `/d/${this.props.params.dealershipId}`
            },
            {
              name: this.props.t('LatestDealsPage.latest_deals')
            }
          ]}
        />
        <Panel>
          <PanelHeader>{this.props.t('LatestDealsPage.latest_deals')}</PanelHeader>
          <PanelToolBar>
            <AlignRight>
              <div>
                <span>{this.props.t('LatestDealsPage.status')}</span>
                <Select
                  theme="alt"
                  options={statusOptions}
                  emptyValue={false}
                  value={this.props.status}
                  onChange={this.handleStatusFilter}
                />
              </div>
            </AlignRight>
            <AlignRight>
              <div>
                <span>{this.props.t('LatestDealsPage.date_time')}</span>
                <SearchDate value={this.props.timePeriod} onChange={this.props.handleSearchDateChange} />
              </div>
            </AlignRight>
          </PanelToolBar>
          <PanelContent>
            {this.props.latestDeals.map((deal, index) => (
              <LatestDealCard
                deal={deal}
                handleConsumer={this.props.handleConsumer}
                handleApplicationClick={this.props.handleApplicationClick}
                key={'card' + index}
              />
            ))}
            <Pagination
              currentPage={this.props.page}
              totalItems={this.props.total}
              pageSize={this.props.pageSize}
              onChange={this.props.handleLatestDealsPage}
            />
          </PanelContent>
        </Panel>
      </Page>
    );
  }
}

LatestDealsPage.propTypes = {
  latestDeals: PropTypes.array,
  handleConsumer: PropTypes.func,
  handleApplicationClick: PropTypes.func,
  options: PropTypes.object,
  handleSearchDateChange: PropTypes.func,
  handleLatestDealsPage: PropTypes.func,
  setStatusFilter: PropTypes.func,
  timePeriod: PropTypes.string,
  statuses: PropTypes.array,
  status: PropTypes.string,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  total: PropTypes.number
};
export default compose(withParams, withTranslation('LatestDeals'))(LatestDealsPage);
