import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  draft.applicationFields.AffordabilityDetails = {
    GrossAnnual: true,
    NetMonthly: false
  };

  delete draft.validationRules.AffordabilityDetails!.Income!.NetMonthly;
  draft.validationRules.AffordabilityDetails = {
    Income: {
      GrossAnnual: 'required, currency'
    }
  };
});
