import React from 'react';
import PropTypes from 'prop-types';
import './cardCelLabel.scss';

const CardCellLabel = ({ children, label }) => (
  <div>
    <div className="cardCellLabel">{label}</div>
    {children}
  </div>
);

CardCellLabel.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string
};

export default CardCellLabel;
