import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import SettingsDealership from '../components/SettingsDealership';
import { connect } from 'react-redux';
import {
  fetchDealershipSettings,
  saveDealershipSettings,
  cleanupDealershipSettings
} from '../../../redux/settings/settingsActions';
import ErrorApology from '../../Common/ErrorApology';
import LoadingDots from '../../Common/Loading/LoadingDots';
import { compose } from 'redux';
import { withParams } from 'hocs/router';

class SettingsDealershipContainer extends React.Component {
  componentDidMount() {
    this.props.fetchDealershipSettings(this.props.params.dealershipId);
  }

  componentWillUnmount() {
    this.props.cleanupDealershipSettings();
  }

  render() {
    const { children, ...remainingProps } = this.props;

    if (this.props.hasError) {
      return <ErrorApology>{this.props.t('SettingsDealershipContainer.error_fetching_settings')}</ErrorApology>;
    }

    if (!this.props.hasLoaded) {
      return <LoadingDots />;
    }

    return <SettingsDealership {...remainingProps} />;
  }
}

SettingsDealershipContainer.propTypes = {
  children: PropTypes.node,
  params: PropTypes.object,
  fetchDealershipSettings: PropTypes.func,
  cleanupDealershipSettings: PropTypes.func,
  hasError: PropTypes.bool,
  hasLoaded: PropTypes
};

function mapStateToProps(state) {
  return {
    options: state.options,
    session: state.session,
    globalDefaultSettings: state.globalDefaultSettings,
    hasLoaded: state.globalDefaultSettings.hasLoaded,
    hasError: state.globalDefaultSettings.hasError,
    dealership: state.dealership
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    fetchDealershipSettings: (dealershipId) => dispatch(fetchDealershipSettings(dealershipId)),
    saveDealershipSettings: (formData) => dispatch(saveDealershipSettings(ownProps.params.dealershipId, formData)),
    cleanupDealershipSettings: () => dispatch(cleanupDealershipSettings())
  };
}

export default compose(
  withParams,
  withTranslation('DealershipSettings'),
  connect(mapStateToProps, mapDispatchToProps)
)(SettingsDealershipContainer);
