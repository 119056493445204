import en from '../../styles/icons/flags/en.svg';
import de from '../../styles/icons/flags/de.svg';
import es from '../../styles/icons/flags/es.svg';
import fr from '../../styles/icons/flags/fr.svg';
import it from '../../styles/icons/flags/it.svg';

const languages = [
  { language: 'en', imageSrc: en, label: 'English (UK)' },
  { language: 'de', imageSrc: de, label: 'Deutsch' },
  { language: 'es', imageSrc: es, label: 'Español' },
  { language: 'fr', imageSrc: fr, label: 'Français' },
  { language: 'it', imageSrc: it, label: 'Italiano' }
];
export default languages;
