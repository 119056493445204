import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Page from '../../Common/Page';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import { observer, inject } from 'mobx-react';
import Breadcrumbs from '../../Common/Breadcrumbs';
import SettingsAccountViewDetails from './SettingsAccountViewDetails';
import SettingsAccountEditDetails from './SettingsAccountEditDetails';
import LoadingSpinner from '../../Common/Loading/LoadingSpinner';
import { withOutletContextProps, withParams } from 'hocs/router';

class User extends React.Component {
  componentDidMount() {
    const { dealershipId, userId } = this.props.params;
    this.props.appStore.userStore.fetchUser(dealershipId, userId);
  }

  render() {
    const {
      userId,
      dealershipId,
      currentUserId,
      saveUserDetails,
      removeUser,
      isSubmitting,
      hasSubmittingError,
      isRemoving,
      hasRemovingError
    } = this.props;
    const { user } = this.props.appStore.userStore;
    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: this.props.t('User.home'),
              path: `/d/${dealershipId}`
            },
            {
              name: this.props.t('User.settings'),
              path: `/d/${dealershipId}/settings`
            },
            {
              name: this.props.t('User.manage_users'),
              path: `/d/${dealershipId}/settings/users`
            },
            {
              name: this.props.t('User.account_settings'),
              path: `/d/${dealershipId}/settings/users/${userId}`
            }
          ]}
        />
        <Panel>
          <PanelHeader>{this.props.t('User.user_details')}</PanelHeader>
          <PanelContent>
            {user && user.claims ? (
              <>
                {this.props.appStore.uiState.canEditAccountSettings ? (
                  <SettingsAccountEditDetails
                    user={user}
                    currentUser={currentUserId}
                    dealershipId={dealershipId}
                    onSubmit={saveUserDetails}
                    isSubmitting={isSubmitting}
                    hasSubmittingError={hasSubmittingError}
                    goBackLink={`/d/${dealershipId}/settings/users`}
                    handleRemove={removeUser}
                    isRemoving={isRemoving}
                    hasRemovingError={hasRemovingError}
                    trackingPage="AccountSettingsManageUser"
                  />
                ) : (
                  <SettingsAccountViewDetails
                    trackingPage="AccountSettingsManageUser"
                    currentUser={currentUserId}
                    user={user}
                    dealershipId={dealershipId}
                  />
                )}
              </>
            ) : (
              <div className="accountSettings__loading">
                <LoadingSpinner />
              </div>
            )}
          </PanelContent>
        </Panel>
      </Page>
    );
  }
}

User.propTypes = {
  userId: PropTypes.string,
  dealershipId: PropTypes.string,
  users: PropTypes.object,
  currentUserId: PropTypes.string,
  saveUserDetails: PropTypes.func,
  removeUser: PropTypes.func,
  isSubmitting: PropTypes.bool,
  hasSubmittingError: PropTypes.bool,
  isRemoving: PropTypes.bool,
  hasRemovingError: PropTypes.bool,
  params: PropTypes.object,
  appStore: PropTypes.object
};
User.defaultProps = {
  users: []
};
export default withOutletContextProps(withParams(withTranslation('ManageUsers')(inject(['appStore'])(observer(User)))));
