import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { getLenderConfig } from '../../../core/lenders';
import { getCfcProgress } from '../../../core/applicationProgress';
import './checkEligibility.scss';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import CFCNavigation from './CFCNavigation';
import CFCPersonalDetails from './CFCPersonalDetails';
import CFCAddressHistory from './CFCAddressHistory';
import CFCEmploymentHistory from './CFCEmploymentHistory';
import CFCSummary from './CFCSummary';
import LoadingDots from '../../Common/Loading/LoadingDots';
import { withLocation } from 'hocs/router';

class CheckEligibility extends React.Component {
  UNSAFE_componentWillMount() {
    this.setState({
      section: 'personal',
      validationRules: getLenderConfig('def', 'cfc', 'validationRules'),
      applicationFields: getLenderConfig('def', 'cfc', 'applicationFields')
    });
    window.ga && window.ga('send', 'pageview', this.props.location.pathname + '?section=check_eligibility_view');
  }

  handleSectionSubmit = (sectionName, formData) => {
    this.props.onSectionSubmit(sectionName, formData);

    if (sectionName === 'PersonalDetails') {
      this.setState({
        section: 'address'
      });
    } else if (sectionName === 'AddressHistory') {
      this.setState({
        section: 'employment'
      });
    } else if (sectionName === 'EmploymentHistory') {
      this.setState({
        section: 'summary'
      });
    }

    this.scrollToTop();
  };
  handleSectionChange = (section) => {
    this.setState({
      section
    });
    this.scrollToTop();
  };

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  render() {
    if (this.props.isLoadingPreFill) {
      return (
        <div>
          <LoadingDots />
        </div>
      );
    }

    let progress = getCfcProgress(this.props.eligibilityCheckDetails);
    let navigationItems = [
      {
        id: 'personal',
        label: this.props.t('CheckEligibility.personal_details'),
        image: 'consumer',
        progress: progress.personal
      },
      {
        id: 'address',
        label: this.props.t('CheckEligibility.address'),
        image: 'location',
        progress: progress.address
      },
      {
        id: 'employment',
        label: this.props.t('CheckEligibility.employment'),
        image: 'employment',
        progress: progress.employment
      },
      {
        id: 'summary',
        label: this.props.t('CheckEligibility.summary'),
        image: 'summary',
        progress: progress.total
      }
    ];
    navigationItems = navigationItems.map((item) => {
      return { ...item, isActive: item.id === this.state.section };
    });
    return (
      <Panel>
        <PanelHeader>{this.props.t('CheckEligibility.finance_eligibility_check')}</PanelHeader>
        <div className="application__outer">
          <div className="checkEligibility__navContainer" key="nav">
            <CFCNavigation items={navigationItems} onChange={this.handleSectionChange} />
          </div>

          <div className="checkEligibility__formContainer" key="formContainer">
            {this.state.section === 'personal' && (
              <CFCPersonalDetails
                defaultValues={this.props.eligibilityCheckDetails.PersonalDetails}
                validationRules={this.state.validationRules.PersonalDetails}
                onSubmit={(formData) => this.handleSectionSubmit('PersonalDetails', formData)}
                allowEmailToBeChanged={!this.props.customerEmail}
                dealershipId={this.props.dealershipId}
              />
            )}

            {this.state.section === 'address' && (
              <CFCAddressHistory
                defaultValues={this.props.eligibilityCheckDetails.AddressHistory}
                validationRules={this.state.validationRules.AddressHistory}
                onSubmit={(formData) => this.handleSectionSubmit('AddressHistory', formData)}
                dealershipId={this.props.dealershipId}
                progress={progress}
                application={this.props.eligibilityCheckDetails}
              />
            )}

            {this.state.section === 'employment' && (
              <CFCEmploymentHistory
                defaultValues={this.props.eligibilityCheckDetails.EmploymentHistory}
                validationRules={this.state.validationRules.EmploymentHistory}
                onSubmit={(formData) => this.handleSectionSubmit('EmploymentHistory', formData)}
                progress={progress}
              />
            )}

            {this.state.section === 'summary' && (
              <CFCSummary
                cfcCheck={this.props.eligibilityCheckDetails}
                validationRules={this.state.validationRules}
                vehicle={this.props.vehicle}
                onSubmit={this.props.onSubmit}
                checkingEligibilityState={this.props.checkingEligibilityState}
                hasAddressError={this.props.hasAddressError}
              />
            )}
          </div>
        </div>
      </Panel>
    );
  }
}

CheckEligibility.propTypes = {
  vehicle: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  eligibilityCheckDetails: PropTypes.object,
  isLoadingPreFill: PropTypes.bool,
  onSectionSubmit: PropTypes.func,
  checkingEligibilityState: PropTypes.string,
  hasAddressError: PropTypes.bool,
  customerEmail: PropTypes.string,
  dealershipId: PropTypes.string,
  location: PropTypes.object
};
export default withLocation(withTranslation('Quoting')(CheckEligibility));
