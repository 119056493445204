import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DashboardTile from './DashboardTile';
import './flipTileOld.scss';

class FlipTile extends Component {
  UNSAFE_componentWillMount() {
    this.setState({
      key: Date.now()
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.Time !== nextProps.Time) {
      this.setState({
        key: Date.now()
      });
    }
  }

  render() {
    return (
      <div className="flipTileOld__outer">
        <div className="dashBoardTileOld__outer">
          <DashboardTile
            key={this.state.key}
            count={this.props.Count}
            type={this.props.Type}
            time={this.props.Time}
            to={this.props.to}
          />
        </div>
      </div>
    );
  }
}

FlipTile.propTypes = {
  Type: PropTypes.string,
  Time: PropTypes.string,
  Count: PropTypes.number,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

FlipTile.defaultProps = {
  items: []
};

export default FlipTile;
