import { inject } from 'mobx-react';
import { useCallback, useState } from 'react';
import ChartContainer from '~/components/Insights/Charts/ChartContainer/ChartContainer';
import ChartDataFilters from '~/components/Insights/Filters/ChartFilters/ChartDataFilters';
import LineChart from '~/components/Insights/Charts/LineChart/LineChart';
import { AppStoreType, ChartName, FiltersStateType } from '~/components/Insights/types';
import getReportConfig, { getDefaultFilters } from '~/components/Insights/services/utils/getReportConfig';
import s from '../charts.module.scss';

const SubmittedApplications = ({ appStore }: { appStore: AppStoreType }) => {
  const reportConfig = getReportConfig(ChartName.SubmittedApplications, appStore);

  const [filters, setFilters] = useState<FiltersStateType>(getDefaultFilters());

  const handleFilterChange = useCallback(
    (chartFilters: FiltersStateType) => {
      setFilters(chartFilters);
    },
    [setFilters]
  );

  return (
    <ChartContainer>
      <div className={s['chartFilter']}>
        <ChartDataFilters reportConfig={reportConfig} onFilterChange={handleFilterChange} />
      </div>
      <div className={s['chartWrapper']}>
        <LineChart filters={filters} reportConfig={reportConfig} />
      </div>
    </ChartContainer>
  );
};

export default inject('appStore')(SubmittedApplications);

