const setCookie = (cname: string, cvalue: string, exMins: number) => {
  // E.G.: To remove a cookie: setCookie('cookie-name', '', 0);

  const d = new Date();
  d.setTime(d.getTime() + exMins * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  const path = `path=/`;
  const domain = window.location.hostname.includes('localhost')
    ? window.location.hostname
    : `.${window.location.hostname}`;

  document.cookie = `${cname}=${cvalue};${expires};${path};domain=${domain};`;
};

export default setCookie;
