import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Common/Icon/Icon';
import DateFormat from '../../../Common/DateFormat';
import './vehicleRegYear.scss';

type VehicleRegYearProps = {
  vehicle: { RegistrationDate: string };
  isSmall?: boolean;
  appStore?: any;
};

const VehicleRegYear = ({ vehicle, isSmall, appStore }: VehicleRegYearProps) => {
  const { t } = useTranslation('Customer');
  let classes = classnames(!isSmall && 'vehicleRegYear', isSmall && 'vehicleRegYear--small');
  const { dateFormat } = appStore.uiState;
  const { RegistrationDate } = vehicle;
  const parsedDate = moment(RegistrationDate, [dateFormat, 'DD/MM/YYYY'], true);
  const isDateValid = parsedDate.isValid() && parsedDate.year() > 1900;

  return (
    <div className={classes} data-th="VehicleRegYear">
      <div className="vehicleRegYear__icon">
        <Icon name="calendar" />
      </div>
      <div className="vehicleRegYear__text">
        {isDateValid ? (
          <DateFormat parse="utc" value={parsedDate.valueOf()} format={dateFormat} />
        ) : (
          t('VehicleRegYear.no_registration_date')
        )}
      </div>
    </div>
  );
};

export default inject('appStore')(VehicleRegYear);
