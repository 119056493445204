import platformApiUtils from '@ivendi/platform-api-utils';
import * as actionTypes from '../actionTypes';
import moment from 'moment';
import uuid from 'uuid';
import { push } from 'routerHistory';
import { VehicleClassJs } from '~/types/vehicle';

export function clearError() {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_CONSUMER_VEHICLE_ERROR_CLEAR });
  };
}

export function addVehicle(consumerId, vehicleData, customerType, dealershipId) {
  const vehicle = Object.assign({}, vehicleData, {
    consumerId: consumerId,
    VehicleId: uuid.v4(),
    CreatedTimestamp: parseInt(moment().format('x'), 10)
  });

  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_CONSUMER_VEHICLE });

    if (vehicle.VehicleClass === VehicleClassJs.bike) {
      vehicle.VehicleClass = VehicleClassJs.motorbike;
    }

    if (vehicle.Condition === 'new' || vehicle.VehicleClass === VehicleClassJs.touringCaravan) {
      vehicle.Mileage = 0;
    }

    platformApiUtils
      .post('v2/customer/' + vehicle.consumerId + '/vehicleofinterest', {
        CustomerType: customerType,
        Id: vehicle.consumerId,
        VehicleId: vehicle.VehicleId,
        Make: vehicle.Make,
        Model: vehicle.Model,
        Derivative: vehicle.Derivative,
        MakeId: vehicle.MakeId || -1,
        ModelId: vehicle.ModelId || -1,
        CapId: vehicle.DerivativeId || -1,
        GlassId: vehicle.GlassId,
        Class: vehicle.Class,
        Condition: vehicle.Condition,
        RegistrationDate: vehicle.RegistrationDate,
        Mileage: vehicle.Mileage,
        Vin: vehicle.Vin,
        Vrm: vehicle.Vrm
      })
      .then(
        () => {
          dispatch({
            type: actionTypes.ADD_CONSUMER_VEHICLE_SUCCESS,
            customerId: vehicle.consumerId,
            vehicle
          });

          push({
            pathname: `/d/${dealershipId}/consumers/${consumerId}`,
            query: {
              vehicleId: vehicle.VehicleId
            }
          });
        },
        (res) => {
          dispatch({
            type: actionTypes.ADD_CONSUMER_VEHICLE_ERROR,
            error: res.status === 409 ? 'VehicleAlreadyExists' : 'AddVehicleError'
          });
        }
      );
  };
}
