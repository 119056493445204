import React from 'react';
import PropTypes from 'prop-types';
import './quickQuoteDummyNavigationItem.scss';
import classnames from 'classnames';
import Icon from '../../Common/Icon/Icon';
import ApplicationProgressBar from '../../Application/components/ApplicationProgressBar';
const QuickQuoteDummyNavigationItem = ({ link, text, imageName, progress }) => {
  const liStyles = classnames(
    link === 'PersonalDetails'
      ? 'quickQuoteDummyNavigationItem__liActive'
      : 'quickQuoteDummyNavigationItem__li quickQuoteDummyNavigationItem__disabled',
    text === 'Summary' && 'quickQuoteDummyNavigationItem__bottomLeftBorderRadius'
  );

  return (
    <li className={liStyles} key="li">
      <span className="quickQuoteDummyNavigationItem__link">
        <div className="quickQuoteDummyNavigationItem__inner">
          <div className="quickQuoteDummyNavigationItem__iconContainer">
            <div className="quickQuoteDummyNavigationItem__iconOuter" key="image">
              <Icon name={imageName} />
            </div>
          </div>
          <div className="quickQuoteDummyNavigationItem__inner">
            <div className="quickQuoteDummyNavigationItem__text" key="text">
              {text}
            </div>
            <ApplicationProgressBar progress={progress} />
          </div>
        </div>
      </span>
    </li>
  );
};
QuickQuoteDummyNavigationItem.propTypes = {
  link: PropTypes.string,
  imageName: PropTypes.any,
  text: PropTypes.string,
  progress: PropTypes.number
};
export default QuickQuoteDummyNavigationItem;
