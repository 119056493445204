import React from 'react';
import PropTypes from 'prop-types';
import './largeButtonLayout.scss';
import LargeMenuButtonWrapper from '../../Application/components/LargeMenuButtonWrapper';

const LargeButtonLayout = ({ children }) => (
  <LargeMenuButtonWrapper>
    {React.Children.toArray(children).map((child, index) => {
      return (
        <div className="largeButtonLayout__button" key={`child${index}`}>
          {React.cloneElement(child)}
        </div>
      );
    })}
  </LargeMenuButtonWrapper>
);

LargeButtonLayout.propTypes = {
  children: PropTypes.node
};

export default LargeButtonLayout;
