import { useTranslation } from 'react-i18next';
import React from 'react';
import './dashboardListHeader.scss';

const DashboardListHeader = () => {
  const { t } = useTranslation('Dashboard');
  return (
    <div className="dashboardListHeader" key="cont">
      <ul className="dashboardListHeader__ul">
        <li className="dashboardListHeader__li dashboardListHeader__long dashboardListHeader__first" key="li1">
          {t('DashboardListHeader.vehicle_and_customer_information')}
        </li>
        <li className="dashboardListHeader__li dashboardListHeader__short" key="li2">
          {t('DashboardListHeader.lead_origin_updated_in')}
        </li>
        <li className="dashboardListHeader__li dashboardListHeader__short" key="li3">
          {t('DashboardListHeader.eligibility')}
        </li>
        <li className="dashboardListHeader__li dashboardListHeader__short dashboardListHeader__last" key="li4">
          {t('DashboardListHeader.created_updated')}
        </li>
      </ul>
    </div>
  );
};

export default DashboardListHeader;
