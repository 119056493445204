import * as actionTypes from '../actionTypes';
import platformApiUtils from '@ivendi/platform-api-utils';
import { schemaMap } from '../../core/schema';
import productsSchema from '../../core/schemas/productsSchema';
import { push } from '../../routerHistory';

export function fetchAll(dealershipId, initialLoad = false) {
  return {
    types: [
      actionTypes.FETCH_DEALERSHIP_PRODUCTS,
      actionTypes.FETCH_DEALERSHIP_PRODUCTS_SUCCESS,
      actionTypes.FETCH_DEALERSHIP_PRODUCTS_ERROR
    ],
    callAPI: () => platformApiUtils.get('v1/dealerships/' + dealershipId + '/products'),
    payload: { initialLoad },
    parseResponse: (data) => schemaMap(data, productsSchema)
  };
}

export function saveAllProducts(dealershipId, productSettingsCollection) {
  return (dispatch) => {
    if (productSettingsCollection.length > 0) {
      dispatch({
        types: [
          actionTypes.SAVE_ALL_PRODUCTS,
          actionTypes.SAVE_ALL_PRODUCTS_SUCCESS,
          actionTypes.SAVE_ALL_PRODUCTS_ERROR
        ],
        callAPI: () => platformApiUtils.post('v1/dealerships/' + dealershipId + '/products', productSettingsCollection),
        payload: {
          productSettingsCollection
        },
        onSuccess: () =>
          window.setTimeout(() => {
            push(`/d/${dealershipId}/settings`);
          }, 300)
      });
    } else {
      window.setTimeout(() => {
        push(`/d/${dealershipId}/settings`);
      }, 300);
    }
  };
}
