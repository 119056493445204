import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './applicationCardCorporate.scss';
import Button from '../../Common/Button/Button';
import { connect } from 'react-redux';
import _ from 'lodash';

const ApplicationCardCorporate = ({ data, type, options, sectionHeading, errorCount, t, showRemove, remove, edit }) => {
  const time = type === 'address' ? data.TimeAtAddress : undefined;

  const contactPosition = _.result(
    _.find(options.Position, {
      key: data.Position
    }),
    'value'
  );

  const containerStyles = classnames(
    'applicationCardCorporate',
    type === 'address' && 'applicationCardCorporate__containerAddress'
  );
  return (
    <div className={containerStyles}>
      <div>
        <span className="applicationCardCorporate__sectionHeader">{sectionHeading}</span>
        {errorCount > 0 ? (
          <div className="applicationCardCorporate__required">
            {t('ApplicationCardCorporate.field_required', {
              count: errorCount
            })}
          </div>
        ) : (
          ''
        )}
      </div>

      <div className="applicationCardCorporate__inner">
        {type === 'address' && (
          <div className="applicationCardCorporate__address" key="address">
            <div>
              {data.BuildingName || ''} {data.BuildingNumber || ''} {data.SubBuilding || ''} {data.Street || ''}
            </div>
            <div>{data.PostTown || ''}</div>
            <div>{data.Postcode || ''}</div>
          </div>
        )}

        {type === 'contact' && (
          <div className="applicationCardCorporate__address" key="address">
            <div>
              {data.Title} {data.FirstName} {data.MiddleNames} {data.LastName}
            </div>
            <div>{contactPosition}</div>
            <div>{data.Email}</div>
          </div>
        )}

        <div className="applicationCardCorporate__right" key="right">
          <div className="applicationCardCorporate__time">
            <div>
              {time && time.Years ? (
                <span>
                  <span className="applicationCardCorporate__number" key="num1">
                    {time.Years}
                  </span>{' '}
                  {t('ApplicationCardCorporate.year', {
                    count: +time.Years
                  })}
                </span>
              ) : (
                ''
              )}

              {time && time.Months ? (
                <span>
                  <span className="applicationCardCorporate__number" key="num2">
                    {' ' + time.Months}
                  </span>{' '}
                  {t('ApplicationCardCorporate.months')}
                </span>
              ) : (
                ''
              )}
            </div>
            {type === 'contact' ? (
              <div className="applicationCardCorporate__contactText applicationCardCorporate__noTime" key="text2">
                <div>
                  {t('ApplicationCardCorporate.tel', {
                    tel: data.HomeTelephone
                  })}
                </div>
                <div>
                  {t('ApplicationCardCorporate.mob', {
                    mob: data.MobileTelephone
                  })}
                </div>
              </div>
            ) : time ? (
              <div className="applicationCardCorporate__timeText" key="text1">
                {type === 'employment'
                  ? t('ApplicationCardCorporate.time_at_employment')
                  : t('ApplicationCardCorporate.time_at_address')}
              </div>
            ) : (
              <div className="applicationCardCorporate__timeText applicationCardCorporate__noTime" key="text2">
                {type === 'employment'
                  ? t('ApplicationCardCorporate.no_time_at_employment')
                  : t('ApplicationCardCorporate.no_time_at_address')}
              </div>
            )}
          </div>

          <div className="applicationCardCorporate__buttonContainer" key="buttonContainer">
            {showRemove ? (
              <div className="applicationCardCorporate__button applicationCardCorporate__removeButton" key="b2">
                <Button type="button" buttonStyle="cancel" size="small" onClick={remove}>
                  {t('ApplicationCardCorporate.remove')}
                </Button>
              </div>
            ) : (
              ''
            )}
            <div className="applicationCardCorporate__button applicationCardCorporate__editButton" key="b1">
              <Button type="button" buttonStyle="secondary" size="small" onClick={edit}>
                {t('ApplicationCardCorporate.edit')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ApplicationCardCorporate.propTypes = {
  data: PropTypes.object,
  edit: PropTypes.func,
  remove: PropTypes.func,
  errorCount: PropTypes.number,
  submitted: PropTypes.bool,
  showRemove: PropTypes.bool,
  children: PropTypes.any,
  type: PropTypes.string,
  sectionHeading: PropTypes.string,
  options: PropTypes.object
};

ApplicationCardCorporate.defaultProps = {
  options: {}
};

function mapStateToProps(state) {
  return {
    options: state.options
  };
}

export default withTranslation('Application')(connect(mapStateToProps)(ApplicationCardCorporate));
