import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './linkedUsersLink.scss';

const LinkedUsersLink = ({ handleClick, t, count }) => {
  return (
    <div className="linkedUsersLink" onClick={handleClick}>
      <div className="linkedUsersLink__text">
        {t('LinkedUsersLink.other_names_linked_to_this_email', {
          count
        })}
      </div>
    </div>
  );
};

LinkedUsersLink.propTypes = {
  count: PropTypes.number,
  aliases: PropTypes.array,
  handleClick: PropTypes.func
};

export default withTranslation('Customer')(LinkedUsersLink);
