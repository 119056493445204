import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './searchResults.scss';

function escapeRegex(str) {
  return str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
}

class SearchResults extends React.Component {
  onClickHandler = (e) => {
    const userId = e.currentTarget.value;
    this.props.onSelectResult(this.props.results.find((user) => user.id === userId));
  };

  render() {
    return (
      <ul key="resultsUL" className="searchResults">
        {_.map(this.props.results, (result, index) => {
          //Surround matching parts of name/email with <b> tags
          let nameParts = `${result.firstName} ${result.surname}`.split(
            new RegExp(`(${escapeRegex(this.props.searchQuery)})`, 'gi')
          );
          for (let i = 1; i < nameParts.length; i += 2) {
            nameParts[i] = <b key={`name${i}${result.id}`}>{nameParts[i]}</b>;
          }

          const emailParts = `${result.email}`.split(new RegExp(`(${escapeRegex(this.props.searchQuery)})`, 'gi'));
          for (let i = 1; i < emailParts.length; i += 2) {
            emailParts[i] = <b key={`email${i}${result.id}`}>{emailParts[i]}</b>;
          }

          return (
            <li key={`resultli${result.id}`} className="searchResults__result">
              <button
                tabIndex="0"
                value={result.id}
                key={`resultButton${result.id}`}
                onClick={this.onClickHandler}
                className={
                  this.props.activeSearchResultIndex === index
                    ? 'searchResults__resultButton searchResults__active'
                    : 'searchResults__resultButton'
                }
              >
                {nameParts}{' '}
                <span key={`span${result.id}`} className="searchResults__resultEmail">
                  {emailParts}
                </span>
              </button>
            </li>
          );
        })}
      </ul>
    );
  }
}

SearchResults.propTypes = {
  results: PropTypes.object,
  onSelectResult: PropTypes.func,
  searchQuery: PropTypes.string,
  activeSearchResultIndex: PropTypes.number
};

export default SearchResults;
