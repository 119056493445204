import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  draft.applicationFields.PersonalDetails!.BornUK = true;
  draft.applicationFields.PersonalDetails!.BirthPlace = true;
  draft.applicationFields.PersonalDetails!.Director = true;
  draft.applicationFields.PersonalDetails!.CountryOfOperation = true;
  draft.applicationFields.PersonalDetails!.CompanySector = true;
  draft.applicationFields.AffordabilityDetails!.DetailsOfReplacementLoan = false;
  draft.applicationFields.AffordabilityDetails!.MortgageOrRental = true;
  draft.applicationFields.AffordabilityDetails!.Other = true;
  draft.applicationFields.AffordabilityDetails!.ForeseeFutureDownturnInFinances = false;
  draft.applicationFields.AffordabilityDetails!.DownturnReason = false;
  draft.applicationFields.AffordabilityDetails!.ChangesExpected = true;
  draft.applicationFields.AffordabilityDetails!.DetailsOfExpectedChanges = true;

  draft.validationRules.PersonalDetails!.Title = 'required';
  draft.validationRules.PersonalDetails!.FirstName = 'required';
  draft.validationRules.PersonalDetails!.LastName = 'required';
  draft.validationRules.PersonalDetails!.Email = 'required, email';
  draft.validationRules.PersonalDetails!.Gender = 'required';
  draft.validationRules.PersonalDetails!.Nationality = 'required';
  draft.validationRules.PersonalDetails!.CountryOfBirth!.BornUK = 'required';
  draft.validationRules.PersonalDetails!.CountryOfBirth!.BirthPlace = 'required:iffalse:BornUK';
  draft.validationRules.PersonalDetails!.DirectorOrBoardMember!.Director = 'required';
  draft.validationRules.PersonalDetails!.DirectorOrBoardMember!.CountryOfOperation = 'required:iftrue:Director';
  draft.validationRules.PersonalDetails!.DirectorOrBoardMember!.CompanySector = 'required:iftrue:Director';
  draft.validationRules.AffordabilityDetails!.MonthlyExpenditure!.MortgageOrRental = 'currency, required, max:999999';
  draft.validationRules.AffordabilityDetails!.MonthlyExpenditure!.Other = 'currency, required,  max:999999';
  draft.validationRules.AffordabilityDetails!.PersonalCircumstances!.ChangesExpected = 'required';
  delete draft.validationRules.AffordabilityDetails!.ReplacementLoan!.DetailsOfReplacementLoan;
  delete draft.validationRules.AffordabilityDetails!.PersonalCircumstances!.ForeseeFutureDownturnInFinances;
  delete draft.validationRules.AffordabilityDetails!.PersonalCircumstances!.DownturnReason;

  draft.editSections.personalDetails = true;
  draft.editApplicationFieldsDisabled.PersonalDetails!.MiddleNames = false;
});
