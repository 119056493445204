import React from 'react';
import PropTypes from 'prop-types';
import './leagueTableTable.scss';

const LeagueTableTable = ({ children }) => <table className="leagueTableTable">{children}</table>;

LeagueTableTable.propTypes = {
  children: PropTypes.node
};

export default LeagueTableTable;
