import React from 'react';
import DetailsTable from '../DetailsTable/DetailsTable';
import { useTranslation } from 'react-i18next';
import { MultiQuotesFormatResponse } from '../../utils/getQuoteDisplaySchema';
import './multiLoansContainer.scss';

export type MultiLoansDetailsProps = {
  loans: MultiQuotesFormatResponse;
  isSingleColumn?: boolean;
};

const MultiLoansDetails: React.FC<MultiLoansDetailsProps> = ({ loans, isSingleColumn }) => {
  const { t } = useTranslation('QuoteCard');

  return (
    <div className="multiLoansDetails">
      {loans &&
        (Object.keys(loans) as (keyof MultiQuotesFormatResponse)[]).map((loanType, index) => {
          const data = loans[loanType];
          return data ? (
            <DetailsTable
              data={data}
              title={t(`MultiLoansDetails.${loanType}`)}
              variant="dark"
              loanType={loanType}
              key={`${loanType}-${index}`}
              isSingleColumn={isSingleColumn}
            />
          ) : null;
        })}
    </div>
  );
};

export default MultiLoansDetails;
