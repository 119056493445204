import React from 'react';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';

import Icon from '../../Common/Icon/Icon';
import { useNavigate } from 'hooks/useNavigate';
import { useParams } from 'react-router-dom';

const CompactQuickQuote = ({ vehicleCondition, appStore }) => {
  const { t } = useTranslation('Header');
  const params = useParams();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate({
      pathname: `/d/${params.dealershipId}/quickquote`,
      query: {
        vehicleType: vehicleCondition
      }
    });
  };

  return (
    <div className="quickQuoteBar">
      <button className="quickQuoteBar__mobileButton" onClick={handleClick}>
        <div className="quickQuoteBar__mobileButtonImage">
          {appStore.uiState.isUk ? <Icon name="quick-quote" /> : <Icon name="quick-quote-eur" />}
        </div>
        <div className="quickQuoteBar__mobileButtonText">{t('QuickQuoteBar.quote')}</div>
      </button>
    </div>
  );
};

export default inject('appStore')(CompactQuickQuote);
