import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import ConsumerName from '../../../Common/ConsumerName';
import BusinessTag from '../../../Common/BusinessTag';
import './corporateCustomer.scss';

const CorporateCustomer = ({ consumer, handleEditCustomer }) => {
  const { t } = useTranslation('Customer');
  return (
    <div className="corporateCustomer">
      <div className="corporateCustomer__body">
        <div className="corporateCustomer__name">
          <div className="corporateCustomer__company">{consumer.TradingName}</div>
          <div className="corporateCustomer__businessTag">
            <BusinessTag userType={consumer.CustomerType} />
          </div>
        </div>
        <ConsumerName
          name={`${consumer.Firstname} ${consumer.Middlename || consumer.Middlenames} ${consumer.Surname}`}
        />
        {consumer.Landline ? <div>{consumer.Landline}</div> : ''}
        {consumer.Mobile ? <div>{consumer.Mobile}</div> : ''}
        <div>{consumer.Email}</div>
        {handleEditCustomer && (
          <div className="corporateCustomer__editProfile" onClick={handleEditCustomer}>
            {t('CorporateCustomer.edit_customer')}
          </div>
        )}
      </div>
    </div>
  );
};

CorporateCustomer.propTypes = {
  consumer: PropTypes.object,
  handleEditCustomer: PropTypes.func
};
export default CorporateCustomer;
