import { mapMetricsToLabels } from './getMetrics';
import { TFunction } from 'i18next';
import { snakeCase } from 'snake-case';
import { ReportConfigType, ApiResponseType, Dataset } from '~/components/Insights/types';

type ChartJsConfig = {
  datasets: Array<{
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
  }>;
  labels: string[];
  lastModified: string;
  points: string[];
};

const COLORS = {
  primary: '#1E90FF',
  secondary: '#F56565',
  tertiary: '#64BC46',
  fallback: '#4ac0c0'
};

export const transformToChartJsFormat = (
  data: ApiResponseType,
  reportConfig: ReportConfigType,
  isDisplayingAllQuoteSources: boolean,
  t: TFunction
): ChartJsConfig => {
  const {
    metric,
    chartFilterConfig: { dataSet, priorityChartFilter }
  } = reportConfig;

  const applicationStatusLabels = mapMetricsToLabels(metric);

  const chartDatasets = data.datasets.map((dataset: Dataset, index: number) => {
    const prioritiseApplicationStatusFilter =
      priorityChartFilter === 'applicationStatus' && reportConfig.chartFilterConfig.applicationStatusConfig.enabled;

    let label: string;

    if (isDisplayingAllQuoteSources && prioritiseApplicationStatusFilter) {
      const metricArray = Array.isArray(metric) ? metric : [metric];

      const matchingLabels = metricArray.flatMap((m) => {
        const entry = applicationStatusLabels.find((entry) => entry.key === m);
        return entry ? [entry.value] : [];
      });

      label = matchingLabels.length > 0 ? matchingLabels[0] : `Dataset ${index + 1}`;
    } else {
      label = t(`ChartFilters.${snakeCase(dataset.label)}`);
    }

    const colorPalette = Object.values(COLORS);
    const color = colorPalette[index % colorPalette.length];

    return {
      label,
      data: dataset[dataSet] || [],
      borderColor: color,
      backgroundColor: color
    };
  });

  return {
    datasets: chartDatasets,
    labels:
      data.points.length > 0 ? data.points : data.datasets[0]?.users?.map((_, index) => `Point ${index + 1}`) || [],
    lastModified: data.lastModified || new Date().toISOString(),
    points: data.points || []
  };
};
