import React from 'react';
import PropTypes from 'prop-types';
import './employmentHistoryPanel.scss';
import EmploymentHistoryPanelItem from './EmploymentHistoryPanelItem';

const EmploymentHistoryPanel = ({ employment }) => {
  return (
    <div className="employmentHistoryPanel">
      {employment.map((item, index) => {
        return (
          <div className="employmentHistoryPanel__div" key={'emp' + index}>
            <EmploymentHistoryPanelItem employment={item} index={index} />
          </div>
        );
      })}
    </div>
  );
};

EmploymentHistoryPanel.propTypes = {
  employment: PropTypes.array
};

EmploymentHistoryPanel.defaultProps = {
  employment: []
};

export default EmploymentHistoryPanel;
