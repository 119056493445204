import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PlaceholderCar from '../../../src/styles/icons/car.svg';
import PlaceholderLcv from '../../../src/styles/icons/lcv-placeholder.svg';
import PlaceholderBike from '../../../src/styles/icons/bike-placeholder.svg';
import PlaceholderMotorhome from '../../../src/styles/icons/motorhome-placeholder.svg';
import './vehicleImage.scss';
import LoadingSpinner from './Loading/LoadingSpinner';
import { VehicleClassJs } from '~/types/vehicle';

class VehicleImage extends React.Component {
  constructor() {
    super();

    this.state = {
      hasError: false,
      hasLoaded: false
    };
  }

  componentDidMount() {
    if (this.refs.image) {
      this.refs.image.addEventListener('error', this.handleImageLoadError);
    }

    if (this.props.imageSource) {
      const img = document.createElement('img');
      img.src = this.props.imageSource;

      const timeoutId = setTimeout(() => {
        this.setState({
          hasLoaded: true
        });
      }, 1000);

      img.onload = () => {
        clearTimeout(timeoutId);

        this.setState({
          hasLoaded: true
        });
      };
      img.onerror = () => {
        clearTimeout(timeoutId);

        this.setState({
          hasError: true
        });

        this.props.onError && this.props.onError();
      };
    }
  }

  componentWillUnmount() {
    if (this.refs.image) {
      this.refs.image.removeEventListener('error', this.handleImageLoadError);
    }
  }

  handleImageLoadError = () => {
    this.setState({
      hasError: true
    });
  };

  getVehicleClass() {
    return this.props.vehicleClass ? this.props.vehicleClass.toLowerCase() : 'car';
  }

  getPlaceholder(vehicleClass) {
    switch (vehicleClass) {
      case VehicleClassJs.car:
        return {
          image: PlaceholderCar,
          alt: this.props.t('VehicleImage.vehicle_car')
        };

      case VehicleClassJs.lcv:
      case VehicleClassJs.van:
        return {
          image: PlaceholderLcv,
          alt: this.props.t('VehicleImage.vehicle_van')
        };

      case VehicleClassJs.bike:
      case VehicleClassJs.motorbike:
        return {
          image: PlaceholderBike,
          alt: this.props.t('VehicleImage.vehicle_bike')
        };

      case VehicleClassJs.motorhome:
      case VehicleClassJs.touringCaravan:
        return {
          image: PlaceholderMotorhome,
          alt: this.props.t('VehicleImage.vehicle_motorhome')
        };

      default:
        return {
          image: PlaceholderCar,
          alt: this.props.t('VehicleImage.vehicle_car')
        };
    }
  }

  render() {
    const vehicleClass = this.getVehicleClass();
    const placeholder = this.getPlaceholder(vehicleClass);
    const imgSrc = this.props.imageSource;
    const showPlaceholder = this.state.hasError || !this.props.imageSource;
    const showLoading = !this.state.hasError && this.props.imageSource && !this.state.hasLoaded;
    const showVehicleImage = !this.state.hasError && this.props.imageSource && this.state.hasLoaded;

    return (
      <div className="vehicleImage" style={{ width: this.props.imageWidth, height: this.props.imageHeight }}>
        {showPlaceholder && (
          <div className="vehicleImage__icon">
            <img className="vehicleImage__placeholder" src={placeholder.image} alt={placeholder.alt} />
          </div>
        )}

        {showLoading && (
          <div className="vehicleImage__spinner">
            <LoadingSpinner />
          </div>
        )}

        {showVehicleImage && (
          <div className="vehicleImage__imageContainer">
            <img className="vehicleImage__image" src={imgSrc} ref="image" alt={this.props.t('VehicleImage.vehicle')} />
          </div>
        )}
      </div>
    );
  }
}

VehicleImage.propTypes = {
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  imageSource: PropTypes.string,
  vehicleClass: PropTypes.string,
  iconFontSize: PropTypes.string,
  iconMargin: PropTypes.string,
  paddingTop: PropTypes.string
};

VehicleImage.defaultProps = {
  vehicleClass: 'car',
  iconFontSize: '80px',
  iconMargin: '13px 0 0 0',
  paddingTop: '0'
};

export { VehicleImage };
export default withTranslation('Common')(VehicleImage);
