import React from 'react';
import PropTypes from 'prop-types';
import { trackPageView } from '@ivendi/snowplow-js';

class TrackPageView extends React.Component {
  UNSAFE_componentWillMount() {
    trackPageView(
      this.props.vrm,
      this.props.dealershipId,
      null,
      this.props.quote && this.props.quote.id,
      this.props.quote && this.props.quote.funderCode
    );
  }

  render() {
    return null;
  }
}

TrackPageView.propTypes = {
  dealershipId: PropTypes.string,
  quote: PropTypes.object,
  vrm: PropTypes.string
};

export default TrackPageView;
