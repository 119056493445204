import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import MoneyFormat from '../../Common/MoneyFormat';
import FunderLogo from '../../Common/FunderLogo';
import PaymentSchedule from './PaymentSchedule';
import { formatExcess, formatNumber } from '~/core/helpers';
import './compareQuotesTableItem.scss';
import { inject, observer } from 'mobx-react';

class CompareQuotesTableItem extends React.Component {
  render() {
    return (
      <tr className="compareQuotesTableItem">
        <th className="compareQuotesTableItem__header">{this.props.header}</th>
        {this.props.quotes.map((quote, index) => {
          return (
            <td className="compareQuotesTableItem__td" key={'td' + index}>
              {this.props.type === 'vaps' && !quote.isUnavailable ? (
                <MoneyFormat
                  value={quote[this.props.field].reduce((accumulator, currentVap) => accumulator + currentVap.Price, 0)}
                />
              ) : (
                ''
              )}
              {this.props.type === '' && !quote.isUnavailable ? quote[this.props.field] : ''}
              {this.props.type === 'number' && !quote.isUnavailable ? (
                <span>
                  {quote[this.props.field] > 0
                    ? formatNumber(quote[this.props.field] ?? 0, 0, this.props.appStore.uiState.countryCode)
                    : ''}
                </span>
              ) : (
                ''
              )}
              {this.props.type === 'excess' && !quote.isUnavailable ? (
                <span>{quote[this.props.field] > 0 ? formatExcess(quote[this.props.field] ?? 0) : ''}</span>
              ) : (
                ''
              )}
              {this.props.type === 'money' && !quote.isUnavailable ? (
                <MoneyFormat value={quote[this.props.field]} />
              ) : (
                ''
              )}{' '}
              {this.props.type === 'moneyOrBlank' && !quote.isUnavailable ? (
                <span>{quote[this.props.field] > 0 ? <MoneyFormat value={quote[this.props.field]} /> : ''}</span>
              ) : (
                ''
              )}{' '}
              {this.props.type === 'months' && !quote.isUnavailable
                ? quote[this.props.field] + ' ' + this.props.t('CompareQuotesTableItem.months')
                : ''}{' '}
              {this.props.type === 'annualRate' && !quote.isUnavailable ? (
                <span>
                  {quote[this.props.field]}
                  {this.props.t('CompareQuotesTableItem.p_a')}
                  {quote.FinanceType === 'PCP' && quote.FunderCode === 'MOT' && <span> *</span>}
                </span>
              ) : (
                ''
              )}
              {this.props.type === 'repapr' && !quote.isUnavailable ? quote[this.props.field] + '%' : ''}
              {this.props.type === 'logo' && !quote.isUnavailable ? (
                <div className="compareQuotesTableItem__logoContainer">
                  <FunderLogo funder={quote.FunderCode} />
                </div>
              ) : (
                ''
              )}
              {this.props.type === 'paymentschedule' && !quote.isUnavailable ? (
                <PaymentSchedule payments={quote.PaymentSchedule} />
              ) : (
                ''
              )}
              {this.props.type === 'product' && !quote.isUnavailable ? (
                <div>
                  <div className="compareQuotesTableItem__productName">{quote.ProductName}</div>
                </div>
              ) : (
                ''
              )}
            </td>
          );
        })}
      </tr>
    );
  }
}

CompareQuotesTableItem.propTypes = {
  quotes: PropTypes.array.isRequired,
  header: PropTypes.string,
  type: PropTypes.string,
  field: PropTypes.string
};
export default withTranslation('Quoting')(inject('appStore')(observer(CompareQuotesTableItem)));
