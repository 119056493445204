import React from 'react';
import './detailsTableItem.scss';
import { FormattedQuoteKey, QuoteRow } from '../../types';
import { QuoteRowLabel } from '../QuoteRowInfo/QuoteRowLabel';
import { QuoteRowValue } from '../QuoteRowInfo/QuoteRowValue';

export type DetailsTableItemProps = {
  label: FormattedQuoteKey;
  value: QuoteRow;
};
const DetailsTableItem: React.FC<DetailsTableItemProps> = ({ label, value }) => {
  return (
    <div className="detailsTableItem" key={`details-table-item-${label}`} data-th={`QuoteCardData_${label}`}>
      <dd className="detailsTableItem__itemLabel" data-th="QuoteCardData_Label">
        <QuoteRowLabel label={label} value={value} />
      </dd>
      <dt className="detailsTableItem__itemValue" data-th="QuoteCardData_Value">
        <QuoteRowValue label={label} value={value} />
      </dt>
    </div>
  );
};

export default DetailsTableItem;
