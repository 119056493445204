import { useQuery, UseQueryResult } from '@tanstack/react-query';
import handleRequest from '../utils/handleRequest';
import handleErrorResponse, { FetchError } from '../utils/handleErrorResponse';
import { ApiResponseType, ChartDimensions, ChartDimensionsType, MetricType } from '~/components/Insights/types';
import { applicationStatusMetrics } from '../utils/getMetrics';

export interface ChartQueryProps {
  metric: MetricType;
  dimensions: ChartDimensionsType[];
  fromDate: string;
  toDate: string;
  dealershipId: string | undefined;
  datePeriod?: 'Hour' | 'Day' | 'Month' | 'Auto';
  funderCode?: string;
}

const useChartData = ({
  metric,
  dimensions = [ChartDimensions.Date],
  fromDate,
  toDate,
  datePeriod = 'Auto',
  dealershipId,
  funderCode
}: ChartQueryProps): UseQueryResult<ApiResponseType, FetchError> => {
  const formattedMetric = metric === 'all' ? applicationStatusMetrics : metric;

  const stringifiedBody = JSON.stringify({
    metric: formattedMetric,
    dimensions,
    fromDate,
    toDate,
    datePeriod,
    ...(funderCode && funderCode !== 'all' ? { funderCode } : {})
  });

  return useQuery(['chartData', metric, dimensions, fromDate, toDate, datePeriod, funderCode], async () => {
    try {
      const response = await handleRequest(`/api-module/analytics/metric/${dealershipId}/query`, {
        method: 'POST',
        body: stringifiedBody
      });

      if (!response.ok) {
        throw await handleErrorResponse(response);
      }

      return await response.json();
    } catch (error) {
      if (error instanceof FetchError) {
        throw error;
      }
      throw new Error('An unexpected error occurred');
    }
  });
};

export default useChartData;
