import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CountryCodes, CountryCodeToSymbol, CurrencyCodeToSymbol } from '../../constants';

export const CurrencySymbol = ({ dealershipCountryCode, countryCode, currency }) => {
  if (currency) {
    return CurrencyCodeToSymbol[currency] || currency;
  }

  return CountryCodeToSymbol[countryCode?.toLowerCase() || dealershipCountryCode?.toLowerCase() || 'gb'] || null;
};

CurrencySymbol.propTypes = {
  countryCode: PropTypes.oneOf(CountryCodes),
  dealershipCountryCode: PropTypes.oneOf(CountryCodes)
};

function mapStateToProps(state) {
  return {
    dealershipCountryCode: state.dealership.CountryCode.toLowerCase()
  };
}

export default connect(mapStateToProps)(CurrencySymbol);
