import platformApiUtils from '@ivendi/platform-api-utils';

export function requestSettlementFigure(request, dealershipId, customerId) {
  return platformApiUtils.post('v1/settlement', {
    DealershipId: dealershipId,
    AgreementId: parseInt(request.agreementId, 10),
    Surname: request.surname,
    Vrm: request.vrm,
    CustomerId: customerId,
    Letter: request.shouldRequestPdf
  });
}

export function fetchNewCarOfferTerms(quoteeUid) {
  return new Promise((resolve) => {
    fetch(`/quoteware/text/${quoteeUid}.json`, {
      headers: {
        'content-type': 'application/json'
      },
      method: 'GET'
    })
      .then((response) => {
        resolve(response.json());
      })
      .catch((error) => {
        resolve(error);
      });
  });
}
