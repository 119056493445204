import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { marked } from 'marked';

class MarkDown extends Component {
  rawMarkup() {
    const rawMarkup = marked(this.props.children.toString(), { sanitize: true });
    return {
      __html: rawMarkup
    };
  }

  render() {
    return <div className="markdown" dangerouslySetInnerHTML={this.rawMarkup()} />;
  }
}

MarkDown.propTypes = {
  children: PropTypes.node
};

export default MarkDown;
