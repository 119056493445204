import { FormattedQuoteKey, QuoteRow, QuoteRowType, QuoteRowValue } from '../types';

export const getQuoteRowFormatType = (label: FormattedQuoteKey, value: QuoteRowValue): QuoteRow => {
  const MONEY_FORMATTED_KEYS = new Set([
    'MonthlyPayment',
    'TotalAmountPayable',
    'CashPrice',
    'PartExchange',
    'SettlementFigure',
    'CashDeposit',
    'DepositContribution',
    'Cashback',
    'TotalDeposit',
    'TotalAmountOfCredit',
    'FirstPayment',
    'FinalPayment',
    'StandardPayments',
    'InterestCharges',
    'TotalAmountPayable',
    'OptionToPurchaseFee',
    'AdministrationFeeA',
    'AdministrationFeeB',
    'NegativeEquity',
    'VehiclePrice'
  ]);
  const NUMBER_FORMATTED_KEYS = new Set(['AnnualMileage']);
  const EXCESS_FORMATTED_KEYS = new Set(['ExcessPerMile']);
  const MONTHS_FORMATTED_KEYS = new Set(['AgreementTerm']);
  const DECIMAL_FORMATTED_KEYS = new Set(['Apr', 'AnnualFixedInterestRate']);

  let type = QuoteRowType.default;

  if (MONEY_FORMATTED_KEYS.has(label) || label.startsWith('VAP__')) {
    type = QuoteRowType.money;
  }
  if (NUMBER_FORMATTED_KEYS.has(label)) {
    type = QuoteRowType.number;
  }
  if (DECIMAL_FORMATTED_KEYS.has(label)) {
    type = QuoteRowType.decimals;
  }
  if (MONTHS_FORMATTED_KEYS.has(label)) {
    type = QuoteRowType.months;
  }
  if (EXCESS_FORMATTED_KEYS.has(label)) {
    type = QuoteRowType.excess;
  }
  if (label === 'StandardPayments' || label.startsWith('PAYMENT__')) {
    type = QuoteRowType.paymentSchedule;
  }

  return {
    value,
    type
  };
};
