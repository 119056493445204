import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import DateFormat from '../../Common/DateFormat';
import MoneyFormat from '../../Common/MoneyFormat';
import Button from '../../Common/Button/Button';
import SentDealTag from './SentDealTag';
import './sentDealMultipleDealsItem.scss';
import isEmpty from 'lodash/isEmpty';
import Vrm from 'components/Common/Vrm';
import { inject } from 'mobx-react';
import observerForHooks from 'hocs/observerForHooks';

export const getOfferPrice = (deal) => {
  let offerPrice = 0;

  if (deal.vehicleDiscountFromListPrice) {
    offerPrice = deal.vehicleCashPrice - deal.vehicleDiscountFromListPrice;
  }

  return offerPrice;
};

const SentDealMultipleDealsItem = ({ deal, vehicle, viewDealLink, appStore }) => {
  const { t } = useTranslation('DigitalDeals');
  const offerPrice = getOfferPrice(deal);
  const shouldShowOfferPrice = offerPrice > 0;
  const vehicleData = !isEmpty(vehicle) && vehicle;

  return (
    <div className="sentDealMultipleDealsItem">
      <div className="sentDealMultipleDealsItem__blueDot" />
      <div className="sentDealMultipleDealsItem__date">
        <DateFormat value={deal.addedAt} format="DD MMM" />
      </div>
      <div className="sentDealMultipleDealsItem__inner">
        <div className="sentDealMultipleDealsItem__dealDetails">
          {!appStore.uiState.isBdk && <Vrm vrm={vehicleData.vrm} />}
          <div className="sentDealMultipleDealsItem__vehicle">
            <div>
              {vehicleData.make} {vehicleData.model}
            </div>
            <div className="sentDealMultipleDealsItem__price">
              <div className="sentDealsItem__vehiclePrice">
                <MoneyFormat value={shouldShowOfferPrice ? offerPrice : deal.vehicleCashPrice} />
              </div>
            </div>
          </div>
        </div>
        <div className="sentDealMultipleDealsItem__details">
          <div className="sentDealMultipleDealsItem__status">
            <SentDealTag status={deal.dealStatus} openedAt={deal.openedAt} />
          </div>
          <div className="sentDealMultipleDealsItem__sentBy">{deal.responsibleUserName}</div>
        </div>

        <div className="sentDealMultipleDealsItem__buttonOuter">
          <div className="sentDealMultipleDealsItem__button">
            <Button to={viewDealLink} buttonStyle="secondary" id="viewDeal">
              {t('SentDealMultipleDealsItem.view')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

SentDealMultipleDealsItem.propTypes = {
  deal: PropTypes.object,
  vehicle: PropTypes.object,
  viewDealLink: PropTypes.string
};
export default inject('appStore')(observerForHooks(SentDealMultipleDealsItem));
