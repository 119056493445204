import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './applicationCard.scss';
import Button from '../../Common/Button/Button';

class ApplicationCard extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    edit: PropTypes.func,
    remove: PropTypes.func,
    errorCount: PropTypes.number,
    submitted: PropTypes.bool,
    showRemove: PropTypes.bool,
    children: PropTypes.any,
    type: PropTypes.string,
    sectionHeading: PropTypes.bool
  };

  splitStatus(val) {
    return val.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  render() {
    const data = this.props.data;
    let fields = '';

    if (this.props.errorCount === 1) {
      fields = this.props.t('ApplicationCard.field_is_still_required');
    } else {
      fields = this.props.t('ApplicationCard.fields_are_still_required');
    }

    let line1;
    let line2;
    let line3;
    let time;

    if (this.props.type === 'address') {
      line1 = (
        <span>
          {data.BuildingName || ''} {data.BuildingNumber || ''} {data.SubBuilding || ''} {data.Street || ''}
        </span>
      );
      line2 = <span>{data.PostTown || ''}</span>;
      line3 = <span>{data.Postcode || ''}</span>;
      time = data.TimeAtAddress;
    }

    if (this.props.type === 'employment') {
      line1 = <span>{data.Employer || ''}</span>;
      line2 = <span>{data.Occupation}</span>;
      line3 = <span>{data.OccupationBasis ? this.splitStatus(data.OccupationBasis) : ''}</span>;
      time = data.TimeAtEmployment;
    }

    return (
      <div className="applicationCard">
        <div>
          <span className="applicationCard__sectionHeader">{this.props.sectionHeading}</span>
          {this.props.errorCount > 0 ? (
            <div className="applicationCard__required">
              {this.props.errorCount} {fields}
            </div>
          ) : (
            ''
          )}
        </div>

        <div className="applicationCard__inner">
          <div className="applicationCard__address" key="address">
            <div>{line1}</div>
            <div>{line2}</div>
            <div>{line3}</div>
          </div>
          <div className="applicationCard__right" key="right">
            <div className="applicationCard__time">
              <div>
                {time && time.Years ? (
                  <span>
                    <span className="applicationCard__number" key="num1">
                      {time.Years}
                    </span>{' '}
                    {this.props.t('ApplicationCard.year', {
                      count: +time.Years
                    })}
                  </span>
                ) : (
                  ''
                )}

                {time && time.Months ? (
                  <span>
                    <span className="applicationCard__number" key="num2">
                      {' ' + time.Months}
                    </span>{' '}
                    {this.props.t('ApplicationCard.months')}
                  </span>
                ) : (
                  ''
                )}
              </div>
              {this.props.type === 'contact' ? (
                <div className="applicationCard__noTime" key="text2">
                  <div>
                    {this.props.t('ApplicationCard.tel', {
                      tel: data.PrimaryTelephone
                    })}
                  </div>
                  <div>
                    {this.props.t('ApplicationCard.mob', {
                      mob: data.SecondaryTelephone
                    })}
                  </div>
                </div>
              ) : time ? (
                <div key="text1">
                  {this.props.type === 'employment'
                    ? this.props.t('ApplicationCard.time_at_employment')
                    : this.props.t('ApplicationCard.time_at_address')}
                </div>
              ) : (
                <div className="applicationCard__noTime" key="text2">
                  {this.props.type === 'employment'
                    ? this.props.t('ApplicationCard.no_time_at_employment')
                    : this.props.t('ApplicationCard.no_time_at_address')}
                </div>
              )}
            </div>
            <div className="applicationCard__divider" />
            <div className="applicationCard__buttonContainer" key="buttonContainer">
              {this.props.showRemove ? (
                <div className="applicationCard__button applicationCard__removeButton" key="b2">
                  <Button type="button" buttonStyle="cancel" size="small" onClick={this.props.remove}>
                    {this.props.t('ApplicationCard.remove')}
                  </Button>
                </div>
              ) : (
                ''
              )}
              <div className="applicationCard__button applicationCard__editButton" key="b1">
                <Button type="button" buttonStyle="secondary" size="small" onClick={this.props.edit}>
                  {this.props.t('ApplicationCard.edit')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('Application')(ApplicationCard);
