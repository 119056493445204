import React, { useEffect } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';

import MarqueeQuotes, { Quote } from '~/components/Dashboard/components/MarqueeQuotes/MarqueeQuotes';
import MetricCards from './components/MetricCards/MetricCards';
import Breadcrumbs from 'components/Common/Breadcrumbs';
import UiState from 'mobx-stores/UiState';
import { observerForHooks } from '../../shared/hocs';

import s from './dealershipMetrics.module.scss';
import DashboardQuotesStore from '~/mobx-stores/DashboardQuotesStore';

interface Props {
  uiState: UiState;
  dashboardQuotesStore: DashboardQuotesStore;
  quotes: Quote[];
  children: React.ReactNode;
}
const DealershipMetrics = (props: Props) => {
  const { t } = useTranslation('Stock');
  const { dealershipId } = useParams<{ dealershipId: string }>();
  const { canViewMetricsBreadcrumbs, canViewDashboard } = props.uiState;
  const quotes = props.quotes;

  useEffect(() => {
    // if canViewDashboard i false, then this page acts as the dashboard...
    if (!canViewDashboard) {
      props.dashboardQuotesStore.subscribeToQuotes();
    }
  }, []);

  return (
    <div className={s['container']}>
      <div className={s['header']}>
        {canViewMetricsBreadcrumbs ? (
          <Breadcrumbs
            items={[
              {
                name: t('Common.home'),
                path: `/d/${dealershipId}`
              },
              {
                name: t('StockMetrics.dealership_metrics')
              }
            ]}
          />
        ) : null}
        {/* if this page is used as the dashboard, then we display realtime quotes  */}
        {canViewDashboard ? null : quotes?.length > 0 ? <MarqueeQuotes quotes={quotes} /> : null}
        <MetricCards />
      </div>

      <Outlet />
    </div>
  );
};

export default inject('appStore')(
  observerForHooks({
    quotes: (props: any) => props.appStore.dashboardQuotesStore.quotes,
    dashboardQuotesStore: (props: any) => props.appStore.dashboardQuotesStore,
    uiState: (props: any) => props.appStore.uiState
  })(DealershipMetrics)
);
