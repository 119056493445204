import { ParamKeyValuePair, URLSearchParamsInit } from 'react-router-dom';

export function objectToSearchParams(init: URLSearchParamsInit = ''): URLSearchParams {
  return new URLSearchParams(
    typeof init === 'string' || Array.isArray(init) || init instanceof URLSearchParams
      ? init
      : Object.keys(init).reduce((memo, key) => {
          let value = init[key];
          return memo.concat(Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value ?? '']]);
        }, [] as ParamKeyValuePair[])
  );
}
