import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './commentsButton.scss';
import Icon from '../../Common/Icon/Icon';

const CommentsButton = ({ onClick, count }) => {
  const { t } = useTranslation('CloseDeal');
  return (
    <div className="commentsButton" data-th="CommentsButton">
      <div className="commentsButton__container" onClick={onClick}>
        <div className="commentsButton__iconOuter">
          <Icon name="note" />
        </div>
        <div className="commentsButton__textOuter">{t('CommentsButton.notes', { count })}</div>
      </div>
    </div>
  );
};

CommentsButton.propTypes = {
  count: PropTypes.number,
  onClick: PropTypes.func
};
export default CommentsButton;
