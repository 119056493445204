import { withTranslation } from 'react-i18next';
import { action, observable, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import Validator from '../../../validators/Validator';
import DateInput from '../../Common/Form/DateInput';
import FormFooter from '../../Common/Form/FormFooter';
import SelectInput from '../../Common/Form/SelectInput';
import TextInput from '../../Common/Form/TextInput';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';

@observer
class CFCPersonalDetailsForm extends React.Component {
  @observable
  formData = {
    DateOfBirth: null,
    NoOfDependants: null,
    FirstName: '',
    DrivingLicence: null,
    MaritalStatus: null,
    Title: '',
    Email: '',
    Telephone: '',
    LastName: ''
  };

  UNSAFE_componentWillMount() {
    this.setInitialFormData(this.props.defaultValues);
    this.setupValidation(this.props.validationRules);
    this.validator.validate(this.formData);
  }

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  setupValidation = (validationRules) => {
    this.validator = new Validator();
    this.validator.setRules(validationRules);
    this.validationReactionDisposer = reaction(() => ({ ...this.formData }), this.validator.validate, {
      fireImmediately: true
    });
  };
  @action
  setInitialFormData = (formData) => {
    this.formData = formData;
  };

  onValidationSuccess() {
    this.props.onSubmit(this.formData);
  }

  submitForm = () => {
    this.validator.validate(this.formData);

    if (!this.validator.errorCount) {
      this.onValidationSuccess();
    }
  };
  @action
  handleFieldChange = (fieldName, value) => {
    this.formData[fieldName] = value;
    this.validator.validate(this.formData);
  };

  render() {
    const errors = this.validator.getErrors();
    const {
      appStore: { uiState }
    } = this.props;

    const funderCode = uiState.getPlatformCode;

    return (
      <MobxForm onSubmit={this.submitForm}>
        <MobxFieldGroup isInline error={errors.Title}>
          <MobxFormLabel>{this.props.t('CFCPersonalDetailsForm.title')}</MobxFormLabel>
          <SelectInput
            options="FinanceTitles"
            id="Title"
            value={this.formData.Title}
            onChange={this.handleFieldChange}
            funderCode={funderCode}
          />
        </MobxFieldGroup>
        <MobxFieldGroup isInline error={errors.FirstName}>
          <MobxFormLabel>{this.props.t('CFCPersonalDetailsForm.first_name')}</MobxFormLabel>
          <TextInput id="FirstName" value={this.formData.FirstName} onChange={this.handleFieldChange} />
        </MobxFieldGroup>
        <MobxFieldGroup isInline error={errors.LastName}>
          <MobxFormLabel>{this.props.t('CFCPersonalDetailsForm.last_name')}</MobxFormLabel>
          <TextInput id="LastName" value={this.formData.LastName} onChange={this.handleFieldChange} />
        </MobxFieldGroup>

        {this.props.allowEmailToBeChanged && (
          <MobxFieldGroup isInline error={errors.Email}>
            <MobxFormLabel>{this.props.t('CFCPersonalDetailsForm.email')}</MobxFormLabel>
            <TextInput id="Email" value={this.formData.Email} onChange={this.handleFieldChange} />
          </MobxFieldGroup>
        )}
        <MobxFieldGroup isInline error={errors.Telephone}>
          <MobxFormLabel>{this.props.t('CFCPersonalDetailsForm.telephone')}</MobxFormLabel>
          <TextInput id="Telephone" value={this.formData.Telephone} onChange={this.handleFieldChange} />
        </MobxFieldGroup>
        <MobxFieldGroup isInline error={errors.DateOfBirth}>
          <MobxFormLabel>{this.props.t('CFCPersonalDetailsForm.date_of_birth')}</MobxFormLabel>
          <DateInput id="DateOfBirth" value={this.formData.DateOfBirth} onChange={this.handleFieldChange} />
        </MobxFieldGroup>
        <MobxFieldGroup isInline error={errors.MaritalStatus}>
          <MobxFormLabel>{this.props.t('CFCPersonalDetailsForm.marital_status')}</MobxFormLabel>
          <SelectInput
            id="MaritalStatus"
            options="MaritalStatus"
            value={this.formData.MaritalStatus}
            onChange={this.handleFieldChange}
          />
        </MobxFieldGroup>
        <MobxFieldGroup isInline error={errors.NoOfDependants}>
          <MobxFormLabel>{this.props.t('CFCPersonalDetailsForm.no_dependants')}</MobxFormLabel>
          <SelectInput
            options="Dependants"
            id="NoOfDependants"
            value={this.formData.NoOfDependants}
            onChange={this.handleFieldChange}
          />
        </MobxFieldGroup>
        <MobxFieldGroup isInline error={errors.DrivingLicence}>
          <MobxFormLabel>{this.props.t('CFCPersonalDetailsForm.driving_licence')}</MobxFormLabel>
          <SelectInput
            options="DrivingLicenseType"
            id="DrivingLicence"
            value={this.formData.DrivingLicence}
            onChange={this.handleFieldChange}
          />
        </MobxFieldGroup>

        <FormFooter submittingState={this.props.submittingState} savingState={this.props.savingState} />
      </MobxForm>
    );
  }
}

CFCPersonalDetailsForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  validationRules: PropTypes.object.isRequired,
  submittingState: PropTypes.string,
  allowEmailToBeChanged: PropTypes.bool
};
CFCPersonalDetailsForm.defaultProps = {
  allowEmailToBeChanged: false
};
export default withTranslation('Quoting')(inject(['appStore'])(CFCPersonalDetailsForm));
