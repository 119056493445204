import platformApiUtils from '@ivendi/platform-api-utils';

export const fetchLatestDeals = (dealershipId, status, page, to, from) => {
  return new Promise(function(resolve, reject) {
    platformApiUtils
      .get('v1/submitteddeals/' + dealershipId, { status, page, to, from })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
