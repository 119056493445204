import {
  ConsumerDutyProductDetails,
  SupportServiceContent,
  SupportServiceSection,
  SupportServiceSectionResource
} from '../../../api/contentService';
import { useFunderDetails, useFundersContent } from '../../../api/contentService/hooks';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import { SupportServiceContentTypes, getFundersContentFilters } from '../../../api/contentService/utils';
import { LenderContactItemType, formatLink, funderDetailsToServiceFormat, getContentIconByType } from '../utils';
import styles from './lenderVulnerableCustomers.module.scss';
import CollapsiblePanel from '../../../components/Common/CollapsiblePanel';
import { useTranslation } from 'react-i18next';
import {
  trackFinanceProductSuitabilityVulnerabilityContentViewed,
  trackFinanceProductSuitabilityVulnerabilityOutboundLink
} from '../../../tracking/avoTracking';

const handleTrackViewVulnerableInfo = (
  panelIsOpen: boolean,
  consumerDuty: ConsumerDutyProductDetails | null | undefined,
  dealershipId: string,
  quoteId: string
) => {
  if (panelIsOpen && consumerDuty) {
    // Track viewing vulnerable customers info
    trackFinanceProductSuitabilityVulnerabilityContentViewed({
      consumerDutyContent: consumerDuty,
      dealershipId,
      quoteId
    });
  }
};

const handleTrackOutboundLinkClicked = (
  event: React.SyntheticEvent<HTMLAnchorElement>,
  serviceName: string,
  consumerDuty: ConsumerDutyProductDetails | null | undefined,
  dealershipId: string,
  quoteId: string
) => {
  if (consumerDuty) {
    trackFinanceProductSuitabilityVulnerabilityOutboundLink({
      consumerDutyContent: consumerDuty,
      dealershipId,
      targetUrl: event.target instanceof HTMLAnchorElement ? event.target.href : '',
      targetTags: [serviceName],
      quoteId
    });
  }
};

export const LenderVulnerableCustomers = ({
  consumerDuty,
  dealershipId,
  quoteId
}: {
  consumerDuty: ConsumerDutyProductDetails | undefined | null;
  dealershipId: string;
  quoteId: string;
}) => {
  const { t } = useTranslation('Quoting');
  const { isLoading: isLoadingFundersContent, data: fundersContent } = useFundersContent(
    getFundersContentFilters(consumerDuty?.funderCode)
  );
  const { isLoading: isLoadingDetails, data: funderDetails } = useFunderDetails(consumerDuty?.funderCode);

  if (isLoadingFundersContent || isLoadingDetails) {
    return <LoadingSpinner center size="large" />;
  }
  if (!fundersContent) {
    return null;
  }

  return (
    <div className={styles.vulnerableCustomers}>
      <CollapsiblePanel
        title={t('VulnerableCustomers.help_for_potentially_vulnerable_customers')}
        onToggle={(panelIsOpen: boolean) => {
          handleTrackViewVulnerableInfo(panelIsOpen, consumerDuty, dealershipId, quoteId);
        }}
      >
        <div>
          <p>
            {t('VulnerableCustomers.the_following_organisations_may_be_able_to_help_you_by_providing_independent_help')}
          </p>
          <div className={styles.vulnerableCustomersServices}>
            {funderDetails && (
              <LenderSupportServiceInformation
                supportService={funderDetailsToServiceFormat(funderDetails)}
                onOutboundLinkClicked={(e, serviceName) => {
                  handleTrackOutboundLinkClicked(e, serviceName, consumerDuty, dealershipId, quoteId);
                }}
              />
            )}
            {fundersContent?.supportServices?.map((supportService: SupportServiceContent) => (
              <LenderSupportServiceInformation
                supportService={supportService}
                key={supportService.id}
                onOutboundLinkClicked={(e, serviceName) => {
                  handleTrackOutboundLinkClicked(e, serviceName, consumerDuty, dealershipId, quoteId);
                }}
              />
            ))}
          </div>
        </div>
      </CollapsiblePanel>
    </div>
  );
};

export const LenderSupportServiceInformation = ({
  supportService,
  onOutboundLinkClicked
}: {
  supportService: SupportServiceContent | null;
  onOutboundLinkClicked: (event: React.SyntheticEvent<HTMLAnchorElement>, serviceName: string) => void;
}) => {
  if (!supportService) {
    return null;
  }
  return (
    <div className={styles.vulnerableCustomersService}>
      <h3 className={styles.vulnerableCustomersService__title}>{supportService.name}</h3>
      {supportService.sections.map((section: SupportServiceSection) => (
        <SupportServiceSectionComponent
          key={section.description}
          section={section}
          onOutboundLinkClicked={(e) => onOutboundLinkClicked(e, supportService.name)}
        />
      ))}
    </div>
  );
};

export const SupportServiceSectionComponent = ({
  section,
  onOutboundLinkClicked
}: {
  section: SupportServiceSection;
  onOutboundLinkClicked: (event: React.SyntheticEvent<HTMLAnchorElement>) => void;
}) => (
  <div className={styles.vulnerableCustomers_serviceSection}>
    <p>{section.description}</p>
    <ul>
      {section.resources?.map((resource: SupportServiceSectionResource) => {
        const resourceTypeName = SupportServiceContentTypes[resource.type] as LenderContactItemType;
        const link = (
          <a href={formatLink(resourceTypeName, resource.value)} onClick={onOutboundLinkClicked}>
            {resource.value}
          </a>
        );
        return (
          <li key={`${resource.label}-${resource.value}`}>
            <img src={getContentIconByType(resourceTypeName)} alt={resourceTypeName} />
            {resource.label} {link}
          </li>
        );
      })}
    </ul>
  </div>
);
