export default function closeDealPreFillDataParser(response) {
  response = response || {};

  let { PlatformMeta } = response;
  let parsedMeta = {};

  if (PlatformMeta) {
    try {
      parsedMeta = JSON.parse(PlatformMeta);
    } catch (err) {
      parsedMeta = {};
    }
  }

  return {
    CashDeposit: response.CashDeposit,
    CashPrice: response.CashPrice,
    PartExchange: response.PartExchange,
    Settlement: response.Settlement,
    Commission: response.Commission,
    Deposit: response.Deposit,
    FunderCode: response.OtherFunder ? 'Other' : response.FunderCode,
    FinanceType: response.FinanceType,
    Advance: response.Advance,
    AnnualDistanceQuoted: response.AnnualDistanceQuoted,
    ChargePerOverDistanceUnit: response.ChargePerOverDistanceUnit,
    AcceptanceFeeLast: response.AcceptanceFeeLast,
    OptionToPurchaseFee: response.OptionToPurchaseFee,
    FlatRate: response.FlatRate,
    RepresentativeApr: response.RepresentativeApr,
    Term: response.Term,
    FirstPayment: response.FirstPayment,
    FollowedBy: response.FollowedBy,
    FollowingPayments: response.FollowingPayments,
    FinalPayment: response.FinalPayment,
    TotalAmountPayable: response.TotalAmountPayable,
    AdditionalCommission: response.AdditionalCommission,
    VehiclePrice: response.VehiclePrice,
    TotalPrice: response.TotalPrice,
    VAT: response.VAT,
    VATQualifying: response.VATQualifying,
    VATAmount: response.VATAmount,
    ValueAddedProducts: response.ValueAddedProducts,

    BasicPrice: parsedMeta.BasicPrice,
    AccessoriesInsurance: parsedMeta.Insurance,
    NonVatableItems: parsedMeta.NonVatableItems,
    AccessoriesOther: parsedMeta.Other,
    AccessoriesWarranty: parsedMeta.Warranty
  };
}
