import React from 'react';
import PropTypes from 'prop-types';
import './dashboardPanelNoItems.scss';
import car from '../../../../styles/icons/car-placeholder.svg';

const DashboardPanelNoItems = ({ children }) => (
  <div className="dashboardPanelNoItems">
    <div>
      <img src={car} alt="car" className="dashboardPanelNoItems__img" />
      <div className="dashboardPanelNoItems__content">{children}</div>
    </div>
  </div>
);

DashboardPanelNoItems.propTypes = {
  children: PropTypes.any
};

export default DashboardPanelNoItems;
