import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './selectMonthlyPaymentModal.scss';
import Modal from '../../Common/Modal/Modal';
import { connect } from 'react-redux';
import * as modalActions from '../../../redux/modal/modalActions';
import Button from '../../Common/Button/Button';
import QuoteInfo from '../components/QuoteInfo';
import QuoteInfoCard from '../components/QuoteInfoCard';
import MediaQuery from 'react-responsive';

const SelectMonthlyPaymentModal = ({ onSave, onApply, quote, savingState, applyingState, isOpen, closeModal }) => {
  const { t } = useTranslation('Quoting');
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <div className="selectMonthlyPaymentModal__header">{t('SelectMonthlyPaymentModal.quote_details')}</div>
      <div className="selectMonthlyPaymentModal__quote">
        <MediaQuery query="(min-width: 641px)">
          <QuoteInfo quote={quote} />
        </MediaQuery>
        <MediaQuery query="(max-width: 640px)">
          <QuoteInfoCard quote={quote} />
        </MediaQuery>
      </div>
      <MediaQuery query="(min-width: 401px)">
        <div className="selectMonthlyPaymentModal__buttonContainer">
          <div className="selectMonthlyPaymentModal__button">
            <Button
              isLoading={savingState === 'loading'}
              hasError={savingState === 'error'}
              buttonStyle="secondary"
              onClick={onSave}
            >
              {t('SelectMonthlyPaymentModal.save_quote')}
            </Button>
          </div>
        </div>
        <div className="selectMonthlyPaymentModal__buttonContainer">
          <div className="selectMonthlyPaymentModal__button">
            <Button
              isLoading={applyingState === 'loading'}
              hasError={applyingState === 'error'}
              onClick={() => onApply(quote)}
            >
              {t('SelectMonthlyPaymentModal.apply_for_this_quote')}
            </Button>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery query="(max-width: 400px)">
        <div className="selectMonthlyPaymentModal__buttonContainer--fullWidth">
          <div className="selectMonthlyPaymentModal__button">
            <Button
              isLoading={savingState === 'loading'}
              hasError={savingState === 'error'}
              buttonStyle="secondary"
              onClick={onSave}
            >
              {t('SelectMonthlyPaymentModal.save_quote')}
            </Button>
          </div>
        </div>
        <div className="selectMonthlyPaymentModal__buttonContainer--fullWidth">
          <div className="selectMonthlyPaymentModal__button">
            <Button
              isLoading={applyingState === 'loading'}
              hasError={applyingState === 'error'}
              onClick={() => onApply(quote)}
            >
              {t('SelectMonthlyPaymentModal.apply_for_this_quote')}
            </Button>
          </div>
        </div>
      </MediaQuery>
    </Modal>
  );
};

SelectMonthlyPaymentModal.propTypes = {
  onSave: PropTypes.func,
  onApply: PropTypes.func,
  quote: PropTypes.number,
  savingState: PropTypes.string,
  applyingState: PropTypes.string,
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool
};

const mapStateToProps = (state) => ({
  quote: state.modals.selectMonthlyPayment && state.modals.selectMonthlyPayment.quote,
  isOpen: state.modals.selectMonthlyPayment ? state.modals.selectMonthlyPayment.isOpen : false
});

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(modalActions.close('selectMonthlyPayment'));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectMonthlyPaymentModal);
