import React from 'react';
import PropTypes from 'prop-types';
import './noProceedNotice.scss';

const NoProceedNotice = ({ text }) => <div className="noProceedNotice">{text}</div>;

NoProceedNotice.propTypes = {
  text: PropTypes.string
};

export default NoProceedNotice;
