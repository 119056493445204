import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './existingCustomerMessage.scss';
import { Link } from 'react-router-dom';

const ExistingCustomerMessage = ({ customer, messageType, dealershipId }) => {
  const { t } = useTranslation('AddCustomer');
  const consumerId = customer && customer.Id ? customer.Id : '';
  let message = '';

  if (messageType === 'corporate') {
    message = 'ExistingCustomerMessage.email_already_exists_warning';
  } else if (messageType === 'residential') {
    message = 'ExistingCustomerMessage.customer_contact_email_already_exists_warning';
  }

  return (
    <div>
      <p className="existingCustomerMessage__message">
        {message && (
          <Trans
            ns="AddCustomer"
            i18nKey={message}
            components={{
              Link: <Link to={`/d/${dealershipId}/consumers/${consumerId}`} className="link" />
            }}
          />
        )}
      </p>
      <div className="existingCustomerMessage__actions">
        <Link to={`/d/${dealershipId}/consumers/${consumerId}`} className="button--viewMore">
          {t('ExistingCustomerMessage.view_contact_record')}
        </Link>
      </div>
    </div>
  );
};

ExistingCustomerMessage.propTypes = {
  customer: PropTypes.object,
  messageType: PropTypes.string,
  dealershipId: PropTypes.string
};
export default ExistingCustomerMessage;
