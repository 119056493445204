import { fetchApplication } from '../../api/application';
import { call, put, select } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import { isAllowed } from '../../core/allowedFinanceTypes';
import { findCompanionQuoteByType } from '~/components/QuoteCard/utils/getQuoteDisplaySchema';
import { hasCompanionQuotes } from '~/features';
import { LoanTypeJs } from '~/components/QuoteCard/types';

export const getApplicantId = (state) => state.application.Id;

export function* fetchApplicationAlways(applicantId) {
  yield put({ type: actionTypes.FETCH_APPLICATION });
  let applicationResponse = yield call(fetchApplication, applicantId);

  if (!applicationResponse || !applicationResponse.Quote) {
    throw new Error('No quote found on application.');
  }

  let financeType = applicationResponse.Quote.FinanceType;
  if (hasCompanionQuotes(applicationResponse.Quote)) {
    const vehicleLoan = findCompanionQuoteByType(applicationResponse.Quote.CompanionQuotes, LoanTypeJs.vehicleLoan);
    financeType = vehicleLoan.FinanceType;
  }

  if (!isAllowed(financeType)) {
    throw new Error(`Unknown finance type ${financeType}.`);
  } else {
    yield put({ type: actionTypes.FETCH_APPLICATION_SUCCESS, response: applicationResponse });
  }
}

export function* fetchApplicationIfNeeded(applicantId) {
  const stateApplicationId = yield select(getApplicantId);

  if (applicantId !== stateApplicationId) {
    yield call(fetchApplicationAlways, applicantId);
  }
}
