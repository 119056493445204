import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import moment from 'moment';
import { inject } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import Button from '~Common/Button/Button';
import SplitButton from '~Common/SplitButton';
import SplitButtonOptionDefault from '~Common/SplitButtonOptionDefault';
import SplitButtonOption from '~Common/SplitButtonOption';
import Modal from '~Common/Modal/Modal';
import * as debug from '~/debug';
import { createVehicle, checkVehicle } from '~/api/stockModule';

import { isFieldEnabled } from '../../../../../../../../shared/helpers/vehicleFormFields';
import { StockVehicleFormFields } from '../../../../../../../../shared/components';

import './createVehicleForm.scss';

const CreateVehicleForm = ({ vehicleTaxonomyData, appStore }) => {
  const { t } = useTranslation('Stock');
  const navigate = useNavigate();
  const { dealershipId } = useParams();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const { formState, handleSubmit, watch } = useFormContext();

  const { vehicleProvider } = appStore.stockStore;
  const { resetData } = appStore.stockCreateStore;

  const hasTaxonomyData = vehicleTaxonomyData.PrimaryTaxonomyId && vehicleTaxonomyData.SecondaryTaxonomyId;
  const isDealerPlatformUpload = true;
  const fieldEnabled = (fieldName) =>
    isFieldEnabled(fieldName, watch('class'), hasTaxonomyData, isDealerPlatformUpload);

  const onSubmit = async (data, { nextStep }) => {
    try {
      const { id: vehicleId } = await createVehicle({
        dealershipId: data.dealershipId,
        origin: 'dealer',
        provider: vehicleProvider,
        primaryId: data.primaryId,
        secondaryId: data.secondaryId,
        attentionGrabber: data.attentionGrabber,
        bodyStyle: data.bodyStyle,
        class: data.class,
        colour: data.colour,
        condition: data.condition,
        dealerReference: data.dealerReference,
        derivative: data.derivative,
        description: data.description,
        doors: data.doors,
        fuel: data.fuelType,
        make: data.make,
        mileage: data.mileage,
        model: data.model,
        noOfSeats: data.seats,
        numberOfPreviousKeepers: data.keepers,
        options: data.options,
        price: data.price,
        registrationDate: moment.utc(data.regDate, 'DD/MM/YYYY'),
        trans: data.transmission,
        vatStatus: data.vatStatus,
        vin: data.vin,
        vrm: data.vrm,
        auctionId: data.auctionId,
        mileageUnit: data.mileageUnit,
        currency: data.currency,
        drivingPosition: data.drivingPosition,
        consumptionInner: data.consumptionInner,
        consumptionOuter: data.consumptionOuter,
        consumptionCombined: data.consumptionCombined,
        includesVat: data.includesVat === 'true' ? true : data.includesVat === 'false' ? false : null,
        vatRate: data.vatRate,
        emission: data.emission,
        energyEfficiencyClass: data.energyEfficiencyClass
      });
      await checkVehicle(data.dealershipId, vehicleId);
      resetData();

      switch (nextStep) {
        case 'edit': {
          navigate(`/d/${data.dealershipId}/stock/edit/${vehicleId}/media`);
          break;
        }

        case 'list': {
          navigate(`/d/${data.dealershipId}/stock/list`);
          break;
        }

        default: {
          navigate(`/d/${data.dealershipId}/stock/list`);
          break;
        }
      }

      window.scrollTo(0, 0);
    } catch (e) {
      debug.error(e);
    }
  };

  const showCancelModal = () => {
    setIsCancelModalOpen(true);
  };

  const closeCancelModal = () => {
    setIsCancelModalOpen(false);
  };

  const handleCancel = () => {
    navigate(`/d/${dealershipId}/stock/list`);
  };

  const isLoading = formState.isSubmitting;
  const hasError = formState.errors;
  const loadingState = isLoading ? 'loading' : hasError ? 'error' : 'default';
  return (
    <>
      <form>
        <StockVehicleFormFields fieldEnabled={fieldEnabled} />

        <div className="createVehicleForm__buttons">
          <div className="createVehicleForm__cancel-btn-cont">
            <Button type="button" buttonStyle="cancel" onClick={showCancelModal} dataThook="stockCreateCancelBtn">
              {t('CreateVehicleForm.cancel')}
            </Button>
          </div>

          <div className="createVehicleForm__submit-btn-cont">
            <SplitButton state={loadingState} disabled={formState.isSubmitting}>
              <SplitButtonOptionDefault
                onClick={handleSubmit((data) => onSubmit(data, { nextStep: 'edit' }))}
                dataThook="stockCreateAndContinueBtn"
              >
                {t('CreateVehicleForm.create_continue')}
              </SplitButtonOptionDefault>
              <SplitButtonOption
                onClick={handleSubmit((data) => onSubmit(data, { nextStep: 'list' }))}
                dataThook="stockCreateAndExitBtn"
                type="submit"
              >
                {t('CreateVehicleForm.create_exit')}
              </SplitButtonOption>
            </SplitButton>
          </div>
        </div>
      </form>
      <Modal
        title={t('CreateVehicleForm.are_you_sure')}
        onClose={closeCancelModal}
        isOpen={isCancelModalOpen}
        onConfirm={handleCancel}
        buttonText={t('CreateVehicleForm.i_understand')}
      >
        <div className="stockCreateContainer__confirmationModal">
          {t(
            'CreateVehicleForm.if_you_proceed_your_vehicle_will_not_be_saved_and_youll_be_returned_to_your_stock_list'
          )}
        </div>
      </Modal>
    </>
  );
};

export default inject('appStore')(CreateVehicleForm);
