import { useQuery } from '@tanstack/react-query';


import type { DealerVehicleQueryResponse } from '~SM/types/StockModule';
import fetchJSON from '../../../../../../../../shared/services/fetchJSON';

const useVehicleData = (dealershipId: string, vehicleId: string) => {
  const url = `${process.env.REACT_APP_STOCK_API_PATH}/vehicle/${dealershipId}/${vehicleId}`;

  return useQuery<DealerVehicleQueryResponse>([dealershipId, vehicleId], () => fetchJSON(url));
};

export default useVehicleData;
