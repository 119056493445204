import { withTranslation } from 'react-i18next';
import DateFormat from '../../Common/DateFormat';
import AssignedToText from '../../AssignLead/components/AssignedToText';
import MoneyFormat from '../../Common/MoneyFormat';
import { commafy, formatAddress, formatExcess } from '../../../core/helpers';
import QuoteCommitmentMessage from '../../Quoting/components/QuoteCommitmentMessage';
import FormatAPR, { FormatNumToDecimals } from 'components/Quoting/components/FormatAPR';

// Printed Combined Quote from Product Compare tab on Price Position
const CombinedPrintedQuote = (props) => {
  const vehicle = props.vehicle || {};
  const quote = props.quote || {};
  const vehicleLoan = quote.VehicleLoan;
  const vapLoan = quote.PersonalLoan ? quote.PersonalLoan : null;
  const negativeEquityLoan = quote.NegativeEquityLoan ? quote.NegativeEquityLoan : null;

  const { countryCode } = props.appStore.uiState;
  return (
    <div style={styles.container}>
      <div style={styles.date}>
        <DateFormat value="" format="DD/MM/YYYY" />
      </div>
      <h2 style={styles.pageHeading}>{props.t('CombinedPrintedQuote.key_financial_information')}</h2>
      <AssignedToText assignees={props.assignees} />
      <h3 style={styles.heading}>{props.t('CombinedPrintedQuote.your_vehicle')}</h3>
      <table style={styles.vehicleTable}>
        <tbody>
          <tr>
            <td style={styles.vehicleName}>
              {vehicle.Make} {vehicle.Model} {vehicle.Derivative}
            </td>
          </tr>
          <tr>
            <td>{props.t('CombinedPrintedQuote.vehicle_type')}</td>
            <td style={styles.vehicleCondition}>{vehicle.Condition}</td>
          </tr>
          <tr>
            <td>{props.t('CombinedPrintedQuote.registration_number')}</td>
            <td style={styles.rightAlign}>{vehicle.SanitizedVrm || vehicle.Vrm}</td>
          </tr>
          <tr>
            <td>{props.t('CombinedPrintedQuote.date_of_registration')}</td>
            <td style={styles.rightAlign}>{vehicle.RegistrationDate}</td>
          </tr>
          <tr>
            <td>{props.t('CombinedPrintedQuote.current_mileage')}</td>
            <td style={styles.rightAlign}>{commafy(vehicle.Mileage)}</td>
          </tr>
        </tbody>
      </table>
      <h3 style={styles.heading}>{props.t('CombinedPrintedQuote.vehicle_loan_financial_information')}</h3>
      <table style={styles.quoteTable}>
        <tbody>
          <tr>
            <td>{props.t('CombinedPrintedQuote.product')}</td>
            <td style={styles.rightAlign}>{vehicleLoan.ProductName}</td>
          </tr>
          <tr>
            <td>{props.t('CombinedPrintedQuote.vehicle_price')}</td>
            <td style={styles.rightAlign}>
              <MoneyFormat value={vehicleLoan.VehiclePrice} />
            </td>
          </tr>
          {vehicleLoan.FinanceType === 'PCP' && (
            <tr>
              <td>{props.t('CombinedPrintedQuote.excess_per_mile')}</td>
              <td style={styles.rightAlign}>{formatExcess(vehicleLoan.ChargePerOverDistanceUnit ?? 0, countryCode)}</td>
            </tr>
          )}
          <tr>
            <td>{props.t('CombinedPrintedQuote.total_deposit')}</td>
            <td style={styles.rightAlign}>
              <MoneyFormat value={vehicleLoan.Deposit} />
            </td>
          </tr>
          <tr>
            <td>{props.t('CombinedPrintedQuote.total_amount_of_credit')}</td>
            <td style={styles.rightAlign}>
              <MoneyFormat value={vehicleLoan.BalancePayable} />
            </td>
          </tr>
          <tr>
            <td>{props.t('CombinedPrintedQuote.total_amount_payable')}</td>
            <td style={styles.rightAlign}>
              <MoneyFormat value={vehicleLoan.TotalAmountPayable} />
            </td>
          </tr>
          <tr>
            <td>{props.t('CombinedPrintedQuote.apr')}</td>
            <td style={styles.rightAlign}>
              <FormatAPR value={vehicleLoan.RepresentativeApr} />
            </td>
          </tr>
          <tr>
            <td>{props.t('CombinedPrintedQuote.interest_charges')}</td>
            <td style={styles.rightAlign}>
              <MoneyFormat value={vehicleLoan.InterestCharges} />
            </td>
          </tr>
          <tr>
            <td>{props.t('CombinedPrintedQuote.annual_fixed_interest_rate')}</td>
            <td style={styles.rightAlign}>
              <FormatNumToDecimals value={vehicleLoan.AnnualRate} decimal={2} appendChar="%" /> p.a
            </td>
          </tr>
          <tr>
            <td>{props.t('CombinedPrintedQuote.option_to_purchase_fee')}</td>
            <td style={styles.rightAlign}>
              <MoneyFormat value={vehicleLoan.OptionToPurchaseFee} />
            </td>
          </tr>
        </tbody>
      </table>
      <h3 style={styles.heading}>{props.t('CombinedPrintedQuote.your_proposed_payment_schedule_vehicle_loan')}</h3>
      <table style={styles.quoteTable}>
        <tbody>
          {vehicleLoan.PaymentSchedule.map((payment, index) => (
            <tr key={index}>
              <td>
                {index > 0 && props.t('CombinedPrintedQuote.followed_by')} {payment.Prefix}
              </td>
              <td style={styles.rightAlign}>
                <MoneyFormat value={payment.Amount} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {vapLoan && (
        <div>
          <h3 style={styles.heading}>{props.t('CombinedPrintedQuote.vap_loan_financial_information')}</h3>
          <table style={styles.quoteTable}>
            <tbody>
              {vapLoan.ValueAddedProducts.map((vap, index) => (
                <tr key={index}>
                  <td>{vap.ProductTypeText}</td>
                  <td style={styles.rightAlign}>
                    <MoneyFormat value={vap.Price} />
                  </td>
                </tr>
              ))}
              <tr>
                <td>{props.t('CombinedPrintedQuote.total_amount_of_credit')}</td>
                <td style={styles.rightAlign}>
                  <MoneyFormat value={vapLoan.BalancePayable} />
                </td>
              </tr>
              <tr>
                <td>{props.t('CombinedPrintedQuote.total_amount_payable')}</td>
                <td style={styles.rightAlign}>
                  <MoneyFormat value={vapLoan.TotalAmountPayable} />
                </td>
              </tr>
              <tr>
                <td>{props.t('CombinedPrintedQuote.apr')}</td>
                <td style={styles.rightAlign}>
                  <FormatAPR value={vapLoan.RepresentativeApr} />
                </td>
              </tr>
              <tr>
                <td>{props.t('CombinedPrintedQuote.interest_charges')}</td>
                <td style={styles.rightAlign}>
                  <MoneyFormat value={vapLoan.InterestCharges} />
                </td>
              </tr>
              <tr>
                <td>{props.t('CombinedPrintedQuote.annual_fixed_interest_rate')}</td>
                <td style={styles.rightAlign}>
                  <FormatNumToDecimals value={vapLoan.AnnualRate} decimal={2} appendChar="%" /> p.a
                </td>
              </tr>
            </tbody>
          </table>
          <h3 style={styles.heading}>{props.t('CombinedPrintedQuote.your_proposed_payment_schedule_vap_loan')}</h3>
          <table style={styles.quoteTable}>
            <tbody>
              {vapLoan.PaymentSchedule.map((payment, index) => (
                <tr key={index}>
                  <td>
                    {index > 0 && props.t('CombinedPrintedQuote.followed_by')} {payment.Prefix}
                  </td>
                  <td style={styles.rightAlign}>
                    <MoneyFormat value={payment.Amount} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {negativeEquityLoan && (
        <div>
          <h3 style={styles.heading}>{props.t('CombinedPrintedQuote.negative_equity_financial_information')}</h3>
          <table style={styles.quoteTable}>
            <tbody>
              <tr>
                <td>{props.t('CombinedPrintedQuote.negative_equity')}</td>
                <td style={styles.rightAlign}>
                  <MoneyFormat value={negativeEquityLoan.NegativeEquity} />
                </td>
              </tr>

              <tr>
                <td>{props.t('CombinedPrintedQuote.total_amount_of_credit')}</td>
                <td style={styles.rightAlign}>
                  <MoneyFormat value={negativeEquityLoan.BalancePayable} />
                </td>
              </tr>
              <tr>
                <td>{props.t('CombinedPrintedQuote.total_amount_payable')}</td>
                <td style={styles.rightAlign}>
                  <MoneyFormat value={negativeEquityLoan.TotalAmountPayable} />
                </td>
              </tr>
              <tr>
                <td>{props.t('CombinedPrintedQuote.apr')}</td>
                <td style={styles.rightAlign}>
                  <FormatAPR value={negativeEquityLoan.RepresentativeApr} />
                </td>
              </tr>
              <tr>
                <td>{props.t('CombinedPrintedQuote.interest_charges')}</td>
                <td style={styles.rightAlign}>
                  <MoneyFormat value={negativeEquityLoan.InterestCharges} />
                </td>
              </tr>
              <tr>
                <td>{props.t('CombinedPrintedQuote.annual_fixed_interest_rate')}</td>
                <td style={styles.rightAlign}>
                  <FormatNumToDecimals value={negativeEquityLoan.AnnualRate} decimal={2} appendChar="%" /> p.a
                </td>
              </tr>
            </tbody>
          </table>
          <h3 style={styles.heading}>
            {props.t('CombinedPrintedQuote.your_proposed_payment_schedule_negative_equity_loan')}
          </h3>
          <table style={styles.quoteTable}>
            <tbody>
              {negativeEquityLoan.PaymentSchedule.map((payment, index) => (
                <tr key={index}>
                  <td>
                    {index > 0 && props.t('CombinedPrintedQuote.followed_by')} {payment.Prefix}
                  </td>
                  <td style={styles.rightAlign}>
                    <MoneyFormat value={payment.Amount} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div>
        <p style={styles.paragraph}>{props.t('CombinedPrintedQuote.fees_disclaimer')}</p>

        {quote.FinanceType === 'PCP' && quote.FunderCode === 'MOT' && (
          <p style={styles.paragraph}>{props.t('CombinedPrintedQuote.mot_pcp_disclaimer')}</p>
        )}

        <p style={styles.paragraph}>{props.t('CombinedPrintedQuote.indemnity_disclaimer')}</p>
        <p style={styles.paragraph}>
          {props.t('CombinedPrintedQuote.assumptions_disclaimer')}
          <QuoteCommitmentMessage />
        </p>
        <p style={styles.paragraph}>{props.t('CombinedPrintedQuote.subject_to_status_disclaimer')}</p>
        <p style={styles.paragraph}>
          <b>
            {props.t('CombinedPrintedQuote.reference')} {quote.QuoteeProductCode}
          </b>
        </p>
      </div>
      <p style={styles.paragraph} key="par1">
        {props.quote && props.quote.Funder && (
          <div>
            {props.t('CombinedPrintedQuote.finance_is_provided_by')} {formatAddress(props.quote.Funder)}.{' '}
          </div>
        )}
      </p>
    </div>
  );
};

const styles = {
  heading: {
    fontSize: '18px',
    borderBottom: '1px solid black',
    textAlign: 'center'
  },
  date: {
    fontSize: '1.4rem',
    textAlign: 'right'
  },
  pageHeading: {
    textAlign: 'center'
  },
  rightAlign: {
    textAlign: 'right'
  },
  vehicleName: {
    textAlign: 'left'
  },
  container: {
    fontSize: '1.4rem',
    width: '80%',
    margin: '0 auto'
  },
  paragraph: {
    fontSize: '1.1rem'
  },
  vehicleTable: {
    width: '100%'
  },
  quoteTable: {
    width: '100%'
  },
  capital: {
    textTransform: 'capitalize'
  },
  footerText: {
    fontSize: '1.1rem',
    clear: 'both'
  },
  vehicleCondition: {
    textTransform: 'capitalize',
    textAlign: 'right'
  }
};
export default withTranslation('Application')(CombinedPrintedQuote);
