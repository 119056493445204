import React from 'react';
import PropTypes from 'prop-types';
import SettingsProductTabsItem from './SettingsProductTabsItem';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import './settingsProductTabs.scss';

const SettingsProductTabs = ({ setActiveTab, activeTab }) => {
  const { t } = useTranslation('ProductSettings');

  return (
    <div className="settingsProductTabs">
      <div className="settingsProductTabs__outer">
        <div className="settingsProductTabs__inner">
          <SettingsProductTabsItem
            index={0}
            text={t('SettingsProductTabs.traditional_products')}
            isActive={activeTab === 0}
            onClick={setActiveTab}
          />
          <SettingsProductTabsItem
            index={1}
            text={t('SettingsProductTabs.apr_only_products')}
            isActive={activeTab === 1}
            onClick={setActiveTab}
          />
        </div>
      </div>
    </div>
  );
};

SettingsProductTabs.propTypes = {
  setActiveTab: PropTypes.func.isRequired,
  activeTab: PropTypes.number
};

export default SettingsProductTabs;
