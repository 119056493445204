import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const EditApplicationRedirector = (props) => {
  const { dealershipId, consumerId, applicantId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit/quote/costs`, {
      replace: true
    });
  }, [navigate, dealershipId, consumerId, applicantId]);

  return null;
};

export default EditApplicationRedirector;
