import DateFormat from '../Common/DateFormat';
import MarkDown from '../Common/MarkDown';
import './platformUpdatesItem.scss';

interface Update {
  CreatedDate: string;
  Title: string;
  Content: string;
  Id: string;
}

interface PlatformUpdatesItemProps {
  update: Update;
  isExpanded: boolean;
  onToggleExpand: () => void;
}

const PlatformUpdatesItem = ({ update, isExpanded, onToggleExpand }: PlatformUpdatesItemProps) => {
  return (
    <div className="platformUpdatesItem">
      <header onClick={onToggleExpand} data-th={`toggleViewPlatformUpdatesItem-${update.Id}`}>
        <div className="platformUpdatesItem__date">
          <DateFormat value={update.CreatedDate} parse="utc" format="MMMM YYYY" />
        </div>
        <h3 className="platformUpdatesItem__title">{update.Title}</h3>
      </header>
      {isExpanded && (
        <div data-th={`expandedPlatformUpdatesItemContent-${update.Id}`}>
          <MarkDown>{update.Content}</MarkDown>
        </div>
      )}
      <hr className="platformUpdatesItem__hr" />
    </div>
  );
};

export default PlatformUpdatesItem;
