import ImageButton from '~Common/ImageButton';
import Modal from '~Common/Modal/Modal';
import { useTranslation } from 'react-i18next';
import './customerCreditModal.scss';

const CustomerCreditModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation('Quoting');
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="customerCreditModal">
        <header>
          <ImageButton image="information" width="30px" height="30px" />
          <h1>{t('CustomerCreditModal.customer_credit_rating_explained')}</h1>
        </header>
        <p>{t('CustomerCreditModal.credit_ratings_are_important')} </p>
        <p>{t('CustomerCreditModal.credit_ratings_can_vary')}</p>

        <ul className="nav">
          <li>
            <a href="https://www.experian.com/" target="_blank" rel="noreferrer">
              Experian
            </a>
          </li>
          <li>
            <a href="https://www.equifax.com/" target="_blank" rel="noreferrer">
              Equifax
            </a>
          </li>
          <li>
            <a href="https://www.transunion.co.uk/" target="_blank" rel="noreferrer">
              TransUnion
            </a>
          </li>
        </ul>

        <p>{t('CustomerCreditModal.when_you_apply_for_finance')}</p>
        <ul>
          <li>{t('CustomerCreditModal.personal_and_financial_information')}</li>
          <li>{t('CustomerCreditModal.historic_and_recent_credit_management')}</li>
          <li>{t('CustomerCreditModal.agreement_specific_information')}</li>
          <li>{t('CustomerCreditModal.affordability_when_compared')}</li>
        </ul>
        <p>{t('CustomerCreditModal.the_rating_you_select')}</p>
      </div>
    </Modal>
  );
};

export default CustomerCreditModal;
