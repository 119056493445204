import React from 'react';
import { useTranslation } from 'react-i18next';

import { CloseX } from '~SM/components/IconSetOne';

import { useFacetFilters } from '../../hooks';

import './selectedFacets.scss';

const SelectedFacets = () => {
  const { t } = useTranslation('Stock');
  const { refine } = useFacetFilters();

  const handleClick = () => {
    refine([]);
  };

  return (
    <button onClick={handleClick} className="selectedFacets__container selectedFacets__curvedBorder">
      <span className="selectedFacets__facetName">{t('SelectedFacets.vehicle_filters_applied')}</span>
      <span className="selectedFacets__close">
        <CloseX />
      </span>
    </button>
  );
};

export default SelectedFacets;
