import { getSessionId } from './sessionId';

export function init(R7Insight) {
  /*eslint-disable*/

  if (process.env.NODE_ENV === 'production') {
    window.R7Insight.init({
      token: process.env.REACT_APP_LOGENTRIES_TOKEN,
      region: 'eu',
      ssl: true,
      catchall: JSON.parse(process.env.REACT_APP_LOGENTRIES_CATCHALL)
    });

    log({
      type: 'SessionStart',
      userAgent: navigator.userAgent,
      sessionId: getSessionId()
    });

    window.addEventListener('error', onError);
    window.addEventListener('unhandledrejection', onUnhandledRejection);
  }
}

function onUnhandledRejection(e) {
  log({
    // If reason is an actual new Error() get the message
    // otherwise treat it as a RejectionEvent and get the reason string
    error: e.reason instanceof Error ? e.reason.message : e.reason,
    level: 'Error',
    location: window.location.href,
    sessionId: getSessionId()
  });
}

function onError(e) {
  log({
    error: e.message,
    stack: e.stack,
    level: 'Error',
    location: window.location.href,
    sessionId: getSessionId()
  });
}

export function error(message) {
  log({
    error: message,
    level: 'Error',
    location: window.location.href,
    sessionId: getSessionId()
  });
}

export function log(obj) {
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    // eslint-disable-next-line no-console
    if (obj.level === 'Error') {
      console.error(obj);
    } else {
      console.log(obj);
    }
  }

  if (process.env.NODE_ENV === 'production') {
    R7Insight.log({
      ...obj,
      sessionId: getSessionId()
    });
  }
}
