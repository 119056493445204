import Validator from './Validator';
import { computed } from 'mobx';

class CorporateQuickQuoteValidator {
  validationRules;
  validator;

  constructor() {
    this.validationRules = {
      tradingName: 'required',
      title: 'required',
      firstName: 'required',
      lastName: 'required',
      position: 'required',
      email: 'required, email',
      businessTelephone: 'required:unless:mobileTelephone, businessPhone',
      mobileTelephone: 'required:unless:businessTelephone, mobile'
    };

    this.validator = new Validator();
    this.validator.setRules(this.validationRules);
  }

  validate = (data) => {
    this.validator.validate(data);
  };

  getErrors = () => {
    return this.validator.getErrors();
  };

  @computed
  get isValid() {
    return !this.validator.errorCount;
  }

  setRules = (rules) => {
    this.validator.setRules(rules);
  };

  @computed
  get errorCount() {
    return this.validator.errorCount;
  }
}

export default CorporateQuickQuoteValidator;
