import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import s from './imageWithFallback.module.scss';

interface Props {
  imgUrl?: string;
  placeholder?: string;
}
const ImageWithFallback = ({ imgUrl, placeholder }: Props) => {
  const [loadError, setLoadError] = React.useState(false);
  const { t } = useTranslation('Stock');
  const handleImageLoadError = () => {
    setLoadError(true);
  };

  const src = imgUrl !== undefined && !loadError ? imgUrl : placeholder;
  const applyPlaceholderStyles = imgUrl === undefined || loadError;
  React.useEffect(() => {
    setLoadError(false);
  }, [imgUrl]);
  return (
    <img
      className={cn(s['image'], applyPlaceholderStyles && cn(s['placeholder']))}
      src={src}
      alt={t('VehicleImage.vehicle')}
      data-th="VehicleImage"
      onError={handleImageLoadError}
    />
  );
};

export default ImageWithFallback;

