import handleErrorResponse from './handleErrorResponse';
import * as debug from '~/debug';

const handleRequest = async (url: string, init: RequestInit = {}): Promise<Response> => {
  const { headers, ...restOfInit } = init;

  try {
    if (!url) {
      throw new TypeError('Invalid URL provided');
    }

    const response = await fetch(url, {
      ...restOfInit,
      headers: {
        'Content-Type': 'application/json',
        ...headers
      }
    });

    if (!response.ok) {
      throw await handleErrorResponse(response);
    }

    return response;
  } catch (err) {
    if (err instanceof TypeError) {
      debug.error(`TypeError: ${err.message}`);
    } else if (err instanceof Error) {
      debug.error(`Error: ${err.message}`);
    } else {
      debug.error('An unknown error occurred');
      throw new Error('An unknown error occurred');
    }
    throw err;
  }
};
export default handleRequest;
