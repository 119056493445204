import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './accountSettings.scss';
import Page from '../../Common/Page';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import { observer, inject } from 'mobx-react';
import Breadcrumbs from '../../Common/Breadcrumbs';
import SettingsAccountViewDetails from './SettingsAccountViewDetails';
import SettingsAccountEditDetails from './SettingsAccountEditDetails';
import LoadingSpinner from '../../Common/Loading/LoadingSpinner';

const AccountSettings = ({
  t,
  user,
  userId,
  dealershipId,
  appStore,
  currentUserId,
  saveUserDetails,
  isSubmitting,
  hasSubmittingError
}) => {
  return (
    <Page>
      <div className="accountSettings__breadcrumbs">
        <Breadcrumbs
          items={[
            {
              name: t('AccountSettings.home'),
              path: `/d/${dealershipId}`
            },
            {
              name: t('AccountSettings.settings'),
              path: `/d/${dealershipId}/settings`
            },
            {
              name: t('AccountSettings.my_account'),
              path: `/d/${dealershipId}/settings/users/${userId}`
            }
          ]}
        />
      </div>
      <Panel>
        <PanelHeader>{t('AccountSettings.my_account')}</PanelHeader>
        <PanelContent>
          {user ? (
            <div className="accountSettings__container">
              {appStore.uiState.canEditAccountSettings ? (
                <SettingsAccountEditDetails
                  user={user}
                  currentUser={currentUserId}
                  dealershipId={dealershipId}
                  onSubmit={saveUserDetails}
                  isSubmitting={isSubmitting}
                  hasSubmittingError={hasSubmittingError}
                  isRemoving={false}
                  hasRemovingError={false}
                  trackingPage="UserAccount"
                  goBackLink={`/d/${dealershipId}/settings`}
                />
              ) : (
                <SettingsAccountViewDetails
                  trackingPage="UserAccount"
                  user={user}
                  currentUser={currentUserId}
                  dealershipId={dealershipId}
                />
              )}
            </div>
          ) : (
            <div className="accountSettings__loading">
              <LoadingSpinner />
            </div>
          )}
        </PanelContent>
      </Panel>
    </Page>
  );
};

AccountSettings.propTypes = {
  userId: PropTypes.string,
  dealershipId: PropTypes.string,
  user: PropTypes.object,
  appStore: PropTypes.object,
  currentUserId: PropTypes.string,
  saveUserDetails: PropTypes.func,
  isSubmitting: PropTypes.bool,
  hasSubmittingError: PropTypes.bool
};
export default withTranslation('ManageUsers')(inject(['appStore'])(observer(AccountSettings)));
