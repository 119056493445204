import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './tabItem.scss';

const TabItem = ({ icon, children, dataHook }) => {
  const textClassNames = classnames('tabItem__text', !icon && 'tabItem__text--noIcon');

  return (
    <div data-th={dataHook} className="tabItem">
      {icon && <div className="tabItem__icon">{icon}</div>}
      <div className={textClassNames}>{children}</div>
    </div>
  );
};

TabItem.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node
};

export default TabItem;
