import { valueToFloat } from '~/core/helpers';

export const formatToNumDecimalPlaces = (value, decimal = 2, appendChar = '') => {
  if ((typeof value !== 'number' && (typeof value !== 'string' || value.trim() === '')) || isNaN(value)) {
    return null;
  }

  const stringValue = value.toString();
  let [integerPart, decimalPart = ''] = stringValue.split('.');

  if (decimalPart.length > decimal) {
    decimalPart = decimalPart.substring(0, decimal);
  } else {
    decimalPart = decimalPart.padEnd(decimal, '0');
  }

  return `${integerPart}${decimalPart === '' ? '' : '.'}${decimalPart}${appendChar}`;
};

export const generateDefaultQuoteRequestValues = ({
  defaultValues,
  vehicleClass,
  vehicleCondition,
  countryCode,
  vehicle,
  isBnpp
}) => {
  const isLcvOrNew = vehicleClass.toLowerCase() === 'lcv' || vehicleCondition.toLowerCase() === 'new';
  let isVatQualifying = isLcvOrNew;

  if (defaultValues.VatQualifying !== null && defaultValues.VatQualifying !== undefined) {
    isVatQualifying = defaultValues.VatQualifying;
  }

  let passedValues = {
    isBnpp: isBnpp,
    CountryCode: countryCode,
    VatQualifying: isVatQualifying,
    VehicleClass: vehicleClass.toLowerCase()
  };

  if (!isLcvOrNew) {
    passedValues = Object.assign(passedValues, {
      VatAmount: 0
    });
  }

  if (vehicle.Price && vehicleClass.toLowerCase() === 'lcv') {
    passedValues.BasicPrice = vehicle.Price;
  } else if (vehicle.Price) {
    passedValues.VehiclePrice = vehicle.Price;
  }

  return Object.assign({}, defaultValues, passedValues);
};

export const shouldShowCoverVatWarning = (quoteRequest) => {
  const { VehicleClass, VatAddedToDeposit } = quoteRequest;

  const isLCV = VehicleClass === 'lcv';
  if (!isLCV) {
    return false;
  }
  const PartExchange = valueToFloat(quoteRequest.PartExchange);
  const OutstandingSettlement = valueToFloat(quoteRequest.OutstandingSettlement);
  const CashDeposit = valueToFloat(quoteRequest.CashDeposit);
  const VatAmount = valueToFloat(quoteRequest.VatAmount);
  const isLCVWithVatAddedToDeposit = isLCV && VatAddedToDeposit === true && VatAmount > 0;
  const isVatCoveredByEquityAndCash = PartExchange - OutstandingSettlement + CashDeposit >= VatAmount;

  return isLCVWithVatAddedToDeposit && !isVatCoveredByEquityAndCash;
};

export const shouldShowNegativeEquityWarning = (quoteRequest) => {
  const CashDeposit = valueToFloat(quoteRequest?.CashDeposit);
  const PartExchange = valueToFloat(quoteRequest?.PartExchange);
  const OutstandingSettlement = valueToFloat(quoteRequest?.OutstandingSettlement);

  const Total = CashDeposit + PartExchange - OutstandingSettlement;

  return Total < 0;
};
