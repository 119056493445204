import _ from 'lodash';
import appStore from '../mobx-stores/appStore';
export const getCustomerEntities = (state) => state.entities.customers;

export const getCustomer = (state, customerId) => {
  const consumerId = customerId && customerId.toLowerCase();
  const customer = state.entities.customers[consumerId];

  if (customer) {
    const { AssignedTo, ...rest } = customer;

    return {
      ...rest,
      assignedTo: AssignedTo ? AssignedTo.map((user) => appStore.userStore.updateUserFromJSON(user)) : []
    };
  }
};

export function getVehicle(state, customerId, vehicleId) {
  const customer = getCustomer(state, customerId);
  return _.chain(customer)
    .get('VehiclesOfInterest')
    .find({ VehicleId: vehicleId })
    .value();
}

export function getCloseDealStatus(state, consumerId, vehicleId) {
  const vehicle = getVehicle(state, consumerId, vehicleId);
  return _.get(vehicle, 'Status', {});
}
