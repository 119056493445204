import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './deletedCustomerSuccessBanner.scss';
import Icon from '../Common/Icon/Icon';

const DeletedCustomerSuccessBanner = ({ isDeleted, onClick }) => {
  const { t } = useTranslation('DeleteCustomer');
  return (
    <TransitionGroup>
      {isDeleted ? (
        <CSSTransition
          classNames="deletedCustomerSuccess"
          timeout={{
            enter: 300,
            exit: 300
          }}
          key="deletedCustomerSuccess"
        >
          <div className="deletedCustomerSuccessBanner">
            <div className="deletedCustomerSuccessBanner__TextContainer">
              <div>{t('DeletedCustomerSuccessBanner.customer_record_successfully_removed')}</div>
              <div className="deletedCustomerSuccessBanner__smallText">
                {t('DeletedCustomerSuccessBanner.short_delay_before_disappears_from_dashboard')}
              </div>
            </div>
            <button onClick={onClick} className="deletedCustomerSuccessBanner__button">
              <Icon name="cross" />
            </button>
          </div>
        </CSSTransition>
      ) : null}
    </TransitionGroup>
  );
};

DeletedCustomerSuccessBanner.propTypes = {
  isDeleted: PropTypes.bool,
  onClick: PropTypes.func
};
export default DeletedCustomerSuccessBanner;
