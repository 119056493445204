import { useState, useCallback, useMemo } from 'react';

import objectValidation from '~/core/objectValidation';
import { countItemsInObject } from '~/core/helpers';

const useValidator = (rules) => {
  const [errors, setErrors] = useState({});

  const validate = useCallback(
    (data) => {
      const errors = objectValidation(data, rules);
      setErrors(errors || {});
    },
    [rules]
  );

  const errorCount = useMemo(() => countItemsInObject(errors), [errors]);

  return { errors, errorCount, validate };
};

export default useValidator;
