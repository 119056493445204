import React from 'react';
import { get } from 'lodash';
import useVehicleMetrics, { type Metric } from '../../services/useVehicleMetrics';
import { CustomersIcon, PriceTagIcon } from '../../../../../../../../shared/icons';
import { useTranslation } from 'react-i18next';
import s from './vehicleMetric.module.scss';

interface Props {
  vehicleId?: string;
  dealershipId?: string;
  metric: Metric;
  dataTh?: string;
}
const VehicleMetric = ({ vehicleId, dealershipId, metric, dataTh = 'vehicleMetric' }: Props) => {
  const { t } = useTranslation('Stock');

  const metricContent = {
    'finance_quotes_displayed:count': { label: t('VehicleMetrics.quotes_in_last_24_hours'), icon: <PriceTagIcon /> },
    'vehicle_search_click:count': { label: t('VehicleMetrics.customers_interested'), icon: <CustomersIcon /> }
  };
  const { data, isSuccess } = useVehicleMetrics({ vehicleId, dealershipId, metric });

  const sum = data && get(data, 'datasets[0].sum[0]');
  if (isSuccess && sum) {
    return (
      <div data-th={dataTh} className={s['vehicleMetric']}>
        <div className={s['vehicleMetricIcon']}>{metricContent[metric].icon}</div>
        <span>
          <span data-th="vehicleMertricValue" className={s['vehicleMetricValue']}>
            {sum ?? '-'}
          </span>{' '}
          {metricContent[metric].label}
        </span>
      </div>
    );
  }
  return null;
};

export default VehicleMetric;
