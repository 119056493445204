import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import ConsumerName from '../../../Common/ConsumerName';
import LinkedUsersLink from '../LinkedUsersLink';
import './customer.scss';
import CustomerEmail from './CustomerEmail';

const Customer = ({ consumer, handleModalOpen, handleEditCustomer, linkedUsers }) => {
  const { t } = useTranslation('Customer');
  return (
    <div className="customer__customerOuter">
      <div className="customer__body">
        <div className="customer__name">
          <ConsumerName name={consumer.Firstname + ' ' + consumer.Middlename + ' ' + consumer.Surname} />
        </div>
        {consumer.Landline ? <div>{consumer.Landline}</div> : ''}
        {consumer.Mobile ? <div>{consumer.Mobile}</div> : ''}
        <CustomerEmail email={consumer.Email} />
        {linkedUsers && (
          <LinkedUsersLink count={consumer.AliasCount} aliases={consumer.Aliases} handleClick={handleModalOpen} />
        )}
        {handleEditCustomer && (
          <div className="customer__editProfile" onClick={handleEditCustomer}>
            {t('Customer.edit_customer')}
          </div>
        )}
      </div>
    </div>
  );
};

Customer.propTypes = {
  consumer: PropTypes.object,
  handleModalOpen: PropTypes.func,
  handleEditCustomer: PropTypes.func,
  linkedUsers: PropTypes.bool
};
export default Customer;
