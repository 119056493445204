import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './selfServicedApplicationTag.scss';
import classnames from 'classnames';
import ssdIcon from '../../styles/icons/fass_envelope_white.svg';

const SelfServicedApplicationTag = ({ position }) => {
  const { t } = useTranslation('Common');
  return (
    <div className="selfServicedApplicationTag">
      <div
        className={classnames(
          'selfServicedApplicationTag__tag',
          position === 'customerPage' && 'selfServicedApplicationTag__topLeft'
        )}
      >
        <div className="selfServicedApplicationTag__icon">
          <img className="selfServicedApplicationTag__img" src={ssdIcon} key="img" alt="" />
        </div>
        <div className="selfServicedApplicationTag__text">{t('SelfServicedApplicationTag.self_service')}</div>
      </div>
    </div>
  );
};

SelfServicedApplicationTag.propTypes = {
  position: PropTypes.string
};
export default SelfServicedApplicationTag;
