import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Landing from '../components/Landing';
import * as dashboardActions from '../../../redux/dashboard/dashboardActions';
import { createSelector } from 'reselect';
import store from '../../../configureStore';
import { dashboardLenderUpdatesSelector } from '../../LenderUpdatesPage/selectors/lenderUpdatesSelectors';
import LoadingDots from '../../Common/Loading/LoadingDots';
import appStore from '../../../mobx-stores/appStore';
import { inject, observer } from 'mobx-react';
import { withOutletContextProps, withParams } from 'hocs/router';
import { push } from '../../../routerHistory';
import { compose } from 'redux';

@inject('appStore')
@observer
class DashboardContainer extends React.Component {
  componentDidMount() {
    store.dispatch(dashboardActions.resetDashboard());

    if (this.props.appStore.uiState.canViewDashboard) {
      this.props.subscribeToUpdates();
      this.props.appStore.dashboardQuotesStore.subscribeToQuotes();
      this.props.fetchDashboardData(this.props.appStore.uiState.canViewReservations);
    } else {
      this.props.redirectToMetrics();
    }
  }

  componentWillUnmount() {
    this.props.unSubscribeFromUpdates();
  }

  render() {
    if (this.props.isLoading || !this.props.hasLoaded) {
      return <LoadingDots />;
    }

    return <Landing {...this.props} />;
  }
}

DashboardContainer.propTypes = {
  children: PropTypes.any,
  fetchDashboardData: PropTypes.func.isRequired,
  subscribeToUpdates: PropTypes.func.isRequired,
  unSubscribeFromUpdates: PropTypes.func.isRequired,
  resetDashboard: PropTypes.func,
  isLoading: PropTypes.bool,
  hasLoaded: PropTypes.bool,
  appStore: PropTypes.object
};

function getDashboardItems(state) {
  //todo temporary glue until dashboard rewritten in MobX
  const items = {};

  for (const key in state.dashboard.items) {
    if (state.dashboard.items.hasOwnProperty(key)) {
      items[key] = {
        ...state.dashboard.items[key],
        assignedTo:
          state.dashboard.items[key].AssignedTo &&
          state.dashboard.items[key].AssignedTo.map((user) => {
            return appStore.userStore.updateUserFromJSON(user);
          })
      };
      delete items[key].AssignedTo;
    }
  }

  return items;
}

function getFinanceApplications(state) {
  return state.dashboard.Applications.Data;
}

function getCfcProspects(state) {
  return state.dashboard.CfcProspects.Data;
}

const financeApplicationsSelector = createSelector(
  getDashboardItems,
  getFinanceApplications,
  (dashboardItems, financeApplications) => {
    return financeApplications.map((id) => dashboardItems[id]);
  }
);

const cfcProspectsSelector = createSelector(getDashboardItems, getCfcProspects, (dashboardItems, cfcProspects) => {
  return cfcProspects.map((id) => dashboardItems[id]);
});

function mapStateToProps(state) {
  return {
    session: state.session,
    dealership: state.dealership,
    isLoading: state.session.isLoading || !state.products.hasInitiallyLoaded,
    hasLoaded: state.dashboard.hasLoaded,
    lenderUpdates: dashboardLenderUpdatesSelector(state),
    financeApplications: financeApplicationsSelector(state),
    cfcProspects: cfcProspectsSelector(state),
    options: state.options,
    dashboard: state.dashboard,
    cfcOptions: state.dashboard.options.CfcProspects,
    applicationsOptions: state.dashboard.options.Applications,
    lenderUpdatesOptions: state.dashboard.options.LenderUpdates,
    dashboardItems: state.dashboard.items
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    subscribeToUpdates: () => dispatch(dashboardActions.subscribeToUpdates()),
    unSubscribeFromUpdates: () => dispatch(dashboardActions.unSubscribeFromUpdates()),
    fetchDashboardData: (canViewReservations) => dispatch(dashboardActions.fetchDashboardData(canViewReservations)),
    filterCfcProspects: (matchRate) => {
      dispatch(dashboardActions.filterDashboardSection('CfcProspects', { MatchRate: matchRate }));
      dispatch(dashboardActions.fetchDashboardSection(ownProps.params.dealershipId, 'CfcProspects'));
    },
    filterApplications: (matchRate) => {
      dispatch(dashboardActions.filterDashboardSection('Applications', { MatchRate: matchRate }));
      dispatch(dashboardActions.fetchDashboardSection(ownProps.params.dealershipId, 'Applications'));
    },
    filterLenderUpdates: (status) => {
      dispatch(dashboardActions.filterDashboardSection('LenderUpdates', { status: status }));
      dispatch(dashboardActions.fetchDashboardSection(ownProps.params.dealershipId, 'LenderUpdates'));
    },
    filterDashboardLeads: (leadState) => {
      dispatch(dashboardActions.filterDashboardLeads(leadState, ownProps.session.UserId));
      dispatch(dashboardActions.fetchDashboardData());
    },
    resetDashboard: () => {
      dispatch(dashboardActions.resetDashboard());
    },
    redirectToStock: () => {
      push(`/d/${ownProps.params.dealershipId}/stock/list`);
    },
    redirectToMetrics: () => {
      push(`/d/${ownProps.params.dealershipId}/stock/dealership-metrics/average-days-in-stock`);
    }
  };
}

export default compose(
  withOutletContextProps,
  withParams,
  connect(mapStateToProps, mapDispatchToProps)
)(DashboardContainer);
