import React from 'react';
import s from './chartFilters.module.scss';

type FilterItemProps = {
  label: string;
  children: React.ReactNode;
};

const FilterItem = ({ label, children }: FilterItemProps) => {
  return (
    <div className={s['selectContainer']}>
      <p className={s['selectLabel']}>{label}</p>
      {children}
    </div>
  );
};

export default FilterItem;

