import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import Panel from '../../Common/Panel';
import PanelContent from '../../Common/PanelContent';
import PanelHeader from '../../Common/PanelHeader';
import PanelToolBar from '../../Common/PanelToolBar';
import LenderUpdatesFilter from '../LenderUpdatesPanel/LenderUpdatesFilter';
import LenderUpdatesPanelItem from '../LenderUpdatesPanel/LenderUpdatesPanelItem';
import FlipTile from '../TilesOld/FlipTile';
import TilesLayout from '../TilesOld/TilesLayout';
import DashboardFilter from './DashboardFilter';
import DashboardPanelLayout from './DashboardPanelLayout';
import DashboardPanelViewMore from './panel/DashboardPanelViewMore';
import NoPanelData from './NoPanelData';
import PanelItem from './PanelItem';
import Select from '../../Common/Form/Select';
import { getTileProps } from '../../../core/getTileLinks';
import { compose } from 'redux';
import { withParams } from 'hocs/router';
import { objectToSearchParams } from 'utils/objectToSearchParams';
import MetricCards from 'modules/Stock/routes/stock/routes/dealership-metrics/components/MetricCards/MetricCards';
import MarqueeQuotes from './MarqueeQuotes';
import { toJS } from 'mobx';
import './landingPage.scss';

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reservationsTile: {
        Count: 0,
        Time: '',
        Type: 'reservations'
      },
      reserveSettingsSet: false,
      reserveError: false
    };
  }

  componentDidMount() {
    const {
      uiState: { canViewReservations, hasOrders }
    } = this.props.appStore;

    if (canViewReservations) {
      this.getReserveData();
    }

    if (hasOrders) {
      this.getSentDealsCount();
    }
  }

  getReserveData = () => {
    const {
      appStore,
      params: { dealershipId }
    } = this.props;
    const { getReservationSettings, getDashboardReserveTileState } = appStore.reserveNowStore;
    getReservationSettings(dealershipId);
    getDashboardReserveTileState(dealershipId).then((response) => {
      if (response && response.status) {
        this.setReserveError();
      }

      if (response && response.reservationCount > 0) {
        const reservationsTile = {
          Count: (response && response.reservationCount) || 0,
          Time: '',
          Type: 'overdue'
        };
        this.setState({
          reservationsTile: reservationsTile
        });
      } else {
        this.getActiveReservationsCount();
      }
    });
  };
  getSentDealsCount = () => {
    const {
      appStore: { sentDealsStore },
      params: { dealershipId }
    } = this.props;
    sentDealsStore.getSentDealsCount(dealershipId);
  };
  getActiveReservationsCount = () => {
    const {
      appStore,
      params: { dealershipId }
    } = this.props;
    const { getReservationsCount } = appStore.reserveNowStore;
    getReservationsCount(dealershipId, 'active').then((response) => {
      if (response && response.reservationCount > 0) {
        const reservationsTile = {
          Count: (response && response.reservationCount) || 0,
          Time: '',
          Type: 'reservations'
        };
        this.setState({
          reservationsTile: reservationsTile
        });
      }
    });
  };
  setReserveError = () => {
    this.setState({
      reserveError: true
    });
  };

  getOptionsValue(name) {
    const obj = this.props.options.LenderStatus.find((status) => status.key.toLowerCase() === name.toLowerCase());
    return obj ? obj.value : name;
  }

  getLenderStatuses() {
    const facets = this.props.dashboard.LenderUpdates.Facets.Unfiltered;
    const statusFacet = facets.find((facet) => facet.Name === 'Status');
    const items = statusFacet ? statusFacet.Items : [];

    return items.map((obj) => ({
      key: obj.Name,
      value: this.getOptionsValue(obj.Name)
    }));
  }

  // handleSurveyClick = () => {
  //     // Send GA event on link click
  //     window.ga &&
  //         window.ga('send', 'event', 'dashboard', 'surveyLinkButtonPress', this.props.dealership.dealershipId);
  // };

  getTiles = () => {
    const { reservationsTile, reserveError } = this.state;
    const {
      appStore,
      dashboard,
      params: { dealershipId }
    } = this.props;
    const {
      uiState: { canViewReservations, canUsePushDeal, isDealershipAdmin },
      reserveNowStore: { isReserveSettingsSet },
      sentDealsStore: { sentDealsTileData, sentDealsError }
    } = appStore;
    let tiles = dashboard.Tiles.map((tile) => {
      return getTileProps(tile, dealershipId, isReserveSettingsSet);
    });

    if (canViewReservations) {
      const resTile = getTileProps(reservationsTile, dealershipId, isReserveSettingsSet, isDealershipAdmin);
      resTile.hasError = reserveError;
      tiles.splice(0, 0, resTile);
    }

    if (canUsePushDeal) {
      const dealTile = getTileProps(sentDealsTileData, dealershipId);
      dealTile.hasError = sentDealsError;
      tiles.splice(1, 0, dealTile);
    }

    return tiles;
  };

  render() {
    const {
      cfcProspects,
      cfcOptions,
      filterCfcProspects,
      financeApplications,
      applicationsOptions,
      filterApplications,
      lenderUpdates,
      lenderUpdatesOptions,
      filterLenderUpdates,
      filterDashboardLeads,
      appStore,
      dashboard
    } = this.props;
    const {
      dashboardQuotesStore: { quotes }
    } = appStore;

    let tiles = this.getTiles();
    const showFinanceChecks = cfcProspects && cfcProspects.length > 0;
    const showFinanceApplications = financeApplications && financeApplications.length > 0;
    const showlenderUpdates = lenderUpdates && lenderUpdates.length > 0;

    let lenderStatuses = this.getLenderStatuses().sort((a, b) => a.value.localeCompare(b.value));

    const options = [].concat(
      [
        {
          key: '',
          value: this.props.t('Landing.all')
        }
      ],
      lenderStatuses
    );
    const marqueeQuotes = toJS(quotes);
    return (
      <div className="landingPage">
        {marqueeQuotes?.length > 0 ? <MarqueeQuotes quotes={marqueeQuotes} /> : null}
        <MetricCards />
        <div className="landingPage__tilesOuter">
          <div className="landingPage__tilesAndHelpMessage">
            <TilesLayout>
              {tiles.map((tile, index) => (
                <FlipTile {...tile} to={tile.link} key={'tile' + index} />
              ))}
            </TilesLayout>

            {/* {!this.props.appStore.uiState.isMotonovo && (
                            <a
                                href="https://app.usabilityhub.com/do/a72ddb684e15/9c4f"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="landingPage__helpMessageContainer"
                                onClick={this.handleSurveyClick}
                            >
                                <img className="landingPage__icon" src={research} />
                                <div className="landingPage__helpMessage">New Product Survey</div>
                            </a>
                        )} */}
          </div>
          <div className="landingPage__leadsFilter" key="leads">
            {this.props.t('Landing.view')}
            <Select
              theme="alt"
              onChange={(event) => filterDashboardLeads(event.currentTarget.value)}
              emptyValue={false}
              value={dashboard.options.activeFilter}
              options="LeadsFilterOptions"
            />
          </div>
        </div>
        <DashboardPanelLayout>
          <Panel>
            <PanelHeader>{this.props.t('Landing.finance_checks')}</PanelHeader>
            <PanelToolBar hasPadding={false} hasUnderline={false}>
              <DashboardFilter filter={cfcOptions.MatchRate} onClick={filterCfcProspects} />
            </PanelToolBar>
            <PanelContent noPadding={true}>
              {showFinanceChecks ? (
                cfcProspects.map((info, index) => (
                  <PanelItem
                    key={`cfcprospect${index}`}
                    dealershipId={this.props.params.dealershipId}
                    item={info}
                    selectedFinanceTab="CfcProspects"
                    length={cfcProspects.length}
                    index={index + 1}
                  />
                ))
              ) : (
                <NoPanelData text={this.props.t('Landing.you_have_no_finance_checks')} />
              )}
            </PanelContent>
            <DashboardPanelViewMore
              link={{
                pathname: `/d/${this.props.params.dealershipId}/cfcprospects`,
                search: objectToSearchParams({
                  matchRate: cfcOptions.MatchRate || '',
                  activeFilter: cfcOptions.activeFilter,
                  page: 1
                }).toString()
              }}
            />
          </Panel>
          <Panel>
            <PanelHeader>{this.props.t('Landing.finance_applications')}</PanelHeader>
            <PanelToolBar hasPadding={false} hasUnderline={false}>
              <DashboardFilter filter={applicationsOptions.MatchRate} onClick={filterApplications} />
            </PanelToolBar>
            <PanelContent noPadding={true}>
              {showFinanceApplications ? (
                financeApplications.map((info, index) => (
                  <PanelItem
                    dealershipId={this.props.params.dealershipId}
                    item={info}
                    key={'cfc' + index}
                    selectedFinanceTab="FinanceApplications"
                    length={financeApplications.length}
                    index={index + 1}
                  />
                ))
              ) : (
                <NoPanelData text={this.props.t('Landing.you_have_no_current_finance_applications')} />
              )}
            </PanelContent>
            <DashboardPanelViewMore
              link={{
                pathname: `/d/${this.props.params.dealershipId}/financeapplications`,
                search: objectToSearchParams({
                  matchRate: applicationsOptions.MatchRate || '',
                  activeFilter: applicationsOptions.activeFilter,
                  page: 1
                }).toString()
              }}
            />
          </Panel>
          <Panel>
            <PanelHeader>{this.props.t('Landing.lender_updates')}</PanelHeader>
            <PanelToolBar hasPadding={false} hasUnderline={false}>
              <LenderUpdatesFilter
                filterLenderUpdates={filterLenderUpdates}
                value={lenderUpdatesOptions.status}
                options={options}
                text={this.props.t('Landing.status')}
              />
            </PanelToolBar>
            <PanelContent noPadding={true}>
              {showlenderUpdates ? (
                lenderUpdates.map((item, index) => (
                  <LenderUpdatesPanelItem
                    dealershipId={this.props.params.dealershipId}
                    item={item}
                    key={'lenderUpdates' + index}
                    index={index + 1}
                    length={lenderUpdates.length}
                  />
                ))
              ) : (
                <NoPanelData text={this.props.t('Landing.you_have_no_current_lender_updates')} />
              )}
            </PanelContent>
            <DashboardPanelViewMore
              link={{
                pathname: `/d/${this.props.params.dealershipId}/lenderupdatespage`,
                search: objectToSearchParams({
                  status: lenderUpdatesOptions.status || '',
                  activeFilter: lenderUpdatesOptions.activeFilter,
                  page: 1
                }).toString()
              }}
            />
          </Panel>
        </DashboardPanelLayout>
      </div>
    );
  }
}

Landing.propTypes = {
  params: PropTypes.object.isRequired,
  session: PropTypes.object,
  dashboard: PropTypes.object,
  options: PropTypes.object,
  fetchDashboardData: PropTypes.func,
  fetchDashboardSection: PropTypes.func,
  filterCfcProspects: PropTypes.func,
  filterApplications: PropTypes.func,
  filterLenderUpdates: PropTypes.func,
  cfcOptions: PropTypes.object,
  applicationsOptions: PropTypes.object,
  lenderUpdatesOptions: PropTypes.object,
  cfcProspects: PropTypes.array,
  lenderUpdates: PropTypes.array,
  financeApplications: PropTypes.array,
  filterDashboardLeads: PropTypes.func,
  appStore: PropTypes.object
};

export default compose(withParams, withTranslation('Dashboard'), inject(['appStore']), observer)(Landing);
