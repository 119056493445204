import PropTypes from 'prop-types';
import './panelFooter.scss';

const PanelFooter = ({ children, save, cancel }) => (
  <div className="panelFooter">
    {children}

    {cancel && <div className="panelFooter__cancel">{cancel}</div>}
    {save && <div className="panelFooter__save">{save}</div>}
  </div>
);

PanelFooter.propTypes = {
  children: PropTypes.node
};

export default PanelFooter;
