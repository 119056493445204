import PropTypes from 'prop-types';
import Checkbox from '../../Common/Form/Checkbox';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import './quotesListCardCheckbox.scss';

const QuotesListCardCheckbox = ({ quote, onQuoteSelect }) => {
  const { t } = useTranslation('QuotesList');
  return (
    <div
      className={classnames('quotesListCardCheckbox', quote.isChecked && 'quotesListCardCheckbox--checked')}
      data-th="QuotesListCardCheckbox"
    >
      <div className="quotesListCardCheckbox__text">{t('QuotesListCardCheckbox.select_quote')}</div>
      <div className="quotesListCardCheckbox__checkbox">
        <Checkbox
          id={'compare' + quote.QuoteId}
          onChange={(checked) => onQuoteSelect(quote.ProductId, checked)}
          value={quote.isChecked}
        />
      </div>
    </div>
  );
};

QuotesListCardCheckbox.propTypes = {
  quote: PropTypes.object,
  onQuoteSelect: PropTypes.func
};

export default QuotesListCardCheckbox;
