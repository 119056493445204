import React from 'react';
import PropTypes from 'prop-types';
import './dashboardPanelLayout.scss';

const DashboardPanelLayout = ({ children }) => (
  <div className="dashboardPanelLayout">
    {React.Children.toArray(children).map((child, i) => {
      return (
        <div key={i} className={`dashboardPanelLayout__panel dashboardPanelLayout__col${i}`}>
          {child}
        </div>
      );
    })}
  </div>
);

DashboardPanelLayout.propTypes = {
  children: PropTypes.node
};
export default DashboardPanelLayout;
