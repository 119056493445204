import React from 'react';
import PropTypes from 'prop-types';
import './bulletListItem.scss';
import classnames from 'classnames';

const BulletListItem = ({ valid, children }) => {
  return <li className={classnames('bulletListItem', valid && 'bulletListItem--valid')}>{children}</li>;
};

BulletListItem.propTypes = {
  children: PropTypes.node,
  valid: PropTypes.bool
};

export default BulletListItem;
