import React from 'react';
import { inject } from 'mobx-react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { StarRating } from './components';
import Checkbox from '~/components/Common/Form/Checkbox';
import { ChevronIcon, FilterIcon } from '../../../../shared/icons';
import { AppStore, StockStoreFacets } from '~SM/types/Types';

import s from './stockListFilterV2.module.scss';

interface Props {
  appStore: AppStore;
  resetFacets: () => void;
  handleToggleFilter: (facet: string, value?: number | boolean) => void;
}

const StockListFilterV2 = (props: Props) => {
  const { t } = useTranslation('Stock');
  const [isOpen, setIsOpen] = React.useState(false);

  const { stockStore, uiState } = props.appStore;

  const toggleFilterMenu = () => {
    setIsOpen((state) => !state);
  };

  const facets: StockStoreFacets = stockStore.facets;

  return (
    <>
      <div className={s['container']}>
        <div data-th="filterButton" onClick={toggleFilterMenu} className={s['filterButton']}>
          <div className={s['filterIcon']}>
            <FilterIcon />
          </div>
          <span className={s['filterButtonTitle']}>{t('StockListFilter.filter')}</span>
          <div className={cn(s['chevronIcon'], isOpen && s['chevronIcon--open'])}>
            <ChevronIcon />
          </div>
        </div>
        {/* Floating Filters */}
        <div className={cn(s['floatingFilterContainer'], !isOpen && s['floatingFilterContainer--closed'])}>
          <div className={s['floatingFilters']}>
            <div className={s['filterHeader']}>
              <div className={s['headerTitle']}>{t('StockListFilter.filter')}</div>
              <button data-th="stockListFilterResetButton" onClick={props.resetFacets} className={s['clearButton']}>
                {t('StockListFilter.clear')}
              </button>
            </div>
            <div className={s['floatingFilterContentContainer']}>
              {uiState.canViewDataAccuracy && (
                <div className={s['floatingFilterContent']}>
                  <div className={s['floatingFilterTitle']}>{t('StockListFilter.data_accuracy')}</div>
                  <div className={s['floatingFilterItems']}>
                    <div
                      className={s['optionContainer']}
                      data-th="accuracyScore1"
                      onClick={() => props.handleToggleFilter('accuracyScore', 1)}
                    >
                      <div className={s['checkboxAndText']}>
                        <Checkbox value={facets['accuracyScore']?.indexOf(1) !== -1} />
                        <div className={s['starsContainer']}>
                          <StarRating stars={3} active={3} />
                        </div>
                      </div>
                      <div data-th="filterCount" className={s['numberOfVehicles']}>
                        {stockStore.counts.accuracyScore[1] || 0}
                      </div>
                    </div>
                    <div
                      className={s['optionContainer']}
                      data-th="accuracyScore2"
                      onClick={() => props.handleToggleFilter('accuracyScore', 2)}
                    >
                      <div className={s['checkboxAndText']}>
                        <Checkbox value={facets['accuracyScore']?.indexOf(2) !== -1} />
                        <div className={s['starsContainer']}>
                          <StarRating stars={3} active={2} />
                        </div>
                      </div>

                      <div data-th="filterCount" className={s['numberOfVehicles']}>
                        {stockStore.counts.accuracyScore[2] || 0}
                      </div>
                    </div>
                    <div
                      className={s['optionContainer']}
                      data-th="accuracyScore3"
                      onClick={() => props.handleToggleFilter('accuracyScore', 3)}
                    >
                      <div className={s['checkboxAndText']}>
                        <Checkbox value={facets['accuracyScore']?.indexOf(3) !== -1} />
                        <div className={s['starsContainer']}>
                          <StarRating stars={3} active={1} />
                        </div>
                      </div>

                      <div data-th="filterCount" className={s['numberOfVehicles']}>
                        {stockStore.counts.accuracyScore[3] || 0}
                      </div>
                    </div>
                    <div
                      className={s['optionContainer']}
                      data-th="accuracyScore4"
                      onClick={() => props.handleToggleFilter('accuracyScore', 4)}
                    >
                      <div className={s['checkboxAndText']}>
                        <Checkbox value={facets['accuracyScore']?.indexOf(4) !== -1} />
                        <div className={s['starsContainer']}>
                          <StarRating stars={3} active={0} />
                        </div>
                      </div>
                      <div data-th="filterCount" className={s['numberOfVehicles']}>
                        {stockStore.counts.accuracyScore[4] || 0}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {uiState.hasJuhuModule && (
                <div className={s['floatingFilterContent']}>
                  <div className={s['floatingFilterTitle']}>{t('StockListItem.advertised_on')} Juhu</div>
                  <div className={s['floatingFilterItems']}>
                    <div
                      className={s['optionContainer']}
                      data-th="onJuhuFilterYes"
                      onClick={() => props.handleToggleFilter('onJuhu', true)}
                    >
                      <div className={s['checkboxAndText']}>
                        <Checkbox value={facets['onJuhu']?.indexOf(true) !== -1} />
                        <span className={s['checkboxText']}>{t('StockListFilter.yes')}</span>
                      </div>
                      <div data-th="filterCount" className={s['numberOfVehicles']}>
                        {stockStore.counts.onJuhu['true'] || '0'}
                      </div>
                    </div>
                    <div
                      className={s['optionContainer']}
                      data-th="onJuhuFilterNo"
                      onClick={() => props.handleToggleFilter('onJuhu', false)}
                    >
                      <div className={s['checkboxAndText']}>
                        <Checkbox value={facets['onJuhu']?.indexOf(false) !== -1} />
                        <span className={s['checkboxText']}>{t('StockListFilter.no')}</span>
                      </div>
                      <div data-th="filterCount" className={s['numberOfVehicles']}>
                        {stockStore.counts.onJuhu['false'] || '0'}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {uiState.canAddVehicles && (
                <div className={s['floatingFilterContent']}>
                  <div className={s['floatingFilterTitle']}>{t('StockListFilter.upload_type')}</div>
                  <div className={s['floatingFilterItems']}>
                    <div
                      className={s['optionContainer']}
                      data-th="dealerPlatformUploadYes"
                      onClick={() => props.handleToggleFilter('dealerPlatformUpload', true)}
                    >
                      <div className={s['checkboxAndText']}>
                        <Checkbox value={facets['dealerPlatformUpload']?.indexOf(true) !== -1} />
                        <span className={s['checkboxText']}>{t('StockListFilter.manual_vehicle')}</span>
                      </div>
                      <div data-th="filterCount" className={s['numberOfVehicles']}>
                        {stockStore.counts.dealerPlatformUpload['true'] || '0'}
                      </div>
                    </div>
                    <div
                      className={s['optionContainer']}
                      data-th="dealerPlatformUploadNo"
                      onClick={() => props.handleToggleFilter('dealerPlatformUpload', false)}
                    >
                      <div className={s['checkboxAndText']}>
                        <Checkbox value={facets['dealerPlatformUpload']?.indexOf(false) !== -1} />
                        <span className={s['checkboxText']}>{t('StockListFilter.feed_vehicle')}</span>
                      </div>
                      <div data-th="filterCount" className={s['numberOfVehicles']}>
                        {stockStore.counts.dealerPlatformUpload['false'] || '0'}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        data-th="clickOutsideFilterContainer"
        onClick={() => setIsOpen(false)}
        className={cn(s['clickOutsideContainer'], isOpen && s['clickOutsideContainer--open'])}
      />
    </>
  );
};

export default inject('appStore')(StockListFilterV2);

