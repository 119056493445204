import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFormFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import TextInputWrappingInput from '../../Common/Form/TextInputWrappingInput';
import { action, reaction, extendObservable } from 'mobx';
import { observer, inject } from 'mobx-react';
import Validator from '../../../validators/Validator';
import SelectInput from '../../Common/Form/SelectInput';
import PostcodeLookup from '../../Common/PostcodeLookup/PostcodeLookup';
import Button from '../../Common/Button/Button';
import SplitButton from '../../Common/SplitButton';
import SplitButtonOptionDefault from '../../Common/SplitButtonOptionDefault';
import SplitButtonOption from '../../Common/SplitButtonOption';
import './editConsumerForm.scss';
import { trimDeepObjectStrings } from '../../../core/helpers';
import { compose } from 'redux';
import { withNavigate } from '../../../hocs/router';
import { PhoneNumberInput } from '~/components/Common/Form/PhoneNumberInput';

class EditConsumerForm extends React.Component {
  constructor(props) {
    super();
    const validationRules = {
      title: 'required',
      firstName: 'required, maxlength:50',
      middleName: 'maxlength:50',
      surname: 'required, maxlength:50',
      email: 'required, email',
      landline: 'required:unless:mobile, landline',
      mobile: 'required:unless:landline, mobile',
      buildingName: 'maxlength:40',
      buildingNumber: 'maxlength:10',
      subBuilding: 'maxlength:15',
      street: 'maxlength:75, lenderAlphanumeric',
      district: 'maxlength:55, lenderAlphanumeric',
      postTown: 'maxlength:35',
      postCode: 'alphaNumSpace'
    };
    extendObservable(this, {
      formData: {
        customerType: 'consumer',
        email: props.customer.Email,
        title: props.customer.Title,
        firstName: props.customer.Firstname,
        middleName: props.customer.Middlename,
        surname: props.customer.Surname,
        landline: props.customer.Landline,
        mobile: props.customer.Mobile,
        buildingName: props.customer.Address.BuildingName,
        buildingNumber: props.customer.Address.BuildingNumber,
        subBuilding: props.customer.Address.SubBuilding,
        street: props.customer.Address.Street,
        district: props.customer.Address.District,
        postTown: props.customer.Address.PostTown,
        postCode: props.customer.Address.Postcode
      }
    });
    this.setUpValidation(validationRules);
  }

  setUpValidation(validationRules) {
    this.validator = new Validator();
    this.validator.setRules(validationRules);
    this.validationReactionDisposer = reaction(() => ({ ...this.formData }), this.validator.validate, {
      fireImmediately: true
    });
  }

  @action
  cleanupFormData = () => {
    this.formData = trimDeepObjectStrings(this.formData);
    this.validator.validate(this.formData);
  };
  handleSubmit = () => {
    const {
      appStore: { customerListStore },
      customer: { Id: customerId }
    } = this.props;
    this.cleanupFormData();

    if (!this.validator.errorCount) {
      customerListStore.updateCustomer(this.formData, customerId).then(() => {
        this.props.navigate(`/d/${this.props.dealershipId}/consumers/${customerId}`);
      });
    }
  };
  handleDeleteCustomer = () => {
    this.props.navigate(`/d/${this.props.dealershipId}/consumers/${this.props.customer.Id}/deletecustomer`);
  };
  handleCancel = () => {
    this.props.navigate(-1);
  };

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  @action
  handlePostCodeLookup = (address) => {
    this.formData.buildingName = address.BuildingName;
    this.formData.buildingNumber = address.BuildingNumber;
    this.formData.subBuilding = address.SubBuilding;
    this.formData.street = address.Street;
    this.formData.district = address.District;
    this.formData.postTown = address.PostTown;
    this.formData.postCode = address.Postcode;
    this.cleanupFormData();
  };
  @action
  setTitle = (fieldName, title) => (this.formData.title = title);
  @action
  setFirstName = (firstName) => (this.formData.firstName = firstName);
  @action
  setMiddleName = (middleName) => (this.formData.middleName = middleName);
  @action
  setSurname = (surname) => (this.formData.surname = surname);
  @action
  setLandline = (landline) => (this.formData.landline = landline);
  @action
  setMobile = (mobile) => (this.formData.mobile = mobile);
  @action
  setBuildingName = (buildingName) => (this.formData.buildingName = buildingName);
  @action
  setBuildingNumber = (buildingNumber) => (this.formData.buildingNumber = buildingNumber);
  @action
  setSubBuilding = (subBuilding) => (this.formData.subBuilding = subBuilding);
  @action
  setStreet = (street) => (this.formData.street = street);
  @action
  setDistrict = (district) => (this.formData.district = district);
  @action
  setPostTown = (postTown) => (this.formData.postTown = postTown);
  @action
  setPostCode = (postCode) => (this.formData.postCode = postCode);

  render() {
    const errors = this.validator.getErrors();
    const {
      appStore: { customerListStore, uiState }
    } = this.props;
    const loadingState = customerListStore.updateCustomerRequest.isLoading ? 'loading' : '';
    const funderCode = uiState.getPlatformCode;

    return (
      <MobxForm onSubmit={this.handleSubmit} focusOnFirstElement className="editConsumerForm">
        <section className="editConsumerForm__personalDetails">
          <h2 className="editConsumerForm__header editConsumerForm__personalDetailsHeader">
            {this.props.t('EditConsumerForm.personal_details')}
          </h2>

          <div className="editConsumerForm__fields">
            <MobxFormFieldGroup error={errors.email}>
              <MobxFormLabel htmlFor="email">{this.props.t('EditConsumerForm.email')}</MobxFormLabel>
              <TextInputWrappingInput
                id="email"
                value={this.formData.email}
                type="email"
                disabled
                autoComplete="none"
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.title}>
              <MobxFormLabel htmlFor="title">{this.props.t('EditConsumerForm.title')}</MobxFormLabel>
              <SelectInput
                id="title"
                value={this.formData.title}
                onChange={this.setTitle}
                options="CustomerTitles"
                funderCode={funderCode}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.firstName}>
              <MobxFormLabel htmlFor="firstName">{this.props.t('EditConsumerForm.first_name')}</MobxFormLabel>
              <TextInputWrappingInput id="firstName" value={this.formData.firstName} onChange={this.setFirstName} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.middleName}>
              <MobxFormLabel htmlFor="middleName">{this.props.t('EditConsumerForm.middle_name')}</MobxFormLabel>
              <TextInputWrappingInput id="middleNames" value={this.formData.middleName} onChange={this.setMiddleName} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.surname}>
              <MobxFormLabel htmlFor="surname">{this.props.t('EditConsumerForm.last_name')}</MobxFormLabel>
              <TextInputWrappingInput id="surname" value={this.formData.surname} onChange={this.setSurname} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup
              error={errors.landline}
              errorMessage={
                errors.landline?.error === 'required' && this.props.t('EditConsumerForm.telephone_or_mobile_required')
              }
            >
              <MobxFormLabel htmlFor="landline">{this.props.t('EditConsumerForm.home_telephone')}</MobxFormLabel>
              <PhoneNumberInput id="landline" value={this.formData.landline} onChange={this.setLandline} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup
              error={errors.mobile}
              errorMessage={
                errors.mobile?.error === 'required' && this.props.t('EditConsumerForm.telephone_or_mobile_required')
              }
            >
              <MobxFormLabel htmlFor="mobile">{this.props.t('EditConsumerForm.or_mobile_telephone')}</MobxFormLabel>
              <PhoneNumberInput id="mobile" value={this.formData.mobile} onChange={this.setMobile} />
            </MobxFormFieldGroup>
          </div>
        </section>

        <section className="editConsumerForm__addressDetails">
          <h2 className="editConsumerForm__header editConsumerForm__addressDetailsHeader">
            {this.props.t('EditConsumerForm.address_details')}
          </h2>

          <div className="editConsumerForm__fields">
            <PostcodeLookup onSubmit={this.handlePostCodeLookup} />

            <MobxFormFieldGroup error={errors.buildingName}>
              <MobxFormLabel htmlFor="buildingName">{this.props.t('EditConsumerForm.building_name')}</MobxFormLabel>
              <TextInputWrappingInput
                id="buildingName"
                value={this.formData.buildingName}
                onChange={this.setBuildingName}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.buildingNumber}>
              <MobxFormLabel htmlFor="buildingNumber">{this.props.t('EditConsumerForm.building_number')}</MobxFormLabel>
              <TextInputWrappingInput
                id="buildingNumber"
                value={this.formData.buildingNumber}
                onChange={this.setBuildingNumber}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.subBuilding}>
              <MobxFormLabel htmlFor="subBuilding">{this.props.t('EditConsumerForm.room_floor')}</MobxFormLabel>
              <TextInputWrappingInput
                id="subBuilding"
                value={this.formData.subBuilding}
                onChange={this.setSubBuilding}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.street}>
              <MobxFormLabel htmlFor="street">{this.props.t('EditConsumerForm.street')}</MobxFormLabel>
              <TextInputWrappingInput id="street" value={this.formData.street} onChange={this.setStreet} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.district}>
              <MobxFormLabel htmlFor="district">{this.props.t('EditConsumerForm.district')}</MobxFormLabel>
              <TextInputWrappingInput id="district" value={this.formData.district} onChange={this.setDistrict} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.postTown}>
              <MobxFormLabel htmlFor="postTown">{this.props.t('EditConsumerForm.city_town')}</MobxFormLabel>
              <TextInputWrappingInput id="postTown" value={this.formData.postTown} onChange={this.setPostTown} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.postCode}>
              <MobxFormLabel htmlFor="postCode">{this.props.t('EditConsumerForm.postcode')}</MobxFormLabel>
              <TextInputWrappingInput id="postCode" value={this.formData.postCode} onChange={this.setPostCode} />
            </MobxFormFieldGroup>
          </div>
        </section>

        <div className="editConsumerForm__footer">
          <div className="editConsumerForm__footerCancel">
            <Button onClick={this.handleCancel} buttonStyle="cancel">
              {this.props.t('EditConsumerForm.cancel')}
            </Button>
          </div>
          <div className="editConsumerForm__footerSubmit">
            {this.props.appStore.uiState.canDeleteConsumers ? (
              <SplitButton state={loadingState}>
                <SplitButtonOptionDefault onClick={this.handleSubmit}>
                  {this.props.t('EditConsumerForm.update_customer_contact')}
                </SplitButtonOptionDefault>
                <SplitButtonOption onClick={this.handleDeleteCustomer} iconName="bin">
                  {this.props.t('EditConsumerForm.delete_customer')}
                </SplitButtonOption>
              </SplitButton>
            ) : (
              <Button isLoading={loadingState === 'loading'} onClick={this.handleSubmit}>
                {this.props.t('EditConsumerForm.update_customer_contact')}
              </Button>
            )}
          </div>
        </div>
      </MobxForm>
    );
  }
}

EditConsumerForm.propTypes = {
  dealershipId: PropTypes.string,
  appStore: PropTypes.object,
  onCustomerTypeChange: PropTypes.func,
  customer: PropTypes.object
};

export default compose(withNavigate, withTranslation('EditCustomer'), inject(['appStore']), observer)(EditConsumerForm);
