import PropTypes from 'prop-types';
import Checkbox from './Checkbox';
import uuid from 'uuid';
import './checkboxWithLabel.scss';
import { useMemo } from 'react';

const CheckboxWithLabel = (props) => {
  let { children, dataThook, ...inputProps } = props;

  const checkboxId = useMemo(() => uuid.v4(), []);

  return (
    <div className="checkboxWithLabel" data-th="CheckboxWithLabel">
      <div className="checkboxWithLabel__checkbox">
        <Checkbox {...inputProps} id={checkboxId} dataThook={dataThook} />
      </div>
      <label className="checkboxWithLabel__label" htmlFor={checkboxId}>
        {children}
      </label>
    </div>
  );
};

CheckboxWithLabel.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
  value: PropTypes.any,
  name: PropTypes.string,
  dataThook: PropTypes.string
};

export default CheckboxWithLabel;
