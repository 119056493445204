import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import ErrorApology from '../../Common/ErrorApology';
import { inject, observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';
import { withParams, withNavigate } from 'hocs/router';
import { compose } from 'redux';

class ManageUsersContainer extends React.Component {
  componentDidMount() {
    this.props.appStore.userStore.fetchAllUsers(this.props.params.dealershipId);
  }

  saveUserDetails = (userId, data) => {
    const { dealershipId } = this.props.params;
    this.props.appStore.userStore.saveUserDetails(dealershipId, userId, data);
  };
  removeUser = (userId) => {
    const { dealershipId } = this.props.params;
    this.props.appStore.userStore.removeUser(dealershipId, userId).then(() => {
      this.props.navigate(`/d/${dealershipId}/settings/users`);
    });
  };

  render() {
    if (this.props.appStore.userStore.fetchAllUsersRequest.isLoading) {
      return null;
    }

    if (
      this.props.appStore.userStore.fetchAllUsersRequest.error &&
      this.props.appStore.userStore.fetchAllUsersRequest.error.status === 403
    ) {
      return (
        <ErrorApology>
          {this.props.t('ManageUsersContainer.you_do_not_have_permission_to_perform_this_action')}
        </ErrorApology>
      );
    }

    if (this.props.appStore.userStore.fetchAllUsersRequest.hasError) {
      return <ErrorApology>{this.props.t('ManageUsersContainer.error_fetching_users')}</ErrorApology>;
    }

    return (
      <Outlet
        context={{
          ...this.props,
          users: this.props.appStore.userStore.manageUsersList,
          userId: this.props.params.userId,
          dealershipId: this.props.params.dealershipId,
          currentUserId: this.props.session.UserId,
          saveUserDetails: this.saveUserDetails,
          isSubmitting: this.props.appStore.userStore.saveUserDetailsRequest.isLoading,
          hasSubmittingError: this.props.appStore.userStore.saveUserDetailsRequest.hasError,
          removeUser: this.removeUser,
          isRemoving: this.props.appStore.userStore.removeUserRequest.isLoading,
          hasRemovingError: this.props.appStore.userStore.removeUserRequest.hasError,
          addUser: this.props.appStore.userStore.addUser,
          isAddingUser: this.props.appStore.userStore.addUserRequest.isLoading,
          hasAddUserError: this.props.appStore.userStore.addUserRequest.hasError,
          hasDuplicateEmailError: _.get(this.props.appStore.userStore.addUserRequest, 'error.status') === 409
        }}
      />
    );
  }
}

ManageUsersContainer.propTypes = {
  session: PropTypes.object,
  appStore: PropTypes.object,
  params: PropTypes.object
};

function mapStateToProps(state) {
  return {
    session: state.session
  };
}

export default compose(
  withNavigate,
  withParams,
  withTranslation('ManageUsers'),
  inject(['appStore']),
  connect(mapStateToProps),
  observer
)(ManageUsersContainer);
