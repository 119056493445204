import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Common/Icon/Icon';
import './sentDealTag.scss';
import DateFormat from '../../Common/DateFormat';
import i18n from '../../../i18n';

export const setTagData = (status) => {
  const tags = {
    Default: {
      text: '',
      icon: ''
    },
    Sent: {
      text: i18n.t('SentTagDeal.sent', {
        ns: 'DigitalDeals'
      }),
      icon: 'send-deal'
    },
    Viewed: {
      text: i18n.t('SentTagDeal.opened', {
        ns: 'DigitalDeals'
      }),
      icon: 'opened-deal'
    },
    ApplicationSubmitted: {
      text: i18n.t('SentTagDeal.applied', {
        ns: 'DigitalDeals'
      }),
      icon: 'tick-in-circle'
    }
  };

  return tags[status];
};

const SentDealTag = ({ status, openedAt }) => {
  status = status ? status : 'Default';
  const tagData = setTagData(status);
  const showOpenedAtDate = status === 'Viewed';

  return (
    <div className="sentDealTag__outer">
      <div className={`sentDealTag sentDealTag--${status}`}>
        <Icon name={tagData.icon} />
        <span className="sentDealTag__text">{tagData.text}</span>
      </div>
      {showOpenedAtDate && <DateFormat value={openedAt} format="DD/MM/YY HH:mm" />}
    </div>
  );
};

SentDealTag.propTypes = {
  status: PropTypes.string,
  openedAt: PropTypes.string
};

export default SentDealTag;
