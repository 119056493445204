import React from 'react';
import PropTypes from 'prop-types';
import Input from './Input';

class TextInputWrappingInput extends React.Component {
  handleChange = (event) => {
    this.props.onChange && this.props.onChange(event.currentTarget.value);
  };

  render() {
    let {
      id,
      value,
      disabled,
      isValid,
      isInvalid,
      type,
      prefixIcon,
      suffixIcon,
      blueIcon,
      placeholder,
      handleModal,
      autoComplete
    } = this.props;

    return (
      <Input
        size={this.props.size}
        type={type}
        value={value}
        onChange={this.handleChange}
        id={id}
        disabled={disabled}
        isValid={isValid}
        isInvalid={isInvalid}
        prefixIcon={prefixIcon}
        suffixIcon={suffixIcon}
        blueIcon={blueIcon}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        placeholder={placeholder}
        handleModal={handleModal}
        autoComplete={autoComplete}
      />
    );
  }
}

TextInputWrappingInput.defaultProps = {
  size: 'medium',
  type: 'text'
};

TextInputWrappingInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  id: PropTypes.string,
  size: PropTypes.string,
  prefixIcon: PropTypes.string,
  suffixIcon: PropTypes.string,
  blueIcon: PropTypes.bool,
  placeholder: PropTypes.string
};

export default TextInputWrappingInput;
