function removeEmpty(obj: Record<string, any>): Record<string, any> {
  let result: Record<string, any> = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value != null) {
      result[key] = value;
    }
  }
  return result;
}

export default removeEmpty;
