import platformApiUtils from '@ivendi/platform-api-utils';

export const sendDeal = (quoteIds, vehicleId, customerId) => {
  return new Promise((resolve, reject) => {
    platformApiUtils
      .post(`v1/customer/${customerId}/sendQuotes`, {
        QuoteIds: quoteIds,
        VehicleId: vehicleId
      })
      .then((response) => {
        if (response) {
          resolve({
            CustomerId: response.CustomerId,
            VehicleId: response.VehicleId
          });
        } else {
          reject(response);
        }
      })
      .catch((err) => reject(err));
  });
};

export const sendDealQuickQuote = (applicationId, customerId, vehicleId, dealershipId, vehicle, quoteIds, formData) => {
  let vehicleClass;
  if (vehicle.Class && vehicle.Class.toLowerCase() === 'bike') {
    vehicleClass = 'motorbike';
  } else if (vehicle.Class && vehicle.Class.toLowerCase() === 'van') {
    vehicleClass = 'lcv';
  } else {
    vehicleClass = vehicle.Class;
  }

  return new Promise((resolve, reject) => {
    platformApiUtils
      .post('v2/quick-quote/', {
        ApplicationId: applicationId,
        CopyApplicationFrom: null,
        CustomerType: formData.CustomerType === 'corporate' ? 'corporate' : 'consumer',
        CustomerId: customerId,

        VehicleId: vehicleId,
        QuoteIds: quoteIds,

        CapId: vehicle.CapId,
        MakeId: vehicle.MakeId,
        ModelId: vehicle.ModelId,
        CapMakeId: vehicle.CapMakeId,
        CapModelId: vehicle.CapModelId,
        Make: vehicle.Make,
        Model: vehicle.Model,
        Derivative: vehicle.Derivative,
        Class: vehicleClass,
        Condition: vehicle.Condition,
        Vrm: vehicle.Vrm,
        Vin : vehicle.Vin,
        SanitizedVrm: vehicle.SanitizedVrm,
        RegistrationDate: vehicle.RegistrationDate,
        Mileage: vehicle.Mileage,
        CdnVehicleImageUrl: vehicle.CdnVehicleImageUrl,
        DealershipId: dealershipId,
        AssignToSelf: formData.AssignToSelf,

        Title: formData.Title,
        Firstname: formData.FirstName,
        Middlenames: formData.MiddleNames,
        Surname: formData.LastName,
        Email: formData.Email,
        Landline: formData.Landline,
        Mobile: formData.Mobile,
        Gender: formData.Gender,
        DateOfBirth: formData.DateOfBirth,
        Nationality: formData.Nationality,
        DualNationality: formData.DualNationality,
        MaritalStatus: formData.MaritalStatus,
        NoOfDependants: formData.NoOfDependants,
        DrivingLicence: formData.DrivingLicence,
        DrivingLicenceNumber: formData.DrivingLicenceNumber,
        Position: formData.Position,
        TradingName: formData.TradingName,
        ApplicationRequestType: 2
      })
      .then((response) => {
        if (response) {
          resolve({
            CustomerId: response.CustomerId,
            VehicleId: response.VehicleId
          });
        } else {
          reject(response);
        }
      })
      .catch((error) => reject(error));
  });
};
