import React from 'react';
import PropTypes from 'prop-types';
import './dealershipDropdownListItem.scss';
import classnames from 'classnames';
const DealershipDropdownListItem = ({ dealer, current, index, handleClick }) => {
  const onClick = () => {
    handleClick(dealer.Id);
  };

  const liClassNames = classnames(
    'dealershipDropdownListItem',
    dealer.Id === current && 'dealershipDropdownListItem--active',
    index === 0 && 'dealershipDropdownListItem--first'
  );

  return (
    <li className={liClassNames} data-th="DealershipDropdownListItem">
      <button className="dealershipDropdownListItem__button" onClick={onClick}>
        {dealer.Name}
      </button>
    </li>
  );
};
DealershipDropdownListItem.propTypes = {
  dealer: PropTypes.object,
  handleClick: PropTypes.func,
  index: PropTypes.number,
  current: PropTypes.string
};

export default DealershipDropdownListItem;
