import * as api from '../api/assignLead';
import { observable, action, computed } from 'mobx';
import Request from '../mobx-models/Request';

class AssignLeadStore {
  @observable searchQuery = '';
  @observable currentLeadAssignees = [];
  @observable searchResults = [];
  @observable selectedSearchResult = null;
  @observable activeSearchResultIndex = null;
  @observable usersToAssign = [];
  @observable usersToUnassign = [];

  initialLeadAssignees = [];
  searchUsersRequest;
  submitAssignedLeadsRequest;

  constructor(store) {
    this.appStore = store;
    this.searchUsersRequest = new Request();
    this.submitAssignedLeadsRequest = new Request();
  }

  @action
  startAssigningLeads = (initialAssignees) => {
    //concat doesn't like observable arrays, get a normal one with .slice()
    this.initialLeadAssignees = [].concat(initialAssignees.slice());
    this.currentLeadAssignees = [].concat(initialAssignees.slice());

    this.usersToAssign = [];
    this.usersToUnassign = [];
  };

  @action
  searchUsers = (dealershipId, query) => {
    this.searchUsersRequest.reset();
    this.searchUsersRequest.onSuccess(this.onSearchUsersSuccess);
    this.searchUsersRequest.call(api.searchUsers, dealershipId, query);

    this.activeSearchResultIndex = null;
    this.selectedSearchResult = null;
    this.searchQuery = query;
  };

  @action
  onSearchUsersSuccess = (results) => {
    this.searchResults = results.map((user) => this.appStore.userStore.updateUserFromJSON(user));
    this.activeSearchResultIndex = 0;
  };

  @action
  selectResult = (user) => {
    this.selectedSearchResult = user;
    this.searchResults = [];
    this.activeSearchResultIndex = null;
  };

  @action
  addUserToList = (user, list) => {
    if (!list.find((existingUser) => existingUser.id === user.id)) {
      list.push(user);
    }
  };

  @action
  removeUserFromList = (user, list) => {
    const existingUserIndex = list.findIndex((existingUser) => existingUser.id === user.id);

    if (existingUserIndex !== -1) {
      list.splice(existingUserIndex, 1);
    }
  };

  @action
  addUserToCurrentAssignees = () => {
    this.addUserToList(this.selectedSearchResult, this.currentLeadAssignees);
    this.addUserToList(this.selectedSearchResult, this.usersToAssign);
    this.removeUserFromList(this.selectedSearchResult, this.usersToUnassign);

    this.searchQuery = '';
    this.selectedSearchResult = null;
  };

  @action
  removeUserFromCurrentAssignees = (userToRemove) => {
    this.removeUserFromList(userToRemove, this.currentLeadAssignees);
    this.addUserToList(userToRemove, this.usersToUnassign);
    this.removeUserFromList(userToRemove, this.usersToAssign);
  };

  @computed
  get submitEnabled() {
    return !!(this.usersToUnassign.length || this.usersToAssign.length);
  }

  @action
  makeNextSearchResultActive = () => {
    if (this.activeSearchResultIndex === null || this.activeSearchResultIndex === this.searchResults.length - 1) {
      this.activeSearchResultIndex = 0;
    } else {
      this.activeSearchResultIndex++;
    }
  };

  @action
  makePrevSearchResultActive = () => {
    if (this.activeSearchResultIndex === null || this.activeSearchResultIndex === 0) {
      this.activeSearchResultIndex = this.searchResults.length - 1;
    } else {
      this.activeSearchResultIndex--;
    }
  };

  @action
  selectCurrentActiveResult = () => {
    this.selectResult(this.searchResults[this.activeSearchResultIndex]);
  };

  @action
  submitAssignedLeads = (customerId) => {
    return new Promise((resolve, reject) => {
      this.submitAssignedLeadsRequest.reset();
      this.submitAssignedLeadsRequest.onSuccess(({ newAssignees, customerId }) => {
        this.onSubmitAssignedLeadsSuccess(customerId);
        resolve(newAssignees);
      });
      this.submitAssignedLeadsRequest.onError(reject);
      this.submitAssignedLeadsRequest.call(
        api.submitAssignedLeads,
        customerId,
        this.currentLeadAssignees,
        this.usersToAssign,
        this.usersToUnassign
      );
    });
  };

  @action
  onSubmitAssignedLeadsSuccess = (customerId) => {
    const newAssignedLeads = [].concat(this.initialLeadAssignees);

    this.usersToAssign.forEach((user) => {
      this.addUserToList(user, newAssignedLeads);
    });

    this.usersToUnassign.forEach((user) => {
      this.removeUserFromList(user, newAssignedLeads);
    });

    this.appStore.customerListStore.updateCustomerAssignedLeads(customerId, newAssignedLeads);
  };

  @computed
  get leadIsNowAssigned() {
    return this.initialLeadAssignees.length === 0 && this.usersToAssign.length > 0;
  }

  @computed
  get leadIsNowUnassigned() {
    return this.initialLeadAssignees.length > 0 && this.currentLeadAssignees.length === 0;
  }

  @action
  resetResults = () => {
    this.searchResults = [];
  };
}

export default AssignLeadStore;
