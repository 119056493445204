import platformApiUtils from '@ivendi/platform-api-utils';

export const searchUsers = (dealershipId, query) => {
  let params = query ? { q: query, page: 1 } : undefined;
  return platformApiUtils.get(`v1/dealerships/${dealershipId}/findUsers`, params).then((response) => response.Results);
};

export const submitAssignedLeads = (customerId, currentLeadAssignees, usersToAssign, usersToUnassign) => {
  const postData = usersToAssign
    .map((user) => ({
      DealershipUserId: user.id,
      IsAssigned: true
    }))
    .concat(
      usersToUnassign.map((user) => ({
        DealershipUserId: user.id,
        IsAssigned: false
      }))
    );

  return platformApiUtils.post(`v1/customer/${customerId}/assign`, postData).then(() => ({
    customerId,
    newAssignees: currentLeadAssignees.map((assignee) => ({
      Id: assignee.id,
      CanAssign: assignee.canAssign,
      Email: assignee.email,
      Firstname: assignee.firstName,
      IsDealerAdmin: assignee.isDealerAdmin,
      LastActive: assignee.lastActive,
      Mobile: assignee.mobile,
      Surname: assignee.surname,
      Avatar: {
        Colour: assignee.avatarColor
      }
    }))
  }));

  //pretend the api returns the new assigned users to us
  //todo remove newAssignees from here,
  //it's only needed so that we can dispatch(updateDashboardAssignedTo()) in AssignLeadModalContainer
  //it can go after dashboard + customer page are using MobX
};
