import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CommentsListItem from './CommentsListItem';
import './commentsList.scss';

const CommentsList = ({ comments }) => {
  return (
    <div className="commentsList" key="container">
      {_.map(comments, (comment, index) => {
        return <CommentsListItem comment={comment} key={index} />;
      })}
    </div>
  );
};

CommentsList.propTypes = {
  comments: PropTypes.array
};

export default CommentsList;
