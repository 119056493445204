import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DateFormat from '../../Common/DateFormat';
import AssignedToText from '../../AssignLead/components/AssignedToText';
import { formatNumber, formatAddress, getOptionKey } from '../../../core/helpers';
import QuoteCommitmentMessage from '../../Quoting/components/QuoteCommitmentMessage';
import { hasCompanionQuotes } from 'features';
import { findCompanionQuoteByType } from '~/components/QuoteCard/utils/getQuoteDisplaySchema';
import { LoanTypeJs, QuoteSummaryVariantTypeJs } from '~/components/QuoteCard/types';
import QuotePanelV2 from '~/components/Reporting/PartialSummarySections/QuotePanelV2';
import './printedQuote.scss';

const PrintedQuote = (props) => {
  // Used for Price Position
  const vehicle = props.vehicle || {};
  const quote = props.quote || {};

  const { countryCode } = props.appStore.uiState;

  const rootQuote = props.quote;
  let vehicleLoan;
  let vapsLoan;
  let negativeEquityLoan;

  if (hasCompanionQuotes(rootQuote)) {
    vehicleLoan = findCompanionQuoteByType(props.quote.CompanionQuotes, LoanTypeJs.vehicleLoan);
    vapsLoan = findCompanionQuoteByType(props.quote.CompanionQuotes, LoanTypeJs.vapsLoan);
    negativeEquityLoan = findCompanionQuoteByType(props.quote.CompanionQuotes, LoanTypeJs.negativeEquityLoan);
  }

  return (
    <div style={styles.container}>
      <div style={styles.date}>
        <DateFormat value="" format="DD/MM/YYYY" />
      </div>
      <h2 style={styles.pageHeading}>{props.t('PrintedQuote.key_financial_information')}</h2>
      <AssignedToText assignees={props.assignees} />
      <h3 style={styles.heading}>{props.t('PrintedQuote.your_vehicle')}</h3>
      <table style={styles.vehicleTable}>
        <tbody>
          <tr>
            <td style={styles.vehicleName}>
              {vehicle.Make} {vehicle.Model} {vehicle.Derivative}
            </td>
          </tr>
          <tr>
            <td>{props.t('PrintedQuote.vehicle_type')}</td>
            <td style={styles.vehicleCondition}>{vehicle.Condition ? getOptionKey(vehicle.Condition).text : ''}</td>
          </tr>
          <tr>
            <td>{props.t('PrintedQuote.registration_number')}</td>
            <td style={styles.rightAlign}>{vehicle.SanitizedVrm || vehicle.Vrm}</td>
          </tr>
          <tr>
            <td>{props.t('PrintedQuote.date_of_registration')}</td>
            <td style={styles.rightAlign}>{vehicle.RegistrationDate}</td>
          </tr>
          <tr>
            <td>{props.t('PrintedQuote.current_mileage')}</td>
            <td style={styles.rightAlign}>{formatNumber(vehicle.Mileage, 0, countryCode)}</td>
          </tr>
        </tbody>
      </table>

      <div className="printSummary">
        {/* Standard or Aggregate Quote Start */}
        <h3 style={styles.heading}>{props.t('PrintedQuote.your_key_financial_information')}</h3>
        <QuotePanelV2
          singleColumn
          quote={rootQuote}
          loanType={LoanTypeJs.standardLoan}
          variant={QuoteSummaryVariantTypeJs.ProductComparePrintSummary}
        />
        {/* Standard or Aggregate Quote END */}
      </div>

      {/* Companion Quotes START */}
      {vehicleLoan && (vapsLoan || negativeEquityLoan) && (
        <div className="printSummary">
          <h2 style={styles.subHeading}>
            {props.t('PrintedQuote.this_quote_is_made_up_of_multiple_finance_components')}
          </h2>
          <h3 style={styles.heading}>{props.t('PrintedQuote.vehicle_loan')}</h3>
          <QuotePanelV2
            singleColumn
            quote={vehicleLoan}
            loanType={LoanTypeJs.vehicleLoan}
            variant={QuoteSummaryVariantTypeJs.ProductComparePrintSummary}
            hasNegativeEquityLoan={negativeEquityLoan !== undefined}
          />
        </div>
      )}
      {negativeEquityLoan && (
        <div className="printSummary">
          <h3 style={styles.heading}>{props.t('PrintedQuote.negative_equity_loan')}</h3>
          <QuotePanelV2
            singleColumn
            quote={negativeEquityLoan}
            loanType={LoanTypeJs.negativeEquityLoan}
            variant={QuoteSummaryVariantTypeJs.ProductComparePrintSummary}
          />
        </div>
      )}
      {vapsLoan && (
        <div className="printSummary">
          <h3 style={styles.heading}>{props.t('PrintedQuote.value_added_products_loan')}</h3>
          <QuotePanelV2
            singleColumn
            quote={vapsLoan}
            loanType={LoanTypeJs.vapsLoan}
            variant={QuoteSummaryVariantTypeJs.ProductComparePrintSummary}
          />
        </div>
      )}
      {/* Companion Quotes END */}

      {/* Legal small text START */}
      <div style={styles.smallTextContainer}>
        <p style={styles.paragraph}>{props.t('PrintedQuote.fees_disclaimer')}</p>

        {quote.FinanceType === 'PCP' && quote.FunderCode === 'MOT' && (
          <p style={styles.paragraph}>{props.t('PrintedQuote.mot_pcp_disclaimer')}</p>
        )}

        <p style={styles.paragraph}>{props.t('PrintedQuote.indemnity_disclaimer')}</p>
        <p style={styles.paragraph}>
          {props.t('PrintedQuote.assumptions_disclaimer')}
          <QuoteCommitmentMessage />
        </p>
        <p style={styles.paragraph}>{props.t('PrintedQuote.subject_to_status_disclaimer')}</p>
        <p style={styles.paragraph}>
          <b>
            {props.t('PrintedQuote.reference')} {quote.QuoteeProductCode}
          </b>
        </p>
        <p style={styles.paragraph} key="par1">
          {props.quote && props.quote.Funder && (
            <span>
              {props.t('PrintedQuote.finance_is_provided_by')} {formatAddress(props.quote.Funder)}.{' '}
            </span>
          )}
        </p>
      </div>
      {/* Legal small text END */}
    </div>
  );
};

PrintedQuote.defaultProps = {
  vehicle: {},
  quote: {}
};
PrintedQuote.propTypes = {
  vehicle: PropTypes.object,
  quote: PropTypes.object,
  assignees: PropTypes.array,
  appStore: PropTypes.object
};
const styles = {
  heading: {
    fontSize: '16px',
    borderBottom: '1px solid black',
    padding: '10px 0',
    textAlign: 'center',
    margin: '5px 0 10px 0'
  },
  subHeading: {
    fontSize: '14px',
    paddingTop: '30px',
    paddingBottom: '20px',
    fontWeight: 'bold'
  },
  date: {
    fontSize: '1.2rem',
    textAlign: 'right'
  },
  pageHeading: {
    textAlign: 'center'
  },
  rightAlign: {
    textAlign: 'right'
  },
  vehicleName: {
    textAlign: 'left'
  },
  container: {
    fontSize: '13px',
    width: '100%',
    margin: '0 auto'
  },
  smallTextContainer: {
    padding: '10px 20px 0 20px '
  },
  paragraph: {
    fontSize: '10px'
  },
  vehicleTable: {
    width: '100%',
    fontSize: '13px',
    margin: '0 auto',
    padding: '0 20px'
  },
  quoteTable: {
    width: '100%',
    fontSize: '13px',
    margin: '0 auto',
    padding: '0 20px'
  },
  capital: {
    textTransform: 'capitalize'
  },
  footerText: {
    fontSize: '13px',
    clear: 'both'
  },
  vehicleCondition: {
    textTransform: 'capitalize',
    textAlign: 'right'
  }
};
export default withTranslation('Application')(PrintedQuote);
