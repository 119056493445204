import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import QuoteItem from '../../Common/QuoteItem';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import QuoteFigureItem from './QuoteFigureItem';
import MoneyFormat from '../../Common/MoneyFormat';
import Button from '../../Common/Button/Button';
import QuoteStepsItem from './QuoteStepsItem';
import Divider from '../../Common/Divider';
import { inject, observer } from 'mobx-react';
import InlineExclamationWarning from '../../Common/InlineExclamationWarning';
import { replaceNumberInStringWithMoneyValue } from '../../../core/helpers';
import FormatAPR, { FormatNumToDecimals } from './FormatAPR';
import './loansBreakdownView.scss';

@inject('appStore')
@observer
class LoansBreakdownView extends React.Component {
  proceedToApplication = () => {
    this.props.proceedToApplication(this.props.quote);
  };

  render() {
    let vehicleLoan = this.props.quote.VehicleLoan;
    let vapsLoan = this.props.quote.PersonalLoan;
    let negEqLoan = this.props.quote.NegativeEquityLoan;
    let numLoans = vapsLoan && negEqLoan ? 3 : 2;
    const stringNumberLoans = numLoans === 3 ? 'three' : 'two';

    return (
      <Panel>
        <PanelHeader>{this.props.t('LoansBreakdownView.combined_quote_breakdown')}</PanelHeader>
        <PanelContent>
          <div className="loansBreakdownView__header">
            {this.props.t('LoansBreakdownView.this_quote_is_made_up_of_count_loans', {
              count: numLoans
            })}
          </div>

          <div>
            <QuoteItem quote={this.props.quote} hasLoans />
          </div>

          <div className="loansBreakdownView__figuresContainer">
            <div className="loansBreakdownView__figuresTable">
              <div className="loansBreakdownView__figuresHeader">{this.props.t('LoansBreakdownView.vehicle_loan')}</div>
              {vehicleLoan.ConsumerWarnings && vehicleLoan.ConsumerWarnings.PublicWarnings && (
                <div className="loansBreakdownView__figuresWarning">
                  {vehicleLoan.ConsumerWarnings.PublicWarnings.map((warning, index) => (
                    <InlineExclamationWarning
                      title={this.props.t('LoansBreakdownView.please_note')}
                      key={`warn${index}`}
                    >
                      {warning.Number === 506 ? replaceNumberInStringWithMoneyValue(warning.Message) : warning.Message}
                    </InlineExclamationWarning>
                  ))}
                </div>
              )}
              <div className="loansBreakdownView__figuresContents">
                <div className="loansBreakdownView__figuresContents--left">
                  <QuoteFigureItem>
                    <>{this.props.t('LoansBreakdownView.product')}</>
                    <>{vehicleLoan.ProductName}</>
                  </QuoteFigureItem>
                  <QuoteFigureItem>
                    <>{this.props.t('LoansBreakdownView.vehicle_price')}</>
                    <MoneyFormat value={vehicleLoan.VehiclePrice} />
                  </QuoteFigureItem>
                  <QuoteFigureItem>
                    <>{this.props.t('LoansBreakdownView.total_deposit')}</>
                    <MoneyFormat value={vehicleLoan.Deposit} />
                  </QuoteFigureItem>
                  <QuoteFigureItem>
                    <>{this.props.t('LoansBreakdownView.total_amount_of_credit')}</>
                    <MoneyFormat value={vehicleLoan.BalancePayable} />
                  </QuoteFigureItem>
                  <QuoteFigureItem>
                    <>{this.props.t('LoansBreakdownView.total_amount_payable')}</>
                    <MoneyFormat value={vehicleLoan.TotalAmountPayable} />
                  </QuoteFigureItem>
                  <QuoteFigureItem>
                    <>{this.props.t('LoansBreakdownView.apr')}</>
                    <FormatAPR value={vehicleLoan.RepresentativeApr} />
                  </QuoteFigureItem>
                </div>
                <div className="loansBreakdownView__figuresContents--right">
                  {vehicleLoan.PaymentSchedule.map((schedule, index) => (
                    <QuoteFigureItem key={`vehpayment${index}`}>
                      <>{schedule.Label}</>
                      <>
                        {schedule.Prefix} <MoneyFormat value={schedule.Amount} />
                      </>
                    </QuoteFigureItem>
                  ))}
                  <QuoteFigureItem>
                    <>{this.props.t('LoansBreakdownView.interest_charges')}</>
                    <MoneyFormat value={vehicleLoan.InterestCharges} />
                  </QuoteFigureItem>
                  <QuoteFigureItem>
                    <>{this.props.t('LoansBreakdownView.annual_fixed_interest_rate')}</>
                    <FormatNumToDecimals value={vehicleLoan.AnnualRate} decimal={2} appendChar="%" />
                  </QuoteFigureItem>
                  <QuoteFigureItem>
                    <>{this.props.t('LoansBreakdownView.option_to_purchase_fee')}</>
                    <>
                      <MoneyFormat value={vehicleLoan.OptionToPurchaseFee} />
                    </>
                  </QuoteFigureItem>
                </div>
              </div>
            </div>

            {vapsLoan && (
              <div className="loansBreakdownView__figuresTable">
                <div className="loansBreakdownView__figuresHeader">
                  {this.props.t('LoansBreakdownView.value_added_products_loan')}
                </div>
                {vapsLoan.ConsumerWarnings && vapsLoan.ConsumerWarnings.PublicWarnings && (
                  <div className="loansBreakdownView__figuresWarning">
                    {vapsLoan.ConsumerWarnings.PublicWarnings.map((warning, index) => (
                      <InlineExclamationWarning
                        title={this.props.t('LoansBreakdownView.please_note')}
                        key={`warn${index}`}
                      >
                        {warning.Number === 506
                          ? replaceNumberInStringWithMoneyValue(warning.Message)
                          : warning.Message}
                      </InlineExclamationWarning>
                    ))}
                  </div>
                )}
                <div className="loansBreakdownView__figuresContents">
                  <div className="loansBreakdownView__figuresContents--left">
                    {vapsLoan.ValueAddedProducts.map((vap, index) => (
                      <QuoteFigureItem key={`${vap.ProductTypeText}${index}`}>
                        <>{vap.ProductTypeText}</>
                        <MoneyFormat value={vap.Price} />
                      </QuoteFigureItem>
                    ))}
                    <QuoteFigureItem>
                      <>{this.props.t('LoansBreakdownView.total_amount_of_credit')}</>
                      <MoneyFormat value={vapsLoan.BalancePayable} />
                    </QuoteFigureItem>
                    <QuoteFigureItem>
                      <>{this.props.t('LoansBreakdownView.total_amount_payable')}</>
                      <MoneyFormat value={vapsLoan.TotalAmountPayable} />
                    </QuoteFigureItem>
                    <QuoteFigureItem>
                      <>{this.props.t('LoansBreakdownView.apr')}</>
                      <FormatAPR value={vapsLoan.RepresentativeApr} />
                    </QuoteFigureItem>
                  </div>
                  <div className="loansBreakdownView__figuresContents--right">
                    {vapsLoan.PaymentSchedule.map((schedule, index) => (
                      <QuoteFigureItem key={`vappayment${index}`}>
                        <>{schedule.Label}</>
                        <>
                          {schedule.Prefix} <MoneyFormat value={schedule.Amount} />
                        </>
                      </QuoteFigureItem>
                    ))}
                    <QuoteFigureItem>
                      <>{this.props.t('LoansBreakdownView.interest_charges')}</>
                      <MoneyFormat value={vapsLoan.InterestCharges} />
                    </QuoteFigureItem>
                    <QuoteFigureItem>
                      <>{this.props.t('LoansBreakdownView.annual_fixed_interest_rate')}</>
                      <FormatNumToDecimals value={vapsLoan.AnnualRate} decimal={2} appendChar="%" />
                    </QuoteFigureItem>
                  </div>
                </div>
              </div>
            )}

            {negEqLoan && (
              <div className="loansBreakdownView__figuresTable">
                <div className="loansBreakdownView__figuresHeader">
                  {this.props.t('LoansBreakdownView.negative_equity_loan')}
                </div>
                {negEqLoan.ConsumerWarnings && negEqLoan.ConsumerWarnings.PublicWarnings && (
                  <div className="loansBreakdownView__figuresWarning">
                    {negEqLoan.ConsumerWarnings.PublicWarnings.map((warning, index) => (
                      <InlineExclamationWarning
                        title={this.props.t('LoansBreakdownView.please_note')}
                        key={`warn${index}`}
                      >
                        {warning.Number === 506
                          ? replaceNumberInStringWithMoneyValue(warning.Message)
                          : warning.Message}
                      </InlineExclamationWarning>
                    ))}
                  </div>
                )}
                <div className="loansBreakdownView__figuresContents">
                  <div className="loansBreakdownView__figuresContents--left">
                    <QuoteFigureItem>
                      <>{this.props.t('LoansBreakdownView.negative_equity')}</>
                      <MoneyFormat value={negEqLoan.NegativeEquity} />
                    </QuoteFigureItem>
                    <QuoteFigureItem>
                      <>{this.props.t('LoansBreakdownView.total_amount_of_credit')}</>
                      <MoneyFormat value={negEqLoan.BalancePayable} />
                    </QuoteFigureItem>
                    <QuoteFigureItem>
                      <>{this.props.t('LoansBreakdownView.total_amount_payable')}</>
                      <MoneyFormat value={negEqLoan.TotalAmountPayable} />
                    </QuoteFigureItem>
                    <QuoteFigureItem>
                      <>{this.props.t('LoansBreakdownView.apr')}</>
                      <FormatAPR value={negEqLoan.RepresentativeApr} />
                    </QuoteFigureItem>
                  </div>
                  <div className="loansBreakdownView__figuresContents--right">
                    {negEqLoan.PaymentSchedule.map((schedule, index) => (
                      <QuoteFigureItem key={`vappayment${index}`}>
                        <>{schedule.Label}</>
                        <>
                          {schedule.Prefix} <MoneyFormat value={schedule.Amount} />
                        </>
                      </QuoteFigureItem>
                    ))}
                    <QuoteFigureItem>
                      <>{this.props.t('LoansBreakdownView.interest_charges')}</>
                      <MoneyFormat value={negEqLoan.InterestCharges} />
                    </QuoteFigureItem>
                    <QuoteFigureItem>
                      <>{this.props.t('LoansBreakdownView.annual_fixed_interest_rate')}</>
                      <FormatNumToDecimals value={negEqLoan.AnnualRate} decimal={2} appendChar="%" />
                    </QuoteFigureItem>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="loansBreakdownView__header">
            {this.props.t('LoansBreakdownView.this_deal_requires_the_submission_of_string_number_loans_applications', {
              stringNumberLoans
            })}
          </div>
          <Divider />
          <div className="loanBreakdownView__quoteSteps">
            <div className="loanBreakdownView__applicationSteps">
              {this.props.t('LoansBreakdownView.1_the_vehicle_loan')}
            </div>
            {vapsLoan && (
              <div className="loanBreakdownView__applicationSteps">
                {' '}
                {this.props.t(
                  'LoansBreakdownView.2_a_personal_loan_to_finance_value_added_products_debtor_creditor_supplier_loan'
                )}
              </div>
            )}
            {negEqLoan && (
              <div className="loanBreakdownView__applicationSteps">
                {this.props.t(
                  'LoansBreakdownView.num_loans_a_personal_loan_to_cover_negative_equity_debtor_creditor_loan',
                  {
                    numLoans
                  }
                )}
              </div>
            )}
          </div>

          <div className="loansBreakdownView__quoteSteps">
            <QuoteStepsItem title={this.props.t('LoansBreakdownView.step_1')}>
              {this.props.t('LoansBreakdownView.select_proceed_to_create_the_count_applications_for_this_customer', {
                count: stringNumberLoans
              })}
            </QuoteStepsItem>
            <QuoteStepsItem title={this.props.t('LoansBreakdownView.step_2')}>
              {this.props.t(
                'LoansBreakdownView.complete_the_application_form_for_the_vehicle_loan_and_submit_to_creation_you_will_get_a_decision_on_the_vehicle_loan_which_you_can_monitor_from_the_lender_updates_screen'
              )}
            </QuoteStepsItem>
            <QuoteStepsItem title={this.props.t('LoansBreakdownView.step_3')}>
              {this.props.t(
                'LoansBreakdownView.once_youve_submitted_the_application_for_the_vehicle_loan_and_creation_have_returned_an_agreement_number_youll_be_able_to_submit_the_next_personal_loan_s_dont_worry_you_wont_need_to_rekey_the_next_application_as_all_of_the_customers_information_will_already_be_filled_in'
              )}
              <br />
              <br />
              {this.props.t(
                'LoansBreakdownView.each_application_contains_a_product_explanation_on_the_summary_tab_so_please_ensure_the_customer_has_read_the_information_and_understands_each_finance_product_prior_to_submission'
              )}
              <br />
              <br />
              {this.props.t(
                'LoansBreakdownView.you_will_get_an_individual_decision_for_each_application_you_submit_and_you_can_monitor_all_of_the_application_statuses_via_the_lender_updates_screen'
              )}
            </QuoteStepsItem>
          </div>

          <div className="loansBreakdownView__buttonsContainer">
            <div className="loansBreakdownView__button">
              <Button buttonStyle="cancel" type="button" onClick={this.props.onCancel} id="loanBreakdownCancel">
                {this.props.t('LoansBreakdownView.go_back')}
              </Button>
            </div>
            {this.props.appStore.uiState.canCreateApplications && (
              <div className="loansBreakdownView__button">
                <Button
                  isLoading={this.props.loadingState === 'loading' || this.props.applicationCreationLoading}
                  hasError={this.props.loadingState === 'error'}
                  onClick={this.proceedToApplication}
                  id="loanBreakdownSubmit"
                >
                  {this.props.t('LoansBreakdownView.proceed')}
                </Button>
              </div>
            )}
          </div>
        </PanelContent>
      </Panel>
    );
  }
}

LoansBreakdownView.propTypes = {
  quote: PropTypes.object,
  onCancel: PropTypes.func,
  loadingState: PropTypes.string,
  proceedToApplication: PropTypes.func,
  applicationCreationLoading: PropTypes.bool
};
export default withTranslation('Quoting')(LoansBreakdownView);
