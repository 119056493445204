import React from 'react';
import PropTypes from 'prop-types';
import './tabStyle.scss';
import classnames from 'classnames';

const TabStyle = (props) => {
  let { children, onClick, tabStyle } = props;

  let tabClasses = classnames(`${tabStyle}__tab`, props.isActive && `${tabStyle}__tabActive`);

  let topBarClasses = classnames(`${tabStyle}__topBar`, props.isActive && `${tabStyle}__topBarActive`);

  return (
    <div className={`${tabStyle}__container`} onClick={onClick}>
      <div className={`${tabStyle}__inner`}>
        <div className={tabClasses}>{children}</div>
        <div className={topBarClasses} />
        {props.isActive && props.tabStyle === 'largeCfc' && <div className="largeCfc__triangle" />}
        {!props.isActive && <div className={`${tabStyle}`} />}
      </div>
    </div>
  );
};

TabStyle.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  tabStyle: PropTypes.string
};

TabStyle.defaultProps = {
  tabStyle: 'default'
};

export default TabStyle;
