import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './smallVrm.scss';

const SmallVrm = ({ vrm }) => {
  const { t } = useTranslation('Common');
  return (
    <div className="smallVrm">
      <div className="smallVrm__regBlue" />
      <div className="smallVrm__vrm">{vrm ? vrm : t('SmallVrm.no_reg')}</div>
    </div>
  );
};

SmallVrm.propTypes = {
  vrm: PropTypes.string
};
export default SmallVrm;
