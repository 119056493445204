import platformApiUtils from '@ivendi/platform-api-utils';
import dealershipEventEmitter from './dealershipEventEmitter';
import uuid from 'uuid';
import { getSessionId } from '../sessionId';

let sessionId = getSessionId();

function handleCaughtError(error, correlationId) {
  window.R7Insight &&
    window.R7Insight.log({
      type: 'AwaitingMessageError',
      error: error.message,
      cid: correlationId,
      sessionId,
      level: 'Error'
    });
}

export default function PubNubPost(path, data, header) {
  let timer;

  return new Promise(function(resolve, reject) {
    const correlationId = uuid.v4();

    const onError = (error) => {
      try {
        clearTimeout(timer);
        dealershipEventEmitter.off(header, onMessage);
        reject(error);
      } catch (error) {
        handleCaughtError(error, correlationId);
      }
    };

    const onMessage = (message) => {
      try {
        if (message.CorrelationId === correlationId) {
          clearTimeout(timer);
          dealershipEventEmitter.off(header, onMessage);
          resolve(message.Payload);
        }
      } catch (error) {
        handleCaughtError(error, correlationId);
      }
    };

    const onTimeout = () => {
      clearTimeout(timer);
      dealershipEventEmitter.off(header, onMessage);
      window.R7Insight &&
        window.R7Insight.log({
          type: 'AwaitingMessageError',
          error: 'TimedOut',
          cid: correlationId,
          sessionId,
          level: 'Error'
        });
      reject('TimedOut');
    };

    try {
      dealershipEventEmitter.on(header, onMessage);
      platformApiUtils.post(path, data, { correlationId }).catch(onError);
      timer = setTimeout(onTimeout, 40000);
    } catch (error) {
      handleCaughtError(error, correlationId);
    }
  });
}
