import React, { memo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const DragItem = memo(({ id, onMoveItem, children, className, gallery }) => {
  const ref = useRef(null);

  const [{ isDragging }, connectDrag] = useDrag({
    // only allowed to drag if gallery matches
    type: gallery,
    item: { id, gallery },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging()
      };
    }
  });

  const [, connectDrop] = useDrop({
    // only allowed to drag if gallery matches
    accept: gallery,
    hover(hoveredOverItem) {
      if (hoveredOverItem.id !== id) {
        onMoveItem(hoveredOverItem.id, id);
      }
    }
  });

  connectDrag(ref);
  connectDrop(ref);

  const transform = isDragging ? 'scale(.7)' : 'scale(1)';
  const containerStyle = { transform };

  // wrap draggable item in a non-draggable stylable container
  return React.Children.map(children, (child) => {
    return (
      <div className={className}>
        {React.cloneElement(child, {
          ref: ref,
          style: containerStyle
        })}
      </div>
    );
  });
});

export default DragItem;
