import { inject } from 'mobx-react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { isMobile, isTablet } from 'react-device-detect';

import { StockImageUpload, StockVideoUpload } from './components';
import { useEffect } from 'react';

const StockMediaUploadContainer = (props) => {
  const dealerPlatformUpload = props.appStore?.stockEditStore?.vehicleData?.dealerPlatformUpload;
  const backend = isMobile || isTablet ? TouchBackend : HTML5Backend;

  useEffect(() => {
    return () => {
      // https://github.com/react-dnd/react-dnd/issues/3178
      /**
        "react-dnd": "^16.0.1",
        "react-dnd-html5-backend": "^14.0.2",
        "react-dnd-touch-backend": "^14.1.1",
        "@uppy/drop-target": "^1.1.3"
       */
      if (window.__isReactDndBackendSetUp) {
        window.__isReactDndBackendSetUp = false;
      }
    };
  }, []);

  return (
    <>
      {dealerPlatformUpload && (
        <DndProvider backend={backend}>
          <StockImageUpload {...props} />
        </DndProvider>
      )}
      <StockVideoUpload />
    </>
  );
};

export default inject('appStore')(StockMediaUploadContainer);
