import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useQueryParams from '~/hooks/useQueryParams';

import Alert from '../../Common/Alert';
import Button from '../../Common/Button/Button';
import AlertCard from 'components/Common/AlertCard/AlertCard';
import './login.scss';

type Session = {
  hasSsoLoginError?: boolean;
  hasIncorrectLogin?: boolean;
  loadingState: 'idle' | 'loading' | 'error';
  errorMessage?: string;
};
export type LoginPageProps = {
  session: Session;
  // TODO: take type declarations from import { signIn } from '~/store/session/actions';
  // once it's converted to typescript
  signIn: (username: string, password: string, returnPath: string) => void;
};
const LoginPage = (props: LoginPageProps) => {
  const { t } = useTranslation('Login');
  const [query] = useQueryParams();
  const [username, setUsername] = useState(query.username || '');
  const [password, setPassword] = useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.signIn(username, password, query.returnpath);
  };

  return (
    <div className="login">
      <div className="login__inner">
        <form onSubmit={handleSubmit} className="login__form" data-th="login-form">
          <h1 className="login__title" key="title">
            {t('welcomeText')}
          </h1>

          <div className="login__loginErrors">
            {props.session.hasSsoLoginError && (
              <Alert>
                Please login via the{' '}
                <a className="login__alertLink" href="https://www.motonovofinance.com/login">
                  MotoNovo Dealer Hub
                </a>
              </Alert>
            )}
            {!props.session.hasSsoLoginError && props.session.hasIncorrectLogin && (
              <Alert>{t('incorrectDetails')}</Alert>
            )}
            {!props.session.hasSsoLoginError &&
              !props.session.hasIncorrectLogin &&
              props.session.loadingState === 'error' && <Alert>{t('errorMessage')}</Alert>}
            {!props.session.hasSsoLoginError && !props.session.hasIncorrectLogin && props.session.errorMessage && (
              <Alert>{props.session.errorMessage}</Alert>
            )}
            {query.passwordreset === 'true' && (
              <AlertCard
                iconName="information"
                paragraph={t('to_login_please_check_your_email_for_password_reset_link')}
              />
            )}
          </div>

          <div className="login__inputContainer" key="ic1">
            <input
              className="login__input"
              key="input2"
              type="email"
              placeholder={t('emailPlaceholder')}
              data-th="username-input"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              id="email"
              maxLength={100}
            />
          </div>

          <div className="login__inputContainer" key="ic2">
            <input
              className="login__input"
              key="input2"
              type="password"
              data-th="password-input"
              placeholder={t('passwordPlaceholder')}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              id="password"
              maxLength={100}
            />
          </div>

          <div className="login__actions">
            <Button
              type="submit"
              buttonStyle="primary"
              isLoading={props.session.loadingState === 'loading'}
              hasError={props.session.loadingState === 'error'}
              id="SignIn"
            >
              {t('signIn')}
            </Button>
          </div>

          <Link to="/auth/forgotpassword" className="login__forgotPasswordContainer" id="ForgotPassword">
            <h1 className="login__forgotPassword"> {t('forgotPassword')}</h1>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
