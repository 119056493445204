import React from 'react';
import PropTypes from 'prop-types';
import './helpVideoMenuSection.scss';

const HelpVideoMenuSection = ({ title, children }) => (
  <div className="helpVideoMenuSection" key="container">
    <div className="helpVideoMenuSection__title" key="title">
      {title}
    </div>
    <div className="helpVideoMenuSection__vids" key="vids">
      {children}
    </div>
  </div>
);

HelpVideoMenuSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

export default HelpVideoMenuSection;
