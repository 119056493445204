import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import SearchBar from '../Search/SearchBar';
import BroadcastStockListFilter from '../BroadcastStockListFilter';
import { BroadcastStockList } from '../../modules/Stock/routes/stock/routes/list/components/StockList';
import Pagination from '../Common/Pagination';
import BroadcastStockListDataAccuracyModal from '../BroadcastStockListDataAccuracyModal';
import Modal from '../Common/Modal/Modal';
import invert from 'lodash/invert';

import './broadcastStockListContainer.scss';
import Button from '../Common/Button/Button';
import { compose } from 'redux';
import { withRouter } from '../../hocs/router';

const labelMap = {
  1: 'good',
  2: 'potential-issues',
  3: 'severe-issues',
  4: 'no-taxonomy'
};

@inject('appStore')
@observer
class BroadcastStockListContainer extends Component {
  state = {
    dataAccuracyModalIsOpen: false
  };

  componentDidMount() {
    if (!localStorage.getItem('updatedVehicleIds')) {
      localStorage.setItem('updatedVehicleIds', JSON.stringify([]));
    }

    let { stockStore } = this.props.appStore;
    stockStore.auth(this.props.params.dealershipId);

    if (this.props.query) {
      this.props.query.q && this.handleSearch('stockSearch', this.props.query.q);

      if (this.props.query.accuracyScores && this.props.query.accuracyScores.length > 0) {
        const accuracyScores =
          this.props.query.accuracyScores !== 'all'
            ? this.props.query.accuracyScores.split(',').map((score) => Number(invert(labelMap)[score]))
            : [1, 2, 3, 4];
        accuracyScores.forEach((score) => {
          this.handleToggleFilter('accuracyScore', score);
        });

        if (this.props.query.page) {
          if (Number(this.props.query.page) > 0) {
            stockStore.handlePageChange(this.props.query.page);
          }
        }
      }
    }
  }

  updateQuery = (query) => {
    this.props.navigate({
      pathname: this.props.location.pathname,
      query: { ...this.props.query, ...query }
    });
  };

  handleSearch = (id, query) => {
    this.props.appStore.stockStore.updateSearchQuery(id, query);
    this.updateQuery({
      q: query
    });
  };

  handleToggleFilter = (filter, value) => {
    this.props.appStore.stockStore.toggleFilter(filter, value);
    const accuracyScores =
      this.props.appStore.stockStore.facets.accuracyScore.length === 4
        ? 'all'
        : [this.props.appStore.stockStore.facets.accuracyScore.map((score) => labelMap[score])];
    const query = {
      accuracyScores
    };
    this.updateQuery(query);
  };

  handleSuggestionSelection = (value) => {
    let { stockStore } = this.props.appStore;
    stockStore.handleSuggestionSelection(value);
  };

  handlePageChange = (page) => {
    let { stockStore } = this.props.appStore;
    stockStore.handlePageChange(page);
    this.updateQuery({
      page
    });
  };

  openDataAccuracyModal = () => {
    this.setState({
      dataAccuracyModalIsOpen: true
    });
  };
  closeDataAccuracyModal = () => {
    this.setState({
      dataAccuracyModalIsOpen: false
    });
  };

  renderPaginationSummary() {
    let { stockStore } = this.props.appStore;

    if (!stockStore.vehicles.length) {
      return;
    }

    let total = stockStore.totalVehicles > 999 ? '1000+' : stockStore.totalVehicles;
    return (
      <div className="broadcastStockListContainer__paginationSummary">
        {this.props.t('StockListContainer.showing')} {total}{' '}
        {this.props.t('StockListContainer.vehicles_in_your_dealership')}
      </div>
    );
  }

  render() {
    let { t, appStore, selectAll, deselectAll, selectedVehicles, onSelectVehicle } = this.props;
    const { stockStore } = appStore;

    return (
      <>
        <Modal isOpen={this.state.dataAccuracyModalIsOpen} onClose={this.closeDataAccuracyModal}>
          <BroadcastStockListDataAccuracyModal />
        </Modal>
        <div className="broadcastStockListContainer">
          <SearchBar
            id="stockSearch"
            onChange={this.handleSearch}
            value={stockStore.searchQuery}
            placeholder={t('StockListContainer.search_vrm_brand_model_etc')}
          />
          <div className="broadcastStockListContainer__filter">
            <BroadcastStockListFilter
              stockStore={stockStore}
              toggleFilter={this.handleToggleFilter}
              openDataAccuracyModal={this.openDataAccuracyModal}
            />
          </div>
          <div className="broadcastStockListContainer__container">
            <div className="broadcastStockListContainer__preResultInfo">
                <Button
                  type="button"
                  buttonStyle="primary"
                  onClick={async () => {
                    selectedVehicles.length > 0 ? deselectAll() : selectAll(false, await stockStore.getVehicleIds());
                  }}
                >
                  {selectedVehicles.length > 0
                    ? t('StockListContainer.deselect_all')
                    : t('StockListContainer.select_all')}
                </Button>
              {this.renderPaginationSummary()}
            </div>
            <hr />
            <div className="broadcastStockListContainer__results">
              <div className="broadcastStockListContainer__searchSuggestions">
                {stockStore.suggestions.length > 0 && stockStore.searchQuery.length > 0 && (
                  <div className="broadcastStockListContainer__suggestionsContainer">
                    {stockStore.suggestions.map((query, i) => {
                      return (
                        <div
                          onClick={() => this.handleSuggestionSelection(query)}
                          key={i}
                          className="broadcastStockListContainer__listItem"
                        >
                          {query}
                        </div>
                      );
                    })}{' '}
                  </div>
                )}
              </div>
              <BroadcastStockList
                vehicles={stockStore.vehicles}
                isLoadingStock={stockStore.isLoadingStock}
                handleNewDataPending={stockStore.handleNewDataPending}
                openModal={this.openDataAccuracyModal}
                onSelectVehicle={onSelectVehicle}
                selectedVehicles={selectedVehicles}
              />
            </div>
          </div>
        </div>
        {stockStore.totalVehicles > 0 && (
          <Pagination
            currentPage={stockStore.currentPage + 1}
            totalItems={stockStore.totalVehicles}
            pageSize={stockStore.vehiclesPerPage}
            onChange={this.handlePageChange}
          />
        )}
      </>
    );
  }
}

export default compose(withRouter, withTranslation('Stock'))(BroadcastStockListContainer);
