import React from 'react';
import PropTypes from 'prop-types';
import { isDefined, formatMoney } from '../../core/helpers';
import { connect } from 'react-redux';

import './moneyFormat.scss';
import { CountryCodes } from '../../constants';

const MoneyFormat = ({ value, dealershipCountryCode, countryCode = null }) => {
  if (!isDefined(value)) {
    return <span className="moneyFormat"></span>;
  }

  return <span className="moneyFormat">{formatMoney(value, countryCode || dealershipCountryCode || 'gb')}</span>;
};

MoneyFormat.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  numberComma: PropTypes.bool,
  noDecimalFigure: PropTypes.bool,
  dealershipCountryCode: PropTypes.oneOf([...CountryCodes, '']),
  countryCode: PropTypes.oneOf([...CountryCodes, ''])
};

function mapStateToProps(state) {
  return {
    dealershipCountryCode: state.dealership.CountryCode
  };
}

export default connect(mapStateToProps)(MoneyFormat);
