import React from 'react';
import Input from './Input';
import { formatPhoneNumber } from '~/utils/formatUtils';

export interface PhoneNumberInputProps {
  isValid: boolean;
  isInvalid: boolean;
  id: string;
  disabled: boolean;
  value: string;
  onBlur: (e: any) => void;
  onChange: (value: string) => void;
  showError: boolean;
  triggerError: () => void;
  size: string;
}

export const PhoneNumberInput = (props: PhoneNumberInputProps) => {
  const { disabled, isInvalid, isValid, id, value, onBlur, onChange, showError, size, triggerError } = props;
  return (
    <Input
      id={id}
      type="tel"
      disabled={disabled}
      isValid={isValid}
      isInvalid={isInvalid}
      value={value}
      size={size}
      showError={showError}
      triggerError={triggerError}
      onChange={(e: any) => onChange && onChange(e.currentTarget.value)}
      onBlur={(e: any) => {
        onBlur && onBlur(e);
        onChange && onChange(formatPhoneNumber(e.target.value));
      }}
    />
  );
};
