import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './th.scss';

const Th = ({ notBold, children, arrowDirection, onClick, isCentred }) => {
  const classNames = classnames(
    'th',
    onClick && 'th--interactive',
    notBold && 'th--notBold',
    isCentred && 'th--isCentred'
  );

  return (
    <th className={classNames} onClick={onClick}>
      {children}
      {arrowDirection === 'asc' ? <span>↑</span> : ''}
      {arrowDirection === 'desc' ? <span>↓</span> : ''}
    </th>
  );
};

Th.propTypes = {
  onClick: PropTypes.func,
  arrowDirection: PropTypes.string,
  children: PropTypes.any,
  notBold: PropTypes.bool
};

export default Th;
