import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './manageUsersTable.scss';
import _ from 'lodash';
import ManageUsersTableItem from './ManageUsersTableItem';
import Th from '../../Common/Th';

class ManageUsersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: 'Firstname',
      sortDirection: 'asc'
    };
  }

  getArrowDirection(fieldName) {
    return this.state.sortBy === fieldName ? this.state.sortDirection : '';
  }

  sortBy(sortBy, defaultSortDirection) {
    return () => {
      let sortDirection = defaultSortDirection || 'asc';

      if (sortBy === this.state.sortBy) {
        sortDirection = this.state.sortDirection === 'asc' ? 'desc' : 'asc';
      }

      this.setState({
        sortBy,
        sortDirection
      });
    };
  }

  render() {
    let users = _.orderBy(this.props.users, this.state.sortBy);

    if (this.state.sortDirection === 'desc') {
      users && users.reverse();
    }

    return (
      <div className="manageUsersTable">
        <table className="manageUsersTable__table">
          <thead className="manageUsersTable__tableHeader" key="thead">
            <tr className="manageUsersTable__headerRow" ref="headerRow" key="headerrow">
              <Th onClick={this.sortBy('firstName')} arrowDirection={this.getArrowDirection('firstName')}>
                {this.props.t('ManageUsersTable.first_name')}
              </Th>
              <Th onClick={this.sortBy('surname')} arrowDirection={this.getArrowDirection('surname')}>
                {this.props.t('ManageUsersTable.last_name')}
              </Th>
              <Th onClick={this.sortBy('email')} arrowDirection={this.getArrowDirection('email')}>
                {this.props.t('ManageUsersTable.email')}
              </Th>
              <Th onClick={this.sortBy('mobile')} arrowDirection={this.getArrowDirection('mobile')}>
                {this.props.t('ManageUsersTable.mobile')}
              </Th>
              <Th onClick={this.sortBy('lastActive')} arrowDirection={this.getArrowDirection('lastActive')}>
                {this.props.t('ManageUsersTable.last_log_on_date')}
              </Th>
            </tr>
          </thead>
          <tbody className="manageUsersTable__tableBody">
            {users.map((user, index) => {
              return (
                <ManageUsersTableItem key={user.id} index={index} user={user} dealershipId={this.props.dealershipId} />
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

ManageUsersTable.propTypes = {
  users: PropTypes.object,
  dealershipId: PropTypes.string.isRequired
};
export default withTranslation('ManageUsers')(ManageUsersTable);
