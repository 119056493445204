import * as actionTypes from '../actionTypes';

const defaultState = {
  VehicleType: 'used',
  UsageType: 'private',
  VehicleClass: 'car',
  PreferredTerm: '18',
  DepositType: 'fixed',
  DepositValue: '',
  hasError: false,
  hasLoaded: false,
  savingState: '',
  DefaultAnnualMileage: 10000
};

export default function globalDefaultSettingsReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.FETCH_DEALERSHIP_SETTINGS:
      return defaultState;

    case actionTypes.FETCH_DEALERSHIP_SETTINGS_SUCCESS:
      let settings = action.response || {};

      return Object.assign({}, state, {
        hasLoaded: true,
        VehicleType: settings.VehicleType || 'used',
        UsageType: settings.UsageType || 'private',
        VehicleClass: settings.VehicleClass || 'car',
        PreferredTerm: settings.PreferredTerm || '18',
        DepositType: settings.DepositType || 'fixed',
        DepositValue: settings.DepositValue || '',
        DefaultAnnualMileage: settings.DefaultAnnualMileage || 10000
      });

    case actionTypes.FETCH_DEALERSHIP_SETTINGS_ERROR:
      return Object.assign({}, state, { hasLoaded: true, hasError: true });

    case actionTypes.SAVE_DEALERSHIP_SETTINGS:
      return Object.assign({}, state, { savingState: 'loading' });

    case actionTypes.SAVE_DEALERSHIP_SETTINGS_SUCCESS:
      return Object.assign(
        {},
        {
          savingState: 'done'
        }
      );

    case actionTypes.SAVE_DEALERSHIP_SETTINGS_ERROR:
      return Object.assign({}, state, { savingState: 'error' });

    default:
      return state;
  }
}
