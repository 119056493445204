import { withTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { inject } from 'mobx-react';
import MediaQuery from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import objectValidation from '~/core/objectValidation';
import { FormProvider, useForm } from 'react-hook-form';

import Panel from '~Common/Panel';
import Modal from '~Common/Modal/Modal';
import CollapsiblePanel from '~Common/CollapsiblePanel';

import PanelHeader from '~Common/PanelHeader';
import LoadingSpinner from '~Common/Loading/LoadingSpinner';
import { CountryCodeToCurrency } from '~/constants';

import { SideNavigation } from './components';
import { StockBreadcrumbs, StockContainerLayout, StockVehicleSidePanel } from '../../../../../../shared/components';

import observerForHooks from '../../../../../../shared/hocs/observerForHooks';
import transformTaxonomyData, {
  isBike,
  transformVehicleClass
} from '../../../../../../shared/helpers/transformTaxonomyData';
import { getValidationRules } from '~/modules/Stock/routes/stock/shared/helpers/vehicleFormFields';
import { removeEmpty } from '../../shared/helpers/';

import './stockEditContainer.scss';

const formatDate = (date) => {
  const newDate = new Date(Date.parse(date));
  return newDate.toLocaleDateString('en-GB');
};

const defaultValue = (object, defaultValue) => {
  for (const key in object) {
    if (object[key] == null) {
      object[key] = defaultValue;
    }
  }

  return object;
};

const defaultToEmptyString = (object) => defaultValue(object, '');

const StockEditContainer = ({ t, ...props }) => {
  const navigate = useNavigate();
  const { dealershipId, vehicleId } = useParams();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const { vehicleData, loadingState, editFormData, vehicleTaxonomyData } = props;
  const { countryCode } = props.appStore.uiState;
  const [rules, setRules] = useState({});

  useEffect(() => {
    const { getVehicleData, clearStore } = props.appStore.stockEditStore;
    getVehicleData(dealershipId, vehicleId);
    return () => {
      clearStore();
    };
  }, [props.appStore.stockEditStore, dealershipId, vehicleId]);

  // Redirect if fetched vehicle does not exist
  useEffect(() => {
    if (loadingState === 'error') {
      const { pushInfoNotification } = props.appStore.notificationStore;
      pushInfoNotification('There was a problem getting the vehicle', 'Info');
      navigate(`/d/${dealershipId}/stock/list`);
    }
  }, [loadingState, props.appStore.stockEditStore, dealershipId, navigate, props.appStore.notificationStore]);

  const handleCancel = () => {
    navigate(`/d/${dealershipId}/stock/list`);
  };

  const closeCancelModal = () => {
    setIsCancelModalOpen(false);
  };

  const methods = useForm({
    mode: 'all',
    resolver: async (data) => {
      const errors = objectValidation(data, rules);

      return !errors ? { values: data, errors: {} } : { errors };
    }
  });

  //   Populate form when data is available
  useEffect(() => {
    if (!vehicleData) return;

    const taxonomyData = vehicleTaxonomyData ? transformTaxonomyData(vehicleTaxonomyData) : {};
    const klass = transformVehicleClass(vehicleData?.taxonomies?.[0]?.class ?? 'car')
    const primaryId = vehicleData?.taxonomies[0]?.primaryId;
    const secondaryId = vehicleData?.taxonomies[0]?.secondaryId;
    const isMotorcycle = isBike(klass);

    const mergedTaxonomyDetails = {
      ...vehicleData.taxonomyDetails,
      ...removeEmpty(vehicleData.suppliedTaxonomyDetails)
    };

    methods.reset(
      defaultToEmptyString({
        condition: vehicleData.identity.condition,
        class: klass,
        vrm: vehicleData.identity.vrm,
        vin: vehicleData.identity.vin,
        regDate: formatDate(vehicleData.advertInformation.registrationDate),
        make: mergedTaxonomyDetails.make,
        model: mergedTaxonomyDetails.model,
        derivative: mergedTaxonomyDetails.derivative,
        bodyStyle: mergedTaxonomyDetails.bodyStyle,
        mileage: vehicleData.advertInformation.mileage,
        doors: mergedTaxonomyDetails.doors,
        seats: isMotorcycle ? 1 : mergedTaxonomyDetails.noOfSeats,
        transmission: isMotorcycle ? 'Manual' : mergedTaxonomyDetails.trans,
        keepers: vehicleData.advertInformation.numberOfPreviousKeepers,
        price: vehicleData.advertInformation.price,
        dealerReference: vehicleData.dealer.dealerReference,
        fuelType: isMotorcycle ? 'Petrol' : mergedTaxonomyDetails.fuel,
        vatStatus: vehicleData.advertInformation.vatStatus,
        description: vehicleData.advertInformation.description,
        colour: vehicleData.advertInformation.colour,
        attentionGrabber: vehicleData.advertInformation.attentionGrabber,
        options: vehicleData.advertInformation.options,
        cc: mergedTaxonomyDetails.cc,
        bhp: mergedTaxonomyDetails.bhp,
        lastModifiedEpoch: vehicleData.lastModifiedEpoch,
        dateCreatedEpoch: vehicleData.dateCreatedEpoch,
        mediaCompanyName: vehicleData.mediaCompanyName,
        auctionId: vehicleData.auctionId,
        currency: vehicleData.currency ?? CountryCodeToCurrency[countryCode],
        mileageUnit: vehicleData.mileageUnit,
        drivingPosition: vehicleData.drivingPosition ?? 'LHD',
        consumptionInner: vehicleData.advertInformation.consumptionInner,
        consumptionOuter: vehicleData.advertInformation.consumptionOuter,
        consumptionCombined: vehicleData.advertInformation.consumptionCombined,
        includesVat:
          typeof vehicleData.advertInformation.includesVat === 'boolean'
            ? vehicleData.advertInformation.includesVat.toString()
            : null,
        vatRate: vehicleData.advertInformation.vatRate,
        emission: vehicleData.advertInformation.emission,
        energyEfficiencyClass: vehicleData.advertInformation.energyEfficiencyClass,
        primaryId,
        secondaryId,

        imgUrl: vehicleData.imgHr,
        dealershipId: dealershipId,
        vehicleId: vehicleId,
        ...editFormData,
        ...taxonomyData
      })
    );
  }, [vehicleData, methods.reset, dealershipId, vehicleId, methods, countryCode, editFormData, vehicleTaxonomyData]);

  const Klass = methods.watch('class');
  //   Update validation rules on class form field change
  React.useEffect(() => {
    if (!vehicleData) return null;

    const hasTaxonomyData =
      (methods.watch('primaryId') && methods.watch('secondaryId')) || !vehicleData.dealerPlatformUpload;

    const rules = getValidationRules({
      vehicleClass: Klass,
      hasTaxonomyData,
      isDealerPlatformUpload: vehicleData.dealerPlatformUpload
    });

    setRules(rules);
  }, [Klass, methods, vehicleData]);

  if (vehicleData) {
    return (
      <StockContainerLayout>
        {/* StockContainerLayout expects an array of children (?)
        or at least an array like object.
        */}
        {[
          <FormProvider {...methods} key={0}>
            <StockBreadcrumbs
              items={[
                {
                  name: t('Common.stock_list'),
                  path: `/d/${dealershipId}/stock/list`
                },
                {
                  name: t('Common.upload_media')
                }
              ]}
            />
            <div className="stockEditContainer__wrapper">
              <MediaQuery minWidth="920px">
                <div className="stockEditContainer__col0">
                  <Panel className="stockEditContainer__panel">
                    <StockVehicleSidePanel />
                  </Panel>
                  <Panel className="stockEditContainer__panel">
                    <PanelHeader>{t('StockEditContainer.view')}</PanelHeader>
                    <SideNavigation />
                  </Panel>
                </div>
              </MediaQuery>
              <div className="stockEditContainer__col1">
                <MediaQuery maxWidth="919px">
                  <CollapsiblePanel id="vehiclePreview" title={t('StockEditContainer.preview_vehicle')}>
                    <StockVehicleSidePanel />
                  </CollapsiblePanel>
                  <Panel className="stockEditContainer__panel">
                    <PanelHeader>{t('StockEditContainer.view')}</PanelHeader>
                    <SideNavigation />
                  </Panel>
                </MediaQuery>
                {loadingState === 'loading' && <LoadingSpinner />}
                {loadingState === 'idle' && props.children}
                {loadingState === 'error' && (
                  <p>{t('StockEditContainer.an_error_has_occured_please_try_again_later')}</p>
                )}
              </div>

              <Modal
                title={t('StockEditContainer.are_you_sure')}
                onClose={closeCancelModal}
                isOpen={isCancelModalOpen}
                onConfirm={handleCancel}
                buttonText={t('StockEditContainer.i_understand')}
              >
                <div className="stockEditContainer__confirmationModal">
                  {t(
                    'StockEditContainer.if_you_proceed_your_amendments_will_not_be_saved_and_youll_be_returned_to_your_stock_list'
                  )}
                </div>
              </Modal>
            </div>
          </FormProvider>
        ]}
      </StockContainerLayout>
    );
  } else {
    return null;
  }
};

export default withTranslation('Stock')(
  inject('appStore')(
    observerForHooks({
      vehicleData: (props) => props.appStore.stockEditStore.vehicleData,
      loadingState: (props) => props.appStore.stockEditStore.loadingState,
      editFormData: (props) => props.appStore.stockEditStore.editFormData,
      vehicleTaxonomyData: (props) => props.appStore.stockEditStore.vehicleTaxonomyData
    })(StockEditContainer)
  )
);
