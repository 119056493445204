import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon/Icon';
import './eligibilitySummaryButton.scss';

const EligibilitySummaryButton = ({ onClick, t }) => {
  return (
    <div className="eligibilitySummaryButton">
      <button onClick={onClick} className="eligibilitySummaryButton__button">
        <div className="eligibilitySummaryButton__buttonIcon">
          <Icon name="summary" />
        </div>
        <div className="eligibilitySummaryButton__buttonText">{t('EligibilitySummaryButton.eligibility_summary')}</div>
      </button>
    </div>
  );
};

EligibilitySummaryButton.propTypes = {
  onClick: PropTypes.func
};

export default withTranslation('Common')(EligibilitySummaryButton);
