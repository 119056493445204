import React from 'react';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import './fieldGroupRhf.scss';
import Label from './Label';
import { ErrorFieldGroupRHF } from './ErrorFieldGroupRHF';
import ButtonInfoIcon from '../Button/ButtonInfoIcon';

type FieldGroupRHFProps = {
  label: string | React.ReactNode;
  name: string;
  disabled?: boolean;
  onTooltipClicked?: () => void;
  children: React.ReactNode;
};

export const FieldGroupRHF = ({ label, name, onTooltipClicked, children }: FieldGroupRHFProps) => {
  const { getFieldState } = useFormContext();

  const fieldState = getFieldState(name);
  const { error } = fieldState;

  return (
    <>
      <div className={cn('fieldGroupRhf', 'inline')}>
        <div className={cn('fieldGroupRhf__label')}>
          <Label>{label}</Label>
          {onTooltipClicked && <ButtonInfoIcon onClick={onTooltipClicked} />}
        </div>
        <div className={cn('fieldGroupRhf__input')}>{children}</div>
      </div>
      {error && <ErrorFieldGroupRHF>{fieldState.error?.message}</ErrorFieldGroupRHF>}
    </>
  );
};
