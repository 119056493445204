import { inject } from 'mobx-react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '~Common/Breadcrumbs';

const StockBreadcrumbs = ({ items, appStore }) => {
  const { dealershipId } = useParams();
  let defaultCrumbs = appStore.uiState.canUseDashboard
    ? [
        {
          name: 'Home',
          path: `/d/${dealershipId}`
        }
      ]
    : [];

  return <Breadcrumbs items={[...defaultCrumbs, ...items]} />;
};

export default inject('appStore')(StockBreadcrumbs);
