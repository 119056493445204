import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../core/helpers';
import QuoteFunderLogo from '../QuotesList/components/QuoteFunderLogo';
import ProductName from '../QuotesList/components/ProductName';
import classnames from 'classnames';
import Modal from '../Common/Modal/Modal';
import ImageButton from '../Common/ImageButton';
import { connect } from 'react-redux';
import MoneyFormat from '../Common/MoneyFormat';
import QuoteLegalText from '~/components/Quoting/components/QuoteLegalText/QuoteLegalText';
import FormatAPR, { FormatNumToDecimals } from 'components/Quoting/components/FormatAPR';
import { QuoteRowLabel } from 'components/QuoteCard/components/QuoteRowInfo/QuoteRowLabel';
import { QuoteRowValue } from 'components/QuoteCard/components/QuoteRowInfo/QuoteRowValue';
import MultiLoansContainer from '../QuoteCard/components/MultiLoans/MultiLoansContainer';
import FieldToggle from '../Common/Form/FieldToggle';
import { useState } from 'react';
import {
  getMultiLoans,
  getTotalFees,
  shouldShowSingularFee,
  getAllVapsTotal,
  getAlteredCashDepositValue
} from '~/components/PushDeal/utils/quoteUtils';
import './pushDealQuotePreview.scss';
import { inject } from 'mobx-react';

const PushDealQuotePreview = (props) => {
  const { newCarOffer, quote } = props;

  const [showMoreButton, setShowMoreButton] = useState(false);
  const [modalToShow, setModalToShow] = useState('');

  const { t } = useTranslation('PushDeal');

  const openModal = (modalName) => {
    setModalToShow(modalName);
  };
  const closeModal = (e) => {
    e && e.preventDefault();
    setModalToShow('');
  };
  const handleClick = () => {
    setShowMoreButton(!showMoreButton);
    window.ga && window.ga('send', 'event', 'PushDeal_quote', 'ButtonPress', 'toggleShowMoreButton');
  };

  const showMoreQuotePreviewClass = classnames(
    showMoreButton ? 'pushDealQuotePreview__showLess' : 'pushDealQuotePreview__showMore'
  );

  const multiLoans = getMultiLoans(quote);
  const showMultiLoans = multiLoans?.Vehicle && (multiLoans.NegativeEquity || multiLoans.ValueAddedProduct);
  const totalFees = getTotalFees(quote);
  const showSingularFee = shouldShowSingularFee(quote);

  return (
    <div className="pushDealQuotePreview__Container">
      <div className={showMoreQuotePreviewClass}>
        {showMoreButton ? (
          <>
            <div className="pushDealQuotePreview__section pushDealQuotePreview__section--logo">
              <div className="pushDealQuotePreview__header">
                <div className="pushDealQuotePreview__funderLogo">
                  <QuoteFunderLogo funder={quote.FunderCode} />
                </div>
                <div className="pushDealQuotePreview__productName">
                  <ProductName productName={quote.ProductName} />
                </div>
              </div>
            </div>
            <div className="pushDealQuotePreview__section">
              <div className="pushDealQuotePreview__subtitle">{t('PushDealQuotePreview.total_price')}</div>
              <div>{<MoneyFormat value={quote.CashPrice} />} </div>
            </div>
            <div className="pushDealQuotePreview__section">
              <div className="pushDealQuotePreview__subtitle">{t('PushDealQuotePreview.vehicle_price')}</div>
              <div>{<MoneyFormat value={quote.VehiclePrice} />} </div>
            </div>
            <div className="pushDealQuotePreview__section">
              <div className="pushDealQuotePreview__subtitle">
                {t('PushDealQuotePreview.value_added_products')}
                {quote.ValueAddedProducts.length > 0 && (
                  <ImageButton
                    image="information"
                    width="25px"
                    height="25px"
                    onClick={() => openModal('showVapsModal')}
                  />
                )}
              </div>
              <div>{<MoneyFormat value={getAllVapsTotal(quote)} />} </div>
            </div>
            <div className="pushDealQuotePreview__section">
              <div className="pushDealQuotePreview__subtitle">
                {t('PushDealQuotePreview.total_deposit')}
                <ImageButton
                  image="information"
                  width="25px"
                  height="25px"
                  dataTestId="total-deposit-modal-btn"
                  onClick={() => openModal('showDepositModal')}
                />
              </div>
              <div>{<MoneyFormat value={quote.TotalDeposit} />} </div>
            </div>
            <div className="pushDealQuotePreview__section">
              <div className="pushDealQuotePreview__subtitle">{t('PushDealQuotePreview.total_amount_of_credit')}</div>
              <div>{<MoneyFormat value={quote.BalancePayable} />} </div>
            </div>
            <div className="pushDealQuotePreview__section">
              <div className="pushDealQuotePreview__subtitle">{t('PushDealQuotePreview.agreement_term')}</div>
              <div>
                {quote.Term} {''} {t('PushDealQuotePreview.months')}
              </div>
            </div>
            <div className="pushDealQuotePreview__section">
              <div className="pushDealQuotePreview__subtitle">{t('PushDealQuotePreview.annual_mileage')}</div>
              <div className="pushDealQuotePreview__value">
                {formatNumber(quote.AnnualDistanceQuoted, 0, props.appStore.uiState.countryCode)}{' '}
                {t('PushDealQuotePreview.miles')}
              </div>
            </div>
            {showSingularFee && quote.OptionToPurchaseFee > 0 && (
              <div className="pushDealQuotePreview__section">
                <div className="pushDealQuotePreview__subtitle">{t('PushDealQuotePreview.option_to_purchase_fee')}</div>
                <div>{<MoneyFormat value={quote.OptionToPurchaseFee} />} </div>
              </div>
            )}
            {showSingularFee && quote.AcceptanceFeeFirst > 0 && (
              <div className="pushDealQuotePreview__section">
                <div className="pushDealQuotePreview__subtitle">{t('PushDealQuotePreview.acceptance_fee_first')}</div>
                <div>{<MoneyFormat value={quote.AcceptanceFeeFirst} />} </div>
              </div>
            )}
            {showSingularFee && quote.AcceptanceFeeLast > 0 && (
              <div className="pushDealQuotePreview__section">
                <div className="pushDealQuotePreview__subtitle">{t('PushDealQuotePreview.acceptance_fee_last')}</div>
                <div>{<MoneyFormat value={quote.AcceptanceFeeLast} />} </div>
              </div>
            )}
            {showSingularFee && quote.AcceptanceFee > 0 && (
              <div className="pushDealQuotePreview__section">
                <div className="pushDealQuotePreview__subtitle">{t('PushDealQuotePreview.acceptance_fee')}</div>
                <div>{<MoneyFormat value={quote.AcceptanceFee} />} </div>
              </div>
            )}

            {!showSingularFee && totalFees > 0 && (
              <div className="pushDealQuotePreview__section">
                <div className="pushDealQuotePreview__subtitle">
                  {t('PushDealQuotePreview.total_fees')}
                  {totalFees > 0 && (
                    <ImageButton
                      image="information"
                      width="25px"
                      height="25px"
                      onClick={() => openModal('showFeesModal')}
                    />
                  )}
                </div>
                <div>{<MoneyFormat value={totalFees} />}</div>
              </div>
            )}
            <div className="pushDealQuotePreview__section">
              <div className="pushDealQuotePreview__subtitle">{t('PushDealQuotePreview.first_payment')}</div>
              <div>{<MoneyFormat value={quote.FirstPayment} />} </div>
            </div>
            <div className="pushDealQuotePreview__section">
              <div className="pushDealQuotePreview__subtitle">
                {quote.FollowedBy} {t('PushDealQuotePreview.payments')}
              </div>
              <div>{<MoneyFormat value={quote.FollowingPayments} />} </div>
            </div>
            <div className="pushDealQuotePreview__section">
              <div className="pushDealQuotePreview__subtitle">{t('PushDealQuotePreview.final_payment')}</div>
              <div>{<MoneyFormat value={quote.FinalPayment} />} </div>
            </div>
            <div className="pushDealQuotePreview__section">
              <div className="pushDealQuotePreview__subtitle">{t('PushDealQuotePreview.interest_charges')}</div>
              <div>{<MoneyFormat value={quote.InterestCharges} />} </div>
            </div>
            <div className="pushDealQuotePreview__section">
              <div className="pushDealQuotePreview__subtitle">
                {t('PushDealQuotePreview.annual_fixed_interest_rate')}
              </div>
              <FormatNumToDecimals value={quote.AnnualRate} decimal={2} appendChar="%" />
            </div>
            <div className="pushDealQuotePreview__section">
              <div className="pushDealQuotePreview__subtitle">{t('PushDealQuotePreview.apr')}</div>
              <div>
                <FormatAPR value={quote.RepresentativeApr} />
              </div>
            </div>
            <div className="pushDealQuotePreview__section">
              <div className="pushDealQuotePreview__subtitle">{t('PushDealQuotePreview.total_amount_payable')}</div>
              <div>{<MoneyFormat value={quote.TotalAmountPayable} />} </div>
            </div>

            {showMultiLoans && <MultiLoansContainer loans={multiLoans} isSingleColumn={true} />}
          </>
        ) : (
          <>
            <div className="pushDealQuotePreview__section pushDealQuotePreview__section--logo">
              <div className="pushDealQuotePreview__header">
                <div className="pushDealQuotePreview__funderLogo">
                  <QuoteFunderLogo funder={quote.FunderCode} />
                </div>
                <div className="pushDealQuotePreview__productName">
                  <ProductName productName={quote.ProductName} />
                </div>
              </div>
            </div>

            <div className="pushDealQuotePreview__section">
              <div className="pushDealQuotePreview__subtitle">{t('PushDealQuotePreview.apr')}</div>
              <div>
                <FormatAPR value={quote.RepresentativeApr} />
              </div>
            </div>
            <div className="pushDealQuotePreview__section">
              <div className="pushDealQuotePreview__subtitle">{t('PushDealQuotePreview.monthly_payment')}</div>
              <div>{<MoneyFormat value={quote.FollowingPayments} />} </div>
            </div>
          </>
        )}
      </div>
      <footer>
        {showMoreButton && (
          <p className="pushDealQuotePreview__quoteSmallPrint__finance">
            <QuoteLegalText quote={quote} />
          </p>
        )}
        {showMoreButton && newCarOffer && (
          <p className="pushDealQuotePreview__quoteSmallPrint__finance">{newCarOffer.OfferText}</p>
        )}
        <div className="quoteCard__tableButton">
          <FieldToggle text="Full Quote" onClick={handleClick} showHide={showMoreButton} />
        </div>
      </footer>

      <Modal
        isOpen={modalToShow === 'showFeesModal'}
        title={t('PushDealQuotePreview.fees_breakdown')}
        onClose={closeModal}
      >
        <div>
          {quote.OptionToPurchaseFee > 0 && (
            <ModalSection title={t('PushDealQuotePreview.option_to_purchase_fee')} value={quote.OptionToPurchaseFee} />
          )}
          {quote.AcceptanceFeeFirst > 0 && (
            <ModalSection title={t('PushDealQuotePreview.first_administration_fee')} value={quote.AcceptanceFeeFirst} />
          )}
          {quote.AcceptanceFeeLast > 0 && (
            <ModalSection title={t('PushDealQuotePreview.last_administration_fee')} value={quote.AcceptanceFeeLast} />
          )}
          {quote.AcceptanceFee > 0 && !quote.AcceptanceFeeFirst && (
            <ModalSection title={t('PushDealQuotePreview.administration_fee')} value={quote.AcceptanceFee} />
          )}
          <div className="pushDealQuotePreview__modalDivider"> </div>

          <div className="pushDealQuotePreview__totalSection">
            <div className="pushDealQuotePreview__subtitle--Bold">{t('PushDealQuotePreview.total_fees')}</div>
            <div>{<MoneyFormat value={totalFees} />} </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalToShow === 'showDepositModal'}
        title={t('PushDealQuotePreview.deposit_breakdown')}
        onClose={closeModal}
      >
        <div>
          {quote.RequestedDeposit >= 0 && (
            <ModalSection title={t('PushDealQuotePreview.cash_deposit')} value={quote.RequestedDeposit} prefix="+" />
          )}
          {quote.FinanceDepositAllowances &&
            quote.FinanceDepositAllowances.map((FinanceDepositAllowance) => (
              <ModalSection
                key={`modal-finance-deposit-allowance-${FinanceDepositAllowance.Name}-${FinanceDepositAllowance.Value}`}
                title={FinanceDepositAllowance.Name}
                value={FinanceDepositAllowance.Value}
              />
            ))}
          {quote.PartExchange >= 0 && (
            <ModalSection title={t('PushDealQuotePreview.part_exchange')} value={quote.PartExchange} prefix="+" />
          )}
          {quote.OutstandingSettlement >= 0 && (
            <ModalSection
              title={t('PushDealQuotePreview.settlement_figure')}
              value={quote.OutstandingSettlement}
              prefix="-"
            />
          )}
          {quote.ValueAddedTax > 0 && (
            <ModalSection title={t('PushDealQuotePreview.value_added_tax')} value={quote.ValueAddedTax} />
          )}

          <div className="pushDealQuotePreview__modalDivider"> </div>
          <div className="pushDealQuotePreview__totalSection">
            <div className="pushDealQuotePreview__subtitle--Bold">
              {t('PushDealQuotePreview.adjusted_cash_deposit')}
            </div>
            <div>{<MoneyFormat value={getAlteredCashDepositValue(quote)} />} </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalToShow === 'showVapsModal'}
        title={t('PushDealQuotePreview.value_added_products_breakdown')}
        onClose={closeModal}
      >
        <div>
          {quote.ValueAddedProducts.length > 0 &&
            quote.ValueAddedProducts.map((vap, index) => (
              <ModalSection
                key={`valueAddedProduct-${index}`}
                title={
                  <>
                    <span>{vap?.ProductTypeText}</span>
                    <span>{vap.Name}</span>
                  </>
                }
                value={vap.Price}
              />
            ))}
          <div className="pushDealQuotePreview__modalDivider"> </div>
          <div className="pushDealQuotePreview__totalSection">
            <div className="pushDealQuotePreview__subtitle--Bold">
              {t('PushDealQuotePreview.total_value_added_products')}
            </div>
            <div>{<MoneyFormat value={getAllVapsTotal(quote)} />} </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    funders: state.funders
  };
}

export const PushDealQuoteInformation = ({ quote }) => {
  return Object.keys(quote).map((label, index) =>
    quote[label].value !== undefined ? (
      <div key={`${label}-${index}`} className="pushDealQuotePreview__section">
        <div className="pushDealQuotePreview__subtitle">
          <QuoteRowLabel label={label} value={quote[label]} />
        </div>
        <div>
          <QuoteRowValue label={label} value={quote[label]} />
        </div>
      </div>
    ) : null
  );
};

export const ModalSection = ({ title, value, prefix }) => (
  <div className="pushDealQuotePreview__modalSection">
    <div className="pushDealQuotePreview__subtitle">{title}</div>
    <div>
      {prefix} {<MoneyFormat value={value} />}{' '}
    </div>
  </div>
);

export default connect(mapStateToProps)(inject('appStore')(PushDealQuotePreview));
