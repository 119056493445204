import React, { useMemo } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import VehicleImage from '~/components/Common/VehicleImage';
import { formatDateTime } from './helpers';
import { useTranslation } from 'react-i18next';
import MoneyFormat from '~/components/Common/MoneyFormat';
import Icon from '~/components/Common/Icon/Icon';
import './MarqueeQuotes.scss';
import cn from 'classnames';
import { useNavigate } from '~/hooks/useNavigate';
import { inject } from 'mobx-react';
import { AppStore } from '~/modules/Stock/types/Types';
import { useParams } from 'react-router-dom';

export interface Quote {
  message_id: string;
  vehicle_vrm: string;
  ivendi_vehicle_id: string;
  src?: string;
  make: string;
  model: string;
  vehicle_price: number;
  monthly_payment: number;
  anonymousId: string;
  timestamp: string;
  total_quotes_today: string;
}

interface Props {
  quotes: Quote[];
  appStore?: AppStore;
}

const slidesFitInViewport = Math.ceil(window.innerWidth / 380) + 1;

const MarqueeQuotes = ({ quotes = [], appStore }: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('Dashboard');
  // docs https://www.embla-carousel.com/api/options/
  const [emblaRef, embla] = useEmblaCarousel({
    duration: 50,
    align: 'start',
    startIndex: 0,
    watchSlides: true,
    watchDrag: true
  });
  const { hasStockEnabled, countryCode, isDe } = appStore!.uiState;

  const slides = useMemo(() => {
    if (quotes.length === 0) return [];

    const tempSlides = [...quotes];
    const blankSlidesNeeded = slidesFitInViewport - quotes.length;

    // blank slides are added to fill the required space for the animation to work smoothly.
    if (blankSlidesNeeded >= 0) {
      const blankSlides = Array(blankSlidesNeeded).fill(null);
      tempSlides.push(...blankSlides);
    }
    return tempSlides;
  }, [quotes]);

  const handleArrowClick = (direction: 'right' | 'left') => {
    direction === 'right' ? embla?.scrollNext() : embla?.scrollPrev();
  };

  const handleVehicleCardClick = (searchQuery: string) => {
    if (!hasStockEnabled) return;
    if (searchQuery?.toLowerCase() === 'new') return;
    if (searchQuery?.toLowerCase() === '') return;

    navigate(`/d/${params.dealershipId}/stock/list?q=${searchQuery}`);
  };

  const renderNewPulse = () => {
    return <div key={Math.random()} className="MarqueeQuotes__ringringNew" />;
  };
  return (
    <div className={'MarqueeQuotes__wrapper'}>
      <div className="MarqueeQuotes__headingContainer">
        <div className="MarqueeQuotes__pulsar">
          <div className="MarqueeQuotes__ringring" />
          {renderNewPulse()}
          <div className="MarqueeQuotes__circle" />
        </div>
        <h2 className={'MarqueeQuotes__heading'}>{t('MarqueeQuotes.real_time_online_quotes')}</h2>
      </div>
      <div className="MarqueeQuotes__carouselWrapper" ref={emblaRef}>
        <div className="MarqueeQuotes__carousel">
          {slides.map((slide, index) => {
            if (!slide) {
              return <div key={index} className="MarqueeQuotes__vehicle" />;
            }

            const imageSource = decodeURIComponent(slide.src || '');
            return (
              <div
                onClick={() => {
                  isDe
                    ? handleVehicleCardClick(slide.ivendi_vehicle_id || '')
                    : handleVehicleCardClick(slide.vehicle_vrm || '');
                }}
                key={slide.message_id ?? index}
                className={cn(
                  'MarqueeQuotes__vehicle',
                  index === 0 && 'MarqueeQuotes__fadein',
                  hasStockEnabled && 'MarqueeQuotes__pointerCursor'
                )}
              >
                <div className={'MarqueeQuotes__img'}>
                  <VehicleImage iconFontSize="100px" iconMargin="25px auto" imageSource={imageSource} />
                  {index === 0 ? <div className="MarqueeQuotes__recent">{t('MarqueeQuotes.recent')} </div> : null}
                </div>
                <div className="MarqueeQuotes__vehicleContent">
                  {isDe ? (
                    // &nbsp; is added so that there's no id or vrm the UI doesn't shift up
                    <div title={slide.ivendi_vehicle_id} className="MarqueeQuotes__id">
                      {slide.ivendi_vehicle_id}&nbsp;
                    </div>
                  ) : (
                    <div title={slide.vehicle_vrm} className="MarqueeQuotes__vrm">
                      {slide.vehicle_vrm}&nbsp;
                    </div>
                  )}

                  <div className="MarqueeQuotes__vehicleInfo">{`${slide.make} ${slide.model}`}</div>
                  <div>
                    <MoneyFormat countryCode={countryCode} value={slide.vehicle_price} />
                  </div>
                  <div> {`${t('MarqueeQuotes.quotes_today')} ${slide.total_quotes_today}`}</div>
                  <div className="MarqueeQuotes__timestamp">{formatDateTime(slide?.timestamp)}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <button
        onClick={() => handleArrowClick('right')}
        className={cn('MarqueeQuotes__carouselArrowRight', 'MarqueeQuotes__carouselArrow')}
      >
        <Icon name="right" />
      </button>
      <button
        onClick={() => handleArrowClick('left')}
        className={cn('MarqueeQuotes__carouselArrowLeft', 'MarqueeQuotes__carouselArrow')}
      >
        <Icon name="left" />
      </button>
    </div>
  );
};

export default inject('appStore')(MarqueeQuotes);
