import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import car from '~/styles/icons/car.svg';
import van from '~/styles/icons/lcv-placeholder.svg';
import bike from '~/styles/icons/bike-placeholder.svg';
import motorHome from '~/styles/icons/motorhome-placeholder.svg';

import { transformVehicleClass } from '../../../../../../../shared/helpers/transformTaxonomyData';

import './StockVehicleImage.scss';
import { VehicleClassJs } from '~/types/vehicle';

const getPlaceHolderByVehicleClass = (vehicleClass) => {
  const klass = transformVehicleClass(vehicleClass).toLowerCase();

  if (klass === VehicleClassJs.lcv || klass === VehicleClassJs.van) {
    return van;
  }

  if (klass === VehicleClassJs.motorbike || klass === VehicleClassJs.bike) {
    return bike;
  }

  if (klass === VehicleClassJs.motorhome || klass === VehicleClassJs.touringCaravan) {
    return motorHome;
  }

  return car;
};

const StockVehicleImage = ({ vehicleImageUrl, vehicleClass }) => {
  const { t } = useTranslation('Stock');

  const [loadError, setLoadError] = useState(false);

  useEffect(() => {
    setLoadError(false);
  }, [vehicleImageUrl]);

  const handleImageLoadError = () => {
    setLoadError(true);
  };

  const hasVehicleImage = !!vehicleImageUrl;
  const placeholder = getPlaceHolderByVehicleClass(vehicleClass);
  return hasVehicleImage && !loadError ? (
    <div className="StockVehicleImage__outer">
      <img
        className="StockVehicleImage"
        src={vehicleImageUrl}
        alt={t('StockVehicleImage.vehicle')}
        onError={handleImageLoadError}
      />
    </div>
  ) : (
    <div className="StockVehicleImage__outer--placeholder">
      <img className="StockVehicleImage--placeholder" src={placeholder} alt={t('StockVehicleImage.vehicle')} />
    </div>
  );
};

StockVehicleImage.propTypes = {
  vehicleImageUrls: PropTypes.string,
  vehicleClass: PropTypes.string
};
export default StockVehicleImage;
