import React, { cloneElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import cn from 'classnames';
import Icon from '../Icon/Icon';
import CurrencySymbol from '../CurrencySymbol';
import i18n from '../../../i18n';
import CurrencyInputBase, { CurrencyInputProps as CurrencyInputBaseProps } from 'react-currency-input-field';
import { CountryCodes, CurrencyCodes } from '../../../constants';
import { Trans, useTranslation } from 'react-i18next';
import { formatMoney } from 'core/helpers';
import './currencyInput.scss';

type AddonProps = {
  children: React.ReactElement;
  className?: string;
  position?: 'prefix' | 'suffix';
};

const Addon = ({ children, className, position }: AddonProps) => {
  return cloneElement(children, {
    className: cn(
      'currencyInput__addon',
      {
        'currencyInput__addon--suffix': position === 'suffix'
      },
      className
    )
  });
};

const CurrencyInputPropTypes = {
  name: PropTypes.string,
  isValid: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  currency: PropTypes.oneOf(CurrencyCodes),
  locale: PropTypes.oneOf(CountryCodes),
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  showError: PropTypes.bool,
  size: PropTypes.string,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  trackingPage: PropTypes.string,
  dataThook: PropTypes.string,
  total: PropTypes.number,
  type: PropTypes.string
};

type CurrencyInputProps = InferProps<typeof CurrencyInputPropTypes>;

const CurrencyInput = ({
  isValid,
  id,
  name,
  value,
  placeholder,
  currency,
  locale,
  disabled,
  onBlur,
  onChange,
  showError,
  size,
  onEdit: onEditProp,
  onRemove: onRemoveProp,
  trackingPage,
  dataThook,
  total,
  type = null
}: CurrencyInputProps) => {
  const { t } = useTranslation('Common');
  const handleChange: CurrencyInputBaseProps['onValueChange'] = (value, name, values) => {
    if (!values) return;

    const hasDecimal = (values?.float || 0) % 1 !== 0 || /[.|,]00$/.test(values.value);

    onChange?.(hasDecimal ? values.value.replace(',', '.') : values.value);
  };

  const handleBlur: CurrencyInputBaseProps['onBlur'] = (e) => {
    if (trackingPage && window.ga && id) {
      window.ga('send', 'event', trackingPage, 'FieldBlur', id);
    }

    onBlur && onBlur(e);
  };
  const onEdit = () => {
    onEditProp?.();
    if (trackingPage && window.ga) {
      window.ga('send', 'event', trackingPage, 'ButtonPress', id + '_EditVap');
    }
  };

  const onRemove = () => {
    onRemoveProp?.();
    if (trackingPage && window.ga) {
      window.ga('send', 'event', trackingPage, 'ButtonPress', id + '_RemoveVap');
    }
  };

  const computedLocale = locale || i18n.language;
  const symbolPosition = computedLocale === 'en' ? 'prefix' : 'suffix';

  let containerClassnames = cn(
    'currencyInput',
    showError && !isValid && !disabled && 'currencyInput--invalid',
    size === 'small' && 'currencyInput--small',
    disabled && 'currencyInput--disabled'
  );

  const symbol = (
    <Addon position={symbolPosition}>
      <label htmlFor={id || undefined}>
        <CurrencySymbol currency={currency} countryCode={undefined} />
      </label>
    </Addon>
  );

  return (
    <div className={containerClassnames}>
      {symbolPosition === 'prefix' && symbol}
      <CurrencyInputBase
        id={id || undefined}
        name={name || undefined}
        placeholder={placeholder || '0'}
        value={value || value === 0 ? value : undefined}
        decimalsLimit={2}
        onValueChange={handleChange}
        onBlur={handleBlur}
        className="currencyInput__input"
        data-th={dataThook ? `${dataThook}Input` : undefined}
        disabled={disabled || undefined}
        maxLength={28}
        autoComplete="off"
        intlConfig={{ locale: computedLocale }}
        aria-label={`${id} Currency Input`}
      />

      {type === 'valueAndTotal' && (
        <div className={`currencyInput__total${disabled ? ' currencyInput__total--disabled' : ''}`}>
          {!disabled ? (
            <Addon className="borderless">
              <Trans i18nKey="CurrencyInput.available" values={{ formattedTotal: formatMoney(total ?? 0) }} />
            </Addon>
          ) : (
            <Addon className="borderless">
              <p>{t('CurrencyInput.unavailable')}</p>
            </Addon>
          )}
        </div>
      )}
      {symbolPosition === 'suffix' && symbol}
      {onEditProp ? (
        <Addon position="suffix" className="currencyInput__btn currencyInput__btn--edit">
          <button type="button" onClick={onEdit} disabled={disabled || undefined}>
            <Icon name="edit" />
          </button>
        </Addon>
      ) : null}
      {onRemoveProp ? (
        <Addon position="suffix" className="currencyInput__btn currencyInput__btn--remove">
          <button type="button" onClick={onRemove} disabled={disabled || undefined}>
            <Icon name="bin" />
          </button>
        </Addon>
      ) : null}
    </div>
  );
};

CurrencyInput.propTypes = CurrencyInputPropTypes;

export default CurrencyInput;
