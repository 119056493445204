import { observable, action } from 'mobx';
import * as api from '../api/applicationStatus';
import Request from '../mobx-models/Request';
import * as debug from '../debug';

class ApplicationStatusStore {
  setCancelApplicationRequest = new Request();
  @observable isCancelLoading;
  @observable isCancelSuccessful = null;

  @action
  setCancelApplication = (applicationId) => {
    this.isCancelLoading = true;
    return this.setCancelApplicationRequest
      .setDelay(5000)
      .onSuccess(this.setCancelApplicationSuccess)
      .onError((e) => {
        this.isCancelLoading = false;
        this.isCancelSuccessful = false;
        debug.error(e);
      })
      .call(api.setReservationSettings, applicationId);
  };

  @action
  setCancelApplicationSuccess = () => {
    this.isCancelLoading = false;
    this.isCancelSuccessful = true;
  };

  @action
  resetModalStatus = () => {
    this.isCancelLoading = null;
    this.isCancelSuccessful = null;
  };
}

export default ApplicationStatusStore;
