import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getFormattedAddress } from './utils';
import './addressLine.scss';

const AddressLine = ({ address, noAddress, style, t }) => {
  let addressString = getFormattedAddress(address);

  if (!addressString && !noAddress) {
    return null;
  }

  return (
    <div className="addressLine__container" key="container">
      <span className="addressLine__address" style={style}>
        {addressString || t('AddressLine.no_address')}
      </span>
    </div>
  );
};

AddressLine.propTypes = {
  address: PropTypes.object,
  noAddress: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

AddressLine.defaultProps = {
  style: {}
};

export default withTranslation('Common')(AddressLine);
