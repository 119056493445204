import { useParams } from 'react-router-dom';

const useGlobalParams = () => {
  const params = useParams();

  if (!params.dealershipId) {
    throw new Error('dealershipId is required in route params');
  }

  return {
    dealershipId: params.dealershipId
  };
};

export default useGlobalParams;
