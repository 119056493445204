import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FinanceApplications from '../components/FinanceApplications';
import * as financeApplicationsActions from '../../../redux/financeApplications/financeApplicationsActions';
import _ from 'lodash';
import { compose } from 'redux';
import { withNavigate, withParams, withQuery } from '../../../hocs/router';
import { push } from '../../../routerHistory';

class FinanceApplicationsContainer extends Component {
  componentDidMount() {
    const { matchRate, activeFilter, page } = this.props.query;
    this.props.fetchFinanceApplications({
      matchRate,
      activeFilter,
      page
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.query, nextProps.query)) {
      const { matchRate, activeFilter, page } = nextProps.query;
      this.props.fetchFinanceApplications({
        matchRate,
        activeFilter,
        page
      });
    }
  }

  handlePageChange = (page) => {
    window.scrollTo(0, 0);
    this.props.navigate({
      pathname: `/d/${this.props.params.dealershipId}/financeapplications`,
      query: {
        ...this.props.query,
        page: page
      }
    });
  };

  handleMatchRateFilter = (matchRate) => {
    this.props.navigate({
      pathname: `/d/${this.props.params.dealershipId}/financeapplications`,
      query: {
        ...this.props.query,
        matchRate: matchRate,
        page: 1
      }
    });
  };

  handleFilterLeads = (activeFilter) => {
    this.props.navigate({
      pathname: `/d/${this.props.params.dealershipId}/financeapplications`,
      query: {
        ...this.props.query,
        activeFilter: activeFilter,
        page: 1
      }
    });
  };

  render() {
    return (
      <FinanceApplications
        dealershipId={this.props.params.dealershipId}
        activeFilter={this.props.query.activeFilter}
        matchRate={this.props.query.matchRate}
        changePage={this.handlePageChange}
        filterFinanceApplicationsLeads={this.handleFilterLeads}
        filterByMatchRate={this.handleMatchRateFilter}
        page={this.props.query.page}
        total={this.props.total}
        pageSize={25}
        items={this.props.items}
        viewConsumer={this.props.viewConsumer}
      />
    );
  }
}

FinanceApplicationsContainer.propTypes = {
  children: PropTypes.any,
  fetchFinanceApplications: PropTypes.func,
  location: PropTypes.object,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  items: PropTypes.array,
  viewConsumer: PropTypes.func,
  params: PropTypes.object
};

function mapStateToProps(state) {
  return {
    items: state.financeApplications.items,
    total: state.financeApplications.total,
    session: state.session,
    isLoading: state.session.isLoading || !state.products.hasInitiallyLoaded,
    options: state.options
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    fetchFinanceApplications: (options) => {
      dispatch(
        financeApplicationsActions.fetchFinanceApplications({
          matchRate: options.matchRate,
          activeFilter: options.activeFilter,
          page: options.page
        })
      );
    },
    viewConsumer: (consumerId) => {
      push(`/d/${ownProps.params.dealershipId}/consumers/${consumerId}`);
    }
  };
}

export default compose(
  withParams,
  withNavigate,
  withQuery,
  connect(mapStateToProps, mapDispatchToProps)
)(FinanceApplicationsContainer);
