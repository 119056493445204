import { useTranslation } from 'react-i18next';
import ApplicationPage from './ApplicationPage';
import PersonalDetailsForm from '../forms/PersonalDetailsForm';
import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import { FixLater } from '~/types/basic';

type PersonalDetailsOutletContext = {
  application: FixLater;
  validationRules: FixLater;
  submitApplicationSection: FixLater;
  saveApplicationSection: FixLater;
  handleSaveSection: FixLater;
  handleCancel: FixLater;
  applicationFields: FixLater;
  editApplicationFieldsDisabled?: FixLater;
};
const PersonalDetails = () => {
  const outletContext = useOutletContext<PersonalDetailsOutletContext>();
  const { t } = useTranslation('Application');
  const location = useLocation();
  const { dealershipId, consumerId, applicantId } = useParams();

  const handleSubmit = (formData: any) => {
    const editing = location.pathname.includes('/edit/');

    if (editing) {
      outletContext.handleSaveSection('PersonalDetails', formData);
    } else {
      outletContext.submitApplicationSection(
        'PersonalDetails',
        applicantId,
        formData,
        `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/addresshistory`
      );
    }
  };
  const handleSave = (formData: any) => {
    // Used when creating an application, not editing
    outletContext.saveApplicationSection('PersonalDetails', applicantId, formData);
  };

  const editing = location.pathname.includes('/edit/');
  let isVatAssist = outletContext.application.Quote.ProductCode.includes('VAT Assist');
  return (
    <ApplicationPage title={t('PersonalDetails.personal_details')}>
      <PersonalDetailsForm
        defaultValues={outletContext.application.PersonalDetails}
        onSubmit={handleSubmit}
        onSave={editing ? undefined : handleSave}
        onCancel={editing ? outletContext.handleCancel : undefined}
        validationRules={outletContext.validationRules.PersonalDetails}
        savingState={outletContext.application.sectionSavingState}
        submittingState={outletContext.application.sectionSubmittingState}
        applicationFields={outletContext.applicationFields.PersonalDetails}
        editApplicationFieldsDisabled={outletContext?.editApplicationFieldsDisabled?.PersonalDetails}
        funderCode={outletContext.application.Quote.FunderCode}
        isVatAssist={isVatAssist}
      />
    </ApplicationPage>
  );
};

export default PersonalDetails;
