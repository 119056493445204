import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './confirmChangeMonthlyPaymentsTermModal.scss';
import Modal from '../../Common/Modal/Modal';
import Button from '../../Common/Button/Button';

const ConfirmChangeMonthlyPaymentsTermModal = ({ onCancel, onCompare, selectedTerm, isOpen }) => {
  const { t } = useTranslation('Quoting');
  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <div className="confirmMonthlyPaymentsTermModal__header">
        {t('ConfirmChangeMonthlyPaymentsTermModal.change_term_and_compare')}
      </div>
      <p className="confirmMonthlyPaymentsTermModal__message">
        {t(
          'ConfirmChangeMonthlyPaymentsTermModal.are_you_sure_you_want_to_compare_these_products_on_a_month_agreement_term',
          { selectedTerm }
        )}
      </p>
      <p className="confirmMonthlyPaymentsTermModal__message">
        {t('ConfirmChangeMonthlyPaymentsTermModal.we_will_generate_live_quotes_based_on_your_selected_term')}
      </p>
      <div className="confirmMonthlyPaymentsTermModal__buttonContainer">
        <div className="confirmMonthlyPaymentsTermModal__button">
          <Button buttonStyle="cancel" onClick={onCancel}>
            {t('ConfirmChangeMonthlyPaymentsTermModal.cancel')}
          </Button>
        </div>
      </div>
      <div className="confirmMonthlyPaymentsTermModal__buttonContainer">
        <div className="confirmMonthlyPaymentsTermModal__button">
          <Button onClick={() => onCompare(selectedTerm)}>{t('ConfirmChangeMonthlyPaymentsTermModal.compare')}</Button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmChangeMonthlyPaymentsTermModal.propTypes = {
  onCancel: PropTypes.func,
  onCompare: PropTypes.func,
  selectedTerm: PropTypes.number,
  isOpen: PropTypes.bool
};

const mapStateToProps = (state) => ({
  selectedTerm:
    state.modals.confirmChangeMonthlyPaymentsTerm && state.modals.confirmChangeMonthlyPaymentsTerm.selectedTerm,
  isOpen: state.modals.confirmChangeMonthlyPaymentsTerm ? state.modals.confirmChangeMonthlyPaymentsTerm.isOpen : false
});

export default connect(mapStateToProps)(ConfirmChangeMonthlyPaymentsTermModal);
