import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import TextArea from './Form/TextArea';
import InputTooltip from './InputTooltip';
import './descriptionTextArea.scss';

class DescriptionTextArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDescriptionTooltip: false
    };
  }

  handleOnFocus = () => {
    this.setState({
      showDescriptionTooltip: true
    });
  };
  handleOnBlur = () => {
    this.setState(
      {
        showDescriptionTooltip: false
      },
      () => {
        this.props.onBlur();
      }
    );
  };

  render() {
    const { showDescriptionTooltip } = this.state;
    const { appStore, description, setDescription, dataThook } = this.props;
    const { hasOrders } = appStore.uiState;
    const showToolTip = hasOrders && showDescriptionTooltip;
    return (
      <div className="descriptionLookup">
        <TextArea
          value={description}
          onChange={setDescription}
          onFocus={this.handleOnFocus}
          onBlur={this.handleOnBlur}
          dataThook={dataThook}
        />
        <div className="descriptionLookup__tooltip">
          <InputTooltip show={showToolTip}>
            <div>
              <p className="descriptionLookup__message">
                {this.props.t('DescriptionTextArea.this_is_for_internal_use_only')}
              </p>
            </div>
          </InputTooltip>
        </div>
      </div>
    );
  }
}

DescriptionTextArea.propTypes = {
  appStore: PropTypes.object,
  description: PropTypes.string,
  setDescription: PropTypes.func,
  dataThook: PropTypes.string
};
export default withTranslation('Common')(inject('appStore')(observer(DescriptionTextArea)));
