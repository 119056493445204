import uuid from 'uuid';

class vehicleOfInterest {
  CustomerType;
  Id;
  VehicleId;
  Make;
  Model;
  Derivative;
  MakeId;
  ModelId;
  CapId;
  GlassId;
  Class;
  Condition;
  Vrm;
  RegistrationDate;
  Mileage;
  Vin;

  updateVehicleOfInterest(vehicle, customerId) {
    this.CustomerType = 'Consumer';
    this.Id = customerId;
    this.VehicleId = uuid.v4();
    this.Make = vehicle.Make;
    this.Model = vehicle.Model;
    this.Derivative = vehicle.Derivative;
    this.MakeId = vehicle.MakeId || -1;
    this.ModelId = vehicle.ModelId || -1;
    this.CapId = vehicle.CapId || -1;
    this.GlassId = vehicle.GlassId || !vehicle.CapId ? -1 : undefined;
    this.Class = vehicle.Class.toLowerCase();
    this.Condition = vehicle.Condition;
    this.Vrm = vehicle.Vrm;
    this.RegistrationDate = vehicle.RegistrationDate;
    this.Mileage = +vehicle.Mileage;
    this.Vin = vehicle.Vin;
  }
}

export default vehicleOfInterest;
