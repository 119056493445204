import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dashboard from '@uppy/react/lib/Dashboard';
import { inject } from 'mobx-react';
import { nanoid } from 'nanoid';
import cn from 'classnames';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/image-editor/dist/style.css';
import '@uppy/webcam/dist/style.css';

import Panel from '~Common/Panel';
import PanelContent from '~Common/PanelContent';
import PanelHeader from '~Common/PanelHeader';
import Modal from '~Common/Modal/Modal';
import Button from '~Common/Button/Button';

import { observerForHooks } from '../../../../../../../../shared/hocs';
import { useVehicleCreateUppy } from './hooks';
import { StockUploadedImages } from './components';

import './stockImageUpload.scss';
import { useParams } from 'react-router-dom';

const uploadUrl = (dealerId, vehicleId) => {
  return `${process.env.REACT_APP_STOCK_API_PATH}/DealerPlatformVehicle/${dealerId}/vehicle/${vehicleId}/image/upload`;
};

const StockImageUpload = (props) => {
  const { t, i18n } = useTranslation('Stock');
  const lng = i18n.language;
  const uploadedFileNames = useRef([]);
  const { syncUploadedImageData } = props.appStore.stockEditStore;
  const { dealershipId, vehicleId } = useParams();
  const [showUploadImagesModal, setShowUploadImagesModal] = useState(false);
  const [uploadType, setUploadType] = useState(''); // main or imperfections
  const [slideNumber, setSlideNumber] = useState(0);
  const [hasUploaded, setHasUploaded] = useState(false);
  const endpoint = uploadUrl(dealershipId, vehicleId);

  const onUploadSuccess = (file) => {
    uploadedFileNames.current.push(file.meta.fileName);
  };

  const formatError = (failedList) =>
    failedList
      .map((img, index) => {
        if (img.response.status >= 400 && img.response.status < 500) {
          return `${index + 1}) ${img.data.name}:\n${t('StockImageUpload.client_error')}`;
        } else {
          return `${index + 1}) ${img.data.name}:\n${t('StockImageUpload.server_error')}`;
        }
      })
      .join('\n\n');

  const onComplete = (response) => {
    if (response.failed.length) {
      const formattedErrorList = formatError(response.failed);
      displayErrors(formattedErrorList, response.failed.length);
    }

    syncUploadedImageData(uploadedFileNames.current, dealershipId, vehicleId);
    uploadedFileNames.current = [];
    setHasUploaded(true);
  };

  const mainGallery = (currentFile) => {
    const fileName = [props.vehicleData.taxonomyDetails.make, props.vehicleData.taxonomyDetails.model, nanoid()]
      .join('-')
      .replace(/\s/g, '-');
    const modifiedFile = {
      ...currentFile,
      meta: { ...currentFile.meta, fileName: `${fileName}.${currentFile.extension}`, tag: 'main' }
    };
    return modifiedFile;
  };

  const imperfectionsGallery = (currentFile) => {
    const fileName = [props.vehicleData.taxonomyDetails.make, props.vehicleData.taxonomyDetails.model, nanoid()]
      .join('-')
      .replace(/\s/g, '-');
    const modifiedFile = {
      ...currentFile,
      meta: { ...currentFile.meta, fileName: `${fileName}.${currentFile.extension}`, tag: 'imperfection' }
    };
    return modifiedFile;
  };

  const mainConfig = useVehicleCreateUppy({
    endpoint,
    onUploadSuccess,
    onComplete,
    onBeforeFileAdded: mainGallery
  });
  const imperfectionsConfig = useVehicleCreateUppy({
    endpoint,
    onUploadSuccess,
    onComplete,
    onBeforeFileAdded: imperfectionsGallery
  });

  const uppyRef = useRef(null);
  uppyRef.current = uploadType === 'main' ? mainConfig : imperfectionsConfig;

  const displayErrors = (formattedErrors, count) => {
    uppyRef.current.info(
      { message: t('StockImageUpload.failed_to_upload', { count: count }), details: formattedErrors },
      'error',
      20000
    );
  };

  const resetUppy = () => {
    setUploadType('');
    mainConfig.cancelAll();
    imperfectionsConfig.cancelAll();
  };

  const closeModal = () => {
    setShowUploadImagesModal(false);
    resetSlideModal();
    resetUppy();
    setHasUploaded(false);
  };

  const showModal = () => {
    setShowUploadImagesModal(true);
  };

  const slideNumberStyles = slideNumber ? `-${String(slideNumber)}00%` : '0%';

  const loadUploadDashboard = (type) => {
    setUploadType(type);
    setSlideNumber(1);
  };

  const resetSlideModal = () => {
    setSlideNumber(0);
    resetUppy();
    setHasUploaded(false);
  };

  return (
    <div>
      <Panel>
        <PanelHeader>{t('StockImageUpload.upload_images')}</PanelHeader>
        <PanelContent>
          <StockUploadedImages showUploadModal={showModal} imagesMetadata={props.vehicleData.imagesMetadata} />
        </PanelContent>
        <Modal title="" onClose={closeModal} isOpen={showUploadImagesModal}>
          <div className="StockImageUpload__uploadModal">
            <div
              style={{
                left: slideNumberStyles
              }}
              className={cn('StockImageUpload__uploadModalSlides')}
            >
              <div className="StockImageUpload__slide">
                <h2 className="StockImageUpload__slideTitle">{t('StockImageUpload.what_would_you_like_to_upload')}</h2>
                <div className="StockImageUpload__SlideButtons">
                  <Button onClick={() => loadUploadDashboard('main')}>
                    {t('StockImageUpload.upload_main_images')}
                  </Button>
                  <Button
                    buttonStyle="secondary"
                    onClick={() => loadUploadDashboard('imperfections')}
                    className={`StockImageUpload__uploadImperfectionsButton--${lng}`}
                  >
                    {t('StockImageUpload.upload_imperfection_images')}
                  </Button>
                </div>
              </div>
              <div className="StockImageUpload__slide">
                {uploadType === 'main' && (
                  <>
                    <h2 className="StockImageUpload__slideTitle">{t('StockImageUpload.gallery_images')}</h2>
                    <Dashboard
                      uppy={mainConfig}
                      className="stockImageUpload__Dashboard"
                      plugins={['Webcam', 'ImageEditor']}
                      inline={true}
                      width="100%"
                      proudlyDisplayPoweredByUppy={false}
                      thumbnailWidth={200}
                      doneButtonHandler={null}
                      hideProgressAfterFinish={true}
                    />
                  </>
                )}

                {uploadType === 'imperfections' && (
                  <>
                    <h2 className="StockImageUpload__slideTitle">{t('StockImageUpload.imperfection_images')}</h2>

                    <Dashboard
                      uppy={imperfectionsConfig}
                      className="stockImageUpload__Dashboard"
                      plugins={['Webcam', 'ImageEditor']}
                      inline={true}
                      width="100%"
                      proudlyDisplayPoweredByUppy={false}
                      thumbnailWidth={200}
                      doneButtonHandler={null}
                      hideProgressAfterFinish={true}
                    />
                  </>
                )}
                <div className="StockImageUpload__modalBackButton">
                  <Button buttonStyle="secondary" onClick={resetSlideModal}>
                    {t('StockImageUpload.back')}
                  </Button>
                  {hasUploaded && <Button onClick={closeModal}>{t('StockImageUpload.close_modal')}</Button>}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </Panel>
    </div>
  );
};

export default inject('appStore')(
  observerForHooks({
    vehicleData: (props) => props.appStore.stockEditStore.vehicleData
  })(StockImageUpload)
);
